<p-toast [style]="{marginTop: '30px'}" position="top-center" key="archTexto"></p-toast>

<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraBotones3]="true"
        [botonVisto]="false" [barraBotonesAsiCon]="true" [barraBotones5]="true" [barraBotones3Alt]="false" (signal)="manejarSenales($event)"></app-invmaebarra>
    </div>
</div> 


<div class="divgrups" style="height: 250px;">
    <div class="p-grid" *ngIf="cargarCombo" style="margin-top: 20px;">
        <div class="p-col-1"></div>
        <div class="p-col-4">
            <span style="font-size: 120%;">Reporte:</span>
            
        </div>
        <div class="p-col-4">
            <p-dropdown 
                        [options]="cmbFormato" 
                        [style]="{'minWidth': '100%', 'width':'100%'}"
                        optionLabel="MAEEXDAARCHPLAN_NOMBRE" 
                        optionValue="MAEEXDAARCHPLAN_CODIGO"
                        [panelStyle]="{'width':'110%'}"
						[(ngModel)]="selectFormato"
                        (onChange)="seleccionarFormato($event)">
            </p-dropdown>
        </div>
    </div>
    <div class="p-grid" >
        <div class="p-col-1"></div>
        <div class="p-col-4">
            <span style="font-size: 120%;">Caracter de separación de archivo plano:</span>
            
        </div>
        <div class="p-col-1">
            <input class="frm" maxlength="1" type="text" 
                pInputText  style="font-size: 18px;"
                [(ngModel)]="separacion"
                width="50%">
        </div>

    </div>
    <div class="p-grid">
        <div class="p-col-1"></div>
        <div class="p-col-4">
            <span style="font-size: 120%;">Archivo:</span>
            
        </div>
        <div class="p-col-4">

            <!-- <p-fileUpload id="com_firma" name="myfile[]"
                                              auto="auto" customUpload="true" chooseLabel="Seleccionar"
                                              (uploadHandler)="onBasicUpload($event)">
                                </p-fileUpload> -->
            <input  class="frm" 
                    type="text" 
                    pInputText
                    [(ngModel)]="nombreTxt"
                    placeholder="Inserte un nombre"
                    >
        </div>
    </div>
    <div class="p-grid" *ngIf="SEPEREXDAT === 1" style="margin-top: 20px;">
        <div class="p-col-1"></div>
        <div class="p-col-4">
            <span style="font-size: 120%;">Periodo:</span>
            
        </div>
        <div class="p-col-4">
            <p-dropdown 
                        [options]="cmbPeriodo" 
                        [style]="{'minWidth': '100%', 'width':'100%'}"
                        optionLabel="PERIODO_NOMBRE" 
                        optionValue="PERIODO_CODIGO"
                        [panelStyle]="{'width':'110%'}"
						[(ngModel)]="selectperiodo"
                        (onChange)="seleccionarFormato($event)">
            </p-dropdown>
        </div>
    </div>
</div>
