<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="guiaRemision"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarGuia" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>

<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones($event)">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="false" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="abrirSeriales()"
									class="archivoOpc">Ingresar Seriales</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="abrirClave('actAnular')"
									class="archivoOpc">Activar Guia Anulada</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="habilitarPorcIva()"
									class="archivoOpc">Modificar Porcentaje de Iva</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="abrirClave('actBtnXML')"
									class="archivoOpc">Activar Botón XML</span>
							</div>
							<!-- CDPJ -->
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="actbasesiva()"
									class="archivoOpc">Actualizar bases IVA</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-folder archivoOpc"></i><span (click)="verbasesiva()"
									class="archivoOpc">Visualizar bases IVA</span>
							</div>
							<!-- CDPJ -->
							<hr />
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="abrirClave('actBtnAnular')"
									class="archivoOpc">Activar botón anular de guia firmada electrónicamente</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="reversarProceso()"
									class="archivoOpc">Reversar Inicio de Proceso de firma electrónica</span>
							</div>
							<div *ngIf="boolfacturaElec === true" class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="continuarFE()"
									class="archivoOpc">Continuar proceso de firma electrónica</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span
									(click)="actualizarDatosElectronicos()" class="archivoOpc">Refrescar datos documento
									electrónico</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="abrirLog()"
									class="archivoOpc">Visualizar log del documento</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="visualizarEst()"
									class="archivoOpc">Estados firma electrónica</span>
							</div>
						</p-scrollPanel>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>

<div class="p-grid" style="width: 98%;">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
			[botonBorrar]="btnBorrar" [botonXML]="botonXML" [botonverAsiento]="boolasiento" (signal)="manejarSenales($event)"
			[botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo"
			[botonReenviarCorreo]="false" [botonSiguiente]="botonSiguiente" [barraGre]="false" [barraBotones3]="true"
			[barraBotonesAsiCon]="false" [botonAnular]="botonAnular" [botonAsiento]="boolasiento" [botonBuscar]="false"
			[botonAlmacenardoc]="true"  >
		</app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-2">
				<input type="text" pInputText
					style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_CODIGO"
					[disabled]="true">
			</div>
			<div class="p-col-1"></div>
			<div class="p-col-2">
				<input type="text" pInputText
					style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_ESTADO"
					[disabled]="true">
			</div>
			<div class="p-col-1">
				<input type="text" pInputText
					style="background-color: rgb(40,138,210); font-weight: bold; color: white;"
					[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_ESTADO_FE"
					[disabled]="true">
			</div>
			<div class="p-col-2">
				<input type="text" pInputText
					style="background-color: rgb(40,138,210); font-weight: bold; color: white;"
					[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_AUTORIZACION_FE"
					[disabled]="true">
			</div>
			<div class="p-col-2">
				<input type="text" pInputText
					style="background-color: rgb(40,138,210); font-weight: bold; color: white;"
					[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_CLAVEACCESO_FE"
					[disabled]="true">
			</div>
			<div class="p-col-2">
				<input type="text" pInputText style="background-color: #EFFFF7"
					[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ASI_NRO"
					[disabled]="true">
			</div>
		</div>
	</div>
</div>

<p-tabView [(activeIndex)]="activeIndex" (onChange)="changeTabs()">
	<p-tabPanel header="Guía de Remisión">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<!--				<div class="p-col-12">-->
				<!--					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">-->
				<div class="p-col-1"></div>
				<div class="p-col-2"></div>
				<div class="p-col-1"></div>
				<div class="p-col-1"></div>
				<div class="p-col-1"></div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
					<span style="font-weight: bold">Traslado: </span>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1"></div>
				<div class="p-col-1"></div>
				<div class="p-col-1"></div>
				<!--					</div>-->
				<!--				</div>-->
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Número:</span>
						</div>
						<div class="p-col-2">
							<input type="text" pInputText (input)="cambio($event)"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_NUMERO"
								[disabled]="blocknum">
						</div>
						<div class="p-col-1">
							<span>Fecha Emisión:</span>
						</div>
						<div class="p-col-1">
							<p-calendar (ngModelChange)="cambio($event)"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_FECHAEMISION"
								dateFormat="dd/mm/yy" [inputStyle]="{'width': '100%'}"></p-calendar>
						</div>
						<div class="p-col-1">
							<p-calendar (ngModelChange)="cambio($event)" [(ngModel)]="venenguiaRemisionService.horaSistema" id="horaEmision"
								(keydown.enter)="cambiarFoco($event)" [inputStyle]="{'width': '100%'}"
								[timeOnly]="true"></p-calendar>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span>Fecha Inicio:</span>
						</div>
						<div class="p-col-1">
							<p-calendar (ngModelChange)="cambio($event)"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_FECINITRASLADO"
								dateFormat="dd/mm/yy" [inputStyle]="{'width': '100%'}"></p-calendar>
						</div>
						<div class="p-col-1">
							<span>Fecha Fin:</span>
						</div>
						<div class="p-col-1">
							<p-calendar (ngModelChange)="cambio($event)"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_FECHFINTRASLADO"
								dateFormat="dd/mm/yy" [inputStyle]="{'width': '100%'}"></p-calendar>
						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Pto Partida:</span>
						</div>
						<div class="p-col-2">
							<input type="text" (input)="cambio($event)" pInputText id="ptoPartida"
								(keydown.enter)="cambiarFoco($event)"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_PTOPARTIDA">
						</div>
						<div class="p-col-1">
							<span>Pto LLegada:</span>
						</div>
						<div class="p-col-2">
							<input type="text" (input)="cambio($event)" pInputText id="ptoLlegada"
								(keydown.enter)="cambiarFoco($event)"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_PTOLLEGADA">
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span>Hora Inicio:</span>
						</div>
						<div class="p-col-1">
							<p-calendar [(ngModel)]="horaInicio" [inputStyle]="{'width': '100%'}" [timeOnly]="true"
								inputId="horaInicio" (keydown.enter)="cambiarFoco($event)"></p-calendar>
						</div>
						<div class="p-col-1">
							<span>Hora Fin:</span>
						</div>
						<div class="p-col-1">
							<p-calendar [(ngModel)]="horaFin" [inputStyle]="{'width': '100%'}" [timeOnly]="true"
								inputId="horaFin" (keydown.enter)="cambiarFoco($event)"></p-calendar>
						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
						<div class="p-col-1"></div>
						<div class="p-col-1"></div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span style="font-weight: bold">Motivo: </span>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1"></div>
						<div class="p-col-1"></div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Comentario:</span>
						</div>
						<div class="p-col-5">
							<input type="text" (input)="cambio($event)" pInputText id="comentario"
								(keydown.enter)="cambiarFoco($event)" autocomplete="off"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_COMENTARIO">
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1" style="text-align: left">
							<p-checkbox label="Compra" binary="true" [(ngModel)]="comprasboolean"
								(ngModelChange)="cambio($event)"></p-checkbox>
						</div>
						<div class="p-col-2">
							<p-checkbox label="Consignación" binary="true" [(ngModel)]="consigboolean"
								(ngModelChange)="cambio($event)"></p-checkbox>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-checkbox label="Devolución" binary="true" [(ngModel)]="devboolean"
								(ngModelChange)="cambio($event)"></p-checkbox>
						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Compra Vta:</span>
						</div>
						<div class="p-col-3">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" id="compraVta" (input)="cambio($event)"
										(keydown.enter)="cambiarFoco($event)" pInputText
										[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCFAC_NUMERO"
										readonly>
								</div>
								<div class="p-col-4">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirFactura()" [disabled]="btnOpenFact"></button>
								</div>
							</div>
						</div>

						<div class="p-col-1">
							<span>Fecha Em:</span>
						</div>
						<div class="p-col-1 ">
							<p-calendar [inputStyle]="{'width': '100%'}" dateFormat="dd/mm/yy"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCFAC_FECHAEMISION"
								[disabled]="true"></p-calendar>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1" style="text-align: left">
							<p-checkbox label="Exportación" binary="true" [(ngModel)]="expboolean"
								(ngModelChange)="cambio($event)"></p-checkbox>
						</div>
						<div class="p-col-2">
							<p-checkbox label="Importación" binary="true" [(ngModel)]="impboolean"
								(ngModelChange)="cambio($event)"></p-checkbox>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-checkbox label="Otros" binary="true" [(ngModel)]="otrosboolean"
								(ngModelChange)="cambio($event)"></p-checkbox>
						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -20px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Bodega:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" (input)="cambio($event)" pInputText id="bodega"
										(keydown.enter)="cambiarFoco($event)"
										[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].BOD_CODIGO">
								</div>
								<div class="p-col-4">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirBodega()"></button>
								</div>
							</div>
						</div>
						<div class="p-col-1">
							<span style="color: blue"> {{nombrebodega}}
							</span>
						</div>
						<div class="p-col-1">
							<span>Ref:</span>
						</div>
						<div class="p-col-1 ">
							<input type="text" (input)="cambio($event)" pInputText
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_REFERENCIA">
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1" style="text-align: left">
							<p-checkbox label="Transform." binary="true" [(ngModel)]="transboolean"
								(ngModelChange)="cambio($event)"></p-checkbox>
						</div>
						<div class="p-col-2">
							<p-checkbox label="Traslado&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" binary="true"
								[(ngModel)]="trasladoboolean" (ngModelChange)="cambio($event)"></p-checkbox>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-checkbox label="Venta" binary="true" [(ngModel)]="ventasboolean"
								(ngModelChange)="cambio($event)"></p-checkbox>
						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
			</div>
		</div>

		<app-aggridsaci *ngIf="detalleGuiaSeleccionado === undefined" [width]="1500" [height]="largo" [enableSorting]="false"
			[enableFilter]="true" [rowData]="venenguiaRemisionService.detguiaRemisionarray[indicador]"
			[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
			[columnDefs]="columnDefsGuiaDetalle" [defaultColDef]="defaultGuiaDetalle"
			(selected)="seleccionDetalleGuia($event)" (rdbtipodet)="cambiarTipo($event)" (cambios)="cambio($event)"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false"
			[botonesAdicionales]="true" [comboOpciones]="comboOpciones" [tipodetalle]="tipodetalle"
			(keydown.f9)="abrirDetalleFactura()" [botonPedidos]="false" [botonNuevo]="btnNuevoGrid"
			[botonGuardar]="btnGuardarGrid" [botonBorrar]="btnBorrarGrid" [botonRegresar]="btnCancelarGrid"
			[btnVerExistenciaBodPed]="false" [btnVervehiculo]="false"></app-aggridsaci>
		<app-aggridsaci *ngIf="detalleGuiaSeleccionado !== undefined && detalleGuiaSeleccionado.DETGRE_TIPODET !== ''"
			[width]="1500" [height]="largo" [enableSorting]="false" [enableFilter]="true"
			[rowData]="venenguiaRemisionService.detguiaRemisionarray[indicador]"
			[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
			[columnDefs]="columnDefsGuiaDetalle" [defaultColDef]="defaultGuiaDetalle"
			(selected)="seleccionDetalleGuia($event)" (rdbtipodet)="cambiarTipo($event)" (cambios)="cambio($event)"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false"
			[botonesAdicionales]="true" [comboOpciones]="comboOpciones"
			[tipodetalle]="detalleGuiaSeleccionado.DETGRE_TIPODET" (keydown.f9)="abrirDetalleFactura()"
			[botonPedidos]="false" [botonNuevo]="btnNuevoGrid" [botonGuardar]="btnGuardarGrid"
			[botonBorrar]="btnBorrarGrid" [botonRegresar]="btnCancelarGrid" [btnVerExistenciaBodPed]="false" 
			[btnVervehiculo]="false">
		</app-aggridsaci>
		<div class="divg">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span style="color: red;">F9 => Traer Items Factura</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-4">
									<p-colorPicker [disabled]="true" [(ngModel)]="choosedColorSnSk"></p-colorPicker>
								</div>
								<div class="p-col-8">
									<span>Sin Stock Necesario</span>
								</div>
							</div>
						</div>
						<div class="p-col-7">
							<div class="p-grid">
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-4">
											<p-colorPicker [disabled]="true" [(ngModel)]="choosedColor"></p-colorPicker>
										</div>
										<div class="p-col-8">
											<span>Existencia Bajo Min o sin existencias</span>
										</div>
									</div>
								</div>
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-4">
											<p-colorPicker [disabled]="true" [(ngModel)]="choosedColorR">
											</p-colorPicker>
										</div>
										<div class="p-col-8">
											<span>Precio Bajo Costo</span>
										</div>
									</div>
								</div>
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-4">
											<p-colorPicker [disabled]="true" [(ngModel)]="choosedColorG">
											</p-colorPicker>
										</div>
										<div class="p-col-8">
											<span>Promociones</span>
										</div>
									</div>
								</div>
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-4">
											<p-colorPicker [disabled]="true" [(ngModel)]="choosedColorSB">
											</p-colorPicker>
										</div>
										<div class="p-col-8">
											<span>Componente</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Generales">
		<div class="divgrups">
			<div class="p-grid">
				<!--				<div class="p-col-12">-->
				<!--					<div class="p-grid">-->
				<div class="p-col-1">
					<span style="font-weight: bold">Destinatario:</span>
				</div>
				<div class="p-col-11"></div>
				<!--					</div>-->
				<!--				</div>-->
				<div class="p-col-12" style="margin-top: -10px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Nombre:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" (input)="cambio($event)" pInputText id="nombreCli"
										(keydown.enter)="cambiarFoco($event)"
										[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].CLI_CODIGO">
								</div>
								<div class="p-col-4">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirClientes()"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span
								style="color: blue">{{venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_NMBRDSTNTR}}</span>
						</div>
						<div class="p-col-7"></div>
					</div>
				</div>

				<div class="p-col-12" style="margin-top: -20px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>RUC/CI:</span>
						</div>
						<div class="p-col-1">
							<input type="text" (input)="cambio($event)" pInputText
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_RUCDSTNTR">

						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span>Lista:</span>
						</div>
						<div class="p-col-1">
							<p-dropdown [style]="{'width': '100px'}" [options]="opcionesprecio"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_LISTA"
								(ngModelChange)="cambio($event)"></p-dropdown>

						</div>
						<div class="p-col-1">
							<span>IVA:</span>
						</div>
						<div class="p-col-1">
							<p-dropdown [style]="{'minWidth': '100px','width': '80px'}" [options]="opcionIvaDesc"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_IVA"
								(ngModelChange)="cambio($event)"></p-dropdown>

						</div>
						<div class="p-col-1">
							<!-- <p-dropdown [options]="opcionIva" [style]="{'minWidth': '100px','width': '80px'}"
								[disabled]="porcIvaBoolean"
								[(ngModel)]="venenguiaRemisionService.dblPorcIvaGrearray[indicador]"
								(ngModelChange)="venenguiaRemisionService.calcularTotal()"></p-dropdown> -->

								<p-dropdown [options]="opcionIva" [style]="{'minWidth': '100%', 'width': '100%'}"
								optionLabel="name" 
								(onChange)="cambiavalorIva($event)"
								[(ngModel)]="selectcombtipoIva"
								[disabled]="porcIvaBoolean"
								></p-dropdown>
						</div>
						<div class="p-col-4"></div>
					</div>
				</div>

				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Dirección:</span>
						</div>
						<div class="p-col-6">
							<input type="text" (input)="cambio($event)" pInputText
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_DIRECCIONDSTNTR">

						</div>
						<div class="p-col-5"></div>

					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Teléfono:</span>
						</div>
						<div class="p-col-3">
							<input type="text" (input)="cambio($event)" pInputText
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_TELEFONODSTNTR">
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
						<div class="p-col-12"></div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
						<div class="p-col-12"></div>
					</div>
				</div>


				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span style="font-weight: bold">Transportista: </span>
						</div>
						<div class="p-col-11"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Nombre: </span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" (input)="cambio($event)" pInputText id="transportNombre"
										(keydown.enter)="cambiarFoco($event)"
										[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].TRANSPORT_CODIGO">
								</div>
								<div class="p-col-4">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirTransportista()"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span
								style="color: blue">{{venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_NMBRTRNSP}}</span>
						</div>
						<div class="p-col-7"></div>
					</div>
				</div>

				<div class="p-col-12" style="margin-top: -20px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>RUC/CI:</span>
						</div>
						<div class="p-col-1">
							<input type="text" (input)="cambio($event)" pInputText
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_RUCTRNSP">

						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span>Tonelaje:</span>
						</div>
						<div class="p-col-1">
							<input type="text" (input)="cambio($event)" pInputText
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_TONELAJE">

						</div>
						<div class="p-col-7"></div>

					</div>
				</div>

				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Auto:</span>
						</div>
						<div class="p-col-6">
							<input type="text" (input)="cambio($event)" pInputText
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_AUTOTNTR">

						</div>
						<div class="p-col-5"></div>
					</div>
				</div>

				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Placa:</span>
						</div>
						<div class="p-col-3">
							<input type="text" (input)="cambio($event)" pInputText
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_PLACATNTR">
						</div>
					</div>
				</div>

				<div class="p-col-12" style="margin-top: -10px">
					<div class="p-grid">
						<div class="p-col-1">
							<span style="font-weight: bold">Cooler Serial: </span>
						</div>
						<div class="p-col-2">
							<input type="text" (input)="cambio($event)" pInputText
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_COOLERSERIAL">
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
						<div class="p-col-12"></div>
					</div>
				</div>

				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span style="font-weight: bold">Otros:</span>
						</div>
						<div class="p-col-11"></div>
					</div>
				</div>


				<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Referencia: </span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" (input)="cambio($event)" id="referencia"
										(keydown.enter)="cambiarFoco($event)" pInputText
										[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_REFERENCIA">
								</div>
								<div class="p-col-4">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirReferencia()"></button>
								</div>
							</div>
						</div>
						<div class="p-col-9"></div>

					</div>
				</div>
			</div>
		</div>

	</p-tabPanel>
	<p-tabPanel header="Información Adicional" [disabled]="btnNuevo && activeIndex<2">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-10"></div>
				<div class="p-col-1">
					<button type="button" style="background-image: url(../../../assets/images/iconos/visto.png);"
						(click)="ingresarInfoAdi()"></button>
					<!--					<button class="aceptar" pButton (click)="ingresarInfoAdi()"-->
					<!--							style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; margin-left: 25px">-->
					<!--						Aceptar-->
					<!--					</button>-->
				</div>
				<div class="p-col-1">
				</div>
			</div>
		</div>

		<app-aggridsaci [width]="" [height]="largoRef" [enableSorting]="false" [enableFilter]="true"
			[rowData]="venenguiaRemisionService.detguiaReferenciaarray[indicador]"
			[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
			[columnDefs]="columnDefsGuiaReferencia" [defaultColDef]="defaultGuiaReferencia" [rowStyle]="rowStyle"
			[singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false"
			(selected)="seleccionDetalleRef($event)"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Estado de Cuenta" [disabled]="btnNuevo && activeIndex<2">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span
						style="color: blue">{{venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].CLI_CODIGO + ' - ' + venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_NMBRDSTNTR}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen"
			[defaultColDef]="defaultColDefCxctmptrnresumen" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Saldos" [disabled]="btnNuevo && activeIndex<2">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span
						style="color: blue">{{venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].CLI_CODIGO + ' - ' + venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_NMBRDSTNTR}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen"
			[defaultColDef]="defaultColDefCxctrnresumen" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Estadísticas" [disabled]="disabledEstadisticas">
		<div class="p-grid">
			<!--LINEA 1-->
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-4">
								<button pButton (click)="mostrarEstadisticas()"
									style="background-image: url(../../../assets/images/iconos/cogwheel.png); width: 40px; height: 40px; background-color: transparent;"
									title="Generar estadísticas">
								</button>
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">VENTAS POR
									MESES</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-chart [options]="options" type="bar" [data]="dataVentasAcum" height="415px"></p-chart>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-6">
								<span style="font-size: 16px; font-weight: bold;">
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									PRODUCTOS MÁS VENDIDOS</span>
							</div>
							<div class="p-col-3">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="cantidad" inputId="orden1"
									(onClick)="orderByTopProd('cantidad')"></p-radioButton>
								<label> Cantidad </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="precio" inputId="orden2"
									(onClick)="orderByTopProd('total')"></p-radioButton>
								<label> Total </label>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px;">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detTopProductos" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsTopProductos"
							[defaultColDef]="defaultColTopProductos" [rowStyle]="rowStyleTopProd" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-12">
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">

					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-4">
							</div>
							<div class="p-col-2">
								<span style="font-size: 16px; font-weight: bold; text-align: center">DOCUMENTOS</span>
							</div>
							<div class="p-col-5">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Documentos por Cobrar:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DXC" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalDocXCobrar" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalDocXCobrarPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Documentos Vencidos:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DOCVENCIDOS" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalDocVencidos" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalDocVencidosPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Cheques Posfechados:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_CHPOSFECHADOS" type="text" pInputText readonly
									style="text-align: right" [(ngModel)]="totalChPosfechados" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalChPosfechadosPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold; float: right">Total:
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DOCUMENTOS" type="text" pInputText readonly
									style="text-align: right; font-weight: bold" [(ngModel)]="totalDocumentos"
									autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Cheques Protestados:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_CHPROTESTADOS" type="text" pInputText readonly
									style="text-align: right" [(ngModel)]="totalChProtestados" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Pedidos Pendientes:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_PEDIDOPEND" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalPedPendientes" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Pedidos Satisfechos:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_PEDSATISF" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalPedSatisfechos" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold; ">Primera Factura: </span>
							</div>
							<div class="p-col-2">
								<input id="FechaPrimeraFact" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="fechaPrimeraFact" autocomplete="off">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-4">
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">DETALLE DE
									FACTURAS</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="fecha" inputId="orden1"
									(onClick)="orderByDetFacturas('fecha')"></p-radioButton>
								<label> Fecha </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="producto" inputId="orden2"
									(onClick)="orderByDetFacturas('producto')"></p-radioButton>
								<label> Producto </label>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detFacturas" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsDetFacturas"
							[defaultColDef]="defaultColDetFacturas" [rowStyle]="rowStyleDetFacts" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
</p-tabView>
<div class="divgrupstabla">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<span class="obligatorio">Neto</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Dscto (Valor)</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Dscto (%)</span>
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<span class="obligatorio">Base I.V.A.</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Valor I.V.A.</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Base I.C.E.</span>
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<span class="obligatorio">Valor I.C.E.</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Base Cero</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">TOTAL</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -20px">
			<div class="p-grid">
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_TOTALNETO">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_VALORDES">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_PORCEDES">
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_BASEIVA">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_VALORIVA">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_BASEICE">
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_VALORICE">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_BASECERO">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_TOTAL">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" [where]="''" (opcionSeleccionada)="manejarSeleccion($event)" [DVBUSGR]="DVBUSGR"
		[consulta]="consulta"></app-busquedadlg>
</p-dialog>
<p-dialog header="Seleccione la opción" [(visible)]="displayDialogBus" [transitionOptions]="'0ms'" [modal]="false"
	appendTo="body"  [style]="{width:'90vw', height:'80vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBus"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>
<p-dialog header="Selección de datos" [(visible)]="displayDialogDetFac" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'70vw', height:'60vh'}">
	<app-vendetfac
		*ngIf="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCFAC_NUMERO !== '' && venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCFAC_NUMERO !== undefined"
		[encfacNumero]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCFAC_NUMERO"
		[hmpConfiguracion]="venenguiaRemisionService.hmpConfiguracion" [nomComponente]="'venGuiaRem'"
		(detallesSeleccionados)="recibirDetalles($event)" (signalSalir)="recibirSenales($event)"></app-vendetfac>
</p-dialog>
<p-dialog header="Selección de datos" [(visible)]="displayDialogPedidos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'70vw', height:'60vh'}">
	<app-venpedidos
		*ngIf="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].CLI_CODIGO !== '' && venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].CLI_CODIGO !== undefined && displayDialogPedidos === true"
		[cliCodigo]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].CLI_CODIGO"
		[indicadorGuia]="indicador" (detallesSeleccionados)="recibirDetallesPedidos($event)"
		(signalSalir)="recibirSenalesPedidos($event)">
	</app-venpedidos>
</p-dialog>
<p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr *ngIf="codigoGuiaAux !== '' && displayDialogSeriales === true"
		[strNumFact]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_NUMERO"
		[strFechaSer]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_FECHAEMISION"
		[strTipoDoc]="'GRE'" [strTipoTrn]="'SA'"></app-invtrnkardexotr>
</p-dialog>
<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<p-dialog header="Log de la firma Electrónica" [(visible)]="displayLog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: '60vh'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<textarea rows="12" cols="100" pInputTextarea style="width: 100%; height: 400px" [(ngModel)]="logResultado"
				[disabled]="true" maxlength="3000"></textarea>
		</div>
	</div>
</p-dialog>
<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'80vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="NumAsiRegCont" [abiertoDesde]="'banTrnBan'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>


<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_NUMERO"
		[strform]="'Si'"
		[strCampo]="'ENCGRE_NUMERO'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>


<p-dialog header="Existencia por bodega" [(visible)]="displayDialogExistBod" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '500px'}">
	<app-existenciabodega *ngIf="displayDialogExistBod" [txtArtCodigo]="txtArtCodigo"
		[txtArtDescripcion]="txtArtDescripcion" [txtListaPrecio]="txtListaPrecio" [iva]="txtIva"
		[txtCliente]="txtObservacionRes" [txtBodegaDes]="txtBodegaRes" (signalSalir)="salirExistenciasBod()">
	</app-existenciabodega>
</p-dialog>

<p-dialog header="Lotes" [(visible)]="displayDialogLotes" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-invtrnlote [auxiliar]="auxiliarlot" [detalle]="detalleGuiaSeleccionado"
		[encabezado]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador]" [cadena]="'GUIREM'"
		(salir)="cerrarLotes()"></app-invtrnlote>
</p-dialog>

<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'VENENCGRE'"
		[txtNumComprobante]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_NUMERO"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>

<!-- ///////////////////////nuevo vehiculo////////////////////// -->
<p-dialog header="Datos Vehículo" [(visible)]="displayvehiculo" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '85vw', height: '85vh'} " [focusOnShow]="true" appendTo="body" >
	
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-2">
            <span>Artículo</span>
        </div>
        <div class="p-col-10">
            <span>{{this.detalleGuiaSeleccionado.DETGRE_CODIGO + ' - ' + this.detalleGuiaSeleccionado.DETGRE_DESCRIPCION }}</span>
        </div>
    </div>
</div>
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>RAMV:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_RAMV"></label>
					<input class="frm" id="ART_RAMV" type="text" pInputText disabled 
					value="{{ART_RAMV}}">
                </div>
                <div class="p-col-1">
                    <span>Motor:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_MOTOR"></label>
					<input class="frm" id="ART_MOTOR" type="text" pInputText disabled
					value="{{ART_MOTOR}}">
                </div>
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Modelo SRI:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_MODELOSRI"></label>
					<input class="frm" id="ART_MODELOSRI" type="text" pInputText disabled
					value="{{ART_MODELOSRI}}">
                </div>
                <div class="p-col-1">
                    <span>Serie:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_SERIEV"></label>
					<input class="frm" id="ART_SERIEV" type="text" pInputText disabled
					value="{{ART_SERIEV}}">
                </div>
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Capacidad:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_CAPACIDAD"></label>
					<input class="frm" id="ART_CAPACIDAD" type="text" pInputText disabled
					value="{{ART_CAPACIDAD}}">
                </div>
                <div class="p-col-1">
                    <span>Cilidraje:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_CILINDRAJE"></label>
					<input class="frm" id="ART_CILINDRAJE" type="text" pInputText disabled
					value="{{ART_CILINDRAJE}}">

                </div>
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Color:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_COLOR"></label>
					<input class="frm" id="ART_COLOR" type="text" pInputText disabled
					value="{{ART_COLOR}}">
                </div>
                <div class="p-col-1">
                    <span>Año:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_ANIO"></label>
					<input class="frm" id="ART_ANIO" type="text" pInputText disabled
					value="{{ART_ANIO}}">
                </div>
                
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Marca:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_MARCAV"></label>
							<input class="frm" id="ART_MARCAV" type="text"  pInputText disabled
							value="{{ART_MARCAV}}">
                        </div>
                        <div class="p-col-7">
                            <label for="ART_MARVEH"></label>
							<input class="frm" id="ART_MARVEH" type="text" pInputText disabled
							value="{{ART_MARVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Tonelaje:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_TONELAJE"></label>
					<input class="frm" id="ART_TONELAJE" type="text" pInputText disabled
					value="{{ART_TONELAJE}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Clase:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_CLASE"></label>
							<input class="frm" id="ART_CLASE" type="text" pInputText disabled
							value="{{ART_CLASE}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_CLAVEH"></label>
							<input class="frm" id="ART_CLAVEH" type="text" pInputText disabled
							value="{{ART_CLAVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Número de ejes:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_NEJES"></label>
					<input class="frm" id="ART_NEJES" type="text" pInputText disabled
					value="{{ART_NEJES}}">
                </div>

            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Tipo de vehiculo:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_TIPOV"></label>
							<input class="frm" id="ART_TIPOV" type="text" pInputText disabled
							value="{{ART_TIPOV}}">
                        </div>
                        <div class="p-col-7">
                            <label for="ART_TIPVEH"></label>
							<input class="frm" id="ART_TIPVEH" type="text" pInputText disabled
							value="{{ART_TIPVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Número de ruedas:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_NRUEDAS"></label>
					<input class="frm" id="ART_NRUEDAS" type="text" pInputText disabled
					value="{{ART_NRUEDAS}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Combustible:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_COMBUSTIBLE"></label>
							<input class="frm" id="ART_COMBUSTIBLE" type="text" pInputText disabled
							value="{{ART_COMBUSTIBLE}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_COMVEH"></label>
							<input class="frm" id="ART_COMVEH" type="text" pInputText disabled
							value="{{ART_COMVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Fecha de fabricación:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_FFABRICA"></label>
					<input class="frm" id="ART_FFABRICA" pInputText disabled
					value="{{ART_FFABRICA}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Dispositivo:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_DISPOSITIVO"></label>
							<input class="frm" id="ART_DISPOSITIVO" type="text" pInputText disabled
							value="{{ART_DISPOSITIVO}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_DISVEH"></label>
							<input class="frm" id="ART_DISVEH" type="text" pInputText disabled
							value="{{ART_DISVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Modelo agencia de tránsito:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_MODELOAT"></label>
					<input class="frm" id="ART_MODELOAT" type="text" pInputText disabled
					value="{{ART_MODELOAT}}">
                </div>
            </div>
        </div>

        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>País de origen:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_PORIGEN"></label>
							<input class="frm" id="ART_PORIGEN" type="text" pInputText disabled
							value="{{ART_PORIGEN}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_P"></label>
							<input class="frm" id="ART_P" type="text" pInputText disabled
							value="{{ART_P}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Detalle de pago:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_DPAGO"></label>
					<input class="frm" id="ART_DPAGO" type="text" pInputText disabled
					value="{{ART_DPAGO}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Banco de Pago:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_BANCO"></label>
							<input class="frm" id="ART_BANCO" type="text" pInputText disabled
							value="{{ART_BANCO}}">
                            
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_NBANCO"></label>
							<input class="frm" id="ART_NBANCO" type="text" pInputText disabled
							value="{{ART_NBANCO}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-6">
                    <p style="text-align: center;">
                        <button class=button_largos type="button"
							style="background-image: url(../../../assets/images/iconspdv/boton-ver-imagen.png); width: 7em"
							(click)="verimagen(detalleGuiaSeleccionado.DETGRE_CODIGO)"></button>
                    </p>
                </div>

            </div>
        </div>
    </div>
</div>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentosim" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentosim" [txtOrigen]="'Invmaearticulo'"
		[txtNumComprobante]="detalleGuiaSeleccionado.DETGRE_CODIGO" (signalSalir)="recibirSenalesDocumentos($event)">
	</app-uploadfiles>
</p-dialog>
<!-- CDPJ -->
<p-dialog header="Estados firma electrónica" [(visible)]="displayDialogEst" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: '60vh'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<label for="ESTFIRMA"></label>
			<textarea id="ESTFIRMA" rows="12" cols="100" pInputTextarea style="width: 100%; height: 230px;font-size: 16px;"
				[(ngModel)]="estfirma" [disabled]="true" maxlength="3000"></textarea>
		</div>
	</div>
</p-dialog>
<p-dialog header="Bases IVA" [(visible)]="displaybasesiva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '55vw', height: '55vh'} " [focusOnShow]="true" appendTo="body" >

	<app-aggridsaci [width]="-800" [height]="-800" [enableSorting]="true" [enableFilter]="true"
			[rowData]="basesiva" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsBasesIva"
			[defaultColDef]="defaultColBasesIva" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
</p-dialog>
<p-dialog header="Formularios de Correos" [(visible)]="displayDialogfrmcorreos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacifrmenviocorreos [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
	[strNumDoc]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].ENCGRE_NUMERO" [strCampo]="'ENCGRE_NUMERO'"
		[strCliProvCod]="venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[indicador].CLI_CODIGO" [CENREPDOCE]="CENREPDOCE"
		(cerrar)="cerrarfrmcorreos()"></app-sacifrmenviocorreos>
</p-dialog>
<!-- CDPJ -->