import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MAERL, MAERL_BI } from '../interfaces/MAERL';
import { MAERLPARAM } from '../interfaces/MAERLPARAM';
import { UsuarioEsquema } from '../usuarioEsquema';
import { Usuario } from '../usuario';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from './conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {
  public url: string;
  public way: string;
  public wayrep:string;

  public parametroIde: string;

  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private datePipe: DatePipe,
    private confIniciales: ConfInicialesService) {
    this.usuarioEsquema.loadStorage();
    this.usuario.loadStorage();
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
    this.wayrep=sessionStorage.getItem('wayrep');
    

  }

  mandarSentenciaReportes(codigo, tipo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/c2faedbb38df600', {
      elementos: {
        OPC_CODIGO: codigo,
        RL_TIPO: tipo
      }
    }, this.confIniciales.httpOptions());
  }

  mandarSentenciaParametros(codigo: MAERL): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/c2faedbb3956d80', {
      elementos: {
        RL_CODIGO: codigo.RL_CODIGO,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  busqueda(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/5fb8cdbb38df5c0', {
      elementos: {}
    }, this.confIniciales.httpOptions());
  }

  mandarSentenciaGrupo(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/c2fbc7431964b00', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  mandarSentenciaGrupoCodigo(codigo): Observable<any> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/38ebf5d0dfb38', {
      elementos: {
        OPCCODIGO: codigo
      }
    }, this.confIniciales.httpOptions());
  }

  insertarReporte(reporteNuevo: MAERL): Observable<any[]> {
    if (reporteNuevo.RL_DESCRIPCION === undefined) {
      reporteNuevo.RL_DESCRIPCION = null;
    }
    if (reporteNuevo.RL_LOCALIZACION === undefined) {
      reporteNuevo.RL_LOCALIZACION = null;
    }
    return this.http.post<any[]>(this.way + '/reporte/listareportes/95f8ededd9c6f80', {
      elementos: {
        p_RL_nombre_1: reporteNuevo.RL_NOMBRE,
        p_RL_archivo_2: reporteNuevo.RL_ARCHIVO,
        p_RL_tipo_3: reporteNuevo.RL_TIPO.substr(0, 1),
        p_RL_procedencia_4: reporteNuevo.RL_PROCEDENCIA.substr(0, 1),
        p_OPC_codigo_5: reporteNuevo.OPC_CODIGO,
        p_RL_proceso_6: reporteNuevo.RL_PROCESO,
        p_RL_clase_7: 'f',
        p_RL_criterios_8: '',
        p_RL_nroformula_9: reporteNuevo.RL_NROFORMULA,
        p_RL_padre_10: 0,
        p_RL_objeto_11: reporteNuevo.RL_OBJETO,
        p_RL_descripcion: reporteNuevo.RL_DESCRIPCION,
        p_RL_localizacion: reporteNuevo.RL_LOCALIZACION,
        p_RL_parametroide: '1'
      }
    }, this.confIniciales.httpOptions());
  }

  eliminareporte(reporteborrar: MAERL): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/eacad2bedd9c7000', {
      elementos: {
        p_RL_codigo_1: reporteborrar.RL_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  mandarSentenciaBU(codigo): Observable<MAERL> {
    return this.http.post<MAERL>(this.way + '/reporte/listareportes/3317e776ecf57e', {
      elementos: {
        RL_CODIGO: codigo,
      }
    }, this.confIniciales.httpOptions());
  }

  actualizareporte(reporte: MAERL): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/299df6dd9c6fae', {
      elementos: {
        p_RL_codigo_1: reporte.RL_CODIGO,
        p_RL_nombre_2: reporte.RL_NOMBRE,
        p_RL_archivo_3: reporte.RL_ARCHIVO,
        p_RL_tipo_4: reporte.RL_TIPO.substr(0, 1),
        p_RL_procedencia_5: reporte.RL_PROCEDENCIA.substr(0, 1),
        p_OPC_codigo_6: reporte.OPC_CODIGO,
        p_RL_proceso_7: reporte.RL_PROCESO,
        p_RL_clase_8: 'a',
        p_RL_criterios_9: '',
        p_RL_nroformula_10: reporte.RL_NROFORMULA,
        p_RL_padre_11: 0,
        p_RL_objeto_12: reporte.RL_OBJETO,
        p_RL_descripcion: reporte.RL_DESCRIPCION,
        p_RL_localizacion: reporte.RL_LOCALIZACION,
        p_RL_parametroide: '1'
      }
    }, this.confIniciales.httpOptions());
  }

  insertarparametro(parametro: MAERLPARAM): Observable<any[]> {
    console.log(parametro);
    return this.http.post<any[]>(this.way + '/reporte/listareportes/4afc76fb956d58', {
      elementos: {
        p_RL_codigo_0: Number(parametro.RL_CODIGO),
        p_RLP_objeto_1: parametro.RLP_OBJETO,
        p_RLP_parametro_2: parametro.RLP_PARAMETRO,
        p_RLP_etiqueta_3: parametro.RLP_ETIQUETA,
        p_RLP_tipo_4: parametro.RLP_TIPO,
        p_RLP_ubicacion_5: Number(parametro.RLP_UBICACION),
        p_RLP_dimension_6: Number(parametro.RLP_DIMENSION),
        p_RLP_maximo_7: Number(parametro.RLP_MAXIMO),
        p_RLP_consulta_8: parametro.RLP_CONSULTA,
        p_RLP_condicion_9: parametro.RLP_CONDICION,
        p_RLP_criterios_10: parametro.RLP_CRITERIOS,
        p_RLP_tabla_11: parametro.RLP_TABLA,
        p_RLP_pasafor_12: '',
        p_RLP_tipovar_13: parametro.RLP_TIPOVARIABLE
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarparametro(parametro: MAERLPARAM, ubicacion, param): Observable<any[]> {
    console.log(parametro);
    return this.http.post<any[]>(this.way + '/reporte/listareportes/299df2b2adaace', {
      elementos: {
        p_RL_codigo_0: Number(parametro.RL_CODIGO),
        p_RLP_objeto_1: parametro.RLP_OBJETO,
        p_RLP_parametro_2: param,
        p_RLP_ubicacion_3: ubicacion,
        p_RLP_parametro_4: parametro.RLP_PARAMETRO,
        p_RLP_etiqueta_5: parametro.RLP_ETIQUETA,
        p_RLP_tipo_6: parametro.RLP_TIPO,
        p_RLP_ubicacion_7: Number(parametro.RLP_UBICACION),
        p_RLP_dimension_8: Number(parametro.RLP_DIMENSION),
        p_RLP_maximo_9: Number(parametro.RLP_MAXIMO),
        p_RLP_consulta_10: parametro.RLP_CONSULTA,
        p_RLP_condicion_11: parametro.RLP_CONDICION,
        p_RLP_criterios_12: parametro.RLP_CRITERIOS,
        p_RLP_tabla_13: parametro.RLP_TABLA,
        p_RLP_pasafor_14: '',
        p_RLP_tipovar_15: parametro.RLP_TIPOVARIABLE
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarParametro(parametro: MAERLPARAM): Observable<any> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/3ab2b655b559dc', {
      elementos: {
        p_RL_codigo_0: Number(parametro.RL_CODIGO),
        p_RLP_objeto_1: parametro.RLP_OBJETO,
        p_RLP_parametro_2: parametro.RLP_PARAMETRO,
        p_RLP_ubicacion_3: parametro.RLP_UBICACION,
      }
    }, this.confIniciales.httpOptions());
  }

  mandarSentenciaBusqueda(sqlCon): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/38ebf5d762fdc', {
      elementos: {
        sentencia: sqlCon
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerGrupo(nombre, prefijo): Observable<any> {
    return this.http.post<any>(this.way + '/reporte/listareportes/c2faebbb70dfb00', {
      elementos: {
        opcnombre: nombre,
        opcobjeto: 'frmLIS_GENERAL',
        modprefijo: prefijo,
        mencodigo: '2'
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerGrupoLis(nombre, prefijo): Observable<any> {
    return this.http.post<any>(this.way + '/reporte/listareportes/c2faebc37ecd680', {
      elementos: {
        opcnombre: nombre,
        opcobjeto: 'frmLIS_GENERAL',
        modprefijo: prefijo,
        mencodigo: '7'
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarTabla(tabla): Observable<any> {
    let strSQL: string;
    strSQL = 'DELETE ' + tabla + ' WHERE PARAMETRO_IDE = \'' + this.parametroIde + '\'';
    // strSQL = 'DELETE ' + tabla;
    console.log(strSQL);
    return this.http.post<any>(this.way + '/reporte/listareportes/7565695d5d52ec0', {
      elementos: {
        TABLA_TMP: tabla,
        PARAMETRO_IDE: this.parametroIde
      }
    }, this.confIniciales.httpOptions());
  }

  ejecutarProcedure(criterios, parametros, proceso): Observable<any> {
    console.log(proceso);
    this.parametroIde = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');

    let strSQL: string;
    strSQL = 'Begin ' + proceso.RL_PROCESO + '(';
    for (const i of criterios) {
      strSQL += i.parametro1 + '=>' + '\'' + i.campo1 + '\'' + ',';
      strSQL += i.parametro2 + '=>' + '\'' + i.campo2 + '\'' + ',';
    }
    for (const i of parametros) {
      strSQL += i.parametro1 + '=>' + '\'' + i.campo1 + '\'' + ',';
    }
    strSQL += 'p_COM_codigo =>' + '\'01\'' + ',' +
      'p_USUIDENTIFICACION =>' + '\'admin\'' + ',' +
      'p_PARAMETRO_IDE =>' + '\'' + this.parametroIde + '\'';
    // strSQL = strSQL.slice(0, -1);
    strSQL += '); END;';
    console.log(strSQL);
    return this.http.post<any>(this.way + '/reporte/listareportes/3a6e67bb9de18e00000', {
      elementos: {
        RL_PROCESO: proceso.RL_PROCESO,
        CRITERIOS_LIST: criterios,
        PARAMETROS_LIST: parametros,
        PARAMETRO_IDE: this.parametroIde
      }
    }, this.confIniciales.httpOptions());
  }

  ejecutarProcedureSN(criterios, parametros, proceso): Promise<any> {
    console.log(proceso);
    if (proceso.RL_PROCESO === null) {
      return;
    }
    this.parametroIde = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    console.log('Ide', this.parametroIde);
    let strSQL: string;
    strSQL = 'Begin ' + proceso.RL_PROCESO + '(';
    for (const i of criterios) {
      strSQL += '\'' + i.campo1 + '\'' + ',';
      strSQL += '\'' + i.campo2 + '\'' + ',';
    }
    for (const i of parametros) {
      strSQL += '\'' + i.campo1 + '\'' + ',';
    }

    strSQL += '' + '\'01\'' + ',' +
    '' + '\'' + this.usuario.identificacion +'\'' + ',' +
      '' + '\'' + this.parametroIde + '\'';
    // strSQL = strSQL.slice(0, -1);
    strSQL += '); END;';
    console.log(strSQL);
    return this.http.post<any>(this.way + '/reporte/listareportes/74dd9de18e6fb80', {
      elementos: {
        RL_PROCESO: proceso.RL_PROCESO,
        CRITERIOS_LIST: criterios,
        PARAMETROS_LIST: parametros,
        PARAMETRO_IDE: this.parametroIde,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ejecutarProcedureSNOrden(criterios, parametros, proceso, criteriosreporte): Promise<any> {
    console.log(proceso);
    if (proceso.RL_PROCESO === null) {
      return;
    }
    this.parametroIde = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    console.log('Ide', this.parametroIde);
    let strSQL: string;
    strSQL = 'Begin ' + proceso.RL_PROCESO + '(';

    for (const j of criteriosreporte) {
      for (const i of criterios) {
        if (j.RLP_PARAMETRO === i.parametro1) {
          if(i.campo1 === null){
            i.campo1='';
          }
          strSQL += '\'' + i.campo1 + '\'' + ',';
        }
        if (j.RLP_PARAMETRO === i.parametro2) {
          if(i.campo2 === null){
            i.campo2='';
          }
          strSQL += '\'' + i.campo2 + '\'' + ',';
        }
      }
      for (const i of parametros) {        
        if (j.RLP_PARAMETRO === i.parametro1) {
          if(i.campo1 === null){
            i.campo1='';
          }
          strSQL += '\'' + i.campo1 + '\'' + ',';
        }
      }
    }

    strSQL += '' + '\'01\'' + ',' +
      '' + '\'admin\'' + ',' +
      '' + '\'' + this.parametroIde + '\'';
    // strSQL = strSQL.slice(0, -1);
    strSQL += '); END;';
    console.log(strSQL);
    //console.log(this.repnewpvbe)
    //if (this.repnewpvbe === true || this.repnewpvbe === 'true') {
      return this.http.post<any>(this.wayrep + '/reporte/listareportes/1d37677867b97c0000', {
        elementos: {
          RL_PROCESO: proceso.RL_PROCESO,
          CRITERIOS_REPORTE: criteriosreporte,
          CRITERIOS_LIST: criterios,
          PARAMETROS_LIST: parametros,
          PARAMETRO_IDE: this.parametroIde,
          USU_IDENTIFICACION:this.usuario.identificacion
        }
      }, this.confIniciales.httpOptions()).toPromise();
    //}
    // else{
    //   return this.http.post<any>(this.way + '/reporte/listareportes/1d37677867b97c0000', {
    //     elementos: {
    //       RL_PROCESO: proceso.RL_PROCESO,
    //       CRITERIOS_REPORTE: criteriosreporte,
    //       CRITERIOS_LIST: criterios,
    //       PARAMETROS_LIST: parametros,
    //       PARAMETRO_IDE: this.parametroIde,
    //       USU_IDENTIFICACION:this.usuario.identificacion
    //     }
    //   }, this.confIniciales.httpOptions()).toPromise();
    // }
    
  }


  generarReporte(nombrereporte, query, booleanexcel, sql): Observable<any> {
    console.log(nombrereporte);
    const ubirep = sessionStorage.getItem('ubicacionReportes');

    let params = {};
    if (sql !== '') {      
      params = {
        COMCODIGO: '01',
        SUBREPORT_DIR: ubirep,
        NOMUSUARIO: this.usuario.identificacion,
        parametroIDE: this.parametroIde,
        querryString: query,
        querryStringUno: sql
      }
    } else {
      params = {
        COMCODIGO: '01',
        SUBREPORT_DIR: ubirep,
        NOMUSUARIO: this.usuario.identificacion,
        parametroIDE: this.parametroIde,
        querryString: query,
      }
    }

    const headers = new HttpHeaders().set('Session-Id', sessionStorage.getItem('session'));
    headers.set('withCredentials', 'true');
    if (booleanexcel === true) {
      return this.http.post(this.way + '/reporte/2d8e776adedd9c0000', {
        nombreReporte: nombrereporte,
        parametros: params,
        excel: booleanexcel,
        
      }, { headers, responseType: 'text' });
    } else {
      return this.http.post(this.way + '/reporte/2d8e776adedd9c0000', {
        nombreReporte: nombrereporte,
        parametros: params,
        excel: booleanexcel,
        
      }, { headers, responseType: 'blob' });
    }
  }
  generarLiReporte(nombrereporte, query, booleanexcel, sql,cod): Observable<any> {
    console.log(nombrereporte);
    const ubirep = sessionStorage.getItem('ubicacionReportes');

    let params = {};
    if (sql !== '') {      
      params = {
        COMCODIGO: '01',
        SUBREPORT_DIR: ubirep,
        NOMUSUARIO: this.usuario.identificacion,
        parametroIDE: this.parametroIde,
        querryString: query,
        querryStringUno: sql
        
      }
    } else {
      params = {
        COMCODIGO: '01',
        SUBREPORT_DIR: ubirep,
        NOMUSUARIO: this.usuario.identificacion,
        parametroIDE: this.parametroIde,
        querryString: query,
      }
    }

    const headers = new HttpHeaders().set('Session-Id', sessionStorage.getItem('session'));
    headers.set('withCredentials', 'true');
    //if (this.repnewpvbe === true) {
      if (booleanexcel === true) {
        return this.http.post(this.wayrep + '/reporte/2d8e776adedd9c0000', {
          nombreReporte: nombrereporte,
          parametros: params,
          excel: booleanexcel,
          codigo:cod
        }, { headers, responseType: 'text' });
      } else {
        return this.http.post(this.wayrep + '/reporte/2d8e776adedd9c0000', {
          nombreReporte: nombrereporte,
          parametros: params,
          excel: booleanexcel,
          codigo:cod
        }, { headers, responseType: 'blob' });
      }
    //}
    // else{
    //   if (booleanexcel === true) {
    //     return this.http.post(this.way + '/reporte/2d8e776adedd9c0000', {
    //       nombreReporte: nombrereporte,
    //       parametros: params,
    //       excel: booleanexcel,
    //       codigo:cod
    //     }, { headers, responseType: 'text' });
    //   } else {
    //     return this.http.post(this.way + '/reporte/2d8e776adedd9c0000', {
    //       nombreReporte: nombrereporte,
    //       parametros: params,
    //       excel: booleanexcel,
    //       codigo:cod
    //     }, { headers, responseType: 'blob' });
    //   }
    // }
  }
  //GSRF
 
  ejecutarsentcsv(sentencia): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/3a6e67baadf1d800000', {
      elementos: {
        sentencia: sentencia,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  mandarSentenciabi(codigo: MAERL): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/reporte/listareportes/1655daadf1d7ec00000000', {
      elementos: {
        RL_CODIGO: codigo.RL_CODIGO,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarparametrobi(aux: MAERL_BI): Observable<any[]> {
    console.log(aux);
    return this.http.post<any[]>(this.way + '/reporte/listareportes/4afc76faade55c0000000000', {
      elementos: {
          RL_CODIGO: aux.RL_CODIGO,
          RLP_CONSULTA: aux.RLP_CONSULTA,
          RLP_NOMBRE:aux.RLP_NOMBRE,
      }
    }, this.confIniciales.httpOptions());
  }
  //GSRF
}
