//
// SACI WEB
// Rutina: Maestro de Clientes
// Propietario: Sandy Simbaña
// Modificado por: SS
// Fecha de creación: 2019
// Fecha de Modificación: 29-11-2019
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CommaeservicioService} from '../comservicios/commaeservicio.service';
import {Commaeservicio} from '../cominterfaces/commaeservicio';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { InvmaearticuloService } from "../../inv/invservicios/invmaearticulo.service";
@Component({
  selector: 'app-commaeservicio',
  templateUrl: './commaeservicio.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class CommaeserviciosComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  nuevoCampo: boolean;
  permisoServicio: SegMaePermiso;
  codigoAux: any;
  arregloCons: any[];
  arregloCEN: any[];
  arregloFSRI: any[];
  largo: string;
  servicioSeleccionado: Commaeservicio;
  public frameworkComponents;
  rowStyle;
  desCodigo: boolean;
  defaultColDefComServicios;
  rowSelection = 'multiple';
  //CDPJ
  tariivacod='';
  tariivaporcentaje:string;
  tariivadescripcion='';
  displayDialogBusquedaFasttariva=false;
  claveDialog = false;
  accion=''
  claveAuth='';
  displayDialogactiva=false;
  displayAcciones: boolean;
  types: SelectItem[];
  busquedacampos: string[];
  tabla: string;
  opcionbusqueda: string
  where = '';
  //CDPJ
  // Codigos alternos

  displayDialogCodAlt = false;
  
  columnDefsComServicios = [
    {
      headerName: 'Código', field: 'SERCOM_CODIGO', cellEditor: 'cellPrueba', width: 90, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 20
          }
        }
    },
    {
      headerName: 'Descripción', field: 'SERCOM_DESCRIPCION', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80
          }
        }
    },
    {
      headerName: 'Codigo Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Precio', field: 'SERCOM_PRECIO', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'IVA', field: 'SERCOM_TRIBUTAIVA',cellEditor: 'agSelectCellEditor', width: 80,onCellValueChanged: (params) => {
        this.cambioCentro(params);
      },
      cellEditorParams:
      {
        values: ['N', 'S']//CDPJ
      }
    },
    {
      headerName: 'Tarifa', field: 'SERCOM_PORCEIVA', cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'left' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Centro de Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Form.SRI', field: 'SER_FORMSRICOM', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 20,
            opcionbuscar: true
          }
        }
    },
    
    {
      headerName: 'Codigo Contable2', field: 'CON_CODIGO2', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'BaseNoObjIva', field: 'SERCOM_BASENOOBJIVA', cellEditor: 'cellPrueba', width: 130,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            regexp: /^[S]$|^[N]$/,
            tamanocelda: 1
          }
        }
    }];
  
  constructor(public comService: CommaeservicioService, private message: MessageService,
              private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService,
              private busqService: InvbusquedaService, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private init: NuevoComponentService,
              private confIniciales: ConfInicialesService,public artService: InvmaearticuloService) {
    this.agTable();
    this.comService.commaeservicio = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefComServicios = {
      editable: true,
      width: 100,
      objeto: 'commaeservicios'
    };
  }
  
  async ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.buscarCon();
    this.buscarCEN();
    this.buscarSRI();
    this.comService.getServicio().subscribe((res) => {
      if (res !== null) {
        this.comService.commaeservicio = res;
      }
    });
    this.permisosService.getListaPermisos('COM', '1', '832').subscribe((res) => {
      this.permisoServicio = res[0];
    });
    this.largo = '315';
    await this.confIniciales.obtenerSrifeTariIva()
    //await this.colocarporcentaje()
  }
  async colocarporcentaje(){
    this.comService.commaeservicio.map(e=>{
      if (e.TARIIVA_CODIGO !== undefined && e.TARIIVA_CODIGO !== null &&  e.TARIIVA_CODIGO !== ''){
        const data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === e.TARIIVA_CODIGO)
        if(data !== undefined && data !== null){
          e.SERCOM_PORCEIVA=data.TARIIVA_PORCENTAJE
        }
      }
      
    })
    this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
  }
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoServicio.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.aggrid.isEditable(true);
        this.servicioSeleccionado = {
          CEN_CODIGO: '',
          COM_CODIGO: '01',
          CON_CODIGO: '',
          CON_CODIGO2: '',
          SERCOM_BASENOOBJIVA: '',
          SERCOM_CODIGO: '',
          SERCOM_DESCRIPCION: '',
          SERCOM_PRECIO: '0.0',
          SERCOM_TRIBUTAIVA: '',
          SER_FORMSRICOM: '',
          SERCOM_PORCEIVA:0,
          TARIIVA_CODIGO:''
        };
        this.comService.commaeservicio.push(this.servicioSeleccionado);
        this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
      } else if (this.permisoServicio.PERINSERCION === 0) {
        this.message.add({
          key: 'servicios',
          severity: 'error',
          summary: 'Inserción de Servicios',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisoServicio.PERACTUALIZACION === 1) {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        this.comService.erServicioCod(this.servicioSeleccionado.SERCOM_CODIGO).subscribe(async existe => {
          if (existe !== null) {
            let coincidencias: number;
            let coincidencias2: number;
            let coincidenciasCEN: number;
            let coincidenciasFSRI: number;
            coincidencias = 0;
            coincidencias2 = 0;
            coincidenciasCEN = 0;
            coincidenciasFSRI = 0;
            console.log('emtro1', this.servicioSeleccionado);
            // this.arregloCons.map((con) => {
            //   if (this.servicioSeleccionado.CON_CODIGO === con.CON_CODIGO) {
            //     coincidencias++;
            //   }
            //   if (this.servicioSeleccionado.CON_CODIGO2 === con.CON_CODIGO) {
            //     coincidencias2++;
            //   }
            // });
            const rsdata: any = await this.comService.encontrarArtCntaySer(this.servicioSeleccionado.CON_CODIGO, 'C');
            const rsdata2: any = await this.comService.encontrarArtCntaySer(this.servicioSeleccionado.CON_CODIGO2, 'C');
            
            if (rsdata !== null) {
              for (const rs of rsdata) {
                coincidencias = rs.NUMERO;
              }
            }
            if (rsdata2 !== null) {
              for (const rs of rsdata) {
                coincidencias2 = rs.NUMERO;
              }
            }
            this.arregloCEN.map((con) => {
              if (this.servicioSeleccionado.CEN_CODIGO === con.CEN_CODIGO) {
                coincidenciasCEN++;
              }
            });
            this.arregloFSRI.map((con) => {
              if (this.servicioSeleccionado.SER_FORMSRICOM === con.REF_CODIGO) {
                coincidenciasFSRI++;
              }
            });
            //CDPJ
            if(this.servicioSeleccionado.TARIIVA_CODIGO === null || this.servicioSeleccionado.TARIIVA_CODIGO === undefined || this.servicioSeleccionado.TARIIVA_CODIGO === ''){
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El Código '+this.servicioSeleccionado.SERCOM_CODIGO+', no tiene un código asignado en el campo tarifa, vuelva a ingresar el porcentaje.'
              });
              this.btnGuardar = false;
              return;
            }
            if(this.servicioSeleccionado.SERCOM_PORCEIVA === null || this.servicioSeleccionado.SERCOM_PORCEIVA === undefined || this.servicioSeleccionado.SERCOM_PORCEIVA === ''){
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El campo Tarifa, no puede estar vacío.'
              });
              this.btnGuardar = false;
              return;
            }
            if(this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'S' && this.servicioSeleccionado.TARIIVA_CODIGO !== null && this.servicioSeleccionado.TARIIVA_CODIGO !== undefined && this.servicioSeleccionado.TARIIVA_CODIGO !== ''){
              let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0
              && Number(element.TARIIVA_PORCENTAJE) === Number(this.servicioSeleccionado.SERCOM_PORCEIVA))
              if(data === undefined || data === null){
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'La tarifa con el porcentaje '+this.servicioSeleccionado.SERCOM_PORCEIVA+' no es parte de los porcentajes que puede seleccionar para servicios que tributan IVA.'
                });
                this.btnGuardar = false;
                return;
              }
            }
            // if(count === 0){
            //   this.message.add({
            //     key: 'servicios',
            //     severity: 'error',
            //     summary: 'Error en IVA',
            //     detail: 'La tarifa con el código '+this.servicioSeleccionado.TARIIVA_CODIGO+' no es parte de los códigos que puede seleccionar para servicios que tributan IVA.'
            //   });
            //   this.btnGuardar = false;
            //   return;
            // }
            //CDPJ
            // if(this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'N'
            //  && (this.servicioSeleccionado.TARIIVA_CODIGO === null || this.servicioSeleccionado.TARIIVA_CODIGO === undefined || this.servicioSeleccionado.TARIIVA_CODIGO === '')){
            //   this.servicioSeleccionado.TARIIVA_CODIGO === '-1'
            // }
            if(this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'N'
            && this.servicioSeleccionado.SERCOM_PORCEIVA !== null && this.servicioSeleccionado.SERCOM_PORCEIVA !== undefined && this.servicioSeleccionado.SERCOM_PORCEIVA !== ''){
              let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0
              && Number(element.TARIIVA_PORCENTAJE) === Number(this.servicioSeleccionado.SERCOM_PORCEIVA))
              console.log(data)
              console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO))
              if(data === undefined || data === null){
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'La tarifa con el porcentaje '+this.servicioSeleccionado.SERCOM_PORCEIVA+' no es parte de los porcentajes que puede seleccionar para servicios que no tributan IVA.'
                });
                this.btnGuardar = false;
                return;
              }
            }
            // if(count === 0){
            //   this.message.add({
            //     key: 'servicios',
            //     severity: 'error',
            //     summary: 'Error en IVA',
            //     detail: 'La tarifa con el código '+this.servicioSeleccionado.TARIIVA_CODIGO+' no es parte de los códigos que puede seleccionar para servicios que no tributan IVA.'
            //   });
            //   this.btnGuardar = false;
            //   return;
            // }
            //CDPJ
            //CDPJ
            if (this.servicioSeleccionado.CEN_CODIGO === '' || this.servicioSeleccionado.CEN_CODIGO === null) {
              if (coincidencias !== 0) {
                if ((coincidenciasFSRI !== 0 && coincidencias2 !== 0) || ((this.servicioSeleccionado.SER_FORMSRICOM === null
                  || this.servicioSeleccionado.SER_FORMSRICOM === '') && this.servicioSeleccionado.CON_CODIGO2 === null
                  || this.servicioSeleccionado.CON_CODIGO2 === '') || (coincidenciasFSRI !== 0
                  && (this.servicioSeleccionado.CON_CODIGO2 === null || this.servicioSeleccionado.CON_CODIGO2 === '')) ||
                  ((this.servicioSeleccionado.SER_FORMSRICOM === null || this.servicioSeleccionado.SER_FORMSRICOM === '')
                    && coincidencias2 !== 0)) {
                  if ((this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'S' || this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'N') &&
                    (this.servicioSeleccionado.SERCOM_BASENOOBJIVA === 'S' || this.servicioSeleccionado.SERCOM_BASENOOBJIVA === 'N'
                      || this.servicioSeleccionado.SERCOM_BASENOOBJIVA === '')) {
                    this.comService.actualizarServicio(this.servicioSeleccionado).subscribe(() => {
                      this.message.add({
                        key: 'servicios',
                        severity: 'success',
                        summary: 'Actualización exitosa',
                        detail: 'Se actualizó el servicio correctamente'
                      });
                      this.auditoriagral.registrarAuditoria('COM_MAESERVICIO', String(this.servicioSeleccionado.SERCOM_CODIGO), 'A',
                        '', '01', '', '', '', '').subscribe(() => {
                      });
                      this.aggrid.isEditable(false);
                    }, error1 => {
                      let mensaje: string;
                      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                      this.message.add({
                        key: 'servicios',
                        severity: 'error',
                        summary: 'Actualización de Servicio',
                        detail: mensaje
                      });
                      this.btnNuevo = false;
                      this.btnGuardar = true;
                      this.btnBorrar = false;
                      this.btnRegresar = true;
                      this.comService.getServicio().subscribe((res) => {
                        this.comService.commaeservicio = res;
                        this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                      });
                    });
                  } else if (this.servicioSeleccionado.SERCOM_TRIBUTAIVA !== 'S' && this.servicioSeleccionado.SERCOM_TRIBUTAIVA !== 'N') {
                    this.message.add({
                      key: 'servicios',
                      severity: 'error',
                      summary: 'Error en IVA',
                      detail: 'El valor de ingresado no existe, por favor ingrese S o N'
                    });
                    this.servicioSeleccionado.SERCOM_TRIBUTAIVA = '';
                    this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                  } else if (this.servicioSeleccionado.SERCOM_BASENOOBJIVA !== 'S'
                    && this.servicioSeleccionado.SERCOM_BASENOOBJIVA !== 'N') {
                    this.message.add({
                      key: 'servicios',
                      severity: 'error',
                      summary: 'Error en Base objeto de IVA',
                      detail: 'El valor de ingresado no existe, por favor ingrese S o N'
                    });
                    this.servicioSeleccionado.SERCOM_BASENOOBJIVA = '';
                    this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                  }
                } else if ((this.servicioSeleccionado.CON_CODIGO2 === ''
                  || this.servicioSeleccionado.CON_CODIGO2 === null || coincidencias2 !== 0 || coincidencias2 === 0)
                  && coincidenciasFSRI === 0) {
                  this.message.add({
                    key: 'servicios',
                    severity: 'error',
                    summary: 'Error en Formas de SRI',
                    detail: 'La Forma SRI ingresada no existe, por favor ingrese una ' +
                      'existente o escoja una de la ventana de búsqueda'
                  });
                  this.servicioSeleccionado.SER_FORMSRICOM = '';
                  this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                } else if ((this.servicioSeleccionado.SER_FORMSRICOM === ''
                  || this.servicioSeleccionado.SER_FORMSRICOM === null || coincidenciasFSRI !== 0 || coincidenciasFSRI === 0)
                  && coincidencias2 === 0) {
                  this.message.add({
                    key: 'servicios',
                    severity: 'error',
                    summary: 'Error en Cuenta Contable2',
                    detail: 'La cuenta contable2 ingresada no existe, por favor ingrese una ' +
                      'existente o escoja una de la ventana de búsqueda'
                  });
                  this.servicioSeleccionado.CON_CODIGO2 = '';
                  this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                }
                
              } else if (coincidencias === 0 && this.servicioSeleccionado.CON_CODIGO.length >0) {
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.servicioSeleccionado.CON_CODIGO = '';
                this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
              }
            } else if (this.servicioSeleccionado.CEN_CODIGO !== '' || this.servicioSeleccionado.CEN_CODIGO !== null) {
              if (coincidencias !== 0) {
                if (coincidenciasCEN !== 0) {
                  if ((coincidenciasFSRI !== 0 && coincidencias2 !== 0) || ((this.servicioSeleccionado.SER_FORMSRICOM === null
                    || this.servicioSeleccionado.SER_FORMSRICOM === '') && this.servicioSeleccionado.CON_CODIGO2 === null
                    || this.servicioSeleccionado.CON_CODIGO2 === '') || (coincidenciasFSRI !== 0
                    && (this.servicioSeleccionado.CON_CODIGO2 === null || this.servicioSeleccionado.CON_CODIGO2 === '')) ||
                    ((this.servicioSeleccionado.SER_FORMSRICOM === null || this.servicioSeleccionado.SER_FORMSRICOM === '')
                      && coincidencias2 !== 0)) {
                    if ((this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'S' || this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'N') &&
                      (this.servicioSeleccionado.SERCOM_BASENOOBJIVA === 'S' || this.servicioSeleccionado.SERCOM_BASENOOBJIVA === 'N'
                        || this.servicioSeleccionado.SERCOM_BASENOOBJIVA === '')) {
                          
                      this.comService.actualizarServicio(this.servicioSeleccionado).subscribe(() => {
                        this.message.add({
                          key: 'servicios',
                          severity: 'success',
                          summary: 'Actualización exitosa',
                          detail: 'Se actualizó el servicio correctamente'
                        });
                        this.auditoriagral.registrarAuditoria('COM_MAESERVICIO', String(this.servicioSeleccionado.SERCOM_CODIGO), 'A',
                          '', '01', '', '', '', '').subscribe(() => {
                        });
                        this.aggrid.isEditable(false);
                      }, error1 => {
                        let mensaje: string;
                        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                        this.message.add({
                          key: 'servicios',
                          severity: 'error',
                          summary: 'Actualización de Servicio',
                          detail: mensaje
                        });
                        this.btnNuevo = false;
                        this.btnGuardar = true;
                        this.btnBorrar = false;
                        this.btnRegresar = true;
                        this.comService.getServicio().subscribe((res) => {
                          this.comService.commaeservicio = res;
                          this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                        });
                      });
                    } else if (this.servicioSeleccionado.SERCOM_TRIBUTAIVA !== 'S' &&
                      this.servicioSeleccionado.SERCOM_TRIBUTAIVA !== 'N') {
                      this.message.add({
                        key: 'servicios',
                        severity: 'error',
                        summary: 'Error en IVA',
                        detail: 'El valor de ingresado no existe, por favor ingrese S o N'
                      });
                      this.servicioSeleccionado.SERCOM_TRIBUTAIVA = '';
                      this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                    } else if (this.servicioSeleccionado.SERCOM_BASENOOBJIVA !== 'S'
                      && this.servicioSeleccionado.SERCOM_BASENOOBJIVA !== 'N'
                      && this.servicioSeleccionado.SERCOM_BASENOOBJIVA === '') {
                      this.message.add({
                        key: 'servicios',
                        severity: 'error',
                        summary: 'Error en Base objeto de IVA',
                        detail: 'El valor de ingresado no existe, por favor ingrese S o N'
                      });
                      this.servicioSeleccionado.SERCOM_BASENOOBJIVA = '';
                      this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                    } else {
                      this.message.add({
                        key: 'servicios',
                        severity: 'error',
                        summary: 'Error en Base objeto de IVA',
                        detail: 'El valor de ingresado no existe, por favor ingrese S o N'
                      });
                    }
                  } else if (coincidenciasFSRI === 0 && (coincidencias2 !== 0 || this.servicioSeleccionado.CON_CODIGO2 === null
                    || this.servicioSeleccionado.CON_CODIGO2 === '' || coincidencias2 === 0)) {
                    this.message.add({
                      key: 'servicios',
                      severity: 'error',
                      summary: 'Error en Formas de SRI',
                      detail: 'La Forma de SRI ingresada no existe, por favor ingrese una ' +
                        'existente o escoja una de la ventana de búsqueda'
                    });
                    this.servicioSeleccionado.SER_FORMSRICOM = '';
                    this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                  } else if (coincidencias2 === 0 && (this.servicioSeleccionado.SER_FORMSRICOM === ''
                    || this.servicioSeleccionado.SER_FORMSRICOM === '' || coincidenciasFSRI !== 0 || coincidenciasFSRI === 0)) {
                    this.message.add({
                      key: 'servicios',
                      severity: 'error',
                      summary: 'Error en Cuenta Contable2',
                      detail: 'La cuenta contable2 ingresada no existe, por favor ingrese una ' +
                        'existente o escoja una de la ventana de búsqueda'
                    });
                    this.servicioSeleccionado.CON_CODIGO2 = '';
                    this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                  }
                } else if (coincidenciasCEN === 0) {
                  this.message.add({
                    key: 'servicios',
                    severity: 'error',
                    summary: 'Error en Centros de Costos',
                    detail: 'El Centro de Costos ingresado no existe, por favor ingrese una ' +
                      'existente o escoja una de la ventana de búsqueda'
                  });
                  this.servicioSeleccionado.CEN_CODIGO = '';
                  this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                }
                
              } else if (coincidencias === 0 && this.servicioSeleccionado.CON_CODIGO.length > 0) {
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.servicioSeleccionado.CON_CODIGO = '';
                this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
              }
            }
          } else if (existe === null) {
            
            let coincidencias1: number;
            let coincidenciasC2: number;
            let coincidenciasCEN1: number;
            let coincidenciasFSRI1: number;
            coincidencias1 = 0;
            coincidenciasC2 = 0;
            coincidenciasCEN1 = 0;
            coincidenciasFSRI1 = 0;
            // this.arregloCons.map((con) => {
            //   if (this.servicioSeleccionado.CON_CODIGO === con.CON_CODIGO) {
            //     coincidencias1++;
            //   }
            //   if (this.servicioSeleccionado.CON_CODIGO2 === con.CON_CODIGO) {
            //     coincidenciasC2++;
            //   }
            // });
            const rsdata: any = await this.comService.encontrarArtCntaySer(this.servicioSeleccionado.CON_CODIGO, 'C');
            const rsdata2: any = await this.comService.encontrarArtCntaySer(this.servicioSeleccionado.CON_CODIGO2, 'C');
            if (rsdata !== null) {
              for (const rs of rsdata) {
                coincidencias1 = rs.NUMERO;
              }
            }
            if (rsdata2 !== null) {
              for (const rs of rsdata) {
                coincidenciasC2 = rs.NUMERO;
              }
            }
            this.arregloCEN.map((con) => {
              if (this.servicioSeleccionado.CEN_CODIGO === con.CEN_CODIGO) {
                coincidenciasCEN1++;
              }
            });
            this.arregloFSRI.map((con) => {
              if (this.servicioSeleccionado.SER_FORMSRICOM === con.REF_CODIGO) {
                coincidenciasFSRI1++;
              }
            });
            if(this.servicioSeleccionado.SERCOM_PORCEIVA === null || this.servicioSeleccionado.SERCOM_PORCEIVA === undefined || this.servicioSeleccionado.SERCOM_PORCEIVA === ''){
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El campo Tarifa, no puede estar vacío.'
              });
              this.btnGuardar = false;
              return;
            }
            // let count=0
            if(this.servicioSeleccionado.TARIIVA_CODIGO === null || this.servicioSeleccionado.TARIIVA_CODIGO === undefined || this.servicioSeleccionado.TARIIVA_CODIGO === ''){
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El Código '+this.servicioSeleccionado.SERCOM_CODIGO+', no tiene un código asignado en el campo tarifa, vuelva a ingresar el porcentaje.'
              });
              this.btnGuardar = false;
              return;
            }
            if(this.servicioSeleccionado.SERCOM_PORCEIVA === null || this.servicioSeleccionado.SERCOM_PORCEIVA === undefined || this.servicioSeleccionado.SERCOM_PORCEIVA === ''){
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El campo Tarifa, no puede estar vacío.'
              });
              this.btnGuardar = false;
              return;
            }
            if(this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'S' && this.servicioSeleccionado.TARIIVA_CODIGO !== null && this.servicioSeleccionado.TARIIVA_CODIGO !== undefined && this.servicioSeleccionado.TARIIVA_CODIGO !== ''){
              let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0
              && Number(element.TARIIVA_PORCENTAJE) === Number(this.servicioSeleccionado.SERCOM_PORCEIVA))
              if(data === undefined || data === null){
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'La tarifa con el porcentaje '+this.servicioSeleccionado.SERCOM_PORCEIVA+' no es parte de los porcentajes que puede seleccionar para servicios que tributan IVA.'
                });
                this.btnGuardar = false;
                return;
              }
            }
            // if(count === 0){
            //   this.message.add({
            //     key: 'servicios',
            //     severity: 'error',
            //     summary: 'Error en IVA',
            //     detail: 'La tarifa con el código '+this.servicioSeleccionado.TARIIVA_CODIGO+' no es parte de los códigos que puede seleccionar para servicios que tributan IVA.'
            //   });
            //   this.btnGuardar = false;
            //   return;
            // }
            //CDPJ
            // if(this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'N'
            //  && (this.servicioSeleccionado.TARIIVA_CODIGO === null || this.servicioSeleccionado.TARIIVA_CODIGO === undefined || this.servicioSeleccionado.TARIIVA_CODIGO === '')){
            //   this.servicioSeleccionado.TARIIVA_CODIGO === '-1'
            // }
            if(this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'N'
            && this.servicioSeleccionado.SERCOM_PORCEIVA !== null && this.servicioSeleccionado.SERCOM_PORCEIVA !== undefined && this.servicioSeleccionado.SERCOM_PORCEIVA !== ''){
              let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0
              && Number(element.TARIIVA_PORCENTAJE) === Number(this.servicioSeleccionado.SERCOM_PORCEIVA))
              console.log(data)
              console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO))
              if(data === undefined || data === null){
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'La tarifa con el porcentaje '+this.servicioSeleccionado.SERCOM_PORCEIVA+' no es parte de los porcentajes que puede seleccionar para servicios que no tributan IVA.'
                });
                this.btnGuardar = false;
                return;
              }
            }
            // if(count === 0){
            //   this.message.add({
            //     key: 'servicios',
            //     severity: 'error',
            //     summary: 'Error en IVA',
            //     detail: 'La tarifa con el código '+this.servicioSeleccionado.TARIIVA_CODIGO+' no es parte de los códigos que puede seleccionar para servicios que no tributan IVA.'
            //   });
            //   this.btnGuardar = false;
            //   return;
            // }
            //CDPJ
            if (this.servicioSeleccionado.CEN_CODIGO === '') {
              if (coincidencias1 !== 0) {
                if ((coincidenciasFSRI1 !== 0 && coincidenciasC2 !== 0) || (this.servicioSeleccionado.SER_FORMSRICOM === '' &&
                  this.servicioSeleccionado.CON_CODIGO2 === '') || (coincidenciasFSRI1 !== 0
                  && this.servicioSeleccionado.CON_CODIGO2 === '') ||
                  (this.servicioSeleccionado.SER_FORMSRICOM === '' && coincidenciasC2 !== 0)) {
                  if ((this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'S' || this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'N') &&
                    (this.servicioSeleccionado.SERCOM_BASENOOBJIVA === 'S' || this.servicioSeleccionado.SERCOM_BASENOOBJIVA === 'N'
                      || this.servicioSeleccionado.SERCOM_BASENOOBJIVA === '')) {
                        //CDPJ
                        if(this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'N'){
                          this.servicioSeleccionado.TARIIVA_CODIGO === '-1'
                        }
                        //CDPJ
                    this.comService.insertarServicio(this.servicioSeleccionado).subscribe(() => {
                      this.message.add({
                        key: 'servicios',
                        severity: 'success',
                        summary: 'Inserción exitosa',
                        detail: 'Se insertó el servicio correctamente'
                      });
                      this.auditoriagral.registrarAuditoria('COM_MAESERVICIO', String(this.servicioSeleccionado.SERCOM_CODIGO), 'I',
                        '', '01', '', '', '', '').subscribe(() => {
                      });
                      this.aggrid.isEditable(false);
                    }, error1 => {
                      let mensaje: string;
                      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                      this.message.add({
                        key: 'servicios',
                        severity: 'error',
                        summary: 'Inserción de Servicio',
                        detail: mensaje
                      });
                      this.btnNuevo = false;
                      this.btnGuardar = true;
                      this.btnBorrar = false;
                      this.btnRegresar = true;
                      this.comService.getServicio().subscribe((res) => {
                        this.comService.commaeservicio = res;
                        this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                      });
                    });
                  } else if (this.servicioSeleccionado.SERCOM_TRIBUTAIVA !== 'S' && this.servicioSeleccionado.SERCOM_TRIBUTAIVA !== 'N') {
                    this.message.add({
                      key: 'servicios',
                      severity: 'error',
                      summary: 'Error en IVA',
                      detail: 'El valor de ingresado no existe, por favor ingrese S o N'
                    });
                    this.servicioSeleccionado.SERCOM_TRIBUTAIVA = '';
                    this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                  } else if (this.servicioSeleccionado.SERCOM_BASENOOBJIVA !== 'S'
                    && this.servicioSeleccionado.SERCOM_BASENOOBJIVA !== 'N'  && this.servicioSeleccionado.SERCOM_BASENOOBJIVA !== '') {
                    this.message.add({
                      key: 'servicios',
                      severity: 'error',
                      summary: 'Error en Base objeto de IVA',
                      detail: 'El valor de ingresado no existe, por favor ingrese S o N'
                    });
                    this.servicioSeleccionado.SERCOM_BASENOOBJIVA = '';
                    this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                  }
                } else if ((this.servicioSeleccionado.CON_CODIGO2 === ''
                  || this.servicioSeleccionado.CON_CODIGO2 === null || coincidenciasC2 !== 0 || coincidenciasC2 === 0)
                  && coincidenciasFSRI1 === 0) {
                  console.log(this.servicioSeleccionado);
                  this.message.add({
                    key: 'servicios',
                    severity: 'error',
                    summary: 'Error en Formas de SRI',
                    detail: 'La Forma SRI ingresada no existe, por favor ingrese una ' +
                      'existente o escoja una de la ventana de búsqueda'
                  });
                  this.servicioSeleccionado.SER_FORMSRICOM = '';
                  this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                } else if ((this.servicioSeleccionado.SER_FORMSRICOM === ''
                  || this.servicioSeleccionado.SER_FORMSRICOM === null || coincidenciasFSRI1 !== 0 || coincidenciasFSRI1 === 0)
                  && coincidenciasC2 === 0) {
                  this.message.add({
                    key: 'servicios',
                    severity: 'error',
                    summary: 'Error en Cuenta Contable2',
                    detail: 'La cuenta contable2 ingresada no existe, por favor ingrese una ' +
                      'existente o escoja una de la ventana de búsqueda'
                  });
                  this.servicioSeleccionado.CON_CODIGO2 = '';
                  this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                }
              } else if (coincidencias1 === 0) {
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.servicioSeleccionado.CON_CODIGO = '';
                this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
              }
              
              
            } else if (this.servicioSeleccionado.CEN_CODIGO !== '' || this.servicioSeleccionado.CEN_CODIGO !== null) {
              if (coincidencias1 !== 0) {
                if (coincidenciasCEN1 !== 0) {
                  if ((coincidenciasFSRI1 !== 0 && coincidenciasC2 !== 0) || ((this.servicioSeleccionado.SER_FORMSRICOM === null
                    || this.servicioSeleccionado.SER_FORMSRICOM === '') && this.servicioSeleccionado.CON_CODIGO2 === null
                    || this.servicioSeleccionado.CON_CODIGO2 === '') || (coincidenciasFSRI1 !== 0
                    && (this.servicioSeleccionado.CON_CODIGO2 === null || this.servicioSeleccionado.CON_CODIGO2 === '')) ||
                    ((this.servicioSeleccionado.SER_FORMSRICOM === null || this.servicioSeleccionado.SER_FORMSRICOM === '')
                      && coincidenciasC2 !== 0)) {
                    if ((this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'S' || this.servicioSeleccionado.SERCOM_TRIBUTAIVA === 'N') &&
                      (this.servicioSeleccionado.SERCOM_BASENOOBJIVA === 'S' || this.servicioSeleccionado.SERCOM_BASENOOBJIVA === 'N'
                        || this.servicioSeleccionado.SERCOM_BASENOOBJIVA === '')) {
                      this.comService.insertarServicio(this.servicioSeleccionado).subscribe(() => {
                        this.message.add({
                          key: 'servicios',
                          severity: 'success',
                          summary: 'Inserción exitosa',
                          detail: 'Se inserto el servicio correctamente'
                        });
                        this.auditoriagral.registrarAuditoria('COM_MAESERVICIO', String(this.servicioSeleccionado.SERCOM_CODIGO), 'A',
                          '', '01', '', '', '', '').subscribe(() => {
                        });
                        this.aggrid.isEditable(false);
                      }, error1 => {
                        let mensaje: string;
                        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                        this.message.add({
                          key: 'servicios',
                          severity: 'error',
                          summary: 'Inserción de Servicio',
                          detail: mensaje
                        });
                        this.btnNuevo = false;
                        this.btnGuardar = true;
                        this.btnBorrar = false;
                        this.btnRegresar = true;
                        this.comService.getServicio().subscribe((res) => {
                          this.comService.commaeservicio = res;
                          this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                        });
                      });
                    } else if (this.servicioSeleccionado.SERCOM_TRIBUTAIVA !== 'S' && this.servicioSeleccionado.SERCOM_TRIBUTAIVA !== 'N') {
                      this.message.add({
                        key: 'servicios',
                        severity: 'error',
                        summary: 'Error en IVA',
                        detail: 'El valor de ingresado no existe, por favor ingrese S o N'
                      });
                      this.servicioSeleccionado.SERCOM_TRIBUTAIVA = '';
                      this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                    } else if (this.servicioSeleccionado.SERCOM_BASENOOBJIVA !== 'S'
                      && this.servicioSeleccionado.SERCOM_BASENOOBJIVA !== 'N'  && this.servicioSeleccionado.SERCOM_BASENOOBJIVA !== 'N') {
                      this.message.add({
                        key: 'servicios',
                        severity: 'error',
                        summary: 'Error en Base objeto de IVA',
                        detail: 'El valor de ingresado no existe, por favor ingrese S o N'
                      });
                      this.servicioSeleccionado.SERCOM_BASENOOBJIVA = '';
                      this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                    }
                  } else if (coincidenciasFSRI1 === 0 && (coincidenciasC2 !== 0 || this.servicioSeleccionado.CON_CODIGO2 === null
                    || this.servicioSeleccionado.CON_CODIGO2 === '' || coincidenciasC2 === 0)) {
                    this.message.add({
                      key: 'servicios',
                      severity: 'error',
                      summary: 'Error en Formas de SRI',
                      detail: 'La Forma de SRI ingresada no existe, por favor ingrese una ' +
                        'existente o escoja una de la ventana de búsqueda'
                    });
                    this.servicioSeleccionado.SER_FORMSRICOM = '';
                    this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                  } else if (coincidenciasC2 === 0 && (this.servicioSeleccionado.SER_FORMSRICOM === ''
                    || this.servicioSeleccionado.SER_FORMSRICOM === '' || coincidenciasFSRI1 !== 0 || coincidenciasFSRI1 === 0)) {
                    this.message.add({
                      key: 'servicios',
                      severity: 'error',
                      summary: 'Error en Cuenta Contable2',
                      detail: 'La cuenta contable2 ingresada no existe, por favor ingrese una ' +
                        'existente o escoja una de la ventana de búsqueda'
                    });
                    this.servicioSeleccionado.CON_CODIGO2 = '';
                    this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                  }
                } else if (coincidenciasCEN1 === 0) {
                  this.message.add({
                    key: 'servicios',
                    severity: 'error',
                    summary: 'Error en Centros de Costos',
                    detail: 'El Centro de Costos ingresado no existe, por favor ingrese una ' +
                      'existente o escoja una de la ventana de búsqueda'
                  });
                  this.servicioSeleccionado.CEN_CODIGO = '';
                  this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
                }
                
              } else if (coincidencias1 === 0 ) {
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.servicioSeleccionado.CON_CODIGO = '';
                this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
              }
            }
          }
        });
      } else if (this.permisoServicio.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'servicios',
          severity: 'error',
          summary: 'Actualización del Servicio',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    ////////////////////////////////////
    if (valor === 'Borrar') {
      if (this.permisoServicio.PERELIMACION === 1) {
        this.btnNuevo = false;
        this.aggrid.isEditable(false);
        if (this.servicioSeleccionado === undefined) {
          this.message.add({
            key: 'servicios',
            severity: 'error',
            summary: 'Eliminacion de Servicios',
            detail: 'Seleccion Incorrecta, no se ha seleccionado un servicio'
          });
        } else {
          this.confirmarServicio();
        }
      } else if (this.permisoServicio.PERELIMACION === 0) {
        this.message.add({
          key: 'servicios',
          severity: 'error',
          summary: 'Eliminación de Servicios',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.btnNuevo = false;
      this.aggrid.isEditable(false);
      this.comService.getServicio().subscribe((res) => {
        this.comService.commaeservicio = res;
        this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
      });
      // this.comService.commaeservicio.map(e=>{
      //   if (e.TARIIVA_CODIGO !== undefined && e.TARIIVA_CODIGO !== null &&  e.TARIIVA_CODIGO !== ''){
      //     let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === e.TARIIVA_CODIGO)
      //     console.log(data)
      //     if(data !== undefined && data !== null){
      //       e.SERCOM_PORCEIVA=Number(data.TARIIVA_PORCENTAJE).toFixed(2)
      //     }
      //   }
       
      // })
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  seleccionServicio(valor) {
    if (valor.object !== null) {
      this.servicioSeleccionado = valor.object;
      this.init.auxsalto = false;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
      }
    }
  }
  
  confirmarServicio() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el servicio',
      header: 'Eliminar Servicio',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarServicios',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarServicio();
      },
      reject: () => {
      }
    });
  }
  
  eliminarServicio() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.comService.eliminarServicio(this.servicioSeleccionado).subscribe(() => {
      this.message.add({
        key: 'servicios',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el servicio correctamente'
      });
      this.auditoriagral.registrarAuditoria('COM_MAESERVICIO', String(this.servicioSeleccionado.SERCOM_CODIGO), 'E',
        '', '01', '', '', '', '').subscribe(() => {
      });
      this.comService.commaeservicio = this.comService.commaeservicio.filter((val, j) => j !==
        this.comService.commaeservicio.indexOf(this.servicioSeleccionado));
      this.aggrid.refreshaggrid(this.comService.commaeservicio, this.defaultColDefComServicios.objeto);
    });
  }
  
  buscarCon() {
    this.busqService.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }
  
  buscarCEN() {
    this.busqService.busquedaConMaeCen4().subscribe((res: any[]) => {
      this.arregloCEN = res === null ? [] : res;
    });
  }
  
  buscarSRI() {
    this.busqService.busquedaSaciMaeReferencia2().subscribe((res: any[]) => {
      this.arregloFSRI = res;
    });
  }
  
  cambio() {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }
  
  
  
  agregarCodigosAlternos() {
    if (Object.keys(this.servicioSeleccionado).length > 0) {
      this.displayDialogCodAlt = true;
      this.closeSidebar();
    } else {
      this.message.add({
        key: 'servicios',
        severity: 'warn',
        summary: 'Información',
        detail: 'Seleccione un servicio...!!!'
      });
    }
  }
  //CDPJ
  cancelariva(){
    this.tariivaporcentaje='';
    this.tariivadescripcion='';
    this.tariivacod=''
  }
  actualizariva(){
    this.closeSidebar();
    this.claveDialog = true;
    this.accion='claveiva'
  
  }
  comprobarClave() {
    if(this.accion === 'claveiva'){
      this.comService.obtenerClaveIva().subscribe((clave) => {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          //this.boolIva=false
          this.claveAuth = '';
          this.displayDialogactiva=true;
        } else if (clave !== this.claveAuth) {
          this.claveAuth = '';
          this.message.add({
            key: 'commaeserclave',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      });
    }
  }
  async aplicarActualizar(){
    if(this.tariivaporcentaje === null || this.tariivaporcentaje ===  undefined || this.tariivaporcentaje === ''){
      this.message.add({
        key: 'artiva',
        severity: 'warn',
        summary: 'Información',
        detail: 'El campo IVA, no puede estar vacío'
      });
      return;
    }
    if(this.tariivaporcentaje !== null && this.tariivaporcentaje !==  undefined && this.tariivaporcentaje !== ''){
      let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.tariivacod && Number(element.TARIIVA_PORCENTAJE) === Number(this.tariivaporcentaje))
      if(data === undefined || data === null){
        this.message.add({
          key: 'artiva',
          severity: 'error',
          summary: 'Error en IVA',
          detail: 'El %IVA con el porcentaje '+this.tariivaporcentaje+' no es parte de los porcentajes que puede seleccionar para actualizar.'
        });
        return;
      }
    }
    await this.finalmenteactualiza()
  }
  async finalmenteactualiza(){
    try {
      await this.comService.actualizarporceiva(this.tariivacod,Number(this.tariivaporcentaje));
  this.auditoriagral.registrarAuditoria('COM_MAESERVICIO','porcentaje'+'/'+
  this.tariivaporcentaje+'/'+'codporcentaje'+'/'+this.tariivacod, 'A', '', '01', '', '', '', '').subscribe();
  this.message.add({
    key: 'artiva',
    severity: 'success',
    summary: 'Información',
    detail: 'Actualización exitosa'
  });
    } catch (error) {
      
    }
  
  }
  busquedaivacod(parametro) {
    this.opcionbusqueda = 'busquedatariivacod';
    this.types = [
      { label: 'Código', value: 'TARIIVA_CODIGO' },
      { label: 'Descripcion', value: 'TARIIVA_DESCRIPCION' },
      { label: 'Porcentaje', value: 'TARIIVA_PORCENTAJE' }
    ];
  
    this.busquedacampos = [parametro,'', '', ''];
    this.tabla = 'SRIFE_TARIIVA';
    this.where = 'TARIIVA_ACTIVO=\'S\'';
    //if(this.chktributaiva === true){
      this.where=this.where+' and TARIIVA_PORCENTAJE<>0 '
    //}
    // else{
    //   this.where=this.where+' and TARIIVA_PORCENTAJE=0 '
    // }
    this.artService.encontrartariivacod(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.tariivacod = eR[0].TARIIVA_CODIGO;
          this.tariivadescripcion = eR[0].TARIIVA_DESCRIPCION;
        } else {
          this.busqService.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFasttariva = true;
          });
        }
      } else {
        this.busqService.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFasttariva = true;
        });
      }
    });
  }
  manejarSeleccion(opcion) {
    if(this.opcionbusqueda === 'busquedatariivacod'){
      this.tariivacod=opcion.TARIIVA_CODIGO;
      this.tariivaporcentaje=opcion.TARIIVA_PORCENTAJE
      this.tariivadescripcion=opcion.TARIIVA_DESCRIPCION;
      this.displayDialogBusquedaFasttariva=false;
    }
  }
  //CDPJ
  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }
  
  recibirSenalesCodAlt(valor) {
    if (valor === true) {
      this.displayDialogCodAlt = false;
    }
  }
  cambioCentro(event) {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
    this.btnRegresar = false;

  }
}

