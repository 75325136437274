import {Component, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {AggridBanComponent} from '../aggrid-ban/aggrid-ban.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {BanconcilbancariaService} from '../banservicios/banconcilbancaria.service';
import {InvbusquedaService} from 'src/app/inv/invservicios/invbusqueda.service';
import {AuditoriagralService} from 'src/app/advantage/advantageservices/auditoriagral.service';
import {AggridsaciComponent} from 'src/app/advantage/aggridsaci/aggridsaci.component';
import {ErroresBaseDatosService} from 'src/app/servicios/errores-base-datos.service';
import {NuevoComponentService} from 'src/app/servicios/nuevo-component.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {Usuario} from '../../usuario';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {BanPartConsil} from '../baninterfaces/banpartconsil';
import {BanEncconsil} from '../baninterfaces/banencconsil';
import {BanDetconsil} from '../baninterfaces/bandetconcil';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Component({
  selector: 'app-banconcilbancaria',
  templateUrl: './banconcilbancaria.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BanconcilbancariaComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  inputDes: boolean;
  btnBuscarDes: boolean;
  
  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonFactura: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonAceptar: boolean;
  disBotonesDialog: boolean;

  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  
  dateTimeMes: any;
  dateTimeAnio: any;
  
  nuevaEncCo: boolean;
  index: number;
  spin = false; ///gsrf
  hmpConfiguracion: any[];
  
  largo: string;
  largoPart: string;
  panel: string;
  
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  consulta: string;
  tabla: string;
  where: string;
  opcionbusqueda: string;
  
  cmbMes: any[];
  cmbAnio: any[];
  strCmbMes: any;
  strCmbAnio: any;
  
  btnaceptar: boolean;
  concilBanDetConcilSelecTodo: boolean;
  chknoconcilia: boolean;
  
  displayDialogBusquedaFast: boolean;
  displayDialogBusqueda: boolean;
  
  public frameworkComponents;
  rowStyle;
  defaultColConcil;
  defaultColPartConsil;
  rowSelection = 'multiple';
  
  boolCmbMes = false;
  boolCmbAnio = false;
  txtTotalConcil = null;
  txtResta = null;
  selectTab = 0;
  lblBanDatos = '';
  
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  
  isNewRecord: boolean;
  
  permisosConsilBan: SegMaePermiso;
  
  detPartConsilSel: BanPartConsil;
  cmbTrnTipo: any[];
  expError = '';
  cmbTipoDoc: any [];

  auxreporte: number = 0;
  strNombreForma: string;
  displayDialogReportes: boolean;
  porcentaje = 0;
  
  columnDefsConcilBancaria = [
    
    {
      headerName: 'N', field: 'NUMERO', width: 50,editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      }
    },
    {
      headerName: 'Fecha', field: 'TRNBAN_FECHA', width: 100, cellStyle: {textAlign: 'left'},editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      }
    },
    {
      headerName: 'Tipo', field: 'TRNBAN_TIPO',  width: 80, cellStyle: {textAlign: 'center'},editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      }
    },
    {
      headerName: 'Número', field: 'TRNBAN_NUMERO',  width: 150, cellStyle: {textAlign: 'left'},editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      }
    },
    {
      headerName: 'Asiento', field: 'ASI_COMPROBANTE', width: 150, cellStyle: {textAlign: 'left'},editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      }
    },
    {
      headerName: 'Importe', field: 'TRNBAN_IMPORTE',  width: 130, cellStyle: {textAlign: 'right'},editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      }
    },
    {
      headerName: 'Concepto', field: 'TRNBAN_CONCEPTO',  width: 300, cellStyle: {textAlign: 'left'},editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      }
    },
    {
      headerName: 'Beneficiario', field: 'TRNBAN_BENEFICIARIO',  width: 280,
    },
    {
      headerName: 'S/N', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent,
      editable: false, width: 50,
      onCellClicked: (params) => {
        this.seleccionChkConciliacion(params);
      }
    },
  ];
  
  columnDefsPartConsil = [
    {
      headerName: 'Tipo', field: 'NOMBRE_TIPO',  cellEditor: 'agSelectCellEditor', editable: true, width: 80, cellStyle: {textAlign: 'center'},
      cellEditorParams:
        {
          values: [this.cmbTipoDoc]
        }
    },
    {
      headerName: 'Fecha', field: 'TRNBAN_FECHA', cellEditor: 'cellPrueba', editable: true, width: 100, cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: true,
            tamanocelda: 80,
          }
        }
    },
    {
      headerName: 'Número', field: 'TRNBAN_NUMERO', cellEditor: 'cellPrueba', editable: true, width: 150, cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 80,
          }
        }
    },
    {
      headerName: 'Importe', field: 'TRNBAN_IMPORTE', cellEditor: 'cellPrueba', editable: true, width: 130, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            int: true
          }
        }
    },
    {
      headerName: 'Concepto', field: 'TRNBAN_CONCEPTO', cellEditor: 'cellPrueba', editable: true, width: 300, cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 80,
          }
        }
    },
    {
      headerName: 'Beneficiario', field: 'TRNBAN_BENEFICIARIO', cellEditor: 'cellPrueba', editable: true, width: 280,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 80,
          }
        }
    },
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent,
      editable: false, width: 55,
      onCellClicked: (params) => {
        this.seleccionChkPartConcil(params);
      }
    },
  ];
  
  constructor(private datePipe: DatePipe,
              private messageService: MessageService,
              private errorService: ErroresBaseDatosService,
              public conciliacionBancaService: BanconcilbancariaService,
              private busqService: InvbusquedaService,
              private auditoriagral: AuditoriagralService,
              private init: NuevoComponentService,
              public usuario: Usuario,
              private permisosService: PermisosService,
              private confirmationService: ConfirmationService,
              private confIniciales: ConfInicialesService) {
    
    this.agTable();
    this.dateTimeMes = new Date();
    this.dateTimeAnio = new Date();
    this.conciliacionBancaService.banenconsil = {};
    this.conciliacionBancaService.detconcil = [];
    this.conciliacionBancaService.patidasconsil = [];
    this.detPartConsilSel = {};
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColConcil = {
      editable: false,
      width: 500,
      objeto: 'banconsilban'
    };
    
    this.defaultColPartConsil = {
      editable: true,
      width: 500,
      objeto: 'banpartconsil'
    };
  }
  
  ngOnInit() {
    this.permisosService.getListaPermisos('BAN', '3', '3550').subscribe((permiso) => {
      this.permisosConsilBan = permiso[0];
    });
    
    this.conciliacionBancaService.cargarDatosCMBTipo().subscribe((tipo) => {
      this.cmbTrnTipo = [];
      this.cmbTipoDoc = [];
      for (const a of tipo) {
        this.cmbTrnTipo.push({name: a.REF_NOMBRE, codigo: a.REF_CODIGO});
        this.cmbTipoDoc.push(a.REF_NOMBRE);
      }
      this.columnDefsPartConsil[0].cellEditorParams = {
        values : this.cmbTipoDoc
      };
    });
  
    this.detPartConsilSel = {};
    
    this.inputDes = true;
    this.largo = '325';
    this.largoPart = '580';
    this.btnBuscarDes = true;
    this.panel = '';
    this.llenarCombos();
    
    
    // botones
    this.botonNuevo = false;
    this.botonRegresar = true;
    this.botonBorrar = false;
    this.botonGuardar = true;
    this.botonAceptar = true;
    this.btnaceptar = false;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
  
    this.btnNuevoGrid = false;
    this.btnGuardarGrid = true;
    this.btnCancelarGrid = true;
    this.btnBorrarGrid = false;
    
    this.concilBanDetConcilSelecTodo = false;
    this.chknoconcilia = false;
    
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
    });
    
    this.displayDialogBusquedaFast = false;
    this.displayDialogBusqueda = false;
    this.disBotonesDialog = false;
  }
    //CDPJ
    ngOnDestroy(): void {
      this.confIniciales.instanciasConcilBanca--;
    }
    //CDPJ
  async manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.nuevo();
    }
    if (valor === 'Guardar') {
      if (this.selectTab === 0) {
        if (await this.guardarConciliacion()) {
          this.porcentaje = 0;
          this.updateDataNew(2, false);
          this.mensajeAlerta('success', 'Información', 'Conciliación guardada exitosamente !!!');
          this.botonBorrar = false;
          this.botonNuevo = false;
          this.botonGuardar = true;
          this.inputDes = true;
          this.btnaceptar = false;
        }
      }
      if (this.selectTab === 1) {
        this.guardarPartidaConciliatoria(this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO);
        this.actualizarSelEnBloque();
      }
    }
    if (valor === 'Borrar') {
      this.confirmarEliminarConcil();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Primero') {
      this.navegar('P');
    }
    if (valor === 'Anterior') {
      this.navegar('A');
    }
    if (valor === 'Siguiente') {
      this.navegar('S');
    }
    if (valor === 'Ultimo') {
      this.navegar('U');
    }
    if (valor === 'Buscar') {
      this.buscaConciliacion();
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    
  }
  
  setFocus(evento) {
  
    if (evento.target.id === 'BAN_CODIGO') {
     // this.busquedabanco(evento.target.value);
      document.getElementById('inputMes').focus();
    }
    if (evento.target.id === 'inputMes') {
      document.getElementById('inputAnio').focus();
    }
    if (evento.target.id === 'inputAnio') {
      document.getElementById('ENCCONCIL_SALDOEC').focus();
    }
  }
  
  nuevo() {

    this.conciliacionBancaService.banenconsil = {
      ENCCONCIL_NUMERO: '',
      COM_CODIGO: '01',
      BAN_CODIGO: '',
      ENCCONCIL_MES: this.datePipe.transform(new Date(), 'MM'),
      ENCCONCIL_ANIO: this.datePipe.transform(new Date(), 'yyyy'),
      ENCCONCIL_FECHACONCILIA: '',
      ENCCONCIL_USUARIOCONCILIA: '',
      ENCCONCIL_SALDOEC: '',
      ASI_COMPROBANTE: '',
      TOTAL_SELECT: Number(0).toFixed(2),
      SALDO_TOTAL: Number(0).toFixed(2),
      SALDO_CTAANT: Number(0).toFixed(2),
      SALDO_LIBROS: ''
    };
  
    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    document.getElementById('divPrincipal2').style.pointerEvents = 'all';
    document.getElementById('BAN_CODIGO').focus();
    
    this.conciliacionBancaService.detconcil = [];
    this.conciliacionBancaService.patidasconsil = [];
    this.chknoconcilia = false;
    this.inputDes = false;
    this.btnBuscarDes = false;
    this.btnaceptar = true;
    this.boolCmbAnio = false;
    this.boolCmbMes = false;
    this.llenarCombos();
    this.isNewRecord = true;
    
    
    this.activarBotones(true, false, false, true, true, true, true);
    
  }
  
  cancelar() {
    
    if (this.selectTab === 0) {
      this.cancelarAccion();
      this.bloquearElementos();
    }
  
    if (this.selectTab === 1) {
        this.updateData(0, '');
    }
  
    this.activarBotones(false, true, false, false, false, false, false);
    
  }
  
  cancelarAccion() {
    if (this.isNewRecord) {
      this.updateDataNew(null, false);
      this.isNewRecord = false;
      this.limpiarCampos();
    } else {
      this.encontrarConciliacion(this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO, this.conciliacionBancaService.banenconsil.BAN_CODIGO);
    }
    
  }
  
  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
  }
  
  llenarCombos() {
    
    this.cmbMes = [{value: '01', label: 'Enero'},
      {value: '02', label: 'Febrero'},
      {value: '03', label: 'Marzo '},
      {value: '04', label: 'Abril'},
      {value: '05', label: 'Mayo'},
      {value: '06', label: 'Junio'},
      {value: '07', label: 'Julio'},
      {value: '08', label: 'Agosto'},
      {value: '09', label: 'Septiembre'},
      {value: '10', label: 'Octubre'},
      {value: '11', label: 'Noviembre'},
      {value: '12', label: 'Diciembre'}];
    
    const fecha = new Date();
    
    for (const a of this.cmbMes) {
      if (Number(a.value) === (fecha.getMonth() + 1)) {
        this.strCmbMes = {value: a.value, label: a.label};
      }
    }
    
    this.cmbAnio = [];
    
    for (let i = 1900; i <= fecha.getFullYear(); i++) {
      this.cmbAnio.push({value: i.toString(), label: i});
    }
    
    for (const a of this.cmbAnio) {
      if (Number(a.value) === fecha.getFullYear()) {
        this.strCmbAnio = {value: a.value, label: a.label};
      }
    }
    
  }
  
  async aceptar() {
   
    if (this.conciliacionBancaService.banenconsil.BAN_CODIGO.trim().length !== 0
    && this.conciliacionBancaService.banenconsil.BAN_NOMBRE.trim().length !== 0) {
      this.spin= true;
      // verifica si ya exixsten conciliaciones previas a la que se esta ingresando
      // si existen muestra  el mensaje de informacion
  
      const result = await this.verificarPeriodo(this.conciliacionBancaService.banenconsil.BAN_CODIGO);
      if (!result) {
        this.mensajeAlerta('error', 'ERROR', 'Existen Conciliaciones Bancarias pertenecientes a periodos anteriores o al mismo periodo.');
        this.spin= false;
        return;
      }
  
      this.conciliacionBancaService.actualizarFechaVencimiento().subscribe(() => {}, error1 => {
        this.mensajeAlerta('error', 'Error', '! Proceso Actualizar fecha de vencimiento no terminó satisfactoriamente.... !');
      });
      this.updateDataNew(1, true);
      this.calculoDiferencia();
      this.conciliacionBancaService.obtenerEstadoCntaAnt(this.conciliacionBancaService.banenconsil.ENCCONCIL_ANIO,
        this.conciliacionBancaService.banenconsil.ENCCONCIL_MES, this.conciliacionBancaService.banenconsil.BAN_CODIGO).subscribe((saldoAnt) => {
        if (Object.keys(saldoAnt).length > 0) {
          this.conciliacionBancaService.banenconsil.SALDO_CTAANT = Number(saldoAnt[0].SALDOANT).toFixed(2);
        } else {
          this.conciliacionBancaService.banenconsil.SALDO_CTAANT = Number(0).toFixed(2);
        }
        this.conciliacionBancaService.banenconsil.SALDO_CTAANT = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.SALDO_CTAANT);
        this.spin= false;
      });
      
    }
    
  }
  
  
  buscaConciliacion() {
    this.opcionbusqueda = 'conciliacion';
    this.types = [
      {label: 'Número', value: 'ENCCONCIL_NUMERO'},
      {label: 'COD. BANCO', value: 'BAN_CODIGO'},
      {label: 'NOMBRE BANCO', value: 'BAN_NOMBRE'},
      {label: 'MES', value: 'ENCCONCIL_MES'},
      {label: 'AÑO', value: 'ENCCONCIL_ANIO'},
    ];
    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = 'ENCCONCIL_NUMERO,BAN_CODIGO,' +
      '(SELECT BAN_BANCO FROM BAN_MAEBAN WHERE BAN_CODIGO = e.ban_codigo ) as BAN_NOMBRE,ENCCONCIL_MES' +
      ',ENCCONCIL_ANIO';
    this.where = '';
    this.tabla = 'BAN_ENCCONCIL E';
    
    this.busqService.busquedaBanEncconcil().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
    
  }
  
  busquedabanco(parametro) {
    this.opcionbusqueda = 'banco';
    this.types = [
      {label: 'Código', value: 'BAN_CODIGO'},
      {label: 'Banco', value: 'BAN_BANCO'},
      {label: 'Cuenta Nro', value: 'BAN_CTANRO'},
      {label: 'Jefe Cuenta', value: 'BAN_JEFECTA'},
      {label: 'Sucursal', value: 'BAN_SUCURSAL'},
      {label: 'Dirección', value: 'BAN_direccion'},
      {label: 'Telefono1', value: 'BAN_telefono1'},
      {label: 'Ref. contable', value: 'CON_CODIGO'},
      {label: 'Cen_Codigo', value: 'CEN_CODIGO'},
      {label: 'Tipo de cuenta', value: 'BAN_TIPOCUENTA'},
      {label: 'Doc. por Pagar', value: 'con_codigo_2'},
      {label: 'Proceso', value: 'ban_proceso_cm'},
      {label: 'DirecciónCM', value: 'directorioCM'},
      {label: 'Dinero Elec', value: 'ban_dineroelec'},
      {label: 'Tel Cel', value: 'ban_telefonocel'},
      {label: 'Ult. Cheque', value: 'BAN_ULTIMOCHEQUE'},
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = '';
    this.where = '';
    this.tabla = 'BAN_MAEBAN';
    
    this.conciliacionBancaService.encontrarBanMaeBan(this.types[0].value +
      ' LIKE \'' + parametro + '\'').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaBanMaeBan3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaBanMaeBan3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  
  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'banco') {
      this.conciliacionBancaService.banenconsil.BAN_CODIGO = opcion.BAN_CODIGO;
      this.conciliacionBancaService.banenconsil.BAN_NOMBRE = opcion.BAN_BANCO;
      this.conciliacionBancaService.banenconsil.CON_CODIGO = opcion.CON_CODIGO;
      this.conciliacionBancaService.banenconsil.BAN_CTANRO = opcion.BAN_CTANRO;
    }
    if (this.opcionbusqueda === 'conciliacion') {
      this.encontrarConciliacion(opcion.ENCCONCIL_NUMERO, opcion.BAN_CODIGO);
    }
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }
  
  // detalle de conciliacion y encabezado
  encontrarConciliacion(encconcilnumero, bancodigo) {
    this.chknoconcilia = false;
  
    this.conciliacionBancaService.banenconsil.TOTAL_SELECT = '';
    this.conciliacionBancaService.banenconsil.SALDO_TOTAL = '';
    this.conciliacionBancaService.banenconsil.SALDO_LIBROS = '';
    
    this.conciliacionBancaService.encontrarEncConciliacion(encconcilnumero, bancodigo).subscribe(resp => {
      for (const data of resp) {
        this.conciliacionBancaService.banenconsil = data;
        this.conciliacionBancaService.banenconsil.ENCCONCIL_SALDOEC = data.ENCCONCIL_SALDOEC.toFixed(2);
        this.actualizarcmb(data.ENCCONCIL_ANIO, data.ENCCONCIL_MES);
      }
      this.inputDes = true;
      this.boolCmbAnio = true;
      this.boolCmbMes = true;
      this.isNewRecord = false;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      
      this.conciliacionBancaService.encontrarConciliacion(this.conciliacionBancaService.banenconsil.BAN_CODIGO).subscribe(dat => {
        if (Object.keys(dat).length > 0) {
          const rs = dat[0];
          this.conciliacionBancaService.banenconsil.BAN_CODIGO = rs.BAN_CODIGO;
          this.conciliacionBancaService.banenconsil.BAN_NOMBRE = rs.BAN_BANCO;
          this.conciliacionBancaService.banenconsil.BAN_CTANRO = rs.BAN_CTANRO;
          this.conciliacionBancaService.banenconsil.CON_CODIGO = rs.CON_CODIGO;
  
          this.lblBanDatos = this.getDatosLblBanco();
          this.updateData(0, '');
          this.btnaceptar = false;
          
          this.conciliacionBancaService.obtenerSaldoMes(bancodigo, this.validarFecha(this.strCmbAnio.value,
            this.strCmbMes.value), this.conciliacionBancaService.banenconsil.CON_CODIGO).subscribe((saldo) => {
            if (Object.keys(saldo).length > 0) {
              this.conciliacionBancaService.banenconsil.SALDO_LIBROS = Number(saldo[0][':B1']).toFixed(2);
            } else {
              this.conciliacionBancaService.banenconsil.SALDO_LIBROS = Number(0).toFixed(2);
            }
            this.conciliacionBancaService.banenconsil.SALDO_LIBROS = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.SALDO_LIBROS);
          });
          this.conciliacionBancaService.obtenerEstadoCntaAnt(this.conciliacionBancaService.banenconsil.ENCCONCIL_ANIO,
            this.conciliacionBancaService.banenconsil.ENCCONCIL_MES, bancodigo).subscribe((saldoAnt) => {
            if (Object.keys(saldoAnt).length > 0) {
              this.conciliacionBancaService.banenconsil.SALDO_CTAANT = Number(saldoAnt[0].SALDOANT).toFixed(2);
            } else {
              this.conciliacionBancaService.banenconsil.SALDO_CTAANT = Number(0).toFixed(2);
            }
            this.totalSeleccionados();
            this.conciliacionBancaService.banenconsil.SALDO_CTAANT = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.SALDO_CTAANT);
          });
        }
      });
      // encontrarDetalleConciliacion
      // this.conciliacionBancaService.banenconsil.TOTAL_SELECT = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.TOTAL_SELECT);
      // this.conciliacionBancaService.banenconsil.SALDO_CTAANT = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.SALDO_CTAANT);
      // this.conciliacionBancaService.banenconsil.SALDO_TOTAL = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.SALDO_TOTAL);
      
      this.conciliacionBancaService.encontrarDetalleConciliacion(encconcilnumero, bancodigo).subscribe(respdet => {
        
        this.conciliacionBancaService.detconcil = respdet;
        if (this.conciliacionBancaService.detconcil !== null) {
          let sumaTotalSel = 0;
          
          for (const item of this.conciliacionBancaService.detconcil) {
            sumaTotalSel = sumaTotalSel + Number(item.TRNBAN_IMPORTE);
          }
          this.conciliacionBancaService.banenconsil.TOTAL_SELECT = sumaTotalSel.toFixed(2);
          this.conciliacionBancaService.banenconsil.TOTAL_SELECT = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.TOTAL_SELECT);
  
          this.conciliacionBancaService.detconcil.map((item) => {
            item.NUMERO = this.conciliacionBancaService.detconcil.indexOf(item) + 1;
            item.TRNBAN_FECHA = item.TRNBAN_FECHA === null ? ''  : item.TRNBAN_FECHA;
            if (item.TRNBAN_FECHA.toString().includes('-') && item.TRNBAN_FECHA !== '') {
              item.TRNBAN_FECHA = this.datePipe.transform(item.TRNBAN_FECHA, 'dd/MM/yyyy');
            }
            item.FECHA_VENCE = item.TRNBAN_FECHA;
            item.ASI_FECHA = item.TRNBAN_FECHA;
            item.TRNBAN_CONCILIA = '1';
            item.TRNBAN_IMPORTE = Number(item.TRNBAN_IMPORTE).toFixed(2);
            item.CHECKCONT = true;
          });
          
        } else {
          this.conciliacionBancaService.banenconsil.TOTAL_SELECT = Number(0).toFixed(2);
        }
      });
      // this.conciliacionBancaService.banenconsil.TOTAL_SELECT = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.TOTAL_SELECT);
      // this.conciliacionBancaService.banenconsil.SALDO_CTAANT = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.SALDO_CTAANT);
      // this.conciliacionBancaService.banenconsil.SALDO_TOTAL = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.SALDO_TOTAL);
      
    });
  }
  
  // Ver si el check esta seleccionado
  
  seleccionChkConciliacion(valor) {
    this.totalSeleccionados();
  
    this.cambio('');
  }
  
  seleccionChkPartConcil(valor) {
    this.cambioGrid(valor);
  }
  
  navegar(strTipo) {
    
    let strCondicion = 'COM_CODIGO = \'01\'';
    
    if (this.conciliacionBancaService.banenconsil.BAN_CODIGO === undefined) {
      this.conciliacionBancaService.banenconsil.BAN_CODIGO = '';
    }
    
    if (this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO === undefined) {
      this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO = '';
    }
    if (strTipo === 'S') {
      if (this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO.length > 0) {
        strCondicion = strCondicion + ' AND ENCCONCIL_NUMERO = \''
          + this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO + '\'';
        strCondicion = strCondicion + ' AND BAN_CODIGO = \''
          + this.conciliacionBancaService.banenconsil.BAN_CODIGO + '\'';
      } else {
        strTipo = 'U';
      }
    }
    if (strTipo === 'A') {
      if (this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO.length > 0) {
        strCondicion = strCondicion + ' AND ENCCONCIL_NUMERO = \''
          + this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO + '\'';
        strCondicion = strCondicion + ' AND BAN_CODIGO = \''
          + this.conciliacionBancaService.banenconsil.BAN_CODIGO + '\'';
      } else {
        strTipo = 'P';
      }
    }
    
    this.definirCondicion(strTipo, ' ', strCondicion);
    
  }
  
  definirCondicion(strTipo, cadena, strCondicion) {
    
    this.conciliacionBancaService.navegarRegistro(strTipo, strCondicion).subscribe((datos) => {
      if (Object.keys(datos).length > 0) {
        this.encontrarConciliacion(datos[0].ENCCONCIL_NUMERO, datos[0].BAN_CODIGO);
        if (this.index < 2) {
          this.botonNuevo = false;
          this.botonGuardar = true;
          this.botonBorrar = false;
          this.botonRegresar = true;
        }
        this.nuevaEncCo = true;
      }
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'concilBancaria',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }
  
  actualizarcmb(strAnio, strMes) {
    for (const a of this.cmbAnio) {
      if (a.value === strAnio) {
        this.strCmbAnio = {value: a.value, label: a.label};
      }
    }
    
    for (const a of this.cmbMes) {
      if (a.value === strMes) {
        this.strCmbMes = {value: a.value, label: a.label};
      }
    }
    
  }
  
  validarFecha(anio, mes): string {
    const fecha = new Date(anio, mes, 0);
    const newfecha = fecha.toLocaleString('en-GB').split(',');
    
    return newfecha[0];
  }
  
  
  totalSeleccionados(): number {
    
    let dblTotal = 0;
    let  value = null;
    let dblSaldoAnt = 0;
    let dblSaldoTotal = 0;
    
    for (const item of this.conciliacionBancaService.detconcil) {
      if (item.CHECKCONT === false) {
        dblTotal = Number(dblTotal) +  0;
      } else {
        dblTotal = Number(dblTotal) +  Number(item.TRNBAN_IMPORTE);
      }
      
    }
    
    value = dblTotal;
    value = Number(value).toFixed(2);

    this.conciliacionBancaService.banenconsil.TOTAL_SELECT = value;
  
    if (this.init.quitarComas(this.conciliacionBancaService.banenconsil.SALDO_CTAANT) !== '') {
      dblSaldoAnt = Number(this.init.quitarComas(this.conciliacionBancaService.banenconsil.SALDO_CTAANT));
    } else {
      dblSaldoAnt = 0;
    }
    
    dblSaldoTotal = dblSaldoAnt + dblTotal;
    
    value = dblSaldoTotal;
    value = Number(value).toFixed(2);
    
    this.conciliacionBancaService.banenconsil.SALDO_TOTAL = value;
  
    this.conciliacionBancaService.banenconsil.SALDO_TOTAL = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.SALDO_TOTAL);
    this.conciliacionBancaService.banenconsil.TOTAL_SELECT = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.TOTAL_SELECT);
  
    return dblTotal;
  }
  
  selectAll(e) {
    this.cambio(e);
    if (e.checked) {
      this.conciliacionBancaService.detconcil.map(item => {
        item.CHECKCONT = true;
        item.TRNBAN_CONCILIA = '1';
      });
      this.aggrid.refreshaggrid(this.conciliacionBancaService.detconcil, this.defaultColConcil.objeto);
    }
    if (!e.checked) {
      this.conciliacionBancaService.detconcil.map(item => {
        item.CHECKCONT = false;
        item.TRNBAN_CONCILIA = '1';
      });
      this.aggrid.refreshaggrid(this.conciliacionBancaService.detconcil, this.defaultColConcil.objeto);
    }
    
    this.calculoDiferencia();
    this.totalSeleccionados();
    
  }
  
  calculoDiferencia(): string {
    this.calculoValConciliados();
    let diferencia = 0.00;
    const strTotConciliado = this.getTotConciliado();
  
    diferencia = strTotConciliado + this.getSaldoLibros();
    
    this.setDiferencia(diferencia);
  
    return String(diferencia);
  }
  
  getTotConciliado(): number {
    return this.txtTotalConcil == null ? 0 : this.txtTotalConcil;
  }
  
  getSaldoLibros(): number {
    return Number(this.init.quitarComas(this.conciliacionBancaService.banenconsil.SALDO_LIBROS));
  }
  
  setDiferencia(diferencia) {
  if (diferencia != null) {
    this.txtResta = Number(diferencia).toFixed(2);
  } else {
    this.txtResta = Number(0).toFixed(2);
  }
  this.conciliacionBancaService.obtenerSaldoMes(this.conciliacionBancaService.banenconsil.BAN_CODIGO,
      this.validarFecha(this.strCmbAnio.value,
        this.strCmbMes.value), this.conciliacionBancaService.banenconsil.CON_CODIGO).subscribe((saldo) => {
      if (Object.keys(saldo).length > 0) {
        //  this.saldolibros = Number(saldo[0][':B1']).toFixed(2);
        this.conciliacionBancaService.banenconsil.SALDO_LIBROS = Number(saldo[0][':B1']).toFixed(2);
      } else {
        // this.saldolibros = Number(0).toFixed(2);
        this.conciliacionBancaService.banenconsil.SALDO_LIBROS = Number(0).toFixed(2);
      }
      this.conciliacionBancaService.banenconsil.SALDO_LIBROS = this.init.moneyValidation(
      this.conciliacionBancaService.banenconsil.SALDO_LIBROS);
  
  });
  }
  calculoValConciliados(): number {
    let sumaConcilados = 0;
    let saldoCuenta = 0;
    saldoCuenta = Number(this.conciliacionBancaService.banenconsil.ENCCONCIL_SALDOEC);
    
    for (const item  of this.conciliacionBancaService.detconcil) {
      if (item.TRNBAN_CONCILIA === '0') {
        sumaConcilados = sumaConcilados + (Number(item.TRNBAN_IMPORTE === null ? 0 : item.TRNBAN_IMPORTE)  * - 1 );
      }
    }
    
    sumaConcilados = saldoCuenta + sumaConcilados;
    this.txtTotalConcil = sumaConcilados;
    
    
    return sumaConcilados;
  }
  
  mostrarTodos(e) {
    if (e.checked) {
      this.conciliacionBancaService.actualizarFechaVencimiento().subscribe(() => {});
      this.updateDataNew(3, true);
      return true;
    }
    if (!e.checked) {
      this.updateDataNew(2, false);
      return false;
    }
  }
  
  updateDataNew(tipo, bool) {
    if (tipo == null) {
      this.conciliacionBancaService.banenconsil = {};
      this.conciliacionBancaService.detconcil = [];
      return;
    }
    let filtro = '';
    switch (tipo) {
      case 1: {
        if (this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO.trim().length === 0) {
          
          this.isNewRecord = true;
          
          const fechaLimite = this.validarFecha(this.strCmbAnio.value,
            this.strCmbMes.value);
          console.log(fechaLimite);
          filtro = ' ban_codigo = \'' + this.conciliacionBancaService.banenconsil.BAN_CODIGO + '\''
            +  ' and  trnban_fechavence <= to_date(\''
            + fechaLimite + '\',\'dd/mm/yyyy\')'
            + ' and  TRNBAN_CONCILIA = 0 and trnban_anulado = 0  ';
          
          this.conciliacionBancaService.consultarBAN_SP_ConsultarTrnBncrCncl(filtro).subscribe((datos) => {
            this.conciliacionBancaService.detconcil = datos;
            if (this.conciliacionBancaService.detconcil !== null) {
              this.conciliacionBancaService.detconcil.map((item) => {
                item.NUMERO = this.conciliacionBancaService.detconcil.indexOf(item) + 1;
                item.TRNBAN_FECHA = item.ASI_FECHA === null ? ''  : item.ASI_FECHA;
                if (item.TRNBAN_FECHA.toString().includes('-') && item.TRNBAN_FECHA !== '') {
                  item.TRNBAN_FECHA = this.datePipe.transform(item.TRNBAN_FECHA, 'dd/MM/yyyy');
                }
                item.FECHA_VENCE = item.TRNBAN_FECHA;
                item.ASI_FECHA = item.TRNBAN_FECHA;
                item.TRNBAN_IMPORTE = Number(item.TRNBAN_IMPORTE).toFixed(2);
                item.CHECKCONT = false;
                item.ASI_COMPROBANTE = item.ASI_NRO;
              });
  
              this.aggrid.refreshaggrid(this.conciliacionBancaService.detconcil, this.defaultColConcil.objeto);
            } else {
              this.conciliacionBancaService.banenconsil.TOTAL_SELECT = Number(0).toFixed(2);
            }
           
          });

        }
        break;
      }
    
      case 2: {
  
        if (this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO.length > 0) {
          this.isNewRecord = false;
          this.conciliacionBancaService.encontrarDetalleConciliacion(this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO,
            this.conciliacionBancaService.banenconsil.BAN_CODIGO).subscribe(detalle => {
    
            this.conciliacionBancaService.detconcil = detalle;
            if (this.conciliacionBancaService.detconcil !== null) {
              let sumaTotalSel = 0;
      
              for (const item of this.conciliacionBancaService.detconcil) {
                sumaTotalSel = sumaTotalSel + Number(item.TRNBAN_IMPORTE);
              }
              this.conciliacionBancaService.banenconsil.TOTAL_SELECT = sumaTotalSel.toFixed(2);
              this.conciliacionBancaService.banenconsil.TOTAL_SELECT = this.init.moneyValidation(this.conciliacionBancaService.banenconsil.TOTAL_SELECT);
              this.conciliacionBancaService.detconcil.map((item) => {
                item.NUMERO = this.conciliacionBancaService.detconcil.indexOf(item) + 1;
                item.TRNBAN_FECHA = item.TRNBAN_FECHA === null ? '' : item.TRNBAN_FECHA;
                if (item.TRNBAN_FECHA.toString().includes('-') && item.TRNBAN_FECHA !== '') {
                  item.TRNBAN_FECHA = this.datePipe.transform(item.TRNBAN_FECHA, 'dd/MM/yyyy');
                }
                item.FECHA_VENCE = item.TRNBAN_FECHA;
                item.ASI_FECHA = item.TRNBAN_FECHA;
                item.TRNBAN_CONCILIA = '1';
                item.TRNBAN_IMPORTE = Number(item.TRNBAN_IMPORTE).toFixed(2);
                item.CHECKCONT = true;
              });
      
            } else {
              this.conciliacionBancaService.banenconsil.TOTAL_SELECT = Number(0).toFixed(2);
            }
            this.aggrid.refreshaggrid(this.conciliacionBancaService.detconcil, this.defaultColConcil.objeto);
          });
        } else {
          this.conciliacionBancaService.detconcil = [];
        }
        break;
      }
    
      case 3: {
        if (this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO.length > 0 && bool === true) {
          this.isNewRecord = false;
        
          const fechaLimite = this.validarFecha(this.strCmbAnio.value,
            this.strCmbMes.value);
          filtro = ' ((ban_codigo = \'' + this.conciliacionBancaService.banenconsil.BAN_CODIGO + '\''
            + ' and  trnban_fechavence <= to_date(\'' + fechaLimite + '\',\'dd/mm/yyyy\')'
            + ' and  TRNBAN_CONCILIA = 0  '
            + ' ) or (ENCCONCIL_NUMERO=\'' + this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO + '\''
            + ' and ban_codigo = \'' + this.conciliacionBancaService.banenconsil.BAN_CODIGO + '\')) and trnban_anulado = 0';
        
        
          this.conciliacionBancaService.consultarDetConNoConcil(filtro).subscribe((datos) => {
            this.conciliacionBancaService.detconcil = [];
            if (datos !== null) {
              for (const item of datos) {
                item.ASI_COMPROBANTE = item.ASI_NRO;
                this.conciliacionBancaService.detconcil.push(item);
              }
              this.conciliacionBancaService.detconcil.map((item) => {
                item.NUMERO = this.conciliacionBancaService.detconcil.indexOf(item) + 1;
                item.TRNBAN_FECHA = item.ASI_FECHA === null ? ''  : item.ASI_FECHA;
                if (item.TRNBAN_FECHA.toString().includes('-') && item.TRNBAN_FECHA !== '') {
                  item.TRNBAN_FECHA = this.datePipe.transform(item.TRNBAN_FECHA, 'dd/MM/yyyy');
                }
                item.FECHA_VENCE = item.TRNBAN_FECHA;
                item.ASI_FECHA = item.TRNBAN_FECHA;
                item.TRNBAN_IMPORTE = Number(item.TRNBAN_IMPORTE).toFixed(2);
                item.CHECKCONT = item.TRNBAN_CONCILIA === 1 ? true : false;
                item.ENCCONCIL_NUMERO = '';
              });
              this.aggrid.refreshaggrid(this.conciliacionBancaService.detconcil, this.defaultColConcil.objeto);
            }
          });
        } else {
          this.conciliacionBancaService.detconcil = [];
        }
        break;
      }
    }
  }
  
  onTab1Change(event) {
    this.activateTab(event.index);
  }
  
  activateTab(tabNumber) {
    this.selectTab = tabNumber;
    if (this.selectTab === 1) {
       this.lblBanDatos = this.getDatosLblBanco();
      // this.updateData(0, '');
    }
  }
  
  getDatosLblBanco(): string {
    let srtDatosBanco = '';
    if (this.conciliacionBancaService.banenconsil.BAN_NOMBRE === '')  {
      return srtDatosBanco;
    }
    srtDatosBanco = 'BANCO: ' + this.conciliacionBancaService.banenconsil.BAN_NOMBRE + '   CUENTA: ' +
      this.conciliacionBancaService.banenconsil.BAN_CTANRO + '   CODIGO: '  + this.conciliacionBancaService.banenconsil.BAN_CODIGO;
    
    return srtDatosBanco;
  }
  
  updateData(tipo, ordenamiento) {
    let filtro;
    let orden;
    if (tipo === 0) {
      filtro = '';
      orden = '';
      
      this.conciliacionBancaService.consultarPartConciliatoria(' encconcil_numero =\'' + this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO
        + '\'' , ' and ban_codigo=\'' + this.conciliacionBancaService.banenconsil.BAN_CODIGO + '\'').subscribe((datos) => {
        
        if (datos !== null) {
          this.conciliacionBancaService.patidasconsil = datos;
  
          this.conciliacionBancaService.patidasconsil.map((item) => {
            item.LINEA = this.conciliacionBancaService.patidasconsil.indexOf(item) + 1;
            item.TRNBAN_FECHA = item.TRNBAN_FECHA === null ? ''  : item.TRNBAN_FECHA;
            if (item.TRNBAN_FECHA.toString().includes('-') && item.TRNBAN_FECHA !== '') {
              item.TRNBAN_FECHA = this.datePipe.transform(item.TRNBAN_FECHA, 'dd/MM/yyyy');
            }
            item.TRNBAN_IMPORTE = Number(item.TRNBAN_IMPORTE).toFixed(2);
            item.CHECKCONT = item.TRNBAN_CONCIL === 1 ? true : false;
            item.NEW_ROW = false;
            
            for (const dato of this.cmbTrnTipo) {
              if (dato.codigo === item.TRNBAN_TIPO || dato.name.trim() === item.TRNBAN_TIPO) {
                item.NOMBRE_TIPO = dato.name;
              }
            }
          });
          const tam = this.conciliacionBancaService.patidasconsil.length;
          this.detPartConsilSel = this.conciliacionBancaService.patidasconsil[tam - 1];
        } else {
          this.detPartConsilSel = {};
          this.conciliacionBancaService.patidasconsil = [];
        }
      });
     
    }
  }
  
  seleccionDetConsil(valor) {
  
  }
  
  seleccionDetPartConsil(valor) {
    
    if (valor === 'nuevo') {
      this.insertarNuevoRegistro();
    }
    if (valor === 'eliminar') {
      this.eliminarRegistro();
    }
  
    if (valor === 'cancelar') {
      this.cancelarDetPart();
    }
    if (valor === 'upload') {
     // this.cancelarAcciones();
    }
    if (valor.object !== undefined) {
      this.detPartConsilSel = valor.object;
    }
  }
  
  cambio(event) {
    if (this.conciliacionBancaService.banenconsil !== undefined) {
      this.activarBotones(true, false, false, true, true, true, true);
    }
  }
  
  cambioGrid(e) {
    if (this.conciliacionBancaService.patidasconsil !== undefined) {
      this.activarBotones(true, false, false, true, true, true, true);
      this.activarBtnGrid(true, false, false);
    }
  }
  
  insertarNuevoRegistro() {
    if (this.permisosConsilBan.PERTODO === 0) {
      if (this.permisosConsilBan.PERINSERCION === 0) {
        this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
          ',  no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
        return;
      }
    }
    
    this.activarBtnGrid(true, false, false);
  
    const linea = this.conciliacionBancaService.patidasconsil.length;
    const nuevodetPartConsil: BanPartConsil = {
      BAN_CODIGO: this.conciliacionBancaService.banenconsil.BAN_CODIGO,
      COM_CODIGO: '01',
      ENCCONCIL_NUMERO: this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO,
      TRNBAN_BENEFICIARIO: '',
      TRNBAN_CONCEPTO: '',
      TRNBAN_CONCIL: 0,
      TRNBAN_FECHA: '',
      TRNBAN_IMPORTE: Number(0).toFixed(2),
      TRNBAN_NUMERO: '',
      TRNBAN_TIPO: '',
      NOMBRE_TIPO: '',
      CHECKCONT: false,
      LINEA: linea,
      NEW_ROW: true,
    };
  
    const indi = this.conciliacionBancaService.patidasconsil.indexOf(this.detPartConsilSel);
    this.conciliacionBancaService.patidasconsil.push(nuevodetPartConsil);
    this.aggrid.refreshaggridindex(this.conciliacionBancaService.patidasconsil, this.defaultColPartConsil.objeto, indi);
    this.aggrid.gridApi.setFocusedCell(indi + 1, 'NOMBRE_TIPO');
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.messageService.add({
      key: 'concilBancaria',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  eliminarRegistro() {
    if (Object.keys(this.detPartConsilSel).length > 0) {
      this.confEliminarDetPartCon();
    } else {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
    }
  }
  
  confEliminarDetPartCon() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'concilBancariaconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.conciliacionBancaService.eliminarParConciliatoria(this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO,
          this.conciliacionBancaService.banenconsil.BAN_CODIGO, this.detPartConsilSel.TRNBAN_NUMERO, this.detPartConsilSel.TRNBAN_TIPO).subscribe(() => {
  
          this.auditoriagral.registrarAuditoria('BAN_TRNPARTIDACONCIL', this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO,
            'E', this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
         
          this.conciliacionBancaService.patidasconsil = this.conciliacionBancaService.patidasconsil.filter((val, j) => j !==
            this.conciliacionBancaService.patidasconsil.indexOf(this.detPartConsilSel));
          this.aggrid.refreshaggrid(this.conciliacionBancaService.patidasconsil, this.defaultColPartConsil.objeto);
        
          this.activarBtnGrid(false, false, true);
          
          });
      },
      reject: () => {
      }
    });
  }
  
  cancelarDetPart() {
    this.updateData(0, '');
    this.activarBtnGrid(false, false, true);
  }
  
  getCodigoBan(valor) {
    this.conciliacionBancaService.encontrarConciliacion(this.conciliacionBancaService.banenconsil.BAN_CODIGO).subscribe(dat => {
        if (dat !== null) {
          const rs = dat[0];
          this.conciliacionBancaService.banenconsil.BAN_NOMBRE = rs.BAN_BANCO;
          this.conciliacionBancaService.banenconsil.BAN_CTANRO = rs.BAN_CTANRO;
          this.conciliacionBancaService.banenconsil.CON_CODIGO = rs.CON_CODIGO;
       } else {
          this.conciliacionBancaService.banenconsil.BAN_NOMBRE = '';
          this.conciliacionBancaService.banenconsil.BAN_CTANRO = '';
          this.conciliacionBancaService.banenconsil.CON_CODIGO = '';
        }
      });
   
  }
  
  async guardarConciliacion() {
    if (this.verificarGuardar() !== null) {
      this.mensajeAlerta('error', 'ERROR', 'Los datos del encabezado de la conciliación no estan completos, por favor ' +
        'ingrese el campo Código del banco e intente nuevamente');
      document.getElementById('BAN_CODIGO').focus();
      return false;
    }
    
    if (this.isNewRecord) {
      const result = await this.verificarPeriodo(this.conciliacionBancaService.banenconsil.BAN_CODIGO);
      if (!result) {
        this.mensajeAlerta('error', 'ERROR', 'Existen Conciliaciones Bancarias pertenecientes a periodos anteriores o al mismo periodo.');
        return;
      }
    }
    
    if (this.isNewRecord) {
      if (this.permisosConsilBan.PERINSERCION === 0) {
        if (this.permisosConsilBan.PERINSERCION === 0) {
          this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible insertar la conciliación debido a que usted no ' +
            'posee permisos de inserción');
          return;
        }
      }
    } else {
      if (this.permisosConsilBan.PERINSERCION === 0) {
        if (this.permisosConsilBan.PERINSERCION === 0) {
          this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible actualizar la conciliación debido a que usted no ' +
            'posee permisos de actualización');
          return;
        }
      }
    }
    
    const numero = await this.insertarEncConciliacion(this.isNewRecord, this.conciliacionBancaService.banenconsil);
  
    this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO = numero;
    if ( numero == null ) {
      this.mensajeAlerta('info', 'Información', 'La conciliación ya existe');
      return false;
    } else {
      this.isNewRecord = false;
      if (!await this.guardarDetalle(numero)) {
        this.isNewRecord = false;
        this.mensajeAlerta('error', 'ERROR', 'Una o más líneas de detalle no se pudieron guardar correctamente, ' +
          'por favor revise los datos');
        return false;
      } else {
        this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO = numero;
      }
    
    }
    
    return true;
    
  }
  
  async guardarPartidaConciliatoria(concilNumero) {
    if (await this.insertaActualizaParConcil(concilNumero)) {
    
      return true;
    } else {
      return false;
    }
  
  }
  
  async insertaActualizaParConcil(concilNumero) {
    const actual: BanPartConsil = this.detPartConsilSel;
    if (actual.NEW_ROW) {
      if (await this.insertarParConciliatoria(concilNumero, actual)) {
        actual.NEW_ROW = false;
        this.activarBotones(false, true, false, false, false, false, false);
        this.activarBtnGrid(true, false, false);
        return true;
      } else {
        this.mensajeAlerta('error', 'ERROR', this.expError);
        const s = 'Datos no validos revice los datos';
        // actualiza.cambiaValores(frmBAN_consilbancaria.EN_EDICION);
        return false;
      }
    
    } else {
      if (this.permisosConsilBan.PERTODO === 0) {
        if (this.permisosConsilBan.PERELIMACION === 0) {
          this.mensajeAlerta('info', 'Información', 'Ha sido imporsible eliminar la conciliación debido a que usted no posee ' +
            'permisos de eliminación');
          return;
        }
      }
  
      if (await this.ActualizarParConciliatoria(concilNumero, actual)) {
        this.activarBotones(false, true, false, false, false, false, false);
        this.activarBtnGrid(true, false, false);
        return true;
      } else {
        this.mensajeAlerta('error', 'ERROR', 'Error no se puede modificar la partida conciliatoria.');
        return false;
      }
    }
  }
  
  async insertarParConciliatoria(encConcilNum, item: BanPartConsil) {
    if (item.TRNBAN_FECHA.toString().includes('-')) {
      item.TRNBAN_FECHA = this.datePipe.transform(item.TRNBAN_FECHA,
        'dd/MM/yyyy');
    }
    for (const tipo of this.cmbTrnTipo) {
      if (tipo.name.trim() === item.NOMBRE_TIPO.trim()) {
        item.TRNBAN_TIPO = tipo.codigo;
      }
    }
    try {
      await this.conciliacionBancaService.insertarParConciliatoria(encConcilNum, item);
      this.auditoriagral.registrarAuditoria('BAN_TRNPARTIDACONCIL', encConcilNum,
        'I', this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
  
    } catch (e) {
      this.expError = e.error;
      return false;
    }
    return true;
  }
  
  async ActualizarParConciliatoria(concilNumero, item: BanPartConsil) {
    if (item.TRNBAN_FECHA.toString().includes('-')) {
      item.TRNBAN_FECHA = this.datePipe.transform(item.TRNBAN_FECHA,
        'dd/MM/yyyy');
    }
    
    if (item.NOMBRE_TIPO !== '') {
      for (const tipo of this.cmbTrnTipo) {
        if (tipo.name.trim() === item.NOMBRE_TIPO.trim()) {
          item.TRNBAN_TIPO = tipo.codigo;
        }
      }
    }
    
    try {
      await this.conciliacionBancaService.ActualizarParConciliatoria(concilNumero, item);
      this.auditoriagral.registrarAuditoria('BAN_TRNPARTIDACONCIL', concilNumero,
        'A', this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      this.expError = err.error;
      return false;
    }
    return true;
  }
  
  
  
  
  
  actualizarSelEnBloque() {
  
  }
  
  verificarGuardar(): string {
    if (this.conciliacionBancaService.banenconsil.BAN_CODIGO.trim().length === 0) {
      return this.conciliacionBancaService.banenconsil.BAN_CODIGO;
    }
    
    return null;
  }
  
  async  verificarPeriodo(banCodigo) {
    let intAnio = 1900 ;
    let intMes = 0;
    const intDia = 1;
    let intAnioActual;
    let intMesActual;
    const intDiaActual = 1;
    try {
      const periodo = await this.conciliacionBancaService.verificaPeriodoConciliacionp(banCodigo);
      if (Object.keys(periodo).length > 0) {
        intAnio = periodo[0].ENCCONCIL_ANIO;
        intMes = periodo[0].ENCCONCIL_MES;
      }
    } catch (e) {
    
    }
    
    intAnioActual = Number(this.strCmbAnio.value);
    intMesActual = Number(this.strCmbMes.value);
  
    const hora = new Date().getHours();
    const minutos = new Date().getMinutes();
    const segundos  = new Date().getSeconds();
    const miliseg = new Date().getMilliseconds();
  
    const calUltConciliacion = new Date(intAnio, intMes - 1, intDia, hora, minutos, segundos, miliseg ).getTime();
    const calConcilActual = new Date(intAnioActual, intMesActual - 1, intDiaActual, hora, minutos, segundos, miliseg ).getTime();
    console.log(calUltConciliacion,'-',calConcilActual)
    if (calUltConciliacion < calConcilActual) {
       return true;
    } else {
      return false;
    }
  }
  async insertarEncConciliacion(isNew, item: BanEncconsil) {
    this.conciliacionBancaService.banenconsil.ENCCONCIL_MES = this.strCmbMes.value;
    this.conciliacionBancaService.banenconsil.ENCCONCIL_ANIO = this.strCmbAnio.value;
    let value;
    
    try {
      const result = await this.conciliacionBancaService.insertarEncConciliacionp(isNew, item);
      if (result !== null) {
        value = result[0].v_ENCCONCIL_NUMERO;
      }
  
      if (this.isNewRecord) {
        this.auditoriagral.registrarAuditoria('BAN_ENCCONCIL', value === null ? this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO : value,
          'I', this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        
      } else {
        this.auditoriagral.registrarAuditoria('BAN_ENCCONCIL', value === null ? this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO : value,
          'A', this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      }
  
      if (value === null) {
        return  this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO;
      }
      return value;
      
    } catch (e) {
      return null;
    }
  }
  
  async guardarDetalle(encnumero) {
    let state = true;

    this.porcentaje = 0;
    
    for (const item of this.conciliacionBancaService.detconcil) { // tablaDetalle.getItemsSelecionados())
      this.porcentaje = this.porcentaje + Number(100 / this.conciliacionBancaService.detconcil.length);
      item.ENCCONCIL_NUMERO = encnumero;
      item.BAN_CODIGO = item.BAN_CODIGO === null ? '' : item.BAN_CODIGO;
      item.TRNBAN_BENEFICIARIO = item.TRNBAN_BENEFICIARIO === null ? '' : item.TRNBAN_BENEFICIARIO;
      item.TRNBAN_CONCEPTO =  item.TRNBAN_CONCEPTO  === null ? '' : item.TRNBAN_CONCEPTO ;
      if (item.FECHA_VENCE.toString().includes('-')) {
        item.TRNBAN_FECHA = this.datePipe.transform(item.FECHA_VENCE,
          'dd/MM/yyyy');
      } else {
        item.TRNBAN_FECHA = item.FECHA_VENCE;
      }
      
      item.TRNBAN_IMPORTE = item.TRNBAN_IMPORTE === null ? '' : item.TRNBAN_IMPORTE;
      item.TRNBAN_TIPO = item.TRNBAN_TIPO === null ? '' : item.TRNBAN_TIPO;
      item.ASI_COMPROBANTE = item.ASI_COMPROBANTE === null ? '' : item.ASI_COMPROBANTE;
     
      item.TRNBAN_NUMERO = item.TRNBAN_NUMERO === null ? '' : item.TRNBAN_NUMERO;
      item.FECHA_CONCIL = new Date();
      const fecha = item.FECHA_CONCIL.toLocaleString('en-GB').split(',');
      item.FECHA_CONCIL = fecha[0];
      
    
      if (item.TRNBAN_CONCILIA === false || Number(item.TRNBAN_CONCILIA) === 0) {
        item.BOOLCONCILIADO = false;
      } else {
        item.BOOLCONCILIADO = true;
      }
    
    
      const ejecutar = await this.actualizaTrnbanActual(item);
      let strConciliado = '';
      
      if (item.BOOLCONCILIADO === true || item.BOOLCONCILIADO === '1') {
        strConciliado = 'S';
      } else {
        strConciliado = 'N';
      }
  
      this.auditoriagral.registrarAuditoria('BAN_DETCONCIL', item.ENCCONCIL_NUMERO + '/' + item.BAN_CODIGO + '/' + item.TRNBAN_NUMERO + '/' + strConciliado,
        'I', this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      
      if (item.CHECKCONT) {
  
        if (! await this.insertarDetConciliacion(item)) {
          state = false;
        }
      } else {
  
      }
    }
    
    
    return state;
  }
  
  async actualizaTrnbanActual(detalle: BanDetconsil) {
    
    try {
      const ejecutar  = await this.conciliacionBancaService.actualizaTrnBan(detalle);
      this.auditoriagral.registrarAuditoria('BAN_TRNBAN', detalle.BAN_CODIGO + '/' + detalle.TRNBAN_TIPO + '/' + detalle.TRNBAN_NUMERO + '/CONCILIACION',
        'I', this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      return false;
    }
    return true;
  
  }
  
  async actualizaTrnBan() {
    for (const item of this.conciliacionBancaService.detconcil) {
      try {
        await this.conciliacionBancaService.actualizaTrnBan(item);
        this.auditoriagral.registrarAuditoria('BAN_TRNBAN', item.BAN_CODIGO + '/' + item.TRNBAN_TIPO + '/' + item.TRNBAN_NUMERO + '/CONCILIACION',
          'A', this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      } catch (err) {
        return false;
      }
    }
    
    return true;
  }
  
  async insertarDetConciliacion(item) {
    try {
      const ejecutar = await this.conciliacionBancaService.insertarDetConciliacion(item);
      this.auditoriagral.registrarAuditoria('BAN_DETCONCIL', item.ENCCONCIL_NUMERO + '/' + item.BAN_CODIGO,
        'I', this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    
    } catch (err) {
      this.mensajeAlerta('error', 'ERROR', '! Proceso guardar conciliación no terminó satisfactoriamente.... !');
      return false;
    }
    return true;
  }
  
  confirmarEliminarConcil() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar la conciliación ?',
      header: 'Confirmación',
      icon: 'pi pi-question-circle',
      key: 'concilBancariaconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
       this.eliminarConciliacion();
      },
      reject: () => {
      }
    });
  }
  
  async eliminarConciliacion() {
    if (this.permisosConsilBan.PERTODO === 0) {
      if (this.permisosConsilBan.PERELIMACION === 0) {
        this.mensajeAlerta('info', 'Información', 'Ha sido imporsible eliminar la conciliación debido a que usted no posee ' +
          'permisos de eliminación');
        return;
      }
    }
    
    if (Object.keys(this.conciliacionBancaService.banenconsil).length  === 0) {
      this.mensajeAlerta('error', 'Información', 'Ha sido imposible eliminar la conciliación debido a que usted no ha ' +
        'seleccionado una conciliación');
      return;
    }
  
    await this.eliminarPartidasConciliatorias(this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO);
    this.actualizaTrnBan();
    await this.eliminarDetale(this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO, this.conciliacionBancaService.banenconsil.BAN_CODIGO);
  
    if (await this.eliminarCabecera()) {
      this.updateDataNew(null, false);
      this.limpiarCampos();
    }
   
  }
  
  async eliminarPartidasConciliatorias(encConcilNumero) {
    try {
      await this.conciliacionBancaService.eliminarPartidasConciliatorias(encConcilNumero);
      await this.auditoriagral.registrarAuditoriaPromise('BAN_TRNPARTIDACONCIL', encConcilNumero,
        'E', this.usuario.identificacion, '01', '', '', '', '');
      
    } catch (err) {
      return false;
    }
    return true;
  }
  
  async eliminarDetale(encConsilNumero, banCodigo) {
    try {
      await this.conciliacionBancaService.eliminarDetale(encConsilNumero, banCodigo);
    } catch (err) {
      return false;
    }
    return true;
  }
  
  async eliminarCabecera() {
    if (! await this.eliminarEncConciliacion()) {
      this.mensajeAlerta('error', 'ERROR', this.expError);
      return false;
    } else {
    
    }
  
  
    return true;
  }
  
  async eliminarEncConciliacion() {
    
      try {
        await this.conciliacionBancaService.eliminarEncConciliacion(this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO,
          this.conciliacionBancaService.banenconsil.BAN_CODIGO);
        await this.auditoriagral.registrarAuditoriaPromise('BAN_ENCCONCIL', this.conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO,
          'E', this.usuario.identificacion, '01', '', '', '', '');
      
      } catch (err) {
        this.expError = err.error;
        return false;
      }
      return true;
  }
  
  limpiarCampos() {
    this.conciliacionBancaService.banenconsil = {};
    this.conciliacionBancaService.detconcil = [];
    this.conciliacionBancaService.patidasconsil = [];
  
    document.getElementById('divPrincipal').style.pointerEvents = 'none';
    document.getElementById('divPrincipal2').style.pointerEvents = 'none';
  
    this.llenarCombos();
  }
  
  activarBotones(nuevo, guardar, eliminar, primero, anterior, siguiente, ultimo) {
    this.botonNuevo = nuevo;
    this.botonGuardar = guardar;
    this.botonBorrar = eliminar;
    this.botonAnterior = anterior;
    this.botonPrimero = primero;
    this.botonSiguiente = siguiente;
    this.botonUltimo = ultimo;
  }
  
  
  bloquearElementos() {
    if (this.isNewRecord) {
      this.boolCmbMes = false;
      this.boolCmbAnio = false;
      this.btnaceptar = true;
      this.inputDes = false;
    } else {
      this.boolCmbMes = true;
      this.boolCmbAnio = true;
      this.inputDes = true;
      this.btnaceptar = false;
      
      return false;
    }
    
  }
  
  activarBtnGrid(nuevo, eliminar, cancelar) {
    this.btnNuevoGrid = nuevo;
    this.btnBorrarGrid = eliminar;
    this.btnCancelarGrid = cancelar;
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  //CDPJ
  isEditFP(data) {
    //CDPJ
   return false;
  }
  //CDPJ
  
}
