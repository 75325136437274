import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { Observable } from 'rxjs';
import { VenDetped } from '../veninterfaces/vendetped';
import { VenEncped } from '../veninterfaces/venencped';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { ProdMaeartparte } from 'src/app/inv/invinterfaces/prodmaeartparte';
import { MessageService } from 'primeng';

@Injectable({
  providedIn: 'root'
})
export class VenencpedService {



  public encabezadoPedidoSeleccionadoarray: VenEncped[] = [];
  public detpedidosarray: VenDetped[][] = [];
  public dblPorcDescGlbPedarray: number[] = [];
  public dblValorDescGlbPedarray: number[] = [];
  public cotizacionBooleanarray: boolean[] = [];

  private url: string;
  public way: string;
  public decimalesCantidadPed: number;
  public decimalesPrecioPed: number;
  public decimalesTotalPed: number;
  public decimalesDescPed: number;
  public dblPorcIvaPed: number[] = [];
  public hmpConfiguracion: any[];
  public permiObjClave: boolean;
  //CDPJ
  public porcetariiva:number;
  public codtariiva:string;
  //CDPJ
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService, private datePipe: DatePipe,
    private init: NuevoComponentService, private message: MessageService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  refrescarURL() {
    if (this.url === null || this.way === null) {
      this.url = sessionStorage.getItem('url');
      this.way = sessionStorage.getItem('way');
    }
    
  }

  getEncPedidos(): Observable<any[]> {
    this.refrescarURL();
    return this.http.post<any[]>(this.way + '/ventas/pedido/i74ku4h0yxul6s8', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getEncPedidosProm() {
    this.refrescarURL();
    return this.http.post<any[]>(this.way + '/ventas/pedido/i74ku4h0yxul6s8', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDetPedidos(pedidoCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/7iabade4or3z34m', {
      elementos: {
        ENCPED_NUMERO: pedidoCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerNombreCli(cliCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/hnknc6rkx0yyg9x', {
      elementos: {
        CLI_CODIGO: cliCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerNombreVen(venCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/ombpnt3rd77qjjl', {
      elementos: {
        VEN_CODIGO: venCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  getListaPrecios(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/sqea2obq0hpi4uo', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  guardarENCPED(encPed: VenEncped): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/sifco8zug981i5f', {
      elementos: {
        p_ENCPED_numero: encPed.ENCPED_NUMERO,
        p_ENCPED_numero_1: encPed.ENCPED_NUMERO,
        p_COM_codigo_2: '01',
        p_CLI_codigo_3: encPed.CLI_CODIGO,
        p_VEN_codigo_4: encPed.VEN_CODIGO,
        p_ENCPED_fechaemision_5: encPed.ENCPED_FECHAEMISION,
        p_ENCPED_fechaentrega_6: encPed.ENCPED_FECHAENTREGA,
        p_ENCPED_iva_7: encPed.ENCPED_IVA,
        p_ENCPED_estado_8: encPed.ENCPED_ESTADO,
        p_ENCPED_orden_10: encPed.ENCPED_ORDEN,
        p_ENCPED_lista_12: encPed.ENCPED_LISTA,
        p_ENCPED_observacion_13: encPed.ENCPED_OBSERVACION,
        p_ENCPED_total_14: Number(encPed.ENCPED_TOTAL),
        p_ENCPED_totalneto_15: Number(encPed.ENCPED_TOTALNETO),
        p_ENCPED_valoriva_18: Number(encPed.ENCPED_VALORIVA),
        p_ENCPED_porceiva_19: Number(encPed.ENCPED_PORCEIVA),
        p_ENCPED_valorice_20: Number(encPed.ENCPED_VALORICE),
        p_ENCPED_porceice_21: Number(encPed.ENCPED_PORCEICE),
        p_ENCPED_baseiva_22: Number(encPed.ENCPED_BASEIVA),
        p_ENCPED_baseice_23: Number(encPed.ENCPED_BASEICE),
        p_ENCPED_basecero_24: Number(encPed.ENCPED_BASECERO),
        p_ENCPED_valordes: Number(encPed.ENCPED_VALORDES),
        p_ENCPED_porcedes: Number(encPed.ENCPED_PORCEDES),
        p_ENCPED_grupo_25: encPed.ENCPED_GRUPO,
        p_ENCPED_referencia: encPed.ENCPED_REFERENCIA,
        p_ENCPED_fechavalidez: encPed.ENCPED_FECHAVALIDEZ,
        p_BOD_codigo: encPed.BOD_CODIGO,
        p_ENCPED_tipo: encPed.ENCPED_TIPO,
        p_ENCPED_tipodscto: encPed.ENCPED_TIPODSCTO,
        p_ENCPED_refcli: encPed.ENCPED_REFCLI
      },
    }, this.confIniciales.httpOptions());
  }

  guardarENCPEDProm(encPed: VenEncped) {
    return this.http.post<any[]>(this.way + '/ventas/pedido/sifco8zug981i5f', {
      elementos: {
        p_ENCPED_numero: encPed.ENCPED_NUMERO,
        p_ENCPED_numero_1: encPed.ENCPED_NUMERO,
        p_COM_codigo_2: '01',
        p_CLI_codigo_3: encPed.CLI_CODIGO,
        p_VEN_codigo_4: encPed.VEN_CODIGO,
        p_ENCPED_fechaemision_5: encPed.ENCPED_FECHAEMISION,
        p_ENCPED_fechaentrega_6: encPed.ENCPED_FECHAENTREGA,
        p_ENCPED_iva_7: encPed.ENCPED_IVA,
        p_ENCPED_estado_8: encPed.ENCPED_ESTADO,
        p_ENCPED_orden_10: encPed.ENCPED_ORDEN,
        p_ENCPED_lista_12: encPed.ENCPED_LISTA,
        p_ENCPED_observacion_13: encPed.ENCPED_OBSERVACION,
        p_ENCPED_total_14: Number(encPed.ENCPED_TOTAL),
        p_ENCPED_totalneto_15: Number(encPed.ENCPED_TOTALNETO),
        p_ENCPED_valoriva_18: Number(encPed.ENCPED_VALORIVA),
        p_ENCPED_porceiva_19: Number(encPed.ENCPED_PORCEIVA),
        p_ENCPED_valorice_20: Number(encPed.ENCPED_VALORICE),
        p_ENCPED_porceice_21: Number(encPed.ENCPED_PORCEICE),
        p_ENCPED_baseiva_22: Number(encPed.ENCPED_BASEIVA),
        p_ENCPED_baseice_23: Number(encPed.ENCPED_BASEICE),
        p_ENCPED_basecero_24: Number(encPed.ENCPED_BASECERO),
        p_ENCPED_valordes: Number(encPed.ENCPED_VALORDES),
        p_ENCPED_porcedes: Number(encPed.ENCPED_PORCEDES),
        p_ENCPED_grupo_25: encPed.ENCPED_GRUPO,
        p_ENCPED_referencia: encPed.ENCPED_REFERENCIA,
        p_ENCPED_fechavalidez: encPed.ENCPED_FECHAVALIDEZ,
        p_BOD_codigo: encPed.BOD_CODIGO,
        p_ENCPED_tipo: encPed.ENCPED_TIPO,
        p_ENCPED_tipodscto: encPed.ENCPED_TIPODSCTO,
        p_ENCPED_refcli: encPed.ENCPED_REFCLI,
        p_ENCPED_docref:encPed.ENCPED_DOCREF,//CDPJ
        p_ENCPED_nemonico: encPed.ENCPED_NEMONICO,//CDPJ
        p_ENCPED_serie: encPed.ENCPED_SERIE,//CDPJ
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarDetalle(vendetped: VenDetped): Observable<any> {
    //  console.log(vendetped);
    return this.http.post(this.way + '/ventas/pedido/qjepkxoszso4ei3', {
      elementos: {
        p_DETPED_linea: vendetped.DETPED_LINEA,
        p_ENCPED_numero_1: vendetped.ENCPED_NUMERO,
        p_COM_codigo_2: '01',
        p_DETPED_tipodet_4: vendetped.DETPED_TIPODET,
        p_DETPED_codigo_5: vendetped.DETPED_CODIGO,
        p_DETPED_descripcion_6: vendetped.DETPED_DESCRIPCION,
        p_DETPED_tribiva_7: vendetped.DETPED_TRIBIVA,
        p_DETPED_tribice_8: vendetped.DETPED_TRIBICE,
        p_DETPED_unidad_9: vendetped.DETPED_UNIDAD,
        p_DETPED_cantidad_10: Number(vendetped.DETPED_CANTIDAD),
        p_DETPED_despacho_11: Number(vendetped.DETPED_DESPACHO),
        p_DETPED_precio_12: Number(vendetped.DETPED_PRECIO),
        p_DETPED_descuento_13: Number(vendetped.DETPED_DESCUENTO),
        p_DETPED_total_14: Number(vendetped.DETPED_TOTAL),
        p_DETPED_iva_15: Number(vendetped.DETPED_IVA),
        p_DETPED_ice_16: Number(vendetped.DETPED_ICE),
        p_DETPED_lista_17: vendetped.DETPED_LISTA,
        p_DETPED_baseiva_18: Number(vendetped.DETPED_BASEIVA),
        p_DETPED_baseice_19: Number(vendetped.DETPED_BASEICE),
        p_DETPED_basecero_20: Number(vendetped.DETPED_BASECERO),
        p_DETPED_porceice_21: Number(vendetped.DETPED_PORCEICE),
        p_DETPED_orden: Number(vendetped.DETPED_ORDEN),
        p_DETPED_numblo: Number(vendetped.DETPED_NUMBLO),
        p_BOD_CODIGO: vendetped.BOD_CODIGO,
        p_DETPED_CODALTERNO: vendetped.DETPED_CODALTERNO,
        p_DETPED_PROMOCION: vendetped.DETPED_PROMOCION,
        p_MAEPROVTA_CODIGO: vendetped.MAEPROVTA_CODIGO,
        p_detped_cantidadund: 0,
        p_detped_lote: vendetped.DETPED_LOTE,
        p_detped_caracteristicas: vendetped.DETPED_CARACTERISTICAS,
        p_detped_facturado: vendetped.DETPED_FACTURADO !== null ? Number(vendetped.DETPED_FACTURADO) : vendetped.DETPED_FACTURADO
      },
    }, this.confIniciales.httpOptions());
  }

  insertarDetalleProm(vendetped: VenDetped) {
    //  console.log(vendetped);
    return this.http.post(this.way + '/ventas/pedido/qjepkxoszso4ei3', {
      elementos: {
        p_DETPED_linea: vendetped.DETPED_LINEA,
        p_ENCPED_numero_1: vendetped.ENCPED_NUMERO,
        p_COM_codigo_2: '01',
        p_DETPED_tipodet_4: vendetped.DETPED_TIPODET,
        p_DETPED_codigo_5: vendetped.DETPED_CODIGO,
        p_DETPED_descripcion_6: vendetped.DETPED_DESCRIPCION,
        p_DETPED_tribiva_7: vendetped.DETPED_TRIBIVA,
        p_DETPED_tribice_8: vendetped.DETPED_TRIBICE,
        p_DETPED_unidad_9: vendetped.DETPED_UNIDAD,
        p_DETPED_cantidad_10: Number(vendetped.DETPED_CANTIDAD),
        p_DETPED_despacho_11: Number(vendetped.DETPED_DESPACHO),
        p_DETPED_precio_12: Number(vendetped.DETPED_PRECIO),
        p_DETPED_descuento_13: Number(vendetped.DETPED_DESCUENTO),
        p_DETPED_total_14: Number(vendetped.DETPED_TOTAL),
        p_DETPED_iva_15: Number(vendetped.DETPED_IVA),
        p_DETPED_ice_16: Number(vendetped.DETPED_ICE),
        p_DETPED_lista_17: vendetped.DETPED_LISTA,
        p_DETPED_baseiva_18: Number(vendetped.DETPED_BASEIVA),
        p_DETPED_baseice_19: Number(vendetped.DETPED_BASEICE),
        p_DETPED_basecero_20: Number(vendetped.DETPED_BASECERO),
        p_DETPED_porceice_21: Number(vendetped.DETPED_PORCEICE),
        p_DETPED_orden: Number(vendetped.DETPED_ORDEN),
        p_DETPED_numblo: Number(vendetped.DETPED_NUMBLO),
        p_BOD_CODIGO: vendetped.BOD_CODIGO,
        p_DETPED_CODALTERNO: vendetped.DETPED_CODALTERNO,
        p_DETPED_PROMOCION: vendetped.DETPED_PROMOCION,
        p_MAEPROVTA_CODIGO: vendetped.MAEPROVTA_CODIGO,
        p_detped_cantidadund: 0,
        p_detped_lote: vendetped.DETPED_LOTE,
        p_detped_caracteristicas: vendetped.DETPED_CARACTERISTICAS,
        p_detped_facturado: vendetped.DETPED_FACTURADO !== null ? Number(vendetped.DETPED_FACTURADO) : vendetped.DETPED_FACTURADO,
        p_cen_codigo:vendetped.CEN_CODIGO,
        p_detped_poriva:vendetped.DETPED_PORIVA,
        p_tarriiva_codigo:vendetped.TARIIVA_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetPed(detPed: VenDetped): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/vni7lyaruqnr4ip', {
      elementos: {
        p_ENCPED_numero_1: detPed.ENCPED_NUMERO,
        p_COM_codigo_2: '01',
        p_DETPED_linea_3: detPed.DETPED_LINEA
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarEnc(encPed: VenEncped): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/yee11d658b869al', {
      elementos: {
        p_ENCPED_numero_1: encPed.ENCPED_NUMERO,
        p_COM_codigo_2: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  insertarUsuidentificacion(usuario): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/xrllifzwjfcjrm8', {
      elementos: {
        USUIDENTIFICACION: usuario.USUIDENTIFICACION,
        ENCPED_NUMERO: usuario.ENCPED_NUMERO
      },
    }, this.confIniciales.httpOptions());
  }

  anularPedido(encped: VenEncped): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/kuaaq78nnv9l7tj', {
      elementos: {
        p_ENCPED_numero: encped.ENCPED_NUMERO,
        p_COM_CODIGO: '01',
        p_ENCPED_estado: 'A'
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerClave(): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pedido/9ykmoo1t58l7lyh', {
      elementos: {},

    }, this.confIniciales.httpOptions());
  }

  insertarEstadoPedido(encped: VenEncped, estado, razon): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/cihtpl6qqv8y8kt', {
      elementos: {
        p_ENCPED_numero: encped.ENCPED_NUMERO,
        p_estado: estado,
        p_razon: razon,
        p_empresa: '01',
        p_usuario: encped.USUIDENTIFICACION
      },
    }, this.confIniciales.httpOptions());
  }

  insertarEstadoPedidoAsync(encped, estado, razon) {
    return this.http.post<any[]>(this.way + '/ventas/pedido/cihtpl6qqv8y8kt', {
      elementos: {
        p_ENCPED_numero: encped.ENCPED_NUMERO,
        p_estado: estado,
        p_razon: razon,
        p_empresa: '01',
        p_usuario: encped.USUIDENTIFICACION
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerEstadoPedido(encped: VenEncped): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/b9vpa83yf6f6buf', {
      elementos: {
        PEDEST_ENCPEDNUMERO: encped.ENCPED_NUMERO
      },
    }, this.confIniciales.httpOptions());
  }

  consultarImagen(artCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/9nj9l6ql1h13d9x', {
      elementos: {
        ART_CODIGO: artCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  calcularTotal() {   
    console.log(this.dblPorcIvaPed[this.init.tabs[this.init.tabindex].indice]) ;
    let dblValorTotal: number;
    let dblCostoTotalItem = 0;
    let dblValorIceItem = 0;
    let dblBaseIceItem = 0;
    let dblBaseIceTotal = 0;
    let dblValorIceTotal = 0;
    let dblBaseCeroTotal = 0;
    let dblBaseIvaTotal = 0;
    let dblValorIvaTotal = 0;
    let dblValorNeto = 0;

    for (let fila = 0; fila < this.detpedidosarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const item = this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila];
      if (item.DETPED_TIPODET !== '*') {
        if (item.DETPED_CODIGO !== '' && item.DETPED_CODIGO !== null) {
          //dblCostoTotalItem = item.DETPED_CANTIDAD * item.DETPED_PRECIO;  
          //CDPJ
          let val=10 ** this.decimalesTotalPed;
          let total=Math.round(item.DETPED_CANTIDAD * val * item.DETPED_PRECIO) / val;
          dblCostoTotalItem = Number(total.toFixed(this.decimalesTotalPed));
          console.log('TOTALITEM',dblCostoTotalItem);
          //CDPJ        
          const dblDsct = dblCostoTotalItem * (item.DETPED_DESCUENTO / 100);
          dblCostoTotalItem = Number(dblCostoTotalItem) - Number(dblDsct);
          console.log('itemSR',dblCostoTotalItem);
          
          dblCostoTotalItem=this.redondearDecimales(dblCostoTotalItem);//CDPJ
          console.log('itemRD',dblCostoTotalItem);
          //item.DETPED_TOTAL = dblCostoTotalItem; // costo total por línea de detalle
          //item.DETPED_TOTAL = Number(item.DETPED_TOTAL).toFixed(this.decimalesTotalPed);
          //CDPJ
          //dblCostoTotalItem=this.redondearDecimales(dblCostoTotalItem)
          // item.DETPED_TOTAL = dblCostoTotalItem;
          item.DETPED_TOTAL =  Number(dblCostoTotalItem.toFixed(this.decimalesTotalPed));//CDPJ
          console.log('item',item.DETPED_TOTAL)
          //CDPJ
          // cálculo del ice por línea de detalle
          if (item.DETPED_TRIBICE !== null && item.DETPED_TRIBICE !== '') {
            if (item.DETPED_TRIBICE === 'S') { // si tributa ice
              dblBaseIceItem = item.DETPED_CANTIDAD * item.DETPED_PRECIO;
              item.DETPED_BASEICE = dblBaseIceItem;
              if (item.DETPED_PORCEICE > 0) {
                dblValorIceItem = dblBaseIceItem * item.DETPED_PORCEICE / 100;
              } else if (item.DETPED_ICE > 0) {
                dblValorIceItem = item.DETPED_ICE;
              }
              item.DETPED_ICE = dblValorIceItem;
            }
          }
          // càlculo del iva por línea de detalle
          let dblBaseIvaItem = 0;
          let dblValorIvaItem = 0;
          if (item.DETPED_TRIBIVA === 'S') {
            dblBaseIvaItem = dblCostoTotalItem * (1 - Number(this.dblPorcDescGlbPedarray[this.init.tabs[this.init.tabindex].indice]) / 100);
          console.log('desc,',this.dblPorcDescGlbPedarray[this.init.tabs[this.init.tabindex].indice])
          console.log('base',dblBaseIvaItem)
        } else {
            dblBaseIvaItem = 0;
          }
          if (item.DETPED_TRIBIVA === 'S') {
            if (item.DETPED_TRIBICE === 'S') {
             // dblBaseIvaItem = dblBaseIvaItem + item.DETPED_ICE;
              dblBaseIvaItem = dblBaseIvaItem ;//GSRF
            }
            item.DETPED_BASECERO = 0;
            dblValorIvaItem = dblBaseIvaItem * Number(item.DETPED_PORIVA) / 100; // IVA 12% DEFECTO

            item.DETPED_IVA = dblValorIvaItem;
          } else {
            item.DETPED_BASECERO = 0;
            dblBaseIvaItem = 0;
            item.DETPED_BASECERO = (dblCostoTotalItem * (1 - Number(this.dblPorcDescGlbPedarray[this.init.tabs[this.init.tabindex].indice]) / 100));
            item.DETPED_IVA = 0;
          }
          item.DETPED_BASEIVA = dblBaseIvaItem;
        }
      }

    }
    // TOTALES DEL DOCUMENTO
    for (let fila2 = 0; fila2 < this.detpedidosarray[this.init.tabs[this.init.tabindex].indice].length; fila2++) {

      dblValorNeto = Number(dblValorNeto) + Number(this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila2].DETPED_TOTAL);
      if (this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila2].DETPED_TIPODET !== '*') {
        if (this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila2].DETPED_TRIBICE === 'S') {
          dblValorIceTotal = dblValorIceTotal + Number(this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila2].DETPED_ICE * this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila2].DETPED_CANTIDAD);
          dblBaseIceTotal = dblBaseIceTotal + Number(this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila2].DETPED_BASEICE);
        }
      }
      dblBaseCeroTotal = dblBaseCeroTotal + Number(this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila2].DETPED_BASECERO);
      dblBaseIvaTotal = dblBaseIvaTotal + Number(this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila2].DETPED_BASEIVA);
      dblValorIvaTotal = dblValorIvaTotal + Number(this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila2].DETPED_IVA);

    }
    for (let fila = 1; fila <= this.detpedidosarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETPED_CANTIDAD = Number(
        this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETPED_CANTIDAD).toFixed(this.decimalesCantidadPed);
      this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETPED_PRECIO = Number(
        this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETPED_PRECIO).toFixed(this.decimalesPrecioPed);
      this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETPED_TOTAL = Number(
        this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETPED_TOTAL).toFixed(this.decimalesTotalPed);
      this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETPED_DESCUENTO = Number(
        this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETPED_DESCUENTO).toFixed(this.decimalesDescPed);
    }
    dblValorTotal = Number(dblValorNeto) + Number(dblValorIvaTotal) + Number(dblValorIceTotal) - Number(
      this.dblValorDescGlbPedarray[this.init.tabs[this.init.tabindex].indice]);
      console.log('Vneto',dblValorNeto)
      console.log('vIva',dblValorIvaTotal)
      console.log('descglobal',this.dblValorDescGlbPedarray[this.init.tabs[this.init.tabindex].indice])
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_TOTALNETO = String(dblValorNeto);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_TOTALNETO = Number(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_TOTALNETO).toFixed
      (this.confIniciales.getNumDecSist());
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORIVA = String(dblValorIvaTotal);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORIVA = Number(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORICE = String(dblValorIceTotal);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORICE = Number(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORICE).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASEICE = String(dblBaseIceTotal);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASEICE = Number(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASEICE).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASECERO = String(dblBaseCeroTotal);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASECERO = Number(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASECERO).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASEIVA = String(dblBaseIvaTotal);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASEIVA = Number(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_TOTAL = String(dblValorTotal);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_TOTAL = Number(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_TOTAL).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_PORCEDES =
      this.dblPorcDescGlbPedarray[this.init.tabs[this.init.tabindex].indice];
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_PORCEDES = Number(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_PORCEDES).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORDES =
      this.dblValorDescGlbPedarray[this.init.tabs[this.init.tabindex].indice];
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORDES = Number(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORDES).toFixed(this.confIniciales.getNumDecSist());
    // colocar los valores en los labels del pie de la factura
    this.comas();
  }

  obtenerConfig(): number {
    const PEDEXTNEG = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PEDEXTNEG').CFG_VALOR1;
    return PEDEXTNEG;
  }
  //GSRF  
  obtenerConfig2(): number {
    const PEDNOMAXEX = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PEDNOMAXEX').CFG_VALOR1;
    return PEDNOMAXEX;
  }
  //GSRF
   //CDPJ
   redondearDecimales(num) {
    let val=0
    //console.log(num);
    val=(Math.round((num + Number.EPSILON) *100)/100);
    val=Number(Number(val).toFixed(this.decimalesTotalPed));
    //console.log(val.toFixed(2))
    //console.log('>><<<<<',total)
    return val;
  }
  //CDPJ

  getConfDescMaxCli(): number {
    const DESMAXCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESMAXCLI').CFG_VALOR1;
    return DESMAXCLI;
  }

  existenciaActualArticulo(strArtCodigo, strBodCodigo): Observable<any[]> {

    const fecha = new Date();
    const hora = fecha.getHours();
    const min = fecha.getMinutes();
    const horaAct = hora + ':' + min;

    if (this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_FECHAEMISION.toString().includes('-')) {
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_FECHAEMISION = this.datePipe.transform(
        this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_FECHAEMISION,
        'dd/MM/yyyy');
    }

    return this.http.post<any[]>(this.way + '/ventas/pedido/q27kserp0jz40t4', {

      elementos: {
        ArtCodigo: strArtCodigo,
        BodCodigo: strBodCodigo,
        ENCPED_FECHAEMISION: this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_FECHAEMISION,
        HoraAct: horaAct
      },
    }, this.confIniciales.httpOptions());

  }


  calculaDscto(valor, total, isValue) {
    if (total === 0.0 && valor === 0.0) {
      return 0.0;
    }
    if (valor > 0.0 && total === 0.0) {
      return 0.0;
    }
    if (isValue) {
      return (valor / total) * 100.0;
    }
    return total * (valor / 100.0);
  }

  comas() {
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_TOTALNETO = this.init.moneyValidation(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_TOTALNETO);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORIVA = this.init.moneyValidation(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORIVA);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORICE = this.init.moneyValidation(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORICE);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASEICE = this.init.moneyValidation(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASEICE);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASECERO = this.init.moneyValidation(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASECERO);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASEIVA = this.init.moneyValidation(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_BASEIVA);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_TOTAL = this.init.moneyValidation(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_TOTAL);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_PORCEDES = this.init.moneyValidation(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_PORCEDES);
    this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORDES = this.init.moneyValidation(
      this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_VALORDES);
  }


  erGetClientCodigo(codigo) {
    return this.http.post(this.way + '/ventas/pedido/7ad9kdebcykxhg4', {
      elementos: {
        CLI_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }


  getConfiguraciones(codigo): number {
    const valorConfig = this.hmpConfiguracion.find(element => element.CFG_CODIGO === codigo).CFG_VALOR1;
    if (valorConfig === undefined) {
      return 0;
    } else {
      return valorConfig;
    }

  }
  //GSRF
  getConfiguraciones2(codigo): number {
    const valorConfig = this.hmpConfiguracion.find(element => element.CFG_CODIGO === codigo).CFG_VALOR2;
    if (valorConfig === undefined) {
      return 0;
    } else {
      return valorConfig;
    }

  }
  //GSRF

  consultarComponentes(strArtCodigo, strBodCodigo, strListaPadre) {
    return this.http.post<any[]>(this.way + '/ventas/pedido/3317e7aacc5b38', {
      elementos: {
        ArtCodigo: strArtCodigo,
        ListaPadre: strListaPadre,
        BodCodigo: strBodCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  async crearNuevoItemServ(): Promise<VenDetped> {
    const cenodigo=await this.getbodcencodigoprom(this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].BOD_CODIGO)
    let intCCPIPED=Number(this.getConfiguraciones('CCPIPED'));
    if(isNaN(intCCPIPED) === true || intCCPIPED === null || intCCPIPED === undefined){
      intCCPIPED=0
    }
    const strCodBodega = this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].BOD_CODIGO;
    const detped: VenDetped = {};
    detped.ENCPED_NUMERO = '';
    detped.COM_CODIGO = '01';
    detped.LINEA = this.detpedidosarray[this.init.tabs[this.init.tabindex].indice].length + 1;
    detped.DETPED_LINEA = 0;
    detped.DETPED_TIPODET = '';
    detped.DETPED_CODIGO = '';
    detped.DETPED_DESCRIPCION = '';
    detped.DETPED_UNIDAD = '';
    detped.DETPED_CANTIDAD = '';
    detped.DETPED_DESPACHO = '';
    detped.DETPED_PRECIO = '';
    detped.DETPED_DESCUENTO = 0;
    detped.DETPED_TOTAL = 0;
    detped.DETPED_TRIBIVA = '';
    detped.DETPED_IVA = 0;
    detped.DETPED_TRIBICE = '';
    detped.DETPED_ICE = 0;
    detped.DETPED_PORCEICE = '';
    detped.DETPED_LISTA = this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_LISTA,
      detped.DETPED_BASEIVA = 0;
    detped.DETPED_BASEICE = 0;
    detped.DETPED_BASECERO = 0;
    detped.DETPED_ORDEN = 0;
    detped.DETPED_NUMBLO = 0;
    detped.BOD_CODIGO = strCodBodega;
    detped.DETPED_CANTIDADPED = '';
    detped.DETPED_CODALTERNO = '';
    detped.DETPED_PROMOCION = '';
    detped.MAEPROVTA_CODIGO = '';
    detped.DETPED_RELACION = '';
    detped.DETPED_ZONACONTROL = '';
    detped.DETPED_UBICACION = '';
    detped.DETPED_CARACTERISTICAS = '';
    detped.DETPED_POSICION = '';
    detped.ENCPED_IVA = this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_IVA;
    detped.PRECIO = '';
    detped.ART_MULTIUNIDAD = 'N';
    detped.DETPED_LOTE = '';
    detped.DETPED_FACTURADO = Number(0).toFixed(2);
    console.log('intCCPIPED',intCCPIPED)
    console.log('cenodigo',cenodigo)
    console.log('cenodigo[0].CEN_CODIGO',cenodigo[0].CEN_CODIGO)
    
    if(intCCPIPED === 1){
      if(cenodigo === null || cenodigo === undefined){
        detped.CEN_CODIGO='';
      }else{
        detped.CEN_CODIGO=cenodigo[0].CEN_CODIGO === null || cenodigo[0].CEN_CODIGO === undefined?'':cenodigo[0].CEN_CODIGO;
      }            
    }else{
      detped.CEN_CODIGO='';
    }
    
    console.log('detped.CEN_CODIGO',detped.CEN_CODIGO)
    return detped;
  }

  consultarArticuloSel(strCodArticulo, strLista, strBodCodigo) {
    const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(new Date(), 'HH:mm');

    return this.http.post<any[]>(this.way + '/ventas/pedido/662fc56fbc755c0', {
      elementos: {
        LISTA: strLista,
        BOD_CODIGO: strBodCodigo,
        strFecha: strFecha + '',
        strHora: strHora + '',
        ART_CODIGO: strCodArticulo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async obtenerCantidadArticuloCompuesto(strArtCodigo, dblCantidadArtPadre, strBodCodigo) {
    let dblCantidad = 0;
    const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(new Date(), 'HH:mm');

    const rs = await this.obtenerExistenciaBodega(strArtCodigo, strBodCodigo,
      strFecha, strHora);

    dblCantidad = rs[0].EXISTENCIA;

    if (dblCantidad < 0) {
      dblCantidad = 0;
    } else {
      if (dblCantidadArtPadre <= dblCantidad) {
        dblCantidad = dblCantidadArtPadre;
      }


    }

    return dblCantidad;
  }

  obtenerExistenciaBodega(strArtCodigo, strBodCodigo, strFecha, strHora) {
    return this.http.post<any[]>(this.way + '/ventas/pedido/q27kserp0jz40t4', {
      elementos: {
        ArtCodigo: strArtCodigo,
        BodCodigo: strBodCodigo,
        ENCPED_FECHAEMISION: strFecha,
        HoraAct: strHora
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  obtenerDatoComponente(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/pedido/c2fad57598b6700', {
      elementos: {
        ART_CODIGO: strArtCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  async FunGlbCambiaCantArtConCompFac(intFila) {
    // cuando cambia cantidad en un articulo compuesto debe tambien
    // multiplicar por
    // los hijos por la cantidad correspondientes.

    let intIndiceLista = 0;
    let item: VenDetped = {};
    let rs = null;
    let dblCantidadHijo = 0;
    let dblCantidadPadre = 0;
    let dblExistArt = 0;
    let listaComponentes: ProdMaeartparte[] = [];



    item = this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][intFila];
    if (item.DETPED_TIPODET !== 'Z') {
      return;
    }


    dblCantidadPadre = item.DETPED_CANTIDAD;

    // Obtiene los datos de los componentes
    listaComponentes = [];


    try {
      rs = await this.obtenerDatoComponente(item.DETPED_CODIGO);

      for (const item of rs) {
        const itemComp: ProdMaeartparte = {};

        itemComp.ART_CODIGO = item.ART_CODIGO;
        itemComp.ART_CODIGOP = item.ART_CODIGOP;
        itemComp.ARTPARTE_CANTIDAD = item.ARTPARTE_CANTIDAD;
        itemComp.ARTPARTE_TOTALART = item.ARTPARTE_TOTALART;
        itemComp.UNI_CODIGO = item.UNI_CODIGO;
        listaComponentes.push(itemComp);
      }

    } catch (err) {

    }

    for (intIndiceLista = intFila; intIndiceLista < this.detpedidosarray[this.init.tabs[this.init.tabindex].indice].length; intIndiceLista++) {
      item = this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][intIndiceLista];
      if (item.DETPED_TIPODET === 'Y') {

        let intIndiceComp = 0;
        let itemComp: ProdMaeartparte;

        for (intIndiceComp = 0; intIndiceComp < listaComponentes.length; intIndiceComp++) {
          itemComp = listaComponentes[intIndiceComp];
          if (itemComp.ART_CODIGOP === item.DETPED_CODIGO) {
            dblCantidadHijo = dblCantidadPadre * itemComp.ARTPARTE_CANTIDAD;

            this.detpedidosarray[this.init.tabs[this.init.tabindex].indice][intIndiceLista].DETPED_CANTIDAD = dblCantidadHijo;
          }// fin si es el componente

        }// fin for

      }
    }

  }

  async obtenerDsctoMinCliente(strCliCodigo) {
    let dblDscto = 0;
    let rs = null;



    try {
      rs = this.http.post<any[]>(this.way + '/ventas/pedido/30beb2b2e35dc60000', {
        elementos: {
          CLI_CODIGO: strCliCodigo
        },
      }, this.confIniciales.httpOptions()).toPromise();

      for (const desc of rs) {
        dblDscto = desc.CLI_DESCUENTO;
      }

    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }

    return dblDscto;
  }

  async obtenerDsctoMaxCliente(strCliCodigo) {
    let dblDscto = 0;
    let rs = null;

    try {
      rs = this.http.post<any[]>(this.way + '/ventas/pedido/185f5aee565665000', {
        elementos: {
          CLI_CODIGO: strCliCodigo
        },
      }, this.confIniciales.httpOptions()).toPromise();

      for (const desc of rs) {
        dblDscto = desc.CLI_DESCUENTOLIM;
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }

    return dblDscto;
  }

  async obtenerDescuentoArticulo(strArtCodigo, strFecha) {
    let dblDsctoArt = 0;
    let rs = null;


    try {
      rs = this.http.post<any[]>(this.way + '/ventas/pedido/c2fad7718adf680', {
        elementos: {
          ART_CODIGO: strArtCodigo,
          FECHA: strFecha
        },
      }, this.confIniciales.httpOptions()).toPromise();

      for (const desc of rs) {
        dblDsctoArt = desc.MAEDSCTO_PORCENTAJE;
      }
    }
    catch (err) {
      this.mensajeAlerta('error', 'Error', 'No se puede obtener el descuento del artículo ' + err.error);
    }
    return dblDsctoArt;
  }

  async verificarDscto(item: VenDetped) {
    const intDESCLINAUT = Number(this.getConfiguraciones('DESCLINAUT'));
    if (intDESCLINAUT === 1) {
      // controlar el descuento de acuerdo al cliente
      let dblDsctoMinCli = await this.obtenerDsctoMinCliente(this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
      const dblDsctoMaxCli = await this.obtenerDsctoMaxCliente(this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);

      if (dblDsctoMaxCli == 0) {
        if (dblDsctoMinCli > 0) {
          if (item.DETPED_DESCUENTO > dblDsctoMinCli) {
            this.mensajeAlerta('info', 'Información', 'El descuento es mayor al asignado al cliente');
            item.DETPED_DESCUENTO = dblDsctoMinCli;
          }
        }
      } else {
        if (item.DETPED_DESCUENTO > dblDsctoMaxCli) {
          this.mensajeAlerta('info', 'Información', 'El descuento es mayor al asignado al cliente');
          item.DETPED_DESCUENTO = dblDsctoMaxCli;
        }
      }
    } else {
      const dblDsctoArt = await this.obtenerDescuentoArticulo(item.DETPED_CODIGO, this.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_FECHAEMISION);
      if (dblDsctoArt > 0) {
        if (dblDsctoArt < item.DETPED_DESCUENTO) {
          this.mensajeAlerta('info', 'Información', 'El descuento es mayor al asignado en el artículo');
          item.DETPED_DESCUENTO = dblDsctoArt;
        }
      }
    }




  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'pedido',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  obtenernumsig(Grupo) {
    return this.http.post(this.way + '/ventas/pedido/185f5d776efeb7000', {
      elementos: {
        Grupo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarnumeracion(Clase, DocRef, Nemonico, Serie) {
    return this.http.post(this.way + '/produccion/ordenprod/299df2ab2', {
      elementos: {
        p_NUM_clase: Clase,
        p_COM_codigo: '01',
        p_NUM_docref: DocRef,
        p_NUM_nemonico: Nemonico,
        p_NUM_serie: Serie
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  verificarActualizarEstPed(encpednumero) {
    return this.http.post<any>(this.way + '/ventas/facturas/4yruu4q43shk64r', {
      elementos: {
        p_ENCPED_numero: encpednumero,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarInfoArticulo(codigoarticulo) {
    return this.http.post<any>(this.way + '/ventas/pedido/41dd95df856fb400000', {
      elementos: {
        codigoarticulo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getestadoactual(lblCodigo) {
    return this.http.post<any>(this.way + '/ventas/pedido/41dd95df856fb400000', {
      elementos: {
        lblCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getArticuloVeh(artcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/41dd56fb267ab8', {
      elementos: {
        C: 'C',
        ART_CODIGO: artcodigo
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerEstadoPedidoAsync(encped: VenEncped) {
    return this.http.post<any[]>(this.way + '/ventas/pedido/b9vpa83yf6f6buf', {
      elementos: {
        PEDEST_ENCPEDNUMERO: encped.ENCPED_NUMERO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerestadosespeciales(ESTADOACTUAL) {
    return this.http.post<any>(this.way + '/ventas/pedido/20eeb3165e1dce00000000000000', {
      elementos: {
        ESTADOACTUAL
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerencpedestado(ENCPED_NUMERO) {
    return this.http.post<any>(this.way + '/ventas/pedido/41dd75d99735dc00000', {
      elementos: {
        ENCPED_NUMERO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ///////GSRF
  obtenerguiaremision(ENCPED_NUMERO) {
    return this.http.post<any>(this.way + '/ventas/pedido/41dd87a4adbad400000', {
      elementos: {
        ENCPED_NUMERO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerfactura(ENCPED_NUMERO) {
    return this.http.post<any>(this.way + '/ventas/pedido/20eebd4cefb6a', {
      elementos: {
        ENCPED_NUMERO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  verificaEstadoPedidos(ENCPED_NUMERO): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/297942eugs3a68e', {
      elementos: {
        p_ENCPED_numero: ENCPED_NUMERO,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  ///////GSRF
  //CDPJ
  getbodcencodigoprom(bodcodigo){
    return this.http.post<any>(this.way + '/ventas/pedido/20eeaf0d7414c8000000000', {
      elementos: {
        p_bodcodigo:bodcodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/ventas/pedido/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  desglobalCliente(clicodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/cc5f9eaf55b6b800000000000000000', {
      elementos: {
        cli_codigo: clicodigo,
        com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erBodCodigo1(strCodigo) {
    return this.http.post(this.way + '/ventas/pedido/76d786b30d94300', {
      elementos: {
        BOD_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerEncpedEstado(encped) {
    return this.http.post<any[]>(this.way + '/ventas/pedido/617d75ddb75d980000000000', {
      elementos: {
        p_encpednumero: encped
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  EncontrarCliente(strclicodigo) {
    return this.http.post(this.way + '/ventas/pedido/75d98bf76adb2c000000', {
      elementos: {
        cli_codigo: strclicodigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getnumG(strtipodoc) {
    return this.http.post<any[]>(this.way + '/ventas/pedido/41ddbfad0', {
      elementos: {
        NUM_DOCREF: strtipodoc,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  consultarpedpendiente() {
    return this.http.post<any[]>(this.way + '/ventas/pedido/cc5f9eaf55bcb8000000000000', {
      elementos: {
        com_codigo: '01',
        estado: 'P'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerClaveanula(): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pedido/9ykmoo1t58l7lan1pe', {
      elementos: {},

    }, this.confIniciales.httpOptions());
  }
  obtenerserie(): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pedido/c2faebbb7c76e80', {
      elementos: {},

    }, this.confIniciales.httpOptions());
  }
  obtenerbasesiva(numero) {
    return this.http.post<any>(this.way + '/ventas/pedido/107756ae3b92fb00000000', {
      elementos: {
        p_ped_numero:numero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizabasesiva(numero) {
    return this.http.post<any>(this.way + '/ventas/pedido/14cee5cd5ab8ee0000', {
      elementos: {
        p_encped_numero:numero,
        p_com_codigo:'01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerSaldoCliente(strCodliente): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/617d75ddbe2aac0000000000', {
      elementos: {
        CODCLIENTE: strCodliente,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  obtenerLimiteCliente(strCodliente): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/guof6mvp1m2bay1', {
      elementos: {
        CODCLIENTE: strCodliente,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  obtenerClave2(clave): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pagocontado/jwn7qmo1cc3b6ih', {
      elementos: {},
      datos: {
        Clave: clave
      }
    }, this.confIniciales.httpOptions());
  }
  obtenerLimicrediCliente(strCodliente): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedido/617d75ddbacad4000000000000000', {
      elementos: {
        CODCLIENTE: strCodliente,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  seleccionarRegistro(tipo, encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pedido/1c755cc64b1757000000000000000000', {
      elementos: {
        p_tipo: tipo,
        p_com_codigo: '01',
        p_encped_numero: encpednumero
      }
    }, this.confIniciales.httpOptions());
  }
  getEncped(encpednumero): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/pedido/1c755cc64b1757000000000000000000', {
      elementos: {
        p_tipo: 'R',
        p_com_codigo: '01',
        p_encped_numero: encpednumero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consultarusutoken(): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/pedido/662fcf57aaf73c000000', {
      elementos: {
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
