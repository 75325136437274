import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class SacifrmenviocorreosService {
  public way: string;
  public parametroIde:string
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService,private datePipe: DatePipe,) {

    this.way = sessionStorage.getItem('way');
}

  CargarfrmsCorreos(origen,strliquicom): Observable<any> {
    // console.log('saci_pck_maecfngimpr_c.saci_sp_consultarMaeCfngImprT(' + codigo +
    //   ',' + empresa +
    //   ',' + opcion + ')');
    return this.http.post<any>(this.way + '/advantage/formcorreos/41dd7e376e331c00000', {
      elementos: {
        p_encenviocor_origen: origen,
        p_encenviocor_liquiret:strliquicom === null || strliquicom === undefined?'':strliquicom
      },
    }, this.confIniciales.httpOptions());
  }
  consultaProcesoRep(codigo, empresa): Observable<any> {
    return this.http.post<any>(this.way + '/advantage/formcorreos/41dd662fcf573c0000000000', {
      elementos: {
        p_codigo: codigo,
        p_empresa: empresa,
      },
    }, this.confIniciales.httpOptions());
  }
  consultacorreocomp() {
    return this.http.post<any>(this.way + '/advantage/formcorreos/1077598dedd86600000000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  generarproceso(repcod, valores,valr,valparams,strform) {
    this.parametroIde = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    return this.http.post<any>(this.way + '/advantage/formcorreos/3a6e67baade77800000', {
      elementos: {
        p_repcod :repcod,
        p_valores :valores,
        p_filavr:valr,
        p_valparams:valparams,
        p_USUIDENTIFICACION:this.usuario.identificacion,
        p_PARAMETRO_IDE: this.parametroIde,
        p_strform:strform === undefined || strform === null?'':strform
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  generarcorreo(encviocorreo, origen, valcampo) {
    return this.http.post<any>(this.way + '/advantage/formcorreos/83aeeda998dee00', {
      elementos: {
        p_encenviocor_codigo:encviocorreo.ENCENVIOCOR_CODIGO,
        p_enviocorvar_codigo:encviocorreo.ENVIOCORVAR_CODIGO ,
        p_enviocorvar_origen: origen,
        p_valorcampo :valcampo,

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consultreporte(strValor){
    return this.http.post<any[]>(this.way + '/advantage/formcorreos/83bbbaab6c8cc00', {
        elementos: {
            rlcodigo: strValor
        }
      }, this.confIniciales.httpOptions()
      
    ).toPromise();
  }
  enviarCorreo(asunto,remitente,cuerpo01,cuerpo02,piecorreo,cliprovcod,nomarchivo,nomreporte,chklogo,repcod, valores,valr,valparams,strform,strNombreForma) {
    //console.log(archivo);
    const ubirep = sessionStorage.getItem('ubicacionReportes');
    let params = {};
    params = {
      COMCODIGO: '01',
      SUBREPORT_DIR: ubirep,
      NOMUSUARIO: this.usuario.identificacion,
      parametroIDE: this.parametroIde,
      querryString: '',
    }
    return this.http.post<any>(this.way + '/advantage/formcorreos/75952aabbd5324000000', {
      elementos: {
        p_asunto:asunto,
        p_remitente:remitente,
        p_cuerpo01:cuerpo01,
        p_cuerpo02:cuerpo02,
        p_piecorreo:piecorreo,
        p_cliprovcod :cliprovcod,
        p_nomarchivo :nomarchivo,
        p_reporte:nomreporte,
        p_chklog: chklogo === 1?true:false,
        p_repcod :repcod,
        p_valores :valores,
        p_filavr:valr,
        p_valparams:valparams,
        p_USUIDENTIFICACION:this.usuario.identificacion,
        p_PARAMETRO_IDE: this.parametroIde,
        p_strform:strform === undefined || strform === null?'':strform,
        p_strNombreForma:strNombreForma
      },
      parametros: params,
      excel: false
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarestadodocelec(strValor,strliquicom){
    return this.http.post<any[]>(this.way + '/advantage/formcorreos/533aee754dc370000000', {
        elementos: {
            numdoc: strValor,
            tipodoc:strliquicom === null || strliquicom === undefined?'':strliquicom
        }
      }, this.confIniciales.httpOptions()
      
    ).toPromise();
  }
  actualizarestadodocelecsacianex(strValor,strliquicom){
    return this.http.post<any[]>(this.way + '/advantage/formcorreos/a675b863aae6700000000', {
        elementos: {
            numdoc: strValor,
            tipodoc:strliquicom === null || strliquicom === undefined?'':strliquicom
        }
      }, this.confIniciales.httpOptions()
      
    ).toPromise();
  }
}
