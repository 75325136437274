import { Component, OnInit, ViewChild } from '@angular/core';

// Ag-grid
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ProdencordproService } from '../prodservicios/prodencordpro.service';
import { DatePipe } from '@angular/common';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { MessageService, SelectItem, ConfirmationService } from 'primeng';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { ProdDetordpro } from '../prodinterfaces/proddetordpro';
import { ProdEncordpro } from '../prodinterfaces/prodencordpro';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { ProdTrncostosind } from '../prodinterfaces/prodtrncostosind';
import { ProdTrnpedidos } from '../prodinterfaces/prodtrnpedidos';
import { ProdTrnliquidacion } from '../prodinterfaces/prodtrnliquidacion';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { Usuario } from 'src/app/usuario';
import { ProdTrnhorashombre } from '../prodinterfaces/prodtrnhorashombre';



@Component({
  selector: 'app-prodencprodproceso',
  templateUrl: './prodencprodproceso.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ProdencprodprocesoComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  hmpConfiguracion: any[];
  indicador: any;
  index: number;
  permisoOrdPro: SegMaePermiso;

  // barra
  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonFactura: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  botonNuevoL: boolean;
  botonGuardarL: boolean;
  botonBorrarL: boolean;
  botonRegresarL: boolean;

  // dialogs    
  displayDialogBusquedaFast: boolean;
  displayAcciones: boolean;
  displayDialogAutorizacion: boolean;
  displayDialogSupervisor: boolean;
  displayAsiContable: boolean;
  displayDialogReportes: boolean;
  displayDialogObservacion: boolean;
  displayControlCalidad: boolean;
  displayDialogLotes: boolean;
  displayDialogLotesMP: boolean;
  displayToken = false;
  spin = false;

  // checkbox
  chk: boolean;
  chbtnIngreso: boolean;
  chbtnInicio: boolean;
  chbtnTermino: boolean;
  chbtnVencLote: boolean;
  // dropdowns
  cmb: any[];
  select: any;
  cmbProcedimiento: any[];
  selectProcedimiento: any;
  cmbTipoProd: any[];
  // enabled
  enabledtxtReferencia: boolean;
  enabledcmbProceso: boolean;
  enabledcmbTipoProd: boolean;

  GRUPO: string;
  ESTADO: string;
  USUARIO: string;
  FECHA: string;
  HORA: string;
  tabItemLiquidacion = '';
  tabItemAdicionales = '';
  cmbProceso: any[];
  selectProceso: any;
  formula: string;
  cmbAsiento: any[];
  selectAsiento: any;
  asiento: string;
  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;
  consulta: string;

  vistxtgrupo: boolean;
  vistxtestado: boolean;
  detordproSelected: ProdDetordpro = {};
  trncostoindSelected: ProdTrncostosind;
  trnpedidoSelected: ProdTrnpedidos;

  trnliquidacionSelected: ProdTrnliquidacion;
  trnhorashombreSelected: ProdTrnhorashombre;
  loteSelected: any;

  str_NumOrdPro: any;
  globalNemonico: any;
  globalSerie: any;
  str_CONCALPRD: any;
  str_GENASIMC: any;
  auxiliar: number;
  auxiliar1: number;
  str_observacion: string;
  bolordenactivada: boolean;

  // reporte
  auxreporte: number = 0;
  strNombreForma: string;
  tokenusuario = '';
  auxiliarlot: number = 0;

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;

  // supervision
  usuarioSup: string;
  claveSup: string;
  strDialogo: string;
  strObjeto: string;
  intPestaniaCol: number;
  bolSupervisor: boolean;
  //AJUSTE
  totalajusteprod:number;
  totalentrada:number;
  totalsalida:number;
  boolliqui1:boolean;
  boolliqui2:boolean;
    // aggrid
    rowSelection = 'multiple';
    public frameworkComponents;
    rowStyle;
    defaultColDef;
    defaultColDefcostoind;
    defaultColDefhorashombre;
    defaultColDefpedido;
    defaultColDefliquidacion;
    defaultColDeftecnico;
    defaultColDefmaquinaria;
    defaultColDefLote;
    largo: string;
    largol: string;
  
    displayDialogDocumentos = false;
    columnDefs = [
      { // 0
        headerName: 'L', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 1
        headerName: 'T', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 2
        headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 85,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 3
        headerName: 'Código', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 110,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 4
        headerName: 'Descripción', field: 'DETORDPRO_DESCRIPCION', cellEditor: 'cellPrueba', width: 300,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 5
        headerName: 'Unidad', field: 'UNI_CODIGO', cellEditor: 'cellPrueba', width: 80,
        editable: (params) => {
          const existDesp = this.pruebaCambio(params.data);
          return existDesp;
        },
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 6
        headerName: 'Cantidad', field: 'DETORDPRO_CANTIDAD', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            tienecalculos: true
          }
        }
      },
      { // 7
        headerName: 'Costo', field: 'DETORDPRO_COSTO', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
        editable: false,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            tienecalculos: true
          }
        }
      },
      { // 8
        headerName: 'Total', field: 'DETORDPRO_TOTAL', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            tienecalculos: true
          }
        }
      },
      { // 9
        headerName: 'CodUnidadCosteo', field: 'UNI_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 10
        headerName: 'CodUnidaSalida', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 11
        headerName: 'ArtParFor', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 12
        headerName: 'Lote', field: 'DETORDPRO_LOTE', cellEditor: 'cellPrueba', width: 100,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 13
        headerName: 'Existencia', field: 'EXISTENCIA', cellEditor: 'cellPrueba', width: 110, cellStyle: { textAlign: 'right' },
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 14
        headerName: 'PPA', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      },
      { // 15
        headerName: 'CPPA', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
        // editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
      }
    ];
    
  columnDefscostoind = [
    { // 0
      headerName: 'L', field: '', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 1
      headerName: 'Fecha', field: 'TRNCOSTOSIND_FECHA', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 2
      headerName: 'Hora', field: 'TRNCOSTOSIND_HORA', cellEditor: 'cellPrueba', width: 85,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 3
      headerName: 'CodCostoEstandar', field: 'CEST_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 4
      headerName: 'Cod.CostoInd.', field: 'CIN_CODIGO', cellEditor: 'cellPrueba', width: 110,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 5
      headerName: 'Nombre', field: 'CIN_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 6
      headerName: 'Costo', field: 'TRNCOSTOSIND_COSTO', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      },
    },
    { // 7
      headerName: 'CodigoAuxiliar', field: 'CODIGO_AUXILIAR', cellEditor: 'cellPrueba', width: 110, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    {
      headerName: 'Sel.', field: 'CHECK',
      cellRendererFramework: AggridBanComponent,
      editable: false,
      width: 50,
      onCellClicked: (params) => {
        this.seleccionItem(params.data);
      }
    }
  ];

  columnDefspedido = [
    { // 0
      headerName: 'Pedido', field: 'ENCPED_NUMERO', cellEditor: 'cellPrueba', width: 150,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Referencia', field: 'ENCPED_REFERENCIA', cellEditor: 'cellPrueba', width: 130,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Fecha', field: 'ENCPED_FECHAEMISION', cellEditor: 'cellPrueba', width: 85,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 3
      headerName: 'CodigoAuxiliar', field: 'CODIGO_AUXILIAR', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 4
      headerName: 'Código', field: 'DETPED_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 5
      headerName: 'Nombre', field: 'DETPED_DESCRIPCION', cellEditor: 'cellPrueba', width: 110,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 6
      headerName: 'Cantidad', field: 'DETPED_CANTIDAD', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      },
    },
    { // 7
      headerName: 'Linea', field: 'DETPED_LINEA', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }];
  columnDefsliquidacion = [
    { // 0
      headerName: 'Fecha', field: 'TRNLIQ_FECHAES', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Tipo', field: 'TRNLIQ_TIPO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 85,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 3
      headerName: 'Código', field: 'TRNLIQ_CODIGO', cellEditor: 'cellPrueba', width: 110,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 4
      headerName: 'Descripción', field: 'TRNLIQ_DESCRIPCION', cellEditor: 'cellPrueba', width: 300,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 5	
      headerName: 'Cantidad', field: 'TRNLIQ_CANTIDAD', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    { // 6
      headerName: 'Entradas', field: 'TRNLIQ_ENTRADA', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      },
    },
    { // 7  
      headerName: 'Salidas', field: 'TRNLIQ_SALIDA', cellEditor: 'cellPrueba', width: 110, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 8
      headerName: 'Hora', field: 'TRNLIQ_HORA', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }, { // 9
      headerName: 'Número Ref', field: 'TRNLIQ_NUMERO', cellEditor: 'cellPrueba', width: 120,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }, { // 10   
      headerName: 'Cuenta Ref', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }, { // 11
      headerName: 'Nombre Cuenta', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', width: 150,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }
  ];
  constructor(
    public encordproService: ProdencordproService, public init: NuevoComponentService,
    private datePipe: DatePipe, private auditoriagral: AuditoriagralService,
    private messageService: MessageService, private confIniciales: ConfInicialesService,
    private permisosService: PermisosService, private busqService: InvbusquedaService,
    private confirmationService: ConfirmationService, private utilitariosService: UtilitariosService,
    public usuario: Usuario) {
    this.agTable();
   }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyle = (params) => {
      if (params.data.COLOREXISTENCIA === 'Y') {
        return { background: 'rgb(250, 163, 225)' };
      }
      if (params.data.COLORFORMULACION === 'Y') {
        return { background: 'rgb(133, 207, 37)' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'detordpro',
      resizable: true
    };

    this.defaultColDefcostoind = {
      editable: true,
      width: 100,
      objeto: 'trncostoind',
      resizable: true
    };


    this.defaultColDefpedido = {
      editable: true,
      width: 100,
      objeto: 'trnpedidos',
      resizable: true
    };

    this.defaultColDefliquidacion = {
      editable: true,
      width: 100,
      objeto: 'trnliquidacion',
      resizable: true
    };
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    const componente = this.init.tabs[this.init.tabindex - 1].component;
    this.tabItemLiquidacion = 'Liquidación de la Orden de Producción';
    this.totalajusteprod=0;
    this.boolliqui1=false;
    this.boolliqui2=true;
    this.index = 0;
    this.largo = '600';
    this.largol = '700';
    this.barraBotones2 = true;
    this.auxiliar = 0;
    this.auxiliar1 = 0;
    this.botonesInicio();

    this.vistxtgrupo = false;
    this.vistxtestado = false;

    this.encordproService.encordpro[this.indicador] = {};
    this.encordproService.detordpro[this.indicador] = [];
    this.encordproService.trncostosindirectos[this.indicador] = [];
    this.encordproService.trnpedidos[this.indicador] = [];
    this.encordproService.trnliquidacion[this.indicador] = [];
    this.encordproService.trntecnicos[this.indicador] = [];
    this.encordproService.trnhorashombre[this.indicador] = [];

    this.GRUPO = '';
    this.ESTADO = '';
    this.USUARIO = this.encordproService.usuario.identificacion;
    this.FECHA = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.HORA = this.datePipe.transform(new Date(), 'HH:mm');
    this.bolordenactivada = false;

    this.hmpConfiguracion = await this.auditoriagral.obtenerConfiguracionProm();
    this.encordproService.hmpConfiguracion = this.hmpConfiguracion;

    this.str_NumOrdPro = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMORDPRO').CFG_VALOR3;
    this.str_GENASIMC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENASIMC').CFG_VALOR1;

    this.confIniciales.getDecimales('frmPROD_ENCORDPRO').subscribe((dec) => {
      if (dec !== null) {
        for (const decimales of dec) {
          if (decimales.NUMDEC_TIPDOC === 'detordpro') {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.encordproService.decimalesCantidad = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'COSTO') {
              this.encordproService.decimalesCosto = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
              this.encordproService.decimalesTotal = Number(decimales.NUMDEC_NUMDEC);
            }
          }
          if (decimales.NUMDEC_TIPDOC === 'encordpro') {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.encordproService.decimalesCantEnc = Number(decimales.NUMDEC_NUMDEC);
            }
          }
          if (decimales.NUMDEC_TIPDOC === 'trncostosind') {
            if (decimales.NUMDEC_CAMPO === 'COSTO') {
              this.encordproService.decimalesCostoind = Number(decimales.NUMDEC_NUMDEC);
            }
          }
        }
        /*dec.map((decimales) => {
          if (decimales.NUMDEC_TIPDOC === 'detordpro') {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.encordproService.decimalesCantidad = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'COSTO') {
              this.encordproService.decimalesCosto = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
              this.encordproService.decimalesTotal = Number(decimales.NUMDEC_NUMDEC);
            }
          }
          if (decimales.NUMDEC_TIPDOC === 'encordpro') {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.encordproService.decimalesCantEnc = Number(decimales.NUMDEC_NUMDEC);
            }
          }
          if (decimales.NUMDEC_TIPDOC === 'trncostosind') {
            if (decimales.NUMDEC_CAMPO === 'COSTO') {
              this.encordproService.decimalesCostoind = Number(decimales.NUMDEC_NUMDEC);
            }
          }
        });*/
        // this.encordproService.decimalesTotal = this.confIniciales.getNumDecSist();
        this.columnDefs[6].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encordproService.decimalesCantidad,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.columnDefs[7].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encordproService.decimalesCosto,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.columnDefs[8].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encordproService.decimalesTotal,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.aggrid.refreshColumnDefs();
      }
    });

    this.llenarCombos();
    this.getPermisos(componente);
  }
  ngOnDestroy(): void {
    this.confIniciales.instanciasProdencordprodprocesos--;
  }
  async getPermisos(componente) {
    try {
      console.log(this.init.tabs[this.init.tabindex - 1].component)
      const data = await this.permisosService.getPermisosFrm(componente);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoOrdPro = data[0];
        } else {
          this.permisoOrdPro = null;
        }
      } else {
        this.permisoOrdPro = null;
      }
    } catch (e) {
      this.permisoOrdPro = null;
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }
  botonesInicio() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.botonFactura = false;
    this.botonAnular = true;
    this.botonXML = false;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.botonNuevoL = true;
    this.botonGuardarL = false;
    this.botonBorrarL = true;
    this.botonRegresarL = true;
  }
  llenarCombos() {
    this.cmbProcedimiento = [{ codigo: 'Producción', name: 'Producción' },
    { codigo: 'Envasado', name: 'Envasado' },
    { codigo: '', name: '' }];
    this.selectProcedimiento = { codigo: '', name: '' };

    this.cmbTipoProd = [];
    //this.cmbTipoProd.push({ codigo: 'F', name: 'Formulación' });
    this.cmbTipoProd.push({ codigo: 'P', name: 'Proceso' });
    this.encordproService.selectTipoProd[this.indicador] = { codigo: 'P', name: 'Proceso' };
    // const PROXPROC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PROXPROC').CFG_VALOR1;
    // if (PROXPROC === 1) {
    //   this.cmbTipoProd.push({ codigo: 'P', name: 'Proceso' });
    // }

    this.ingresaTiposProcesos();
  }
  async ingresaTiposProcesos() {
    const formulitas = [{ codigo: '', name: '' }];
    this.selectProceso = { codigo: '', name: '' };
    // const data = await this.encordproService.getTipoProceso(this.encordproService.encordpro[this.indicador].ART_CODIGO);
    // for (const rs of data) {
    //   formulitas.push({ codigo: rs.FOR_CODIGO, name: rs.FOR_CODIGO });
    // }
    this.cmbProceso = formulitas;
  }
  async manejarSenales(valor) {
    //CDPJ
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
        // Verifica el periodo contable
        const dia = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getDate();
        const mes = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getMonth();
        const anio = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getFullYear();
    
    
        if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
          this.messageService.add({
            key: 'encordpro',
            severity: 'info',
            summary: 'Información',
            detail: 'Error, fecha debe de estar dentro del período contable'
          });
          return;
        }
    }
    let strCodigo=0
    const detalle = await this.encordproService.verificarDetalleOrdProd(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO)
    if (detalle !== null && detalle !== undefined) {
      for (const rs of detalle) {
        strCodigo = rs.NUMERO;
      }
    }
    //CDPJ
    if (valor === 'Nuevo') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0 
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if(strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P'){
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.NuevaOrdenProduccion();
    }
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Cancelar') {
       //CDPJ
       if (this.encordproService.detordpro[this.indicador].length == 0 
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if(strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P'){
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.cancelar('boton');
    }
    if (valor === 'Primero') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0 
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if(strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P'){
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0 
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if(strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P'){
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0 
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if(strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P'){
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0 
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if(strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P'){
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0 
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if(strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P'){
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.busquedaorden();
    }
    // if (valor === 'IngresarProducto') {
    //   this.IngresarProductoTerminado();
    // }
    if (valor === 'BorrarAsiento') {
      this.EliminarAsientoContable();
    }
    if (valor === 'Asiento') {
      this.GenerarAsientoContable();
    }
    if (valor === 'AsientoVer') {
      this.verAsiento();
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'ClaseNumeracion') {
      this.GRUPO = '';
    }
    if (valor === 'Salir') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0 
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if(strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        &&  this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P'){
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (valor === 'Almacenardoc') {
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'encordpro',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    //CDPJ
    if (valor === 'Anular') {
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'I'
        || this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'T') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Solo se pueden anular Ordenes de Producción en estado PENDIENTE'
        });
        return;
      } else {
        this.confirmationService.confirm({
          message: 'Está seguro de anular el registro?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarencordpro',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: async () => {
            this.confirmationService.close();
            await this.anularOrdProd();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      }
    }
    //CDPJ
  }
  async anularOrdProd() {
    try {
      let asiento=''
      if (this.cmbAsiento.length > 0) {
        this.selectAsiento = this.cmbAsiento[0];
        asiento=this.selectAsiento.codigo
       } else {
        asiento =''
      //   this.messageService.add({
      //     key: 'encordpro',
      //     severity: 'warn',
      //     summary: 'Información',
      //     detail: 'El asiento contable está vacío'
      //   });
      //   return;
       }
      //console.log('asi', this.selectAsiento.codigo)
      await this.encordproService.anularOrdProdu(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO, asiento);
      this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO, 'AN',
        '', '01', '', '', '', '').subscribe();
        if (this.cmbAsiento.length > 0) {
          await this.encordproService.anularAsinro(asiento);
          this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + asiento, 'AN', '', '01', '', '', '', '').subscribe();
        }
        
      await this.cancelar('boton');
    } catch (error) {
      console.log('error',error)
    }
  }
  async NuevaOrdenProduccion() {
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO === '') { // Nuevo registro
      // Verifica los permisos
      if (this.permisoOrdPro !== null) {
        if (this.permisoOrdPro.PERTODO === 0) {
          if (this.permisoOrdPro.PERINSERCION === 0) {
            this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.encordproService.usuario.identificacion
                + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
    }

    this.enabledcmbTipoProd = true;
    this.enabledtxtReferencia = true;

    this.obtenerNumOrden();
    this.limpiarDatos();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA = '';
    this.USUARIO = this.encordproService.usuario.identificacion;
    this.FECHA = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.HORA = this.datePipe.transform(new Date(), 'HH:mm');

    const dbod = await this.encordproService.extraerCodigoBodega();
    for (const rs of dbod) {
      this.encordproService.encordpro[this.indicador].BOD_CODIGO = rs.REF_CODIGO === null ? '' : rs.REF_CODIGO;
    }
    this.botonesmodificar();
    document.getElementById('txtReferencia').focus();
  }
  async obtenerNumOrden() {
    let bolNum = false;
    let strNumActual = "";
    let strNemonico = "";
    let strSerie = "";

    if (this.str_NumOrdPro === 'G') { // numeración general (clase de doc)
    } else { // numeración por punto de venta      
      if (this.GRUPO === '') {
        this.busquedanumeracion();
      } else {
        strNumActual = await this.obtenerNumSiguiente();
        strSerie = this.globalSerie;
        strNemonico = this.globalNemonico;
        this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA = strNemonico + "" + strSerie + "" + strNumActual;
        this.enabledtxtReferencia = true;
      }
    }

    return bolNum;
  }
  busquedanumeracion() {
    this.opcionbusqueda = 'numeracion';
    this.types = [
      { label: 'Descripción', value: 'NUM_CLASE' },
      { label: 'Nemónico', value: 'NUM_NEMONICO' },
      { label: 'Serie', value: 'NUM_SERIE' },
      { label: 'Actual', value: 'NUM_SECACTUAL' }
    ];
    this.busquedacampos = ['', '', '', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAENUMERACION';
    this.where = '(NUM_DOCREF=\'ORP\') AND NUM_TIPO = \'C\'';

    this.busqService.busquedaSaciMaeNumeracion2().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  async obtenerNumSiguiente() {
    let strNumActual = "";
    const data: any = await this.encordproService.obtenernumsig(this.GRUPO);
    for (const rs of data) {
      strNumActual = rs.NUM_SECACTUAL;
    }
    return strNumActual;
  }
  async limpiarDatos() {
    this.encordproService.encordpro[this.indicador] = {};

    document.getElementById('contorno').style.pointerEvents = 'all';
    // document.getElementById('contornodetalle').style.pointerEvents = 'all';
    this.aggrid.habilitarGrid();

    this.botonAnular = true;//CDPJ ANTES FALSE AHORA TRUE

    this.encordproService.encordpro[this.indicador].ART_CODIGO = '';
    this.encordproService.encordpro[this.indicador].ART_NOMBRE = '';
    this.encordproService.encordpro[this.indicador].UNI_CODIGO = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_COMENTARIO = '';

    this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI = this.datePipe.transform(new Date(), 'HH:mm');
    this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN = this.datePipe.transform(new Date(), 'HH:mm');

    this.encordproService.encordpro[this.indicador].ENCPED_NUMERO = '';
    this.encordproService.encordpro[this.indicador].BOD_CODIGO = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO = 'P';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO = '';

    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION = new Date();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH = new Date();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO = new Date();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN = new Date();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE = new Date();

    this.chbtnIngreso = false;
    this.chbtnInicio = true;
    this.chbtnTermino = false;
    this.chbtnVencLote = false;
    this.selectProcedimiento = this.cmbProcedimiento[2];

    const dataest = await this.encordproService.getNombreEstado(this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO);
    for (const rs of dataest) {
      this.ESTADO = rs.REF_NOMBREC;
    }
    this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD = Number('1').toFixed(this.encordproService.decimalesCantEnc);

    this.encordproService.encordpro[this.indicador].BOD_CODIGO_ENT = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO = '';

    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA = new Date();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH = new Date();

    this.llenarCombos();
    /*if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') {
      this.enabledcmbProceso === true;
    } else {
      this.enabledcmbProceso === false;
    }*/

    this.encordproService.detordpro[this.indicador] = [];
    this.encordproService.trncostosindirectos[this.indicador] = [];
    this.encordproService.trnpedidos[this.indicador] = [];
    this.encordproService.trnliquidacion[this.indicador] = [];
    this.encordproService.trntecnicos[this.indicador] = [];
    this.encordproService.trnhorashombre[this.indicador] = [];
    /*		
		// limpiar la tabla
		tablaCostosInd.cerrarEditorSinGrabar();
		modeloCostosInd.getlstCostosInd().clear();
		tablaCostosInd.redraw();
		// limpiar la tabla
		tablaTecnicos.cerrarEditorSinGrabar();
		modeloTecnicos.getlstTrnTecnicos().clear();
		tablaTecnicos.redraw();
		// limpiar la tabla
		tablaMaquinaria.cerrarEditorSinGrabar();
		modeloMaquinaria.getlstTrnMaquinaria().clear();
		tablaMaquinaria.redraw();
		// limpiar la tabla
		tablaPedidos.cerrarEditorSinGrabar();
		modeloPedido.getlstPedidos().clear();
		tablaPedidos.redraw();
		// limpiar la Liquidacion
		tablaLiquidacion.cerrarEditorSinGrabar();
		modeloLiquidacion.getlstProdTrnliquid().clear();
		tablaLiquidacion.redraw();
		*/
    this.encordproService.encordpro[this.indicador].ENCORDPRO_LOTE = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_OPERARIO = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_EQUIPO = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TEMPERATURA = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_SOLVENTE = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_MEZCLA = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOSOLVENTE = '';

    this.cmbAsiento = [];
  }
  cambio(cambio) {
    if (cambio === true) {
      this.botonesmodificar();
    }
  }
  botonesmodificar() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;

  }
  async guardarRegistro() {
    // TODO Auto-generated method stub
    this.spin = true;
    if (this.index == 0) {
      await this.GuardarOrdenProduccion();
    }
    if (this.index == 1) {
      await this.GuardarCostosIndirectos();
    }
    // if (this.index == 2) {
    //   await this.GuardarHorasHombre();
    // }
    if (this.index == 2) {
      await this.GuardarPedido();
    }
    // if (this.index == 5) {
    //   await this.GuardarDatosAdicionales();
    // }
    // if (this.index == 6) {
    //   await this.GuardarTecnicos();
    // }
    // if (this.index == 7) {
    //   await this.GuardarMaquinaria();
    // }
    this.spin = false;
  }
  async GuardarOrdenProduccion() {

    let Codigo;
    let Cantidad = 0.0;
    let CtrlEliminaDet = 0;
    let cmdguardarTag = "";
    // Si el tipo de producción es por formulación entonces guarda en el kardex

    if (this.encordproService.selectTipoProd[this.indicador].codigo === "F") {
      if (this.encordproService.detordpro[this.indicador].length == 0) {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        this.spin=false;
        return;
      }
    }

    const dia = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getDate();
    const mes = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getMonth();
    const anio = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getFullYear();

    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'info',
        summary: 'Información',
        detail: 'Error, fecha debe de estar dentro del período contable'
      });
      this.spin=false;
      return;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'A') {
      this.spin=false;
      return;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO === '') {
      cmdguardarTag = "N"; // nuevo
    } else {
      cmdguardarTag = "M"; // modificación
    }
    const faltacampos = await this.FaltaCampos();
    const faltacamposd = await this.FaltaCamposD();
    // if (await this.verificarExistenciasArticulosOP() === false) {
    //   return;
    // }

    CtrlEliminaDet = 0;
   

    if (faltacampos === true) { // Valida que los datos de la cabecera esten ingresados
      if (faltacamposd === true) { // 'Valida los datos ingresados en el detalle
        if (cmdguardarTag === "N") { // Nuevo registro
          // Verifica los permisos
          if (this.permisoOrdPro !== null) {
            if (this.permisoOrdPro.PERTODO === 0) {
              if (this.permisoOrdPro.PERINSERCION === 0) {
                this.messageService.add({
                  key: 'encordpro',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'El usuario '
                    + this.encordproService.usuario.identificacion
                    + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
                });
                return;
              }
            }
          }
        } else { // Modifica registro
          // Verifica los permisos
          if (this.permisoOrdPro !== null) {
            if (this.permisoOrdPro.PERTODO === 0) {
              if (this.permisoOrdPro.PERACTUALIZACION === 0) {
                this.messageService.add({
                  key: 'encordpro',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'El usuario '
                    + this.encordproService.usuario.identificacion
                    + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
                });
                return;
              }
            }
          }
        }
      } else {
        return;
      }
    } else {
      return;
    }

    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') {
      this.encordproService.SumarTotal();
    } else {
      this.encordproService.SumarTotalXProceso();
    }

    // -------------------------------------------------
    // GRABA LA CABECERA DE LA ORDEN DE PRODUCCION
    // -------------------------------------------------

    const item: ProdEncordpro = {}

    item.ENCORDPRO_NUMERO = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;
    item.COM_CODIGO = '01';
    item.ART_CODIGO = this.encordproService.encordpro[this.indicador].ART_CODIGO;
    item.UNI_CODIGO = this.encordproService.encordpro[this.indicador].UNI_CODIGO;
    item.BOD_CODIGO = this.encordproService.encordpro[this.indicador].BOD_CODIGO;
    item.ENCORDPRO_TIPOPROC = this.encordproService.selectTipoProd[this.indicador].codigo === '' ? 'P' : this.encordproService.selectTipoProd[this.indicador].codigo;
    item.ENCORDPRO_ESTADO = this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO;
    const fechaemi = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
    const fechaemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');
    item.ENCORDPRO_FECHAEMISION = fechaemi + ' ' + fechaemih;
    if (this.chbtnInicio === true) {
      item.ENCORDPRO_FECHAINICIO = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI.toString().includes('-')) {
        item.ENCORDPRO_HORAINI = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI, 'HH:mm');
      } else {
        item.ENCORDPRO_HORAINI = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
      }
    } else {
      item.ENCORDPRO_FECHAINICIO = '';
      item.ENCORDPRO_HORAINI = '';
    }
    item.ENCORDPRO_CANTIDAD = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD);

    if (this.chbtnTermino === true) {
      item.ENCORDPRO_FECHAFIN = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
        item.ENCORDPRO_HORAFIN = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
      } else {
        item.ENCORDPRO_HORAFIN = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
      }
    } else {
      item.ENCORDPRO_FECHAFIN = '';
      item.ENCORDPRO_HORAFIN = '';
    }
    item.ENCORDPRO_COSTOTOTAL = '0';
    item.ENCORDPRO_COSTOUNITARIO = '0';
    item.ENCORDPRO_COMENTARIO = this.encordproService.encordpro[this.indicador].ENCORDPRO_COMENTARIO;
    item.ENCORDPRO_TOTALCOSTOMP = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP);
    item.ENCORDPRO_TOTALCOSTOIND = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND);
    item.ENCORDPRO_TOTALCOSTOPRM = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM);
    item.ENCORDPRO_TOTALPRODUCIDO = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO);
    item.ENCORDPRO_REFERENCIA = this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA;
    item.BOD_CODIGO_ENT = this.encordproService.encordpro[this.indicador].BOD_CODIGO_ENT;
    if (this.chbtnIngreso === true) {
      const fechaent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'dd/MM/yyyy');
      const fechaenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');
      item.ENCORDPRO_FECHAENTRADA = fechaent + ' ' + fechaenth;
    } else {
      item.ENCORDPRO_FECHAENTRADA = '';
    }
    // item.FOR_CODIGO = this.formula;
    //CDPJ
    item.FOR_CODIGO = this.formula;
    if(this.encordproService.selectTipoProd[this.indicador].codigo === 'P'&&(this.formula === null || this.formula === '' || this.formula === undefined)){
      item.FOR_CODIGO = '';
    }
    //CDPJ
    item.ENCPED_NUMERO = this.encordproService.encordpro[this.indicador].ENCPED_NUMERO;
    item.ENCORDPRO_TIPOPROD = this.encordproService.selectTipoProd[this.indicador].codigo;
    item.ENCORDPRO_LOTE = this.encordproService.encordpro[this.indicador].ENCORDPRO_LOTE;
    item.ENCORDPRO_OPERARIO = this.encordproService.encordpro[this.indicador].ENCORDPRO_OPERARIO;
    item.ENCORDPRO_EQUIPO = this.encordproService.encordpro[this.indicador].ENCORDPRO_EQUIPO;
    item.ENCORDPRO_PROCEDIMIENTO = this.leeTipoProceso();
    if (this.chbtnVencLote === true) {
      item.ENCORDPRO_VENCIMIENTOLOTE = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE, 'dd/MM/yyyy');
    } else {
      item.ENCORDPRO_VENCIMIENTOLOTE = '';
    }
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORASHOMBRE === ''
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_HORASHOMBRE === null
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_HORASHOMBRE === undefined) {
      item.ENCORDPRO_HORASHOMBRE = '';
    } else {
      item.ENCORDPRO_HORASHOMBRE = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORASHOMBRE);
    }

    const data: any = await this.encordproService.guardarencabezado(item).catch(e=>{
      this.spin=false;
    });

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO === '') { // Inserta un nuevo registro
      for (const rs of data) {
        if (this.str_NumOrdPro === 'C') {
          this.ActualizarNumero(this.GRUPO, 'ORP', this.globalNemonico, this.globalSerie);
        }
        this.enabledtxtReferencia = false;
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO = rs[':B3'];
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO+'/'+this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA, 'I',
          '', '01', '', '', '', '').subscribe();
        //GuardarCostosIndirectos();
        //ActualizaFechaCostoEstandar();
        //enabledcmbTipoProd = false;
      }
    } else {
      this.enabledtxtReferencia = false;
      this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO+'/'+this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA, 'A',
        '', '01', '', '', '', '').subscribe();
      //GuardarCostosIndirectos();
      //ActualizaFechaCostoEstandar();
    }


    // -------------------------------------------------
    // GRABA EL DETALLE
    // -------------------------------------------------

    for (const det of this.encordproService.detordpro[this.indicador]) {
      Codigo = det.ART_CODIGO;
      Cantidad = det.DETORDPRO_CANTIDAD;

      if (Codigo !== '') {
        const itemdet: ProdDetordpro = {};
        itemdet.ENCORDPRO_NUMERO = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;
        itemdet.COM_CODIGO = '01';
        itemdet.DETORDPRO_LINEA = det.DETORDPRO_LINEA;
        itemdet.ART_CODIGO = det.ART_CODIGO;
        itemdet.UNI_CODIGO = det.UNI_CODIGO;
        itemdet.BOD_CODIGO = det.BOD_CODIGO;
        itemdet.DETORDPRO_DESCRIPCION = det.DETORDPRO_DESCRIPCION;
        itemdet.DETORDPRO_CANTIDAD = Number(det.DETORDPRO_CANTIDAD);
        itemdet.DETORDPRO_COSTO = Number(det.DETORDPRO_COSTO);
        itemdet.DETORDPRO_TOTAL = Number(det.DETORDPRO_TOTAL);
        itemdet.DETORDPRO_LOTE = det.DETORDPRO_LOTE;
        itemdet.DETORDPRO_PPA = det.DETORDPRO_PPA;
        itemdet.DETORDPRO_CPPA = det.DETORDPRO_CPPA;
        itemdet.DETORDPRO_RENDIMIENTO = 0.0;
        const datadet: any = await this.encordproService.guardardetalle(itemdet).catch(e=>{
          this.spin=false;
        })

        for (const rsdet of datadet) {
          if (det.DETORDPRO_LINEA === 0) {
            det.DETORDPRO_LINEA = rsdet[':B1'];; // retorna el nuevo numero de línea
            this.auditoriagral.registrarAuditoria('PROD_DETORDPRO',
              this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + det.ART_CODIGO + '/', 'I',
              '', '01', '', '', '', '').subscribe();
          } else {
            this.auditoriagral.registrarAuditoria('PROD_DETORDPRO',
              this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + det.ART_CODIGO + '/', 'A',
              '', '01', '', '', '', '').subscribe();
          }
        }
      }
    }
    this.messageService.add({
      key: 'encordpro',
      severity: 'success',
      summary: 'Información',
      detail: 'Orden de producción guardada'
    });


    // Si el tipo de producción es por formulación entonces guarda en el kardex
    // if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') {
    //   this.GenerarKardex();
    // }

    console.log('this.GenerarAsientoContable();');
    await this.GenerarAsientoContable();

    if (this.chbtnTermino === true) {
      await this.ActualizaEstadoFactura("T", this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      // ActivarBoton("E");
    } else {
      await this.ActualizaEstadoFactura("P", this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      // ActivarBoton("G");
    }
    this.cancelar('auto');
  }
  async FaltaCampos() {
    const dia = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getDate();
    const mes = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getMonth();
    const anio = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getFullYear();
    const rsdata: any = await this.encordproService.encontrarArt(this.encordproService.encordpro[this.indicador].ART_CODIGO);
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, fecha debe de estar dentro del período contable'
      });
      this.spin=false//CDPJ
      return false;
    }
   
    let strCodigo = 0;
                if (rsdata !== null) {
                  for (const rs of rsdata) {
                    strCodigo = rs.NUMERO;
                  }
                }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA === '') { // Número de comprobante
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese el número del comprobante'
      });
      this.spin=false//CDPJ
      return false;
    }

    if (this.encordproService.encordpro[this.indicador].ART_CODIGO === '') { // Codigo del artículo
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese el artículo'
      });
      document.getElementById('txtArticulo').focus();
      this.spin=false//CDPJ
      return false;
    }

    if (this.encordproService.encordpro[this.indicador].UNI_CODIGO === '') { // Unidad
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese la Unidad del artículo'
      });
      document.getElementById('txtUnidad').focus();
      this.spin=false//CDPJ
      return false;
    }

    if (this.encordproService.encordpro[this.indicador].BOD_CODIGO === '') { // Bodega
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese la Bodega'
      });
      document.getElementById('txtBodegaMP').focus();
      this.spin=false//CDPJ
      return false;
    }

    if (this.encordproService.encordpro[this.indicador].BOD_CODIGO_ENT === '') { // Bodega
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese la Bodega en donde va a ingresar el producto terminado'
      });
      document.getElementById('txtBodegaPT').focus();
      this.spin=false//CDPJ
      return false;
    }
    if(strCodigo === 0){
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'El artículo '
          + this.encordproService.encordpro[this.indicador].ART_CODIGO
          + ', no existe.'
      });
      this.spin=false;
      return false;
    }
    if (this.chbtnInicio === true) {
      let tmpFechaIni = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'yyyy-MM-dd');
      let tmpHoraIni = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI.toString().includes('-')) {
        tmpHoraIni = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI, 'HH:mm');
      }

      const dateemi = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'yyyy-MM-dd');
      const dateemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');

      const FechaEmi = new Date(dateemi + ' ' + dateemih);
      const FechaIni = new Date(tmpFechaIni + 'T' + tmpHoraIni + ':00');

      if (FechaEmi > FechaIni) {
        console.log(FechaEmi);
        console.log('FechaEmi mayor > FechaIni');
        console.log(FechaIni);
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'La Fecha en que Inicia no puede ser MENOR a la Fecha de Emisión'
        });
        this.spin=false//CDPJ
        return false;
      }
    }

    if (this.chbtnInicio === true && this.chbtnTermino === true) {
      let tmpFechaIni = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'yyyy-MM-dd');
      let tmpFechaFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'yyyy-MM-dd');
      let tmpHoraIni = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
      let tmpHoraFin = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI.toString().includes('-')) {
        tmpHoraIni = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI, 'HH:mm');
      }
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
        tmpHoraFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
      }

      const FechaIni = new Date(tmpFechaIni + 'T' + tmpHoraIni + ':00');
      const FechaFin = new Date(tmpFechaFin + 'T' + tmpHoraFin + ':00');

      if (FechaIni > FechaFin) {
        console.log(FechaIni);
        console.log('FechaIni mayor > FechaFin');
        console.log(FechaFin);
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'La Fecha en que Termina no puede ser MENOR a la Fecha en que Inicia'
        });
        this.spin=false//CDPJ
        return false;
      }
    }

    if (this.chbtnIngreso === true && this.chbtnTermino === true) {

      let tmpFechaFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'yyyy-MM-dd');
      let tmpHoraFin = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
        tmpHoraFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
      }

      const dateent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'yyyy-MM-dd');
      const dateenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');

      const FechaFin = new Date(tmpFechaFin + 'T' + tmpHoraFin + ':00');
      const FechaEnt = new Date(dateent + ' ' + dateenth);

      if (FechaFin > FechaEnt) {
        console.log(FechaFin);
        console.log('FechaFin mayor > FechaEnt');
        console.log(FechaEnt);
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'La Fecha en que Ingresa el producto no puede ser MENOR a la Fecha en que Termina'
        });
        this.spin=false//CDPJ
        return false;
      }
    }
    return true;
  }
  async FaltaCamposD() {

    // Valida los campos ingresados en el detalle
    for (const item of this.encordproService.detordpro[this.indicador]) {
      const data: any = await this.encordproService.verificarexistearticulo(item.ART_CODIGO).catch(e=>{
        this.spin=false;
        return;
      })
      if (item.ART_CODIGO !== '') {

        if (item.BOD_CODIGO === "") { // BODEGA
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'Por Favor Ingrese La Bodega.'
          });
          this.spin=false//CDPJ
          return false;
        }

      
        // CODIGO DEL ARTICULO
        if (data === null) {
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El artículo '+ item.ART_CODIGO + ' no existe'
          });
          this.spin=false//CDPJ
          return false;
        } else {
          for (const rs of data) {
            if (rs.ART_CODIGO === '') {
              this.messageService.add({
                key: 'encordpro',
                severity: 'error',
                summary: 'Información',
                detail: 'El artículo '+ item.ART_CODIGO + ' no existe'
              });
              this.spin=false//CDPJ
              return false;
            }
          }
        }

        // UNIDAD DE MATERIA PRIMA
        if (item.UNI_CODIGO === "") { // BODEGA
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'Por Favor Ingrese La Unidad'
          });
          this.spin=false//CDPJ
          return false;
        }

        if (Number(item.DETORDPRO_CANTIDAD) === 0) { // BODEGA
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El artículo ' + item.ART_CODIGO + ' tiene cantidad 0.00'
          });
          this.spin=false//CDPJ
          return false;
        }
      } else {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese el artículo'
        });
        this.spin=false//CDPJ
        return false;
      }
    }
    return true;
  }
  leeTipoProceso(): string {
    let strtipProd = "";
    if (this.selectProcedimiento.codigo === '')
      strtipProd = "";
    if (this.selectProcedimiento.codigo === "Producción")
      strtipProd = "01";
    if (this.selectProcedimiento.codigo === "Envasado")
      strtipProd = "02";
    return strtipProd;
  }
  ActualizarNumero(Clase, DocRef, Nemonico, Serie) {
    this.encordproService.actualizarnumeracion(Clase, DocRef, Nemonico, Serie);
  }
  async GenerarAsientoContable() {

    if (this.encordproService.selectTipoProd[this.indicador].codigo === "F") { // Formulación // CDPJ SE CAMBIÓ ANTES this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOPROD
      if (this.str_GENASIMC === '1') {
        // ASIENTO REGISTRO DE ENVIO DE MATERIA PRIMA AL COSTO (DE
        // ACUERDO AL CODIGO DEL MAESTRO DE
        // INVENTARIO
        await this.encordproService.generarasiprocostos(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      }

      // ASIENTO DE PRODUCTO EN PROCESO
      await this.encordproService.generarasiordpro(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);

      // ASIENTO DE PRODUCTO TERMINADO
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'I') {
        await this.encordproService.generarasiordproi(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      }
    } else { // Si es por procesos
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'I') {
        await this.encordproService.generarasientoaju(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      }
    }

    this.DespliegaNumeroAsiento(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
  }
  async DespliegaNumeroAsiento(NumOrdPro) {
    this.cmbAsiento = [];
    let asientito = [];
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOPROD === "F") { // Formulación
      // Código el asiento de materia prima al costo
      if (this.chbtnInicio === true) {
        const mes = this.completarFecha(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO.getMonth() + 1);
        const data: any = await this.encordproService.getasientomateriaprima(NumOrdPro, mes);
        if (data !== null) {
          for (const rs of data) {
            asientito.push({ codigo: rs.ASI_NRO, name: rs.ASI_NRO });
          }
        }
      }
      // Código el asiento del producto en proceso
      if (this.chbtnInicio === true) {
        const mes = this.completarFecha(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO.getMonth() + 1);
        const data: any = await this.encordproService.getasientoproductoenproceso(NumOrdPro, mes);
        if (data !== null) {
          for (const rs of data) {
            asientito.push({ codigo: rs.ASI_NRO, name: rs.ASI_NRO });
          }
        }
      }
      // Código del asiento del producto terminado
      if (this.chbtnIngreso === true) {
        const mes = this.completarFecha(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA.getMonth() + 1);
        const data: any = await this.encordproService.getasientoproductoterminado(NumOrdPro, mes);
        if (data !== null) {
          for (const rs of data) {
            asientito.push({ codigo: rs.ASI_NRO, name: rs.ASI_NRO });
          }
        }
      }
    } else { // Por procesos
      // Código del asiento del producto terminado
      if (this.chbtnIngreso === true) {
        const mes = this.completarFecha(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA.getMonth() + 1);
        const data: any = await this.encordproService.getasientoproductoterminadoproceso(NumOrdPro, mes);
        if (data !== null) {
          for (const rs of data) {
            asientito.push({ codigo: rs.ASI_NRO, name: rs.ASI_NRO });
          }
        }
      }
    }

    this.cmbAsiento = asientito;
    if (this.cmbAsiento.length > 0) {
      this.selectAsiento = this.cmbAsiento[0];
    }
  }
  completarFecha(strFecha) {
    strFecha = strFecha.toString();
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
  }
  async ActualizaEstadoFactura(Estado, CodEncOrdPro) {
    this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO = Estado;
    const dataest = await this.encordproService.getNombreEstado(this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO);
    for (const rs of dataest) {
      this.ESTADO = rs.REF_NOMBREC;
    }
    // Actualiza el estado de la orden de producción y la fecha de la entrada
    await this.encordproService.actualizarestado(Estado, CodEncOrdPro);
  }
  async cancelar(flag) {
    this.spin = true;
    this.botonesInicio();
    await this.mostrarDatos(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO, flag);
    this.spin = false;
  }
  async mostrarDatos(CodOrdPro, flag) {
    this.spin=true//GSRF
    if (flag === 'boton') {
      this.bolordenactivada = false;
    }
    // DESPLIEGA LA CABECERA DE LA ORDEN DE PRODUCCION
    await this.limpiarDatos();
    document.getElementById('contorno').style.pointerEvents = 'all';
    // document.getElementById('contornodetalle').style.pointerEvents = 'all';
    this.aggrid.habilitarGrid();

    if (CodOrdPro === '' || CodOrdPro === undefined || CodOrdPro === null) {
      this.spin=false//GSRF
      return;
    }

    this.enabledtxtReferencia = false;
    const data = await this.encordproService.getEncordpro(CodOrdPro);
    for (const rs of data) {
      this.encordproService.encordpro[this.indicador] = rs;
    }

    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION);
    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION);
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA !== null) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA);
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA);
      this.chbtnIngreso = true;
    } else {
      this.chbtnIngreso = false;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO !== null) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO);
      this.chbtnInicio = true;
    } else {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO = new Date();
      this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI = this.datePipe.transform(new Date(), 'HH:mm');
      this.chbtnInicio = false;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN !== null) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN);
      this.chbtnTermino = true;
    } else {
      this.chbtnTermino = false;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_PROCEDIMIENTO !== null) {
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_PROCEDIMIENTO === '01') {
        this.selectProcedimiento = { codigo: '0', name: '0' }
      }
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_PROCEDIMIENTO === '02') {
        this.selectProcedimiento = { codigo: '1', name: '1' }
      }
    } else {
      this.selectProcedimiento = { codigo: '', name: '' }
    }

    this.ingresaTiposProcesos();
    if (this.encordproService.encordpro[this.indicador].FOR_CODIGO !== null) {
      this.selectProceso = { codigo: this.encordproService.encordpro[this.indicador].FOR_CODIGO, name: this.encordproService.encordpro[this.indicador].FOR_CODIGO }
      this.formula = this.selectProceso.codigo;
    }

    // Tipo de Produccion
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOPROD !== null) {
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOPROD === "F") {
        this.encordproService.selectTipoProd[this.indicador] = { codigo: 'F', name: 'Formulación' };
        this.enabledcmbProceso === true;
      } else {
        this.encordproService.selectTipoProd[this.indicador] = { codigo: 'P', name: 'Proceso' };
        this.enabledcmbProceso === false;
      }
    }

    this.enabledcmbTipoProd === false;
    //this.activarDesactivarTabsPorTipoProc();

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'I'
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'T'
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'A') {
      if (this.bolordenactivada === false) {
        document.getElementById('contorno').style.pointerEvents = 'none';
        this.aggrid.bloquearGrid();
        this.botonAnular = false;//CDPJ
      }
    }
    const dataest = await this.encordproService.getNombreEstado(this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO);
    for (const rs of dataest) {
      this.ESTADO = rs.REF_NOMBREC;
    }
    if (this.ESTADO === undefined || this.ESTADO === '' || this.ESTADO === null) {
      this.ESTADO = this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO;
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'La descripción para el código de estado ' + this.ESTADO + ' no existe'
      });
    }
    this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD =
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD).toFixed(this.encordproService.decimalesCantEnc);
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP =
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP).toFixed(this.confIniciales.getNumDecSist());
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND =
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND).toFixed(this.confIniciales.getNumDecSist());
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM =
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM).toFixed(this.confIniciales.getNumDecSist());
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO =
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO).toFixed(this.confIniciales.getNumDecSist());
    await this.modeloDetallelistarDatos(CodOrdPro);
    this.modeloCostosIndlistarDatos(CodOrdPro);
    // this.modeloTecnicoslistarDatos(CodOrdPro);
    // this.modeloMaquinarialistarDatos(CodOrdPro);
    this.modeloPedidolistarDatos(CodOrdPro);
    //this.modeloHorashombrelistarDatos(CodOrdPro);

    this.modeloDetalleVerificaSiEsParteFormulaAll();
    this.DespliegaNumeroAsiento(CodOrdPro);

    // Despliega Datos de la liquidación si fue realizada por procesos
    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'P') {
      this.modeloLiquidacionDesplegarDatosLiquidacion(CodOrdPro);
    }

    // const str_LOTORDPRO = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LOTORDPRO').CFG_VALOR1;
    // if (str_LOTORDPRO === "1") {
    //   this.desplegarLotesProd();
    // }
    /*
        if (txtEstado.getText().equals("P")) {
          ActivarBoton("E");
        } else if (txtEstado.getText().equals("T")) {
          ActivarBoton("T");
        } else if (txtEstado.getText().equals("I")) {
          ActivarBoton("I");
        } else if (txtEstado.getText().equals("A")) {
          ActivarBoton("A");
        }  */
        this.spin=false//GSRF
  }
  async modeloDetallelistarDatos(strNumOrd) {
    //this.removerColores();

    const FechaInicio = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
    const HoraInicia = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;

    const data = await this.encordproService.getDetordpro(FechaInicio, HoraInicia, strNumOrd);
    for (const rs of data) {
      rs.CLAVE = rs.DETORDPRO_LINEA;
      rs.DETORDPRO_CANTIDAD = Number(rs.DETORDPRO_CANTIDAD).toFixed(this.encordproService.decimalesCantidad);
      rs.DETORDPRO_COSTO = Number(rs.DETORDPRO_COSTO).toFixed(this.encordproService.decimalesCosto);
      rs.DETORDPRO_TOTAL = Number(rs.DETORDPRO_TOTAL).toFixed(this.encordproService.decimalesTotal);
      rs.EXISTENCIA = Number(rs.EXISTENCIA).toFixed(this.encordproService.decimalesCosto);
    }

    this.encordproService.detordpro[this.indicador] = data;
  }
  async modeloCostosIndlistarDatos(CodOrdPro) {
    const data: any = await this.encordproService.gettrncostosind(CodOrdPro);
    for (const rs of data) {
      rs.CLAVE = rs.DETORDPRO_LINEA;
      rs.TRNCOSTOSIND_FECHA = this.datePipe.transform(rs.TRNCOSTOSIND_FECHA, 'dd/MM/yyyy');
      rs.TRNCOSTOSIND_COSTO = Number(rs.TRNCOSTOSIND_COSTO).toFixed(this.encordproService.decimalesCostoind);
      rs.CODIGO_AUXILIAR = 'X';
      if (rs.TRNCOSTOSIND_INICIAL === 'S') {
        rs.CHECK = true;
      } else {
        rs.CHECK = false;
      }
    }
    this.encordproService.trncostosindirectos[this.indicador] = data;
  }
  async modeloPedidolistarDatos(CodOrdPro) {
    const data: any = await this.encordproService.gettrnpedidos(CodOrdPro);
    for (const rs of data) {
      rs.CLAVE = rs.DETPED_LINEA;
      rs.ENCPED_FECHAEMISION = this.datePipe.transform(rs.ENCPED_FECHAEMISION, 'dd/MM/yyyy');
      rs.DETPED_CANTIDAD = Number(rs.DETPED_CANTIDAD).toFixed(this.encordproService.decimalesCantidad);
      rs.CODIGO_AUXILIAR = 'X';
    }
    this.encordproService.trnpedidos[this.indicador] = data;
  }
  async modeloDetalleVerificaSiEsParteFormulaAll() {
    for (const item of this.encordproService.detordpro[this.indicador]) {
      let Sw = 0;
      let Codigo = item.ART_CODIGO; // Código del artículo      
      const data: any = await this.encordproService.verificarparteformula(this.encordproService.encordpro[this.indicador].ART_CODIGO, Codigo);
      if (data !== null) {
        for (const rs of data) {
          if (rs.CONTFORMULA !== 0) {
            Sw = 1; // Si el artículo pertenece a la fórmula
          }
          if (Sw == 0) { // Si no pertenece el artículo a la fórmula          
            item.ARTPARFOR = 'X';
            item.COLORFORMULACION = 'Y';
          } else {
            item.ARTPARFOR = '';
            item.COLORFORMULACION = '';
          }
        }
      }
    }
    this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);
  }
  async modeloLiquidacionDesplegarDatosLiquidacion(CodOrdPro) {
    try {
      let toten=0;
      let totsa=0
      const data = await this.encordproService.consultdetliquidacionprod(CodOrdPro);
      if (data !== null && data !== undefined && data.length>0){
        this.encordproService.trnliquidacion[this.indicador] = data;
        for (const reg of this.encordproService.trnliquidacion[this.indicador]){
          reg.TRNLIQ_FECHAES=this.datePipe.transform(reg.TRNLIQ_FECHAES,'dd/MM/yyyy');
          reg.TRNLIQ_CANTIDAD=Number(Number(reg.TRNLIQ_CANTIDAD).toFixed(this.encordproService.decimalesCantEnc));
          reg.TRNLIQ_COSTOT=Number(Number(reg.TRNLIQ_COSTOT).toFixed(this.encordproService.decimalesCosto));
          if(reg.TRNLIQ_TIPO==='SA' ){
            reg.TRNLIQ_SALIDA=Number(Number(reg.TRNLIQ_COSTOT).toFixed(this.encordproService.decimalesCosto));
            reg.TRNLIQ_ENTRADA=Number('0').toFixed(this.encordproService.decimalesCosto);
            totsa=totsa+reg.TRNLIQ_SALIDA;
          }else if(reg.TRNLIQ_TIPO==='EN'){
            reg.TRNLIQ_ENTRADA=Number(Number(reg.TRNLIQ_COSTOT).toFixed(this.encordproService.decimalesCosto));
            reg.TRNLIQ_SALIDA=Number('0').toFixed(this.encordproService.decimalesCosto);
          toten=toten+reg.TRNLIQ_ENTRADA;
          }
          
        }  
        this.totalajusteprod=Number(totsa)-Number(toten);
        this.totalentrada=Number(Number(toten).toFixed(this.encordproService.decimalesCosto))
        this.totalsalida=Number(Number(totsa).toFixed(this.encordproService.decimalesCosto))
        this.totalajusteprod=Number(Number(this.totalajusteprod).toFixed(this.encordproService.decimalesCosto));
        this.boolliqui1=true;
        this.boolliqui2=false;
      }
      this.totalajusteprod=Number(totsa)-Number(toten);
        this.totalentrada=Number(Number(toten).toFixed(this.encordproService.decimalesCosto))
        this.totalsalida=Number(Number(totsa).toFixed(this.encordproService.decimalesCosto))
        this.totalajusteprod=Number(Number(this.totalajusteprod).toFixed(this.encordproService.decimalesCosto));
    } catch (error) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Hubo un problema al consultar la Liquidación de la Orden de Porducción.'
      });
     
    }
  }
  async GuardarCostosIndirectos() {
    
    for (const det of this.encordproService.trncostosindirectos[this.indicador]) {
      if (det.CODIGO_AUXILIAR === "") { // Inserta un nuevo registro
        const item = det;
        item.TRNCOSTOSIND_COSTO = Number(det.TRNCOSTOSIND_COSTO);
        if (item.CHECK === true) {
          item.TRNCOSTOSIND_INICIAL = "S";
        } else {
          item.TRNCOSTOSIND_INICIAL = "";
        }
        await this.encordproService.guardarcostoindirecto(item);
        item.CODIGO_AUXILIAR = "X";
      } else { // Actualiza
        const item = det;
        item.TRNCOSTOSIND_COSTO = Number(det.TRNCOSTOSIND_COSTO);
        if (item.CHECK === true) {
          item.TRNCOSTOSIND_INICIAL = "S";
        } else {
          item.TRNCOSTOSIND_INICIAL = "";
        }
        this.encordproService.actualizarcostoindirecto(item);
      }
    }

    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') { // Formulación
      this.encordproService.SumarTotal();
    } else { // Por proceso
      this.encordproService.SumarTotalXProceso();
    }

    this.ActualizarTotales(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);

    // if (this.selectAsiento.codigo === "") {
    //   await this.GenerarAsientoContable();
    // }
    this.botonesInicio();
  }

  async GuardarDatosAdicionales() {
    const item: ProdEncordpro = {};
    item.ENCORDPRO_NUMERO = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;
    item.COM_CODIGO = '01';
    item.ENCORDPRO_TEMPERATURA = this.encordproService.encordpro[this.indicador].ENCORDPRO_TEMPERATURA;
    item.ENCORDPRO_SOLVENTE = this.encordproService.encordpro[this.indicador].ENCORDPRO_SOLVENTE;
    item.ENCORDPRO_MEZCLA = this.encordproService.encordpro[this.indicador].ENCORDPRO_MEZCLA;
    item.ENCORDPRO_TIPOSOLVENTE = this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOSOLVENTE;
    await this.encordproService.guardardatosadicionales(item);
    this.botonesInicio();
  }
  async ActualizarTotales(CodOrdPro) {
    await this.encordproService.actualizartotales(
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP),
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND),
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM),
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO),
      CodOrdPro);
  }
  GuardarPedido() {
    for (const det of this.encordproService.trnpedidos[this.indicador]) {
      const item: ProdTrnpedidos = det;
      if (item.ENCPED_NUMERO !== "") {
        if (item.CODIGO_AUXILIAR === "") {
          item.DETPED_CANTIDAD = Number(det.DETPED_CANTIDAD);
          this.encordproService.guardarpedido(item);
          item.CODIGO_AUXILIAR = 'X';
        } else {
          item.DETPED_CANTIDAD = Number(det.DETPED_CANTIDAD);
          this.encordproService.actualizarpedido(item);
        }
      }
      this.botonesInicio();
    }
  }
  async eliminarRegistro() {
    // validar que no tenga entradas o salidas registradas
    let prodin=0;
    const rs = await this.encordproService.ValidaEnSaOrdProd(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
    //console.log('rs',rs)
    if(rs !== null && rs !== undefined){
      for (const data of rs){
        prodin=data.NUMERO
      }
    } 
    let Clave;
    // Verifica los permisos
    if (this.permisoOrdPro !== null) {
      if (this.permisoOrdPro.PERTODO === 0) {
        if (this.permisoOrdPro.PERELIMACION === 0) {
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encordproService.usuario.identificacion
              + ', no tiene permiso de Eliminación. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }
    if(prodin>0){
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede eliminar la Orden de Producción ya que tiene ingresadas entradas o salidas.'
      });
      return;
    }
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO === '') {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Seleccione el Registro a Eliminar.'
      });
      return;
    }

    Clave = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;

    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencordpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        this.confirmationService.close();
        // await this.encordproService.eliminarordenproduccion(Clave);
        // this.EliminarAsientoContable();
        //CDPJ
        //await this.EliminarAsientoContable();
        await this.encordproService.eliminarordenproduccion(Clave);
        // await this.EliminarAsientoContable();
        if (this.cmbAsiento.length > 0) {
          for (const asiento of this.cmbAsiento) {
            await this.encordproService.eliminarasientocontable(asiento.codigo);
            this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
            Clave+'/'+asiento.codigo, 'E', '', '01', '', '', '', '').subscribe();
          }
          
        }
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
            Clave+'/'+this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA, 'E', '', '01', '', '', '', '').subscribe();
            this.cmbAsiento = []
        await this.limpiarDatos();
        await this.botonesInicio();
        //CDPJ
        // await this.limpiarDatos();
        // this.botonesInicio();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });

  }
  async seleccionarRegistro(Opcion) {
    const str_BUSORDREF = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BUSORDREF').CFG_VALOR1;
    let data: any;
    if (str_BUSORDREF === 1) {
      data = await this.encordproService.seleccionarregistrorefproc(Opcion, this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA);
    } else {
      data = await this.encordproService.seleccionarregistroproc(Opcion, this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
    }

    if (data !== null) {
      this.botonesInicio();
      this.mostrarDatos(data[0].ENCORDPRO_NUMERO, 'boton');
    }
  }
  async reversarliquidacion() {
    if (this.permisoOrdPro !== null) {
      if (this.permisoOrdPro.PERTODO === 0) {
        if (this.permisoOrdPro.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encordproService.usuario.identificacion
              + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }
    try {
      await this.encordproService.eliminarprodliquidacion(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      await this.ActualizaEstadoFactura("P", this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      await this.encordproService.actualizarFechaIngreso("", this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      await this.GenerarAsientoContable();
      await this.mostrarDatos(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO, '');
      this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
          'LIQ'+this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO+
          '/'+this.encordproService.encordpro[this.indicador].BOD_CODIGO+'/'+
          this.encordproService.encordpro[this.indicador].ART_CODIGO+'/'+
          this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION+'/'+
          this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'E',
        '', '01', '', '', '', '').subscribe();
        this.boolliqui1=false;
        this.boolliqui2=true;
    } catch (error) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Hubo un problema al reversar la Liquidación de la Orden de Porducción.'
      });
    }
  }
  colocarhorafin(){
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;

    if(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN === null 
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN === undefined
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN === '' ){
        this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN = this.datePipe.transform(new Date(), 'HH:mm');
      }
    
  }
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
  async procesarliquidacion(){
    if (this.permisoOrdPro !== null) {
      if (this.permisoOrdPro.PERTODO === 0) {
        if (this.permisoOrdPro.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encordproService.usuario.identificacion
              + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }
    if(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN === null || 
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN === undefined ||
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN === '' ||
      this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN === null ||
      this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN === undefined ||
      this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN === ''
      ){
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'La fecha y hora del campo Término no pueden estar vacíos.'
        });
        this.spin=false//CDPJ
        this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN = new Date();
        this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN = this.datePipe.transform(new Date(), 'HH:mm');

        return;
        
      }
      let tmpFechaFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'yyyy-MM-dd');
      let tmpHoraFin = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
      let control=0
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
        tmpHoraFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
      }
      const dateemi = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'yyyy-MM-dd');
      const dateemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');

      const FechaEmi = new Date(dateemi + ' ' + dateemih);
      const FechaFin = new Date(tmpFechaFin + 'T' + tmpHoraFin + ':00');
      if (FechaFin < FechaEmi) {
        console.log(FechaEmi);
        console.log('FechaIni mayor > FechaFin');
        console.log(FechaFin);
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'La Fecha en que Termina no puede ser MENOR a la Fecha de Emisión'
        });
        this.spin=false//CDPJ
        return false;
      }
      if(this.encordproService.trnliquidacion[this.indicador].length === 0){
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'No se puede realizar la liquidación ya que no existe entradas y salidas correspondientes.'
        });
        this.spin=false//CDPJ
        return false;
      }
//---eliminar la liquidación
      try {
        this.spin=true;
        await this.encordproService.eliminarprodliquidacion(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
        //--- insertar en la tabla de liquidacion
        for (const reg of this.encordproService.trnliquidacion[this.indicador]){
          
          await this.encordproService.insertarprodliquidacion(reg);
          this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
          reg.ENCORDPRO_NUMERO+'/'+reg.TRNLIQ_NUMERO+'/'+reg.TRNLIQ_CODIGO+'/'+reg.TRNLIQ_FECHAES, 'I',
        '', '01', '', '', '', '').subscribe();
        }
        this.messageService.add({
          key: 'encordpro',
          severity: 'success',
          summary: 'Información',
          detail: 'La Liquidación de la Orden de Porducción se guardó exitosamente.'
        });

      } catch (error) {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Hubo un problema al guardar la Liquidación de la Orden de Porducción.'
        });
        this.spin=false;
        control=1;
        return;
      }
      try {
        if(control === 0){
          const item: ProdEncordpro = {}
          item.ENCORDPRO_NUMERO = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;
          item.COM_CODIGO = '01';
          item.ART_CODIGO = this.encordproService.encordpro[this.indicador].ART_CODIGO;       
          item.BOD_CODIGO = this.encordproService.encordpro[this.indicador].BOD_CODIGO;
          // const fechaemi = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
          // const fechaemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
          const datefin  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
          if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
            item.ENCORDPRO_HORAFIN = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
          } else {
            item.ENCORDPRO_HORAFIN = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
          }       
          item.ENCORDPRO_FECHAFIN = datefin;
          item.ENCORDPRO_TOTALCOSTOMP = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP);
          item.ENCORDPRO_TOTALCOSTOIND = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND);
          item.ENCORDPRO_TOTALCOSTOPRM = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM);
          item.ENCORDPRO_TOTALPRODUCIDO = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO);
          await this.encordproService.liquidarordprodproceso(item,this.totalajusteprod);
          this.encordproService.SumarTotalXProceso();
          await this.ActualizaEstadoFactura("I", item.ENCORDPRO_NUMERO);
          await this.GuardarCostosIndirectos();
          await this.mostrarDatos(item.ENCORDPRO_NUMERO, '');
          await this.GenerarAsientoContable();
          this.messageService.add({
            key: 'encordpro',
            severity: 'success',
            summary: 'Información',
            detail: 'La Liquidación de la Orden de Porducción se procesó exitosamente.'
          });
          this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
          'LIQ'+item.ENCORDPRO_NUMERO+'/'+item.BOD_CODIGO+'/'+item.ART_CODIGO+'/'+item.ENCORDPRO_FECHAEMISION+'/'+item.ENCORDPRO_FECHAEMISIONH, 'I',
        '', '01', '', '', '', '').subscribe();
        this.spin=false;
        }else{
          this.spin=false;
        }
      } catch (error) {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Hubo un problema al procesar la Liquidación de la Orden de Porducción.'
        });
        this.spin=false;
      }
     
  }
  async desplegarEyS(){
    try {
      let toten=0;
      let totsa=0
      const data=  await this.encordproService.consulEyS(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      if (data !== null && data !== undefined && data.length>0){
        this.encordproService.trnliquidacion[this.indicador] = data;
        for (const reg of this.encordproService.trnliquidacion[this.indicador]){
          reg.ENCORDPRO_NUMERO=this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO
          reg.TRNLIQ_FECHAES=this.datePipe.transform(reg.TRNLIQ_FECHAES,'dd/MM/yyyy');
          reg.TRNLIQ_CANTIDAD=Number(Number(reg.TRNLIQ_CANTIDAD).toFixed(this.encordproService.decimalesCantEnc));
          reg.TRNLIQ_COSTOT=Number(Number(reg.TRNLIQ_COSTOT).toFixed(this.encordproService.decimalesCosto));
          if(reg.TRNLIQ_TIPO==='SA' ){
            reg.TRNLIQ_SALIDA=Number(Number(reg.TRNLIQ_SALIDA).toFixed(this.encordproService.decimalesCosto));
            reg.TRNLIQ_ENTRADA=Number('0').toFixed(this.encordproService.decimalesCosto);
            totsa=totsa+reg.TRNLIQ_SALIDA;
          }else if(reg.TRNLIQ_TIPO==='EN'){
            reg.TRNLIQ_ENTRADA=Number(Number(reg.TRNLIQ_ENTRADA).toFixed(this.encordproService.decimalesCosto));
            reg.TRNLIQ_SALIDA=Number('0').toFixed(this.encordproService.decimalesCosto);
          toten=toten+reg.TRNLIQ_ENTRADA;
          }
          
        }  
        this.totalajusteprod=Number(totsa)-Number(toten);
        this.totalentrada=Number(Number(toten).toFixed(this.encordproService.decimalesCosto))
        this.totalsalida=Number(Number(totsa).toFixed(this.encordproService.decimalesCosto))
        this.totalajusteprod=Number(Number(this.totalajusteprod).toFixed(this.encordproService.decimalesCosto));
      }
      
    } catch (error) {
      console.log(error)
    }

  }
  busquedaorden() {
    this.opcionbusqueda = 'orden';
    this.types = [
      { label: 'Orden', value: 'ENCORDPRO_NUMERO' },
      { label: 'Referencia', value: 'ENCORDPRO_REFERENCIA' },
      { label: 'Fecha Emi.', value: 'ENCORDPRO_FECHAEMISION' },
      { label: 'Fecha Inicio', value: 'ENCORDPRO_FECHAINICIO' },
      { label: 'Estado', value: 'ENCORDPRO_ESTADO' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'PROD_ENCORDPRO';
    this.where = ' ENCORDPRO_TIPOPROD=\'P\' and ROWNUM < 50';

    this.busqService.busquedaProEncOrdPro2().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.ENCORDPRO_FECHAEMISION !== null) {
          registro.ENCORDPRO_FECHAEMISION = this.datePipe.transform(registro.ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCORDPRO_FECHAEMISION = '';
        }
        if (registro.ENCORDPRO_FECHAINICIO !== null) {
          registro.ENCORDPRO_FECHAINICIO = this.datePipe.transform(registro.ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
        } else {
          registro.ENCORDPRO_FECHAINICIO = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }
 
  IngresarProductoTerminado() {
    if (this.chbtnIngreso === false) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese la fecha de Ingreso del producto'
      });
      return;
    }

    this.IngresarProductoTerminado2();
  }

  async IngresarProductoTerminado2() {
    // verificar control de calidad
    if (this.str_CONCALPRD === '1') {
      const Dato1: any = await this.encordproService.verificarControlCalidad(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);

      if (Dato1 !== null) {
        for (const rs of Dato1) {
          if (rs.TRNCON_ESTADO !== "OK") {
            this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'El certificado de calidad no esta en OK, por favor verifique'
            });
            return;
          } else {
            this.IngresarProductoTerminado3();
          }
        }
      } else {
        this.messageService.add({
          key: 'encordpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'El certificado de calidad no esta en OK, por favor verifique'
        });
        this.claveAuth = '';
        this.autorizacionCodigo = 'OKCONCAL';
        this.displayDialogAutorizacion = true;
      }
    } else {
      this.IngresarProductoTerminado3();
    }
  }

  async IngresarProductoTerminado3() {
    const Dato: any = await this.encordproService.encontrarestado(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO)

    if (Dato !== null) {
      for (const rs of Dato) {
        if (rs.ENCORDPRO_ESTADO === "I") {
          this.confirmationService.confirm({
            message: 'Está seguro de reversar la entrada del Producto',
            header: 'Pregunta',
            icon: 'pi pi-exclamation-triangle',
            key: 'confirmarencordpro',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: async() => {
              this.confirmationService.close();
              const dateent  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'dd/MM/yyyy');
              const dateenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');
              const datefin  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
              const datefinh = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
              const dateini  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
              const dateinih = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
              const dateemi  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
              const dateemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');
              const DTFechaIngreso = dateent + ' ' + dateenth;
              const rs = await this.utilitariosService.enviarCorreoTokenRvProdAdmaws(this.encordproService.usuarioEsquema.esquema, 
                this.encordproService.usuario.identificacion,
                this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA,
                DTFechaIngreso,
                dateemi+' '+dateemih,
                dateini+' '+dateinih,
                datefin+' '+datefinh
                );
              if (rs) {
                this.displayToken = true;
              }
              //this.ReversarEntrada();
            },
            reject: () => {
              this.confirmationService.close();
            }
          });
          return;
        } else {
          if (rs.ENCORDPRO_ESTADO === "T") {
            // Valida fechas
            let tmpFechaFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'yyyy-MM-dd');
            let tmpHoraFin = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
            if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
              tmpHoraFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
            }
            const dateent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'yyyy-MM-dd');
            const dateenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');

            const FechaFin = new Date(tmpFechaFin + 'T' + tmpHoraFin + ':00');
            const FechaEnt = new Date(dateent + ' ' + dateenth);
            
            if (FechaFin > FechaEnt) {
              console.log(FechaFin);
              console.log('mayor >');
              console.log(FechaEnt);
              this.messageService.add({
                key: 'encordpro',
                severity: 'error',
                summary: 'Información',
                detail: 'La Fecha en que Ingresa el producto no puede ser MENOR a la Fecha en que Termina'
              });
              return;
            }

            this.confirmationService.confirm({
              message: 'Está seguro de realizar el ingreso',
              header: 'Pregunta',
              icon: 'pi pi-exclamation-triangle',
              key: 'confirmarencordpro',
              acceptLabel: 'Si',
              rejectLabel: 'No',
              accept: () => {
                this.confirmationService.close();
                this.RegistrarEntradaProducto(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
                document.getElementById('contorno').style.pointerEvents = 'none';
                this.aggrid.bloquearGrid();
              },
              reject: () => {
                this.confirmationService.close();
              }
            });
          } else {
            this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'La orden de producción tiene que tener estado de terminada'
            });
            return;
          }
        }
      }
    }
  }
  async RegistrarEntradaProducto(CodEncOrdPro) {
    const dateent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'dd/MM/yyyy');
    const dateenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');

    const DTFechaIngreso = dateent + ' ' + dateenth;

    await this.encordproService.actualizarFechaIngreso(DTFechaIngreso, CodEncOrdPro);
    // REGISTRA LA ENTRADA DE PRODUCTO TERMINADO
    //await this.encordproService.regtranskardexent(CodEncOrdPro);

    this.ActualizaEstadoFactura("I", CodEncOrdPro);
    // ActivarBoton("I");
    // ActualizaFechaCostoEstandar();
    await this.GenerarAsientoContable();
    const datefin  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
      const datefinh = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
      const dateini  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
      const dateinih = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
      const dateemi  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
      const dateemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO+'/'+this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA+'/Fec-Hing/'+dateent+' '+dateenth, 'I', '', '01', '', '', '', '').subscribe();
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO+'/'+this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA+'/Fec-Hemi/'+dateemi+' '+dateemih, 'I', '', '01', '', '', '', '').subscribe();
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO+'/'+this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA+'/Fec-Hini/'+dateini+' '+dateinih, 'I', '', '01', '', '', '', '').subscribe();
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO+'/'+this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA+'/Fec-Hterm/'+datefin+' '+datefinh, 'I', '', '01', '', '', '', '').subscribe();
  }
  EliminarAsientoContable() {
    let Clave = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;
    this.confirmationService.confirm({
      message: '¿Está seguro de eliminar el asiento contable?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencordpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        this.confirmationService.close();
        for (const asiento of this.cmbAsiento) {
          await this.encordproService.eliminarasientocontable(asiento.codigo);
          //CDPJ
          this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
          Clave+'/'+asiento.codigo, 'E', '', '01', '', '', '', '').subscribe();
          //CDPJ
        }
        this.cmbAsiento = []
        //CDPJ
        await this.limpiarDatos();
        this.botonesInicio();
        //CDPJ
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  verAsiento() {
    this.asiento = this.selectAsiento.codigo;
    if (this.asiento === null || this.asiento === undefined || this.asiento === '') {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      this.displayAsiContable = true;
    }
  }
  pruebaCambio(param): boolean {
    if (param.DETORDPRO_CANTIDAD > 0) {
      return false;
    } else {
      return true;
    }
  }
  seleccionItem(valor) {
    this.botonesmodificar();
  }
  changeProceso() {
    this.formula = '';

    //this.desplegarFormula();
  }
  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'txtReferencia': {
        element = document.getElementById('txtArticulo');
        break;
      }
      case 'txtArticulo': {
        this.busquedaarticulo(event.target.value);
        break;
      }
      case 'txtUnidad': {
        element = document.getElementById('txtCantidad');
        break;
      }
      case 'txtCantidad': {
        if (this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD > 0) {
          this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD).toFixed(4);
          this.changeProceso();
          element = document.getElementById('txtBodegaPT');
        } else {
          element = document.getElementById('txtBodegaPT');
        }
        break;
      }
      case 'txtBodegaPT': {
        this.busquedabodega(event.target.value, 'pt');
        break;
      }
      case 'txtBodegaMP': {
        this.busquedabodega(event.target.value, 'mp');
        break;
      }
      case 'txtLote': {
        element = document.getElementById('txtPedido');
        break;
      }
      case 'txtPedido': {
        this.busquedapedido(event.target.value);
        break;
      }
      case 'txtOpe': {
        element = document.getElementById('txtEquipo');
        break;
      }
      case 'txtEquipo': {
        element = document.getElementById('txtObservacion');
        break;
      }
      case 'txtObservacion': {
        element = document.getElementById('txtArticulo');
        break;
      }
      // pestaña Adicionales
      case 'txtTemperatura': {
        element = document.getElementById('txtSolvente');
        break;
      }
      case 'txtSolvente': {
        element = document.getElementById('txtMezcla');
        break;
      }
      case 'txtMezcla': {
        element = document.getElementById('txtTipoSolvente');
        break;
      }
      case 'txtTipoSolvente': {
        element = document.getElementById('txtTemperatura');
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }
  async busquedabodega(parametro, tipo) {
    if (tipo === 'mp') {
      this.opcionbusqueda = 'bodegamp';
    }
    if (tipo === 'pt') {
      this.opcionbusqueda = 'bodegapt';
    }
    this.types = [
      { label: 'Código', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEBODEGA';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.encordproService.encontrarbodega(parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.busqService.busquedaInvMaeBodega1(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  async busquedapedido(parametro) {
    this.opcionbusqueda = 'pedido';
    this.types = [
      { label: 'Número', value: 'ENCPED_NUMERO' },
      { label: 'Comprobante', value: 'ENCPED_REFERENCIA' },
      { label: 'Fec. Emisión', value: 'ENCPED_FECHAEMISION' },
      { label: 'Fec. Entrega', value: 'ENCPED_FECHAENTREGA' },
      { label: 'Estado', value: 'ENCPED_ESTADO' },
      { label: 'Nombre Cliente', value: 'CLI_NOMBRE' },
      { label: 'Cod Cliente', value: 'CLI_CODIGO' },
      { label: 'Cod_Vendedor', value: 'VEN_CODIGO' },
      { label: 'Observación', value: 'ENCPED_OBSERVACION' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', ''];
    this.consulta = 'A.*, VEN_FNC_NOMBRE_CLIENTE(A.CLI_CODIGO, A.COM_CODIGO)';
    this.tabla = 'VEN_ENCPED A';
    this.where = 'ENCPED_NUMERO IN (SELECT ENCPED_NUMERO FROM VEN_DETPED where DETPED_CODIGO = \''
      + this.encordproService.encordpro[this.indicador].ART_CODIGO + '\')';

    this.busqService.busquedaVenEncped1(this.encordproService.encordpro[this.indicador].ART_CODIGO, parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  async manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'orden') {
      this.botonesInicio();
      this.mostrarDatos(opcion.ENCORDPRO_NUMERO, 'boton');
    }
    if (this.opcionbusqueda === 'numeracion') {
      let strNumActual = "";
      let strNemonico = "";
      let strSerie = "";
      if (opcion.NUM_SECACTUAL.length > 0) {
        strNumActual = opcion.NUM_SECACTUAL;
        this.GRUPO = opcion.NUM_CLASE;
        strNemonico = opcion.NUM_NEMONICO;
        this.globalNemonico = strNemonico;
        strSerie = opcion.NUM_SERIE;
        this.globalSerie = strSerie;
        this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA = strNemonico + "" + strSerie + "" + strNumActual;
        this.enabledtxtReferencia = true;
      } else {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe escoger una numeración'
        });
      }
    }
    if (this.opcionbusqueda === 'articulo') {
      this.encordproService.encordpro[this.indicador].ART_CODIGO = opcion.ART_CODIGO;
      this.encordproService.encordpro[this.indicador].ART_NOMBRE = opcion.ART_NOMBRE;
      const data: any = await this.encordproService.completararticulo(opcion.ART_CODIGO);
      for (const rs of data) {
        this.encordproService.encordpro[this.indicador].UNI_CODIGO = rs.ART_UNIDADCOSTEO;
        this.encordproService.encordpro[this.indicador].ENCORDPRO_COMENTARIO = rs.ART_OBSERVACION;
      }
      this.ingresaTiposProcesos();
      document.getElementById('txtCantidad').focus();
    }
    if (this.opcionbusqueda === 'pedido') {
      this.encordproService.encordpro[this.indicador].ENCPED_NUMERO = opcion.ENCPED_NUMERO;
      this.encordproService.encordpro[this.indicador].CLIENTEPEDIDO = opcion.CLI_NOMBRE;
      document.getElementById('txtOpe').focus();
    }
    if (this.opcionbusqueda === 'bodegamp') {
      this.encordproService.encordpro[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
      document.getElementById('txtLote').focus();
    }
    if (this.opcionbusqueda === 'bodegapt') {
      this.encordproService.encordpro[this.indicador].BOD_CODIGO_ENT = opcion.BOD_CODIGO;
      document.getElementById('txtBodegaMP').focus();
    }
    this.displayDialogBusquedaFast = false;
  }
  async busquedaarticulo(parametro) {
    this.opcionbusqueda = 'articulo';
    this.types = [
      { label: 'Orden', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'NombreCorto', value: 'ART_NOMBREC' },
      { label: 'CodigoAlterno', value: 'ART_CODIGOALT1' },
      { label: 'Ubicación', value: 'ART_UBICACION' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Alterno2', value: 'ART_CODIGOALT2' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'COM_CODIGO = \'01\' AND ART_ACTIVO=\'S\'' +
      ' AND NVL(ART_PRODUCTOPROD,\'X\')=\'S\'';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.encordproService.encontrararticulo(parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.busqService.busquedaInvMaeArticulo4(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  validarClave() {
    if (this.usuarioSup.length > 0) {
      this.verificarUsuarioSupervisor();  // verifica si el usuario es supervisor
    } else {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el usuario.'
      });
    }
  }
  verificarUsuarioSupervisor() {
    this.encordproService.ecVerificarUsuarioSupervisor(this.usuarioSup).subscribe(eC => {
      for (const rs of eC) {
        if (rs.COUN > 0) {
          if (this.claveSup.length > 0) {
            this.verificarClaveSupervisor();
          } else {
            this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'Ingrese la clave.'
            });
          }
        } else {
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario ' + this.usuarioSup + ' no es Supervisor o No existe'
          });
        }
      }
    });
  }
  verificarClaveSupervisor() {
    let strSql = 'SELECT NVL(S.USU_CLAVESUP,\'\') AS USUCLAVESUP FROM  SACI_MAEGUIOBJ O, SACI_MAEGUIOBJPER S WHERE ';
    strSql = strSql + ' O.GUIOBJ_CODIGO = S.GUIOBJ_CODIGO AND ';
    strSql = strSql + ' O.GUIOBJ_FORMA = \'' + this.strDialogo + '\' AND ';
    strSql = strSql + ' O.GUIOBJ_NOMBOBJ = \'' + this.strObjeto + '\' AND ';
    strSql = strSql + ' S.COM_CODIGO = \'01\' AND ';
    strSql = strSql + ' S.USU_IDENTIFICACION = \'' + this.encordproService.usuario.identificacion + '\' AND ';
    strSql = strSql + ' S.USU_IDENTIFICACIONSUP = \'' + this.usuarioSup + '\'';
    if (this.intPestaniaCol > 0) {
      strSql = strSql + ' AND O.GUIOBJ_TABCOL = ' + this.intPestaniaCol;
    }

    this.encordproService.ecVerificarClaveSupervisor(this.strDialogo, this.strObjeto, this.encordproService.usuario.identificacion, this.usuarioSup, this.intPestaniaCol).subscribe(data => {
      if (data.length === 0) {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Asigne la clave en los permisos ' +
            'especiales del usuario.'
        });
      } else {
        for (const rs of data) {
          if (rs.USUCLAVESUP !== null) {
            if (rs.USUCLAVESUP.length > 0) {
              if (rs.USUCLAVESUP === this.claveSup) {
                this.bolSupervisor = true;
                this.accionesSupervisor();
              } else {
                this.messageService.add({
                  key: 'encordpro',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'La clave está incorrecta..'
                });
              }
            } else {
              this.messageService.add({
                key: 'encordpro',
                severity: 'error',
                summary: 'Información',
                detail: 'Asigne la clave en los permisos ' +
                  'especiales del usuario.'
              });
            }
          } else {
            this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'Asigne la clave en los permisos ' +
                'especiales del usuario.'
            });
          }
        }
      }
    });
  }
  accionesSupervisor() {
    if (this.strObjeto === 'cmdEntradaProducto') {
      this.IngresarProductoTerminado2();
      this.displayDialogSupervisor = false;
    }
  }
  onHideSupervisor() {
    if (this.bolSupervisor === false) {
      if (this.strObjeto === 'cmdEntradaProducto') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para el ingreso de la orden de producción'
        });
      }
    }
  }
  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'OKCONCAL') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente la clave.'
        });
      }
    }
  }
  async selecciondetalle(params) {
    this.closeSidebar();
    if (params === 'nuevo') {
      this.nuevodetalle();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalle();
      return;
    }

    // if (params === 'lotes') {
    //   this.abrirLotes();
    // }

    if (params.object !== undefined) {
      this.detordproSelected = params.object;
    }
  }
  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }
  nuevodetalle() {
    if (this.encordproService.encordpro[this.indicador].ART_CODIGO.length === 0) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese el artículo.'
      });
      return;
    }

    const detordpro: ProdDetordpro = this.nuevoElemento();;
    const indi = this.encordproService.detordpro[this.indicador].indexOf(this.detordproSelected);

    if (indi === -1) {
      detordpro.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.detordpro[this.indicador].push(detordpro);
      this.detordproSelected = detordpro;
      this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);
      if (this.encordproService.detordpro[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell(0, 'ART_CODIGO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell((this.encordproService.detordpro[this.indicador].length) - 1, 'ART_CODIGO');
      }
    } else {
      detordpro.CLAVE = (indi + 1) + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.detordpro[this.indicador].splice(indi + 1, 0, detordpro);
      this.detordproSelected = detordpro;
      this.aggrid.refreshaggridindex(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDef.objeto].setFocusedCell(indi + 1, 'ART_CODIGO');
    }
  }
  nuevoElemento() {
    const item: ProdDetordpro = {};

    item.DETORDPRO_LINEA = 0;
    item.BOD_CODIGO = this.encordproService.encordpro[this.indicador].BOD_CODIGO;
    item.ART_CODIGO = '';
    item.DETORDPRO_DESCRIPCION = '';
    item.UNI_CODIGO = '';
    item.DETORDPRO_CANTIDAD = 0.0;
    item.DETORDPRO_COSTO = 0.0;
    item.DETORDPRO_TOTAL = 0.0;
    item.DETORDPRO_LOTE = '';
    item.EXISTENCIA = 0.0;
    item.DETORDPRO_PPA = '';
    item.DETORDPRO_CPPA = '';
    item.COLOREXISTENCIA = '';
    item.COLORFORMULACION = '';

    return item
  }
  cancelardetalle() {

  }
  eliminarLineaDetalle() {
    if (this.detordproSelected !== undefined && this.encordproService.detordpro[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.detordproSelected.DETORDPRO_LINEA !== 0) {
            this.encordproService.eliminarDetalle(this.detordproSelected);
            this.encordproService.detordpro[this.indicador].splice(this.encordproService.detordpro[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.detordproSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            //CDPJ
            this.auditoriagral.registrarAuditoria('PROD_DETORDPRO',
            this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.detordproSelected.ART_CODIGO + '/', 'E',
            '', '01', '', '', '', '').subscribe();
            //CDPJ
            if (this.encordproService.detordpro[this.indicador].length > 0) {
              this.detordproSelected = this.encordproService.detordpro[this.indicador]
              [this.encordproService.detordpro[this.indicador].length - 1];
            }
             
            this.encordproService.SumarTotal();
            this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);

            if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== '') {
              this.guardarRegistro();
            }
          } else {
            this.encordproService.detordpro[this.indicador].splice(this.encordproService.detordpro[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.detordproSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.detordpro[this.indicador].length > 0) {
              this.detordproSelected = this.encordproService.detordpro[this.indicador]
              [this.encordproService.detordpro[this.indicador].length - 1];
            }
           
            this.encordproService.SumarTotal();
            this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }
  
  async seleccionpedido(params) {
    if (params === 'nuevo') {
      this.nuevodetallepedido();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetallepedido();
      return;
    }

    if (params.object !== undefined) {
      this.trnpedidoSelected = params.object;
    }
  }
  nuevodetallepedido() {
    const detalle: ProdTrnpedidos = {
      ENCORDPRO_NUMERO: this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO,
      COM_CODIGO: '01',
      ENCPED_NUMERO: '',
      DETPED_LINEA: '',
      DETPED_CANTIDAD: '',
      DETPED_DESCRIPCION: '',
      DETPED_CODIGO: '',
      ENCPED_REFERENCIA: '',
      ENCPED_FECHAEMISION: '',
      CODIGO_AUXILIAR: ''
    }
    const indi = this.encordproService.trnpedidos[this.indicador].indexOf(this.trnpedidoSelected);

    if (indi === -1) {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trnpedidos[this.indicador].push(detalle);
      this.trnpedidoSelected = detalle;
      this.aggrid.refreshaggrid(this.encordproService.trnpedidos[this.indicador], this.defaultColDefpedido.objeto);
      if (this.encordproService.trnpedidos[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefpedido.objeto].setFocusedCell(0, 'ENCPED_NUMERO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefpedido.objeto].setFocusedCell((this.encordproService.trnpedidos[this.indicador].length) - 1, 'ENCPED_NUMERO');
      }
    } else {
      detalle.CLAVE = (indi + 1) + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trnpedidos[this.indicador].splice(indi + 1, 0, detalle);
      this.trnpedidoSelected = detalle;
      this.aggrid.refreshaggridindex(this.encordproService.trnpedidos[this.indicador], this.defaultColDefpedido.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefpedido.objeto].setFocusedCell(indi + 1, 'ENCPED_NUMERO');
    }
  }
  eliminarLineaDetallepedido() {
    if (this.defaultColDefpedido !== undefined && this.encordproService.trnpedidos[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.trnpedidoSelected.CODIGO_AUXILIAR === 'X') {
            this.encordproService.eliminarDetallepedido(this.trnpedidoSelected);
            this.encordproService.trnpedidos[this.indicador].splice(this.encordproService.trnpedidos[this.indicador].findIndex(
              det => det.CLAVE === this.trnpedidoSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trnpedidos[this.indicador].length > 0) {
              this.trnpedidoSelected = this.encordproService.trnpedidos[this.indicador]
              [this.encordproService.trnpedidos[this.indicador].length - 1];
            }

            this.aggrid.refreshaggrid(this.encordproService.trnpedidos[this.indicador], this.defaultColDefpedido.objeto);
          } else {
            this.encordproService.trnpedidos[this.indicador].splice(this.encordproService.trnpedidos[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.trnpedidoSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trnpedidos[this.indicador].length > 0) {
              this.trnpedidoSelected = this.encordproService.trnpedidos[this.indicador]
              [this.encordproService.trnpedidos[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encordproService.trnpedidos[this.indicador], this.defaultColDefpedido.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }
  async ReversarEntrada2(CodEncOrdPro) {
    this.displayDialogObservacion = false;

    if (this.str_observacion.length == 0) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, para reversar debe ingresar la razón por la que va ha reversar la entrada'
      });
      return;
    }
    if (this.str_observacion.length > 250) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Máxima longitud 250, actual = ' + this.str_observacion.length
      });
      return;
    }

    // Elimina el registro de la transacción de kardex
    await this.encordproService.eliminarkardex(CodEncOrdPro);
    const strDato = CodEncOrdPro + '/' + "REV_E" + '/' + this.str_observacion;
    this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
      strDato, 'A', '', '01', '', '', '', '').subscribe();
      const dateent  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'dd/MM/yyyy');
      const dateenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');
      const datefin  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
      const datefinh = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
      const dateini  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
      const dateinih = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
      const dateemi  = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
      const dateemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        strDato+'/Fec-Hing/'+dateent+' '+dateenth, 'A', '', '01', '', '', '', '').subscribe();
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        strDato+'/Fec-Hemi/'+dateemi+' '+dateemih, 'A', '', '01', '', '', '', '').subscribe();
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        strDato+'/Fec-Hini/'+dateini+' '+dateinih, 'A', '', '01', '', '', '', '').subscribe();
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        strDato+'/Fec-Hterm/'+datefin+' '+datefinh, 'A', '', '01', '', '', '', '').subscribe();
    // Actualiza el estado de la orden de producción y la fecha de la entrada
    this.ActualizaEstadoFactura("T", CodEncOrdPro);
    this.chbtnIngreso === false;
    this.encordproService.actualizarFechaIngreso("", CodEncOrdPro);
    await this.GenerarAsientoContable();
    this.EliminarAsientoContableOPT();
		/*	ActivarBoton("T");
		*/
  }
  async EliminarAsientoContableOPT() {
    for (const asiento of this.cmbAsiento) {
      if (asiento.codigo.includes('OPT')) {
        await this.encordproService.eliminarasientocontable(asiento.codigo);
      }
    }
  }
  comprobarClave() {
    this.encordproService.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }
  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'OKCONCAL') {
      this.IngresarProductoTerminado3();
    }
    this.displayDialogAutorizacion = false;
  }
  async seleccioncostoindirecto(params) {
    if (params === 'nuevo') {
      this.nuevodetallecostoind();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetallecostoind();
      return;
    }

    if (params.object !== undefined) {
      this.trncostoindSelected = params.object;
    }
  }

  nuevodetallecostoind() {
    const detalle: ProdTrncostosind = {
      ENCORDPRO_NUMERO: this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO,
      COM_CODIGO: '01',
      TRNCOSTOSIND_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      TRNCOSTOSIND_HORA: '',
      CEST_CODIGO: '',
      CIN_CODIGO: '',
      TRNCOSTOSIND_COSTO: '',
      TRNCOSTOSIND_INICIAL: '',
      CODIGO_AUXILIAR: '',
      CHECK: false
    }
    const indi = this.encordproService.trncostosindirectos[this.indicador].indexOf(this.trncostoindSelected);

    if (indi === -1) {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trncostosindirectos[this.indicador].push(detalle);
      this.trncostoindSelected = detalle;
      this.aggrid.refreshaggrid(this.encordproService.trncostosindirectos[this.indicador], this.defaultColDefcostoind.objeto);
      if (this.encordproService.trncostosindirectos[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefcostoind.objeto].setFocusedCell(0, 'TRNCOSTOSIND_FECHA');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefcostoind.objeto].setFocusedCell((this.encordproService.trncostosindirectos[this.indicador].length) - 1, 'TRNCOSTOSIND_FECHA');
      }
    } else {
      detalle.CLAVE = (indi + 1) + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trncostosindirectos[this.indicador].splice(indi + 1, 0, detalle);
      this.trncostoindSelected = detalle;
      this.aggrid.refreshaggridindex(this.encordproService.trncostosindirectos[this.indicador], this.defaultColDefcostoind.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefcostoind.objeto].setFocusedCell(indi + 1, 'TRNCOSTOSIND_FECHA');
    }
  }

  eliminarLineaDetallecostoind() {
    if (this.trncostoindSelected !== undefined && this.encordproService.trncostosindirectos[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.trncostoindSelected.CODIGO_AUXILIAR === 'X') {
            this.encordproService.eliminarDetallecostoind(this.trncostoindSelected);
            this.encordproService.trncostosindirectos[this.indicador].splice(this.encordproService.trncostosindirectos[this.indicador].findIndex(
              det => det.CLAVE === this.trncostoindSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trncostosindirectos[this.indicador].length > 0) {
              this.trncostoindSelected = this.encordproService.trncostosindirectos[this.indicador]
              [this.encordproService.trncostosindirectos[this.indicador].length - 1];
            }

            this.aggrid.refreshaggrid(this.encordproService.trncostosindirectos[this.indicador], this.defaultColDefcostoind.objeto);
          } else {
            this.encordproService.trncostosindirectos[this.indicador].splice(this.encordproService.trncostosindirectos[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.trncostoindSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trncostosindirectos[this.indicador].length > 0) {
              this.trncostoindSelected = this.encordproService.trncostosindirectos[this.indicador]
              [this.encordproService.trncostosindirectos[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encordproService.trncostosindirectos[this.indicador], this.defaultColDefcostoind.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }
  ingresochbtnTermino() {
    this.botonesmodificar();

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN === null || this.chbtnTermino === true) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN = new Date();
      this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN = this.datePipe.transform(new Date(), 'HH:mm');
    }
  }
  ingresochbtnInicio() {
    this.botonesmodificar();

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO === null || this.chbtnInicio === true) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO = new Date();
      this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI = this.datePipe.transform(new Date(), 'HH:mm');
    }
  }
  ingresochbtnVencLote() {
    this.botonesmodificar();
  }
  changetipoprod(event) {
    // if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') {
    //   this.enabledcmbProceso = true;
    // } else {
    //   this.enabledcmbProceso = false;
    // }
    //this.activarDesactivarTabsPorTipoProc();
  }
  busqueda(parametro) {

  }
  ingresochbtnIngreso() {

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === "I") {
      this.chbtnIngreso = true;
      return;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO !== "T") {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'La Orden de Producción debe estar en estado TERMINADA.'
      });
      this.chbtnIngreso = false;
      return;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA === null || this.chbtnIngreso === true) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA = new Date();
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH = new Date();
    }

  }
  async changeTabs() {

  }
  activarOrden() {
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'T') {
      this.bolordenactivada = true;
      document.getElementById('contorno').style.pointerEvents = 'all';
      // document.getElementById('contornodetalle').style.pointerEvents = 'all';
      this.aggrid.habilitarGrid();
      this.messageService.add({
        key: 'encordpro',
        severity: 'success',
        summary: 'Información',
        detail: 'Orden de producción activada'
      });
    }

    this.closeSidebar();
  }
  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }
  editarDetalle() {

  }
}
