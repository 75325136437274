import {
  AfterContentInit,
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {BantrnbaneditService} from '../banservicios/bantrnbanedit.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {BanTrnbanVar} from '../baninterfaces/bantrnbanVar';
import {DatePipe} from '@angular/common';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {Usuario} from '../../usuario';
import {AggridBanComponent} from '../aggrid-ban/aggrid-ban.component';
import {BanTrnbanCaj} from '../baninterfaces/bantrnbanCaj';
import {BantrnbanEdit} from '../baninterfaces/bantrnbanedit';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { PagocontadoService } from '../../ven/venservicios/pagocontado.service';//CDPJ
import {finalize} from 'rxjs/operators';//GSRF

@Component({
  selector: 'app-bantrnbanedit',
  templateUrl: './bantrnbanedit.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BantrnbaneditComponent implements OnInit, OnChanges{
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  
  @Input() banCodigo: string;
  @Input() fechaEmision: string;
  @Input() org: string;
  @Input() tipo: string;
  @Input() an: number;
  @Input() numero: string;
  @Input() referencia: string;
  @Input() concepto: string;
  @Input() importe: number;
  @Input() beneficiario: string;
  @Input() numDoc: string;
  @Input() concilia: number;
  @Input() fechaconta: string;
  @Input() asiNum: string;
  @Input() numConcilia: string;
  @Input() registroContable: boolean;
  @Input() asiento: boolean;
  @Input() isBandera: boolean;
  @Output() signalSalir = new EventEmitter<boolean>();
  @Input() tipoAccion: string;
  
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  
  permisosTrnBan: SegMaePermiso;
  
  displayAcciones: boolean;
  
  cmbTipo: any[];
  strTipo: any;
  
  boolAnulado: boolean;
  boolConcilia: boolean;
  boolFechaVence: boolean;
  boolPagoElect: boolean;
  boolTrnBanNum: boolean;
  senalblock: boolean;
  boolSaldoInicial: boolean;
  boolImporte: boolean;
  
  boolAbono: boolean;
  boolCancelacion: boolean;
  
  editCodBan: boolean;
  editCmbTipo: boolean;
  editNum: boolean;
  btnBancos: boolean;
  
  
  opcion: string;
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  asinroorigen='';
  indice;
  
  largo: string;
  public frameworkComponents;
  rowStyle;
  defaultBanTrnBanVar;
  rowSelection = 'multiple';
  defaultBanTrnBanCaj;
  
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  
  detalleVariosSel: BanTrnbanVar;
  detalleCajaSel: BanTrnbanCaj;
  intAnulado: number;
  
  selectTab = 0;
  
  glbStrNumeroTransaccionBan: string;
  numPestana = true;
 
  boolIndex2 = true;
  boolIndex3 = true;
  boolIndex1 = false;
  
  cont = 0;
  strAsiNro = '';
  numsecAc = '';
  numSec = '';
  secuencial = '';
  
  cmbTipoCaj: any[];
  strTipoCaj: any;
  
  cmbCaja: any[];
  strCaja: any;
  
  cmbBodega: any[];
  strBodega: any;
  
  boolMovConta: boolean;
  boolMovCons: boolean;
  boolMovCartAct: boolean;
  boolMovVentas: boolean;
  boolSelectTodos: boolean;
  boolMovCaja: boolean;
  boolMovCartPas: boolean;
  
  desde = new Date();
  hasta = new Date();
  
  co = 0;
  aa = 0;
  
  displayAsiContable: boolean;
  
  NumAsiRegCont: string;
  
  intINFCONBAN = 0;
  
  claveDialog: boolean;
  claveAuth: string;
  accionClave: string;
  
  columnDefsBanTrnBanVar = [
    {
      headerName: 'Referencia Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 160, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Descripción', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', editable: true, width: 180,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 25,
            opcionbuscar: false
          }
        }
    },
    {
      headerName: 'Debe', field: 'VARIOS_DEBE', cellEditor: 'cellPrueba', editable: true, width: 100, cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Haber', field: 'VARIOS_HABER', editable: true, width: 100, cellEditor: 'cellPrueba', cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Centro de Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', editable: true, width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10,
            opcionbuscar: true,
            tienecalculos: true
          }
        }
    }
  ];
  
  columnDefsBanTrnBanCaj  = [
    {
      headerName: 'Chk', field: 'CHECK_ITEM', editable: false, cellRendererFramework: AggridBanComponent, width: 60, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Tipo', field: 'TRNCAJ_TIPO', cellEditor: 'cellPrueba', editable: false, width: 80, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Número', field: 'TRNCAJ_NUMERO', cellEditor: 'cellPrueba', editable: false, width: 110, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Fecha', field: 'TRNCAJ_FECHA', cellEditor: 'cellPrueba', editable: false, width: 130, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Importe', field: 'TRNCAJ_IMPORTE', cellEditor: 'cellPrueba', editable: false, width: 100, cellStyle: {textAlign: 'right'},
    },
    {
      headerName: 'Depositado', field: 'TRNCAJ_PAGADO', cellEditor: 'cellPrueba', editable: false, width: 130, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Saldo', field: 'TRNCAJ_SALDO', cellEditor: 'cellPrueba', editable: false, width: 130, cellStyle: {textAlign: 'right'},
    },
    {
      headerName: 'Importe', field: 'TRNCAJDET_IMPORTE', cellEditor: 'cellPrueba', editable: (params) => {
        const editable = this.editImporte(params.data);
        return editable;
      }, width: 100, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Banco', field: 'BANCLI_NOMBRE', cellEditor: 'cellPrueba', editable: false, width: 80, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Cod. Cliente', field: 'CLI_CODIGO', cellEditor: 'cellPrueba', editable: false, width: 100, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Cliente', field: 'CLI_NOMBRE', cellEditor: 'cellPrueba', editable: false, width: 130, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Código Caja', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', editable: false, width: 100, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Origen', field: 'TRNCAJ_ORIGEN', cellEditor: 'cellPrueba', editable: false, width: 100, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Código Banco', field: 'BANCLI_CODIGO', cellEditor: 'cellPrueba', editable: false, width: 100, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Doc. Num', field: 'TRNCAJ_DOCNRO', cellEditor: 'cellPrueba', editable: false, width: 100, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Com. Código', field: 'COM_CODIGO', cellEditor: 'cellPrueba', editable: false, width: 100, cellStyle: {textAlign: 'left'},
    },
    {
      headerName: 'Documento', field: 'TRNDOCUMENTO_NRODOC', cellEditor: 'cellPrueba', editable: false, width: 100, cellStyle: {textAlign: 'left'},
    }
  ];
  
  
  constructor( public bantrnbanEditService: BantrnbaneditService, public busquedaSer: InvbusquedaService,
               private message: MessageService, private permisosService: PermisosService,
               public confIniciales: ConfInicialesService, private confirmationService: ConfirmationService,
               private datePipe: DatePipe, private auditoriagral: AuditoriagralService, public usuario: Usuario,
               private  auditoriaGralService: AuditoriagralService, private init: NuevoComponentService,
               private pagocontadoService:PagocontadoService//CDPJ
               ) {
  
    // this.cmbTipo = [];
    this.bantrnbanEditService.banTrnbanSelect = {};
    this.agTable();
    this.bantrnbanEditService.banTrnBanVarios = [];
    this.bantrnbanEditService.banTrnBanCaja = [];
    this.detalleVariosSel = {};
    this.detalleCajaSel = {};
    
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultBanTrnBanVar = {
      editable: true,
      width: 500,
      objeto: 'banTrnBanVar',
      resizable: false
    };
    this.defaultBanTrnBanCaj = {
      editable: true,
      width: 500,
      objeto: 'banTrnBanCaj',
      resizable: false
    };
  }

  ngOnInit() {
    
    this.bantrnbanEditService.glbStrCodigo = '';
    this.bantrnbanEditService.glbStrNombre = '';
    this.bantrnbanEditService.glbStrCodigo2 = '';
  
    this.claveDialog = false;
    this.claveAuth = '';
    this.accionClave = '';
    
    this.displayAsiContable = false;
    this.detalleVariosSel = {};
    this.detalleCajaSel = {};
    this.bantrnbanEditService.banTrnbanSelect = {};
    this.permisosService.getListaPermisos('CON', '6', '32').subscribe((permiso) => {
      this.permisosTrnBan = permiso[0];
    });
  
    this.btnNuevoGrid = false;
    this.btnCancelarGrid = false;
    this.btnBorrarGrid = false;
  
    this.largo = '580';
    this.cmbTipoCaj = [];
    this.cmbTipoCaj.push({name: 'Cheque', id: 1}, {name: 'Efectivo', id: 2}, {name: '', id: 3} );
    this.strTipoCaj = this.cmbTipoCaj[2];
    
    this.bantrnbanEditService.cargarCaja().subscribe((caja) => {
      this.cmbCaja = [];
      for (const a of caja) {
        this.cmbCaja.push({name: a.CAJ_DESCRIPCION, codigo: a.CAJ_CODIGO});
      }
      this.cmbCaja.push({name: '', codigo: ''});
      this.strCaja = this.cmbCaja[this.cmbCaja.length - 1];
    });
    
    this.bantrnbanEditService.cargarBodega().subscribe((bodega) => {
      this.cmbBodega = [];
      for (const a of bodega) {
        this.cmbBodega.push({name: a.BOD_NOMBRE, codigo: a.BOD_CODIGO});
      }
      this.cmbBodega.push({name: '', codigo: ''});
      this.strBodega = this.cmbBodega[this.cmbBodega.length - 1];
    });
  
    this.boolMovConta = true;
    this.boolMovCons = true;
    this.boolMovCartAct = true;
    this.boolMovVentas = true;
    this.boolSelectTodos = true;
    this.boolMovCaja = true;
    this.boolMovCartPas = true;
  
    this.bantrnbanEditService.cargarDatosCMBTipo().subscribe((tipo) => {
      this.cmbTipo = [];
      for (const a of tipo) {
        this.cmbTipo.push({name: a.REF_NOMBRE});
      }
      
      this.strTipo = this.cmbTipo[0];
      // if (this.tipoAccion === 'view') {
      
      if (!this.registroContable) {
        this.listarDatos();
      }
  
      if (this.registroContable) {
        this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO = '' ;
        if (this.intINFCONBAN === 1 && this.registroContable === true) {
          this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION = this.fechaEmision;
          this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO = this.fechaEmision;
          this.bantrnbanEditService.banTrnbanSelect.CONCEPTO = this.concepto;
        }
        this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = '';
        this.bantrnbanEditService.banTrnbanSelect.REFERENCIA = '';
        this.bantrnbanEditService.banTrnbanSelect.CONCEPTO = '';
        this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO = '';
        this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = '';
        this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE = '';
        this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO = '';
        this.bantrnbanEditService.banTrnbanSelect.IMPORTE_RES = 0;
        this.bantrnbanEditService.banTrnbanSelect.EFECTIVO = 0;
        this.bantrnbanEditService.banTrnbanSelect.CHEQUE = 0;
       // document.getElementById('divPrincipal').style.pointerEvents = 'all';
      }
      this.bloqueoI();
    });
  
  
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnRegresar = true;
    // this.btnNuevoGrid = true;
    // this.btnGuardarGrid = true;
    // this.btnCancelarGrid = true;
    // this.btnBorrarGrid = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
    this.displayAcciones = false;
    
    this.bantrnbanEditService.banTrnbanSelect.ORIGEN = 'BAN';
    
    this.boolImporte = false;
    this.boolAnulado = false;
    this.boolSaldoInicial = false;
    this.boolConcilia = false;
    this.boolFechaVence = true;
    this.boolPagoElect = false;
    this.boolTrnBanNum = false;
    this.boolCancelacion = true;
    this.boolAbono = false;
  
    this.bantrnbanEditService.boolAbono = false;
    this.bantrnbanEditService.boolCancelacion = true;
    
    this.editCodBan = false;
    this.editCmbTipo = false;
    this.editNum = false;
    this.btnBancos = false;
    
  
    /*this.bantrnbanEditService.cargarDatosCMBTipo().subscribe((tipo) => {
      
      for (const a of tipo) {
        this.cmbTipo.push({name: a.REF_NOMBRE});
      }
    });*/
  
    this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION = new Date();
    this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO = new Date();
    
    
    this.auditoriaGralService.obtenerConfiguracion1().subscribe((res) => {
      this.bantrnbanEditService.hmpConfiguracion = res;
    
    });
  
    
  }
  
  ngOnChanges(changes) {
  
  }
  
  async listarDatos() {
  
    if (this.org !== 'BAN') {
      document.getElementById('divPrincipal3').style.pointerEvents = 'none';
    }
    this.btnGuardar = true;
    this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO = this.banCodigo;
    this.bantrnbanEditService.consultaNombreBan(this.banCodigo).subscribe((banco) => {
      if (Object.keys(banco).length > 0) {
        this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE = banco[0].BAN_BANCO;
      } else {
        this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE = '';
      }
    });
    
    this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = this.numero;
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE = this.importe;
    // Importe Total
    
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = this.importe;
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = this.init.moneyValidation(this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL);
  
  
    this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO = this.beneficiario;
  
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE_RES = 0;
    this.bantrnbanEditService.banTrnbanSelect.EFECTIVO = 0;
    this.bantrnbanEditService.banTrnbanSelect.CHEQUE = 0;
   
    if (this.an === 1) {
      this.boolAnulado = true;
    }
  
    if (this.an === 0) {
      this.boolAnulado = false;
    }
    
    this.bantrnbanEditService.banTrnbanSelect.REFERENCIA = this.referencia;
  
    this.bantrnbanEditService.banTrnbanSelect.ORIGEN = this.org;
    this.cargarTipos();
    this.bantrnbanEditService.banTrnbanSelect.CONCEPTO = this.concepto;
    this.bantrnbanEditService.banTrnbanSelect.NRO_DOCUMENTO = this.numDoc;
    
    if (this.concilia === 1) {
      this.boolConcilia = true;
      
      this.bantrnbanEditService.banTrnbanSelect.NUMERO_CONCILIACION = this.numConcilia === null ? '' : this.numConcilia;
      
    }
    if (this.concilia === 0) {
      this.boolConcilia = false;
      this.bantrnbanEditService.banTrnbanSelect.NUMERO_CONCILIACION = '';
    }
  
    if (this.strTipo === this.cmbTipo[1]) {
      this.boolFechaVence = false;
    } else  {
      this.boolFechaVence = true;
    }
    
    if (this.asiNum !== '' && this.asiNum !== null) {
      this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = this.asiNum;
    } else {
      this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = '';
    }
    
    if (this.bantrnbanEditService.banTrnbanSelect.ASI_NRO !== '') {
     /* this.actualiza_tabla_deposito(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
        this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc()); */
    }
    
    if (this.fechaEmision.toString().includes('-')) {
      this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION = new Date();
    } else {
      this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION = '';
      const dia = this.fechaEmision.substring(0, 2);
      const mes = this.fechaEmision.substring(3, 5);
      const anio = this.fechaEmision.substring(6, 10);
      const fechaEm = mes + '/' + dia + '/' + anio;
      
     /* const fecha = new Date();
      fecha.setDate(Number(dia));
      fecha.setMonth(Number(mes) - 1);
      fecha.setFullYear(Number(anio)); */
     
      // const dia = fecha.setDate(FECHA_EMISION)
      
      this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION = new Date(fechaEm);
      
    }
  
    if (this.fechaconta === null || this.fechaEmision.toString().includes('-')) {
      this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO = new Date();
    } else {
      this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO = '';
      const dia = this.fechaconta.substring(0, 2);
      const mes = this.fechaconta.substring(3, 5);
      const anio = this.fechaconta.substring(6, 10);
      const fechaVen = mes + '/' + dia + '/' + anio;
      this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO = new Date(fechaVen);
    }
    
    
    
    if (this.banCodigo.trim() !== null && this.banCodigo.trim() !== '' && this.banCodigo.trim() !== undefined) {
      const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);
      if (this.org === 'BAN') {
        if (indexCmb === 2) {
          this.boolIndex2 = false;
          this.boolImporte = true;
        }
        if (indexCmb !== 2) {
          this.boolIndex3 = false;
        }
      }
    }
  
    
  
    this.bantrnbanEditService.cxp_fnc_verificar_trans_cash(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO + '-' + this.tipoDoc() +
      '-' + this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, '', 'BANCOS').subscribe((cash) => {
        if (cash[0].RESULTADO >= 1) {
          this.mensajeAlerta('info', 'Información', 'Documento incluido en el Cash Management no puede modificar');
          return;
        }
        
    
        if ((this.an === 0 || this.concilia === 0 || cash[0].RESULTADO === 0) && this.org === 'BAN') {
          document.getElementById('divPrincipal3').style.pointerEvents = 'all';
        } else {
          document.getElementById('divPrincipal3').style.pointerEvents = 'none';
          this.btnBorrar = true;
        }
        
    
        if (this.org === 'BAN') {
          if (this.banCodigo.length < 1) {
            this.btnGuardar = true;
            this.btnBorrar = true;
            // btnVexAsiCont.setEnabled(false);
          } else {
            this.btnGuardar = true;
            this.btnBorrar = false;
            // btnVexAsiCont.setEnabled(true);
          }
        }
        
    
        if (this.an === 1 ) {
          this.btnBorrar = true;
        }
    
        const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);
    
        if (this.org === 'BAN') {
          if (indexCmb !== 2) {
        
            if (this.bantrnbanEditService.banTrnbanSelect.ASI_NRO !== '') {
              
              this.consultarVarios(this.tipoDoc(), this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO.trim(),
                this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO.trim());
              this.totalImporteDH();
              
            }
          }
        }
  
  
    });
    
    this.bantrnbanEditService.encontrarProCodigo(this.banCodigo, this.tipoDoc(), this.numero).subscribe((res) => {
               if (res !== null) {
                 this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO = res[0].PRO_CODIGO;
                 this.bantrnbanEditService.encontrarProNombre( this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO).subscribe(pro => {
                   if (pro !== null) {
                     this.bantrnbanEditService.banTrnbanSelect.PRO_NOMBRE = pro[0].PRO_NOMBRE;
                   } else {
                     this.bantrnbanEditService.banTrnbanSelect.PRO_NOMBRE = '';
                   }
                 });
               } else {
                 this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO = '';
                 this.bantrnbanEditService.banTrnbanSelect.PRO_NOMBRE = '';
               }
    });
  
    if (this.org !== 'BAN') {
      document.getElementById('divPrincipal3').style.pointerEvents = 'none';
    }
  
    if (this.an === 1) {
      document.getElementById('divPrincipal3').style.pointerEvents = 'none';
      this.boolIndex2 = true;
      this.boolIndex3 = true;
    }
  
    this.bantrnbanEditService.encontrarSaldoInicial( this.banCodigo, this.tipoDoc(), this.numero).subscribe((saldo) => {
        if (saldo !== null) {
          if (saldo[0].TRNBAN_SALDOINI !== null) {
            if (saldo[0].TRNBAN_SALDOINI === '1') {
              this.boolSaldoInicial = true;
            } else {
              this.boolSaldoInicial = false;
            }
          } else {
            this.boolSaldoInicial = false;
          }
        }
    });
  //CDPJ
  const res1=await this.pagocontadoService.consultarconciliacion(this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO,this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO);
  if (res1 !== null && res1 !== undefined) {
    if (res1[0] !== null && res1[0] !== undefined && res1[0] !== '') {
      if (res1[0].NUMERO !== ''
        && res1[0].NUMERO !== null
        && res1[0].NUMERO !== undefined
        && res1[0].NUMERO !== 0) {
        //this.mensajeAlerta('error', 'Documentos Conciliados', 'No se puede eliminar porque el pago tiene documentos conciliados');
        //numero=res[0].TRNBAN_NUMERO;
        //errores = errores + 1;
        this.bloqueoC();
        
      }else{
        //this.eliminaDatos();
      }
    }
  }
   //BLOQUEAR SI ES UN MOVIIMENTO DE BANCOS QUE FUE HECHO DESDE REGISTROS CONTABLES 
   const res2=await this.bantrnbanEditService.consultarorigenasi(this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO,this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO);
   console.log(res2)
   if (res2 !== null && res2 !== undefined) {
     if (res2[0] !== null && res2[0] !== undefined) {
      this.asinroorigen=res2[0].ORIGE === null || res2[0].ORIGE === undefined?'':res2[0].ORIGE;
       if (res2[0].ORIGEN === null || res2[0].ORIGEN === '') {
         //this.mensajeAlerta('error', 'Documentos Conciliados', 'No se puede eliminar porque el pago tiene documentos conciliados');
         //numero=res[0].TRNBAN_NUMERO;
         //errores = errores + 1;
         this.bloqueoC();
         this.boolIndex3=true;
         
       }else{
         //this.eliminaDatos();
       }
     }
   }
  //CDPJ
   
  }
  bloqueoC(){
    this.editCodBan = true;
    this.editCmbTipo = true;
    this.editNum = true;
    this.btnBancos = true;
    this.btnGuardar = true;
    document.getElementById('divPrincipal3').style.pointerEvents = 'none';
    this.btnBorrar = true;
    document.getElementById('divPrincipal4').style.pointerEvents = 'none';
  }
  actualiza_tabla_deposito(strCodBanco, strNum, tipoDoc) {
    this.bantrnbanEditService.depositos(strCodBanco, tipoDoc, strNum).subscribe((deposito) => {
      if (deposito !== null) {
        this.bantrnbanEditService.banTrnBanCaja = deposito;
        this.bantrnbanEditService.banTrnBanCaja.map(item => {
          item.TRNCAJ_FECHA = this.datePipe.transform(item.TRNCAJ_FECHA , 'dd/MM/yyyy');
          item.TRNCAJDET_IMPORTE = Number(item.TRNCAJDET_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
          item.TRNCAJ_PAGADO = Number(item.TRNCAJ_PAGADO).toFixed(this.confIniciales.getNumDecSist());
          item.TRNCAJ_IMPORTE = Number(item.TRNCAJ_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
          item.TRNCAJ_SALDO = Number(item.TRNCAJ_SALDO).toFixed(this.confIniciales.getNumDecSist());
          item.CHECK_ITEM = true;
         // this.bantrnbanEditService.suma();
        });
        // this.aggrid.gridApi.setFocusedCell(this.bantrnbanEditService.banTrnBanCaja.length - 1, 'CHECK_ITEM');
        this.bantrnbanEditService.suma();
      }
      
    });
    
  }
  
  tipoDoc(): string {
    if (this.strTipo === this.cmbTipo[0]) {
      return 'AJ';
    }
    if (this.strTipo === this.cmbTipo[1]) {
      return 'CH';
    }
    if (this.strTipo === this.cmbTipo[2]) {
      return 'DP';
    }
    if (this.strTipo === this.cmbTipo[3]) {
      return 'NC';
    }
    if (this.strTipo === this.cmbTipo[4]) {
      return 'ND';
    }
    if (this.strTipo === this.cmbTipo[5]) {
      return 'RE';
    }
    
    return '';
  }
  
  cargarTipos() {
    if (this.tipo !== '') {
      if (this.tipo === 'AJ') {
        this.strTipo = this.cmbTipo[0];
      }
      if (this.tipo === 'CH') {
        this.strTipo = this.cmbTipo[1];
      }
      if (this.tipo === 'DP') {
        this.strTipo = this.cmbTipo[2];
      }
      if (this.tipo === 'NC') {
        this.strTipo = this.cmbTipo[3];
      }
      if (this.tipo === 'ND') {
        this.strTipo = this.cmbTipo[4];
      }
      if (this.tipo === 'RE') {
        this.strTipo = this.cmbTipo[5];
      }
    } else {
      this.strTipo = this.cmbTipo[0];
    }
  
    
  }
  
  manejarSenales(valor) {
    
    if (valor === 'Guardar') {
      this.guardarDocumento();
    }
  
    if (valor === 'Borrar') {
      this.eliminarDocumento();
    }
    
    if (valor === 'Salir') {
      this.salir();
    }
    
    if (valor === 'Cancelar') {
      this.deshacer();
    }
    
    if (valor === 'AsientoVer') {
      this.verAsientoContable();
    }
  }
  
  salir() {
   
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);
  
    if (this.registroContable) {
    
      this.bantrnbanEditService.glbStrCodigo = this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO; // trnban_numero
      this.bantrnbanEditService.glbStrNombre = this.tipoDoc(); // trnban_tipo
      this.bantrnbanEditService.glbStrCodigo2 = this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO; // ban_codigo
    
    }
    
    if (this.bantrnbanEditService.banTrnbanSelect.ORIGEN === 'BAN' && indexCmb !== 2 && !this.asiento &&
      (this.bantrnbanEditService.banTrnbanSelect.ASI_NRO.length < 1 || this.bantrnbanEditService.banTrnbanSelect.ASI_NRO.startsWith('AB'))
     && this.asinroorigen !== ''
      ) {
    
      if (this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO.trim().length === 0) {
        this.signalSalir.emit(true);
        return;
      }
      
    
      if (this.verificaValores(this.bantrnbanEditService.banTrnbanSelect.IMPORTE === '' ? '0' :
        this.bantrnbanEditService.banTrnbanSelect.IMPORTE) === 0) {
        
        if (this.btnGuardar === false) {
  
          this.confirmationService.confirm({
            message: '¿ Desea guardar los cambios existentes?',
            header: 'Guardar',
            icon: 'pi pi-exclamation-triangle',
            key: 'trnbanEditConf',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
              this.guardarDocumento();
            },
            reject: () => {
              this.signalSalir.emit(true);
            }
          });
        } else {
          this.signalSalir.emit(true);
        }
      
      } else {
        if (this.boolSaldoInicial === false && this.boolPagoElect === false ) {
          this.mensajeAlerta('error', 'ERROR', 'El importe es distinto al valor de varios');
          return;
        }
      }
    
    } else {
      this.signalSalir.emit(true);
    }
  
    /*if (this.registroContable) {
    
      this.bantrnbanEditService.glbStrCodigo = this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO; // trnban_numero
      this.bantrnbanEditService.glbStrNombre = this.tipoDoc(); // trnban_tipo
      this.bantrnbanEditService.glbStrCodigo2 = this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO; // ban_codigo
    
    }*/
    
    
  
   // this.signalSalir.emit(true);
  
  }
  
  acciones(valor) {
    this.displayAcciones = !this.displayAcciones;
    const  elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className = 'layout-config';
      }
    }
  }
  
  guardarDocumento() {
    
    
     const dia = this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.getDate();
     const mes = this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.getMonth();
     const anio = this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.getFullYear();
     
     this.bantrnbanEditService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
       if (per[0].CONTADOR === 1) {
            this.guardarDatos();
       } else {
         this.mensajeAlerta('error', 'ERROR', 'La Fecha del documento debe estar dentro del periodo contable.');
       }
     });
  }
  
  guardarDatos() {
    this.bantrnbanEditService.cxp_fnc_verificar_trans_cash(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO + '-' + this.tipoDoc() +
      '-' + this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, '', 'BANCOS').subscribe((cash) => {
      if (cash[0].RESULTADO >= 1) {
        this.mensajeAlerta('info', 'Información', 'Documento incluido en el Cash Management no puede modificar');
        return;
      }
  
      if (this.selectTab !== 2) {
  
      if (!this.verificaFechas()) {
        this.mensajeAlerta('error', 'ERROR', 'La fecha de vencimiento no puede ser menor a la fecha de contabilización');
        return;
      } else {
        if (this.boolPagoElect === true ) {
          if(this.tipoDoc() === 'CH'){
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = '';
          }

          if(this.tipoDoc() === 'DP'){
            if (this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO === '') {
              this.mensajeAlerta('error', 'ERROR', 'El número del documento es obligatorio');
              return;
            }
          }
        }
        if (this.boolPagoElect === false) {
          if (this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO === '') {
            this.mensajeAlerta('error', 'ERROR', 'El número del documento es obligatorio');
            return;
          }
        }
    
        if (this.strTipo.name === '' || this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO === ''
          || this.bantrnbanEditService.banTrnbanSelect.IMPORTE === '') {
      
          this.mensajeAlerta('error', 'ERROR',
            'Uno de los siguientes campos no se ha ingresado correctamente: Banco, Tipo de transacción, Importe o Número; son obligatorios');
          return;
        }
        if ((this.tipoDoc() === 'DP' || this.tipoDoc() === 'CH')
          && (this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO === '' || this.bantrnbanEditService.banTrnbanSelect.CONCEPTO === '')) {
      
          this.mensajeAlerta('error', 'ERROR',
            'El campo beneficiario y concepto es obligatorio para este tipo de transacción');
          return;
        } else {
          if (this.editNum === false) {
        
            this.bantrnbanEditService.num_Exist(this.tipoDoc()).subscribe((exist) => {
              if (Object.keys(exist).length > 0) {
                this.senalblock = true;
                this.mensajeAlerta('error', 'ERROR',
                  'El número de comprobante ya existe ingrese uno diferente');
                return;
              } else {
                this.senalblock = false;
              }
          
              this.bantrnbanEditService.existe_Banco(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO).subscribe((banco) => {
                if (Object.keys(banco).length === 0) {
                  this.senalblock = true;
                  this.mensajeAlerta('error', 'ERROR',
                    'El codigo de banco no existe');
                  return;
                }
            
                if (!this.senalblock) {
              
                  // System.out.println("entro1");
                  // activaDesactivaCampos();
                  if (this.selectTab === 0) {
                    if (this.strTipo.name === 'DEPOSITO' && Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE) === 0) {
                      // System.out.println(txtimporteres.getText().isEmpty());
                      this.mensajeAlerta('error', 'ERROR',
                        'No puede guardar con un importe de 0, seleccione los importes en la pestaña caja');
                      return;
    
                    }
                  }
                  if (this.selectTab === 1) {
                    if (this.strTipo.name === 'DEPOSITO' && Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE_RES) === 0) {
  
                      this.mensajeAlerta('error', 'ERROR',
                        'No puede guardar con un importe de 0, seleccione algunos valores');
                      
                      return;
                    }
                  }
                  if ( this.selectTab === 0 && Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE) === 0) {
                
                    this.mensajeAlerta('error', 'ERROR',
                      'No puede guardar con un importe de 0, ingrese un valor');
                    return;
                  }
              
                  this.colocaImporteDocumento();
                  this.guardarRegistro();
                  this.bloqueoS();
                  this.btnGuardar = true;
                  this.btnBorrar = false;
                }
              });
          
            });
          } else {
            this.senalblock = false;
  
            if (!this.senalblock) {
    
              // System.out.println("entro1");
              // activaDesactivaCampos();
    
              if (this.strTipo.name === 'DEPOSITO' && Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE) === 0) {
                // System.out.println(txtimporteres.getText().isEmpty());
                this.mensajeAlerta('error', 'ERROR',
                  'No puede guardar con un importe de 0, seleccione los importes en la pestaña caja');
                return;
      
              }
              if (Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE) === 0) {
      
                this.mensajeAlerta('error', 'ERROR',
                  'No puede guardar con un importe de 0, ingrese un valor');
                return;
              }
    
              this.colocaImporteDocumento();
              this.guardarRegistro();
              this.bloqueoS();
              this.btnGuardar = true;
              this.btnBorrar = false;
            }
            
          }
        }
    
    
        // verificas que no se sobrepase del presupuesto
    
      }
    } else {
  
        if (!this.senalblock) {
    
          // System.out.println("entro1");
          // activaDesactivaCampos();
    
          if (this.strTipo.name === 'DEPOSITO' && Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE) === 0) {
            // System.out.println(txtimporteres.getText().isEmpty());
            this.mensajeAlerta('error', 'ERROR',
              'No puede guardar con un importe de 0, seleccione los importes en la pestaña caja');
            return;
      
          }
          if (Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE) === 0) {
      
            this.mensajeAlerta('error', 'ERROR',
              'No puede guardar con un importe de 0, ingrese un valor');
            return;
          }
    
          this.colocaImporteDocumento();
          this.guardarRegistro();
          this.bloqueoS();
          this.btnGuardar = true;
          this.btnBorrar = false;
        }
      }
        
    });
  }
  
  onTab1Change(event) {
    
    this.activateTab(event.index);
  }
  
  activateTab(tabNumber) {
    this.selectTab = tabNumber;
    if (this.selectTab === 1) {
      if (this.bantrnbanEditService.banTrnbanSelect.ASI_NRO !== '') {
        this.actualiza_tabla_deposito(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc());
      }
    }
  
    if (this.selectTab === 0) {
      this.colocaImporteDocumento();
    }
   // this.habilita_Pestañas();
  }
  
  buscarBancos() {
    this.opcion = 'BuscarBanco';
    this.types = [
      {label: 'CÓDIGO', value: 'BAN_CODIGO'},
      {label: 'BANCO', value: 'BAN_BANCO'},
      {label: 'CUENTA NRO', value: 'BAN_CTANRO'},
      {label: 'JEFE CUENTA', value: 'BAN_JEFECTA'},
      {label: 'SUCURSAL', value: 'BAN_SUCURSAL'},
      {label: 'DIRECCIÓN', value: 'BAN_DIRECCION'},
      {label: 'TELEFONO1', value: 'BAN_TELEFONO1'},
      {label: 'REF. CONTABLE', value: 'CON_CODIGO'},
      {label: 'CEN_CODIGO', value: 'CEN_CODIGO'},
      {label: 'TIPO DE CUENTA', value: 'BAN_TIPOCUENTA'},
      {label: 'DOC. POR PAGAR', value: 'CON_CODIGO_2'},
      {label: 'PROCESO', value: 'BAN_PROCESO_CM'},
      {label: 'DIRECTORIOCM', value: 'BAN_DIRECTORIO_CM'},
      {label: 'DINEROELEC', value: 'BAN_DINEROELEC'},
      {label: 'TELCEL', value: 'BAN_TELEFONOCEL'},
      {label: 'ULT. CHEQUE', value: 'BAN_ULTIMOCHEQUE'},
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = 'BAN_codigo,BAN_banco, NVL(BAN_ctanro, \'*\') AS BAN_ctanro, BAN_jefecta,BAN_sucursal,' +
      ' BAN_direccion, BAN_telefono1, NVL(CON_codigo, \'\') as CON_codigo, CEN_CODIGO,BAN_tipocuenta,' +
      ' con_codigo_2, ban_proceso_cm, ban_directorio_cm, ban_dineroelec, ban_telefonocel,BAN_ULTIMOCHEQUE';
    this.tabla = 'BAN_MAEBAN';
    this.where = '';
    this.busquedaSer.busquedaBanMaeBan5().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  manejarSeleccion(valor) {
    if (this.opcion === 'BuscarBanco') {
      this.seleccionBanco(valor);
    
    }
    
    if (this.opcion === 'BuscarProveedor') {
      if (valor.PRO_CODIGO !== null) {
        this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO = valor.PRO_CODIGO;
        this.bantrnbanEditService.banTrnbanSelect.PRO_NOMBRE = valor.PRO_NOMBRE;
        this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO = this.bantrnbanEditService.banTrnbanSelect.PRO_NOMBRE;
        document.getElementById('pro_codigo').focus();
      }
    }
  
    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }
  
  seleccionBanco(valor) {
    if (valor.BAN_CODIGO === null) {
      this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO = '';
      this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE = '';
    } else {
      this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO = valor.BAN_CODIGO;
      this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE = valor.BAN_BANCO;
      this.btnGuardar = false;
    }
  }
  
  cambiarFoco(evento) {
   
    if (evento.target.id === 'ban_codigo') {
  
      if (this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO !== '') {
        this.busquedaSer.encontrarRegistro37(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO).subscribe((ban) => {
          if (ban !== null) {
            this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE = ban[0].BAN_BANCO;
            this.btnGuardar = false;
          } else {
            this.mensajeAlerta('error', 'ERROR', 'El código del banco no existe');
            this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE = '';
            this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO = '';
            document.getElementById('ban_codigo').focus();
            this.buscarBancos();
          }
        });
  
       // document.getElementById('comprobante').focus();
    
      } else {
        this.buscarBancos();
      }
    }
  
    if (evento.target.id === 'ban_numero') {
      document.getElementById('ban_importe').focus();
    }
    if (evento.target.id === 'ban_importe') {
      document.getElementById('ban_beneficiario').focus();
    }
    if (evento.target.id === 'ban_beneficiario') {
      document.getElementById('ban_referencia').focus();
    }
    if (evento.target.id === 'ban_referencia') {
      document.getElementById('ban_concepto').focus();
    }
  }
  
  buscarProveedor() {
    this.buscarCodigo(this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO, this.bantrnbanEditService.banTrnbanSelect.PRO_NOMBRE,
      this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO, 'COM_PCK_MAEPROVEEDOR_C.COM_SP_consultarProveedor_1',
      'com_maeproveedor', '', 'claseMaestra');
    
    this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO = this.bantrnbanEditService.banTrnbanSelect.PRO_NOMBRE;
    this.btnGuardar = false;
    this.btnBorrar = true;
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'trnbanEdit',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  buscarCodigo(txtCodigo, lblNombre, txtFoco, strPaquete, strTabla, strFiltro, claseMaestra) {
    let strCondicion;
    let Nombre = '';
    strCondicion = 'pro_codigo' + ' like ' + '\'' + txtCodigo + '%\'';
  
    if (txtCodigo !== '' && txtCodigo !== undefined && txtCodigo !== null) {	 // si ingreso de datos en la caja de texto
      
        if (strFiltro === '') {
          this.busquedaSer.encontrarRegistro38(txtCodigo).subscribe((prov) => {
            if (prov !== null) {
              Nombre = prov[0].PRO_NOMBRE;
            }
            if (Nombre === '') {
              this.buscarProveedor2(strCondicion);
            } else {
              this.bantrnbanEditService.banTrnbanSelect.PRO_NOMBRE = Nombre;
              this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO = Nombre;
              document.getElementById('pro_codigo').focus();
            }
          });
        }
    } else {
      this.buscarProveedor2('ROWNUM < 50');
    }
  }
  
  buscarProveedor2(where) {
    this.opcion = 'BuscarProveedor';
    this.types = [
      {label: 'CODIGO', value: 'PRO_CODIGO'},
      {label: 'NOMBRE', value: 'PRO_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'PRO_NOMBREC'},
      {label: 'GRUPO', value: 'GRU_CODIGO'},
      {label: 'RUC', value: 'PRO_RUCIDE'},
      {label: 'CUENTA', value: 'CON_CODIGO1'},
     
    ];
    this.busquedacampos = ['', '', '', '', '', ''];
    this.consulta = 'PRO_NOMBRE, PRO_CODIGO, PRO_NOMBREC, GRU_CODIGO, PRO_RUCIDE, CON_CODIGO1';
    this.tabla = 'COM_MAEPROVEEDOR';
    this.where = where;
    this.busquedaSer.busquedaComMaeProveedor(this.where).subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  verificaFechas(): boolean {
    if (this.strTipo.name.toLowerCase().trim() === 'cheque') {
      const strMesv = ('' + (this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO.getMonth() + 1)).length === 1 ? '0'
        + (this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO.getMonth() + 1) : '' + (
          this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO.getMonth() + 1);
      const strDiav = ('' + this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO.getDate()).length === 1 ? '0'
        + this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO.getDate() : '' + this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO.getDate();
      const strAniov = ('' + this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO.getFullYear());
    
      const strFechaVen = strAniov + '-' + strMesv + '-' + strDiav;
    
      if (Date.parse(this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO) >= Date.parse(this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION)) {
        return true;
      } else {
      
        return false;
      }
    } else {
      return true;
    }
  }
  
  guardarRegistro() {
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = this.bantrnbanEditService.banTrnbanSelect.IMPORTE;
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = this.init.quitarComas(this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL);
  
    if (!this.isBandera) {
      if (this.permisosTrnBan != null) {
        if (this.permisosTrnBan.PERTODO === 0) {
          if (this.permisosTrnBan.PERACTUALIZACION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible '
              + 'insertar el registro seleccionado debido a que usted no pesee permisos de actualización');
            return;
          }
        }
      }
      this.actualizarRegistro();
      return;
    }
  
    if (this.isBandera) {
      if (this.permisosTrnBan != null) {
        if (this.permisosTrnBan.PERTODO === 0) {
          if (this.permisosTrnBan.PERINSERCION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible '
              + 'insertar el registro seleccionado debido a que usted no pesee permisos de inserción');
            return;
          }
        }
      }
      this.isBandera = false;
      this.insertarRegistro();
      return;
    }
    
  }
  
  async insertarRegistro() {
    if (this.boolAnulado) {
      this.intAnulado = 1;
    } else {
      this.intAnulado = 0;
    }
  
    let strPagoElectronico = '';
    let Fecha = '';
    if (this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.toString().includes('-')) {
       Fecha = this.datePipe.transform(this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION,
        'dd/MM/yyyy');
    }
    
    let FechaVen = '';
    if (this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO.toString().includes('-')) {
       FechaVen = this.datePipe.transform(this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO,
        'dd/MM/yyyy');
    }
  
    let strTrnBanNumero = '';
  
    if (this.selectTab === 0) {
    
      if (this.boolPagoElect) {
        strPagoElectronico = '1';
      } else {
        strPagoElectronico = '0';
      }
  
      const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);
      //GSRF
      console.log(indexCmb)
      if (indexCmb === 1 || indexCmb === 4) {
        this.bantrnbanEditService.consulta_con_cod().subscribe((conCod) => {
          this.insertar(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, Fecha, this.tipoDoc(),
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, '', this.bantrnbanEditService.banTrnbanSelect.IMPORTE,
            this.bantrnbanEditService.banTrnbanSelect.CONCEPTO, this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO,
            this.intAnulado,  conCod[0].CON_CODIGO, '01',
            this.bantrnbanEditService.banTrnbanSelect.ORIGEN, this.bantrnbanEditService.banTrnbanSelect.REFERENCIA, FechaVen,
            '', this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO, strPagoElectronico);
          this.bantrnbanEditService.actualiza_ultCH().subscribe((actCheq) => {});
  
        });
        
      } else {
        this.bantrnbanEditService.consulta_con_cod().subscribe((conCod) => {
          this.insertar(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, Fecha, this.tipoDoc(),
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, '', this.bantrnbanEditService.banTrnbanSelect.IMPORTE,
            this.bantrnbanEditService.banTrnbanSelect.CONCEPTO, this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO, this.intAnulado,
            conCod[0].CON_CODIGO, '01', this.bantrnbanEditService.banTrnbanSelect.ORIGEN, this.bantrnbanEditService.banTrnbanSelect.REFERENCIA,
            Fecha, '', this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO, strPagoElectronico);
        });
  
        if (indexCmb === 0) {
          this.bantrnbanEditService.consulta_con_cod().subscribe((conCod) => {
           this.insertaAJ('', Fecha, this.tipoDoc(), this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO,
             '', this.bantrnbanEditService.banTrnbanSelect.IMPORTE,
             this.bantrnbanEditService.banTrnbanSelect.CONCEPTO, this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO,
             this.intAnulado, conCod[0].CON_CODIGO, '01', this.bantrnbanEditService.banTrnbanSelect.ORIGEN,
             this.bantrnbanEditService.banTrnbanSelect.REFERENCIA);
          });
        }
      
      }
    //GSRF
      // if (this.boolPagoElect === true) {
      
      //   this.bantrnbanEditService.encontrarTrnBanNumero(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
      //      this.tipoDoc(), this.glbStrNumeroTransaccionBan).subscribe((findReg) => {
      //       strTrnBanNumero = findReg[0].trnban_numero;
      //       this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = strTrnBanNumero;
          
      //   });
      
      // }
    
    
      if (indexCmb === 2) {
      
        if (!this.registroContable && !this.asiento) {
         await this.movimientoCaja();
        }
        
        this.bloqueoS();
      
        if (!this.registroContable && !this.asiento) {
          await this.actualizarNumeroDep(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc());
          // this.bantrnbanEditService.actualizarNumeroDep(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
          //   this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc()).subscribe(() => {});
         
        }
      }
      
      if (indexCmb !== 2 && this.tipoAccion !== 'newCont') {
         this.boolIndex3 = false;
      }
    
      this.numPestana = false;
      this.actualizarSaldoInicial();
      
    }
  
    if (this.selectTab === 1) {
    
      if (this.boolPagoElect === true) {
        strPagoElectronico = '1';
      } else {
        strPagoElectronico = '0';
      }
  
      this.bantrnbanEditService.consulta_con_cod().subscribe((conCod) => {
        this.bantrnbanEditService.insertar(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, Fecha, this.tipoDoc(),
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, '', this.bantrnbanEditService.banTrnbanSelect.IMPORTE_RES,
          this.bantrnbanEditService.banTrnbanSelect.CONCEPTO, this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO,
          this.intAnulado,  conCod[0].CON_CODIGO, '01',
          this.bantrnbanEditService.banTrnbanSelect.ORIGEN, this.bantrnbanEditService.banTrnbanSelect.REFERENCIA, Fecha,
          '', this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO, strPagoElectronico).subscribe((save) => {
           /* this.consultarBanMov(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
              this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, ''); */
            const strClave = this.tipoDoc() + '\'' + this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO + '\'';
            this.auditoriagral.registrarAuditoria('BAN_TRNBAN', strClave, 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
            this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente !!!');
            if (save !== null) {
            this.glbStrNumeroTransaccionBan = save[0][':B1'];
            }
    
        });
        
        this.bantrnbanEditService.actualiza_ultCH().subscribe((actCheq) => {});
    
      });
  
      const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);
    
      if (indexCmb === 2) {
        await this.movimientoCaja();
        this.bloqueoS();
  
        await this.actualizarNumeroDep(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc());
  
        // this.bantrnbanEditService.actualizarNumeroDep(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
        //   this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc()).subscribe(() => {
        //
        //   this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO + '/' +
        //     this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO + '/' + this.tipoDoc() + '/' + 'ActDep.', 'A',
        //     this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        //   });
        //
        // });
      
      
      }
    
    }
  
    if (this.selectTab === 2) {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
                  this.defaultBanTrnBanVar.objeto].stopEditing();
      this.eliminarUltimaLineaVarios();
      this.guardarRegistroVarios();
    
      this.numPestana = true;
    
    }
  
   /* this.consultarBanMov(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
      this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, ''); */
   
  }
  
  bloqueoI() {

    if (this.banCodigo !== '') {
      this.btnGuardar = true;
      if (this.org === 'BAN') {
        this.btnBorrar = false;
      } else {
        this.btnBorrar = true;
      }
      
      this.editCodBan = true;
      this.editCmbTipo = true;
      this.editNum = true;
      this.btnBancos = true;
    }
  }
  
  bloqueoS() {
    
    if (this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO !== '') {
      this.btnGuardar = true;
      if (this.bantrnbanEditService.banTrnbanSelect.ORIGEN === 'BAN') {
        this.btnBorrar = false;
      } else {
        this.btnBorrar = true;
      }
      
      this.editCodBan = true;
      this.editCmbTipo = true;
      this.editNum = true;
      this.btnBancos = true;
    }
  }
  
  seleccionDetVarios(valor) {
  
    if (valor === 'nuevo') {
      if (this.permisosTrnBan != null) {
    
        if (this.permisosTrnBan.PERTODO === 0) {
          if (this.permisosTrnBan.PERINSERCION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible '
             + 'insertar el registro seleccionado debido a que usted no pesee permisos de inserción');
            return;
          }
        }
      }
  
      this.nuevoImporte();
      this.btnGuardar = false;
      this.btnBorrar = true;
      
    
    }
    if (valor === 'eliminar') {
      if (this.permisosTrnBan != null) {
    
        if (this.permisosTrnBan.PERTODO === 0) {
          if (this.permisosTrnBan.PERELIMACION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible '
              + 'eliminar el registro seleccionado debido a que usted no pesee permisos de eliminación');
            return;
          }
        }
      }
  
      this.eliminarRegistroVar();
      this.btnGuardar = false;
      this.btnBorrar = true;
    
    }
    if (valor === 'cancelar') {
    this.cancelarLineaVarios();
    }
    
    if (valor.object !== undefined) {
      this.detalleVariosSel = valor.object;
    
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'CON_CODIGO') {
      
      }
    
    }
  
  }
  
  cambio(event) {
    if (this.org === 'BAN') {
      this.btnGuardar = false;
      this.btnRegresar = false;
      this.btnNuevo = true;
    }
  }
  
  nuevoImporte() {
    const linea = this.bantrnbanEditService.banTrnBanVarios.length;
    const nuevodetVarios = {
      BAN_CODIGO: '',
      TRNBAN_TIPO: '',
      TRNBAN_NUMERO: '',
      CON_CODIGO: '',
      CEN_CODIGO: '',
      TRNBANVAR_DESCRIPCION: '',
      TRNBANVAR_IMPORTE: 0,
      TRNBANVAR_CODSEC: '',
      COM_CODIGO: '01',
      LINEA: linea + 1,
      VARIOS_DEBE: Number(0).toFixed(this.confIniciales.getNumDecSist()),
      VARIOS_HABER: Number(0).toFixed(this.confIniciales.getNumDecSist()),
      CON_NOMBRE: '',
      CON_CENTRO: ''
    };
  
    const indi = this.bantrnbanEditService.banTrnBanVarios.indexOf(this.detalleVariosSel);
   
    this.bantrnbanEditService.banTrnBanVarios.push(nuevodetVarios);
    this.detalleVariosSel=nuevodetVarios;
    this.aggrid.refreshaggridindex(this.bantrnbanEditService.banTrnBanVarios, this.defaultBanTrnBanVar.objeto, indi);
  
    this.aggrid.gridApi.setFocusedCell(indi + 1, 'CON_CODIGO');
    this.boolIndex1 = true;
  
  
  }
  
  eliminarRegistroVar() {
    if (Object.keys(this.detalleVariosSel).length > 0) {
      this.confirmEliminarDetAsi();
    } else {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
    }
  }
  
  confirmEliminarDetAsi() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'trnbanEditConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDetalleSel();
      },
      reject: () => {
      }
    });
  }
  
  eliminarDetalleSel() {
    this.bantrnbanEditService.banTrnBanVarios = this.bantrnbanEditService.banTrnBanVarios.filter((val, j) => j !==
      this.bantrnbanEditService.banTrnBanVarios.indexOf(this.detalleVariosSel));
    this.aggrid.refreshaggrid(this.bantrnbanEditService.banTrnBanVarios, this.defaultBanTrnBanVar.objeto);
  }
  
  cancelarLineaVarios() {
    let intIndiceLista = 0;
    for ( intIndiceLista = 0; intIndiceLista < this.bantrnbanEditService.banTrnBanVarios.length; intIndiceLista++) {
      const item =  this.bantrnbanEditService.banTrnBanVarios[intIndiceLista];
      if (item.CON_CODIGO === '') {
        this.bantrnbanEditService.banTrnBanVarios.splice(this.bantrnbanEditService.banTrnBanVarios.findIndex(
          varios => varios.LINEA === this.detalleVariosSel.LINEA),
          1);
      }
    }
    this.aggrid.refreshColumnDefs();
    this.aggrid.refreshaggrid(this.bantrnbanEditService.banTrnBanVarios, this.defaultBanTrnBanVar.objeto);
  }
  
  
  pierdeFocoImporte() {
    
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);

    if ((indexCmb === 1 || indexCmb === 4 || indexCmb === 5) && !this.bantrnbanEditService.banTrnbanSelect.IMPORTE
      .startsWith('-')) {
      this.bantrnbanEditService.banTrnbanSelect.IMPORTE = '-' + this.bantrnbanEditService.banTrnbanSelect.IMPORTE;
    }
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE).toFixed(
      this.confIniciales.getNumDecSist());
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = this.init.moneyValidation(this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL);
  
  }
  
  cambioCmb(valor) {
  
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);
  
    if (indexCmb === 1) {
      this.boolFechaVence = false;
      this.boolImporte = false;
    
      this.bantrnbanEditService.configCH().subscribe((cheque) => {
        if (cheque[0].CFG_VALOR1 === 1) {
          this.bantrnbanEditService.auto_numCHEQUE().subscribe((ultCheq) => {
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = ultCheq[0].NUMCHEQUE;
          });
        } else {
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = '';
        }
      });
    
    } else {
      this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = '';
      this.boolFechaVence = true;
      this.boolImporte = false;
    }
  
    if (indexCmb === 2) {
      this.bantrnbanEditService.banTrnbanSelect.IMPORTE = '' + 0.0;
      if (this.asiento) {
        this.boolImporte = false;
        this.boolIndex2 = true;
      } else {
        this.boolImporte = true;
        this.boolIndex2 = false;
      }
    } else {
      this.boolIndex2 = true;
    }
  
    if (indexCmb === 0) {
      this.bantrnbanEditService.consulta_max_docnum(' AND TRNBAN_TIPO =\'AJ\'', 'AJ').subscribe((consulta) => {
        this.numSec = consulta[0].NUMSEC;
      });
    }
  
    if (indexCmb === 1) {
      this.bantrnbanEditService.consulta_max_docnum(' AND TRNBAN_TIPO =\'CH\'', 'CH').subscribe((consulta) => {
        this.numSec = consulta[0].NUMSEC;
      });
    }
  
    if (indexCmb === 2) {
      this.bantrnbanEditService.consulta_max_docnum(' AND TRNBAN_TIPO =\'DP\'', 'DP').subscribe((consulta) => {
        this.numSec = consulta[0].NUMSEC;
      });
    }
    
    if (indexCmb === 3) {
      this.bantrnbanEditService.consulta_max_docnum(' AND TRNBAN_TIPO =\'NC\'', 'NC').subscribe((consulta) => {
        this.numSec = consulta[0].NUMSEC;
      });
    }
    if (indexCmb === 4) {
      this.bantrnbanEditService.consulta_max_docnum(' AND TRNBAN_TIPO =\'ND\'', 'ND').subscribe((consulta) => {
        this.numSec = consulta[0].NUMSEC;
      });
    }
    if (indexCmb === 5) {
      this.bantrnbanEditService.consulta_max_docnum(' AND TRNBAN_TIPO =\'RE\'', 'RE').subscribe((consulta) => {
        this.numSec = consulta[0].NUMSEC;
      });
    }
    this.secuencial = this.numSec;
  
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE = Number(0).toFixed(this.confIniciales.getNumDecSist());
    
   /* if (indexCmb === 2) {
    this.boolIndex2 = false;
    } else {
      this.boolIndex2 = true;
    } */
    
   /* if (indexCmb === 1) {
      this.boolFechaVence = false;
    } else {
      this.boolFechaVence = true;
    } */
    
   // this.bantrnbanEditService.banTrnbanSelect.IMPORTE = Number(0).toFixed(this.confIniciales.getNumDecSist());
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = this.bantrnbanEditService.banTrnbanSelect.IMPORTE;
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = this.init.moneyValidation(this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL);
  
  }
  
  insertar(bancodigo, fechEmision, tipo, numero, numDoc, importe, concepto, beneficiario, AN, conCodigo,
           comcodigo, org, referencia, fechacont, strEncRequisicion, strCodProveedor, strPagoElectronico) {
            let strTrnBanNumero = '';
    this.bantrnbanEditService.insertar(bancodigo, fechEmision, tipo, numero, numDoc, importe, concepto, beneficiario, AN, conCodigo,
      comcodigo, org, referencia, fechacont, strEncRequisicion, strCodProveedor, strPagoElectronico).pipe(finalize(() => {if (this.boolPagoElect === true) {
        if (this.tipoDoc() === 'CH'){
      console.log('entra pago electronico')
        this.bantrnbanEditService.encontrarTrnBanNumero1(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
           this.tipoDoc(), this.glbStrNumeroTransaccionBan).subscribe((findReg) => {
            console.log('entra pago electronico', findReg)
            console.log('entra pago electronico', findReg[0].TRNBAN_NUMERO);
            strTrnBanNumero = findReg[0].TRNBAN_NUMERO ;
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = strTrnBanNumero;
        });
        }
      }})).subscribe((save) => {
      const strClave = this.tipoDoc() + '\'' + this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO + '\'';
      this.auditoriagral.registrarAuditoria('BAN_TRNBAN', strClave, 'I',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente !!!');
  
  
      if (save !== null) {
        this.glbStrNumeroTransaccionBan = save[0][':B1'];
      }
  
    });
  }
  
  insertaAJ(codAjus, fechEmision, tipo, numero, numDoc, importe, concepto, beneficiario, AN, conCodigo, comcodigo, org, referencia) {
    this.bantrnbanEditService.insertaAJ(codAjus, fechEmision, tipo, numero, numDoc, importe, concepto, beneficiario, AN, conCodigo,
      comcodigo, org, referencia).subscribe((ajuste) => {});
  }
  
  async movimientoCaja() {
    
    if (await this.eliminaTrnCajaDeposito(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
      this.tipoDoc(), this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO)) {
      console.log('cumplio paso1');
      if (await this.guarda_tabla_detalle(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
        this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc())) {
        console.log('cumplio paso2');
        if (await this.ban_sp_actlzsaldotrncajxtrnban(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO)) {
          console.log('cumplio paso 3');
          let Fecha = '';
          if (this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.toString().includes('-')) {
            Fecha = this.datePipe.transform(this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION,
                'dd/MM/yyyy');
          }
          if (await this.genera_asiento_trn(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, Fecha, 'BAN' )) {
            console.log('cumplio paso 4');
            if (this.CargarTrnCajaDeposito(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
              this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO)) {
              console.log('cumplio paso 5');
            }
          }
          
        }
      }
      
    }
    
      // this.bantrnbanEditService.eliminaTrnCajaDeposito(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
      //   this.tipoDoc(), this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO).subscribe((elimDep) => {
      //     this.guarda_tabla_detalle(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
      //       this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc());
      //
      // });
      
  }
  
  async eliminaTrnCajaDeposito(banCodigo, banTipo, numero) {
    try {
      await this.bantrnbanEditService.eliminaTrnCajaDepositoProm(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
        this.tipoDoc(), this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO);
      return true;
    } catch (err) {
      this.mensajeAlerta('error', 'Error', 'Error al eliminar las transacciones de caja');
      return false;
    }
  }
  
  actualizarSaldoInicial() {
    let  strSaldoInicial = '';
    if (this.boolSaldoInicial) {
      strSaldoInicial = '1';
    } else {
      strSaldoInicial = '0';
    }
    this.bantrnbanEditService.actualizarSaldoinicial(strSaldoInicial, this.tipoDoc()).subscribe((saldoIni) => {});
  }
  
  habilita_Pestañas() {
  
    const indice = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);
    
    if (this.selectTab !== 0 && (this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE.length === 0)) {
  
      this.boolIndex1 = true;
      this.boolIndex2 = false;
      this.boolIndex3 = false;
      this.mensajeAlerta('info', 'INFORMACION', 'PRIMERO SELECCIONE UN BANCO');
      return;
    }
    
    if (indice === 2 && this.selectTab === 1) {
      this.selectTab = 1;
      this.boolIndex2 = true;
      this.boolIndex1 = false;
      this.boolIndex3 = false;
      return;
    }
    
    if (this.btnGuardar) {
      if (indice === 0 && this.selectTab === 1) {
        this.selectTab = 0;
        this.boolIndex1 = true;
        this.boolIndex2 = false;
        this.boolIndex3 = false;
      }
      if (indice === 1 && this.selectTab === 1) {
        this.selectTab = 0;
        this.boolIndex1 = true;
        this.boolIndex2 = false;
        this.boolIndex3 = false;
      }
      
      if (indice === 3 && this.selectTab === 1) {
        this.selectTab = 0;
        this.boolIndex1 = true;
        this.boolIndex2 = false;
        this.boolIndex3 = false;
      }
      
      if (indice === 4 && this.selectTab === 1) {
        this.selectTab = 0;
        this.boolIndex1 = true;
        this.boolIndex2 = false;
        this.boolIndex3 = false;
      }
      if (indice === 5 && this.selectTab === 1) {
        this.selectTab = 0;
        this.boolIndex1 = true;
        this.boolIndex2 = false;
        this.boolIndex3 = false;
      }
      if (indice === 2 && this.selectTab === 2) {
        this.selectTab = 0;
        this.boolIndex1 = true;
        this.boolIndex2 = false;
        this.boolIndex3 = false;
      }
    } else {
      if (this.selectTab === 2) {
        this.selectTab = 0;
        this.boolIndex1 = true;
        this.boolIndex2 = false;
        this.boolIndex3 = false;
      }
      if (this.selectTab === 1) {
        this.selectTab = 0;
        this.boolIndex1 = true;
        this.boolIndex2 = false;
        this.boolIndex3 = false;
      }
      
    }
  }
  
  eliminarUltimaLineaVarios() {
    let intIndiceLista = 0;
  
    for (intIndiceLista = 0; intIndiceLista < this.bantrnbanEditService.banTrnBanVarios.length; intIndiceLista++) {
      const item = this.bantrnbanEditService.banTrnBanVarios[intIndiceLista];
      if (item.CON_CODIGO === '') {
        this.bantrnbanEditService.banTrnBanVarios.splice(this.bantrnbanEditService.banTrnBanVarios.findIndex(
          varios => varios.LINEA === this.detalleVariosSel.LINEA),
          1);
      }
    
    }
    this.aggrid.refreshColumnDefs();
    this.aggrid.refreshaggrid(this.bantrnbanEditService.banTrnBanVarios, this.defaultBanTrnBanVar.objeto);
    // this.conencasilService.calcularCostoTotal();
  }
  
  guardarRegistroVarios() {
    if (this.boolAnulado) {
      this.intAnulado = 1;
    } else {
      this.intAnulado = 0;
    }
  
    let Fecha = '';
    if (this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.toString().includes('-')) {
      Fecha = this.datePipe.transform(this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION,
        'dd/MM/yyyy');
    }
    this.guardarVarios(this.tipoDoc(), this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO,
      this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, '', this.init.quitarComas(this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL),
      '', '', this.bantrnbanEditService.banTrnbanSelect.CONCEPTO, '', '01', Fecha,
      this.bantrnbanEditService.banTrnbanSelect.ORIGEN);
  
    //  strNumAsi = this.strAsiNro;
    // if (ModificarMovCajaModeloVarios.isErrorF()) {
    //  if (strNumAsi != null)
    //    lblAsiNro.setText(strNumAsi);
    
    // }
  }
  
 async  guardarVarios(strTipo, strTrnBanNumero, strBanCodigo, strTrnBanDocNro, strImporte, conCodigo, cenCodigo,
                Concepto, codsec, comCodigo, fecha, origen) {
    
                  this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
                  this.defaultBanTrnBanVar.objeto].stopEditing();
  
    const dblImporte = Number(strImporte);
  
    let bolGuardaVarios = false;
    let strTrnCajVarImporte = 0;
    
    const dblSaldoVarios = this.totalImporteDH();
  
    if ( Math.abs(dblImporte) === Math.abs(dblSaldoVarios) ) {
    //GSRF
    for (let intIndiceLista = 0; intIndiceLista < this.bantrnbanEditService.banTrnBanVarios.length; intIndiceLista++) {
      if (this.bantrnbanEditService.banTrnBanVarios[intIndiceLista].CON_CODIGO.substr(-1, 1) === '.') {
        this.mensajeAlerta('error', 'Cuenta Contable', 'No se puede elegir esa cuenta contable !!!');
        this.bantrnbanEditService.banTrnBanVarios[intIndiceLista].CON_CODIGO = '';
        this.bantrnbanEditService.banTrnBanVarios[intIndiceLista].CON_NOMBRE = '';
        this.aggrid.refreshaggridindex(this.bantrnbanEditService.banTrnBanVarios, this.defaultBanTrnBanVar.objeto, intIndiceLista);
        return;
      }
      let numdep = await this.bantrnbanEditService.getcuentconta(this.bantrnbanEditService.banTrnBanVarios[intIndiceLista].CON_CODIGO);
      console.log('NC',numdep.length);
      if (numdep.length === 0) {
        this.mensajeAlerta('error', 'Cuenta Contable', 'Cuenta contable no existe'); 
        return;
      }

    }
    //GSRF
      // setErrorF(true);
     // this.eliminarVarios(strTipo, strTrnBanNumero, strBanCodigo, strTrnBanDocNro, '01');
  
      this.bantrnbanEditService.eliminarVarios(strTipo, strTrnBanNumero, strBanCodigo, strTrnBanDocNro, '01').subscribe(async () => {
        const strClave = strTipo + '\'' + strTrnBanNumero + '\'';
        this.auditoriagral.registrarAuditoria('BAN_TRNCAJVAR', strClave, 'E',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
  
        for (let intIndiceLista = 0; intIndiceLista < this.bantrnbanEditService.banTrnBanVarios.length; intIndiceLista++) {
          const itemVar = this.bantrnbanEditService.banTrnBanVarios[intIndiceLista];
    
    
    
          let suma = Number(itemVar.VARIOS_DEBE === null ? '' + this.confIniciales.getNumDecSist() :
            itemVar.VARIOS_DEBE.trim() === '' ? '' +  this.confIniciales.getNumDecSist() : itemVar.VARIOS_DEBE);
         
          
          suma = suma * -1;
         
          const aux = Number(itemVar.VARIOS_HABER === null ? '' + this.confIniciales.getNumDecSist() :
            itemVar.VARIOS_HABER.trim() === '' ? '' + this.confIniciales.getNumDecSist() : itemVar.VARIOS_HABER);
          
    
    
          strTrnCajVarImporte = (suma + aux) * -1;
    
          await this.bantrnbanEditService.guardarVarios(strBanCodigo, strTipo, strTrnBanNumero, itemVar.CON_CODIGO, itemVar.CEN_CODIGO,
            Concepto, '' + strTrnCajVarImporte, '' + this.bantrnbanEditService.fechaHraSistema(),
            '01').toPromise()//subscribe(() => {
            bolGuardaVarios = true;
            // nuevoItem = false;
            // filaNueva = -1;
            const strClave2 = strTipo + '\'' + strTrnBanNumero + '\'' + itemVar.CON_CODIGO;
            this.auditoriagral.registrarAuditoria('BAN_TRNCAJVAR', strClave2, 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
            this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente !!!');
            this.generarAsiento();
            // setErrorF(true);
          //});
    
        }
        this.boolIndex1 = false;
      });
      
       // cierre del for
    } else {
      this.mensajeAlerta('error', 'Error', 'El valor del total del importe en Varios es distinto al valor del importe de caja.');
     // setErrorF(false);
      bolGuardaVarios = false;
      return;
    }
  
  }
  
  totalImporteDH(): number {
    
    let dblDebe = 0;
    let dblHaber = 0;
    let dblSaldo = 0;
    let dblsaldoActual = 0;
    
    
    
    for ( const item of this.bantrnbanEditService.banTrnBanVarios ) {
      let debe = 0;
      if (Number(item.VARIOS_DEBE) > 0 ) {
          debe = -item.VARIOS_DEBE;
      }
      dblDebe = Number(item.VARIOS_DEBE === null ? '0' : debe);
      dblHaber = Number(item.VARIOS_HABER === null ? '0' : item.VARIOS_HABER);
      
      dblSaldo =  dblHaber + dblDebe;
  
      dblsaldoActual  += dblSaldo;
      
  
    }
    
    dblsaldoActual =  Number(dblsaldoActual.toFixed(this.confIniciales.getNumDecSist()));
    
    return dblsaldoActual;
  }
  
  eliminarVarios(strTipo, strTrnCajNumero, strCajCodigo, strTrnCajDocNro, strCpnia) {
    this.bantrnbanEditService.eliminarVarios(strTipo, strTrnCajNumero, strCajCodigo, strTrnCajDocNro, strCpnia).subscribe(() => {
      const strClave = strTipo + '\'' + strTrnCajNumero + '\'';
      this.auditoriagral.registrarAuditoria('BAN_TRNCAJVAR', strClave, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    });
  }
  
  
  
  generarAsiento() {
    
    this.cont = this.cont + 1;
    // let bolGeneraAsto = false;
  
    let Fecha = '';
    if (this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.toString().includes('-')) {
      Fecha = this.datePipe.transform(this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION,
        'dd/MM/yyyy');
    }
    
    this.bantrnbanEditService.encontrarTrnBanConcilia(this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO,
      this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc()).subscribe((docCon) => {
        if (docCon[0].TRNBAN_CONCILIA === 1) {
          this.mensajeAlerta('info', 'Información', 'El documento: ' + this.tipoDoc() + ' Número: ' +
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO + 'Banco: ' + this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO +
          ', no se puede contabilizar ya que esta conciliado.');
          return false;
        }
        this.bantrnbanEditService.generarAsiento(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
          this.tipoDoc(), this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, Fecha,
          this.bantrnbanEditService.banTrnbanSelect.ORIGEN, '01', this.usuario.identificacion).subscribe((genAsi) => {
          this.strAsiNro = genAsi[0][':B1'];
          // if (this.bantrnbanEditService.banTrnBanVarios.length === this.cont + 1) {
            
         // this.mensajeAlerta('success' , 'Información' , 'El Asiento Contable se generó correctamente');
          this.btnGuardar = true;
          this.btnBorrar = false;
    
        //  }
          this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = this.strAsiNro;
  
        });
    });
    
    
  }
  
  
  async actualizarRegistro() {
    this.numsecAc = this.numDoc;
    let strPagoElectronico = '';
  
    // System.out.println(chbtnAnulado.getSelection());
  
    if (this.boolAnulado) {
      this.intAnulado = 1;
    } else {
      this.intAnulado = 0;
    
    }
  
    let Fecha = '';
    if (this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.toString().includes('-')) {
      Fecha = this.datePipe.transform(this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION,
        'dd/MM/yyyy');
    }
  
    let FechaCont = '';
    if (this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO.toString().includes('-')) {
      FechaCont = this.datePipe.transform(this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO,
        'dd/MM/yyyy');
    }
  
    if (this.boolPagoElect) {
      strPagoElectronico = '1';
    } else {
      strPagoElectronico = '0';
    }
  
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);
  
  
    if (this.selectTab === 0) {
    
      if (indexCmb === 1 || indexCmb === 4) {
        this.bantrnbanEditService.consulta_con_cod().subscribe((conCod) => {
          this.actualizaRegistro(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, Fecha, this.tipoDoc(),
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, '', this.bantrnbanEditService.banTrnbanSelect.IMPORTE,
            this.bantrnbanEditService.banTrnbanSelect.CONCEPTO, this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO,
            this.intAnulado, conCod[0].CON_CODIGO, '01',
            this.bantrnbanEditService.banTrnbanSelect.ORIGEN, this.bantrnbanEditService.banTrnbanSelect.REFERENCIA,
            FechaCont, '', this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO, strPagoElectronico);
        });
        
      } else {
  
        this.bantrnbanEditService.consulta_con_cod().subscribe((conCod) => {
          this.actualizaRegistro(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, Fecha, this.tipoDoc(),
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, '', this.bantrnbanEditService.banTrnbanSelect.IMPORTE,
            this.bantrnbanEditService.banTrnbanSelect.CONCEPTO, this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO,
            this.intAnulado, conCod[0].CON_CODIGO, '01',
            this.bantrnbanEditService.banTrnbanSelect.ORIGEN, this.bantrnbanEditService.banTrnbanSelect.REFERENCIA,
            Fecha, '', this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO, strPagoElectronico);
        });
      
      }
    
      if (indexCmb === 2) {
      
        if (!this.registroContable && !this.asiento) {
          await this.movimientoCaja();
          
          await this.actualizarNumeroDep(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
            this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc());
          
          // this.bantrnbanEditService.actualizarNumeroDep(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
          //   this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc()).subscribe(() => {
          //
          //   this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO + '/' +
          //     this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO + '/' + this.tipoDoc() + '/' + 'ActDep.', 'A',
          //     this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          //   });
          //
          // });
        }
        this.bloqueoS();
      }
    
    }
  
    if (this.selectTab === 1) {
    
      if (this.boolPagoElect) {
        strPagoElectronico = '1';
      } else {
        strPagoElectronico = '0';
      }
      this.bantrnbanEditService.consulta_con_cod().subscribe((conCod) => {
        
        this.bantrnbanEditService.actualizaRegistro(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, Fecha, this.tipoDoc(),
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, '', this.bantrnbanEditService.banTrnbanSelect.IMPORTE_RES,
          this.bantrnbanEditService.banTrnbanSelect.CONCEPTO, this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO, this.intAnulado,
          conCod[0].CON_CODIGO, '01', this.bantrnbanEditService.banTrnbanSelect.ORIGEN,
          this.bantrnbanEditService.banTrnbanSelect.REFERENCIA, Fecha, '', this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO,
          strPagoElectronico).subscribe(() => {
            /*this.consultarBanMov(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
              this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.bantrnbanEditService.banTrnbanSelect.ASI_NRO); */
            const  strClave = this.tipoDoc() + '\'' + this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO + '\'';
            this.auditoriagral.registrarAuditoria('BAN_TRNBAN', strClave, 'A',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
            this.mensajeAlerta('success', 'Información', 'Registro actualizado exitosamente !!!');
  
        });
      });
      
    
      if (indexCmb === 2) {
        if (!this.registroContable && !this.asiento) {
          await this.movimientoCaja();
  
        }
        this.bloqueoS();
      
        /* this.consultarBanMov(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, 'AND ASI_NRO = \'' +
          this.bantrnbanEditService.banTrnbanSelect.ASI_NRO + '\''); */
        // this.mostrarDatos(operAct);
  
        await this.actualizarNumeroDep(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc());
        
        // this.bantrnbanEditService.actualizarNumeroDep(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
        //   this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc()).subscribe(() => {
        //
        //   this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO + '/' +
        //     this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO + '/' + this.tipoDoc() + '/' + 'ActDep.', 'A',
        //     this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        //   });
        //
        // });
      }
    
    }
    if (this.selectTab === 2) {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
                  this.defaultBanTrnBanVar.objeto].stopEditing();
      this.eliminarUltimaLineaVarios();
      this.guardarRegistroVarios();
    
    }
  
    /*this.consultarBanMov(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
      this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.bantrnbanEditService.banTrnbanSelect.ASI_NRO);*/
    
   // mostrarDatos(operAct);
  }
  
  actualizaRegistro(bancodigo, fechEmision, tipo, numero, numDoc, importe, concepto, beneficiario, AN, conCodigo, comcodigo, org,
                    referencia, fechacont, strEncRequisicion, strCodProveedor, strPagoElectronico) {
    
    this.bantrnbanEditService.actualizaRegistro(bancodigo, fechEmision, tipo, numero, numDoc, importe, concepto, beneficiario, AN, conCodigo,
      comcodigo, org, referencia, fechacont, strEncRequisicion, strCodProveedor, strPagoElectronico).subscribe(() => {
      const  strClave = tipo + '\'' + numero + '\'';
      this.auditoriagral.registrarAuditoria('BAN_TRNBAN', strClave, 'A',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    });
    
  }
  
  verificaValores(importe): number {
    const valor = this.totalImporteDH() - Number(importe);
    return valor;
  }
  
  consultarVarios(strTrnbanTipo, strtrnbanNumero, strBanCodigo) {
    this.bantrnbanEditService.consultarDatos(strTrnbanTipo, strtrnbanNumero, strBanCodigo).subscribe((datosVarios) => {
      
      if (datosVarios !== null && datosVarios.length > 0) {
         this.bantrnbanEditService.banTrnBanVarios = datosVarios;
         this.bantrnbanEditService.banTrnBanVarios.map(item => {
           if (Number(item.TRNBANVAR_IMPORTE) > 0) {
             item.VARIOS_DEBE = item.TRNBANVAR_IMPORTE;
             item.VARIOS_HABER = 0.0;
           } else {
             item.VARIOS_DEBE = 0.0;
             item.VARIOS_HABER = item.TRNBANVAR_IMPORTE * -1;
           }
  
           item.VARIOS_DEBE = item.VARIOS_DEBE.toFixed(this.confIniciales.getNumDecSist());
           item.VARIOS_HABER = item.VARIOS_HABER.toFixed(this.confIniciales.getNumDecSist());
         });
      }
    });
  }
  
  cambioAnulado(event) {
  
    const dia = this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.getDate();
    const mes = this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.getMonth();
    const anio = this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.getFullYear();
  
    this.bantrnbanEditService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
      if (per[0].CONTADOR === 1) {
        this.anulacion();
      } else {
        this.mensajeAlerta('error', 'ERROR', 'La Fecha del documento debe estar dentro del periodo contable.');
      }
    });
    
  }

  anulacion() {
    if (this.bantrnbanEditService.banTrnbanSelect.ASI_NRO !== '') {
        this.confirmationService.confirm({
          message: '¿ Está seguro anular el documento ?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'trnbanEditConf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.boolAnulado = true;
            if (this.boolAnulado) {
              this.bantrnbanEditService.anularAsientoContable(this.bantrnbanEditService.banTrnbanSelect.ASI_NRO.trim()).subscribe(() => {
                if (!this.isBandera) {
                  this.bantrnbanEditService.eliminaTrnCajaDeposito(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
                    this.tipoDoc(), this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO).subscribe(() => {
                    /*actualiza_tabla_deposito(txtCodBanco.getText(),
                      txtNum.getText(), tipoDoc()); */
                      document.getElementById('divPrincipal3').style.pointerEvents = 'none';
                      this.mensajeAlerta('info', 'Información', 'El Asiento Contable esta ANULADO');
                      this.btnGuardar = true;
                      this.btnBorrar = true;
                      this.boolIndex2 = true;
                      this.boolIndex3 = true;
                  });
                }
              });
            }
          },
          reject: () => {
            this.boolAnulado = false;
          }
        });
    } else {
      this.mensajeAlerta('error', 'ERROR', '"No se puede anular un documento sin asiento contable');
      this.boolAnulado = false;
      return;
    }
  }
  
  eliminarDocumento() {
  
    const dia = this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.getDate();
    const mes = this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.getMonth();
    const anio = this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.getFullYear();
  
    this.bantrnbanEditService.verificarPeriodoContable(dia, mes, anio).subscribe(async (per) => {
      if (per[0].CONTADOR === 1) {
        if (this.bantrnbanEditService.banTrnbanSelect.ORIGEN === 'BAN') {
          
          //this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO//-------
          const res1=await this.pagocontadoService.consultarconciliacion(this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO,this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO);
          if (res1 !== null && res1 !== undefined) {
            if (res1[0] !== null && res1[0] !== undefined && res1[0] !== '') {
              if (res1[0].NUMERO !== ''
                && res1[0].NUMERO !== null
                && res1[0].NUMERO !== undefined
                && res1[0].NUMERO !== 0) {
                this.mensajeAlerta('error', 'Documentos Conciliados', 'No se puede eliminar porque el pago tiene documentos conciliados');
                //numero=res[0].TRNBAN_NUMERO;
                //errores = errores + 1;
              }else{
                this.eliminaDatos();
              }
            }
          }
        } else {
          this.mensajeAlerta('error', 'ERROR', 'El documento no se puede eliminar, no pertenece a BANCOS');
        }
      } else {
        this.mensajeAlerta('error', 'ERROR', 'La Fecha del documento debe estar dentro del periodo contable.');
      }
    });
  }
  
  eliminaDatos() {
    if (this.permisosTrnBan != null) {
      if (this.permisosTrnBan.PERTODO === 0) {
        if (this.permisosTrnBan.PERELIMACION === 0) {
          this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible '
            + 'eliminar el registro seleccionado debido a que usted no pesee permisos de eliminación');
          return;
        }
      }
    }
  
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);
  
    this.bantrnbanEditService.cxp_fnc_verificar_trans_cash(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO + '-' + this.tipoDoc() +
      '-' + this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, '', 'BANCOS').subscribe((cash) => {
      if (cash[0].RESULTADO >= 1) {
        this.mensajeAlerta('info', 'Información', 'Documento incluido en el Cash Management no puede eliminar');
        return;
      }
  
      if (indexCmb === 0) {
        this.bantrnbanEditService.eliminarTRNBANAJCDET(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc()).subscribe(() => {
          this.bantrnbanEditService.eliminar_TRNBANAJCVAR(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO).subscribe(() => {
            this.bantrnbanEditService.borra_ASI_NRO(this.bantrnbanEditService.banTrnbanSelect.ASI_NRO);
            this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = '';
            // this.limpiaCampos();
          }, error1 => {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible eliminar el registro seleccionado');
          });
        }, error1 => {
          this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible eliminar el registro seleccionado');
        });
      }
  
      if (this.bantrnbanEditService.banTrnbanSelect.ASI_NRO.startsWith('ABA')) {
        this.bantrnbanEditService.eliminaTrnCajaDeposito(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO).subscribe(() => {
            this.bantrnbanEditService.eliminarVarios(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
              this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, '', '01').subscribe(() => {
              this.bantrnbanEditService.eliminarBancoMovimiento(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
                this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO).subscribe(() => {
                const strClave = this.tipoDoc() + '\'' + this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO + '\'';
                this.auditoriagral.registrarAuditoria('BAN_TRNBAN', strClave, 'E',
                  this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                });
                this.bantrnbanEditService.anularAsientoContable(this.bantrnbanEditService.banTrnbanSelect.ASI_NRO).subscribe(() => {
  
                /*  actualiza_tabla_deposito(txtCodBanco.getText(),
                    txtNum.getText(), tipoDoc()); */
                  this.mensajeAlerta('info', 'Información', 'El Asiento Contable está ANULADO');
                  this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = '';
                  this.limpiaCampos();
                  this.boolIndex2 = true;
                  this.boolIndex3 = true;
                }, error1 => {
                  this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible eliminar el registro seleccionado');
  
                });
              });
            });
        }, error1 => {
          this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible eliminar el registro seleccionado');
        });
        
      } else {
  
        this.bantrnbanEditService.eliminarBancoMovimiento(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO).subscribe(() => {
            const strClave = this.tipoDoc() + '\'' + this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO + '\'';
            this.auditoriagral.registrarAuditoria('BAN_TRNBAN', strClave, 'E',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = '';
          this.limpiaCampos();
        }, error1 => {
          this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible eliminar el registro seleccionado');
    
        });
      }
    });
    
    this.btnGuardar = true;
    this.btnBorrar = true;
  }
  
  limpiaCampos() {
    this.strTipo = this.cmbTipo[0];
    this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO = '';
    this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO = '';
    this.bantrnbanEditService.banTrnbanSelect.CONCEPTO = '';
    this.bantrnbanEditService.banTrnbanSelect.NUMERO_CONCILIACION = '';
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE = '';
    this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE = '';
    this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = '';
    this.bantrnbanEditService.banTrnbanSelect.NRO_DOCUMENTO = '';
    this.bantrnbanEditService.banTrnbanSelect.REFERENCIA = '';
    this.bantrnbanEditService.banTrnbanSelect.ORIGEN = 'BAN';
    this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = '';
    this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = '';
    this.bantrnbanEditService.banTrnbanSelect.PRO_CODIGO = '';
    this.bantrnbanEditService.banTrnbanSelect.PRO_NOMBRE = '';
    this.boolPagoElect = false;
    
  }
  
  cambioAbono(e) {
    if (e.checked) {
      this.boolCancelacion = false;
      this.boolAbono = true;
  
      this.bantrnbanEditService.boolAbono = true;
      this.bantrnbanEditService.boolCancelacion = false;
    } else {
      this.boolCancelacion = true;
      this.boolAbono = false;
  
      this.bantrnbanEditService.boolAbono = false;
      this.bantrnbanEditService.boolCancelacion = true;
    }
  }
  
  cambioCancelacion(e) {
    if (e.checked) {
      this.boolAbono = false;
      this.boolCancelacion = true;
  
      this.bantrnbanEditService.boolAbono = false;
      this.bantrnbanEditService.boolCancelacion = true;
    } else {
      this.boolCancelacion = false;
      this.boolAbono = true;
  
      this.bantrnbanEditService.boolAbono = true;
      this.bantrnbanEditService.boolCancelacion = false;
    }
  }
  
  selectTodos(e) {
    if (e.checked) {
      this.boolMovConta = true;
      this.boolMovCons = true;
      this.boolMovCartAct = true;
      this.boolMovVentas = true;
      this.boolMovCaja = true;
      this.boolMovCartPas = true;
    } else {
      this.boolMovConta = false;
      this.boolMovCons = false;
      this.boolMovCartAct = false;
      this.boolMovVentas = false;
      this.boolSelectTodos = false;
      this.boolMovCaja = false;
      this.boolMovCartPas = false;
    }
  }
  
  consultaFiltros() {
    if (this.bantrnbanEditService.banTrnbanSelect.ASI_NRO === '') {
      if (this.filtros_chkbtn() !== null) {
        let co = 0;
        for (let i = 1; i < this.bantrnbanEditService.banTrnBanCaja.length + 1 ; i++) {
          co += 1;
        }
        if (co > 0) {
        
          if (this.strBodega.name === '') {
          
            this.actualiza_tabla(this.condicion(), 'PEN', '');
          
          }
          if (this.strBodega.name !== '') {
            this.actualiza_tabla(this.condicion(), 'BPEN', this.strCaja.codigo);
          }
        
        } else {
        
          if (this.strBodega.name === '') {
          
            this.actualiza_tabla(this.condicion(), 'T', '');
          
          }
          if (this.strBodega.name !== '') {
            this.actualiza_tabla(this.condicion(), 'BT', this.strCaja.codigo);
          }
        
        }
      
      } else {
        this.actualiza_tabla('TRNCAJ_ORIGEN IN(\'xxx\')', 'PEN', '');
      
      }
    } else {
      if (this.filtros_chkbtn() !== null) {
        let co = 0;
        for (let i = 1; i < this.bantrnbanEditService.banTrnBanCaja.length + 1; i++) {
          co += 1;
        }
        if (co > 0) {
      
          if (this.strBodega.name === '') {
        
            this.actualiza_tabla_actualizacion(this.condicion(), 'PEN', '');
        
          }
          if (this.strBodega.name !== '') {
            this.actualiza_tabla_actualizacion(this.condicion(), 'BPEN', this.strBodega.codigo);
          }
      
        } else {
      
          if (this.strBodega.name === '') {
        
            this.actualiza_tabla_actualizacion(this.condicion(), 'T', '');
        
          }
          if (this.strBodega.name !== '') {
            this.actualiza_tabla_actualizacion(this.condicion(), 'BT', this.strBodega.codigo);
          }
      
        }
    
      } else {
        this.actualiza_tabla_actualizacion('TRNCAJ_ORIGEN IN(\'xxx\')', 'PEN', '');
    
      }
    }
    this.btnGuardar = false;
    this.btnBorrar = true;
  }
  
  filtros_chkbtn(): string {
    let sentencia = null;
    let con;
    let cxc;
    let fac;
    let caj;
    let cxp;
    let cons;
    if (this.boolMovConta) {
      con = ' \'CON\',';
    } else {
      con = '';
    }
    if (this.boolMovCartAct) {
      cxc = ' \'CXC\',';
    } else {
      cxc = '';
    }
    if (this.boolMovVentas) {
      fac = ' \'FAC\',  \'PTOVTA\',\'VEN\',';
    } else {
      fac = '';
    }
    if (this.boolMovCaja) {
      caj = ' \'CAJ\',';
    } else {
      caj = '';
    }
    if (this.boolMovCartPas) {
      cxp = ' \'CXP\',';
    } else {
      cxp = '';
    }
    if (this.boolMovCons) {
      cons = ' \'PCV\'';
    } else {
      cons = '';
    }
  
    sentencia = 'TRNCAJ_ORIGEN IN(' + con + cxc + fac + caj + cxp + cons;
    if (sentencia.endsWith(',')) {
      sentencia = sentencia.substring(0, sentencia.length - 1);
    }
    sentencia = sentencia + ')';
  
    if (sentencia.length === 18) {
      sentencia = null;
    }
    
    return sentencia;
  }
  
  seleccionDetCaja(valor) {
    if (valor.object !== undefined) {
      this.detalleCajaSel = valor.object;
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'CHECK_ITEM') {
        this.aggrid.refreshColumnDefs();
        this.btnGuardar = false;
        this.aggrid.gridApi.setFocusedCell(this.bantrnbanEditService.banTrnBanCaja.indexOf(this.detalleCajaSel), 'CHECK_ITEM');
      }
    }
  }
  
  actualiza_tabla(where, opcion, Bod) {
      this.bantrnbanEditService.consultarMovimiento(where, opcion, Bod).subscribe((mov) => {
        if (mov !== null) {
           this.bantrnbanEditService.banTrnBanCaja = mov;
           this.bantrnbanEditService.banTrnBanCaja.map(item => {
             item.COM_CODIGO = '01';
             item.CHECK_ITEM = false;
             item.TRNCAJDET_IMPORTE = Number(0).toFixed(this.confIniciales.getNumDecSist());
             item.TRNCAJ_PAGADO = Number(item.TRNCAJ_PAGADO).toFixed(this.confIniciales.getNumDecSist());
             item.TRNCAJ_IMPORTE = Number(item.TRNCAJ_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
             if (item.TRNCAJ_FECHA.toString().includes('-')) {
               item.TRNCAJ_FECHA = this.datePipe.transform(item.TRNCAJ_FECHA, 'dd/MM/yyyy');
             }
             item.TRNCAJ_SALDO = Number(item.TRNCAJ_SALDO).toFixed(this.confIniciales.getNumDecSist());
           });
           this.aggrid.refreshColumnDefs();
           this.aggrid.gridApi.setFocusedCell(this.bantrnbanEditService.banTrnBanCaja.length - 1, 'CHECK_ITEM');
        } else {
          this.bantrnbanEditService.banTrnBanCaja = [];
        }
      });
  }
  
  condicion(): string {
    let condicion1 = this.filtros_chkbtn()
      + ' AND TRNCAJ_ANULADO=0 AND  TC.TRNCAJ_FECHA between to_date(\'' + this.desde.getDate()
      + '/' + (this.desde.getMonth() + 1) + '/' + this.desde.getFullYear()
      + '\',\'dd/mm/yyyy\') and to_date(\'' + this.hasta.getDate() + '/'
      + (this.hasta.getMonth() + 1) + '/' + this.hasta.getFullYear()
      + '\',\'dd/mm/yyyy\')';
    if (this.strTipoCaj.name !== '') {
      condicion1 += ' AND TRNCAJ_TIPO =\'' + this.combo() + '\'';
    }
    if (this.strCaja.name !== '') {
      condicion1 += ' AND TC.CAJ_CODIGO=\'' + this.strCaja.codigo + '\'';
    }
    
    return condicion1;
  }
  
   combo(): string {
    if (this.strTipoCaj.name === 'Efectivo') {
      return 'EF';
    }
    if (this.strTipoCaj.name === 'Cheque') {
       return 'CH';
    }
    
    return '';
    
  }
  
  actualiza_tabla_actualizacion(where, opcion, Bod) {
  
    this.actualiza_tabla_deposito(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
      this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc());
    
    this.bantrnbanEditService.consultarMovimiento(where, opcion, Bod).subscribe((mov) => {
      if (mov !== null) {
        for (const item of mov) {
          item.TRNCAJDET_IMPORTE = Number(0).toFixed(this.confIniciales.getNumDecSist());
          item.CHECK_ITEM = false;
          this.bantrnbanEditService.banTrnBanCaja.push(item);
        }
        this.aggrid.refreshaggrid(this.bantrnbanEditService.banTrnBanCaja, this.defaultBanTrnBanCaj.objeto);
        
        this.bantrnbanEditService.banTrnBanCaja.map(item => {
          item.COM_CODIGO = '01';
          if (item.TRNCAJ_FECHA.toString().includes('-')) {
            item.TRNCAJ_FECHA = this.datePipe.transform(item.TRNCAJ_FECHA, 'dd/MM/yyyy');
          }
          
          item.TRNCAJ_PAGADO = Number(item.TRNCAJ_PAGADO).toFixed(this.confIniciales.getNumDecSist());
          item.TRNCAJ_IMPORTE = Number(item.TRNCAJ_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
          item.TRNCAJ_SALDO = Number(item.TRNCAJ_SALDO).toFixed(this.confIniciales.getNumDecSist());
        });
        this.aggrid.refreshColumnDefs();
        this.aggrid.gridApi.setFocusedCell(this.bantrnbanEditService.banTrnBanCaja.length - 1, 'CHECK_ITEM');
      }
    });
  }
  
  
  
  selectTodosDet(e) {
    let intIndiceLista = 0;
    for (intIndiceLista = 0; intIndiceLista < this.bantrnbanEditService.banTrnBanCaja.length; intIndiceLista++) {
      this.bantrnbanEditService.banTrnBanCaja[intIndiceLista].CHECK_ITEM = e.checked ? true :  false;
      this.bantrnbanEditService.valores(intIndiceLista);
      this.aggrid.refreshColumnDefs();
    }
    
   // this.aggrid.refreshaggrid(this.bantrnbanEditService.banTrnBanCaja, this.defaultBanTrnBanCaj.objeto);
    
    
  }
  
  colocaImporteDocumento() {
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipo.name);
    if (indexCmb === 2) {
      if (this.bantrnbanEditService.banTrnbanSelect.ASI_NRO === '') {
        if (this.co === 0) {
          if (this.bantrnbanEditService.banTrnbanSelect.IMPORTE === null) {
            this.aa = 0;
          }
          if (this.bantrnbanEditService.banTrnbanSelect.IMPORTE !== null) {
            this.aa = Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE);
          }
          
        }

        this.bantrnbanEditService.banTrnbanSelect.IMPORTE = Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE_RES !== '' ? '' +
          (this.aa + Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE_RES)) : '' + this.aa).toFixed(this.confIniciales.getNumDecSist());
        this.co += 1;
      } else {
        this.bantrnbanEditService.banTrnbanSelect.IMPORTE = '' + Number(this.bantrnbanEditService.banTrnbanSelect.IMPORTE_RES);
      }
    
    }
  }
  
  consultarBanMov(strCodCaja, strTrnCajTipo, strTrnCajNumero, asinro) {
    this.bantrnbanEditService.consultarBanMov(strCodCaja, strTrnCajTipo, strTrnCajNumero, asinro).subscribe((BanMov) => {
      if (Object.keys(BanMov).length > 0) {
        
        this.bantrnbanEditService.item.NUMERO_CONCILIACION = BanMov[0].ENCCONCIL_NUMERO;
        this.bantrnbanEditService.item.BAN_CODIGO = BanMov[0].BAN_CODIGO;
        this.bantrnbanEditService.item.TIPO = BanMov[0].TRNBAN_TIPO === null ? '' : BanMov[0].TRNBAN_TIPO;
        this.bantrnbanEditService.item.TRNBAN_NUMERO = BanMov[0].TRNBAN_NUMERO === null ? '' : BanMov[0].TRNBAN_NUMERO;
        this.bantrnbanEditService.item.FECHA_EMISION = new Date(BanMov[0].TRNBAN_FECHA);
        this.bantrnbanEditService.item.IMPORTE = BanMov[0].TRNBAN_IMPORTE;
        this.bantrnbanEditService.item.CONCEPTO = BanMov[0].TRNBAN_CONCEPTO;
        this.bantrnbanEditService.item.BENEFICIARIO = BanMov[0].TRNBAN_BENEFICIARIO;
        this.bantrnbanEditService.item.ANULADO = BanMov[0].TRNBAN_ANULADO === null ? 0 : BanMov[0].TRNBAN_ANULADO;
        this.bantrnbanEditService.item.CON_CODIGO = BanMov[0].CON_CODIGO === null ? '' : BanMov[0].CON_CODIGO;
        this.bantrnbanEditService.item.ORIGEN = BanMov[0].TRNBAN_ORIGEN;
        this.bantrnbanEditService.item.CONCILIA =  BanMov[0].TRNBAN_CONCILIA === null ? 0 : BanMov[0].TRNBAN_CONCILIA;
        this.bantrnbanEditService.item.NRO_DOCUMENTO = BanMov[0].TRNBAN_DOCNRO === null ? '' : BanMov[0].TRNBAN_DOCNRO;
        
        if (BanMov[0].ASI_NRO !== null) {
          this.bantrnbanEditService.item.ASI_NRO = BanMov[0].ASI_NRO;
        } else {
          this.bantrnbanEditService.item.ASI_NRO = '';
        }
        this.bantrnbanEditService.item.REFERENCIA = BanMov[0].TRNBAN_NUMEROREFUSER;
    
        if ( BanMov[0].TRNBAN_FECHAVENCE != null) {
          
          this.bantrnbanEditService.item.FECHA_VENCIMIENTO = new Date(BanMov[0].TRNBAN_FECHAVENCE);
        } else {
          this.bantrnbanEditService.item.FECHA_VENCIMIENTO = new Date();
        }
      } else {
        this.bantrnbanEditService.item.BAN_CODIGO = null;
      }
      this.mostrarDatos(this.bantrnbanEditService.item);
    });
  }
  
  mostrarDatos(item: BantrnbanEdit) {
    if (item.BAN_CODIGO !== null) {
      
      if (item.ASI_NRO !== null) {
        this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = item.ASI_NRO;
      }
      
      if (item.BAN_CODIGO !== null) {
        this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO = item.BAN_CODIGO;
      }
  
      this.bantrnbanEditService.existe_Banco(item.BAN_CODIGO).subscribe((banco) => {
        if (Object.keys(banco).length > 0 ) {
          this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE =  banco[0].BAN_BANCO;
        } else {
          this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE = '';
        }
      });
      if (Number(item.IMPORTE) !== 0) {
        this.bantrnbanEditService.banTrnbanSelect.IMPORTE = Number('' + item.IMPORTE).toFixed(this.confIniciales.getNumDecSist());
        this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = Number('' + item.IMPORTE).toFixed(this.confIniciales.getNumDecSist());
        
        if (item.TIPO === 'DP') {
          this.bantrnbanEditService.banTrnbanSelect.IMPORTE_RES = Number('' + item.IMPORTE).toFixed(this.confIniciales.getNumDecSist());
        }
      }
      if (item.CONCEPTO !== null) {
        this.bantrnbanEditService.banTrnbanSelect.CONCEPTO = item.CONCEPTO;
      }
      
      if (item.REFERENCIA !== null) {
        this.bantrnbanEditService.banTrnbanSelect.REFERENCIA = item.REFERENCIA;
      }
      if (item.TRNBAN_NUMERO !== null) {
        this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = item.TRNBAN_NUMERO;
      }
      if (item.BENEFICIARIO !== null) {
        this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO = item.BENEFICIARIO;
      }
      if (item.ORIGEN !== null) {
        this.bantrnbanEditService.banTrnbanSelect.ORIGEN = item.ORIGEN;
      } else {
        this.bantrnbanEditService.banTrnbanSelect.ORIGEN = 'BAN';
      }
      
      if (( String(item.ORIGEN) === 'BAN' && item.ANULADO === 0 && item.CONCILIA === 0)) {
        document.getElementById('divPrincipal3').style.pointerEvents = 'all';
  
      } else {
        document.getElementById('divPrincipal3').style.pointerEvents = 'none';
        this.btnBorrar = true;
      }
      
      if (item.NRO_DOCUMENTO !== null) {
        this.bantrnbanEditService.banTrnbanSelect.NRO_DOCUMENTO = item.NRO_DOCUMENTO;
      }
      if (item.ANULADO !== 0) {
        this.boolAnulado = true;
      } else {
        this.boolAnulado = false;
      }
      if (item.FECHA_EMISION !== null) {
        this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION = item.FECHA_EMISION;
      }
      if (item.FECHA_VENCIMIENTO != null) {
        this.bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO = item.FECHA_VENCIMIENTO;
      }
  
      if (item.TIPO === 'AJ') {
        this.strTipo = this.cmbTipo[0];
      }
      if (item.TIPO === 'CH') {
        this.strTipo = this.cmbTipo[1];
      }
      if (item.TIPO === 'DP') {
        this.strTipo = this.cmbTipo[2];
      }
      if (item.TIPO === 'NC') {
        this.strTipo = this.cmbTipo[3];
      }
      if (item.TIPO === 'ND') {
        this.strTipo = this.cmbTipo[4];
      }
      if (item.TIPO === 'RE') {
        this.strTipo = this.cmbTipo[5];
      }
      
      if (item.TIPO === 'AJ' || item.TIPO === 'DP' || item.TIPO === 'ND' || item.TIPO === 'NC' || item.TIPO === 'RE') {
        this.boolFechaVence = true;
      }
      if (item.TIPO === 'CH') {
        this.boolFechaVence = false;
      }
      this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = this.init.moneyValidation(this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL);
    } else {
      
      this.bantrnbanEditService.banTrnbanSelect.BENEFICIARIO = '';
      this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO = '';
      this.bantrnbanEditService.banTrnbanSelect.CONCEPTO = '';
      this.bantrnbanEditService.banTrnbanSelect.NUMERO_CONCILIACION = '';
      this.bantrnbanEditService.banTrnbanSelect.IMPORTE = '';
      this.bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE = '';
      this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO = '';
      this.bantrnbanEditService.banTrnbanSelect.REFERENCIA = '';
      this.bantrnbanEditService.banTrnbanSelect.ORIGEN = 'BAN';
      this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = '';
      this.bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL = '';
      this.boolAnulado = false;
      this.boolConcilia = false;
    }
  }
  
  async guarda_tabla_detalle(codbanco, numero, tipo) {
    
    for (let j = 0; j < this.bantrnbanEditService.banTrnBanCaja.length; j++) {
      if (this.bantrnbanEditService.banTrnBanCaja[j].CHECK_ITEM === true) {
        if (await this.actualizarMovimiento(codbanco, numero, tipo , this.bantrnbanEditService.banTrnBanCaja[j]) === false) {
          return false;
        }
      }
    }
 
    
    
    
    return true;
  
  
    /*if (this.bantrnbanEditService.banTrnBanCaja.length > 0) {
      for (let j = 0; j < this.bantrnbanEditService.banTrnBanCaja.length; j++) {
        
        if (this.bantrnbanEditService.banTrnBanCaja[j].CHECK_ITEM === true) {
          this.bantrnbanEditService.actualizarMovimiento(codbanco, numero, tipo , this.bantrnbanEditService.banTrnBanCaja[j]).subscribe(() => {
  
            if (!this.registroContable && !this.asiento) {
              this.bantrnbanEditService.actualizarNumeroDep(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO,
                this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, this.tipoDoc()).subscribe(() => {});
            }
            
            }, error1 => {
            this.mensajeAlerta('error', 'ERROR', 'Error al actualizar los movimientos');
          });
        }
      }
  
      setTimeout(() => {
        this.ban_sp_actlzsaldotrncajxtrnban(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
          this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO);
      }, 1500);
  
     
    } */
  }
  
  async actualizarNumeroDep(strBanCodigo, strTrnBanNumero, strTrnBanTipo) {
    try {
      await this.bantrnbanEditService.actualizarNumeroDepProm(strBanCodigo, strTrnBanNumero, strTrnBanTipo);
      this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO + '/' +
        this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO + '/' + this.tipoDoc() + '/' + 'ActDep.', 'A',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
    
    }
  }
  
  async actualizarMovimiento(codbanco, numero, tipo, item: BanTrnbanCaj) {
    try {
      await this.bantrnbanEditService.actualizarMovimientoProm(codbanco, numero, tipo, item);
      return true;
    } catch (err) {
      this.mensajeAlerta('error', 'ERROR', 'Error al actualizar los movimientos');
      return false;
    }
  }
  
  async ban_sp_actlzsaldotrncajxtrnban(codbanco, numero, tipo) {
    
    try {
      await this.bantrnbanEditService.ban_sp_actlzsaldotrncajxtrnbanProm(codbanco, numero, tipo);
      return true;
    } catch (err) {
      this.mensajeAlerta('error', 'ERROR', 'Error al actualizar el saldo de las transacciones de caja');
      return false;
    }
    
    // this.bantrnbanEditService.ban_sp_actlzsaldotrncajxtrnban(codbanco, numero, tipo).subscribe(() => {
    //   let Fecha = '';
    //   if (this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION.toString().includes('-')) {
    //     Fecha = this.datePipe.transform(this.bantrnbanEditService.banTrnbanSelect.FECHA_EMISION,
    //       'dd/MM/yyyy');
    //   }
    //   this.genera_asiento_trn(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
    //     this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO, Fecha, 'BAN' );
    //
    // }, error1 => {
    //   this.mensajeAlerta('error', 'ERROR', 'Error al actualizar el saldo de las transacciones de caja');
    // });
  }
  
  async genera_asiento_trn(codbanco, tipo, numero, fecha, origen) {
    try {
      const genAsi = await this.bantrnbanEditService. genera_asiento_trnProm(codbanco, tipo, numero, fecha, origen);
      this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = genAsi[0][':B1'];
      return true;
    } catch (err) {
      console.log(err.error);
      this.mensajeAlerta('error', 'ERROR', 'Error al ejecutar el proceso de contabilización');
      return false;
    }
    // this.bantrnbanEditService.genera_asiento_trn(codbanco, tipo, numero, fecha, origen).subscribe((genAsi) => {
    //   this.bantrnbanEditService.banTrnbanSelect.ASI_NRO = genAsi[0][':B1'];
    //   this.CargarTrnCajaDeposito(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(),
    //     this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO);
    // }, error1 => {
    //   this.mensajeAlerta('error', 'ERROR', 'Error al ejecutar el proceso de contabilización');
    // });
  }
  
  CargarTrnCajaDeposito(codbanco, tipo, numero) {
     this.bantrnbanEditService.CargarTrnCajaDeposito(codbanco, tipo, numero).subscribe((Caja) => {
       if (Caja !== null) {
         this.bantrnbanEditService.banTrnBanCaja = Caja;
         this.bantrnbanEditService.banTrnBanCaja.map(item => {
           item.TRNCAJ_FECHA = this.datePipe.transform(item.TRNCAJ_FECHA, 'dd/MM/yyyy');
           item.TRNCAJDET_IMPORTE = Number(item.TRNCAJDET_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
           item.TRNCAJ_PAGADO = Number(item.TRNCAJ_PAGADO).toFixed(this.confIniciales.getNumDecSist());
           item.TRNCAJ_IMPORTE = Number(item.TRNCAJ_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
           item.TRNCAJ_SALDO = Number(item.TRNCAJ_SALDO).toFixed(this.confIniciales.getNumDecSist());
           item.CHECK_ITEM = true;
         });
        // this.aggrid.gridApi.setFocusedCell(this.bantrnbanEditService.banTrnBanCaja.length - 1, 'CHECK_ITEM');
         this.bantrnbanEditService.suma();
    
       }
     }, error1 => {
       this.mensajeAlerta('error', 'ERROR', 'Error al cargar las transacciones de caja a depositar');
     });
     
     return true;
  }
  
  editImporte(data): boolean  {
    if (this.boolCancelacion) {
      return false;
    }
    if (this.boolAbono) {
      return true;
    }
    
    return true;
  }
  
  deshacer() {
    if (this.selectTab === 0) {
      this.consultarBanMov(this.bantrnbanEditService.banTrnbanSelect.BAN_CODIGO, this.tipoDoc(), this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO,
        this.bantrnbanEditService.banTrnbanSelect.ASI_NRO);
      this.limpiaCampos();
    }
    if (this.selectTab === 1) {
      this.boolSelectTodos = false;
  
      this.boolMovConta = false;
      this.boolMovCons = false;
      this.boolMovCartAct = false;
      this.boolMovVentas = false;
      this.boolMovCaja = false;
      this.boolMovCartPas = false;
  
      this.strBodega = this.cmbBodega[this.cmbBodega.length - 1];
      this.strCaja = this.cmbCaja[this.cmbCaja.length - 1];
      this.strTipoCaj = this.cmbTipoCaj[this.cmbTipoCaj.length - 1];
      
      this.desde = new Date();
      this.hasta = new Date();
      
      this.actualiza_tabla(' trncaj_tipo = \'\'', 'PEN', '');
    }
    this.btnGuardar = true;
    this.btnBorrar = false;
  }
  
  verAsientoContable() {
    if (this.bantrnbanEditService.banTrnbanSelect.ASI_NRO !== '' && this.bantrnbanEditService.banTrnbanSelect.ASI_NRO !== null) {
    
      
      if (this.verificarVisualizacionAsiento() === false) {
        return;
      }
      this.NumAsiRegCont = this.bantrnbanEditService.banTrnbanSelect.ASI_NRO;
      
      this.displayAsiContable = true;
    } else {
      this.mensajeAlerta('info', 'Información', 'No ha generado el Asiento Contable');
    }
    
  }
  
  verificarVisualizacionAsiento(): boolean {
    const bolCRLVISASI = Number(this.bantrnbanEditService.getConfiguraciones('CRLVISASI'));
    if (bolCRLVISASI === 0) {
      return true;
    }
  
   // const bolVisAsi = false;
  }
  
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
  
  eliminarUltimaConsulta() {
  
  }
  
  comprobarClave() {
    this.bantrnbanEditService.obtenerClave(this.accionClave).subscribe((clave) => {
      let claveReal;
      if (Object.keys(clave).length > 0) {
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          if (this.accionClave === 'eliminarOtroOrg') {
            this.eliminaDatos();
  
            this.auditoriagral.registrarAuditoria('BAN_TRNBAN', this.bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO
              + '/' + 'HabilitarEliminar', 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          }
          
          this.claveAuth = '';
        } else if (clave !== this.claveAuth) {
          this.claveDialog = false;
          this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
        }
      } else  {
        this.claveDialog = false;
        this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
      }
      
    });
  }
  
  abrirClave(accion) {
    this.displayAcciones = false;
    this.accionClave = accion;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }
    this.claveDialog = true;
  }
  
  confirmarEliminar(accion) {
    this.confirmationService.confirm({
      message: '¿ Está seguro que desea eliminar el movimiento de banco ?',
      header: 'Eliminar movimiento de banco',
      icon: 'pi pi-exclamation-triangle',
      key: 'trnbanEditConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.abrirClave(accion);
      },
      reject: () => {
      }
    });
  }
  
}
