import { Component, OnInit } from '@angular/core';
import {EsquemasAnex} from '../anexinterfaces/anexgenerararchivos';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AnexgenerararchivosService} from '../anexservicios/anexgenerararchivos.service';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {Usuario} from '../../usuario';
import {ConfirmationService, MessageService, SelectItem, MenuItem} from 'primeng';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {DatePipe} from '@angular/common';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {SegMaeCompania} from '../../seg/seginterfaces/segmaecompania';
import {SaciMaeConfiguracion} from '../../seg/seginterfaces/sacimaeconfiguracion';
import { AnexgenerarxmlService } from '../anexservicios/anexgenerarxml.service';
import {SRIXMLGenerator} from '../anexinterfaces/SRIXMLGenerator';


@Component({
  selector: 'app-anexgenerararchivos',
  templateUrl: './anexgenerararchivos.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class AnexgenerararchivosComponent implements OnInit {
  
  
  cmbRegMicroempresas: any[];
  selectRegMicroemp: any;
  txtPerMes = '';
  lblPerMes = '';
  cmbAnio: any[];
  selectAnio: any;
  
  detalleEsquema: EsquemasAnex[] = [];
  
  public frameworkComponents;
  rowStyle;
  defaultColEsquema;
  rowSelection = 'multiple';
  largo: string;
  
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  opcion: string;
  
  displayDialogEmpresas = false;
  query = '';
  
  detalleSeleccionado: EsquemasAnex = {};
  datosfe: SegMaeCompania;
  
  displayFactNoAuto = false;
  factuNoAuto: any[] = [];
  bolGenerate = false;
  
  intFECRECEMI = 0;
  strSerie1 = '';
  strSerie2 = '';
  strSerie3 = '';
  strSerie4 = '';
  intNCNOFAC = 0;

  items: MenuItem[];
  activeIndex: number = 0;
  verGenArchivo = true;
  verGenXML = false;
  
  
  columnDefsEsquema = [
    {
      headerName: 'Esquema', field: 'ESQUEMA_NOMBRE', editable: false, width: 160,
    },
    {
      headerName: 'Empresa', field: 'ESQUEMA_EMPRESA', editable: false, width: 160,
    }
  ];
  
  btnRadio = '';
  
  glbstrcodigo = '';

  // GENERAR XML

  cmbMicroempresas: any[];
  selectRegMicroempx: any;
  txtPerMesx = '';
  lblPerMesx = '';
  cmbAniox: any[];
  selectAniox: any;
  btnRadiox = '';
  intFECRECEMIx = 0;
  strSerie1x = '';
  strSerie2x = '';
  strSerie3x = '';
  strSerie4x = '';
  intNCNOFACx = 0;
  
  
  constructor(public generarArchivoService: AnexgenerararchivosService, private permisosService: PermisosService, public usuario: Usuario,
              private message: MessageService, private busquedaSer: InvbusquedaService,
              private datePipe: DatePipe, private auditoriagral: AuditoriagralService,
              private init: NuevoComponentService,  private confirmationService: ConfirmationService, 
              private errorService: ErroresBaseDatosService, public generarxmlService: AnexgenerarxmlService,
              public sriXmlGenerador: SRIXMLGenerator) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColEsquema = {
      editable: true,
      width: 100,
      objeto: 'anexesquemas',
      resizable: true
    };
  }
  
  async ngOnInit() {
    
    this.largo = '580';
    
    // Regimen de microempresas
    this.cmbRegMicroempresas = [];
    this.cmbRegMicroempresas.push({codigo: 0, label: ''}, {codigo: 1, label: 'SI'}, {codigo: 2, label: 'NO'});
    this.selectRegMicroemp = this.cmbRegMicroempresas[0];
    
    // Anio
    this.cmbAnio = [];
    const anio = new Date();
    let max = anio.getFullYear();
    max += 2;
    for (let i = max; i > max - 100; i--) {
      this.cmbAnio.push({anio: '' + i});
    }
    
    this.selectAnio = this.cmbAnio[2];
    
    const rs = await this.generarArchivoService.consultarEsquemas('ESQUEMA_ORIGEN = \'ANEX\'');
    this.detalleEsquema = rs;
    const res = await this.generarArchivoService.leerConfiguracionDatosElectronicos();
    this.datosfe = res[0];
    
    this.leerConfiguracion();

    // GENERAR XML
    this.items = [{
      label: 'Generar Archivos',
      command:(event: any) => {
        this.activeIndex = 0;
        this.verGenArchivo = true;
        this.verGenXML = false;
      }
    },{
      label: 'Generar XML',
      command:(event: any) => {
        this.activeIndex = 1;
        this.verGenArchivo = false;
        this.verGenXML = true;
      }
    }]

    // Regimen de microempresas
    this.cmbMicroempresas = [];
    this.cmbMicroempresas.push({codigo: 0, label: ''}, {codigo: 1, label: 'SI'}, {codigo: 2, label: 'NO'});
    this.selectRegMicroempx = this.cmbMicroempresas[0];
    
    // Anio
    this.cmbAniox = [];
    const aniox = new Date();
    let maxx = aniox.getFullYear();
    maxx+= 2;
    for (let i = maxx; i > maxx - 100; i--) {
      this.cmbAniox.push({anio: '' + i});
    }

    this.selectAniox = this.cmbAniox[2];
    this.generarxmlService.proceso = false;
    
  }
  
  async manejarSenales(valor) {
    if (valor === 'Visto') {
      if (this.activeIndex === 0) {
        this.generarxmlService.proceso = true;//CDPJ
        if (this.btnRadio === 'VentasRes') {
          if (this.datosfe.COM_TIPOAMBFACTELEC === '1' || this.datosfe.COM_TIPOAMBFACTELEC === '2') { // Si esta en ambiente producción
            // Verificar facturas no firmadas
            if (await this.verificarSiExistenFacIncorrectas(this.txtPerMes, this.selectAnio.anio) !== 0) {
              await this.listadoFacNoAut(this.txtPerMes, this.selectAnio.anio, this.selectRegMicroemp.label);
            } else {
              this.bolGenerate = await this.generar();
            }
          } else {
            this.bolGenerate = await this.generar();
          }
        } else {
          this.bolGenerate = await this.generar();
        }
      }

      if (this.activeIndex === 1) {
        this.bolGenerate = await this.generarXML();
      }
      
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  async listadoFacNoAut(strmes: string, stranio: string, strMicroempresa: string) {
    
    const fechaIni = this.leerFechaInicial(stranio, strmes, strMicroempresa, strmes);
    const fechaFin = this.leerFechaFinal(stranio, strmes, strMicroempresa, strmes);
    
    try {
      const res = await this.generarArchivoService.facNoAutorizadas(fechaIni, fechaFin);
      if (res.length > 0) {
        this.factuNoAuto = res;
        this.displayFactNoAuto = true;
      }
    } catch {
      this.mensajeAlerta('error', 'Error', '! Proceso listar facturas no autorizadas no terminó satisfactoriamente.... !');
    }
  }
  
  async verificarSiExistenFacIncorrectas( strMes, strAnio) {
    
    const fechaFin = this.leerFechaFinal(Number(strAnio), strMes, this.selectRegMicroemp.label, strMes);
    const fechaIni = this.leerFechaInicial(Number(strAnio), strMes, this.selectRegMicroemp.label, strMes);
    
    this.glbstrcodigo = '0';
    let intNumReg = 0;
    
    try {
      const rs = await this.generarArchivoService.verificarFacIncorrectas(fechaIni, fechaFin);
      if (rs.length > 0) {
        intNumReg = rs[0].CONT;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso listar facturas no autorizadas no terminó satisfactoriamente.... ! ' + mensaje);
    }
    return intNumReg;
  }
  
  
  leerFechaFinal(intAnio, strMes, strMicroempresas, periodo) {
    let fechaFin = '';
    if (strMicroempresas === 'SI') {
      if (strMes === '01' || strMes === '02' || strMes === '03' || strMes === '04' || strMes === '05'
        || strMes === '06') {
        fechaFin = this.getNexDate('06', intAnio);
      } else {
        fechaFin = this.getNexDate('12', intAnio);
      }
    } else {
      fechaFin = this.getNexDate(periodo, intAnio);
    }
    
    return fechaFin;
  }
  
  getNexDate(periodo, anio) {
    const fecha = new Date(anio, Number(periodo), 0);
    const strFecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    return strFecha;
  }
  
  
  leerFechaInicial(intAnio, strMes, strMicroempresas, periodo) {
    let fechaIni = '';
    if (strMicroempresas === 'SI') {
      if (strMes === '01' || strMes === '02' || strMes === '03' || strMes === '04' || strMes === '05'
        || strMes === '06') {
        fechaIni = '01/' + '01' + '/' + intAnio;
      } else {
        fechaIni = '01/' + '07' + '/' + intAnio;
      }
    } else {
      fechaIni = '01/' + periodo + '/' + intAnio;
    }
    
    return fechaIni;
  }
  
  
  
  
  busquedaSimple(tipe) {
    if (tipe === 1) {
      
      if (this.detalleEsquema !== null) {
        if (this.detalleEsquema.length > 0) {
          this.mensajeAlerta('info', 'Información', 'No esta permitido ingresar mas de un esquema');
          return;
        }
        
      }
      
      this.query =  this.getCondition();
      this.displayDialogEmpresas = true;
      
    } else if (tipe === 2) {
      let strMes = '';
      if (this.activeIndex === 0) {
        this.txtPerMes = '';
        this.lblPerMes = '';

        strMes = this.cadenaMeses(this.selectRegMicroemp.label);
      } else if (this.activeIndex === 1) {
      this.txtPerMesx = '';
      this.lblPerMesx = '';
      
      strMes = this.cadenaMeses(this.selectRegMicroempx.label);
      }
      
      
      this.opcion = 'BuscarPeriodo';
      this.types = [
        {label: 'CÓDIGO', value: 'CODSRI_CODIGO'},
        {label: 'DESCRIPCIÓN', value: 'CODSRI_DESCRIPCION'},
        {label: 'TIPO', value: 'CODSRI_TIPO'},
        {label: 'AÑO', value: 'CODSRI_ANIO'},
        {label: 'REF', value: 'CODSRI_REF'},
        {label: 'CODIGOCON', value: 'CON_CODIGO'},
      ];
      this.busquedacampos = ['', '', '', '', '', ''];
      this.tabla = ' ANEX_MAECODSRI';
      this.where = ' CODSRI_TIPO=\'PI\' AND CODSRI_CODIGO IN ' + strMes +' order by 1';
      this.consulta = ' CODSRI_CODIGO, CODSRI_DESCRIPCION, CODSRI_TIPO, CODSRI_ANIO, CODSRI_REF, CON_CODIGO ';
      this.busquedaSer.busquedaAnexCodSri(strMes).subscribe((res: any[]) => {
        this.arregloCons = res;
        this.displayDialogBusquedaFast = true;
      });
    }
  }
  manejarSeleccion(valor) {
    if (this.opcion === 'BuscarPeriodo') {
      if (this.activeIndex === 0) {
        this.txtPerMes = valor.CODSRI_CODIGO;
        this.lblPerMes = valor.CODSRI_DESCRIPCION;
      } else if (this.activeIndex === 1) {
        this.txtPerMesx = valor.CODSRI_CODIGO;
        this.lblPerMesx = valor.CODSRI_DESCRIPCION;
      }
      
      
    }
    this.displayDialogBusquedaFast = false;
  }
  
  
  cadenaMeses(strMicroempresas) {
    let strCadena = '';
    if (strMicroempresas === 'SI') {
      strCadena = '(\'06\',\'12\')';
    } else {
      strCadena = '(\'01\',\'02\',\'03\',\'04\',\'05\',\'06\',\'07\',\'08\',\'09\',\'10\',\'11\',\'12\')';
    }
    return strCadena;
  }
  
  getCondition(): string {

    if (this.detalleEsquema === null ) {
      return '';
    }
    if (this.detalleEsquema.length <= 0 ) {
      return '';
    }
    
    let condicion = ' WHERE EMPRESA_CODIGO NOT IN(';
    for (const e of this.detalleEsquema) {
      condicion += '' + e.ESQUEMA_CODIGO + ',';
    }
    
    if (condicion.endsWith(',')) {
      condicion = condicion.substring(0, condicion.length - 1);
    }
    
    condicion += ')';
    return condicion;
  }
  
  recibirEmpresas(valores: any[]) {
    if (valores.length > 0) {
      this.agregarEsquema(valores);
    }
    this.displayDialogEmpresas = false;
  }
  
  async agregarEsquema(data: any[]) {
    let h = data.length;
    while (h !== 1) {
      data.splice(h - 1);
      h--;
    }
    
    for (const e of data) {
      const es: EsquemasAnex = {};
      es.COM_CODIGO = '01';
      es.ESQUEMA_CODIGO = Number(e.EMPRESA_CODIGO);
      es.ESQUEMA_EMPRESA = e.EMPRESA_ESQUEMA;
      es.ESQUEMA_NOMBRE = e.EMPRESA_NOMBRE;
      es.ESQUEMA_ORIGEN = 'ANEX';
      await this.insertarEsquema(es);
    }
    try {
      const rs = await this.generarArchivoService.consultarEsquemas('ESQUEMA_ORIGEN = \'ANEX\'');
      this.detalleEsquema = rs;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error',  mensaje);
    }
    
  }
  
  async insertarEsquema(item) {
    const params: any[5] = [];
    params[0] = '' + item.ESQUEMA_CODIGO;
    params[1] = item.COM_CODIGO;
    params[2] = item.ESQUEMA_NOMBRE;
    params[3] = item.ESQUEMA_EMPRESA;
    params[4] = item.ESQUEMA_ORIGEN;
    
    try {
      await this.generarArchivoService.insertarEsquema(params);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'Ocurrió un error al insertar el esquema ' + mensaje);
      return false;
    }
    return true;
  }
  
  recibirSenalesEmpresas(valor) {
    if (valor === true) {
      this.displayDialogEmpresas = false;
    }
  }
  
  seleccionDetEsquema(valor) {
    if (valor === 'nuevo') {
      this.busquedaSimple(1);
    }
    if (valor === 'eliminar') {
      this.eliminarEsquema();
    }
    if (valor.object !== undefined) {
      this.detalleSeleccionado = valor.object;
    }
  }
  
  eliminarEsquema() {
    if (Object.keys(this.detalleSeleccionado).length === 0) {
      this.mensajeAlerta('error', 'Error', 'Debe haber un registro seleccionado para poder eliminarlo');
      return;
    }
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro seleccionado ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'anexgenarchConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmEliminar();
      },
      reject: () => {
      }
    });
  }
  
  async confirmEliminar() {
    if (await this.eliminarEsquema2()) {
    } else {
      this.mensajeAlerta('error', 'Error', 'Ha ocurrido un error, no se pudo eliminar el registro');
    }
    
    try {
      const rs = await this.generarArchivoService.consultarEsquemas('ESQUEMA_ORIGEN = \'ANEX\'');
      this.detalleEsquema = rs;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error',  mensaje);
    }
  }
  
  async eliminarEsquema2() {
    const params: any[3] = [];
    params[0] = this.detalleSeleccionado.COM_CODIGO;
    params[1] = '' + this.detalleSeleccionado.ESQUEMA_CODIGO;
    params[2] = '' + this.detalleSeleccionado.ESQUEMA_ORIGEN;
    
    try {
      await this.generarArchivoService.eliminarEsquema(params);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'Ocurrió un error al insertar el esquema ' + mensaje);
      return false;
    }
    
    return true;
  }
  
  cambioRegimen() {
    this.txtPerMes = '';
    this.lblPerMes = '';
  }
  
  eliminarDatosTablasTemporales() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar los datos de las tablas temporales ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'anexgenarchConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmEliminarDatosTemp();
      },
      reject: () => {
      }
    });
  }
  
  async confirmEliminarDatosTemp() {
    try {
      await this.generarArchivoService.eliminarIvaCom();
      this.auditoriagral.registrarAuditoria('ANEX_IVACOM', 'DATOSTEMP', 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar información no terminó satisfactoriamente.... ! ' + mensaje);
      
    }
    
    try {
      await this.generarArchivoService.eliminarIvaVen();
      this.auditoriagral.registrarAuditoria('ANEX_IVAVEN', 'DATOSTEMP', 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar información no terminó satisfactoriamente.... ! ' + mensaje);
    }
    
    
    try {
      await this.generarArchivoService.eliminarIvaAnu();
      this.auditoriagral.registrarAuditoria('ANEX_IVAANULADO', 'DATOSTEMP', 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar información no terminó satisfactoriamente.... ! ' + mensaje);
    }
    
    this.mensajeAlerta('info', 'Información', 'Proceso terminó satisfactoriamente');
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'anexgenarch',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  async procesoCerrarAplicacion() {
    if (this.factuNoAuto.length !== 0) {
      
      this.confirmationService.confirm({
        message: 'Existen facturas que no estan autorizadas, necesita procesar la información de todas maneras?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'factnoautConf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.glbstrcodigo = '0';
          this.displayFactNoAuto = false;
          this.auditoriagral.registrarAuditoria('ANEX_IVAVEN', 'Decide Continuar con el proceso/ANEX', 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
          this.continuar();
          this.confirmationService.close();
        },
        reject: () => {
          this.glbstrcodigo = '1';
          this.displayFactNoAuto = false;
          this.continuar();
          this.confirmationService.close();
          this.generarxmlService.proceso = false;//CDPJ
        }
      });
      
    } else {
      this.glbstrcodigo = '0';
      this.displayFactNoAuto = false;
      this.continuar();
    }
    
  }
  
  async continuar() {
    
    if (this.glbstrcodigo === '1') {
      return;
    }
    
    // this.actualizaRucVentas();
    this.bolGenerate = await this.generar();
  }
  
  
  async generar() {
    const op = this.btnRadio === 'Compras' ? 1 : this.btnRadio === 'VentasRes' ? 4 : this.btnRadio === 'CompAnulados' ? 5 : -1;
    
    if (op === -1) {
      this.mensajeAlerta('error', 'Error', 'Por favor seleccione el tipo de documento a generar');
      return false;
    }

    if (this.detalleEsquema === null) {
      this.mensajeAlerta('error', 'Error', 'Por favor seleccione uno o mas esquemas como origen para generar los documentos');
      return false;
    }
    
    if (this.detalleEsquema.length <= 0) {
      this.mensajeAlerta('error', 'Error', 'Por favor seleccione uno o mas esquemas como origen para generar los documentos');
      return false;
    }
    // Verifica si existe el codigo de sri
    let periodo = String(this.txtPerMes).trim();
    const rs = await this.generarArchivoService.obtenerCodSri(periodo);
    
    periodo = rs === null ? '' : rs[0].CODSRI_CODIGO;
    
    if (periodo === '') {
      this.mensajeAlerta('error', 'Error', 'El periodo informado no existe, por favor seleccione un periodo válido');
      return false;
    }
    this.txtPerMes = periodo;
    
    const rs1 = await this.generarArchivoService.obtenerCodSriRef(periodo);
    
    periodo = rs1 === null ? '' : rs1[0].CODSRI_REF;
    
    const anio = Number(this.selectAnio.anio);
    switch (op) {
      case 1:  // Generacion de documentos de compras
        for (const esquema of this.detalleEsquema) {
          
          const gen = await this.generarCompras(esquema.ESQUEMA_NOMBRE, String(this.txtPerMes).trim(), anio, this.intFECRECEMI, false,
            this.selectRegMicroemp.label);
          if (gen === 0) {
            this.mensajeAlerta('info', 'Información', 'Se han generado correctamente los anexos de compras para el esquema ' + esquema.ESQUEMA_NOMBRE);
            this.generarxmlService.proceso = false;//CDPJ
          } else {
            this.mensajeAlerta('error', 'Error', 'Ha ocurrido un error, los anexos del esquema ' + esquema.ESQUEMA_NOMBRE + ' no fueron generados correctamente');
            this.generarxmlService.proceso = false;//CDPJ

          }
        }
      break;
      case 4:  // Generación de documentos de Ventas Resumidas
        await this.eliminarDatosTablasTemporalFormaPago();
        for (const esquema of this.detalleEsquema) {
          const gen = await this.generarVentas(esquema.ESQUEMA_NOMBRE, String(this.txtPerMes).trim(), anio, this.strSerie1,	this.strSerie2, this.strSerie3, 
          this.strSerie4, this.intNCNOFAC, false, this.selectRegMicroemp.label);
          if (gen === 0) {
            this.mensajeAlerta('info', 'Información', 'Se han generado correctamente los anexos de ventas para el esquema ' + esquema.ESQUEMA_NOMBRE);
            this.generarxmlService.proceso = false;//CDPJ
          } else {
            this.mensajeAlerta('error', 'Error', 'Ha ocurrido un error, los anexos del esquema ' + esquema.ESQUEMA_NOMBRE + ' no fueron generados correctamente');
            this.generarxmlService.proceso = false;//CDPJ

          }
        }
      break;
      case 5:  // Generación de documentos de Ventas Anuladas
        for (const esquema of this.detalleEsquema) {
          const gen = await this.generarVentasAnuladas(esquema.ESQUEMA_NOMBRE, String(this.txtPerMes).trim(), anio, false, this.strSerie1, this.strSerie2,
						this.strSerie3, this.strSerie4, this.selectRegMicroemp.label);
          if (gen === 0) {
            this.mensajeAlerta('info', 'Información', 'Se han generado correctamente los anexos de comprobantes anulados para el esquema ' + esquema.ESQUEMA_NOMBRE);
            this.generarxmlService.proceso = false;//CDPJ
          } else {
            this.mensajeAlerta('error', 'Error', 'Ha ocurrido un error, los anexos del esquema ' + esquema.ESQUEMA_NOMBRE + ' no fueron generados correctamente');
            this.generarxmlService.proceso = false;//CDPJ
          }
        }
      break;

    }
    this.generarxmlService.proceso = false;//CDPJ
    await this.truncarValoresNumericos();
    return true;
  }

  async generarVentas(esquema, periodo, anio, strSerie1, strSerie2, strSerie3, strSerie4, intNCNOFAC, bolSoloFacturasFisicas, strMicroempresa) {
    let estado = 0;
    const params: any[5] = [];
    params[0] = anio;
    params[1] = periodo;
    params[2] = '01';
    params[3] = strMicroempresa;
    params[4] = esquema;
    
    try {
      await this.generarArchivoService.generarVentas(params);
      
    } catch (err) {
      estado = 1;
      this.generarxmlService.proceso = false;//CDPJ
    }
    
    return estado;
  }

  async generarVentasAnuladas(esquema, periodo, anio, bolSelSoloFacFisicas, strSerie1, strSerie2, strSerie3, strSerie4, strMicroempresas) {
    let estado = 0;
    const params: any[5] = [];
    params[0] = anio;
    params[1] = periodo;
    params[2] = '01';
    params[3] = strMicroempresas;
    params[4] = esquema;
    
    try {
      await this.generarArchivoService.generarDocumentosAnulados(params);
    } catch (err) {
      this.generarxmlService.proceso = false;//CDPJ
      estado = 1;
    }
    
    return estado;
    return 0;
  }

  async eliminarDatosTablasTemporalFormaPago() {

		try {
      await this.generarArchivoService.eliminarDatosTablasTmpFormaPago();

      await this.auditoriagral.registrarAuditoriaPromise('ANEX_TRANFORMPAGO', 'DATOSFORMAPAGO', 'E',
        this.usuario.identificacion, '01', '', '', '', '');

		} catch (err) {
      this.generarxmlService.proceso = false;//CDPJ
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar información no terminó satisfactoriamente.... ! ' + mensaje);
    }
    
  }
  
  
  async generarCompras(esquema, periodo, anio, intFECRECEMI, bolFacturasFisicas, strMicroempresa) {
    let estado = 0;
    const params: any[5] = [];
    params[0] = anio;
    params[1] = periodo;
    params[2] = '01';
    params[3] = strMicroempresa;
    params[4] = esquema;
    
    try {
      await this.generarArchivoService.generarCompras(params);
    } catch (err) {
      this.generarxmlService.proceso = false;//CDPJ
      estado = 1;
    }
    
    return estado;
  }
  
  async leerConfiguracion() {
    let lstConfiguracion: SaciMaeConfiguracion[] = [];
    
    lstConfiguracion = await this.generarArchivoService.obtenerConfiguracion();
    
    for (const item of lstConfiguracion) {
      if (item.CFG_CODIGO === 'FECRECEMI') {
        this.intFECRECEMI = Number(item.CFG_VALOR1);
      }
      // Controlar los saldos de los items de OC y NR por cantidad(A) y montos(S, C)
      if (item.CFG_CODIGO === 'SERNOATS1') {
        this.strSerie1 = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
      }
      
      if (item.CFG_CODIGO === 'SERNOATS2') {
        this.strSerie2 = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
      }
      
      if (item.CFG_CODIGO === 'SERNOATS3') {
        this.strSerie3 = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
      }
      
      if (item.CFG_CODIGO === 'SERNOATS4') {
        this.strSerie4 = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
      }
      
      if (item.CFG_CODIGO === 'NCNOFAC') {
        this.intNCNOFAC = Number(item.CFG_VALOR1);
      }
      
      
    }
  }
  
  async truncarValoresNumericos() {
    
    try {
      await this.generarArchivoService.truncarValoresIvaCom();
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso truncar datos en ANEX_IVACOM no terminó satisfactoriamente.... ! ' + mensaje);
    }
    
    try {
      await this.generarArchivoService.truncarValoresRetCom();
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso truncar datos en ANEX_RETCOM no terminó satisfactoriamente.... ! ' + mensaje);
    }
    
    try {
      await this.generarArchivoService.truncarValoresIvaVen();
    } catch (err) {
      
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso truncar datos en ANEX_IVAVEN no terminó satisfactoriamente.... ! ' + mensaje);
    }
    
  }

  cambioRegimenx() {
    this.txtPerMesx = '';
    this.lblPerMesx = '';
  }

  async generarXML() {
    
    this.generarxmlService.proceso = true;
    const op = this.btnRadiox === 'AnexTrans' ? this.sriXmlGenerador.ANEXO_TRANSACCIONAL : -1;
    
    if (op === -1) {
      this.mensajeAlerta('error', 'Error', 'Por favor seleccione el tipo de documento a generar');
      this.generarxmlService.proceso = false;
      return false;
    }
    
    let periodo = String(this.txtPerMesx).trim();
    
    const rs = await this.generarxmlService.obtenerCodSri(periodo);
    
    periodo = rs === null ? '' : rs[0].CODSRI_CODIGO;
    if (periodo === '') {
      this.mensajeAlerta('error', 'Error', 'El periodo informado no existe, por favor seleccione un periodo valido');
      document.getElementById('PER_INFORMADO').focus();
      this.generarxmlService.proceso = false;
      return false;
    }
    
    const anio = Number(this.selectAniox.anio);
    const strSoloFacturasFisicas = '0';
    
    switch (op) {
      case this.sriXmlGenerador.ANEXO_TRANSACCIONAL:
        this.sriXmlGenerador.SRIXMLGenerator('', op, this.txtPerMesx, anio, this, strSoloFacturasFisicas, this.intNCNOFACx, this.selectRegMicroempx.label);
        if(this.generarxmlService.way !== this.generarxmlService.wayrep){
          this.mensajeAlerta('success', 'ATS', 'El proceso de generar el ATS se inición correctamente, pronto recibirás en tu correo el archivo.');
          this.generarxmlService.proceso = false;
        }
       
        break;
      
    }
    
    
  }

  
}
