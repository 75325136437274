import { Component, OnInit } from '@angular/core';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { NomexpliqutiarchtxtService } from '../nomservicios/nomexpliqutiarchtxt.service';
import { MessageService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';

@Component({
  selector: 'app-nomexpliqutiarchtxt',
  templateUrl: './nomexpliqutiarchtxt.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomexpliqutiarchtxtComponent implements OnInit {

  constructor(private init: NuevoComponentService,
    private expliqutiarchtxtSrv: NomexpliqutiarchtxtService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService) { }
  cmbFormato: any[] = [];
  selectFormato: any;
  cmbPeriodo: any[] = []
  selectperiodo: any

  cargarCombo = false;

  separacion = ',';
  btnVisto = true;
  nombreTxt = '';
  SEPEREXDAT = 0;
  ngOnInit(): void {
    this.obtenerFormato();
    this.obtenerconfig();
  }
  async obtenerconfig() {
    try {
      const datos = await this.expliqutiarchtxtSrv.getconfig();
      let formatos = await this.expliqutiarchtxtSrv.cargaperiodos();
      for (const iterator of datos) {
        this.SEPEREXDAT = Number(iterator.CFG_VALOR1)
      }
      if (this.SEPEREXDAT === 1) {
        
        this.cmbPeriodo.push({ PERIODO_NOMBRE: '', PERIODO_CODIGO: 0 })

        formatos.map(formato => {
          this.cmbPeriodo.push(formato)

        });
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción: ' + mensaje);
    }
  }
  async obtenerFormato() {
    try {

      let formatos = await this.expliqutiarchtxtSrv.cargaFormatos();
      this.cmbFormato.push({ MAEEXDAARCHPLAN_NOMBRE: '', MAEEXDAARCHPLAN_CODIGO: '' })

      formatos.map(formato => {
        this.cmbFormato.push(formato)

      });
      this.cargarCombo = true;
    }
    catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción: ' + mensaje);
    }

  }

  seleccionarFormato(e) {
  }

  onBasicUpload(event) {
    const fileReader = new FileReader();
    for (const file of event.files) {
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        localStorage.setItem('txtdatosblob', fileReader.result + '');
      };
    }
  }


  async buscarArchCodigo() {
    await this.expliqutiarchtxtSrv.encontrarRegistro('MAEEXDAARCHPLAN_PROCESO', 'SACI_MAEEXDAARCHPLAN', `MAEEXDAARCHPLAN_CODIGO='${this.selectFormato.MAEEXDAARCHPLAN_CODIGO}'`).then(procSql => {

      if (procSql === null) {
        this.ejecutarProceso('');
      } else {
        this.ejecutarProceso(procSql[0].MAEEXDAARCHPLAN_PROCESO);
      }

    }).catch(e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción: ' + mensaje);
    })
  }

  async ejecutarProceso(procSql) {
    if (this.SEPEREXDAT === 1) {
      await this.expliqutiarchtxtSrv.ejecutarProcesodos(this.selectFormato.MAEEXDAARCHPLAN_CODIGO, this.separacion, procSql,this.selectperiodo.PERIODO_CODIGO).then(r => {

        this.funGenArchTexto();

      }).catch(e => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción: ' + mensaje);
      })
    } else {
      await this.expliqutiarchtxtSrv.ejecutarProceso(this.selectFormato.MAEEXDAARCHPLAN_CODIGO, this.separacion, procSql).then(r => {

        this.funGenArchTexto();

      }).catch(e => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción: ' + mensaje);
      })
    }



  }

  async funGenArchTexto() {
    await this.expliqutiarchtxtSrv.funGenArchTexto(this.selectFormato.MAEEXDAARCHPLAN_CODIGO).then(lineas => {
      console.log(lineas);
      if (lineas !== null) {

        let texto = '';

        for (let linea of lineas) {
          texto = texto + linea.LINEA + '\r\n';
        }
        console.log(texto);
        this.download(this.nombreTxt, texto);

      } else {
        this.mensajeAlerta('error', 'Error', 'No se ha encontrado información para generar el archivo');
      }


    }).catch(e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    })
  }

  download(filename, text) {
    if (filename === '') {
      this.mensajeAlerta('error', 'Error', 'Ingrese un nombre de archivo');
    } else {

      const blob = new Blob([text], { type: 'text/plain' });
      const element = document.createElement('a');
      element.setAttribute('download', filename);
      element.setAttribute('href', window.URL.createObjectURL(blob));
      element.click();
    }
  }


  manejarSenales(valor) {
    switch (valor) {
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
      case 'Visto':
        if (this.selectFormato &&
          (this.selectFormato.MAEEXDAARCHPLAN_NOMBRE !== '' && this.selectFormato.MAEEXDAARCHPLAN_CODIGO !== '')) {
          this.buscarArchCodigo();
        } else {
          this.mensajeAlerta('error', 'Error', 'Seleccione un reporte');
        }
        break;
      default:
        break;
    }
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'archTexto',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }


}
