import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class NomexpliqutiarchtxtService {


  private way: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
      this.way = sessionStorage.getItem('way');
     }


     cargaFormatos() {
    
      return this.http.post<any[]>(this.way + '/nom/exportardatos/poia6cwo7pz8', {
          elementos: {},
        }, this.confIniciales.httpOptions()).toPromise();
    }


    encontrarRegistro(strCampo, strTabla, strCondicion) {
      return this.http.post(this.way + '/nom/exportardatos/h41qk5pr1edp', {
        datos:{
          Campo: strCampo,
          Tabla: strTabla,
          Condicion: strCondicion
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }

    ejecutarProceso(codigo: string, caracter: string , proceso: string){
      return this.http.post<any>(this.way + '/nom/exportardatos/jtwd1enbv64r', {
        elementos: {
          p_maeexdaarchplan_codigo: codigo,
          p_caracter: caracter,
          p_usuidentificacion:  this.usuario.identificacion
        },
        datos: {
          Proceso: proceso
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }

    funGenArchTexto(codigo: string){
      return this.http.post<any>(this.way + '/nom/exportardatos/h41qk5nbv64r', {
        elementos: {
          p_maeexdaarchplan_codigo: codigo,
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    getconfig(){
    
      return this.http.post<any[]>(this.way + '/nom/exportardatos/83bacc5df285f00', {
          elementos: {},
        }, this.confIniciales.httpOptions()).toPromise();
    }
    ejecutarProcesodos(codigo: string, caracter: string , proceso: string,periodo){
      return this.http.post<any>(this.way + '/nom/exportardatos/jtwd1enbv64r', {
        elementos: {
          p_maeexdaarchplan_codigo: codigo,
          p_caracter: caracter,
          p_usuidentificacion:  this.usuario.identificacion,
          p_periodo:periodo
        },
        datos: {
          Proceso: proceso+'N'
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    cargaperiodos() {
    
      return this.http.post<any[]>(this.way + '/nom/exportardatos/18adc15bcbb72c0000', {
          elementos: {},
        }, this.confIniciales.httpOptions()).toPromise();
    }
}
