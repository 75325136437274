<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encptovta"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarencptovta" title="Pregunta" appendTo="body">
</p-confirmDialog>
<div class="layout-config">
    <div class="layout-config-content-wrapper">
        <a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
            <i class="pi pi-bars" style="cursor: pointer"></i>
        </a>
        <p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
            [dismissible]="true" [showCloseIcon]="false">
            <br>
            <br>
            <br>
            <p-tabView>
                <p-tabPanel header="Acciones">
                    <div class="p-grid">
                        <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
                            <div class="p-col-12">
                                <i class="pi pi-caret-right archivoOpc"></i><span (click)="accion1()"
                                    class="archivoOpc">Agregar Pedido</span>
                            </div>
                            <div class="p-col-12">
                                <i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarSeriales()"
                                     class="archivoOpc">Ingresar Seriales</span>
                            </div>
                            <div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="activarFacturaAnuladaFE()"
									class="archivoOpc">Activar boton de anular factura firmada electrónicamente</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="reversarFE()"
									class="archivoOpc">Reversar inicio de proceso de Firma Electrónica</span>
							</div>
							<div *ngIf="boolfacturaElec === true" class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="continuarFE()"
									class="archivoOpc">Continuar proceso de firma electrónica</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="refrescarDatosFE('acciones')"
									class="archivoOpc">Refrescar datos de factura electronica</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="visualizarLog()"
									class="archivoOpc">Visualizar log del documento</span>
                            </div>
                            <!-- CDPJ -->
								<div class="p-col-12">
									<i class="pi pi-folder archivoOpc"></i><span (click)="verbasesiva()"
										class="archivoOpc">Visualizar bases IVA</span>
								</div>
								<!-- CDPJ -->
                        </p-scrollPanel>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </p-sidebar>
    </div>
</div>
<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra (signal)="manejarSenales($event)" [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="false"
            [botonBorrar]="botonBorrar" [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar"
            [botonRegresar]="botonRegresar" [botonPrimero]="!botonPrimero" [botonAnterior]="!botonAnterior"
            [botonSiguiente]="!botonSiguiente" [botonUltimo]="!botonUltimo" [barraBotones5]="false"
            [barraPtoVta]="false" [botonAnular]="botonAnular" [botonAsiento]="false" [botonverAsiento]="false"
            [botonPago]="false" [botonPagoCredito]="false" [botonBuscar]="false" [botonXML]="false"
            [botonReenviarCorreo]="false" [botonAlmacenardoc]="true" [botonBinocular]="true">
        </app-invmaebarra>
    </div>
</div>
<div class="p-grid" style="margin-top: -12px; margin-bottom: -10px; width: 99%;">
    <!--LINEA 1-->
    <div class="p-col-2">
        <label for="lblNumFact"></label>
        <input class="frm" id="lblNumFact" type="text" pInputText
            style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
            [disabled]="true" [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_NUMERO"
            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
    </div>
    <div class="p-col-1">
        <label for="lblBodega"></label>
        <input class="frm" id="lblBodega" type="text" pInputText
            style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
            [disabled]="true" [(ngModel)]="encptovtaService.encfac[indicador].BOD_CODIGO"
            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
    </div>
    <div class="p-col-1">
        <label for="lblEstado"></label>
        <input class="frm" id="lblEstado" type="text" pInputText
            style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
            [disabled]="true" [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_ESTADO"
            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
    </div>
    <div class="p-col-1">
        <label for="lblImpreso"></label>
        <input *ngIf="lblImpresosetVisible==true" class="frm" id="lblImpreso" type="text" pInputText
            style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
            [disabled]="true" [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_IMPRESO"
            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
    </div>
    <div class="p-col-1">
        <label for="ENCFAC_ESTADO_FE"></label>
        <input class="frm" id="ENCFAC_ESTADO_FE" type="text" pInputText
            style="background-color: rgb(40, 138, 210); text-align: center; color: rgb(255,255,255); font-weight: bold"
            [disabled]="true" [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_ESTADO_FE"
            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
    </div>
    <div class="p-col-1">
        <label for="ENCFAC_AUTORIZACION_FE"></label>
        <input class="frm" id="ENCFAC_AUTORIZACION_FE" type="text" pInputText
            style="background-color: rgb(40, 138, 210); text-align: center; color: rgb(255,255,255); font-weight: bold"
            [disabled]="true" [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_AUTORIZACION_FE"
            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
    </div>
    <div class="p-col-1">
        <label for="ENCFAC_CLAVEACCESO_FE"></label>
        <input class="frm" id="ENCFAC_CLAVEACCESO_FE" type="text" pInputText
            style="background-color: rgb(40, 138, 210); text-align: center; color: rgb(255,255,255); font-weight: bold"
            [disabled]="true" [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_CLAVEACCESO_FE"
            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
    </div>
    <div class="p-col-4">
        <div class="p-grid">
            <div class="p-col-4">
                <p-checkbox name="chkNotaVenta" value="" label="Nota Venta" binary="{{chkNotaVenta}}"
                    [(ngModel)]="chkNotaVenta" [disabled]="!chkNotaVentasetEnabled" (click)="seleccionarNV(true)"></p-checkbox>
            </div>
            <div class="p-col-4">
                <p-checkbox name="chkFactura" value="" label="Factura" binary="{{chkFactura}}" [(ngModel)]="chkFactura"
                    [disabled]="!chkFacturasetEnabled" (click)="seleccionarNV(false)">
                </p-checkbox>
            </div>
            <div class="p-col-4">
                <label for="lblAsiento"></label>
                <input class="frm" id="lblAsiento" type="text" pInputText
                    style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                    [disabled]="true" [(ngModel)]="encptovtaService.encfac[indicador].ASI_NRO"
                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
            </div>
        </div>
    </div>
</div>
<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 210px)'}">
    <div class="contorno" id="contorno">
        <div class="divgrups">
            <div class="p-grid">
                <!--LINEA 2-->
                <div class="p-col-1">
                    <span>Número:</span>
                </div>
                <div class="p-col-2">
                    <label for="txtNumero"></label>
                    <input class="frm" id="txtNumero" type="text" pInputText [disabled]="!txtNumerosetEditable"
                        [(ngModel)]="encptovtaService.encfac[indicador].TXT_NUMERO" (keydown.enter)="setFocus($event)"
                        (keydown)="botonesmodificar()" autocomplete="off">
                </div>
                <div class="p-col-1">
                    <span>Autorización:</span>
                </div>
                <div class="p-col-1">
                    <label for="lblAutorizacion"></label>
                    <input class="frm" id="lblAutorizacion" type="text" pInputText [disabled]="boolauth"
                        [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_AUTORIZACION"
                        (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                </div>
                <div class="p-col-4">
                    <div class="p-grid">
                        <div class="p-col-2">
                            <span>Emisión:</span>
                        </div>
                        <div class="p-col-3">
                            <p-calendar id="dateTimeEmision" [inputStyle]="{width: '100%', height: '20px'}"
                                [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_FECHAEMISION"
                                [readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                (keydown)="botonesmodificar()">
                            </p-calendar>
                        </div>
                        <div class="p-col-2">
                            <p-calendar id="dateTimeEmisionHora" [inputStyle]="{width: '80%', height: '20px'}"
                                [timeOnly]="true" [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_HORAEMISION"
                                [readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                (keydown)="botonesmodificar()">
                            </p-calendar>
                        </div>
                        <div class="p-col-1"></div>
                        <div class="p-col-2">
                            <span>Días C.</span>
                        </div>
                        <div class="p-col-2">
                            <label for="txtNumDiasCredito"></label>
                            <input class="frm" id="txtNumDiasCredito" type="text" pInputText
                                [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_DIASCRDT"
                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                        </div>
                    </div>
                </div>
                <div class="p-col-1">
                </div>
                <div class="p-col-1">
                    <span>F.Vence:</span>
                </div>
                <div class="p-col-1">
                    <p-calendar id="dateTimeVenc" [inputStyle]="{width: '100%', height: '20px'}"
                        [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_FECHAVENCIMIENTO" [readonlyInput]="true"
                        dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()">
                    </p-calendar>
                </div>
                <!--LINEA 3-->
                <div class="p-col-12" style="margin-top: -15px">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Venta:</span>
                        </div>
                        <div class="p-col-2">
                            <p-dropdown [options]="cmbventa" [style]="{'minWidth': '100%', 'width':'100%'}"
                                optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectventa"
                                (onChange)="busqVentas($event)"></p-dropdown>
                        </div>
                        <div class="p-col-6"></div>
                        <div class="p-col-3">
                            <span *ngIf="labelPromocionsetVisible">Revise Promociones</span>
                        </div>

                    </div>
                </div>
                <!--LINEA 4-->
                <div class="p-col-12" style="margin-top: -15px">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Cliente:</span>
                        </div>
                        <div class="p-col-1">
                            <label for="txtCodCliente"></label>
                            <input class="frm" id="txtCodCliente" type="text" pInputText
                                [disabled]="!txtCodClientesetEditable"
                                [(ngModel)]="encptovtaService.encfac[indicador].CLI_CODIGO"
                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <button type="button"
                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                [disabled]="!btnClientesetEnabled" (click)="botoncliente()"></button>
                        </div>
                        <div class="p-col-3">
                            <label for="lblCliente"></label>
                            <input class="frm" id="lblCliente" type="text" pInputText disabled style="color: blue;"
                                [(ngModel)]="encptovtaService.encfac[indicador].CLI_NOMBRE" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <span>Ref.:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="txtRefCliente"></label>
                            <input class="frm" id="txtRefCliente" type="text" pInputText
                                [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_REFCLI"
                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                        </div>
                        <div class="p-col-1">
                            <span>Placa:</span>
                        </div>
                        <div class="p-col-1">
                            <label for="txtPlaca"></label>
                            <input class="frm" id="txtPlaca" type="text" pInputText
                                [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_PLACA"
                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                        </div>
                    </div>
                </div>
                <!--LINEA 5-->
                <div class="p-col-12" style="margin-top: -20px; margin-bottom: -20px">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Vendedor:</span>
                        </div>
                        <div class="p-col-1">
                            <label for="txtCodVendedor"></label>
                            <input class="frm" id="txtCodVendedor" type="text" pInputText
                                [disabled]="!txtCodVendedorsetEditable"
                                [(ngModel)]="encptovtaService.encfac[indicador].VEN_CODIGO"
                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <button type="button"
                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                [disabled]="!btnVendedorsetEnabled"
                                (click)="busquedavendedor(encptovtaService.encfac[indicador].VEN_CODIGO)"></button>
                        </div>
                        <div class="p-col-3">
                            <label for="lblVendedor"></label>
                            <input class="frm" id="lblVendedor" type="text" pInputText disabled style="color: blue;"
                                [(ngModel)]="encptovtaService.encfac[indicador].VEN_NOMBRE" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <span>Ref.:</span>
                        </div>
                        <div class="p-col-1">
                            <label for="txtReferencia"></label>
                            <input class="frm" id="txtReferencia" type="text" pInputText
                                [disabled]="!txtReferenciasetEditable"
                                [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_REFERENCIA"
                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <button type="button"
                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                [disabled]="!btnReferenciasetEnabled"
                                (click)="busquedacliente(encptovtaService.encfac[indicador].ENCFAC_REFERENCIA)"></button>
                        </div>
                        <div class="p-col-3">
                            <div class="p-grid">
                                <div class="p-col-3">
                                    <span>Lst. Precios:</span>
                                </div>
                                <div class="p-col-3">
                                    <p-dropdown [options]="cmblista" [style]="{'minWidth': '100%', 'width':'100%'}"
                                        optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectlista"
                                        (onChange)="busqlistasprecio($event)"></p-dropdown>
                                </div>
                                <div class="p-col-3">
                                    <span>%Iva</span>
                                </div>
                                <div class="p-col-3">
                                    <!-- <p-dropdown [options]="cmbiva" [style]="{'minWidth': '100%', 'width':'100%'}"
                                        optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectiva"
                                        (onChange)="busqVentas($event)"></p-dropdown> -->
                                        <p-dropdown [options]="cmbporceiva" [style]="{'minWidth': '100%', 'width': '100%'}"
                                        optionLabel="name" 
                                        (onChange)="cambiavalorIva($event)"
										[(ngModel)]="selectcombtipoIva"

							            ></p-dropdown> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="divgrups" style="margin-top: -8px;">
            <div class="p-grid" style="margin-bottom: -15px;">
                <div class="p-col-7">
                    <div class="divgrups" style="margin-bottom: -20px;">
                        <div class="p-grid">
                            <!--LINEA 6-->
                            <div class="p-col-2">
                                <span>Código:</span>
                            </div>
                            <div class="p-col-8">
                                <label for="lblCodArt"></label>
                                <input class="frm" id="lblCodArt" type="text" pInputText disabled [(ngModel)]="lblCodArt"
                                    style="color: blue; font-weight: bold;" (keydown.enter)="setFocus($event)"
                                    (keydown)="botonesmodificar()" autocomplete="off">
                            </div>
                            <div class="p-col-2">
                                <span>Saldo Cliente:</span>
                            </div>
                            <!--LINEA 7-->
                            <div class="p-col-12" style="margin-top: -10px">
                                <div class="p-grid">
                                    <div class="p-col-2">
                                        <span>Unidad:</span>
                                    </div>
                                    <div class="p-col-1">
                                        <label for="lblUnidad"></label>
                                        <input class="frm" id="lblUnidad" type="text" pInputText disabled [(ngModel)]="lblUnidad"
                                            style="text-align: center; color: blue; font-weight: bold;"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-2">
                                        <span>Cantidad:</span>
                                    </div>
                                    <div class="p-col-1">
                                        <label for="lblCantidad"></label>
                                        <input class="frm" id="lblCantidad" type="text" pInputText disabled
                                            style="text-align: right; color: blue; font-weight: bold;"
                                            [(ngModel)]="lblCantidad" (keydown.enter)="setFocus($event)"
                                            (keydown)="botonesmodificar()" autocomplete="off">
                                    </div>
                                    <div class="p-col-2">
                                        <span>Descuento:</span>
                                    </div>
                                    <div class="p-col-1">
                                        <label for="lblDsctoArt"></label>
                                        <input class="frm" id="lblDsctoArt" type="text" pInputText disabled
                                            style="text-align: right; color: blue; font-weight: bold;"
                                            [(ngModel)]="lblDsctoArt" (keydown.enter)="setFocus($event)"
                                            (keydown)="botonesmodificar()" autocomplete="off">
                                    </div>
                                    <div class="p-col-1"></div>
                                    <div class="p-col-2">
                                        <label for="lblSaldoCliente"></label>
                                        <input class="frm" id="lblSaldoCliente" type="text" pInputText disabled
                                            style="text-align: right; color: blue; font-weight: bold;"
                                            [(ngModel)]="lblSaldoCliente" (keydown.enter)="setFocus($event)"
                                            (keydown)="botonesmodificar()" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <!--LINEA 8-->
                            <div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
                                <div class="p-grid">
                                    <div class="p-col-2">
                                        <span>Existencia:</span>
                                    </div>
                                    <div class="p-col-3">
                                        <label for="lblExistencia"></label>
                                        <input class="frm" id="lblExistencia" type="text" pInputText disabled
                                            style="text-align: right; color: blue; font-weight: bold;"
                                            [(ngModel)]="lblExistencia" (keydown.enter)="setFocus($event)"
                                            (keydown)="botonesmodificar()" autocomplete="off">
                                    </div>
                                    <div class="p-col-6">
                                        <span *ngIf="labelChqProt">No se permite el pago con cheque por falta de
                                            fondos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-1"></div>
                <div class="p-col-4">
                    <div class="divgrups">
                        <div class="p-grid">
                            <!--LINEA 6-->
                            <div class="p-col-12">
                                <div style="text-align: center;">
                                    <span style="font-weight: bold;">PRECIO DE VENTA AL PÚBLICO</span>
                                </div>
                            </div>
                            <div class="p-col-2"></div>
                            <div class="p-col-8">
                                <label for="lblPVP"></label>
                                <input class="frm" id="lblPVP" type="text" pInputText disabled [(ngModel)]="lblPVP"
                                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off"
                                    style="height: 50px; font-size: 30pt; margin-top: -15px; margin-bottom: -15px;
                                    text-align: center; color: rgb(0, 0, 255); font-weight: bold;">
                            </div>
                            <div class="p-col-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <app-aggridsaci  [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
            [rowData]="encptovtaService.detfac[indicador]" [frameworkComponents]="frameworkComponents"
            [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsDetfac"
            [defaultColDef]="defaultColDef" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
            (selected)="selecciondetfac($event)" [mostrarGuardar]="false" [botonesAdicionales]="true"
            (cambios)="cambio($event)"
            (keydown.f4)="desglosarIvaItem()"
            ></app-aggridsaci>
            <div class="p-grid" style="margin-top: -15px; margin-bottom: -10px;">
                <div class="p-col-2">
                    <span style="color: red;">F4 => Desglosar el IVA del Item</span>
                </div>
            </div>
            <div class="p-grid" style="margin-top: -15px; margin-bottom: -10px;">
          
            
            <div class="p-col-1">
                <button class="sincss" pButton (click)="pagoTarjetaCredito()" [disabled]="boolpago">Pago TC.
                </button>
            </div>
            <div class="p-col-1">
                <button class="sincss" pButton (click)="pagoTarjetaDebito()" [disabled]="boolpago" >Pago TD.
                </button>
            </div>
            <div class="p-col-1"></div>
            <div class="p-col-2">
                <span>Máximo Items</span>
                <span style="color: blue;">{{encptovtaService.encfac[indicador].ENCFAC_MAXIMOITEMS}}</span>
            </div>
            <div class="p-col-2">
                <span>Items Disponibles:</span>
                <span style="color: blue;">{{encptovtaService.encfac[indicador].ENCFAC_ITEMSDISPONIBLES}}</span>
            </div>
            <div class="p-col-1"></div>
            <div class="p-col-2">
                <div class="p-grid">
                    <div class="p-col-5" style="margin-top: 10px; height: 25%; background-color: rgb(255,207,185)">
                    </div>
                    <div class="p-col-7">
                        <span>Artículos cambiados</span>
                    </div>
                </div>
            </div>
            <div class="p-col-2">
                <div class="p-grid">
                    <div class="p-col-5" style="margin-top: 10px; height: 25%; background-color: rgb(128, 255, 128)">
                    </div>
                    <div class="p-col-7">
                        <span>Promociones</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="divgrups">
            <div class="p-grid" style="margin-bottom: -18px;">
                <div class="p-col-6">
                    <div class="divgrups">
                        <div class="p-grid">
                            <!--LINEA 1-->
                            <div class="p-col-2">
                                <span>Total Neto:</span>
                            </div>
                            <div class="p-col-2">
                                <label for="lblNeto"></label>
                                <input class="frm" id="lblNeto" type="text" pInputText disabled
                                    style="text-align: right; color: blue; font-weight: bold;"
                                    [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_TOTALNETO"
                                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                    autocomplete="off">
                            </div>
                            <div class="p-col-2"></div>
                            <div class="p-col-2">
                                <span>Base Cero:</span>
                            </div>
                            <div class="p-col-2">
                                <label for="lblBaseCero"></label>
                                <input class="frm" id="lblBaseCero" type="text" pInputText disabled
                                    style="text-align: right; color: blue; font-weight: bold;"
                                    [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_BASECERO"
                                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                    autocomplete="off">
                            </div>
                            <!--LINEA 2-->
                            <div class="p-col-12" style="margin-top: -10px">
                                <div class="p-grid">
                                    <div class="p-col-2">
                                        <span>Descuento:</span>
                                    </div>
                                    <div class="p-col-2">
                                        <label for="lblDescuento"></label>
                                        <input class="frm" id="lblDescuento" type="text"  pInputText disabled
                                            style="text-align: right;color: blue; font-weight: bold;"
                                            [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_VALORDES"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off" >
                                    </div>
                                    <div class="p-col-1">
                                        <label for="lblPorcDscto"></label>
                                        <input class="frm" id="lblPorcDscto" type="text" pInputText disabled
                                            style="text-align: right;color: blue; font-weight: bold;"
                                            [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_PORCEDES"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off" >
                                    </div>
                                    <div class="p-col-1">
                                        <span>%</span>
                                    </div>
                                </div>
                            </div>
                            <!--LINEA 3-->
                            <div class="p-col-12" style="margin-top: -15px">
                                <div class="p-grid">
                                    <div class="p-col-2">
                                        <span>Valor IVA:</span>
                                    </div>
                                    <div class="p-col-2">
                                        <label for="lblIva"></label>
                                        <input class="frm" id="lblIva" type="text" pInputText disabled
                                            style="text-align: right;color: blue; font-weight: bold;"
                                            [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_VALORIVA"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-2"></div>
                                    <div class="p-col-2">
                                        <span>Base IVA:</span>
                                    </div>
                                    <div class="p-col-2">
                                        <label for="lblBaseIva"></label>
                                        <input class="frm" id="lblBaseIva" type="text" pInputText disabled
                                            style="text-align: right;color: blue; font-weight: bold;"
                                            [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_BASEIVA"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <!--LINEA 4-->
                            <div class="p-col-12" style="margin-top: -15px; margin-bottom: -20px;">
                                <div class="p-grid">
                                    <div class="p-col-2">
                                        <span>Valor ICE:</span>
                                    </div>
                                    <div class="p-col-2">
                                        <label for="lblIce"></label>
                                        <input class="frm" id="lblIce" type="text" pInputText disabled
                                            style="text-align: right;color: blue; font-weight: bold;"
                                            [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_VALORICE"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-2"></div>
                                    <div class="p-col-2">
                                        <span>Base ICE:</span>
                                    </div>
                                    <div class="p-col-2">
                                        <label for="lblBaseIce"></label>
                                        <input class="frm" id="lblBaseIce" type="text" pInputText disabled
                                            style="text-align: right;color: blue; font-weight: bold;"
                                            [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_BASEICE"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="divgrups">
                        <div class="p-grid" style="margin-bottom: 4px;">
                            <!--LINEA 1-->
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div class="p-col-5"></div>
                                    <div class="p-col-2">
                                        <span style="font-weight: bold; font-size: 12pt;">A PAGAR</span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div class="p-col-2"></div>
                                    <div class="p-col-8">
                                        <label for="lblTotal"></label>
                                        <input class="frm" id="lblTotal" type="text" pInputText disabled
                                            [(ngModel)]="encptovtaService.encfac[indicador].ENCFAC_TOTAL"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off"
                                            style="height: 50px; font-size: 30pt; margin-top: -15px;
                                            margin-bottom: -15px; text-align: center; color: rgb(128, 0, 0); font-weight: bold;"
                                            readonly="true">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
</p-scrollPanel>

<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="false"
    appendTo="body" [closable]="false" [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
    <app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
        (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
        [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>


<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
    </p-progressSpinner>
</div>

<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
    <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
    <div class="divgrups">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-1"></div>
                    <div class="p-col-4">
                        <label for="clave" style="font-weight: bold">Clave de Autorización:</label>
                    </div>
                    <div class="p-col-2"></div>
                    <div class="p-col-4">
                        <input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
                            (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
                    </div>
                    <div class="p-col-1"></div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Observación" [(visible)]="lblObsAnuladasetVisible" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw'} ">
    <span>{{lblObsAnulada}}</span>
</p-dialog>
<!-- CDPJ -->

<p-dialog header="Cuotas - Cliente" [(visible)]="displayDialogCuotas" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '80vw', height: 'auto'}">
	<app-cxcenccuota *ngIf="displayDialogCuotas" [auxiliar]="auxcuotas"
		[auxNumFact]="encptovtaService.encfac[indicador].ENCFAC_NUMERO"
		[encfacdata]="encptovtaService.encfac[indicador]" (cerrar)="cerrarCuotas($event)"></app-cxcenccuota>
</p-dialog>
<!-- CDPJ -->
<p-dialog header="Pago de la Factura" [(visible)]="displayDialogPagoContado" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: 'auto'}" (onHide)="onHideEvent()">
    <app-pagocontado *ngIf="displayDialogPagoContado" [auxiliar]="auxiliar" [strNumFact]="strNumFact" [encfac]="encptovtaService.encfac[indicador]"
        [modulo]="'ptovta'" [ven_codigo]="ven_codigo" (salir)="cerrarPago()"></app-pagocontado>
</p-dialog>

<p-dialog header="Pago a Crédito" [(visible)]="displayDialogPagoCredito" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '500px'}">
    <div class="divgrups">
        <div class="ui-g ui-fluid">
            <div class="divgrupsdark">
                <div class="p-grid">
                    <div class="p-col-1">
                    </div>
                    <div class="p-col-5">
                        <button type="button" class="big"
                            style="background-image: url(../../../assets/images/iconos/visto.png);"
                            (click)="obtenerSeleccion()"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-3">
            </div>
            <div class="p-col-6">
                <div class="divgrups">
                    <div class="p-grid">
                        <!-- <div class="p-col-12">
                            <p-radioButton id="FEC" name="pagcre" value="FEC" label="Fecha Vencimiento"
                                [(ngModel)]="rbtFECCUO"></p-radioButton>
                        </div>
                        <div class="p-col-12">
                            <p-radioButton id="CUO" name="pagcre" value="CUO" label="Cuotas" [(ngModel)]="rbtFECCUO">
                            </p-radioButton>
                        </div> -->
                        <!-- CDPJ -->
                        <form [formGroup]="myForm">
                            <div class="p-col-12">
                                <p-radioButton id="FEC" name="pagcre" value="FEC" label="Fecha Vencimiento"
                                    [(ngModel)]="rbtFECCUO" formControlName="myRadio"></p-radioButton>
                            </div>
                            <div class="p-col-12">
                                <p-radioButton id="CUO" name="pagcre" value="CUO" label="Cuotas" [(ngModel)]="rbtFECCUO"
                                    formControlName="myRadio">
                                </p-radioButton>
                            </div>
                        </form>
                        <!-- CDPJ -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>


<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
    <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
    <div class="divgrups">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-1"></div>
                    <div class="p-col-4">
                        <label for="clave" style="font-weight: bold">Clave de Autorización:</label>
                    </div>
                    <div class="p-col-2"></div>
                    <div class="p-col-4">
                        <input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
                            (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
                    </div>
                    <div class="p-col-1"></div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Nuevo Cliente" [(visible)]="displayDialogNuevoCliente" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '35vw', height: 'auto'}" [closable]="false">
    <app-dlgnuevocliente *ngIf="displayDialogNuevoCliente" [strCodigoVendedor]="encptovtaService.encfac[indicador].VEN_CODIGO"
    [txtRuc]="txtRuc"  
    (salir)="salirNuevoCliente($event)"></app-dlgnuevocliente>
</p-dialog>

<p-dialog header="Clave Supervisor" [(visible)]="displayDialogSupervisor" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" (onHide)="onHideSupervisor()" [style]="{width: '40vw', height: 'auto'} ">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1"></div>
                <div class="p-col-4">
                    <label for="usuariosup" style="font-weight: bold">Usuario:</label>
                </div>
                <div class="p-col-2"></div>
                <div class="p-col-4">
                    <input pInputText id="usuariosup" [(ngModel)]="usuarioSup" placeholder="Ingrese el usuario">
                </div>
                <div class="p-col-1"></div>
                <div class="p-col-1"></div>
                <div class="p-col-4">
                    <label for="clavesup" style="font-weight: bold">Clave:</label>
                </div>
                <div class="p-col-2"></div>
                <div class="p-col-4">
                    <input type="password" pInputText id="clavesup" [(ngModel)]="claveSup"
                        (keydown.enter)="validarClave()" placeholder="Ingrese la clave">
                </div>
                <div class="p-col-1"></div>
            </div>
        </div>
    </div>
</p-dialog>


<p-dialog header="Anular Factura" [(visible)]="displayDialogRazon" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '400px'}">
    <div class="divgrups">

        <div class="ui-g ui-fluid">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Razón</span>
                </div>
                <div class="p-col-11"></div>
                <div class="p-col-12">
                    <label for="RAZONANULAR"></label>
                    <input class="frm" id="RAZONANULAR" type="text" pInputText [(ngModel)]="razonanular"
                        autocomplete="off">
                </div>
                <div class="p-col-12">
                    Debe ingresar la razón para anular la factura
                </div>
                <div class="p-col-8">
                </div>
                <div class="p-col-2">
                    <button type="button" class="big"
                        style="background-image: url(../../../assets/images/iconos/visto.png);"
                        (click)="anularFactura()"></button>
                </div>
                <div class="p-col-2">
                    <button type="button" class="big"
                        style="background-image: url(../../../assets/images/iconos/borrar.png);"
                        (click)="cancelarAnularFac()"></button>
                </div>

            </div>

        </div>
    </div>
</p-dialog>


<p-dialog header="Descuento Global" [(visible)]="displayDialogDescuento" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '35vw', height: '35vh'}">
    <div class="p-grid">
        <div class="p-col-12">
            <button pButton (click)="aplicarDescuento()"
                style="background-image: url(../../../assets/images/iconos/visto.png);">
            </button>
        </div>
        <div class="p-col-12">
            <p-tabView>
                <p-tabPanel header="Tipo Descuento">
                    <div class="p-grid">
                        <div class="p-col-1"></div>
                        <div class="p-col-3">
                            <p-radioButton name="opcionDescuento" label="Por Valor" value="dscValor"
                                [(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
                        </div>
                        <div class="p-col-3"></div>
                        <div class="p-col-3">
                            <p-radioButton name="opcionDescuento" label="En Porcentaje" value="dscPorcentaje"
                                [(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
                        </div>
                        <div class="p-col-2"></div>
                        <div class="p-col-1"></div>
                        <div class="p-col-4">
                            <label for="DscValor"></label>
                            <input id="DscValor" type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
                                [(ngModel)]="valorDscValor" [disabled]="booleanValor" appThreeDigitDecimalNumber>
                        </div>
                        <div class="p-col-2"></div>
                        <div class="p-col-4">
                            <label for="DscPorcentaje"></label>
                            <input id="DscPorcentaje" type="text" pInputText pKeyFilter="num"
                                (focusout)="comprobarValor()" [(ngModel)]="valorDscPorcentaje"
                                [disabled]="booleanPorcentaje" appTwoDigitDecimaNumber>
                        </div>
                        <div class="p-col-1"></div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
    <app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'venencfac'"
        (signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="encptovtaService.encfac[indicador].ENCFAC_NUMERO" [strCampo]="'ENCFAC_NUMERO'"
		(cerrar)="salirDialog()"></app-sacimaecfngimpr>
</p-dialog>

<!-- CDPJ -->
<p-dialog header="Actualización de Datos" [(visible)]="displayDialogActualizacionFE" [transitionOptions]="'0ms'"
	[modal]="true" appendTo="body" [style]="{width: '45vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<button pButton (click)="actualizarDatos('C', this.encptovtaService.encfac[this.indicador].CLI_CODIGO)" style="background-image: url(../../../assets/images/iconos/guardar.png);
 					background-size: contain !important; width: 40px; height: 40px">
			</button>
		</div>
		<div class="p-col-12">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-4">
						<label for="TXTCORREO1" style="font-weight: bold">Correo Electrónico1:</label>
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-6">
						<label for="TXTCORREO1"></label>
						<input class="frm" id="TXTCORREO1" type="text" pInputText [(ngModel)]="txtCorreo1"
							autocomplete="off">
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="TXTCORREO2" style="font-weight: bold">Correo Electrónico2:</label>
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-6">
						<label for="TXTCORREO2"></label>
						<input class="frm" id="TXTCORREO2" type="text" pInputText [(ngModel)]="txtCorreo2"
							autocomplete="off">
					</div>
					<div class="p-col-2"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr *ngIf="displayDialogSeriales" [strNumFact]="strNumFact" [strFechaSer]="strFechaSer" [strTipoDoc]="'VENPTOVTA'" [strTipoTrn]="'SA'"
		(cerrar)="cerrarSeriales()"></app-invtrnkardexotr>
</p-dialog>
<p-dialog header="Log de la Firma Electrónica" [(visible)]="displayDialogLog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: '60vh'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<label for="LOGFIRMA"></label>
			<textarea id="LOGFIRMA" rows="12" cols="100" pInputTextarea style="width: 100%; height: 400px"
				[(ngModel)]="logResultado" [disabled]="true" maxlength="3000"></textarea>
		</div>
	</div>
</p-dialog>
<p-dialog header="Bases IVA" [(visible)]="displaybasesiva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '55vw', height: '55vh'} " [focusOnShow]="true" appendTo="body" >

	<app-aggridsaci [width]="-800" [height]="-800" [enableSorting]="true" [enableFilter]="true"
			[rowData]="basesiva" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsBasesIva"
			[defaultColDef]="defaultColBasesIva" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>



</p-dialog>
<!-- CDPJ -->