<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="notrec"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="notrecConfirmar" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones($event)">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
				   [dismissible]="true" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="compclaveanular()"
																			  class="archivoOpc">Anular</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="confirmarGenerarAsiento()"
																			  class="archivoOpc">Generar Asiento</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="verAsiento()"
																			  class="archivoOpc">Abrir Asiento</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarSeriales()"
																			  class="archivoOpc">Ingresar Seriales</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="displayTasaC()"
																			  class="archivoOpc">Calcular Tasa de Cambio</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="actbasesiva()"
								class="archivoOpc">Actualizar bases IVA</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="verbasesiva()"
								class="archivoOpc">Visualizar bases IVA</span>
						</div>

					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<div class="p-grid" style="width: 99%;">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar" [botonBuscar]="btnBuscar"
						 (signal)="manejarSenales($event)" [botonAnterior]="btnAnterior" [botonPrimero]="btnPrimero"
						 [botonUltimo]="btnUltimo"
						 [botonSiguiente]="btnSiguiente" [barraBotones3]="true" [barraNotasRecepcion]="false"
						 [botonAnular]="false"
						 [botonAsiento]="false" [botonverAsiento]="false" [botonAlmacenardoc] = "true"></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -5px; width: 99%;">
	<div class="p-col-2">
		<input type="text" pInputText style="background-color: deepskyblue"
			   [(ngModel)]="notRecSeleccionado.ENCNOTREC_NUMERO" [disabled]="true">
	</div>
	<div class="p-col-2"></div>
	<div class="p-col-2">
		<input type="text" pInputText style="background-color: deepskyblue" [(ngModel)]="auxEstado"
			   [disabled]="true">
	</div>
	<div class="p-col-2"></div>
	<div class="p-col-2">
		<input type="text" pInputText style="background-color: deepskyblue"
			   [(ngModel)]="notRecSeleccionado.ASI_NRO" [disabled]="true">
	</div>
	<div class="p-col-2"></div>
</div>
<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()">
	<p-tabPanel header="Recepción">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span class="txtAlign">Comprobante:</span>
						</div>
						<div class="p-col-2">
							<input type="text" id="comprobante" pInputText
								   [(ngModel)]="notRecSeleccionado.ENCNOTREC_REFERENCIA"
								   [disabled]="true">
						</div>
						<div class="p-col-2">

							<p-checkbox id="chkmercons"
										name="chkmercons" label="Mercadería en consignación"
										binary="{{chkmercons}}"
										[(ngModel)]="chkmercons" (click)="cambio('mercons')"
										(keydown.enter)="setFocus($event)"></p-checkbox>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1">
							<span class="txtAlign">Fecha Emisión:</span>
						</div>
						<div class="p-col-1">
							<p-calendar [(ngModel)]="strFechaEmision"
										id="ENCNOTREC_FEMISION" dateFormat="dd/mm/yy"
										[inputStyle]="{'width': '80px'}"
										(ngModelChange)="cambio($event)"></p-calendar>
						</div>
						<div class="p-col-1">
							<span class="txtAlign">% I.V.A.</span>
						</div>
						<div class="p-col-1">
							<input type="text" id="ENCNOTREC_PORCEIVA"
								   [disabled]="ivaB"
								   (keydown.enter)="setFocus($event)"
								   [(ngModel)]="notRecSeleccionado.ENCNOTREC_PORCEIVA">
						</div>
						<!-- <div class="p-col-1">
							<div class="p-grid">
								<div class="p-col-9">
									<input type="text" id="iva" pInputText style="width: 100%" disabled
									[(ngModel)]="notRecSeleccionado.ENCNOTREC_PORCEIVA"
									>
								</div>
								<div class="p-col-3">
									<button type="button"  [disabled]="ivaB"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirbusquedaiva()"></button>
								</div>
							</div>
						</div> -->
						<!-- <div class="p-col-1">
							<span style="color: blue; float: left">{{tariivadescripcion}}</span>
						</div> -->
					</div>
				</div>
				<!-- <div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span class="txtAlign">Proveedor:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" id="PRO_CODIGO"
										   (input)="cambio($event)"
										   (keydown.enter)="buscarProveedor()" pInputText
										   [(ngModel)]="notRecSeleccionado.PRO_CODIGO">
								</div>
								<div class="p-col-4">
									<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="buscarProveedor()"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span style="color: blue">{{proveedorNombre}}</span>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1">
							<span class="txtAlign">Fecha Entrega:</span>
						</div>
						<div class="p-col-1">
							<p-calendar [(ngModel)]="strFechaEntrega"
										dateFormat="dd/mm/yy" [inputStyle]="{'width': '80px'}"></p-calendar>

						</div>
						<div class="p-col-1">
							<span class="txtAlign">Referencia:</span>
						</div>
						<div class="p-col-1">
							<input type="text" id="ENCNOTREC_REFERENCIA_ADI" (input)="cambio($event)"
								   (keydown.enter)="setFocus($event)" pInputText
								   [(ngModel)]="notRecSeleccionado.ENCNOTREC_REFERENCIA_ADI">
						</div>
					</div>
				</div> -->
				<!-- CDPJ -->
				<div *ngIf="aucref === false"class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span class="txtAlign">Proveedor:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" id="PRO_CODIGO"
										   (input)="cambio($event)"
										   (keydown.enter)="buscarProveedor()" pInputText
										   [(ngModel)]="notRecSeleccionado.PRO_CODIGO">
								</div>
								<div class="p-col-4">
									<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="buscarProveedor()"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span style="color: blue">{{proveedorNombre}}</span>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1">
							<span class="txtAlign">Fecha Entrega:</span>
						</div>
						<div class="p-col-1">
							<p-calendar [(ngModel)]="strFechaEntrega"
										dateFormat="dd/mm/yy" [inputStyle]="{'width': '80px'}"></p-calendar>

						</div>
						<div class="p-col-1">
							<span class="txtAlign">Referencia:</span>
						</div>
						<div class="p-col-1">
							<input type="text" id="ENCNOTREC_REFERENCIA_ADI" (input)="cambio($event)"
								   (keydown.enter)="setFocus($event)" pInputText
								   [(ngModel)]="notRecSeleccionado.ENCNOTREC_REFERENCIA_ADI">
						</div>
					</div>
				</div>
				<!-- CDPJ -->
				<div *ngIf="aucref === true" class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span class="txtAlign">Proveedor:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" id="PRO_CODIGO"
										   (input)="cambio($event)"
										   (keydown.enter)="buscarProveedor()" pInputText
										   [(ngModel)]="notRecSeleccionado.PRO_CODIGO">
								</div>
								<div class="p-col-4">
									<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="buscarProveedor()"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span style="color: blue">{{proveedorNombre}}</span>
						</div>
						<!-- <div class="p-col-2"></div> -->
						<div class="p-col-1">
							<span class="txtAlign">Fecha Entrega:</span>
						</div>
						<div class="p-col-1">
							<p-calendar [(ngModel)]="strFechaEntrega"
										dateFormat="dd/mm/yy" [inputStyle]="{'width': '80px'}"></p-calendar>
						</div>
						<div class="p-col-1">
							<span class="txtAlign">Referencia:</span>						
						</div>
						<div class="p-col-4">
							<input type="text" id="ENCNOTREC_REFERENCIA_ADI" (input)="cambio($event)"
								   (keydown.enter)="setFocus($event)" pInputText
								   [(ngModel)]="notRecSeleccionado.ENCNOTREC_REFERENCIA_ADI">
						</div>
						
					</div>
				</div>
				<!-- CDPJ -->
				<div class="p-col-12" style="margin-top: -23px">
					<div class="p-grid">
						<div class="p-col-1">
							<span class="txtAlign">Contacto:</span>
						</div>
						<div class="p-col-5">
							<input id="ENCNOTREC_CONTACTO" (input)="cambio($event)"
								   (keydown.enter)="setFocus($event)"
								   type="text" pInputText [(ngModel)]="notRecSeleccionado.ENCNOTREC_CONTACTO">
						</div>

						<div class="p-col-1">
								
						</div>
						<div class="p-col-1">
							<!-- CDPJ -->
							<span class="txtAlign">Tasa de cambio:</span>
						</div>
						<div class="p-col-1">
							<!-- CDPJ -->
							<!-- <input type="text" 								   
								   (keydown.enter)="setFocus($event)"
								   [(ngModel)]="notRecSeleccionado.ENCNOTREC_TASA_CAMBIO"> -->
								   <input type="text" (input)="validarNumero()" pInputText pKeyFilter="num" id="ENCNOTREC_TASA_CAMBIO" 
								   (input)="cambio($event)" mask="0000.0000"
								   
									   [(ngModel)]="notRecSeleccionado.ENCNOTREC_TASA_CAMBIO"
									   maxlength="9" >
						</div>
						<div class="p-col-1">
							<span class="txtAlign">Bodega:</span>
						</div>
						<div class="p-col-1">
							<input type="text" id="auxBodCod" (input)="cambio($event)"
								   (keydown.enter)="buscarBodega()" pInputText
								   [(ngModel)]="auxBodCod">
						</div>
						<div class="p-col-1">
							<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="buscarBodega()"></button>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -20px; margin-bottom: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span class="txtAlign">Comentario:</span>
						</div>
						<div class="p-col-8">
							<input type="text" (input)="cambio($event)" id="observacion"
								   (keydown.enter)="setFocus($event)"
								   [(ngModel)]="notRecSeleccionado.ENCNOTREC_OBSERVACION">
						</div>
						<div class="p-col-1"><span class="txtAlign">Fact. Relac:</span></div>
						<div class="p-col-1"><input type="text" id="bodega" (input)="cambio($event)"
													(keydown.enter)="setFocus($event)" pInputText
													[(ngModel)]="notRecSeleccionado.ENCNOTREC_FLAG"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<app-aggridsaci  [width]="1650"
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="comnotrecServicio.comnotrecRecepcionDETALLEarray[indicador]"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsRecepcion"
							[defaultColDef]=" defaultRecepcion"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="true"
							[mostrarGuardar]="false"
							[botonesAdicionales]="true"
							[comboOpciones]="comboOpciones"
							(rdbtipodet)="cambiarTipo($event)"
							[tipodetalle]="tipodetalle"
							[botonOrdenCompra]="false"
							[botonOrden]="btnOrdenCompra"
							[botonNuevo]="btnNuevoGrid"
							[botonBorrar]="btnBorrarGrid"
							[botonRegresar]="btnCancelarGrid"
							(cambioboton)="cambiarBoton($event)"
							(selected)="senialestablaRecepcion($event)"
							aling="left"
							(cambios)="cambio($event)">


			</app-aggridsaci>
		</div>
	</p-tabPanel>

	<p-tabPanel header="Importaciones">
		<div class="p-col-12">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="comnotrecServicio.comnotrecImportacionarray[indicador]"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsImportaciones"
							[defaultColDef]=" defaultImportaciones"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="true"
							[mostrarGuardar]="false"
							[botonesAdicionales]="true"
							[botonNuevo]="btnNuevoGrid"
							[botonBorrar]="btnBorrarGrid"
							[botonRegresar]="btnCancelarGrid"
							[notlistenkey]="bolean"
							(selected)="senialestablaImportacion($event)"
							aling="left"
							(cambios)="cambio($event)"
							(cambioboton)="cambiarBoton($event)">
			</app-aggridsaci>
		</div>
		<div class="divgrups2" style="padding-left: 20px" id="divImportaciones">
			<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
				<div class="p-col-2">
					<span>Tipo de Distribución</span>
				</div>
				<div class="p-col-10"></div>
				<div class="p-col-6" id="divImportacioneschk">
					<div class="p-grid">
						<div class="p-col-1">
							<p-checkbox id="chkvalor"
										name="chkvalor" value="" label="" binary="{{chkvalor}}"
										[disabled]="chkvalorDis"
										[(ngModel)]="chkvalor" (click)="controlchecksImportacion()"
										(keydown.enter)="setFocus($event)"></p-checkbox>
						</div>
						<div class="p-col-2">
							<span>Valor</span>
						</div>
						<div class="p-col-1">
							<p-checkbox id="chkvolumen"
										name="chkfacelectro" value="" label="" binary="{{chkvolumen}}"
										[disabled]="chkvolumenDis"
										[(ngModel)]="chkvolumen" (click)="controlchecksImportacion()"
										(keydown.enter)="setFocus($event)"></p-checkbox>
						</div>
						<div class="p-col-2">
							<span>Volumen</span>
						</div>
						<div class="p-col-1">
							<p-checkbox id="chkpeso"
										name="chkpeso" value="" label="" binary="{{chkpeso}}"
										[disabled]="chkpesoDis"
										[(ngModel)]="chkpeso" (click)="controlchecksImportacion()"
										(keydown.enter)="setFocus($event)"></p-checkbox>
						</div>
						<div class="p-col-2">
							<span>Peso</span>
						</div>
						<div class="p-col-1">
							<p-checkbox id="chkfactor"
										name="chkfactor" value="" label="" binary="{{chkfactor}}"
										[disabled]="chkfactorDis"
										[(ngModel)]="chkfactor" (click)="controlchecksImportacion()"
										(keydown.enter)="setFocus($event)"></p-checkbox>
						</div>
						<div class="p-col-2">
							<span>Factor</span>
						</div>
					</div>
				</div>
				<div class="p-col-2">
					<button class="aceptar" pButton
							(click)="distribuir()"
							style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; margin-left: 25px"
							[disabled]="boolDistribuir">

						Distribuir
					</button>
				</div>
				<div class="p-col-2">
					<button class="aceptar" pButton
							(click)="reverzar()"
							style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; margin-left: 25px"
							[disabled]="boolReverzar">
						Reverzar
					</button>
				</div>
				<div class="p-col-1">
					<input align="rigth" class="label" class="frm" id="TOTAL_IMPORTACION" type="text"
						   pInputText
						   [(ngModel)]="notRecSeleccionado.TOTAL_DISTRIBUIR"
						   autocomplete="off" [disabled]="true">
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Varios">
		<div class="p-col-12">
			<app-aggridsaci  [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="comnotrecServicio.comnotrecVararray[indicador]"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsVarios"
							[defaultColDef]=" defaultVarios"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="true"
							[mostrarGuardar]="false"
							[botonesAdicionales]="true"
							[botonNuevo]="btnNuevoGrid"
							[botonBorrar]="btnBorrarGrid"
							[botonRegresar]="btnCancelarGrid"
							(selected)="senialestablaVarios($event)"
							aling="left"
							(cambios)="cambio($event)"
							(cambioboton)="cambiarBoton($event)"
			>
			</app-aggridsaci>
		</div>

		<div class="divgrups2" id="divVarios">
			<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
				<div class="p-col-8"></div>

				<div class="p-col-2">
					<input class="label" class="frm" id="TOTAL_VARIOS2" type="text" pInputText
						   [(ngModel)]="notRecSeleccionado.TOTAL_VARIOS"
						   autocomplete="off" [disabled]="true">
				</div>

			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Estado de Cuenta">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Proveedor:</span>
				</div>

				<div class="p-col-6">
					<input class="label" class="frm" id="PRO_CODNOMBRE" type="text" pInputText
						   [(ngModel)]="codnombre"
						   autocomplete="off" [disabled]="true">
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-2"></div>


			</div>
		</div>
		<app-aggridsaci  [width]=""
						[height]="largoestcta"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="cxctmptrnresumen"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsCxctmptrnresumen"
						[defaultColDef]="defaultProvEC"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="false"
						[mostrarGuardar]="false"
						[botonesAdicionales]="false"
		></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Saldos">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Proveedor:</span>
				</div>

				<div class="p-col-6">
					<input class="label" class="frm" id="PRO_CODNOMBR1" type="text" pInputText
						   [(ngModel)]="codnombre"
						   autocomplete="off" [disabled]="true">
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-2"></div>


			</div>
		</div>
		<app-aggridsaci  [width]=""
						[height]="largosaldo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="cxctrnresumen"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsCxctrnresumen"
						[defaultColDef]="defaultProvS"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="false"
						[mostrarGuardar]="false"
						[botonesAdicionales]="false"
		></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Estadísticas" [disabled]="disabledEstadisticas">
		<div class="p-grid">
			<!--LINEA 1-->
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-4">
								<button pButton (click)="mostrarEstadisticas()"
										style="background-image: url(../../../assets/images/iconos/cogwheel.png); width: 40px; height: 40px; background-color: transparent;"
										title="Generar estadísticas">
								</button>
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">COMPRAS POR MESES</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-chart [options]="options" type="bar" [data]="dataComprasProveedor" height="415px"></p-chart>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-6">
									<span style="font-size: 16px; font-weight: bold;">
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										PRODUCTOS MÁS COMPRADOS</span>
							</div>
							<div class="p-col-3">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="cantidad" (onClick)="orderByTopProd('cantidad')"
											   inputId="orden1"
								></p-radioButton>
								<label> Cantidad </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="precio" (onClick)="orderByTopProd('total')"
											   inputId="orden2"
								></p-radioButton>
								<label> Total </label>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px;">
						<app-aggridsaci [width]=""
										[height]="'390'"
										[enableSorting]="true"
										[enableFilter]="true"
										[rowData]="detTopProductos"
										[frameworkComponents]="frameworkComponents"
										[animateRows]="true"
										[rowSelection]="rowSelection"
										[columnDefs]="columnDefsTopProductos"
										[defaultColDef]="defaultColTopProductos"
										[rowStyle]="rowStyleTopProd"
										[botones]="false"
										[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: 20px;">
				<div class="p-grid">
					<div class="p-col-5">
					</div>
					<div class="p-col-2">
						<span style="font-size: 16px; font-weight: bold;">
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										FORMULARIO</span>
					</div>
					<div class="p-col-5">
					</div>

					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Artículo:</span>
							</div>

							<div class="p-col-2" style="text-align: left">
								<div class="p-grid">
									<div class="p-col-8">
										<input id="ART_CODIGO" type="text" pInputText
											   autocomplete="off" [(ngModel)]="txtArtCodigo">
									</div>
									<div class="p-col-4">
										<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="buscarArticulo()"
										></button>
									</div>
								</div>
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Unidad:</span>
							</div>
							<div class="p-col-1">
								<input id="ART_UNIDAD" type="text" pInputText disabled [(ngModel)]="txtArtUnidad"
									   autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Existencia Máxima:</span>
									</div>
									<div class="p-col-5">
										<input id="ART_EXISTMAX" type="text" pInputText disabled
											   autocomplete="off" [(ngModel)]="txtExistenciaMax" >
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -5px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Descripción:</span>
							</div>

							<div class="p-col-2" style="text-align: left">
								<input id="ART_DESCRIPCION" type="text" pInputText disabled
									   autocomplete="off" [(ngModel)]="txtArtDescripcion">
							</div>

							<div class="p-col-2">
								<!--								<span>Rotación:</span>-->
							</div>
							<div class="p-col-1">
								<!--								<input id="ART_ROTACION" type="text" pInputText disabled [(ngModel)]="txtArtRotacion"-->
								<!--									   autocomplete="off">-->
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Existencia Mínima:</span>
									</div>
									<div class="p-col-5">
										<input id="ART_EXISTMIN" type="text" pInputText disabled [(ngModel)]="txtExistenciaMin"
											   autocomplete="off">
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-table [value]="detalleExistencia">
							<ng-template pTemplate="header">
								<tr>
									<th>Existencia</th>
									<th>Ordenes de Compra</th>
									<th>Pedidos Clientes</th>
									<th>Saldo</th>
									<th>Existencia Mínima</th>
									<th>Comprar</th>
									<th>Últ. Costo Compra</th>
									<th>Total Compra</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-existencia>
								<tr>
									<td style="text-align: right">{{existencia.EXISTENCIA}}</td>
									<td style="text-align: right">{{existencia.ORDENES_COMPRA}}</td>
									<td style="text-align: right">{{existencia.PEDIDOS_CLIENTES}}</td>
									<td style="text-align: right">{{existencia.SALDO}}</td>
									<td style="text-align: right">{{existencia.EXIST_MIN}}</td>
									<td style="text-align: right">{{existencia.COMPRAR}}</td>
									<td style="text-align: right">{{existencia.ULTCOSTO_COM}}</td>
									<td style="text-align: right">{{existencia.TOTAL_COMPRA}}</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
				<p-tabView>
					<p-tabPanel header="Movimientos">
						<div class="p-col-12" style="margin-top: 10px;">
							<div class="p-grid">
								<div class="p-col-3">
								</div>
								<div class="p-col-2">
									<span>Ordenar por:</span>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-radioButton name="orden" value="fecha" (onClick)="llenarMovByProvArt('fecha')"
												   inputId="orden1"
									></p-radioButton>
									<label> Fecha </label>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-radioButton name="orden" value="costo" (onClick)="llenarMovByProvArt('costo')"
												   inputId="orden2"
									></p-radioButton>
									<label> Costo </label>
								</div>
							</div>
						</div>
						<app-aggridsaci [width]=""
										[height]="'450'"
										[enableSorting]="true"
										[enableFilter]="true"
										[rowData]="detMovimientosProv"
										[frameworkComponents]="frameworkComponents"
										[animateRows]="true"
										[rowSelection]="rowSelection"
										[columnDefs]="columnDefsMovimientosByProv"
										[defaultColDef]="defaultColMovByProv"
										[rowStyle]="rowStyle"
										[botones]="false"
										[singleClickEdit]="false">
						</app-aggridsaci>


					</p-tabPanel>
					<p-tabPanel header="Existencia por Bodega">
						<app-aggridsaci [width]=""
										[height]="'450'"
										[enableSorting]="true"
										[enableFilter]="true"
										[rowData]="detExistenciaBodegas"
										[frameworkComponents]="frameworkComponents"
										[animateRows]="true"
										[rowSelection]="rowSelection"
										[columnDefs]="columnDefsExistenciaBodegas"
										[defaultColDef]="defaultColExistBod"
										[rowStyle]="rowStyle"
										[botones]="false"
										[singleClickEdit]="false">
						</app-aggridsaci>
					</p-tabPanel>
					<p-tabPanel header="Ventas vs Compras">
						<div class="p-grid">
							<!--LINEA 1-->
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-12">
										<p-chart  type="bar" [data]="dataVentasVsCompras" height="415px"></p-chart>
									</div>
								</div>
							</div>
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-4">
									</div>
									<div class="p-col-4"><span style="font-size: 16px; font-weight: bold;">
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										Índice de Provisión</span>
									</div>
									<div class="p-col-4">
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Inventario inicial:</span>
											</div>
											<div class="p-col-2">
												<input id="INVENTARIO_INICIAL" type="text" pInputText
													   autocomplete="off" readonly [(ngModel)]="txtInventarioIni" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>(+) Compras del periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="COMPRAS_PERIODO" type="text" pInputText
													   autocomplete="off" readonly [(ngModel)]="txtComprasPeriodo" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Total disponible:</span>
											</div>
											<div class="p-col-2">
												<input id="TOTAL_DISPONIBLE" type="text" pInputText
													   autocomplete="off" readonly
													   [(ngModel)]="txtTotalDisponible" style="text-align: right; background-color: yellow">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Ventas del último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="VENTA_ULTIMOPER" type="text" pInputText
													   autocomplete="off" readonly [(ngModel)]="txtVenUltimoPer" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Ventas promedio 10 periodos anteriores:</span>
											</div>
											<div class="p-col-2">
												<input id="VENTASPROM_PERANT" type="text" pInputText
													   autocomplete="off" readonly [(ngModel)]="txtVenPromPerAnt" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV_ULTPER" type="text" pInputText
													   autocomplete="off" readonly [(ngModel)]="txtIndiceProvUltPer" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión 10 últimos periodos:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV" type="text" pInputText
													   autocomplete="off" readonly [(ngModel)]="txtIndiceProvPeriodos" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Compras planificadas:</span>
											</div>
											<div class="p-col-2">
												<input id="COMPRAS_PLANIFICADAS" type="number" pInputText
													   autocomplete="off" [(ngModel)]="txtComprasPlanificadas" style="text-align: right">
											</div>
											<div class="p-col-2">
												<button pButton (click)="calcularIndice()"
														style="background-image: url(../../../assets/images/iconos/cogwheel.png);background-color: transparent;"
														title="Calcular Índice">
												</button>
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Total disponible:</span>
											</div>
											<div class="p-col-2">
												<input id="totalDisponible" type="text" pInputText readonly
													   autocomplete="off" [(ngModel)]="txtTotalDisponibleCom" style="text-align: right; background-color: yellow">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV_ULT" type="text" pInputText
													   autocomplete="off" readonly [(ngModel)]="txtIndiceProvUltPer2" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión 10 últimos periodos:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPRO" type="text" pInputText
													   autocomplete="off" readonly [(ngModel)]="txtIndiceProvPeriodos2" style="text-align: right">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
	</p-tabPanel>
	<div class="divgrupstabla">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<span class="obligatorio">Neto</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Dscto (Valor)</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Dscto (%)</span>
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<span class="obligatorio">Base I.V.A.</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Valor I.V.A.</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Base I.C.E.</span>
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<span class="obligatorio">Valor I.C.E.</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Base Cero</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Total</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -20px">
				<div class="p-grid">
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<input type="text"
									   class="totales"
									   pInputText [(ngModel)]="notRecSeleccionado.ENCNOTREC_TOTALNETO"
									   disabled>
							</div>
							<div class="p-col-4">
								<input type="text"
									   class="totales"
									   pInputText [(ngModel)]="notRecSeleccionado.ENCNOTREC_VALORDES"
									   disabled>
							</div>
							<div class="p-col-4">
								<input type="text"
									   class="totales"
									   pInputText [(ngModel)]="notRecSeleccionado.ENCNOTREC_PORCEDES"
									   disabled>
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<input type="text"
									   class="totales"
									   pInputText [(ngModel)]="notRecSeleccionado.ENCNOTREC_BASEIVA"
									   disabled>
							</div>
							<div class="p-col-4">
								<input type="text"
									   class="totales"
									   pInputText [(ngModel)]="notRecSeleccionado.ENCNOTREC_VALORIVA"
									   disabled>
							</div>
							<div class="p-col-4">
								<input type="text"
									   class="totales"
									   pInputText [(ngModel)]="notRecSeleccionado.ENCNOTREC_BASEICE"
									   disabled>
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<input type="text"
									   class="totales"
									   pInputText [(ngModel)]="notRecSeleccionado.ENCNOTREC_VALORICE"
									   disabled>
							</div>
							<div class="p-col-4">
								<input type="number"
									   class="totales"
									   pInputText [(ngModel)]="notRecSeleccionado.ENCNOTREC_BASECERO"
									   disabled>
							</div>
							<div class="p-col-4">
								<input type="text"
									   class="totales"
									   pInputText [(ngModel)]="notRecSeleccionado.ENCNOTREC_TOTAL"
									   disabled>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-tabView>


<p-dialog header="Seleccione la opción" [(visible)]="displayDialogBusqueda"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width:'90vw', height:'80vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
					 [busquedaParams]="busquedacampos" [consulta]="consulta" [where]="where"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<!-- <p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'90vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'comencdevfac'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog> -->
<p-dialog header="Lotes" [(visible)]="displayDialogLotes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnlote [auxiliar]="auxiliarlot" [detalle]="notRecRecepcion" [encabezado]="notRecSeleccionado" [cadena]="'COMNOT'"></app-invtrnlote>
</p-dialog>
<p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  (onHide)="seleccionarDetalle()"

		  appendTo="body"
		  [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr *ngIf="displayDialogSeriales" [strNumFact]="notRecSeleccionado.ENCNOTREC_NUMERO" [strFechaSer]="strFechaSer"
						 [strTipoDoc]="'NOTREC'"
						 [strTipoTrn]="'EN'"
						 (cerrar)="cerrarSeriales()"></app-invtrnkardexotr>
</p-dialog>
<p-dialog header="Descuento Global" [(visible)]="displayDialogDescuento"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '35vw', height: '35vh'}">
	<div class="p-grid">
		<div class="p-col-12">
			<button class="littlebuttons" pButton (click)="aplicarDescuento()"
					style="background-image: url(../../../assets/images/iconos/visto.png);">
			</button>
		</div>
		<div class="p-col-12">
			<p-tabView>
				<p-tabPanel header="Tipo Descuento">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="Por Valor" value="dscValor" [(ngModel)]="okay"
										   (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-3"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="En Porcentaje" value="dscPorcentaje"
										   [(ngModel)]="okay" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								   (keydown.enter)="aplicarDescuento()" [(ngModel)]="okay" [disabled]="booleanValor"
								   appTwoDigitDecimaNumber>
							<!--<p-message *ngIf="valorInvalidoV === true" severity="error" text="Valor de descuento no válido"></p-message>-->
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								   (keydown.enter)="aplicarDescuento()" [(ngModel)]="okay"
								   [disabled]="booleanPorcentaje" appTwoDigitDecimaNumber>
							<!--<p-message *ngIf="valorInvalidoP === true" severity="error" text="Porcentaje no válido "></p-message>-->
						</div>
						<div class="p-col-1"></div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</p-dialog>

<!--
<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '60vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clave" [(ngModel)]="claveAuth" (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>
<p-dialog header="Estado del pedido" [(visible)]="estadopedDialog" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '60vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-2">
					<input type="text" pInputText style="background-color: deepskyblue" [(ngModel)]="encpedService.encabezadoPedidoSeleccionado.ENCPED_NUMERO" [disabled]="true">
				</div>
				<div class="p-col-3"></div>
				<div class="p-col-2">
					<span>Estado Actual:</span>
				</div>
				<div class="p-col-1">
					<span *ngIf="estadoPedido[estadoPedido.length - 1] !== undefined" style="color: red">{{estadoPedido[estadoPedido.length - 1].PEDEST_ESTADO}}</span>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-1">
					<button type="button" style="background-image: url(../../../assets/images/iconos/guardar.png);" (click)="guardarEstPed()"></button>
				</div>
				<div class="p-col-1">
					<button type="button" style="background-image: url(../../../assets/images/iconos/salir.png);" (click)="salirEstPed()"></button>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cambiar Estado:</span>
				</div>
				<div class="p-col-2">
					<p-dropdown [options]="opcionEstado" [(ngModel)]="estadoValor" [style]="{'width': '200px'}"></p-dropdown>
				</div>
				<div class="p-col-8"></div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Razón:</span>
				</div>
				<div class="p-col-10">
					<input type="text" style="width: 100%" [(ngModel)]="razonValor">
				</div>
			</div>
		</div>
		<p-table [scrollable]="true" scrollHeight="300px" [value]="estadoPedido">
			<ng-template pTemplate="colgroup" let-columns>
				<colgroup>
					<col style="width: 100px">
					<col style="width: 100px">
					<col style="width: 150px">
					<col style="width: 100px">
					<col style="width: 300px">
				</colgroup>
			</ng-template>
			<ng-template pTemplate="header">
				<tr class="ui-table-scrollable-wrapper">
					<th style="width: 100px">Fecha</th>
					<th style="width: 100px">Hora</th>
					<th style="width: 150px">Estado</th>
					<th style="width: 100px">Usuario</th>
					<th style="width: 300px">Razón</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body"
						 let-rowData let-estado>
				<tr>
					<td style="height: 36px">{{estado.PEDEST_FECHA}}</td>
					<td>{{estado.PEDEST_HORA}}</td>
					<td>{{estado.PEDEST_ESTADO}}</td>
					<td  style="height: 36px">
						{{estado.PEDEST_USUARIO}}
					</td>
					<td style="height: 36px">
						{{estado.PEDEST_RAZON}}
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</p-dialog>


-->
<p-dialog header="Descuento Global" [(visible)]="displayDialogDescuento"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '35vw', height: '35vh'}">
	<div class="p-grid">
		<div class="p-col-12">
			<button class="littlebuttons" pButton (click)="aplicarDescuento()"
					style="background-image: url(../../../assets/images/iconos/visto.png);">
			</button>
		</div>
		<div class="p-col-12">
			<p-tabView>
				<p-tabPanel header="Tipo Descuento">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="Por Valor" value="dscValor"
										   [(ngModel)]="dscSeleccionado"
										   (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-3"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="En Porcentaje" value="dscPorcentaje"
										   [(ngModel)]="dscSeleccionado"
										   (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								   (keydown.enter)="aplicarDescuento()" [(ngModel)]="valorDscValor"
								   [disabled]="booleanValor" appTwoDigitDecimaNumber>
							<p-message *ngIf="valorInvalidoV === true" severity="error"
									   text="Valor de descuento no válido"></p-message>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								   (keydown.enter)="aplicarDescuento()" [(ngModel)]="valorDscPorcentaje"
								   [disabled]="booleanPorcentaje" appTwoDigitDecimaNumber>
							<p-message *ngIf="valorInvalidoP === true" severity="error"
									   text="Porcentaje no válido "></p-message>
						</div>
						<div class="p-col-1"></div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</p-dialog>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>
<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="NumAsiRegCont" [abiertoDesde]="'comnotrec'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>
<p-dialog header="Reportes" [(visible)]="displayDialogReportes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
						 [strNumDoc]="notRecSeleccionado.ENCNOTREC_NUMERO"
						 [strCampo]="'ENCNOTREC_NUMERO'"
						 [strform]="'Si'"
						(cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'COMNOTREC'" [txtNumComprobante] = "notRecSeleccionado.ENCNOTREC_NUMERO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>

<!-- CDPJ Display-->
<p-dialog header="Tasa Cambio" [(visible)]="displayDialogTasa"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  [style]="{width: '35vw', height: '35vh'}">
	<div class="p-grid">
		<div class="p-col-12">
			<button class="littlebuttons" pButton (click)="aplicarTasaCambio()"
					style="background-image: url(../../../assets/images/iconos/visto.png);">
			</button>
		</div>
		<div class="p-col-12">
			<p-tabView>
				<p-tabPanel header="Tipo de cambio">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionTasa" label="Aplicar tasa de cambio" value="valorTasaCambio" [(ngModel)]="okay"
							[(ngModel)]="tasaSeleccionado" (ngModelChange)="cambioTasa()" [disabled]="booleanATasa"></p-radioButton>
						</div>
						<div class="p-col-3"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionTasa" label="Volver al valor original" value="valorOriginal"
							[(ngModel)]="tasaSeleccionado" (ngModelChange)="cambioTasa()" [(ngModel)]="okay" [disabled]="booleanAOriginal"></p-radioButton>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
						<div class="p-col-4">
							
							<!--<p-message *ngIf="valorInvalidoV === true" severity="error" text="Valor de descuento no válido"></p-message>-->
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-4">
							
							<!--<p-message *ngIf="valorInvalidoP === true" severity="error" text="Porcentaje no válido "></p-message>-->
						</div>
						<div class="p-col-1"></div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</p-dialog>
<p-dialog header="Clave de Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()"
							   placeholder="Ingrese la clave" autocomplete="off">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>

</p-dialog>
<p-dialog header="Bases IVA" [(visible)]="displaybasesiva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '55vw', height: '55vh'} " [focusOnShow]="true" appendTo="body" >

	<app-aggridsaci [width]="-800" [height]="-800" [enableSorting]="true" [enableFilter]="true"
			[rowData]="basesiva" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsBasesIva"
			[defaultColDef]="defaultColBasesIva" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
</p-dialog>
<!-- CDPJ -->
<!-- GSRF -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<!-- GSRF -->