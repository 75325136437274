import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NomcfgrgenService {
  
  public way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, 
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }

  cargaDatos(strCodigo) {
    let strSentencia = 'select cfg_valor1 from saci_maeconfiguracion where cfg_codigo = \'' + strCodigo  + '\'';
  
    return this.http.post<any[]>(this.way + '/nom/configuracion/617d95df8dd94c', {
        elementos: {
          CFG_CODIGO: strCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  actulizarDatos(strCodigo, cfg_valor1) {
    let strSentencia = 'update saci_maeconfiguracion  set cfg_valor1 = \'' + cfg_valor1 + '\'where cfg_codigo = \''+ strCodigo  + '\'';
    
    return this.http.post<any[]>(this.way + '/nom/configuracion/299df4aefc6eca', {
        elementos: {
          CFG_VALOR1: cfg_valor1,
          CFG_CODIGO: strCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  actulizarDatos2(strCodigo, cfg_valor1) {
    //let strSentencia = 'update saci_maeconfiguracion  set cfg_valor3 = \'' + cfg_valor1 + '\'where cfg_codigo = \''+ strCodigo  + '\'';
    
    return this.http.post<any[]>(this.way + '/nom/configuracion/299df4aefc6ecae', {
        elementos: {
          CFG_VALOR3: cfg_valor1,
          CFG_CODIGO: strCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
}
