import { Component, OnInit, ViewChild } from '@angular/core';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';
import { InvbusquedaService } from '../invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { InvDettransfer } from '../invinterfaces/invdettransfer';
import { InvenctransferService } from '../invservicios/invenctransfer.service';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { InvEnctransfer } from '../invinterfaces/invenctransfer';
import { Usuario } from 'src/app/usuario';
import { InvtrnkardexotrService } from '../../ven/venservicios/invtrnkardexotr.service';
import { InvTrnkardexotr } from '../../ven/veninterfaces/invtrnkardexotr';//CDPJ
@Component({
  selector: 'app-invenctransfer',
  templateUrl: './invenctransfer.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvenctransferComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;

  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonFactura: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  botonAutorizar: boolean;
  barraTransferencia: boolean;
  //horacam;
  botonTransferencia;

  index: number;

  dettransferSelected: InvDettransfer;
  // trnvariostrfSelected: InvTrnvariostrf;

  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  consulta: string;
  tabla: string;
  where: string;
  opcionbusqueda: string;

  // dialogs
  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogAutorizacion: boolean;
  displayDialogSeriales: boolean;
  displayDialogReportes: boolean;
  displayAcciones: boolean;

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;

  // Seriales
  strNumFact: any;
  strFechaSer: any;
  //CDPJ
  serialseleccionado: InvTrnkardexotr;
  disablebod: boolean = false;
  control = 0;
  COSTRFINV
  //CDPJ
  largo: string;

  groupDatosPorNumAdic: boolean;
  groupCodProv: boolean;
  boldateAutoriza: boolean;

  intMultiplesNum: number;
  intProveedorTransf: number;
  intUSURAZELI: number;

  permisos: SegMaePermiso;
  chkguiaremision: boolean;

  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  rowStyleEC;
  rowStyleSaldo;
  defaultColDef;
  disablebodconfig = false
  TNRBLBODO = 0
  boduser = ''
  bodnomuser = ''
  // defaultColDefVarios;

  // Reservas
  displayDialogReservas = false;
  bodCodigoReserva;
  bodDestinoReserva;
  fechaReserva;

  displayDialogDocumentos = false;

  columnDefsDettransfer = [
    { // 0
      headerName: 'Código', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Descripción', field: 'ART_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Unidad', field: 'UNI_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 200
        }
      }
    },
    { // 3
      headerName: 'Cantidad', field: 'DETTRANSFER_CANTIDAD', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tienecalculos: true,
          tamanocelda: 32,
          int: true,
        }
      }
    },
    { // 4
      headerName: 'Costo', field: 'DETTRANSFER_COSTOUNIT', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tienecalculos: true
        }
      }
    },
    { // 5
      headerName: 'Total', field: 'DETTRANSFER_COSTOTOTAL', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 6
      headerName: 'Referencia', field: 'DETTRANSFER_REFERENCIA', cellEditor: 'cellPrueba', width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    //CDPJ
    { // 7
      headerName: 'Exis.B.O', field: 'EXSBODORIGEN', cellEditor: 'cellPrueba', width: 100,
      editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 3,
          obligatorio: true,
          fecha: false,
          clave: true,
          tienecalculos: true
        }
      }
    },
    { // 8
      headerName: 'Exis.B.D', field: 'EXSBODDESTINO', cellEditor: 'cellPrueba', width: 100,
      editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 3,
          obligatorio: true,
          fecha: false,
          clave: true,
          tienecalculos: true
        }
      }
    }
    //CDPJ
  ];

  /*columnDefsTrnVariosTrf = [
    { // 0
      headerName: 'Código', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
    },
    { // 1
      headerName: 'Nombre', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 25,
            opcionbuscar: true
          }
        }
    },
    { // 2
      headerName: 'Importe', field: 'TRNVARIOS_IMPORTE', cellEditor: 'cellPrueba', width: 100,
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 25,
            opcionbuscar: true
          }
        }
    },
    { // 3
      headerName: 'Centro C.', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 25,
            opcionbuscar: true
          }
        }
    }
  ];*/

  strNombreForma: string;
  auxreporte: number = 0;

  constructor(public enctransferService: InvenctransferService, private init: NuevoComponentService,
    private busqService: InvbusquedaService, private datePipe: DatePipe,
    private messageService: MessageService, private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService, private confIniciales: ConfInicialesService,
    private confirmationService: ConfirmationService, private utilitariosService: UtilitariosService,
    private permisosService: PermisosService, public usuario: Usuario, public trnkardexotrservice: InvtrnkardexotrService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'dettransfer'
    };

    /*this.defaultColDefVarios = {
      editable: true,
      width: 100,
      objeto: 'trnvariostrf'
    };*/
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;

    this.ListarConfiguracionesTransfer();

    this.largo = '-5800';
    this.index = 0;


    this.displayAcciones = false;

    this.barraBotones2 = true;
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonFactura = false;
    this.botonAnular = false;
    this.botonXML = false;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.botonAutorizar = true;

    this.groupDatosPorNumAdic = true;
    this.groupCodProv = false;

    this.enctransferService.invenctransferarray[this.indicador] = {
      ASI_NRO: ''
    };

    this.enctransferService.invdettransferarray[this.indicador] = [];

    const dec: any = await this.confIniciales.getDecimalesP('frmINV_ENCTRANSFER');
    const permiso = await this.permisosService.getListaPermisos('INV', '6', '852').toPromise();
    this.enctransferService.decTOTALDET = 2;
    this.enctransferService.decTOTAL = 2;
    this.enctransferService.decdetcantidad = 2;
    this.enctransferService.decdetcosto = 2;
    if (dec !== null) {
      dec.map((decimales) => {
        if (decimales.NUMDEC_CAMPO === 'TOTALDET') {
          this.enctransferService.decTOTALDET = Number(decimales.NUMDEC_NUMDEC);
        } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
          this.enctransferService.decTOTAL = Number(decimales.NUMDEC_NUMDEC);
        } else if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
          this.enctransferService.decdetcantidad = Number(decimales.NUMDEC_NUMDEC);
        } else if (decimales.NUMDEC_CAMPO === 'COSTO') {
          this.enctransferService.decdetcosto = Number(decimales.NUMDEC_NUMDEC);
        }
      });
    }

    this.columnDefsDettransfer[3].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.enctransferService.decdetcantidad,
        obligatorio: true,
        fecha: false,
        clave: false,
        tienecalculos: true,
        tamanocelda: 32,
        int: true,
      }
    }

    this.columnDefsDettransfer[4].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.enctransferService.decdetcosto,
        obligatorio: true,
        fecha: false,
        clave: false,
        tienecalculos: true,
        tamanocelda: 32,
        int: true,
      }
    }

    this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultColDef.objeto, this.columnDefsDettransfer);

    //this.permisosService.getListaPermisos('INV', '6', '852').subscribe((permiso) => {
    this.permisos = permiso[0];
    //});
    this.serialseleccionado = {};//CDPJ
    console.log(this.permisos);

  }

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable
      const dia = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_FECHAEMISION.getDate();
      const mes = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_FECHAEMISION.getMonth();
      const anio = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_FECHAEMISION.getFullYear();

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'enctransfer',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;

    if (valor === 'Nuevo') {
      if (await this.ValidarSeriales(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO)) {
        // if (this.enctransferService.TNRBLBODO === 1 &&
        //   (this.boduser === null
        //     || this.boduser === undefined
        //     || this.boduser === '') && this.disablebodconfig === true) {
        //   this.messageService.add({
        //     key: 'enctransfer',
        //     severity: 'info',
        //     summary: 'Información',
        //     detail: 'Error, No tiene parametrizada una bodega, comuniquese con soporte '
        //   });
        //   return
        // }
        this.nuevo();
      }

    }
    if (valor === 'Guardar') {
      if (this.control === 0) {
        this.control = 1
        this.guardar();
      }

    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Primero') {
      //CDPJ
      if (await this.ValidarSeriales(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO)
      ) {
        console.log('5');
        this.seleccionarRegistro('P');
      }
      //CDPJ
    }
    if (valor === 'Anterior') {
      //CDPJ
      if (await this.ValidarSeriales(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO)) {
        this.seleccionarRegistro('A');
      }
      //CDPJ
    }
    if (valor === 'Siguiente') {
      //CDPJ
      if (await this.ValidarSeriales(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO)) {
        this.seleccionarRegistro('S');
      }
      //CDPJ
    }
    if (valor === 'Ultimo') {
      //CDPJ
      if (await this.ValidarSeriales(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO)) {
        this.seleccionarRegistro('U');
      }
      //CDPJ
    }
    if (valor === 'Exportar') {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERINSERCION === 0 && this.permisos.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de inserción ni de actualización, Consulte con el Administrador del Sistema.'
          });
          return;
        }
        if (this.permisos.PERSELECCION === 1 && this.permisos.PERINSERCION === 0 && this.permisos.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario solo tiene permisos para consultar, Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }

      this.exportarTransferencia();
    }
    if (valor === 'Importar') {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERINSERCION === 0 && this.permisos.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de inserción ni de actualización, Consulte con el Administrador del Sistema.'
          });
          return;
        }
        if (this.permisos.PERSELECCION === 1 && this.permisos.PERINSERCION === 0 && this.permisos.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario solo tiene permisos para consultar, Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      //CDPJ
      if (await this.ValidarSeriales(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO)) {
        this.buscar();
      }
      //CDPJ
    }
    if (valor === 'Autorizar') {
      //CDPJ
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERINSERCION === 0 && this.permisos.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de inserción ni de actualización, Consulte con el Administrador del Sistema.'
          });
          return;
        }
        if (this.permisos.PERSELECCION === 1 && this.permisos.PERINSERCION === 0 && this.permisos.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario solo tiene permisos para consultar, Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
      if (await this.ValidarSeriales(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO)) {
        this.autorizaTransferencia();
      }
      //CDPJ
    }
    if (valor === 'Transferir') {
      //CDPJ
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERINSERCION === 0 && this.permisos.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de inserción ni de actualización, Consulte con el Administrador del Sistema.'
          });
          return;
        }
        if (this.permisos.PERSELECCION === 1 && this.permisos.PERINSERCION === 0 && this.permisos.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario solo tiene permisos para consultar, Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }

      //if (await this.ValidarSeriales(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO)) {
      this.realizarTransferencia();
      //}
      //CDPJ
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Almacenardoc') {
      if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    if (valor === 'Salir') {
      if (await this.ValidarSeriales(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO)) {
        this.init.tabs.splice(this.init.tabindex, 1);
      }

    }
  }

  nuevo() {

    /* if (tabTransferencia.getSelectionIndex() == 0) { // generales
       if (listaPermisos != null) {
         SegMaePermiso;
         item = new SegMaePermiso();
         item = listaPermisos.get(0);
         if (item.getPerTodo() == 0) {
           if (item.getPerInsercion() == 0) {
             MessageDialog.openInformation(shell, 'Información', 'El usuario ' +
               Conexion.usuarioConectado + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
             return;
           }
         }
       }
     }
     
     if (verificarTotales() == false)
       return;
     
     if (!ValidarSeriales(lblNumero.getText()))
       return; */
    this.disablebod = false;
    if (this.enctransferService.TNRBLBODO === 0) {
      this.disablebodconfig = false
    }else if(this.enctransferService.TNRBLBODO === 1 && this.boduser === ''){
      this.disablebodconfig = false
    }
    this.limpiarDatos();

    /* if (Utilidades.verificarPeriodoContable(dateTimeFechaEmision.getDay(),
       dateTimeFechaEmision.getMonth(), dateTimeFechaEmision.getYear()) == false) {
       MessageDialog.openInformation(shell, 'Información', 'Error, fecha debe de estar dentro del período contable');
       return;
     }
     */
    document.getElementById('txtComprobante').focus();
    document.getElementById('contorno').style.pointerEvents = 'all';

    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;

    if (this.intMultiplesNum === 1) {
      this.busquedaNumeracion();
    }
  }

  busquedaNumeracion() {
    this.opcionbusqueda = 'numeracion';
    this.types = [
      { label: 'Código', value: 'NUMTRA_SIGLA' },
      { label: 'Descripción', value: 'NUMTRA_DESCRIPCION' },
      { label: 'Cliente', value: 'NUMTRA_CLIENTE' }
    ];

    this.busqService.busquedaInvTrnNumTrasfer1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusqueda = true;
    });
  }

  ListarConfiguracionesTransfer() {

    this.enctransferService.ecListConfTransfer().subscribe(async data => {
      for (const rs of data) {

        if (rs.CFG_CODIGO === 'MULNUMTRA') {
          this.intMultiplesNum = Number(rs.CFG_VALOR1);
        }
        if (rs.CFG_CODIGO === 'PROVTRF') {
          this.intProveedorTransf = Number(rs.CFG_VALOR1);
        }
        if (rs.CFG_CODIGO === 'USURAZELI') {
          this.intUSURAZELI = Number(rs.CFG_VALOR1);
        }
        if (rs.CFG_CODIGO === 'COSTRFINV') {
          this.COSTRFINV = Number(rs.CFG_VALOR1)
        }
        if (rs.CFG_CODIGO === 'TNRBLBODO') {
          this.enctransferService.TNRBLBODO = Number(rs.CFG_VALOR1)
        }
      }
      if (this.enctransferService.TNRBLBODO === 1) {

        this.disablebodconfig = true;
        const rs = await this.enctransferService.getBodegaUsuario()

        for (const iterator of rs) {
          if (iterator.BOD_CODIGO !== null && iterator.BOD_CODIGO !== undefined) {
            this.boduser = iterator.BOD_CODIGO === null || iterator.BOD_CODIGO === undefined ? '' : iterator.BOD_CODIGO
            const er = await this.enctransferService.erFindBodDestino(this.boduser).toPromise();
            if (er !== null) {
              if (er[0] !== undefined) {
                this.bodnomuser = er[0].BOD_NOMBRE;

              }
            }
          } else {
            this.disablebodconfig = false
          }
        }
        this.enctransferService.invenctransferarray[this.indicador].BOD_CODIGO = this.boduser
        this.enctransferService.invenctransferarray[this.indicador].BOD_NOMBRE = this.bodnomuser
        //.subscribe(er => {

        //});
      }
      if (this.COSTRFINV === 1) {
        this.columnDefsDettransfer.splice(4, 1);
        this.columnDefsDettransfer.splice(4, 1);
        this.aggrid.refreshColumnDefs();
      }
    });

  } // Fin listarConfiguraciones de inventario


  limpiarDatos() {
    this.boldateAutoriza = false;
    this.botonAutorizar = true;
    this.control = 0;//CDPJ
    let bod = ''
    let bodnom = ''
    if (this.enctransferService.TNRBLBODO === 1) {
      bod = this.boduser
      bodnom = this.bodnomuser
    }
    const fechaActual = new Date();
    this.enctransferService.invenctransferarray[this.indicador] = {
      TRANSFER_NRO: '',
      TRANSFER_COMPROBANTE: '',
      BOD_CODIGO: bod,
      BOD_DESTINO: '',
      TRANSFER_COMENTARIO: '',
      BOD_NOMBRE: bodnom,
      BOD_DESTINONOMBRE: '',
      TRANSFER_PERSONAAUTORIZA: '',
      TRANSFER_REFER: '',
      TRANSFER_REFERENCIA: '',
      TRANSPORT_CODIGO: '',
      TRANSPORT_NOMBRE: '',
      TRANSPORT_AUTO: '',
      TRANSPORT_PLACA: '',
      MUESTRARIO: '',
      CLI_CODIGO: '',
      CLI_NOMBRE: '',
      TRANSFER_ESTADO: 'P',
      PRO_CODIGO: '',
      PRO_NOMBRE: '',
      TRANSFER_FECHAEMISION: fechaActual,
      TRANSFER_HORA: fechaActual,
      TRANSFER_FECHAAUTORIZA: fechaActual,
      TRANSFER_FECHATRANSFER: fechaActual,
      ASI_NRO: '',
      TOTAL: '',
      COM_CODIGO: '01'
    };

    this.nombreEstado('P');
    this.chkguiaremision = false;
    /*strControlImportar = false;
    tabTransferencia.setSelection(0);*/

    this.enctransferService.invdettransferarray[this.indicador] = [];
    this.enctransferService.invtrnvariostrfarray[this.indicador] = [];
  }

  nombreEstado(strEstado) {
    let strRefNombreC = '';

    this.enctransferService.erNombreEstado(strEstado).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          strRefNombreC = er[0].REF_NOMBREC;
        }
      }
      if (strRefNombreC == null) {
        strRefNombreC = 'PENDIENTE';
      }
      this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO = strRefNombreC;
    });

  }

  async guardarRegistro() {
    if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO.length === 0) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERINSERCION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de inserción, Consulte con el Administrador del Sistema.'
          });
          this.control = 0;//CDPJ
          return;
        }
      }
    } else {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de actualización, Consulte con el Administrador del Sistema.'
          });
          this.control = 0;//CDPJ
          return;
        }
      }
    }
    const strFechaEmision = this.datePipe.transform(this.enctransferService.invenctransferarray[
      this.indicador].TRANSFER_FECHAEMISION, 'dd/MM/yyyy');
    const strFechaAutorizacion = this.datePipe.transform(this.enctransferService.invenctransferarray[
      this.indicador].TRANSFER_FECHAAUTORIZA, 'dd/MM/yyyy');
    const strFechaTransferencia = this.datePipe.transform(this.enctransferService.invenctransferarray[
      this.indicador].TRANSFER_FECHATRANSFER, 'dd/MM/yyyy');
    let strHora;
    //GSRF
    if (this.enctransferService.horacam === this.enctransferService.invenctransferarray[this.indicador].TRANSFER_HORA) {
      strHora = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_HORA;
      console.log('AQUIIII11111', strHora);
    } else {
      strHora = this.datePipe.transform(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_HORA, 'HH:mm');
      console.log('AQUIIII2222', strHora);
    }
    //GSRF
    if (this.enctransferService.invenctransferarray[this.indicador].BOD_CODIGO === '' ||
      this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINO === '') {
      this.messageService.add({
        key: 'enctransfer',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese la Bodega Origen y la Bodega Destino'
      });
      this.control = 0;//CDPJ
      return;
    }

    if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_PERSONAAUTORIZA === null) {
      this.enctransferService.invenctransferarray[this.indicador].TRANSFER_PERSONAAUTORIZA = '';
    }

    if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_PERSONAAUTORIZA === '' &&
      this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO === 'AUTORIZADO') {
      this.messageService.add({
        key: 'enctransfer',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el Nombre de la persona que autoriza la transferencia'
      });
      this.control = 0;//CDPJ
      return;
    }

    if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO.length === 0) {
      // if (this.permisos.PERTODO === 0) {
      // } else if (this.permisos.PERINSERCION === 0) {
      //   this.messageService.add({
      //     key: 'enctransfer',
      //     severity: 'error',
      //     summary: 'Información',
      //     detail: 'El usuario no tiene permisos de inserción, Consulte con el Administrador del Sistema.'
      //   });
      //   return;
      // }
      const item: InvEnctransfer = {};
      item.COM_CODIGO = this.enctransferService.invenctransferarray[this.indicador].COM_CODIGO;
      item.TRANSFER_FECHAEMISION = strFechaEmision;
      item.TRANSFER_FECHAAUTORIZA = strFechaAutorizacion;
      item.TRANSFER_FECHATRANSFER = strFechaTransferencia;
      item.BOD_CODIGO = this.enctransferService.invenctransferarray[this.indicador].BOD_CODIGO;
      item.TRANSFER_COMENTARIO = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_COMENTARIO;
      item.TRANSFER_ESTADO = '';
      if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO === 'PENDIENTE') {
        item.TRANSFER_ESTADO = 'P';
      }
      if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO === 'AUTORIZADO') {
        item.TRANSFER_ESTADO = 'A';
      }
      if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO === 'TRANSFERIDO') {
        item.TRANSFER_ESTADO = 'T';
      }
      item.TRANSFER_COMPROBANTE = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_COMPROBANTE;
      item.TRANSFER_PERSONAAUTORIZA = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_PERSONAAUTORIZA;
      if (this.chkguiaremision === true) {
        item.TRANSFER_TIPO = 'G';
      } else {
        item.TRANSFER_TIPO = 'N';
      }
      item.TRANSPORT_CODIGO = this.enctransferService.invenctransferarray[this.indicador].TRANSPORT_CODIGO;
      item.TRANSFER_REFER = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_REFER;
      item.TRANSFER_FLAG = 0;
      item.MUESTRARIO = this.enctransferService.invenctransferarray[this.indicador].MUESTRARIO;
      item.CLI_CODIGO = this.enctransferService.invenctransferarray[this.indicador].CLI_CODIGO;
      item.TRANSFER_REFERENCIA = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_REFERENCIA;
      item.PRO_CODIGO = this.enctransferService.invenctransferarray[this.indicador].PRO_CODIGO;
      item.PRO_NOMBRE = this.enctransferService.invenctransferarray[this.indicador].PRO_NOMBRE;
      item.TRANSFER_HORA = strHora;

      const data = await this.enctransferService.insertarEnctransferProm(item).catch(e => {
        this.control = 0;//CDPJ
        return;
      });
      for (const rs of data) {
        this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO = rs.TRANSFER_NRO;
        if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_COMPROBANTE === '') {
          this.enctransferService.invenctransferarray[this.indicador].TRANSFER_COMPROBANTE = rs.TRANSFER_NRO;
        }
      }

      this.auditoriagral.registrarAuditoria('INV_ENCTRANSFER',
        this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO, 'I',
        '', '01', '', '', '', '').subscribe();
      await this.GuardarDetalle();
      if (item.TRANSFER_ESTADO === 'T') {
        await this.transferir();
        document.getElementById('contorno').style.pointerEvents = 'none';
      }
      //CDPJ
      this.disablebod = true;
      this.disablebodconfig = true;
      //CDPJ
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = true;
      this.encontrarTransferencia(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO);

      /* this.enctransferService.insertarEnctransfer(item).subscribe(data => {
         for (const rs of data) {
           this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO = rs.TRANSFER_NRO;
           if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_COMPROBANTE === '') {
             this.enctransferService.invenctransferarray[this.indicador].TRANSFER_COMPROBANTE = rs.TRANSFER_NRO;
           }
         }
         this.auditoriagral.registrarAuditoria('INV_ENCTRANSFER',
           this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO, 'I',
           '', '01', '', '', '', '').subscribe();
         this.GuardarDetalle();
         if (item.TRANSFER_ESTADO === 'T') {
           this.transferir();
           document.getElementById('contorno').style.pointerEvents = 'none';
         }
         setTimeout(() => {
           this.botonNuevo = false;
           this.botonGuardar = true;
           this.botonBorrar = false;
           this.botonRegresar = true;
           this.encontrarTransferencia(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO);
         }, 200);
         
       }); */
    } else { // Fin si  es nuevo registro
      // Actualiza
      if (this.permisos.PERTODO === 0) {
      } else if (this.permisos.PERACTUALIZACION === 0) {
        this.messageService.add({
          key: 'enctransfer',
          severity: 'error',
          summary: 'Información',
          detail: 'El usuario no tiene permisos de actualización, Consulte con el Administrador del Sistema.'
        });
        this.control = 0;//CDPJ
        return;
      }
      const item: InvEnctransfer = {};
      item.TRANSFER_NRO = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
      item.COM_CODIGO = this.enctransferService.invenctransferarray[this.indicador].COM_CODIGO;
      item.TRANSFER_FECHAEMISION = strFechaEmision;
      item.TRANSFER_FECHAAUTORIZA = strFechaAutorizacion;
      item.TRANSFER_FECHATRANSFER = strFechaTransferencia;
      item.BOD_CODIGO = this.enctransferService.invenctransferarray[this.indicador].BOD_CODIGO;
      item.TRANSFER_COMENTARIO = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_COMENTARIO;
      if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO === 'PENDIENTE') {
        item.TRANSFER_ESTADO = 'P';
      }
      if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO === 'AUTORIZADO') {
        item.TRANSFER_ESTADO = 'A';
      }
      if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO === 'TRANSFERIDO') {
        item.TRANSFER_ESTADO = 'T';
      }
      item.TRANSFER_COMPROBANTE = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_COMPROBANTE;
      item.TRANSFER_PERSONAAUTORIZA = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_PERSONAAUTORIZA;
      if (this.chkguiaremision === true) {
        item.TRANSFER_TIPO = 'G';
      } else {
        item.TRANSFER_TIPO = 'N';
      }
      item.TRANSPORT_CODIGO = this.enctransferService.invenctransferarray[this.indicador].TRANSPORT_CODIGO;
      item.MUESTRARIO = this.enctransferService.invenctransferarray[this.indicador].MUESTRARIO;
      item.CLI_CODIGO = this.enctransferService.invenctransferarray[this.indicador].CLI_CODIGO;
      item.TRANSFER_REFERENCIA = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_REFERENCIA;
      item.PRO_CODIGO = this.enctransferService.invenctransferarray[this.indicador].PRO_CODIGO;
      item.PRO_NOMBRE = this.enctransferService.invenctransferarray[this.indicador].PRO_NOMBRE;
      item.TRANSFER_HORA = strHora;

      await this.enctransferService.actualizarEnctransferProm(item);
      this.auditoriagral.registrarAuditoria('INV_ENCTRANSFER',
        this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO, 'A',
        '', '01', '', '', '', '').subscribe();
      await this.GuardarDetalle();
      //CDPJ
      this.disablebod = true;
      this.disablebodconfig = true;
      //CDPJ
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = true;
      this.encontrarTransferencia(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO);
      /*this.enctransferService.actualizarEnctransfer(item).subscribe(() => {
        this.auditoriagral.registrarAuditoria('INV_ENCTRANSFER',
          this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO, 'A',
          '', '01', '', '', '', '').subscribe();
        this.GuardarDetalle();
        setTimeout(() => {
          this.botonNuevo = false;
          this.botonGuardar = true;
          this.botonBorrar = false;
          this.botonRegresar = true;
          this.encontrarTransferencia(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO);
        }, 200);
      }); */
    } // fin actualizacion

  }

  GuardarVarios() {

  }

  async GuardarDetalle() {
    let intIndiceLista = 0;
    let item: InvDettransfer = {};
    for (intIndiceLista = 0; intIndiceLista < this.enctransferService.invdettransferarray[this.indicador].length; intIndiceLista++) {
      item = this.enctransferService.invdettransferarray[this.indicador][intIndiceLista];
      if (item.ART_CODIGO === '') {
        // lstInvDetTransfer.remove(intIndiceLista);
        this.enctransferService.invdettransferarray[this.indicador].splice(intIndiceLista, 1);
      }
    }

    intIndiceLista = 0;
    for (intIndiceLista = 0; intIndiceLista < this.enctransferService.invdettransferarray[this.indicador].length; intIndiceLista++) {
      item = this.enctransferService.invdettransferarray[this.indicador][intIndiceLista];
      if (item.ART_CODIGO !== '') {
        if (item.DETTRANSFER_LINEA === 0) {
          const itemdet: InvDettransfer = {};
          itemdet.TRANSFER_NRO = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
          itemdet.COM_CODIGO = '01';
          itemdet.ART_CODIGO = item.ART_CODIGO;
          itemdet.BOD_CODIGO = item.BOD_CODIGO;
          itemdet.DETTRANSFER_CANTIDAD = Number(item.DETTRANSFER_CANTIDAD);
          itemdet.DETTRANSFER_COSTOTOTAL = Number(item.DETTRANSFER_COSTOTOTAL);
          itemdet.UNI_CODIGO = item.UNI_CODIGO;
          itemdet.DETTRANSFER_FLAG = 0;
          itemdet.DETTRANSFER_COSTOUNIT = Number(item.DETTRANSFER_COSTOUNIT);
          itemdet.DETTRANSFER_REFERENCIA = item.DETTRANSFER_REFERENCIA;
          itemdet.TRNRES_NUMERO = item.TRNRES_NUMERO;
          try {
            await this.enctransferService.insertarDetTransfer(itemdet);
            this.auditoriagral.registrarAuditoria('INV_DETTRANSFER',
              itemdet.TRANSFER_NRO + '/' + item.ART_CODIGO, 'I',
              '', '01', '', '', '', '').subscribe();
            if (itemdet.TRNRES_NUMERO !== '') {
              await this.enctransferService.cambiarEstadoReserva(itemdet.TRNRES_NUMERO, 'P');
            }
          } catch (error1) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'enctransfer',
              severity: 'error',
              summary: 'Insertar Detalle Transferencia',
              detail: mensaje
            });
            this.disablebod = false;//CDPJ
            if (this.enctransferService.TNRBLBODO === 0) {
              this.disablebodconfig = false
            }
            this.control = 0;//CDPJ
          }
        } else { // Fin si inserta un nuevo registro
          const itemdet: InvDettransfer = {};
          itemdet.TRANSFER_NRO = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
          itemdet.COM_CODIGO = '01';
          itemdet.ART_CODIGO = item.ART_CODIGO;
          itemdet.BOD_CODIGO = item.BOD_CODIGO;
          itemdet.DETTRANSFER_CANTIDAD = Number(item.DETTRANSFER_CANTIDAD);
          itemdet.DETTRANSFER_COSTOTOTAL = Number(item.DETTRANSFER_COSTOTOTAL);
          itemdet.UNI_CODIGO = item.UNI_CODIGO;
          itemdet.DETTRANSFER_COSTOUNIT = Number(item.DETTRANSFER_COSTOUNIT);
          itemdet.DETTRANSFER_LINEA = item.DETTRANSFER_LINEA;
          itemdet.DETTRANSFER_REFERENCIA = item.DETTRANSFER_REFERENCIA;
          try {
            await this.enctransferService.actualizarDetTransfer(item);
            this.auditoriagral.registrarAuditoria('INV_DETTRANSFER',
              itemdet.TRANSFER_NRO + '/' + item.ART_CODIGO, 'A',
              '', '01', '', '', '', '').subscribe();

          } catch (error1) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'enctransfer',
              severity: 'error',
              summary: 'Insertar Detalle Transferencia',
              detail: mensaje
            });
            this.disablebod = false;//CDPJ
            if (this.enctransferService.TNRBLBODO === 0) {
              this.disablebodconfig = false
            }
            this.control = 0;//CDPJ
          }
        } // Fin actualización
      } // Fin si no es un valor en blanco
    }
  }

  guardar() {
    // let dblTotalTransf = 0;
    // let dblTotalVarios = 0;

    if (this.index === 0) {
      if (this.intMultiplesNum === 1 && this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO.length === 0 &&
        (this.enctransferService.invenctransferarray[this.indicador].MUESTRARIO === null
          || this.enctransferService.invenctransferarray[this.indicador].MUESTRARIO === undefined
          || this.enctransferService.invenctransferarray[this.indicador].MUESTRARIO === '')) {
        this.messageService.add({
          key: 'enctransfer',
          severity: 'warn',
          summary: 'Encabezado de la Trasnferencia',
          detail: 'Debe seleccionar una numeración'
        });
        setTimeout(() => {
          this.busquedaNumeracion();
        }, 2000);
        this.control = 0;//CDPJ
        return;
      }
      // Verificar que la cantidad de la transferencia no sea mayor que la cantidad reservada
      console.log(this.enctransferService.invdettransferarray[this.indicador]);
      for (const itemt of this.enctransferService.invdettransferarray[this.indicador]) {
        if (itemt.TRNRES_NUMERO !== null && itemt.TRNRES_CANTIDAD !== undefined) {
          if (Number(itemt.DETTRANSFER_CANTIDAD) > Number(itemt.TRNRES_CANTIDAD)) {
            this.messageService.add({
              key: 'enctransfer',
              severity: 'warn',
              summary: 'Insertar Detalle Transferencia',
              detail: 'La cantidad del detalle de la tansferencia es mayor a la cantidad reservada'
            });
            this.control = 0;//CDPJ

            return;
          }
        }
      }
      this.guardarRegistro();
    }
    /*if (this.index === 1) {
      dblTotalTransf = this.enctransferService.calcularCostoTotal();
      // dblTotalVarios = this.enctransferService.calcularTotalVarios();
      
      if (dblTotalVarios > 0) {
        if (dblTotalTransf === dblTotalVarios) {
          this.GuardarVarios();
        } else {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El total varios no es igual al total de la transferencia'
          });
        }
      } else {
        modeloVarios.activarBotones(true, true, false);
        activaBotones('G', true, false, false, true, true, true, true, true, true, true, true, true, true, true, true);
      }
    }*/
  }

  eliminar() {
    //CDPJ
    let contador = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let intLinea = 0;
    let strNumDoc = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
    let strSentencia = '';
    // Verifica los permisos
    if (this.permisos !== null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERELIMACION === 0) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.enctransferService.usuario.identificacion
              + ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }
    //CDPJ
    /*if (tabTransferencia.getSelectionIndex() == 0) // generales
    {
      if (listaPermisos != null) {
        SegMaePermiso
        item = new SegMaePermiso();
        item = listaPermisos.get(0);
        if (item.getPerTodo() == 0) {
          if (item.getPerElimacion() == 0) {
            MessageDialog.openInformation(shell, "Información", "El usuario " +
              Conexion.usuarioConectado + ", no tiene permiso de Eliminación. Consulte con el Administrador del Sistema.");
            return;
          }
        }
      }
    }*/


    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Eliminar Transferencia',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmenctransfer',
      accept: async () => {
        /*if (intUSURAZELI == 1) {
          DlgUsuarioRazon
          dlgUsuRaz = new DlgUsuarioRazon(
            getParent(), this, "EncTransfer");
          dlgUsuRaz.open();
        }
  
        UtilidadesInv.eliminarSeriales(lblNumero.getText(), "TRF", Long.valueOf("0"), "", "");*/


        this.enctransferService.eliminarEncTransfer(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO).subscribe();
        /*if (intUSURAZELI == 1) {
          SaciMaeAuditoriaGral.registrarAuditoria("INV_ENCTRANSFER",
            lblNumero.getText() + StrUsuRazEli, "E",
            Conexion.usuarioConectado, Conexion.COMPANIA, " ", " ",
            " ", FechaHoraSistema.fechaHraSistema());
        } else {
          SaciMaeAuditoriaGral.registrarAuditoria("INV_ENCTRANSFER",
            lblNumero.getText(), "E", Conexion.usuarioConectado,
            Conexion.COMPANIA, " ", " ", " ", FechaHoraSistema
              .fechaHraSistema());
        }*/
        //CDPJ
        this.enctransferService.eliminarDetTransfer(this.enctransferService.invdettransferarray[this.indicador]);

        for (let i = 1; i <= this.enctransferService.invdettransferarray[this.indicador].length; i++) {

          artserialflag = this.enctransferService.invdettransferarray[this.indicador][i - 1].ART_SERIALFLAG;
          cantidad = this.enctransferService.invdettransferarray[this.indicador][i - 1].DETTRANSFER_CANTIDAD;
          intLinea = this.enctransferService.invdettransferarray[this.indicador][i - 1].DETTRANSFER_LINEA;
          articulo = this.enctransferService.invdettransferarray[this.indicador][i - 1].ART_CODIGO;

          if (artserialflag === 'S') {

            // strSentencia = 'select count(*) AS COUN from INV_TRNKARDEXOTR ';
            // strSentencia = strSentencia + ' where art_codigo = \''
            //   + articulo + '\' and TRNKARDEXOTR_NUMERO = \''
            //   + strNumDoc + '\'';
            // strSentencia = strSentencia + ' and trnkardexotr_linea = ' + intLinea;

            const data3 = await this.enctransferService.validarSeriales(articulo, strNumDoc, intLinea);
            console.log(data3);
            for (const rs of data3) {
              if (rs.COUN !== undefined && rs.COUN !== null) {
                contador = contador + rs.COUN
              }
            }
          }
        }
        if (contador !== 0) {
          for (let y = 1; y <= contador; y++) {

            //console.log(y);
            //eliminar salida
            this.serialseleccionado.TRNKARDEXOTR_NUMERO = strNumDoc + '_SA';
            this.serialseleccionado.TRNKARDEXOTR_TIPO = 'SA'
            this.serialseleccionado.TRNKARDEXOTR_NUMSEC = y
            this.serialseleccionado.COM_CODIGO = '01'
            this.trnkardexotrservice.eliminarKardexOtr(this.serialseleccionado).subscribe(data => {
              this.messageService.add({
                key: 'seriales',
                severity: 'success',
                summary: 'Información',
                detail: 'Serial Eliminado'
              });
            });
            this.auditoriagral.registrarAuditoria('INV_ENCTRANSFER_SRL_SA',
              this.serialseleccionado.TRNKARDEXOTR_NUMERO.toString(), 'E',
              '', '01', '', '', '', '').subscribe();

            //eliminar entrada
            //console.log('1',this.serialseleccionado);
            this.serialseleccionado.TRNKARDEXOTR_NUMERO = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
            this.serialseleccionado.TRNKARDEXOTR_TIPO = 'EN'
            this.serialseleccionado.TRNKARDEXOTR_NUMSEC = y
            this.serialseleccionado.COM_CODIGO = '01'
            this.trnkardexotrservice.eliminarKardexOtr(this.serialseleccionado).subscribe(data => {
              this.messageService.add({
                key: 'seriales',
                severity: 'success',
                summary: 'Información',
                detail: 'Serial Eliminado'
              });
            });
            //console.log('2.1',this.serialseleccionado);

            this.auditoriagral.registrarAuditoria('INV_ENCTRANSFER_SRL_EN',
              this.serialseleccionado.TRNKARDEXOTR_NUMERO.toString(), 'E',
              '', '01', '', '', '', '').subscribe();
            //console.log('2.1',this.serialseleccionado);          
          }
        }
        //eliminar entrada serialesCDPJ


        //CDPJ
        this.actualizarEstadoReservas();

        // this.limpiarDatos();
        /*
        if (UtilidadesInv.intRecalculaCostoPromedio == 1)//Recalcula el costo promedio de los articulos insertados
        {
          strFechaEmision = Utilidades.ConvierteFechaAString(dateTimeFechaTransf.getDay(),
            dateTimeFechaTransf.getMonth(), dateTimeFechaTransf.getYear());
          UtilidadesInv.recalcularCostosArtEliminados(lblNumero.getText(), strFechaEmision);
        }
  
        activaBotones("INI", false, true, true, false, false, false, false, false, false, false, false, false, false, false, false);
        activarBotonesDetalle(true, false, false);*/


      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  cancelar() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    const numero = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
    this.limpiarDatos();
    this.encontrarTransferencia(numero);
  }

  seleccionarRegistro(strTipo) {
    this.irAregistro(strTipo);
  }

  irAregistro(strTipo) {
    if (strTipo === 'P') {
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }
    if (strTipo === 'A' || strTipo === 'S') {
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }
    if (strTipo === 'U') {
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = false;
      this.botonUltimo = false;
    }

    let numero = '';

    if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO !== undefined) {
      numero = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
    }

    this.enctransferService.seleccionarRegistro(strTipo, numero,
      'com_codigo=\'01\'').subscribe((datos: any[]) => {
        if (datos !== null) {
          this.limpiarDatos();
          this.encontrarTransferencia(datos[0].TRANSFER_NRO);
          this.botonNuevo = false;
          this.botonGuardar = true;
          this.botonBorrar = false;
          this.botonRegresar = true;
        }
      }, error1 => {
        const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'enctransfer',
          severity: 'error',
          summary: 'Error',
          detail: error
        });
      });
  }

  buscar() {
    this.opcionbusqueda = 'transferencia';
    this.types = [
      { label: 'Código', value: 'TRANSFER_NRO' },
      { label: 'Fec. Emisión', value: 'TRANSFER_FECHAEMISION' },
      { label: 'Fec. Transfer', value: 'TRANSFER_FECHATRANSFER' },
      { label: 'Bodega', value: 'BOD_CODIGO' },
      { label: 'Estado', value: 'TRANSFER_ESTADO' },
      { label: 'Cliente', value: 'CLI_CODIGO' },
      { label: 'Persona', value: 'TRANSFER_PERSONAAUTORIZA' },
      { label: 'Fec. Autoriza', value: 'TRANSFER_FECHAAUTORIZA' },
      { label: 'Comentario', value: 'TRANSFER_COMENTARIO' }
    ];


    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'INV_ENCTRANSFER';
    this.where = '';


    this.busqService.busquedaInvEncTransfer1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.TRANSFER_FECHAEMISION !== null) {
          registro.TRANSFER_FECHAEMISION = this.datePipe.transform(registro.TRANSFER_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.TRANSFER_FECHAEMISION = '';
        }
        if (registro.TRANSFER_FECHATRANSFER !== null) {
          registro.TRANSFER_FECHATRANSFER = this.datePipe.transform(registro.TRANSFER_FECHATRANSFER, 'dd/MM/yyyy');
        } else {
          registro.TRANSFER_FECHATRANSFER = '';
        }
        if (registro.TRANSFER_FECHAAUTORIZA !== null) {
          registro.TRANSFER_FECHAAUTORIZA = this.datePipe.transform(registro.TRANSFER_FECHAAUTORIZA, 'dd/MM/yyyy');
        } else {
          registro.TRANSFER_FECHAAUTORIZA = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  encontrarTransferencia(transfernumero) {
    this.enctransferService.getEnctransfer(transfernumero).subscribe(data => {
      for (const rs of data) {
        this.enctransferService.horacam = rs.TRANSFER_HORA//GSRF
        rs.TRANSFER_FECHAEMISION = new Date(rs.TRANSFER_FECHAEMISION);
        rs.TRANSFER_FECHAAUTORIZA = new Date(rs.TRANSFER_FECHAAUTORIZA);
        rs.TRANSFER_FECHATRANSFER = new Date(rs.TRANSFER_FECHATRANSFER);
        rs.MUESTRARIO = rs.TRANSFER_REFERENCIA;
        this.enctransferService.invenctransferarray[this.indicador] = rs;
        this.control = 0;//CDPJ
        this.nombreEstado(rs.TRANSFER_ESTADO);
        switch (rs.TRANSFER_ESTADO) {
          case 'P':
            document.getElementById('contorno').style.pointerEvents = 'all';
            this.botonXML = false;
            break;
          case 'T':
            document.getElementById('contorno').style.pointerEvents = 'none';
            this.botonNuevo = false;
            this.botonGuardar = true;
            this.botonBorrar = true;
            this.botonAutorizar = false;
            break;
          case 'A':
            document.getElementById('contorno').style.pointerEvents = 'all';
            this.boldateAutoriza = true;
            this.botonXML = true;
            break;
          default:
            break;
        }
      }
      this.enctransferService.getDettransfer(transfernumero).subscribe(datadet => {
        //CDPJ
        console.log(datadet)
        if (datadet.length === 0) {
          this.disablebod = false;
          if (this.enctransferService.TNRBLBODO === 0) {
            this.disablebodconfig = false
          }
        } else {
          this.disablebodconfig = true
          this.disablebod = true;
        }
        //CDPJ
        this.enctransferService.invdettransferarray[this.indicador] = datadet;
        for (const rsdet of this.enctransferService.invdettransferarray[this.indicador]) {
          rsdet.CLAVE = rsdet.DETTRANSFER_LINEA;
          rsdet.TRNRES_CANTIDAD = rsdet.DETTRANSFER_CANTIDAD;
          rsdet.DETTRANSFER_COSTOUNIT = Number(rsdet.DETTRANSFER_COSTOUNIT).toFixed(this.enctransferService.decdetcosto);
        }
        for (const rsdet of datadet) {
          this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINO = rsdet.BOD_CODIGO;
        }
        this.enctransferService.erFindBodDestino(this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINO).subscribe(er => {
          if (er !== null) {
            if (er[0] !== undefined) {
              this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINONOMBRE = er[0].BOD_NOMBRE;
            }
          }
        });
        this.enctransferService.calcularCostoTotal();

        /*this.enctransferService.getTrnvariostrf(transfernumero).subscribe(datavar => {
          this.enctransferService.invtrnvariostrfarray[this.indicador] = datavar;
        });*/
      });
    });
  }
  //CDPJ
  async ValidarSeriales(strNumDoc) {
    let errores = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let intLinea = 0;
    let strSentencia = '';


    for (let i = 1; i <= this.enctransferService.invdettransferarray[this.indicador].length; i++) {
      console.log('1', this.enctransferService.invdettransferarray);
      artserialflag = this.enctransferService.invdettransferarray[this.indicador][i - 1].ART_SERIALFLAG;
      cantidad = this.enctransferService.invdettransferarray[this.indicador][i - 1].DETTRANSFER_CANTIDAD;
      intLinea = this.enctransferService.invdettransferarray[this.indicador][i - 1].DETTRANSFER_LINEA;
      articulo = this.enctransferService.invdettransferarray[this.indicador][i - 1].ART_CODIGO;

      if (artserialflag === 'S') {
        console.log('2');
        strSentencia = 'select count(*) AS COUN from INV_TRNKARDEXOTR ';
        strSentencia = strSentencia + ' where art_codigo = \''
          + articulo + '\' and TRNKARDEXOTR_NUMERO = \''
          + strNumDoc + '\'';
        strSentencia = strSentencia + ' and trnkardexotr_linea = ' + intLinea;

        const data3 = await this.enctransferService.validarSeriales(articulo, strNumDoc, intLinea);
        for (const rs of data3) {
          console.log('3');
          if (rs.COUN !== Number(cantidad)) {
            errores++;
          }
        }
      }
    }

    if (errores > 0 && this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO === 'TRANSFERIDO') {
      this.messageService.add({
        key: 'enctransfer',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese los seriales.'
      });
      return false;
    } else {
      console.log('4');
      return true;
    }
  }
  //CDPJ
  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'txtComprobante': {
        element = document.getElementById('txtCodBodOrigen');
        break;
      }
      case 'txtCodBodOrigen': {
        this.busquedaBodega(event.target.value, 'origen');
        break;
      }
      case 'txtCodBodDestino': {
        this.busquedaBodega(event.target.value, 'destino');
        break;
      }
      case 'txtComentario': {
        this.nuevodetalle();
        break;
      }
      case 'txtNombreAutoriza': {
        element = document.getElementById('txtReferencia');
        break;
      }
      case 'txtReferencia': {
        element = document.getElementById('txtCodTransportista');
        break;
      }
      case 'txtCodTransportista': {
        this.busquedaTransportista(event.target.value);
        break;
      }
      case 'txtCodCliente': {
        this.busquedacliente(event.target.value);
        break;
      }
    }


    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
  }

  busquedaBodega(parametro, opcionbusqueda) {
    if (parametro === undefined) {
      parametro = '';
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '';
    this.tabla = 'inv_maebodega';
    this.where = '';

    this.enctransferService.erFindBodega(this.tabla, this.types[0].value,
      parametro).subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.manejarSeleccion(eR[0]);
          } else {
            this.busqService.busquedaInvMaeBodega1(parametro).subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaInvMaeBodega1(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  busquedaTransportista(parametro) {
    if (parametro === undefined) {
      parametro = '';
    }

    this.opcionbusqueda = 'transportista';
    this.types = [
      { label: 'Código', value: 'TRANSPORT_CODIGO' },
      { label: 'Nombre', value: 'TRANSPORT_NOMBRE' },
      { label: 'Ruc', value: 'TRANSPORT_RUC' },
      { label: 'Teléfono1', value: 'TRANSPORT_TELEFONO1' },
      { label: 'Teléfono2', value: 'TRANSPORT_TELEFONO2' },
      { label: 'Dirección', value: 'TRANSPORT_DIRECCION' }
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'VEN_MAETRANSPORT';
    this.where = '';

    this.enctransferService.erFindTransportistas(this.tabla, this.types[0].value, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaVenMaeTranport1(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeTranport1(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  selecciondettransfer(params) {
    if (params === 'nuevo') {
      this.nuevodetalle();
      return;
    }
    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalle();
      return;
    }
    if (params === 'reservas') {
      this.agregarReservas();
      return;
    }

    if (params.object !== undefined) {
      this.dettransferSelected = params.object;
    }

  }

  agregarReservas() {
    if (String(this.enctransferService.invenctransferarray[this.indicador].BOD_CODIGO).trim() === '') {
      this.messageService.add({
        key: 'enctransfer',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese la bodega de origen'
      });
      return;
    }
    if (String(this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINO).trim() === '') {
      this.messageService.add({
        key: 'enctransfer',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese la bodega de destino'
      });
      return;
    }

    this.bodCodigoReserva = this.enctransferService.invenctransferarray[this.indicador].BOD_CODIGO;
    this.bodDestinoReserva = this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINO;
    this.fechaReserva = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_FECHAEMISION;

    this.displayDialogReservas = true;
  }

  cancelardetalle() {

  }

  eliminarLineaDetalle() {
    if (this.dettransferSelected !== undefined && this.enctransferService.invdettransferarray[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmenctransfer',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.dettransferSelected.DETTRANSFER_LINEA !== 0) {
            this.enctransferService.eliminarDetTransfer(this.dettransferSelected).subscribe(() => {
              this.enctransferService.invdettransferarray[this.indicador].splice(
                this.enctransferService.invdettransferarray[this.indicador].findIndex(
                  detalle => detalle.DETTRANSFER_LINEA === this.dettransferSelected.DETTRANSFER_LINEA),
                1);
              this.messageService.add({
                key: 'enctransfer',
                severity: 'success',
                summary: 'Información',
                detail: 'El detalle se elimino correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_DETTRANSFER',
                this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO + '/' + this.dettransferSelected.ART_CODIGO, 'E',
                '', '01', '', '', '', '').subscribe();
              this.eliminarSeriales(this.dettransferSelected);//CDPJ
              if (this.enctransferService.invdettransferarray[this.indicador].length > 0) {
                this.dettransferSelected = this.enctransferService.invdettransferarray[this.indicador][
                  this.enctransferService.invdettransferarray[this.indicador].length - 1];
              } else {//CDPJ
                this.disablebod = false;
                if (this.enctransferService.TNRBLBODO === 0) {
                  this.disablebodconfig = false
                }
              }
              this.enctransferService.calcularCostoTotal();
              this.aggrid.refreshaggrid(this.enctransferService.invdettransferarray[this.indicador], this.defaultColDef.objeto);
            });

          } else {
            this.enctransferService.invdettransferarray[this.indicador].splice(
              this.enctransferService.invdettransferarray[this.indicador].findIndex(
                detalle => detalle.CLAVE === this.dettransferSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'enctransfer',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se elimino correctamente'
            });
            if (this.enctransferService.invdettransferarray[this.indicador].length > 0) {
              this.dettransferSelected = this.enctransferService.invdettransferarray[this.indicador][
                this.enctransferService.invdettransferarray[this.indicador].length - 1];
            }
            this.enctransferService.calcularCostoTotal();
            this.aggrid.refreshaggrid(this.enctransferService.invdettransferarray[this.indicador], this.defaultColDef.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  /*selecciontrnvariostrf(params) {
    if (params === 'nuevo') {
      this.nuevodetalle();
      return;
    }
    if (params.object !== undefined) {
      this.trnvariostrfSelected = params.object;
    }
  }*/
  async eliminarSeriales(dettransferSelected) {
    //CDPJ
    let contador = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let intLinea = 0;
    let strNumDoc = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
    let strSentencia = '';
    artserialflag = dettransferSelected.ART_SERIALFLAG;
    cantidad = dettransferSelected.DETTRANSFER_CANTIDAD;
    intLinea = dettransferSelected.DETTRANSFER_LINEA;
    articulo = dettransferSelected.ART_CODIGO;

    if (artserialflag === 'S') {
      // strSentencia = 'select count(*) AS COUN from INV_TRNKARDEXOTR ';
      // strSentencia = strSentencia + ' where art_codigo = \''
      //   + articulo + '\' and TRNKARDEXOTR_NUMERO = \''
      //   + strNumDoc + '\'';
      // strSentencia = strSentencia + ' and trnkardexotr_linea = ' + intLinea;

      const data3 = await this.enctransferService.validarSeriales(articulo, strNumDoc, intLinea);
      console.log(data3);
      for (const rs of data3) {
        if (rs.COUN !== undefined && rs.COUN !== null) {
          contador = contador + rs.COUN
        }
      }
    }
    if (contador !== 0) {
      for (let y = 1; y <= contador; y++) {
        //console.log(y);
        //eliminar salida
        this.serialseleccionado.TRNKARDEXOTR_NUMERO = strNumDoc + '_SA';
        this.serialseleccionado.TRNKARDEXOTR_TIPO = 'SA'
        this.serialseleccionado.TRNKARDEXOTR_NUMSEC = y
        this.serialseleccionado.COM_CODIGO = '01'
        this.trnkardexotrservice.eliminarKardexOtr(this.serialseleccionado).subscribe(data => {

        });
        this.auditoriagral.registrarAuditoria('INV_ENCTRANSFER_SRL_SADET',
          this.serialseleccionado.TRNKARDEXOTR_NUMERO.toString(), 'E',
          '', '01', '', '', '', '').subscribe();

        //eliminar entrada
        //console.log('1',this.serialseleccionado);
        this.serialseleccionado.TRNKARDEXOTR_NUMERO = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
        this.serialseleccionado.TRNKARDEXOTR_TIPO = 'EN'
        this.serialseleccionado.TRNKARDEXOTR_NUMSEC = y
        this.serialseleccionado.COM_CODIGO = '01'
        this.trnkardexotrservice.eliminarKardexOtr(this.serialseleccionado).subscribe(data => {

        });
        //console.log('2.1',this.serialseleccionado);

        this.auditoriagral.registrarAuditoria('INV_ENCTRANSFER_SRL_ENDET',
          this.serialseleccionado.TRNKARDEXOTR_NUMERO.toString(), 'E',
          '', '01', '', '', '', '').subscribe();
        //console.log('2.1',this.serialseleccionado);    
      }
    }

  }

  //CDPJ
  nuevodetalle() {
    if (this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINO === null) {
      this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINO = '';
    }

    if (this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINO === '') {
      this.messageService.add({
        key: 'enctransfer',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese la Bodega Destino'
      });
      document.getElementById('txtCodBodDestino').focus();
      return;
    }

    if (this.index === 0) {
      const dettransfer: InvDettransfer = {
        TRANSFER_NRO: this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO,
        COM_CODIGO: '01',
        ART_CODIGO: '',
        BOD_CODIGO: this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINO,
        DETTRANSFER_CANTIDAD: 0,
        DETTRANSFER_COSTOTOTAL: 0,
        UNI_CODIGO: '',
        DETTRANSFER_FLAG: 0,
        DETTRANSFER_COSTOUNIT: 0,
        DETTRANSFER_LINEA: 0,
        DETTRANSFER_REFERENCIA: '',
        ART_NOMBRE: '',
        CLAVE: '',
        TRNRES_NUMERO: '',
        EXSBODDESTINO: 0.000,
        EXSBODORIGEN: 0.000
      };

      const indi = this.enctransferService.invdettransferarray[this.indicador].indexOf(this.dettransferSelected);
      if (indi === -1) {
        dettransfer.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.enctransferService.invdettransferarray[this.indicador].push(dettransfer);
        this.dettransferSelected = dettransfer;
        this.aggrid.refreshaggrid(this.enctransferService.invdettransferarray[this.indicador], this.defaultColDef.objeto);
      } else {
        dettransfer.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.enctransferService.invdettransferarray[this.indicador].splice(indi + 1, 0, dettransfer);
        this.dettransferSelected = dettransfer;
        this.aggrid.refreshaggridindex(this.enctransferService.invdettransferarray[this.indicador], this.defaultColDef.objeto, indi);
      }
    }

    /*if (this.index === 1) {
      const trnvarios: InvTrnvariostrf = {
        TRANSFER_NRO: this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO,
        COM_CODIGO: '01',
        TRNVARIOSTRF_LINEA: '',
        CON_CODIGO: '',
        CEN_CODIGO: '',
        TRNVARIOS_IMPORTE: '',
        CLAVE: ''
      };
      
      const indi = this.enctransferService.invtrnvariostrfarray[this.indicador].indexOf(this.trnvariostrfSelected);
      if (indi === -1) {
        trnvarios.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.enctransferService.invtrnvariostrfarray[this.indicador].push(trnvarios);
        this.trnvariostrfSelected = trnvarios;
        this.aggrid.refreshaggrid(this.enctransferService.invtrnvariostrfarray[this.indicador], this.defaultColDefVarios.objeto);
      } else {
        trnvarios.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.enctransferService.invtrnvariostrfarray[this.indicador].splice(indi + 1, 0, trnvarios);
        this.trnvariostrfSelected = trnvarios;
        this.aggrid.refreshaggridindex(this.enctransferService.invtrnvariostrfarray[this.indicador], this.defaultColDefVarios.objeto, indi);
      }
    }*/
  }

  cambio(cambio) {
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = true;
    }
  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'transferencia') {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = true;
      this.limpiarDatos();
      this.encontrarTransferencia(opcion.TRANSFER_NRO);
    }

    if (this.opcionbusqueda === 'origen') {
      this.enctransferService.invenctransferarray[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
      this.enctransferService.invenctransferarray[this.indicador].BOD_NOMBRE = opcion.BOD_NOMBRE;
      document.getElementById('txtCodBodDestino').focus();
    }
    if (this.opcionbusqueda === 'destino') {
      this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINO = opcion.BOD_CODIGO;
      this.enctransferService.invenctransferarray[this.indicador].BOD_DESTINONOMBRE = opcion.BOD_NOMBRE;
      document.getElementById('txtComentario').focus();
    }
    if (this.opcionbusqueda === 'transportista') {
      this.enctransferService.invenctransferarray[this.indicador].TRANSPORT_CODIGO = opcion.TRANSPORT_CODIGO;
      this.enctransferService.invenctransferarray[this.indicador].TRANSPORT_NOMBRE = opcion.TRANSPORT_NOMBRE;
      this.enctransferService.erFindCodigoTransportistas(this.enctransferService.invenctransferarray[this.indicador].TRANSPORT_CODIGO).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            this.enctransferService.invenctransferarray[this.indicador].TRANSPORT_AUTO = er[0].TRANSPORT_AUTO;
          }
        }
      });
      this.enctransferService.erFindPlacaTransportistas(this.enctransferService.invenctransferarray[this.indicador].TRANSPORT_CODIGO).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            this.enctransferService.invenctransferarray[this.indicador].TRANSPORT_PLACA = er[0].TRANSPORT_PLACA;
          }
        }
      });
      document.getElementById('txtComprobante').focus();
    }
    if (this.opcionbusqueda === 'numeracion') {
      this.enctransferService.invenctransferarray[this.indicador].MUESTRARIO = opcion.NUMTRA_SIGLA;
      this.groupDatosPorNumAdic = true;
      document.getElementById('txtComprobante').focus();
    }
    if (this.opcionbusqueda === 'cliente') {
      this.enctransferService.invenctransferarray[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;
      this.enctransferService.invenctransferarray[this.indicador].CLI_NOMBRE = opcion.CLI_NOMBRE;
    }

    this.salirDialog();
  }

  autorizaTransferencia() {
    this.claveAuth = '';
    this.autorizacionCodigo = 'AUTTRF';
    this.displayDialogAutorizacion = true;
  }

  realizarTransferencia() {
    let strEstado = '';

    // let dblTotalTransf = 0;
    // let dblTotalVarios = 0;

    /*dblTotalTransf = this.enctransferService.calcularCostoTotal();
    // dblTotalVarios = this.enctransferService.calcularTotalVarios();
    if (dblTotalVarios > 0) {
      if (dblTotalTransf !== dblTotalVarios) {
        this.messageService.add({
          key: 'enctransfer',
          severity: 'error',
          summary: 'Información',
          detail: 'El total varios no es igual al total de la transferencia'
        });
        return;
      }
    }*/
    if (this.botonGuardar === false) {
      this.messageService.add({
        key: 'enctransfer',
        severity: 'error',
        summary: 'Información',
        detail: 'Guarde la factura para realizar la transferencia'
      });
      return;
    }

    this.enctransferService.erRealTransfer(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          strEstado = er[0].TRANSFER_ESTADO;
        }
      }

      if (strEstado !== null) {
        if (strEstado !== 'A') {
          if (strEstado === 'T') {
            this.confirmationService.confirm({
              message: 'Está seguro de reversar la transferencia',
              header: 'Información',
              icon: 'pi pi-exclamation-triangle',
              key: 'confirmenctransfer',
              accept: () => {
                this.confirmationService.close();
                this.reversarTransferencia();
              },
              reject: () => {
                this.confirmationService.close();
              }
            });
            return;
          }
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'Debe autorizar e ingresar el nombre de la persona que autorizó la transferencia y guarde'
          });
          return;
        } else {
          this.confirmationService.confirm({
            message: 'Está seguro de realizar la transferencia',
            header: 'Información',
            icon: 'pi pi-exclamation-triangle',
            key: 'confirmenctransfer',
            accept: () => {
              this.confirmationService.close();
              this.transferir();
            },
            reject: () => {
              this.confirmationService.close();
            }
          });
        }
      }
    });
  }

  async reversarTransferencia() {
    let strSentencia = 'delete from inv_trnkardex where com_codigo=' + '\'01\'';
    strSentencia = strSentencia + ' and trnart_numero=trim(' + '\'' +
      this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO + '\'' + ')';

    await this.enctransferService.reversarTransferencia(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO);
    this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO = 'AUTORIZADO';
    // Actualizar el estado de la transferencia
    await this.guardarRegistro();
    await this.actualizaEntregasReserva('P');

    // this.enctransferService.ejecutarConsulta(strSentencia).subscribe(data => {
    //   this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO = 'AUTORIZADO';
    //   // Actualizar el estado de la transferencia
    //   this.guardarRegistro();
    // });


  }

  async transferir() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;

    const strFechaTransferencia = this.datePipe.transform(this.enctransferService.invenctransferarray[
      this.indicador].TRANSFER_FECHATRANSFER, 'dd/MM/yyyy');
    const strHora = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_HORA;


    if (await this.verificarExistencia() === false) {
      return;
    }


    this.enctransferService.registroTrnKardex(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO,
      strFechaTransferencia + ' ' + strHora).subscribe(data => {
        // actualiza el estado
        this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO = 'TRANSFERIDO';
        this.botonAutorizar = false;
        // Actualizar el estado de la transferencia
        this.guardarRegistro();
        this.actualizaEntregasReserva('T');
        // Recalcula el costo promedio de los articulos insertados
        // if (this.intRecalculaCostoPromedio == 1) {
        const strFechaEmision = this.datePipe.transform(this.enctransferService.invenctransferarray[
          this.indicador].TRANSFER_FECHAEMISION, 'dd/MM/yyyy');
        this.enctransferService.recalculaCostoArtDoc('select art_codigo from inv_dettransfer' +
          ' where TRANSFER_NRO=' + '\'' + this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO +
          '\'', strFechaEmision);
        // }
      });
  }

  async verificarExistencia() {
    let intIndiceLista = 0;
    let item: InvDettransfer;
    let dblExistencia = 0;

    const strFechaTransferencia = this.datePipe.transform(this.enctransferService.invenctransferarray[
      this.indicador].TRANSFER_FECHATRANSFER, 'dd/MM/yyyy');
    const strHora = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_HORA;

    for (intIndiceLista = 0; intIndiceLista < this.enctransferService.invdettransferarray[this.indicador].length; intIndiceLista++) {
      item = this.enctransferService.invdettransferarray[this.indicador][intIndiceLista];
      if (item.ART_CODIGO !== '') {
        const data = await this.enctransferService.obtenerExistenciaBodega('FAC', item.ART_CODIGO,
          this.enctransferService.invenctransferarray[this.indicador].BOD_CODIGO, strFechaTransferencia, strHora);
        for (const rs of data) {
          dblExistencia = rs.EXIST;
        }
        if (dblExistencia < item.DETTRANSFER_CANTIDAD) {
          this.messageService.add({
            key: 'enctransfer',
            severity: 'error',
            summary: 'Información',
            detail: 'No hay la existencia necesaria del artículo ' + item.ART_CODIGO + ', ' +
              'la existecia actual es de: ' + dblExistencia
          });
          return false;
        }
      }
    }
    return true;
  }


  comprobarClave() {
    this.enctransferService.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'AUTTRF') {
      const fechaActual = new Date();
      this.boldateAutoriza = true;
      this.botonesmodificar();
      this.nombreEstado('A');
      this.enctransferService.invenctransferarray[this.indicador].TRANSFER_FECHAAUTORIZA = fechaActual;
      document.getElementById('txtNombreAutoriza').focus();
      this.displayDialogAutorizacion = false;
    }
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'AUTTRF') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave.'
        });
      }
    }
  }

  generarArchivoEncTransfer(archivo) {
    /* ObjectOutputStream;
     oos = null;
     
     oos = new ObjectOutputStream(new FileOutputStream(archivo));
     InvEncTransfer;
     item = new InvEncTransfer();
     item = seleccionarEncTransfer();
     
     oos.writeObject(item);// escribe en el archivo el objeto
     oos.close();
     */
  }

  seleccionarEncTransfer() {
    const item: InvEnctransfer = {};

    const parametros0 = 'SOLREG';
    const parametros1 = ' a.transfer_nro=' + '\'' + this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO +
      '\'' + ' and a.com_codigo=' + '\'01\'';
    const parametros2 = '';

    this.enctransferService.consultarEncTransfer(parametros0, parametros1, parametros2).subscribe(data => {
      for (const rs of data) {
        item.ASI_NRO = rs.ASI_NRO;
        item.BOD_CODIGO = rs.BOD_CODIGO;
        item.CLI_CODIGO = rs.CLI_CODIGO;
        item.COM_CODIGO = rs.COM_CODIGO;
        item.PRO_CODIGO = rs.PRO_CODIGO;
        item.PRO_NOMBRE = rs.PRO_NOMBRE;
        item.TRANSFER_COMENTARIO = rs.TRANSFER_COMENTARIO;
        item.TRANSFER_COMPROBANTE = rs.TRANSFER_COMPROBANTE;
        item.TRANSFER_ESTADO = rs.TRANSFER_ESTADO;
        item.TRANSFER_FECHAAUTORIZA = rs.TRANSFER_FECHAAUTORIZA;
        item.TRANSFER_FECHAEMISION = rs.TRANSFER_FECHAEMISION;
        item.TRANSFER_FECHATRANSFER = rs.TRANSFER_FECHATRANSFER;
        item.TRANSFER_FLAG = rs.TRANSFER_FLAG;
        item.TRANSFER_HORA = rs.TRANSFER_HORA;
        item.TRANSFER_NRO = rs.TRANSFER_NRO;
        item.TRANSFER_PERSONAAUTORIZA = rs.ASI_NRO;
        item.TRANSFER_REFER = rs.ASI_NRO;
        item.TRANSFER_REFERENCIA = rs.ASI_NRO;
        item.TRANSFER_TIPO = rs.ASI_NRO;
        item.TRANSPORT_CODIGO = rs.ASI_NRO;
        item.USUIDENTIFICACION = rs.ASI_NRO;
        item.CLI_NOMBRE = rs.ASI_NRO;
        item.TRANSPORT_NOMBRE = rs.ASI_NRO;
        item.TRANSPORT_PLACA = rs.TRANSPORT_PLACA;
        item.TRANSPORT_AUTO = rs.TRANSPORT_AUTO;
      }
    });

    return item;
  }

  obtenerPathImpExp(): boolean {
    return true;
  }

  exportarTransferencia() {
    let strArchivo = '';
    let strPath = '';
    if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO !== '') {
      if (this.obtenerPathImpExp()) {
        /* File archivoEnc = new File( strPath , strArchivo);
         File archivoDet = new File( strPath , "det_" + strArchivo);
         
           generarArchivoEncTransfer(archivoEnc);
           generarArchivoDetTransfer(archivoDet);*/
        this.messageService.add({
          key: 'encfacpro',
          severity: 'success',
          summary: 'Información',
          detail: 'Se han generado los archivos:' +
            strArchivo + ' y det_' + strArchivo
        });
      } else {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'error',
          summary: 'Error',
          detail: 'No se Realizó la Exportación...'
        });
      }
    } else {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'error',
        summary: 'Error',
        detail: 'Por favor, escoja el documento que desea exportar.'
      });
    }
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }

  agregarSeriales() {
    if (this.enctransferService.invenctransferarray[this.indicador].TRANSFER_ESTADO === 'TRANSFERIDO') {
      this.strNumFact = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
      this.strFechaSer = this.datePipe.transform(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_FECHAEMISION, 'dd/MM/yyyy');
      this.displayDialogSeriales = true;
    } else {
      this.messageService.add({
        key: 'enctransfer',
        severity: 'warn',
        summary: 'Información',
        detail: 'Solo puede ingresar los seriales una vez hecha la transferencia'
      });
    }


    this.closeSidebar();
  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  cerrarSeriales() {
    this.displayDialogSeriales = false;
  }

  salirDialog() {
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
    this.displayDialogAutorizacion = false;
    this.displayDialogSeriales = false;
    this.displayAcciones = false;
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  async recibirReservas(valores) {

    console.log(valores);
    let transferencia: InvDettransfer;
    let transferencias: InvDettransfer[];
    transferencias = [];
    transferencia = {};
    if (valores.length !== 0) {
      for (const valor of valores) {
        transferencia.TRANSFER_NRO = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_NRO;
        transferencia.DETTRANSFER_REFERENCIA = '';
        transferencia.TRNRES_NUMERO = valor.TRNRES_NUMERO;
        transferencia.ART_CODIGO = valor.ART_CODIGO;
        transferencia.ART_NOMBRE = valor.ART_NOMBRE;
        transferencia.UNI_CODIGO = valor.ART_UNIDAD;
        transferencia.BOD_CODIGO = valor.BOD_CODIGODES;
        transferencia.CLAVE = valor.TRNRES_NUMERO;
        transferencia.COM_CODIGO = valor.COM_CODIGO;
        transferencia.DETTRANSFER_CANTIDAD = Number(valor.TRNRES_CANTIDAD) - Number(valor.TRNRES_ENTREGADO) - Number(valor.TRANSFER_CANTIDAD_P);
        transferencia.DETTRANSFER_CANTIDAD = Number(transferencia.DETTRANSFER_CANTIDAD).toFixed(2);
        transferencia.TRNRES_CANTIDAD = transferencia.DETTRANSFER_CANTIDAD;
        transferencia.DETTRANSFER_FLAG = 0;
        transferencia.DETTRANSFER_LINEA = 0;

        const strFecha = this.datePipe.transform(this.enctransferService.invenctransferarray[this.indicador].TRANSFER_FECHAEMISION, 'dd/MM/yyyy HH:mm:ss');
        const strHora = this.enctransferService.invenctransferarray[this.indicador].TRANSFER_HORA;



        const rs = await this.enctransferService.recibirReservas(valor.ART_CODIGO, strFecha, strHora);
        console.log(rs);
        transferencia.DETTRANSFER_COSTOUNIT = Number(rs[0].COSTO).toFixed(2);
        transferencia.DETTRANSFER_COSTOTOTAL = Number(rs[0].COSTO * Number(transferencia.DETTRANSFER_CANTIDAD)).toFixed(2);
        this.enctransferService.invdettransferarray[this.indicador].push(transferencia);
        transferencia = {};
      }

      this.enctransferService.calcularCostoTotal();
      this.aggrid.refreshaggrid(this.enctransferService.invdettransferarray[this.indicador], this.defaultColDef.objeto);
      this.displayDialogReservas = false;
    }
  }

  recibirSenalesReservas(valor) {
    if (valor === true) {
      this.displayDialogReservas = false;
    }
  }

  async actualizaEntregasReserva(estado) {
    for (const item of this.enctransferService.invdettransferarray[this.indicador]) {
      if (item.TRNRES_NUMERO !== '' && item.TRNRES_NUMERO !== null) {
        await this.enctransferService.actualizarEntregaReserva(item.TRNRES_NUMERO, Number(item.DETTRANSFER_CANTIDAD), estado);
      }
    }
  }

  async actualizarEstadoReservas() {
    for (const item of this.enctransferService.invdettransferarray[this.indicador]) {
      if (item.TRNRES_NUMERO !== '' && item.TRNRES_NUMERO !== null) {
        await this.enctransferService.actualizarEntregaReserva(item.TRNRES_NUMERO, Number(item.DETTRANSFER_CANTIDAD), 'E');
      }
    }

    this.limpiarDatos();
  }

  async busquedacliente(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'cliente';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RucIde', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'CLI_ZONA' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'NombreC', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.enctransferService.erpBusquedaCliente(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.busqService.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });

  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }

}
