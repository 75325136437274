import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { NommaeEmpleado } from '../nominterfaces/nomempleado';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';
import { nomIngrFijo } from '../nominterfaces/nomingrfijo';
import { nomEgreFijo } from '../nominterfaces/nomegrefijo';
import { nomVacaciones } from '../nominterfaces/nomvacaciones';
import { nomCargaFami } from '../nominterfaces/nomcargasfam';
@Injectable({
  providedIn: 'root'
})
export class 
NomempleadosService {
  private url: string;
  public way: string;
  private nommaeEmpleado: NommaeEmpleado;
  public nommaenomIngrFijo:nomIngrFijo[]=[];
  public nommaeEgreFijo:nomEgreFijo[]=[];
  public nomVacaciones:nomVacaciones[]=[];
  public nomcrudVaca:nomVacaciones[]=[];
  public nomCargaFamil:nomCargaFami[]=[];
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService,
    public usuario: Usuario,
    private pipe: DatePipe) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
    this.usuario.identificacion;
  }

  agregarEmpleado(empleado:NommaeEmpleado){

    console.log(empleado);
    if(empleado.NUEVO){
      empleado.EMP_CODIGO=null;
    };
    return this.http.post(this.way + '/nom/empleados/2dq0y1985h9yfsf', {
      elementos: {
        p_emp_codigo :empleado.EMP_CODIGO,
        p_emp_nombre :empleado.EMP_NOMBRE,
        p_emp_apellido :empleado.EMP_APELLIDO,
        p_emp_tipoide :empleado.EMP_TIPOIDE,
        p_emp_rucide :empleado.EMP_RUCIDE,
        p_emp_direccion :empleado.EMP_DIRECCION,
        p_emp_calle :empleado.EMP_CALLE,
        p_emp_casa :empleado.EMP_CASA,
        p_prov_codigo :empleado.PROV_CODIGO,
        p_prov_codigoc :empleado.PROV_CODIGOC,
        p_emp_telefono1 :empleado.EMP_TELEFONO1,
        p_emp_telefono2 :empleado.EMP_TELEFONO2,
        p_dep_codigo :empleado.DEP_CODIGO,
        p_emp_remsaluni :empleado.EMP_REMSALUNI,
        p_emp_bonresp :empleado.EMP_BONRESP,
        p_emp_presiess :empleado.EMP_PRESIESS,
        p_emp_prestamo :empleado.EMP_PRESTAMO,
        p_con_codigopres :empleado.CON_CODIGOPRES,
        p_emp_segurofp :empleado.EMP_SEGUROFP,
        p_emp_movilizacion :empleado.EMP_MOVILIZACION,
        p_emp_fecingreso :this.cambiarfechanull(empleado.EMP_FECINGRESO),
        p_emp_activo :empleado.EMP_ACTIVO,
        p_ven_codigo :empleado.VEN_CODIGO,
        p_emp_anticipo :empleado.EMP_ANTICIPO,
        p_com_codigo :'01',
        p_emp_telefono :empleado.EMP_TELEFONO,
        p_emp_alimentacion :empleado.EMP_ALIMENTACION,
        p_cli_codigo :empleado.CLI_CODIGO,
        p_CON_CODIGOSXP :empleado.CON_CODIGOSXP,
        p_BAN_CODIGOEMP :empleado.BAN_CODIGOEMP,
        p_EMP_CTAAHORROS :empleado.EMP_CTAAHORROS,
        p_emp_presthipotecario :empleado.EMP_PRESTHIPOTECARIO,
        p_emp_fndsrsrva :empleado.EMP_FNDSRSRVA,
        p_emp_fndrsvpagar   :empleado.EMP_FNDRSVPAGAR,
        p_emp_fndrsvrretener :empleado.EMP_FNDRSVRRETENER,
        p_emp_roladmn  :empleado.EMP_ROLADMN,
        p_emp_rolvend  :empleado.EMP_ROLVEND,
        p_emp_calcdectercero :empleado.EMP_CALCDECTERCERO,
        p_emp_calcdeccuarto :empleado.EMP_CALCDECCUARTO,
        p_emp_calcvacaciones :empleado.EMP_CALCVACACIONES,
        p_emp_calc75       :empleado.EMP_CALC75,
        p_emp_ctacalc75     :empleado.EMP_CTACALC75,
        p_emp_compsala       :empleado.EMP_COMPSALA,
        p_emp_ctacompsala   :empleado.EMP_CTACOMPSALA,
        p_emp_codigo2 :empleado.EMP_RUCIDE,
        p_emp_tiprol_codigo_03  :empleado.EMP_TIPROL_CODIGO_03,
        p_emp_tiprol_codigo_04  :empleado.EMP_TIPROL_CODIGO_04,
        p_emp_deciterccalc      :empleado.EMP_DECITERCCALC,
        p_emp_decitercpaga      :empleado.EMP_DECITERCPAGA,
        p_emp_decitercreti      :empleado.EMP_DECITERCRETI,
        p_emp_decicuarcalc      :empleado.EMP_DECICUARCALC,
        p_emp_decicuarpaga      :empleado.EMP_DECICUARPAGA,
        p_emp_decicuarreti      :empleado.EMP_DECICUARRETI,
        p_emp_porcaporindi      :empleado.EMP_PORCAPORINDI,
        p_emp_porcaporpatr      :empleado.EMP_PORCAPORPATR,
        p_emp_porcfondreser     :empleado.EMP_PORCFONDRESER,
        p_emp_plazocontrato     :empleado.EMP_PLAZOCONTRATO,
        p_emp_fechacontrato     :this.cambiarfechanull(empleado.EMP_FECHACONTRATO),
        p_emp_vacacalc          :empleado.EMP_VACACALC,
        p_emp_vacapaga          :empleado.EMP_VACAPAGA,
        p_emp_vacareti          :empleado.EMP_VACARETI,
        p_emp_alimtribiess      :empleado.EMP_ALIMTRIBIESS,
        p_emp_fecsalida         :this.cambiarfechanull(empleado.EMP_FECSALIDA),
        p_tiprol_codigo         :empleado.TIPROL_CODIGO,
        p_emp_caussali          :empleado.EMP_CAUSSALI,
        p_emp_detasali          :empleado.EMP_DETASALI,
        p_con_codiremusalaunif  :empleado.CON_CODIREMUSALAUNIF,
        p_con_codialim          :empleado.CON_CODIALIM,
        p_con_codiboniresp      :empleado.CON_CODIBONIRESP,
        p_emp_eti1ing           :empleado.EMP_ETI1ING,
        p_con_codieti1ing       :empleado.CON_CODIETI1ING,
        p_emp_eti1ingtribiess   :empleado.EMP_ETI1INGTRIBIESS,
        p_emp_eti2ing           :empleado.EMP_ETI2ING,
        p_con_codieti2ing       :empleado.CON_CODIETI2ING,
        p_emp_eti2ingtribiess   :empleado.EMP_ETI2INGTRIBIESS,
        p_con_codianti          :empleado.CON_CODIANTI,
        p_con_codipresiessq     :empleado.CON_CODIPRESIESSQ,
        p_con_codipreshipo      :empleado.CON_CODIPRESHIPO,
        p_con_codisegupriv      :empleado.CON_CODISEGUPRIV,
        p_con_codimovi          :empleado.CON_CODIMOVI,
        p_con_coditele          :empleado.CON_CODITELE,
        p_emp_sexo              :empleado.EMP_SEXO,
        p_emp_cargo             :empleado.EMP_CARGO,
        p_emp_cargiess          :empleado.EMP_CARGIESS,
        p_emp_correo            :empleado.EMP_CORREO,
        p_emp_celular           :empleado.EMP_CELULAR,
        p_emp_cedumili          :empleado.EMP_CEDUMILI,
        p_emp_centcost          :empleado.EMP_CENTCOST,
        p_emp_concodigo         :empleado.EMP_CONCODIGO,
        p_emp_fechnaci          :this.cambiarfechanull(empleado.EMP_FECHNACI),
        p_emp_formpago          :empleado.EMP_FORMPAGO,
        p_emp_estatrab          :empleado.EMP_ESTATRAB,
        p_emp_tipoempl          :empleado.EMP_TIPOEMPL,
        p_emp_porcdisc          :empleado.EMP_PORCDISC,
        p_emp_carncona          :empleado.EMP_CARNCONA,
        p_emp_detadisc          :empleado.EMP_DETADISC,
        p_emp_rucidediscsust    :empleado.EMP_RUCIDEDISCSUST,
        p_emp_nombrdiscsust     :empleado.EMP_NOMBRDISCSUST,
        p_emp_decicuartregi     :empleado.EMP_DECICUARTREGI,
        p_emp_condtrabrespdisc :empleado.EMP_CONDTRABRESPDISC,
        p_emp_tipctabanc       :empleado.EMP_TIPCTABANC,
        p_emp_codibancempl          :empleado.EMP_CODIBANCEMPL,
        p_emp_numcargas             :empleado.EMP_NUMCARGAS,    
        p_emp_estacivil         :empleado.EMP_ESTACIVIL,
        p_emp_correoempre   :empleado.EMP_CORREOEMPRE,
        p_emp_correopers  :empleado.EMP_CORREOPERS,
        p_emp_nacionalidad :empleado.EMP_NACIONALIDAD,
        p_emp_licencaduca  :this.cambiarfechanull(empleado.EMP_LICENCADUCA),
        p_emp_licencia  :empleado.EMP_LICENCIA,
        p_emp_carnet  :empleado.EMP_CARNET,
        p_emp_lugarnaci  :empleado.EMP_LUGARNACI,
        p_emp_jefeinmd  :empleado.EMP_JEFEINMD,
        p_emp_jefeext  :empleado.EMP_JEFEEXT,
        p_emp_emprasumimpurent  :empleado.EMP_EMPRASUMIMPURENT,
        p_emp_tiposistsalaneto   :empleado.EMP_TIPOSISTSALANETO,
        p_emp_resitrab :empleado.EMP_RESITRAB,
        p_emp_paisresi :empleado.EMP_PAISRESI,
        p_emp_apliconvevitdobltrib  :empleado.EMP_APLICONVEVITDOBLTRIB,
        p_emp_trabjornparc  :empleado.EMP_TRABJORNPARC,
        p_emp_horatiemparc :empleado.EMP_HORATIEMPARC,
        p_emp_enfcatastrofica: empleado.EMP_ENFCATASTROFICA 
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  cambiarfechanull(valor){
    let res="";
    if(isNullOrUndefined(valor)){
      return res;
    }else{
      try{
        if(valor.toString().includes('-')){
          res=this.pipe.transform(valor,'dd/MM/yyyy');
          return res;
        }else{
          return valor;
        }
       
      }catch(err){
        return valor;
      } 
    }
  }
  async empleadoingrefijo(emp_codigo){
    return  await this.http.post(this.way + '/nom/empleados/yyfuxzu9k9cn4ff', {
      elementos: {
        p_emp_codigo:emp_codigo,
        p_com_codigo:'01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async eliminarempleadoingrefijo(maeingrfijo_codigo){
    return  await this.http.post(this.way + '/nom/empleados/74u0fhorckemn08', {
      elementos: {
        p_maeingrfijo_codigo:maeingrfijo_codigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  generarPeriodoVacaciones(emp_codigo){
    return this.http.post(this.way + '/nom/empleados/poia6cwo7pz8qw3', {
      elementos: {
        p_emp_codigo:emp_codigo,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async empleadoegrefijo(emp_codigo){
    return await this.http.post(this.way + '/nom/empleados/3vqfrhvlmdnerph', {
      elementos: {
        p_emp_codigo:emp_codigo,
        p_com_codigo:'01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async eliminarempleadoegrefijo( maeegrefijo_codigo ){
    return  await this.http.post(this.way + '/nom/empleados/tgzfrl3x57dr3ah', {
      elementos: {
        p_maeegrefijo_codigo : maeegrefijo_codigo 
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async actuinfoingrfijo( ingresoFijo:nomIngrFijo ,emplecodigo,orden){
    if(ingresoFijo.NUEVO){
      ingresoFijo.MAEINGRFIJO_CODIGO=null;
    };
    if(isNullOrUndefined(ingresoFijo.CEN_CODIGO)){
      ingresoFijo.CEN_CODIGO='';
    };
    if(isNullOrUndefined(ingresoFijo.MAEINGRFIJO_NROCUOT)||ingresoFijo.MAEINGRFIJO_NROCUOT==''){
      ingresoFijo.MAEINGRFIJO_NROCUOT=0;
    };
    if(isNullOrUndefined(ingresoFijo.MAERUBRO_CODIGO) || ingresoFijo.MAERUBRO_CODIGO==''){
      ingresoFijo.MAERUBRO_CODIGO="";
    };
    return  await this.http.post(this.way + '/nom/empleados/nly12y53vz5kknm', {
      elementos: {              
        p_maeingrfijo_codigo: ingresoFijo.MAEINGRFIJO_CODIGO,
        p_emp_codigo:         emplecodigo,
        p_maeingrfijo_descripcion:ingresoFijo.MAEINGRFIJO_DESCRIPCION,
        p_maeingrfijo_importe: parseFloat(ingresoFijo.MAEINGRFIJO_IMPORTE),
        p_maeingrfijo_aporiess: (ingresoFijo.MAEINGRFIJO_APORIESS==true)?"1":"0",
        p_maeingrfijo_nrocuot:  parseInt(ingresoFijo.MAEINGRFIJO_NROCUOT),
        p_maeingrfijo_fecini: this.cambiarfechanull(ingresoFijo.MAEINGRFIJO_FECINI),
        p_maeingrfijo_fecfin:this.cambiarfechanull(ingresoFijo.MAEINGRFIJO_FECFIN),
        p_com_codigo: '01',
        p_con_com_codigo: '01',
        p_con_codigo: ingresoFijo.CON_CODIGO,
        p_cen_codigo:  ingresoFijo.CEN_CODIGO,
        p_cen_comcodigo: '01',
        p_maeingrfijo_orden:parseInt(orden),
        p_maerubro_codigo: parseInt(ingresoFijo.MAERUBRO_CODIGO),
        p_maeingrfijo_fechregi: this.cambiarfechanull(ingresoFijo.MAEINGRFIJO_FECHREGI),
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async genecuotingrfijo(ingresoFijo:nomIngrFijo){
    if(isNullOrUndefined(ingresoFijo.MAEINGRFIJO_NROCUOT)||ingresoFijo.MAEINGRFIJO_NROCUOT==''){
      ingresoFijo.MAEINGRFIJO_NROCUOT=1;
    };
    if(parseInt(ingresoFijo.MAEINGRFIJO_NROCUOT)==0){
      ingresoFijo.MAEINGRFIJO_NROCUOT=1;
    }
    if(isNullOrUndefined(ingresoFijo.MAERUBRO_CODIGO) || ingresoFijo.MAERUBRO_CODIGO==''){
      ingresoFijo.MAERUBRO_CODIGO="";
    };
    console.log(ingresoFijo);
    return  await this.http.post(this.way + '/nom/empleados/buu7gc98vw1xa7o', {
      elementos: {
        p_maeingrfijo_codigo: parseInt(ingresoFijo.MAEINGRFIJO_CODIGO),
        p_maeingrfijo_nrocuot: ingresoFijo.MAEINGRFIJO_NROCUOT  ,
        p_maeingrfijo_fecini:  ingresoFijo.MAEINGRFIJO_FECINI  ,
        p_maeingrfijo_importe:  parseFloat(ingresoFijo.MAEINGRFIJO_IMPORTE) ,
        p_periodo_codigo:    parseInt(ingresoFijo.MAEPERIODO_CODIGO)
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async actuinfoegrefijo(egresoFijo:nomEgreFijo,emplecodigo,orden){
    if(egresoFijo.NUEVO){
      egresoFijo.MAEEGREFIJO_CODIGO=null;
    };
    if(isNullOrUndefined(egresoFijo.CEN_CODIGO)){
      egresoFijo.CEN_CODIGO='';
    };
    if(isNullOrUndefined(egresoFijo.MAEEGREFIJO_NROCUOT)||egresoFijo.MAEEGREFIJO_NROCUOT==''){
      egresoFijo.MAEEGREFIJO_NROCUOT=1;
    };
    return  await this.http.post(this.way + '/nom/empleados/lbm0bi1ge2ya4y5', {
      elementos: {
        p_maeegrefijo_codigo:       egresoFijo.MAEEGREFIJO_CODIGO,
        p_emp_codigo:               emplecodigo,
        p_maeegrefijo_descripcion:  egresoFijo.MAEEGREFIJO_DESCRIPCION,
        p_maeegrefijo_importe:      parseFloat(egresoFijo.MAEEGREFIJO_IMPORTE),
        p_maeegrefijo_nrocuot:      parseInt(egresoFijo.MAEEGREFIJO_NROCUOT),
        p_maeegrefijo_fecini:       this.cambiarfechanull(egresoFijo.MAEEGREFIJO_FECINI),
        p_maeegrefijo_fecfin:       this.cambiarfechanull(egresoFijo.MAEEGREFIJO_FECFIN),
        p_con_codigo:               egresoFijo.CON_CODIGO,
        p_com_codigo:               '01',
        p_com_concodigo:            '01',
        p_cen_codigo:               egresoFijo.CEN_CODIGO,
        p_cen_comcodigo:            '01',
        p_maeegrefijo_orden:        parseInt(orden),
        p_maerubro_codigo:          parseInt(egresoFijo.MAERUBRO_CODIGO),
        p_maeegrefijo_fechregi:     this.cambiarfechanull(egresoFijo.MAEEGREFIJO_FECHREGI),
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async genecuotegrefijo(egresoFijo:nomEgreFijo){
    if(isNullOrUndefined(egresoFijo.MAEEGREFIJO_NROCUOT)||egresoFijo.MAEEGREFIJO_NROCUOT==''){
      egresoFijo.MAEEGREFIJO_NROCUOT=1;
    };
    if(parseInt(egresoFijo.MAEEGREFIJO_NROCUOT)==0){
      egresoFijo.MAEEGREFIJO_NROCUOT=1;
    }
    return  await this.http.post(this.way + '/nom/empleados/vkeufpnloy65t5j', {
      elementos: {
        p_maeegrefijo_codigo:    parseInt(egresoFijo.MAEEGREFIJO_CODIGO),
        p_maeegrefijo_nrocuot:   egresoFijo.MAEEGREFIJO_NROCUOT,
        p_maeegrefijo_fecini:    egresoFijo.MAEEGREFIJO_FECINI,
        p_maeegrefijo_importe:   parseFloat(egresoFijo.MAEEGREFIJO_IMPORTE),
        p_periodo_codigo:        parseInt(egresoFijo.MAEPERIODO_CODIGO)
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  listavacaciones(emp_codigo){
    return this.http.post(this.way + '/nom/empleados/vt8e16mfx12lged', {
      elementos: {
        p_emp_codigo:emp_codigo,
        p_com_codigo:'01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async cargafamiliar(emp_codigo){
     return await this.http.post(this.way + '/nom/empleados/jfibwjkul8lk92g', {
      elementos: {
        p_emp_codigo:emp_codigo,
        p_com_codigo:'01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async elimicargfami(empcargfami_codigo){
    return await this.http.post(this.way + '/nom/empleados/40e1cb8sf052d1b', {
     elementos: {
      p_empcargfami_codigo: empcargfami_codigo
     },
   }, this.confIniciales.httpOptions()).toPromise();
 }
 async actucargfami(actufami:nomCargaFami,emp_codigo){
  if(actufami.NUEVO){
    actufami.EMPCARGFAMI_CODIGO=null;
  };
  return await this.http.post(this.way + '/nom/empleados/2mgsxb7wb8jzm7z', {
   elementos: {
    p_emp_codigo            : emp_codigo,
    p_com_codigo            :'01',
    p_empcargfami_codigo    :actufami.EMPCARGFAMI_CODIGO,
    p_empcargfami_nombre    :actufami.EMPCARGFAMI_NOMBRE,
    p_empcargfami_tipoide   :actufami.EMPCARGFAMI_TIPOIDE,
    p_empcargfami_rucide    :actufami.EMPCARGFAMI_RUCIDE,
    p_empcargfami_sexo      :actufami.EMPCARGFAMI_SEXO,
    p_empcargfami_tiporela  :actufami.EMPCARGFAMI_TIPORELA,
    p_empcargfami_fechnaci  :this.cambiarfechanull(actufami.EMPCARGFAMI_FECHNACI),
    p_empcargfami_disc      :(actufami.EMPCARGFAMI_DISC==true)?"S":"N",
    p_empcargfami_porcdisc  :parseFloat(actufami.EMPCARGFAMI_PORCDISC),
    p_empcargfami_carncona  :actufami.EMPCARGFAMI_CARNCONA,
    p_empcargfami_estatus   :actufami.EMPCARGFAMI_ESTATUS,
    p_empcargfami_gastded   :actufami.EMPCARGFAMI_GASTDED
   },
 }, this.confIniciales.httpOptions()).toPromise();
}
actunumcarga(emp_codigo,numCargas){
  if(isNullOrUndefined(numCargas)||numCargas==''){
    numCargas=0;
  };
  return this.http.post(this.way + '/nom/empleados/mxyroffd1avyv3o', {
    elementos: {
      strNumCargas:numCargas,
      strEMP_CODIGO:emp_codigo
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
eliminalineadetvacacion(tranvacaempl_codigo){
  return this.http.post(this.way + '/nom/empleados/0hvu71u079oqu83', {
    elementos: {
      p_tranvacaempl_codigo: tranvacaempl_codigo ,
     
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
async verificaexistprovvac(codEmplead,fechaIni,fechaFin){
  return this.http.post(this.way + '/nom/empleados/pafkiem8ma64y1j', {
    elementos: {
      strCodEmpleado: codEmplead ,
      strFechaIni:this.cambiarfechanull(fechaIni),
      strFechaFin:this.cambiarfechanull(fechaFin)
     
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
actualilineadetvacacion(vaca:nomVacaciones,emp_codigo){
  return this.http.post(this.way + '/nom/empleados/svfai4i4ayx5oio', {
    elementos: {
      p_tranvacaempl_codigo         :parseInt(vaca.TRANVACAEMPL_CODIGO),
      p_com_codigo                  :'01',
      p_tranvacaempl_tipo           :vaca.TRANVACAEMPL_TIPO,
      p_tranvacaempl_periodo        :vaca.TRANVACAEMPL_PERIODO,
      p_tranvacaempl_feciniperi     :this.cambiarfechanull(vaca.TRANVACAEMPL_FECINIPERI),
      p_tranvacaempl_fecfinperi     :this.cambiarfechanull(vaca.TRANVACAEMPL_FECFINPERI),
      p_tranvacaempl_diasperi       :parseInt(vaca.TRANVACAEMPL_DIASPERI),
      p_tranvacaempl_diasprorr      :parseFloat(vaca.TRANVACAEMPL_DIASPRORR),
      p_tranvacaempl_baseacum       :parseFloat(vaca.TRANVACAEMPL_BASEACUM),
      p_tranvacaempl_valor          :parseFloat(vaca.TRANVACAEMPL_VALOR),
      p_tranvacaempl_estado         :vaca.TRANVACAEMPL_ESTADO,
      p_tranvacaempl_fechsalivaca   :this.cambiarfechanull(vaca.TRANVACAEMPL_FECHSALIVACA),
      p_tranvacaempl_fechregrvaca   :this.cambiarfechanull(vaca.TRANVACAEMPL_FECHREGRVACA),
      p_tranvacaempl_observaciones  :vaca.TRANVACAEMPL_OBSERVACIONES,
      p_periodo_codigo              :parseInt(vaca.PERIODO_CODIGO),
      p_emp_codigo                  :emp_codigo
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
  eliminarEmpleado(emp_codigo){
    return this.http.post(this.way + '/nom/empleados/h41qk5pr1edpl0d', {
      elementos: {
        p_emp_codigo:emp_codigo
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }
 
  verificarFecha(emp_rucide,emp_fecingreso){
    return this.http.post(this.way + '/nom/empleados/jtwd1enbv64r10n', {
      elementos: {
        p_emp_rucide:emp_rucide ,
        p_emp_fecingreso:this.cambiarfechanull(emp_fecingreso)
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  geneperiodovaciones(emp_codigo){
    return this.http.post(this.way + '/nom/empleados/h41qk5pr1edpl0d', {
      elementos: {
        p_emp_codigo: emp_codigo,
        p_com_codigo:'01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async selectDatosadi(){
    return this.http.post(this.way + '/nom/empleados/7qrtcj50y2tkpuk', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //Pasar backend
  async  selectPrimero(){
    return this.http.post(this.way + '/nom/empleados/ih5kedxlra87zf8', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  selectPrimeroProm(){
    return this.http.post(this.way + '/nom/empleados/ih5kedxlra87zf8', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async  selectUltimo(){
    return this.http.post(this.way + '/nom/empleados/993szaunmi703ha', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  selectUltimoProm(){
    return this.http.post(this.way + '/nom/empleados/993szaunmi703ha', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async  selectAnterior(EMP_APELLIDO, EMP_NOMBRE){
    var ape: string = EMP_APELLIDO;
    var nom: String = EMP_NOMBRE;
    var strNOM_APE = ape.trim() + " " + nom.trim();
    return this.http.post(this.way + '/nom/empleados/g3dl6c9pq2v9qte', {
      elementos: {
        NOM_APE:strNOM_APE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async  selectSiguiente(EMP_APELLIDO, EMP_NOMBRE){
    var ape: string = EMP_APELLIDO;
    var nom: String = EMP_NOMBRE;
    var strNOM_APE = ape.trim() + " " + nom.trim();
    return this.http.post(this.way + '/nom/empleados/mbw5fsn1hwhfjqn', {
      elementos: {
        NOM_APE:strNOM_APE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async  selectEmpleado(strEMP_CODIGO){
    return this.http.post(this.way + '/nom/empleados/s08oytoxkcmlv46', {
      elementos: {
        EMP_CODIGO:strEMP_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  selectEmpleadoProm(strEMP_CODIGO){
    return this.http.post(this.way + '/nom/empleados/s08oytoxkcmlv46', {
      elementos: {
        EMP_CODIGO:strEMP_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //Jona buqueda hacer final
  /*Busqueda Dialog*/
  
  busquedaTabla() {
    return this.http.post(this.way + '/nom/empleados/2ef8b594e75b34', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //Jona continua
  async  bustipoID(){
    return this.http.post(this.way + '/nom/empleados/9jmg5rg6grpqcnb', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async  busPlazoContrato(){
    return this.http.post(this.way + '/nom/empleados/ut0rf5yjjipzxu2', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //jona con
  async  busDepartamento(){
    return this.http.post(this.way + '/nom/empleados/8eyjs5xagzmgoj4', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async  busCausaDespido(){
    return this.http.post(this.way + '/nom/empleados/em6rat1o9o3fowf', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async  busNacionalidad(){
    return this.http.post(this.way + '/nom/empleados/furcwjybg3ao9xi', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async  busProvincia(){
    return this.http.post(this.way + '/nom/empleados/gv3a7ij0xuq5zit', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async  busCiudad(strcodprov){
    return this.http.post(this.way + '/nom/empleados/gt7vau8zh94uf6q', {
      elementos: {
        codprov:strcodprov
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async  busConTrabRescDisc(){
    return this.http.post(this.way + '/nom/empleados/mwus1zyd04wquad', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async  busTIPOSISTSALANETO(){
    return this.http.post(this.way + '/nom/empleados/pwbgb8ts3pkuplk', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async busRESITRAB(){
    return this.http.post(this.way + '/nom/empleados/bcghfgcyrrh9wdu', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async busPAISRESI(){
    return this.http.post(this.way + '/nom/empleados/iq6dfp0yy2ql553', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async busAPLICONVEVITDOBLTRIB(){
    return this.http.post(this.way + '/nom/empleados/k3b7z4yfoy2v693', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async busAplicaConvenio(){
    return this.http.post(this.way + '/nom/empleados/5z3dcjrdh0j1gqe', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async busVEN_CODIGO(){
    return this.http.post(this.way + '/nom/empleados/yygxc0brem5vq88', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async busCliCod(){
    return this.http.post(this.way + '/nom/empleados/2gp3tpvfzbnkmm7', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async busEMP_TIPCTABANC(){
    return this.http.post(this.way + '/nom/empleados/y6r9qcaxfgouc12', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async busActivarValor(){
    var filtro = "AUTORIZACION_CODIGO='NOMACVA' AND COM_CODIGO='01'";
    var opcion = "CON";
    var condicion = "";
    return this.http.post(this.way + '/nom/empleados/9qbzp83abax3vde', {
      elementos: {
        p_opcion: opcion,
        p_filtro: filtro,
        p_condicion: condicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  
  async validarActivarValor(password) {
    var boo: boolean = false;
    await this.busActivarValor().then(res => {
      if (String(password) == res[0].AUTORIZACION_CLAVE) {
        boo = true;
      } else if (String(password) != res[0].AUTORIZACION_CLAVE) {
        boo = false;
      }
    })
    return boo;

  }

  encontrarRegistro1(strParametro1, strParametro2) {
    return this.http.post(this.way + '/nom/empleados/3aecc5fbbdba00', {
      elementos: {
        parametro1: strParametro1,
        parametro2: strParametro2
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro2(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddedc2', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro3(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddedc3', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro4(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddedc4', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro5(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddedc5', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro6(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddedc6', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro7(strParametro, strTipo) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddedc7', {
      elementos: {
        parametro: strParametro,
        tipo: strTipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro8(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddedc8', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro9(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddedc9', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro10(strParametro) {
    return this.http.post(this.way + '/nom/empleados/3aecc5fbbdb820', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro11(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddec21', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro12(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddec22', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro13(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddec23', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro14(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddec24', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro15(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddec25', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro16(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddec26', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro17(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddec27', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro18(strParametro) {
    return this.http.post(this.way + '/nom/empleados/1d7662fddec28', {
      elementos: {
        parametro: strParametro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerConfig(strCodigo) {
    return this.http.post(this.way + '/nom/empleados/30beb3177ca1e', {
      elementos: {
        codigo: strCodigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
   //CDPJ
 EncontrarCnta(concodigo) {
  return this.http.post<any[]>(this.way + '/inventario/articulo/ebb317eed5b66000', {
    elementos: {
      con_codigo: concodigo
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
encontrarEtiquetas() {
  return this.http.post<any[]>(this.way + '/nom/empleados/20eebbb2d79dd6000000000', {
    elementos: {     
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
}
