
// SACI WEB
// Rutina: Ingreso de Documentos - Facturas de Ventas
// Propietario: Victor Perez
// Modificado por: VP
// Fecha de creación: 12-11-2019
// Fecha de Modificación: 27-07-2019
//GSRF
import { Venexpofac } from '../veninterfaces/venexpofac';
//GSRF
import { InvmaearticuloService } from '../../inv/invservicios/invmaearticulo.service';
import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { VenEncfacZ } from '../veninterfaces/venencfacz';
import { SaciMaenumeracionG } from '../veninterfaces/sacimaenumeraciong';
import { VenDetfacZ } from '../veninterfaces/vendetfacz';
import { Cxctmptrnresumen } from '../veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../veninterfaces/cxctrnresumen';
import { Eletrndocumento } from '../veninterfaces/eletrndocumento';
import { VenInfoadicfac } from '../veninterfaces/veninfoadicfac';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { VenencfacService } from '../venservicios/venencfac.service';
import { VenmaeclienteService } from '../venservicios/venmaecliente.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { CalculosServiceService } from '../../servicios/calculos-service.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { PagocontadoService } from '../venservicios/pagocontado.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
// Ag-grid
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { SegMaeCompania } from '../veninterfaces/segmaecompania';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { VenestadisticaService } from '../venservicios/venestadistica.service';
import { VenDetFacturas, VenTopProductos } from '../veninterfaces/venestadisticas';
import { Usuario } from 'src/app/usuario';
import { Venencfacbasesiva } from "../veninterfaces/venencfacbasesiva";

//import { GalleriaThumbnails } from 'primeng';

@Component({
  selector: 'app-venencfac',
  templateUrl: './venencfac.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})

export class VenencfacComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;
  index: number;
  permisoFactura: SegMaePermiso;

  // dropdowns
  cmblistasprecio: any[];
  selectlistasprecio: any;
  cmbivaaux: any[];
  selectiva: any;
  cmbbodega: any[];
  selectbodega: any;
  cmbporceiva: any[];
  selectporceiva: any;
  cmbtipofactura: any[];
  selecttipofactura: any;

  // dialogs
  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogAsiento: boolean;
  displayDialogPagoCredito: boolean;
  displayDialogPagoContado: boolean;
  displayDialogDescuento: boolean;
  displayDialogRazon: boolean;
  displayDialogSeriales: boolean;
  displayDialogCuotas: boolean;
  displayDialogLotes: boolean;
  displayDialogLog: boolean;
  displayDialogDatosVentaArticulo: boolean;
  displayDialogAutorizacion: boolean;
  displayDialogSupervisor: boolean;
  displayAcciones: boolean;
  displayDialogReportes: boolean;
  displayAsiContable: boolean;
  displayDialogActualizacionFE: boolean;
  displayDialogReembolsos: boolean;
  displayDialogpedidodpendientes: boolean;
  spin = false;
  control = 0
  // barra
  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonFactura: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;

  // supervision
  usuarioSup: string;
  claveSup: string;
  strDialogo: string;
  strObjeto: string;
  intPestaniaCol: number;
  bolSupervisor: boolean;

  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;

  // interfaces
  detfacSelected: VenDetfacZ;
  nuevoItem: VenDetfacZ;
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  veninfoadicfac: VenInfoadicfac[];
  infoAdicSelected: VenInfoadicfac;
  numeroPedidos: string[];
  numeroOrdtra: string[];
  listaPedidos: VenDetfacZ[];
  numeroGuias: string[];
  listaGuias: VenDetfacZ[];
  datosfe: SegMaeCompania;

  auxiliar: number;
  auxiliar1: number;
  auxreporte: number = 0;
  auxcuotas: number;
  auxiliarrem: number = 0;
  auxiliarlot: number = 0;
  strNombreForma: string;
  vrango: boolean;//CDPJ

  descglobaconfig: any;//CDPJ
  descGlobal: number = 0;//CDPJ
  CNAFEPV: number//CDPJ
  ACCCFV: number//CDPJ
  DSNEBINGR: number;//CDPJ
  boolfacturaElec//CDPJ
  iva: number;//CDPJ
  ivavigente: number;//CDPJ
  displayDialogEst: boolean//CDPJ
  estfirma: string//CDPJ
  selectcombtipoIva: any;//CDPJ
  displaybasesiva: boolean = false;
  basesiva: Venencfacbasesiva[] = []
  defaultColBasesIva;
  asiento: any;
  strNumFact: any;
  strFechaSer: any;
  chknotadeventa: boolean;
  labelPromocion: boolean;
  rbtFECCUO: string;
  FEC: boolean;
  CUO: boolean;
  bolNuevo: boolean;
  bolNuevodet: boolean;//GSRF
  listarefrescada: boolean;
  bolCliente: boolean;
  razonanular: string;
  tipodetalle: string;
  logResultado: string;
  tmpdatoscliente: any;
  bolestcta: boolean;
  bolsaldo: boolean;
  boolfeh = false;//CDPJ
  numfac: string;//CDPJ
  bolean: boolean;//CDPJ

  // descuento global
  booleanValor: boolean;
  booleanPorcentaje: boolean;
  valorDscValor: number;
  valorDscPorcentaje: number;
  dscClienteActual: number;
  dscSeleccionado: string;
  valorotrdsctos: number = 0;//CDPJ
  valorotrdsDif: number = 0;
  valorotrdsBacero: number = 0;//CDPJ
  // configuraciones
  CONSIDNV: number;
  REFMAE: number;
  REFLOCMED: number;
  PUNVTAS: number;
  STIPIVACLI: number;
  NVNOIVA: number;
  NUMITEMFAC: number;
  FACCC: number;
  FACLIGREQ: number;
  FACCANUND: number;
  BLFHFAC: number;//CDPJ
  GUINOKARD: number;//CDPJ
  TIPDET: string;
  CENREPDOCE: number;
  // aggrid
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  rowStyleEC;
  rowStyleSaldo;
  defaultColDef;
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  defaultColDefInfoAdic;
  comboOpciones: any[];
  largo: string;
  largoestcta: string;

  txtCorreo1: string;
  txtCorreo2: string;

  // Estadistica

  dataVentasAcum: any;
  detTopProductos: VenTopProductos[] = [];
  detFacturas: VenDetFacturas[] = [];
  defaultColTopProductos;
  defaultColDetFacturas;
  rowStyleTopProd;
  rowStyleDetFacts;
  disabledEstadisticas = true;
  totalDocXCobrar: any = '';
  totalDocXCobrarPorc: any = '';
  totalDocVencidos: any = '';
  totalDocVencidosPorc: any = '';
  totalChPosfechados: any = '';
  totalChPosfechadosPorc: any = '';
  totalDocumentos: any = '';
  totalPedPendientes: any = '';
  totalPedSatisfechos: any = '';
  fechaPrimeraFact: any = '';
  totalChProtestados: any = '';
  options = {
    legend: {
      display: false
    }
  };

  // Existencia por bodega

  displayDialogExistBod = false;
  txtArtCodigo = '';
  txtArtDescripcion = '';
  txtArtUnidad = '';
  txtListaPrecio = '';
  txtObservacionRes = '';
  txtIva;
  txtBodegaRes = '';

  // Pedidos con componentes 
  intACTPEDVAL = 0;

  displayDialogDocumentos = false;

  ///// nuevo vehiculo
  ART_CODIGO = '';
  ART_NOMBRE = '';
  ART_RAMV = '';
  ART_MODELOSRI = '';
  ART_MODELOAT = '';
  ART_MARCAV = '';
  ART_CLASE = '';
  ART_TIPOV = '';
  ART_CILINDRAJE = '';
  ART_ANIO = '';
  ART_TONELAJE = '';
  ART_COLOR = '';
  ART_PORIGEN = '';
  ART_MOTOR = '';
  ART_SERIEV = '';
  ART_NRUEDAS = '';
  ART_NEJES = '';
  ART_CAPACIDAD = '';
  ART_COMBUSTIBLE = '';
  ART_BANCO = '';
  ART_NBANCO = '';
  ART_DPAGO = '';
  ART_FFABRICA = '';
  ART_DISPOSITIVO = '';
  ART_MARVEH = '';
  ART_CLAVEH = '';
  ART_TIPVEH = '';
  ART_COMVEH = '';
  ART_DISVEH = '';
  ART_P = '';
  displayvehiculo = false;
  displayDialogDocumentosim = false;

  COM_VEH1 = '';
  COM_VEH2 = '';
  //GSRF
  exportacionNuevoSeleccionado: Venexpofac = {};
  booleanImpuestoRenta: boolean;
  booleanRefrendo: boolean;
  opcionesIngresoExterior: SelectItem[];
  opcionesTipoIngresoExterior: SelectItem[];
  opcionesRegimen: SelectItem[];
  booleanRegimen: boolean;
  booleanParaiso: boolean;
  booleanRegimenP: boolean;
  booleanRegimenP1: boolean;
  codigoPaisRegimen: string;
  codigoPaisParaiso: string;
  nombrePaisSeleccionado: string;
  nombrePaisSeleccionado1: string;
  opcionesExportacion: SelectItem[];
  opcionesDistrito: SelectItem[];
  opcionesRegRefrendo: SelectItem[];
  chkactivo: boolean;
  edita: boolean;
  expo = false;
  SELECTIPOREGI: any;
  SELECTEXPORTACIONDE: any;
  SELECTIPOINGRESO: any;
  SELECTINGGRAVADOEXT: any;
  bloqbdfac: boolean;
  bloqdiasfac: boolean;
  //GSRF
  displayDialogfrmcorreos: boolean;//CDPJ
  columnDefsDetfac = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Código', field: 'DETFAC_CODIGO', cellEditor: 'cellPrueba',
      editable: (params) => {
        params.data.ENCPED_NUMERO = params.data.ENCPED_NUMERO === null ? '' : params.data.ENCPED_NUMERO;
        return params.data.ENCPED_NUMERO.length === 0;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,
      cellStyle: (params) => {
        const color = this.coloreaarDescrip(params.data);
        return { background: color};
      },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 200
        }
      }
    },
    { // 3
      headerName: 'Iva', field: 'DETFAC_TRIBIVA', cellEditor: 'cellPrueba', width: 60,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tienecalculos: true,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/,
          noblanco: true
        }
      }
    },
    { // 4
      headerName: 'Ice', field: 'DETFAC_TRIBICE', cellEditor: 'cellPrueba', width: 60,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tienecalculos: true,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/,
          noblanco: true
        }
      }
    },
    { // 5
      headerName: 'Unidad', field: 'DETFAC_UNIDAD', cellEditor: 'agSelectCellEditor', editable: true, width: 80,
      onCellValueChanged: (params) => {
        this.cambiarPrecioUnidad(params);
      },
      cellEditorParams:
      {
        values: []
      }
    },
    { // 6
      headerName: 'Cantidad', field: 'DETFAC_CANTIDAD', cellEditor: 'cellPrueba', width: 90,
      onCellClicked: (params) => {
        this.abrirLotes(params.data);
      }, onCellValueChanged: (params) => {
        const FOCCANLIN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FOCCANLIN').CFG_VALOR1;
        if (FOCCANLIN === 1) {
          this.nuevodetalle();
          console.log('enter----');
        }

      },
      cellStyle: (params) => {
        const color = this.colorearCantidad(params.data);
        return { background: color, textAlign: 'right' };
      },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 7
      headerName: 'Precio', field: 'DETFAC_PRECIO', cellEditor: 'cellPrueba', width: 90,
      // , editable: true,
      cellStyle: (params) => {
        const color = this.colorearPrecio(params.data);
        return { background: color, textAlign: 'right' };
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true,
          autorizacion: true
        }
      }
    },
    { // 8
      headerName: 'Dc (%)', field: 'DETFAC_DESCUENTO', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 9
      headerName: 'Total', field: 'DETFAC_TOTAL', cellEditor: 'cellPrueba', width: 90,
      // , editable: true,
      cellStyle: (params) => {
        const color = this.ColorearTotal(params.data);
        return { background: color, textAlign: 'right' };
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 10
      headerName: 'Tipo', field: 'DETFAC_TIPOSRI', cellEditor: 'cellPrueba', width: 70,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          opcionbuscar: true
        }
      }
    },
    { // 11
      headerName: 'Lista', field: 'DETFAC_LISTA', cellEditor: 'agSelectCellEditor', editable: true, width: 80,
      onCellValueChanged: (params) => {
        this.changelistaprecios(params.data);
      },
      cellEditorParams: {
        values: []
      }
    },
    { // 12
      headerName: 'C.Costo', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          opcionbuscar: true
        }
      }
    },
    { // 13
      headerName: 'Requisición', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 14
      headerName: 'Código', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 15
      headerName: 'Descripción', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 16
      headerName: 'Partida', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 17
      headerName: 'BaseNoObjIv', field: 'DETFAC_TRIBASENOOBJIVA', cellEditor: 'cellPrueba', width: 80,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/,
        }
      }
    },
    { // 18
      headerName: 'Cant.Und', field: 'DETFAC_CANTIDADUND', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // GSRFR
      headerName: 'irbpnr', field: 'DETFAC_IRBPNR', hide: true, cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      editable: (params) => {
        return false;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // GSRF
      headerName: 'Total irbpnr', field: 'DETFAC_VALORIRBPNR', hide: true, cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      editable: (params) => {
        return false;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // CDPJ
      headerName: '%IVA', field: 'DETFAC_PORIVA', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true,
          opcionbuscar: true
        }
      }
    }
  ];

  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' }
    },
  ];

  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha Vence', field: 'TRNCOBRO_FECHAVENCE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' }
    },
  ];

  columnDefsInfoAdic = [
    {
      headerName: 'Nombre', field: 'INFAD_CODIGO', cellEditor: 'cellPrueba', width: 200,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 300
        }
      }
    },
    {
      headerName: 'Información Adicional', field: 'INFAD_DESCRIPCION', cellEditor: 'cellPrueba', width: 400,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 300
        }
      }
    }
  ];

  columnDefsTopProductos = [
    {
      headerName: 'Producto', field: 'DETFAC_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', width: 190, editable: false
    },
    {
      headerName: 'Cantidad', field: 'CANTIDAD_TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFAC_PRECIO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Stock', field: 'STOCK', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsDetFacturas = [
    {
      headerName: 'Fecha', field: 'ENCFAC_FECHAEMISION', width: 90, editable: false
    },
    {
      headerName: 'Factura', field: 'ENCFAC_NUMERO', width: 140, editable: false
    },
    {
      headerName: 'Producto', field: 'DETFAC_CODIGO', width: 90, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', width: 100, editable: false
    },
    {
      headerName: 'Cantidad', field: 'DETFAC_CANTIDAD', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFAC_PRECIO', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'DETFAC_TOTAL', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //CDPJ
  columnDefsBasesIva = [
    {
      headerName: '%IVA', field: 'TARIIVA_DESCRIPCION', width: 115, editable: false
    },
    {
      headerName: 'Base', field: 'IVA_BASE', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Valor IVA', field: 'IVA_VALOR', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //CDPJ

  choosedColorExisMin: string;
  choosedColorPreBajoCosto: string;
  choosedColorTotalIncluEnDscto: string;//CDPJ
  choosedColorOrgPed: string;
  choosedColorOrgGre: string;
  disablediva: boolean = true;
  numfacntg: Number;

  constructor(public encfacService: VenencfacService, private busqService: InvbusquedaService,
    private calc: CalculosServiceService, private datePipe: DatePipe, private auditoriagral: AuditoriagralService,
    private messageService: MessageService, private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService, public cliService: VenmaeclienteService,
    private confIniciales: ConfInicialesService, private utilitariosService: UtilitariosService,
    private pagconservice: PagocontadoService, public init: NuevoComponentService,
    public route: Router, private permisosService: PermisosService,
    private venEstadisticasService: VenestadisticaService, public usuario: Usuario, private busquedaVeh: InvmaearticuloService) {
    this.agTable();
    this.detTopProductos = [];
    this.detFacturas = [];
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyle = (params) => {
      if (params.data.DETFAC_TIPODET === 'Y') {
        return { background: 'rgb(255, 204, 255)' };
      }
    };
    //GSRF
    this.rowStyle = (params) => {
      this.bolNuevodet = true
      console.log('******', this.detfacSelected)
      console.log(params.data.DETFAC_DESCRIPCION)
      if (this.detfacSelected !== undefined) {

        if (this.detfacSelected.DETFAC_TIPODET !== '*' && params.data.DETFAC_CODIGO !== '') {
          this.bolNuevodet = false;
          if (params.data.DETFAC_DESCRIPCION !== '') {
            return this.bolNuevodet = false;
          } else {
            return this.bolNuevodet = true;
          }

        } else if (this.detfacSelected.DETFAC_TIPODET === '*' && params.data.DETFAC_DESCRIPCION !== '') {
          return this.bolNuevodet = false;
        }
      } else {
        return this.bolNuevodet = false;
      }

    };
    //GSRF

    this.rowStyleEC = (params) => {
      if (params.data.TRNCOBRO_TIPO !== 'A') {
        if (params.data.TRNCOBRO_FECHATRN === '') {
          return { background: 'rgb( 255, 255, 170)' };
        } else {
          return { background: 'rgb( 196, 225, 255)' };
        }
      }
    };

    this.rowStyleSaldo = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb( 255, 255, 170)' };
      }
    };

    this.rowStyleTopProd = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.DETFAC_CODIGO === '' || params.data.DETFAC_CODIGO === null) {
        return { background: 'rgb( 164, 255, 167)' };
      }
    };

    this.rowStyleDetFacts = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'detfac',
      resizable: true
    };

    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen',
      resizable: true
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen',
      resizable: true
    };

    this.defaultColDefInfoAdic = {
      editable: true,
      width: 100,
      objeto: 'infoadicfac',
      resizable: true
    };

    this.defaultColTopProductos = {
      editable: true,
      width: 100,
      objeto: 'topProductos',
      resizable: true
    };

    this.defaultColDetFacturas = {
      editable: true,
      width: 100,
      objeto: 'detalleFacturas',
      resizable: true
    };
    //CDPJ
    this.defaultColBasesIva = {
      editable: false,
      width: 100,
      objeto: 'basesiva',
    };
    //CDPJ
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;

    this.encfacService.refrescarURL();
    this.index = 0;
    this.barraBotones2 = true;
    this.displayDialogfrmcorreos = false;
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonFactura = false;
    this.botonAnular = false;
    this.botonXML = false;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.spin = true;
    //this.bloqbdfac=false//GSRF

    this.encfacService.encfacarray[this.indicador] = {};
    console.log(this.encfacService.encfacarray[this.indicador])
    this.encfacService.detfacarray[this.indicador] = [];
    this.detfacSelected = {};
    this.largo = '-5800';
    this.largoestcta = '420';
    this.displayAcciones = false;
    this.bolNuevodet = false;

    this.auxiliar = 0;
    this.auxiliar1 = 0;
    this.auxcuotas = 0;
    this.bolNuevo = true;
    this.bolCliente = false;
    this.listarefrescada = false;
    this.numeroPedidos = [];
    this.numeroOrdtra = [];

    this.getConfiguracion();
    this.getPermisos();
    this.labelPromocion = false;
    this.comboOpciones = [{ label: 'A', value: 'A' },
    { label: 'S', value: 'S' }, { label: 'C', value: 'C' }, { label: '*', value: '*' }];
    this.asiento = '';
    this.bolestcta = false;
    this.bolsaldo = false;
    this.claveAuth = '';
    this.autorizacionCodigo = '';
    this.displayDialogAutorizacion = false;
    this.bolAutorizacion = false;

    this.claveSup = '';
    this.usuarioSup = '';
    this.strDialogo = '';
    this.strObjeto = '';
    this.intPestaniaCol = 0;
    this.displayDialogSupervisor = false;
    this.bolSupervisor = false;

    this.logResultado = '';

    this.choosedColorExisMin = 'rgb(128, 128, 255)';
    this.choosedColorPreBajoCosto = 'rgb(255, 98, 98)';
    this.choosedColorTotalIncluEnDscto = 'rgb(234, 250, 93)';
    this.choosedColorOrgPed = 'rgb(245,236,166)'
    this.choosedColorOrgGre = 'rgb(102,248,177)'

    this.tipodetalle = this.TIPDET === null || this.TIPDET === undefined ? 'A' : this.TIPDET;

    this.encfacService.leerConfiguracionDatosElectronicos().subscribe(data => {
      for (const rs of data) {
        this.datosfe = rs;
      }
    });
    //CDPJ
    this.boolfacturaElec = await this.encfacService.leerValidarRucsFacElec().catch(e => {

    });
    console.log(this.boolfacturaElec)
    //CDPJ
    this.iva = await this.confIniciales.obtenerImpuesto('I', 'A');
    this.ivavigente = this.iva;
    this.iva = Number(this.iva);

    await this.confIniciales.obtenerSrifeTariIva();
    await this.llenarcombo()//CDPJ
    await this.llenarCombos();
    await this.decimales();
    this.nuevaFactura();
    this.detTopProductos = [];
    this.detFacturas = [];
    //GSRF
    this.codigoPaisParaiso = '';
    this.codigoPaisRegimen = '';
    this.nombrePaisSeleccionado = '';
    this.nombrePaisSeleccionado1 = '';
    this.opcionesExportacion = [];
    this.opcionesRegimen = [{ label: '', value: '' }, { label: '01-Regimen General', value: '01' }, { label: '02-Paraiso Fiscal', value: '02' },
    { label: '03-Regimen Físcal preferente juridicción de menos imposición', value: '03' }];
    this.opcionesExportacion = [{ label: '', value: '' }, { label: '01-Con Refrendo', value: '01' }, { label: '02-Sin Refrendo', value: '02' },
    { label: '03-Exportación de Servicios u Otros Ingresos del exterior(incluye activos intangibles)', value: '03' }];
    this.opcionesIngresoExterior = [{ label: '', value: '' }, { label: 'SI', value: 'SI' }, { label: 'NO', value: 'NO' }];
    this.booleanRegimen = true;
    this.booleanParaiso = true;
    this.booleanRegimenP = true;
    this.booleanRegimenP1 = true;
    this.booleanRefrendo = true;
    this.opcionesDistrito = [];
    this.opcionesRegRefrendo = [];
    this.opcionesTipoIngresoExterior = [];
    this.edita = true;
    this.encfacService.obtenerDistrito().subscribe((res3) => {
      res3.map((distrito) => {
        let nuevoItem: any;
        nuevoItem = { label: distrito.CODSRI_CODIGO + '-' + distrito.CODSRI_DESCRIPCION, value: distrito.CODSRI_CODIGO };
        this.opcionesDistrito.push(nuevoItem);
      });
      this.opcionesDistrito.push({ label: '', value: '' });
    });
    this.encfacService.obtenerRegimenRefrendo().subscribe((res4) => {
      res4.map((regrefrendo) => {
        let nuevoItem: any;
        nuevoItem = { label: regrefrendo.CODSRI_CODIGO + '-' + regrefrendo.CODSRI_DESCRIPCION, value: regrefrendo.CODSRI_CODIGO };
        this.opcionesRegRefrendo.push(nuevoItem);
      });
      this.opcionesRegRefrendo.push({ label: '', value: '' });
    });
    this.encfacService.obtenerTipoIExterior().subscribe((res5) => {
      res5.map((tipoIext) => {
        let nuevoItem: any;
        nuevoItem = { label: tipoIext.CODSRI_CODIGO + '-' + tipoIext.CODSRI_DESCRIPCION, value: tipoIext.CODSRI_CODIGO };
        this.opcionesTipoIngresoExterior.push(nuevoItem);
      });
      this.opcionesTipoIngresoExterior.push({ label: '', value: '' });
      this.spin = false;
    });
    this.expo = false;
    //GSRF

  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciaFacturas--;
  }
  //CDPJ

  async decimales() {
    this.confIniciales.getDecimales('frmVEN_ENCFAC').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
            this.encfacService.decimalesCantidadFac = Number(decimales.NUMDEC_NUMDEC);
          } else if (decimales.NUMDEC_CAMPO === 'PRECIO') {
            this.encfacService.decimalesPrecioFac = Number(decimales.NUMDEC_NUMDEC);
          } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
            this.encfacService.decimalesTotalFac = Number(decimales.NUMDEC_NUMDEC);
          } else if (decimales.NUMDEC_CAMPO === 'DESCUENTO') {
            this.encfacService.decimalesDescFac = Number(decimales.NUMDEC_NUMDEC);
          }
        });

        this.columnDefsDetfac[6].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encfacService.decimalesCantidadFac,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.columnDefsDetfac[7].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encfacService.decimalesPrecioFac,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.columnDefsDetfac[8].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encfacService.decimalesDescFac,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.columnDefsDetfac[9].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encfacService.decimalesTotalFac,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.aggrid.refreshColumnDefs();
      }
    });

  }

  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoFactura = data[0];
        } else {
          this.permisoFactura = null;
        }
      } else {
        this.permisoFactura = null;
      }
    } catch (e) {
      this.permisoFactura = null;
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
      this.spin = false;
    }
  }


  async changeTabs() {
    if (this.index > 0) {
      this.botonNuevo = true;
      this.botonGuardar = true;
      this.botonBorrar = true;
      this.botonRegresar = true;
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
    } else {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = true;
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }
    if (this.index === 1) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.cliService.getInfoEstadoCuenta(this.encfacService.encfacarray[this.indicador].CLI_CODIGO,
          'encfac');
      }
    }
    if (this.index === 2) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.cliService.getInfoSaldos(this.encfacService.encfacarray[this.indicador].CLI_CODIGO,
          'encfac');
      }
    }
    if (this.index === 4) {
      this.infoadicional(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
    }
    if (this.index === 7) {
      this.dataVentasAcum = {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ]
      };

      this.totalDocumentos = '';
      this.totalChPosfechadosPorc = '';
      this.totalDocVencidosPorc = '';
      this.totalDocXCobrar = '';
      this.totalDocXCobrarPorc = '';
      this.totalChPosfechados = '';
      this.totalDocVencidos = '';
      this.totalPedPendientes = '';
      this.totalPedSatisfechos = '';
      this.fechaPrimeraFact = '';
      this.detTopProductos = [];
      this.detFacturas = [];
      this.totalChProtestados = '';

    } else {

    }
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'CLI_CODIGO': {
        this.busquedacliente(event.target.value);
        break;
      }
      case 'VEN_CODIGO': {
        this.busquedavendedor(event.target.value);
        break;
      }
      case 'ENCFAC_REFERENCIA': {
        element = document.getElementById('ENCFAC_COMENTARIO');
        break;
      }
      case 'ENCFAC_COMENTARIO': {
        this.nuevodetalle();
        break;
      }
      case 'ENCFAC_DIASCRDT': {
        this.calcularFechaVencimiento();
        element = document.getElementById('ENCFACPRO_NUMINGRESO');
        break;
      }///GSRF
      case 'COMERCIOEXTERIOR': {
        element = document.getElementById('INCOTERMFACTURA');
        break;
      }
      case 'INCOTERMFACTURA': {
        element = document.getElementById('LUGARINCOTERM');
        break;
      }
      case 'LUGARINCOTERM': {
        element = document.getElementById('PAISORIGEN');
        break;
      }
      case 'PAISORIGEN': {
        element = document.getElementById('PUERTOEMBARQUE');
        break;
      }
      case 'PUERTOEMBARQUE': {
        element = document.getElementById('PUERTODESTINO');
        break;
      }
      case 'PUERTODESTINO': {
        element = document.getElementById('PAISDESTINO');
        break;
      }
      case 'PAISDESTINO': {
        element = document.getElementById('PAISADQUISICION');
        break;
      }
      case 'PAISADQUISICION': {
        element = document.getElementById('DIRCOMPRADOR');
        break;
      }
      case 'DIRCOMPRADOR': {
        element = document.getElementById('INCOTERMTOTALSINIMPUESTOS');
        break;
      }
      case 'INCOTERMTOTALSINIMPUESTOS': {
        element = document.getElementById('FLETEINTERNACIONAL');
        break;
      }
      case 'FLETEINTERNACIONAL': {
        element = document.getElementById('SEGUROINTERNACIONAL');
        break;
      }
      case 'SEGUROINTERNACIONAL': {
        element = document.getElementById('GASTOSADUANEROS');
        break;
      }
      case 'GASTOSADUANEROS': {
        element = document.getElementById('GASTOSTRANSPORTEOTROS');
        break;
      }
      case 'GASTOSTRANSPORTEOTROS': {
        element = document.getElementById('FORMAPAGO');
        break;
      }
      case 'DENOEXPCLI': {
        element = document.getElementById('paisResid');
        break;
      }
      case 'VALORIMPEXT': {
        element = document.getElementById('VALORFOB');
        break;
      }
      case 'VALORFOB': {
        element = document.getElementById('FECHAEMBARQUE');
        break;
      }
      case 'FECHAEMBARQUE': {
        element = document.getElementById('doctran');
        break;
      }
      case 'doctran': {
        element = document.getElementById('ANIO');
        break;
      }
      case 'ANIO': {
        element = document.getElementById('CORRELATIVO');
        break;
      }
      case 'CORRELATIVO': {
        element = document.getElementById('VALORFOBCOMPROBANTE');
        break;
      }
      ///GSRF
      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }

  }

  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
    this.disabledEstadisticas = true;
  }


  async busquedacliente(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'cliente';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RucIde', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'CLI_ZONA' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'NombreC', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.encfacService.erpBusquedaCliente(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    //this.spin = true;
    this.busqService.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
      this.spin = true;
      this.arregloCons = datos;
      // this.spin = false;
      this.displayDialogBusquedaFast = true;
      this.spin = false;
    });

  }

  busquedavendedor(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'vendedor';
    this.types = [
      { label: 'Código', value: 'VEN_CODIGO' },
      { label: 'Nombre', value: 'VEN_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.tabla = 'VEN_MAEVENDEDOR';
    this.where = '';

    this.encfacService.erBusquedaVendedor(this.types[1].value, this.tabla, this.types[0].value, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.encfacService.encfacarray[this.indicador].VEN_NOMBRE = eR[0].VEN_NOMBRE;
          document.getElementById('ENCFAC_REFERENCIA').focus();
        } else {
          this.spin = true;
          this.busqService.busquedaVenMaeVendedor3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.spin = false;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.spin = true;
        this.busqService.busquedaVenMaeVendedor3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.spin = false;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  rdbtipodet(params) {
    this.tipodetalle = params;
  }

  async selecciondetfac(params) {

    if (params === 'nuevo') {
      this.bolNuevodet = true;
      this.nuevodetalle();
      return;
    }

    if (params === 'cancelar') {
      this.bolNuevodet = false;
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.bolNuevodet = false;
      this.eliminarLineaDetalle();
      return;
    }

    if (params === 'agPed') {
      this.bolNuevodet = false;
      this.agregarPedido();
      return;
    }

    if (params === 'agGui') {
      this.bolNuevodet = false;
      this.agregarGuia();
      return;
    }

    if (params === 'agOT') {
      this.bolNuevodet = false;
      this.agregarOrdenTrabajo();
      return;
    }

    if (params === 'existenciasBod') {
      console.log(this.detfacSelected)
      this.txtArtUnidad = this.selectlistasprecio.codigo;
      this.txtListaPrecio = this.selectlistasprecio.codigo;
      this.txtIva = this.selectcombtipoIva.codigo;
      this.txtBodegaRes = this.selectbodega.codigo;

      this.txtObservacionRes = this.encfacService.encfacarray[this.indicador].CLI_CODIGO + '-' + this.encfacService.encfacarray[this.indicador].CLI_NOMBRE;

      if (this.detfacSelected === undefined) {

        this.txtArtCodigo = '';
        this.txtArtDescripcion = '';
        //  this.displayDialogExistBod = true;

      } else if (Object.keys(this.detfacSelected).length > 0) {

        if (this.detfacSelected.DETFAC_CODIGO === '' || this.detfacSelected.DETFAC_TIPODET === '*'
          || this.detfacSelected.DETFAC_TIPODET === 'S' || this.detfacSelected.DETFAC_TIPODET === 'C') {
          this.txtArtCodigo = '';
          this.txtArtDescripcion = '';
          this.displayDialogExistBod = true;
        } else {
          this.txtArtCodigo = this.detfacSelected.DETFAC_CODIGO;
          this.txtArtDescripcion = this.detfacSelected.DETFAC_DESCRIPCION;
          this.displayDialogExistBod = true;
        }
      }
    }

    ///////////////////////////vehiculo

    if (params === 'verInfoArtVeh') {
      this.bolNuevodet = false;
      console.log(this.detfacSelected);
      this.ART_CODIGO = '';
      this.ART_NOMBRE = '';
      this.ART_RAMV = '';
      this.ART_MODELOSRI = '';
      this.ART_MODELOAT = '';
      this.ART_MARCAV = '';
      this.ART_CLASE = '';
      this.ART_TIPOV = '';
      this.ART_CILINDRAJE = '';
      this.ART_ANIO = '';
      this.ART_TONELAJE = '';
      this.ART_COLOR = '';
      this.ART_PORIGEN = '';
      this.ART_MOTOR = '';
      this.ART_SERIEV = '';
      this.ART_NRUEDAS = '';
      this.ART_NEJES = '';
      this.ART_CAPACIDAD = '';
      this.ART_COMBUSTIBLE = '';
      this.ART_BANCO = '';
      this.ART_DPAGO = '';
      this.ART_FFABRICA = '';
      this.ART_DISPOSITIVO = '';
      this.ART_MARVEH = '';
      this.ART_CLAVEH = '';
      this.ART_TIPVEH = '';
      this.ART_COMVEH = '';
      this.ART_DISVEH = '';
      this.ART_P = '';

      if (this.detfacSelected.DETFAC_TIPODET === 'A') {
        this.busquedaVeh.getArticulo(this.detfacSelected.DETFAC_CODIGO).subscribe(async (res) => {
          for (const i of res) {
            this.ART_CODIGO = i.ART_CODIGO === null ? '' : i.ART_CODIGO;
            this.ART_NOMBRE = i.ART_NOMBRE === null ? '' : i.ART_NOMBRE;
            this.ART_RAMV = i.ART_RAMV === null ? '' : i.ART_RAMV;
            this.ART_MODELOSRI = i.ART_MODELOSRI === null ? '' : i.ART_MODELOSRI;
            this.ART_MODELOAT = i.ART_MODELOAT === null ? '' : i.ART_MODELOAT;
            this.ART_MARCAV = i.ART_MARCAV === null ? '' : i.ART_MARCAV;
            this.ART_CLASE = i.ART_CLASE === null ? '' : i.ART_CLASE;
            this.ART_TIPOV = i.ART_TIPOV === null ? '' : i.ART_TIPOV;
            this.ART_CILINDRAJE = i.ART_CILINDRAJE === null ? '' : i.ART_CILINDRAJE;
            this.ART_ANIO = i.ART_ANIO === null ? '' : i.ART_ANIO;
            this.ART_TONELAJE = i.ART_TONELAJE === null ? '' : i.ART_TONELAJE;
            this.ART_COLOR = i.ART_COLOR === null ? '' : i.ART_COLOR;
            this.ART_PORIGEN = i.ART_PORIGEN === null ? '' : i.ART_PORIGEN;
            this.ART_MOTOR = i.ART_MOTOR === null ? '' : i.ART_MOTOR;
            this.ART_SERIEV = i.ART_SERIEV === null ? '' : i.ART_SERIEV;
            this.ART_NRUEDAS = i.ART_NRUEDAS === null ? '' : i.ART_NRUEDAS;
            this.ART_NEJES = i.ART_NEJES === null ? '' : i.ART_NEJES;
            this.ART_CAPACIDAD = i.ART_CAPACIDAD === null ? '' : i.ART_CAPACIDAD;
            this.ART_COMBUSTIBLE = i.ART_COMBUSTIBLE === null ? '' : i.ART_COMBUSTIBLE;
            this.ART_BANCO = i.ART_BANCO === null ? '' : i.ART_BANCO;
            this.ART_DPAGO = i.ART_DPAGO === null ? '' : i.ART_DPAGO;
            this.ART_FFABRICA = i.ART_FFABRICA === null ? '' : this.datePipe.transform(i.ART_FFABRICA, 'dd/MM/yyyy');
            this.ART_DISPOSITIVO = i.ART_DISPOSITIVO === null ? '' : i.ART_DISPOSITIVO;

            if (i.ART_MARCAV !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_MARCAV, 'MARVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_MARVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_CLASE !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_CLASE, 'CLAVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_CLAVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_TIPOV !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_TIPOV, 'TIPVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_TIPVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_COMBUSTIBLE !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_COMBUSTIBLE, 'COMVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_COMVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_DISPOSITIVO !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_DISPOSITIVO, 'DISVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_DISVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_PORIGEN !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_PORIGEN, 'P').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_P = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_BANCO !== null) {
              this.cliService.erNombreBanco(i.ART_BANCO).subscribe((formsricom) => {
                if (formsricom !== null) {
                  if (formsricom[0] !== undefined) {
                    this.ART_NBANCO = formsricom[0].BANCLI_NOMBRE;
                  }
                }
              });
            }
            if (i.ART_RAMV !== null) {
              this.displayvehiculo = true;
            } else {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Detalle Vehículo',
                detail: 'El articulo seleccionado no es un vehículo.'
              });
            }
          }
        });

      }
    }


    if (params.object !== undefined) {
      if (params.object !== this.detfacSelected) {
        this.listarefrescada = false;
      }
      this.detfacSelected = params.object;
      console.log('params', params.object)
      this.obtenerCantidadFisica(this.detfacSelected);
      this.desplegarPrecio(this.detfacSelected);
      this.tipodetalle = this.detfacSelected.DETFAC_TIPODET;
      this.multiunidad();
      if (this.listarefrescada === false) {
        this.listaprecios();
      }
    }
  }

  async abrirLotes(params) {
    const detalle = params;
    if ((this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length > 0
      && detalle.ART_CADUCA === 'S') === true) {
      if (detalle.ART_UNIDADVENTA !== detalle.DETFAC_UNIDAD) {
        const data: any = await this.utilitariosService.getFactorcantidad(detalle.ART_UNIDADVENTA, detalle.DETFAC_UNIDAD);
        let factor = '';
        let cant = '';
        for (const rs of data) {
          factor = rs.EQU_FACTOR;
          cant = rs.EQU_CANTIDAD;
        }
        if (factor === '*') {
          detalle.DETFAC_CANTIDADB = Number(detalle.DETFAC_CANTIDAD) * Number(cant);
        } else {
          detalle.DETFAC_CANTIDADB = Number(detalle.DETFAC_CANTIDAD) / Number(cant);
        }
      }
      this.detfacSelected = detalle;
      this.auxiliarlot++;
      this.displayDialogLotes = true;
    }
  }

  abrirDatosVentaArticulo() {
    this.closeSidebar();

    this.displayDialogDatosVentaArticulo = false;
    if (this.detfacSelected !== null && this.detfacSelected !== undefined) {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length > 0 && this.detfacSelected.DETFAC_CODIGO.length > 0) {
        this.displayDialogDatosVentaArticulo = true;
      }
    }
  }

  agregarPedido() {
    if (this.encfacService.encfacarray[this.indicador].BOD_CODIGO.length === 0) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Seleccione la bodega.'
      });
      return;
    }

    const NUMGUAFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMGUAFAC').CFG_VALOR1;
    if (NUMGUAFAC === 0) {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length === 0) {
        this.messageService.add({
          key: 'encfac',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese el número de la factura.'
        });
        return;
      }
    }

    if (this.encfacService.encfacarray[this.indicador].CLI_CODIGO.length > 0) {
      this.buscarPedido();
    } else {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese el cliente.'
      });
    }
  }

  agregarGuia() {
    if (this.encfacService.encfacarray[this.indicador].BOD_CODIGO.length === 0) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Seleccione la bodega.'
      });
      return;
    }

    const NUMGUAFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMGUAFAC').CFG_VALOR1;
    if (NUMGUAFAC === 0) {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length === 0) {
        this.messageService.add({
          key: 'encfac',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese el número de la factura.'
        });
        return;
      }
    }

    if (this.encfacService.encfacarray[this.indicador].CLI_CODIGO.length > 0) {
      this.buscarGuia();
    } else {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese el cliente.'
      });
    }
  }

  agregarSeriales() {
    this.strNumFact = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
    this.strFechaSer = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    this.displayDialogSeriales = true;

    this.closeSidebar();
  }

  buscarPedido() {
    const strFecha = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');

    let strCondicion = ' COM_CODIGO = \'01\'';
    strCondicion = strCondicion + ' AND ENCPED_ESTADO = \'P\'';
    strCondicion = strCondicion + ' AND CLI_CODIGO = \''
      + this.encfacService.encfacarray[this.indicador].CLI_CODIGO + '\'';
    strCondicion = strCondicion
      + ' AND TO_DATE(TO_CHAR(ENCPED_FECHAVALIDEZ,\'DD/MM/YYYY\'),\'DD/MM/YYYY\')>= TO_DATE(\''
      + strFecha + '\',\'DD/MM/YYYY\')';
    strCondicion = strCondicion + ' AND ENCPED_FECHAEMISION <= TO_DATE(\''
      + strFecha + '\',\'DD/MM/YYYY\')';

    this.opcionbusqueda = 'agPed';
    this.types = [
      { label: 'NUMERO', value: 'ENCPED_NUMERO' },
      { label: 'COMPROBANTE', value: 'ENCPED_REFERENCIA' },
      { label: 'FEC. EMISIÓN', value: 'ENCPED_FECHAEMISION' },
      { label: 'FEC. ENTREGA', value: 'ENCPED_FECHAENTREGA' },
      { label: 'ESTADO', value: 'ENCPED_ESTADO' },
      { label: 'NOMBRE', value: 'NOMBRE' },
      { label: 'CLIENTE', value: 'CLI_CODIGO' },
      { label: 'VENDEDOR', value: 'VEN_CODIGO' },
      { label: 'OBSERVACIÓN', value: 'ENCPED_OBSERVACION' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCPED A';
    this.where = strCondicion;
    //this.spin = true;
    this.busqService.busquedaVenEncped2(strCondicion).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.ENCPED_FECHAEMISION !== null) {
            registro.ENCPED_FECHAEMISION = this.datePipe.transform(registro.ENCPED_FECHAEMISION, 'dd/MM/yyyy');
          } else {
            registro.ENCPED_FECHAEMISION = '';
          }
          if (registro.ENCPED_FECHAENTREGA !== null) {
            registro.ENCPED_FECHAENTREGA = this.datePipe.transform(registro.ENCPED_FECHAENTREGA, 'dd/MM/yyyy');
          } else {
            registro.ENCPED_FECHAENTREGA = '';
          }
        });
      }
      //this.spin = false;
      this.displayDialogBusquedaFast = true;
    });

    this.closeSidebar();
  }

  buscarGuia() {
    const strFecha = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');

    let strCondicion = ' COM_CODIGO = \'01\'';
    strCondicion = strCondicion + ' AND ENCGRE_ESTADO = \'P\'';
    strCondicion = strCondicion + ' AND CLI_CODIGO = \''
      + this.encfacService.encfacarray[this.indicador].CLI_CODIGO + '\'';
    strCondicion = strCondicion
      + ' AND TO_DATE(ENCGRE_FECHAEMISION,\'DD/MM/YYYY\') <= TO_DATE(\''
      + strFecha + '\',\'DD/MM/YYYY\')';
    if (this.DSNEBINGR === 1) {
      strCondicion = strCondicion + ' AND  NVL(SUBSTR(ENCGRE_NUMERO,0,2),\'Z\')=\'NE\''
    }

    this.opcionbusqueda = 'agGui';
    this.types = [
      { label: 'CÓDIGO', value: 'ENCGRE_CODIGO' },
      { label: 'NUMERO', value: 'ENCGRE_NUMERO' },
      { label: 'FEC. EMISIÓN', value: 'ENCGRE_FECHAEMISION' },
      { label: 'TRANSPORTISTA', value: 'ENCGRE_NMBRTRNSP' },
      { label: 'CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE', value: 'NOMBRE' },
      { label: 'TOTAL', value: 'ENCGRE_TOTAL' },
      { label: 'FACTURA', value: 'ENCFAC_NUMERO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCGRE A';
    this.where = strCondicion;

    this.spin = true;
    this.busqService.busquedaVenEncgre1(strCondicion).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.ENCGRE_FECHAEMISION !== null) {
            registro.ENCGRE_FECHAEMISION = this.datePipe.transform(registro.ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
          } else {
            registro.ENCGRE_FECHAEMISION = '';
          }
        });
      }
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  seleccioninfoadic(params) {

    if (params === 'nuevo') {
      this.nuevodetalle();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalle();
      return;
    }

    if (params.object !== undefined) {
      this.infoAdicSelected = params.object;
    }

  }

  cancelardetalle() {
    if (this.index === 0) {
      if (this.detfacSelected.DETFAC_CODIGO === '' && this.detfacSelected.DETFAC_TIPODET !== '*') {
        this.encfacService.detfacarray[this.indicador].splice(this.encfacService.detfacarray[this.indicador].findIndex(
          detfac => detfac.DETFAC_LINEA === this.detfacSelected.DETFAC_LINEA
            && detfac.DETFAC_LINEA_3 === this.detfacSelected.DETFAC_LINEA_3),
          1);
        if (this.encfacService.detfacarray[this.indicador].length > 0) {
          this.detfacSelected = this.encfacService.detfacarray[this.indicador][this.encfacService.detfacarray[this.indicador].length - 1];
        }
        this.encfacService.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
      }
    }

    if (this.index === 4) {
      this.encfacService.consultarInfoAdicional(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(ia => {
        this.veninfoadicfac = ia;
        this.aggrid.refreshaggrid(this.veninfoadicfac, this.defaultColDefInfoAdic.objeto);
      });
    }

  }

  eliminarUltimaLineaDetalle() {
    if (this.encfacService.detfacarray[this.indicador].length > 0) {
      if ((this.encfacService.detfacarray[this.indicador][this.encfacService.detfacarray[this.indicador].length - 1].DETFAC_CODIGO === ''
        || this.encfacService.detfacarray[this.indicador][this.encfacService.detfacarray[this.indicador].length - 1].DETFAC_CODIGO === null
        || this.encfacService.detfacarray[this.indicador][this.encfacService.detfacarray[this.indicador].length - 1].DETFAC_CODIGO === undefined)
        && this.encfacService.detfacarray[this.indicador][this.encfacService.detfacarray[this.indicador].length - 1].DETFAC_TIPODET !== '*') {
        this.encfacService.detfacarray[this.indicador].splice(this.encfacService.detfacarray[this.indicador].length - 1, 1);
        this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
      }
    }
  }

  eliminarLineaDetalle() {
    if (this.index === 0) {
      if (this.detfacSelected !== undefined && this.encfacService.detfacarray[this.indicador].length > 0) {
        this.confirmationService.confirm({
          message: 'Está seguro de eliminar el registro?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarencfac',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            if (this.detfacSelected.DETFAC_LINEA_3 !== 0) {
              this.encfacService.eliminarDetalleFact(this.detfacSelected).subscribe(() => {
                this.encfacService.detfacarray[this.indicador].splice(this.encfacService.detfacarray[this.indicador].findIndex(
                  detfac => detfac.DETFAC_LINEA === this.detfacSelected.DETFAC_LINEA),
                  1);
                this.messageService.add({
                  key: 'encfac',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'El detalle se eliminó correctamente'
                });
                //CDPJ
                this.auditoriagral.registrarAuditoria('VEN_DETFAC',
                  this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/' + this.detfacSelected.DETFAC_CODIGO + '/VEN', 'E',
                  '', '01', '', '', '', '').subscribe();
                //CDPJ
                if (this.encfacService.detfacarray[this.indicador].length > 0) {
                  this.detfacSelected = this.encfacService.detfacarray[this.indicador][this.encfacService.detfacarray[this.indicador].length - 1];
                }

                this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
                this.encfacService.calcularTotales();
                if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO !== '') {
                  this.guardar();
                }

              });
            } else {
              this.encfacService.detfacarray[this.indicador].splice(this.encfacService.detfacarray[this.indicador].findIndex(
                detfac => detfac.DETFAC_LINEA === this.detfacSelected.DETFAC_LINEA
                  && detfac.DETFAC_LINEA_3 === this.detfacSelected.DETFAC_LINEA_3),
                1);
              this.messageService.add({
                key: 'encfac',
                severity: 'success',
                summary: 'Información',
                detail: 'El detalle se eliminó correctamente'
              });
              if (this.encfacService.detfacarray[this.indicador].length > 0) {
                this.detfacSelected = this.encfacService.detfacarray[this.indicador][this.encfacService.detfacarray[this.indicador].length - 1];
              }


              this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
              this.encfacService.calcularTotales();
            }
            const itemdisponibles = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES);
            this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES = itemdisponibles + 1;
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      }
    }

    if (this.index === 4) {
      if (this.infoAdicSelected !== undefined && this.veninfoadicfac.length > 0) {
        this.confirmationService.confirm({
          message: 'Está seguro de eliminar el registro?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarencfac',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            if (this.infoAdicSelected.INFAD_NUMSEC !== 0) {
              this.encfacService.eliminarInfoAdicFac(this.infoAdicSelected).subscribe(() => {
                this.auditoriagral.registrarAuditoria('VEN_INFOADICFAC',
                  this.infoAdicSelected.ENCFAC_NUMERO + '/' + this.infoAdicSelected.INFAD_CODIGO, 'E',
                  '', '01', '', '', '', '').subscribe();
                this.veninfoadicfac.splice(this.veninfoadicfac.findIndex(
                  infadi => infadi.INFAD_NUMSEC === this.infoAdicSelected.INFAD_NUMSEC),
                  1);
                this.messageService.add({
                  key: 'encfac',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'La información adicional se eliminó correctamente'
                });
                if (this.veninfoadicfac.length > 0) {
                  this.infoAdicSelected = this.veninfoadicfac[this.veninfoadicfac.length - 1];
                }
                this.aggrid.refreshaggrid(this.veninfoadicfac, this.defaultColDefInfoAdic.objeto);
              });
            } else {
              this.veninfoadicfac.splice(this.veninfoadicfac.findIndex(
                infadi => infadi.INFAD_NUMSEC === this.infoAdicSelected.INFAD_NUMSEC),
                1);
              this.messageService.add({
                key: 'encfac',
                severity: 'success',
                summary: 'Información',
                detail: 'El detalle se eliminó correctamente'
              });
              if (this.veninfoadicfac.length > 0) {
                this.infoAdicSelected = this.veninfoadicfac[this.veninfoadicfac.length - 1];
              }
              this.aggrid.refreshaggrid(this.veninfoadicfac, this.defaultColDefInfoAdic.objeto);
            }
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      }
    }
  }

  nuevodetalle() {
    if (this.index === 0) {
      if (this.encfacService.encfacarray[this.indicador].CLI_CODIGO !== undefined) {
        if (this.encfacService.encfacarray[this.indicador].CLI_CODIGO.length === 0) {
          this.messageService.add({
            key: 'encfac',
            severity: 'warn',
            summary: 'Información',
            detail: 'Ingrese el cliente.'
          });
          return;
        }
      } else {
        this.messageService.add({
          key: 'encfac',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese el cliente.'
        });
        return;
      }
      if (this.encfacService.encfacarray[this.indicador].CLI_NOMBRE === '') {
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Información',
          detail: 'Presione enter para verificar el cliente'
        });
        this.bolNuevodet = false;
        return;
      }

      this.crearNuevoItem();
      const detfac: VenDetfacZ = this.nuevoItem;
      const indi = this.encfacService.detfacarray[this.indicador].indexOf(this.detfacSelected);

      if (indi === -1) {
        this.encfacService.detfacarray[this.indicador].push(detfac);
        this.detfacSelected = detfac;
        this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
        if (this.detfacSelected.DETFAC_TIPODET !== '*') {
          if (this.encfacService.detfacarray[this.indicador].length === 1) {
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
              this.defaultColDef.objeto].setFocusedCell(0, 'DETFAC_CODIGO');
          } else {
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
              this.defaultColDef.objeto].setFocusedCell((this.encfacService.detfacarray[this.indicador].length) - 1, 'DETFAC_CODIGO');
          }
        } else {
          this.detfacSelected.DETFAC_PORIVA = 0;
          this.detfacSelected.TARIIVA_CODIGO = '-1';
          if (this.encfacService.detfacarray[this.indicador].length === 1) {
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
              this.defaultColDef.objeto].setFocusedCell(0, 'DETFAC_DESCRIPCION');
          } else {
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
              this.defaultColDef.objeto].setFocusedCell((this.encfacService.detfacarray[this.indicador].length) - 1, 'DETFAC_DESCRIPCION');
          }
        }
      } else {
        this.encfacService.detfacarray[this.indicador].splice(indi + 1, 0, detfac);
        this.detfacSelected = detfac;
        this.aggrid.refreshaggridindex(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto, indi);
        if (this.detfacSelected.DETFAC_TIPODET !== '*') {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(indi + 1, 'DETFAC_CODIGO');
        } else {
          this.detfacSelected.DETFAC_PORIVA = 0;
          this.detfacSelected.TARIIVA_CODIGO = '-1';
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(indi + 1, 'DETFAC_DESCRIPCION');
        }
      }

      const itemdisponibles = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES);
      this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES = itemdisponibles - 1;
    }

    if (this.index === 4) {
      const iaf: VenInfoadicfac = {
        ENCFAC_NUMERO: this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO,
        COM_CODIGO: '01',
        INFAD_NUMSEC: 0,
        INFAD_CODIGO: '',
        INFAD_DESCRIPCION: ''
      };

      const indi = this.veninfoadicfac.indexOf(this.infoAdicSelected);
      if (indi === -1) {
        this.veninfoadicfac.push(iaf);
        this.infoAdicSelected = iaf;
        this.aggrid.refreshaggrid(this.veninfoadicfac, this.defaultColDefInfoAdic.objeto);
      } else {
        this.veninfoadicfac.splice(indi + 1, 0, iaf);
        this.infoAdicSelected = iaf;
        this.aggrid.refreshaggridindex(this.veninfoadicfac, this.defaultColDefInfoAdic.objeto, indi);
      }
    }
  }

  getConfiguracion() {
    try {
      this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
        this.hmpConfiguracion = conf;
        this.encfacService.hmpConfiguracion = conf;
        this.CONSIDNV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CONSIDNV').CFG_VALOR1;
        this.REFMAE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'REFMAE').CFG_VALOR1;
        this.REFLOCMED = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'REFLOCMED').CFG_VALOR1;
        this.PUNVTAS = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PUNVTAS').CFG_VALOR1;
        this.STIPIVACLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'STIPIVACLI').CFG_VALOR1;
        this.NVNOIVA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NVNOIVA').CFG_VALOR1;
        this.NUMITEMFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMITEMFAC').CFG_VALOR2;
        this.FACCC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACCC').CFG_VALOR1;
        this.FACLIGREQ = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACLIGREQ').CFG_VALOR1;
        this.FACCANUND = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACCANUND').CFG_VALOR1;
        const LISTPREC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LISTPREC').CFG_VALOR1;
        this.intACTPEDVAL = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ACTPEDVAL').CFG_VALOR1;
        //CDPJ
        this.CENREPDOCE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENREPDOCE').CFG_VALOR1;
        this.BLFHFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BLFHFAC').CFG_VALOR1;
        this.GUINOKARD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GUINOKARD').CFG_VALOR1;
        this.TIPDET = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'TIPDET').CFG_VALOR3;
        if (this.BLFHFAC === 1) {
          this.boolfeh = true;

        }
        this.descglobaconfig = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESGLOFP').CFG_VALOR1;
        this.CNAFEPV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CNAFEPV').CFG_VALOR1;
        this.ACCCFV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ACCCFV').CFG_VALOR1;
        this.DSNEBINGR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DSNEBINGR').CFG_VALOR1;
        //CDPJ
        if (this.FACCANUND !== 1) {
          this.columnDefsDetfac.splice(18, 1);
        }

        if (this.FACLIGREQ !== 1) {
          this.columnDefsDetfac.splice(13, 4);
        }

        if (this.FACCC !== 1) {
          this.columnDefsDetfac.splice(12, 1);
        }

        if (LISTPREC !== 1) {
          this.columnDefsDetfac[11] = { // 11
            headerName: 'Lista', field: 'DETFAC_LISTA', cellEditor: 'cellPrueba', width: 80,
            cellStyle: (params) => {
              const color = this.coloreaarDescrip(params.data);
              return { background: 'transparent'};
            },
            // , editable: true,
            cellEditorParams:
            {
              values: {
                mayusculas: true,
                numeros: true,
                alphabetic: true,
                saltoslinea: 1,
                newrow: false,
                numerodecimales: 0,
                obligatorio: true,
                fecha: false,
                clave: false,
                tamanocelda: 32
              }
            }
          };
        }
        this.aggrid.refreshColumnDefs();
      });
      this.strDialogo = 'frmVEN_ENCFAC';
      this.strObjeto = 'cmbBodega';


      this.encfacService.countSacimaeobj(this.strDialogo, this.strObjeto, this.encfacService.usuario.identificacion, 0).subscribe(eC => {
        for (const rs of eC) {
          if (rs.COUN > 0) {
            this.bloqbdfac = true;
          } else {
            this.bloqbdfac = false;
          }
        }
      });
    } catch (error) {
      console.log(error)
      this.spin = false;
    }

  }

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir'
      && valor !== 'facturasAbonadas') {
      // Verifica el periodo contable
      const dia = this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION.getDate();
      const mes = this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION.getMonth();
      const anio = this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION.getFullYear();


      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'encfac',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    if (this.index === 7) {
      return;
    }

    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;

    if (this.hmpConfiguracion === undefined ||
      this.encfacService.hmpConfiguracion === undefined) {
      this.getConfiguracion();
    }

    if (valor === 'Nuevo') {
      //GSRF
      const bloqbd = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACBLOBVD').CFG_VALOR1;
      if (bloqbd === 1) {
        this.bloqbdfac = true
        this.bloqdiasfac = true;
      }
      //GSRF
      this.bolNuevodet = false;
      this.nuevo();
      this.disabledEstadisticas = true;
    }
    if (valor === 'Guardar') {
      if (this.control === 0) {
        this.control = 1
        this.spin = true;
        await this.validarango();
        this.spin = false;
        this.guardar();
      }

    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      this.buscar();
    }
    if (valor === 'facturasAbonadas') {
      this.busquedafacturanofirmados();
    }
    if (valor === 'Descuento') {
      this.aplicarDescuentoGlobal();
    }
    if (valor === 'Pago') {
      this.Pago('contado');
    }
    if (valor === 'Credito') {
      this.Pago('credito');
    }
    if (valor === 'Asiento') {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === null) {
        this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO = '';
      }
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO.length > 0) {
        this.generarAsiento();
      } else {
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Información',
          detail: 'La factura no tiene forma de pago.'
        });
      }

    }
    if (valor === 'AsientoVer') {
      this.verAsiento();
    }
    if (valor === 'Anular') {
      //CDPJ
      if (this.botonGuardar === false) {
        this.messageService.add({
          key: 'encfac',
          severity: 'warn',
          summary: 'Información',
          detail: 'Guarde la factura antes de realizar la anulación.'
        });
        return;
      }
      //CDPJ
      this.anularRegistro();
    }
    if (valor === 'Firmar') {
      this.verificarExisteFP('', 'firmar');
    }
    if (valor === 'EnviarCorreo') {
      if (this.datosfe.COM_FEACTIVADA === '0') {
        return;
      }
      if (this.CENREPDOCE === 1) {
        if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== 'AT') {
          this.messageService.add({
            key: 'encfac',
            severity: 'info',
            summary: 'Información',
            detail: 'La factura debe estar en estado AT'
          });
          return
        }
        await this.despliegaopcionride();
        return
      }
      this.actualizarEstadoParaReenviarCorreo(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
    }
    if (valor === 'ClaseNumeracion') {
      this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO = '';
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Almacenardoc') {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'encfac',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    if (valor === 'Salir') {
      // this.encfacService.ACTPRUEBA().subscribe();
      this.verificarExisteFP('', 'salir');
    }

    this.closeSidebar();
    /*document.getElementById('contorno').style.pointerEvents = 'all';
    document.getElementById('contornodetalle').style.pointerEvents = 'all';*/
  }
  async despliegaopcionride() {
    this.strNombreForma = this.init.tabs[this.init.tabindex].component;
    this.auxreporte++;
    this.displayDialogfrmcorreos = true
  }
  cerrarfrmcorreos() {
    this.displayDialogfrmcorreos = false;
    this.cancelar();
  }
  generarAsiento() {
    // if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === null) {
    //   this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO = '';
    // }
    //if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO.length > 0) {
    //CDPJ
    let tip_dia = '';
    let trncobronrodoc = ''
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === 'T') {
      tip_dia = 'CI'
      this.pagconservice.erFindTransfer(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).pipe(finalize(() => {
        this.encfacService.generarAsientoContableSinCxc(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, tip_dia,
          trncobronrodoc).pipe(finalize(() => {
            this.encfacService.erGenerarAsiento(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(cen => {
              if (cen[0] !== undefined) {
                this.cancelar();
                this.encfacService.encfacarray[this.indicador].ASI_NRO = cen[0].ASI_NRO;
                this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
                  this.encfacService.encfacarray[this.indicador].ASI_NRO + '/VEN', 'I',
                  '', '01', '', '', '', '').subscribe();
                const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
                if (INGRAPFAC === 0) {// ingreso rapido de la factura
                  this.messageService.add({
                    key: 'encfac',
                    severity: 'success',
                    summary: 'Información',
                    detail: 'El Asiento Contable se generó correctamente'
                  });
                }
              }
            });
          })).subscribe(asi => {

          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
            this.messageService.add({
              key: 'pagcon',
              severity: 'error',
              summary: 'Asiento Contable',
              detail: mensaje
            });
            this.spin = false;
            //this.botonGuardar = false;

          });
      })).subscribe(formsricom => {
        trncobronrodoc = formsricom[0].TRNCOBRO_NRODOC;
      })

    } else if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === 'C') {
      tip_dia = 'AF'
      this.encfacService.generarAsientoContable(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, tip_dia,
        this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.substr(
          this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length - 7)).subscribe(() => {
            this.encfacService.erGenerarAsiento(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(cen => {
              if (cen[0] !== undefined) {
                this.cancelar();
                this.encfacService.encfacarray[this.indicador].ASI_NRO = cen[0].ASI_NRO;
                this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
                  this.encfacService.encfacarray[this.indicador].ASI_NRO + '/VEN', 'I',
                  '', '01', '', '', '', '').subscribe();
                const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
                if (INGRAPFAC === 0) {// ingreso rapido de la factura
                  this.messageService.add({
                    key: 'encfac',
                    severity: 'success',
                    summary: 'Información',
                    detail: 'El Asiento Contable se generó correctamente'
                  });
                }
              }
            });
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Asiento Contable',
              detail: mensaje
            });
          });
    }
    //CDPJ
    // this.encfacService.generarAsientoContable(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'AF',
    //   this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.substr(
    //     this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length - 7)).subscribe(() => {
    //       this.encfacService.erGenerarAsiento(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(cen => {
    //         if (cen[0] !== undefined) {
    //           this.cancelar();
    //           this.encfacService.encfacarray[this.indicador].ASI_NRO = cen[0].ASI_NRO;
    //           this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
    //             this.encfacService.encfacarray[this.indicador].ASI_NRO + '/VEN', 'I',
    //             '', '01', '', '', '', '').subscribe();
    //           const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
    //           if (INGRAPFAC === 0) {// ingreso rapido de la factura
    //             this.messageService.add({
    //               key: 'encfac',
    //               severity: 'success',
    //               summary: 'Información',
    //               detail: 'El Asiento Contable se generó correctamente'
    //             });
    //           }
    //         }
    //       });
    //     }, error1 => {
    //       let mensaje: string;
    //       mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
    //       this.messageService.add({
    //         key: 'encfac',
    //         severity: 'error',
    //         summary: 'Asiento Contable',
    //         detail: mensaje
    //       });
    //     });
    // } else {
    //   this.messageService.add({
    //     key: 'encfac',
    //     severity: 'error',
    //     summary: 'Información',
    //     detail: 'La factura no tiene forma de pago.'
    //   });
    // }
  }

  nuevo() {
    if (this.index === 0) {
      this.verificarExisteFP('', 'nuevo');
    }
  }

  buscar() {
    this.verificarExisteFP('', 'buscar');
  }

  async Pago(tipo) {
    if (await this.validarLotes() == false) {
      return;
    }

    this.ValidarSeriales(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, '', tipo);
  }

  irAnuevaFac() {
    this.bolNuevo = true;
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.control = 0
    this.limpiartablas();
    this.obtenerNumFact();

    const DESNROFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESNROFAC').CFG_VALOR1;
    const NUMGUAFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMGUAFAC').CFG_VALOR1;
    this.numfacntg = NUMGUAFAC;
    if (DESNROFAC === 1 || NUMGUAFAC === 1) {
      document.getElementById('ENCFAC_NUMERO').setAttribute('disabled', 'true');
    }

    this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION = new Date();
    this.encfacService.encfacarray[this.indicador].ENCFAC_HORAEMISION = new Date();
    this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAVENCIMIENTO = new Date();
    this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO = 'PENDIENTE';

    this.encfacService.dblPorcDescGlb = 0;
    this.encfacService.dblValorDescGlb = 0;
    this.encfacService.dblValorotrdscto = 0;//CDPJ
    const CONFINAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CONFINAUT').CFG_VALOR1;
    if (CONFINAUT === 1) {
      this.traerCodigoConsumidorFinal();
    }
    console.log('boo', this.bolean);
    if (this.displayDialogBusqueda === false && this.bolean === false) {
      document.getElementById('CLI_CODIGO').focus();
      document.getElementById('contorno').style.pointerEvents = 'all';
      // document.getElementById('contornodetalle').style.pointerEvents = 'all';
      this.aggrid.habilitarGrid();
    }

  }
  //CDPJ
  validarEncfacNumero() {
    var valor = this.encfacService.encfacarray[this.indicador];
    let index = '';
    if (this.numfacntg !== 1 && this.numfac !== 'G' &&
      this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO !== ''
      && this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO !== null
      && this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO !== undefined) {
      //console.log('entro 1.1');
      console.log('nemo', this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO)
      let index = valor.ENCFAC_NUMERO.toString().substring(valor.ENCFAC_NEMONICO.length, valor.ENCFAC_NUMERO.length);
      console.log('valor', index);
      console.log('tamaño', index.length)
      if (index.length !== 15) {
        return false;
      }
      else {
        return true;
      }
    } else {
      return true;
    }

  }
  //CDPJ
  async guardar() {
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO === '') { // Nuevo registro
      // Verifica los permisos
      if (this.permisoFactura !== null) {
        if (this.permisoFactura.PERTODO === 0) {
          if (this.permisoFactura.PERINSERCION === 0) {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.encfacService.usuario.identificacion
                + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
    } else { // Modifica registro
      // Verifica los permisos
      if (this.permisoFactura !== null) {
        if (this.permisoFactura.PERTODO === 0) {
          if (this.permisoFactura.PERACTUALIZACION === 0) {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.encfacService.usuario.identificacion
                + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
    }
    //CDPJ
    let numero = 0;
    const strNumFact = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
    if (strNumFact !== undefined && strNumFact !== null) {
      const num = await this.encfacService.existePagosCxCProm(strNumFact)//.subscribe(pagos => {
      if (num != null && num !== undefined) {
        for (const data1 of num) {
          numero = data1.NUMERO === null || data1.NUMERO === undefined ? 0 : data1.NUMERO;
        }
      }
      if (numero > 0) {
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Error',
          detail: 'La factura tiene registrado pagos.'
        });
        this.control = 0
        return;
      }
      //});
    }
    //CDPJ
    //GSRF
    let resp = await this.encfacService.getEncfac(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
    console.log(resp);
    console.log(this.encfacService.encfacarray[this.indicador].ENCFAC_TIPOFACTURA);
    console.log(this.SELECTIPOREGI);
    console.log(this.chkactivo);
    if (this.SELECTIPOREGI !== '') {
      if (resp !== null) {
        for (const data1 of resp) {
          if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO !== '' && data1.ENCFAC_TIPOFACTURA === '01' && (this.SELECTIPOREGI === undefined || this.SELECTIPOREGI === '')) {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Exportación nuevo',
              detail: 'Selecione el Tipo de regimen fiscal del exterior y llene todos lo campos'
            });
            this.control = 0
            return;
            //GSRF
          } else if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO !== '' && data1.ENCFAC_TIPOFACTURA === '01' && (this.SELECTEXPORTACIONDE === undefined || this.SELECTEXPORTACIONDE === '')) {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Exportación nuevo',
              detail: 'Selecione el Tipo de Exportación y llene todos lo campos'
            });
            this.control = 0
            return;
            //GSRF
          } else if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO !== '' && data1.ENCFAC_TIPOFACTURA === '01' && (this.encfacService.encfacarray[this.indicador].VALORFOB === undefined || this.encfacService.encfacarray[this.indicador].VALORFOB === '')) {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Exportación nuevo',
              detail: 'Ingrese Valor FOB'
            });
            this.control = 0
            return;
            //GSRF
          } else if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO !== '' && data1.ENCFAC_TIPOFACTURA === '01' && (this.encfacService.encfacarray[this.indicador].VALORFOBCOMPROBANTE === undefined || this.encfacService.encfacarray[this.indicador].VALORFOBCOMPROBANTE === '')) {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Exportación nuevo',
              detail: 'Ingrese Valor FOB del Comprobante Local'
            });
            this.control = 0
            return;
            //GSRF
          }

          if (data1.ENCFAC_TIPOFACTURA === '01' && this.SELECTIPOREGI === '01') {
            if (this.encfacService.encfacarray[this.indicador].codigoPaisRegimen === undefined || this.encfacService.encfacarray[this.indicador].codigoPaisRegimen === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese País al que se realiza el pago en régimen general'
              });
              this.control = 0
              return;
            }
          } else if (data1.ENCFAC_TIPOFACTURA === '01' && this.SELECTIPOREGI === '02') {
            if (this.encfacService.encfacarray[this.indicador].codigoPaisParaiso === undefined || this.encfacService.encfacarray[this.indicador].codigoPaisParaiso === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese País al que se realiza el pago paraiso fiscal'
              });
              this.control = 0
              return;
            }
          } else if (data1.ENCFAC_TIPOFACTURA === '01' && this.SELECTIPOREGI === '03') {
            if (this.encfacService.encfacarray[this.indicador].DENOEXPCLI === undefined || this.encfacService.encfacarray[this.indicador].DENOEXPCLI === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese Denominación del régimen fiscal'
              });
              this.control = 0
              return;
            } else if (this.encfacService.encfacarray[this.indicador].PAISEFECEXP === undefined || this.encfacService.encfacarray[this.indicador].PAISEFECEXP === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese País de residencia o establecimiento permanente a quien se efectua la exportación o ingreso del exterior'
              });
              this.control = 0
              return;
            }
          }

          console.log(this.SELECTINGGRAVADOEXT);
          if (data1.ENCFAC_TIPOFACTURA === '01' && this.SELECTEXPORTACIONDE === '01') {
            if (this.encfacService.encfacarray[this.indicador].DOCTRANSP === undefined || this.encfacService.encfacarray[this.indicador].DOCTRANSP === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese N° Documento de transporte'
              });
              this.control = 0
              return;
            } else if (this.encfacService.encfacarray[this.indicador].DISTADUANERO === undefined || this.encfacService.encfacarray[this.indicador].DISTADUANERO === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese Distrito'
              });
              this.control = 0
              return;
            } else if (this.encfacService.encfacarray[this.indicador].ANIO === undefined || this.encfacService.encfacarray[this.indicador].ANIO === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese Año'
              });
              this.control = 0
              return;
            } else if (this.encfacService.encfacarray[this.indicador].REGIMEN === undefined || this.encfacService.encfacarray[this.indicador].REGIMEN === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese Régimen'
              });
              this.control = 0
              return;
            } else if (this.encfacService.encfacarray[this.indicador].CORRELATIVO === undefined || this.encfacService.encfacarray[this.indicador].CORRELATIVO === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese Correlativo'
              });
              this.control = 0
              return;
            }
          } else if (data1.ENCFAC_TIPOFACTURA === '01' && this.SELECTEXPORTACIONDE === '03') {
            if (this.SELECTIPOINGRESO === undefined || this.SELECTIPOINGRESO === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese Tipo de Ingreso del exterior'
              });
              this.control = 0
              return;
            } if (this.SELECTINGGRAVADOEXT === undefined || this.SELECTINGGRAVADOEXT === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Exportación nuevo',
                detail: 'Ingrese El ingreso del exterior fue gravado con impuesto '
              });
              this.control = 0
              return;
            }
            if (this.SELECTINGGRAVADOEXT === undefined || this.SELECTINGGRAVADOEXT === 'SI') {
              if (this.encfacService.encfacarray[this.indicador].VALORIMPEXT === undefined || this.encfacService.encfacarray[this.indicador].VALORIMPEXT === '') {
                this.messageService.add({
                  key: 'encfac',
                  severity: 'error',
                  summary: 'Exportación nuevo',
                  detail: 'Ingrese Valor del impuesto a la renta o impuesto similar pagado en el exterior por el ingreso obtenido '
                });
                this.control = 0
                return;
              }
            }
          }

        }
      }
    }
    //GSRF
    const NOFCCERO = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOFCCERO').CFG_VALOR1;
    const DESNROFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESNROFAC').CFG_VALOR1;//CDPJ
    const NUMGUAFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMGUAFAC').CFG_VALOR1;//CDPJ
    this.eliminarUltimaLineaDetalle();
    if (this.encfacService.encfacarray[this.indicador].CLI_CODIGO === '') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'No existe un cliente'
      });
      this.control = 0
    }

    //GSRF 
    else if (this.encfacService.encfacarray[this.indicador].CLI_NOMBRE === '') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Presione enter para verificar el cliente'
      });
      this.control = 0
    }
    //GSRF 
    else if (this.encfacService.detfacarray[this.indicador].length === 0) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'No existe detalle'
      });
      this.control = 0
    } else if (this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION === '' ||
      this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION === null ||
      this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAVENCIMIENTO === '' ||
      this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAVENCIMIENTO === null) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Las fechas ingresadas son incorrectas'
      });
      this.control = 0
      //}
      //else if (this.validarEncfacNumero() === false) {
      //     this.messageService.add({
      //       key: 'encfac',
      //       severity: 'error',
      //       summary: 'Información',
      //       detail: 'El número de la factura debe tener 15 dígitos'
      //     });
      //     //return;
    } else if (this.vrango === true && DESNROFAC === 1 && NUMGUAFAC === 0) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'El número de la factura está fuera del rango de la numeración'
      });
      this.control = 0
    } else {

      // if (this.encfacService.detfacarray[this.indicador].length !== 0) {
      //   const NOGSCC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOGSCC').CFG_VALOR1;
      //   const CENXCTACON = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENXCTACON').CFG_VALOR1;
      //   let errores = 0;
      //   this.encfacService.detfacarray[this.indicador].map(async (fac) => {
      //     if (fac.DETFAC_TIPODET !== '*' && fac.DETFAC_TIPODET !== 'Y') {
      //       if (fac.BOD_CODIGO === '') {
      //         this.messageService.add({
      //           key: 'encfac',
      //           severity: 'error',
      //           summary: 'Información',
      //           detail: 'Ingrese una bodega al detalle de la factura'
      //         });
      //         errores++;
      //       } else if (Number(fac.DETFAC_CANTIDAD) <= 0) {
      //         this.messageService.add({
      //           key: 'encfac',
      //           severity: 'error',
      //           summary: 'Información',
      //           detail: 'Ingrese una cantidad valida al detalle de la factura'
      //         });
      //         errores++;
      //       } else if (Number(fac.DETFAC_PRECIO) === 0 && NOFCCERO === 1) {
      //         this.messageService.add({
      //           key: 'encfac',
      //           severity: 'error',
      //           summary: 'Información',
      //           detail: 'No puede existir items con total igual a cero'
      //         });
      //         errores++;
      //       } //GSRF
      //      else if (fac.DETFAC_TIPODET !== '*' && fac.DETFAC_CODIGO ===''){
      //       this.messageService.add({
      //         key: 'encfac',
      //         severity: 'error',
      //         summary: 'Información',
      //         detail: 'No puede existir items sin código de articulo'
      //       });
      //       errores++;
      //       //GSRF 
      //       //CDPJ
      //       }//CDPJ
      //       else if(this.numfacntg!==1){
      //         if (this.validarEncfacNumero() === false) {
      //        this.messageService.add({
      //          key: 'encfac',
      //          severity: 'error',
      //          summary: 'Información',
      //          detail: 'El número de la factura debe tener 15 números'
      //        });
      //        errores++;
      //      }//CDPJ
      //    }else
      //         // verifica si el centro de costos fue ingresado
      //         if (NOGSCC === 1) {
      //           if (fac.CEN_CODIGO === '') {
      //             this.messageService.add({
      //               key: 'encfac',
      //               severity: 'error',
      //               summary: 'Información',
      //               detail: 'Verifique el ingreso del centro de costos'
      //             });
      //             errores++;
      //           }
      //         } else
      //           // Verificar ingreso de centro de costos por item
      //           if (CENXCTACON === 1) {
      //             const strAplicaCentroCostos = await this.verificarCentroCostosCuenta(fac.DETFAC_CODIGO, fac.DETFAC_TIPODET);
      //             if (fac.CEN_CODIGO === '' && strAplicaCentroCostos === "Si") {
      //               this.messageService.add({
      //                 key: 'encfac',
      //                 severity: 'error',
      //                 summary: 'Información',
      //                 detail: 'Verifique el ingreso del centro de costos'
      //               });
      //               errores++;
      //             }
      //           }
      //     }
      //   });
      //   this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      //     this.defaultColDef.objeto].setFocusedCell(this.encfacService.detfacarray[this.indicador].indexOf(this.detfacSelected), 'BOD_CODIGO');
      //   if (errores === 0) {
      //     this.guardarFactura();
      //   }
      // }
      //CDPJ
      if (this.encfacService.detfacarray[this.indicador].length !== 0) {
        this.spin = true;
        const NOGSCC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOGSCC').CFG_VALOR1;
        const CENXCTACON = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENXCTACON').CFG_VALOR1;
        const intFCEXSNEG = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCEXSNEG').CFG_VALOR1;
        const intVEPLDFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VEPLDFAC').CFG_VALOR1;
        //console.log('valor>>>>', NOGSCC);
        let errores = 0;
        let fila = 0
        let strUnidadCosteo = '';
        await Promise.all(this.encfacService.detfacarray[this.indicador].map(async (fac) => {
          //for (const fac of this.encfacService.detfacarray[this.indicador]) {
          //console.log('valor>>>>', NOGSCC);
          fila++;
          if (fac.DETFAC_TIPODET !== '*' && fac.DETFAC_TIPODET !== 'Y') {
            // ARTICULOS			            
            //const rsdata1: any = await this.encfacService.encontrarArticulo(fac.DETFAC_CODIGO);
            const rsdata: any = await this.encfacService.encontrarArtCntaySer(fac.DETFAC_CODIGO, fac.DETFAC_TIPODET);
            const unidadCosteo = await this.encfacService.erpGetExistenciaPartII(fac.DETFAC_CODIGO);
            const equi = await this.encfacService.cantidadequivalencia(fac.DETFAC_UNIDAD);
            if (unidadCosteo !== null) {
              if (unidadCosteo[0] !== undefined) {
                strUnidadCosteo = unidadCosteo[0].ART_UNIDADCOSTEO;
              }
            }
            // let strCodigo = "";
            // if (rsdata !== null) {
            //   for (const rs of rsdata) {
            //     strCodigo = rs.ART_CODIGO;
            //   }
            // }

            let strCodigo = 0;
            if (rsdata !== null) {
              for (const rs of rsdata) {
                strCodigo = rs.NUMERO;
              }
            }
            if (fac.BOD_CODIGO === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'Ingrese una bodega al detalle de la factura'
              });
              errores++;
              this.spin = false;
              this.control = 0
              return;
            } else if (Number(fac.DETFAC_CANTIDAD) <= 0) {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'Ingrese una cantidad valida al detalle de la factura'
              });
              errores++;
              this.spin = false;
              this.control = 0
              return;
            } else if (Number(fac.DETFAC_PRECIO) === 0 && NOFCCERO === 1) {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'No puede existir items con total igual a cero'
              });
              errores++;
              this.spin = false;
              this.control = 0
              return;

            } else if (fac.DETFAC_TIPODET !== '*' && fac.DETFAC_CODIGO === '') {//GSRF
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'No puede existir items sin código de articulo'
              });
              errores++;
              this.spin = false;
              this.control = 0
              return;
              //GSRF
            } else if (intFCEXSNEG === 0 && fac.DETFAC_TIPODET != 'S' && fac.DETFAC_TIPODET != 'C'
              && this.validarlinea(fac) === false && intVEPLDFAC === 1) {// no permitir facturar con existencias negativas
              //   //CDPJ
              await this.encfacService.verificarExistenciaArticulo(fac);
              let cantidatot = 0;
              cantidatot = await this.calcularcantidad(fac);
              //   // await this.encfacService.obtenerExistenciaArticulo(fac.DETFAC_CODIGO, 
              //   //   fac.BOD_CODIGO, this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO,
              //   //   this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy'),
              //   //   this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'HH:mm'), fac,
              //   //   'verificarexistencia');
              //   console.log('detalle cant',fac.DETFAC_CANTIDAD);
              //   //await this.encfacService.verificarExistenciaArticulo(fac);

              // //console.log('>><<<codigo',fac.DETFAC_CODIGO)
              //    //await this.encfacService.verificarExistenciaArticulo(this.encfacService.detfacarray[this.indicador]);
              //   //  console.log('>>>><cantidad',cantidatot);
              //    //console.log('>>>><',this.encfacService.existencia);
              //   //await this.verificarexistencias(fac.DETFAC_CODIGO);
              console.log('detalle existencia', fac)
              console.log('detalle existencia', fac.EXISTENCIA)
              //   console.log('detalle cantidad',fac.DETFAC_CANTIDAD )
              //   console.log('detalle cantidad',cantidatot )
              if (cantidatot > fac.EXISTENCIA) {
                this.messageService.add({
                  key: 'encfac',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No se pueden facturar existencias negativas' +
                    ' del artículo ' + fac.DETFAC_CODIGO
                    + ' de la bodega ' + fac.BOD_CODIGO
                });

                errores++;
                //fac.DETFAC_CANTIDAD=0.00;
                this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
                this.encfacService.calcularTotales();
                this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
                this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.encfacService.detfacarray[this.indicador].indexOf(this.detfacSelected), 'DETFAC_PRECIO');
                this.spin = false;
                this.control = 0
                return;
              } else if (Number(fac.DETFAC_CANTIDAD) > Number(fac.DETPED_CANTIDAD)) {
                this.encfacService.verificarCantidadPedido(fac);

                errores++;
                this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
                this.encfacService.calcularTotales();
                this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
                this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.encfacService.detfacarray[this.indicador].indexOf(this.detfacSelected), 'DETFAC_PRECIO');
                this.spin = false;  //CDPJ
                this.control = 0
                return;
              }
              //CDPJ
            } if (fac.DETFAC_UNIDAD != strUnidadCosteo && fac.DETFAC_TIPODET != 'S' && fac.DETFAC_TIPODET != 'C') {
              let cantidadequi = 0
              console.log('fac.DETFAC_UNIDAD', fac.DETFAC_UNIDAD);
              console.log('strUnidadCosteo', strUnidadCosteo);
              console.log('fac.DETFAC_CODIGO', fac.DETFAC_CODIGO)
              if (equi !== null) {
                if (equi[0] !== undefined) {
                  cantidadequi = equi[0].NUMERO;
                }
              }
              console.log('cantidad', cantidadequi);
              //console.log('unidadventa',fac.DETFAC_UNIDAD);
              if (cantidadequi <= 0) {
                this.messageService.add({
                  key: 'encfac',
                  severity: 'error',
                  summary: 'Información',
                  detail: "La unidad de equivalencia para: " + fac.DETFAC_UNIDAD + " del artículo " + fac.DETFAC_CODIGO
                    + " no existe."
                });
                errores++;
                this.spin = false;
                this.control = 0
                return;
              }
            } if (NOGSCC === 1 && this.FACCC === 1) { // verifica si el centro de costos fue ingresado

              //console.log('entro1', errores);
              if (fac.CEN_CODIGO === '') {
                this.messageService.add({
                  key: 'encfac',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'Verifique el ingreso del centro de costos'
                });
                errores++;
                this.spin = false;
                this.control = 0
                return;
              }

              if (CENXCTACON === 1) {// Verificar ingreso de centro de costos por item
                //console.log('entro2', errores);
                const strAplicaCentroCostos = await this.verificarCentroCostosCuenta(fac.DETFAC_CODIGO, fac.DETFAC_TIPODET);
                if (fac.CEN_CODIGO === '' && strAplicaCentroCostos === "Si") {
                  this.messageService.add({
                    key: 'encfac',
                    severity: 'error',
                    summary: 'Información',
                    detail: 'Verifique el ingreso del centro de costos'
                  });
                  errores++;
                  this.spin = false;
                  this.control = 0
                  return;
                }
              }

            } if (fac.DETFAC_TIPODET === 'A' && strCodigo === 0) {//CDPJ
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'El artículo ' + fac.DETFAC_CODIGO + ' no existe'
              });
              this.control = 0
              errores++;
            } else if (fac.DETFAC_TIPODET === 'S' && strCodigo === 0) {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'El servicio ' + fac.DETFAC_CODIGO + ' no existe'
              });
              this.control = 0
              errores++;
            } else if (fac.DETFAC_TIPODET === 'C' && strCodigo === 0) {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'La cuenta contable ' + fac.DETFAC_CODIGO + ' no existe'
              });
              this.control = 0
              errores++;
            }
            //CDPJ
            if (fac.TARIIVA_CODIGO === null || fac.TARIIVA_CODIGO === undefined || fac.TARIIVA_CODIGO === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'Falta seleccionar el tipo de porcentaje del código ' + fac.DETFAC_CODIGO
              });
              this.control = 0
              errores++;
            }
            else if (fac.DETFAC_PORIVA === null || fac.DETFAC_PORIVA === undefined || fac.DETFAC_PORIVA === '') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El campo %IVA no puede estar vacio, a menos que sea un comentario'
              });
              this.control = 0
              errores++;

            } else if (fac.DETFAC_TRIBIVA === 'S' && fac.TARIIVA_CODIGO !== null && fac.TARIIVA_CODIGO !== undefined && fac.TARIIVA_CODIGO !== '') {
              let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === fac.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0 && Number(fac.DETFAC_PORIVA) !== 0 && Number(element.TARIIVA_PORCENTAJE) === Number(fac.DETFAC_PORIVA))
              if (data === undefined || data === null) {
                this.messageService.add({
                  key: 'encfac',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'El %IVA con el porcentaje ' + fac.DETFAC_PORIVA + ' no es parte de los porcentajes que puede seleccionar cuando se tributa IVA.'
                });
                this.control = 0
                errores++;
              }
            } else if (fac.DETFAC_TRIBIVA === 'N'
              && fac.TARIIVA_CODIGO !== null && fac.TARIIVA_CODIGO !== undefined && fac.TARIIVA_CODIGO !== '') {
              let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === fac.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0 && Number(fac.DETFAC_PORIVA) === 0 && Number(element.TARIIVA_PORCENTAJE) === Number(fac.DETFAC_PORIVA))
              console.log(data)
              console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === fac.TARIIVA_CODIGO))
              if (data === undefined || data === null) {
                this.messageService.add({
                  key: 'encfac',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'El %IVA con el porcentaje ' + fac.DETFAC_PORIVA + ' no es parte de los porcentajes que puede seleccionar cuando no se tributan IVA.'
                });
                this.control = 0
                errores++;
              }
            }
            //CDPJ
          }
        }));
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell(this.encfacService.detfacarray[this.indicador].indexOf(this.detfacSelected), 'BOD_CODIGO');
        console.log('entro3', errores);
        if (errores === 0) {
          this.spin = false;//CDPJ
          await this.guardarFactura();
          // this.validarEncfacNumero();
        }

        this.spin = false;//CDPJ
      }
      //CDPJ
    }
  }
  //CDPJ
  validarlinea(fac) {
    console.log('1', fac.ENCPED_NUMERO);
    console.log('2', fac.ENCGRE_NUMERO);
    let val = true;
    fac.DETPED_LINEA = fac.DETPED_LINEA === undefined || fac.DETPED_LINEA === null ? '' : fac.DETPED_LINEA;
    fac.DETGRE_LINEA = fac.DETGRE_LINEA === undefined || fac.DETGRE_LINEA === null ? '' : fac.DETGRE_LINEA;
    if (fac.DETPED_LINEA !== '' && fac.DETGRE_LINEA === '') {
      val = false;

    } else if (fac.DETGRE_LINEA !== '') {
      val = true;
    } else if (fac.DETGRE_LINEA === '' && fac.DETPED_LINEA === '') {
      val = false;
    }
    return val;
  }
  async validarango() {

    let valFac = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
    let intTamanioLista = 0;
    let intNumFinal = 0;
    let strInicial = '';
    let strNumActual = '';
    let strTipoDoc = '';
    let strTipoNum = 'FAC';
    let item: SaciMaenumeracionG;
    let strSerie = '';
    let strNumFinal = '';
    let strNemonico = '';
    const NUMFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMFAC').CFG_VALOR3;
    if (NUMFAC === 'G') { // numeración
      // general(clase
      // de
      // doc)
      if (this.chknotadeventa === true) {
        strTipoNum = 'NV';
        strTipoDoc = 'NV';
      } try {
        this.encfacService.obtenerNumeracionG(strTipoNum).subscribe(num => {
          for (const n of num) {
            intTamanioLista = num.length;
            item = n;
          }
          strInicial = item.NUM_SECINICIAL;
          intNumFinal = item.NUM_SECFINAL;
          strSerie = item.NUM_SERIE;
          // console.log('numfinal', intNumFinal);
          // console.log('inicial', strInicial);
          strNumActual = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO//(<HTMLInputElement>document.getElementById("ENCFAC_NUMERO")).value
          // console.log('num actual', strNumActual);
          // console.log('num actual2', strNumActual);
          strNumActual = strNumActual.toString().substring(strSerie.length, valFac.length);
          //console.log('num actual3', strNumActual);
          if ((Number(strNumActual) > Number(intNumFinal) || Number(strNumActual) < Number(strInicial)) || strNumActual.length !== 9) {
            //console.log('fue mayorpor clase');
            this.vrango = true;
          } else {
            this.vrango = false;
          }

        });
      } catch (error) {

      }

    } else if (NUMFAC === 'C') { // numeración
      // por
      // punto
      // de
      // venta
      // if (this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO === undefined) {
      //   this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO = '';
      // }

      //if (this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO.length === 0) {
      //this.busquedanumeracion();
      //} else {
      let strnumdoc2 = ''
      try {
        this.encfacService.erObtenerNumFact(this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO).subscribe(er => {
          if (er !== null) {
            if (er[0] !== undefined) {
              const itemNum = er[0];
              //strNumActual = itemNum.NUM_SECACTUAL;
              strSerie = itemNum.NUM_SERIE;
              strNumFinal = itemNum.NUM_SECFINAL;
              strInicial = itemNum.NUM_SECINICIAL;
              strNumActual = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO//(<HTMLInputElement>document.getElementById("ENCFAC_NUMERO")).value
              console.log('num actual', strNumActual);
              strNemonico = itemNum.NUM_NEMONICO;
              strNumActual = strNumActual.toString().substring(strNemonico.length, valFac.length)
              console.log('num actual2', strNumActual);
              strNumActual = strNumActual.toString().substring(strSerie.length, valFac.length);
              console.log('num actual3', strNumActual);
              if ((strNumActual > strNumFinal || strNumActual < strInicial) || strNumActual.length !== 9) {
                console.log('fue mayor');
                this.vrango = true;
              } else {
                this.vrango = false;
              }

              //CDPJ
            }
          }
        });
      } catch (error) {
      }

      //}
    }

  }
  //CDPJ
  async calcularcantidad(fac) {
    let cantidatot = 0
    for (const fact of this.encfacService.detfacarray[this.indicador]) {
      if (fact.DETFAC_CODIGO === fac.DETFAC_CODIGO
        && fact.BOD_CODIGO === fac.BOD_CODIGO
        && fact.DETFAC_UNIDAD === fac.DETFAC_UNIDAD) {
        //console.log('detalle cant',fac.DETFAC_CANTIDAD);
        cantidatot = cantidatot + Number(fact.DETFAC_CANTIDAD)

      }
    }
    return cantidatot
  }



  async guardarFactura() {
    this.spin = true;
    this.encfacService.calcularTotales();
    const item: VenEncfacZ = {};
    const DETFACLOT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DETFACLOT').CFG_VALOR1;

    this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO);
    this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES);
    this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES);
    this.encfacService.encfacarray[this.indicador].ENCFAC_VALORICE = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORICE);
    this.encfacService.encfacarray[this.indicador].ENCFAC_BASEICE = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_BASEICE);
    this.encfacService.encfacarray[this.indicador].ENCFAC_BASECERO = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_BASECERO);
    this.encfacService.encfacarray[this.indicador].ENCFAC_BASEIVA = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_BASEIVA);
    this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIVA = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIVA);
    this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL);
    this.encfacService.encfacarray[this.indicador].ENCFAC_BASENOOBJIVA = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_BASENOOBJIVA);
    this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIRBPNR = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIRBPNR);
    this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS); //CDPJ
    this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF); //CDPJ
    this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO = this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO); //CDPJ

    item.ENCFAC_NUMERO = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
    item.COM_CODIGO = '01';
    const strFecha = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_HORAEMISION, 'HH:mm');
    item.ENCFAC_FECHAEMISION = strFecha + ' ' + strHora;
    item.ENCFAC_FECHAENTREGA = strFecha;
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PENDIENTE') {
      item.ENCFAC_ESTADO = 'P';
    } else if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PAGADA') {
      item.ENCFAC_ESTADO = 'G';
    } else if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      item.ENCFAC_ESTADO = 'A';
    }
    item.CLI_CODIGO = this.encfacService.encfacarray[this.indicador].CLI_CODIGO;
    item.CLI_NOMBRE = this.encfacService.encfacarray[this.indicador].CLI_NOMBRE;
    item.BOD_CODIGO = this.encfacService.encfacarray[this.indicador].BOD_CODIGO;
    item.VEN_CODIGO = this.encfacService.encfacarray[this.indicador].VEN_CODIGO;
    item.ENCFFA_CODIGO = '';
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === undefined ||
      this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === null ||
      this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === '') {
      item.ENCFAC_FORMAPAGO = '';
    } else {
      item.ENCFAC_FORMAPAGO = this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO;
    }
    item.ENCFAC_REFERENCIA = this.encfacService.encfacarray[this.indicador].ENCFAC_REFERENCIA;
    item.ENCFAC_TIPO = '';
    item.ENCFAC_LISTA = this.selectlistasprecio.codigo;
    item.ENCFAC_FECHAVENCIMIENTO = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAVENCIMIENTO, 'dd/MM/yyyy');
    item.ENCFAC_TOTAL = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL);
    item.ENCFAC_TOTALNETO = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO);
    item.ENCFAC_PORCEIVA = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEIVA);
    item.ENCFAC_VALORIVA = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIVA);
    item.ENCFAC_PORCECAR = 0;
    item.ENCFAC_VALORCAR = 0;
    item.ENCFAC_PORCERTC = 0;
    item.ENCFAC_VALORRTC = 0;
    item.ENCFAC_PORCEDES = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES);
    item.ENCFAC_VALORDES = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES);
    item.ENCFAC_VALORDEV = 0;
    item.ENCFAC_PORCEINT = 0;
    item.ENCFAC_VALORINT = 0;
    item.ENCFAC_PORCEREC = 0;
    item.ENCFAC_VALORREC = 0;
    item.ENCFAC_VALORICE = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORICE);
    item.ENCFAC_PORCEICE = 0;
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_IMPRESO === 'IMPRESA') {
      item.ENCFAC_IMPRESO = 'S';
    } else {
      item.ENCFAC_IMPRESO = 'N';
    }
    item.ENCFAC_COMENTARIO = this.encfacService.encfacarray[this.indicador].ENCFAC_COMENTARIO;
    item.ENCFAC_TIPOFAC = 'S';
    item.ENCFAC_GENERAFAC = 'N';
    item.ENCFAC_NUMERODOB = '';
    item.ENCFAC_DIRECCION = this.encfacService.encfacarray[this.indicador].ENCFAC_DIRECCION;
    item.ENCFAC_TELEFONO = this.encfacService.encfacarray[this.indicador].ENCFAC_TELEFONO;
    item.ENCFAC_RUCIDE = this.encfacService.encfacarray[this.indicador].ENCFAC_RUCIDE;
    item.ENCFAC_CHOFER = '';
    item.ENCFAC_CARRO = '';
    item.ENCFAC_PLACA = '';
    item.ENCFAC_IVA = this.selectiva.codigo;
    item.ENCFAC_BASEIVA = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASEIVA);
    item.ENCFAC_BASEICE = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASEICE);
    item.ENCFAC_BASECERO = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASECERO);
    item.ENCFAC_OTROS = '';
    item.ENCFAC_GRUPO = this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO;
    item.ENCFAC_NEMONICO = this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO;
    item.ENCFAC_SERIE = this.encfacService.encfacarray[this.indicador].ENCFAC_SERIE;
    item.ENCFAC_SECACTUAL = this.encfacService.encfacarray[this.indicador].ENCFAC_SECACTUAL;
    item.ENCFAC_DIASCRDT = this.encfacService.encfacarray[this.indicador].ENCFAC_DIASCRDT;
    item.ENCFAC_CLIDSCT = 0;
    item.USU_IDENTIFICACION = '';
    item.ENCFAC_VALORIRBPNR = this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIRBPNR;//GSRF

    item.ENCFAC_TIPODSCTO = '';

    if (this.encfacService.dblPorcDescGlb !== undefined) {
      if (Number(this.encfacService.dblPorcDescGlb) > 0) { // tipo de descuento
        if (this.dscSeleccionado === 'dscValor') {
          item.ENCFAC_TIPODSCTO = 'V';
        } else {
          item.ENCFAC_TIPODSCTO = 'P';
        }
      } else {
        item.ENCFAC_TIPODSCTO = '';
      }
    } else {
      item.ENCFAC_TIPODSCTO = '';
    }


    item.ENCFAC_REFCLI = this.encfacService.encfacarray[this.indicador].CLI_NOMBRE;
    item.ENCFAC_DIRECCION2 = this.encfacService.encfacarray[this.indicador].ENCFAC_DIRECCION2;
    item.ENCFAC_TELEFONO2 = this.encfacService.encfacarray[this.indicador].ENCFAC_TELEFONO2;
    item.ENCFAC_FAX = this.encfacService.encfacarray[this.indicador].ENCFAC_FAX;
    item.ENCFAC_CORREO = this.encfacService.encfacarray[this.indicador].ENCFAC_CORREO;
    item.ENCFAC_NOTADEVENTA = this.chknotadeventa === true ? 'S' : '';
    item.ENCFAC_ORIGEN = 'VEN';
    item.ENCFAC_AUTORIZACION = this.encfacService.encfacarray[this.indicador].ENCFAC_AUTORIZACION;
    item.ENCFAC_NUMPUNTOS = 0;
    item.ENCFAC_TIPOFACTURA = this.encfacService.encfacarray[this.indicador].ENCFAC_TIPOFACTURA;
    item.ENCFAC_BASENOOBJIVA = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASENOOBJIVA);
    item.ENCFAC_OTRDSCTOS = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS);//CDPJ
    item.ENCFAC_OTRDSCTOSBASEDIF = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF);//CDPJ
    item.ENCFAC_OTRDSCTOSBASECERO = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO);//CDPJ
    let txtNumero = '';

    if (this.bolNuevo) {
      txtNumero = '';
    } else {
      txtNumero = item.ENCFAC_NUMERO;
    }

    try {
      let encs: any;
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO !== undefined
        && this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO !== null
        && this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO !== '') {
        item.ENCFAC_SERVICIO = Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO));
        encs = await this.encfacService.insertarEncabezadoFactServ(item, txtNumero).catch(e => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(e.error.substr(0, 9));
          this.messageService.add({
            key: 'encfac',
            severity: 'error',
            summary: 'Insertar Encabezado Factura',
            detail: e.error
          });
          this.control = 0
          this.spin = false;
          return
        });
      } else {
        encs = await this.encfacService.insertarEncabezadoFact(item, txtNumero).catch(e => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(e.error.substr(0, 9));
          this.messageService.add({
            key: 'encfac',
            severity: 'error',
            summary: 'Insertar Encabezado Factura',
            detail: e.error
          });
          this.control = 0
          this.spin = false;
          return
        });
      }
      for (const enc of encs) {
        this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO = enc[':B1'];
        let fila = 1;
        for (const det of this.encfacService.detfacarray[this.indicador]) {
          const itemdet: VenDetfacZ = {};
          itemdet.DETFAC_LINEA_3 = det.DETFAC_LINEA_3;
          itemdet.ENCFAC_NUMERO = enc[':B1'];
          itemdet.COM_CODIGO = '01';
          itemdet.DETFAC_LINEA = det.DETFAC_LINEA;
          itemdet.DETFAC_TIPODET = det.DETFAC_TIPODET;
          itemdet.BOD_CODIGO = det.BOD_CODIGO;
          itemdet.DETFAC_CODIGO = det.DETFAC_CODIGO;
          itemdet.DETFAC_DESCRIPCION = det.DETFAC_DESCRIPCION;
          itemdet.DETFAC_TRIBIVA = det.DETFAC_TRIBIVA;
          itemdet.DETFAC_TRIBICE = det.DETFAC_TRIBICE;
          itemdet.UNI_SIMBOLO = det.UNI_SIMBOLO;
          itemdet.DETFAC_CANTIDAD = Number(det.DETFAC_CANTIDAD);
          itemdet.DETFAC_PRECIO = Number(det.DETFAC_PRECIO);
          itemdet.DETFAC_DESCUENTO = Number(det.DETFAC_DESCUENTO);
          itemdet.DETFAC_TOTAL = Number(det.DETFAC_TOTAL);
          itemdet.DETFAC_IVA = det.DETFAC_IVA;
          itemdet.DETFAC_ICE = det.DETFAC_ICE;
          itemdet.DETFAC_LISTA = det.DETFAC_LISTA;
          itemdet.DETFAC_BASEIVA = det.DETFAC_BASEIVA;
          itemdet.DETFAC_BASEICE = det.DETFAC_BASEICE;
          itemdet.DETFAC_BASECERO = det.DETFAC_BASECERO;
          itemdet.DETFAC_PORCEICE = det.DETFAC_PORCEICE;
          itemdet.ENCCOT_NUMERO = det.ENCCOT_NUMERO;
          itemdet.DETCOT_LINEA = det.DETCOT_LINEA;
          itemdet.ENCPED_NUMERO = det.ENCPED_NUMERO;
          itemdet.DETPED_LINEA = det.DETPED_LINEA;
          itemdet.ENCGRE_NUMERO = det.ENCGRE_NUMERO;
          itemdet.DETGRE_LINEA = det.DETGRE_LINEA;
          itemdet.DETFAC_UNIDAD = det.DETFAC_UNIDAD;
          itemdet.DETFAC_TIPO = '';
          itemdet.DETFAC_CODIGOREF = '';
          itemdet.ENCFAC_NUMERO = enc[':B1'];
          itemdet.DETFAC_PROMOCION = det.DETFAC_PROMOCION;
          if (DETFACLOT === 1) {
            if (det.DETFAC_TIPODET === '*') {
              itemdet.DETFAC_ORDEN = det.DETFAC_ORDEN;
            } else {
              itemdet.DETFAC_ORDEN = det.DETFAC_LINEA;
            }
          } else {
            itemdet.DETFAC_ORDEN = fila;
          }
          itemdet.DETFAC_IRBPNR = det.DETFAC_IRBPNR;//GSRF
          itemdet.DETFAC_VALORIRBPNR = det.DETFAC_VALORIRBPNR;//GSRF
          itemdet.DETFAC_LINORGPROM = det.DETFAC_LINORGPROM;
          itemdet.MAEPRM_CODIGO = det.MAEPRM_CODIGO;
          itemdet.DETFAC_FECHAREALIZACION = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
          itemdet.DETFAC_PRECIOC = det.DETFAC_PRECIOC;
          itemdet.CEN_CODIGO = det.CEN_CODIGO;
          itemdet.DETFAC_BULTO = '';
          itemdet.DETFAC_CANTIDADB = 0;
          itemdet.DETFAC_TIPOSRI = det.DETFAC_TIPOSRI;
          itemdet.DETFAC_SIGNO = '+';
          itemdet.DETFAC_ARTACAMBIO = det.DETFAC_ARTACAMBIO;
          itemdet.ORDTRA_NUMERO = det.ORDTRA_NUMERO;
          itemdet.DETORDTRA_LINEA = det.DETORDTRA_LINEA;
          itemdet.ENCREQ_NUMERO = det.ENCREQ_NUMERO;
          itemdet.DETREQ_LINEA = det.DETREQ_LINEA;
          itemdet.DETFAC_BASENOOBJIVA = det.DETFAC_BASENOOBJIVA;
          itemdet.DETFAC_TRIBASENOOBJIVA = det.DETFAC_TRIBASENOOBJIVA;
          itemdet.ENCMAT_NUMERO = det.ENCMAT_NUMERO;
          itemdet.DETMAT_CODIGO = det.DETMAT_CODIGO;
          if (DETFACLOT === 1) {
            if (det.DETFAC_TIPODET === '*') {
              itemdet.DETFAC_ORDENLOTE = det.DETFAC_ORDEN;
            } else {
              itemdet.DETFAC_ORDENLOTE = det.DETFAC_LINEA;
            }
          } else {
            itemdet.DETFAC_ORDENLOTE = fila;
          }
          itemdet.DETFAC_CANTIDADUND = det.DETFAC_CANTIDADUND;
          itemdet.DETFAC_INCLUENDSCTO = det.DETFAC_INCLUENDSCTO;//CDPJ
          itemdet.DETFAC_PORIVA = det.DETFAC_PORIVA;//CDPJ
          itemdet.TARIIVA_CODIGO = det.TARIIVA_CODIGO;//CDPJ
          if (itemdet.DETFAC_TIPODET === '*') {
            itemdet.DETFAC_PORIVA = 0;//CDPJ
            itemdet.TARIIVA_CODIGO = '-1';//CDPJ
          }
          fila++;
          try {
            const dets = await this.encfacService.insertarDetalleFactura(itemdet).catch(e => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(e.error.substr(0, 9));
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Insertar Detalle Factura',
                detail: e.error
              });
              this.control = 0
              this.spin = false;
              return
            });
            this.botonNuevo = false;
            this.botonGuardar = true;
            this.botonBorrar = false;
            this.botonRegresar = true;

            const tipoCli = await this.venEstadisticasService.eridcliente(this.encfacService.encfacarray[this.indicador].CLI_CODIGO);
            if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
              this.disabledEstadisticas = true;
            } else {
              this.disabledEstadisticas = false;
            }

            for (const rs of dets) {
              if (itemdet.DETFAC_LINEA_3 === 0) {
                itemdet.DETFAC_LINEA_3 = rs.DETFAC_LINEA;
                this.auditoriagral.registrarAuditoria('VEN_DETFAC',
                  this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/' + itemdet.DETFAC_CODIGO + '/VEN', 'I',
                  '', '01', '', '', '', '').subscribe();
                this.auditoriagral.registrarAuditoria('VEN_DETFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/' + itemdet.DETFAC_CODIGO + '/'
                  + 'PorIva:' + itemdet.DETFAC_PORIVA + '/' + 'CodTarIva:' + itemdet.TARIIVA_CODIGO, 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                if (itemdet.ENCPED_NUMERO !== null && itemdet.ENCPED_NUMERO !== '' && itemdet.ENCPED_NUMERO !== undefined) {
                  this.auditoriagral.registrarAuditoria('VEN_DETFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/' + itemdet.ENCPED_NUMERO + '/' + itemdet.DETFAC_CODIGO + '/' + itemdet.DETFAC_CANTIDAD
                    , 'I',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                }
                if (itemdet.ENCGRE_NUMERO !== null && itemdet.ENCGRE_NUMERO !== '' && itemdet.ENCGRE_NUMERO !== undefined) {
                  this.auditoriagral.registrarAuditoria('VEN_DETFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/ GRE ' + itemdet.ENCGRE_NUMERO + '/' + itemdet.DETFAC_CODIGO + '/' + itemdet.DETFAC_CANTIDAD
                    , 'I',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                }

              } else {
                this.auditoriagral.registrarAuditoria('VEN_DETFAC',
                  this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/' + itemdet.DETFAC_CODIGO + '/VEN', 'A',
                  '', '01', '', '', '', '').subscribe();
                this.auditoriagral.registrarAuditoria('VEN_DETFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/' + itemdet.DETFAC_CODIGO + '/'
                  + 'PorIva:' + itemdet.DETFAC_PORIVA + '/' + 'CodTarIva:' + itemdet.TARIIVA_CODIGO, 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                if (itemdet.ENCPED_NUMERO !== null && itemdet.ENCPED_NUMERO !== '' && itemdet.ENCPED_NUMERO !== undefined) {
                  this.auditoriagral.registrarAuditoria('VEN_DETFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/' + itemdet.ENCPED_NUMERO + '/' + itemdet.DETFAC_CODIGO + '/' + itemdet.DETFAC_CANTIDAD
                    , 'A',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                }
                if (itemdet.ENCGRE_NUMERO !== null && itemdet.ENCGRE_NUMERO !== '' && itemdet.ENCGRE_NUMERO !== undefined) {
                  this.auditoriagral.registrarAuditoria('VEN_DETFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/GRE ' + itemdet.ENCGRE_NUMERO + '/' + itemdet.DETFAC_CODIGO + '/' + itemdet.DETFAC_CANTIDAD
                    , 'A',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                }
              }
            }
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(err.error.substr(0, 9));
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Insertar Detalle Factura',
              detail: mensaje
            });
            this.control = 0
            this.spin = false;
          }
        }
        this.messageService.add({
          key: 'encfac',
          severity: 'success',
          summary: 'Factura Guardada',
          detail: 'La factura ha sido guardada correctamente.'
        });
        this.control = 0
        this.spin = false;
        if (this.bolNuevo) {
          this.auditoriagral.registrarAuditoria('VEN_ENCFAC',
            this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/VEN', 'I',
            '', '01', '', '', '', '').subscribe();
          let strDoc = 'FAC';

          if (this.chknotadeventa === true) {
            strDoc = 'NV';
          }

          const NUMGUAFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMGUAFAC').CFG_VALOR1;

          if (NUMGUAFAC === 0) {
            await this.actualizarNumeracion(this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO, strDoc,
              this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO,
              this.encfacService.encfacarray[this.indicador].ENCFAC_SERIE);
          }

          const intPANACTEMA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PANACTEMA').CFG_VALOR1;
          if (intPANACTEMA === 1) {
            if (this.datosfe.COM_FEACTIVADA === '1') { // Si esta en ambiente producción
              this.txtCorreo1 = '';
              this.txtCorreo2 = '';
              this.spin = true;
              await this.leerDatos('C', this.encfacService.encfacarray[this.indicador].CLI_CODIGO);
              this.spin = false;
              this.displayDialogActualizacionFE = true;
            }
          }
        } else {
          this.auditoriagral.registrarAuditoria('VEN_ENCFAC',
            this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/VEN', 'A',
            '', '01', '', '', '', '').subscribe();
        }

        //this.encontrarFactura(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, '');
        //this.encfacService.actualizarKardex(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe();
        //CDPJ
        if (this.GUINOKARD === 0) {
          this.encfacService.actualizarKardex(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe();
        } else {
          this.encfacService.actualizarKardexFacGui(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe();
        }
        this.generarAsiento();
        //CDPJ
        this.encfacService.ingDatosAdicCli(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe();
        this.infoadicional(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);//CDPJ

        this.extraerNumeroPedidos(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'G');
        this.extraerGuiasRem(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'G');
        this.extraerNumeroGuias(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'G');
        this.extraerNumeroOrdenesTrabajo(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
      }
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Insertar Encabezado Factura',
        detail: mensaje
      });
      this.control = 0
      this.spin = false;
    }
    //////GSRF

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO !== null) {
      console.log(this.chkactivo);
      if (this.index === 5) {
        console.log(this.encfacService.encfacarray[this.indicador].COMERCIOEXTERIOR);
        item.COMERCIOEXTERIOR = this.encfacService.encfacarray[this.indicador].COMERCIOEXTERIOR === null || this.encfacService.encfacarray[this.indicador].COMERCIOEXTERIOR === undefined ? '' : this.encfacService.encfacarray[this.indicador].COMERCIOEXTERIOR;
        item.INCOTERMFACTURA = this.encfacService.encfacarray[this.indicador].INCOTERMFACTURA === null || this.encfacService.encfacarray[this.indicador].INCOTERMFACTURA === undefined ? '' : this.encfacService.encfacarray[this.indicador].INCOTERMFACTURA;
        item.LUGARINCOTERM = this.encfacService.encfacarray[this.indicador].LUGARINCOTERM === null || this.encfacService.encfacarray[this.indicador].LUGARINCOTERM === undefined ? '' : this.encfacService.encfacarray[this.indicador].LUGARINCOTERM;
        item.PAISORIGEN = this.encfacService.encfacarray[this.indicador].PAISORIGEN === null || this.encfacService.encfacarray[this.indicador].PAISORIGEN === undefined ? '' : this.encfacService.encfacarray[this.indicador].PAISORIGEN;
        item.PUERTOEMBARQUE = this.encfacService.encfacarray[this.indicador].PUERTOEMBARQUE === null || this.encfacService.encfacarray[this.indicador].PUERTOEMBARQUE === undefined ? '' : this.encfacService.encfacarray[this.indicador].PUERTOEMBARQUE;
        item.PUERTODESTINO = this.encfacService.encfacarray[this.indicador].PUERTODESTINO === null || this.encfacService.encfacarray[this.indicador].PUERTODESTINO === undefined ? '' : this.encfacService.encfacarray[this.indicador].PUERTODESTINO;
        item.PAISDESTINO = this.encfacService.encfacarray[this.indicador].PAISDESTINO === null || this.encfacService.encfacarray[this.indicador].PAISDESTINO === undefined ? '' : this.encfacService.encfacarray[this.indicador].PAISDESTINO;
        item.PAISADQUISICION = this.encfacService.encfacarray[this.indicador].PAISADQUISICION === null || this.encfacService.encfacarray[this.indicador].PAISADQUISICION === undefined ? '' : this.encfacService.encfacarray[this.indicador].PAISADQUISICION;
        item.DIRCOMPRADOR = this.encfacService.encfacarray[this.indicador].DIRCOMPRADOR === null || this.encfacService.encfacarray[this.indicador].DIRCOMPRADOR === undefined ? '' : this.encfacService.encfacarray[this.indicador].DIRCOMPRADOR;
        item.INCOTERMTOTALSINIMPUESTOS = this.encfacService.encfacarray[this.indicador].INCOTERMTOTALSINIMPUESTOS === null || this.encfacService.encfacarray[this.indicador].INCOTERMTOTALSINIMPUESTOS === undefined ? '' : this.encfacService.encfacarray[this.indicador].INCOTERMTOTALSINIMPUESTOS;
        item.FLETEINTERNACIONAL = Number(this.encfacService.encfacarray[this.indicador].FLETEINTERNACIONAL === null ? '' : this.encfacService.encfacarray[this.indicador].FLETEINTERNACIONAL);
        item.SEGUROINTERNACIONAL = Number(this.encfacService.encfacarray[this.indicador].SEGUROINTERNACIONAL === null ? '' : this.encfacService.encfacarray[this.indicador].SEGUROINTERNACIONAL);
        item.GASTOSADUANEROS = Number(this.encfacService.encfacarray[this.indicador].GASTOSADUANEROS === null ? '' : this.encfacService.encfacarray[this.indicador].GASTOSADUANEROS);
        item.GASTOSTRANSPORTEOTROS = Number(this.encfacService.encfacarray[this.indicador].GASTOSTRANSPORTEOTROS === null ? '' : this.encfacService.encfacarray[this.indicador].GASTOSTRANSPORTEOTROS);
        item.FORMAPAGO = Number(this.encfacService.encfacarray[this.indicador].FORMAPAGO === null ? '' : this.encfacService.encfacarray[this.indicador].FORMAPAGO);

        console.log(item.FORMAPAGO, 'dentro 1');
        this.encfacService.getfacexp(item.ENCFAC_NUMERO).subscribe((res) => {
          console.log(res);
          if (res.length === 0) {
            this.encfacService.insertexporadi(item, 'INS');
            this.auditoriagral.registrarAuditoria('VEN_EXPOFAC',
              item.ENCFAC_NUMERO + '/VEN', 'I',
              '', '01', '', '', '', '').subscribe();
            console.log('inserta');

          } else {
            this.encfacService.insertexporadi(item, 'ACT');
            this.auditoriagral.registrarAuditoria('VEN_EXPOFAC',
              item.ENCFAC_NUMERO + '/VEN', 'A',
              '', '01', '', '', '', '').subscribe();
            console.log('actualiza');
          };
          this.cancelar();

          console.log(this.SELECTIPOREGI);
        });
      }
      if (this.SELECTIPOREGI !== undefined) {
        if (this.SELECTIPOREGI !== '') {
          //---------------CLIENTE-----------------///
          this.encfacService.encfacarray[this.indicador].TIPOREGI = this.SELECTIPOREGI;
          item.TIPOREGI = this.encfacService.encfacarray[this.indicador].TIPOREGI === null ? '' : this.encfacService.encfacarray[this.indicador].TIPOREGI;
          if (this.SELECTIPOREGI === '01') {
            item.PAISEFECPAGOGEN = this.encfacService.encfacarray[this.indicador].codigoPaisRegimen === null ? '' : this.encfacService.encfacarray[this.indicador].codigoPaisRegimen;
          } else if (this.SELECTIPOREGI === '02') {
            item.PAISEFECPAGOGEN = this.encfacService.encfacarray[this.indicador].codigoPaisParaiso === null ? '' : this.encfacService.encfacarray[this.indicador].codigoPaisParaiso;
          } else if (this.SELECTIPOREGI === '03') {
            item.PAISEFECPAGOGEN = '';
          }
          item.PAISEFECEXP = this.encfacService.encfacarray[this.indicador].PAISEFECEXP === null ? '' : this.encfacService.encfacarray[this.indicador].PAISEFECEXP;
          item.DENOEXPCLI = this.encfacService.encfacarray[this.indicador].DENOEXPCLI === null ? '' : this.encfacService.encfacarray[this.indicador].DENOEXPCLI;
          item.CLI_PARTEREL = this.encfacService.encfacarray[this.indicador].CLI_PARTEREL === null ? '' : this.encfacService.encfacarray[this.indicador].CLI_PARTEREL;
          item.CLI_TIPO = this.encfacService.encfacarray[this.indicador].CLI_TIPO === null ? '' : this.encfacService.encfacarray[this.indicador].CLI_TIPO;
          //---------------REFRENDO-----------------///
          if (this.SELECTEXPORTACIONDE === '01') {
            item.DOCTRANSP = this.encfacService.encfacarray[this.indicador].DOCTRANSP === null ? '' : this.encfacService.encfacarray[this.indicador].DOCTRANSP;
            item.CORRELATIVO = this.encfacService.encfacarray[this.indicador].CORRELATIVO === null ? '' : this.encfacService.encfacarray[this.indicador].CORRELATIVO;
            item.ANIO = this.encfacService.encfacarray[this.indicador].ANIO === null ? '' : this.encfacService.encfacarray[this.indicador].ANIO;
            item.REGIMEN = this.encfacService.encfacarray[this.indicador].REGIMEN === null ? '' : this.encfacService.encfacarray[this.indicador].REGIMEN;
            item.DISTADUANERO = this.encfacService.encfacarray[this.indicador].DISTADUANERO === null ? '' : this.encfacService.encfacarray[this.indicador].DISTADUANERO;
            item.VALORFOB = this.encfacService.encfacarray[this.indicador].VALORFOB === null ? '' : this.encfacService.encfacarray[this.indicador].VALORFOB;
            item.VALORIMPEXT = '';
            item.INGGRAVADOEXT = '';
            item.TIPOINGRESO = '';
            this.encfacService.encfacarray[this.indicador].EXPORTACIONDE = this.SELECTEXPORTACIONDE;
            item.EXPORTACIONDE = this.encfacService.encfacarray[this.indicador].EXPORTACIONDE === null ? '' : this.encfacService.encfacarray[this.indicador].EXPORTACIONDE;
            item.FECHAEMBARQUE = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].FECHAEMBARQUE, 'dd/MM/yyyy');
          } else if (this.SELECTEXPORTACIONDE === '02') {
            item.DOCTRANSP = '';
            item.CORRELATIVO = '';
            item.ANIO = '';
            item.REGIMEN = '';
            item.DISTADUANERO = '';
            item.VALORFOB = this.encfacService.encfacarray[this.indicador].VALORFOB === null ? '' : this.encfacService.encfacarray[this.indicador].VALORFOB;
            item.VALORIMPEXT = '';
            item.INGGRAVADOEXT = '';
            item.TIPOINGRESO = '';
            this.encfacService.encfacarray[this.indicador].EXPORTACIONDE = this.SELECTEXPORTACIONDE;
            item.EXPORTACIONDE = this.encfacService.encfacarray[this.indicador].EXPORTACIONDE === null ? '' : this.encfacService.encfacarray[this.indicador].EXPORTACIONDE;
            item.FECHAEMBARQUE = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].FECHAEMBARQUE, 'dd/MM/yyyy');

          } else if (this.SELECTEXPORTACIONDE === '03') {
            item.DOCTRANSP = '';
            item.CORRELATIVO = '';
            item.ANIO = '';
            item.REGIMEN = '';
            item.DISTADUANERO = '';
            item.VALORFOB = this.encfacService.encfacarray[this.indicador].VALORFOB === null ? '' : this.encfacService.encfacarray[this.indicador].VALORFOB;
            item.VALORIMPEXT = this.encfacService.encfacarray[this.indicador].VALORIMPEXT === null ? '' : this.encfacService.encfacarray[this.indicador].VALORIMPEXT;
            this.encfacService.encfacarray[this.indicador].INGGRAVADOEXT = this.SELECTINGGRAVADOEXT;
            item.INGGRAVADOEXT = this.encfacService.encfacarray[this.indicador].INGGRAVADOEXT === null ? '' : this.encfacService.encfacarray[this.indicador].INGGRAVADOEXT;
            this.encfacService.encfacarray[this.indicador].TIPOINGRESO = this.SELECTIPOINGRESO;
            item.TIPOINGRESO = this.encfacService.encfacarray[this.indicador].TIPOINGRESO === null ? '' : this.encfacService.encfacarray[this.indicador].TIPOINGRESO;
            this.encfacService.encfacarray[this.indicador].EXPORTACIONDE = this.SELECTEXPORTACIONDE;
            item.EXPORTACIONDE = this.encfacService.encfacarray[this.indicador].EXPORTACIONDE === null ? '' : this.encfacService.encfacarray[this.indicador].EXPORTACIONDE;
            item.FECHAEMBARQUE = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].FECHAEMBARQUE, 'dd/MM/yyyy');

          }
          item.VALORFOBCOMPROBANTE = this.encfacService.encfacarray[this.indicador].VALORFOBCOMPROBANTE === null ? '' : this.encfacService.encfacarray[this.indicador].VALORFOBCOMPROBANTE;
          console.log(item.CLI_TIPO);
          console.log(item.CLI_PARTEREL);
          console.log(item.TIPOREGI);
          console.log(item.PAISEFECPAGOGEN);
          console.log(item.PAISEFECEXP);
          console.log(item.DENOEXPCLI);
          console.log(item.DOCTRANSP);
          console.log(item.CORRELATIVO);
          console.log(item.ANIO);
          console.log(item.REGIMEN);
          console.log(item.DISTADUANERO);
          console.log(item.VALORFOB);
          console.log(item.VALORIMPEXT);
          console.log(item.INGGRAVADOEXT);
          console.log(item.TIPOINGRESO);
          console.log(item.EXPORTACIONDE);
          console.log(item.FECHAEMBARQUE);
          console.log(item.VALORFOBCOMPROBANTE);
          console.log(item.FORMAPAGO, 'dentro 2');
          this.encfacService.getfacexp(item.ENCFAC_NUMERO).subscribe((res) => {
            console.log(res);
            if (res.length === 0) {
              this.encfacService.insertexporadinew(item, 'INS');
              this.auditoriagral.registrarAuditoria('VEN_EXPOFAC',
                item.ENCFAC_NUMERO + '/VEN', 'I',
                '', '01', '', '', '', '').subscribe();
              console.log('inserta');

            } else {
              this.encfacService.insertexporadinew(item, 'ACT');
              this.auditoriagral.registrarAuditoria('VEN_EXPOFAC',
                item.ENCFAC_NUMERO + '/VEN', 'A',
                '', '01', '', '', '', '').subscribe();
              console.log('actualiza');
            };
            this.cancelar();


          });
        }
      }
    }
    this.cancelar();
    //////GSRF
  }

  async eliminar() {
    // Verifica los permisos
    if (this.permisoFactura !== null) {
      if (this.permisoFactura.PERTODO === 0) {
        if (this.permisoFactura.PERELIMACION === 0) {
          this.messageService.add({
            key: 'encfac',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encfacService.usuario.identificacion
              + ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }

    const strNumFact = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;

    const NOELIMFACI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOELIMFACI').CFG_VALOR1;
    if (NOELIMFACI === 1) {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_IMPRESO) {
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Error',
          detail: 'No se puede eliminar una Factura'
            + ' que ya está impresa.'
        });
      }
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede eliminar una Factura'
          + ' que está anulada.'
      });
      return;
    }
    ///////cambio autorizacion
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== null) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede eliminar una Factura'
          + ' que tiene autorización.'
      });
      return;
    }

    if (strNumFact !== undefined && strNumFact !== null) {
      if (strNumFact.length > 0) {
        this.encfacService.existePagosCxC(strNumFact).subscribe(pagos => {
          if (pagos['0']['COUNT(TRNDOCUMENTO_NRODOC)'] > 0) {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Error',
              detail: 'La factura tiene registrado pagos.'
            });
          } else {
            this.confirmationService.confirm({
              message: 'Esta seguro que desea eliminar la Factura ' + strNumFact,
              header: 'Eliminar Factura',
              icon: 'pi pi-exclamation-triangle',
              key: 'confirmarencfac',
              accept: () => {
                this.confirmationService.close();
                this.extraerPedidos(strNumFact);
                this.extraerNumeroPedidos(strNumFact, 'E');
                this.extraerGuiasRem(strNumFact, 'E');
                this.extraerNumeroGuias(strNumFact, 'E');
                this.extraerNumeroOrdenesTrabajo(strNumFact);
                ///GSRF
                this.encfacService.deletfacexp(strNumFact).subscribe(() => { });
                ///GSRF
                this.encfacService.eliminarFactura(strNumFact).subscribe(() => {
                  this.verificarActualizarEstPed();
                  this.actualizarFechaEntregaPed();
                  this.actualizarDespachoPed();

                  this.actualizarDespachoGuiaRem();
                  this.verificarActualizarEstGuia();

                  if (this.intACTPEDVAL === 1) {
                    this.verificarActualizarEstPedFactu();
                    this.actualizarFacturadoPed();
                  }

                  // verificarActualizarMatricula();
                  if (this.encfacService.encfacarray[this.indicador].ASI_NRO !== null) {
                    if (this.encfacService.encfacarray[this.indicador].ASI_NRO.length > 0) {
                      const ELIASIELI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ELIASIELI').CFG_VALOR1;
                      if (ELIASIELI === 0) {
                        this.encfacService.anularAsientoContable(this.encfacService.encfacarray[this.indicador].ASI_NRO);
                      } else
                        this.encfacService.eliminarAsientoContable(this.encfacService.encfacarray[this.indicador].ASI_NRO);
                    }
                  }


                  this.encfacService.eliminarSeriales(strNumFact, 'VEN', 0, '', '');

                  this.auditoriagral.registrarAuditoria('VEN_ENCFAC',
                    this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'E',
                    '', '01', '', '', '', '').subscribe();

                  this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO = '';
                  this.cancelar();
                  ///GSRF
                  this.chkactivo = false;
                  this.limpiaexport();
                  ///GSRF
                  this.messageService.add({
                    key: 'encfac',
                    severity: 'success',
                    summary: 'Eliminar Factura',
                    detail: 'La factura se eliminó correctamente'
                  });
                }, error1 => {
                  let mensaje: string;
                  mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                  this.messageService.add({
                    key: 'encfac',
                    severity: 'error',
                    summary: 'Eliminación de Factura',
                    detail: mensaje
                  });
                });
              },
              reject: () => {
                this.confirmationService.close();
              }
            });
          }
        });
      } else {
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Error',
          detail: 'No hay un documento para la eliminación.'
        });
      }
    } else {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No hay un documento para la eliminación.'
      });
    }
  }

  limpiartablas() {
    this.tipodetalle = this.TIPDET === null || this.TIPDET === undefined ? 'A' : this.TIPDET;
    this.bolCliente = false;
    this.nuevaFactura();
    this.limpiarDatosFactura();
    // document.getElementById('contornodetalle').style.pointerEvents = 'all';
    this.aggrid.habilitarGrid();
  }

  limpiarDatosFactura() {
    // selecciona el primer item de la lista de precios
    if (this.cmblistasprecio === undefined) {
      this.llenarCombos();
    }

    if (this.cmblistasprecio.length > 0) {
      const j = this.cmblistasprecio[0];
      this.selectlistasprecio = { codigo: j.codigo, name: j.name };
      this.encfacService.encfacarray[this.indicador].ENCFAC_LISTA = j.codigo;
    }

    // coloca la bodega por defecto según la configuración
    this.obtenerBodegaDefecto();

    for (const k of this.cmbivaaux) {
      if (k.codigo === '2') {
        this.selectiva = { codigo: k.codigo, name: k.name };
        this.encfacService.encfacarray[this.indicador].ENCFAC_IVA = k.codigo;
      }
    }

    this.selectiva = this.cmbivaaux[0];
    this.encfacService.encfacarray[this.indicador].ENCFAC_IVA = this.selectiva.codigo;

    if (this.cmbtipofactura.length > 0) {
      const j = this.cmbtipofactura[0];
      this.selecttipofactura = { codigo: j.codigo, name: j.name };
      this.encfacService.encfacarray[this.indicador].ENCFAC_TIPOFACTURA = j.codigo;
    }

    // for (const k of this.cmbporceiva) {
    //   if (k.codigo === '12') {
    //     this.selectporceiva = { codigo: k.codigo, name: k.name };
    //     this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEIVA = k.codigo;
    //   }
    // }

    const SELTIPIVA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'SELTIPIVA').CFG_VALOR1;
    // permitir seleccionar el iva
    if (SELTIPIVA === 1) {
      this.disablediva = false;
    }


    if (this.NUMITEMFAC > 0) {
      this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES = this.NUMITEMFAC;
    }

    this.bolestcta = false;
    this.bolsaldo = false;
  }

  obtenerBodegaDefecto() {
    let strBodCodigo = '';
    const BODDEFUSU = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BODDEFUSU').CFG_VALOR1;
    // colocar la bodega de sacimaereferencia
    if (BODDEFUSU === 0) {
      this.encfacService.erObtenerBodegaDefecto().subscribe(data => {
        strBodCodigo = data[0].REF_CODIGO;
        for (const h of this.cmbbodega) {
          if (strBodCodigo === h.codigo) {
            this.selectbodega = { codigo: h.codigo, name: h.name };
            this.encfacService.encfacarray[this.indicador].BOD_CODIGO = h.codigo;
          }
        }
      });
    } else { // coloca la bodega del maestro de usuarios
      this.encfacService.getBodegaUsuario().subscribe(rs => {
        for (const r of rs) {
          strBodCodigo = r.BOD_CODIGO === null ? '' : r.BOD_CODIGO;
        }
        for (const h of this.cmbbodega) {
          if (strBodCodigo === h.codigo) {
            this.selectbodega = { codigo: h.codigo, name: h.name };
            this.encfacService.encfacarray[this.indicador].BOD_CODIGO = h.codigo;
          }
        }
      });
    }
  }

  nuevaFactura() {
    let lblNumeroDoc = '';

    if (this.encfacService.encfacarray[this.indicador] !== undefined) {
      lblNumeroDoc = this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO;
    }

    let bodega = '';
    if (this.selectbodega !== undefined ||
      this.selectbodega !== null) {
      bodega = this.selectbodega.codigo;
    }
    this.llenarcombo()
    this.encfacService.encfacarray[this.indicador] = {
      ENCFAC_NUMERO: '',
      COM_CODIGO: '',
      ENCFAC_FECHAEMISION: '',
      ENCFAC_FECHAENTREGA: '',
      ENCFAC_IVA: '',
      ENCFAC_ESTADO: '',
      CLI_CODIGO: '',
      CLI_NOMBRE: '',
      BOD_CODIGO: bodega,
      VEN_CODIGO: '',
      ENCFAC_REFERENCIA: '',
      ENCFFA_CODIGO: '',
      ENCFAC_FORMAPAGO: '',
      ENCFAC_TIPO: '',
      ENCFAC_LISTA: '',
      ENCFAC_FECHAVENCIMIENTO: '',
      ENCFAC_TOTAL: '',
      ENCFAC_TOTALNETO: '',
      ENCFAC_VALORDES: '',
      ENCFAC_PORCEDES: '',
      ENCFAC_VALORIVA: '',
      ENCFAC_PORCEIVA: Number(this.selectcombtipoIva.name),
      ENCFAC_VALORICE: '',
      ENCFAC_PORCEICE: '',
      ENCFAC_BASEIVA: '',
      ENCFAC_BASEICE: '',
      ENCFAC_BASECERO: '',
      ENCFAC_PORCECAR: '',
      ENCFAC_VALORCAR: '',
      ENCFAC_PORCERTC: '',
      ENCFAC_VALORRTC: '',
      ENCFAC_VALORDEV: '',
      ENCFAC_BASEIMP: '',
      ENCFAC_PORCEINT: '',
      ENCFAC_VALORINT: '',
      ENCFAC_PORCEREC: '',
      ENCFAC_VALORREC: '',
      ENCFAC_IMPRESO: '',
      ENCFAC_COMENTARIO: '',
      ENCFAC_TIPOFAC: '',
      ENCFAC_GENERAFAC: '',
      ENCFAC_NUMERODOB: '',
      ENCFAC_DIRECCION: '',
      ENCFAC_TELEFONO: '',
      ENCFAC_RUCIDE: '',
      ENCFAC_CHOFER: '',
      ENCFAC_CARRO: '',
      ENCFAC_PLACA: '',
      ENCFAC_GRUPO: lblNumeroDoc,
      ENCFAC_OTROS: '',
      ENCFAC_NEMONICO: '',
      ENCFAC_SERIE: '',
      ENCFAC_SECACTUAL: '',
      ASI_NRO: '',
      ENCFAC_DIASCRDT: '',
      ENCFAC_CLIDSCT: '',
      USU_IDENTIFICACION: '',
      ENCFAC_OBSERVACION: '',
      ENCFAC_FLAG: '',
      ENCFAC_TIPODSCTO: '',
      ENCFAC_ORIGEN: '',
      ENCFAC_REFCLI: '',
      ENCFAC_DIRECCION2: '',
      ENCFAC_TELEFONO2: '',
      ENCFAC_FAX: '',
      ENCFAC_CORREO: '',
      ENCFAC_FLAGMOD: '',
      ENCFAC_FECHASERVIDOR: '',
      ENCFAC_NOTADEVENTA: '',
      MAEMOTIVO_CODIGO: '',
      ENCFAC_VALORSERCLI: '',
      ENCFAC_PORCESERCLI: '',
      ENCFAC_AUTORIZACION: '',
      ENCFAC_ORGIMP: '',
      ENCFAC_NOMBRE: '',
      ENCFAC_JEFEZONA: '',
      ENCFAC_CAMPANIA: '',
      ENCFAC_NUMPUNTOS: '',
      ENCFAC_ESTADO_FE: '',
      ENCFAC_AUTORIZACION_FE: '',
      ENCFAC_CLAVEACCESO_FE: '',
      ENCFAC_FECHAAUT_FE: '',
      ENCFAC_NUMFACTELEC: '',
      COM_TIPOAMBFACTELEC: '',
      ENCFAC_PATHXMLNOAUTO_FE: '',
      ENCFAC_PATHPDF_FE: '',
      ENCFAC_FACTEXPO: '',
      ENCFAC_BASENOOBJIVA: '',
      ENCFAC_TIPOFACTURA: '',
      ENCFAC_CODMEDIGO: '',
      ENCFAC_NOMMEDICO: '',
      ENCFAC_DESCUENTO: '',
      ENCFAC_CODMEDICO: '',
      ENCFAC_PLACA_CLI: '',
      ENCFAC_SALDOCLIENTE: '',
      ENCFAC_HORAEMISION: '',
      VEN_NOMBRE: '',
      ENCFAC_REFERENCIANOMBRE: '',
      ENCFAC_ITEMSDISPONIBLES: this.NUMITEMFAC,
      ///GSRF
      COMERCIOEXTERIOR: '',
      INCOTERMFACTURA: '',
      LUGARINCOTERM: '',
      PAISORIGEN: '',
      PUERTOEMBARQUE: '',
      PUERTODESTINO: '',
      PAISDESTINO: '',
      PAISADQUISICION: '',
      DIRCOMPRADOR: '',
      INCOTERMTOTALSINIMPUESTOS: '',
      FLETEINTERNACIONAL: '',
      SEGUROINTERNACIONAL: '',
      GASTOSADUANEROS: '',
      GASTOSTRANSPORTEOTROS: '',
      FORMAPAGO: '',
      CLI_TIPOIDE: '',
      CLI_RUCIDE: '',
      CLI_PARTEREL: '',
      CLI_TIPO: '',
      CLI_NOMBREXP: '',

      ENCFAC_FECHAEMBARQUE: '',
      ENCFAC_FECHAEMISIONEXP: '',
      PUNTOEMISION: '',
      ESTABLECIMIENTO: '',

      TPIDCLIENTEEX: '',
      PARTERELEXP: '',
      DENOEXPCLI: '',
      TIPOREGI: '',
      PAISEFECPAGOGEN: '',
      PAISEFECEXP: '',
      EXPORTACIONDE: '',
      DISTADUANERO: '',
      ANIO: '',
      REGIMEN: '',
      CORRELATIVO: '',
      DOCTRANSP: '',
      FECHAEMBARQUE: '',
      VALORFOB: '',
      VALORFOBCOMPROBANTE: '',
      TIPOINGRESO: '',
      INGGRAVADOEXT: '',
      VALORIMPEXT: '',
      TIPOCOMPROBANTE: '',
      codigoPaisRegimen: '',
      codigoPaisParaiso: '',
      nombrePaisSeleccionado: '',
      nombrePaisSeleccionado1: '',
      ENCFAC_OTRDSCTOS: '',
      ENCFAC_OTRDSCTOSBASEDIF: '',
      ENCFAC_OTRDSCTOSBASECERO: ''
      ///GSRF
    };
    this.SELECTIPOREGI = '';
    this.SELECTEXPORTACIONDE = '';
    this.encfacService.detfacarray[this.indicador] = [];
    console.log(this.SELECTIPOREGI);
    console.log(this.SELECTEXPORTACIONDE);

  }

  async obtenerNumFact() {
    let intTamanioLista = 0;
    let intNumFinal = 0;
    let intNumActual = 0;
    let strNumActual = '';
    let strTipoDoc = '';
    let strNumAut = '';
    let strTipoNum = 'FAC';
    let strFechaCad = '';
    let item: SaciMaenumeracionG;
    let intTamNumFact = 0;
    let strNumDoc = '';
    let strSerie = '';
    let strNumFinal = '';
    let strNemonico = '';
    const NUMFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMFAC').CFG_VALOR3;
    const NUMPTOUSU = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMPTOUSU').CFG_VALOR1;
    this.numfac = NUMFAC;
    if (NUMPTOUSU === 1) {
      const data = await this.encfacService.obtenerNumeracionRef(this.usuario.identificacion)
      if (data !== null && data !== undefined && data.length > 0) {
        for (const rs of data) {
          this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO = rs.NUM_CLASE
        }
        let strnumdoc2 = ''
        const data1 = <any>await this.encfacService.erObtenerNumFact(this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO).toPromise()//.subscribe(async er => {
        for (const er of data1) {
          if (er !== null) {
            if (er !== undefined) {

              const itemNum = er;
              strNumActual = itemNum.NUM_SECACTUAL;
              strSerie = itemNum.NUM_SERIE;
              strNumFinal = itemNum.NUM_SECFINAL;
              strNemonico = itemNum.NUM_NEMONICO;
              strTipoDoc = itemNum.NUM_DOCREF;
              strNumAut = itemNum.NUM_AUTORIZACION;
              strFechaCad = itemNum.NUM_FECHACAD;

              if (strNumFinal.length > 0) {
                intNumFinal = Number(strNumFinal);
              }
              if (strNumActual.length > 0) {
                intTamanioLista = 1;
                intNumActual = Number(strNumActual);
              }


              intTamNumFact = strNumActual.length; // tamaño del número
              // secuencial de la factura
              strNumDoc = strNemonico + strSerie + strNumActual;
              strnumdoc2 = strSerie + strNumActual;
              this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO = strNemonico;
              this.encfacService.encfacarray[this.indicador].ENCFAC_SERIE = strSerie;
              this.encfacService.encfacarray[this.indicador].ENCFAC_SECACTUAL = strNumActual;

              // this.obtenerNumFact2(strFechaCad, strTipoDoc, strNumAut, intTamanioLista,
              //   intNumActual, intNumFinal, strNumDoc);
              //CDPJ
              if (strnumdoc2.length !== 15) {
                this.messageService.add({
                  key: 'encfac',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'El número de la factura debe tener 15 dígitos' +
                    ' entre la serie más el número actual ' +
                    ' consulte la numeración por Punto de venta.'
                });
                document.getElementById('contorno').style.pointerEvents = 'none';
                // document.getElementById('contornodetalle').style.pointerEvents = 'none';
                this.aggrid.bloquearGrid();
                this.bolean = true;
                return;
              } else {
                this.bolean = false
                await this.obtenerNumFact2(strFechaCad, strTipoDoc, strNumAut, intTamanioLista,
                  intNumActual, intNumFinal, strNumDoc);
                //CDPJ

                if (this.CNAFEPV === 1) {
                  itemNum.NUM_FE = itemNum.NUM_FE === null || itemNum.NUM_FE === undefined ? 0 : itemNum.NUM_FE
                  console.log(itemNum.NUM_FE)
                  if (itemNum.NUM_FE === 1) {
                    this.botonXML = false;
                  } else {
                    this.botonXML = true;
                  }
                }
                //CDPJ
              }

              //CDPJ

            }
          }
        }//);
      } else {
        if (NUMFAC === 'G') { // numeración
          // general(clase
          // de
          // doc)
          if (this.chknotadeventa === true) {
            strTipoNum = 'NV';
            strTipoDoc = 'NV';
          }
          this.encfacService.obtenerNumeracionG(strTipoNum).subscribe(num => {
            for (const n of num) {
              intTamanioLista = num.length;
              item = n;
            }

            this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO = '';
            this.encfacService.encfacarray[this.indicador].ENCFAC_AUTORIZACION = item.NUM_AUTORIZACION;
            this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO = '';
            this.encfacService.encfacarray[this.indicador].ENCFAC_SERIE = item.NUM_SERIE;
            this.encfacService.encfacarray[this.indicador].ENCFAC_SECACTUAL = item.NUM_SECACTUAL;

            intNumActual = Number(item.NUM_SECACTUAL);
            intNumFinal = Number(item.NUM_SECFINAL);
            intTamNumFact = item.NUM_SECACTUAL.length;
            strNumAut = item.NUM_AUTORIZACION;
            strFechaCad = item.NUM_FECHACAD;
            strNumDoc = item.NUM_SERIE
              + item.NUM_SECACTUAL;
            strSerie = item.NUM_SERIE;
            //CDPJ
            if (strNumDoc.length !== 15) {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'El número de la factura debe tener 15 dígitos' +
                  ' entre la serie más el número actual ' +
                  ' consulte la numeración por Clase.'
              });
              document.getElementById('contorno').style.pointerEvents = 'none';
              // document.getElementById('contornodetalle').style.pointerEvents = 'none';
              this.aggrid.bloquearGrid();
              return;
            } else {
              this.obtenerNumFact2(strFechaCad, strTipoDoc, strNumAut, intTamanioLista,
                intNumActual, intNumFinal, strNumDoc);
            }

            //CDPJ
          });
        } else if (NUMFAC === 'C') { // numeración
          // por
          // punto
          // de
          // venta
          if (this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO === undefined) {
            this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO = '';
          }

          if (this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO.length === 0) {
            this.busquedanumeracion();
          } else {
            let strnumdoc2 = ''
            this.encfacService.erObtenerNumFact(this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO).subscribe(async er => {
              if (er !== null) {
                if (er[0] !== undefined) {
                  const itemNum = er[0];
                  strNumActual = itemNum.NUM_SECACTUAL;
                  strSerie = itemNum.NUM_SERIE;
                  strNumFinal = itemNum.NUM_SECFINAL;
                  strNemonico = itemNum.NUM_NEMONICO;
                  strTipoDoc = itemNum.NUM_DOCREF;
                  strNumAut = itemNum.NUM_AUTORIZACION;
                  strFechaCad = itemNum.NUM_FECHACAD;

                  if (strNumFinal.length > 0) {
                    intNumFinal = Number(strNumFinal);
                  }
                  if (strNumActual.length > 0) {
                    intTamanioLista = 1;
                    intNumActual = Number(strNumActual);
                  }


                  intTamNumFact = strNumActual.length; // tamaño del número
                  // secuencial de la factura
                  strNumDoc = strNemonico + strSerie + strNumActual;
                  strnumdoc2 = strSerie + strNumActual;
                  this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO = strNemonico;
                  this.encfacService.encfacarray[this.indicador].ENCFAC_SERIE = strSerie;
                  this.encfacService.encfacarray[this.indicador].ENCFAC_SECACTUAL = strNumActual;

                  // this.obtenerNumFact2(strFechaCad, strTipoDoc, strNumAut, intTamanioLista,
                  //   intNumActual, intNumFinal, strNumDoc);
                  //CDPJ
                  if (strnumdoc2.length !== 15) {
                    this.messageService.add({
                      key: 'encfac',
                      severity: 'error',
                      summary: 'Información',
                      detail: 'El número de la factura debe tener 15 dígitos' +
                        ' entre la serie más el número actual ' +
                        ' consulte la numeración por Punto de venta.'
                    });
                    document.getElementById('contorno').style.pointerEvents = 'none';
                    // document.getElementById('contornodetalle').style.pointerEvents = 'none';
                    this.aggrid.bloquearGrid();
                    this.bolean = true;
                    return;
                  } else {
                    this.bolean = false
                    await this.obtenerNumFact2(strFechaCad, strTipoDoc, strNumAut, intTamanioLista,
                      intNumActual, intNumFinal, strNumDoc);
                    //CDPJ

                    if (this.CNAFEPV === 1) {
                      itemNum.NUM_FE = itemNum.NUM_FE === null || itemNum.NUM_FE === undefined ? 0 : itemNum.NUM_FE
                      console.log(itemNum.NUM_FE)
                      if (itemNum.NUM_FE === 1) {
                        this.botonXML = false;
                      } else {
                        this.botonXML = true;
                      }
                    }
                    //CDPJ
                  }

                  //CDPJ

                }
              }
            });

          }
        }
      }

      return;
    }

    if (NUMFAC === 'G') { // numeración
      // general(clase
      // de
      // doc)
      if (this.chknotadeventa === true) {
        strTipoNum = 'NV';
        strTipoDoc = 'NV';
      }
      this.encfacService.obtenerNumeracionG(strTipoNum).subscribe(num => {
        for (const n of num) {
          intTamanioLista = num.length;
          item = n;
        }

        this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO = '';
        this.encfacService.encfacarray[this.indicador].ENCFAC_AUTORIZACION = item.NUM_AUTORIZACION;
        this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO = '';
        this.encfacService.encfacarray[this.indicador].ENCFAC_SERIE = item.NUM_SERIE;
        this.encfacService.encfacarray[this.indicador].ENCFAC_SECACTUAL = item.NUM_SECACTUAL;

        intNumActual = Number(item.NUM_SECACTUAL);
        intNumFinal = Number(item.NUM_SECFINAL);
        intTamNumFact = item.NUM_SECACTUAL.length;
        strNumAut = item.NUM_AUTORIZACION;
        strFechaCad = item.NUM_FECHACAD;
        strNumDoc = item.NUM_SERIE
          + item.NUM_SECACTUAL;
        strSerie = item.NUM_SERIE;
        //CDPJ
        if (strNumDoc.length !== 15) {
          this.messageService.add({
            key: 'encfac',
            severity: 'error',
            summary: 'Información',
            detail: 'El número de la factura debe tener 15 dígitos' +
              ' entre la serie más el número actual ' +
              ' consulte la numeración por Clase.'
          });
          document.getElementById('contorno').style.pointerEvents = 'none';
          // document.getElementById('contornodetalle').style.pointerEvents = 'none';
          this.aggrid.bloquearGrid();
          return;
        } else {
          this.obtenerNumFact2(strFechaCad, strTipoDoc, strNumAut, intTamanioLista,
            intNumActual, intNumFinal, strNumDoc);
        }

        //CDPJ
      });
    } else if (NUMFAC === 'C') { // numeración
      // por
      // punto
      // de
      // venta
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO === undefined) {
        this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO = '';
      }

      if (this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO.length === 0) {
        this.busquedanumeracion();
      } else {
        let strnumdoc2 = ''
        this.encfacService.erObtenerNumFact(this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO).subscribe(async er => {
          if (er !== null) {
            if (er[0] !== undefined) {
              const itemNum = er[0];
              strNumActual = itemNum.NUM_SECACTUAL;
              strSerie = itemNum.NUM_SERIE;
              strNumFinal = itemNum.NUM_SECFINAL;
              strNemonico = itemNum.NUM_NEMONICO;
              strTipoDoc = itemNum.NUM_DOCREF;
              strNumAut = itemNum.NUM_AUTORIZACION;
              strFechaCad = itemNum.NUM_FECHACAD;

              if (strNumFinal.length > 0) {
                intNumFinal = Number(strNumFinal);
              }
              if (strNumActual.length > 0) {
                intTamanioLista = 1;
                intNumActual = Number(strNumActual);
              }


              intTamNumFact = strNumActual.length; // tamaño del número
              // secuencial de la factura
              strNumDoc = strNemonico + strSerie + strNumActual;
              strnumdoc2 = strSerie + strNumActual;
              this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO = strNemonico;
              this.encfacService.encfacarray[this.indicador].ENCFAC_SERIE = strSerie;
              this.encfacService.encfacarray[this.indicador].ENCFAC_SECACTUAL = strNumActual;

              // this.obtenerNumFact2(strFechaCad, strTipoDoc, strNumAut, intTamanioLista,
              //   intNumActual, intNumFinal, strNumDoc);
              //CDPJ
              if (strnumdoc2.length !== 15) {
                this.messageService.add({
                  key: 'encfac',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'El número de la factura debe tener 15 dígitos' +
                    ' entre la serie más el número actual ' +
                    ' consulte la numeración por Punto de venta.'
                });
                document.getElementById('contorno').style.pointerEvents = 'none';
                // document.getElementById('contornodetalle').style.pointerEvents = 'none';
                this.aggrid.bloquearGrid();
                this.bolean = true;
                return;
              } else {
                this.bolean = false
                await this.obtenerNumFact2(strFechaCad, strTipoDoc, strNumAut, intTamanioLista,
                  intNumActual, intNumFinal, strNumDoc);
                //CDPJ

                if (this.CNAFEPV === 1) {
                  itemNum.NUM_FE = itemNum.NUM_FE === null || itemNum.NUM_FE === undefined ? 0 : itemNum.NUM_FE
                  console.log(itemNum.NUM_FE)
                  if (itemNum.NUM_FE === 1) {
                    this.botonXML = false;
                  } else {
                    this.botonXML = true;
                  }
                }
                //CDPJ
              }

              //CDPJ

            }
          }
        });

      }
    }
  }

  async obtenerNumFact2(strFechaCad, strTipoDoc, strNumAut, intTamanioLista,
    intNumActual, intNumFinal, strNumDoc) {
    console.log(intNumActual);
    console.log('final', intNumFinal)
    const strFecha = new Date();
    const NUMGUAFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMGUAFAC').CFG_VALOR1;

    // fecha de caducidad
    if (strFechaCad !== null && strFechaCad.length > 0) {
      if (new Date(strFechaCad) < strFecha) {
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Información',
          detail: 'La numeración de la factura está caducada'
        });

        this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO = '';
        this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO = '';
        this.encfacService.encfacarray[this.indicador].ENCFAC_SERIE = '';
        this.encfacService.encfacarray[this.indicador].ENCFAC_SECACTUAL = '';

        if (strTipoDoc === 'NV') {
          this.chknotadeventa = false;
        }
        document.getElementById('contorno').style.pointerEvents = 'none';
        // document.getElementById('contornodetalle').style.pointerEvents = 'none';
        this.aggrid.bloquearGrid();

        this.bolean = true;//CDPJ
        return;
      } else {
        this.bolean = false;//CDPJ
      }
    }

    // número de autorización
    if (strNumAut !== null) {
      this.encfacService.encfacarray[this.indicador].ENCFAC_AUTORIZACION = strNumAut;
    }

    if (intTamanioLista === 0) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'No existe una numeración para la Factura. '
          + 'Consulte con el Administrador.'
      });
      document.getElementById('contorno').style.pointerEvents = 'none';
      // document.getElementById('contornodetalle').style.pointerEvents = 'none';
      this.aggrid.bloquearGrid();

      this.bolean = true;//CDPJ
      return;
    } else if (intNumActual >= intNumFinal) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Se terminó el secuencial de la Factura.'
      });
      document.getElementById('contorno').style.pointerEvents = 'none';
      // document.getElementById('contornodetalle').style.pointerEvents = 'none';
      this.aggrid.bloquearGrid();

      this.bolean = true;//CDPJ
      return;
    } else {
      this.limpiarDatosFactura();
      // COLOCAR EL CHECK EN NOTA DE VENTA
      this.chknotadeventa = strTipoDoc === 'NV';
      //GSRF
      const FCPRINIVA = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCPRINIVA').CFG_VALOR1;
      if (FCPRINIVA === 1) {
        this.selectiva = { codigo: this.cmbivaaux[1].codigo, name: this.cmbivaaux[1].name };
      } else {
        this.selectiva = { codigo: this.cmbivaaux[2].codigo, name: this.cmbivaaux[2].name };
      }
      //GSRF
      console.log('1.111')
      document.getElementById('CLI_CODIGO').focus();
      document.getElementById('contorno').style.pointerEvents = 'all';
      // document.getElementById('contornodetalle').style.pointerEvents = 'all';
      this.aggrid.habilitarGrid();
      if (NUMGUAFAC === 0) {
        this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO = strNumDoc;
      }
      this.bolean = false;//CDPJ
    }
  }

  cancelar() {
    this.bolNuevodet = false;
    if (this.index < 2) {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = true;
    }
    const encfacnumero = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
    this.limpiartablas();
    this.encontrarFactura(encfacnumero, '');
  }

  onHideEvent() {
    this.cancelar();
  }

  async manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'factura') {
      this.encontrarFactura(opcion.ENCFAC_NUMERO, '');
      if (this.index < 2) {
        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = false;
        this.botonRegresar = true;
      }
    }

    if (this.opcionbusqueda === 'numeracion') {
      let intNumActual = 0;
      let intNumFinal = 0;
      let strNumActual = '';
      let strNemonico = '';
      let strSerie = '';
      let strTipoDoc = '';
      let strNumAut = '';
      let strFechaCad = '';
      this.encfacService.erGetNumeracion(opcion.NUM_CLASE).subscribe(data => {
        if (data !== null) {
          if (data[0] !== undefined) {
            const itemNum = data[0];
            const intTamanioLista = opcion.NUM_SECACTUAL.length > 0 ? 1 : 0;
            if (intTamanioLista > 0) {
              intNumActual = Number(itemNum.NUM_SECACTUAL);
              intNumFinal = Number(itemNum.NUM_SECFINAL);
              strNumActual = itemNum.NUM_SECACTUAL;
              strNemonico = itemNum.NUM_NEMONICO;
              strSerie = itemNum.NUM_SERIE;
              strTipoDoc = itemNum.NUM_DOCREF;
              strNumAut = itemNum.NUM_AUTORIZACION;
              strFechaCad = itemNum.NUM_FECHACAD;
              this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO = opcion.NUM_CLASE !== null ? opcion.NUM_CLASE : '';
            } else {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'Debe escoger una numeración.'
              });
              document.getElementById('contorno').style.pointerEvents = 'none';
              // document.getElementById('contornodetalle').style.pointerEvents = 'none';
              this.aggrid.bloquearGrid();
              return;
            }

            // secuencial de la factura
            const strNumDoc = strNemonico + strSerie + strNumActual;
            const strnumdoc1 = strSerie + strNumActual;
            this.encfacService.encfacarray[this.indicador].ENCFAC_NEMONICO = strNemonico;
            this.encfacService.encfacarray[this.indicador].ENCFAC_SERIE = strSerie;
            this.encfacService.encfacarray[this.indicador].ENCFAC_SECACTUAL = strNumActual;
            if (strnumdoc1.length !== 15) {

              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'El número de la factura debe tener 15 dígitos' +
                  ' entre la serie más el número actual ' +
                  ' consulte la numeración por Punto de venta.'
              });
              document.getElementById('contorno').style.pointerEvents = 'none';
              // document.getElementById('contornodetalle').style.pointerEvents = 'none';
              this.aggrid.bloquearGrid();
              return;
            } else {
              this.obtenerNumFact2(strFechaCad, strTipoDoc, strNumAut, intTamanioLista,
                intNumActual, intNumFinal, strNumDoc);
              //CDPJ
              if (this.CNAFEPV === 1) {
                itemNum.NUM_FE = itemNum.NUM_FE === null || itemNum.NUM_FE === undefined ? 0 : itemNum.NUM_FE
                console.log(itemNum.NUM_FE)
                if (itemNum.NUM_FE === 1) {
                  this.botonXML = false;
                } else {
                  this.botonXML = true;
                }
              }
              //CDPJ
            }

          } else {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Información',
              detail: 'Debe escoger una numeración.'
            });
            document.getElementById('contorno').style.pointerEvents = 'none';
            // document.getElementById('contornodetalle').style.pointerEvents = 'none';
            this.aggrid.bloquearGrid();
          }
        } else {
          this.messageService.add({
            key: 'encfac',
            severity: 'error',
            summary: 'Información',
            detail: 'Debe escoger una numeración.'
          });
          document.getElementById('contorno').style.pointerEvents = 'none';
          // document.getElementById('contornodetalle').style.pointerEvents = 'none';
          this.aggrid.bloquearGrid();
        }
      });
    }

    if (this.opcionbusqueda === 'cliente') {
      const data = await this.encfacService.obtenernombregrucli(opcion.GRU_CODIGO)
      console.log(data)
      if (data.length > 0) {
        if (data !== null && data !== undefined && data !== '') {
          if (data[0] !== null && data[0] !== undefined && data[0] !== '') {
            this.encfacService.grunombre = data[0].GRU_NOMBRE === '' || data[0].GRU_NOMBRE === null || data[0].GRU_NOMBRE === undefined ? 'No Existe el grupo' : data[0].GRU_NOMBRE;
          }
        }
      }
      this.encfacService.encfacarray[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;
      this.encfacService.encfacarray[this.indicador].CLI_NOMBRE = opcion.CLI_NOMBRE;
      this.bolNuevodet = false;//GSRF
      if (this.ACCCFV === 1) {
        this.encfacService.encfacarray[this.indicador].ENCFAC_COMENTARIO = opcion.CLI_CONTACTO === null || opcion.CLI_CONTACTO === undefined ? '' : opcion.CLI_CONTACTO
      }
      this.tmpdatoscliente = opcion;
      if (this.encfacService.encfacarray[this.indicador].CLI_CODIGO.length > 0) {
        const CNTRLESCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CNTRLESCLI').CFG_VALOR1;
        if (CNTRLESCLI === 1) { // dejar facturar al cliente  si está desactivado
          this.controlarEstadoCliente(opcion);
        } else {
          this.clienteSeleccionado(opcion);
        }
      }
    }
    if (this.opcionbusqueda === 'vendedor') {
      this.encfacService.encfacarray[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO;
      this.encfacService.encfacarray[this.indicador].VEN_NOMBRE = opcion.VEN_NOMBRE;
      document.getElementById('ENCFAC_REFERENCIA').focus();
    }
    if (this.opcionbusqueda === 'agPed') {
      this.agregarDetallePedido(opcion);
    }

    if (this.opcionbusqueda === 'agOT') {
      this.consultarOrdTra(opcion.ORDTRA_NUMERO);
    }
    ///GSRF
    if (this.opcionbusqueda === 'BUSQUEDARG') {
      this.encfacService.encfacarray[this.indicador].codigoPaisRegimen = opcion.CODSRI_CODIGO;
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado = opcion.CODSRI_DESCRIPCION;
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado1 = opcion.CODSRI_DESCRIPCION;
      this.encfacService.encfacarray[this.indicador].PAISEFECEXP = opcion.CODSRI_CODIGO;
      this.displayDialogBusquedaFast = false;
    }
    if (this.opcionbusqueda === 'BUSQUEDAPF') {
      this.encfacService.encfacarray[this.indicador].codigoPaisParaiso = opcion.CODSRI_CODIGO;
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado = opcion.CODSRI_DESCRIPCION;
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado1 = opcion.CODSRI_DESCRIPCION;
      this.encfacService.encfacarray[this.indicador].PAISEFECEXP = opcion.CODSRI_CODIGO;
      this.displayDialogBusquedaFast = false;
    }
    if (this.opcionbusqueda === 'BUSQUEDAEX') {
      this.encfacService.encfacarray[this.indicador].PAISEFECEXP = opcion.CODSRI_CODIGO;
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado1 = opcion.CODSRI_DESCRIPCION;
      this.displayDialogBusquedaFast = false;
    }
    ///GSRF
    if (this.opcionbusqueda === 'agGui') {
      this.encfacService.consultarGuia(opcion.ENCGRE_CODIGO, this.encfacService.encfacarray[this.indicador]).subscribe(async data => {
        let intTamLista = data.length;
        let intdetactual = Number(this.encfacService.detfacarray[this.indicador].length);
        let fila = 1;
        for (const rs of data) {
          const dblCantFac = rs.CANTARTFACDEGUIAREM;
          const dblCantPend = rs.DETGRE_CANTIDAD - dblCantFac; // TOTAL DESPACHADO
          const dblCantFacActual = this.obtenerCantFacActual(rs.DETGRE_CODIGO, 0, rs.DETGRE_LINEA,
            rs.ENCGRE_CODIGO, ''); // cantidad en los otros items de la factura
          //GSRF
          this.encfacService.consulvenped(rs.ENCGRE_CODIGO, rs.ENCGRE_NUMERO).subscribe(data1 => {
            console.log(data1[0].VENDEDOR);
            if (data1[0].VENDEDOR !== undefined && data1[0].VENDEDOR !== null && data1[0].VENDEDOR !== '') {
              console.log('aqui1');
              if (this.encfacService.encfacarray[this.indicador].VEN_CODIGO !== data1[0].VENDEDOR) {
                console.log('aqui2');
                this.confirmationService.confirm({
                  message: 'El codigo de vendedor del pedido es ' + data1[0].VENDEDOR + ' desea cambiarlo?',
                  header: 'Pregunta',
                  icon: 'pi pi-exclamation-triangle',
                  key: 'confirmarencfac',
                  acceptLabel: 'Si',
                  rejectLabel: 'No',
                  accept: () => {
                    this.confirmationService.close();
                    this.encfacService.encfacarray[this.indicador].VEN_CODIGO = data1[0].VENDEDOR
                    this.busquedavendedor(data1[0].VENDEDOR);
                  },
                  reject: () => {
                    this.confirmationService.close();
                  }
                });
              }
            }
          })
          //GSRF
          for (const k of this.cmbporceiva) {
            console.log(k.codigo, rs.ENCGRE_PORCEIVA);
            if (k.name === rs.ENCGRE_PORCEIVA) {
              this.selectcombtipoIva = { codigo: k.codigo, name: k.name };
              this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEIVA = k.name;
            }
          }
          if (dblCantPend - dblCantFacActual > 0 || rs.DETGRE_TIPODET === '*') {
            if (fila === 1) {
              intdetactual++;
              //this.crearNuevoItem();
              this.nuevodetalle();
              this.nuevoItem.DETFAC_TIPODET = '*';
              this.nuevoItem.DETFAC_DESCRIPCION = '*** Guía '
                + rs.ENCGRE_NUMERO + ' ***';

              this.nuevoItem.ENCGRE_NUMERO = rs.ENCGRE_CODIGO;
              this.nuevoItem.DETFAC_TRIBIVA = '';
              this.nuevoItem.DETFAC_TRIBIVAAUX = '';
              this.nuevoItem.DETFAC_LINEA = intdetactual;
              this.nuevoItem.DETFAC_ORDEN = fila;
              this.nuevoItem.DETFAC_PORIVA = 0;//CDPJ
              this.nuevoItem.TARIIVA_CODIGO = '-1';//CDPJ

              //this.encfacService.detfacarray[this.indicador].push(this.nuevoItem);
              fila = fila + 1;
            }

            intdetactual++;
            //this.crearNuevoItem();
            this.nuevodetalle();
            const item: VenDetfacZ = this.nuevoItem;
            intTamLista = data.length;

            item.DETFAC_TIPODET = rs.DETGRE_TIPODET;
            item.DETFAC_CODIGO = rs.DETGRE_CODIGO;
            item.DETFAC_DESCRIPCION = rs.DETGRE_DESCRIPCION;
            item.DETFAC_TRIBIVA = rs.DETGRE_TRIBIVA;
            item.DETFAC_TRIBIVAAUX = rs.DETGRE_TRIBIVA;
            item.DETFAC_TRIBICE = rs.DETGRE_TRIBICE;
            item.DETFAC_UNIDAD = rs.DETGRE_UNIDAD;
            item.DETFAC_LISTA = rs.DETGRE_LISTA;
            item.UNI_SIMBOLO = rs.DETGRE_UNIDAD;
            item.DETFAC_CANTIDAD = rs.DETGRE_CANTIDAD
              - dblCantFac - dblCantFacActual;

            const PREMAECLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PREMAECLI').CFG_VALOR1;
            if (PREMAECLI === 0) {
              item.DETFAC_PRECIO = rs.DETGRE_PRECIO;
            } else {
              item.DETFAC_PRECIO = rs.PRECIOXCLIENTE;
            }
            item.DETFAC_DESCUENTO = rs.DETGRE_DESCUENTO;
            item.DETFAC_TOTAL = rs.DETGRE_TOTAL;
            item.DETFAC_PROMOCION = '0';
            item.ENCGRE_NUMERO = rs.ENCGRE_CODIGO;
            item.DETGRE_LINEA = rs.DETGRE_LINEA;
            item.CEN_CODIGO = rs.CEN_CODIGO;
            item.ENCPED_NUMERO = '';
            item.DETFAC_TIPOSRI = '';
            item.ENCFAC_NUMERO = '';
            item.ART_MULTIUNIDAD = rs.ART_MULTIUNIDAD;
            item.DETFAC_LINEA = intdetactual;
            item.BOD_CODIGO = rs.BOD_CODIGO;
            item.DETFAC_PORIVA = rs.DETGRE_PORIVA;//CDPJ
            item.TARIIVA_CODIGO = rs.TARIIVA_CODIGO;//CDPJ
            item.DETFAC_TRIBASENOOBJIVA = rs.TARIIVA_CODIGO === '6' ? 'S' : '';

            //GSRF
            const datas = await this.encfacService.consultarArticuloSelProm(rs.DETGRE_CODIGO);
            for (const data of datas) {
              console.log("----->", data);
              item.DETFAC_IRBPNR = data.ART_IRBPNR === null || data.ART_IRBPNR === undefined ? 0 : data.ART_IRBPNR;
            }
            item.DETFAC_VALORIRBPNR = '0';
            //GSRF

            //this.encfacService.detfacarray[this.indicador].push(item);
            intTamLista = intTamLista + 1;

            fila = fila + 1;
          }
        }

        this.encfacService.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
      });
    }

    this.salirDialog();
  }

  crearNuevoItem() {
    const strCodBodega = this.encfacService.encfacarray[this.indicador].BOD_CODIGO;
    console.log(strCodBodega);
    const detfac: VenDetfacZ = {};


    const ARTANTBOD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ARTANTBOD').CFG_VALOR1;
    if (ARTANTBOD === 1) {
      detfac.BOD_CODIGO = '';
    } else {
      detfac.BOD_CODIGO = strCodBodega;
    }

    const BODCENCOS = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BODCENCOS').CFG_VALOR1;
    if (BODCENCOS === 1) {
      detfac.CEN_CODIGO = strCodBodega;
    } else {
      detfac.CEN_CODIGO = '';
    }

    const CENCOSBOD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENCOSBOD').CFG_VALOR1;
    if (CENCOSBOD === 1) {
      this.encfacService.ercrearNuevoItem(strCodBodega).subscribe(cen => {
        if (cen[0] !== undefined) {
          detfac.CEN_CODIGO = cen[0].CEN_CODIGO;
        }
      });
    } else {
      detfac.CEN_CODIGO = '';
    }

    detfac.ENCFAC_NUMERO = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
    detfac.COM_CODIGO = '01';
    detfac.DETCOT_LINEA = 0;
    detfac.DETFAC_ARTACAMBIO = '';
    detfac.DETFAC_BASECERO = 0;
    detfac.DETFAC_BASEICE = 0;
    detfac.DETFAC_BASEIVA = 0;

    if (this.tipodetalle === undefined) {
      this.tipodetalle = this.TIPDET === null || this.TIPDET === undefined ? 'A' : this.TIPDET;
      detfac.DETFAC_TIPODET = 'A';
    } else {
      if (this.tipodetalle === 'Z' || this.tipodetalle === 'Y') {
        this.tipodetalle = 'A';
      }
      detfac.DETFAC_TIPODET = this.tipodetalle;
    }


    detfac.DETFAC_CANTIDAD = 1;


    detfac.DETFAC_PRECIO = 0;
    detfac.DETFAC_DESCUENTO = 0;
    detfac.DETFAC_TOTAL = 0;

    detfac.DETFAC_CODIGO = '';
    detfac.DETFAC_CODIGOREF = '';
    detfac.DETFAC_CONCODCSTVTA = '';
    detfac.DETFAC_CONCODINV = '';
    detfac.DETFAC_DESCRIPCION = '';
    detfac.DETFAC_LINEA = this.encfacService.detfacarray[this.indicador].length + 1;
    detfac.DETFAC_LISTA = this.selectlistasprecio.codigo;
    detfac.DETFAC_LINEA_3 = 0;
    detfac.ENCFAC_IVA = this.selectiva.codigo;

    // datos iniciales
    detfac.DETFAC_PORCEICE = 0;
    detfac.ENCCOT_NUMERO = '';
    detfac.ENCPED_NUMERO = '';
    detfac.ENCGRE_NUMERO = '';
    detfac.DETGRE_LINEA = '';
    detfac.DETFAC_PROMOCION = '0';
    detfac.DETFAC_LINORGPROM = '0';
    detfac.MAEPRM_CODIGO = '0';
    detfac.DETFAC_PRECIOC = 0;
    detfac.DETFAC_TIPOSRI = '';
    detfac.ORDTRA_NUMERO = '';
    detfac.DETORDTRA_LINEA = '0';
    detfac.ENCREQ_NUMERO = '';
    detfac.DETREQ_LINEA = '0';
    detfac.ENCMAT_NUMERO = '';
    detfac.DETMAT_CODIGO = '0';
    detfac.DETFAC_CANTIDADUND = 0;
    detfac.DETFAC_BASENOOBJIVA = 0;
    detfac.DETFAC_ICE = 0;
    detfac.DETFAC_IVA = 0;
    detfac.DETFAC_TRIBIVA = '';
    detfac.DETFAC_TRIBIVAAUX = '';
    detfac.DETFAC_TRIBICE = '';
    detfac.DETFAC_TRIBASENOOBJIVA = '';
    detfac.UNI_SIMBOLO = '';
    detfac.DETPED_LINEA = '';
    detfac.DETFAC_UNIDAD = '';
    detfac.ART_MULTIUNIDAD = '';
    detfac.EXISTENCIA = 0;
    detfac.DETFAC_IRBPNR = 0;//GSRF
    detfac.DETFAC_VALORIRBPNR = 0;//GSRF
    detfac.DETFAC_INCLUENDSCTO = 'N';//CDPJ
    detfac.DETFAC_PORIVA = Number(this.selectcombtipoIva.name);//CDPJ
    detfac.TARIIVA_CODIGO = this.selectcombtipoIva.codigo;
    this.nuevoItem = detfac;
  }

  async completarDatosCliente(cli) {

    this.encfacService.encfacarray[this.indicador].ENCFAC_DIRECCION = cli.CLI_DIRECCION1;
    this.encfacService.encfacarray[this.indicador].ENCFAC_DIRECCION2 = cli.CLI_DIRECCION2;
    this.encfacService.encfacarray[this.indicador].ENCFAC_TELEFONO = cli.CLI_TELEFONO1;
    this.encfacService.encfacarray[this.indicador].ENCFAC_TELEFONO2 = cli.CLI_TELEFONO2;
    this.encfacService.encfacarray[this.indicador].ENCFAC_RUCIDE = cli.CLI_RUCIDE;
    this.encfacService.encfacarray[this.indicador].ENCFAC_CORREO = cli.CLI_CORREO;
    this.encfacService.encfacarray[this.indicador].ENCFAC_FAX = cli.CLI_FAX;
    this.encfacService.encfacarray[this.indicador].CLI_LIMCREDIT = cli.CLI_LIMCREDIT;
    this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM = cli.CLI_DESCUENTOLIM;

    if (cli.CLI_DESCUENTO !== null) {
      this.dscClienteActual = cli.CLI_DESCUENTO;
    } else {
      this.dscClienteActual = 0;
    }

    if (this.bolNuevo === true) {
      if (cli.CLI_DIACREDIT > 0) {
        this.encfacService.encfacarray[this.indicador].ENCFAC_DIASCRDT = cli.CLI_DIACREDIT;
      } else {
        this.encfacService.encfacarray[this.indicador].ENCFAC_DIASCRDT = 0;
      }
      this.calcularFechaVencimiento();
    }

    if (cli.CLI_IVA === null) {
      cli.CLI_IVA = '0';
    }
    // tipo de iva de acuerdo al cliente
    const FCPRINIVA = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCPRINIVA').CFG_VALOR1;
    if (FCPRINIVA !== 1) {
      if (this.STIPIVACLI === 1) {
        this.cmbivaaux.map((iva) => {
          if (iva.codigo === cli.CLI_IVA) {
            if (this.bolNuevo === true) {
              this.selectiva = { codigo: iva.codigo, name: iva.name };
            }
          }
        });
      }
    }
    // si es nota de venta y esta no tributa iva
    if (this.chknotadeventa === true
      && this.NVNOIVA === 1) {
      this.selectiva = { codigo: this.cmbivaaux[2].codigo, name: this.cmbivaaux[2].name };
    }

    this.cmblistasprecio.map((lista) => {
      if (lista.codigo === cli.CLI_LISTA) {
        if (this.bolNuevo === true) {
          this.selectlistasprecio = { codigo: lista.codigo, name: lista.name };
          this.encfacService.encfacarray[this.indicador].ENCFAC_LISTA = lista.codigo;
        }
      }
    });

    if (this.bolNuevo === true) {
      this.encfacService.encfacarray[this.indicador].VEN_CODIGO = cli.VEN_CODIGO;
      if (this.encfacService.encfacarray[this.indicador].VEN_CODIGO !== null) {
        const er = await this.encfacService.erpDatosVendedor(this.encfacService.encfacarray[this.indicador].VEN_CODIGO);
        if (er[0] !== undefined) {
          this.encfacService.encfacarray[this.indicador].VEN_NOMBRE = er[0].VEN_NOMBRE;
        }
      } else {
        this.encfacService.encfacarray[this.indicador].VEN_NOMBRE = '';
      }
    }

    const saldo = await this.encfacService.obtenerSaldoCliente(cli.CLI_CODIGO).toPromise();
    console.log(saldo)
    if (saldo[0] !== undefined) {
      this.encfacService.encfacarray[this.indicador].ENCFAC_SALDOCLIENTE = saldo[0].SALDO.toFixed(this.confIniciales.getNumDecSist());
    }
  }

  async verificarExisteFP(strTipo, accion) {
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO === '') {
      if (accion === 'navegacion') {
        this.irARegistro(strTipo);
      }
      if (accion === 'nuevo') {
        this.irAnuevaFac();
      }
      if (accion === 'buscar') {
        this.busquedafactura();
      }
      if (accion === 'salir') {
        this.init.tabs.splice(this.init.tabindex, 1);
      }
      if (accion === 'firmar') {
        this.validarFirmaElec();
      }
      return;
    }
    const GUASINFP = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GUASINFP').CFG_VALOR1;
    if (GUASINFP === 1) {
      await this.ValidarSeriales(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, strTipo, accion);
      return;
    }
    // verificar si el usuario que está conectado es el mismo que hizo la factura
    /*const data = await this.encfacService.encontrarRegistroPromise('USU_IDENTIFICACION', 'VEN_ENCFAC', 'ENCFAC_NUMERO = \''
      + this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '\' AND COM_CODIGO = \'01\'');
    if (data !== null) {
      if (data[0] !== undefined) {
        if (data[0].USU_IDENTIFICACION !== this.encfacService.usuario.identificacion) {*/
    if (this.encfacService.encfacarray[this.indicador].USU_IDENTIFICACION !== this.encfacService.usuario.identificacion) {
      await this.ValidarSeriales(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, strTipo, accion);
    } else {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === null) {
        this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO = '';
      }
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO.length === 0
        && this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO !== 'ANULADA') {
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Información',
          detail: 'La factura no tiene una forma de pago'
        });
      } else {
        await this.ValidarSeriales(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, strTipo, accion);
      }
    }
    /* }
   }*/
  }

  seleccionarRegistro(strTipo) {
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO === undefined) {
      this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO = '';
    }
    this.verificarExisteFP(strTipo, 'navegacion');
  }

  irARegistro(strTipo) {
    let strCondicion = 'NVL(ENCFAC_ORIGEN,\'A\')<>\'PTOVTA\' AND COM_CODIGO = \'01\'';
    if (strTipo === 'S') {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length > 0) {
        strCondicion = strCondicion + ' AND ENCFAC_NUMERO > \''
          + this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '\' AND ENCFAC_NUMERO LIKE \''
          + this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.substr(0, 3) + '%\'';
      } else {
        strTipo = 'U';
      }
    }
    if (strTipo === 'A') {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length > 0) {
        strCondicion = strCondicion + ' AND ENCFAC_NUMERO < \''
          + this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '\'';
      } else {
        strTipo = 'P';
      }
    }

    if (strTipo === 'P') {
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }
    if (strTipo === 'A' || strTipo === 'S') {
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }
    if (strTipo === 'U') {
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = false;
      this.botonUltimo = false;
    }

    this.encfacService.seleccionarRegistro(strTipo, ' ', strCondicion).subscribe((datos: any[]) => {
      if (datos !== null) {
        this.encontrarFactura(datos[0], 'sR');
        if (this.index < 2) {
          this.botonNuevo = false;
          this.botonGuardar = true;
          this.botonBorrar = false;
          this.botonRegresar = true;
        }
      }
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }

  async encontrarFactura(encfaccodigo, tipo) {
    this.init.disabledtab = true;
    this.detfacSelected = undefined;
    this.bolestcta = false;
    this.bolsaldo = false;
    let resp = [];
    //GSRF
    this.chkactivo = false;
    this.limpiaexport();
    //GSRF
    if (tipo === 'sR') {
      resp.push(encfaccodigo);
    } else {
      //this.spin = true;
      resp = await this.encfacService.getEncfac(encfaccodigo);
      //this.spin = false;
    }
    if (resp === null) {
      this.init.disabledtab = false;
      return;
    }
    for (const data of resp) {
      data.ENCFAC_FECHAEMISION = new Date(data.ENCFAC_FECHAEMISION);
      data.ENCFAC_FECHAENTREGA = new Date(data.ENCFAC_FECHAENTREGA);
      data.ENCFAC_FECHASERVIDOR = new Date(data.ENCFAC_FECHASERVIDOR);
      data.ENCFAC_FECHAVENCIMIENTO = new Date(data.ENCFAC_FECHAVENCIMIENTO);
      this.bolNuevo = false;
      this.bolCliente = true;
      this.encfacService.encfacarray[this.indicador] = data;
      this.encfacService.encfacarray[this.indicador].ENCFAC_HORAEMISION = new Date(data.ENCFAC_FECHAEMISION);
      //GSRF
      this.encfacService.getfacexp(data.ENCFAC_NUMERO).subscribe((res) => {
        for (const data1 of res) {
          console.log(data1);
          if (data1.length === 0) {
            this.chkactivo = false;
            this.limpiaexport();
          } else {
            this.chkactivo = true;
            this.limpiaexport();
            console.log(data1.COMERCIOEXTERIOR);
            console.log(this.encfacService.encfacarray[this.indicador].COMERCIOEXTERIOR = data1.COMERCIOEXTERIOR);
            this.encfacService.encfacarray[this.indicador].COMERCIOEXTERIOR = data1.COMERCIOEXTERIOR;
            this.encfacService.encfacarray[this.indicador].INCOTERMFACTURA = data1.INCOTERMFACTURA;
            this.encfacService.encfacarray[this.indicador].LUGARINCOTERM = data1.LUGARINCOTERM;
            this.encfacService.encfacarray[this.indicador].PAISORIGEN = data1.PAISORIGEN;
            this.encfacService.encfacarray[this.indicador].PUERTOEMBARQUE = data1.PUERTOEMBARQUE;
            this.encfacService.encfacarray[this.indicador].PUERTODESTINO = data1.PUERTODESTINO;
            this.encfacService.encfacarray[this.indicador].PAISDESTINO = data1.PAISDESTINO;
            this.encfacService.encfacarray[this.indicador].PAISADQUISICION = data1.PAISADQUISICION;
            this.encfacService.encfacarray[this.indicador].DIRCOMPRADOR = data1.DIRCOMPRADOR;
            this.encfacService.encfacarray[this.indicador].INCOTERMTOTALSINIMPUESTOS = data1.INCOTERMTOTALSINIMPUESTOS;
            this.encfacService.encfacarray[this.indicador].FLETEINTERNACIONAL = data1.FLETEINTERNACIONAL;
            this.encfacService.encfacarray[this.indicador].SEGUROINTERNACIONAL = data1.SEGUROINTERNACIONAL;
            this.encfacService.encfacarray[this.indicador].GASTOSADUANEROS = data1.GASTOSADUANEROS;
            this.encfacService.encfacarray[this.indicador].GASTOSTRANSPORTEOTROS = data1.GASTOSTRANSPORTEOTROS;
            this.encfacService.encfacarray[this.indicador].FORMAPAGO = data1.FORMAPAGO;
            //////////// EXPORTACION NUEVO
            this.encfacService.encfacarray[this.indicador].CLI_TIPO = data1.TPIDCLIENTEEX;
            this.encfacService.encfacarray[this.indicador].CLI_PARTEREL = data1.PARTERELEXP;
            this.SELECTIPOREGI = data1.TIPOREGI;
            if (data1.TIPOREGI === '01') {
              this.encfacService.encfacarray[this.indicador].codigoPaisRegimen = data1.PAISEFECPAGOGEN;
            } else if (data1.TIPOREGI === '02') {
              this.encfacService.encfacarray[this.indicador].codigoPaisParaiso = data1.PAISEFECPAGOGEN;
            }
            this.encfacService.encfacarray[this.indicador].PAISEFECEXP = data1.PAISEFECEXP;
            this.encfacService.encfacarray[this.indicador].DENOEXPCLI = data1.DENOEXPCLI;
            this.encfacService.encfacarray[this.indicador].DOCTRANSP = data1.DOCTRANSP;
            this.encfacService.encfacarray[this.indicador].CORRELATIVO = data1.CORRELATIVO;
            this.encfacService.encfacarray[this.indicador].ANIO = data1.ANIO;
            this.encfacService.encfacarray[this.indicador].REGIMEN = data1.REGIMEN;
            this.encfacService.encfacarray[this.indicador].DISTADUANERO = data1.DISTADUANERO;
            this.encfacService.encfacarray[this.indicador].VALORFOB = data1.VALORFOB;
            this.encfacService.encfacarray[this.indicador].VALORIMPEXT = data1.VALORIMPEXT;
            if (data1.INGGRAVADOEXT === null) {
              this.SELECTINGGRAVADOEXT = '';
            } else {
              this.SELECTINGGRAVADOEXT = data1.INGGRAVADOEXT;
            }
            if (data1.TIPOINGRESO === null) {
              this.SELECTIPOINGRESO = '';
            } else {
              this.SELECTIPOINGRESO = data1.TIPOINGRESO;
            }
            this.SELECTEXPORTACIONDE = data1.EXPORTACIONDE;
            data1.FECHAEMBARQUE = new Date();
            this.encfacService.encfacarray[this.indicador].FECHAEMBARQUE = data1.FECHAEMBARQUE;
            this.encfacService.encfacarray[this.indicador].VALORFOBCOMPROBANTE = data1.VALORFOBCOMPROBANTE;
            if (this.SELECTEXPORTACIONDE === '01') {
              this.booleanRefrendo = true;
              this.booleanImpuestoRenta = true;
            } else if (this.SELECTEXPORTACIONDE === '02') {
              this.booleanRefrendo = true;
              this.booleanImpuestoRenta = true;
            } else if (this.SELECTEXPORTACIONDE === '03') {
              this.booleanRefrendo = false;
              this.booleanImpuestoRenta = false;
            } else if (this.SELECTEXPORTACIONDE === '') {
              this.booleanRefrendo = true;
              this.booleanImpuestoRenta = true;
            }

            if (this.SELECTIPOREGI === '01') {
              this.booleanRegimen = false;
              this.booleanParaiso = true;
              this.booleanRegimenP = true;
              this.booleanRegimenP1 = true;
            } else if (this.SELECTIPOREGI === '02') {
              this.booleanRegimen = true;
              this.booleanParaiso = false;
              this.booleanRegimenP = true;
              this.booleanRegimenP1 = false;
            } else if (this.SELECTIPOREGI === '03') {
              this.booleanRegimen = true;
              this.booleanParaiso = true;
              this.booleanRegimenP = false;
              this.booleanRegimenP1 = false;
            } else if (this.SELECTIPOREGI === '') {
              this.booleanRegimen = true;
              this.booleanParaiso = true;
              this.booleanRegimenP = true;
              this.booleanRegimenP1 = true;
            }

            if (this.SELECTINGGRAVADOEXT === 'SI') {
              this.booleanImpuestoRenta = false;
            } else if (this.SELECTINGGRAVADOEXT === 'NO') {
              this.booleanImpuestoRenta = true;
            } else if (this.SELECTINGGRAVADOEXT === '') {
              this.booleanImpuestoRenta = true;
            };
          }
        }
      })
      //GSRF
      switch (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO) {
        case 'P':
          this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO = 'PENDIENTE';
          document.getElementById('contorno').style.pointerEvents = 'all';
          // document.getElementById('contornodetalle').style.pointerEvents = 'all';
          this.aggrid.habilitarGrid();
          this.botonXML = false;
          if (this.CNAFEPV === 1) {
            const data = await this.encfacService.obtenerNumFE(this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO)
            if (data !== null && data !== undefined) {
              for (const rs of data) {
                if (Number(rs.NUM_FE) === 1) {
                  this.botonXML = false;
                } else if (Number(rs.NUM_FE) === 0) {
                  this.botonXML = true;
                }
              }
            } else {
              this.botonXML = false;
            }
          }
          break;
        case 'G':
          this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO = 'PAGADA';
          document.getElementById('contorno').style.pointerEvents = 'none';
          // document.getElementById('contornodetalle').style.pointerEvents = 'none';
          this.aggrid.bloquearGrid();
          this.botonXML = false;
          if (this.CNAFEPV === 1) {
            const data = await this.encfacService.obtenerNumFE(this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO)
            if (data !== null && data !== undefined) {
              for (const rs of data) {
                if (Number(rs.NUM_FE) === 1) {
                  this.botonXML = false;
                } else if (Number(rs.NUM_FE) === 0) {
                  this.botonXML = true;
                }
              }
            } else {
              this.botonXML = false;
            }
          }
          break;
        case 'A':
          this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO = 'ANULADA';
          document.getElementById('contorno').style.pointerEvents = 'none';
          // document.getElementById('contornodetalle').style.pointerEvents = 'none';
          this.aggrid.bloquearGrid();
          this.botonXML = true;
          break;
        default:
          break;
      }
      switch (this.encfacService.encfacarray[this.indicador].ENCFAC_IMPRESO) {
        case 'S':
          this.encfacService.encfacarray[this.indicador].ENCFAC_IMPRESO = 'IMPRESA';
          break;
        case 'N':
          this.encfacService.encfacarray[this.indicador].ENCFAC_IMPRESO = 'N';
          break;
        default:
          break;
      }

      for (const j of this.cmblistasprecio) {
        if (this.encfacService.encfacarray[this.indicador].ENCFAC_LISTA === j.codigo) {
          this.selectlistasprecio = { codigo: j.codigo, name: j.name };
          this.encfacService.encfacarray[this.indicador].ENCFAC_LISTA = j.codigo;
        }
      }

      for (const j of this.cmbivaaux) {
        if (this.encfacService.encfacarray[this.indicador].ENCFAC_IVA === j.codigo) {
          this.selectiva = { codigo: j.codigo, name: j.name };
        }
      }

      for (const j of this.cmbporceiva) {
        if (Number(this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEIVA) === Number(j.name)) {
          this.selectcombtipoIva = { codigo: j.codigo, name: j.name };
          this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEIVA = Number(this.selectcombtipoIva.name)
        }
      }
      let porcentaje = this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEIVA
      let data1 = this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(porcentaje))
      this.encfacService.porcetariiva = Number(porcentaje);
      this.encfacService.codtariiva = data1.TARIIVA_CODIGO;

      for (const j of this.cmbbodega) {
        if (this.encfacService.encfacarray[this.indicador].BOD_CODIGO === j.codigo) {
          this.selectbodega = { codigo: j.codigo, name: j.name };
        }
      }

      for (const j of this.cmbtipofactura) {
        if (this.encfacService.encfacarray[this.indicador].ENCFAC_TIPOFACTURA === j.codigo) {
          this.selecttipofactura = { codigo: j.codigo, name: j.name };
          //GSRF
          console.log(j.codigo);
          if (j.codigo === "01") {
            this.expo = false;
            console.log(data.ENCFAC_SERIE);
            this.encfacService.encfacarray[this.indicador].PUNTOEMISION = data.ENCFAC_SERIE.substr(1, 3);
            this.encfacService.encfacarray[this.indicador].ESTABLECIMIENTO = data.ENCFAC_SERIE.substr(4, 6);
            //this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISIONEXP = new Date();
            //this.encfacService.encfacarray[this.indicador].FECHAEMBARQUE = new Date();
            this.cliService.getCliente(this.encfacService.encfacarray[this.indicador].CLI_CODIGO).subscribe((res1) => {
              for (const i1 of res1) {
                this.encfacService.encfacarray[this.indicador].CLI_TIPOIDE = i1.CLI_TIPOIDE;
                this.encfacService.encfacarray[this.indicador].CLI_RUCIDE = i1.CLI_RUCIDE;
                this.encfacService.encfacarray[this.indicador].CLI_PARTEREL = i1.CLI_PARTEREL;
                this.encfacService.encfacarray[this.indicador].CLI_TIPO = i1.CLI_TIPO;
                this.encfacService.encfacarray[this.indicador].CLI_NOMBREXP = i1.CLI_NOMBRE;
              }
            });
          } else {
            this.expo = true;
          }
          //GSRF
        }
      }
      this.encfacService.encfacarray[this.indicador].ENCFAC_SALDOCLIENTE = data.SALDO;
      if (this.NUMITEMFAC > 0) {
        this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES = this.NUMITEMFAC;
      }
      this.valorDscValor = this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES;
      this.encfacService.dblValorDescGlb = this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES;
      this.encfacService.dblPorcDescGlb = this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES;
      this.encfacService.dblValorotrdscto = this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS;//CDPJ
      this.encfacService.dblValorotrdsctoDif = this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF;//CDPJ
      this.encfacService.dblValorotrdsctoBcero = this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO;//CDPJ

      this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES).toFixed(3);
      //CDPJ
      this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES).toFixed(2);
      this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS).toFixed(2);

      this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF).toFixed(2);
      this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO).toFixed(2);

      this.valorotrdsctos = this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS;
      this.valorotrdsDif = this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF;
      this.valorotrdsBacero = this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO;

      //CDPJ
      this.encfacService.encfacarray[this.indicador].ENCFAC_VALORICE =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORICE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_BASEICE =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASEICE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_BASECERO =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASECERO).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_BASEIVA =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIVA =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_BASENOOBJIVA =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASENOOBJIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIRBPNR =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIRBPNR).toFixed(this.confIniciales.getNumDecSist());

      if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== '' &&
        this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== null) {
        document.getElementById('contorno').style.pointerEvents = 'none';
        // document.getElementById('contornodetalle').style.pointerEvents = 'none';
        this.aggrid.bloquearGrid();
        //this.botonAnular = true;
      } else if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === '' ||
        this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === null) {
        //this.botonAnular = false;
      }
      this.encfacService.comas();

      if (this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO !== undefined
        && this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO !== null
        && this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO !== '') {
        this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO =
          Number(this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO).toFixed(this.confIniciales.getNumDecSist());
        this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO =
          this.init.moneyValidation(this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO);
      }

      const strFecha = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
      const strHora = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_HORAEMISION, 'HH:mm');
      this.spin = true;
      const r2 = await this.encfacService.getDetfac(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, strFecha, strHora);
      this.spin = false;
      if (r2 === null) {
        this.encfacService.detfacarray[this.indicador] = [];
      } else {
        this.encfacService.detfacarray[this.indicador] = r2;
      }
      //CDPJ
      const descGlobal = await this.encfacService.desglobalCliente(this.encfacService.encfacarray[this.indicador].CLI_CODIGO);
      if (descGlobal !== null && descGlobal !== undefined) {
        for (const data of descGlobal) {
          this.descGlobal = data.DESCUENTO
        }

      } else {
        this.descGlobal = 0;
      }
      //CDPJ
      this.encfacService.detfacarray[this.indicador].map(async (deta, index) => {
        this.encfacService.detfacarray[this.indicador][index].DETFAC_LINEA_3 = deta.DETFAC_LINEA;
        this.encfacService.detfacarray[this.indicador][index].DETFAC_TRIBIVAAUX = deta.DETFAC_TRIBIVA;

        this.encfacService.detfacarray[this.indicador][index].DETFAC_CANTIDAD =
          Number(this.encfacService.detfacarray[this.indicador][index].DETFAC_CANTIDAD).toFixed(this.encfacService.decimalesCantidadFac);
        this.encfacService.detfacarray[this.indicador][index].DETFAC_PRECIO =
          Number(this.encfacService.detfacarray[this.indicador][index].DETFAC_PRECIO).toFixed(this.encfacService.decimalesPrecioFac);
        this.encfacService.detfacarray[this.indicador][index].DETFAC_DESCUENTO =
          Number(this.encfacService.detfacarray[this.indicador][index].DETFAC_DESCUENTO).toFixed(this.encfacService.decimalesDescFac);
        this.encfacService.detfacarray[this.indicador][index].DETFAC_TOTAL =
          Number(this.encfacService.detfacarray[this.indicador][index].DETFAC_TOTAL).toFixed(this.encfacService.decimalesTotalFac);

        // this.encfacService.detfacarray[this.indicador][index].ART_MULTIUNIDAD = deta.ART_MULTIUNIDAD;
        // this.encfacService.detfacarray[this.indicador][index].UNIDAD_VENTA = deta.ART_UNIDADVENTA;
        // this.encfacService.detfacarray[this.indicador][index].ART_CADUCA = deta.CADUCA;

        const itemdisponibles = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES);
        this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES = itemdisponibles - 1;
        await this.comprobarPrecio(deta);
        await this.comprobarExistencia(deta);
      });
      this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
      this.extraerNumeroPedidos(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'S');
    }

    if (this.encfacService.encfacarray[this.indicador].CLI_CODIGO === '') {
      this.disabledEstadisticas = true;
    } else {
      this.encfacService.erGetClienteCodigo(this.encfacService.encfacarray[this.indicador].CLI_CODIGO).subscribe((tipoCli) => {
        if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
          this.disabledEstadisticas = true;
        } else {
          this.disabledEstadisticas = false;
        }
      });
    }
    this.init.disabledtab = false;
  }

  infoadicional(encfaccodigo) {
    this.encfacService.consultarInfoAdicional(encfaccodigo).subscribe(ia => {
      this.veninfoadicfac = ia;
    });
  }


  async llenarCombos() {
    this.encfacService.obtenerListasPrecios().subscribe((olp) => {
      this.cmblistasprecio = [];
      for (const lp of olp) {
        this.cmblistasprecio.push({ codigo: lp.ARTPRE_CODIGO, name: lp.ARTPRE_CODIGO });
      }
    });

    this.cmbivaaux = [{ codigo: '1', name: 'Neto + Iva' },
    { codigo: '2', name: 'Incluído Iva' },
    { codigo: '0', name: 'Exento' }];



    const bods: any = await this.encfacService.obtenerBodegasInternas();
    this.cmbbodega = [];
    for (const b of bods) {
      this.cmbbodega.push({ codigo: b.BOD_CODIGO, name: b.BOD_NOMBRE + ' (' + b.BOD_CODIGO + ')' });
    }
    this.selectbodega = this.cmbbodega[0];


    //   this.cmbporceiva = [{ codigo: '12', name: '12' },
    //   { codigo: '8', name: '8' },
    // ];


    this.cmbtipofactura = [{ codigo: '18', name: '18-Venta Normal' },
    { codigo: '01', name: '01-Factura de Exportación' },
    { codigo: '41', name: '41-Factura de Reembolsos' },
    { codigo: '370', name: '370-Factura Operadora Transporte / Socio' },
    { codigo: '371', name: '371-Comprobante Socio A Operadora De Transporte' }];
  }

  async llenarcombo() {
    //CDPJ
    this.cmbporceiva = [];
    for (let i = 0; this.confIniciales.srifetariiva[i]; i++) {
      let item = this.confIniciales.srifetariiva[i]
      if (item.TARIIVA_CODIGO !== '6' && item.TARIIVA_CODIGO !== '7' && item.TARIIVA_CODIGO !== '-1') {
        this.cmbporceiva.push({ codigo: item.TARIIVA_CODIGO, name: item.TARIIVA_PORCENTAJE.toString() })
      }
    }
    console.log(this.cmbporceiva)
    for (const data of this.cmbporceiva) {
      if (Number(data.name) === Number(this.iva)) {
        this.selectcombtipoIva = { codigo: data.codigo, name: data.name }
        this.encfacService.porcetariiva = Number(this.selectcombtipoIva.name)
        this.encfacService.codtariiva = this.selectcombtipoIva.codigo
        this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEIVA = this.encfacService.porcetariiva
      }
    }
    //CDPJ
  }
  busqlistasprecio(lp) {
    this.encfacService.encfacarray[this.indicador].ENCFAC_LISTA = lp.value.codigo;
  }
  busqiva(iva) {
    this.encfacService.encfacarray[this.indicador].ENCFAC_IVA = iva.value.codigo;
  }
  cambiavalorIva(select) {
    this.selectcombtipoIva.codigo = select.value.codigo
    this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEIVA = Number(select.value.name);
    //console.log(this.encordService.dblPorcIvaPed)
    this.encfacService.porcetariiva = Number(select.value.name)
    this.encfacService.codtariiva = select.value.codigo
  }

  busqbodega(bod) {
    this.encfacService.encfacarray[this.indicador].BOD_CODIGO = bod.value.codigo;
  }

  busqporceiva(porceiva) {
    this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEIVA = porceiva.value.codigo;
    this.encfacService.calcularTotales();
  }

  busqtipofactura(tipofac) {
    this.encfacService.encfacarray[this.indicador].ENCFAC_TIPOFACTURA = tipofac.value.codigo;
  }

  busquedafactura() {
    this.opcionbusqueda = 'factura';
    this.types = [
      { label: 'NÚMERO', value: 'ENCFAC_NUMERO' },
      { label: 'F_EMISIÓN', value: 'ENCFAC_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCFAC_ESTADO' },
      { label: 'COD_CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE_CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'RUC', value: 'ENCFAC_RUCIDE' },
      { label: 'BODEGA', value: 'BOD_CODIGO' },
      { label: 'VENDEDOR', value: 'VEN_CODIGO' },
      { label: 'TOTAL', value: 'ENCFAC_TOTAL' },
      { label: 'NETO', value: 'ENCFAC_TOTALNETO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCFAC';
    this.where = 'ENCFAC_ORIGEN' + ' !=\'PTOVTA\' order by ENCFAC_FECHAEMISION desc ';

    //this.spin = true;
    this.busqService.busquedaVenEncFac3().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.ENCFAC_FECHAEMISION !== null) {
          registro.ENCFAC_FECHAEMISION = this.datePipe.transform(registro.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCFAC_FECHAEMISION = '';
        }
      });
      //this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }
  //GSRF
  busquedafacturanofirmados() {
    this.opcionbusqueda = 'factura';
    this.types = [
      { label: 'F_EMISIÓN', value: 'ENCFAC_FECHAEMISION' },
      { label: 'ESTADO_FE', value: 'ENCFAC_ESTADO_FE' },
      { label: 'FORMA PAGO', value: 'ENCFAC_FORMAPAGO' },
      { label: 'NÚMERO', value: 'ENCFAC_NUMERO' },
      { label: 'CLAVE_ACCESO', value: 'ENCFAC_CLAVEACCESO_FE' },
      { label: 'COD_CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE_CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'TOTAL', value: 'ENCFAC_TOTAL' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCFAC';
    this.where = 'ENCFAC_ORIGEN !=\'PTOVTA\' and( ENCFAC_ESTADO_FE NOT IN (\'NT\',\'SN\') or ENCFAC_ESTADO_FE is null or ENCFAC_FORMAPAGO is null)AND ENCFAC_ESTADO !=\'A\'  order by ENCFAC_FECHAEMISION desc ';

    //this.spin = true;
    this.busqService.busquedaVennofirma().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.ENCFAC_FECHAEMISION !== null) {
          registro.ENCFAC_FECHAEMISION = this.datePipe.transform(registro.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCFAC_FECHAEMISION = '';
        }
      });
      //this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  //GSRF
  verAsiento() {
    this.asiento = this.encfacService.encfacarray[this.indicador].ASI_NRO;
    if (this.asiento === null || this.asiento === undefined || this.asiento === '') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      // this.displayDialogAsiento = true;
      this.displayAsiContable = true;
    }
  }

  busquedanumeracion() {
    this.opcionbusqueda = 'numeracion';
    this.types = [
      { label: 'Descripción', value: 'NUM_CLASE' },
      { label: 'Nemónico', value: 'NUM_NEMONICO' },
      { label: 'Serie', value: 'NUM_SERIE' },
      { label: 'Actual', value: 'NUM_SECACTUAL' }
    ];
    this.busquedacampos = ['', '', '', ''];
    this.tabla = 'SACI_MAENUMERACION';
    this.where = ' (NUM_DOCREF=\'FAC\' OR NUM_DOCREF = ' +
      '\'NV\') AND NUM_TIPO = \'C\' order by 1 ';

    this.busqService.busquedaSaciMaeNumeracion4().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusqueda = true;
    });
  }

  PagoFacturaContado() {

    if (this.botonGuardar === false) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Guarde la factura antes de realizar el pago.'
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL === 0) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'El valor de la factura es cero.'
      });
      return;
    }

    let intNumPagos = 0;
    this.encfacService.existePagoDirecto(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(epd => {
      intNumPagos = epd['0'].COUNT;
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PENDIENTE') {
        if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === null) {
          this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO = '';
        }
        if (intNumPagos === 0 && this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === '') {
          this.auxiliar++;
          this.strNumFact = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
          this.displayDialogPagoContado = true;
        } else if (intNumPagos === 1) {
          this.auxiliar++;
          this.strNumFact = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
          this.displayDialogPagoContado = true;
        } else {
          this.messageService.add({
            key: 'encfac',
            severity: 'warn',
            summary: 'Error',
            detail: 'El pago de la factura está a crédito.'
          });
        }
      } else {
        if (intNumPagos === 1) {
          this.auxiliar++;
          this.strNumFact = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
          this.displayDialogPagoContado = true;
        } else {
          this.messageService.add({
            key: 'encfac',
            severity: 'warn',
            summary: 'Información',
            detail: 'El pago se realizó desde cuentas por cobrar'
          });
        }
      }
    });


  }

  Credito() {
    const AUTFCVEN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'AUTFCVEN').CFG_VALOR1;

    const FACTIPCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACTIPCLI').CFG_VALOR1;
    if (FACTIPCLI === 1) {
      this.encfacService.erCredito(this.encfacService.encfacarray[this.indicador].CLI_CODIGO).subscribe(er => {
        let strTipoCli = '';
        if (er !== null) {
          if (er[0] !== undefined) {
            strTipoCli = er[0].CLI_TIPO;
          }
        }
        if (strTipoCli === '3') {
          this.messageService.add({
            key: 'encfac',
            severity: 'error',
            summary: 'Información',
            detail: 'Al cliente solo se le permite pagos en Efectivo'
          });
          return;
        } else {
          this.autorizarFacturacionLimCredito(this.encfacService.encfacarray[this.indicador].CLI_CODIGO,
            this.encfacService.encfacarray[this.indicador].CLI_LIMCREDIT, AUTFCVEN,
            this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL, this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
        }
      });
    } else {
      this.autorizarFacturacionLimCredito(this.encfacService.encfacarray[this.indicador].CLI_CODIGO,
        this.encfacService.encfacarray[this.indicador].CLI_LIMCREDIT, AUTFCVEN,
        this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL, this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
    }
  }


  autorizarFacturacionLimCredito(strCliCodigo, dblCliLimCredit, intCfgAutFacVen, dblTotalFact, strNumFac) {
    let dblSaldoCli = 0;

    if (intCfgAutFacVen === 1 && dblCliLimCredit > 0) {
      this.encfacService.obtenerSaldoClienteFac(strCliCodigo, strNumFac).subscribe(saldo => {
        dblSaldoCli = Number(saldo['0'].SALDO);
        dblSaldoCli = dblSaldoCli + Number(dblTotalFact);
        if (dblSaldoCli > dblCliLimCredit) {
          this.messageService.add({
            key: 'encfac',
            severity: 'warn',
            summary: 'Información',
            detail: 'El saldo del Cliente es ' + dblSaldoCli + ' y su límite de crédito es de ' + dblCliLimCredit
          });
          this.claveAuth = '';
          this.autorizacionCodigo = 'AUTFCVEN';
          setTimeout(() => {
            this.displayDialogAutorizacion = true;
          }, 2000);
        } else {
          this.PagoFacturaCredito();
        }
      });
    } else {
      this.PagoFacturaCredito();
    }
  }

  PagoFacturaCredito() {
    if (this.botonGuardar === false) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Guarde la factura antes de realizar el pago.'
      });
      return;
    }
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL === 0) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'El valor de la factura es cero.'
      });
      return;
    }

    let intNumPagos = 0;
    this.encfacService.existePagoDirecto(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(epd => {
      /// GSRF
      let confinal = '';
      this.encfacService.erTraerCodConsumidorFinal().subscribe(er => {
        //let confinal = '';
        if (er !== null) {
          if (er[0] !== undefined) {
            confinal = er[0].CFG_VALOR3;
          }
        }
        if (this.encfacService.encfacarray[this.indicador].CLI_CODIGO === confinal) {
          console.log('aqui', this.encfacService.encfacarray[this.indicador].CLI_CODIGO + " = " + confinal);
          this.messageService.add({
            key: 'encfac',
            severity: 'warn',
            summary: 'Información',
            detail: 'No puede realizar pago a crédito con el consumidor final.'
          });
        } else {
          intNumPagos = epd['0'].COUNT;
          if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === null) {
            this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO = '';
          }
          if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PENDIENTE') {
            if (intNumPagos === 0 && this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === '') {
              this.rbtFECCUO = 'FEC';
              this.displayDialogPagoCredito = true;
            } else if (intNumPagos === 1) {
              this.messageService.add({
                key: 'encfac',
                severity: 'warn',
                summary: 'Error',
                detail: 'El pago de la factura está al contado'
              });
            } else if (intNumPagos === 0 && this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO.length > 0) {
              this.messageService.add({
                key: 'encfac',
                severity: 'warn',
                summary: 'Error',
                detail: 'El pago de la factura está a crédito'
              });
            } else {
              this.messageService.add({
                key: 'encfac',
                severity: 'warn',
                summary: 'Error',
                detail: 'El pago de la factura se realizó desde cuentas por cobrar'
              });
            }
          } else { // mostrar cuotas si es que existe o presentar mensaje de pagos desde cxc
            if (intNumPagos === 1) {
              this.messageService.add({
                key: 'encfac',
                severity: 'warn',
                summary: 'Información',
                detail: 'El pago de la factura está al contado'
              });
            } else {
              this.messageService.add({
                key: 'encfac',
                severity: 'warn',
                summary: 'Información',
                detail: 'El pago de la factura se realizó desde cuentas por cobrar'
              });
            }
          }
        }
      });
      //GSRF
      // intNumPagos = epd['0'].COUNT;
      // if (this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === null) {
      //   this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO = '';
      // }
      // if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PENDIENTE') {
      //   if (intNumPagos === 0 && this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO === '') {
      //     this.rbtFECCUO = 'FEC';
      //     this.displayDialogPagoCredito = true;
      //   } else if (intNumPagos === 1) {
      //     this.messageService.add({
      //       key: 'encfac',
      //       severity: 'warn',
      //       summary: 'Error',
      //       detail: 'El pago de la factura está al contado'
      //     });
      //   } else if (intNumPagos === 0 && this.encfacService.encfacarray[this.indicador].ENCFAC_FORMAPAGO.length > 0) {
      //     this.messageService.add({
      //       key: 'encfac',
      //       severity: 'warn',
      //       summary: 'Error',
      //       detail: 'El pago de la factura está a crédito'
      //     });
      //   } else {
      //     this.messageService.add({
      //       key: 'encfac',
      //       severity: 'warn',
      //       summary: 'Error',
      //       detail: 'El pago de la factura se realizó desde cuentas por cobrar'
      //     });
      //   }
      // } else { // mostrar cuotas si es que existe o presentar mensaje de pagos desde cxc
      //   if (intNumPagos === 1) {
      //     this.messageService.add({
      //       key: 'encfac',
      //       severity: 'warn',
      //       summary: 'Información',
      //       detail: 'El pago de la factura está al contado'
      //     });
      //   } else {
      //     this.messageService.add({
      //       key: 'encfac',
      //       severity: 'warn',
      //       summary: 'Información',
      //       detail: 'El pago de la factura se realizó desde cuentas por cobrar'
      //     });
      //   }
      // }
    });
  }

  cerrarCuotas(event) {
    if (event) {
      this.encfacService.generarAsientoContable(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'AF',
        this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.substr(
          this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length - 7)).subscribe(() => {
            this.encfacService.erCerrarCuotas(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(cen => {
              if (cen[0] !== undefined) {
                this.encfacService.actualizaFormaPagoFact(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'C').subscribe(() => {
                  this.cancelar();
                });
                this.encfacService.encfacarray[this.indicador].ASI_NRO = cen[0].ASI_NRO;
                const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
                if (INGRAPFAC === 0) {// ingreso rapido de la factura
                  this.messageService.add({
                    key: 'encfac',
                    severity: 'success',
                    summary: 'Información',
                    detail: 'El Asiento Contable se generó correctamente'
                  });
                }
              }
            });
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Asiento Contable',
              detail: mensaje
            });
          });
      //CDPJ
      this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/FP/' + 'CUO', 'I',
        '', '01', '', '', '', '').subscribe();
      //CDPJ
      this.displayDialogCuotas = false;
    } else {
      this.displayDialogCuotas = false;
    }
  }

  obtenerSeleccion() {
    if (this.rbtFECCUO !== undefined) {
      if (this.rbtFECCUO === 'CUO') {
        // cuotas
        this.auxcuotas++;
        this.displayDialogCuotas = true;
      }

      if (this.rbtFECCUO === 'FEC') {
        // this.encfacService.generarAsientoContable(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'AF',
        //   this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.substr(
        //     this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length - 7)).subscribe(() => {
        //       this.encfacService.erGetSeleccion(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(cen => {
        //         if (cen[0] !== undefined) {
        //           this.encfacService.actualizaFormaPagoFact(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'C').subscribe(() => {
        //             this.cancelar();
        //           });
        //           this.encfacService.encfacarray[this.indicador].ASI_NRO = cen[0].ASI_NRO;
        //           this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
        //             this.encfacService.encfacarray[this.indicador].ASI_NRO + '/VEN', 'I',
        //             '', '01', '', '', '', '').subscribe();
        //           const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
        //           if (INGRAPFAC === 0) { // ingreso rapido de la factura
        //             this.messageService.add({
        //               key: 'encfac',
        //               severity: 'success',
        //               summary: 'Información',
        //               detail: 'El Asiento Contable se generó correctamente'
        //             });
        //           }
        //         }
        //       });
        //     }, error1 => {
        //       let mensaje: string;
        //       mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
        //       this.messageService.add({
        //         key: 'encfac',
        //         severity: 'error',
        //         summary: 'Asiento Contable',
        //         detail: mensaje
        //       });
        //     });
        this.encfacService.generarAsientoContable(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'AF',
          this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.substr(
            this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length - 7)).subscribe(() => {
              this.encfacService.erGetSeleccion(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).pipe(finalize(async () => { await this.actualizaFPCredito() })).subscribe(cen => {
                if (cen[0] !== undefined) {

                  this.encfacService.encfacarray[this.indicador].ASI_NRO = cen[0].ASI_NRO;
                  this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
                    this.encfacService.encfacarray[this.indicador].ASI_NRO + '/VEN', 'I',
                    '', '01', '', '', '', '').subscribe();
                  const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
                  if (INGRAPFAC === 0) { // ingreso rapido de la factura
                    this.messageService.add({
                      key: 'encfac',
                      severity: 'success',
                      summary: 'Información',
                      detail: 'El Asiento Contable se generó correctamente'
                    });
                  }
                }
              });
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Asiento Contable',
                detail: mensaje
              });
            });
      }
      //CDPJ
      this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/FP/' + 'C', 'I',
        '', '01', '', '', '', '').subscribe();
      //CDPJ
      this.displayDialogPagoCredito = false;
    } else {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Seleccione una opción'
      });
    }
  }
  //CDPJ
  async  actualizaFPCredito() {
    const data = await this.encfacService.actualizaFormaPagoFact(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'C').toPromise().catch(e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Asiento Contable',
        detail: mensaje
      });
    })
    this.cancelar();

  }

  //CDPJ
  cambio(cambio) {
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = true;
    }
  }

  async aplicarDescuentoGlobal() {
    const descGlobal = await this.encfacService.desglobalCliente(this.encfacService.encfacarray[this.indicador].CLI_CODIGO);
    if (descGlobal !== null && descGlobal !== undefined) {
      for (const data of descGlobal) {
        this.descGlobal = data.DESCUENTO
      }
    }
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está anulada.'
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PAGADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está pagada.'
      });
      return;
    }

    if (Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL)) > 0) {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === undefined
        || this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === null
        || this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === ''
        || Number(Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES)).toFixed(this.confIniciales.getNumDecSist())) === 0) {
        // this.valorDscPorcentaje = 0;
        // this.valorDscValor = 0;
        if (this.descglobaconfig === 1) {
          this.valorDscPorcentaje = this.descGlobal;
          this.valorDscValor = 0;
        } else {
          this.valorDscPorcentaje = 0;
          this.valorDscValor = 0;
        }
      } else {
        this.valorDscValor = this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES;
        this.valorDscPorcentaje = this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES;
        const strdscporce = Number(this.init.quitarComas(this.valorDscPorcentaje)).toFixed(this.confIniciales.getNumDecSist());
        this.valorDscPorcentaje = Number(strdscporce);
      }

      if (this.valorDscPorcentaje > 0) {
      } else {
        if (Number(this.dscClienteActual) > 0) {
          this.valorDscPorcentaje = Number(this.dscClienteActual);
        }
      }

      this.displayDialogDescuento = true;
    }
  }

  aplicarDescuento() {

    if (this.booleanPorcentaje === false) {
      this.valorDscValor = Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL)) * this.valorDscPorcentaje / 100;
    }

    if (this.booleanValor === false) {
      this.valorDscPorcentaje = this.valorDscValor * 100 / Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL));
    }

    if (Number(this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM) > 0) {
      if (this.valorDscPorcentaje > Number(this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM)) {
        this.messageService.add({
          key: 'encfac',
          severity: 'warn',
          summary: 'Información',
          detail: 'El Descuento máximo que se puede '
            + 'aplicar al cliente es '
            + this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM
            + '%.'
        });
        this.displayDialogDescuento = false;
        this.valorDscPorcentaje = Number(this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM);
        this.valorDscValor = 0;
        return;
      }
    }

    let tipo = '';
    if (this.booleanValor === false) {
      tipo = 'valor';
      if (this.dscClienteActual < (this.valorDscValor * 100 / Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL)))) {
        this.confirmarDescuento(tipo);
      } else {
        this.descuentoAplicado(tipo);
      }
    }
    if (this.booleanPorcentaje === false) {
      tipo = 'porcentaje';
      if (this.dscClienteActual < this.valorDscValor) {
        this.confirmarDescuento(tipo);
      } else {
        this.descuentoAplicado(tipo);
      }
    }
  }

  descuentoAplicado(tipo) {
    this.displayDialogDescuento = false;
    this.botonGuardar = false;
    this.botonNuevo = true;

    if (tipo === 'valor') {
      this.encfacService.dblValorDescGlb = this.valorDscValor;
      this.encfacService.dblPorcDescGlb = (this.encfacService.dblValorDescGlb * 100) /
        Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO));
      this.valorDscPorcentaje = this.encfacService.dblPorcDescGlb;
    }

    if (tipo === 'porcentaje') {
      this.encfacService.dblPorcDescGlb = this.valorDscPorcentaje;
      this.encfacService.dblValorDescGlb = Number(
        Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO)) * this.valorDscPorcentaje / 100);
      this.valorDscValor = this.encfacService.dblValorDescGlb;
    }

    this.encfacService.calcularTotales();
  }

  confirmarDescuento(tipo) {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea aplicar un descuento mayor',
      header: 'El cliente tiene un descuento menor',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfac',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.descuentoAplicado(tipo);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }


  comprobarValor() {
    if (this.booleanValor === false) {
      if (Number(this.valorDscValor) > Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL))) {
        this.valorDscValor = Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL));
      }
    }

    if (this.booleanPorcentaje === false) {
      if (this.valorDscPorcentaje > 100) {
        this.valorDscPorcentaje = 100;
      }
    }
  }


  cambioDescuento() {
    if (this.dscSeleccionado === 'dscValor') {
      this.booleanValor = false;
      this.booleanPorcentaje = true;
    }

    if (this.dscSeleccionado === 'dscPorcentaje') {
      this.booleanPorcentaje = false;
      this.booleanValor = true;
    }
  }

  guardarDatosBloque() {
    for (const infoadic of this.veninfoadicfac) {
      this.encfacService.guardarInfoAdicFac(infoadic).subscribe((data) => {
        if (infoadic.INFAD_NUMSEC === 0) {
          for (const rs of data) {
            infoadic.INFAD_NUMSEC = rs[':B1'];
          }
          this.auditoriagral.registrarAuditoria('VEN_INFOADICFAC',
            infoadic.ENCFAC_NUMERO + '/' + infoadic.INFAD_CODIGO + '/' + infoadic.INFAD_DESCRIPCION, 'I', '', '01', '', '', '', '').subscribe();
        } else {
          this.auditoriagral.registrarAuditoria('VEN_INFOADICFAC',
            infoadic.ENCFAC_NUMERO + '/' + infoadic.INFAD_CODIGO + '/' + infoadic.INFAD_DESCRIPCION, 'A', '', '01', '', '', '', '').subscribe();
        }
      });
    }
    this.messageService.add({
      key: 'encfac',
      severity: 'success',
      summary: 'Información',
      detail: 'Datos guardados correctamente'
    });
  }


  multiunidad() {
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      this.defaultColDef.objeto].getFocusedCell() === null) {
      return;
    }
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      this.defaultColDef.objeto].getFocusedCell().column === null) {
      return;
    }
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      this.defaultColDef.objeto].getFocusedCell().column.colId === 'DETFAC_UNIDAD') {
      if (this.detfacSelected.ART_MULTIUNIDAD === 'S') {
        const opciones = [];
        if (this.detfacSelected.UNIDAD_VENTA === undefined) {
          this.detfacSelected.UNIDAD_VENTA = this.detfacSelected.ART_UNIDADVENTA;
        }
        opciones.push(this.detfacSelected.UNIDAD_VENTA);
        this.utilitariosService.getUnidadesEqui(this.detfacSelected.UNIDAD_VENTA).subscribe((res) => {
          if (res !== null) {
            res.map((unidades) => {
              opciones.push(unidades.UNI_CODIGODESTINO);
            });
          }
          this.columnDefsDetfac[5].cellEditor = 'agSelectCellEditor';
          this.columnDefsDetfac[5].editable = () => {
            return true;
          };
          this.columnDefsDetfac[5].cellEditorParams = {
            values: opciones
          };
          this.aggrid.refreshColumnDefs();
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.encfacService.detfacarray[this.indicador].indexOf(this.detfacSelected), 'DETFAC_UNIDAD');
        });
      } else {
        this.columnDefsDetfac[5].cellEditor = 'cellPrueba';
        this.columnDefsDetfac[5].editable = () => {
          return false;
        };
        this.aggrid.refreshColumnDefs();
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.encfacService.detfacarray[this.indicador].indexOf(this.detfacSelected), 'DETFAC_UNIDAD');
      }
    }
  }

  obtenerCantFacActual(strArtCodigo, intLineaPedido,
    intLineaGuia, strEncGreCodigo, strEncPedNumero): number {
    let dblCant = 0;

    for (let fila = 1; fila <= this.encfacService.detfacarray[this.indicador].length; fila++) {
      const item = this.encfacService.detfacarray[this.indicador][fila - 1];

      if (item.DETFAC_TIPODET === 'A' || item.DETFAC_TIPODET === 'S' || item.DETFAC_TIPODET === 'C') {
        if (item.DETFAC_CODIGO === strArtCodigo) {
          if (intLineaPedido !== 0) {
            if (item.DETPED_LINEA === intLineaPedido
              && item.ENCPED_NUMERO === strEncPedNumero) {
              dblCant = dblCant + Number(item.DETFAC_CANTIDAD);
            }
          } else if (intLineaGuia !== 0) {
            if (item.DETGRE_LINEA === intLineaGuia
              && item.ENCGRE_NUMERO === strEncGreCodigo) {
              dblCant = dblCant + Number(item.DETFAC_CANTIDAD);
            }
          }
        }
      }
    }

    return dblCant;
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  anularRegistro() {
    // Verifica los permisos
    if (this.permisoFactura !== null) {
      if (this.permisoFactura.PERTODO === 0) {
        if (this.permisoFactura.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: 'encfac',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encfacService.usuario.identificacion
              + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }

    const GENCLAAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENCLAAUT').CFG_VALOR1;
    if (GENCLAAUT === 0) {
      /* if (Utilidades.verificarPermisosObjConClave("frmVEN_ENCFAC",
         "CmdAnulada", 0))
         if (!anularConClaveSup())
           return; */

      // verificar si la factura tiene pagos realizados
      this.encfacService.existePagosCxC(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(pagos => {
        if (pagos['0']['COUNT(TRNDOCUMENTO_NRODOC)'] > 0) {
          this.messageService.add({
            key: 'encfac',
            severity: 'error',
            summary: 'Error',
            detail: 'La factura tiene registrado pagos.'
          });
          return;
        } else {
          this.strDialogo = 'frmVEN_ENCFAC';
          this.strObjeto = 'CmdAnulada';
          this.intPestaniaCol = 0;
          this.usuarioSup = '';
          this.claveSup = '';

          this.encfacService.countSacimaeobj(this.strDialogo, this.strObjeto, this.encfacService.usuario.identificacion, this.intPestaniaCol).subscribe(eC => {
            for (const rs of eC) {
              if (rs.COUN > 0) {
                this.displayDialogSupervisor = true;
              } else {
                this.indicarRazon();
              }
            }
          });


        }
      });
    } else {
      this.claveAuth = '';
      this.autorizacionCodigo = 'ANULAFV';
      this.displayDialogAutorizacion = true;
    }
  }


  indicarRazon() {
    this.razonanular = '';
    this.displayDialogRazon = true;
  }

  anularFactura() {
    let strRazon = this.razonanular;
    if (strRazon === null || strRazon === undefined) {
      strRazon = '';
    }

    if (strRazon.length === 0) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Para anular la factura se debe ingresar la razón.'
      });
      return;
    }

    this.encfacService.anularFactura(strRazon, this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(() => {
      if (this.encfacService.encfacarray[this.indicador].ASI_NRO !== null) {
        if (this.encfacService.encfacarray[this.indicador].ASI_NRO.length > 0) {
          this.pagconservice.anularAsientoContable(this.encfacService.encfacarray[this.indicador].ASI_NRO).subscribe();
        }
      }
      this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/VEN', 'Anular',
        '', '01', '', '', '', '').subscribe();

      this.encfacService.ecAnularFactDistri(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe();
      this.encfacService.ecVenInfoAdiFact(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe();

      // pedidos
      this.extraerPedidos(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
      this.extraerNumeroPedidos(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'A');

      // guías de remisión
      this.extraerGuiasRem(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'A');
      this.extraerNumeroGuias(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'A');
      this.actualizarInvtrnlote(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
      this.extraerNumeroOrdenesTrabajo(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);


      this.messageService.add({
        key: 'encfac',
        severity: 'success',
        summary: 'Anular Factura',
        detail: 'Factura anulada correctamente'
      });
      this.encontrarFactura(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, '');
      this.displayDialogRazon = false;
    });


  }

  cancelarAnularFac() {
    this.razonanular = '';
    this.displayDialogRazon = false;
  }

  comprobarClave() {
    this.pagconservice.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }


  activarFacturaAnulada() {
    this.closeSidebar();
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.claveAuth = '';
      this.autorizacionCodigo = 'ACTDOC';
      this.displayDialogAutorizacion = true;
    } else {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'La factura no fue anulada.'
      });
    }


  }

  activarFacturaAnuladaFE() {
    this.closeSidebar();


    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO !== 'PENDIENTE') {
      return;
    }

    this.claveAuth = '';
    this.autorizacionCodigo = 'ACTFAFIR';
    this.displayDialogAutorizacion = true;

  }

  validarClave() {
    if (this.usuarioSup.length > 0) {
      this.verificarUsuarioSupervisor();  // verifica si el usuario es supervisor
    } else {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el usuario.'
      });
    }

  }

  verificarUsuarioSupervisor() {


    this.encfacService.ecVerificarUsuarioSupervisor(this.usuarioSup).subscribe(eC => {
      for (const rs of eC) {
        if (rs.COUN > 0) {
          if (this.claveSup.length > 0) {
            this.verificarClaveSupervisor();
          } else {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Información',
              detail: 'Ingrese la clave.'
            });
          }
        } else {
          this.messageService.add({
            key: 'encfac',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario ' + this.usuarioSup + ' no es Supervisor o No existe'
          });
        }
      }
    });
  }

  verificarClaveSupervisor() {
    let strSql = 'SELECT NVL(S.USU_CLAVESUP,\'\') AS USUCLAVESUP FROM  SACI_MAEGUIOBJ O, SACI_MAEGUIOBJPER S WHERE ';
    strSql = strSql + ' O.GUIOBJ_CODIGO = S.GUIOBJ_CODIGO AND ';
    strSql = strSql + ' O.GUIOBJ_FORMA = \'' + this.strDialogo + '\' AND ';
    strSql = strSql + ' O.GUIOBJ_NOMBOBJ = \'' + this.strObjeto + '\' AND ';
    strSql = strSql + ' S.COM_CODIGO = \'01\' AND ';
    strSql = strSql + ' S.USU_IDENTIFICACION = \'' + this.encfacService.usuario.identificacion + '\' AND ';
    strSql = strSql + ' S.USU_IDENTIFICACIONSUP = \'' + this.usuarioSup + '\'';
    if (this.intPestaniaCol > 0) {
      strSql = strSql + ' AND O.GUIOBJ_TABCOL = ' + this.intPestaniaCol;
    }

    this.encfacService.ecVerificarClaveSupervisor(this.strDialogo, this.strObjeto, this.encfacService.usuario.identificacion, this.usuarioSup, this.intPestaniaCol).subscribe(data => {
      if (data.length === 0) {
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Información',
          detail: 'Asigne la clave en los permisos ' +
            'especiales del usuario.'
        });
      } else {
        for (const rs of data) {
          if (rs.USUCLAVESUP !== null) {
            if (rs.USUCLAVESUP.length > 0) {
              if (rs.USUCLAVESUP === this.claveSup) {
                this.bolSupervisor = true;
                this.accionesSupervisor();
              } else {
                this.messageService.add({
                  key: 'encfac',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'La clave está incorrecta..'
                });
              }
            } else {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'Asigne la clave en los permisos ' +
                  'especiales del usuario.'
              });
            }
          } else {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Información',
              detail: 'Asigne la clave en los permisos ' +
                'especiales del usuario.'
            });
          }
        }
      }
    });
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'FCCLDA') {
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para la Facturación.'
        });
        this.encfacService.encfacarray[this.indicador].CLI_CODIGO = '';
        this.encfacService.encfacarray[this.indicador].CLI_NOMBRE = '';
        document.getElementById('CLI_CODIGO').focus();
      }
    }
  }

  onHideSupervisor() {
    if (this.bolSupervisor === false) {
      if (this.strObjeto === 'cmdclicodigo') {
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para la Facturación.'
        });
        this.encfacService.encfacarray[this.indicador].CLI_CODIGO = '';
        this.encfacService.encfacarray[this.indicador].CLI_NOMBRE = '';
        document.getElementById('CLI_CODIGO').focus();
      }
    }
  }

  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'ANULAFV') {
      this.confirmationService.confirm({
        message: 'Está seguro de anular la factura?',
        header: 'Reversar Pago',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencfac',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          // verificar si la factura tiene pagos realizados
          this.encfacService.existePagosCxC(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(pagos => {
            if (pagos['0']['COUNT(TRNDOCUMENTO_NRODOC)'] > 0) {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Error',
                detail: 'La factura tiene registrado pagos.'
              });
              return;
            } else {
              this.indicarRazon();
            }
          });
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
      this.displayDialogAutorizacion = false;
    }

    if (this.autorizacionCodigo === 'ACTDOC') {
      this.encfacService.actualizarEstadoDocumento('ven_encfac',
        'encfac_estado', 'P', 'encfac_numero=\''
        + this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO
      + '\'').subscribe(() => {
        this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/ACTIVA', 'A',
          '', '01', '', '', '', '').subscribe();
        this.encontrarFactura(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, '');
      });
      this.messageService.add({
        key: 'encfac',
        severity: 'success',
        summary: 'Activar Factura',
        detail: 'Factura activada correctamente'
      });
      this.displayDialogAutorizacion = false;
    }

    if (this.autorizacionCodigo === 'AUTFE') {
      this.firmarElectronicamente();
      this.displayDialogAutorizacion = false;
    }

    if (this.autorizacionCodigo === 'ACTFAFIR') {
      this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/VEN/ACT-BOTON-ANULAR', 'A',
        '', '01', '', '', '', '').subscribe();
      this.botonAnular = false;
      this.messageService.add({
        key: 'encfac',
        severity: 'success',
        summary: 'Botón Anular',
        detail: 'Botón anular habilitado'
      });
      this.displayDialogAutorizacion = false;
    }

    if (this.autorizacionCodigo === 'AUTFCVEN') {
      this.displayDialogAutorizacion = false;
      this.PagoFacturaCredito();
    }

    if (this.autorizacionCodigo === 'FCCLDA') {
      this.clienteSeleccionado(this.tmpdatoscliente);
      this.displayDialogAutorizacion = false;
    }
  }

  accionesSupervisor() {
    if (this.strObjeto === 'cmdclicodigo') {
      this.clienteSeleccionado(this.tmpdatoscliente);
      this.displayDialogSupervisor = false;
    }
    if (this.strObjeto === 'CmdAnulada') {
      this.indicarRazon();
      this.displayDialogSupervisor = false;
    }
  }

  validarFirmaElec() {
    const strNumDoc = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;

    if (strNumDoc.length <= 0) {
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'VA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Documento ya fue procesado'
      });
      return;
    }


    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'AT'
      || this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'NT'
      || this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Documento no puede se autorizado'
      });
      return;
    }

    const CLAAUTFE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CLAAUTFE').CFG_VALOR1;
    if (CLAAUTFE === 1) { // si esta activado la
      // seguridad par firmar
      // electrónicamente
      this.claveAuth = '';
      this.autorizacionCodigo = 'AUTFE';
      this.displayDialogAutorizacion = true;
    } else {
      this.firmarElectronicamente();
    }

    /*
    
      // verifica si existe la forma de pago en la factura anterior a la
      // búsqueda
      if (strNumDoc.length() > 0 && !btnGuardar.getEnabled()) {
        if (!verificarExisteFP())
          return;
      }
      
  */

  }

  firmarElectronicamente() {
    this.confirmationService.confirm({
      message: 'Está seguro de firmar el documento '
        + this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '?',
      header: 'Facturación Electrónica',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfac',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        const strNumDoc = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
        const strFecha = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
        let strTipoFac = '';
        const strCodigo = this.encfacService.encfacarray[this.indicador].ENCFAC_TIPOFACTURA;
        // const strFechaServidor = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
        const strFechaServidor = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

        // Si esta en pruebas o producción
        if (this.datosfe.COM_TIPOAMBFACTELEC === '1'
          || this.datosfe.COM_TIPOAMBFACTELEC === '2') {
          if (this.datosfe.COM_FECHACADFIRMAELE != null) {
            if (this.datosfe.COM_FECHACADFIRMAELE.length > 0) {
              if (new Date(strFechaServidor) > new Date(this.datosfe.COM_FECHACADFIRMAELE)) {
                this.messageService.add({
                  key: 'encfac',
                  severity: 'error',
                  summary: 'Error',
                  detail: 'No puede firmar ningún documento electrónico porque esta caducada la fecha de validez de la firma electrónica'
                });
                return;
              }
            } else {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Error',
                detail: 'No existe fecha de caducidad de la firma electrónica'
              });
              return;
            }
          } else {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Error',
              detail: 'No existe fecha de caducidad de la firma electrónica'
            });
            return;
          }

          if (strCodigo === '18' || strCodigo === '') { // factura
            // norma
            strTipoFac = 'FCN';
          }
          if (strCodigo === '01') {// factura de exportación
            strTipoFac = 'FCE';
          }
          if (strCodigo === '41') { // factura De reembolso
            strTipoFac = 'FCR';
          }
          if (strCodigo === '370') {// factura de operadora de
            // transporte
            strTipoFac = 'FOT';
          }
          if (strCodigo === '371') {// Comprobante Socio A Operadora
            // De Transporte
            strTipoFac = 'COT';
          }

          const item: Eletrndocumento = {};
          item.TRNDOC_NUMDOC = strNumDoc;
          item.TRNDOC_TIPO = strTipoFac;
          item.TRNDOC_ESTADO = 'VA';
          item.TRNDOC_RESULTADO = '';
          item.TRNDOC_UBICAARCH = '';
          item.TRNDOC_SECUENCIAL = '';
          item.COM_CODIGO = '01';
          item.TRNDOC_TIPOAMB_FE = this.datosfe.COM_TIPOAMBFACTELEC;

          this.encfacService.insertarEleTrnDocumento(item).subscribe(async () => {
            this.messageService.add({
              key: 'encfac',
              severity: 'success',
              summary: 'Información',
              detail: 'Proceso de firma electrónica iniciado correctamente.'
            });
            this.spin = true;
            const data = await this.utilitariosService.procesoFirmaElectronica(strNumDoc, strFecha).catch(e => {
              this.spin = false;
            });
            if (data !== 'NOAUTORIZADO') {
              this.messageService.add({
                key: 'encfac',
                severity: 'warn',
                summary: 'Información',
                detail: data
              });
              this.spin = false;
              this.encontrarFactura(strNumDoc, '');
            } else {
              this.spin = true;
              await setTimeout(async () => {

                await this.refrescarDatosFE('proceso');
                this.spin = false;
              }, 6000);

            }

          }, error1 => {
            this.spin = false;
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Error',
              detail: mensaje
            });
          });
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });

  }

  visualizarLog() {
    this.closeSidebar();
    this.logResultado = '';
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO !== 'ANULADA') {
      this.encfacService.obtenerLogFE(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, '01').subscribe((res) => {
        if (res.length !== 0) {
          if (res[0] !== null) {
            this.logResultado = JSON.stringify(res[0].TRNDOC_RESULTADO);
          }
        }
        this.displayDialogLog = true;
      });
    } else {
      return;
    }
  }
  //CDPJ
  async visualizarEst() {
    this.closeSidebar();
    this.estfirma = '';
    const data = await this.encfacService.obtenerEstFE().toPromise()
    this.displayDialogEst = true;
    if (data !== null && data !== undefined) {
      for (const est of data) {
        this.estfirma = this.estfirma + est.REF_CODIGO + ' ' + est.REF_NOMBRE + '\n'
      }
    }
  }
  //CDPJ
  refrescarDatosFE(accion) {
    this.closeSidebar();
    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }

    this.encfacService.actualizarDatosElectronicos(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO).subscribe(data => {
      for (const rs of data) {
        this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE = rs.ENCFAC_ESTADO_FE;
        this.encfacService.encfacarray[this.indicador].ENCFAC_AUTORIZACION_FE = rs.ENCFAC_AUTORIZACION_FE;
        this.encfacService.encfacarray[this.indicador].ENCFAC_CLAVEACCESO_FE = rs.ENCFAC_CLAVEACCESO_FE;
      }
      if (accion === 'acciones') {
        this.messageService.add({
          key: 'encfac',
          severity: 'success',
          summary: 'Información',
          detail: 'Datos actualizados'
        });
      }
      if (accion === 'proceso') {
        this.messageService.add({
          key: 'encfac',
          severity: 'success',
          summary: 'Información',
          detail: 'Documento procesado'
        });
      }
    });
  }

  insertarServicioSeguro() {
    this.closeSidebar();
    if (this.botonGuardar === false) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Guarde la factura antes de realizar esta acción.'
      });
      return;
    }

    let strCodigo = '';
    let strPorcentaje = '';
    let dblPorcentaje = 0;
    let strBaseSeguro = '';
    let strSrvNombre = '';
    let dblBaseSeguro = 0;

    if (this.encfacService.detfacarray[this.indicador].length === 0) {
      return;
    }

    const SERSEGVTA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'SERSEGVTA').CFG_VALOR3;
    const PORSEGVTA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PORSEGVTA').CFG_VALOR3;

    if (SERSEGVTA !== null) { // Código    // del    // servicio
      if (SERSEGVTA.length > 0) {
        strCodigo = SERSEGVTA;
      }

      if (PORSEGVTA !== null) { // porcentaje      // del      // seguro
        if (PORSEGVTA.length > 0) {
          strPorcentaje = PORSEGVTA;
          dblPorcentaje = Number(strPorcentaje);
        }
      }

      if (this.encfacService.encfacarray[this.indicador].ENCFAC_REFERENCIA.length > 0) {// BASE DEL      // SEGURO
        strBaseSeguro = this.encfacService.encfacarray[this.indicador].ENCFAC_REFERENCIA;
        dblBaseSeguro = Number(strBaseSeguro);
      }
    }
    if (strCodigo !== '' && dblPorcentaje > 0 && dblBaseSeguro > 0) {
      this.encfacService.erInsertServicioSeguro(strCodigo).subscribe(cen => {
        if (cen[0] !== undefined) {
          strSrvNombre = cen[0].SER_DESCRIPCION;
          if (strSrvNombre === '') {
            this.messageService.add({
              key: 'encfac',
              severity: 'warn',
              summary: 'Información',
              detail: 'Código del servicio no existe.'
            });
          } else {
            this.aplicarServicioSeguro(strCodigo, dblPorcentaje, dblBaseSeguro);
          }
        } else {
          this.messageService.add({
            key: 'encfac',
            severity: 'warn',
            summary: 'Información',
            detail: 'Código del servicio no existe.'
          });
        }
      });
    }
  }

  aplicarServicioSeguro(strCodigo, dblPorcentaje, dblBaseSeguro) {

    let dblValorSeguro = 0;

    dblValorSeguro = dblBaseSeguro * (dblPorcentaje / 100);
    dblValorSeguro = Number(dblValorSeguro.toFixed(this.encfacService.decimalesPrecioFac));

    this.encfacService.getDatosServicio(strCodigo).subscribe(data => {
      for (const rs of data) {
        this.crearNuevoItem();
        const item: VenDetfacZ = this.nuevoItem;

        item.BOD_CODIGO = this.encfacService.encfacarray[this.indicador].BOD_CODIGO;
        item.COM_CODIGO = '01';
        item.DETFAC_LINEA = 0;
        item.DETFAC_TIPODET = 'S';
        item.DETFAC_CODIGO = rs.SER_CODIGO;
        item.DETFAC_DESCRIPCION = rs.SER_DESCRIPCION;
        item.DETFAC_TRIBIVA = rs.SER_TRIBUTAIVA;
        item.DETFAC_TRIBIVAAUX = rs.SER_TRIBUTAIVA;
        item.DETFAC_TRIBICE = 'N';
        item.DETFAC_UNIDAD = 'U';
        item.DETFAC_CANTIDAD = 1;
        item.DETFAC_PROMOCION = '0';
        item.DETFAC_PRECIO = dblValorSeguro;
        item.DETFAC_DESCUENTO = 0;
        item.DETFAC_TOTAL = dblValorSeguro;
        item.DETFAC_IVA = 0;
        item.DETFAC_ICE = 0;
        item.DETFAC_LISTA = 'A';
        item.DETFAC_BASEIVA = 0;
        item.DETFAC_BASEICE = 0;
        item.DETFAC_BASECERO = 0;
        item.DETFAC_PORCEICE = 0;
        item.ENCPED_NUMERO = '';
        item.DETPED_LINEA = 0;
        item.UNI_SIMBOLO = 'U';
        item.ENCGRE_NUMERO = '';
        item.DETFAC_TIPOSRI = '';
        item.CEN_CODIGO = '';
        item.ENCFAC_NUMERO = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;

        this.encfacService.detfacarray[this.indicador].push(item);

        this.encfacService.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
      }
    });
  }

  disminuirPrecioIva() {
    const item = this.detfacSelected;

    let dblPrecioUnitario = 0;
    const dblPorIva = Number(this.selectcombtipoIva.name);


    if (item.DETFAC_TIPODET !== '*') {
      if (Number(item.DETFAC_PRECIO) !== 0) {

        dblPrecioUnitario = Number(item.DETFAC_PRECIO)
          / ((100 + dblPorIva) / 100);
        item.DETFAC_PRECIO = dblPrecioUnitario.toFixed(this.encfacService.decimalesPrecioFac);
      }
    }

    this.encfacService.calcularTotales();
    this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);

  }

  async actualizarNumeracion(strClase,
    strTipoDoc, strNemonico, strSerie) {
    await this.encfacService.actNumeroSiguiente(strClase, strTipoDoc, strNemonico, strSerie);
    /*for (const rs of data) {
       if (rs.getInt(1) == 0)
         bolActualizar = true;
       else if (rs.getInt(1) == 1)
         bolActualizar = false;
    }*/
  }

  listaprecios() {
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].getFocusedCell() === null) {
      return;
    }

    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].getFocusedCell().column === null) {
      return;
    }

    if (this.detfacSelected.DETFAC_CODIGO !== null) {
      if (this.detfacSelected.DETFAC_CODIGO.length > 0 &&
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].getFocusedCell().column.colId === 'DETFAC_LISTA') {
        const LISTPREC = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LISTPREC').CFG_VALOR1;

        if (LISTPREC === 1) {
          this.utilitariosService.getListaPrecioArt(this.detfacSelected.DETFAC_CODIGO).subscribe((lista) => {
            const opciones = [];
            opciones.push(this.detfacSelected.DETFAC_LISTA);
            lista.map((codigo) => {
              if (codigo.ARTPRE_CODIGO !== opciones[0]) {
                opciones.push(codigo.ARTPRE_CODIGO);
              }
            });
            this.columnDefsDetfac[11].cellEditorParams = {
              values: opciones
            };

            this.aggrid.refreshColumnDefs();
            this.listarefrescada = true;
          });
        }
      }
    }
  }

  changelistaprecios(itemDet) {
    if (itemDet.DETFAC_UNIDAD !== null) {
      if (itemDet.DETFAC_UNIDAD.length > 0) {
        let dblPrecio = 0;
        this.encfacService.ecChangelistaprecios(itemDet.DETFAC_CODIGO, itemDet.DETFAC_LISTA, itemDet.DETFAC_UNIDAD).subscribe(data => {
          for (const rs of data) {
            dblPrecio = rs.PRECIO;
          }

          const FCPRINIVA = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCPRINIVA').CFG_VALOR1;
          // configuración que indica si el precio en los artículos está
          // incluído el iva
          if (FCPRINIVA === 1 && itemDet.DETFAC_TRIBIVA === 'S') {
            dblPrecio = dblPrecio / (1 + Number(itemDet.DETFAC_PORIVA) / 100);
          }

          if (itemDet.DETFAC_TRIBIVA === 'S') {
            if (dblPrecio > 0) {
              if (this.selectiva.codigo === '2') {
                dblPrecio = dblPrecio
                  / (1 + Number(itemDet.DETFAC_PORIVA) / 100);
              }
            }
          }
          this.calcularDescuento(itemDet);
          itemDet.DETFAC_PRECIO = dblPrecio;
          this.encfacService.calcularTotales();
          this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
        });
      }
    }
  }
  //CDPJ
  async calcularDescuento(itemDet) {
    try {
      //console.log('antes', itemDet.DETFAC_LISTA);
      const dato = await this.encfacService.erArtPreDescuento(itemDet.DETFAC_CODIGO, itemDet.DETFAC_LISTA);
      console.log(dato);
      //console.log('1.0',dato['ARTPRE_DESCUENTO']);
      if (dato !== null) {
        ///console.log('1',dato[0].ARTPRE_DESCUENTO);
        if (dato.length > 0) {
          //console.log('2',dato[0]);
          if (dato[0].ARTPRE_CODIGO !== null) {
            itemDet.DETFAC_DESCUENTO = Number(dato[0].ARTPRE_DESCUENTO);
            console.log('descuento', itemDet.DETFAC_DESCUENTO);
          } else {
            itemDet.DETFAC_DESCUENTO = 0;
          }
        } else {
          itemDet.DETFAC_DESCUENTO = 0;
        }

      } else {
        itemDet.DETFAC_DESCUENTO = 0;
      }
    } catch (err) {
      console.log(err)
    }
    this.encfacService.calcularTotales();
    this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
  }
  async aggTotdsctglo() {
    this.closeSidebar();
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está anulada.'
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PAGADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está pagada.'
      });
      return;
    }
    if (this.detfacSelected.DETFAC_INCLUENDSCTO === 'S') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'El detalle seleccionado ya está incluido en el descuento'

      });
      return;
    }

    if (Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL)) > 0) {
      //  //let descValorI=0
      //  let desValorDif=0;
      //  let desValorBcero=0;
      //  if (this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === undefined
      //    || this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === null
      //    || this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === ''
      //    || this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === 0) {
      //    // this.valorDscPorcentaje = 0;
      //    // this.valorDscValor = 0;
      //      //this.valorDscPorcentaje =0;
      //      this.valorotrdsctos =   this.detfacSelected.DETFAC_TOTAL - this.detfacSelected.DETFAC_TOTAL * this.descGlobal / 100 ;
      //     //  this.booleanValor = false;
      //     //  this.booleanPorcentaje = true;
      //     } else {
      //       if(this.detfacSelected.DETFAC_TRIBIVA === 'S'){
      //         desValorDif=this.detfacSelected.DETFAC_TOTAL - (this.detfacSelected.DETFAC_TOTAL * (this.descGlobal / 100));

      //         //this.valorotrdsDif= Number(Number(this.init.quitarComas(this.valorotrdsDif)).toFixed(this.confIniciales.getNumDecSist())) +  Number(Number(this.init.quitarComas(desValorDif)).toFixed(this.confIniciales.getNumDecSist()));
      //         this.valorotrdsDif= Number(this.init.quitarComas(this.valorotrdsDif)) +  Number(this.init.quitarComas(desValorDif));

      //       }else if(this.detfacSelected.DETFAC_TRIBIVA === 'N'){
      //         desValorBcero=this.detfacSelected.DETFAC_TOTAL - (this.detfacSelected.DETFAC_TOTAL * (this.descGlobal / 100));

      //         //this.valorotrdsBacero= Number(Number(this.init.quitarComas(this.valorotrdsBacero)).toFixed(this.confIniciales.getNumDecSist())) +  Number(Number(this.init.quitarComas(desValorBcero)).toFixed(this.confIniciales.getNumDecSist()));
      //         this.valorotrdsBacero= Number(this.init.quitarComas(this.valorotrdsBacero)) +  Number(this.init.quitarComas(desValorBcero));

      //       }
      //       //desValorDif=this.detfacSelected.DETFAC_TOTAL - (this.detfacSelected.DETFAC_TOTAL * (this.descGlobal / 100));
      //       console.log('desValorDif',desValorDif)
      //       console.log('desValorBcero',desValorBcero)
      //       this.valorotrdsctos =Number(this.init.quitarComas(this.valorotrdsctos)) +  Number(this.init.quitarComas(desValorDif)) +  Number(this.init.quitarComas(desValorBcero));
      //       this.valorotrdsctos=Number(Number(this.init.quitarComas(this.valorotrdsctos)).toFixed(this.confIniciales.getNumDecSist()))
      //       this.valorotrdsDif=Number(Number(this.init.quitarComas(this.valorotrdsDif)).toFixed(this.confIniciales.getNumDecSist()))
      //       this.valorotrdsBacero=Number(Number(this.init.quitarComas(this.valorotrdsBacero)).toFixed(this.confIniciales.getNumDecSist()))

      //       console.log('this.valorDscValor',this.valorDscValor)
      //     //   this.valorDscPorcentaje = this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES;
      //     //   const strdscporce = Number(this.init.quitarComas(this.valorDscPorcentaje)).toFixed(this.confIniciales.getNumDecSist());
      //     //   this.valorDscPorcentaje = Number(strdscporce);
      //     }
      //   //*****calcular el descuento */
      //   if (this.booleanPorcentaje === false) {
      //    // this.valorDscValor = Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL)) * this.valorDscPorcentaje / 100;
      //   }

      //   if (this.booleanValor === false) {
      //     //this.valorDscPorcentaje = this.valorDscValor * 100 / Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL));
      //   }
      this.botonGuardar = false;
      this.botonNuevo = true;
      this.detfacSelected.DETFAC_INCLUENDSCTO = 'S';
      //   // if (Number(this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM) > 0) {
      //   //   if (this.valorDscPorcentaje > Number(this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM)) {
      //   //     this.messageService.add({
      //   //       key: 'encfac',
      //   //       severity: 'warn',
      //   //       summary: 'Información',
      //   //       detail: 'El Descuento máximo que se puede '
      //   //         + 'aplicar al cliente es '
      //   //         + this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM
      //   //         + '%.'
      //   //     });
      //   //     this.displayDialogDescuento = false;
      //   //     this.valorDscPorcentaje = Number(this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM);
      //   //     this.valorDscValor = 0;
      //   //     return;
      //   //   }
      //   // }
      //    this.encfacService.dblValorotrdscto = Number(this.valorotrdsctos);
      //    this.encfacService.dblValorotrdsctoDif =Number(this.valorotrdsDif);
      //    this.encfacService.dblValorotrdsctoBcero = Number(this.valorotrdsBacero);
      //   // this.encfacService.dblPorcDescGlb = (this.encfacService.dblValorDescGlb * 100) /
      //   //   Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO));
      //   // this.valorDscPorcentaje = this.encfacService.dblPorcDescGlb;


      this.encfacService.calcularTotales();
    }
    this.messageService.add({
      key: 'encfac',
      severity: 'success',
      summary: 'Información',
      detail: 'Total agregado al descuento global'
    });
  }
  async reversaraggTotdsctglo() {
    this.closeSidebar();
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está anulada.'
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PAGADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está pagada.'
      });
      return;
    }
    if (this.detfacSelected.DETFAC_INCLUENDSCTO === 'S') {
      if (Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL)) > 0) {
        //     let descValorI=0;
        //     let desValorDif=0;
        //     let desValorBcero=0;
        //     if (this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === undefined
        //       || this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === null
        //       || this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === ''
        //       || this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES === 0) {
        // // this.valorDscPorcentaje = 0;
        //      // this.valorDscValor = 0;
        //        //this.valorDscPorcentaje =0;
        //        this.valorotrdsctos =   this.detfacSelected.DETFAC_TOTAL - this.detfacSelected.DETFAC_TOTAL * this.descGlobal / 100 ;
        //       //  this.booleanValor = false;
        //       //  this.booleanPorcentaje = true;
        //        } else {
        //         if(this.detfacSelected.DETFAC_TRIBIVA === 'S'){
        //           desValorDif=this.detfacSelected.DETFAC_TOTAL - (this.detfacSelected.DETFAC_TOTAL * (this.descGlobal / 100));
        //           //this.valorotrdsDif= Number(Number(this.init.quitarComas(this.valorotrdsDif)).toFixed(this.confIniciales.getNumDecSist())) -  Number(Number(this.init.quitarComas(desValorDif)).toFixed(this.confIniciales.getNumDecSist()));
        //           this.valorotrdsDif= Number(this.init.quitarComas(this.valorotrdsDif)) -  Number(this.init.quitarComas(desValorDif));

        //         }else if(this.detfacSelected.DETFAC_TRIBIVA === 'N'){
        //           desValorBcero=this.detfacSelected.DETFAC_TOTAL - (this.detfacSelected.DETFAC_TOTAL * (this.descGlobal / 100));
        //           //this.valorotrdsBacero= Number(Number(this.init.quitarComas(this.valorotrdsBacero)).toFixed(this.confIniciales.getNumDecSist())) -  Number(Number(this.init.quitarComas(desValorBcero)).toFixed(this.confIniciales.getNumDecSist()));
        //           this.valorotrdsBacero= Number(this.init.quitarComas(this.valorotrdsBacero)) -  Number(this.init.quitarComas(desValorBcero));

        //         }
        //         //desValorDif=this.detfacSelected.DETFAC_TOTAL - (this.detfacSelected.DETFAC_TOTAL * (this.descGlobal / 100));
        //         console.log('desValorDif',desValorDif)
        //         console.log('desValorBcero',desValorBcero)
        //         //this.valorotrdsctos =Number(Number(this.init.quitarComas(this.valorotrdsctos)).toFixed(this.confIniciales.getNumDecSist())) -  Number(Number(this.init.quitarComas(desValorDif)).toFixed(this.confIniciales.getNumDecSist())) -  Number(Number(this.init.quitarComas(desValorBcero)).toFixed(this.confIniciales.getNumDecSist()));
        //         this.valorotrdsctos =Number(this.init.quitarComas(this.valorotrdsctos)) -  Number(this.init.quitarComas(desValorDif)) -  Number(this.init.quitarComas(desValorBcero));
        //         console.log('<>this.valorDscValor',this.valorotrdsctos)
        //         this.valorotrdsctos =Number(Number(this.init.quitarComas(this.valorotrdsctos)).toFixed(this.confIniciales.getNumDecSist()))
        //         this.valorotrdsDif =Number(Number(this.init.quitarComas(this.valorotrdsDif)).toFixed(this.confIniciales.getNumDecSist()))
        //         this.valorotrdsBacero =Number(Number(this.init.quitarComas(this.valorotrdsBacero)).toFixed(this.confIniciales.getNumDecSist()))      
        //         console.log('this.valorDscValor',this.valorotrdsctos)
        //        }
        //      //*****calcular el descuento */
        //      if (this.booleanPorcentaje === false) {
        //       // this.valorDscValor = Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL)) * this.valorDscPorcentaje / 100;
        //      }

        //      if (this.booleanValor === false) {
        //        //this.valorDscPorcentaje = this.valorDscValor * 100 / Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL));
        //      }
        this.botonGuardar = false;
        this.botonNuevo = true;
        this.detfacSelected.DETFAC_INCLUENDSCTO = 'N';
        //      // if (Number(this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM) > 0) {
        //      //   if (this.valorDscPorcentaje > Number(this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM)) {
        //      //     this.messageService.add({
        //      //       key: 'encfac',
        //      //       severity: 'warn',
        //      //       summary: 'Información',
        //      //       detail: 'El Descuento máximo que se puede '
        //      //         + 'aplicar al cliente es '
        //      //         + this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM
        //      //         + '%.'
        //      //     });
        //      //     this.displayDialogDescuento = false;
        //      //     this.valorDscPorcentaje = Number(this.encfacService.encfacarray[this.indicador].CLI_DESCUENTOLIM);
        //      //     this.valorDscValor = 0;
        //      //     return;
        //      //   }
        //      // }
        //     //  this.encfacService.dblValorDescGlb = this.valorDscValor;
        //     //  this.encfacService.dblPorcDescGlb = (this.encfacService.dblValorDescGlb * 100) /
        //     //    Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO));
        //     //  this.valorDscPorcentaje = this.encfacService.dblPorcDescGlb;
        //     this.encfacService.dblValorotrdscto = Number(this.valorotrdsctos);
        //     this.encfacService.dblValorotrdsctoDif =Number(this.valorotrdsDif);
        //     this.encfacService.dblValorotrdsctoBcero = Number(this.valorotrdsBacero);
        this.encfacService.calcularTotales();
      }
      this.messageService.add({
        key: 'encfac',
        severity: 'success',
        summary: 'Información',
        detail: 'Total eliminado del descuento global'
      });
    } else {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'El detalle seleccionado ya no está incluido en el descuento'

      });
      return;
    }
  }
  ////***DISTRIBUCION DE LOTES */
  async reversarInvLotes() {
    this.closeSidebar();
    let resp = [];
    resp = await this.encfacService.getEncfac(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);

    if (this.botonGuardar === false) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Guarde la factura antes de realizar el reverso de la distribución de lotes.'
      });
      return;
    }
    if (resp === null || this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO === null
      || this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO === ''
      || this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO === undefined) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Debe guardar la factura antes de realizar el reverso de la distribución de lotes.'
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'AT'
      || this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'NT'
    ) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'No se puede reversar la distitribuir lotes en facturas cuyo estado sea AT o NT'
      });
      return;
    }
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PAGADA'
      || this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA'
    ) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'No se puede reversar la distitribuir lotes en facturas pagadas o anuladas'
      });
      return;
    }
    this.confirmationService.confirm({
      message: 'Está seguro de reversar la distribución de lotes automático',
      header: 'Reversar Lotes',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfac',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        await this.reversarlotes()

      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  async distribuirInvLotes() {
    this.closeSidebar();
    let resp = [];
    let numerolotes = 0;
    resp = await this.encfacService.getEncfac(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);

    if (this.botonGuardar === false) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Guarde la factura antes de realizar la distribución de lotes.'
      });
      return;
    }
    if (resp === null || this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO === null
      || this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO === ''
      || this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO === undefined) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Debe guardar la factura antes de realizar la distribución de lotes.'
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'AT'
      || this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'NT'
    ) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'No se puede distitribuir lotes en facturas cuyo estado sea AT o NT'
      });
      return;
    }
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PAGADA'
      || this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA'
    ) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'No se puede distitribuir lotes en facturas pagadas o anuladas'
      });
      return;
    }
    this.confirmationService.confirm({
      message: 'Está seguro de realizar la distribución de lotes automático?',
      header: 'Distribuir Lotes',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfac',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        const data = await this.encfacService.getNumlotes(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
        if (data !== null && data !== undefined) {
          for (const num of data) {
            numerolotes = Number(num.NUMERO)
          }
        }
        console.log('numerolotes', numerolotes)
        if (Number(numerolotes) > 0) {
          console.log('si')
          await this.confirmarelimlotes()
        } else {
          await this.distribuirLotes()
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  async confirmarelimlotes() {
    console.log('si2')
    this.confirmationService.confirm({
      message: 'El documento tiene lotes ingresados, desea eliminarlos?',
      header: 'Eliminar Lotes',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfac',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        await this.reversarlotes()
        await this.distribuirLotes()
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  async reversarlotes() {
    try {
      await this.encfacService.reversarLotes(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
      await this.encfacService.agregarlotescomenatario(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
      this.messageService.add({
        key: 'encfac',
        severity: 'success',
        summary: 'Información',
        detail: 'Éxito al reversar la distribución de lotes'
      });
      await this.encontrarFactura(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, '');
    } catch (error) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Hubo un error al reversar la distribución de lotes'
      });
      return;
    }

  }
  async distribuirLotes() {
    try {
      await this.encfacService.distribuirLotes(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
      this.messageService.add({
        key: 'encfac',
        severity: 'success',
        summary: 'Información',
        detail: 'Éxito al distribuir los lotes'
      });
      this.confirmationService.confirm({
        message: 'Agregar los lotes ingresados como comentario?',
        header: 'Distribuir Lotes',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencfac',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          await this.agregarlotescomentario()

        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } catch (error) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Hubo un error al distribuir los lotes'
      });
      return;
    }
  }
  agregarcomentarioInvLotes() {
    this.closeSidebar();
    this.confirmationService.confirm({
      message: 'Agregar los lotes ingresados como comentario?',
      header: 'Distribuir Lotes',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfac',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        await this.agregarlotescomentario()

      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  async agregarlotescomentario() {
    try {
      await this.encfacService.agregarlotescomenatario(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
      this.messageService.add({
        key: 'encfac',
        severity: 'success',
        summary: 'Información',
        detail: 'Éxito al agregar como comentario los lotes ingresados'
      });
      this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/' + 'ACCIONES_AGG_LOTES_COMENTARIO', 'A',
        '', '01', '', '', '', '').subscribe(() => {
        });
      await this.encontrarFactura(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, '');
    } catch (error) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Hubo un error al agregar como comentario los lotes ingresados'
      });
      return;

    }
  }
  //CDPJ
  calcularFechaVencimiento() {
    const dateTimeFecIni: Date = this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION;
    this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAVENCIMIENTO = this.obtenerFechaVence(dateTimeFecIni.getDate(),
      dateTimeFecIni.getMonth(), dateTimeFecIni.getFullYear());
  }

  obtenerFechaVence(dia, mes, anio): Date {
    let fecha1: Date;
    fecha1 = new Date(anio, mes, dia);

    fecha1.setDate(dia + (this.encfacService.encfacarray[this.indicador].ENCFAC_DIASCRDT === '' ? 0 :
      Number(this.encfacService.encfacarray[this.indicador].ENCFAC_DIASCRDT)));

    return fecha1;
  }
  ///GSRF
  calculardiasVencimiento() {
    const dateTimeFecIn: Date = this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION;
    const dateTimeFecFi: Date = this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAVENCIMIENTO;
    let fechaini: Date;
    let fechafin: Date;
    fechaini = new Date(dateTimeFecIn.getFullYear(), dateTimeFecIn.getMonth(), dateTimeFecIn.getDate());
    fechafin = new Date(dateTimeFecFi.getFullYear(), dateTimeFecFi.getMonth(), dateTimeFecFi.getDate());
    var dias = fechafin.getTime() - fechaini.getTime();
    console.log(fechafin.getTime());
    console.log(fechaini.getTime());
    var total = Math.round(dias / (1000 * 60 * 60 * 24));
    console.log(total);
    if (total < 0) {
      this.encfacService.encfacarray[this.indicador].ENCFAC_DIASCRDT = '0';
      this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAVENCIMIENTO = this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION;
    } else {
      this.encfacService.encfacarray[this.indicador].ENCFAC_DIASCRDT = total;
    }
  }
  ///GSRF
  limpiarLineasVacias() {
    for (let i = this.encfacService.detfacarray[this.indicador].length - 1; i >= 0; i--) {
      if (this.encfacService.detfacarray[this.indicador][i].DETFAC_CODIGO === '' &&
        this.encfacService.detfacarray[this.indicador][i].DETFAC_DESCRIPCION === '') {
        this.encfacService.detfacarray[this.indicador].splice(i, 1);
      }
    }
  }


  verificarActualizarEstPed() {
    for (const pedido of this.numeroPedidos) {
      this.encfacService.verificarActualizarEstPed(pedido).subscribe();
    }
  }
  //CDPJ
  actualizarInvtrnlote(strNumFact) {
    this.encfacService.ActualizaInvtrnlote(strNumFact).subscribe();
  }
  //CDPJ
  verificarActualizarEstPedFactu() {
    for (const pedido of this.numeroPedidos) {
      this.encfacService.verificarActualizarEstPedFact(pedido).subscribe();
    }
  }

  verificarActualizarEstOrdTra() {
    for (const ordtra of this.numeroOrdtra) {
      this.encfacService.verificarActualizarEstOrdTra(ordtra).subscribe();
    }
  }

  extraerPedidos(strNumFact) {
    let strNumPed = '';
    this.encfacService.erExtraerPedidos(strNumFact).subscribe(er => {
      if (er !== null) {
        if (er !== undefined) {
          strNumPed = er[0].COUNT;
        }
      }

      let intNumPed = 0;

      if (strNumPed.length > 0) {
        intNumPed = Number(strNumPed);
      }

      if (intNumPed > 0) {
        this.listaPedidos = [];
        this.encfacService.ecExtraerPedidos(strNumFact).subscribe(data => {
          for (const rs of data) {
            const item: VenDetfacZ = {};
            item.ENCPED_NUMERO(rs.ENCPED_NUMERO);
            item.DETPED_LINEA(rs.DETPED_LINEA);

            this.listaPedidos.push(item);
          }
        });
      }
    });
  }

  extraerNumeroPedidos(strNumFact, tipo) {
    this.numeroPedidos = [];
    this.encfacService.ecExtraerNumeroPedidos(strNumFact).subscribe(data => {
      for (const rs of data) {
        this.numeroPedidos.push(rs.ENCPED_NUMERO);
      }

      if (tipo === 'A' || tipo === 'G') {
        if (this.numeroPedidos.length > 0) {
          this.verificarActualizarEstPed(); // actualiza el estado del pedido
          this.actualizarFechaEntregaPed();
          this.actualizarDespachoPed();

          if (this.intACTPEDVAL === 1) {
            this.verificarActualizarEstPedFactu(); // actualiza el estado del pedido
            this.actualizarFacturadoPed();
          }
        }
      }

      if (tipo === 'S') {
        for (const pedido of this.numeroPedidos) {
          this.cantidadsobrantepedidos(pedido);
        }
      }
    });
  }

  cantidadsobrantepedidos(pedido) {
    this.encfacService.consultarPedido(pedido).subscribe(dataped => {
      for (const det of this.encfacService.detfacarray[this.indicador]) {
        if (det.ENCPED_NUMERO !== null && det.ENCPED_NUMERO !== undefined && det.ENCPED_NUMERO !== '') {
          for (const rs of dataped) {
            if (rs.DETPED_LINEA === det.DETPED_LINEA && rs.DETPED_CODIGO === det.DETFAC_CODIGO) {
              let dblCantPend = 0;
              const dblCantFac = rs.CANTARTFACDELPEDIDO;
              const dblCantGuiaR = rs.CANTARTGUIADELPEDIDO;

              dblCantPend = rs.DETPED_CANTIDAD - dblCantFac
                - dblCantGuiaR + Number(det.DETFAC_CANTIDAD); // TOTAL DESPACHADO
              const dblCantFacActual = this.obtenerCantFacActual(rs.DETPED_CODIGO, rs.DETPED_LINEA,
                0, '', rs.ENCPED_NUMERO); // cantidad en los otros
              // items de la factura
              if (dblCantPend + dblCantFacActual > 0 || rs.DETPED_TIPODET === '*') {
                det.DETPED_CANTIDAD = rs.DETPED_CANTIDAD
                  - dblCantFac - dblCantGuiaR + dblCantFacActual;
              }
            }
          }
        }
      }
    });
  }

  actualizarFechaEntregaPed() {
    for (const pedido of this.numeroPedidos) {
      // extraer la fecha de entrega

      let strFechaEnt = '';
      this.encfacService.ecActualizarFechaEntregaPed(pedido).subscribe(data => {
        for (const rs of data) {
          strFechaEnt = this.datePipe.transform(rs.FECHAEMISION, 'dd/MM/yyyy');
        }
        this.encfacService.ActualizaFechaEntrega(pedido, strFechaEnt).subscribe();
      });
    }
  }


  actualizarDespachoPed() {
    for (let fila = 1; fila <= this.numeroPedidos.length; fila++) {
      this.encfacService.actualizaDespachoPedido(this.numeroPedidos[fila - 1]).subscribe();
    }
  }

  actualizarFacturadoPed() {
    for (let fila = 1; fila <= this.numeroPedidos.length; fila++) {
      this.encfacService.actualizaFacturadoPedido(this.numeroPedidos[fila - 1]).subscribe();
    }
  }

  extraerGuiasRem(strNumFact, tipo) {
    let strNumGuia = '';
    this.listaGuias = [];
    this.encfacService.erExtraerGuiasRem(strNumFact).subscribe(er => {

      if (er !== null) {
        if (er[0] !== undefined) {
          strNumGuia = er[0].COUNT;
        }
      }

      if (Number(strNumGuia) > 0) {
        let strSql = 'SELECT DISTINCT ENCGRE_NUMERO, DETGRE_LINEA FROM VEN_DETFAC';
        strSql = strSql + ' WHERE ENCFAC_NUMERO = \'' + strNumFact + '\'';
        strSql = strSql + ' AND COM_CODIGO = \'01\' AND NOT(ENCGRE_NUMERO IS NULL)';

        this.encfacService.ecExtraerGuiasRem(strNumFact).subscribe(data => {

          for (const rs of data) {
            const item: VenDetfacZ = {};
            item.ENCGRE_NUMERO = rs.ENCGRE_NUMERO;
            item.DETGRE_LINEA = rs.DETGRE_LINEA;
            this.listaGuias.push(item);
          }

          if (tipo === 'A' || tipo === 'G') {
            if (this.listaGuias.length > 0) {
              this.actualizarDespachoGuiaRem();
            }
          }
        });
      }
    });
  }

  extraerNumeroGuias(strNumFact, tipo) {

    this.encfacService.ecExtraerNumeroGuias(strNumFact).subscribe(data => {
      this.numeroGuias = [];
      for (const rs of data) {
        this.numeroGuias.push(rs.ENCGRE_NUMERO);
      }
      if (tipo === 'A' || tipo === 'G') {
        if (this.numeroGuias.length > 0) {
          this.verificarActualizarEstGuia();
        }
      }
    });
  }

  actualizarDespachoGuiaRem() {
    for (const guia of this.listaGuias) {
      this.encfacService.actualizarDespachoGuiaRem(guia).subscribe();
    }
  }

  verificarActualizarEstGuia() {
    for (const guia of this.numeroGuias) {
      console.log(guia);
      this.encfacService.VerificarGuiaRem(guia).subscribe();
    }
  }


  colorearPrecio(precio) {
    if (precio.DETFAC_CODIGO !== '') {
      if (Number(precio.DETFAC_PRECIO) < Number(precio.COSTOPROM)) {
        return this.choosedColorPreBajoCosto;
      } else {
        return 'transparent';
      }
    } else {
      return 'transparent';
    }
  }
  //CDPJ
  ColorearTotal(total) {
    if (total.DETFAC_CODIGO !== '' && total.DETFAC_TIPODET !== '*') {
      if (total.DETFAC_INCLUENDSCTO === 'S') {
        return this.choosedColorTotalIncluEnDscto;
      } else {
        return 'transparent';
      }
    } else {
      return 'transparent';
    }
  }
  //CDPJ
  async comprobarPrecio(item) {
    let dblCostoProm = item.COSTO;
    const strFecha = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_HORAEMISION, 'HH:mm');


    const MARPREBCOS = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'MARPREBCOS').CFG_VALOR1;
    if (MARPREBCOS === 1) { // marcar el precio bajo el costo
      if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad
        // colorear el precio bajo el costo
        if (item.DETFAC_CODIGO.length > 0) {
          if (item.DETFAC_TIPODET !== 'Y') {
            /*const data = await this.encfacService.obtenerCostoPromedio(item
              .DETFAC_CODIGO, strFecha, strHora);
            for (const rs of data) {
              dblCostoProm = rs.COSTO;
            }*/
            item.COSTOPROM = dblCostoProm;
            this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
          }
        }
      }
    }

    const PREBAJCOM = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PREBAJCOM').CFG_VALOR1;

    // Si esta activado Marcar los precios que estan bajo el costo de compra
    if (PREBAJCOM === 1) { // marcar el precio bajo el costo

      if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad

        // colorear el precio bajo el costo
        if (item.DETFAC_CODIGO.length > 0) {
          if (item.DETFAC_TIPODET !== 'Y') {
            const data = await this.encfacService.obtenerCostoCompraGen(item
              .DETFAC_CODIGO, strFecha, strHora);
            for (const rs of data) {
              dblCostoProm = rs.COSTO;
            }
            item.COSTOPROM = dblCostoProm;
          }
        }
      }
    }
  }

  colorearCantidad(existencia) {
    if (existencia.DETFAC_CODIGO !== '') {
      // console.log('Existencia ', existencia.EXISTENCIA);
      if (Number(existencia.EXISTENCIA) < Number(existencia.ART_CANTMIN)) {
        return this.choosedColorExisMin;
      } else {
        return 'transparent';
      }
    } else {
      return 'transparent';
    }
  }
  coloreaarDescrip(item) {
    
    if (item.ENCPED_NUMERO !== '' && item.ENCPED_NUMERO !== null && item.ENCPED_NUMERO !== undefined
    && item.DETFAC_TIPODET !== '*' && item.DETFAC_TIPODET !== 'Y') {
      return this.choosedColorOrgPed
    } else if (item.ENCGRE_NUMERO !== '' && item.ENCGRE_NUMERO !== null && item.ENCGRE_NUMERO !== undefined && item.DETFAC_TIPODET !== '*' && item.DETFAC_TIPODET !== 'Y') {
      return this.choosedColorOrgGre
    }else{
      return 'transparent'
    }
  }

  async obtenerCantMinArt(strArtCodigo, strBodCodigo) {
    let dblCantMin = 0;

    try {
      const data = await this.encfacService.obtenerCantMinArt(strArtCodigo, strBodCodigo);
      for (const rs of data) {
        dblCantMin = rs.ARTEXT_MINIMA;
      }
    } catch (err) {
    }

    return dblCantMin;
  }

  async comprobarExistencia(item) {
    let dblCantMin = 0;
    let dblExist = 0;

    if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad
      if (item.DETFAC_TIPODET !== 'Y') {
        const data = await this.encfacService.obtenerCantMinArt(item
          .DETFAC_CODIGO, item.BOD_CODIGO);
        for (const rs of data) {
          dblCantMin = rs.ARTEXT_MINIMA;
          // dblExist = rs.EXISTENCIA;
        }
        item.ART_CANTMIN = dblCantMin;
        //item.EXISTENCIA = dblExist;
      }
    }
  }

  async ValidarSeriales(strNumDoc, strTipo, accion) {
    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PAGADA') {
      document.getElementById('contorno').style.pointerEvents = 'none';
      // document.getElementById('contornodetalle').style.pointerEvents = 'none';
      this.aggrid.bloquearGrid();
    }

    let errores = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let intLinea = 0;
    let strSentencia = '';

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      if (accion === 'navegacion') {
        this.irARegistro(strTipo);
      }
      if (accion === 'nuevo') {
        this.irAnuevaFac();
      }
      if (accion === 'buscar') {
        this.busquedafactura();
      }
      if (accion === 'contado') {
        this.PagoFacturaContado();
      }
      if (accion === 'credito') {
        this.Credito();
      }
      if (accion === 'salir') {
        this.init.tabs.splice(this.init.tabindex, 1);
      }
      if (accion === 'firmar') {
        this.validarFirmaElec();
      }
    } else {
      for (let i = 1; i <= this.encfacService.detfacarray[this.indicador].length; i++) {
        /*const data = await this.encfacService.encontrarRegistroPromise('ART_SERIALFLAG', 'inv_maearticulo',
          'art_codigo=\'' + this.encfacService.detfacarray[this.indicador][i - 1].DETFAC_CODIGO + '\'');
        if (data !== null) {
          if (data[0] !== undefined) {
            artserialflag = data[0].ART_SERIALFLAG;
          }
        }*/
        artserialflag = this.encfacService.detfacarray[this.indicador][i - 1].ART_SERIALFLAG;
        cantidad = this.encfacService.detfacarray[this.indicador][i - 1].DETFAC_CANTIDAD;
        intLinea = this.encfacService.detfacarray[this.indicador][i - 1].DETFAC_LINEA;
        articulo = this.encfacService.detfacarray[this.indicador][i - 1].DETFAC_CODIGO;

        if (artserialflag === 'S') {


          const data3 = await this.encfacService.ecpValidarSeriales(articulo, strNumDoc, intLinea);
          for (const rs of data3) {
            if (rs.COUN !== Number(cantidad)) {
              errores++;
            }
          }
        }
      }

      if (errores > 0) {
        this.messageService.add({
          key: 'encfac',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese los seriales.'
        });
      } else {
        if (accion === 'navegacion') {
          this.irARegistro(strTipo);
        }
        if (accion === 'nuevo') {
          this.irAnuevaFac();
        }
        if (accion === 'buscar') {
          this.busquedafactura();
        }
        if (accion === 'contado') {
          this.PagoFacturaContado();
        }
        if (accion === 'credito') {
          this.Credito();
        }
        if (accion === 'salir') {
          this.init.tabs.splice(this.init.tabindex, 1);
        }
        if (accion === 'firmar') {
          this.validarFirmaElec();
        }
      }
    }
  }

  traerCodigoConsumidorFinal() {
    const CONFINAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CONFINAUT').CFG_VALOR1;
    if (CONFINAUT === 1) {
      let strCodigoConFinal = '';
      let strNombreConFinal = '';

      this.encfacService.erTraerCodConsumidorFinal().subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            strCodigoConFinal = er[0].CFG_VALOR3;
          }
        }

        // trae el nombre del consumidor final
        this.encfacService.erTrCodigoConsFinal(strCodigoConFinal).subscribe(er2 => {
          if (er2 !== null) {
            if (er2[0] !== undefined) {
              strNombreConFinal = er2[0].CLI_NOMBRE;
            }
          }
          this.encfacService.encfacarray[this.indicador].CLI_CODIGO = strCodigoConFinal;
          this.encfacService.encfacarray[this.indicador].CLI_NOMBRE = strNombreConFinal;
        });
      });
    }
  }

  clienteSeleccionado(opcion) {
    // controlar la facturación de acuerdo al tipo de cliente
    // dependiendo de la configuración
    const FACTIPCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACTIPCLI').CFG_VALOR1;
    if (FACTIPCLI === 1) {
      this.encfacService.erClienteSeleccionado(this.encfacService.encfacarray[this.indicador].CLI_CODIGO).subscribe(er => {
        let strTipoCli = '';
        if (er !== null) {
          if (er[0] !== undefined) {
            strTipoCli = er[0].CLI_TIPO;
          }
        }
        if (strTipoCli === '2') {
          this.messageService.add({
            key: 'encfac',
            severity: 'error',
            summary: 'Información',
            detail: 'El Cliente está Bloqueado.'
          });
          // document.getElementById('contornodetalle').style.pointerEvents = 'none';
          this.aggrid.bloquearGrid();
        } else {
          // document.getElementById('contornodetalle').style.pointerEvents = 'all';
          this.aggrid.habilitarGrid();
        }
      });
    }
    this.completarDatosCliente(opcion);
    document.getElementById('VEN_CODIGO').focus();
  }


  controlarEstadoCliente(opcion) {
    if (opcion.CLI_ESTADO === 'D') {
      this.confirmationService.confirm({
        message: 'El cliente '
          + this.encfacService.encfacarray[this.indicador].CLI_CODIGO
          + '  NO está activo. Desea Continuar facturando?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencfac',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          const GENCLAAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENCLAAUT').CFG_VALOR1;
          if (GENCLAAUT === 0) {
            this.strDialogo = 'frmVEN_ENCFAC';
            this.strObjeto = 'cmdclicodigo';
            this.intPestaniaCol = 0;
            this.usuarioSup = '';
            this.claveSup = '';
            this.bolSupervisor = false;
            this.displayDialogSupervisor = true;
          } else {
            this.claveAuth = '';
            this.autorizacionCodigo = 'FCCLDA';
            this.bolAutorizacion = false;
            this.displayDialogAutorizacion = true;
          }
        },
        reject: () => {
          this.confirmationService.close();
          this.encfacService.encfacarray[this.indicador].CLI_CODIGO = '';
          this.encfacService.encfacarray[this.indicador].CLI_NOMBRE = '';
          document.getElementById('CLI_CODIGO').focus();
        }
      });
    } else {
      this.clienteSeleccionado(opcion);
    }
  }


  async obtenerCantidadFisica(item) {
    const CANTFISICA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CANTFISICA').CFG_VALOR1;
    if (CANTFISICA === 1) { // ver
      // cantidad
      // física
      if (item.DETFAC_TIPODET !== 'S'
        && item.DETFAC_TIPODET !== 'C') {
        if (item.DETFAC_CODIGO !== '' && item.DETFAC_CODIGO !== undefined && item.DETFAC_CODIGO !== null) {
          if (item.DETFAC_CODIGO.length > 0) {
            if (item.DETFAC_TIPODET === 'A') { // si no es
              // un
              // artículo
              // compuesto
              await this.encfacService.obtenerExistenciaArticulo(item.DETFAC_CODIGO, item.BOD_CODIGO,
                this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO,
                this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy'),
                this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'HH:mm'),
                item, 'cantfisica');
              if (item.DETFAC_TIPODET !== '*') {
                const strExistencia = item.EXISTENCIA;
                const er = await this.encfacService.erpCosteoarticulo(item.DETFAC_CODIGO);
                let strUnidadCosteo = '';
                if (er !== null) {
                  if (er[0] !== undefined) {
                    strUnidadCosteo = er[0].ART_UNIDADCOSTEO;
                  }
                }
                this.encfacService.encfacarray[this.indicador].ENCFAC_CFISICA = strExistencia + ' ' + strUnidadCosteo;
              }
            } else {
              this.encfacService.encfacarray[this.indicador].ENCFAC_CFISICA = item.DETFAC_CANTIDAD;
            }
          } else {
            this.encfacService.encfacarray[this.indicador].ENCFAC_CFISICA = '';
          }
        } else {
          this.encfacService.encfacarray[this.indicador].ENCFAC_CFISICA = '';
        }
      } else {
        this.encfacService.encfacarray[this.indicador].ENCFAC_CFISICA = '';
      }
    }
  }

  desplegarPrecio(item) {
    // precio de venta al público
    let dblPrecio = Number(item.DETFAC_TOTAL) + Number(item.DETFAC_IVA)
      + Number(item.DETFAC_ICE);
    if (Number(item.DETFAC_CANTIDAD) !== 0) {
      dblPrecio = dblPrecio / Number(item.DETFAC_CANTIDAD);
    }
    this.encfacService.encfacarray[this.indicador].ENCFAC_PVP = dblPrecio.toFixed(2);
  }

  cerrarSeriales() {
    this.displayDialogSeriales = false;
  }

  salirDialog() {
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
    this.displayDialogAsiento = false;
    this.displayDialogPagoCredito = false;
    this.displayDialogDescuento = false;
    this.displayDialogRazon = false;
    this.displayDialogSeriales = false;
    this.displayDialogCuotas = false;
    this.displayDialogLotes = false;
    this.displayDialogLog = false;
    this.displayDialogEst = false;//CDPJ
    this.displayDialogDatosVentaArticulo = false;
    this.displayDialogPagoContado = false;
    this.displayDialogAutorizacion = false;
    this.displayDialogSupervisor = false;
    this.displayDialogReportes = false;
    this.displayAsiContable = false;
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  async leerDatos(strTipoCorreo, strCodigo) {
    if (strTipoCorreo === 'C') { // clientes
      // this.encfacService.erDatosCliCorreo(strCodigo).subscribe(er => {
      //   if (er !== null) {
      //     if (er[0] !== undefined) {
      //       this.txtCorreo1 = er[0].CLI_CORREO;
      //       this.txtCorreo2 = er[0].CLI_CORREO2;
      //     }
      //   }
      // });
      const correos = await this.encfacService.erDatosCliCorreo(strCodigo);
      console.log('--++-- correos', correos)
      if (correos !== null) {
        if (correos[0] !== undefined) {
          this.txtCorreo1 = correos[0].CLI_CORREO;
          this.txtCorreo2 = correos[0].CLI_CORREO2;
        }
      }
    }

    if (strTipoCorreo === 'P') {
      this.encfacService.erDatosProCorreo(strCodigo).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            this.txtCorreo1 = er[0].PRO_CORREO;
            this.txtCorreo2 = er[0].PRO_CORREO2;
          }
        }
      });
    }
  }

  actualizarDatos(strTipoCorreo, strCodigo) {
    if (this.txtCorreo1 === null) {
      this.txtCorreo1 = '';
    }

    if (this.txtCorreo2 === null) {
      this.txtCorreo2 = '';
    }

    if (this.txtCorreo1.length === 0) {

      this.encfacService.erActualizarDatos().subscribe(er => {
        let correocopia = '';
        if (er !== null) {
          if (er[0] !== undefined) {
            correocopia = er[0].COM_CORREOCOPIAFAC;
          }
        }

        this.txtCorreo1 = correocopia;
      });


    }

    let strSentencia = '';


    if (strTipoCorreo === 'C') { // clientes

      this.encfacService.ecActualizarCorreoCli(this.txtCorreo1, this.txtCorreo2, strCodigo).subscribe();
      this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.txtCorreo1 + '/' + this.txtCorreo2 + '/' + strCodigo, 'A',
        '', '01', '', '', '', '').subscribe();
      //CDPJ
      try {
        for (const infoadic of this.veninfoadicfac) {
          console.log(infoadic);
          if (infoadic.INFAD_CODIGO === 'correo') {
            infoadic.INFAD_DESCRIPCION = this.txtCorreo1;
            this.encfacService.guardarInfoAdicFac(infoadic).subscribe((data) => {
              if (infoadic.INFAD_NUMSEC === 0) {
                for (const rs of data) {
                  infoadic.INFAD_NUMSEC = rs[':B1'];
                }
                this.auditoriagral.registrarAuditoria('VEN_INFOADICFAC',
                  infoadic.ENCFAC_NUMERO + '/' + infoadic.INFAD_CODIGO, 'I', '', '01', '', '', '', '').subscribe();
              } else {
                this.auditoriagral.registrarAuditoria('VEN_INFOADICFAC',
                  infoadic.ENCFAC_NUMERO + '/' + infoadic.INFAD_CODIGO, 'A', '', '01', '', '', '', '').subscribe();
              }
            });
          }
          console.log('2', infoadic);
        }
      } catch (error) {
        //console.log(infoadic);
        console.log(error);
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Información',
          detail: 'No se pudo guardar los datos en la información adicional.'
        });
      }
      this.messageService.add({
        key: 'encfac',
        severity: 'success',
        summary: 'Información',
        detail: 'Datos guardados correctamente'
      });
      //CDPJ
    }


    if (strTipoCorreo === 'P') {// proveedores

      this.encfacService.ecActualizarCorreoPro(this.txtCorreo1, this.txtCorreo2, strCodigo).subscribe();

    }

    this.displayDialogActualizacionFE = false;

  }

  agregarDetallePedido(opcion) {
    this.limpiarLineasVacias();
    if (this.intACTPEDVAL === 0) {
      this.encfacService.consultarPedido(opcion.ENCPED_NUMERO).subscribe(async dataped => {
        let intTamLista = dataped.length;
        let intdetactual = Number(this.encfacService.detfacarray[this.indicador].length);
        let fila = 1;
        console.log(dataped);
        for (const rs of dataped) {
          this.spin = true;
          //GSRF
          if (this.encfacService.encfacarray[this.indicador].VEN_CODIGO !== rs.VEN_CODIGO) {
            this.confirmationService.confirm({
              message: 'El codigo de vendedor del pedido es ' + rs.VEN_CODIGO + ' desea cambiarlo?',
              header: 'Pregunta',
              icon: 'pi pi-exclamation-triangle',
              key: 'confirmarencfac',
              acceptLabel: 'Si',
              rejectLabel: 'No',
              accept: () => {
                this.confirmationService.close();
                this.encfacService.encfacarray[this.indicador].VEN_CODIGO = rs.VEN_CODIGO
                this.busquedavendedor(rs.VEN_CODIGO);
              },
              reject: () => {
                this.confirmationService.close();
              }
            });
          }
          //GSRF
          console.log(rs.ENCPED_TIPODSCTO);
          if (rs.ENCPED_TIPODSCTO !== null) {
            if (rs.ENCPED_TIPODSCTO === 'V') {
              this.dscSeleccionado = 'dscValor';
              this.booleanValor = false;
              this.booleanPorcentaje = true;
            }
            if (rs.ENCPED_TIPODSCTO === 'P') {
              this.dscSeleccionado = 'dscPorcentaje';
              this.booleanPorcentaje = false;
              this.booleanValor = true;
            }
          }

          this.valorDscValor = rs.ENCPED_VALORDES;
          this.valorDscPorcentaje = Number(rs.ENCPED_VALORDES) > 0 ? (Number(rs.ENCPED_VALORDES) * 100) / rs.ENCPED_TOTALNETO : 0;
          this.encfacService.dblPorcDescGlb = Number(rs.ENCPED_VALORDES) > 0 ? (Number(rs.ENCPED_VALORDES) * 100) / rs.ENCPED_TOTALNETO : 0;
          this.encfacService.dblValorDescGlb = rs.ENCPED_VALORDES;

          /*this.valorDscValor = rs.ENCPED_VALORDES;
          this.valorDscPorcentaje = rs.ENCPED_PORCEDES;
          this.encfacService.dblPorcDescGlb = rs.ENCPED_PORCEDES;
          this.encfacService.dblValorDescGlb = rs.ENCPED_VALORDES;*/

          const strArtCompuesto = rs.ART_COMPUESTO;
          const dblCantFac = rs.CANTARTFACDELPEDIDO;
          const dblCantGuiaR = rs.CANTARTGUIADELPEDIDO;

          let dblCantPend = 0;
          dblCantPend = rs.DETPED_CANTIDAD - dblCantFac
            - dblCantGuiaR; // TOTAL DESPACHADO
          const dblCantFacActual = this.obtenerCantFacActual(rs.DETPED_CODIGO, rs.DETPED_LINEA,
            0, '', rs.ENCPED_NUMERO); // cantidad en los otros
          // items de la factura

          if (dblCantPend - dblCantFacActual > 0 || rs.DETPED_TIPODET === '*') {
            const COMNUMPED = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'COMNUMPED').CFG_VALOR1;
            if (COMNUMPED !== 1) {
              if (fila === 1) {
                intdetactual++;
                //this.crearNuevoItem();
                this.nuevodetalle();
                this.nuevoItem.DETFAC_TIPODET = '*';
                this.nuevoItem.DETFAC_DESCRIPCION = '*** Pedido '
                  + rs.ENCPED_NUMERO + ' ***';
                this.nuevoItem.DETFAC_ORDEN = fila;
                this.nuevoItem.ENCPED_NUMERO = '';
                this.nuevoItem.DETFAC_TRIBIVA = '';
                this.nuevoItem.DETFAC_TRIBIVAAUX = '';
                this.nuevoItem.DETFAC_DESCUENTO = 0;
                this.nuevoItem.DETFAC_LINEA = intdetactual;
                this.nuevoItem.DETPED_LINEA = 0;
                this.nuevoItem.DETFAC_PORIVA = 0;//CDPJ
                this.nuevoItem.TARIIVA_CODIGO = '-1';//CDPJ
                //this.encfacService.detfacarray[this.indicador].push(this.nuevoItem);
                fila = fila + 1;
              }
            }

            intdetactual++;
            //this.crearNuevoItem();
            this.nuevodetalle();
            const item: VenDetfacZ = this.nuevoItem;
            intTamLista = dataped.length;

            item.BOD_CODIGO = rs.BOD_CODIGO;
            item.COM_CODIGO = '01';
            item.DETFAC_LINEA = intdetactual;
            item.DETFAC_TIPODET = rs.DETPED_TIPODET;
            item.DETFAC_CODIGO = rs.DETPED_CODIGO;
            item.DETFAC_DESCRIPCION = rs.DETPED_DESCRIPCION;
            item.DETFAC_TRIBIVA = rs.DETPED_TRIBIVA;
            item.DETFAC_TRIBIVAAUX = rs.DETPED_TRIBIVA;
            item.DETFAC_TRIBICE = rs.DETPED_TRIBICE;
            item.DETFAC_UNIDAD = rs.UNI_SIMBOLO;
            item.DETFAC_CANTIDAD = rs.DETPED_CANTIDAD
              - dblCantFac - dblCantGuiaR - dblCantFacActual;
            item.DETPED_CANTIDAD = item.DETFAC_CANTIDAD;
            item.DETFAC_PROMOCION = '0';
            item.DETFAC_PRECIO = rs.DETPED_PRECIO;
            item.DETFAC_DESCUENTO = rs.DETPED_DESCUENTO;
            item.DETFAC_TOTAL = rs.DETPED_TOTAL;
            item.DETFAC_IVA = rs.DETPED_IVA;
            item.DETFAC_ICE = rs.DETPED_ICE;
            item.DETFAC_LISTA = rs.DETPED_LISTA;
            item.DETFAC_BASEIVA = rs.DETPED_BASEIVA;
            item.DETFAC_BASEICE = rs.DETPED_BASEICE;
            item.DETFAC_BASECERO = rs.DETPED_BASECERO;
            item.DETFAC_PORCEICE = rs.DETPED_PORCEICE;
            item.ENCPED_NUMERO = rs.ENCPED_NUMERO;
            item.DETPED_LINEA = rs.DETPED_LINEA;
            item.UNI_SIMBOLO = rs.UNI_CODIGO;
            item.ENCGRE_NUMERO = '';
            item.DETFAC_TIPOSRI = '';
            item.ART_MULTIUNIDAD = rs.ART_MULTIUNIDAD;
            item.CEN_CODIGO = rs.CEN_CODIGO === null || rs.CEN_CODIGO === undefined ? '' : rs.CEN_CODIGO;//CDPJ
            item.ENCFAC_NUMERO = '';
            item.DETFAC_PORIVA = rs.DETPED_PORIVA;//CDPJ
            item.TARIIVA_CODIGO = rs.TARIIVA_CODIGO;//CDPJ
            item.DETFAC_TRIBASENOOBJIVA = rs.TARIIVA_CODIGO === '6' ? 'S' : '';
            //GSRF
            const datas = await this.encfacService.consultarArticuloSelProm(rs.DETPED_CODIGO);
            for (const data of datas) {
              console.log("----->", data);
              item.DETFAC_IRBPNR = data.ART_IRBPNR === null || data.ART_IRBPNR === undefined ? 0 : data.ART_IRBPNR;
            }
            item.DETFAC_VALORIRBPNR = '0';
            //GSRF
            //this.encfacService.detfacarray[this.indicador].push(item);
            await this.comprobarPrecio(item);
            await this.comprobarExistencia(item);
            const intFCEXSNEG = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCEXSNEG').CFG_VALOR1;
            if (intFCEXSNEG === 0){
              await this.encfacService.verificarExistenciaArticulo(item);
            }
            
            intTamLista = intTamLista + 1;

            fila = fila + 1;

            if (item.DETFAC_TIPODET !== 'Y') {// sino es un componente indica que es un artículo compuesto
              if (strArtCompuesto === 'S') {
                const data22 = await this.encfacService.consultarComponentes(item.DETFAC_CODIGO, item
                  .BOD_CODIGO, item.DETFAC_LISTA);
                for (const rs2 of data22) {
                  const detfac: VenDetfacZ = this.encfacService.crearNuevoItemServ();
                  detfac.DETFAC_CODIGO = rs2.ART_CODIGOP;
                  detfac.DETFAC_UNIDAD = rs2.UNI_CODIGO;
                  detfac.DETFAC_DESCRIPCION = rs2.ART_NOMBRE;
                  detfac.DETFAC_CANTIDAD = rs2.ARTPARTE_CANTIDAD;
                  detfac.DETFAC_TIPODET = 'Y';
                  detfac.BOD_CODIGO = item.BOD_CODIGO;
                  detfac.DETFAC_PROMOCION = '0';
                  detfac.ENCFAC_NUMERO = '';
                  detfac.DETFAC_IRBPNR = 0;//GSRF
                  if (rs.PRECIO !== undefined) {
                    detfac.DETFAC_PRECIOC = rs2.PRECIO;
                    detfac.DETFAC_PRECIO = rs2.PRECIO;
                  } else {
                    detfac.DETFAC_PRECIOC = 0;
                    detfac.DETFAC_PRECIO = 0;
                  }
                  detfac.DETFAC_DESCUENTO = 0;
                  this.encfacService.consultarArticuloSel(detfac.DETFAC_CODIGO).subscribe(datas => {
                    for (const data of datas) {
                      if (detfac.ENCFAC_IVA !== 0) {
                        detfac.DETFAC_TRIBIVA = data.ART_TRIBUTAIVA;
                        detfac.DETFAC_TRIBIVAAUX = data.ART_TRIBUTAIVA;
                      } else {
                        detfac.DETFAC_TRIBIVA = 'N';
                        detfac.DETFAC_TRIBIVAAUX = 'N';
                      }
                      detfac.DETFAC_TRIBICE = data.ART_TRIBUTAICE;
                      detfac.DETFAC_UNIDAD = data.ART_UNIDADVENTA;
                      detfac.UNIDAD_VENTA = data.ART_UNIDADVENTA;
                      detfac.DETFAC_TRIBASENOOBJIVA = data.ART_BASENOOBJIVA;
                      detfac.ART_MULTIUNIDAD = data.ART_MULTIUNIDAD;
                      detfac.DETFAC_PORIVA = data.ART_PORIVA;//CDPJ
                      detfac.TARIIVA_CODIGO = data.TARIIVA_CODIGO;//CDPJ
                    }

                    this.encfacService.detfacarray[this.indicador].push(detfac);
                    this.encfacService.calcularTotales();
                    this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
                  });
                }



                item.DETFAC_TIPODET = 'Z';

                // obtener la cantidad del artículo compuesto
                // dependiendo de sus componentes
                if (item.DETFAC_TIPODET === 'Z') { // si es
                  // un
                  // artículo
                  // compuesto
                  /*let dblCantidad = UtilidadesVen
                  .obtenerCantidadArticuloCompuesto(item
                      .getDetFacCodigo(), item
                      .getDetFacCantidad(),
                    NUMDECCANTIDAD, item
                      .getBodCodigo(),
                    cmpFact.strFecha,
                    cmpFact.strHora);
                  item.setDetFacCantidad(dblCantidad);
                  cmpFact.lblCantFisica.setText(String
                    .valueOf(item.getDetFacCantidad()));*/
                }
              }
            }
          }

          this.encfacService.calcularTotales();
          this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
          this.spin = false;
        }
      });
    }

    if (this.intACTPEDVAL === 1) {
      this.encfacService.consultarPedidoCompuesto(opcion.ENCPED_NUMERO).subscribe(async dataped => {
        let intTamLista = dataped.length;
        let intdetactual = Number(this.encfacService.detfacarray[this.indicador].length);
        let fila = 1;

        for (const rs of dataped) {
          this.spin = true;
          console.log(rs);
          if (rs.ENCPED_TIPODSCTO !== null) {
            if (rs.ENCPED_TIPODSCTO === 'V') {
              this.dscSeleccionado = 'dscValor';
              this.booleanValor = false;
              this.booleanPorcentaje = true;
            }
            if (rs.ENCPED_TIPODSCTO === 'P') {
              this.dscSeleccionado = 'dscPorcentaje';
              this.booleanPorcentaje = false;
              this.booleanValor = true;

            }
          }


          this.valorDscValor = rs.ENCPED_VALORDES;
          this.valorDscPorcentaje = Number(rs.ENCPED_VALORDES) > 0 ? (Number(rs.ENCPED_VALORDES) * 100) / rs.ENCPED_TOTALNETO : 0;
          this.encfacService.dblPorcDescGlb = Number(rs.ENCPED_VALORDES) > 0 ? (Number(rs.ENCPED_VALORDES) * 100) / rs.ENCPED_TOTALNETO : 0;
          this.encfacService.dblValorDescGlb = rs.ENCPED_VALORDES;


          const strArtCompuesto = rs.ART_COMPUESTO;
          const dblTotalFac = rs.TOTARTDETFACPED;
          const dblTotalGuiaR = rs.TOTARTDETGREPED;

          let dblTotalPend = 0;
          dblTotalPend = rs.DETPED_PRECIO - dblTotalFac// rs.DETPED_TOTAL - dblTotalFac
            - dblTotalGuiaR; // TOTAL DESPACHADO
          const dblTotalFacActual = this.obtenerTotalDetFacActual(rs.DETPED_CODIGO, rs.DETPED_LINEA,
            0, '', rs.ENCPED_NUMERO); // cantidad en los otros
          // items de la factura

          if (dblTotalPend - dblTotalFacActual > 0 || rs.DETPED_TIPODET === '*') {
            if (fila === 1) {
              intdetactual++;
              //this.crearNuevoItem();
              this.nuevodetalle();
              this.nuevoItem.DETFAC_TIPODET = '*';
              this.nuevoItem.DETFAC_DESCRIPCION = '*** Pedido '
                + rs.ENCPED_NUMERO + ' ***';
              this.nuevoItem.DETFAC_ORDEN = fila;

              this.nuevoItem.ENCPED_NUMERO = '';
              this.nuevoItem.DETFAC_TRIBIVA = '';
              this.nuevoItem.DETFAC_TRIBIVAAUX = '';
              this.nuevoItem.DETFAC_DESCUENTO = 0;
              this.nuevoItem.DETFAC_LINEA = intdetactual;
              this.nuevoItem.DETPED_LINEA = 0;
              this.nuevoItem.DETFAC_PORIVA = 0;//CDPJ
              this.nuevoItem.TARIIVA_CODIGO = '-1';//CDPJ
              //this.encfacService.detfacarray[this.indicador].push(this.nuevoItem);
              fila = fila + 1;
            }

            intdetactual++;
            //this.crearNuevoItem();
            this.nuevodetalle();
            const item: VenDetfacZ = this.nuevoItem;
            intTamLista = dataped.length;

            if (rs.DETPED_TIPODET !== 'Y') {
              item.BOD_CODIGO = rs.BOD_CODIGO;
              item.COM_CODIGO = '01';
              item.DETFAC_LINEA = intdetactual;
              item.DETFAC_TIPODET = rs.DETPED_TIPODET;
              item.DETFAC_CODIGO = rs.DETPED_CODIGO;
              item.DETFAC_DESCRIPCION = rs.DETPED_DESCRIPCION;
              item.DETFAC_TRIBIVA = rs.DETPED_TRIBIVA;
              item.DETFAC_TRIBIVAAUX = rs.DETPED_TRIBIVA;
              item.DETFAC_TRIBICE = rs.DETPED_TRIBICE;
              item.DETFAC_UNIDAD = rs.UNI_SIMBOLO;
              item.DETFAC_CANTIDAD = 1,
                item.DETPED_CANTIDAD = item.DETFAC_CANTIDAD;
              item.DETFAC_PROMOCION = '0';
              item.DETFAC_PRECIO = rs.DETPED_PRECIO - rs.TOTARTDETFACPED; // rs.DETPED_PRECIO - rs.DETPED_FACTURADO;
              item.PRECIO_FACTURADO = item.DETFAC_PRECIO;
              item.DETFAC_DESCUENTO = rs.DETPED_DESCUENTO;
              item.DETFAC_TOTAL = rs.DETPED_TOTAL - rs.DETPED_FACTURADO
                - dblTotalFac - dblTotalGuiaR - dblTotalFacActual;
              item.DETFAC_IVA = rs.DETPED_IVA;
              item.DETFAC_ICE = rs.DETPED_ICE;
              item.DETFAC_LISTA = rs.DETPED_LISTA;
              item.DETFAC_BASEIVA = rs.DETPED_BASEIVA;
              item.DETFAC_BASEICE = rs.DETPED_BASEICE;
              item.DETFAC_BASECERO = rs.DETPED_BASECERO;
              item.DETFAC_PORCEICE = rs.DETPED_PORCEICE;
              item.ENCPED_NUMERO = rs.ENCPED_NUMERO;
              item.DETPED_LINEA = rs.DETPED_LINEA;
              item.UNI_SIMBOLO = rs.UNI_CODIGO;
              item.ENCGRE_NUMERO = '';
              item.DETFAC_TIPOSRI = '';
              item.ART_MULTIUNIDAD = rs.ART_MULTIUNIDAD;
              item.CEN_CODIGO = '';
              item.ENCFAC_NUMERO = '';
              item.DETFAC_PORIVA = rs.DETPED_PORIVA;//CDPJ
              item.TARIIVA_CODIGO = rs.TARIIVA_CODIGO;//CDPJ
              item.DETFAC_TRIBASENOOBJIVA = rs.TARIIVA_CODIGO === '6' ? 'S' : '';
              //GSRF
              const datas = await this.encfacService.consultarArticuloSelProm(rs.DETPED_CODIGO);
              for (const data of datas) {
                console.log("----->", data);
                item.DETFAC_IRBPNR = data.ART_IRBPNR === null || data.ART_IRBPNR === undefined ? 0 : data.ART_IRBPNR;
              }
              item.DETFAC_VALORIRBPNR = '0';
              //GSRF
              //this.encfacService.detfacarray[this.indicador].push(item);
              intTamLista = intTamLista + 1;

              fila = fila + 1;
            }
          }

          this.encfacService.calcularTotales();
          this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
          this.spin = false;
        }
      });
    }

  }

  mostrarEstadisticas() {
    this.calcularDocumentos();
    this.ventasAcumuladas();
    this.topProductos();
    this.detalleVentas();
    this.pedidosPendientes();
    this.pedidosOk();
    this.primeraFactura();
    this.chequeProtestado();
  }

  async primeraFactura() {

    try {
      const rs6 = await this.venEstadisticasService.primeraFactura(this.encfacService.encfacarray[this.indicador].CLI_CODIGO);
      this.fechaPrimeraFact = this.datePipe.transform(rs6[0].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    } catch (err) {

    }

  }

  async pedidosPendientes() {

    try {
      const rs4 = await this.venEstadisticasService.cantidadPedidos(
        this.encfacService.encfacarray[this.indicador].CLI_CODIGO, 'P');
      this.totalPedPendientes = rs4[0].CANTIDAD;
    } catch (err) {

    }
  }

  async pedidosOk() {

    try {

      const rs5 = await this.venEstadisticasService.cantidadPedidos(
        this.encfacService.encfacarray[this.indicador].CLI_CODIGO, 'OK');
      this.totalPedSatisfechos = rs5[0].CANTIDAD;

    } catch (err) {

    }
  }

  async chequeProtestado() {

    try {

      const rs7 = await this.venEstadisticasService.cantidadChProtestados(
        this.encfacService.encfacarray[this.indicador].CLI_CODIGO);
      this.totalChProtestados = rs7[0].TOTAL;

    } catch (err) {

    }
  }

  async detalleVentas() {

    const detalle: VenDetFacturas[] = [];

    try {
      const rs8 = await this.venEstadisticasService.detalleVentas(
        this.encfacService.encfacarray[this.indicador].CLI_CODIGO, 'fecha');
      this.detFacturas = [];
      if (rs8 !== null) {
        for (const item of rs8) {
          const det: VenDetFacturas = {};
          det.ENCFAC_NUMERO = item.ENCFAC_NUMERO;
          det.ENCFAC_FECHAEMISION = this.datePipe.transform(item.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.DETFAC_CANTIDAD = Number(item.DETFAC_CANTIDAD).toFixed(2);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          det.DETFAC_TOTAL = Number(item.DETFAC_TOTAL).toFixed(2);
          detalle.push(det);
        }

        this.detFacturas = detalle;
      }
    } catch (err) {

    }

  }

  async calcularDocumentos() {

    try {
      const rs1 = await this.venEstadisticasService.encontrarDocumentos('PORCOBRAR',
        this.encfacService.encfacarray[this.indicador].CLI_CODIGO);
      this.totalDocXCobrar = this.init.moneyValidation(Number(rs1[0].SALDO).toFixed(2));
      const rs2 = await this.venEstadisticasService.encontrarDocumentos('VENCIDOS',
        this.encfacService.encfacarray[this.indicador].CLI_CODIGO);
      this.totalDocVencidos = this.init.moneyValidation(Number(rs2[0].SALDO).toFixed(2));
      const rs3 = await this.venEstadisticasService.encontrarDocumentos('POSFECHADOS',
        this.encfacService.encfacarray[this.indicador].CLI_CODIGO);
      this.totalChPosfechados = this.init.moneyValidation(Number(rs3[0].SALDO).toFixed(2));

      this.totalDocumentos = Number(rs1[0].SALDO) + Number(rs2[0].SALDO) + Number(rs3[0].SALDO);
      this.totalDocXCobrarPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs1[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;
      this.totalDocVencidosPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs2[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;
      this.totalChPosfechadosPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs3[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;

      this.totalDocumentos = this.init.moneyValidation(Number(this.totalDocumentos).toFixed(2));

    } catch (err) {

    }

  }

  async orderByDetFacturas(tipo) {
    this.detFacturas = [];
    const detalle: VenDetFacturas[] = [];
    let rs8;


    try {

      if (tipo === 'fecha') {
        rs8 = await this.venEstadisticasService.detalleVentas(
          this.encfacService.encfacarray[this.indicador].CLI_CODIGO, 'fecha');
      }

      if (tipo === 'producto') {
        rs8 = await this.venEstadisticasService.detalleVentas(
          this.encfacService.encfacarray[this.indicador].CLI_CODIGO, 'producto');
      }

      if (rs8 !== null) {
        for (const item of rs8) {
          const det: VenDetFacturas = {};
          det.ENCFAC_NUMERO = item.ENCFAC_NUMERO;
          det.ENCFAC_FECHAEMISION = this.datePipe.transform(item.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.DETFAC_CANTIDAD = Number(item.DETFAC_CANTIDAD).toFixed(2);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          det.DETFAC_TOTAL = Number(item.DETFAC_TOTAL).toFixed(2);
          detalle.push(det);
        }

        this.detFacturas = detalle;
      }

    } catch (err) {

    }
  }

  async ventasAcumuladas() {

    const fechaInicial = new Date();
    fechaInicial.setMonth(fechaInicial.getMonth() - 12);
    fechaInicial.setDate(1);


    const fechaAct = new Date();
    const rangoFechas: any[] = [];

    for (let i = 1; fechaInicial <= fechaAct; i++) {
      rangoFechas.push(this.datePipe.transform(fechaInicial, 'dd/MM/yyyy'));
      fechaInicial.setMonth(fechaInicial.getMonth() + 1);
    }


    try {

      const rs = await this.venEstadisticasService.ventasAcumuladas(this.encfacService.encfacarray[this.indicador].CLI_CODIGO);


      if (rs !== null) {

        const index1 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[0]);
        const valor1 = index1 >= 0 ? rs[index1].VENTA_TOTAL : 0;
        const index2 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[1]);
        const valor2 = index2 >= 0 ? rs[index2].VENTA_TOTAL : 0;
        const index3 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[2]);
        const valor3 = index3 >= 0 ? rs[index3].VENTA_TOTAL : 0;
        const index4 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[3]);
        const valor4 = index4 >= 0 ? rs[index4].VENTA_TOTAL : 0;
        const index5 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[4]);
        const valor5 = index5 >= 0 ? rs[index5].VENTA_TOTAL : 0;
        const index6 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[5]);
        const valor6 = index6 >= 0 ? rs[index6].VENTA_TOTAL : 0;
        const index7 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[6]);
        const valor7 = index7 >= 0 ? rs[index7].VENTA_TOTAL : 0;
        const index8 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[7]);
        const valor8 = index8 >= 0 ? rs[index8].VENTA_TOTAL : 0;
        const index9 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[8]);
        const valor9 = index9 >= 0 ? rs[index9].VENTA_TOTAL : 0;
        const index10 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[9]);
        const valor10 = index10 >= 0 ? rs[index10].VENTA_TOTAL : 0;
        const index11 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[10]);
        const valor11 = index11 >= 0 ? rs[index11].VENTA_TOTAL : 0;
        const index12 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[11]);
        const valor12 = index12 >= 0 ? rs[index12].VENTA_TOTAL : 0;
        const index13 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[12]);
        const valor13 = index13 >= 0 ? rs[index13].VENTA_TOTAL : 0;

        this.dataVentasAcum = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [valor1, valor2, valor3, valor4, valor5, valor6, valor7,
              valor8, valor9, valor10, valor11, valor12, valor13]
          }
          ]
        };

      } else {
        this.dataVentasAcum = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          ]
        };
      }
    } catch (err) {

    }
  }

  async topProductos() {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;
    const detalle: VenTopProductos[] = [];

    try {
      const rs = await this.venEstadisticasService.topProductos(
        this.encfacService.encfacarray[this.indicador].CLI_CODIGO, 'cantidad');
      if (rs !== null) {
        for (const item of rs) {
          const det: VenTopProductos = {};
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFAC_PRECIO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: VenTopProductos = {};
        totales.DETFAC_CODIGO = '';
        totales.DETFAC_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFAC_PRECIO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  async orderByTopProd(tipo) {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;

    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    const detalle: VenTopProductos[] = [];
    let rs;

    try {
      if (tipo === 'total') {
        rs = await this.venEstadisticasService.topProductos(
          this.encfacService.encfacarray[this.indicador].CLI_CODIGO, 'total');
      }

      if (tipo === 'cantidad') {
        rs = await this.venEstadisticasService.topProductos(
          this.encfacService.encfacarray[this.indicador].CLI_CODIGO, 'cantidad');
      }
      if (rs !== null) {
        for (const item of rs) {
          const det: VenTopProductos = {};
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFAC_PRECIO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: VenTopProductos = {};
        totales.DETFAC_CODIGO = '';
        totales.DETFAC_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFAC_PRECIO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  salirExistenciasBod() {
    this.displayDialogExistBod = false;
  }

  cerrarLotes() {
    this.displayDialogLotes = false;

    const DETFACLOT = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DETFACLOT').CFG_VALOR1;
    if (DETFACLOT == 1) {
      this.confirmationService.confirm({
        message: 'Agregar la información de lotes como comentario?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencfac',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          this.listarLotes(this.detfacSelected.DETFAC_LINEA,
            this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
          /*guardarFactura();
          dlgFactura.cancelarRegistro();
          modeloFactura.actualizarOrden(txtNumero
            .getText().trim());*/
        },
        reject: () => {
          this.confirmationService.close();
        }
      });

    }
  }

  cambiarPrecioUnidad(event) {
    this.encfacService.ecChangelistaprecios(event.data.DETFAC_CODIGO, event.data.DETFAC_LISTA, event.data.DETFAC_UNIDAD).subscribe(data => {
      for (const rs of data) {


        let dblPrecio = rs.PRECIO;

        const FCPRINIVA = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCPRINIVA').CFG_VALOR1;
        // configuración que indica si el precio en los artículos está
        // incluído el iva
        if (FCPRINIVA === 1 && event.data.DETFAC_TRIBIVA === 'S') {
          dblPrecio = dblPrecio / (1 + Number(rs.DETFAC_PORIVA) / 100);
        }

        if (event.data.DETFAC_TRIBIVA === 'S') {
          if (dblPrecio > 0) {
            if (this.selectiva.codigo === '2') {
              dblPrecio = dblPrecio
                / (1 + Number(rs.DETFAC_PORIVA) / 100);
            }
          }
        }

        this.encfacService.detfacarray[this.indicador][this.encfacService.detfacarray[this.indicador].findIndex(
          detfac => detfac.DETFAC_LINEA === event.data.DETFAC_LINEA)].DETFAC_PRECIO = dblPrecio;
        this.encfacService.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
      }
    });
  }

  // enviarEstadoCuenta() {

  // }

  async actualizarEstadoParaReenviarCorreo(strNumDoc) {

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'La factura está anulada'
      });
      return;
    }

    const data = await this.encfacService.actualizarDatosElecProm(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
    for (const rs of data) {
      this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE = rs.ENCFAC_ESTADO_FE;
      this.encfacService.encfacarray[this.indicador].ENCFAC_AUTORIZACION_FE = rs.ENCFAC_AUTORIZACION_FE;
      this.encfacService.encfacarray[this.indicador].ENCFAC_CLAVEACCESO_FE = rs.ENCFAC_CLAVEACCESO_FE;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'NT') {
      try {
        await this.encfacService.actualizarEstadoFE(strNumDoc);
        const data = await this.utilitariosService.reenviarCorreo(strNumDoc);
        this.messageService.add({
          key: 'encfac',
          severity: 'success',
          summary: 'Información',
          detail: data
        });
        this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO', strNumDoc + 'VEN_REV_CORREO', 'A',
          '', '01', '', '', '', '').subscribe();
        this.messageService.add({
          key: 'encfac',
          severity: 'success',
          summary: 'Información',
          detail: 'Proceso actualizar estado para reenvió de correo  terminó satisfactoriamente'
        });
        this.refrescarDatosFE('interno');
      } catch (e) {
        e.printStackTrace();
        this.messageService.add({
          key: 'encfac',
          severity: 'error',
          summary: 'Error',
          detail: 'Proceso actualizar estado para reenvió de correo no terminó satisfactoriamente'
        });
      }
    } else {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Solo puede reenviar el correo si la factura esta notificada'
      });
    }
  }



  async reversarFE() {
    this.closeSidebar();
    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }
    const strNumDoc = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO
    if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha seleccionado un documento'
      });
      return;
    }

    const data = await this.encfacService.actualizarDatosElecProm(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
    for (const rs of data) {
      this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE = rs.ENCFAC_ESTADO_FE;
      this.encfacService.encfacarray[this.indicador].ENCFAC_AUTORIZACION_FE = rs.ENCFAC_AUTORIZACION_FE;
      this.encfacService.encfacarray[this.indicador].ENCFAC_CLAVEACCESO_FE = rs.ENCFAC_CLAVEACCESO_FE;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === "ANULADA") {
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== "AT"
      && this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== "NT"
      && this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== "SN") {

      this.confirmationService.confirm({
        message: 'Está seguro de reversar el proceso?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencfac',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          try {
            this.utilitariosService.reversarProcesoFE(strNumDoc, '01');
            this.utilitariosService.reversarProcesoFEClave(this.encfacService.encfacarray[this.indicador].ENCFAC_CLAVEACCESO_FE);
            this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO', strNumDoc + 'VEN_REVERSAR_FE', 'E',
              '', '01', '', '', '', '').subscribe();
            this.messageService.add({
              key: 'encfac',
              severity: 'success',
              summary: 'Información',
              detail: 'Reversar proceso de inicio de firma electrónica terminó satisfactoriamente'
            });
            this.refrescarDatosFE('interno');
          } catch (e) {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Error',
              detail: 'Reversar proceso de inicio de firma electrónica no terminó satisfactoriamente'
            });
          }
        }, reject: () => {
          this.confirmationService.close();
        }
      });
    } else {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Solo puede reversar el proceso de facturación electrónica si el documento no esta NT(Autorizado) , AT(Notificado) O SN (AUTORIZADO)'
      });
    }
  }


  async continuarFE() {
    this.closeSidebar();
    const msgclave = 'encfac';
    const strNumDoc = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;

    if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'No ha seleccionado un documento'
      });
      return;
    }

    if (this.datosfe.COM_FEACTIVADA === '0') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Facturación electrónica no está activada.'
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === ''
      || this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === undefined
      || this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === null) {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento no ha iniciado el proceso de firma electrónica.'
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'VA') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra en su estado inicial, reverse el proceso de firma electrónica.'
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'NT') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra notificado.'
      });
      return;
    }


    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === 'NA') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Visualize el log. Corrija el documento y reverse el proceso de firma electrónica.'
      });
      return;
    }

    this.spin = true;
    const data = await this.utilitariosService.continuarFirmaElectronica(strNumDoc).catch(e => {
      this.spin = false;
    });
    if (data !== 'NOAUTORIZADO' && data !== 'El SRI presenta intermitencias, reanude el proceso de firma más tarde.' && data !== 'Ocurrio un error.') {
      this.messageService.add({
        key: msgclave,
        severity: 'success',
        summary: 'Información',
        detail: data
      });
    } else {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: data
      });
    }
    this.spin = false;
    this.cancelar();
  }


  async listarLotes(DetFacLinea, EncfacCodigo) {
    // elimina los lotes ingresados en el detalle previamente
    await this.encfacService.eliminarlotecomentario(DetFacLinea, EncfacCodigo);

    // selecciona los lotes actualizados y agrega los nuevos  
    const data = await this.encfacService.selectlotecomentario(DetFacLinea, EncfacCodigo);

    for (const rs of data) {
      let lotenumero = rs.LOTE_NUMERO;
      let lotecantidad = rs.TRNLOTE_CANTIDAD;
      let lotefecha = rs.TRNLOTE_FECHAVENCIMIENTO;

      const AGRLOTCOM = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'AGRLOTCOM').CFG_VALOR1;
      if (AGRLOTCOM == 1) {
        this.nuevaLineaDetalleLote("Cant."
          + Math.round(lotecantidad) + " Lot." + lotenumero
          + " Venc." + this.datePipe.transform(lotefecha, 'dd/MM/yyyy'), DetFacLinea);
      } else {
        this.nuevaLineaDetalleLote("Lot." + lotenumero
          + " Venc." + this.datePipe.transform(lotefecha, 'dd/MM/yyyy'), DetFacLinea);
      }
    }
  }// fin listar datos

  nuevaLineaDetalleLote(LoteDocumento, linea) {
    this.crearNuevoItem();
    const detfac: VenDetfacZ = this.nuevoItem;
    detfac.BOD_CODIGO = '';
    detfac.DETFAC_DESCRIPCION = LoteDocumento;
    detfac.DETFAC_TIPODET = '*';
    detfac.DETFAC_ORDEN = linea;
    const indi = this.encfacService.detfacarray[this.indicador].indexOf(this.detfacSelected);

    this.encfacService.detfacarray[this.indicador].splice(indi + 1, 0, detfac);
    this.detfacSelected = detfac;
    this.aggrid.refreshaggridindex(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto, indi);
  }

  obtenerTotalDetFacActual(strArtCodigo, intLineaPedido,
    intLineaGuia, strEncGreCodigo, strEncPedNumero): number {
    let dblCant = 0;

    for (let fila = 1; fila <= this.encfacService.detfacarray[this.indicador].length; fila++) {
      const item = this.encfacService.detfacarray[this.indicador][fila - 1];

      if (item.DETFAC_TIPODET === 'A' || item.DETFAC_TIPODET === 'S' || item.DETFAC_TIPODET === 'C' || item.DETFAC_TIPODET === 'Y') {
        if (item.DETFAC_CODIGO === strArtCodigo) {
          if (intLineaPedido !== 0) {
            if (item.DETPED_LINEA === intLineaPedido
              && item.ENCPED_NUMERO === strEncPedNumero) {
              dblCant = dblCant + Number(item.DETFAC_TOTAL);
            }
          } else if (intLineaGuia !== 0) {
            if (item.DETGRE_LINEA === intLineaGuia
              && item.ENCGRE_NUMERO === strEncGreCodigo) {
              dblCant = dblCant + Number(item.DETFAC_TOTAL);
            }
          }
        }
      }
    }

    return dblCant;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }

  verificarImportes() {

    let dblBaseCero = 0;
    let dblBaseIva = 0;
    let dblMontoIce = 0;
    let dblMontoIva = 0;
    // por configración se va a validar el calculo de los importes NVTOREMVEN
    const NVTOREMVEN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NVTOREMVEN').CFG_VALOR1;
    console.log(NVTOREMVEN)
    if (Number(NVTOREMVEN) === 0) {
      this.encfacService.getImportesReembolso(this.strNumFact).subscribe(data => {

        for (const rs of data) {
          if (rs.TRNREE_BASECERO === null) {
            return;
          }
        }

        for (const rs of data) {
          dblBaseCero = rs.TRNREE_BASECERO.toFixed(this.confIniciales.getNumDecSist());
          dblBaseIva = rs.TRNREE_BASEIVA.toFixed(this.confIniciales.getNumDecSist());
          dblMontoIce = rs.TRNREE_MONTOICE.toFixed(this.confIniciales.getNumDecSist());
          dblMontoIva = rs.TRNREE_MONTOIVA.toFixed(this.confIniciales.getNumDecSist());
        }
        console.log('///******////', Number(dblBaseCero))
        console.log('///******////', Number(dblBaseIva))
        console.log('///******////', Number(dblMontoIce))
        console.log('///******////', Number(dblMontoIva))
        console.log('///******////', Number(Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_BASECERO)).toFixed(this.confIniciales.getNumDecSist())))
        console.log('///******////', Number(Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_BASEIVA)).toFixed(this.confIniciales.getNumDecSist())))
        console.log('///******////', Number(Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORICE)).toFixed(this.confIniciales.getNumDecSist())))
        console.log('///******////', Number(Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIVA)).toFixed(this.confIniciales.getNumDecSist())))


        if (Number(Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_BASEIVA)).toFixed(this.confIniciales.getNumDecSist())) !== Number(dblBaseIva)) {
          this.displayDialogReembolsos = true;
          this.messageService.add({
            key: 'reemb',
            severity: 'warn',
            summary: 'Información',
            detail: 'La base iva de la factura no es igual al total de la base iva de los reembolsos'
          });
        }

        if (Number(Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_BASECERO)).toFixed(this.confIniciales.getNumDecSist())) !== Number(dblBaseCero)) {
          this.displayDialogReembolsos = true;
          this.messageService.add({
            key: 'reemb',
            severity: 'warn',
            summary: 'Información',
            detail: 'La base cero de la factura no es igual al total de la base cero de los reembolsos'
          });
        }

        if (Number(Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORICE)).toFixed(this.confIniciales.getNumDecSist())) !== Number(dblMontoIce)) {
          this.displayDialogReembolsos = true;
          this.messageService.add({
            key: 'reemb',
            severity: 'warn',
            summary: 'Información',
            detail: 'El monto ice de la factura no es igual al total del monto ice de los reembolsos'
          });
        }

        if (Number(Number(this.init.quitarComas(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIVA)).toFixed(this.confIniciales.getNumDecSist())) !== Number(dblMontoIva)) {
          this.displayDialogReembolsos = true;
          this.messageService.add({
            key: 'reemb',
            severity: 'warn',
            summary: 'Información',
            detail: 'El monto iva de la factura no es igual al total del monto iva de los reembolsos'
          });
        }
      });
    }


  }

  agregarReembolsos() {
    this.auxiliarrem++;
    this.strNumFact = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
    this.strFechaSer = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    this.displayDialogReembolsos = true;

    this.closeSidebar();
  }

  cerrarReembolsos() {
    this.displayDialogReembolsos = false;
  }
  /////GSRF
  verpedidospendientes() {
    this.displayDialogpedidodpendientes = true;
    this.closeSidebar();
  }
  cerrarpedidospendientes() {
    this.displayDialogpedidodpendientes = false;
  }
  /////GSRF

  verificaritemCero() {

    let dblverificar = false;

    for (const item of this.encfacService.detfacarray[this.indicador]) {
      if (item.DETFAC_TIPODET !== '*' && Number(item.DETFAC_PRECIO) === 0) {
        dblverificar = true;
      }
    }

    return dblverificar;
  }

  agregarOrdenTrabajo() {

    if (this.encfacService.encfacarray[this.indicador].BOD_CODIGO.length === 0) {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Seleccione la bodega.'
      });
      return;
    }

    const NUMGUAFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMGUAFAC').CFG_VALOR1;
    if (NUMGUAFAC === 0) {
      if (this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO.length === 0) {
        this.messageService.add({
          key: 'encfac',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese el número de la factura.'
        });
        return;
      }
    }

    if (this.encfacService.encfacarray[this.indicador].CLI_CODIGO.length > 0) {
      this.buscarOrdenTrabajo();
    } else {
      this.messageService.add({
        key: 'encfac',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese el cliente.'
      });
    }
		/*
		if (dlgFactura.getHmpConfiguracion().get("NOFACPREL") == 1) {
			if (modeloFactura.getListaDetFact().size() != 0) {
				txtCodCliente.setEnabled(false);
				btnCliente.setEnabled(false);
			} else {
				txtCodCliente.setEnabled(true);
				btnCliente.setEnabled(true);
			}
		}
    */
  }


  buscarOrdenTrabajo() {
    let strCondicion = " COM_CODIGO = \'01\'";
    strCondicion = strCondicion + " AND ORDTRA_ESTADO = 'P'";
    strCondicion = strCondicion + " AND CLI_CODIGO_FAC = '"
      + this.encfacService.encfacarray[this.indicador].CLI_CODIGO + "'";

    this.opcionbusqueda = 'agOT';
    this.types = [
      { label: 'Número', value: 'ORDTRA_NUMERO' },
      { label: 'Fecha', value: 'ORDTRA_FECHA' },
      { label: 'Estado', value: 'ORDTRA_ESTADO' },
      { label: 'NombreCli', value: 'NOMBRE' },
      { label: 'NombreC', value: 'CLI_NOMBREC' },
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Total', value: 'ORDTRA_TOTAL' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCORDTRA O';
    this.where = strCondicion;
    //this.spin = true;
    this.busqService.busquedaVenEncordtra(strCondicion).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.ORDTRA_FECHA !== null) {
            registro.ORDTRA_FECHA = this.datePipe.transform(registro.ORDTRA_FECHA, 'dd/MM/yyyy');
          } else {
            registro.ORDTRA_FECHA = '';
          }
        });
      }
      //this.spin = false;
      this.displayDialogBusquedaFast = true;
    });

    this.closeSidebar();
  }

  obtenerCantFacActualOrdTra(strCodigo, intLinea, strOrdTra): number {
    let dblCant = 0;

    for (const item of this.encfacService.detfacarray[this.indicador]) {
      if (item.DETFAC_CODIGO === strCodigo) {
        if (item.DETORDTRA_LINEA === intLinea
          && item.ORDTRA_NUMERO === strOrdTra)
          dblCant = dblCant + Number(item.DETFAC_CANTIDAD);
      }

    }

    return dblCant;
  }

  async consultarOrdTra(strOrdTraNumero) {

    const dataordtra = await this.encfacService.consultarordentra(strOrdTraNumero);
    let intTamLista = dataordtra.length;

    let fila = 1
    for (const rs of dataordtra) {
      //   this.dblValorDescGlb = 0;
      //   this.dblPorcDescGlb = 0;

      let dblCantFac = rs.CANTARTFACDEORDTRA;

      let dblCantPend = Number(rs.DETORDTRA_CANTIDAD) - Number(dblCantFac); // TOTAL DESPACHADO

      let dblCantFacActual = this.obtenerCantFacActualOrdTra(rs.DETORDTRA_CODIGO, rs.DETORDTRA_LINEA, rs.ORDTRA_NUMERO); // cantidad en los otros items de la factura

      if (dblCantPend - dblCantFacActual > 0
        || rs.DETORDTRA_TIPODET === "*") {
        if (fila == 1) {
          this.crearNuevoItem();
          this.nuevoItem.DETFAC_TIPODET = '*';
          this.nuevoItem.DETFAC_DESCRIPCION = "*** ORDEN DE TRABAJO "
            + rs.ORDTRA_NUMERO + " ***";
          this.nuevoItem.ENCPED_NUMERO = '';
          this.nuevoItem.DETFAC_TRIBIVA = '';
          this.nuevoItem.DETFAC_DESCUENTO = 0;
          this.nuevoItem.DETFAC_LINEA = 0;
          this.nuevoItem.DETFAC_ORDEN = fila;

          this.encfacService.detfacarray[this.indicador].push(this.nuevoItem);
          fila = fila + 1;
        }
        console.log(fila);
        const itemdisponibles = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES);
        if (itemdisponibles > 0) {
          /* intNumItemsDisp = itemdisponibles
               - (this.getRowCount() - 1);
           if (controlNumItems() == false) {
             cmpFact.toolBarDetalle.getItem(0).setEnabled(false);
             dlgFact.mnuNuevaLinea.setEnabled(false);
             return;
           } else {
             cmpFact.toolBarDetalle.getItem(0).setEnabled(true);
             dlgFact.mnuNuevaLinea.setEnabled(true);
             deshabilitarTipoDet(true); // habilitar el tipo de detalle                   
           }*/
        }

        // actualizar número de items disponibles
        if (this.NUMITEMFAC > 0) {
          this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES = this.NUMITEMFAC;
        }
        /*if (dlgFact.getHmpConfiguracion().get("NUMITEMFAC") > 0)
          cmpFact.lblItemsDisp.setText(String
              .valueOf(intNumItemsDisp - 1));*/


        //               intTamLista = listaDetFact.size();   

        console.log('crearNuevoItem');
        this.crearNuevoItem();
        const item: VenDetfacZ = this.nuevoItem;
        intTamLista = dataordtra.length;

        item.BOD_CODIGO = rs.BOD_CODIGO;
        item.COM_CODIGO = '01';
        item.DETFAC_LINEA = 0;
        item.DETFAC_TIPODET = 'A';
        item.DETFAC_CODIGO = rs.DETORDTRA_CODIGO;
        item.DETFAC_DESCRIPCION = rs.DETORDTRA_DESCRIPCION;
        item.DETFAC_TRIBIVA = rs.DETORDTRA_TRIBIVA;
        item.DETFAC_TRIBICE = "N";
        item.DETFAC_UNIDAD = rs.DETORDTRA_UNIDAD;
        item.DETFAC_CANTIDAD = rs.DETORDTRA_CANTIDAD - dblCantFac - dblCantFacActual;
        item.DETFAC_PROMOCION = "0";
        item.DETFAC_PRECIO = rs.DETORDTRA_PRECIO;
        item.DETFAC_DESCUENTO = 0;
        item.DETFAC_TOTAL = rs.DETORDTRA_TOTAL;
        item.DETFAC_IVA = rs.DETORDTRA_IVA;
        item.DETFAC_ICE = 0;
        item.DETFAC_LISTA = "A";

        if (rs.DETORDTRA_TRIBIVA === "N")
          item.DETFAC_BASEIVA = 0;
        else
          item.DETFAC_BASEIVA = rs.DETORDTRA_TOTAL;

        item.DETFAC_BASEICE = 0;

        if (rs.DETORDTRA_TRIBIVA === "N")
          item.DETFAC_BASECERO = rs.DETORDTRA_TOTAL;
        else
          item.DETFAC_BASECERO = 0;

        item.DETFAC_PORCEICE = 0;
        item.ORDTRA_NUMERO = rs.ORDTRA_NUMERO;
        item.DETORDTRA_LINEA = rs.DETORDTRA_LINEA;
        item.UNI_SIMBOLO = rs.UNI_CODIGO;
        item.ENCGRE_NUMERO = "";
        item.ENCPED_NUMERO = "";
        item.DETFAC_TIPOSRI = "";
        item.ART_MULTIUNIDAD = rs.ART_MULTIUNIDAD === null ? '' : rs.ART_MULTIUNIDAD;
        if (!this.bolNuevo)
          item.ENCFAC_NUMERO = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;
        else
          item.ENCFAC_NUMERO = "";

        // precio de los componentes 
        console.log('push');
        this.encfacService.detfacarray[this.indicador].push(item);
        console.log(this.encfacService.detfacarray[this.indicador]);
        intTamLista = intTamLista + 1;

        // colorearPrecio(item, listaDetFact.indexOf(item) + 1);
        // colorearCantidad(item, listaDetFact.indexOf(item) + 1);
        fila = fila + 1;

        /*cmpFact.toolBarDetalle.getItem(0).setEnabled(true);
        cmpFact.toolBarDetalle.getItem(1).setEnabled(true);
        dlgFact.mnuNuevaLinea.setEnabled(true);
        dlgFact.mnuElimLin.setEnabled(true);
 
        cmpFact.activarBotMod();*/
      }

      this.encfacService.calcularTotales();
      this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
      /*cmpFact.dblValorDescGlb = cmpFact.dblValorNeto
          * cmpFact.dblPorcDescGlb / 100;
      */
    }


    /*calcularTotalesFact();
    completarInformacion();
    colorearPrecioTodaFactura();
    colorearCantidadTodaFactura();
 
    
    if (DlgDescuento.glbBolEsValor)
      cmpFact.dblPorcDescGlb = cmpFact.dblValorDescGlb * 100
          / cmpFact.dblValorNeto;*/



  }

  async verificarCentroCostosCuenta(strCodigo, strTipo) {

    let strAplicaCentro = "";
    let strCuenta = "";

    //si es servicio
    if (strTipo === "A" || strTipo === "Y" || strTipo === "Z") {
      const data = await this.encfacService.ercuentaarticulo(strCodigo);
      for (let i of data) {
        strCuenta = i.CON_CODIGOACT;
      }
    }

    if (strTipo === "S") {
      const data = await this.encfacService.ercuentaservicio(strCodigo);
      for (let i of data) {
        strCuenta = i.CON_CODIGO;
      }
    }

    if (strTipo === "C" || strTipo === "") {
      strCuenta = strCodigo;
    }

    const data = await this.encfacService.ercentrocuenta(strCuenta);
    for (let i of data) {
      strAplicaCentro = i.CON_CENTRO;
    }

    return strAplicaCentro;
  }

  async extraerNumeroOrdenesTrabajo(strNumFact) {
    const data = await this.encfacService.extraerordenestrabajo(strNumFact);
    for (const rs of data) {
      this.numeroOrdtra.push(rs.ORDTRA_NUMERO);
    }

    // actualiza el estado de las ordenes de trabajo
    this.verificarActualizarEstOrdTra();
  }

  agregarPorceService() {
    this.closeSidebar();

    if (this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO !== null
      && this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO !== undefined
      && this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO !== '') {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el porcentaje de servicio',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencfac',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          this.botonesmodificar();
          this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO = '';
          this.encfacService.calcularTotales();
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está anulada.'
      });
      return;
    }

    if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === 'PAGADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está pagada.'
      });
      return;
    }

    let baseServicio = 0;
    for (const det of this.encfacService.detfacarray[this.indicador]) {
      if (det.ART_APLICASERVICIO === 'S') {
        const baseItem = det.DETFAC_CANTIDAD *
          det.DETFAC_PRECIO;
        baseServicio = baseServicio + Number(baseItem);
      }
    }
    let dblValorServicio =
      Number(baseServicio) * 10 / 100;
    this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO = this.init.moneyValidation(dblValorServicio.toFixed(this.confIniciales.getNumDecSist()));
    this.encfacService.calcularTotales();
    this.botonesmodificar();
  }

  async validarLotes() {
    const VALINGLOT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VALINGLOT').CFG_VALOR1;
    if (VALINGLOT == 0) {
      return true;
    } else {
      const strNumDoc = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO;


      for (const item of this.encfacService.detfacarray[this.indicador]) {
        let articulo = item.DETFAC_CODIGO;
        let cantidad = item.DETFAC_CANTIDAD;
        let linea = item.DETFAC_LINEA;

        if (item.ART_UNIDADVENTA !== item.DETFAC_UNIDAD) {
          const data: any = await this.utilitariosService.getFactorcantidad(item.ART_UNIDADVENTA, item.DETFAC_UNIDAD);
          let factor = '';
          let cant = '';
          for (const rs of data) {
            factor = rs.EQU_FACTOR;
            cant = rs.EQU_CANTIDAD;
          }
          if (factor === '*') {
            cantidad = Number(item.DETFAC_CANTIDAD) * Number(cant);
          } else {
            cantidad = Number(item.DETFAC_CANTIDAD) / Number(cant);
          }
        }

        if (item.ENCGRE_NUMERO === null) {
          item.ENCGRE_NUMERO = '';
        }
        if (item.ENCGRE_NUMERO.length > 0) {
        } else {
          const data = await this.utilitariosService.validalote(articulo, cantidad, linea, strNumDoc);
          for (const rs of data) {
            console.log(rs);
            if (rs.CANT === '0') {
              this.messageService.add({
                key: 'encfac',
                severity: 'error',
                summary: 'Información',
                detail: 'Ingrese los lotes'
              });
              return false;
            }
          }
        }
      }
      return true;
    }
  }

  modificariva() {
    this.closeSidebar();
    this.disablediva = false;
  }
  ////////////////////////////vehiculo
  async verimagen(parametro) {
    //this.opcion= 'verimagen';
    if (this.detfacSelected.DETFAC_CODIGO !== '') {
      const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
      if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
        this.displayDialogDocumentosim = true;
      } else {
        this.messageService.add({
          key: 'encfac',
          severity: 'info',
          summary: 'Información',
          detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
        });
      }

    }
  }


  async cargardatosveh() {

    this.COM_VEH1 = 'MARCA:' + this.ART_MARVEH + '|DESCRIPCIÓN:' + this.ART_NOMBRE + '|MODELO SRI:' + this.ART_MODELOSRI + '|MODELO HOMOLOGACIÓN:' + this.ART_MODELOAT + '|MOTOR:' + this.ART_MOTOR + '|SERIE:' + this.ART_SERIEV + '|COLOR:' + this.ART_COLOR + '|RAMV:' + this.ART_RAMV + '|TIPO:' + this.ART_TIPVEH;
    this.COM_VEH2 = 'CLASE:' + this.ART_CLAVEH + '|PAIS ORIGEN:' + this.ART_P + '|AÑO MODELO:' + this.ART_ANIO + '|COMBUSTIBLE:' + this.ART_COMVEH + '|TONELAJE:' + this.ART_TONELAJE + '|CAPACIDAD:' + this.ART_CAPACIDAD + '|CILINDRAJE:' + this.ART_CILINDRAJE + '|DISPOSITIVO:' + this.ART_DISVEH + '|NRO. RUEDAS:' + this.ART_NRUEDAS + '|NRO. EJES:' + this.ART_NEJES + '|';
    this.nuevodetalle();
    this.nuevoItem.DETFAC_TIPODET = '*';
    this.nuevoItem.DETFAC_DESCRIPCION = this.COM_VEH1;
    this.nuevodetalle();
    this.nuevoItem.DETFAC_TIPODET = '*';
    this.nuevoItem.DETFAC_DESCRIPCION = this.COM_VEH2;
    this.nuevodetalle();
    this.displayvehiculo = false;

  }
  ///GSRF
  cambiarFoco(evento) {

  }
  habilitarImpuestoRenta() {
    this.botonesmodificar();
    if (this.SELECTINGGRAVADOEXT === 'SI') {
      this.booleanImpuestoRenta = false;
      // document.getElementById('valorImpuestoR').focus();
    } else if (this.SELECTINGGRAVADOEXT === 'NO') {
      this.booleanImpuestoRenta = true;
      this.encfacService.encfacarray[this.indicador].VALORIMPEXT = '';
      //document.getElementById('valorfobC').focus();
    } else if (this.SELECTINGGRAVADOEXT === '') {
      this.booleanImpuestoRenta = true;
      this.encfacService.encfacarray[this.indicador].VALORIMPEXT = '';
    }
  }

  habilitarExpNuevoCliente() {
    //this.cambio();
    this.botonesmodificar();
    console.log(this.SELECTIPOREGI)
    if (this.SELECTIPOREGI === '01') {
      this.booleanRegimen = false;
      this.booleanParaiso = true;
      this.booleanRegimenP = true;
      this.booleanRegimenP1 = true;
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado = '';
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado1 = '';
      this.encfacService.encfacarray[this.indicador].codigoPaisRegimen = '';
      this.encfacService.encfacarray[this.indicador].codigoPaisParaiso = '';
      this.encfacService.encfacarray[this.indicador].PAISEFECEXP = '';
      this.encfacService.encfacarray[this.indicador].DENOEXPCLI = '';
      //console.log(document.getElementById('codigoPaisRegimen'));
      //console.log(document.getElementById('codigoPaisRegimen').focus());
      //document.getElementById('codigoPaisRegimen').focus();
    } else if (this.SELECTIPOREGI === '02') {
      this.booleanRegimen = true;
      this.booleanParaiso = false;
      this.booleanRegimenP = true;
      this.booleanRegimenP1 = false;
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado = '';
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado1 = '';
      this.encfacService.encfacarray[this.indicador].codigoPaisRegimen = '';
      this.encfacService.encfacarray[this.indicador].codigoPaisParaiso = '';
      this.encfacService.encfacarray[this.indicador].PAISEFECEXP = '';
      this.encfacService.encfacarray[this.indicador].DENOEXPCLI = '';
      document.getElementById('codigoPaisParaiso').focus();
    } else if (this.SELECTIPOREGI === '03') {
      this.booleanRegimen = true;
      this.booleanParaiso = true;
      this.booleanRegimenP = false;
      this.booleanRegimenP1 = false;
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado = '';
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado1 = '';
      this.encfacService.encfacarray[this.indicador].codigoPaisRegimen = '';
      this.encfacService.encfacarray[this.indicador].codigoPaisParaiso = '';
      this.encfacService.encfacarray[this.indicador].PAISEFECEXP = '';
      this.encfacService.encfacarray[this.indicador].DENOEXPCLI = '';
      document.getElementById('paisResid').focus();
    } else if (this.SELECTIPOREGI === '') {
      this.booleanRegimen = true;
      this.booleanParaiso = true;
      this.booleanRegimenP = true;
      this.booleanRegimenP1 = true;
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado = '';
      this.encfacService.encfacarray[this.indicador].nombrePaisSeleccionado = '';
      this.encfacService.encfacarray[this.indicador].codigoPaisRegimen = '';
      this.encfacService.encfacarray[this.indicador].codigoPaisParaiso = '';
      this.encfacService.encfacarray[this.indicador].PAISEFECEXP = '';
      this.encfacService.encfacarray[this.indicador].DENOEXPCLI = '';
    }

  }
  habilitarRefrendos() {
    this.botonesmodificar();
    if (this.SELECTEXPORTACIONDE === '01') {
      this.booleanRefrendo = true;
      this.booleanImpuestoRenta = true;
      //document.getElementById('valorfobC').focus();
      this.encfacService.encfacarray[this.indicador].DOCTRANSP = '';
      this.encfacService.encfacarray[this.indicador].CORRELATIVO = '';
      this.encfacService.encfacarray[this.indicador].ANIO = '';
      this.encfacService.encfacarray[this.indicador].REGIMEN = '';
      this.encfacService.encfacarray[this.indicador].DISTADUANERO = '';
      this.encfacService.encfacarray[this.indicador].VALORFOB = '';
      this.encfacService.encfacarray[this.indicador].VALORIMPEXT = '';
      this.encfacService.encfacarray[this.indicador].INGGRAVADOEXT = '';
      this.encfacService.encfacarray[this.indicador].TIPOINGRESO = '';
      this.SELECTIPOINGRESO = '';
      this.SELECTINGGRAVADOEXT = '';
    } else if (this.SELECTEXPORTACIONDE === '02') {
      this.booleanRefrendo = true;
      this.booleanImpuestoRenta = true;
      //document.getElementById('valorfobC').focus();
      this.encfacService.encfacarray[this.indicador].DOCTRANSP = '';
      this.encfacService.encfacarray[this.indicador].CORRELATIVO = '';
      this.encfacService.encfacarray[this.indicador].ANIO = '';
      this.encfacService.encfacarray[this.indicador].REGIMEN = '';
      this.encfacService.encfacarray[this.indicador].DISTADUANERO = '';
      this.encfacService.encfacarray[this.indicador].VALORFOB = '';
      this.encfacService.encfacarray[this.indicador].VALORIMPEXT = '';
      this.encfacService.encfacarray[this.indicador].INGGRAVADOEXT = '';
      this.encfacService.encfacarray[this.indicador].TIPOINGRESO = '';
      this.SELECTIPOINGRESO = '';
      this.SELECTINGGRAVADOEXT = '';
    } else if (this.SELECTEXPORTACIONDE === '03') {
      this.booleanRefrendo = false;
      this.booleanImpuestoRenta = false;
      this.encfacService.encfacarray[this.indicador].DOCTRANSP = '';
      this.encfacService.encfacarray[this.indicador].CORRELATIVO = '';
      this.encfacService.encfacarray[this.indicador].ANIO = '';
      this.encfacService.encfacarray[this.indicador].REGIMEN = '';
      this.encfacService.encfacarray[this.indicador].DISTADUANERO = '';
      this.encfacService.encfacarray[this.indicador].VALORFOB = '';
      this.encfacService.encfacarray[this.indicador].VALORIMPEXT = '';
      this.encfacService.encfacarray[this.indicador].INGGRAVADOEXT = '';
      this.encfacService.encfacarray[this.indicador].TIPOINGRESO = '';
      this.SELECTIPOINGRESO = '';
      this.SELECTINGGRAVADOEXT = '';
    } else if (this.SELECTEXPORTACIONDE === '') {
      this.booleanRefrendo = true;
      this.booleanImpuestoRenta = true;
      this.encfacService.encfacarray[this.indicador].DOCTRANSP = '';
      this.encfacService.encfacarray[this.indicador].CORRELATIVO = '';
      this.encfacService.encfacarray[this.indicador].ANIO = '';
      this.encfacService.encfacarray[this.indicador].REGIMEN = '';
      this.encfacService.encfacarray[this.indicador].DISTADUANERO = '';
      this.encfacService.encfacarray[this.indicador].VALORFOB = '';
      this.encfacService.encfacarray[this.indicador].VALORIMPEXT = '';
      this.encfacService.encfacarray[this.indicador].INGGRAVADOEXT = '';
      this.encfacService.encfacarray[this.indicador].TIPOINGRESO = '';
      this.SELECTIPOINGRESO = '';
      this.SELECTINGGRAVADOEXT = '';
    }
  }
  abrirPaisesExportacion() {
    this.opcionbusqueda = 'BUSQUEDAEX';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.encfacService.encfacarray[this.indicador].PAISEFECEXP, '', '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    //this.consulta = '*';
    this.where = '';
    this.encfacService.busquedaAnexMaeCodSri11().subscribe((datos: any[]) => {
      this.botonesmodificar();
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  abrirPaisesParaiso() {
    this.opcionbusqueda = 'BUSQUEDAPF';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.encfacService.encfacarray[this.indicador].codigoPaisParaiso, '', '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    //this.consulta = '*';
    this.where = '';
    this.encfacService.busquedaAnexMaeCodSri10().subscribe((datos: any[]) => {
      this.botonesmodificar();
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  abrirPaisesRegimen() {
    this.opcionbusqueda = 'BUSQUEDARG';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = ['', '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    //this.consulta = '*';
    this.where = '';

    this.encfacService.busquedaAnexMaeCodSri9().subscribe((datos: any[]) => {
      this.botonesmodificar();
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  limpiaexport() {
    console.log(this.chkactivo)
    //this.edita = true;
    if (this.chkactivo !== true) {
      this.edita = true;
      this.encfacService.encfacarray[this.indicador].COMERCIOEXTERIOR = '';
      this.encfacService.encfacarray[this.indicador].INCOTERMFACTURA = '';
      this.encfacService.encfacarray[this.indicador].LUGARINCOTERM = '';
      this.encfacService.encfacarray[this.indicador].PAISORIGEN = '';
      this.encfacService.encfacarray[this.indicador].PUERTOEMBARQUE = '';
      this.encfacService.encfacarray[this.indicador].PUERTODESTINO = '';
      this.encfacService.encfacarray[this.indicador].PAISDESTINO = '';
      this.encfacService.encfacarray[this.indicador].PAISADQUISICION = '';
      this.encfacService.encfacarray[this.indicador].DIRCOMPRADOR = '';
      this.encfacService.encfacarray[this.indicador].INCOTERMTOTALSINIMPUESTOS = '';
      this.encfacService.encfacarray[this.indicador].FLETEINTERNACIONAL = '';
      this.encfacService.encfacarray[this.indicador].SEGUROINTERNACIONAL = '';
      this.encfacService.encfacarray[this.indicador].GASTOSADUANEROS = '';
      this.encfacService.encfacarray[this.indicador].GASTOSTRANSPORTEOTROS = '';
      this.encfacService.encfacarray[this.indicador].FORMAPAGO = '';
    } else {
      this.edita = false;
      this.chkactivo = true;
    }
  }
  ///GSRF
  // CDPJ
  async verbasesiva() {
    this.closeSidebar()
    this.basesiva = []
    const data = await this.encfacService.obtenerbasesiva(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
    if (data !== null && data !== undefined) {
      this.basesiva = data
      for (const data2 of this.basesiva) {
        data2.TARIIVA_PORCENTAJE = isNaN(Number(data2.TARIIVA_PORCENTAJE)) ? Number(0).toFixed(2) : Number(data2.TARIIVA_PORCENTAJE).toFixed(2)
        data2.IVA_BASE = isNaN(Number(data2.IVA_BASE)) ? Number(0).toFixed(2) : Number(data2.IVA_BASE).toFixed(2)
        data2.IVA_VALOR = isNaN(Number(data2.IVA_VALOR)) ? Number(0).toFixed(2) : Number(data2.IVA_VALOR).toFixed(2)
      }
    }
    this.displaybasesiva = true;

  }
  async actbasesiva() {
    this.spin = true;
    this.closeSidebar();
    try {
      await this.encfacService.actualizabasesiva(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
      this.messageService.add({
        key: 'encfac',
        severity: 'success',
        summary: 'Información',
        detail: 'Bases IVA actualizadas correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO + '/' + 'ACCIONES_ACT_VALORES_BASES_IVA', 'A',
        '', '01', '', '', '', '').subscribe(() => {
        });
      this.spin = false;
    } catch (error) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'Error al actualiza las bases IVA' + error
      });
      this.spin = false;
    }

  }
  //CDPJ
}
