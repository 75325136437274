//
// SACI WEB
// Rutina: Devolución de Facturas
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 10-02-2020
// Fecha de Modificación: 21-02-2020
//
import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from '../../usuario';
import { Vendetdevfac } from '../veninterfaces/vendetdevfac';
import { Venexpodvf } from '../veninterfaces/venexpodvf';
import { Venencdevfac } from '../veninterfaces/venencdevfac';
import { Cxctmptrnresumen } from '../veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../veninterfaces/cxctrnresumen';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { SegMaeCompania } from '../veninterfaces/segmaecompania';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { VenencdevfacService } from '../venservicios/venencdevfac.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { VenmaeclienteService } from '../venservicios/venmaecliente.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { NumeracionService } from '../../advantage/advantageservices/numeracion.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';

// Ag-grid
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { ComExistenciaBodegas } from '../../com/cominterfaces/comestadisticas';
import { VenInfoadicdevfac } from '../veninterfaces/veninfoadicdevfac'; //GSRF 
import { Venencdvffacbasesiva } from "../veninterfaces/venencdvffacbasesiva";

@Component({
  selector: 'app-venencdevfac',
  templateUrl: './venencdevfac.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class VenencdevfacComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;

  // dropdowns
  opcionIva: SelectItem[];
  opcionIvaDesc: SelectItem[];
  opcionesBodega: SelectItem[];
  opcionesRegimen: SelectItem[];
  opcionesExportacion: SelectItem[];
  opcionesDistrito: SelectItem[];
  opcionesRegRefrendo: SelectItem[];
  opcionesIngresoExterior: SelectItem[];
  opcionesTipoIngresoExterior: SelectItem[];
  opcionesprecio: SelectItem[];

  // dialogs
  displayDialogDetFac: boolean;
  displayDialogPedidos: boolean;
  displayDialogAsiento: boolean;
  displayAsiContable: boolean;
  displayDialogSeriales: boolean;
  displayAcciones: boolean;
  displayDialogDescuento: boolean;
  displayDialogPago: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogBus: boolean;
  displayDialogLotes: boolean;
  claveDialog: boolean;
  spin = false;

  detalleDVFSeleccionado: Vendetdevfac = {};
  detalleDVFSeleccionadoAux: Vendetdevfac = {};
  exportacionNuevoSeleccionado: Venexpodvf = {};
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  encabezadosDevF: Venencdevfac[] = [];
  permisosDVF: SegMaePermiso;

  // barra
  barraBotones2: boolean;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnRegresar: boolean;
  btnBorrar: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonAsiento: boolean;
  botonPago: boolean;
  botonBuscar: boolean;
  botonVerAsiento: boolean;
  botonAnterior: boolean;
  botonPrimero: boolean;
  botonUltimo: boolean;
  botonSiguiente: boolean;

  auxiliar: number;
  auxiliarnum: number;
  auxiliarpago: number;
  auxiliarlot: number = 0;
  claveExportNuevo: string;
  tipodetalleactivo: string;
  displayLog: boolean;
  valorInvalidoV: boolean;
  valorInvalidoP: boolean;
  numeracionDialog: boolean;
  editarCeldas: boolean;
  booleanValor: boolean;
  booleanPorcentaje: boolean;
  booleanRegimen: boolean;
  booleanParaiso: boolean;
  booleanRegimenP: boolean;
  booleanRefrendo: boolean;
  booleanImpuestoRenta: boolean;
  totalApagar: number;
  nombrePaisSeleccionado: string;
  codigoPaisRegimen: string;
  codigoPaisParaiso: string;
  dscSeleccionado: string;
  valorDscValor: number;
  valorDscPorcentaje: number;
  asiento: string;
  numdecsist: number;
  dscClienteActual: number;
  numeroActual: number;
  claveAuth: string;
  saldoCliente: string;
  nombreVendedor: string;
  logResultado: string;
  numeracionActual: any;
  decimalesCantidadDVF: number;
  decimalesPrecioDVF: number;
  decimalesTotalDVF: number;
  decimalesDescDVF: number;
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  btnVerificarExis: boolean;
  accionClave: string;
  opcion: string;
  numDocRef: string;
  imagenProducto: string;
  indice: number;
  i: number;
  estadoRef: string;
  cotizacionBoolean: boolean;
  fechaEmision: any;
  fechaInicio: any;
  fechaFin: any;
  horaSistema: any;
  horaInicio: any;
  horaFin: any;
  porcIvaBoolean: boolean;
  choosedColorG: string;
  choosedColorSB: string;
  ventasboolean: boolean;
  comprasboolean: boolean;
  consigboolean: boolean;
  trasladoboolean: boolean;
  devboolean: boolean;
  impboolean: boolean;
  expboolean: boolean;
  otrosboolean: boolean;
  transboolean: boolean;
  activeIndex: number;
  nombrebodega: string;
  listaprecios: any[];
  confNumeracion: string;
  confPago: string;
  tipodetalle: string;
  bolNuevo: boolean;
  edittxtnumero: boolean;
  bolestcta: boolean;
  bolsaldo: boolean;
  datosfe: SegMaeCompania;

  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  arregloBus: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;
  consulta: string;

  // aggrid
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  rowStyleEC;
  rowStyleSaldo;
  defaultColDefDevolucion;
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  comboOpciones: any[];
  largo: string;
  largoRef: string;
  largoestcta: string;
  CNAFEPV:number//CDPJ
  boolfacturaElec//CDPJ
  displayDialogEst:boolean//CDPJ
  estfirma:string//CDPJ
  iva:number;//CDPJ
  cmbporceiva: any[];//CDPJ
  selectcombtipoIva: any;//CDPJ
  displaybasesiva:boolean=false;//CDPJ
  basesiva:Venencdvffacbasesiva[]=[];//CDPJ
  defaultColBasesIva;//CDPJ

  auxreporte: number = 0;
  strNombreForma: string;
  displayDialogReportes: boolean;
  displayDialogfrmcorreos:boolean;//CDPJ
//CDPJ permissos especiales 
usuarioSup: string;
claveSup: string;
strDialogo: string;
strObjeto: string;
intPestaniaCol: number;
displayDialogSupervisor: boolean;
//CDPJ
CENREPDOCE:number;//CDPJ
  // Existencia por bodega
  displayDialogExistBod = false;
  displayDialogImagenArt = false;
  txtArtCodigo = '';
  txtArtDescripcion = '';
  txtArtPrecioLista = '';
  txtArtPathFoto = '';
  defaultColExistBod;
  detExistenciaBodegas: ComExistenciaBodegas[] = [];
  disabledVerImagen = true;
  ///GSRF
  defaultColDefInfoAdic; 
  veninfoadicdevfac: VenInfoadicdevfac[];
  infoAdicSelected: VenInfoadicdevfac;
  //GSRF

  displayDialogDocumentos = false;

  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];

  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha Vence', field: 'TRNCOBRO_FECHAVENCE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];
  columnDefsDVFDetalle = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 50,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Código', field: 'DETDVF_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 20,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Descripción', field: 'DETDVF_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100
        }
      }
    },
    { // 3
      headerName: 'IVA', field: 'DETDVF_TRIBIVA', cellEditor: 'cellPrueba', width: 80,
      editable: (params) => {
        console.log('1----',params);
        const dis=this.discamp(params);
        return dis;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          regexp: /^[S]$|^[N]$/,
          tamanocelda: 1,
          tienecalculos: true
        }
      }
    },
    { // 4
      headerName: 'ICE', field: 'DETDVF_TRIBICE', cellEditor: 'cellPrueba', width: 80,
      editable: (params) => {
        console.log('2----',params);
        const dis=this.discamp(params);
        return dis;
      }, 
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          regexp: /^[S]$|^[N]$/,
          tamanocelda: 1,
          tienecalculos: true
        }
      }
    },
    { // 5
      headerName: 'Unidad', field: 'DETDVF_UNIDAD', cellEditor: 'agSelectCellEditor',
      editable: (params) => {
        const dis=this.discampuni(params);
        return dis;
      }, width: 100,
      cellEditorParams:
      {
        values: []
      }
    },
    { // 6
      headerName: 'Cantidad', field: 'DETDVF_CANTIDAD', cellEditor: 'cellPrueba', width: 100,
      onCellClicked: (params) => {
        this.abrirLotes(params.data);
      },
      cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    { // 7
      headerName: 'Precio', field: 'DETDVF_PRECIO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    { // 8
      headerName: 'Dsct(%)', field: 'DETDVF_DESCUENTO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    { // 9
      headerName: 'Total', field: 'DETDVF_TOTAL', cellEditor: 'cellPrueba', width: 150, cellStyle: { textAlign: 'right' }, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true
        }
      }
    },
    { // 10
      headerName: 'Lst.', field: 'DETDVF_LISTA', cellEditor: 'agSelectCellEditor', editable: true, width: 80,
      cellEditorParams:
      {
        values: []
      }
    },
    { // 11
      headerName: 'Tipo', field: 'DETFAC_TIPOSRI', editable: false, width: 80,
      cellEditorParams:{
        values:{
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 20,
          opcionbuscar: true
        }
      }
    },
    { // 12
      headerName: 'C.Costos', field: 'CEN_CODIGO', editable: false, width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 13
      headerName: 'Cant.Und', field: 'DETDVF_CANTIDADUND', editable: false, width: 80
    },
    { 
      headerName: 'irbpnr', field: 'DETDVF_IRBPNR', hide: true, width: 120, cellStyle: { textAlign: 'right' }, editable: false
    },
    { 
      headerName: 'Total irbpnr', field: 'DETDVF_VALORIRBPNR', hide: true, width: 120, cellStyle: { textAlign: 'right' }, editable: false
    },
    { // CDPJ
      headerName: '%IVA', field: 'DETDVF_PORIVA', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true,
          opcionbuscar: true
        }
      }
    }
  ];

  columnDefsExistenciaBodegas = [
    {
      headerName: 'Código', field: 'BOD_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'BOD_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Existencia', field: 'EXISTENCIA', width: 120, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Pedidos', field: 'PEDIDO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Disponible', field: 'SALDO', width: 120, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio ', field: 'PRECIO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio + IVA', field: 'TOTAL', width: 120, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //GSRF
  columnDefsInfoAdic = [
    {
      headerName: 'Nombre', field: 'INFAD_CODIGO', cellEditor: 'cellPrueba', width: 200,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 300
        }
      }
    },
    {
      headerName: 'Información Adicional', field: 'INFAD_DESCRIPCION', cellEditor: 'cellPrueba', width: 400,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 300
        }
      }
    }
  ];
  //GSRF
  //CDPJ
columnDefsBasesIva = [
  {
    headerName: '%IVA', field: 'TARIIVA_DESCRIPCION', width: 115, editable: false
  },
  {
    headerName: 'Base', field: 'IVA_BASE', width: 90, cellStyle: { textAlign: 'right' }, editable: false
  },
  {
    headerName: 'Valor IVA', field: 'IVA_VALOR', width: 90, cellStyle: { textAlign: 'right' }, editable: false
  }
];
//CDPJ
  constructor(public venDevFacService: VenencdevfacService, private confirmationService: ConfirmationService,
    private busquedaSer: InvbusquedaService, private datePipe: DatePipe,
    private messageService: MessageService, private utilitariosService: UtilitariosService,
    private cliService: VenmaeclienteService, public usuario: Usuario,
    private auditoriagral: AuditoriagralService, private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService, private permisosService: PermisosService,
    private numeracionService: NumeracionService, private init: NuevoComponentService) {
    this.agTable();
    this.usuario.loadStorage();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.rowStyle = (params) => {
      if (params.data.DETDVF_TIPODET === 'Y') {
        return { background: 'rgb(255, 204, 255)' };
      }
    };

    this.rowStyleEC = (params) => {
      if (params.data.TRNCOBRO_TIPO !== 'A') {
        if (params.data.TRNCOBRO_FECHATRN === '') {
          return { background: 'rgb( 255, 255, 170)' };
        } else {
          return { background: 'rgb( 196, 225, 255)' };
        }
      }
    };

    this.rowStyleSaldo = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb( 255, 255, 170)' };
      }
    };

    this.defaultColDefDevolucion = {
      editable: true,
      width: 100,
      objeto: 'vendetdvf',
      resizable: true
    };
    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen',
      resizable: true
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen',
      resizable: true
    };

    this.defaultColExistBod = {
      editable: true,
      width: 100,
      objeto: 'existenciabodegas',
      resizable: true
    };
    //GSRF
    this.defaultColDefInfoAdic = {
      editable: true,
      width: 100,
      objeto: 'infoadicdevfac',
      resizable: true
    };
    //GSRF
  }

  async ngOnInit() {
    this.veninfoadicdevfac =[];//GSRF
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.largo = '-5800';
    this.largoestcta = '420';
    this.largoRef = '400';
    this.opcionesprecio = [];
    this.listaprecios = [];
    this.opcionesBodega = [];
    this.opcionesRegimen = [];
    this.opcionesExportacion = [];
    this.opcionesDistrito = [];
    this.opcionesRegRefrendo = [];
    this.opcionesIngresoExterior = [];
    this.opcionesTipoIngresoExterior = [];
    this.venDevFacService.encdevfacarray[this.indicador] = {};
    this.venDevFacService.detdevfacarray[this.indicador] = [];
    this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO = '';
    this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO = '';
    this.venDevFacService.encdevfacarray[this.indicador].CLI_NOMBRE = '';
    this.bolNuevo = true;
    this.displayAcciones = false;
    this.displayDialogDescuento = false;
    this.booleanValor = true;
    this.auxiliar = 0;
    this.auxiliarnum = 0;
    this.auxiliarpago = 0;
    this.booleanPorcentaje = false;
    this.bolestcta = false;
    this.bolsaldo = false;
    this.numDocRef = '';
    this.asiento = '';
    this.consulta = '';
    this.accionClave = '';
    this.nombrePaisSeleccionado = '';
    this.tipodetalleactivo = 'all';
    this.dscSeleccionado = 'dscPorcentaje';
    this.claveExportNuevo = '';
    this.codigoPaisRegimen = '';
    this.codigoPaisParaiso = '';
    this.where = '';
    this.valorDscValor = 0;
    this.valorDscPorcentaje = 0;
    this.porcIvaBoolean = true;
    this.booleanRegimen = true;
    this.booleanParaiso = true;
    this.booleanRegimenP = true;
    this.booleanRefrendo = true;
    this.numeracionActual = {};
    this.dscClienteActual = 0;
    this.totalApagar = 0;
    this.displayDialogDetFac = false;
    this.displayDialogPedidos = false;
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.displayDialogSeriales = false;
    this.editarCeldas = false;
    this.displayDialogPago = false;
    this.claveDialog = false;
    this.displayLog = false;
    this.valorInvalidoP = false;
    this.valorInvalidoV = false;
    this.numeracionDialog = false;
    this.logResultado = '';
    this.saldoCliente = '';
    this.estadoRef = '';
    this.nombreVendedor = '';
    this.valorDscValor = 0;
    this.valorDscPorcentaje = 0;
    this.choosedColorG = '#00FF00';
    this.choosedColorSB = '#FF80F0';
    this.claveAuth = '';
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.botonAnular = false;
    this.botonXML = false;
    this.botonAsiento = false;
    this.botonVerAsiento = false;
    this.botonBuscar = false;
    this.botonPago = false;
    this.cotizacionBoolean = false;
    this.booleanImpuestoRenta = true;
    this.activeIndex = 0;
    this.ventasboolean = false;
    this.imagenProducto = '';
    this.comprasboolean = false;
    this.consigboolean = false;
    this.trasladoboolean = false;
    this.nombrebodega = '';
    this.devboolean = false;
    this.impboolean = false;
    this.expboolean = false;
    this.otrosboolean = false;
    this.transboolean = false;
    this.displayDialogBus = false;
    this.numeroActual = 0;
    this.numdecsist = this.confIniciales.getNumDecSist();
    
    this.displayDialogBusquedaFast = false;
    this.comboOpciones = [{ label: 'A', value: 'A' },
    { label: 'S', value: 'S' }, { label: 'C', value: 'C' }, { label: '*', value: '*' }];
    this.tipodetalle = 'A';
    //this.opcionIva = [{ label: '12', value: 12 }, { label: '8', value: 8 }];

    //this.venDevFacService.dblPorcIvaDevF[this.indicador] = this.opcionIva[0].value;
    this.opcionIvaDesc = [{ label: 'Neto + IVA', value: '1' },
    { label: 'Incluido IVA', value: '2' }, { label: 'Exento', value: '0' }];
    this.opcionesRegimen = [{ label: '01-Regimen General', value: '01' }, { label: '02-Paraiso Fiscal', value: '02' },
    { label: '03-Regimen Físcal preferente juridicción de menos imposición', value: '03' }, { label: '', value: '' }];
    this.opcionesExportacion = [{ label: '01-Con Refrendo', value: '01' }, { label: '02-Sin Refrendo', value: '02' },
    { label: '03-Exportación de Servicios u Otros Ingresos del exterior(incluye activos intangibles)', value: '03' }, {
      label: '',
      value: ''
    }];
    this.opcionesIngresoExterior = [{ label: 'SI', value: 'SI' }, { label: 'NO', value: 'NO' }, { label: '', value: '' }];
    this.fechaEmision = new Date().toLocaleString().substr(0, 10);
    this.fechaInicio = new Date().toLocaleString().substr(0, 10);
    this.fechaFin = new Date().toLocaleString().substr(0, 10);
    this.horaInicio = new Date();
    this.horaFin = new Date();
    this.horaSistema = new Date();
    this.confIniciales.getDecimales('frmVEN_ENCDVF').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
            this.decimalesCantidadDVF = Number(decimales.NUMDEC_NUMDEC);
            this.venDevFacService.decimalesCantidadDevF = this.decimalesCantidadDVF;
          } else if (decimales.NUMDEC_CAMPO === 'PRECIO') {
            this.decimalesPrecioDVF = Number(decimales.NUMDEC_NUMDEC);
            this.venDevFacService.decimalesPrecioDevF = this.decimalesPrecioDVF;
          } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
            this.decimalesTotalDVF = Number(decimales.NUMDEC_NUMDEC);
            this.venDevFacService.decimalesTotalDevF = this.decimalesTotalDVF;
          } else if (decimales.NUMDEC_CAMPO === 'DESCUENTO') {
            this.decimalesDescDVF = Number(decimales.NUMDEC_NUMDEC);
            this.venDevFacService.decimalesDescDVF = this.decimalesDescDVF;
          }
        });
        this.columnDefsDVFDetalle[6].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.venDevFacService.decimalesCantidadDevF,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };
        this.columnDefsDVFDetalle[7].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.venDevFacService.decimalesPrecioDevF,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };
        this.columnDefsDVFDetalle[8].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: this.venDevFacService.decimalesDescDVF,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };
        this.columnDefsDVFDetalle[9].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.venDevFacService.decimalesTotalDevF,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            int: true
          }
        };
        
        this.aggrid.refreshColumnDefs();
      }
    });
    await this.getPermisos();
    //CDPJ
    this.boolfacturaElec= await this.venDevFacService.leerValidarRucsFacElec().catch(e=>{
      
    });
    console.log(this.boolfacturaElec)
    //CDPJ
    this.auditoriagral.obtenerConfiguracion1().subscribe((hmpConf) => {
      this.hmpConfiguracion = hmpConf;
      hmpConf.map((conf) => {
        if (conf.CFG_CODIGO === 'NUMDVF') {
          this.confNumeracion = conf.CFG_VALOR3;
        }
        if (conf.CFG_CODIGO === 'NUMCHEAUT') {
          this.confPago = conf.CFG_VALOR1;
        }
        if (conf.CFG_CODIGO === 'FACCC') {
          if (conf.CFG_VALOR1 !== 1) {
            this.columnDefsDVFDetalle.splice(12, 1);
            this.aggrid.refreshColumnDefs();
          }
        }
        if (conf.CFG_CODIGO === 'FACCANUND') {
          if (conf.CFG_VALOR1 !== 1) {
            this.columnDefsDVFDetalle.splice(13, 1);
            this.aggrid.refreshColumnDefs();
          }
        }
        //CDPJ
        if (conf.CFG_CODIGO === 'CNAFEPV') {
          this.CNAFEPV=conf.CFG_VALOR1
        }
        //CDPJ
      });
    });
    this.venDevFacService.getListaPrecios().subscribe((res) => {
      this.listaprecios = res;
      this.listaprecios.map((precio) => {
        let nuevoItem: any;
        nuevoItem = { label: precio.ARTPRE_CODIGO, value: precio.ARTPRE_CODIGO };
        this.opcionesprecio.push(nuevoItem);
      });
    });
    this.venDevFacService.obtenerDistrito().subscribe((res3) => {
      res3.map((distrito) => {
        let nuevoItem: any;
        nuevoItem = { label: distrito.CODSRI_CODIGO + '-' + distrito.CODSRI_DESCRIPCION, value: distrito.CODSRI_CODIGO };
        this.opcionesDistrito.push(nuevoItem);
      });
      this.opcionesDistrito.push({ label: '', value: '' });
    });
    this.venDevFacService.obtenerRegimenRefrendo().subscribe((res4) => {
      res4.map((regrefrendo) => {
        let nuevoItem: any;
        nuevoItem = { label: regrefrendo.CODSRI_CODIGO + '-' + regrefrendo.CODSRI_DESCRIPCION, value: regrefrendo.CODSRI_CODIGO };
        this.opcionesRegRefrendo.push(nuevoItem);
      });
      this.opcionesRegRefrendo.push({ label: '', value: '' });
    });
    this.venDevFacService.obtenerTipoIExterior().subscribe((res5) => {
      res5.map((tipoIext) => {
        let nuevoItem: any;
        nuevoItem = { label: tipoIext.CODSRI_CODIGO + '-' + tipoIext.CODSRI_DESCRIPCION, value: tipoIext.CODSRI_CODIGO };
        this.opcionesTipoIngresoExterior.push(nuevoItem);
      });
      this.opcionesTipoIngresoExterior.push({ label: '', value: '' });
    });
    this.venDevFacService.getBodegas().subscribe((res2) => {
      res2.map((bodega) => {
        let nuevoItem: any;
        nuevoItem = { label: bodega.BOD_NOMBRE, value: bodega.BOD_CODIGO };
        this.opcionesBodega.push(nuevoItem);
      });
    });
    this.indice = 0;
    this.i = 0;
    // this.venDevFacService.getEncDevF().subscribe((res) => {
    //   this.encabezadosDevF = res;
    // });
    this.venDevFacService.leerConfiguracionDatosElectronicos().subscribe(data => {
      for (const rs of data) {
        this.datosfe = rs;
      }
    });
    this.iva = await this.confIniciales.obtenerImpuesto('I', 'A');
    await this.confIniciales.obtenerSrifeTariIva();
    await this.llenarcombo()//CDPJ
  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciasDevFac--;
  }
//CDPJ
async llenarcombo(){
  //CDPJ
   this.cmbporceiva=[];
   for(let i=0;  this.confIniciales.srifetariiva[i];i++){
     let item=this.confIniciales.srifetariiva[i]
     if(item.TARIIVA_CODIGO !=='6' &&  item.TARIIVA_CODIGO !=='7'  && item.TARIIVA_CODIGO !== '-1'){
       this.cmbporceiva.push({codigo:item.TARIIVA_CODIGO,name:item.TARIIVA_PORCENTAJE.toString()})
     }
   }
   console.log(this.cmbporceiva)
   for(const data of this.cmbporceiva){
     if(Number(data.name) === Number(this.iva)){
       this.selectcombtipoIva={codigo:data.codigo,name:data.name}
       this.venDevFacService.porcetariiva=Number(this.selectcombtipoIva.name)
       this.venDevFacService.codtariiva=this.selectcombtipoIva.codigo
       this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA=this.venDevFacService.porcetariiva
     }
   }
}
cambiavalorIva(select){
  this.selectcombtipoIva.codigo=select.value.codigo
  this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA=Number(select.value.name);
  //console.log(this.encordService.dblPorcIvaPed)
  this.venDevFacService.porcetariiva=Number(select.value.name)
  this.venDevFacService.codtariiva=select.value.codigo
}
  async getPermisos() {
    try {
      console.log(this.init.tabs[this.init.tabindex - 1]);
      const data = await this.permisosService.getPermisosFrmPVorVEN(this.init.tabs[this.init.tabindex - 1]);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisosDVF = data[0];
        } else {
          this.permisosDVF = null;
        }
      } else {
        this.permisosDVF = null;
      }
    } catch (e) {
      this.permisosDVF = null;
      this.messageService.add({
        key: 'devolucion',
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }

  navegarDevolucion(valor) {
    // if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO === undefined) {
    //   return;
    // }

    if (this.verificarDevPagada() === false) {
      return;
    }

    this.ValidarSeriales(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO, valor, 'navegacion');

  }

  verificarDevPagada(): boolean {
    let bolPagada = true;
    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PENDIENTE' && this.edittxtnumero === false) {
      this.messageService.add({
        key: 'devolucion',
        severity: 'error',
        summary: 'Información',
        detail: 'La devolución no ha sido pagada.'
      });
      bolPagada = false;
    }

    return bolPagada;
  }


  async ValidarSeriales(strNumDoc, strTipo, accion) {
    let errores = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let intLinea = 0;
    let strSentencia = '';

    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'ANULADA') {
      if (accion === 'navegacion') {
        this.irDevolucion(strTipo);
      }
      if (accion === 'nuevo') {
        this.limpiarDatos();
        this.bolNuevo = true;
        this.edittxtnumero = true;
        this.nuevaDVF('N');
      }
      if (accion === 'buscar') {
        // this.busquedafactura();
      }
      if (accion === 'contado') {
        // this.PagoFacturaContado();
      }
      if (accion === 'credito') {
        // this.Credito();
      }
      if (accion === 'salir') {
        this.init.tabs.splice(this.init.tabindex, 1);
      }
    } else {
      for (let i = 1; i <= this.venDevFacService.detdevfacarray[this.indicador].length; i++) {
        artserialflag = this.venDevFacService.detdevfacarray[this.indicador][i - 1].ART_SERIALFLAG;
        articulo = this.venDevFacService.detdevfacarray[this.indicador][i - 1].DETDVF_CODIGO;
        cantidad = this.venDevFacService.detdevfacarray[this.indicador][i - 1].DETDVF_CANTIDAD;
        intLinea = this.venDevFacService.detdevfacarray[this.indicador][i - 1].DETDVF_LINEA;

        if (artserialflag === 'S') {
          /* strSentencia = 'select count(*) AS COUN from INV_TRNKARDEXOTR ';
           strSentencia = strSentencia + ' where art_codigo = \''
             + articulo + '\' and TRNKARDEXOTR_NUMERO = \''
             + strNumDoc + '\'';
           strSentencia = strSentencia + ' and trnkardexotr_linea = ' + intLinea; */

          const data3 = await this.venDevFacService.ejecutarConsultaPromise(articulo, strNumDoc, intLinea);
          for (const rs of data3) {
            if (rs.COUN !== Number(cantidad)) {
              errores++;
            }
          }
        }
      }


      if (errores > 0) {
        this.messageService.add({
          key: 'devolucion',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese los seriales.'
        });
      } else {
        if (accion === 'navegacion') {
          this.irDevolucion(strTipo);
        }
        if (accion === 'nuevo') {
          this.limpiarDatos();
          this.bolNuevo = true;
          this.edittxtnumero = true;
          this.nuevaDVF('N');
        }
        if (accion === 'buscar') {
          // this.busquedafactura();
        }
        if (accion === 'contado') {
          // this.PagoFacturaContado();
        }
        if (accion === 'credito') {
          // this.Credito();
        }
        if (accion === 'salir') {
          this.init.tabs.splice(this.init.tabindex, 1);
        }
      }
    }
  }

  irDevolucion(valor) {
    // this.venDevFacService.getEncDevF().subscribe((res) => {
    //   this.encabezadosDevF = res;
    // });
    let strCondicion = 'ENCDVF_NUMERO IS NOT NULL AND COM_CODIGO = \'01\'';
    let strTipo =''
    if (valor === 'Primero') {
      // this.indice = 0;
      // if (this.botonUltimo === true) {
      //   this.botonUltimo = !this.botonUltimo;
      // }
      // if (this.botonSiguiente === true) {
      //   this.botonSiguiente = !this.botonSiguiente;
      // }
      // this.botonPrimero = true;
      // this.botonAnterior = true;
      // //this.venDevFacService.encdevfacarray[this.indicador] = this.encabezadosDevF[this.indice];
      // //this.obtenerDatos();
      strTipo='P';
    }

    if (valor === 'Siguiente') {
      // // if (this.indice === -1) {
      // //   return;
      // // }
      // // this.indice++;
      // // this.venDevFacService.encdevfacarray[this.indicador] = this.encabezadosDevF[this.indice];
      // if (this.botonPrimero === true && this.botonAnterior === true) {
      //   this.botonPrimero = false;
      //   this.botonAnterior = false;
      // }
      // if (this.indice >= this.encabezadosDevF.length - 1) {
      //   this.botonUltimo = true;
      //   this.botonSiguiente = true;
      //   if (this.indice === this.encabezadosDevF.length - 1) {
      //     //this.obtenerDatos();
      //   } else if (this.indice !== this.encabezadosDevF.length - 1) {
      //     return;
      //   }
      // } else if (this.indice < this.encabezadosDevF.length - 1) {
      //   //this.obtenerDatos();
      // }
      strTipo = 'S';
      if(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO.length>0){
        strCondicion = strCondicion + ' AND ENCDVF_NUMERO > \''
          + this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO + '\' AND ENCDVF_NUMERO LIKE \''
          + this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO.substr(0, 3) + '%\'';
      }else{
        strTipo = 'U';
      }
      
    }

    if (valor === 'Anterior') {
      // if (this.indice === 0 || this.indice === -1) {
      //   return;
      // }
      // this.indice--;
      // this.venDevFacService.encdevfacarray[this.indicador] = this.encabezadosDevF[this.indice];
      // if (this.botonUltimo === true && this.botonSiguiente === true) {
      //   this.botonUltimo = false;
      //   this.botonSiguiente = false;
      // }
      // if (this.indice <= 0) {
      //   this.botonPrimero = true;
      //   this.botonAnterior = true;
      //   if (this.indice === 0) {
      //     //this.obtenerDatos();
      //   } else if (this.indice !== 0) {
      //     return;
      //   }
      // } else if (this.indice > 0) {
      //   //this.obtenerDatos();
      // }
      strTipo = 'A';
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO.length > 0) {
        strCondicion = strCondicion + ' AND ENCDVF_NUMERO < \''
          + this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO + '\'';
      } else {
        strTipo = 'P';
      }
    }

    if (valor === 'Ultimo') {
      // this.indice = this.encabezadosDevF.length - 1;
      // if (this.indice === -1) {
      //   return;
      // }
      // this.venDevFacService.encdevfacarray[this.indicador] = this.encabezadosDevF[this.indice];
      // if (this.botonPrimero === true) {
      //   this.botonPrimero = !this.botonPrimero;
      // }
      // if (this.botonAnterior === true) {
      //   this.botonAnterior = !this.botonAnterior;
      // }
      // this.botonSiguiente = true;
      // this.botonUltimo = true;
      // //this.obtenerDatos();
      strTipo = 'U';
    }
    if (strTipo === 'P') {
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = false;
      this.botonUltimo = false;
    }
    if (strTipo === 'A' || strTipo === 'S') {
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
    }
    if (strTipo === 'U') {
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }
    this.venDevFacService.seleccionarRegistro(strTipo, ' ', strCondicion).subscribe((datos: any[]) => {
      if (datos !== null) {
        this.obtenerDatos(datos[0].ENCDVF_NUMERO);
        if (this.activeIndex === 0) {
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
        }
      }
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'devolucion',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir' && 
      valor !== 'facturasAbonadas') {
      console.log(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION);
      let dia = new Date().getDate();
      let mes = new Date().getMonth();
      let anio = new Date().getFullYear();
      // Verifica el periodo contable
      try {
        dia = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION.getDate();
        mes = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION.getMonth();
        anio = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION.getFullYear();
      } catch (e) {
        const tmpfecha = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION.substr(0, 10);
        const splfec = tmpfecha.split('/');
        const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
        dia = fecha.getDate();
        mes = fecha.getMonth();
        anio = fecha.getFullYear();
      }

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'devolucion',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
    if (valor === 'Primero') {
      this.navegarDevolucion(valor);
    }

    if (valor === 'Siguiente') {
      this.navegarDevolucion(valor);
    }

    if (valor === 'Anterior') {
      this.navegarDevolucion(valor);
    }

    if (valor === 'Ultimo') {
      this.navegarDevolucion(valor);
    }

    if (valor === 'Nuevo') {
      if (this.permisosDVF.PERINSERCION === 1) {
        if (this.verificarDevPagada() === false) {
          return;
        }

        this.ValidarSeriales(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO, valor, 'nuevo');

      } else if (this.permisosDVF.PERINSERCION === 0) {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Inserción de Devolución',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
        return;
      }

    }

    if (valor === 'Borrar') {
      // if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PAGADA') {
      //   this.messageService.add({
      //     key: 'devolucion',
      //     severity: 'error',
      //     summary: 'Eliminación de Devolución',
      //     detail: 'No es posible eliminar la factura esta pagada'
      //   });
        
      // }//CDPJ COMENTÉ

      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== '' &&
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== null &&
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== undefined) {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Eliminación de Devolución',
          detail: 'No es posible eliminar la factura esta firmada'
        });
        return;
      }

      if (this.permisosDVF.PERELIMACION === 1) {
        if (this.activeIndex === 0) {
          this.confirmarDev();
        } else if (this.activeIndex === 3) {
          this.confirmarExportDev();
        }
      } else if (this.permisosDVF.PERELIMACION === 0) {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Eliminación de Devolución',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }

    if (valor === 'Guardar') {
      const NOFCCERO = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOFCCERO').CFG_VALOR1;//CDPJ
      const FACCC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACCC').CFG_VALOR1;
      if (this.permisosDVF.PERACTUALIZACION === 1) {
        if (this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO === '' 
         || this.venDevFacService.encdevfacarray[this.indicador].CLI_NOMBRE === ''||
          this.venDevFacService.detdevfacarray[this.indicador].length === 0) {
          this.messageService.add({
            key: 'devolucion',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'No existe un cliente o el detalle está vacio'
          });
        } else if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION === '' ||
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION === null) {
          this.messageService.add({
            key: 'devolucion',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'Ingrese fechas validas para guardar la devolución'
          });
        } else if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA === '') {
          this.messageService.add({
            key: 'devolucion',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'No se puede guardar una devolución sin una referencia'
          });
        } else {
          if (this.venDevFacService.detdevfacarray[this.indicador].length !== 0) {
            let errores = 0;
            let msgcont = 0;
            await Promise.all(this.venDevFacService.detdevfacarray[this.indicador].map(async(dev) => {
              if(dev.DETDVF_TIPODET === 'C' || dev.DETDVF_TIPODET === 'S'){
                if(dev.DETDVF_TRIBICE === "" || dev.DETDVF_TRIBIVA === "" ){
                  this.messageService.add({
                    key: 'devolucion',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'IVA o ICE no puede ir vacio porfavor ingrese S o N'
                  });
                  errores++;

                }

              }
              if (dev.DETDVF_TIPODET === '*' || dev.DETDVF_TIPODET === 'Y') {
                errores += 0;
              } else {
                let cont=0;
                let conttiposri=0;
                const num= await this.venDevFacService.validarcencodigo(dev.CEN_CODIGO);
                const rsdata: any = await this.venDevFacService.encontrarArtCntaySer(dev.DETDVF_CODIGO, dev.DETDVF_TIPODET);
                const data = await this.venDevFacService.validartipocodsri(dev.DETFAC_TIPOSRI);
                let strCodigo = 0;
                if (rsdata !== null) {
                  for (const rs of rsdata) {
                    strCodigo = rs.NUMERO;
                  }
                }
                if (num !== null && num !== undefined){
                  for( const rs of num){
                    if(rs.NUMERO !== null && rs.NUMERO!== undefined && rs.NUMERO !== ''){
                      cont=rs.NUMERO
                    }
                    
                  }
                }
                if (data !== null && data !== undefined){
                  for( const rs of data){
                    if(rs.NUMERO !== null && rs.NUMERO!== undefined && rs.NUMERO !== ''){
                      conttiposri=rs.NUMERO
                    }
                    
                  }
                }
                if (dev.DETDVF_CODIGO === '' && dev.DETDVF_TIPODET !== '*') {
                  //this.venDevFacService.detdevfacarray[this.indicador] = this.venDevFacService.detdevfacarray[this.indicador].filter((val, j) => j !==
                    //this.venDevFacService.detdevfacarray[this.indicador].indexOf(dev));
                  //this.aggrid.refreshaggrid(this.venDevFacService.detdevfacarray[this.indicador], this.defaultColDefDevolucion.objeto);
                  this.messageService.add({
                    key: 'devolucion',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'El código en el detalle está vacío'
                  });
                  errores++;
                }else if (dev.DETDVF_CODIGO === '') {
                  this.venDevFacService.detdevfacarray[this.indicador] = this.venDevFacService.detdevfacarray[this.indicador].filter((val, j) => j !==
                    this.venDevFacService.detdevfacarray[this.indicador].indexOf(dev));
                  this.aggrid.refreshaggrid(this.venDevFacService.detdevfacarray[this.indicador], this.defaultColDefDevolucion.objeto);
                } else if (dev.BOD_CODIGO === '') {
                  if (msgcont === 0) {
                    msgcont++;
                    this.messageService.add({
                      key: 'devolucion',
                      severity: 'error',
                      summary: 'Inserción de Devolución',
                      detail: 'Ingrese una bodega al detalle de la devolución'
                    });
                  }
                  errores++;
                } else if (Number(dev.DETDVF_CANTIDAD) <= 0) {
                  if (msgcont === 0) {
                    msgcont++;
                    this.messageService.add({
                      key: 'devolucion',
                      severity: 'error',
                      summary: 'Inserción de Devolución',
                      detail: 'Ingrese una cantidad valida al detalle de la devolución'
                    });
                  }
                  errores++;
                } else if (Number(dev.DETDVF_PRECIO) <= 0  && NOFCCERO === 1) {
                  if (msgcont === 0) {
                    msgcont++;
                    this.messageService.add({
                      key: 'devolucion',
                      severity: 'error',
                      summary: 'Inserción de Devolución',
                      detail: 'Ingrese un precio valido al detalle de la devolución'
                    });
                  }
                  errores++;
                }else if(dev.DETDVF_TIPODET === 'A' && strCodigo === 0){//CDPJ
                  this.messageService.add({
                    key: 'devolucion',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'El artículo '+ dev.DETDVF_CODIGO +' no existe'
                  });
                  errores++;
                }else if(dev.DETDVF_TIPODET === 'S' && strCodigo === 0){
                  this.messageService.add({
                    key: 'devolucion',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'El servicio '+ dev.DETDVF_CODIGO +' no existe'
                  });
                  errores++;
                }else if(dev.DETDVF_TIPODET === 'C' && strCodigo === 0){
                  this.messageService.add({
                    key: 'devolucion',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'La cuenta contable '+ dev.DETDVF_CODIGO +' no existe'
                  });
                  errores++;
                }

                //CDPJ
            if(dev.TARIIVA_CODIGO === null || dev.TARIIVA_CODIGO === undefined || dev.TARIIVA_CODIGO === ''){
              this.messageService.add({
                key: 'devolucion',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'Falta seleccionar el tipo de porcentaje del código '+dev.DETDVF_CODIGO
              });
              errores++;                     
          }
            else if(dev.DETDVF_PORIVA === null || dev.DETDVF_PORIVA === undefined || dev.DETDVF_PORIVA === ''){
              this.messageService.add({
                key: 'devolucion',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El campo %IVA no puede estar vacio, a menos que sea un comentario'
              });
              errores++;             
            
          }else if(dev.DETDVF_TRIBIVA === 'S' && dev.TARIIVA_CODIGO !== null && dev.TARIIVA_CODIGO !== undefined && dev.TARIIVA_CODIGO !== ''){
            let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === dev.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0 && Number(dev.DETDVF_PORIVA) !== 0 &&  Number(element.TARIIVA_PORCENTAJE) === Number(dev.DETDVF_PORIVA))
            if(data === undefined || data === null){
              this.messageService.add({
                key: 'devolucion',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El %IVA con el porcentaje '+dev.DETDVF_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando se tributa IVA.'
              });
              errores++;             
            }
          }else if(dev.DETDVF_TRIBIVA === 'N'
          && dev.TARIIVA_CODIGO !== null && dev.TARIIVA_CODIGO !== undefined && dev.TARIIVA_CODIGO !== ''){
            let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === dev.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0 && Number(dev.DETDVF_PORIVA) === 0 &&  Number(element.TARIIVA_PORCENTAJE) === Number(dev.DETDVF_PORIVA))
            console.log(data)
            console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === dev.TARIIVA_CODIGO))
            if(data === undefined || data === null){
              this.messageService.add({
                key: 'devolucion',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El %IVA con el porcentaje '+dev.DETDVF_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando no se tributan IVA.'
              });
              errores++;
            }
          }
          if (FACCC === 1 && (dev.CEN_CODIGO === null || dev.CEN_CODIGO === undefined || dev.CEN_CODIGO === '') ){
            this.messageService.add({
              key: 'devolucion',
              severity: 'error',
              summary: 'Error en IVA',
              detail: 'El campo del centro de costos está vacío.'
            });
            errores++;
          }
          if(dev.CEN_CODIGO !== undefined && dev.CEN_CODIGO !== null && dev.CEN_CODIGO !== ''){//CDPJ
            console.log('entro al cen');
            if(cont === 0){
              this.messageService.add({
                key: 'devolucion',
                severity: 'error',
                summary: 'Inserción de Guía',
                detail: 'El código '+dev.CEN_CODIGO +' del campo centro de costos, no existe'
              });
              errores++;
            }
            //CDPJ
          }
          if(dev.DETFAC_TIPOSRI !== null && dev.DETFAC_TIPOSRI !== undefined && String(dev.DETFAC_TIPOSRI).trim() !== ''){
            if(conttiposri === 0){
              this.messageService.add({
                key: 'devolucion',
                severity: 'error',
                summary: 'Inserción de Guía',
                detail: 'El código '+dev.DETFAC_TIPOSRI +' del campo tipo, no existe'
              });
              errores++;
            }
          }
            //CDPJ
            //CDPJ
              }
            }));
            if (errores === 0) {
              this.venDevFacService.obtenerSaldoFactura(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA).subscribe(data => {
                this.venDevFacService.encdevfacarray[this.indicador].SALDOFAC = 0;
                for (const rs of data) {
                  this.venDevFacService.encdevfacarray[this.indicador].SALDOFAC = rs.SALDO;
                }
                this.guardarDevolucion();
              });

            }
          }
        }
      } else if (this.permisosDVF.PERACTUALIZACION === 0) {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Actualización de Devolucioón',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }

    if (valor === 'Cancelar') {
      this.cancelarEdicionDevolucion();
    }

    if (valor === 'Anular') {
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO !== 'ANULADA') {
        let strVal = 0;
        strVal = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENCLAAUT').CFG_VALOR1;

    if (strVal === 0) {


      this.strDialogo = 'frmVEN_ENCDVF';
      this.strObjeto = 'CmdAnulada';
      this.intPestaniaCol = 0;
      this.usuarioSup = '';
      this.claveSup = '';

      this.venDevFacService.countSacimaeobj(this.strDialogo, this.strObjeto, this.venDevFacService.usuario.identificacion, this.intPestaniaCol).subscribe(eC => {
        for (const rs of eC) {
          if (rs.COUN > 0) {
            this.displayDialogSupervisor = true;
          } else {
            this.confirmarAnularDev();
          }
        }
      });
    }else{
      this.abrirClave('ANULADVF');
    }
      } else if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'ANULADA') {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Anular Devolución',
          detail: 'La Devolución ya esta Anulada'
        });
      }
    }

    if (valor === 'Buscar') {
      this.ValidarSerialesBusqueda()
      //this.buscarDevoluciones();
    }
    if (valor === 'facturasAbonadas') {
      this.buscarDevolucionesnofirma();
    }
    if (valor === 'Asiento') {
      if (this.btnGuardar === true) {
        if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO !== 'PENDIENTE') {
          this.confirmarGenerarAsiento();
        } else {
          this.messageService.add({
            key: 'devolucion',
            severity: 'error',
            summary: 'Información',
            detail: 'No se puede generar el asiento, la ' +
              'devolución no ha sido pagada.'
          });
        }
      } else {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Asiento Devolución',
          detail: 'No se puede generar un asiento debido a que la factura no esta guardada'
        });
      }
    }

    if (valor === 'AsientoVer') {
      this.venDevFacService.getAsiento(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((res) => {
        this.asiento = res[0].ASI_NRO;
        if (this.asiento === null) {
          this.messageService.add({
            key: 'devolucion',
            severity: 'error',
            summary: 'Visualizacion de Asiento Contable',
            detail: 'Esta devolución no tiene un asiento contable'
          });
        } else {
          this.auxiliar++;
          // this.displayDialogAsiento = true;
          this.displayAsiContable = true;
        }
      });
    }

    if (valor === 'Firmar') {
      this.insertarEleTrnDoc();
    }

    if (valor === 'EnviarCorreo') {
      this.CENREPDOCE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENREPDOCE').CFG_VALOR1;
      if(this.CENREPDOCE === 1){
        if(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== 'AT'){
          this.messageService.add({
            key: 'devolucion',
            severity: 'info',
            summary: 'Información',
            detail: 'La devolución debe estar en estado AT'
          });
          return
        }
        await this.despliegaopcionride();
        return
      }
      this.reenviarCorreo();
    }

    if (valor === 'ClaseNumeracion') {
      if (this.btnNuevo === false) {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO = '';
      } else {
        return;
      }
    }

    if (valor === 'Descuento') {
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'G' ||
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PAGADA') {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Descuento Global',
          detail: 'La devolución esta pagada.',
        });
        return;
      }

      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'A' ||
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'ANULADA') {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Descuento Global',
          detail: 'La devolución esta anulada.',
        });
        return;
      }
      console.log(this.venDevFacService.encdevfacarray[this.indicador])
      if (this.venDevFacService.encdevfacarray[this.indicador] !== undefined) {
        if (Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTAL)) > 0) {
          if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES === undefined
            || this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES === null
            || this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES === '') {
            this.valorDscPorcentaje = 0;
            this.valorDscValor = 0;
          } else {
            this.valorDscValor = Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES));
            this.valorDscPorcentaje = Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES)) * 100 /
            Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTALNETO));
            const strdscporce = this.valorDscPorcentaje.toFixed(this.confIniciales.getNumDecSist());
            this.valorDscPorcentaje = Number(strdscporce);
          }

          if (this.valorDscPorcentaje > 0) {
          } else {
            if (Number(this.dscClienteActual) > 0) {
              this.valorDscPorcentaje = Number(this.dscClienteActual);
            }
          }
          this.displayDialogDescuento = true;
        }
      }
    }

    if (valor === 'Pago') {
      let saldoDev = 0;
      this.totalApagar = 0;
      if (Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTAL)) === 0) {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Pago Devolución',
          detail: 'El total de la devolución es cero',
        });
      }
      if (this.btnGuardar === false) {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Pago Devolución',
          detail: 'Primero guarde la devolución',
        });
        return;
      }

      this.venDevFacService.obtenerPago(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((res) => {
        if (Number(res[0].PAGO) === 0 && this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PAGADA') {
          this.venDevFacService.obtenerPago2(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((res2) => {
            if (Number(res2[0].PAGO1 === 0) && this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PAGADA') {
              this.venDevFacService.obtenerPago3(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((res3) => {
                if (Number(res3[0].PAGO2 === 0) && this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PAGADA') {
                  this.messageService.add({
                    key: 'devolucion',
                    severity: 'info',
                    summary: 'Pago Devolución',
                    detail: 'La devolución fue pagada con el saldo del documento referenciado',
                  });
                } else {
                  this.venDevFacService.obtenerSaldoFacturaSnc(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO,
                    this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA).subscribe((saldo) => {
                      this.auxiliarpago++;
                      saldoDev = saldo[0].SALDO;
                      this.totalApagar = Number(Number(Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTAL)) - saldoDev).toFixed(2));
                      this.displayDialogPago = true;
                    });
                }
              });
            } else if (Number(res2[0].PAGO1 !== 0)) {
              this.venDevFacService.obtenerSaldoFacturaSnc(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO,
                this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA).subscribe((saldo) => {
                  this.auxiliarpago++;
                  saldoDev = saldo[0].SALDO;
                  this.totalApagar = Number(Number(Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTAL)) - saldoDev).toFixed(2));
                  this.displayDialogPago = true;
                });
            }
          });
        } else if (Number(res[0].PAGO !== 0) || this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PENDIENTE') {
          this.venDevFacService.obtenerSaldoFacturaSnc(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO,
            this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA).subscribe((saldo) => {
              this.auxiliarpago++;
              saldoDev = saldo[0].SALDO;
              this.totalApagar = Number(Number(Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTAL)) - saldoDev).toFixed(2));
              this.displayDialogPago = true;
            });
        }
      });
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }

    if (valor === 'Almacenardoc') {
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'devolucion',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }

    if (valor === 'Salir') {
      if (this.verificarDevPagada() === false) {
        return;
      }

      this.ValidarSeriales(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO, valor, 'salir');
    }
    // document.getElementById('contorno').style.pointerEvents = 'all';
  }
//CDPJ
async ValidarSerialesBusqueda(){
  let errores = 0;
  let articulo = '';
  let cantidad = 0;
  let artserialflag = '';
  let intLinea = 0;
  let strSentencia = '';
  if ( this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'ANULADA'||
  this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'A') {
    this.buscarDevoluciones();
  } else if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO === '') {
    this.buscarDevoluciones();
  }else{
    for (let i = 1; i <= this.venDevFacService.detdevfacarray[this.indicador].length; i++) {
      artserialflag = this.venDevFacService.detdevfacarray[this.indicador][i - 1].ART_SERIALFLAG;
      articulo = this.venDevFacService.detdevfacarray[this.indicador][i - 1].DETDVF_CODIGO;
      cantidad = this.venDevFacService.detdevfacarray[this.indicador][i - 1].DETDVF_CANTIDAD;
      intLinea = this.venDevFacService.detdevfacarray[this.indicador][i - 1].DETDVF_LINEA;
  
      if (artserialflag === 'S') {
        /* strSentencia = 'select count(*) AS COUN from INV_TRNKARDEXOTR ';
         strSentencia = strSentencia + ' where art_codigo = \''
           + articulo + '\' and TRNKARDEXOTR_NUMERO = \''
           + strNumDoc + '\'';
         strSentencia = strSentencia + ' and trnkardexotr_linea = ' + intLinea; */
  
        const data3 = await this.venDevFacService.ejecutarConsultaPromise(articulo, this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO, intLinea);
        for (const rs of data3) {
          if (rs.COUN !== Number(cantidad)) {
            errores++;
          }
        }
      }
    }
    if (errores > 0) {
      this.messageService.add({
        key: 'devolucion',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese los seriales.'
      });
    } else{
      this.buscarDevoluciones();
    }
  }
  
}
verificarClaveSupervisor() {

  // if (this.intPestaniaCol > 0) {
  //   strSql = strSql + ' AND O.GUIOBJ_TABCOL = ' + this.intPestaniaCol;
  // }

  this.venDevFacService.verificarPermisosSuperv(this.strDialogo, this.strObjeto, this.venDevFacService.usuario.identificacion, this.usuarioSup,
    this.intPestaniaCol).subscribe(data => {
      if (data.length === 0) {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Información',
          detail: 'Asigne la clave en los permisos ' +
            'especiales del usuario.'
        });
      } else {
        for (const rs of data) {
          if (rs.USUCLAVESUP !== null) {
            if (rs.USUCLAVESUP.length > 0) {
              if (rs.USUCLAVESUP === this.claveSup) {
                this.accionesSupervisor();
              } else {
                this.messageService.add({
                  key: 'devolucion',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'La clave está incorrecta..'
                });
              }
            } else {
              this.messageService.add({
                key: 'devolucion',
                severity: 'error',
                summary: 'Información',
                detail: 'Asigne la clave en los permisos ' +
                  'especiales del usuario.'
              });
            }
          } else {
            this.messageService.add({
              key: 'devolucion',
              severity: 'error',
              summary: 'Información',
              detail: 'Asigne la clave en los permisos ' +
                'especiales del usuario.'
            });
          }
        }
      }
    });
}
accionesSupervisor() {
  if (this.strObjeto === 'CmdAnulada') {
    this.confirmarAnularDev();
    this.displayDialogSupervisor = false;
  }
}
validarClave() {
  if (this.usuarioSup.length > 0) {
    this.verificarUsuarioSupervisor();  // verifica si el usuario es supervisor
  } else {
    this.messageService.add({
      key: 'devolucion',
      severity: 'error',
      summary: 'Información',
      detail: 'Ingrese el usuario.'
    });
  }
}
verificarUsuarioSupervisor() {
  let strSql = 'SELECT COUNT(*) AS COUN FROM SACI_MAEGUIOBJPER S ';
  strSql = strSql + ' WHERE S.USU_IDENTIFICACIONSUP = \'' + this.usuarioSup + '\' AND ';
  strSql = strSql + ' S.COM_CODIGO = \'01\'';

  this.venDevFacService.countSacimaeObjper(this.usuarioSup).subscribe(eC => {
    for (const rs of eC) {
      if (rs.COUN > 0) {
        if (this.claveSup.length > 0) {
          this.verificarClaveSupervisor();
        } else {
          this.messageService.add({
            key: 'devolucion',
            severity: 'error',
            summary: 'Información',
            detail: 'Ingrese la clave.'
          });
        }
      } else {
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Información',
          detail: 'El usuario ' + this.usuarioSup + ' no es Supervisor o No existe'
        });
      }
    }
  });
}
activarFacAnulada() {
  if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'ANULADA') {
    this.abrirClave('ACTDOC');
    this.closeSidebar();
    setTimeout(() => {
      document.getElementById('clave').focus();
    }, 500);
  }
 
}
async despliegaopcionride(){
  this.strNombreForma = this.init.tabs[this.init.tabindex].component;
  this.auxreporte++;
  this.displayDialogfrmcorreos=true
}
cerrarfrmcorreos(){
  this.displayDialogfrmcorreos=false;
  this.cancelarEdicionDevolucion();
}
//CDPJ
  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  limpiarDatos() {
    const grupo = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO;
    this.venDevFacService.encdevfacarray[this.indicador] = {
      COM_CODIGO: '01',
      ENCDVF_FECHAEMISION: new Date(),
      ENCDVF_FECHAENTREGA: new Date(),
      ENCDVF_IVA: '1',
      ENCDVF_ESTADO: '',
      CLI_CODIGO: '',
      CLI_NOMBRE: '',
      BOD_CODIGO: '001',
      VEN_CODIGO: '',
      ENCDVF_REFERENCIA: '',
      ENCFFA_CODIGO: '',
      ENCDVF_FORMAPAGO: '',
      ENCDVF_TIPO: '',
      ENCDVF_LISTA: 'A',
      ENCDVF_FECHAVENCIMIENTO: new Date(),
      ENCDVF_TOTAL: 0,
      ENCDVF_TOTALNETO: 0,
      ENCDVF_VALORIRBPNR: 0,//GSRF
      ENCDVF_VALORDES: 0,
      ENCDVF_PORCEDES: 0,
      ENCDVF_VALORIVA: 0,
      ENCDVF_PORCEIVA: Number(this.venDevFacService.porcetariiva),
      ENCDVF_VALORICE: 0,
      ENCDVF_PORCEICE: 0,
      ENCDVF_BASEIVA: 0,
      ENCDVF_BASEICE: 0,
      ENCDVF_BASECERO: 0,
      ENCDVF_PORCECAR: 0,
      ENCDVF_VALORCAR: 0,
      ENCDVF_PORCERTC: 0,
      ENCDVF_VALORRTC: 0,
      ENCDVF_VALORDEV: 0,
      ENCDVF_PORCEINT: 0,
      ENCDVF_VALORINT: 0,
      ENCDVF_PORCEREC: 0,
      ENCDVF_VALORREC: 0,
      ENCDVF_IMPRESO: 'N',
      ENCDVF_COMENTARIO: '',
      ENCDVF_TIPOFAC: 'S',
      ENCDVF_GENERAFAC: 'N',
      ENCDVF_NUMERODOB: '',
      ENCDVF_DIRECCION: '',
      ENCDVF_TELEFONO: '',
      ENCDVF_RUCIDE: '',
      ENCDVF_CHOFER: '',
      ENCDVF_CARRO: '',
      ENCDVF_PLACA: '',
      ENCDVF_OTROS: 0,
      ASI_NRO: '',
      ENCDVF_CLIDSCT: 0,
      USU_IDENTIFICACION: this.usuario.identificacion,
      ENCDVF_TIPODSCTO: '',
      ENCDVF_FLAG: 0,
      ENCDVFP_NUMERO: '',
      CLI_ZONA: '',
      VEN_NOMBRE: '',
      CLI_OBSERVACION: '',
      REF_TIPO: '',
      REF_NOMBRE: '',
      REF_CODIGO: '',
      ENCDVF_ESTADO_FE: '',
      ENCDVF_AUTORIZACION_FE: '',
      ENCDVF_CLAVEACCESO_FE: '',
      ENCDVF_FECHAAUT_FE: '',
      ENCDVF_NUMFACTELEC: '',
      COM_TIPOAMBFACTELEC: '',
      ENCDVF_PATHXMLNOAUTO_FE: '',
      ENCDVF_PATHPDF_FE: '',
      ENCDVF_TIPONC: '',
      SALDO: '',
      ENCDVF_GRUPO: grupo,
      ENCDVF_NUMERO: '',
      ENCDVF_SERIE: '',
      ENCDVF_NEMONICO: '',
      ENCDVF_SECACTUAL: '',
      ENCDVF_AUTORIZACION: ''
    };
    this.venDevFacService.detdevfacarray[this.indicador] = [];
    this.nombreVendedor = '';
    this.estadoRef = '';
    this.bolestcta = false;
    this.bolsaldo = false;
  }


  nuevaDVF(parametro) {
    if (parametro === 'N') { // N para nuevo
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = true;
      this.btnRegresar = false;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal').style.opacity = '1.0';
      this.aggrid.habilitarGrid();///GSRF
      this.columnDefsDVFDetalle.map((columna) => {
       //GSRF
       console.log('12236',columna)
       if(columna.field !== 'DETDVF_UNIDAD' && columna.field !== 'DETDVF_TRIBIVA' && columna.field !== 'DETDVF_TRIBICE'){
         columna.editable = true;
       } 
       //GSRF
      });
      this.aggrid.refreshColumnDefs();
      this.editarCeldas = false;
      this.tipodetalleactivo = 'all';
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
      this.botonPago = true;
      this.botonAnular = true;
      this.botonBuscar = true;
      this.botonAsiento = true;
      this.botonVerAsiento = true;
      this.porcIvaBoolean = true;
      if (this.confNumeracion === 'G') {
        this.venDevFacService.obtenerNumeracion().subscribe((res) => {
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO = String(res[0].NUM_SERIE).trim() + String(res[0].NUM_SECACTUAL).trim();
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_AUTORIZACION = String(res[0].NUM_AUTORIZACION).trim();
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_SECACTUAL = String(res[0].NUM_SECACTUAL).trim();
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_SERIE = String(res[0].NUM_SERIE).trim();
        });
      } else if (this.confNumeracion === 'C') {
        if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO === '' ||
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO === null ||
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO === undefined) {
          this.numDocRef = 'DVF';
          this.auxiliarnum++;
          this.numeracionDialog = true;
        } else {
          this.numDocRef = 'DVF';
          this.numeracionService.obtenerNumeracionTipo(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO,
            this.numDocRef).subscribe((numeracion) => {
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO = String(numeracion[0].NUM_NEMONICO).trim() + String
                (numeracion[0].NUM_SERIE).trim() + String(numeracion[0].NUM_SECACTUAL).trim();
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_AUTORIZACION = String(numeracion[0].NUM_AUTORIZACION).trim();
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_SECACTUAL = String(numeracion[0].NUM_SECACTUAL).trim();
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_SERIE = String(numeracion[0].NUM_SERIE).trim();
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NEMONICO = String(numeracion[0].NUM_NEMONICO).trim();
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO = String(numeracion[0].NUM_CLASE).trim();
              //CDPJ
              if(this.CNAFEPV === 1){
                numeracion[0].NUM_FE=numeracion[0].NUM_FE === null || numeracion[0].NUM_FE === undefined?0:numeracion[0].NUM_FE
                console.log(numeracion[0].NUM_FE)
                    if(numeracion[0].NUM_FE === 1){
                      this.botonXML = false;
                    }else{
                      this.botonXML = true;
                    }
              }
              //CDPJ
            });
        }
      }
      this.llenarcombo()
      const nuevaDevolucion = {
        COM_CODIGO: '01',
        ENCDVF_FECHAEMISION: new Date(),
        ENCDVF_FECHAENTREGA: new Date(),
        ENCDVF_IVA: '1',
        ENCDVF_ESTADO: 'PENDIENTE',
        CLI_CODIGO: '',
        CLI_NOMBRE: '',
        BOD_CODIGO: '001',
        VEN_CODIGO: '',
        ENCDVF_REFERENCIA: '',
        ENCFFA_CODIGO: '',
        ENCDVF_FORMAPAGO: '',
        ENCDVF_TIPO: '',
        ENCDVF_LISTA: 'A',
        ENCDVF_FECHAVENCIMIENTO: new Date(),
        ENCDVF_TOTAL: 0,
        ENCDVF_TOTALNETO: 0,
        ENCDVF_VALORDES: 0,
        ENCDVF_PORCEDES: 0,
        ENCDVF_VALORIVA: 0,
        ENCDVF_PORCEIVA: Number(this.venDevFacService.porcetariiva),
        ENCDVF_VALORICE: 0,
        ENCDVF_PORCEICE: 0,
        ENCDVF_BASEIVA: 0,
        ENCDVF_BASEICE: 0,
        ENCDVF_BASECERO: 0,
        ENCDVF_PORCECAR: 0,
        ENCDVF_VALORCAR: 0,
        ENCDVF_PORCERTC: 0,
        ENCDVF_VALORRTC: 0,
        ENCDVF_VALORDEV: 0,
        ENCDVF_PORCEINT: 0,
        ENCDVF_VALORINT: 0,
        ENCDVF_PORCEREC: 0,
        ENCDVF_VALORREC: 0,
        ENCDVF_VALORIRBPNR: 0, //GSRF
        ENCDVF_IMPRESO: 'N',
        ENCDVF_COMENTARIO: '',
        ENCDVF_TIPOFAC: 'S',
        ENCDVF_GENERAFAC: 'N',
        ENCDVF_NUMERODOB: '',
        ENCDVF_DIRECCION: '',
        ENCDVF_TELEFONO: '',
        ENCDVF_RUCIDE: '',
        ENCDVF_CHOFER: '',
        ENCDVF_CARRO: '',
        ENCDVF_PLACA: '',
        ENCDVF_OTROS: 0,
        ASI_NRO: '',
        ENCDVF_CLIDSCT: 0,
        USU_IDENTIFICACION: this.usuario.identificacion,
        ENCDVF_TIPODSCTO: '',
        ENCDVF_FLAG: 0,
        ENCDVFP_NUMERO: '',
        CLI_ZONA: '',
        VEN_NOMBRE: '',
        CLI_OBSERVACION: '',
        REF_TIPO: '',
        REF_NOMBRE: '',
        REF_CODIGO: '',
        ENCDVF_ESTADO_FE: '',
        ENCDVF_AUTORIZACION_FE: '',
        ENCDVF_CLAVEACCESO_FE: '',
        ENCDVF_FECHAAUT_FE: '',
        ENCDVF_NUMFACTELEC: '',
        COM_TIPOAMBFACTELEC: '',
        ENCDVF_PATHXMLNOAUTO_FE: '',
        ENCDVF_PATHPDF_FE: '',
        ENCDVF_TIPONC: '',
        SALDO: ''
      };
      this.exportacionNuevoSeleccionado = {
        ANIO: '',
        COM_CODIGO: '01',
        COMERCIOEXTERIOR: '',
        CORRELATIVO: '',
        DENOEXPCLI: '',
        DIRCOMPRADOR: '',
        DISTADUANERO: '',
        DOCTRANSP: '',
        ENCDVF_NUMERO: this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO,
        EXPORTACIONDE: '',
        FECHAEMBARQUE: new Date(),
        FLETEINTERNACIONAL: '',
        FORMAPAGO: '',
        GASTOSADUANEROS: '',
        GASTOSTRANSPORTEOTROS: '',
        INCOTERMFACTURA: '',
        INCOTERMTOTALSINIMPUESTOS: '',
        INGGRAVADOEXT: '',
        LUGARINCOTERM: '',
        PAISADQUISICION: '',
        PAISDESTINO: '',
        PAISEFECEXP: '',
        PAISEFECPAGOGEN: '',
        PAISORIGEN: '',
        PARTERELEXP: '',
        PUERTODESTINO: '',
        PUERTOEMBARQUE: '',
        REGIMEN: '',
        SEGUROINTERNACIONAL: '',
        TIPOCOMPROBANTE: '',
        TIPOINGRESO: '',
        TIPOREGI: '',
        TPIDCLIENTEEX: '',
        VALORFOB: '',
        VALORFOBCOMPROBANTE: '',
        VALORIMPEXT: '',
        PUNTOEMISION: '',
        ESTABLECIMIENTO: '',
        ENCDVF_SECACTUAL: '',
        ENCDVF_FECHAEMISION: '',
        ENCDVF_AUTORIZACION: '',
        CLI_TIPOIDE: '',
        CLI_RUCIDE: '',
        CLI_PARTEREL: '',
        CLI_NOMBRE: '',
        TIPOCLI: ''
      };
      this.nombreVendedor = '';
      this.estadoRef = '';
      this.horaSistema = new Date();
      this.venDevFacService.encdevfacarray[this.indicador] = nuevaDevolucion;
      this.venDevFacService.dblPorcDescGlbDevFarray[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES;
      this.venDevFacService.dblValorDescGlbDevFarray[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES;
      this.venDevFacService.detdevfacarray[this.indicador] = [];
      this.claveExportNuevo = 'INS';
      document.getElementById('cliente').focus();
      this.indice = -1;
    } else if (parametro === 'C') { // C para cancelar
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.botonUltimo = false;
      this.botonAnterior = false;
      this.botonPrimero = false;
      this.botonSiguiente = false;
      this.botonBuscar = false;
      this.botonPago = false;
      this.botonAnular = false;
      this.botonAsiento = false;
      this.botonVerAsiento = false;
      this.porcIvaBoolean = true;
      this.llenarcombo()
      const nuevaDevolucion = {
        COM_CODIGO: '01', ENCDVF_FECHAEMISION: new Date(), ENCDVF_FECHAENTREGA: new Date(), ENCDVF_IVA: '1',
        ENCDVF_ESTADO: '', CLI_CODIGO: '', CLI_NOMBRE: '', BOD_CODIGO: '001', VEN_CODIGO: '', ENCDVF_REFERENCIA: '', ENCFFA_CODIGO: '',
        ENCDVF_FORMAPAGO: '', ENCDVF_TIPO: '', ENCDVF_LISTA: 'A', ENCDVF_FECHAVENCIMIENTO: new Date(), ENCDVF_TOTAL: 0, ENCDVF_TOTALNETO: 0,
        ENCDVF_VALORDES: 0, ENCDVF_PORCEDES: 0, ENCDVF_VALORIVA: 0, ENCDVF_PORCEIVA: Number(this.venDevFacService.porcetariiva), ENCDVF_VALORICE: 0, ENCDVF_PORCEICE: 0,
        ENCDVF_BASEIVA: 0, ENCDVF_BASEICE: 0, ENCDVF_BASECERO: 0, ENCDVF_PORCECAR: 0, ENCDVF_VALORCAR: 0, ENCDVF_PORCERTC: 0,
        ENCDVF_VALORRTC: 0, ENCDVF_VALORDEV: 0, ENCDVF_PORCEINT: 0, ENCDVF_VALORINT: 0, ENCDVF_PORCEREC: 0, ENCDVF_VALORREC: 0,
        ENCDVF_IMPRESO: 'N', ENCDVF_COMENTARIO: '', ENCDVF_TIPOFAC: 'S', ENCDVF_GENERAFAC: 'N', ENCDVF_NUMERODOB: '', ENCDVF_DIRECCION: '',
        ENCDVF_TELEFONO: '', ENCDVF_RUCIDE: '', ENCDVF_CHOFER: '', ENCDVF_CARRO: '', ENCDVF_PLACA: '', ENCDVF_OTROS: 0, ASI_NRO: '',
        ENCDVF_CLIDSCT: 0, USU_IDENTIFICACION: this.usuario.identificacion, ENCDVF_TIPODSCTO: '', ENCDVF_FLAG: 0, ENCDVFP_NUMERO: '',
        CLI_ZONA: '', VEN_NOMBRE: '', CLI_OBSERVACION: '', REF_TIPO: '', REF_NOMBRE: '', REF_CODIGO: '', ENCDVF_ESTADO_FE: '',
        ENCDVF_AUTORIZACION_FE: '', ENCDVF_CLAVEACCESO_FE: '', ENCDVF_FECHAAUT_FE: '', ENCDVF_NUMFACTELEC: '',
        COM_TIPOAMBFACTELEC: '', ENCDVF_PATHXMLNOAUTO_FE: '', ENCDVF_PATHPDF_FE: '', ENCDVF_TIPONC: '', SALDO: '',
      };
      this.nombreVendedor = '';
      this.estadoRef = '';
      this.horaSistema = new Date();
      this.venDevFacService.encdevfacarray[this.indicador] = nuevaDevolucion;
      this.venDevFacService.dblPorcDescGlbDevFarray[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES;
      this.venDevFacService.dblValorDescGlbDevFarray[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES;
      this.venDevFacService.detdevfacarray[this.indicador] = [];
    }


  }

  guardarDevolucion() {
    if (this.activeIndex === 0) {
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION.toString().includes('-')) {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION =
          this.datePipe.transform(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION,
            'dd/MM/yyyy');
      }

      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAENTREGA.toString().includes('-')) {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAENTREGA =
          this.datePipe.transform(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAENTREGA,
            'dd/MM/yyyy');
      }

      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAVENCIMIENTO.toString().includes('-')) {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAVENCIMIENTO =
          this.datePipe.transform(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAVENCIMIENTO,
            'dd/MM/yyyy');
      }

      let hora3;
      let minutos3;
      hora3 = this.horaSistema.getHours();
      minutos3 = this.horaSistema.getMinutes();
      if (hora3.toString().length === 1) {
        hora3 = '0' + hora3;
      } else if (minutos3.toString().length === 1) {
        minutos3 = '0' + minutos3;
      }

      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION.toString().includes(':')) {
      } else {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION =
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION + ' ' + hora3 + ':' + minutos3;
      }

      this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTALNETO = this.init.quitarComas(this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTALNETO);
      this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_PORCEDES = this.init.quitarComas(this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_PORCEDES);
      this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORDES = this.init.quitarComas(this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORDES);
      this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEIVA = this.init.quitarComas(this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEIVA);
      this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORIVA = this.init.quitarComas(this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORIVA);
      this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEICE = this.init.quitarComas(this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEICE);
      this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORICE = this.init.quitarComas(this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORICE);
      this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASECERO = this.init.quitarComas(this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASECERO);
      this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTAL = this.init.quitarComas(this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTAL);


      if (this.estadoRef === 'PENDIENTE') {
        if (Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTAL) <=
          Number(this.venDevFacService.encdevfacarray[this.indicador].SALDOFAC)) {
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'G';
        } else {
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'P';
        }
      } else if (this.estadoRef === 'PAGADA') {
        if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PENDIENTE') {
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'P';
        }
      }
      let txtNumero = '';
      if (this.bolNuevo) {
        txtNumero = '';
      } else {
        txtNumero = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO;
      }

      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA = Number(this.venDevFacService.porcetariiva)//this.venDevFacService.dblPorcIvaDevF[this.indicador];
      this.venDevFacService.guardarENCDEV(this.venDevFacService.encdevfacarray[this.indicador],
        txtNumero).subscribe(async () => {
          console.log('111', this.venDevFacService.detdevfacarray[this.indicador])
          let rownum = 0;
          for (const dev of this.venDevFacService.detdevfacarray[this.indicador]){           
          //this.venDevFacService.detdevfacarray[this.indicador].map((dev) =>{
            console.log('111',dev)
            rownum=rownum+1;
            console.log('1112',rownum)
            dev.LINEA = rownum;
            dev.ENCDVF_NUMERO = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO;
            if(dev.DETDVF_TIPODET === '*'){
              dev.DETDVF_PORIVA=0;
              dev.TARIIVA_CODIGO='-1';
            }
            await this.venDevFacService.insertarDetalle(dev).toPromise().catch(e=>{
              this.messageService.add({
                key: 'devolucion',
                severity: 'error',
                summary: 'Inserción de Devolución',
                detail: 'Error al insertar el item '+dev.DETDVF_CODIGO+ ' de la línea '+dev.DETDVF_LINEA + 'por favor verifique y vuelva a guarda.'
              });
              return;
            })//.subscribe(() => {
              if (dev.DETDVF_LINEA === 0) {
                dev.DETDVF_LINEA = dev.LINEA;
                this.auditoriagral.registrarAuditoria('VEN_DETDVF', String(dev.ENCDVF_NUMERO)+'/'+dev.DETDVF_CODIGO, 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  this.auditoriagral.registrarAuditoria('VEN_DETDVF', String(dev.ENCDVF_NUMERO)+'/'+dev.DETDVF_CODIGO + '/' 
                  +'PorIva:'+ dev.DETDVF_PORIVA + '/' + 'CodTarIva:'+dev.TARIIVA_CODIGO, 'I', 
                  '', '01', '', '', '', '').subscribe(() => {
                  });
              } else if (dev.DETDVF_LINEA !== 0) {
                this.auditoriagral.registrarAuditoria('VEN_DETDVF', String(dev.ENCDVF_NUMERO)+'/'+dev.DETDVF_CODIGO, 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  this.auditoriagral.registrarAuditoria('VEN_DETDVF', String(dev.ENCDVF_NUMERO)+'/'+dev.DETDVF_CODIGO + '/' 
                  +'PorIva:'+ dev.DETDVF_PORIVA + '/' + 'CodTarIva:'+dev.TARIIVA_CODIGO, 'A', 
                  '', '01', '', '', '', '').subscribe(() => {
                  });
              }
            //});


          } //);
          if (this.bolNuevo) {
            this.messageService.add({
              key: 'devolucion',
              severity: 'success',
              summary: 'Inserción de Devolución',
              detail: 'La devolución se insertó exitosamente'
            });
          } else {
            this.messageService.add({
              key: 'devolucion',
              severity: 'success',
              summary: 'Actualización de Devolución',
              detail: 'La devolución se actualizó exitosamente'
            });
          }

          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.botonBuscar = false;
          this.botonPago = false;
          this.botonAnular = false;
          this.botonAsiento = false;
          this.botonVerAsiento = false;
          this.porcIvaBoolean = true;
          this.edittxtnumero = false;
          if (this.bolNuevo) {
            this.auditoriagral.registrarAuditoria('VEN_ENCDVF', String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO)
              , 'I', '', '01', '', '', '', '').subscribe(() => {
              });
          } else {
            this.auditoriagral.registrarAuditoria('VEN_ENCDVF', String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO)
              , 'A', '', '01', '', '', '', '').subscribe(() => {
              });
          }
          this.venDevFacService.obtenerSaldoCliente(this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO).subscribe((res) => {
            this.venDevFacService.encdevfacarray[this.indicador].SALDO = Number(res[0].SALDO).toFixed(2);
          });
          if (this.bolNuevo) {
            this.venDevFacService.actualizarNumeracion(this.venDevFacService.encdevfacarray[this.indicador]).subscribe();
          }
          this.venDevFacService.eliminarTrnDoc(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO, '04').subscribe(() => {
          });
          this.venDevFacService.actualizarEstFac(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA,
            this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO).subscribe(() => {
              this.venDevFacService.encontrarEncfacEstado(
                this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA).subscribe((res) => {
                  if (res[0].ENCFAC_ESTADO === 'P') {
                    this.estadoRef = 'PENDIENTE';
                  } else if (res[0].ENCFAC_ESTADO === 'G') {
                    this.estadoRef = 'PAGADA';
                  }
                });
            });
            this.venDevFacService.ingDatosAdicCli(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe();///GSRF
          this.venDevFacService.actualizarKardex(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe(() => {
            if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'P') {
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'PENDIENTE';
              this.columnDefsDVFDetalle.map((columna) => {
                //GSRF
                if(columna.field !== 'DETDVF_UNIDAD'){
                  columna.editable = true;
                }
                //GSRF
              });
              this.aggrid.refreshColumnDefs();
              this.editarCeldas = false;
              this.tipodetalleactivo = 'all';
            } else if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'G') {
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'PAGADA';
              this.columnDefsDVFDetalle.map((columna) => {
                columna.editable = false;
              });
              this.aggrid.refreshColumnDefs();
              this.editarCeldas = true;
              this.tipodetalleactivo = 'none';
              this.venDevFacService.generarAsiento(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe(() => {
                this.messageService.add({
                  key: 'devolucion',
                  severity: 'success',
                  summary: 'Asiento Contable',
                  detail: 'Se generó el asiento contable con éxito'
                });
                this.venDevFacService.getEncDevolucionNum(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((asi) => {
                  this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO = asi[0].ASI_NRO;
                });
              });
              document.getElementById('divPrincipal').style.pointerEvents = 'none';
              // document.getElementById('divPrincipal').style.opacity = '0.7';
              this.botonAnular = true;
              this.btnBorrar = true;
              this.validacionbotonborrar();
            }
          });
          // this.venDevFacService.getEncDevF().subscribe((res1) => {
          //   this.encabezadosDevF = res1;
          // });
          // this.getPestanias();
          this.infoadicional(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO);//GSRF
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          if (this.bolNuevo) {
            this.messageService.add({
              key: 'devolucion',
              severity: 'error',
              summary: 'Inserción de Devolución',
              detail: mensaje
            });
          } else {
            this.messageService.add({
              key: 'devolucion',
              severity: 'error',
              summary: 'Actualización de Devolución',
              detail: mensaje
            });
          }
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.edittxtnumero = false;
        });
    } else if (this.activeIndex === 3) {
      if (this.claveExportNuevo === 'INS') {
        this.exportacionNuevoSeleccionado.FECHAEMBARQUE = new Date();
        this.exportacionNuevoSeleccionado.FECHAEMBARQUE = this.datePipe.transform(this.exportacionNuevoSeleccionado.FECHAEMBARQUE, 'dd/MM/yyyy');
        if (this.booleanRegimen === false) {
          this.exportacionNuevoSeleccionado.PAISEFECPAGOGEN = this.codigoPaisRegimen;
        } else if (this.booleanParaiso === false) {
          this.exportacionNuevoSeleccionado.PAISEFECPAGOGEN = this.codigoPaisParaiso;
        } else if (this.booleanRegimenP === false) {
          this.exportacionNuevoSeleccionado.PAISEFECPAGOGEN = this.exportacionNuevoSeleccionado.PAISEFECEXP;
        }
        this.venDevFacService.guardarExportNuevo(this.exportacionNuevoSeleccionado, this.claveExportNuevo).subscribe(() => {
          this.messageService.add({
            key: 'devolucion',
            severity: 'success',
            summary: 'Inserción de Exportación',
            detail: 'Se ingreso la nueva exportación correctamente'
          });
          this.exportacionNuevoSeleccionado.FECHAEMBARQUE = new Date();
          this.auditoriagral.registrarAuditoria('VEN_EXPODVF', String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO) + '/', 'I',
            '', '01', '', '', '', '').subscribe(() => {
            });
          this.claveExportNuevo = 'ACT';
        });
      } else if (this.claveExportNuevo === 'ACT') {
        if (this.exportacionNuevoSeleccionado.FECHAEMBARQUE.toString().includes('-')) {
          this.exportacionNuevoSeleccionado.FECHAEMBARQUE = this.datePipe.transform(this.exportacionNuevoSeleccionado.FECHAEMBARQUE, 'dd/MM/yyyy');
        }
        if (this.booleanRegimen === false) {
          this.exportacionNuevoSeleccionado.PAISEFECPAGOGEN = this.codigoPaisRegimen;
        } else if (this.booleanParaiso === false) {
          this.exportacionNuevoSeleccionado.PAISEFECPAGOGEN = this.codigoPaisParaiso;
        } else if (this.booleanRegimenP === false) {
          this.exportacionNuevoSeleccionado.PAISEFECPAGOGEN = this.exportacionNuevoSeleccionado.PAISEFECEXP;
        }
        this.venDevFacService.guardarExportNuevo(this.exportacionNuevoSeleccionado, this.claveExportNuevo).subscribe(() => {
          this.messageService.add({
            key: 'devolucion',
            severity: 'success',
            summary: 'Actualización de Exportación',
            detail: 'Se actualizó la nueva exportación correctamente'
          });
          this.auditoriagral.registrarAuditoria('VEN_EXPODVF', String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO) + '/', 'A',
            '', '01', '', '', '', '').subscribe(() => {
            });
        });
      }
    }
  }

  confirmarDev() {
    ///////cambio autorizacion
    console.log(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE);
    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== '' &&
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== null) {
        return this.messageService.add({
          key: 'devolucionConf',
          severity: 'error',
          summary: 'Error',
          detail: 'No se puede eliminar una devolución'
          + ' que tiene autorización.'
        });
    }else{
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la devolución',
      header: 'Eliminar Devolución',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDevolucion();
      },
      reject: () => {
      }
    });
    }
  }

  confirmarExportDev() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la exportación',
      header: 'Eliminar Exportación',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarExport();
      },
      reject: () => {
      }
    });
  }

  eliminarExport() {
    this.venDevFacService.eliminarExportNuevo(this.exportacionNuevoSeleccionado).subscribe(() => {
      this.messageService.add({
        key: 'devolucion',
        severity: 'success',
        summary: 'Eliminación de Exportación',
        detail: 'Exportación  eliminada correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_EXPODVF', String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO) + '/',
        'E', '', '01', '', '', '', '').subscribe(() => {
        });
      this.exportacionNuevoSeleccionado = {
        ANIO: '',
        COM_CODIGO: '01',
        COMERCIOEXTERIOR: '',
        CORRELATIVO: '',
        DENOEXPCLI: '',
        DIRCOMPRADOR: '',
        DISTADUANERO: '',
        DOCTRANSP: '',
        ENCDVF_NUMERO: this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO,
        EXPORTACIONDE: '',
        FECHAEMBARQUE: new Date(),
        FLETEINTERNACIONAL: '',
        FORMAPAGO: '',
        GASTOSADUANEROS: '',
        GASTOSTRANSPORTEOTROS: '',
        INCOTERMFACTURA: '',
        INCOTERMTOTALSINIMPUESTOS: '',
        INGGRAVADOEXT: '',
        LUGARINCOTERM: '',
        PAISADQUISICION: '',
        PAISDESTINO: '',
        PAISEFECEXP: '',
        PAISEFECPAGOGEN: '',
        PAISORIGEN: '',
        PARTERELEXP: '',
        PUERTODESTINO: '',
        PUERTOEMBARQUE: '',
        REGIMEN: '',
        SEGUROINTERNACIONAL: '',
        TIPOCOMPROBANTE: '',
        TIPOINGRESO: '',
        TIPOREGI: '',
        TPIDCLIENTEEX: '',
        VALORFOB: '',
        VALORFOBCOMPROBANTE: '',
        VALORIMPEXT: '',
        PUNTOEMISION: '',
        ESTABLECIMIENTO: '',
        ENCDVF_SECACTUAL: '',
        ENCDVF_FECHAEMISION: '',
        ENCDVF_AUTORIZACION: '',
        CLI_TIPOIDE: '',
        CLI_RUCIDE: '',
        CLI_PARTEREL: '',
        CLI_NOMBRE: '',
        TIPOCLI: ''
      };
      this.nombrePaisSeleccionado = '';
      this.codigoPaisParaiso = '';
      this.codigoPaisRegimen = '';
      this.booleanParaiso = true;
      this.booleanImpuestoRenta = true;
      this.booleanRefrendo = true;
      this.booleanRegimen = true;
      this.booleanRegimenP = true;
      this.claveExportNuevo = 'INS';
    });
  }

  eliminarDevolucion() {
    if (this.venDevFacService.encdevfacarray[this.indicador] !== undefined) {
      this.venDevFacService.eliminarEnc(this.venDevFacService.encdevfacarray[this.indicador]).subscribe(() => {
        this.messageService.add({
          key: 'devolucion',
          severity: 'success',
          summary: 'Eliminación de Devolución',
          detail: 'Devolución  eliminada correctamente'
        });
        if (this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO !== '' && this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO !== null) {
          this.venDevFacService.anularAsiento(this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO).subscribe(() => {
            this.venDevFacService.actualizarAsiNro(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe(() => {
            });
          });
        }
        this.venDevFacService.actualizarEstFac(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA, this.venDevFacService
          .encdevfacarray[this.indicador].CLI_CODIGO).subscribe(() => {
          });
        this.venDevFacService.eliminarSeriales(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO, 'DVFVEN').subscribe(() => {
        });
        this.auditoriagral.registrarAuditoria('VEN_ENCDVF', String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO),
          'E', '', '01', '', '', '', '').subscribe(() => {
          });
        this.venDevFacService.detdevfacarray[this.indicador].map(data => {
          this.auditoriagral.registrarAuditoria('VEN_DETDVF', String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO)+'/'+data.DETDVF_CODIGO
            , 'E', '', '01', '', '', '', '').subscribe(() => {
            });
        });
        this.limpiarDatos();
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        // this.venDevFacService.getEncDevF().subscribe((res1) => {
        //   this.encabezadosDevF = res1;
        // });
      });
    } else {
      this.messageService.add({
        key: 'devolucion',
        severity: 'error',
        summary: 'Eliminación de Devolución',
        detail: 'Seleccione una devolución para ser eliminada'
      });
    }
  }

  cancelarEdicionDevolucion() {
    // //CDPJ
    // this.venDevFacService.getEncDevF().subscribe((res) => {
    //   this.encabezadosDevF = res;
    // });
    // //CDPJ
    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO !== '' &&
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO !== undefined) {
      this.venDevFacService.getEncDevolucionNum(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((res) => {
        if (res.length === 0) {
          this.limpiarDatos();
          this.nuevaDVF('C');
        } else {
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.botonAnterior = false;
          this.botonPrimero = false;
          this.botonUltimo = false;
          this.botonSiguiente = false;
          this.botonBuscar = false;
          this.botonPago = false;
          this.botonAnular = false;
          this.botonAsiento = false;
          this.botonVerAsiento = false;
          this.venDevFacService.encdevfacarray[this.indicador] = res[0];
          this.obtenerDatos(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO);
        }
      });
    } else {
      this.limpiarDatos();
      this.nuevaDVF('C');
    }
  }

  abrirClave(accion) {
    this.closeSidebar();
    this.claveAuth = '';
    this.accionClave = accion;
    this.claveDialog = true;
  }

  comprobarClave() {
    this.venDevFacService.obtenerClave(this.accionClave).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.claveDialog = false;
        if (this.accionClave === 'ANULADVF') {
          this.confirmarAnularDev();
        } else if (this.accionClave === 'firmarDoc') {
          this.confirmarFirmar();
        }else if (this.accionClave === 'ACTDOC'){
          this.confirmarActiDevAnu()
        }
      }
    });
  }
//CDPJ
confirmarActiDevAnu(){
  this.venDevFacService.actualizarEstadoDocumento(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe(() => {
    
    this.auditoriagral.registrarAuditoria('VEN_ENCDVF', this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO + '/' + 'ACTIVA', 'A',
      '', '01', '', '', '', '').subscribe();
    this.messageService.add({
      key: 'devolucion',
      severity: 'warn',
      summary: 'Información',
      detail: 'Tiene que volver a grabar la devolución para que se registre en cartera activa, ' 
    });

    this.cancelarEdicionDevolucion();

    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO !== '') {
      this.botonAnular = false;
    }
  });
}
//CDPJ
  confirmarAnularDev() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea anular la devolución',
      header: 'Anular Devolución',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.anularDev();
      },
      reject: () => {
      }
    });
  }

  anularDev() {
    this.venDevFacService.anularDev(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe(() => {
      if (this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO !== '' && this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO !== null) {
        this.venDevFacService.anularAsiento(this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO).subscribe(() => {
        });
      }
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== '' ||
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== null) {
        this.auditoriagral.registrarAuditoria('VEN_ENCDVF',
          String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO) +
          '/DEV-ELE/VEN', 'Anular', '', '01', '',
          '', '', '').subscribe(() => {
          });
      } else {
        this.auditoriagral.registrarAuditoria('VEN_ENCDVF',
          String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO) +
          '/VEN', 'Anular', '', '01', '',
          '', '', '').subscribe(() => {
          });
      }
      this.venDevFacService.eliminarSeriales(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO, 'DVFVEN').subscribe(() => {
      });
      this.venDevFacService.actualizarEstFac(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA, this.venDevFacService
        .encdevfacarray[this.indicador].CLI_CODIGO).subscribe(() => {
        });
      this.messageService.add({
        key: 'devolucion',
        severity: 'success',
        summary: 'Anular Devolución',
        detail: 'Se anulo la devolución'
      });
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'ANULADA';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      //  document.getElementById('divPrincipal').style.opacity = '0.7';
      this.columnDefsDVFDetalle.map((columna) => {
        columna.editable = false;
      });
      this.aggrid.refreshColumnDefs();
      this.editarCeldas = true;
      this.tipodetalleactivo = 'none';
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      this.botonAnular = true;
      this.botonXML = true;
      this.botonAsiento = true;
      this.botonPago = true;
      this.btnBorrar = true;
      this.validacionbotonborrar();
    });
  }

  async obtenerDatos(encdvfnum) {
    this.bolNuevo = false;
    this.edittxtnumero = false;
    this.bolestcta = false;
    this.bolsaldo = false;
    //CDPJ
    let resp = [];
    resp = await this.venDevFacService.getEncdvf(encdvfnum);
    if (resp === null) {
      this.init.disabledtab = false;
      return;
    }
    //CDPJ
    for (const data of resp) {
      //data.ENCDVF_FECHAEMISION = new Date(data.ENCFAC_FECHAEMISION);
      this.venDevFacService.encdevfacarray[this.indicador] = data;    
      this.venDevFacService.mostrarExportarNuevo(data.ENCDVF_NUMERO).subscribe((res) => {
        this.spin=true;
        if (res.length !== 0) {
          console.log(res);
          this.exportacionNuevoSeleccionado = {
            ANIO: res[0].ANIO,
            COM_CODIGO: '01',
            COMERCIOEXTERIOR: '',
            CORRELATIVO: res[0].CORRELATIVO,
            DENOEXPCLI: res[0].DENOEXPCLI,
            DIRCOMPRADOR: '',
            DISTADUANERO: res[0].DISTADUANERO,
            DOCTRANSP: res[0].DOCTRANSP,
            ENCDVF_NUMERO: this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO,
            EXPORTACIONDE: res[0].EXPORTACIONDE,
            FECHAEMBARQUE: res[0].FECHAEMBARQUE.toString(),
            FLETEINTERNACIONAL: '',
            FORMAPAGO: '',
            GASTOSADUANEROS: '',
            GASTOSTRANSPORTEOTROS: '',
            INCOTERMFACTURA: '',
            INCOTERMTOTALSINIMPUESTOS: '',
            INGGRAVADOEXT: res[0].INGGRAVADOEXT,
            LUGARINCOTERM: '',
            PAISADQUISICION: '',
            PAISDESTINO: '',
            PAISEFECEXP: res[0].PAISEFECEXP,
            PAISEFECPAGOGEN: res[0].PAISEFECPAGOGEN,
            PAISORIGEN: '',
            PARTERELEXP: '',
            PUERTODESTINO: '',
            PUERTOEMBARQUE: '',
            REGIMEN: res[0].REGIMEN,
            SEGUROINTERNACIONAL: '',
            TIPOCOMPROBANTE: res[0].TIPOCOMPROBANTE,
            TIPOINGRESO: res[0].TIPOINGRESO,
            TIPOREGI: res[0].TIPOREGI,
            TPIDCLIENTEEX: '',
            VALORFOB: res[0].VALORFOB,
            VALORFOBCOMPROBANTE: res[0].VALORFOBCOMPROBANTE,
            VALORIMPEXT: res[0].VALORIMPEXT,
            CLI_NOMBRE: res[0].CLI_NOMBRE,
            CLI_PARTEREL: res[0].CLI_PARTEREL,
            CLI_RUCIDE: res[0].CLI_RUCIDE,
            CLI_TIPOIDE: res[0].CLI_TIPOIDE,
            ENCDVF_AUTORIZACION: res[0].ENCDVF_AUTORIZACION,
            ENCDVF_FECHAEMISION: res[0].ENCDVF_FECHAEMISION,
            ENCDVF_SECACTUAL: res[0].ENCDVF_SECACTUAL,
            ESTABLECIMIENTO: res[0].ESTABLECIMIENTO,
            PUNTOEMISION: res[0].PUNTOEMISION,
            TIPOCLI: res[0].TIPOCLI
          };
          console.log(this.exportacionNuevoSeleccionado);
          if (this.exportacionNuevoSeleccionado.REGIMEN === '01') {
            this.booleanRegimen = false;
            this.booleanRegimenP = true;
            this.booleanParaiso = true;
            this.codigoPaisRegimen = this.exportacionNuevoSeleccionado.PAISEFECPAGOGEN;
            this.venDevFacService.encontrarCodSriDescipcion('codsri_codigo = \'' +
              this.exportacionNuevoSeleccionado.PAISEFECPAGOGEN + '\' ' + 'AND CODSRI_TIPO = \'RG\'').subscribe((cons) => {
                this.nombrePaisSeleccionado = cons[0].CODSRI_DESCRIPCION;
              });
          } else if (this.exportacionNuevoSeleccionado.REGIMEN === '02') {
            this.booleanParaiso = false;
            this.booleanRegimen = true;
            this.booleanRegimenP = true;
            this.codigoPaisParaiso = this.exportacionNuevoSeleccionado.PAISEFECPAGOGEN;
            this.venDevFacService.encontrarCodSriDescipcion('codsri_codigo = \'' +
              this.exportacionNuevoSeleccionado.PAISEFECPAGOGEN + '\' ' + 'AND CODSRI_TIPO = \'PG\'').subscribe((cons) => {
                this.nombrePaisSeleccionado = cons[0].CODSRI_DESCRIPCION;
              });
          } else if (this.exportacionNuevoSeleccionado.REGIMEN === '03') {
            this.booleanParaiso = true;
            this.booleanRegimen = true;
            this.booleanRegimenP = false;
            this.venDevFacService.encontrarCodSriDescipcion('codsri_codigo = \'' +
              this.exportacionNuevoSeleccionado.PAISEFECEXP + '\' ' + 'AND CODSRI_TIPO = \'PA\'').subscribe((cons) => {
                this.nombrePaisSeleccionado = cons[0].CODSRI_DESCRIPCION;
              });
          }
          if (this.exportacionNuevoSeleccionado.EXPORTACIONDE === '01') {
            this.exportacionNuevoSeleccionado.TIPOINGRESO = '';
            this.exportacionNuevoSeleccionado.INGGRAVADOEXT = '';
            this.booleanRefrendo = true;
            this.booleanImpuestoRenta = true;
          } else if (this.exportacionNuevoSeleccionado.EXPORTACIONDE === '02') {
            this.exportacionNuevoSeleccionado.TIPOINGRESO = '';
            this.exportacionNuevoSeleccionado.INGGRAVADOEXT = '';
            this.exportacionNuevoSeleccionado.DOCTRANSP = '';
            this.exportacionNuevoSeleccionado.DISTADUANERO = '';
            this.exportacionNuevoSeleccionado.ANIO = '';
            this.exportacionNuevoSeleccionado.TIPOREGI = '';
            this.exportacionNuevoSeleccionado.CORRELATIVO = '';
            this.booleanImpuestoRenta = true;
            this.booleanRefrendo = true;
          } else if (this.exportacionNuevoSeleccionado.EXPORTACIONDE === '03') {
            this.booleanRefrendo = false;
            if (this.exportacionNuevoSeleccionado.INGGRAVADOEXT === 'SI') {
              this.booleanImpuestoRenta = true;
            }
            this.exportacionNuevoSeleccionado.DOCTRANSP = '';
            this.exportacionNuevoSeleccionado.DISTADUANERO = '';
            this.exportacionNuevoSeleccionado.ANIO = '';
            this.exportacionNuevoSeleccionado.TIPOREGI = '';
            this.exportacionNuevoSeleccionado.CORRELATIVO = '';
          }
          this.claveExportNuevo = 'ACT';
        } else if (res.length === 0) {
          this.exportacionNuevoSeleccionado = {
            ANIO: '',
            COM_CODIGO: '01',
            COMERCIOEXTERIOR: '',
            CORRELATIVO: '',
            DENOEXPCLI: '',
            DIRCOMPRADOR: '',
            DISTADUANERO: '',
            DOCTRANSP: '',
            ENCDVF_NUMERO: encdvfnum,
            EXPORTACIONDE: '',
            FECHAEMBARQUE: new Date(),
            FLETEINTERNACIONAL: '',
            FORMAPAGO: '',
            GASTOSADUANEROS: '',
            GASTOSTRANSPORTEOTROS: '',
            INCOTERMFACTURA: '',
            INCOTERMTOTALSINIMPUESTOS: '',
            INGGRAVADOEXT: '',
            LUGARINCOTERM: '',
            PAISADQUISICION: '',
            PAISDESTINO: '',
            PAISEFECEXP: '',
            PAISEFECPAGOGEN: '',
            PAISORIGEN: '',
            PARTERELEXP: '',
            PUERTODESTINO: '',
            PUERTOEMBARQUE: '',
            REGIMEN: '',
            SEGUROINTERNACIONAL: '',
            TIPOCOMPROBANTE: '',
            TIPOINGRESO: '',
            TIPOREGI: '',
            TPIDCLIENTEEX: '',
            VALORFOB: '',
            VALORFOBCOMPROBANTE: '',
            VALORIMPEXT: '',
            PUNTOEMISION: '',
            ESTABLECIMIENTO: '',
            ENCDVF_SECACTUAL: '',
            ENCDVF_FECHAEMISION: '',
            ENCDVF_AUTORIZACION: '',
            CLI_TIPOIDE: '',
            CLI_RUCIDE: '',
            CLI_PARTEREL: '',
            CLI_NOMBRE: '',
            TIPOCLI: ''
          };
          this.claveExportNuevo = 'INS';
        }
      });
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'P' ||
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PENDIENTE') {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'PENDIENTE';
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        // document.getElementById('divPrincipal').style.opacity = '1.0';
        this.columnDefsDVFDetalle.map((columna) => {
          //GSRF
          if(columna.field !== 'DETDVF_UNIDAD'){
            columna.editable = true;
          }
          //GSRF
        });
        this.aggrid.refreshColumnDefs();
        this.editarCeldas = false;
        this.tipodetalleactivo = 'all';
        this.btnNuevoGrid = false;
        this.btnGuardarGrid = false;
        this.btnCancelarGrid = false;
        this.btnBorrarGrid = false;
        this.botonAnular = false;
        this.botonXML = false;
        //CDPJ
        if(this.CNAFEPV === 1){
          const data= await this.venDevFacService.obtenerNumFE(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO)
           if(data !== null && data !== undefined){
             for(const rs of data){
               if(Number(rs.NUM_FE) === 1){
                 this.botonXML = false;
               }else if(Number(rs.NUM_FE) === 0) {
                 this.botonXML = true;
               }
             }
           }else{
             this.botonXML = false;
           }
         }
         //CDPJ
        this.botonAsiento = false;
        this.botonPago = false;
        this.btnBorrar = false;
      } else if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'G' ||
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PAGADA') {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'PAGADA';
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        // document.getElementById('divPrincipal').style.opacity = '0.7';
        this.columnDefsDVFDetalle.map((columna) => {
          columna.editable = false;
        });
        this.aggrid.refreshColumnDefs();
        this.editarCeldas = true;
        this.tipodetalleactivo = 'none';
        this.btnNuevoGrid = true;
        this.btnGuardarGrid = true;
        this.btnCancelarGrid = true;
        this.btnBorrarGrid = true;
        this.botonAnular = true;
        this.botonXML = false;
        //CDPJ
        if(this.CNAFEPV === 1){
          const data= await this.venDevFacService.obtenerNumFE(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO)
           if(data !== null && data !== undefined){
             for(const rs of data){
               if(Number(rs.NUM_FE) === 1){
                 this.botonXML = false;
               }else if(Number(rs.NUM_FE) === 0) {
                 this.botonXML = true;
               }
             }
           }else{
             this.botonXML = false;
           }
         }
        //CDPJ
        this.botonAsiento = true;
        this.botonPago = false;
        this.btnBorrar = true;
        this.validacionbotonborrar();
      } else if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'A' ||
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'ANULADA') {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'ANULADA';
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        // document.getElementById('divPrincipal').style.opacity = '0.7';
        this.columnDefsDVFDetalle.map((columna) => {
          columna.editable = false;
        });
        this.aggrid.refreshColumnDefs();
        this.editarCeldas = true;
        this.tipodetalleactivo = 'none';
        this.btnNuevoGrid = true;
        this.btnGuardarGrid = true;
        this.btnCancelarGrid = true;
        this.btnBorrarGrid = true;
        this.botonAnular = true;
        this.botonXML = true;
        this.botonAsiento = true;
        this.botonPago = true;
        this.btnBorrar = true;
        this.validacionbotonborrar();
      }
      //GSRF
      if ( this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== '' &&
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== null && 
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== undefined) {
        console.log('ENTRA-*-*-*',this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE)
      //this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'PAGADA';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal').style.opacity = '0.7';
      this.columnDefsDVFDetalle.map((columna) => {
        columna.editable = false;
      });
      this.aggrid.refreshColumnDefs();
      this.editarCeldas = true;
      this.tipodetalleactivo = 'none';
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      //this.botonAnular = false;
      this.botonXML = false;
      if(this.CNAFEPV === 1){
        const data= await this.venDevFacService.obtenerNumFE(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO)
         if(data !== null && data !== undefined){
           for(const rs of data){
             if(Number(rs.NUM_FE) === 1){
               this.botonXML = false;
             }else if(Number(rs.NUM_FE) === 0) {
               this.botonXML = true;
             }
           }
         }else{
           this.botonXML = false;
         }
       }
      //this.botonAsiento = false;
      this.botonPago = false;
      this.btnBorrar = true;
     // this.validacionbotonborrar();
    }
      //GSRF
      this.valorDscValor = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES;
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES =
        Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES).toFixed(this.numdecsist);
      this.venDevFacService.dblValorDescGlbDevFarray[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES;
      this.valorDscPorcentaje = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES;
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES =
        Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES).toFixed(this.numdecsist);
      this.venDevFacService.dblPorcDescGlbDevFarray[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES;
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORIVA =
        Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORIVA).toFixed(this.numdecsist);
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_BASEIVA =
        Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_BASEIVA).toFixed(this.numdecsist);
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORICE =
        Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORICE).toFixed(this.numdecsist);
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_BASEICE =
        Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_BASEICE).toFixed(this.numdecsist);
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_BASECERO =
        Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_BASECERO).toFixed(this.numdecsist);
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTALNETO =
        Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTALNETO).toFixed(this.numdecsist);
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTAL =
        Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTAL).toFixed(this.numdecsist);
      this.venDevFacService.encdevfacarray[this.indicador].SALDO =
        Number(this.venDevFacService.encdevfacarray[this.indicador].SALDO).toFixed(2);
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORIRBPNR =
        Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORIRBPNR).toFixed(this.numdecsist);
      //CDPJ
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== '' &&
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== null) {
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
          // document.getElementById('contornodetalle').style.pointerEvents = 'none';
          this.aggrid.bloquearGrid();
          //this.botonAnular = true;
        } else if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE === '' ||
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE === null) {
          //this.botonAnular = false;
        }
  
      //CDPJ
      this.venDevFacService.comas();
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA !== null) {
        this.venDevFacService.obtenerEstadoReferencia(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA).subscribe
          ((estado) => {
            if (estado !== null) {
              if (estado[0].ENCFAC_ESTADO === 'P') {
                this.estadoRef = 'PENDIENTE';
              } else if (estado[0].ENCFAC_ESTADO === 'G') {
                this.estadoRef = 'PAGADA';
              } else if (estado[0].ENCFAC_ESTADO === 'A') {
                this.estadoRef = 'ANULADA';
              }
            }
          });
      }
      if (this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO !== null) {
        this.venDevFacService.obtenerNombreVen(this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO).subscribe((res1) => {
          this.nombreVendedor = res1[0].VEN_NOMBRE;
        });
      } else {
        this.nombreVendedor = '';
      }
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION =
        new Date(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION);
      this.horaSistema = new Date(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION);   
  
      this.venDevFacService.dblPorcIvaDevF[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA;
      //CDPJ
      for (const j of this.cmbporceiva) {
        if (Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA) === Number(j.name)) {
          this.selectcombtipoIva = { codigo: j.codigo, name: j.name };
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA=Number(this.selectcombtipoIva.name)
        }
      }
      let porcentaje=this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA
      let data1=this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(porcentaje))
      this.venDevFacService.porcetariiva=Number(porcentaje);
      this.venDevFacService.codtariiva=data1.TARIIVA_CODIGO;
      //CDPJ
  
  
  
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO !== '') {
        this.venDevFacService.getDetDevF(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((res) => {
          if (res !== null && res.length !== 0) {
            this.venDevFacService.detdevfacarray[this.indicador] = res;
            this.venDevFacService.detdevfacarray[this.indicador].map((pedido) => {
              const opcPedido = [];
              const opcLista = [];
              opcPedido.push(pedido.DETDVF_UNIDAD);
              this.columnDefsDVFDetalle[5].cellEditorParams = {
                values: opcPedido
              };
              this.aggrid.refreshColumnDefs();
              pedido.DETDVF_PRECIO = pedido.DETDVF_PRECIO.toFixed(this.decimalesPrecioDVF);
              pedido.DETDVF_CANTIDAD = pedido.DETDVF_CANTIDAD.toFixed(this.decimalesCantidadDVF);
              pedido.DETDVF_TOTAL = Number(pedido.DETDVF_TOTAL).toFixed(this.decimalesTotalDVF);
              pedido.DETDVF_DESCUENTO = Number(pedido.DETDVF_DESCUENTO).toFixed(this.decimalesDescDVF);
              pedido.LINEA = pedido.DETFAC_LINEA;
              this.utilitariosService.getListaPrecioArt(pedido.DETDVF_CODIGO).subscribe((res1) => {
                res1.map((ped) => {
                  opcLista.push(ped.ARTPRE_CODIGO);
                });
                this.columnDefsDVFDetalle[10].cellEditorParams = {
                  values: opcLista
                };
                this.aggrid.refreshColumnDefs();
              });
            });
            this.detalleDVFSeleccionado = this.venDevFacService.detdevfacarray[this.indicador][0];
          }
        });
      }
      // this.getPestanias();
      this.infoadicional(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO);//GSRF
    }

    
  }


  numeracionSeleccionada(valor) {
    this.numeracionActual = valor;
    this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_GRUPO = this.numeracionActual.NUM_CLASE;
    this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO = String(this.numeracionActual.NUM_NEMONICO).trim() +
      String(this.numeracionActual.NUM_SERIE).trim() + String(this.numeracionActual.NUM_SECACTUAL).trim();
    this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_AUTORIZACION = String(this.numeracionActual.NUM_AUTORIZACION).trim();
    this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_SECACTUAL = String(this.numeracionActual.NUM_SECACTUAL).trim();
    this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_SERIE = String(this.numeracionActual.NUM_SERIE).trim();
    this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NEMONICO = String(this.numeracionActual.NUM_NEMONICO).trim();
    //CDPJ
    if(this.CNAFEPV === 1){
      this.numeracionActual.NUM_FE=this.numeracionActual.NUM_FE === null || this.numeracionActual.NUM_FE === undefined?0:this.numeracionActual.NUM_FE
      console.log(this.numeracionActual.NUM_FE)
          if(this.numeracionActual.NUM_FE === 1){
            this.botonXML = false;
          }else{
            this.botonXML = true;
          }
    }
    //CDPJ
    this.numeracionDialog = false;
    document.getElementById('cliente').focus();
  }

  buscarDevoluciones() {
    this.opcion = 'BUSCAR';
    this.types = [
      { label: 'NÚMERO', value: 'ENCDVF_NUMERO' },
      { label: 'FEC. EMISIÓN', value: 'ENCDVF_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCDVF_ESTADO' },
      { label: 'NOMBRE CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'COD CLIENTE', value: 'CLI_CODIGO' },
      { label: 'BODEGA', value: 'BOD_CODIGO' },
      { label: 'VENDEDOR', value: 'VEN_CODIGO' },
      { label: 'TOTAL', value: 'ENCDVF_TOTAL' },
      { label: 'TOTAL NETO', value: 'ENCDVF_TOTALNETO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCDVF';
    this.consulta = 'ENCDVF_FECHAEMISION, ENCDVF_NUMERO, ENCDVF_ESTADO,' +
      'BOD_CODIGO, CLI_CODIGO, VEN_CODIGO, ENCDVF_TOTAL, ENCDVF_TOTALNETO, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE';
    this.where = 'ENCDVF_NUMERO IS NOT NULL ORDER BY ENCDVF_FECHAEMISION DESC';
    this.busquedaSer.busquedaVenEncDVF1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((registro) => {
        if (registro.ENCDVF_FECHAEMISION !== null) {
          registro.ENCDVF_FECHAEMISION = this.datePipe.transform(registro.ENCDVF_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCDVF_FECHAEMISION = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  //GSRF
  buscarDevolucionesnofirma() {
    this.opcion = 'BUSCAR';
    this.types = [
      { label: 'FEC. EMISIÓN', value: 'ENCDVF_FECHAEMISION' },
      { label: 'ESTADO_FE', value: 'ENCDVF_ESTADO_FE' },
      { label: 'NÚMERO', value: 'ENCDVF_NUMERO' },
      { label: 'CLAVE_ACCESO', value: 'ENCDVF_CLAVEACCESO_FE' },
      { label: 'COD CLIENTE', value: 'CLI_CODIGO' },
      { label: 'TOTAL', value: 'ENCDVF_TOTAL' },
    ];
    this.busquedacampos = ['', '', '', '', '', ''];
    this.tabla = 'VEN_ENCDVF';
    this.consulta = 'ENCDVF_FECHAEMISION, ENCDVF_NUMERO, ENCDVF_ESTADO,' +
      'BOD_CODIGO, CLI_CODIGO, VEN_CODIGO, ENCDVF_TOTAL, ENCDVF_TOTALNETO, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE,ENCDVF_ESTADO_FE,ENCDVF_CLAVEACCESO_FE';
    this.where = 'ENCDVF_NUMERO IS NOT NULL and (ENCDVF_ESTADO_FE NOT IN (\'NT\',\'SN\') or ENCDVF_ESTADO_FE is null)and ENCDVF_ESTADO !=\'A\' ORDER BY ENCDVF_FECHAEMISION DESC';
    this.busquedaSer.busquedaVenEncDVFNOFIRMA().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((registro) => {
        if (registro.ENCDVF_FECHAEMISION !== null) {
          registro.ENCDVF_FECHAEMISION = this.datePipe.transform(registro.ENCDVF_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCDVF_FECHAEMISION = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }
  //GSRF
  abrirClientes() {
    this.opcion = 'BUSQUEDACLI';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RUCIDE', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'ZONA_NOMBRE' },
      { label: 'Vendedor', value: 'VEN_NOMBRE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'Nombreec', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.consulta = 'ven_maecliente.*,  VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO) AS' +
      ' VEN_NOMBRE, VEN_FNC_OBTENER_ZONACLI(CLI_ZONA) AS ZONA_NOMBRE';
    this.where = '';

    this.busquedaSer.busquedaVenMaeCliente1(this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirPaisesRegimen() {
    this.opcion = 'BUSQUEDARG';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.codigoPaisRegimen, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = '';

    this.busquedaSer.busquedaAnexMaeCodSri9().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirPaisesParaiso() {
    this.opcion = 'BUSQUEDAPF';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.codigoPaisParaiso, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = '';
    this.busquedaSer.busquedaAnexMaeCodSri10().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirPaisesExportacion() {
    this.opcion = 'BUSQUEDAEX';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.exportacionNuevoSeleccionado.PAISEFECEXP, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = '';
    this.busquedaSer.busquedaAnexMaeCodSri11().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirVendedor() {
    this.opcion = 'BUSQUEDAVEN';
    this.types = [
      { label: 'Código', value: 'VEN_CODIGO' },
      { label: 'Nombre', value: 'VEN_NOMBRE' }
    ];
    this.busquedacampos = [this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO, ''];
    this.tabla = 'ven_maevendedor';
    this.consulta = '*';
    this.where = '';
    this.busquedaSer.busquedaVenMaeVendedor3(this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirFactura() {
    this.opcion = 'BUSQUEDAFAC';
    this.types = [
      { label: 'NÚMERO', value: 'ENCFAC_NUMERO' },
      { label: 'F_EMISIÓN', value: 'ENCFAC_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCFAC_ESTADO' },
      { label: 'COD_CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE_CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'RUC', value: 'ENCFAC_RUCIDE' },
      { label: 'BODEGA', value: 'BOD_CODIGO' },
      { label: 'VENDEDOR', value: 'VEN_CODIGO' },
      { label: 'TOTAL', value: 'ENCFAC_TOTAL' },
      { label: 'NETO', value: 'ENCFAC_TOTALNETO' },
    ];
    this.busquedacampos = [this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA, '', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCFAC';
    this.consulta = '*';
    this.where = 'ENCFAC_ESTADO != \'A\' AND ROWNUM < 50 AND CLI_CODIGO = \'' +
      this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO + '\' AND ' + this.types[0].value + ' LIKE \'%' +
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA + '%\' ORDER BY ENCFAC_FECHAEMISION DESC';
    this.busquedaSer.busquedaVenEncfac1(this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO,
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA).subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.arregloCons.map((registro) => {
          if (registro.ENCFAC_FECHAEMISION !== null) {
            registro.ENCFAC_FECHAEMISION = this.datePipe.transform(registro.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
          } else {
            registro.ENCFAC_FECHAEMISION = '';
          }
        });
        this.displayDialogBusquedaFast = true;
      });
  }

  manejarSeleccion(opcion) {
    if (this.opcion === 'BUSCAR') {
      this.spin=true;//CDPJ
      // this.encabezadosDevF.map((enca) => {
      //   if (enca.ENCDVF_NUMERO === opcion.ENCDVF_NUMERO) {
      //     this.indice = this.encabezadosDevF.indexOf(enca);
      //   }
      // });
      // if (this.indice === -1) {
      //   return;
      // }
      // if (this.indice === this.encabezadosDevF.length - 1) {
      //   this.botonUltimo = true;
      //   this.botonSiguiente = true;
      //   this.venDevFacService.encdevfacarray[this.indicador] = this.encabezadosDevF[this.indice];
      //   this.obtenerDatos();
      // } else if (this.indice === 0) {
      //   this.botonPrimero = true;
      //   this.botonAnterior = true;
      //   this.venDevFacService.encdevfacarray[this.indicador] = this.encabezadosDevF[this.indice];
      //   this.obtenerDatos();
      // } else {
      //   this.botonPrimero = false;
      //   this.botonAnterior = false;
      //   this.botonSiguiente = false;
      //   this.botonUltimo = false;
      //   this.venDevFacService.encdevfacarray[this.indicador] = this.encabezadosDevF[this.indice];
      //   this.obtenerDatos();
      // }
      this.obtenerDatos(opcion.ENCDVF_NUMERO);
      if (this.activeIndex === 0) {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
      }
    
      
      this.displayDialogBusquedaFast = false;
    } else if (this.opcion === 'BUSQUEDACLI') {
      this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;
      if (opcion.CLI_LISTA !== null && opcion.CLI_LISTA !== undefined) {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_LISTA = opcion.CLI_LISTA;
      }
      if (opcion.CLI_DESCUENTO !== null) {
        this.dscClienteActual = opcion.CLI_DESCUENTO;
      } else if (opcion.CLI_DESCUENTO === null) {
        this.dscClienteActual = 0;
      }
      this.venDevFacService.encdevfacarray[this.indicador].CLI_NOMBRE = opcion.CLI_NOMBRE;
      if (opcion.CLI_IVA !== undefined) {
        if (opcion.CLI_IVA === null) {
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_IVA = '1';
        } else {
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_IVA = opcion.CLI_IVA;
        }
      }
      this.venDevFacService.obtenerSaldoCliente(this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO).subscribe((res) => {
        this.venDevFacService.encdevfacarray[this.indicador].SALDO = Number(res[0].SALDO).toFixed(2);
      });
      this.displayDialogBusquedaFast = false;
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      document.getElementById('referencia').focus();
      //GSRF
      this.venDevFacService.detdevfacarray[this.indicador]=[];
      this.estadoRef='';
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA=''; 
      //GSRF
    } else if (this.opcion === 'BUSQUEDAVEN') {
      this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO;
      if (this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO !== '') {
        this.venDevFacService.obtenerNombreVen(this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO).subscribe((res2) => {
          this.nombreVendedor = res2[0].VEN_NOMBRE;
        });
      }
      this.displayDialogBusquedaFast = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      document.getElementById('comentario').focus();
    } else if (this.opcion === 'BUSQUEDAFAC') {
      this.cargarDatosReferencia(opcion);
    } else if (this.opcion === 'BUSQUEDARG') {
      this.codigoPaisRegimen = opcion.CODSRI_CODIGO;
      this.nombrePaisSeleccionado = opcion.CODSRI_DESCRIPCION;
      this.displayDialogBusquedaFast = false;
    } else if (this.opcion === 'BUSQUEDAPF') {
      this.codigoPaisParaiso = opcion.CODSRI_CODIGO;
      this.nombrePaisSeleccionado = opcion.CODSRI_DESCRIPCION;
      this.displayDialogBusquedaFast = false;
    } else if (this.opcion === 'BUSQUEDAEX') {
      this.exportacionNuevoSeleccionado.PAISEFECEXP = opcion.CODSRI_CODIGO;
      this.nombrePaisSeleccionado = opcion.CODSRI_DESCRIPCION;
      this.displayDialogBusquedaFast = false;
    }
    if (this.opcionbusqueda === 'articulo') {
      this.txtArtCodigo = opcion.ART_CODIGO;
      this.txtArtDescripcion = opcion.ART_NOMBRE;
      this.displayDialogBusquedaFast = false;
      this.llenarExistenciaBod();
    }
  }

  async seleccionDetalleDvf(valor) {
    if (valor === 'nuevo') {
      this.nuevodetalleDevolucion();
    }

    if (valor === 'eliminar') {
      this.confirmarEliminarDetDev();
    }

    if (valor === 'cancelar') {
      this.cancelarDetDVF();
    }

    if (valor === 'existenciasBod') {
      this.disabledVerImagen = true;
      this.detExistenciaBodegas = [];
      this.txtArtPrecioLista = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_LISTA;

      if (this.detalleDVFSeleccionado === undefined) {

        this.txtArtCodigo = '';
        this.txtArtDescripcion = '';
        this.displayDialogExistBod = true;

      } else if (Object.keys(this.detalleDVFSeleccionado).length > 0) {

        if (this.detalleDVFSeleccionado.DETDVF_CODIGO === '' || this.detalleDVFSeleccionado.DETDVF_TIPODET === '*'
          || this.detalleDVFSeleccionado.DETDVF_TIPODET === 'S' || this.detalleDVFSeleccionado.DETDVF_TIPODET === 'C') {

          this.txtArtCodigo = '';
          this.txtArtDescripcion = '';
          this.displayDialogExistBod = true;

        } else {

          //  const strSql = 'select art_imagen from TOUCH_MAEPANTALLA where art_codigo = \'' + this.detalleDVFSeleccionado.DETDVF_CODIGO + '\'';

          this.txtArtCodigo = this.detalleDVFSeleccionado.DETDVF_CODIGO;
          this.txtArtDescripcion = this.detalleDVFSeleccionado.DETDVF_DESCRIPCION;
          try {
            const rs = await this.venDevFacService.existenciaPorBodega(this.txtArtCodigo, this.txtArtPrecioLista,
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION,
              this.horaSistema, this.venDevFacService.dblPorcIvaDevF[this.indicador]);

            const imagen = await this.venDevFacService.obtenerImgArticulo(this.detalleDVFSeleccionado.DETDVF_CODIGO);


            this.disabledVerImagen = imagen.length === 0 ? true : (imagen[0].ART_IMAGEN === null ? true : false);

            this.txtArtPathFoto = imagen.length === 0 ? '' : imagen[0].ART_IMAGEN;


            const detalle: ComExistenciaBodegas[] = [];
            for (const item of rs) {
              if (Number(item.EXISTENCIA) > 0) {
                const det: ComExistenciaBodegas = {};
                det.BOD_CODIGO = item.BOD_CODIGO;
                det.BOD_NOMBRE = item.BOD_NOMBRE;
                det.EXISTENCIA = Number(item.EXISTENCIA).toFixed(2);
                det.PEDIDO = Number(item.PEDIDO).toFixed(2);
                det.SALDO = Number(item.SALDO).toFixed(2);
                det.ART_TRIBUTAIVA = item.ART_TRIBUTAIVA;
                det.PRECIO = Number(item.PRECIO).toFixed(2);
                det.IVA = item.ART_TRIBUTAIVA === 'S' ? Number(item.IVA).toFixed(2) : Number(0).toFixed(2);
                det.TOTAL = Number(item.PRECIO + Number(det.IVA)).toFixed(2);
                detalle.push(det);
              }

            }
            this.detExistenciaBodegas = detalle;
            this.displayDialogExistBod = true;
          } catch (err) {

          }
        }
      }


    }

    if (valor.object !== undefined) {
      this.detalleDVFSeleccionado = valor.object;
      this.tipodetalle = this.detalleDVFSeleccionado.DETDVF_TIPODET;
      this.multiunidad();
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'DETDVF_LISTA') {
        this.utilitariosService.getListaPrecioArt(this.detalleDVFSeleccionado.DETDVF_CODIGO).subscribe((res1) => {
          const opciones1 = [];
          opciones1.push(this.venDevFacService.detdevfacarray[this.indicador][this.venDevFacService.detdevfacarray[this.indicador].indexOf(this.detalleDVFSeleccionado)].DETDVF_LISTA);
          res1.map((codigo) => {
            if (codigo.ARTPRE_CODIGO !== opciones1[0]) {
              opciones1.push(codigo.ARTPRE_CODIGO);
            }
          });
          this.columnDefsDVFDetalle[10].cellEditorParams = {
            values: opciones1
          };
          this.aggrid.refreshColumnDefs();
          this.aggrid.gridApi.setFocusedCell(this.venDevFacService.detdevfacarray[this.indicador].indexOf(this.detalleDVFSeleccionado), 'DETDVF_LISTA');
          res1.map((codigoPre) => {
            if (this.venDevFacService.detdevfacarray[this.indicador][this.venDevFacService.detdevfacarray[this.indicador].indexOf(this.detalleDVFSeleccionado)].DETDVF_LISTA === codigoPre.ARTPRE_CODIGO) {
              this.venDevFacService.detdevfacarray[this.indicador][this.venDevFacService.detdevfacarray[this.indicador].indexOf(this.detalleDVFSeleccionado)].DETDVF_PRECIO = codigoPre.ARTPRE_PRECIO;
            }
          });
        });
      }
    }
  }

  nuevodetalleDevolucion() {
    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== '' &&
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== null) {
      return;
    }
    if (this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO !== '') {
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA !== '') {
        this.btnNuevoGrid = true;
        this.btnGuardarGrid = false;
        this.btnBorrarGrid = false;
        this.btnCancelarGrid = false;
        const linea = this.venDevFacService.detdevfacarray[this.indicador].length;
        const nuevodetalle = {
          BOD_CODIGO: this.venDevFacService.encdevfacarray[this.indicador].BOD_CODIGO, CEN_CODIGO: '', COM_CODIGO: '01',
          DETDVF_BASECERO: 0, DETDVF_BASEICE: 0, DETDVF_BASEIVA: 0, DETDVF_CAJAS: 0, DETDVF_CANTIDAD: 1, DETDVF_CANTIDADUND: 0,
          DETDVF_CODIGO: '', DETDVF_CODIGOREF: '', DETDVF_CONCODCSTVTA: '', DETDVF_CONCODINV: '', DETDVF_DESCRIPCION: '',
          DETDVF_DESCUENTO: 0, DETDVF_DESPACHO: 0, DETDVF_FLAG: '', DETDVF_FRACCIONES: '', DETDVF_HORA: '', DETDVF_ICE: '',
          DETDVF_IVA: this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_IVA, DETDVF_LINEA: 0, DETDVF_LISTA:
            this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_LISTA, DETDVF_PORCEICE: '', DETDVF_PRECIO: 0, DETDVF_PRECIOC: 0,
          DETDVF_PROMOCION: '', DETDVF_SERIAL: '', DETDVF_TIPO: '', DETDVF_TIPODET: this.tipodetalle, DETDVF_TOTAL: 0, DETDVF_TRIBICE: '',
          DETDVF_TRIBIVA: '', DETDVF_UNIDAD: '', DETDVF_VALCSTVTA: '', DETDVF_VALINV: '', DETFAC_LINEA: '', DETFAC_TIPOSRI: '',
          ENCDVF_NUMERO: '', ENCFAC_NUMERO: this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA, UNI_SIMBOLO: '',
          LINEA: String(linea + 1), MULTIUNIDAD: '', PRECIO: 0
          ,DETDVF_IRBPNR: 0, DETDVF_VALORIRBPNR: 0, //GSRF
          DETDVF_PORIVA:Number(this.venDevFacService.porcetariiva),TARIIVA_CODIGO:''
        };
        if (this.detalleDVFSeleccionado !== undefined && this.detalleDVFSeleccionado !== null) {
         // const indi = this.venDevFacService.detdevfacarray[this.indicador].indexOf(this.detalleDVFSeleccionado);
         const indi = this.venDevFacService.idcelldevfac; 
         if (indi === -1) {
            this.venDevFacService.detdevfacarray[this.indicador].push(nuevodetalle);
            this.detalleDVFSeleccionado = this.venDevFacService.detdevfacarray[this.indicador][this.venDevFacService.detdevfacarray[this.indicador].length - 1];
            this.aggrid.refreshaggrid(this.venDevFacService.detdevfacarray[this.indicador], this.defaultColDefDevolucion.objeto);
            this.aggrid.gridApi.setFocusedCell(this.venDevFacService.detdevfacarray[this.indicador].length - 1, 'DETDVF_CODIGO');
            //CDPJ
            //console.log('tipodet1',this.detalleDVFSeleccionado.DETDVF_TIPODET)
            if(this.tipodetalle === '*'){
              this.detalleDVFSeleccionado.TARIIVA_CODIGO='-1';
              this.detalleDVFSeleccionado.DETDVF_PORIVA=0;
              this.aggrid.gridApi.setFocusedCell(this.venDevFacService.detdevfacarray[this.indicador].length - 1, 'DETDVF_DESCRIPCION');
            }
            //CDPJ
          } else {
            this.venDevFacService.detdevfacarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
            this.detalleDVFSeleccionado=nuevodetalle;
            this.aggrid.refreshaggridindex(this.venDevFacService.detdevfacarray[this.indicador], this.defaultColDefDevolucion.objeto, indi);
            this.aggrid.gridApi.setFocusedCell(indi + 1, 'DETDVF_CODIGO');
           //CDPJ
          // console.log('tipodet2',this.detalleDVFSeleccionado.DETDVF_TIPODET);
             if(this.tipodetalle === '*'){
              this.detalleDVFSeleccionado.TARIIVA_CODIGO='-1';
              this.detalleDVFSeleccionado.DETDVF_PORIVA=0;
            this.aggrid.gridApi.setFocusedCell(indi + 1,'DETDVF_DESCRIPCION');
          }
          //CDPJ
          this.venDevFacService.idcelldevfac=indi+1;//GSRF
          }
        }
      } else {
        this.btnNuevoGrid = false;
        this.btnGuardarGrid = false;
        this.btnBorrarGrid = false;
        this.btnCancelarGrid = false;
        this.messageService.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Información',
          detail: 'Escoja la referencia de la devolución.'
        });
        document.getElementById('referencia').focus();
        return;

      }
    } else {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.messageService.add({
        key: 'devolucion',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el cliente.'
      });
      document.getElementById('cliente').focus();
    }
  }

  confirmarEliminarDetDev() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDetDev();
      },
      reject: () => {
      }
    });
  }

  eliminarDetDev() {
    if (this.detalleDVFSeleccionado !== undefined) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleDVFSeleccionado.DETDVF_LINEA === 0) {
        this.venDevFacService.detdevfacarray[this.indicador] = this.venDevFacService.detdevfacarray[this.indicador].filter((val, j) => j !==
          this.venDevFacService.detdevfacarray[this.indicador].indexOf(this.detalleDVFSeleccionado));
        this.aggrid.refreshaggrid(this.venDevFacService.detdevfacarray[this.indicador], this.defaultColDefDevolucion.objeto);
        this.venDevFacService.calcularTotal();
      } else {
        this.venDevFacService.eliminarDetDVF(this.detalleDVFSeleccionado).subscribe(() => {
          this.venDevFacService.detdevfacarray[this.indicador] = this.venDevFacService.detdevfacarray[this.indicador].filter((val, j) => j !==
            this.venDevFacService.detdevfacarray[this.indicador].indexOf(this.detalleDVFSeleccionado));
          this.aggrid.refreshaggrid(this.venDevFacService.detdevfacarray[this.indicador], this.defaultColDefDevolucion.objeto);
          this.auditoriagral.registrarAuditoria('VEN_DETDVF', String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO)+'/'+this.detalleDVFSeleccionado.DETDVF_CODIGO, 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
          this.venDevFacService.calcularTotal();
        });
      }
    }
  }

  cancelarDetDVF() {
    if (this.detalleDVFSeleccionado.DETDVF_LINEA === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      if (this.detalleDVFSeleccionado.DETDVF_CODIGO === '') {
        this.venDevFacService.detdevfacarray[this.indicador] = this.venDevFacService.detdevfacarray[this.indicador].filter((val, j) => j !==
          this.venDevFacService.detdevfacarray[this.indicador].indexOf(this.detalleDVFSeleccionado));
        this.detalleDVFSeleccionado = {};
      }
    }

    /* if (this.detalleDVFSeleccionado.DETDVF_LINEA !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.venDevFacService.getDetDevF(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((res) => {
        this.venDevFacService.detdevfacarray[this.indicador] = res;
        this.venDevFacService.detdevfacarray[this.indicador].map((detalle) => {
          if (detalle.DETDVF_LINEA === this.detalleDVFSeleccionado.DETDVF_LINEA) {
            this.detalleDVFSeleccionadoAux
              = detalle;
          }
        });
        this.detalleDVFSeleccionado = this.detalleDVFSeleccionadoAux;
        const opcPedido = [];
        const opcLista = [];
        opcPedido.push(this.detalleDVFSeleccionado.DETDVF_UNIDAD);
        this.columnDefsDVFDetalle[5].cellEditorParams = {
          values: opcPedido
        };
        this.aggrid.refreshColumnDefs();
        this.utilitariosService.getListaPrecioArt(this.detalleDVFSeleccionado.DETDVF_CODIGO).subscribe((res1) => {
          res1.map((ped) => {
            opcLista.push(ped.ARTPRE_CODIGO);
          });
          this.columnDefsDVFDetalle[10].cellEditorParams = {
            values: opcLista
          };
          this.aggrid.refreshColumnDefs();
        });
        this.aggrid.refreshaggrid(this.venDevFacService.detdevfacarray[this.indicador], this.defaultColDefDevolucion.objeto);
        this.venDevFacService.calcularTotal();
      });
    } */
  }

  cambio() {
    if (this.venDevFacService.encdevfacarray[this.indicador] !== undefined) {
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PENDIENTE') {
        this.btnGuardar = false;
        this.btnRegresar = false;
        this.btnNuevo = true;
        this.btnNuevoGrid = false;
      }
    }
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'cliente') {
      this.busquedaSer.encontrarRegistro44(this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO).subscribe((resC) => {
        if (resC !== null) {
          this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO = resC[0].CLI_CODIGO;
          if (resC[0].CLI_LISTA !== null && resC[0].CLI_LISTA !== undefined) {
            this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_LISTA = resC[0].CLI_LISTA;
          }
          if (resC[0].CLI_DESCUENTO !== null) {
            this.dscClienteActual = resC[0].CLI_DESCUENTO;
          } else if (resC[0].CLI_DESCUENTO === null) {
            this.dscClienteActual = 0;
          }
          this.venDevFacService.encdevfacarray[this.indicador].CLI_NOMBRE = resC[0].CLI_NOMBRE;
          if (resC[0].CLI_IVA !== undefined) {
            if (resC[0].CLI_IVA === null) {
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_IVA = '1';
            } else {
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_IVA = resC[0].CLI_IVA;
            }
          }
          this.venDevFacService.obtenerSaldoCliente(this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO).subscribe((res) => {
            this.venDevFacService.encdevfacarray[this.indicador].SALDO = Number(res[0].SALDO).toFixed(2);
          });
          this.displayDialogBusquedaFast = false;
          this.btnNuevoGrid = false;
          this.btnBorrarGrid = false;
          this.btnCancelarGrid = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('referencia').focus();
          //GSRF
          this.venDevFacService.detdevfacarray[this.indicador]=[];
          this.estadoRef='';
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA=''; 
          //GSRF
        } else {
          this.abrirClientes();
        }
      });
    }

    if (evento.target.id === 'referencia') {
      this.busquedaSer.encontrarRegistro49(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA).subscribe((resR) => {
        if (resR !== null) {
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA = resR[0].ENCFAC_NUMERO;
          if (resR[0].VEN_CODIGO !== null) {
            this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO = resR[0].VEN_CODIGO;
          } else {
            this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO = '';
          }
          if (this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO !== '') {
            this.venDevFacService.obtenerNombreVen(this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO).subscribe((res2) => {
              this.nombreVendedor = res2[0].VEN_NOMBRE;
            });
          }
          if (resR[0].ENCFAC_ESTADO === 'P') {
            this.estadoRef = 'PENDIENTE';
          } else if (resR[0].ENCFAC_ESTADO === 'G') {
            this.estadoRef = 'PAGADA';
          }
          this.venDevFacService.encdevfacarray[this.indicador].BOD_CODIGO = resR[0].BOD_CODIGO;
          this.displayDialogBusquedaFast = false;
          //PORCENTAJE DEL IVA DE LA CABECERA DE LA FACTURA CDPJ
          for (const j of this.cmbporceiva) {
            if (Number(resR[0].ENCFAC_PORCEIVA) === Number(j.name)) {
              this.selectcombtipoIva = { codigo: j.codigo, name: j.name };
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA=Number(this.selectcombtipoIva.name)
            }
          }
          let porcentaje=this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA
          let data1=this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(porcentaje))
          this.venDevFacService.porcetariiva=Number(porcentaje);
          this.venDevFacService.codtariiva=data1.TARIIVA_CODIGO;
          //CDPJ
          document.getElementById('vendedor').focus();
        } else {
          this.abrirFactura();
        }
      });
    }

    if (evento.target.id === 'vendedor') {
      this.busquedaSer.encontrarRegistro45(this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO).subscribe((resV) => {
        if (resV !== null) {
          this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO = resV[0].VEN_CODIGO;
          if (this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO !== '') {
            this.venDevFacService.obtenerNombreVen(this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO).subscribe((res2) => {
              this.nombreVendedor = res2[0].VEN_NOMBRE;
            });
          }
          this.displayDialogBusquedaFast = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('comentario').focus();
        } else {
          this.abrirVendedor();
        }
      });
    } else if (evento.target.id === 'comentario') {
      this.nuevodetalleDevolucion();
    } else if (evento.target.id === 'paisesreg') {
      this.busquedaSer.encontrarRegistro50(this.codigoPaisRegimen).subscribe((resPR) => {
        if (resPR !== null) {
          this.codigoPaisRegimen = resPR[0].CODSRI_CODIGO;
          this.nombrePaisSeleccionado = resPR[0].CODSRI_DESCRIPCION;
          this.displayDialogBusquedaFast = false;
        } else {
          this.abrirPaisesRegimen();
        }
      });
    } else if (evento.target.id === 'paisParaiso') {
      this.busquedaSer.encontrarRegistro50(this.codigoPaisParaiso).subscribe((resPP) => {
        if (resPP !== null) {
          this.codigoPaisParaiso = resPP[0].CODSRI_CODIGO;
          this.nombrePaisSeleccionado = resPP[0].CODSRI_DESCRIPCION;
          this.displayDialogBusquedaFast = false;
        } else {
          this.abrirPaisesParaiso();
        }
      });
    } else if (evento.target.id === 'denoExp') {
      document.getElementById('paisResid').focus();
    } else if (evento.target.id === 'paisResid') {
      this.busquedaSer.encontrarRegistro50(this.exportacionNuevoSeleccionado.PAISEFECEXP).subscribe((resPRes) => {
        if (resPRes !== null) {
          this.exportacionNuevoSeleccionado.PAISEFECEXP = resPRes[0].CODSRI_CODIGO;
          this.nombrePaisSeleccionado = resPRes[0].CODSRI_DESCRIPCION;
          this.displayDialogBusquedaFast = false;
        } else {
          this.abrirPaisesExportacion();
        }
      });
    } else if (evento.target.id === 'valorImpuestoR') {
      document.getElementById('valorfobC').focus();
    } else if (evento.target.id === 'valorfobC' && this.exportacionNuevoSeleccionado.EXPORTACIONDE === '01') {
      document.getElementById('doctran').focus();
    }
  }

  cambiarTipo(valor) {
    this.tipodetalle = valor;
  }

  confirmarGenerarAsiento() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea generar el asiento',
      header: 'Asiento Devolución',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.venDevFacService.generarAsiento(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe(() => {
          this.messageService.add({
            key: 'devolucion',
            severity: 'success',
            summary: 'Asiento Devolución',
            detail: 'Se ingreso un asiento a la devolución'
          });
        });
      },
      reject: () => {
      }
    });
  }

  aplicarDescuento() {
    if (this.valorInvalidoV === true || this.valorInvalidoP === true) {
      this.displayDialogDescuento = false;
      this.messageService.add({
        key: 'devolucion',
        severity: 'success',
        summary: 'Descuento Global',
        detail: 'El valor del descuento es incorrecto'
      });
      return;
    }

    let tipo = '';
    if (this.booleanValor === false) {
      if (this.dscClienteActual < (this.valorDscValor * 100 / Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTAL)))) {
        tipo = 'valor';
        this.confirmarDescuento(tipo);
      } else {
        tipo = 'valor';
        this.descuentoAplicado(tipo);
      }
    }
    if (this.booleanPorcentaje === false) {
      if (this.dscClienteActual < this.valorDscPorcentaje) {
        tipo = 'porcentaje';
        this.confirmarDescuento(tipo);
      } else {
        tipo = 'porcentaje';
        this.descuentoAplicado(tipo);
      }
    }
  }

  cambioDescuento() {
    if (this.dscSeleccionado === 'dscValor') {
      this.booleanValor = false;
      this.booleanPorcentaje = true;
    } else if (this.dscSeleccionado === 'dscPorcentaje') {
      this.booleanPorcentaje = false;
      this.booleanValor = true;
    }
  }

  comprobarValor() {
    if (this.booleanValor === false) {
      this.valorDscPorcentaje = Number(this.valorDscValor * 100) / Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTALNETO);
      this.valorDscPorcentaje = Number(this.valorDscPorcentaje.toFixed(2));
      this.valorInvalidoV = this.valorDscValor > Number(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTALNETO);
    } else if (this.booleanPorcentaje === false) {
      this.valorDscValor = Number(this.valorDscPorcentaje * this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTALNETO) / 100;
      this.valorDscValor = Number(this.valorDscValor.toFixed(2));
      this.valorInvalidoP = this.valorDscPorcentaje > 100;
    }
  }

  confirmarDescuento(tipo) {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea aplicar un descuento mayor',
      header: 'El cliente tiene un descuento menor',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.descuentoAplicado(tipo);
      },
      reject: () => {
      }
    });
  }

  descuentoAplicado(tipo) {
    this.displayDialogDescuento = false;
    this.btnGuardar = false;
    this.btnNuevo = true;
    if (tipo === 'valor') {
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES = this.valorDscValor;
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES = (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES
        * 100) / Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTAL));
      this.valorDscPorcentaje = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES;
      this.venDevFacService.dblPorcDescGlbDevFarray[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES;
      this.venDevFacService.dblValorDescGlbDevFarray[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES;
      this.venDevFacService.calcularTotal();
    } else if (tipo === 'porcentaje') {
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES = this.valorDscPorcentaje;
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES = Number(
        Number(this.init.quitarComas(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_TOTALNETO))* this.valorDscPorcentaje / 100);
      this.valorDscValor = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES;
      this.venDevFacService.dblPorcDescGlbDevFarray[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEDES;
      this.venDevFacService.dblValorDescGlbDevFarray[this.indicador] = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_VALORDES;
      this.venDevFacService.calcularTotal();
    }
  }

  abrirDetalleFactura() {
    this.displayDialogDetFac = true;
  }

  recibirSenales(valor) {
    if (valor === true) {
      this.displayDialogDetFac = false;
    }
  }

  recibirDetalles(valores) {
    let devdetalle: Vendetdevfac;
    let devdetalles: Vendetdevfac[];

    devdetalles = [];
    devdetalle = {};
    if (valores !== null) {
      valores.map((valor) => {
        devdetalle.BOD_CODIGO = valor.BOD_CODIGO;
        devdetalle.DETDVF_CODIGO = valor.DETFAC_CODIGO;
        devdetalle.DETDVF_DESCRIPCION = valor.DETFAC_DESCRIPCION;
        devdetalle.DETDVF_TRIBIVA = valor.DETFAC_TRIBIVA;
        devdetalle.DETDVF_TRIBICE = valor.DETFAC_TRIBICE;
        devdetalle.DETDVF_UNIDAD = valor.DETFAC_UNIDAD;
        devdetalle.UNI_SIMBOLO = valor.DETFAC_UNIDAD;
        devdetalle.DETDVF_CANTIDAD = valor.DETFAC_CANTIDAD;
        devdetalle.CANTIDAD = valor.DETFAC_CANTIDAD;
        devdetalle.DETDVF_PRECIO = valor.DETFAC_PRECIO;
        devdetalle.DETDVF_PRECIOC = 0;
        devdetalle.PRECIO = valor.DETFAC_PRECIO;
        devdetalle.DETDVF_TOTAL = valor.DETFAC_TOTAL;
        devdetalle.DETDVF_IRBPNR = valor.DETFAC_IRBPNR === null || valor.DETFAC_IRBPNR === undefined ? 0 : valor.DETFAC_IRBPNR;
        devdetalle.DETDVF_VALORIRBPNR = valor.DETFAC_VALORIRBPNR === null || valor.DETFAC_VALORIRBPNR === undefined ? 0 : valor.DETFAC_VALORIRBPNR;
        devdetalle.DETDVF_DESCUENTO = valor.DETFAC_DESCUENTO;
        devdetalle.DETDVF_LISTA = valor.DETFAC_LISTA;
        devdetalle.DETDVF_TIPODET = valor.DETFAC_TIPODET;
        devdetalle.DETFAC_LINEA = valor.DETFAC_LINEA;
        devdetalle.LINEA = valor.DETFAC_LINEA;
        devdetalle.DETDVF_LINEA = 0;
        devdetalle.DETDVF_CAJAS = '';
        devdetalle.DETDVF_CANTIDADUND = '';
        devdetalle.DETDVF_CODIGOREF = '';
        devdetalle.CEN_CODIGO = '';
        devdetalle.ENCFAC_NUMERO = valor.ENCFAC_NUMERO;
        devdetalle.DETDVF_DESPACHO = '';
        devdetalle.ENCDVF_NUMERO = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO;
        devdetalle.DETDVF_IVA = valor.DETFAC_IVA;
        devdetalle.DETDVF_ICE = valor.DETFAC_ICE;
        devdetalle.DETDVF_PORCEICE = valor.DETFAC_PORCEICE;
        devdetalle.COM_CODIGO = '01';
        devdetalle.DETDVF_BASEIVA = valor.DETFAC_BASEIVA;
        devdetalle.DETDVF_BASEICE = valor.DETFAC_BASEICE;
        devdetalle.DETDVF_BASECERO = valor.DETFAC_BASECERO;
        devdetalle.DETDVF_PROMOCION = valor.DETFAC_PROMOCION;
        devdetalle.DETDVF_VALINV = 0;
        devdetalle.DETDVF_VALCSTVTA = 0;
        devdetalle.DETDVF_FLAG = '';
        devdetalle.DETDVF_FRACCIONES = '';
        devdetalle.DETDVF_HORA = '';
        devdetalle.DETDVF_SERIAL = '';
        devdetalle.DETDVF_TIPO = '';
        devdetalle.DETFAC_TIPOSRI = valor.DETFAC_TIPOSRI === null || valor.DETFAC_TIPOSRI === undefined?'':valor.DETFAC_TIPOSRI;
        devdetalle.DETDVF_CONCODCSTVTA = '';
        devdetalle.DETDVF_CONCODINV = '';
        devdetalle.DETDVF_PORIVA=valor.DETFAC_PORIVA;
        devdetalle.TARIIVA_CODIGO=valor.TARIIVA_CODIGO;
        devdetalles.push(devdetalle);

        devdetalle = {};
      });
      this.venDevFacService.detdevfacarray[this.indicador] = devdetalles;

      this.venDevFacService.detdevfacarray[this.indicador].map(devdet => {
        this.venDevFacService.encontrarArtMultiunidad(devdet.DETDVF_CODIGO).subscribe(eR => {
          let multiunidad = '';
          if (eR !== null) {
            if (eR[0] !== undefined) {
              multiunidad = eR[0].ART_MULTIUNIDAD;
            }
          }
          devdet.MULTIUNIDAD = multiunidad;
        });
      });
      this.aggrid.refreshaggrid(this.venDevFacService.detdevfacarray[this.indicador], this.defaultColDefDevolucion.objeto);
      this.venDevFacService.calcularTotal();
      this.displayDialogDetFac = false;
    }
  }

  recibirGuardarPago(valor) {
    if (valor === true) {
      this.venDevFacService.actualizarEstadoDev('G', this.venDevFacService.encdevfacarray[this.indicador]).subscribe(() => {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'PAGADA';
        this.venDevFacService.actualizarEstFac(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA,
          this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO).subscribe(() => {
            this.venDevFacService.generarAsiento(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO.toString().trim()).subscribe(() => {
              this.messageService.add({
                key: 'devolucion',
                severity: 'success',
                summary: 'Asiento Contable',
                detail: 'Se generó el asiento contable con éxito'
              });
              this.displayDialogPago = false;
              this.venDevFacService.getEncDevolucionNum(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((asi) => {
                this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO = asi[0].ASI_NRO;
              });
              document.getElementById('divPrincipal').style.pointerEvents = 'none';
              // document.getElementById('divPrincipal').style.opacity = '0.7';
              this.botonAnular = true;
              this.btnBorrar = true;
              this.validacionbotonborrar();
            }, error1 => {
              this.messageService.add({
                key: 'pagcondvf',
                severity: 'error',
                summary: 'Asiento Contable',
                detail: 'No se generó el asiento contable, error: ' + error1.error
              });
            });
          });
      });
    }
  }

  salirDialog() {
    this.displayDialogPago = false;
    this.displayDialogDetFac = false;
    this.displayDialogPedidos = false;
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.displayDialogSeriales = false;
    this.displayAcciones = false;
    this.displayDialogDescuento = false;
    this.displayDialogPago = false;
  }

  recibirEliminarPago(valor) {
    if (valor === true) {
      this.venDevFacService.actualizarEstadoDev('P', this.venDevFacService.encdevfacarray[this.indicador]).subscribe(() => {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO = 'PENDIENTE';
        if (this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO !== '' && this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO !== null) {
          this.venDevFacService.anularAsiento(this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO).subscribe(() => {
            this.venDevFacService.actualizarAsiNro(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe(() => {
              this.venDevFacService.encdevfacarray[this.indicador].ASI_NRO = '';
            });
          });
        }
        this.messageService.add({
          key: 'devolucion',
          severity: 'success',
          summary: 'Pago reversado',
          detail: 'El pago se reverso correctamente'
        });
        this.displayDialogPago = false;
        this.cancelarEdicionDevolucion();
      });
    }
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }

  abrirSeriales() {
    this.closeSidebar();
    this.displayDialogSeriales = true;
  }

  habilitarPorcIva() {
    this.porcIvaBoolean = false;
    this.acciones();
    this.cambio();
  }

  reversarProceso() {
    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO === '') {
      return;
    }
    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== 'AUT' &&
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== 'NT'&&
      this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE !== 'SN') {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea reversar el proceso?',
        header: 'Reversar Proceso',
        icon: 'pi pi-exclamation-triangle',
        key: 'devolucionConf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          console.log(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO);
          this.utilitariosService.reversarProcesoFE(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO, '04');
          this.utilitariosService.reversarProcesoFEClave(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_CLAVEACCESO_FE);
          this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO',
            String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO + 'VEN_REVERSAR_DEV'),
            'E', '', '01', '', '', '',
            '').subscribe(() => {
              this.botonXML = true;
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE = '';
              this.messageService.add({
                key: 'devolucion',
                severity: 'success',
                summary: 'Reversar Proceso',
                detail: 'Reversar proceso de inicio de firma electrónica terminó satisfactoriamente'
              });
            });
        },
        reject: () => {
        }
      });
    } else {
      this.messageService.add({
        key: 'devolucion',
        severity: 'error',
        summary: 'Reversar Proceso',
        detail: 'La nota de crédito esta autorizada o notificada'
      });
    }
  }

  abrirLog() {
    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO !== 'ANULADA') {
      this.venDevFacService.obtenerLogFE(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO, '04').subscribe((res) => {
        if (res.length !== 0) {
          if (res[0] !== null) {
            this.logResultado = JSON.stringify(res[0].TRNDOC_RESULTADO);
          }
        }
        this.displayLog = true;
        this.closeSidebar();
      });
    } else {
      return;
    }
  }
//CDPJ
async visualizarEst(){
  this.closeSidebar();
  this.estfirma='';
  const data =await this.venDevFacService.obtenerEstFE().toPromise()
  this.displayDialogEst = true;
  if(data !== null && data !== undefined){
    for (const est of data){
      this.estfirma=this.estfirma+est.REF_CODIGO+' '+est.REF_NOMBRE+'\n'
    }
  }
 
}
//CDPJ
  refrescarDatosFE() {
    this.closeSidebar();
    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }

    this.venDevFacService.actualizarDatosElectronicos(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe(data => {
      for (const rs of data) {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE = rs.ENCDVF_ESTADO_FE;
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_AUTORIZACION_FE = rs.ENCDVF_AUTORIZACION_FE;
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_CLAVEACCESO_FE = rs.ENCDVF_CLAVEACCESO_FE;
      }
    });

  }

  insertarEleTrnDoc() {
    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE === 'VA') {
      this.messageService.add({
        key: 'devolucion',
        severity: 'error',
        summary: 'Información',
        detail: 'El documento ya fue procesado'
      });
      return;
    }
    if (this.btnGuardar === false) {
      this.messageService.add({
        key: 'devolucion',
        severity: 'error',
        summary: 'Información',
        detail: 'Primero guarde la devolución'
      });
      return;
    } else {
      this.abrirClave('firmarDoc');
    }
  }

  confirmarFirmar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea firmar el documento ' + this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO + '?',
      header: 'Firmar Electrónicamente',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.firmarDoc();
      },
      reject: () => {
      }
    });
  }

  firmarDoc() {
    const tipoFac = 'NC';
    const strNumDoc = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO;
    let strFecha = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION;
    console.log('antes' + strFecha);
    if (strFecha.toString().includes('-')) {
      strFecha = this.datePipe.transform(strFecha,
        'dd/MM/yyyy');
    }
    console.log(strFecha);
    this.venDevFacService.insertarEleTrnDoc(strNumDoc, tipoFac,
      this.usuario.identificacion, this.datosfe.COM_TIPOAMBFACTELEC).subscribe(async () => {
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE = 'VA';
        this.botonAnular = true;
        this.messageService.add({
          key: 'devolucion',
          severity: 'success',
          summary: 'Firma Electrónica',
          detail: 'Proceso de firma electrónica iniciado correctamente.'
        });
        this.spin = true;
        const data = await this.utilitariosService.procesoFirmaElectronica(strNumDoc, strFecha).catch(e=>{
          this.spin = false;
        });
        if (data !== 'NOAUTORIZADO') {
          this.messageService.add({
            key: 'devolucion',
            severity: 'warn',
            summary: 'Información',
            detail: data
          });
          this.spin = false;
        this.cancelarEdicionDevolucion();
        }else{
          this.spin = true;
          await setTimeout(async() => {
            
            await this.refrescarDatosFE();
           this.spin = false;
           this.messageService.add({
            key: 'devolucion',
            severity: 'success',
            summary: 'Información',
            detail: 'Documento procesado'
          });
          }, 6000);
        }
        
      });
      //this.spin = false;
  }

  reenviarCorreo() {
    if (this.venDevFacService.encdevfacarray[this.indicador] !== undefined) {
      if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO !== 'ANULADA') {
        if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE === 'NT') {
          this.venDevFacService.reenviarCorreo(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe(async () => {
            const data = await this.utilitariosService.reenviarCorreo(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO);
            this.messageService.add({
              key: 'devolucion',
              severity: 'success',
              summary: 'Información',
              detail: data
            });
            this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO',
              String(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO +
                'VEN_REV_CORREO'), 'A', '', '01', '', '',
              '', '').subscribe(() => {
                this.messageService.add({
                  key: 'devolucion',
                  severity: 'success',
                  summary: 'Envió de correo',
                  detail: 'Proceso actualizar estado para reenvió de correo  terminó satisfactoriamente'
                });
              });
          });
        } else {
          this.messageService.add({
            key: 'devolucion',
            severity: 'error',
            summary: 'Envió de correo',
            detail: 'Solo puede reenviar el correo si la factura esta notificada'
          });
        }
      } else {
        return;
      }
    }
  }

  habilitarExpNuevoCliente() {
    this.cambio();
    if (this.exportacionNuevoSeleccionado.REGIMEN === '01') {
      this.booleanRegimen = false;
      this.booleanParaiso = true;
      this.booleanRegimenP = true;
      this.nombrePaisSeleccionado = '';
      this.codigoPaisRegimen = '';
      this.codigoPaisParaiso = '';
      this.exportacionNuevoSeleccionado.PAISEFECEXP = '';
      this.exportacionNuevoSeleccionado.DENOEXPCLI = '';
      document.getElementById('paisesreg').focus();
    } else if (this.exportacionNuevoSeleccionado.REGIMEN === '02') {
      this.booleanRegimen = true;
      this.booleanParaiso = false;
      this.booleanRegimenP = true;
      this.nombrePaisSeleccionado = '';
      this.codigoPaisRegimen = '';
      this.codigoPaisParaiso = '';
      this.exportacionNuevoSeleccionado.PAISEFECEXP = '';
      this.exportacionNuevoSeleccionado.DENOEXPCLI = '';
      document.getElementById('paisParaiso').focus();
    } else if (this.exportacionNuevoSeleccionado.REGIMEN === '03') {
      this.booleanRegimen = true;
      this.booleanParaiso = true;
      this.booleanRegimenP = false;
      this.nombrePaisSeleccionado = '';
      this.codigoPaisRegimen = '';
      this.codigoPaisParaiso = '';
      this.exportacionNuevoSeleccionado.PAISEFECEXP = '';
      this.exportacionNuevoSeleccionado.DENOEXPCLI = '';
      document.getElementById('denoExp').focus();
    } else if (this.exportacionNuevoSeleccionado.REGIMEN === '') {
      this.booleanRegimen = true;
      this.booleanParaiso = true;
      this.booleanRegimenP = true;
      this.nombrePaisSeleccionado = '';
      this.codigoPaisRegimen = '';
      this.codigoPaisParaiso = '';
      this.exportacionNuevoSeleccionado.PAISEFECEXP = '';
      this.exportacionNuevoSeleccionado.DENOEXPCLI = '';
    }
  }

  habilitarRefrendos() {
    if (this.exportacionNuevoSeleccionado.EXPORTACIONDE === '01') {
      this.booleanRefrendo = true;
      this.booleanImpuestoRenta = true;
      document.getElementById('valorfobC').focus();
      this.exportacionNuevoSeleccionado.DOCTRANSP = '';
      this.exportacionNuevoSeleccionado.CORRELATIVO = '';
      this.exportacionNuevoSeleccionado.ANIO = '';
      this.exportacionNuevoSeleccionado.TIPOREGI = '';
      this.exportacionNuevoSeleccionado.DISTADUANERO = '';
      this.exportacionNuevoSeleccionado.VALORFOB = '';
      this.exportacionNuevoSeleccionado.VALORIMPEXT = '';
      this.exportacionNuevoSeleccionado.INGGRAVADOEXT = '';
      this.exportacionNuevoSeleccionado.TIPOINGRESO = '';
    } else if (this.exportacionNuevoSeleccionado.EXPORTACIONDE === '02') {
      this.booleanRefrendo = true;
      this.booleanImpuestoRenta = true;
      document.getElementById('valorfobC').focus();
      this.exportacionNuevoSeleccionado.DOCTRANSP = '';
      this.exportacionNuevoSeleccionado.CORRELATIVO = '';
      this.exportacionNuevoSeleccionado.ANIO = '';
      this.exportacionNuevoSeleccionado.TIPOREGI = '';
      this.exportacionNuevoSeleccionado.DISTADUANERO = '';
      this.exportacionNuevoSeleccionado.VALORFOB = '';
      this.exportacionNuevoSeleccionado.VALORIMPEXT = '';
      this.exportacionNuevoSeleccionado.INGGRAVADOEXT = '';
      this.exportacionNuevoSeleccionado.TIPOINGRESO = '';
    } else if (this.exportacionNuevoSeleccionado.EXPORTACIONDE === '03') {
      this.booleanRefrendo = false;
      this.booleanImpuestoRenta = false;
      this.exportacionNuevoSeleccionado.DOCTRANSP = '';
      this.exportacionNuevoSeleccionado.CORRELATIVO = '';
      this.exportacionNuevoSeleccionado.ANIO = '';
      this.exportacionNuevoSeleccionado.TIPOREGI = '';
      this.exportacionNuevoSeleccionado.DISTADUANERO = '';
      this.exportacionNuevoSeleccionado.VALORFOB = '';
      this.exportacionNuevoSeleccionado.VALORIMPEXT = '';
      this.exportacionNuevoSeleccionado.INGGRAVADOEXT = '';
      this.exportacionNuevoSeleccionado.TIPOINGRESO = '';
    } else if (this.exportacionNuevoSeleccionado.EXPORTACIONDE === '') {
      this.booleanRefrendo = true;
      this.booleanImpuestoRenta = true;
      this.exportacionNuevoSeleccionado.DOCTRANSP = '';
      this.exportacionNuevoSeleccionado.CORRELATIVO = '';
      this.exportacionNuevoSeleccionado.ANIO = '';
      this.exportacionNuevoSeleccionado.TIPOREGI = '';
      this.exportacionNuevoSeleccionado.DISTADUANERO = '';
      this.exportacionNuevoSeleccionado.VALORFOB = '';
      this.exportacionNuevoSeleccionado.VALORIMPEXT = '';
      this.exportacionNuevoSeleccionado.INGGRAVADOEXT = '';
      this.exportacionNuevoSeleccionado.TIPOINGRESO = '';
    }
  }

  habilitarImpuestoRenta() {
    if (this.exportacionNuevoSeleccionado.INGGRAVADOEXT === 'SI') {
      this.booleanImpuestoRenta = false;
      document.getElementById('valorImpuestoR').focus();
    } else if (this.exportacionNuevoSeleccionado.INGGRAVADOEXT === 'NO') {
      this.booleanImpuestoRenta = true;
      document.getElementById('valorfobC').focus();
    } else if (this.exportacionNuevoSeleccionado.INGGRAVADOEXT === '') {
      this.booleanImpuestoRenta = true;
    }
  }

  async changeTabs() {
    if (this.activeIndex > 0) {
      this.btnNuevo = true;
      this.btnGuardar = true;
      this.btnBorrar = true;
      this.btnRegresar = true;
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonUltimo = true;
      this.bolestcta = false;//CDPJ
      this.bolsaldo = false;//CDPJ
    } else {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
      this.bolestcta = false;//CDPJ
      this.bolsaldo = false;//CDPJ
    }
    if (this.activeIndex === 1) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.cliService.getInfoEstadoCuenta(this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO,
          'devolucion');
      }
      this.bolsaldo = false;//CDPJ
    }
    if (this.activeIndex === 2) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.cliService.getInfoSaldos(this.venDevFacService.encdevfacarray[this.indicador].CLI_CODIGO,
          'devolucion');
      }
      this.bolestcta = false;//CDPJ
    }
  }


  cargarDatosReferencia(opcion) {
    this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_REFERENCIA = opcion.ENCFAC_NUMERO;

    /*let strSql = 'SELECT * FROM VEN_ENCFAC WHERE ENCFAC_NUMERO = \'' + opcion.ENCFAC_NUMERO + '\'';
    strSql = strSql + ' AND COM_CODIGO = \'01\'';*/

    this.venDevFacService.ecCargarDatosRefe(opcion.ENCFAC_NUMERO).subscribe(data => {
      for (const rs of data) {
        // bodega
        const BODDEF = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BODDEF').CFG_VALOR1;
        if (BODDEF === 0) { // No considerar la bodega por defecto
          this.venDevFacService.encdevfacarray[this.indicador].BOD_CODIGO = rs.BOD_CODIGO;
          /*for (let index = 0; index < this.opcionesBodega.length; index++) {
            if (this.opcionesBodega[index].value === rs.BOD_CODIGO) {
              this.venDevFacService.encdevfacarray[this.indicador].BOD_CODIGO = rs.BOD_CODIGO;
            }
          }*/
        }

        // tipo de iva
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_IVA = rs.ENCFAC_IVA;
        /* if (rs.ENCFAC_IVA === '1') { // Neto + Iva
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_IVA = '0';
        } else if (rs.ENCFAC_IVA === '2') { // Incluído Iva
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_IVA = '1';
        } else if (rs.ENCFAC_IVA === '0') { // Exento
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_IVA = '2';
         */
          //PORCENTAJE DEL IVA DE LA CABECERA DE LA FACTURA CDPJ
          for (const j of this.cmbporceiva) {
            if (Number(rs.ENCFAC_PORCEIVA) === Number(j.name)) {
              this.selectcombtipoIva = { codigo: j.codigo, name: j.name };
              this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA=Number(this.selectcombtipoIva.name)
            }
          }
          let porcentaje=this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_PORCEIVA
          let data1=this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(porcentaje))
          this.venDevFacService.porcetariiva=Number(porcentaje);
          this.venDevFacService.codtariiva=data1.TARIIVA_CODIGO;
          //CDPJ
        

        // lista de precios
        if (rs.ENCFAC_LISTA.length > 0) {
          this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_LISTA = rs.ENCFAC_LISTA;
        }

        // vendedor
        if (rs.VEN_CODIGO !== null) {
          this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO = rs.VEN_CODIGO;
        } else {
          this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO = '';
        }
        if (this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO !== '') {
          this.venDevFacService.obtenerNombreVen(this.venDevFacService.encdevfacarray[this.indicador].VEN_CODIGO).subscribe((res2) => {
            this.nombreVendedor = res2[0].VEN_NOMBRE;
          });
        }

        // estado
        if (opcion.ENCFAC_ESTADO === 'P') {
          this.estadoRef = 'PENDIENTE';
        } else if (opcion.ENCFAC_ESTADO === 'G') {
          this.estadoRef = 'PAGADA';
        }
        /*
              //				descuento global
              dblValorDescGlb = rs.getDouble("ENCFAC_VALORDES");
              dblPorcDescGlb = rs.getDouble("ENCFAC_PORCEDES");
        
              if ( rs.getString("ENCFAC_TIPODSCTO") != null )
              {
                if ( rs.getString("ENCFAC_TIPODSCTO").equals("P") )
                  DlgDescuento.glbBolEsValor = false;
                else
                  DlgDescuento.glbBolEsValor = true;
              }
              //				porcentaje del iva
              dblPorcIva=  rs.getDouble("encfac_porceiva");
              for (int i=0;i<=cmbPorcentajeIva.getItemCount()-1;i++)
              {
                if (Double.parseDouble(cmbPorcentajeIva.getItem(i))==dblPorcIva)
                  cmbPorcentajeIva.select(i);*/
        this.venDevFacService.encdevfacarray[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
        //CDPJ
        this.venDevFacService.dblPorcDescGlbDevFarray[this.indicador] = opcion.ENCFAC_PORCEDES;
        this.venDevFacService.dblValorDescGlbDevFarray[this.indicador] = opcion.ENCFAC_VALORDES;
        //CDPJ
        this.displayDialogBusquedaFast = false;
        document.getElementById('vendedor').focus();
      }
    });
  }

  multiunidad() {
    if (this.aggrid.gridApi.getFocusedCell() === null) {
      return;
    }
    if (this.aggrid.gridApi.getFocusedCell().column === null) {
      return;
    }

    if (this.aggrid.gridApi.getFocusedCell().column.colId === 'DETDVF_UNIDAD') {
      console.log(this.detalleDVFSeleccionado.MULTIUNIDAD);
      if (this.detalleDVFSeleccionado.MULTIUNIDAD === 'S') {
        const opciones = [];
        opciones.push(this.detalleDVFSeleccionado.DETDVF_UNIDAD);
        this.utilitariosService.getUnidadesEqui(this.detalleDVFSeleccionado.DETDVF_UNIDAD).subscribe((res) => {
          if (res !== null) {
            res.map((unidades) => {
              opciones.push(unidades.UNI_CODIGODESTINO);
            });
          }
          this.columnDefsDVFDetalle[5].cellEditor = 'agSelectCellEditor';
         // this.columnDefsDVFDetalle[5].editable = true;
          this.columnDefsDVFDetalle[5].cellEditorParams = {
            values: opciones
          };
          this.aggrid.refreshColumnDefs();
          this.aggrid.gridApi.setFocusedCell(this.venDevFacService.detdevfacarray[this.indicador].indexOf(this.detalleDVFSeleccionado), 'DETDVF_UNIDAD');
        });
      } else {
        this.columnDefsDVFDetalle[5].cellEditor = 'cellPrueba';
       // this.columnDefsDVFDetalle[5].editable = false;
        this.aggrid.refreshColumnDefs();
        this.aggrid.gridApi.setFocusedCell(this.venDevFacService.detdevfacarray[this.indicador].indexOf(this.detalleDVFSeleccionado), 'DETDVF_UNIDAD');
      }
    }
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  cerrarSeriales() {
    this.displayDialogSeriales = false;
  }

  validacionbotonborrar() {
    this.venDevFacService.obtenerPago(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((res) => {
      if (Number(res[0].PAGO) === 0 && this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PAGADA') {
        this.venDevFacService.obtenerPago2(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((res2) => {
          if (Number(res2[0].PAGO1 === 0) && this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PAGADA') {
            this.venDevFacService.obtenerPago3(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO).subscribe((res3) => {
              if (Number(res3[0].PAGO2 === 0) && this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PAGADA') {
                this.btnBorrar = false;
                this.botonAnular = false;//CDPJ
              } else {
                this.btnBorrar = true;
                this.botonAnular = true;//CDPJ
              }
            });
          } else if (Number(res2[0].PAGO1 !== 0)) {
            this.btnBorrar = true;
            this.botonAnular = true;//CDPJ
          }
        });
      } else if (Number(res[0].PAGO !== 0) ||
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO === 'PENDIENTE') {
        this.btnBorrar = true;
        this.botonAnular = true;//CDPJ
      }
    });
  }

  buscarArticulo() {

    this.txtArtCodigo = '';
    this.txtArtDescripcion = '';
    this.detExistenciaBodegas = [];

    this.opcionbusqueda = 'articulo';
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'NombreCorto', value: 'ART_NOMBREC' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Ubicación', value: 'ART_UBICACION' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Alterno3', value: 'ART_CODIGOALT3' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ART_ACTIVO=\'S\'';

    this.busquedaSer.busquedaInvMaeArticulo1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async llenarExistenciaBod() {
    this.disabledVerImagen = true;
    this.detExistenciaBodegas = [];
    // const strSql = 'select art_imagen from TOUCH_MAEPANTALLA where art_codigo = \'' + this.txtArtCodigo + '\'';
    try {
      const rs = await this.venDevFacService.existenciaPorBodega(this.txtArtCodigo, this.txtArtPrecioLista,
        this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_FECHAEMISION,
        this.horaSistema, this.venDevFacService.dblPorcIvaDevF[this.indicador]);
      const imagen = await this.venDevFacService.obtenerImgArticulo(this.txtArtCodigo);
      this.disabledVerImagen = imagen.length === 0 ? true : (imagen[0].ART_IMAGEN === null ? true : false);
      this.txtArtPathFoto = imagen.length === 0 ? '' : imagen[0].ART_IMAGEN;
      const detalle: ComExistenciaBodegas[] = [];
      for (const item of rs) {
        if (Number(item.EXISTENCIA) > 0) {
          const det: ComExistenciaBodegas = {};
          det.BOD_CODIGO = item.BOD_CODIGO;
          det.BOD_NOMBRE = item.BOD_NOMBRE;
          det.EXISTENCIA = Number(item.EXISTENCIA).toFixed(2);
          det.PEDIDO = Number(item.PEDIDO).toFixed(2);
          det.SALDO = Number(item.SALDO).toFixed(2);
          det.ART_TRIBUTAIVA = item.ART_TRIBUTAIVA;
          det.PRECIO = Number(item.PRECIO).toFixed(2);
          det.IVA = item.ART_TRIBUTAIVA === 'S' ? Number(item.IVA).toFixed(2) : Number(0).toFixed(2);
          det.TOTAL = Number(item.PRECIO + Number(det.IVA)).toFixed(2);
          detalle.push(det);
        }

      }
      this.detExistenciaBodegas = detalle;
    } catch (err) {

    }
  }

  visualizarImagen() {
    this.displayDialogImagenArt = true;
  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  async continuarFE() {
    this.closeSidebar();
    const msgclave = 'devolucion';
    const strNumDoc = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO;

    if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'No ha seleccionado un documento'
      });
      return;
    }

    if (this.datosfe.COM_FEACTIVADA === '0') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Facturación electrónica no está activada.'
      });
      return;
    }

    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE === ''
      || this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE === undefined
      || this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE === null) {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento no ha iniciado el proceso de firma electrónica.'
      });
      return;
    }

    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE === 'VA') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra en su estado inicial, reverse el proceso de firma electrónica.'
      });
      return;
    }

    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE === 'NT') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra notificado.'
      });
      return;
    }


    if (this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_ESTADO_FE === 'NA') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Visualize el log. Corrija el documento y reverse el proceso de firma electrónica.'
      });
      return;
    }

    this.spin = true;
    const data = await this.utilitariosService.continuarFirmaElectronica(strNumDoc).catch(e=>{
      this.spin = false;
    });
    this.spin = false;
    if (data !== 'NOAUTORIZADO') {
      this.messageService.add({
        key: msgclave,
        severity: 'success',
        summary: 'Información',
        detail: data
      });
    }
  }

  abrirLotes(params) {
    this.detalleDVFSeleccionado = params;
    this.auxiliarlot++;
    this.displayDialogLotes = this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO.length > 0
      && this.detalleDVFSeleccionado.ART_CADUCA === 'S';
  }

  cerrarLotes() {
    this.displayDialogLotes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }
//CDPJ
  
onHideEvent() {
  this.cancelarEdicionDevolucion();
}
solonumeros(evento){
   
  let nemonico=0;
  let secuencial=0
  let serie=0;
  let count=0
  secuencial=this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_SECACTUAL.length;
  serie=this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_SERIE.length
  if(this.confNumeracion !== 'G'){
    nemonico=this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NEMONICO.length;
  }
  

let inputValue = evento.target.value;
let posicionPermitida = nemonico
if(inputValue.length > posicionPermitida){
  inputValue=inputValue.substring(0,posicionPermitida)+inputValue.substring(posicionPermitida).replace(/[^0-9]/g,'')
 }//else{
//   count=posicionPermitida-inputValue.length
//   if(count<0){
//     posicionPermitida=posicionPermitida+(-1*count)
//   }else{
//     posicionPermitida=posicionPermitida-count;
//   }
  

//   console.log(posicionPermitida)
//   console.log(inputValue.length)
  
//   //if(inputValue.length > posicionPermitida){
//     inputValue=inputValue.substring(0,posicionPermitida)+inputValue.substring(posicionPermitida).replace(/[^0-9]/g,'')
//   //}
// }
evento.target.value =inputValue;
}
//CDPJ
///GSRF
discamp(parametro): Boolean {
  if(parametro.data.DETDVF_TIPODET ==='A'){ 
 return false;
  }else{
  return true;
  }
}

discampuni(parametro): Boolean {
  if(parametro.data.DETDVF_TIPODET ==='A' && parametro.data.MULTIUNIDAD ==='S' ){ 
 return true;
  }else{
  return false;
  }
}
seleccioninfoadic(params) {

  if (params === 'nuevo') {
    this.nuevodetalle();
    return;
  }

  if (params === 'cancelar') {
    this.infoadicional(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO);
    return;
  }

  if (params === 'eliminar') {
    this.eliminarDetalleinfadi();
    return;
  }

  if (params.object !== undefined) {
    this.infoAdicSelected = params.object;
  }

}

nuevodetalle() {
  console.log(this.veninfoadicdevfac) 
    const iaf: VenInfoadicdevfac = {
      ENCDVF_NUMERO: this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO,
      COM_CODIGO: '01',
      INFAD_NUMSEC: 0,
      INFAD_CODIGO: '',
      INFAD_DESCRIPCION: ''
    };

    const indi = this.veninfoadicdevfac.indexOf(this.infoAdicSelected); 
    if (indi === -1) {
      this.veninfoadicdevfac.push(iaf);
      this.infoAdicSelected = iaf;
      this.aggrid.refreshaggrid(this.veninfoadicdevfac, this.defaultColDefInfoAdic.objeto);
    } else {
      this.veninfoadicdevfac.splice(indi + 1, 0, iaf);
      this.infoAdicSelected = iaf;
      this.aggrid.refreshaggridindex(this.veninfoadicdevfac, this.defaultColDefInfoAdic.objeto, indi);
    }
  
}

infoadicional(encfaccodigo) {
  this.venDevFacService.consultarInfoAdicional(encfaccodigo).subscribe(ia => {
    this.veninfoadicdevfac = ia;
    this.spin=false;//CDPJ
  });
}
guardarDatosBloque() {
  for (const infoadic of this.veninfoadicdevfac) {
    this.venDevFacService.guardarInfoAdicFac(infoadic).subscribe((data) => {
      if (infoadic.INFAD_NUMSEC === 0) {
        for (const rs of data) {
          infoadic.INFAD_NUMSEC = rs[':B1'];
        }
        this.auditoriagral.registrarAuditoria('VEN_INFOADICDEVFAC',
          infoadic.ENCDVF_NUMERO + '/' + infoadic.INFAD_CODIGO, 'I', '', '01', '', '', '', '').subscribe();
      } else {
        this.auditoriagral.registrarAuditoria('VEN_INFOADICDEVFAC',
          infoadic.ENCDVF_NUMERO + '/' + infoadic.INFAD_CODIGO, 'A', '', '01', '', '', '', '').subscribe();
      }
    });
  }
  this.messageService.add({
    key: 'devolucion',
    severity: 'success',
    summary: 'Información',
    detail: 'Datos guardados correctamente'
  });
}
eliminarDetalleinfadi() {
  console.log('<<<<<<<<',this.infoAdicSelected)
  console.log('<<<<<<<<',this.veninfoadicdevfac)
    if (this.infoAdicSelected !== undefined && this.veninfoadicdevfac.length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'devolucionConf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.infoAdicSelected.INFAD_NUMSEC !== 0) {
            this.venDevFacService.eliminarInfoAdicFac(this.infoAdicSelected).subscribe(() => {
              this.auditoriagral.registrarAuditoria('VEN_INFOADICDEVFAC',
                this.infoAdicSelected.ENCDVF_NUMERO + '/' + this.infoAdicSelected.INFAD_CODIGO, 'E',
                '', '01', '', '', '', '').subscribe();
              this.veninfoadicdevfac.splice(this.veninfoadicdevfac.findIndex(
                infadi => infadi.INFAD_NUMSEC === this.infoAdicSelected.INFAD_NUMSEC),
                1);
              this.messageService.add({
                key: 'encfac',
                severity: 'success',
                summary: 'Información',
                detail: 'La información adicional se eliminó correctamente'
              });
              if (this.veninfoadicdevfac.length > 0) {
                this.infoAdicSelected = this.veninfoadicdevfac[this.veninfoadicdevfac.length - 1];
              }
              this.aggrid.refreshaggrid(this.veninfoadicdevfac, this.defaultColDefInfoAdic.objeto);
            });
          } else {
            this.veninfoadicdevfac.splice(this.veninfoadicdevfac.findIndex(
              infadi => infadi.INFAD_NUMSEC === this.infoAdicSelected.INFAD_NUMSEC),
              1);
            this.messageService.add({
              key: 'encfac',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.veninfoadicdevfac.length > 0) {
              this.infoAdicSelected = this.veninfoadicdevfac[this.veninfoadicdevfac.length - 1];
            }
            this.aggrid.refreshaggrid(this.veninfoadicdevfac, this.defaultColDefInfoAdic.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  
}
///GSRF
// CDPJ
async verbasesiva(){
  this.closeSidebar()
  this.basesiva=[]
  const data = await this.venDevFacService.obtenerbasesiva(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO);
  if(data !== null && data !== undefined){
   this.basesiva=data
   for (const data2 of  this.basesiva){
     data2.TARIIVA_PORCENTAJE=isNaN(Number(data2.TARIIVA_PORCENTAJE))?Number(0).toFixed(2):Number(data2.TARIIVA_PORCENTAJE).toFixed(2)
     data2.IVA_BASE=isNaN(Number(data2.IVA_BASE))?Number(0).toFixed(2):Number(data2.IVA_BASE).toFixed(2)
     data2.IVA_VALOR=isNaN(Number(data2.IVA_VALOR))?Number(0).toFixed(2):Number(data2.IVA_VALOR).toFixed(2)
   }
  }
  this.displaybasesiva=true;

}
async actbasesiva(){
  this.spin=true;
  this.closeSidebar();
  try {
    await this.venDevFacService.actualizabasesiva(this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO);
    this.messageService.add({
      key: 'devolucion',
      severity: 'success',
      summary: 'Información',
      detail: 'Bases IVA actualizadas correctamente'
    });
    this.auditoriagral.registrarAuditoria('VEN_ENCDVF', this.venDevFacService.encdevfacarray[this.indicador].ENCDVF_NUMERO+'/'+'ACCIONES_ACT_VALORES_BASES_IVA', 'A', 
    '', '01', '', '', '', '').subscribe(() => {
    });
    this.spin=false;
  } catch (error) {
    this.messageService.add({
      key: 'devolucion',
      severity: 'error',
      summary: 'Información',
      detail: 'Error al actualiza las bases IVA'+ error
    });
    this.spin=false;
  }
  
}
//cdpj
}
