import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { InvtrnloteService } from '../venservicios/invtrnlote.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { InvTrnlote } from '../veninterfaces/invtrnlote';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';//CDPJ
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-invtrnlote',
  templateUrl: './invtrnlote.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvtrnloteComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  @Input() auxiliar?;
  @Input() detalle?;
  @Input() encabezado?;
  @Input() cadena?;
  @Output() salir = new EventEmitter<boolean>();

  indicador: any;

  auxcodigo?;
  auxdescripcion?;
  auxlinea?;
  auxnumero?;
  dblCantidad?;
  largo: string;

  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  oculto: boolean;
  activado: boolean;
  desactivado: boolean;

  loteseleccionado: InvTrnlote;

  strCodigo: string;
  strDescripcion: string;
  orgLote: string = ''//CDPJ
  rowSelection = 'multiple';
  NCFVML: number;
  public frameworkComponents;
  rowStyle;
  defaultColDefDetalle;

  columnDefsDetalle = [
    { // 0
      headerName: 'Lote', field: 'LOTE_NUMERO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 20,
          opcionbuscar: true
        }
      }
    }, { // 1
      headerName: 'Vencimiento', field: 'TRNLOTE_FECHAVENCIMIENTO', cellEditor: 'cellPrueba', width: 110, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          clave: false,
          tamanocelda: 20
        }
      }
    }, { // 2
      headerName: 'Cantidad', field: 'TRNLOTE_CANTIDAD', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 20,
          tienecalculos: true,
          focusout: true
        }
      }
    }];


  constructor(public trnloteservice: InvtrnloteService, private datePipe: DatePipe,
    private messageService: MessageService, public init: NuevoComponentService, private confIniciales: ConfInicialesService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDefDetalle = {
      editable: true,
      width: 80,
      objeto: 'tablalote'
    };
  }


  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;

    this.largo = '700';
    this.oculto = true;
    this.activado = false;
    this.desactivado = true;

    this.botonNuevo = this.activado;
    this.botonGuardar = this.desactivado;
    this.botonBorrar = this.activado;
    this.botonRegresar = this.desactivado;
    switch (this.cadena) {
      case 'FACVEN':
        if (this.detalle !== undefined) {
          this.detalle = { DETFAC_CODIGO: '' };
        }
        break;
      case 'COMNOT':
        if (this.detalle !== undefined) {
          this.detalle = { DETNOTREC_CODIGO: '' };
        }
        break;
      case 'FACPRO':
        if (this.detalle !== undefined) {
          this.detalle = { DETFACPRO_CODIGO: '' };
        }
        break;
      case 'EN':
        if (this.detalle !== undefined) {
          this.detalle = { KAR_NRO: '' };
        }
        break;
      case 'SA':
        if (this.detalle !== undefined) {
          this.detalle = { KAR_NRO: '' };
        }
        break;
      case 'ORDPROD':
        if (this.detalle !== undefined) {
          this.detalle = { ENCORDPRO_NUMERO: '' };
        }
        break;
      default:
        break;
    }
    this.orgLote = this.cadena//CDPJ
    this.trnloteservice.hmpConfiguracion = await this.trnloteservice.getConfiguracion();
    this.NCFVML=this.trnloteservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NCFVML').CFG_VALOR1;
    this.strCodigo = '';
    this.strDescripcion = '';
    //CDPJ
    this.confIniciales.getDecimales('frmINV_TRNLOTE').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_TIPDOC === 'detcant') {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.trnloteservice.decimalesCANTIDADlote = Number(decimales.NUMDEC_NUMDEC);
            }
          }
        });
        this.columnDefsDetalle[2].cellEditorParams = {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.trnloteservice.decimalesCANTIDADlote,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 20,
            tienecalculos: true,
            focusout: true
          }
        };
        this.aggrid.refreshColumnDefs();
      }

    })
    if(this.NCFVML === 1){
      this.columnDefsDetalle.splice(1, 1);
    }
    this.aggrid.refreshColumnDefs();
    //CDPJ
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const e in changes) {
      if ((e === 'auxiliar') && this.detalle !== null && this.detalle !== undefined) {
        this.trnloteservice.strOrigen[this.indicador] = this.cadena;
        switch (this.cadena) {
          case 'FACVEN':
            this.auxcodigo = this.detalle.DETFAC_CODIGO;
            this.auxdescripcion = this.detalle.DETFAC_DESCRIPCION;
            this.auxlinea = this.detalle.DETFAC_LINEA;
            this.auxnumero = this.encabezado.ENCFAC_NUMERO;
            this.dblCantidad = this.detalle.DETFAC_CANTIDAD;
            if (this.detalle.ART_UNIDADVENTA !== this.detalle.DETFAC_UNIDAD) {
              this.dblCantidad = this.detalle.DETFAC_CANTIDADB;
            }
            break;
          case 'COMNOT':
            this.auxcodigo = this.detalle.DETNOTREC_CODIGO;
            this.auxdescripcion = this.detalle.DETNOTREC_DESCRIPCION;
            this.auxlinea = this.detalle.DETNOTREC_LINEA;
            this.auxnumero = this.encabezado.ENCNOTREC_NUMERO;
            break;
          case 'FACPRO':
            this.auxcodigo = this.detalle.DETFACPRO_CODIGO;
            this.auxdescripcion = this.detalle.DETFACPRO_DESCRIPCION;
            this.auxlinea = this.detalle.DETFACPRO_LINEA;
            this.auxnumero = this.encabezado.ENCFACPRO_NUMERO;
            break;
          case 'EN':
            this.auxcodigo = this.detalle.ART_CODIGO;
            this.auxdescripcion = this.detalle.ART_NOMBRE;
            this.auxlinea = this.detalle.DETKAR_LINEA;
            this.auxnumero = this.encabezado.KAR_NRO;
            break;
          case 'SA':
            this.auxcodigo = this.detalle.ART_CODIGO;
            this.auxdescripcion = this.detalle.ART_NOMBRE;
            this.auxlinea = this.detalle.DETKAR_LINEA;
            this.auxnumero = this.encabezado.KAR_NRO;
            break;
          case 'DEVCOM':
            this.auxcodigo = this.detalle.DETDEV_CODIGO;
            this.auxdescripcion = this.detalle.DETDEV_DESCRIPCION;
            this.auxlinea = this.detalle.DETDEV_LINEA;
            this.auxnumero = this.encabezado.ENCDEV_NUMERO;
            break;
          case 'LIQCOM':
            this.auxcodigo = this.detalle.DETFACPRO_CODIGO;
            this.auxdescripcion = this.detalle.DETFACPRO_DESCRIPCION;
            this.auxlinea = this.detalle.DETFACPRO_LINEA;
            this.auxnumero = this.encabezado.ENCFACPRO_NUMERO;
            break;
          case 'DEVFAC':
            this.auxcodigo = this.detalle.DETDVF_CODIGO;
            this.auxdescripcion = this.detalle.DETDVF_DESCRIPCION;
            this.auxlinea = this.detalle.DETDVF_LINEA;
            this.auxnumero = this.encabezado.ENCDVF_NUMERO;
            break;
          case 'GUIREM':
            this.auxcodigo = this.detalle.DETGRE_CODIGO;
            this.auxdescripcion = this.detalle.DETGRE_DESCRIPCION;
            this.auxlinea = this.detalle.DETGRE_LINEA;
            this.auxnumero = this.encabezado.ENCGRE_CODIGO;
            this.dblCantidad = this.detalle.DETGRE_CANTIDAD;
            if (this.detalle.ART_UNIDADVENTA !== this.detalle.DETGRE_UNIDAD) {
              this.dblCantidad = this.detalle.DETGRE_CANTIDADB;
            }
            break;
          case 'ORDPROD':
            this.auxcodigo = this.detalle.ART_CODIGO;
            this.auxdescripcion = this.detalle.DETORDPRO_DESCRIPCION;
            this.auxlinea = this.detalle.DETORDPRO_LINEA;
            this.auxnumero = this.encabezado.ENCORDPRO_NUMERO;
            this.dblCantidad = this.detalle.DETORDPRO_CANTIDAD;
            break;
          default:
            break;
        }
        this.orgLote = this.cadena//CDPJ
        this.strCodigo = this.auxcodigo;
        this.strDescripcion = this.auxdescripcion;
        this.trnloteservice.auxcod = this.strCodigo//CDPJ
        this.mostrarLotes();
      }
    }
  }

  manejarSenales(valor) {

    if (valor === 'Nuevo') {
      this.nuevoLote();
    }

    if (valor === 'Guardar') {
      this.guardarLote();
      this.botonNuevo = this.activado;
      this.botonGuardar = this.desactivado;
      this.botonBorrar = this.activado;
      this.botonRegresar = this.desactivado;
    }

    if (valor === 'Borrar') {
      this.eliminarLote();
    }

    if (valor === 'Cancelar') {
      this.mostrarLotes();
      this.botonNuevo = this.activado;
      this.botonGuardar = this.desactivado;
      this.botonBorrar = this.activado;
      this.botonRegresar = this.desactivado;
    }

    if (valor === 'Salir') {
      if (this.validarSalir() === true) {
        this.salir.emit(true);
      }
    }

  }

  mostrarLotes() {
    if (this.auxcodigo !== undefined) {
      if(this.trnloteservice.strOrigen[this.indicador] === "ORDPROD"){
        this.trnloteservice.listarDatosprod(this.auxnumero, this.auxlinea, this.auxcodigo).subscribe(data => {
          const lotes = [];
          if(data !== null && data !== undefined && data.length > 0){
            if(this.NCFVML === 1){
              this.columnDefsDetalle[0].editable = false;
              this.columnDefsDetalle[1].editable = false;
              this.aggrid.refreshColumnDefs();
              }else{
              this.columnDefsDetalle[0].editable = false;
              this.columnDefsDetalle[1].editable = false;
              this.columnDefsDetalle[2].editable = false;
              this.aggrid.refreshColumnDefs();
              }
              this.aggrid.bloquearGrid()
          }
          for (const rs of data) {
            rs.TRNLOTE_FECHAVENCIMIENTO = this.datePipe.transform(rs.TRNLOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
            rs.CODIGO_AUXILIAR = 1;
            rs.CLAVE = rs.LOTE_NUMERO;
            rs.TRNLOTE_CANTIDAD=Number(Number(rs.TRNLOTE_CANTIDAD).toFixed(this.trnloteservice.decimalesCANTIDADlote))
            lotes.push(rs);
          }
          this.trnloteservice.detlotearray[this.indicador] = lotes;
        });
      
      }else{
        this.trnloteservice.listarDatos(this.auxnumero, this.auxlinea, this.cadena).subscribe(data => {
          const lotes = [];
          if(data !== null && data !== undefined && data.length > 0){
            if(this.NCFVML === 1){
              this.columnDefsDetalle[0].editable = false;
              this.columnDefsDetalle[1].editable = false;
              this.aggrid.refreshColumnDefs();
              }else{
              this.columnDefsDetalle[0].editable = false;
              this.columnDefsDetalle[1].editable = false;
              this.columnDefsDetalle[2].editable = false;
              this.aggrid.refreshColumnDefs();
              }
              this.aggrid.bloquearGrid()
          }
          for (const rs of data) {
            rs.TRNLOTE_FECHAVENCIMIENTO = this.datePipe.transform(rs.TRNLOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
            rs.CODIGO_AUXILIAR = 1;
            rs.CLAVE = rs.LOTE_NUMERO;
            lotes.push(rs);
          }
          this.trnloteservice.detlotearray[this.indicador] = lotes;
        });
      }
      

    }
  }

  nuevoLote() {
    if(this.NCFVML === 1){
    this.columnDefsDetalle[0].editable = true;
    this.columnDefsDetalle[1].editable = true;
    this.aggrid.refreshColumnDefs();
    this.aggrid.habilitarGrid()
    }else{
    this.columnDefsDetalle[0].editable = true;
    this.columnDefsDetalle[1].editable = true;
    this.columnDefsDetalle[2].editable = true;
    this.aggrid.refreshColumnDefs();
    this.aggrid.habilitarGrid()
    }
    const lote: InvTrnlote = {
      LOTE_NUMERO: '',
      TRNLOTE_FECHAVENCIMIENTO: '',
      TRNLOTE_DOCUMENTO: this.auxnumero,
      TRNLOTE_LINEADOC: this.auxlinea,
      TRNLOTE_CANTIDAD: 0,
      TRNLOTE_UNIDADES: 0,
      TRNLOTE_FRACCIONES: 0,
      COM_CODIGO: '01',
      TRNLOTE_ORIGEN: this.cadena,
      ART_CODIGO: this.auxcodigo,
      CODIGO_AUXILIAR: 0
    };

    const indi = this.trnloteservice.detlotearray[this.indicador].indexOf(this.loteseleccionado);
    if (indi === -1) {
      lote.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.trnloteservice.detlotearray[this.indicador].push(lote);
      this.loteseleccionado = lote;
      this.aggrid.refreshaggrid(this.trnloteservice.detlotearray[this.indicador], this.defaultColDefDetalle.objeto);
    } else {
      lote.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.trnloteservice.detlotearray[this.indicador].splice(indi + 1, 0, lote);
      this.loteseleccionado = lote;
      this.aggrid.refreshaggridindex(this.trnloteservice.detlotearray[this.indicador], this.defaultColDefDetalle.objeto, indi);
    }

    this.botonesmodificar();

  }
  //CDPJ
  async verificarSaldoLoteML(lote, artcod) {
    let dblsaldo = 0
    let dblCantidadReg = 0;
    //cantidad grabada actualmente en el documento 
    const data = await this.trnloteservice.cantidadgrabada(this.auxnumero, this.auxlinea);
    // saldo
    const data2 = await this.trnloteservice.cantidadsaldo(lote, artcod);
    if (data !== null && data !== undefined && data.length > 0) {
      for (const item of data) {
        dblCantidadReg = Number(item.TRNLOTE_CANTIDAD)
      }
    }
    if (data2 !== null && data2 !== undefined && data2.length > 0) {
      for (const item of data2) {
        dblsaldo = Number(item.SALDO)
      }
    }

    dblsaldo = dblsaldo + dblCantidadReg
    return dblsaldo;

  }
  async verificarSaldo(lote, artcod) {
    let dblsaldo = 0
    let dblCantidadReg = 0;
    //cantidad grabada actualmente en el documento 
    const data = await this.trnloteservice.cantidadgrabada(this.auxnumero, this.auxlinea);
    // saldo
    const data2 = await this.trnloteservice.cantidadsaldo2(lote, artcod);
    if (data !== null && data !== undefined && data.length > 0) {
      for (const item of data) {
        dblCantidadReg = Number(item.TRNLOTE_CANTIDAD)
      }
    }
    if (data2 !== null && data2 !== undefined && data2.length > 0) {
      for (const item of data2) {
        dblsaldo = Number(item.SALDO)
      }
    }

    dblsaldo = dblsaldo + dblCantidadReg
    return dblsaldo;

  }
  //CDPJ
  async guardarLote() {
    if (this.loteseleccionado.LOTE_NUMERO.length === 0) {
      this.messageService.add({
        key: 'trnlote',
        severity: 'error',
        summary: 'Error',
        detail: 'Código  incorrecto.'
      });
      return;
    }
    if (this.loteseleccionado.TRNLOTE_FECHAVENCIMIENTO.length === 0) {
      this.messageService.add({
        key: 'trnlote',
        severity: 'error',
        summary: 'Error',
        detail: 'Fecha incorrecta.'
      });
      return;
    }

    const VALLOTART = this.trnloteservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VALLOTART').CFG_VALOR1;
    const LOTEPRO = this.trnloteservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LOTEPRO').CFG_VALOR1;
    for (const lote of this.trnloteservice.detlotearray[this.indicador]) {
      let cantPermitida = 0;
      let dblsaldo=0;
      let dblCantidad=0;
      // if (this.trnloteservice.strOrigen[this.indicador] === "ORDPROD") {

      //   if (LOTEPRO === 1) {
      //     dblsaldo = await this.verificarSaldoLoteML(lote.LOTE_NUMERO, this.auxcodigo)
      //   } else {
      //     //const data1 = await this.verificarSaldo(lote.LOTE_NUMERO, this.auxcodigo)
      //     dblsaldo = await this.verificarSaldo(lote.LOTE_NUMERO, this.auxcodigo)
      //   }
      //   if (Number(lote.TRNLOTE_CANTIDAD) > dblsaldo) {
      //     this.messageService.add({
      //       key: 'trnlote',
      //       severity: 'error',
      //       summary: 'Error',
      //       detail: 'No existe esa cantidad'
      //     });
      //     lote.TRNLOTE_CANTIDAD = cantPermitida;
      //     this.aggrid.refreshaggrid(this.trnloteservice.detlotearray[this.indicador], 'tablalote');
      //     return;
      //   }

      // } else if (VALLOTART === 1
      //   && (this.trnloteservice.strOrigen[this.indicador] === "DEVCOM"
      //     || this.trnloteservice.strOrigen[this.indicador] === "SA"
      //     || this.trnloteservice.strOrigen[this.indicador] === "FACVEN"
      //     || this.trnloteservice.strOrigen[this.indicador] === "GUIREM"
      //     || this.trnloteservice.strOrigen[this.indicador] === "ORDPROD")) {
      //   const data = await this.trnloteservice.existencialote(lote.LOTE_NUMERO, this.auxcodigo);
      //   for (const rs of data) {
      //     cantPermitida = Number(rs.EXISTENCIA);
      //   }
      //   if (Number(lote.TRNLOTE_CANTIDAD) > cantPermitida) {
      //     this.messageService.add({
      //       key: 'trnlote',
      //       severity: 'error',
      //       summary: 'Error',
      //       detail: 'No existe esa cantidad'
      //     });
      //     lote.TRNLOTE_CANTIDAD = cantPermitida;
      //     this.aggrid.refreshaggrid(this.trnloteservice.detlotearray[this.indicador], 'tablalote');
      //     return;
      //   }
      // }
      if(this.NCFVML !== 1){
        if(lote.TRNLOTE_FECHAVENCIMIENTO === null || lote.TRNLOTE_FECHAVENCIMIENTO === undefined || lote.TRNLOTE_FECHAVENCIMIENTO === ''){
          this.messageService.add({
                  key: 'trnlote',
                  severity: 'error',
                  summary: 'Error',
                  detail: 'La fecha de vencimiento del lote está vacía'
                });
          return
        }
      }
      if(lote.LOTE_NUMERO === null || lote.LOTE_NUMERO === undefined || lote.LOTE_NUMERO === ''){
        this.messageService.add({
                key: 'trnlote',
                severity: 'error',
                summary: 'Error',
                detail: 'El número de lote está vacío'
              });
        return
      }
      if(lote.TRNLOTE_CANTIDAD === null || lote.TRNLOTE_CANTIDAD === undefined || lote.TRNLOTE_CANTIDAD === ''){
        this.messageService.add({
                key: 'trnlote',
                severity: 'error',
                summary: 'Error',
                detail: 'La cantidad del lote está vacía'
              });
        return
      }
    }
    if (this.trnloteservice.strOrigen[this.indicador] === "ORDPROD") {
      //eliminar de la prod_trnlote
      await this.trnloteservice.eliminarprodtrnlote(this.auxnumero,this.auxlinea)


    }
    for (const lote of this.trnloteservice.detlotearray[this.indicador]) {
      lote.TRNLOTE_CANTIDAD = Number(lote.TRNLOTE_CANTIDAD);
      if (this.trnloteservice.strOrigen[this.indicador] === "ORDPROD") {
        await this.trnloteservice.insertarprodtrnlote(lote);
        this.messageService.add({
          key: 'trnlote',
          severity: 'success',
          summary: 'Información',
          detail: 'Lote ' + lote.LOTE_NUMERO + ' guardado.'
        });
      }else{
        if (lote.CODIGO_AUXILIAR === 0) {
          this.trnloteservice.insertarTrnLote(lote).subscribe(data => {
            this.messageService.add({
              key: 'trnlote',
              severity: 'success',
              summary: 'Información',
              detail: 'Lote ' + lote.LOTE_NUMERO + ' guardado.'
            });
          });
          this.trnloteservice.detlotearray[this.indicador][this.trnloteservice.detlotearray[this.indicador].findIndex(lote =>
            lote.CLAVE === lote.CLAVE)].CODIGO_AUXILIAR = 1;
          lote.CODIGO_AUXILIAR = 1;
        } else if (lote.CODIGO_AUXILIAR === 1) {
          this.trnloteservice.actualizarTrnLote(lote).subscribe(data => {
            this.messageService.add({
              key: 'trnlote',
              severity: 'success',
              summary: 'Información',
              detail: 'Lote ' + lote.LOTE_NUMERO + ' actualizado.'
            });
          });
        }
      }
    }
    await this.mostrarLotes();
    /*this.loteseleccionado.TRNLOTE_CANTIDAD = Number(this.loteseleccionado.TRNLOTE_CANTIDAD);
        if (this.loteseleccionado.CODIGO_AUXILIAR === 0) {
          this.trnloteservice.insertarTrnLote(this.loteseleccionado).subscribe(data => {
            this.messageService.add({
              key: 'trnlote',
              severity: 'success',
              summary: 'Información',
              detail: 'Lote guardado.'
            });
          });
          this.trnloteservice.detlote[this.trnloteservice.detlote.findIndex(lote =>
            lote.CLAVE === this.loteseleccionado.CLAVE)].CODIGO_AUXILIAR = 1;
          this.loteseleccionado.CODIGO_AUXILIAR = 1;
        } else if (this.loteseleccionado.CODIGO_AUXILIAR === 1) {
          this.trnloteservice.actualizarTrnLote(this.loteseleccionado).subscribe(data => {
            this.messageService.add({
              key: 'trnlote',
              severity: 'success',
              summary: 'Información',
              detail: 'Lote actualizado.'
            });
          });
        }*/
  }

  eliminarLote() {
    if (this.trnloteservice.strOrigen[this.indicador] === "ORDPROD") {
      this.trnloteservice.eliminarTrnLoteprod(this.loteseleccionado).subscribe(data => {
        this.messageService.add({
          key: 'trnlote',
          severity: 'success',
          summary: 'Información',
          detail: 'Lote eliminado.'
        });
        this.mostrarLotes();
      });

    }else{
      this.trnloteservice.eliminarTrnLote(this.loteseleccionado).subscribe(data => {
        this.messageService.add({
          key: 'trnlote',
          severity: 'success',
          summary: 'Información',
          detail: 'Lote eliminado.'
        });
        this.mostrarLotes();
      });
    }
    
  }

  seleccionlote(params) {
    if (params.object === undefined) {
      return;
    }

    this.loteseleccionado = params.object;
  }

  botonesmodificar() {
    this.botonNuevo = this.desactivado;
    this.botonGuardar = this.activado;
    this.botonBorrar = this.desactivado;
    this.botonRegresar = this.activado;
  }

  validarSalir(): boolean {
    let lblCantidad = 0;
    //CDPJ
    if (this.trnloteservice.detlotearray[this.indicador].length === 0) {
      return true
    }
    //CDPJ
    for (const item of this.trnloteservice.detlotearray[this.indicador]) {
      lblCantidad = lblCantidad + Number(item.TRNLOTE_CANTIDAD);
    }

    if (this.cadena === "FACVEN") {
      if (this.botonGuardar === this.activado) {
        this.messageService.add({
          key: 'trnlote',
          severity: 'warn',
          summary: 'Información',
          detail: 'Guarde los lotes para salir'
        });
        return false;
      }

      if (this.dblCantidad != lblCantidad) {
        this.messageService.add({
          key: 'trnlote',
          severity: 'error',
          summary: 'Información',
          detail: 'La cantidad ingresada no coincide con el detalle de la factura (' + this.dblCantidad + ')'
        });
        return false;
      }
    }

    if (this.cadena === "GUIREM") {
      if (this.botonGuardar === this.activado) {
        this.messageService.add({
          key: 'trnlote',
          severity: 'warn',
          summary: 'Información',
          detail: 'Guarde los lotes para salir'
        });
        return false;
      }

      if (this.dblCantidad != lblCantidad) {
        this.messageService.add({
          key: 'trnlote',
          severity: 'error',
          summary: 'Información',
          detail: 'La cantidad ingresada no coincide con el detalle de la guía de remisión (' + this.dblCantidad + ')'
        });
        return false;
      }
    }

    return true;

  }
}
