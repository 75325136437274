import { Component, EventEmitter, OnInit, Output } from '@angular/core';


import { NomcfgrgenService } from '../nomservicios/nomcfgrgen.service';
import { NomCfgrgen } from '../nominterfaces/nomcfgrgen';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { MessageService } from 'primeng';
@Component({
  selector: 'app-nomcfgrgen',
  templateUrl: './nomcfgrgen.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomcfgrgenComponent implements OnInit {
  @Output() salir = new EventEmitter<boolean>();
  btnGuardar = true;
  botonRegresar: boolean;
  btnRegresar: boolean;
  cargabool: boolean;
  nomcfgrgen: NomCfgrgen = {
    NOMAPIND: '',
    NOMAPPATR: '',
    NOMPRFNRSV: '',
    NOMSUEBAS: '',
    NOMFCHE100: '',
    NOMFCHE50: '',
    NOMFCHE25: '',
    NOMHOTRME: '',
    NOMDIASMES: '',
    TXTNOMENVCC: '',
    NOMCARE: false,
    NOMCDRES: false,
    NOMCDRPN: false,
    NOMESP100: false,
    NOMIESS25: false,
    NOMENVCC: false,
    SEPEREXDAT:false
  };



  constructor(private cfgrgenService: NomcfgrgenService,
    private init: NuevoComponentService,
    private errorService: ErroresBaseDatosService,
    private messageService: MessageService, ) { }

  ngOnInit() {
    this.btnRegresar = false;
    this.getDataNomina();
    this.cargabool = false;
  }

  async getDataNomina() {
    /**
     * ===================        DECLARAR PROMESAS   ============================
     */
    const getNOMAPIND = await this.cfgrgenService.cargaDatos('NOMAPIND').then(nomapind => {
      const NOMAPIND = nomapind[0].CFG_VALOR1.toFixed(2);
      return NOMAPIND;
    });

    const getNOMAPPATR = await this.cfgrgenService.cargaDatos('NOMAPPATR').then(nomappatr => {
      const NOMAPPATR = nomappatr[0].CFG_VALOR1.toFixed(2);
      return NOMAPPATR;
    });

    const getNOMPRFNRSV = await this.cfgrgenService.cargaDatos('NOMPRFNRSV').then(nomprfnrsv => {
      const NOMPRFNRSV = nomprfnrsv[0].CFG_VALOR1.toFixed(2);
      return NOMPRFNRSV;
    });

    const getNOMSUEBAS = await this.cfgrgenService.cargaDatos('NOMSUEBAS').then(nomsuebas => {
      const NOMSUEBAS = nomsuebas[0].CFG_VALOR1.toFixed(2);
      return NOMSUEBAS;
    });

    const getNOMFCHE100 = await this.cfgrgenService.cargaDatos('NOMFCHE100').then(nomfche100 => {
      const NOMFCHE100 = nomfche100[0].CFG_VALOR1.toFixed(2);
      return NOMFCHE100;
    });

    const getNOMFCHE50 = await this.cfgrgenService.cargaDatos('NOMFCHE50').then(nompfche50 => {
      const NOMFCHE50 = nompfche50[0].CFG_VALOR1.toFixed(2);
      return NOMFCHE50;
    });

    const getNOMFCHE25 = await this.cfgrgenService.cargaDatos('NOMFCHE25').then(nompfche25 => {
      const NOMFCHE25 = nompfche25[0].CFG_VALOR1.toFixed(2);
      return NOMFCHE25;
    });

    const getNOMHOTRME = await this.cfgrgenService.cargaDatos('NOMHOTRME').then(nomphotrme => {
      const NOMHOTRME = nomphotrme[0].CFG_VALOR1.toFixed(2);
      return NOMHOTRME;
    })

    const getNOMDIASMES = await this.cfgrgenService.cargaDatos('NOMDIASMES').then(nomdiasmes => {
      const NOMDIASMES = nomdiasmes[0].CFG_VALOR1.toFixed(2);
      return NOMDIASMES;
    })

    const getNOMCARE = await this.cfgrgenService.cargaDatos('NOMCARE').then(nomcares => {
      const NOMCARE = (nomcares[0].CFG_VALOR1 === 1) ? true : false;
      return NOMCARE;
    })

    const getNOMCDRES = await this.cfgrgenService.cargaDatos('NOMCDRES').then(nomcdres => {
      const NOMCDRES = (nomcdres[0].CFG_VALOR1 === 1) ? true : false;
      return NOMCDRES;
    })

    const getNOMCDRPN = await this.cfgrgenService.cargaDatos('NOMCDRPN').then(nomcdrpn => {
      const NOMCDRPN = (nomcdrpn[0].CFG_VALOR1 === 1) ? true : false;
      return NOMCDRPN;
    })

    const getNOMESP100 = await this.cfgrgenService.cargaDatos('NOMESP100').then(nomesp100 => {
      const NOMESP100 = (nomesp100[0].CFG_VALOR1 === 1) ? true : false;
      return NOMESP100;
    })

    const getNOMIESS25 = await this.cfgrgenService.cargaDatos('NOMIESS25').then(nomiess25 => {
      const NOMIESS25 = (nomiess25[0].CFG_VALOR1 === 1) ? true : false;
      return NOMIESS25;
    })
    //CDPJ
    const getNOMENVCC = await this.cfgrgenService.cargaDatos('NOMENVCC').then(nomenvcc => {
      const NOMENVCC = (nomenvcc[0].CFG_VALOR1 === 1) ? true : false;
      return NOMENVCC;
    })
    const getTXTNOMENVCC = await this.cfgrgenService.cargaDatos('NOMENVCC').then(txtnomenvcc => {
      const TXTNOMENVCC = (txtnomenvcc[0].CFG_VALOR3 === null || txtnomenvcc[0].CFG_VALOR3 === undefined) ? '' : txtnomenvcc[0].CFG_VALOR3;
      return TXTNOMENVCC;
    })
    const getSEPEREXDAT = await this.cfgrgenService.cargaDatos('SEPEREXDAT').then(seperexdat => {
      const SEPEREXDAT = (seperexdat[0].CFG_VALOR1 === 1) ? true : false;
      return SEPEREXDAT;
    })
    //CDPJ

    /**
     *  =============    EJECUTAR PROMESAS ==============
     */
    Promise.all([ // asignar el retorno de cada promesa a un array
      getNOMAPIND,    // 0
      getNOMAPPATR,   // 1
      getNOMPRFNRSV,  // 2
      getNOMSUEBAS,   // 3
      getNOMFCHE100,  // 4
      getNOMFCHE50,   // 5
      getNOMFCHE25,   // 6
      getNOMHOTRME,   // 7
      getNOMDIASMES,  // 8
      getNOMCARE,     // 9
      getNOMCDRES,    // 10
      getNOMCDRPN,    // 11
      getNOMESP100,   // 12
      getNOMIESS25,    // 13
      getNOMENVCC,    // 14
      getTXTNOMENVCC, // 15
      getSEPEREXDAT,  //16 

    ]).then(values => {
      this.nomcfgrgen.NOMAPIND = values[0];
      this.nomcfgrgen.NOMAPPATR = values[1];
      this.nomcfgrgen.NOMPRFNRSV = values[2];
      this.nomcfgrgen.NOMSUEBAS = values[3];
      this.nomcfgrgen.NOMFCHE100 = values[4];
      this.nomcfgrgen.NOMFCHE50 = values[5];
      this.nomcfgrgen.NOMFCHE25 = values[6];
      this.nomcfgrgen.NOMHOTRME = values[7];
      this.nomcfgrgen.NOMDIASMES = values[8];
      this.nomcfgrgen.NOMCARE = values[9];
      this.nomcfgrgen.NOMCDRES = values[10];
      this.nomcfgrgen.NOMCDRPN = values[11];
      this.nomcfgrgen.NOMESP100 = values[12];
      this.nomcfgrgen.NOMIESS25 = values[13];
      this.nomcfgrgen.NOMENVCC = values[14];
      this.nomcfgrgen.TXTNOMENVCC = values[15];
      this.nomcfgrgen.SEPEREXDAT = values[16];
      this.cargabool = true;
    })
      .catch(err => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.menssajeAlerta('error', 'Error', mensaje);
      });
  }


  manejarSenales(valor) {
    if (valor === 'Guardar') {
      this.actualizarTabla();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  async actualizarTabla() {



    /**
     * ===================        DECLARAR PROMESAS   ============================
     */
    const postNOMAPIND = await this.cfgrgenService.actulizarDatos('NOMAPIND', Number(this.nomcfgrgen.NOMAPIND))
      .then(nomapind => { return nomapind; });

    const postNOMAPPATR = await this.cfgrgenService.actulizarDatos('NOMAPPATR', Number(this.nomcfgrgen.NOMAPPATR)).then(nomappatr => {
      return nomappatr;
    });

    const postNOMPRFNRSV = await this.cfgrgenService.actulizarDatos('NOMPRFNRSV', Number(this.nomcfgrgen.NOMPRFNRSV)).then(nomprfnrsv => {
      return nomprfnrsv;
    });

    const postNOMSUEBAS = await this.cfgrgenService.actulizarDatos('NOMSUEBAS', Number(this.nomcfgrgen.NOMSUEBAS)).then(nomsuebas => {
      return nomsuebas;
    });

    const postNOMFCHE100 = await this.cfgrgenService.actulizarDatos('NOMFCHE100', Number(this.nomcfgrgen.NOMFCHE100)).then(nomfche100 => {
      return nomfche100;
    });

    const postNOMFCHE50 = await this.cfgrgenService.actulizarDatos('NOMFCHE50', Number(this.nomcfgrgen.NOMFCHE50)).then(nompfche50 => {
      return nompfche50;
    });

    const postNOMFCHE25 = await this.cfgrgenService.actulizarDatos('NOMFCHE25', Number(this.nomcfgrgen.NOMFCHE25)).then(nompfche25 => {
      return nompfche25;
    });

    const postNOMHOTRME = await this.cfgrgenService.actulizarDatos('NOMHOTRME', Number(this.nomcfgrgen.NOMHOTRME)).then(nomphotrme => {
      return nomphotrme;
    })

    const postNOMDIASMES = await this.cfgrgenService.actulizarDatos('NOMDIASMES', Number(this.nomcfgrgen.NOMDIASMES)).then(nomdiasmes => {
      return nomdiasmes;
    })

    const postNOMCARE = await this.cfgrgenService.actulizarDatos('NOMCARE', Number(this.nomcfgrgen.NOMCARE)).then(nomcares => {
      return nomcares;
    })

    const postNOMCDRES = await this.cfgrgenService.actulizarDatos('NOMCDRES', Number(this.nomcfgrgen.NOMCDRES)).then(nomcdres => {
      return nomcdres;
    })

    const postNOMCDRPN = await this.cfgrgenService.actulizarDatos('NOMCDRPN', Number(this.nomcfgrgen.NOMCDRPN)).then(nomcdrpn => {
      return nomcdrpn;
    })

    const postNOMESP100 = await this.cfgrgenService.actulizarDatos('NOMESP100', Number(this.nomcfgrgen.NOMESP100)).then(nomesp100 => {
      return nomesp100;
    })

    const postNOMIESS25 = await this.cfgrgenService.actulizarDatos('NOMIESS25', Number(this.nomcfgrgen.NOMIESS25)).then(nomiess25 => {
      return nomiess25;
    })

    const postNOMENVCC = await this.cfgrgenService.actulizarDatos('NOMENVCC', Number(this.nomcfgrgen.NOMENVCC)).then(nomenvcc => {
      return nomenvcc;
    })
    const postTXTNOMENVCC = await this.cfgrgenService.actulizarDatos2('NOMENVCC', this.nomcfgrgen.TXTNOMENVCC).then(txtnomenvcc => {
      return txtnomenvcc;
    })
    const postSEPEREXDAT = await this.cfgrgenService.actulizarDatos('SEPEREXDAT', Number(this.nomcfgrgen.SEPEREXDAT)).then(seperexdat => {
      return seperexdat;
    })
    /**
     *  =============    EJECUTAR PROMESAS ==============
     */
    Promise.all([ // asignar el retorno de cada promesa a un array
      postNOMAPIND,    // 0
      postNOMAPPATR,   // 1
      postNOMPRFNRSV,  // 2
      postNOMSUEBAS,   // 3
      postNOMFCHE100,  // 4
      postNOMFCHE50,   // 5
      postNOMFCHE25,   // 6
      postNOMHOTRME,   // 7
      postNOMDIASMES,  // 8
      postNOMCARE,     // 9
      postNOMCDRES,    // 10
      postNOMCDRPN,    // 11
      postNOMESP100,   // 12
      postNOMIESS25,    // 13
      postNOMENVCC,      //14
      postTXTNOMENVCC,   //15
      postSEPEREXDAT //16

    ]).then(values => {
      this.menssajeAlerta('success', 'Información', ' ¡ Cambios guardados exitosamente !');
      this.cargar();
    })
      .catch(err => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.menssajeAlerta('error', 'Error', mensaje);
      });
  }

  cambio(event) {
    this.btnGuardar = false;
  }

  cargar() {
    this.btnGuardar = true;
    this.btnRegresar = false;
    this.getDataNomina();
  }

  menssajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'nomcf',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });

  }
}
