import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { InvTrnkardexotr } from '../veninterfaces/invtrnkardexotr';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvtrnkardexotrService {

  public url: string;
  private _tablaseriales: InvTrnkardexotr[];
  private _auxsalto: boolean;
  public way: string;

  constructor(private http: HttpClient,
    private confIniciales: ConfInicialesService,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  /*
  get tablaseriales(): InvTrnkardexotr[] {
    return this._tablaseriales;
  }
  
  set tablaseriales(value: InvTrnkardexotr[]) {
    this._tablaseriales = value;
  }*/

  get auxsalto(): boolean {
    return this._auxsalto;
  }

  set auxsalto(value: boolean) {
    this._auxsalto = value;
  }




  consultarArtículos(strTipoDoc, strNumDoc): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/vqxe1ul36aam9co', {
      elementos: {
        TipoDoc: strTipoDoc,
        NumDoc: strNumDoc
      },
    }, this.confIniciales.httpOptions());
  }


  consultarDetalle(strartCodigo, strNumDoc, strTipoDoc, strintLineaDetalle): Observable<any[]> {
    if (strTipoDoc === 'TRF') {
      strNumDoc = strNumDoc + '_SA';
    }
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/tfqo67dzhgczdeb', {
      elementos: {
        artCodigo: strartCodigo,
        NumDoc: strNumDoc,
        TipoDoc: strTipoDoc,
        intLineaDetalle: strintLineaDetalle
      },
    }, this.confIniciales.httpOptions());
  }

  consultarDetalleDev(strartCodigo, strNumDoc, strTipoDoc): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/jatu8kchkp4lryn', {
      elementos: {
        artCodigo: strartCodigo,
        NumDoc: strNumDoc,
        TipoDoc: strTipoDoc,

      },
    }, this.confIniciales.httpOptions());
  }
  consultarDetalleDevFac(strartCodigo, strNumDoc, strTipoDoc): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/17ee315b6bbaab000000000', {
      elementos: {
        artCodigo: strartCodigo,
        NumDoc: strNumDoc,
        TipoDoc: strTipoDoc,

      },
    }, this.confIniciales.httpOptions());
  }
  esExisteSerial(strTRNKARDEXOTR_NUMSERIE, strART_CODIGO) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/s56s90vuop5ss7a', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: strTRNKARDEXOTR_NUMSERIE,
        ART_CODIGO: strART_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  esvalExisteSerial(strTipoDoc, strNumDoc, item) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/73v87jjzdqwhkre', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: item.TRNKARDEXOTR_NUMSERIE,
        TRNKARDEXOTR_DIM: item.TRNKARDEXOTR_DIM,
        BOD_CODIGO: item.BOD_CODIGO,
        TRNKARDEXOTR_LINEA: item.TRNKARDEXOTR_LINEA,
        ART_CODIGO: item.ART_CODIGO,
        TRNKARDEXOTR_TELEFONO: item.TRNKARDEXOTR_TELEFONO,
        TipoDoc: strTipoDoc,
        NumDoc: strNumDoc
      },
    }, this.confIniciales.httpOptions());
  }
  esvalSalidaSerial(strTRNKARDEXOTR_NUMSERIE, strART_CODIGO, strBOD_CODIGO) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/4dxbktx99cg88jj', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: strTRNKARDEXOTR_NUMSERIE,
        ART_CODIGO: strART_CODIGO,
        BOD_CODIGO: strBOD_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  esvalidaExistenciaSerialDimSalida(strTipoDoc, strNumDoc, item) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/45g917nk5be4q43', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: item.TRNKARDEXOTR_NUMSERIE,
        TRNKARDEXOTR_DIM: item.TRNKARDEXOTR_DIM,
        BOD_CODIGO: item.BOD_CODIGO,
        TRNKARDEXOTR_LINEA: item.TRNKARDEXOTR_LINEA,
        ART_CODIGO: item.ART_CODIGO,
        TRNKARDEXOTR_TELEFONO: item.TRNKARDEXOTR_TELEFONO,
        TipoDoc: strTipoDoc,
        NumDoc: strNumDoc
      },
    }, this.confIniciales.httpOptions());
  }
  esValiexiserialdimsal(strART_CODIGO, strTRNKARDEXOTR_NUMSERIE) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/dxnuxesoti687t9', {
      elementos: {
        ART_CODIGO: strART_CODIGO,
        TRNKARDEXOTR_NUMSERIE: strTRNKARDEXOTR_NUMSERIE
      },
    }, this.confIniciales.httpOptions());
  }
  esValExisciaSerDimSalCu(strTipoDoc, strNumDoc, item) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/x3tqnf3jx91f6b2', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: item.TRNKARDEXOTR_NUMSERIE,
        TRNKARDEXOTR_DIM: item.TRNKARDEXOTR_DIM,
        BOD_CODIGO: item.BOD_CODIGO,
        TRNKARDEXOTR_LINEA: item.TRNKARDEXOTR_LINEA,
        ART_CODIGO: item.ART_CODIGO,
        TRNKARDEXOTR_TELEFONO: item.TRNKARDEXOTR_TELEFONO,
        TipoDoc: strTipoDoc,
        NumDoc: strNumDoc
      },
    }, this.confIniciales.httpOptions());

  }
  esVaExisciaSerDiSalCu2(strART_CODIGO, strTRNKARDEXOTR_NUMSERIE) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/832t4knv6e8ycka', {
      elementos: {
        ART_CODIGO: strART_CODIGO,
        TRNKARDEXOTR_NUMSERIE: strTRNKARDEXOTR_NUMSERIE
      },
    }, this.confIniciales.httpOptions());
  }
  esVaExsciaSerDiSalCu3(strTipoDoc, strNumDoc, item) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/xf1c0xiua7hxt7n', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: item.TRNKARDEXOTR_NUMSERIE,
        TRNKARDEXOTR_DIM: item.TRNKARDEXOTR_DIM,
        BOD_CODIGO: item.BOD_CODIGO,
        TRNKARDEXOTR_LINEA: item.TRNKARDEXOTR_LINEA,
        ART_CODIGO: item.ART_CODIGO,
        TRNKARDEXOTR_TELEFONO: item.TRNKARDEXOTR_TELEFONO,
        TipoDoc: strTipoDoc,
        NumDoc: strNumDoc
      },
    }, this.confIniciales.httpOptions());

  }
  esValExsciaSerDiSalCu4(strART_CODIGO, strTRNKARDEXOTR_NUMSERIE) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/g35u17sxkgdxvsn', {
      elementos: {
        ART_CODIGO: strART_CODIGO,
        TRNKARDEXOTR_NUMSERIE: strTRNKARDEXOTR_NUMSERIE
      },
    }, this.confIniciales.httpOptions());

  }

  valiExistSerDimEntradaCu(strTipoDoc, strNumDoc, item) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/bfkmeu35txpt062', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: item.TRNKARDEXOTR_NUMSERIE,
        TRNKARDEXOTR_DIM: item.TRNKARDEXOTR_DIM,
        BOD_CODIGO: item.BOD_CODIGO,
        TRNKARDEXOTR_LINEA: item.TRNKARDEXOTR_LINEA,
        ART_CODIGO: item.ART_CODIGO,
        TRNKARDEXOTR_TELEFONO: item.TRNKARDEXOTR_TELEFONO,
        TipoDoc: strTipoDoc,
        NumDoc: strNumDoc
      },
    }, this.confIniciales.httpOptions());

  }

  validExistSerDimEntradCu5(strTipoDoc, strNumDoc, item) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/ojzfe8o1aine5tu', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: item.TRNKARDEXOTR_NUMSERIE,
        TRNKARDEXOTR_DIM: item.TRNKARDEXOTR_DIM,
        BOD_CODIGO: item.BOD_CODIGO,
        TRNKARDEXOTR_LINEA: item.TRNKARDEXOTR_LINEA,
        ART_CODIGO: item.ART_CODIGO,
        TRNKARDEXOTR_TELEFONO: item.TRNKARDEXOTR_TELEFONO,
        TipoDoc: strTipoDoc,
        NumDoc: strNumDoc
      },
    }, this.confIniciales.httpOptions());

  }
  validarDatosArticulo(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/4tnm1d2htckdixl', {
      elementos: {
        ArtCodigo: strArtCodigo
      },
    }, this.confIniciales.httpOptions());
  }


  insertarKardexOtr(item, strTipoDoc, fecha): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/ip3zy7ligsia8jc', {
      elementos: {
        p_trnkardexotr_numero: item.TRNKARDEXOTR_NUMERO,
        p_trnkardexotr_numsec: item.TRNKARDEXOTR_NUMSEC,
        p_com_codigo: item.COM_CODIGO,
        p_bod_codigo: item.BOD_CODIGO,
        p_art_codigo: item.ART_CODIGO,
        p_trnkardexotr_numserie: item.TRNKARDEXOTR_NUMSERIE,
        p_trnkardexotr_dim: item.TRNKARDEXOTR_DIM,
        p_trnkardexotr_tipo: item.TRNKARDEXOTR_TIPO,
        p_trnkardexotr_origen: strTipoDoc,
        p_trnkardexotr_linea: item.TRNKARDEXOTR_LINEA,
        p_trnkardexotr_telefono: item.TRNKARDEXOTR_TELEFONO,
        p_trnkardexotr_fechatrn: fecha,

      },
    }, this.confIniciales.httpOptions());
  }

  eliminarKardexOtr(item): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/4o41242kk42xf8x', {
      elementos: {
        p_trnkardexotr_numero: item.TRNKARDEXOTR_NUMERO,
        p_trnkardexotr_numsec: item.TRNKARDEXOTR_NUMSEC,
        p_com_codigo: item.COM_CODIGO,
        p_trnkardexotr_tipo: item.TRNKARDEXOTR_TIPO

      },
    }, this.confIniciales.httpOptions());
  }
  eliminarKardexOtrAll(item,strdoc): Observable<any[]> {
    console.log(item)
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/3ab2b4aeaeeef400000', {
      elementos: {
        p_trnkardexotr_numero: strdoc,
        p_trnkardexotr_art: item.ARTCODIGO,
        p_trnkardexotr_bod: item.BODCODIGO,
        p_trnkardexotr_linea:item.NUMLINEA,
        p_com_codigo: '01',


      },
    }, this.confIniciales.httpOptions());
  }

  extraerArticuloPorSerie(item): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/qj4xrgfvpaj8v6s', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: item.TRNKARDEXOTR_NUMSERIE

      },
    }, this.confIniciales.httpOptions());
  }
}




