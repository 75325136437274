import { Component, OnInit, ViewChild } from '@angular/core';
import { ComEncfacpro } from '../cominterfaces/comencfacpro';
import { ComDetfacpro } from '../cominterfaces/comdetfacpro';
import { SaciTrnretencion } from '../../interfaces/sacitrnretencion';
import { CxpTrncobro } from '../cominterfaces/cxptrncobro';
import { Cxctmptrnresumen } from '../../ven/veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../../ven/veninterfaces/cxctrnresumen';
import { ComTrncargos } from '../cominterfaces/comtrncargos';
import { Eletrndocumento } from '../../ven/veninterfaces/eletrndocumento';
import { SubirXml } from '../cominterfaces/subirxml';
import { SegMaeCompania } from '../../ven/veninterfaces/segmaecompania';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { ComencfacproService } from '../comservicios/comencfacpro.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { CommaeproveedorService } from '../comservicios/commaeproveedor.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { DatosanexosComponent } from '../datosanexos/datosanexos.component';

// Ag-grid
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ComExistenciaBodegas, ComExistencias, ComMovimientoProv, ComTopProductos } from '../cominterfaces/comestadisticas';
import { ComestadisticasService } from '../comservicios/comestadisticas.service';
import { Usuario } from 'src/app/usuario';
import { Comencfacprobasesiva } from "../cominterfaces/comencfacprobasesiva";

@Component({
  selector: 'app-comencfacpro',
  templateUrl: './comencfacpro.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ComencfacproComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  // @ts-ignore
  @ViewChild(DatosanexosComponent) datosanexos: DatosanexosComponent;

  strFacturacionElect: string;
  indicador: any;

  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonFactura: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;

  index: number;
  //CDPJ
  botonNuevoGrid: boolean
  botonBorrarGrid: boolean
  botonRegresarGrid: boolean
  botonOrdenCompraGrid: boolean

  habilitarbotonesfac: boolean;
  habilitarVisto: boolean;
  boolfacturaElec//CDPJ
  displayDialogEst: boolean//CDPJ
  estfirma: string//CDPJ
  control: number = 0
  selecttipoiva: any;
  basesiva: Comencfacprobasesiva[] = [];
  displaybasesiva: boolean = false;
  defaultColBasesIva
  ivavigente: number;
  CENREPDOCE: number;//CDPJ
  //CDPJ
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  consulta: string;
  tabla: string;
  where: string;
  opcionbusqueda: string;

  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogDescuento: boolean;
  displayDialogDatosAnexos: boolean;
  strtotalfac
  displayDialogAutorizacion: boolean;
  displayDialogAsiento: boolean;
  displayDialogSeriales: boolean;
  displayDialogActualizacionDE: boolean;
  displayDialogSupervisor: boolean;
  displayDialogDistribucion: boolean;
  displayDialogPagoDocumento: boolean;
  displayDialogReembolsos: boolean;
  displayDialogActualizacionPrecios: boolean;
  displayDialogLog: boolean;
  displayDialogCargarRetElec: boolean;
  displayDialogSubirXmlFactura: boolean;
  displayDialogSubirXmlFacturaFormato1: boolean;
  displayDialogSubirXmlFacturaFormato2: boolean;
  displayAsiContable: boolean;
  displayDialogReportes: boolean;
  displayDialogLotes: boolean;
  displayDialogDocumentos = false;
  spin = false;

  claveAuth: string;
  autorizacionCodigo: string;
  auxiliarAutorizacion: string;
  auxiliarlot: number = 0;

  usuarioSup: string;
  claveSup: string;
  strDialogo: string;
  strObjeto: string;
  intPestaniaCol: number;

  strEncOrdCom: string;

  asiento: any;
  strNumFact: any;
  strFechaSer: any;

  txtCorreo1: string;
  txtCorreo2: string;

  cmbiva: any[];
  cmbivaaux: any[];

  cmbserie: any[];
  cmbserieaux: any[];

  chkgasto: boolean;
  chknoobjiva: boolean;
  chkDisCargosSobreCostos: boolean;

  numeroAutorizacion: string;

  largo: string;
  tipodetalle: string;
  TIPDET: string;
  comboOpciones: any[];
  ultimabodegacod: string;
  ultimabodeganame: string;
  hmpConfiguracion: any[];
  nuevoItem: ComDetfacpro;
  nuevoItemRet: SaciTrnretencion;
  detfacproSelected: ComDetfacpro;
  trnretencionSelected: SaciTrnretencion;

  displayAcciones: boolean;

  booleanValor: boolean;
  booleanPorcentaje: boolean;
  valorDscValor: number;
  valorDscPorcentaje: number;
  dscSeleccionado: string;

  dscClienteActual: number;

  bolverificarIngresoTG: boolean;
  bolcmbSerie: boolean;
  bolNumRet: boolean;
  nuevoret: boolean;
  bolProveedor: boolean;
  bolSerie: boolean;
  bolComprobante: boolean;
  bolAnularRetencion: boolean;

  NUMITEMFAC: number;
  FACCANUND: number;
  strCODRET1: string;
  strCODRET2: string;
  strCODRET3: string;
  strCODRET4: string;
  strCODRET5: string;
  strCODRET6: string;

  strTransporte: string;
  bolTransporte: boolean;
  strCodCuentaProv: string;
  mensajekardex: string = '';

  largoestcta: string;
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];

  cargoSelected: ComTrncargos;

  logResultado: string;

  bodcodigoxml: string;
  bodnombrexml: string;
  idcreditoxml: string;
  idcreditonombrexml: string;
  centrocostosxml: string;
  centrocostosnombrexml: string;
  localizacionxml2: string;
  numerobloquearchivosxml: string;
  strnumerobloquearchivosxml: string;
  lblAplicaRegMicroempresas: string;
  lblMensajeCRM: string;

  fileReader: FileReader;
  fileXml: any;
  strDatosXml: string[];
  filesarray: any[];
  subirxml: SubirXml[];

  invisible: boolean;
  bolestcta: boolean;
  bolsaldo: boolean;

  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  rowStyleEC;
  rowStyleSaldo;
  defaultColDef;
  defaultColDefRet;
  defaultColDefCargos;
  defaultColDefSubirXml;

  // ESTADISTICAS

  detTopProductos: ComTopProductos[] = [];
  dataComprasProveedor: any;
  dataVentasVsCompras: any;
  disabledEstadisticas = true;
  options = {
    legend: {
      display: false
    }
  };
  defaultColTopProductos;
  defaultColMovByProv;
  defaultColExistBod;
  rowStyleTopProd;
  detalleExistencia: ComExistencias[] = [];
  detMovimientosProv: ComMovimientoProv[] = [];
  detExistenciaBodegas: ComExistenciaBodegas[] = [];
  txtArtCodigo = '';
  txtArtDescripcion = '';
  txtArtUnidad = '';
  txtArtRotacion = '';
  txtExistenciaMin: any = '';
  txtExistenciaMax: any = '';
  txtInventarioIni: any = '';
  txtComprasPeriodo: any = '';
  txtTotalDisponible: any = '';
  txtVenUltimoPer: any = '';
  txtVenPromPerAnt: any = '';
  txtIndiceProvUltPer: any = '';
  txtIndiceProvPeriodos: any = '';
  txtComprasPlanificadas: any = '';
  txtTotalDisponibleCom: any = '';
  txtIndiceProvUltPer2: any = '';
  txtIndiceProvPeriodos2: any = '';

  filesImportXML = [];
  porcentaje = 0;

  columnDefsDetfac = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Código', field: 'DETFACPRO_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      //GSRF
      cellStyle: (params) => {
        const color = this.colorearCantidad(params.data);
        return { background: color, textAlign: 'right' };
      },
      //GSRF
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Descripción', field: 'DETFACPRO_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 200
        }
      }
    },
    { // 3
      headerName: 'Iva', field: 'DETFACPRO_PORIVA', cellEditor: 'cellPrueba', width: 60,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true,
          opcionbuscar: true
        }
      }
    },
    { // 4
      headerName: 'Ice', field: 'DETFACPRO_TRIBICE', cellEditor: 'cellPrueba', width: 60,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tienecalculos: true,
          tamanocelda: 1,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/
        }
      }
    },
    { // 5
      headerName: 'Unidad', field: 'DETFACPRO_UNIDAD', cellEditor: 'agSelectCellEditor',
      editable: (params) => {
        const boolEdit = this.isEditFPUND(params.data);
        return boolEdit;
      },
      width: 80,
      cellEditorParams:
      {
        values: []
      }
    },
    { // 6
      headerName: 'Cantidad', field: 'DETFACPRO_CANTIDAD', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      onCellClicked: (params) => {
        this.abrirLotes(params.data);
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 8,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 7
      headerName: 'Costo', field: 'DETFACPRO_COSTO', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAllnotrec(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 8
      headerName: 'Desc. ($)', field: 'DETFACPRO_VALDES', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 9
      headerName: 'Desc. (%)', field: 'DETFACPRO_PORDES', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 100,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 3,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 10
      headerName: 'Dsc2(%)', field: 'DETFACPRO_PORDES2', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 11
      headerName: 'Dsc3(%)', field: 'DETFACPRO_PORDES3', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 12
      headerName: 'Factor', field: 'DETFACPRO_FACTOR', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 13
      headerName: 'Total', field: 'DETFACPRO_TOTAL', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 14
      headerName: 'Prom.', field: 'DETFACPRO_PROMOCION', cellEditor: 'cellPrueba',
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 15
      headerName: 'C.Costo', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 16
      headerName: 'Esquema', field: 'DETFACPRO_ESQUEMADOC', cellEditor: 'cellPrueba',
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 17
      headerName: 'Tipo', field: 'DETFACPRO_TIPOSRI', cellEditor: 'cellPrueba', width: 70,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 18
      headerName: 'PrecioA', field: 'DETFACPRO_PRECIOA', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 19
      headerName: 'PrecioB', field: 'DETFACPRO_PRECIOB', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 20
      headerName: 'PrecioC', field: 'DETFACPRO_PRECIOC', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 21
      headerName: 'B.NoObjIva', field: 'DETFACPRO_TRIBASENOOBJIVA', cellEditor: 'cellPrueba', width: 80,
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 22
      headerName: 'CodAlterno', field: 'DETFACPRO_CODIGOALT', cellEditor: 'cellPrueba',
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 23
      headerName: 'Cant.Und', field: 'DETFACPRO_CANTIDADUND', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // , editable: true,
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 24
      headerName: 'T.Gasto', field: 'TIPGAS_CODIGO', cellEditor: 'cellPrueba', editable: (params) => {
        return this.comprobarTipoGasto(params.data);
      },
      // , editable: true,
      cellEditorParams: (params) => {
        return this.comprobarTipoGastocell(params.data);
      }
    },
    { // 25
      headerName: 'TipoDet', field: 'DETFACPRO_TIPODET', cellEditor: 'cellPrueba',
      editable: (params) => {
        const boolEdit = this.isEditAll();
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 0
        }
      }
    },
    { // GSRF
      headerName: 'Artículo', field: 'DETFACPRO_AJUSTE', cellEditor: 'cellPrueba', width: 120,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      onCellClicked: (params) => {
        this.notrecnot(params.data);
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tienecalculos: true,
          tamanocelda: 32,
          //regexp: /^[S]$|^[s]$/,
        }
      }
    }
  ];

  columnDefsRet = [
    { // 0
      headerName: 'Código', field: 'RETENCION_CODIGO', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Descripción', field: 'TRNRETENCION_DESCRIPCION', cellEditor: 'cellPrueba', width: 120,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Serie', field: 'TRNRETENCION_SERIE', width: 80, cellEditor: 'cellPrueba',
      editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 3
      headerName: 'Retención', field: 'TRNRETENCION_NRO', width: 120, cellEditor: 'cellPrueba',
      editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 4
      headerName: 'Autoriz.', field: 'TRNRETENCION_AUTORIZACION', width: 120, cellEditor: 'cellPrueba',
      editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 5
      headerName: 'BaseGrabada', field: 'TRNRETENCION_BASEG', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      editable: p => {
        return p.data.RETENCION_TIPRET === 'RF';
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 6
      headerName: 'Base0', field: 'TRNRETENCION_BASE0', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      editable: p => {
        return p.data.RETENCION_TIPRET === 'RF';
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 7
      headerName: 'BaseNoGrabada', field: 'TRNRETENCION_BASENG', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      editable: p => {
        return p.data.RETENCION_TIPRET === 'RF';
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 8
      headerName: 'Base', field: 'TRNRETENCION_BASE', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 9
      headerName: '%', field: 'TRNRETENCION_PORCENTAJE', cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'right' },
      editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 10
      headerName: 'T.Retenido', field: 'TRNRETENCION_TOTALRETENIDO', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 11
      headerName: 'Fecha', field: 'TRNRETENCION_FECHAEMISION', cellEditor: 'cellPrueba', width: 100, onCellValueChanged: (params) => {
        this.cambioTrnretencionFE(params);
      },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    { // 12
      headerName: 'FechaPagoDiv', field: 'TRNRETENCION_FECHAPAGODIV', cellEditor: 'cellPrueba', width: 120,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 13
      headerName: 'ImpuestoRPagSoc',
      field: 'TRNRETENCION_IMPRENPAGADO',
      cellEditor: 'cellPrueba',
      width: 120,
      cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 14
      headerName: 'AñoUtil.', field: 'TRNRETENCION_ANIOUTIL', cellEditor: 'cellPrueba',
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }
  ];

  columnDefsCargos = [
    {
      headerName: 'Código', field: 'CARGO_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 5,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'CARGO_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 35
        }
      }
    },
    {
      headerName: 'Cnta.Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150,
      editable: false
    },
    {
      headerName: 'Importe', field: 'TRNCARGOS_IMPORTE', cellEditor: 'cellPrueba', width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    }
  ];

  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', width: 100,
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];

  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHAVENCE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];

  columnDefsSubirXml = [
    {
      headerName: 'Archivo', field: 'ARCHIVO'
    },
    {
      headerName: 'Autorización', field: 'AUTORIZACION', width: 400
    },
    {
      headerName: 'Fecha', field: 'FECHA'
    },
    {
      headerName: 'Factura', field: 'FACTURA'
    },
  ];


  columnDefsTopProductos = [
    {
      headerName: 'Producto', field: 'DETFACPRO_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFACPRO_DESCRIPCION', width: 190, editable: false
    },
    {
      headerName: 'Cantidad', field: 'CANTIDAD_TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFACPRO_COSTO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Stock', field: 'STOCK', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsMovimientosByProv = [
    {
      headerName: 'Proveedor', field: 'PRO_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'PRO_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Fecha Compra', field: 'ENCFACPRO_FECHAEMISION', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Costo', field: 'DETFACPRO_COSTO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsExistenciaBodegas = [
    {
      headerName: 'Código', field: 'BOD_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'BOD_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Existencia', field: 'EXISTENCIA', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Pedidos', field: 'PEDIDO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Saldo', field: 'SALDO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //CDPJ
  columnDefsBasesIva = [
    {
      headerName: '%IVA', field: 'TARIIVA_DESCRIPCION', width: 115, editable: false
    },
    {
      headerName: 'Base', field: 'IVA_BASE', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Valor IVA', field: 'IVA_VALOR', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //CDPJ
  errores = 0;
  auxiliarrem: number;
  auxiliarpag: number;
  auxiliarpre: number;
  auxiliar1: number;
  auxiliar2: number;
  auxreporte: number = 0;
  strNombreForma: string;
  displayDialogfrmcorreos: boolean;//CDPJ
  datosfe: SegMaeCompania = {};

  constructor(public encfacproService: ComencfacproService, private busqService: InvbusquedaService,
    private datePipe: DatePipe, private messageService: MessageService, private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService, private confIniciales: ConfInicialesService,
    private confirmationService: ConfirmationService, private utilitariosService: UtilitariosService,
    private proveedorServicio: CommaeproveedorService, private init: NuevoComponentService,
    private venEstadisticasService: ComestadisticasService, public usuario: Usuario) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.data.ENCGRE_CODIGO !== '' && params.data.ENCGRE_CODIGO !== null && params.data.ENCGRE_CODIGO !== undefined) {
        return { background: 'rgb(128, 255, 128)' };
      }
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb(255,255,170)' };
      }
    };

    this.rowStyleEC = (params) => {
      if (params.data.TRNCOBRO_TIPO !== 'A') {
        if (params.data.TRNCOBRO_FECHATRN === '') {
          return { background: 'rgb( 255, 255, 170)' };
        } else {
          return { background: 'rgb( 196, 225, 255)' };
        }
      }
    };

    this.rowStyleSaldo = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb( 255, 255, 170)' };
      }
    };

    this.rowStyleTopProd = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.DETFACPRO_CODIGO === '' || params.data.DETFACPRO_CODIGO === null) {
        return { background: 'rgb( 164, 255, 167)' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'detfacpro'
    };

    this.defaultColDefRet = {
      editable: true,
      width: 100,
      objeto: 'trnretencion'
    };

    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen'
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen'
    };

    this.defaultColDefCargos = {
      editable: true,
      width: 100,
      objeto: 'trncargos'
    };

    this.defaultColDefSubirXml = {
      editable: false,
      width: 100,
      objeto: 'subirxml'
    };

    this.defaultColTopProductos = {
      editable: true,
      width: 100,
      objeto: 'topProductos',
      resizable: true
    };

    this.defaultColMovByProv = {
      editable: true,
      width: 100,
      objeto: 'movproveedores',
      resizable: true
    };

    this.defaultColExistBod = {
      editable: true,
      width: 100,
      objeto: 'existenciabodegas',
      resizable: true
    };
    this.defaultColBasesIva = {
      editable: false,
      width: 100,
      objeto: 'basesiva',
    };
  }
  intAUTSRID: number;//CDPJ
  intADANPROFC: number;//CDPJ
  async ngOnInit() {
    this.activarbotonesgrid();//CDPJ
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.encfacproService.spin = false;//CDPJ
    this.index = 0;
    this.largo = '-5800';
    this.largoestcta = '420';
    this.encfacproService.encfacproarray[this.indicador] = {};
    console.log(this.encfacproService.encfacproarray[this.indicador])
    this.barraBotones2 = true;
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonFactura = false;
    this.botonAnular = false;
    this.botonXML = false;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.nuevoret = true;
    this.displayAcciones = false;

    this.llenarCombos();
    //CDPJ
    this.boolfacturaElec = await this.encfacproService.leerValidarRucsFacElec().catch(e => {

    });
    console.log(this.boolfacturaElec)
    this.encfacproService.porceiva = await this.confIniciales.obtenerImpuesto('I', 'A');
    this.ivavigente = this.encfacproService.porceiva;
    await this.confIniciales.obtenerSrifeTariIva()
    this.encfacproService.tariivacod = this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(this.encfacproService.porceiva)).TARIIVA_CODIGO
    //CDPJ

    this.comboOpciones = [{ label: 'A', value: 'A' },
    { label: 'S', value: 'S' }, { label: 'C', value: 'C' }, { label: '*', value: '*' }];

    this.salirDialog();
    this.nuevaFactura();
    this.claveAuth = '';
    this.autorizacionCodigo = '';
    this.auxiliarAutorizacion = '';

    this.claveSup = '';
    this.usuarioSup = '';
    this.strDialogo = '';
    this.strObjeto = '';
    this.intPestaniaCol = 0;

    this.asiento = '';

    this.strTransporte = '';
    this.bolTransporte = true;

    this.ultimabodegacod = '';
    this.ultimabodeganame = '';

    this.logResultado = '';

    this.encfacproService.dblPorcDescGlbarray[this.indicador] = 0;
    this.encfacproService.dblValorDescGlbarray[this.indicador] = 0;

    this.encfacproService.encretarray[this.indicador] = {
      TRNRETENCION_SERIE: '',
      TRNRETENCION_NRO: '',
      TRNRETENCION_AUTORIZACION: '',
      DOCREF: ''
    };

    this.bolcmbSerie = false;
    this.bolNumRet = true;
    this.bolProveedor = false;
    this.bolSerie = false;
    this.bolComprobante = false;
    this.bolAnularRetencion = false;

    this.auxiliarpag = 0;
    this.auxiliarrem = 0;
    this.auxiliarpre = 0;
    this.auxiliar1 = 0;
    this.auxiliar2 = 0;
    this.encfacproService.strFacturaExportadaarray[this.indicador] = '';

    this.invisible = true;

    this.cxctrnresumen = [];
    this.cxctmptrnresumen = [];
    this.bolestcta = false;
    this.bolsaldo = false;

    this.confIniciales.getDecimales('frmCOM_ENCFACPRO').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_TIPDOC === 'detfacpro' || decimales.NUMDEC_TIPDOC === 'DetFacPro') {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.encfacproService.decimalesCANTIDADfac = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'COSTO') {
              this.encfacproService.decimalesCOSTOfac = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
              this.encfacproService.decimalesTOTALDetFacPro = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'PORCENTAJE') {
              this.encfacproService.decimalesPorcentajeret = Number(decimales.NUMDEC_NUMDEC);
            }
          }
        });

        this.columnDefsDetfac[3].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.confIniciales.getNumDecSist(),
            obligatorio: true,
            fecha: false,
            clave: false,
            //tienecalculos: true,
            tamanocelda: 32,
            opcionbuscar: true
          }
        };

        this.columnDefsDetfac[6].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encfacproService.decimalesCANTIDADfac,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.columnDefsDetfac[7].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encfacproService.decimalesCOSTOfac,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.columnDefsDetfac[8].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encfacproService.decimalesCOSTOfac,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.columnDefsDetfac[9].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 3,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.columnDefsDetfac[10].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.confIniciales.getNumDecSist(),
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.columnDefsDetfac[11].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.confIniciales.getNumDecSist(),
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.columnDefsDetfac[12].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.confIniciales.getNumDecSist(),
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32
          }
        };
        this.columnDefsDetfac[13].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encfacproService.decimalesTOTALDetFacPro,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32
          }
        };
        this.columnDefsDetfac[14].cellEditorParams = {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encfacproService.decimalesTOTALDetFacPro,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32
          }
        };
        this.columnDefsRet[9].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encfacproService.decimalesPorcentajeret,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 32
          }
        };
      }
      this.aggrid.refreshColumnDefs();

      this.confIniciales.getDecimales('frmINV_MAEARTICULO').subscribe((dec2) => {
        if (dec2 !== null) {
          dec2.map((decimales) => {
            if (decimales.NUMDEC_TIPDOC === 'MaePrecio') {
              if (decimales.NUMDEC_CAMPO === 'COSTO') {
                this.encfacproService.decimalesPrecios = Number(decimales.NUMDEC_NUMDEC);
              }
            }
          });

          this.columnDefsDetfac[18].cellEditorParams = {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: false,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: this.encfacproService.decimalesPrecios,
              obligatorio: true,
              fecha: false,
              clave: false,
              tamanocelda: 32,
              tienecalculos: true
            }
          };
          this.columnDefsDetfac[19].cellEditorParams = {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: false,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: this.encfacproService.decimalesPrecios,
              obligatorio: true,
              fecha: false,
              clave: false,
              tamanocelda: 32,
              tienecalculos: true
            }
          };
          this.columnDefsDetfac[20].cellEditorParams = {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: false,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: this.encfacproService.decimalesPrecios,
              obligatorio: true,
              fecha: false,
              clave: false,
              tamanocelda: 32,
              tienecalculos: true
            }
          };
        }
        this.aggrid.refreshColumnDefs();

        this.confIniciales.getDecimales('frmVEN_ENCFAC').subscribe(async (dec3) => {
          if (dec3 !== null) {
            dec3.map((decimales) => {
              if (decimales.NUMDEC_CAMPO === 'CANTUND') {
                this.encfacproService.decimalesCantUnd = Number(decimales.NUMDEC_NUMDEC);
              }

            });
            this.columnDefsDetfac[23].cellEditorParams = {
              values: {
                mayusculas: true,
                numeros: false,
                alphabetic: true,
                saltoslinea: 1,
                newrow: false,
                numerodecimales: this.encfacproService.decimalesCantUnd,
                obligatorio: true,
                fecha: false,
                clave: false,
                tamanocelda: 32
              }
            };
            this.aggrid.refreshColumnDefs();
          }

          await this.getConfiguracion();

        });
      });
    });

    this.encfacproService.leerConfiguracionDatosElectronicos().subscribe(data => {
      for (const rs of data) {
        this.datosfe = rs;
      }
    });

    this.limpiartablas();
    this.configuracionRegMic();
  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciasFacComp--;
  }
  //CDPJ
  configuracionRegMic() {
    this.utilitariosService.configRegMic().subscribe(data => {
      let glbintregimenMicroempresas = '';
      let glbintagenteret = '';
      for (const rs of data) {
        glbintregimenMicroempresas = rs.COM_CONREGMIC;
        glbintagenteret = rs.COM_AGENTERET;
      }

      if (glbintregimenMicroempresas === '1'
        || glbintagenteret === '1') {
        if (glbintregimenMicroempresas === '1'
          && glbintagenteret === '1') {
          this.lblMensajeCRM = 'Contribuyente aplica régimen de Microempresas y agente de retención';
        } else {
          if (glbintregimenMicroempresas === '1') {
            this.lblMensajeCRM = 'Contribuyente aplica régimen de Microempresas';
          }
          if (glbintagenteret === '1') {
            this.lblMensajeCRM = 'Contribuyente aplica agente de retención';
          }
        }
      } else {
        this.lblMensajeCRM = '';
      }
    });
  }

  getConfiguracion() {
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      this.encfacproService.hmpConfiguracion = conf;
      this.NUMITEMFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMITEMFAC').CFG_VALOR2;
      this.FACCANUND = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACCANUND').CFG_VALOR1;
      const MOSCOLP = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'MOSCOLP').CFG_VALOR1;
      const CENCOSDET = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENCOSDET').CFG_VALOR1;
      const DSCARTCOM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DSCARTCOM').CFG_VALOR1;
      const NRFACTORNR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NRFACTORNR').CFG_VALOR1;
      this.strCODRET1 = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODRET1').CFG_VALOR3;
      this.strCODRET2 = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODRET2').CFG_VALOR3;
      this.strCODRET3 = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODRET3').CFG_VALOR3;
      this.strCODRET4 = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODRET4').CFG_VALOR3;
      this.strCODRET5 = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODRET5').CFG_VALOR3;
      this.strCODRET6 = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODRET6').CFG_VALOR3;
      this.TIPDET = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'TIPDET').CFG_VALOR3;
      //CDPJ
      this.CENREPDOCE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENREPDOCE').CFG_VALOR1;
      this.intAUTSRID = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'AUTSRI').CFG_VALOR1;
      console.log(this.intAUTSRID);
      if (this.intAUTSRID === 1) {
        this.habilitarbotonesfac = false;
        console.log('entr', this.intAUTSRID);
      } else {
        this.habilitarbotonesfac = true;
      }
      this.intADANPROFC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ADANPROFC').CFG_VALOR1
      if (this.intADANPROFC === 1) {
        this.habilitarVisto = false
      } else {
        this.habilitarVisto = true
      }
      //CDPJ


      if (this.strCODRET1 === null) {
        this.strCODRET1 = '';
      }
      if (this.strCODRET2 === null) {
        this.strCODRET2 = '';
      }
      if (this.strCODRET3 === null) {
        this.strCODRET3 = '';
      }
      if (this.strCODRET4 === null) {
        this.strCODRET4 = '';
      }
      if (this.strCODRET5 === null) {
        this.strCODRET5 = '';
      }
      if (this.strCODRET6 === null) {
        this.strCODRET6 = '';
      }

      if (this.FACCANUND !== 1) {
        this.columnDefsDetfac.splice(23, 1);
      }

      if (MOSCOLP !== 1) {
        this.columnDefsDetfac.splice(20, 1);
        this.columnDefsDetfac.splice(19, 1);
        this.columnDefsDetfac.splice(18, 1);
      }

      this.columnDefsDetfac.splice(16, 1);

      if (CENCOSDET !== 1) {
        this.columnDefsDetfac.splice(15, 1);
      }

      this.columnDefsDetfac.splice(14, 1);

      if (NRFACTORNR !== 1) {
        this.columnDefsDetfac.splice(12, 1);
      }

      if (DSCARTCOM !== 1) {
        this.columnDefsDetfac.splice(11, 1);
        this.columnDefsDetfac.splice(10, 1);
      }
      this.aggrid.refreshColumnDefs();
    });
  }

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable
      const dia = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION.getDate();
      const mes = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION.getMonth();
      const anio = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION.getFullYear();

      //ANTES ESTABA COMENTADO
      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    if (this.index === 5) {
      return;
    }
    let strCodigo = 0
    let srtArtCntaSer = 0;
    const detalle = await this.encfacproService.verificarDetalleFacpro(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO)
    const detalle2 = await this.encfacproService.verificarDetalleDevFacproArtCntaSer(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
    if (detalle !== null && detalle !== undefined) {
      for (const rs of detalle) {
        strCodigo = rs.NUMERO;
      }
    }
    if (detalle2 !== null && detalle2 !== undefined) {
      for (const rs of detalle2) {
        srtArtCntaSer = rs.NUMERO;
      }
    }
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;

    if (this.hmpConfiguracion === undefined ||
      this.encfacproService.hmpConfiguracion === undefined) {
      this.getConfiguracion();
    }

    if (valor === 'Nuevo') {
      //CDPJ
      if (this.encfacproService.detfacproarray[this.indicador].length === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== ''
      ) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Factura'
        });
        return;
      }
      if (strCodigo === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura no tiene guardado el detalle'
        });
        return;
      }
      if (srtArtCntaSer === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.VerificarIngresoRetenciones('Nuevo', '');
    }
    if (valor === 'Guardar') {
      let isXML = false;

      for (const item of this.encfacproService.detfacproarray[this.indicador]) {
        if (item.DETFACPRO_XML === true) {
          isXML = true;
        }
        if (item.DETFACPRO_CODIGO === null && item.DETFACPRO_TIPODET !== '*') {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'warn',
            summary: 'Información',
            detail: 'Verifique que las líneas del detalle tengan un código'
          });
          return;
        }
        //GSRF
        if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '' && item.DETFACPRO_TIPODET === '') {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'warn',
            summary: 'Información',
            detail: 'Verifique que las líneas del detalle tengan un código homologado ' + item.DETFACPRO_CODIGO
          });
          return;
        }
        //GSRF
      }
      if (isXML) {
        this.agregarCodigoAlternoXml();
      }
      this.guardar();
    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Primero') {
      //CDPJ
      if (this.encfacproService.detfacproarray[this.indicador].length === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== ''
      ) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Factura'
        });
        return;
      }
      if (strCodigo === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura no tiene guardado el detalle'
        });
        return;
      }
      if (srtArtCntaSer === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      //CDPJ
      if (this.encfacproService.detfacproarray[this.indicador].length === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== ''
      ) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Factura'
        });
        return;
      }
      if (strCodigo === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura no tiene guardado el detalle'
        });
        return;
      }
      if (srtArtCntaSer === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      //CDPJ
      if (this.encfacproService.detfacproarray[this.indicador].length === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== ''
      ) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Factura'
        });
        return;
      }
      if (strCodigo === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura no tiene guardado el detalle'
        });
        return;
      }
      if (srtArtCntaSer === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      //CDPJ
      if (this.encfacproService.detfacproarray[this.indicador].length === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== ''
      ) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Factura'
        });
        return;
      }
      if (strCodigo === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura no tiene guardado el detalle'
        });
        return;
      }
      if (srtArtCntaSer === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      //CDPJ
      if (this.encfacproService.detfacproarray[this.indicador].length === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== ''
      ) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Factura'
        });
        return;
      }
      if (strCodigo === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura no tiene guardado el detalle'
        });
        return;
      }
      if (srtArtCntaSer === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.VerificarIngresoRetenciones('Buscar', '');
    }
    //GSRF  
    if (valor === 'facturasAbonadas') {
      console.log('--aquiiiiii---')
      this.busquedafactuIMPTMP();
    }
    //GSRF
    if (valor === 'Descuento') {
      this.aplicarDescuentoGlobal();
    }
    if (valor === 'Pago') {
      this.PagoDocumento();
    }
    if (valor === 'Credito') {
      this.Credito();
    }
    if (valor === 'Asiento') {
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === 'Crédito') {
        this.generarAsiContable();
      }
    }
    if (valor === 'AsientoVer') {
      this.verAsientoContable();
    }
    if (valor === 'Anular') {
      this.anularRegistro();
    }
    if (valor === 'Firmar') {
      this.guardarDatosFacElec();
    }
    if (valor === 'ActualizarPrecios') {
      this.actualizarPreciosFP();
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Almacenardoc') {
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        const usu = await this.encfacproService.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    if (valor === 'Salir') {
      //CDPJ
      if (this.encfacproService.detfacproarray[this.indicador].length === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== ''
      ) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Factura'
        });
        return;
      }
      if (strCodigo === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura no tiene guardado el detalle'
        });
        return;
      }
      if (srtArtCntaSer === 0
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== undefined
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'info',
          summary: 'Información',
          detail: 'La Factura debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.VerificarIngresoRetenciones('Salir', '');
    }

    this.closeSidebar();
  }

  emisionSelect() {
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAREC =
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION;
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAVENCIMIENTO =
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION;
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'PRO_CODIGO': {
        this.busquedaproveedor(event.target.value);
        break;
      }
      case 'ENCFACPRO_SERIE': {
        element = document.getElementById('ENCFACPRO_REFERENCIA');
        break;
      }
      case 'ENCFACPRO_REFERENCIA': {
        const intNumCar = 9;
        const intTamanioCad = event.target.value.length;
        let strCadCeros = '';

        for (let i = 1; i <= intNumCar - intTamanioCad; i++) {
          strCadCeros = strCadCeros + '0';
        }
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA = strCadCeros + event.target.value;
        element = document.getElementById('ENCFACPRO_CONTACTO');
        break;
      }
      case 'ENCFACPRO_CONTACTO': {
        element = document.getElementById('ENCFACPRO_IDCRETRI');
        break;
      }
      case 'ENCFACPRO_IDCRETRI': {
        this.busquedaidcretri(event.target.value, 'cretri');
        break;
      }
      case 'ENCFACPRO_TIPCOM': {
        this.busquedatipcom(event.target.value);
        break;
      }
      case 'BOD_CODIGO': {
        this.busquedaBodega(event.target.value, 'bodega');
        break;
      }
      case 'ENCFACPRO_AUTORIZACION': {
        element = document.getElementById('ENCFACPRO_NUMDIASPLAZO');
        break;
      }
      case 'ENCFACPRO_NUMDIASPLAZO': {
        this.calcularFechaVencimiento();
        element = document.getElementById('ENCFACPRO_NUMINGRESO');
        break;
      }
      case 'ENCFACPRO_NUMINGRESO': {
        element = document.getElementById('ENCFACPRO_COMENTARIO');
        break;
      }
      case 'ENCFACPRO_COMENTARIO': {
        //this.nuevodetalle();
        if (this.intAUTSRID === 1) { //constrolar SRI
          const VALDATSRI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VALDATSRI').CFG_VALOR1;
          if (VALDATSRI === 1) { //varificarverigicar
            if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '') {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Información',
                detail: 'Error, Ingrese el Id del Crédito Tributario.'
              });
            } else {
              if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM === '') {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'Error, Ingrese el Tipo de Documento.'
                });
              } else {
                if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION === '') {
                  this.messageService.add({
                    key: 'encfacpro',
                    severity: 'warn',
                    summary: 'Información',
                    detail: 'Error, Ingrese el Número de Autorización de la factura.'
                  });
                } else {
                  this.autorizacionSri('tipdoc');
                  if (this.habilitarbotonesfac === true) {
                    this.nuevodetalle();//CDPJ
                  }

                }
              }
            }
          } else {
            this.autorizacionSri('tipdoc');
            if (this.habilitarbotonesfac === true) {
              this.nuevodetalle();//CDPJ
            }
          }

        } else {
          //this.autorizacionSri('tipdoc');
          //this.nuevodetalle();//CDPJ
          //NO SRI
          const VALDATSRI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VALDATSRI').CFG_VALOR1;
          if (VALDATSRI === 1) {
            if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '') {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Información',
                detail: 'Error, Ingrese el Id del Crédito Tributario.'
              });
            } else {
              if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM === '') {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'Error, Ingrese el Tipo de Documento.'
                });
              } else {
                if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION === '') {
                  this.messageService.add({
                    key: 'encfacpro',
                    severity: 'warn',
                    summary: 'Información',
                    detail: 'Error, Ingrese el Número de Autorización de la factura.'
                  });
                } else {
                  //this.autorizacionSri('tipdoc');
                  //if(this.habilitarbotonesfac === true){
                  this.nuevodetalle();//CDPJ
                  //}

                }
              }
            }
          } else {
            //this.autorizacionSri('tipdoc');
            //if(this.habilitarbotonesfac === true){
            this.nuevodetalle();//CDPJ
            //}
          }
        }
        break;
      }
      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }

  }

  nuevodetalle() {
    if (this.index === 0) {
      if (this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO !== undefined) {
        if (this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO.length === 0) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'warn',
            summary: 'Información',
            detail: 'Ingrese el proveedor.'
          });
          return;
          //GSRF
        } else if (this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO === "" || this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO === null) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'warn',
            summary: 'Información',
            detail: 'Ingrese Bodega.'
          });
          return;
        }
        //GSRF
      } else {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese el proveedor.'
        });
        return;
      }

      this.crearNuevoItem();
      const detfac: ComDetfacpro = this.nuevoItem;
      const indi = this.encfacproService.detfacproarray[this.indicador].indexOf(this.detfacproSelected);

      if (indi === -1) {
        detfac.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encfacproService.detfacproarray[this.indicador].push(detfac);
        this.detfacproSelected = detfac;
        this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);
        if (this.detfacproSelected.DETFACPRO_TIPODET !== '*') {
          if (this.encfacproService.detfacproarray[this.indicador].length === 1) {
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(0, 'DETFACPRO_CODIGO');
          } else {
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
              this.defaultColDef.objeto].setFocusedCell((this.encfacproService.detfacproarray[this.indicador].length) - 1, 'DETFACPRO_CODIGO');
          }
        } else {
          if (this.encfacproService.detfacproarray[this.indicador].length === 1) {
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(0, 'DETFACPRO_DESCRIPCION');
          } else {
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
              this.defaultColDef.objeto].setFocusedCell((this.encfacproService.detfacproarray[this.indicador].length) - 1, 'DETFACPRO_DESCRIPCION');
          }
        }
      } else {
        detfac.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encfacproService.detfacproarray[this.indicador].splice(indi + 1, 0, detfac);
        this.detfacproSelected = detfac;
        this.aggrid.refreshaggridindex(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto, indi);
        if (this.detfacproSelected.DETFACPRO_TIPODET !== '*') {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(indi + 1, 'DETFACPRO_CODIGO');
        } else {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(indi + 1, 'DETFACPRO_DESCRIPCION');
        }
      }
      this.botonesmodificar();
    }

    if (this.index === 1) {
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== '' &&
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== undefined) {
        return;
      }

      if (this.encfacproService.trnretencionarray[this.indicador].length === 0) {
        if (this.cmbserie.length === 0) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'warn',
            summary: 'Confirmación',
            detail: 'Seleccione la serie por favor.'
          });
          return;
        }
      }

      this.crearNuevoItemRet();
      const trnretencion: SaciTrnretencion = this.nuevoItemRet;
      const indi = this.encfacproService.trnretencionarray[this.indicador].indexOf(this.trnretencionSelected);
      console.log(indi);
      if (indi === -1) {
        trnretencion.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encfacproService.trnretencionarray[this.indicador].push(trnretencion);
        this.trnretencionSelected = trnretencion;
        this.aggrid.refreshaggrid(this.encfacproService.trnretencionarray[this.indicador], this.defaultColDefRet.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefRet.objeto].setFocusedCell(0, 'RETENCION_CODIGO');
        this.bolNumRet = true;
      } else {
        trnretencion.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encfacproService.trnretencionarray[this.indicador].splice(indi + 1, 0, trnretencion);
        this.trnretencionSelected = trnretencion;
        this.aggrid.refreshaggridindex(this.encfacproService.trnretencionarray[this.indicador], this.defaultColDefRet.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefRet.objeto].setFocusedCell(indi + 1, 'RETENCION_CODIGO');
        this.bolNumRet = true;
      }
      this.bolcmbSerie = true;
      this.botonesmodificar();
    }

    if (this.index === 2) {
      const cargo: ComTrncargos = {
        ENCFACPRO_NUMERO: this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO,
        TRNCARGOS_LINEA: 0,
        CARGO_CODIGO: '',
        CARGO_NOMBRE: '',
        TRNCARGOS_IMPORTE: 0,
        COM_CODIGO: '01',
        CON_CODIGO: '',
        CEN_CODIGO: '',
        CLAVE: ''
      };

      const indi = this.encfacproService.trncargosarray[this.indicador].indexOf(this.cargoSelected);
      if (indi === -1) {
        cargo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encfacproService.trncargosarray[this.indicador].push(cargo);
        this.cargoSelected = cargo;
        this.aggrid.refreshaggrid(this.encfacproService.trncargosarray[this.indicador], this.defaultColDefCargos.objeto);
      } else {
        cargo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encfacproService.trncargosarray[this.indicador].splice(indi + 1, 0, cargo);
        this.cargoSelected = cargo;
        this.aggrid.refreshaggridindex(this.encfacproService.trncargosarray[this.indicador], this.defaultColDefCargos.objeto, indi);
      }
      this.botonesmodificar();
    }

  }

  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;

    this.disabledEstadisticas = true;
  }


  /*gridsnull() {
    if (this.index === 3) {
      this.llenarEstadoCuenta();
    }
    if (this.index === 4) {
      this.llenarTablaSaldos();
    }
  }*/


  busquedaproveedor(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'proveedor';
    this.types = [
      { label: 'Código', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' },
      { label: 'Nombre Corto', value: 'PRO_NOMBREC' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Ruc', value: 'PRO_RUCIDE' },
      { label: 'Cuenta', value: 'CON_CODIGO1' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'COM_MAEPROVEEDOR';
    this.where = '';

    this.encfacproService.erMaeProveedor(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaComMaeProveedor3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaComMaeProveedor3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedatransportista(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'transportista';
    this.types = [
      { label: 'Código', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' },
      { label: 'Nombre Corto', value: 'PRO_NOMBREC' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Ruc', value: 'PRO_RUCIDE' },
      { label: 'Cuenta', value: 'CON_CODIGO1' },
      { label: 'Tipo', value: 'PRO_TIPOA' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'COM_MAEPROVEEDOR';
    this.where = '';

    this.busqService.busquedaComMaeProveedor3(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  busquedaidcretri(parametro, opcionbusqueda) {
    const strFechaEmision = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
    let strSentencia = '';
    strSentencia = strSentencia + ' to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')>= codsri_fechaini';
    strSentencia = strSentencia + ' and to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')<= codsri_fechafin';

    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref.', value: 'CODSRI_REF' },
      { label: 'Código Con.', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'ANEX_MAECODSRI';
    this.where = 'CODSRI_TIPO=\'CT\' AND ' + strSentencia;

    this.encfacproService.erCodSriDescripcion1(strFechaEmision, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRIDET = eR[0].CODSRI_DESCRIPCION;
          document.getElementById('ENCFACPRO_TIPCOM').focus();
        } else {
          this.busqService.busquedaAnexMaeCodSri2(strSentencia, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri2(strSentencia, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedatipcom(parametro) {
    if (this.encfacproService.encfacproarray[this.indicador].PRO_TIPOID !== '' &&
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI !== '') {
      const strFechaEmision = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
      let strSentencia = '';
      strSentencia = strSentencia + ' to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')>= codsri_fechaini';
      strSentencia = strSentencia + ' and to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')<= codsri_fechafin';

      if (parametro === null) {
        parametro = '';
      }
      let strTipoDoc = this.verificarTipoDocumento();

      if (strTipoDoc === '') {
        strTipoDoc = '1=1';
      }

      this.opcionbusqueda = 'tipcom';
      this.types = [
        { label: 'Código', value: 'CODSRI_CODIGO' },
        { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
        { label: 'Tipo', value: 'CODSRI_TIPO' },
        { label: 'Año', value: 'CODSRI_ANIO' },
        { label: 'Ref.', value: 'CODSRI_REF' },
        { label: 'Código Con.', value: 'CON_CODIGO' },
      ];
      this.busquedacampos = [parametro, '', '', '', '', ''];
      this.consulta = '';
      this.tabla = 'ANEX_MAECODSRI';
      this.where = 'CODSRI_TIPO=\'TC\' AND ' + strTipoDoc + ' AND ' + strSentencia + '';

      this.encfacproService.erCodSriDescripcion2(strFechaEmision, strTipoDoc, parametro).subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOMDET = eR[0].CODSRI_DESCRIPCION;
            this.autorizacionSri('tipdoc');
            document.getElementById('BOD_CODIGO').focus();
          } else {
            this.busqService.busquedaAnexMaeCodSri3(strTipoDoc, strSentencia, parametro).subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaAnexMaeCodSri3(strTipoDoc, strSentencia, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
    } else {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese proveedor y Id de crédito'
      });
    }
  }

  busquedaBodega(parametro, opcionbusqueda) {
    if (parametro === undefined) {
      parametro = '';
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '';
    this.tabla = 'inv_maebodega';
    this.where = '';

    this.encfacproService.erBodNombre1(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          if (opcionbusqueda === 'bodega') {
            this.encfacproService.encfacproarray[this.indicador].BOD_NOMBRE = eR[0].BOD_NOMBRE;
            document.getElementById('ENCFACPRO_AUTORIZACION').focus();
          }
          if (opcionbusqueda === 'bodegaxml') {
            this.bodnombrexml = eR[0].BOD_NOMBRE;
            if (document.getElementById('IDCREDITOXML') !== null) {
              document.getElementById('IDCREDITOXML').focus();
            }
            if (document.getElementById('IDCREDITOXMLF1') !== null) {
              document.getElementById('IDCREDITOXMLF1').focus();
            }
            if (document.getElementById('IDCREDITOXMLF2') !== null) {
              document.getElementById('IDCREDITOXMLF2').focus();
            }
          }
        } else {
          this.busqService.busquedaInvMaeBodega2(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaInvMaeBodega2(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaCEN(parametro) {
    this.opcionbusqueda = 'centro';
    this.types = [
      { label: 'Código', value: 'CEN_CODIGO' },
      { label: 'Nombre', value: 'CEN_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '';
    this.tabla = 'CON_MAECEN';
    this.where = '';

    this.busqService.busquedaConMaeCen2(parametro).subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusqueda = true;
    });
  }

  llenarCombos() {
    this.cmbivaaux = [{ codigo: '1', name: 'Neto + Iva' },
    // {codigo: '2', name: 'Incluído Iva'},
    { codigo: '0', name: 'Exento' }];

    let strSentencia = 'select distinct num_serie from saci_maenumeracionG ';
    strSentencia = strSentencia + ' where num_docref like \'RT%\' ';

    this.encfacproService.getNumSerieCmb().subscribe(data => {
      this.cmbserieaux = [];
      for (const rs of data) {
        this.cmbserieaux.push({ codigo: rs.NUM_SERIE, name: rs.NUM_SERIE });
      }
    });

  }

  llenarcmbiva() {
    this.cmbiva = this.cmbivaaux;
  }

  busqiva(iva) {
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA = iva.value.codigo;
    this.encfacproService.strTipoIvaarray[this.indicador] = iva.value.name;
  }

  llenarcmbserie() {
    this.cmbserie = this.cmbserieaux;
  }

  busqserie(iva) {
    if (iva.value !== undefined) {
      this.encfacproService.encretarray[this.indicador].TRNRETENCION_SERIE = iva.value.codigo;
    }

    if (iva.target !== undefined) {
      this.encfacproService.encretarray[this.indicador].TRNRETENCION_SERIE = iva.target.innerText;
    }

    const strTipoDoc = this.encfacproService.encretarray[this.indicador].TRNRETENCION_SERIE + '\' AND NUM_DOCREF LIKE \'RT%';

    const strSentencia = 'SELECT * FROM SACI_MAENUMERACIONG WHERE NUM_SERIE = \''
      + strTipoDoc + '\' AND COM_CODIGO = \'01\'';

    this.encfacproService.getSaciMaeNumeraciong(this.encfacproService.encretarray[this.indicador].TRNRETENCION_SERIE).subscribe(data => {
      for (const rs of data) {
        this.encfacproService.encretarray[this.indicador].DOCREF = rs.NUM_DOCREF;
        this.encfacproService.encretarray[this.indicador].TRNRETENCION_NRO = rs.NUM_SECACTUAL;
        this.encfacproService.encretarray[this.indicador].TRNRETENCION_AUTORIZACION = rs.NUM_AUTORIZACION;
      }
    });
  }

  async  selecciondetfacpro(params) {

    if (params === 'nuevo') {
      // this.nuevodetalle();
      if (this.intAUTSRID === 1) { //constrolar SRI
        const VALDATSRI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VALDATSRI').CFG_VALOR1;
        if (VALDATSRI === 1) { //varificarverigicar
          if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '') {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, Ingrese el Id del Crédito Tributario.'
            });
          } else {
            if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM === '') {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Información',
                detail: 'Error, Ingrese el Tipo de Documento.'
              });
            } else {
              if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION === '') {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'Error, Ingrese el Número de Autorización de la factura.'
                });
              } else {
                this.autorizacionSri('tipdoc');
                if (this.habilitarbotonesfac === true) {
                  this.nuevodetalle();//CDPJ
                }

              }
            }
          }
        } else {
          this.autorizacionSri('tipdoc');
          if (this.habilitarbotonesfac === true) {
            this.nuevodetalle();//CDPJ
          }
        }

      } else {
        //this.autorizacionSri('tipdoc');
        //this.nuevodetalle();//CDPJ
        //NO SRI
        const VALDATSRI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VALDATSRI').CFG_VALOR1;
        if (VALDATSRI === 1) {
          if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '') {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, Ingrese el Id del Crédito Tributario.'
            });
          } else {
            if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM === '') {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Información',
                detail: 'Error, Ingrese el Tipo de Documento.'
              });
            } else {
              if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION === '') {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'Error, Ingrese el Número de Autorización de la factura.'
                });
              } else {
                //this.autorizacionSri('tipdoc');
                //if(this.habilitarbotonesfac === true){
                this.nuevodetalle();//CDPJ
                //}

              }
            }
          }
        } else {
          //this.autorizacionSri('tipdoc');
          //if(this.habilitarbotonesfac === true){
          this.nuevodetalle();//CDPJ
          //}
        }
      }
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalle();
      return;
    }

    if (params === 'agOC') {
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA' ||
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
        return;
      }
      this.agregarOrdenCompra();
      return;
    }

    if (params === 'agNR') {
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA' ||
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
        return;
      }
      this.agregarNotaRecepcion();
      return;
    }

    if (params === 'btnDistribuirCostos') {
      this.distribuirCostos();
      return;
    }
    //GSRF
    if (params === 'homologaitem') {
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '' && this.detfacproSelected.DETFACPRO_TIPODET === '') {
        console.log('---+++666++--', this.detfacproSelected)
        const resul = await this.encfacproService.buscarthomo(this.detfacproSelected.DETFACPRO_CODIGO, this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO);
        console.log('---+++666++--', resul)
        if (resul[0].DETFAC_HOMOLOGADO === 1) {
          this.detfacproSelected.DETFACPRO_CODIGO = resul[0].DETFAC_CODIGOTIPO;
          this.detfacproSelected.DETFACPRO_DESCRIPCION = resul[0].DETFAC_NOMBRETIPO;
          this.detfacproSelected.DETFACPRO_UNIDAD = resul[0].DETFACPRO_UNIDAD;
          this.detfacproSelected.DETFACPRO_TIPODET = resul[0].DETFAC_TIPO;
          this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);
        } else {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'warn',
            summary: 'Información',
            detail: 'Articulo ' + this.detfacproSelected.DETFACPRO_CODIGO + ' no homologado'
          });
        }
      } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '' && this.detfacproSelected.DETFACPRO_TIPODET !== '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Articulo ' + this.detfacproSelected.DETFACPRO_CODIGO + ' se encuentra homologado'
        });
      }
      return;
    }
    //GSRF
    if (params.object !== undefined) {
      this.detfacproSelected = params.object;
      this.tipodetalle = this.detfacproSelected.DETFACPRO_TIPODET;
      this.multiunidad();
    }

  }

  selecciontrnretencion(params) {
    if (params === 'nuevo') {
      this.nuevodetalle();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalle();
      return;
    }

    if (params.object !== undefined) {
      this.trnretencionSelected = params.object;
    }
  }

  rdbtipodet(params) {
    this.tipodetalle = params;
  }


  cambio(cambio) {
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = true;
    }
  }

  async encontrarFactura(encfacprocodigo) {
    this.limpiartablas();
    const resp = await this.encfacproService.getEncfacpro(encfacprocodigo);
    for (const data of resp) {
      data.ENCFACPRO_FECHAVENCIMIENTO = new Date(data.ENCFACPRO_FECHAVENCIMIENTO);
      data.ENCFACPRO_FECHAREC = new Date(data.ENCFACPRO_FECHAREC);
      data.ENCFACPRO_FECHAEMISION = new Date(data.ENCFACPRO_FECHAEMISION);
      data.ENCFACPRO_FECHACADFAC = new Date(data.ENCFACPRO_FECHACADFAC);
      data.ENCFACPRO_FECHAAUT_FELIQ = new Date(data.ENCFACPRO_FECHAAUT_FELIQ);
      data.ENCFACPRO_FECHAAUTRET_FE = new Date(data.ENCFACPRO_FECHAAUTRET_FE);
      const auxtotal = data.ENCFACPRO_TOTAL;
      data.ENCFACPRO_TOTAL = '';

      const er = await this.encfacproService.erRefNombrec1(data.ENCFACPRO_ESTADO);
      if (er[0] !== undefined) {
        data.ENCFACPRO_ESTADO = er[0].REF_NOMBREC;
      }

      if (data.ENCFACPRO_ESTADO === 'PAGADA' || data.ENCFACPRO_ESTADO === 'ANULADA') {
        document.getElementById('contorno').style.pointerEvents = 'none';
        document.getElementById('contorno2').style.pointerEvents = 'none';
        document.getElementById('contorno3').style.pointerEvents = 'none';
        //this.aggrid.bloquearGrid();
        this.bloquearbotonesgrid();//CDPJ
        this.bolAnularRetencion = true;
        this.botonBorrar = true;
        this.botonAnular = true;
      } else {
        document.getElementById('contorno').style.pointerEvents = 'all';
        document.getElementById('contorno2').style.pointerEvents = 'all';
        document.getElementById('contorno3').style.pointerEvents = 'all';
        this.aggrid.habilitarGrid();
        this.activarbotonesgrid();//CDPJ
        this.bolAnularRetencion = false;
        this.botonBorrar = false;
        this.botonAnular = false;
      }
      this.encfacproService.encfacproarray[this.indicador] = data;
      this.completarDatosProveedor(this.encfacproService.encfacproarray[this.indicador], 'consulta');

      this.chkgasto = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_GASTO === 'S';
      this.chknoobjiva = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NOOBJETOIVA === 'S';
      this.chkDisCargosSobreCostos = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_DISTCARGOS === 'S';

      for (const j of this.cmbivaaux) {
        if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA === j.codigo) {
          this.cmbiva = [{ codigo: j.codigo, name: j.name }];
          this.selecttipoiva = { codigo: j.codigo, name: j.name }//CDPJ
        }
      }

      // Codigo Cuenta por Cobrar
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === 'C') {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO = 'Crédito';
      } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === 'T') {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO = 'Contado';
      } else {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO = '';
      }

      let strSen = '';
      strSen = strSen + ' to_date( \'' + this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION,
        'dd/MM/yyyy') + '\' ,\'dd/mm/yyyy\')>= codsri_fechaini';
      strSen = strSen + ' and to_date( \'' + this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION,
        'dd/MM/yyyy') + '\' ,\'dd/mm/yyyy\')<= codsri_fechafin';


      this.encfacproService.erCodSriDescripcion3(this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy'),
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI).subscribe(er1 => {
          if (er1[0] !== undefined) {
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRIDET = er1[0].CODSRI_DESCRIPCION;
          }
        });

      this.encfacproService.erCodSriDescripcion4(this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy'),
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM).subscribe(er2 => {
          if (er2[0] !== undefined) {
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOMDET = er2[0].CODSRI_DESCRIPCION;
          }
        });

      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEICE =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEICE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORICE =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORICE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASENOOBJIVA =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASENOOBJIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.strFacturaExportadaarray[this.indicador] = data.ENCFACPRO_FLAG === null ? '' : data.ENCFACPRO_FLAG;
      this.encfacproService.dblPorcDescGlbarray[this.indicador] = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES;
      this.encfacproService.dblValorDescGlbarray[this.indicador] = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES;

      if (this.encfacproService.strFacturaExportadaarray[this.indicador] === '2') {
        const dblTotalNeto = data.ENCFACPRO_TOTALNETO;
        const dblBaseIva = data.ENCFACPRO_BASEIVA;
        const dblBaseIce = data.ENCFACPRO_BASEICE;
        const dblBaseCero = data.ENCFACPRO_BASECERO;
        const dblValorIva = data.ENCFACPRO_VALORIVA;
        const dblValorIce = data.ENCFACPRO_VALORICE;
        const dblValorTotal = auxtotal;
        const dblBaseNoObjetoIva = data.ENCFACPRO_BASENOOBJIVA;

        this.encfacproService.mostrarTotalesFacExp(dblTotalNeto, dblBaseIva, dblBaseIce,
          dblBaseCero, dblValorIva, dblValorIce, dblValorTotal, dblBaseNoObjetoIva);
      }

      this.encfacproService.comas();

      const r2 = await this.encfacproService.getDetfacproPromise(encfacprocodigo);
      this.encfacproService.detfacproarray[this.indicador] = r2;
      let intSW = 0;

      for (const rs of this.encfacproService.detfacproarray[this.indicador]) {
        rs.DETFACPRO_PORIVA = Number(rs.DETFACPRO_PORIVA).toFixed(this.confIniciales.getNumDecSist());
        rs.DETFACPRO_CANTIDAD = Number(rs.DETFACPRO_CANTIDAD).toFixed(this.encfacproService.decimalesCANTIDADfac);
        rs.DETFACPRO_COSTO = Number(rs.DETFACPRO_COSTO).toFixed(this.encfacproService.decimalesCOSTOfac);
        rs.DETFACPRO_TOTAL = Number(rs.DETFACPRO_TOTAL).toFixed(this.encfacproService.decimalesTOTALDetFacPro);
        rs.DETFACPRO_PORDES = Number(rs.DETFACPRO_PORDES).toFixed(3);//GSRF
        rs.DETFACPRO_VALDES = Number(rs.DETFACPRO_VALDES).toFixed(this.encfacproService.decimalesCOSTOfac);//GSRF
        rs.DETFACPRO_PORDES2 = Number(rs.DETFACPRO_PORDES2).toFixed(this.confIniciales.getNumDecSist());
        rs.DETFACPRO_PORDES3 = Number(rs.DETFACPRO_PORDES3).toFixed(this.confIniciales.getNumDecSist());
        rs.DETFACPRO_XML = (rs.DETFACPRO_CODIGO === null && rs.DETFACPRO_TIPODET !== '*') ? true : false;
        rs.DETFACPRO_AJUSTE = rs.DETFACPRO_AJUSTE === null || rs.DETFACPRO_AJUSTE === undefined ? '' : rs.DETFACPRO_AJUSTE;//GSRF
        rs.ENCNOTREC_NUMERO = rs.ENCNOTREC_NUMERO === null || rs.ENCNOTREC_NUMERO === undefined ? '' : rs.ENCNOTREC_NUMERO;//GSRF
        this.encfacproService.consultarArticuloSel(rs.DETFACPRO_CODIGO).subscribe(datas => {
          for (const dat of datas) {
            rs.ART_MULTIUNIDAD = dat.ART_MULTIUNIDAD;
            rs.UNIDAD = dat.ART_UNIDADCOSTEO;
            rs.ART_CADUCA = dat.CADUCA;
            rs.ART_SERIALFLAG = dat.ART_SERIALFLAG;
          }
        });
        if (rs.DETFACPRO_TIPODET === 'C') {
          let strDato = '';
          this.encfacproService.erConTipGas1(rs.DETFACPRO_CODIGO).subscribe(er3 => {
            if (er3 !== null) {
              if (er3[0] !== undefined) {
                strDato = er3[0].CON_TIPGAS;
              }
            }

            if (strDato === 'Si') {
              rs.BOLTIPOGASTO = true;
            }
          });
        } else {
          rs.BOLTIPOGASTO = true;
        }

        // if (intSW === 0) {
        //   this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO = rs.BOD_CODIGO;
        //   intSW++;
        //   this.encfacproService.erBodNombre2(this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO).subscribe(er2 => {
        //     if (er2 !== null) {
        //       if (er2[0] !== undefined) {
        //         this.encfacproService.encfacproarray[this.indicador].BOD_NOMBRE = er2[0].BOD_NOMBRE;
        //       }
        //     }
        //   });
        // }
        //CDPJ
        if (intSW === 0) {
          if (rs.BOD_CODIGO === null
            || rs.BOD_CODIGO === undefined
            || rs.BOD_CODIGO === '') {
            intSW = 0;
          } else {
            console.log('entro', intSW)
            this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO = rs.BOD_CODIGO;
            intSW++;
            this.encfacproService.erBodNombre2(this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO).subscribe(er2 => {
              if (er2 !== null) {
                if (er2[0] !== undefined) {
                  this.encfacproService.encfacproarray[this.indicador].BOD_NOMBRE = er2[0].BOD_NOMBRE;
                }
              }
            });
          }
        }
        //CDPJ
      }

      await this.listarDatosRetencion();
      // this.llenarEstadoCuenta();
      // this.llenarTablaSaldos();
      const res = await this.encfacproService.verificarKardex(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
      for (const r of res) {
        this.mensajekardex = r.RESPUESTA;
      }
      this.bolProveedor = true;
      this.bolSerie = true;
      this.bolComprobante = true;
      this.encfacproService.strTipoDescuentoarray[this.indicador] = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES;//CDPJ

      const datacargos = await this.encfacproService.consultarCargos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
      if (datacargos !== undefined && datacargos !== null) {
        this.encfacproService.trncargosarray[this.indicador] = datacargos;
      }
      this.encfacproService.sumarTotalCargos();

      if (this.encfacproService.strFacturaExportadaarray[this.indicador] !== '2') {
        const intNOCATOTFP = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOCATOTFP').CFG_VALOR1;
        if (intNOCATOTFP === 0) {
          this.encfacproService.calcularTotales();
        } else {
          this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL = Number(auxtotal).toFixed(this.confIniciales.getNumDecSist());
        }
      }

      if (this.botonGuardar === false) {
        if (this.index < 2) {
          this.botonNuevo = false;
          this.botonGuardar = true;
          this.botonBorrar = false;
          this.botonRegresar = true;
        }
      }
      if (this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO !== '') {
        this.disabledEstadisticas = false;
      } else {
        this.disabledEstadisticas = true;
      }
    }
  }
  //GSRF
  async encontrarFacturaimp(encfacprocodigo) {
    this.limpiartablas();
    const resp = await this.encfacproService.getEncfacproimp(encfacprocodigo);
    for (const data of resp) {
      data.ENCFACPRO_NUMERO_IMP = data.ENCFACPRO_NUMERO;
      data.ENCFACPRO_NUMERO = '';
      data.ENCFACPRO_FECHAVENCIMIENTO = new Date(data.ENCFACPRO_FECHAVENCIMIENTO);
      data.ENCFACPRO_FECHAREC = new Date(data.ENCFACPRO_FECHAREC);
      data.ENCFACPRO_FECHAEMISION = new Date(data.ENCFACPRO_FECHAEMISION);
      data.ENCFACPRO_FECHACADFAC = new Date(data.ENCFACPRO_FECHACADFAC);
      data.ENCFACPRO_FECHAAUT_FELIQ = new Date(data.ENCFACPRO_FECHAAUT_FELIQ);
      data.ENCFACPRO_FECHAAUTRET_FE = new Date(data.ENCFACPRO_FECHAAUTRET_FE);
      const auxtotal = data.ENCFACPRO_TOTAL;
      data.ENCFACPRO_TOTAL = '';
      data.ENCFACPRO_FLAG = '11';//CDPJ
      data.ENCFACPRO_ORIGEN = 'IMP';//CDPJ
      //data.ENCFACPRO_IVA='1';//CDPJ
      const er = await this.encfacproService.erRefNombrec1(data.ENCFACPRO_ESTADO);
      if (er[0] !== undefined) {
        data.ENCFACPRO_ESTADO = er[0].REF_NOMBREC;
      }

      if (data.ENCFACPRO_ESTADO === 'PAGADA' || data.ENCFACPRO_ESTADO === 'ANULADA') {
        document.getElementById('contorno').style.pointerEvents = 'none';
        document.getElementById('contorno2').style.pointerEvents = 'none';
        document.getElementById('contorno3').style.pointerEvents = 'none';
        //this.aggrid.bloquearGrid();
        this.bloquearbotonesgrid();//CDPJ
        this.bolAnularRetencion = true;
        this.botonBorrar = true;
        this.botonAnular = true;
      } else {
        document.getElementById('contorno').style.pointerEvents = 'all';
        document.getElementById('contorno2').style.pointerEvents = 'all';
        document.getElementById('contorno3').style.pointerEvents = 'all';
        this.aggrid.habilitarGrid();
        this.activarbotonesgrid();//CDPJ
        this.bolAnularRetencion = false;
        this.botonBorrar = false;
        this.botonAnular = false;
      }
      this.encfacproService.encfacproarray[this.indicador] = data;
      this.completarDatosProveedor(this.encfacproService.encfacproarray[this.indicador], 'consulta');

      this.chkgasto = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_GASTO === 'S';
      this.chknoobjiva = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NOOBJETOIVA === 'S';
      this.chkDisCargosSobreCostos = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_DISTCARGOS === 'S';

      for (const j of this.cmbivaaux) {
        if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA === j.codigo) {
          this.cmbiva = [{ codigo: j.codigo, name: j.name }];
          this.selecttipoiva = { codigo: j.codigo, name: j.name }
        }
      }

      // Codigo Cuenta por Cobrar
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === 'C') {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO = 'Crédito';
      } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === 'T') {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO = 'Contado';
      } else {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO = '';
      }

      let strSen = '';
      strSen = strSen + ' to_date( \'' + this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION,
        'dd/MM/yyyy') + '\' ,\'dd/mm/yyyy\')>= codsri_fechaini';
      strSen = strSen + ' and to_date( \'' + this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION,
        'dd/MM/yyyy') + '\' ,\'dd/mm/yyyy\')<= codsri_fechafin';


      this.encfacproService.erCodSriDescripcion3(this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy'),
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI).subscribe(er1 => {
          if (er1[0] !== undefined) {
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRIDET = er1[0].CODSRI_DESCRIPCION;
          }
        });

      this.encfacproService.erCodSriDescripcion4(this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy'),
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM).subscribe(er2 => {
          if (er2[0] !== undefined) {
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOMDET = er2[0].CODSRI_DESCRIPCION;
          }
        });

      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEICE =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEICE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORICE =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORICE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASENOOBJIVA =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASENOOBJIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES =
        Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.strFacturaExportadaarray[this.indicador] = data.ENCFACPRO_FLAG === null ? '' : data.ENCFACPRO_FLAG;
      this.encfacproService.dblPorcDescGlbarray[this.indicador] = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES;
      this.encfacproService.dblValorDescGlbarray[this.indicador] = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES;

      if (this.encfacproService.strFacturaExportadaarray[this.indicador] === '2') {
        const dblTotalNeto = data.ENCFACPRO_TOTALNETO;
        const dblBaseIva = data.ENCFACPRO_BASEIVA;
        const dblBaseIce = data.ENCFACPRO_BASEICE;
        const dblBaseCero = data.ENCFACPRO_BASECERO;
        const dblValorIva = data.ENCFACPRO_VALORIVA;
        const dblValorIce = data.ENCFACPRO_VALORICE;
        const dblValorTotal = auxtotal;
        const dblBaseNoObjetoIva = data.ENCFACPRO_BASENOOBJIVA;

        this.encfacproService.mostrarTotalesFacExp(dblTotalNeto, dblBaseIva, dblBaseIce,
          dblBaseCero, dblValorIva, dblValorIce, dblValorTotal, dblBaseNoObjetoIva);
      }

      this.encfacproService.comas();

      const r2 = await this.encfacproService.getDetfacproimp(encfacprocodigo);
      this.encfacproService.detfacproarray[this.indicador] = r2;
      let intSW = 0;

      for (const rs of this.encfacproService.detfacproarray[this.indicador]) {
        rs.ENCFACPRO_NUMERO = '';
        // rs.DETFACPRO_CODIGO = '';
        rs.DETFACPRO_TIPODET = '';
        rs.DETFACPRO_LINEA = 0;
        rs.DETFACPRO_CODIGO_IMP = rs.DETFACPRO_CODIGO;
        rs.DETFACPRO_PORIVA = Number(rs.DETFACPRO_PORIVA).toFixed(this.confIniciales.getNumDecSist());
        rs.DETFACPRO_CANTIDAD = Number(rs.DETFACPRO_CANTIDAD).toFixed(this.encfacproService.decimalesCANTIDADfac);
        rs.DETFACPRO_COSTO = Number(rs.DETFACPRO_COSTO).toFixed(this.encfacproService.decimalesCOSTOfac);
        rs.DETFACPRO_TOTAL = Number(rs.DETFACPRO_TOTAL).toFixed(this.encfacproService.decimalesTOTALDetFacPro);
        rs.DETFACPRO_PORDES = Number(rs.DETFACPRO_PORDES).toFixed(3);//GSRF
        rs.DETFACPRO_VALDES = Number(rs.DETFACPRO_VALDES).toFixed(this.encfacproService.decimalesCOSTOfac);//GSRF
        rs.DETFACPRO_PORDES2 = Number(rs.DETFACPRO_PORDES2).toFixed(this.confIniciales.getNumDecSist());
        rs.DETFACPRO_PORDES3 = Number(rs.DETFACPRO_PORDES3).toFixed(this.confIniciales.getNumDecSist());
        rs.DETFACPRO_XML = (rs.DETFACPRO_CODIGO === null && rs.DETFACPRO_TIPODET !== '*') ? true : false;
        rs.DETFACPRO_AJUSTE = rs.DETFACPRO_AJUSTE === null || rs.DETFACPRO_AJUSTE === undefined ? '' : rs.DETFACPRO_AJUSTE;//GSRF
        rs.ENCNOTREC_NUMERO = rs.ENCNOTREC_NUMERO === null || rs.ENCNOTREC_NUMERO === undefined ? '' : rs.ENCNOTREC_NUMERO;//GSRF
        this.encfacproService.consultarArticuloSel(rs.DETFACPRO_CODIGO).subscribe(datas => {
          for (const dat of datas) {
            rs.ART_MULTIUNIDAD = dat.ART_MULTIUNIDAD;
            rs.UNIDAD = dat.ART_UNIDADCOSTEO;
            rs.ART_CADUCA = dat.CADUCA;
            rs.ART_SERIALFLAG = dat.ART_SERIALFLAG;
          }
        });
        if (rs.DETFACPRO_TIPODET === 'C') {
          let strDato = '';
          this.encfacproService.erConTipGas1(rs.DETFACPRO_CODIGO).subscribe(er3 => {
            if (er3 !== null) {
              if (er3[0] !== undefined) {
                strDato = er3[0].CON_TIPGAS;
              }
            }

            if (strDato === 'Si') {
              rs.BOLTIPOGASTO = true;
            }
          });
        } else {
          rs.BOLTIPOGASTO = true;
        }

        // if (intSW === 0) {
        //   this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO = rs.BOD_CODIGO;
        //   intSW++;
        //   this.encfacproService.erBodNombre2(this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO).subscribe(er2 => {
        //     if (er2 !== null) {
        //       if (er2[0] !== undefined) {
        //         this.encfacproService.encfacproarray[this.indicador].BOD_NOMBRE = er2[0].BOD_NOMBRE;
        //       }
        //     }
        //   });
        // }
        //CDPJ
        if (intSW === 0) {
          if (rs.BOD_CODIGO === null
            || rs.BOD_CODIGO === undefined
            || rs.BOD_CODIGO === '') {
            intSW = 0;
          } else {
            console.log('entro', intSW)
            this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO = rs.BOD_CODIGO;
            intSW++;
            this.encfacproService.erBodNombre2(this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO).subscribe(er2 => {
              if (er2 !== null) {
                if (er2[0] !== undefined) {
                  this.encfacproService.encfacproarray[this.indicador].BOD_NOMBRE = er2[0].BOD_NOMBRE;
                }
              }
            });
          }
        }
        //CDPJ
      }

      await this.listarDatosRetencion();
      // this.llenarEstadoCuenta();
      // this.llenarTablaSaldos();
      const res = await this.encfacproService.verificarKardex(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
      for (const r of res) {
        this.mensajekardex = r.RESPUESTA;
      }
      this.bolProveedor = true;
      this.bolSerie = true;
      this.bolComprobante = true;

      const datacargos = await this.encfacproService.consultarCargos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
      if (datacargos !== undefined && datacargos !== null) {
        this.encfacproService.trncargosarray[this.indicador] = datacargos;
      }
      this.encfacproService.sumarTotalCargos();

      if (this.encfacproService.strFacturaExportadaarray[this.indicador] !== '2') {
        const intNOCATOTFP = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOCATOTFP').CFG_VALOR1;
        if (intNOCATOTFP === 0) {
          this.encfacproService.calcularTotales();
        } else {
          this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL = Number(auxtotal).toFixed(this.confIniciales.getNumDecSist());
        }
      }

      if (this.botonGuardar === false) {
        if (this.index < 2) {
          this.botonNuevo = false;
          this.botonGuardar = true;
          this.botonBorrar = false;
          this.botonRegresar = true;
        }
      }
      if (this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO !== '') {
        this.disabledEstadisticas = false;
      } else {
        this.disabledEstadisticas = true;
      }
    }
    console.log('++++', this.encfacproService.encfacproarray[this.indicador])
    console.log('++++', this.encfacproService.detfacproarray[this.indicador])
  }
  //GSRF
  async listarDatosRetencion() {
    const data = await this.encfacproService.getDatosretencion(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
    let linea = 1;
    this.bolcmbSerie = false;
    this.encfacproService.trnretencionarray[this.indicador] = data;
    if (this.encfacproService.trnretencionarray[this.indicador].length > 0) {
      this.nuevoret = false;
    } else {
      this.nuevoret = true;
    }
    for (const rs of this.encfacproService.trnretencionarray[this.indicador]) {
      rs.TRNRETENCION_FECHAEMISION = this.datePipe.transform(rs.TRNRETENCION_FECHAEMISION, 'dd/MM/yyyy');
      rs.TRNRETENCION_FECHAPAGODIV = this.datePipe.transform(rs.TRNRETENCION_FECHAPAGODIV, 'dd/MM/yyyy');
      rs.ENCFAC_NUMERO = rs.TRNCOBRO_NROCOMPROBANTE;
      rs.CLAVE = linea + '' + rs.RETENCION_CODIGO;
      linea++;
      this.encfacproService.encretarray[this.indicador] = {
        TRNRETENCION_SERIE: rs.TRNRETENCION_SERIE,
        TRNRETENCION_NRO: rs.TRNRETENCION_NRO,
        TRNRETENCION_AUTORIZACION: rs.TRNRETENCION_AUTORIZACION,
        DOCREF: ''
      };
      this.trnretencionSelected = rs;
      this.bolcmbSerie = true;
    }

    this.encfacproService.calcularTotalesRetencion();

    for (const j of this.cmbserieaux) {
      if (this.encfacproService.encretarray[this.indicador].TRNRETENCION_SERIE === j.codigo) {
        this.cmbserie = [{ codigo: j.codigo, name: j.name }];
      }
    }

    const data2 = await this.encfacproService.getSaciMaeNumeraciongProm(this.encfacproService.encretarray[this.indicador].TRNRETENCION_SERIE);
    for (const rs of data2) {
      this.encfacproService.encretarray[this.indicador].DOCREF = rs.NUM_DOCREF;
      this.encfacproService.encretarray[this.indicador].TRNRETENCION_AUTORIZACION = rs.NUM_AUTORIZACION;
    }

  }

  nuevo() {
    if (this.index === 0) {
      this.botonNuevo = true;
      this.botonGuardar = false;
      this.botonBorrar = true;
      this.botonRegresar = false;
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
      this.bolcmbSerie = false;
      this.bolNumRet = true;
      this.bolProveedor = false;
      this.bolSerie = false;
      this.bolComprobante = false;
      this.bolAnularRetencion = false;
      this.nuevoret = true;
      this.activarbotonesgrid();//CDPJ
      this.limpiartablas();

      document.getElementById('contorno').style.pointerEvents = 'all';
      document.getElementById('contorno2').style.pointerEvents = 'all';
      document.getElementById('contorno3').style.pointerEvents = 'all';
      this.aggrid.habilitarGrid();

      document.getElementById('PRO_CODIGO').focus();
    }
  }

  limpiartablas() {
    this.index = 0;
    this.tipodetalle = this.TIPDET === null || this.TIPDET === undefined ? 'A' : this.TIPDET;
    if (this.encfacproService.encfacproarray[this.indicador] !== undefined) {
      this.ultimabodegacod = this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO;
      this.ultimabodeganame = this.encfacproService.encfacproarray[this.indicador].BOD_NOMBRE;
    }
    this.nuevaFactura();
    this.limpiarDatosFactura();
    this.bolNumRet = true;
    this.bolestcta = false;
    this.bolsaldo = false;
    this.lblAplicaRegMicroempresas = '';
    this.mensajekardex = '';
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION = new Date();
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAREC = new Date();
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHACADFAC = new Date();
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAVENCIMIENTO = new Date();
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO = 'PENDIENTE';
    this.encfacproService.dblPorcDescGlbarray[this.indicador] = 0;
    this.encfacproService.dblValorDescGlbarray[this.indicador] = 0;
    this.control = 0;//CDPJ
  }

  nuevaFactura() {
    this.encfacproService.encfacproarray[this.indicador] = {
      ENCFACPRO_NUMERO: '',
      COM_CODIGO: '',
      ENCFACPRO_FECHAEMISION: '',
      ENCFACPRO_FECHAVENCIMIENTO: '',
      ENCFACPRO_IVA: '',
      ENCFACPRO_ESTADO: '',
      PRO_CODIGO: '',
      PRO_NOMBRE: '',
      ENCFACPRO_CONTACTO: '',
      ENCFACPRO_REFERENCIA: '',
      ENCFACPRO_TOTAL: '',
      ENCFACPRO_TOTALNETO: '',
      ENCFACPRO_VALORDES: '',
      ENCFACPRO_PORCEDES: '',
      ENCFACPRO_VALORIVA: '',
      ENCFACPRO_PORCEIVA: '',
      ENCFACPRO_VALORICE: '',
      ENCFACPRO_BASEIVA: '',
      ENCFACPRO_BASEICE: '',
      ENCFACPRO_BASECERO: '',
      ENCFACPRO_COMENTARIO: '',
      ENCFACPRO_OTROS: '',
      ASI_NRO: '',
      ENCFACPRO_FECHAREC: '',
      ENCFACPRO_DISTCARGOS: '',
      ENCFACPRO_NUMDIASPLAZO: '',
      ENCFACPRO_IDCRETRI: '',
      ENCFACPRO_IDCRETRIDET: '',
      ENCFACPRO_SERIE: '',
      ENCFACPRO_AUTORIZACION: '',
      ENCFACPRO_TIPCOM: '',
      ENCFACPRO_TIPCOMDET: '',
      ENCFACPRO_MONTIVAPRESER: '',
      ENCFACPRO_PORIVAPRESER: '',
      ENCFACPRO_MONTRETPRESER: '',
      ENCFACPRO_MONTIVATRABIE: '',
      ENCFACPRO_PORIVATRABIE: '',
      ENCFACPRO_MONTRETTRABIE: '',
      MAEMOTIVO_CODIGO: '',
      ENCFACPRO_FECHACADFAC: '',
      ENCFACPRO_FLAG: '',
      ENCFACPRO_NUMINGRESO: '',
      ENCFACPRO_NUMLIQUIDACION: '',
      ENCFACPRO_FORMAPAGO: '',
      ENCFACPRO_TIPODES: '',
      USU_IDENTIFICACION: '',
      ENCFACPRO_GASTO: '',
      ENCORDCOM_NUMERO: '',
      ENCFACPRO_PAGOLOCALEXT: '',
      ENCFACPRO_PAISPAGO: '',
      ENCFACPRO_CONVDOBLETRIB: '',
      ENCFACPRO_PAGOEXTERIOR: '',
      ENCFACPRO_RETSERIEEST: '',
      ENCFACPRO_RETSERIEPTOEMI: '',
      ENCFACPRO_RETSECUENCIAL: '',
      ENCFACPRO_RETAUTORIZACION: '',
      ENCFACPRO_RETFECHA: '',
      ENCFACPRO_ESTADO_FE: '',
      ENCFACPRO_AUTORIZACION_FE: '',
      ENCFACPRO_CLAVEACCESO_FE: '',
      ENCFACPRO_NOOBJETOIVA: '',
      ENCFACPRO_NUMRETENCION: '',
      ENCFACPRO_LOCALIZACIONXML: '',
      ENCFACPRO_LOCALIZACIONPDF: '',
      ENCFACPRO_FECHAAUTRET_FE: '',
      ENCFACPRO_LOCALIZACIONXMLRET: '',
      ENCFACPRO_PATHXMLRET: '',
      COM_TIPOAMBFACTELEC: '',
      ENCFACPRO_PATHXMLNOAUTO_FE: '',
      ENCFACPRO_PATHPDF_FE: '',
      ENCFACPRO_BLOQUEFACXML: '',
      ENCFACPRO_BASENOOBJIVA: '',
      ENCFACPRO_PAGOREGFISCAL: '',
      ENCFACPRO_TIPOREGFISCAL: '',
      ENCFACPRO_CODPAISREGGEN: '',
      ENCFACPRO_CODPAISPARFIS: '',
      ENCFACPRO_DENOMINACION: '',
      ENCFACPRO_RUCTRANSPORTISTA: '',
      ENCFACPRO_RAZONTRANSPORTISTA: '',
      ENCFACPRO_REGIMENTRANSPOR: '',
      ENCFACPRO_ESTADO_FELIQ: '',
      ENCFACPRO_AUTORIZACION_FELIQ: '',
      ENCFACPRO_CLAVEACCESO_FELIQ: '',
      TRNFORMAPAGO_CODIGO: '',
      ENCFACPRO_FECHAAUT_FELIQ: '',
      BOD_CODIGO: this.ultimabodegacod,
      BOD_NOMBRE: this.ultimabodeganame,
      PRO_TIPOID: '',
      TOTALCARGOS: '0.0',
      TOTALRETENCION: '0.0',
      ENCFACPRO_ORIGEN: ''
    };
    this.encfacproService.detfacproarray[this.indicador] = [];

    this.encfacproService.encretarray[this.indicador] = {
      TRNRETENCION_SERIE: '',
      TRNRETENCION_NRO: '',
      TRNRETENCION_AUTORIZACION: '',
      DOCREF: ''
    };
    this.encfacproService.trnretencionarray[this.indicador] = [];
    this.encfacproService.trncargosarray[this.indicador] = [];
    this.encfacproService.strTipoDescuentoarray[this.indicador] = '';//CDPJ
  }

  eliminar() {
    let errores = 0;
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA') {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'La factura está pagada'
      });
      errores++;
      return;
    }

    console.log(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO);
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'La factura está anulada'
      });
      errores++;
      return;
    }

    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        // this.confirmationService.close();
        // if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'A') {
        //   this.messageService.add({
        //     key: 'encfacpro',
        //     severity: 'error',
        //     summary: 'Error',
        //     detail: 'La factura no puede ser eliminada ya que se encuentra anulada'
        //   });
        //   return;
        // }
        // if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '' ||
        //   this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === null ||
        //   this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === undefined) {
        //   this.messageService.add({
        //     key: 'encfacpro',
        //     severity: 'error',
        //     summary: 'Error',
        //     detail: 'Seleccione el registro a Eliminar'
        //   });
        //   return;
        // }
        // this.validarSerialesEli();
        this.confirmationService.close();
        if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'A') {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'error',
            summary: 'Error',
            detail: 'La factura no puede ser eliminada ya que se encuentra anulada'
          });
          errores++;
          //return;
        } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '' ||
          this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === null ||
          this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === undefined) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'error',
            summary: 'Error',
            detail: 'Seleccione el registro a Eliminar'
          });
          errores++;
          //return;
          //CDPJ
        } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PENDIENTE') {
          try {
            const resp = await this.encfacproService.getElectrndocumento(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
            console.log(resp);
            //console.log(resp.NUMERO)
            const trncobro = await this.encfacproService.extraercobro(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO)
            //console.log(trncobro);
            //console.log(trncobro[0].NUMERO );
            if (trncobro === null || trncobro === '' || trncobro === undefined) {
              console.log('if', trncobro);
            } else {
              if (trncobro[0].NUMERO !== 0) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'error',
                  summary: 'Error',
                  detail: 'La factura tiene abonos'
                });
                errores++;
                // return;
              }
            }
            if (resp === null || resp === '' || resp === undefined) {
              console.log('if', resp);
            } else {
              if (resp[0].NUMERO !== 0) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'error',
                  summary: 'Error',
                  detail: 'La factura tiene retenciones firmadas'
                });
                errores++;
              }
            }
          } catch (error) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'error',
              summary: 'Error',
              detail: error
            });
          }
        }//else{
        //console.log('errores>>>><', errores);
        if (errores === 0) {
          this.validarSerialesEli();
        }
        //}
        //CDPJ
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  validacionesEliminar() {
    this.encfacproService.extraerCodigo(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(ec => {
      const LstCodigosOrdComNotRec: ComDetfacpro[] = [];

      if (ec !== null) {
        for (const rs of ec) {
          const item: ComDetfacpro = {};
          item.DETFACPRO_ESQUEMADOC = rs.DETFACPRO_ESQUEMADOC;
          item.ENCNOTREC_NUMERO = rs.ENCNOTREC_NUMERO;
          item.ENCORDCOM_NUMERO = rs.ENCORDCOM_NUMERO;
          LstCodigosOrdComNotRec.push(item);
        }
      }

      this.encfacproService.eliminarFactura(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(() => {
        const strNumFactura = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
        const strFechaEmision = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
        this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', strNumFactura + '/' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE
          + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA + '/'
          + this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO, 'E',
          '', '01', '', '', '', '').subscribe();
        for (const item2 of LstCodigosOrdComNotRec) {
          item2.ENCFACPRO_NUMERO = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
          this.encfacproService.ActEstTransKardex(item2).subscribe();
        }

        this.EliminaTrnKardexOtr(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, 'COM',
          0, '', '');

        this.actualizarEstadoOrdenCompra();
        this.encfacproService.deleteimptable(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION)
        this.messageService.add({
          key: 'encfacpro',
          severity: 'success',
          summary: 'Información',
          detail: 'Registro eliminado correctamente'
        });

        this.limpiartablas();

        const intRECCOSPRO = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RECCOSPRO').CFG_VALOR1;
        if (intRECCOSPRO === 1) {
          this.encfacproService.recalcularCostosArtEliminados(strNumFactura, strFechaEmision).subscribe();
        }

      });
    });
  }

  async cancelar() {
    if (this.index < 3) {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = true;
    }

    const encfacnumero = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
    this.limpiartablas();
    this.encontrarFactura(encfacnumero);
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }

  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }


  busquedafacturapro() {
    this.opcionbusqueda = 'factura';
    this.types = [
      { label: 'NUMERO', value: 'ENCFACPRO_NUMERO' },
      { label: 'REFERENCIA', value: 'ENCFACPRO_REFERENCIA' },
      { label: 'FEC.EMI', value: 'ENCFACPRO_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCFACPRO_ESTADO' },
      { label: 'NOMBRE_PROV', value: 'PRO_NOMBRE' },
      { label: 'NOMBRE_CORTO', value: 'PRO_NOMBREC' },
      { label: 'PROVEEDOR', value: 'PRO_CODIGO' },
      { label: 'CONTACTO', value: 'ENCFACPRO_CONTACTO' },
      { label: 'TOTAL', value: 'ENCFACPRO_TOTAL' },
      { label: 'TOTALNETO', value: 'ENCFACPRO_TOTALNETO' },
      { label: 'BASEIVA', value: 'ENCFACPRO_BASEIVA' },
      { label: 'COMENTARIO', value: 'ENCFACPRO_COMENTARIO' }//GSRF
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = 'A.*, COM_FNC_NOMBRE_PROVEEDOR(A.PRO_CODIGO) AS PRO_NOMBRE';
    this.tabla = 'COM_ENCFACPRO A';
    this.where = 'ENCFACPRO_TIPCOM NOT IN (\'03\') and NVL(ENCFACPRO_ORIGEN,\'FACCOM\')<>\'LIQ\' ORDER BY ENCFACPRO_FECHAEMISION DESC ';

    this.busqService.busquedaComEncFacPro3().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      console.log(datos);
      this.arregloCons.map((registro) => {
        if (registro.ENCFACPRO_TOTALNETO !== null) {
          registro.ENCFACPRO_TOTALNETO = Number(registro.ENCFACPRO_TOTALNETO).toFixed(2);
        }
        if (registro.ENCFACPRO_BASEIVA !== null) {
          registro.ENCFACPRO_BASEIVA = Number(registro.ENCFACPRO_BASEIVA).toFixed(2);
        }

        if (registro.ENCFACPRO_FECHAEMISION !== null) {
          registro.ENCFACPRO_FECHAEMISION = this.datePipe.transform(registro.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCFACPRO_FECHAEMISION = '';
        }
        if (registro.ENCFACPRO_TOTAL !== null) {
          registro.ENCFACPRO_TOTAL = Number(registro.ENCFACPRO_TOTAL).toFixed(2);
        } else {
          registro.ENCFACPRO_TOTAL = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }
  //GSRF
  busquedafactuIMPTMP() {
    this.opcionbusqueda = 'facturaimp';
    this.types = [
      { label: 'NUMERO', value: 'ENCFACPRO_NUMERO' },
      { label: 'REFERENCIA', value: 'ENCFACPRO_REFERENCIA' },
      { label: 'FEC.EMI', value: 'ENCFACPRO_FECHAEMISION' },
      { label: 'PROVEEDOR', value: 'PRO_CODIGO' },
      { label: 'NOMBRE_PROV', value: 'PRO_NOMBRE' },
      { label: 'TOTAL', value: 'ENCFACPRO_TOTAL' },
      { label: 'TOTALNETO', value: 'ENCFACPRO_TOTALNETO' },
      { label: 'COMENTARIO', value: 'ENCFACPRO_COMENTARIO' }//GSRF
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = 'A.*, COM_FNC_NOMBRE_PROVEEDOR(A.PRO_CODIGO) AS PRO_NOMBRE';
    this.tabla = 'COM_ENCFACPRO_IMP A';
    this.where = 'ENCFACPRO_TIPCOM NOT IN (\'03\') ORDER BY ENCFACPRO_NUMERO ';

    this.busqService.busquedaComEncFacProimp().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      console.log(datos);
      this.arregloCons.map((registro) => {
        if (registro.ENCFACPRO_TOTALNETO !== null) {
          registro.ENCFACPRO_TOTALNETO = Number(registro.ENCFACPRO_TOTALNETO).toFixed(2);
        }
        if (registro.ENCFACPRO_BASEIVA !== null) {
          registro.ENCFACPRO_BASEIVA = Number(registro.ENCFACPRO_BASEIVA).toFixed(2);
        }

        if (registro.ENCFACPRO_FECHAEMISION !== null) {
          registro.ENCFACPRO_FECHAEMISION = this.datePipe.transform(registro.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCFACPRO_FECHAEMISION = '';
        }
        if (registro.ENCFACPRO_TOTAL !== null) {
          registro.ENCFACPRO_TOTAL = Number(registro.ENCFACPRO_TOTAL).toFixed(2);
        } else {
          registro.ENCFACPRO_TOTAL = '';
        }
        registro.ENCFACPRO_FLAG = '11';
      });
      this.displayDialogBusquedaFast = true;
    });
  }
  //GSRF
  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'factura') {
      this.encontrarFactura(opcion.ENCFACPRO_NUMERO);
    }
    //GSRF
    if (this.opcionbusqueda === 'facturaimp') {
      this.encontrarFacturaimp(opcion.ENCFACPRO_NUMERO);
    }
    //GSRF
    if (this.opcionbusqueda === 'proveedor') {
      this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO = opcion.PRO_CODIGO;
      this.encfacproService.encfacproarray[this.indicador].PRO_NOMBRE = opcion.PRO_NOMBRE;
      this.completarDatosProveedor(opcion, 'nuevo');
      document.getElementById('ENCFACPRO_SERIE').focus();
    }

    if (this.opcionbusqueda === 'transportista') {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_RUCTRANSPORTISTA = opcion.PRO_CODIGO;
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_RAZONTRANSPORTISTA = opcion.PRO_NOMBRE;
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REGIMENTRANSPOR = opcion.PRO_TIPOA;

      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REGIMENTRANSPOR !== null) {
        this.encfacproService.erCodSriDescripcion5(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REGIMENTRANSPOR).subscribe(er1 => {
          if (er1 !== null) {
            if (er1[0] !== undefined) {
              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REGIMENTRANSPOR = er1[0].CODSRI_DESCRIPCION;
            }
          }
        });
      } else {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REGIMENTRANSPOR = '';
      }
      document.getElementById('ENCFACPRO_SERIE').focus();
    }

    if (this.opcionbusqueda === 'cretri') {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI = opcion.CODSRI_CODIGO;
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRIDET = opcion.CODSRI_DESCRIPCION;
      document.getElementById('ENCFACPRO_TIPCOM').focus();
    }

    if (this.opcionbusqueda === 'cretrixml') {
      this.idcreditoxml = opcion.CODSRI_CODIGO;
      this.idcreditonombrexml = opcion.CODSRI_DESCRIPCION;
      if (document.getElementById('CENTROCOSTOSXML') !== null) {
        document.getElementById('CENTROCOSTOSXML').focus();
      }
      if (document.getElementById('CENTROCOSTOSXMLF1') !== null) {
        document.getElementById('CENTROCOSTOSXMLF1').focus();
      }
      if (document.getElementById('CENTROCOSTOSXMLF2') !== null) {
        document.getElementById('CENTROCOSTOSXMLF2').focus();
      }
    }

    if (this.opcionbusqueda === 'centro') {
      this.centrocostosxml = opcion.CEN_CODIGO;
      this.centrocostosnombrexml = opcion.CEN_NOMBRE;
      if (document.getElementById('LOCALIZACIONXML2') !== null) {
        document.getElementById('LOCALIZACIONXML2').focus();
      }
      if (document.getElementById('LOCALIZACIONXML2F1') !== null) {
        document.getElementById('LOCALIZACIONXML2F1').focus();
      }
      if (document.getElementById('LOCALIZACIONXML2F2') !== null) {
        document.getElementById('LOCALIZACIONXML2F2').focus();
      }
    }

    if (this.opcionbusqueda === 'tipcom') {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM = opcion.CODSRI_CODIGO;
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOMDET = opcion.CODSRI_DESCRIPCION;
      this.autorizacionSri('tipdoc');
      document.getElementById('BOD_CODIGO').focus();
    }

    if (this.opcionbusqueda === 'bodega') {
      this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
      this.encfacproService.encfacproarray[this.indicador].BOD_NOMBRE = opcion.BOD_NOMBRE;
      document.getElementById('ENCFACPRO_AUTORIZACION').focus();
    }

    if (this.opcionbusqueda === 'bodegaxml') {
      this.bodcodigoxml = opcion.BOD_CODIGO;
      this.bodnombrexml = opcion.BOD_NOMBRE;
      if (document.getElementById('IDCREDITOXML') !== null) {
        document.getElementById('IDCREDITOXML').focus();
      }
      if (document.getElementById('IDCREDITOXMLF1') !== null) {
        document.getElementById('IDCREDITOXMLF1').focus();
      }
      if (document.getElementById('IDCREDITOXMLF2') !== null) {
        document.getElementById('IDCREDITOXMLF2').focus();
      }
    }


    if (this.opcionbusqueda === 'agOC') {
      const strEncOrdCom = opcion.ENCORDCOM_NUMERO;
      const intSALCM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'SALCM').CFG_VALOR1;
      if (intSALCM === 1) { // manejo especial utilizando requisiciones
        this.verificaSiTieneLiberacionReq(strEncOrdCom);
      } else {
        this.LlenarcamposOrdenCompra(strEncOrdCom);
      }
    }

    if (this.opcionbusqueda === 'agNR') {
      const strEncNotRec = opcion.ENCNOTREC_NUMERO;
      this.LlenarcamposNotaRecepcion(strEncNotRec);

    }

    if (this.opcionbusqueda === 'articulo') {
      this.txtArtCodigo = opcion.ART_CODIGO;
      this.txtArtDescripcion = opcion.ART_NOMBRE;
      this.txtArtUnidad = opcion.ART_UNIDADCOSTEO;
      this.txtExistenciaMin = opcion.ART_CANTMIN === null ? 0 : opcion.ART_CANTMIN;
      this.txtExistenciaMax = opcion.ART_CANTMAX === null ? 0 : opcion.ART_CANTMAX;
      this.llenarTablaExistencia();
      this.llenarMovByProvArt('fecha');
      this.llenarExistenciaBodegas();
      this.ventasVsCompras();
      this.llenarIndiceProvisiones();
    }

    if (this.opcionbusqueda === 'agGui') {
      this.LlenarcamposGuiaRemision(opcion.ENCGRE_CODIGO)
    }

    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }


  completarDatosProveedor(rs, aux) {
    this.strCodCuentaProv = rs.CON_CODIGO1;
    this.strTransporte = '';
    if (aux === 'nuevo') {
      this.encfacproService.encfacproarray[this.indicador].PRO_TIPOID = '';
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_CONTACTO = '';
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE = '';
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE = '';
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMDIASPLAZO = '';

      const intAUTSRI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'AUTSRI').CFG_VALOR1;

      if (intAUTSRI === 0) {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION = rs.PRO_NUMAUTORIZACION === null ? '' : rs.PRO_NUMAUTORIZACION;
      }

      if (rs.PRO_TIPOIDE !== null) {
        this.encfacproService.encfacproarray[this.indicador].PRO_TIPOID = rs.PRO_TIPOIDE;
      }
      if (rs.PRO_CONTACTO !== null) {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_CONTACTO = rs.PRO_CONTACTO;
      }
      if (rs.PRO_NUMSERIE !== null) {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE = rs.PRO_NUMSERIE;
      }
      if (rs.PRO_DIACREDIT !== null) {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMDIASPLAZO = rs.PRO_DIACREDIT;
      }
    }
    if (rs.PRO_TRANSPORTE !== null) {
      this.strTransporte = rs.PRO_TRANSPORTE;
    }
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === null) {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE = '';
    }

    this.bolTransporte = !(this.strTransporte === 'S'
      && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PENDIENTE'
      && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === '');

    this.strFacturacionElect = rs.PRO_FACTURAELECT;

    // this.bolAnularRetencion = this.strFacturacionElect === 'S';

    if (rs.PRO_CONREGMIC === 'S'
      || rs.PRO_AGENTERET === 'S') {
      if (rs.PRO_CONREGMIC === 'S'
        && rs.PRO_AGENTERET === 'S') {
        this.lblAplicaRegMicroempresas = 'Proveedor aplica régimen de Microempresas y agente de retención';
      } else {
        if (rs.PRO_CONREGMIC === 'S') {
          this.lblAplicaRegMicroempresas = 'Proveedor aplica régimen de Microempresas';
        }
        if (rs.PRO_AGENTERET === 'S') {
          this.lblAplicaRegMicroempresas = 'Proveedor aplica agente de retención';
        }
      }
    } else {
      this.lblAplicaRegMicroempresas = '';
    }
  }

  seleccionarRegistro(strTipo) {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === undefined) {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO = '';
    }

    this.VerificarIngresoRetenciones('seleccionarRegistro', strTipo);

  }

  seleccionarRegistro2(strTipo) {
    this.ValidarSeriales(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, strTipo);
  }

  irARegistro(strTipo) {
    if (strTipo === 'P') {
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }
    if (strTipo === 'A' || strTipo === 'S') {
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }
    if (strTipo === 'U') {
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = false;
      this.botonUltimo = false;
    }

    this.encfacproService.seleccionarRegistro(strTipo, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe((datos: any[]) => {
      if (datos !== null) {
        this.encontrarFactura(datos[0].ENCFACPRO_NUMERO);
      }
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'encfacpro',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }

  verificarTipoDocumento(): string {
    let strTipoDoc = '';

    if (this.encfacproService.encfacproarray[this.indicador].PRO_TIPOID === '1') { // RUC
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '01') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'04\',\'05\',\'11\',\'12\',\'21\',\'41\',\'43\',\'47\',\'48\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '02') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'02\',\'04\',\'05\',\'09\',\'11\',\'12\',\'19\',\'20\',\'21\',\'41\',\'43\',\'47\',\'48\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '03') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'04\',\'05\',\'41\',\'47\',\'48\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '04') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'02\',\'04\',\'05\',\'41\',\'47\',\'48\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '05') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'02\',\'04\',\'05\',\'11\',\'41\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '06') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'04\',\'05\',\'41\',\'43\',\'47\',\'48\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '07') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'02\',\'04\',\'05\',\'41\',\'43\',\'47\',\'48\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '08') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'02\',\'04\',\'05\',\'21\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '09') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'04\',\'05\',\'45\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '10') {
        strTipoDoc = 'codsri_codigo in (\'19\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '11') {
        strTipoDoc = 'codsri_codigo in (\'12\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '12') {
        strTipoDoc = 'codsri_codigo in (\'42\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '13') {
        strTipoDoc = 'codsri_codigo in (\'19\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '14') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'02\',\'03\',\'04\',\'05\')';
      }

    }
    if (this.encfacproService.encfacproarray[this.indicador].PRO_TIPOID === '3') { // pasaporte
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '01') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'41\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '02') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'15\',\'19\',\'41\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '03') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'41\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '04') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'15\',\'41\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '05') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'15\',\'41\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '06') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'41\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '07') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'15\',\'41\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '08') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '09') {
        strTipoDoc = 'codsri_codigo in (\'04\',\'05\',\'45\',\'294\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '10') {
        strTipoDoc = 'codsri_codigo in (\'19\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '11') {
        strTipoDoc = 'codsri_codigo in (\'454\')';
      }		// no hay credito tributario
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '12') {
        strTipoDoc = 'codsri_codigo in (\'785\')';
      }		// no hay credito tributario
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '13') {
        strTipoDoc = 'codsri_codigo in (\'19\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '14') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'02\',\'03\',\'04\',\'05\')';
      }


    }

    if (this.encfacproService.encfacproarray[this.indicador].PRO_TIPOID === '2') { // Cedula
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '01') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'41\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '02') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'19\',\'41\',\'48\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '03') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'41\',\'48\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '04') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'41\',\'48\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '05') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'41\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '06') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'41\',\'48\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '07') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'41\',\'48\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '08') {
        strTipoDoc = 'codsri_codigo in (\'03\',\'04\',\'05\',\'294\',\'344\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '09') {
        strTipoDoc = 'codsri_codigo in (\'04\',\'05\',\'45\',\'294\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '10') {
        strTipoDoc = 'codsri_codigo in (\'19\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '11') {
        strTipoDoc = 'codsri_codigo in (\'454\')';
      }		// no hay credito tributario
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '12') {
        strTipoDoc = 'codsri_codigo in (\'785\')';
      }		// no hay credito tributario
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '13') {
        strTipoDoc = 'codsri_codigo in (\'19\')';
      }
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '14') {
        strTipoDoc = 'codsri_codigo in (\'01\',\'02\',\'03\',\'04\',\'05\')';
      }

    }

    return strTipoDoc;

  }

  crearNuevoItem() {
    const strCodBodega = this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO;

    const detfacpro: ComDetfacpro = {};

    const BODCENCOS = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BODCENCOS').CFG_VALOR1;

    detfacpro.BOD_CODIGO = strCodBodega;
    if (detfacpro.BOD_CODIGO === undefined || detfacpro.BOD_CODIGO === null) {
      detfacpro.BOD_CODIGO = '';
    }

    if (BODCENCOS === 1) {
      detfacpro.CEN_CODIGO = strCodBodega;
    } else {
      detfacpro.CEN_CODIGO = '';
    }
    detfacpro.COM_CODIGO = '01';
    detfacpro.DETFACPRO_BASECERO = 0;
    detfacpro.DETFACPRO_BASEICE = 0;
    detfacpro.DETFACPRO_BASEIVA = 0;
    detfacpro.DETFACPRO_CANTIDAD = 1;
    detfacpro.DETFACPRO_CODIGO = '';
    detfacpro.DETFACPRO_COSTO = 0;
    detfacpro.DETFACPRO_DESCRIPCION = '';
    detfacpro.DETFACPRO_ENTREGADO = 0;
    detfacpro.DETFACPRO_ESQUEMADOC = '';
    detfacpro.DETFACPRO_FACTOR = 0;
    detfacpro.DETFACPRO_ICE = 0;
    detfacpro.DETFACPRO_IVA = 0;
    detfacpro.DETFACPRO_LINEA = 0;
    detfacpro.DETFACPRO_PORCEICE = 0;
    detfacpro.DETFACPRO_PORDES = 0;
    detfacpro.DETFACPRO_PORDES2 = 0;
    detfacpro.DETFACPRO_PORDES3 = 0;
    detfacpro.DETFACPRO_PORIVA = 0;
    detfacpro.DETFACPRO_PRECIOFOB = 0;
    detfacpro.DETFACPRO_PROMOCION = '';
    detfacpro.DETFACPRO_TIPODET = this.tipodetalle;
    detfacpro.DETFACPRO_TOTAL = 0;
    detfacpro.DETFACPRO_TOTALINCP = 0;
    detfacpro.DETFACPRO_TRIBICE = '';
    detfacpro.DETFACPRO_AJUSTE = '';//GSRF
    detfacpro.DETFACPRO_UNIDAD = '';
    detfacpro.DETFACPRO_VALDES = 0;
    detfacpro.DETNOTREC_LINEA = 0;
    detfacpro.DETORDCOM_LINEA = 0;
    detfacpro.ENCFACPRO_NUMERO = '';
    detfacpro.ENCNOTREC_NUMERO = '';
    detfacpro.ENCORDCOM_NUMERO = '';
    detfacpro.DETFACPRO_TIPOSRI = '';
    detfacpro.DETFACPRO_PRECIOA = 0;
    detfacpro.DETFACPRO_PRECIOB = 0;
    detfacpro.DETFACPRO_PRECIOC = 0;
    detfacpro.DETFACPRO_TRIBASENOOBJIVA = '';
    detfacpro.DETFACPRO_BASENOOBJIVA = 0;
    detfacpro.TRNSOLFAC_CODIGO = '';
    detfacpro.TRNSOLFAC_LINEA = '';
    detfacpro.ENCREQ_NUMERO = '';
    detfacpro.DETREQ_LINEA = '';
    detfacpro.ENCGRE_CODIGO = '';
    detfacpro.DETGRE_LINEA = '';
    detfacpro.DETFACPRO_CANTIDADUND = '';

    detfacpro.TIPGAS_CODIGO = '';
    detfacpro.BOLTIPOGASTO = false;
    detfacpro.DETFACPRO_XML = false;
    //CDPJ
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA === '0') {
      detfacpro.TARIIVA_CODIGO = '7';
      detfacpro.DETFACPRO_PORIVA = 0;
    } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA === '1') {
      const data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(this.encfacproService.porceiva))
      if (data !== null && data !== undefined) {
        detfacpro.TARIIVA_CODIGO = data.TARIIVA_CODIGO;
        detfacpro.DETFACPRO_PORIVA = data.TARIIVA_PORCENTAJE;

      }
    }
    //CDPJ
    this.nuevoItem = detfacpro;
  }

  crearNuevoItemRet() {
    const trnretencion: SaciTrnretencion = {};

    trnretencion.CEN_CODIGO = '';
    trnretencion.CLI_CODIGO = '';
    trnretencion.COM_CODIGO = '01';
    trnretencion.CON_CODIGO = '';
    trnretencion.ENCFAC_NUMERO = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
    trnretencion.PRO_CODIGO = this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO;
    trnretencion.RETENCION_CODIGO = '';
    trnretencion.TRNRETENCION_TIPORET = '';
    trnretencion.TRNCOBRO_NRODOC = '';
    trnretencion.TRNRETENCION_AUTORIZACION = this.encfacproService.encretarray[this.indicador].TRNRETENCION_AUTORIZACION;

    trnretencion.TRNRETENCION_BASE = 0;
    trnretencion.TRNRETENCION_BASE0 = 0;
    trnretencion.TRNRETENCION_BASEG = 0;
    trnretencion.TRNRETENCION_BASENG = 0;
    trnretencion.TRNRETENCION_DESCRIPCION = '';
    trnretencion.TRNRETENCION_FLAG = 0;
    trnretencion.TRNRETENCION_NRO = this.encfacproService.encretarray[this.indicador].TRNRETENCION_NRO;
    trnretencion.TRNRETENCION_ORIGEN = '';
    trnretencion.TRNRETENCION_PORCENTAJE = 0;
    trnretencion.TRNRETENCION_SERIE = this.encfacproService.encretarray[this.indicador].TRNRETENCION_SERIE;
    trnretencion.TRNRETENCION_TOTALRETENIDO = 0;

    trnretencion.TRNRETENCION_FECHAPAGODIV = '';
    trnretencion.TRNRETENCION_IMPRENPAGADO = 0;
    trnretencion.TRNRETENCION_ANIOUTIL = '';

    const intRETFECEMI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RETFECEMI').CFG_VALOR1;

    if (intRETFECEMI === 0) {
      trnretencion.TRNRETENCION_FECHAEMISION = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAREC, 'dd/MM/yyyy');
    } else {
      trnretencion.TRNRETENCION_FECHAEMISION = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
    }

    trnretencion.TRNRETENCION_REFERENCIA = '';
    trnretencion.TRNCOBRO_NROCOMPROBANTE = '';
    trnretencion.COM_CODIGO_CXC = '';
    trnretencion.TRNRETENCION_ESTADO = '';
    trnretencion.CLAVE = '';


    this.nuevoItemRet = trnretencion;

  }

  // aplicarDescuentoGlobal() {
  //   if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA') {
  //     this.messageService.add({
  //       key: 'encfacpro',
  //       severity: 'warn',
  //       summary: 'Información',
  //       detail: 'La factura está pagada'
  //     });
  //     return;
  //   }

  //   if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
  //     this.messageService.add({
  //       key: 'encfacpro',
  //       severity: 'warn',
  //       summary: 'Información',
  //       detail: 'La factura está anulada'
  //     });
  //     return;
  //   }

  //   console.log(this.encfacproService.encfacproarray[this.indicador]);

  //   if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL > 0) {
  //     if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES === undefined
  //       || this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES === null
  //       || this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES === '') {
  //       this.valorDscPorcentaje = 0;
  //       this.valorDscValor = 0;
  //     } else {
  //       this.valorDscValor = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES;
  //       this.valorDscPorcentaje = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES * 100 / this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO;
  //     }

  //     this.displayDialogDescuento = true;
  //   }
  // }
  aplicarDescuentoGlobal() {

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está anulada.'
      });
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está pagada.'
      });
      return;
    }

    if (Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL)) > 0) {
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES === undefined
        || this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES === null
        || this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES === '') {
        this.valorDscPorcentaje = 0;
        this.valorDscValor = 0;
      } else {
        this.valorDscValor = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES;
        this.valorDscPorcentaje = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES;
        const strdscvalor = Number(this.init.quitarComas(this.valorDscValor)).toFixed(this.confIniciales.getNumDecSist());
        this.valorDscValor = Number(strdscvalor);
        const strdscporce = Number(this.init.quitarComas(this.valorDscPorcentaje)).toFixed(this.confIniciales.getNumDecSist());
        this.valorDscPorcentaje = Number(strdscporce);
      }

      if (this.valorDscPorcentaje > 0) {
      } else {
        if (Number(this.dscClienteActual) > 0) {
          this.valorDscPorcentaje = Number(this.dscClienteActual);
        }
      }
      console.log('this.valorDscValor<>', this.valorDscValor)
      console.log('this.valorDscPorcentaje', this.valorDscPorcentaje)
      this.displayDialogDescuento = true;
    }
  }

  // aplicarDescuento() {
  //   let tipo = '';

  //   if (this.booleanPorcentaje === false) {
  //     this.valorDscValor = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL * this.valorDscPorcentaje / 100;
  //   }

  //   if (this.booleanValor === false) {
  //     tipo = 'valor';
  //     // if (this.dscClienteActual < (this.valorDscValor * 100 / Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL))) {
  //     //   this.confirmarDescuento(tipo);
  //     // } else {
  //     this.descuentoAplicado(tipo);
  //     // }
  //   }

  //   if (this.booleanPorcentaje === false) {
  //     tipo = 'porcentaje';
  //     // if (this.dscClienteActual < this.valorDscPorcentaje) {
  //     //   this.confirmarDescuento(tipo);
  //     // } else {
  //     this.descuentoAplicado(tipo);
  //     // }
  //   }

  // }

  aplicarDescuento() {
    //this.booleanValor = false;
    // if (this.booleanPorcentaje === false) {
    //   this.valorDscValor = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL)) * this.valorDscPorcentaje / 100;
    // }

    // if (this.booleanValor === false) {
    //   this.valorDscPorcentaje = this.valorDscValor * 100 / Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL));
    // }
    console.log('this.booleanValor', this.booleanValor)
    console.log('this.booleanPorcentaje', this.booleanPorcentaje)
    let tipo = '';
    if (this.booleanValor === false) {
      tipo = 'valor';
      if (this.dscClienteActual < (this.valorDscValor * 100 / Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL)))) {
        this.confirmarDescuento(tipo);
      } else {
        this.descuentoAplicado(tipo);
      }
    }
    if (this.booleanPorcentaje === false) {
      tipo = 'porcentaje';
      if (this.dscClienteActual < this.valorDscValor) {
        this.confirmarDescuento(tipo);
      } else {
        this.descuentoAplicado(tipo);
      }
    }
  }

  confirmarDescuento(tipo) {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea aplicar un descuento mayor',
      header: 'El cliente tiene un descuento menor',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.descuentoAplicado(tipo);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  descuentoAplicado(tipo) {
    this.displayDialogDescuento = false;
    this.botonGuardar = false;
    this.botonNuevo = true;

    if (tipo === 'valor') {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES = 'V';
      this.encfacproService.dblValorDescGlbarray[this.indicador] = this.valorDscValor;
      console.log('this.valorDscValor', this.valorDscValor)
      // this.encfacproService.dblPorcDescGlbarray[this.indicador] = (this.encfacproService.dblValorDescGlbarray[this.indicador] * 100) /
      //   this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO;
      this.encfacproService.dblPorcDescGlbarray[this.indicador] = (Number(this.init.quitarComas(this.encfacproService.dblValorDescGlbarray[this.indicador])) * 100) /
        Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO));
      this.valorDscPorcentaje = this.encfacproService.dblPorcDescGlbarray[this.indicador];
      this.encfacproService.strTipoDescuentoarray[this.indicador] = 'V';//CDPJ
    }

    if (tipo === 'porcentaje') {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES = 'P';
      this.encfacproService.dblPorcDescGlbarray[this.indicador] = this.valorDscPorcentaje;
      console.log('this.valorDscPorcentaje', this.valorDscPorcentaje)
      // this.encfacproService.dblValorDescGlbarray[this.indicador] = Number(
      //   this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO * this.valorDscPorcentaje / 100);
      this.encfacproService.dblValorDescGlbarray[this.indicador] = Number(
        Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO)) * this.valorDscPorcentaje / 100);
      this.valorDscValor = this.encfacproService.dblValorDescGlbarray[this.indicador];
      this.encfacproService.strTipoDescuentoarray[this.indicador] = 'P';//CDPJ
    }

    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES = this.valorDscValor;
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES = this.valorDscPorcentaje;

    console.log('this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES)
    console.log('this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES)
    this.encfacproService.calcularTotales();
  }

  cambioDescuento() {

    if (this.dscSeleccionado === 'dscValor') {
      this.booleanValor = false;
      this.booleanPorcentaje = true;
    }

    if (this.dscSeleccionado === 'dscPorcentaje') {
      this.booleanPorcentaje = false;
      this.booleanValor = true;
    }

  }


  comprobarValor() {

    if (this.booleanValor === false) {
      if (Number(this.valorDscValor) > Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO))) {
        this.valorDscValor = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO));
      }
      this.valorDscPorcentaje = this.valorDscValor * 100 / Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO));
    }

    if (this.booleanPorcentaje === false) {

      if (Number(this.valorDscPorcentaje) > 100) {
        this.valorDscPorcentaje = 100;
      }

      this.valorDscValor = this.valorDscPorcentaje * Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO)) / 100;
    }


  }

  limpiarDatosFactura() {

    for (const k of this.cmbivaaux) {
      if (k.codigo === '1') {
        this.cmbiva = [{ codigo: k.codigo, name: k.name }];
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA = k.codigo;
        this.encfacproService.strTipoIvaarray[this.indicador] = k.name;
        this.selecttipoiva = { codigo: k.codigo, name: k.name }//CDPJ
      }
    }

    this.cmbserie = [];

  }

  calcularFechaVencimiento() {
    const dateTimeIniCobros: Date = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION;
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAVENCIMIENTO = this.obtenerFechaVence(dateTimeIniCobros.getDate(),
      dateTimeIniCobros.getMonth(), dateTimeIniCobros.getFullYear());
  }

  obtenerFechaVence(dia, mes, anio): Date {
    let fecha1: Date;
    fecha1 = new Date(anio, mes, dia);

    fecha1.setDate(dia + (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMDIASPLAZO === '' ? 0 : Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMDIASPLAZO)));

    return fecha1;
  }
  //CDPJ
  completarReferencia() {
    const intNumCar = 9;
    const intTamanioCad = (<HTMLInputElement>document.getElementById("ENCFACPRO_REFERENCIA")).value
    let strCadCeros = '';
    if (intTamanioCad.length < 9 && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA !== '') {
      for (let i = 1; i <= intNumCar - intTamanioCad.length; i++) {
        strCadCeros = strCadCeros + '0';
      }
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA = strCadCeros + intTamanioCad;
      (<HTMLInputElement>document.getElementById("ENCFACPRO_REFERENCIA")).value = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA;
    }
  }
  async revisarAbonos() {
    let numero = 0
    const data = await this.encfacproService.getRevisarAbonos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO)
    if (data != null && data !== undefined) {
      for (const data1 of data) {
        numero = data1.NUMERO === null || data1.NUMERO === undefined ? 0 : data1.NUMERO;
      }
    }
    if (numero > 0) {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Tomar en consideración que la factura tiene ingresado abonos'
      });
    }
  }
  //CDPJ
  async guardar() {
    //CDPJ
    this.encfacproService.spin = true;//CDPJ
    this.botonGuardar = true;//CDPJ
    await this.revisarAbonos();
    this.completarReferencia();
    //CDPJ
    this.eliminarUltimaLineaDetalle();
    if (this.index === 0) {
      this.encfacproService.spin = true;//CDPJ
      //GSRF 
      console.log('***---*', this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO)
      //console.log('***---*', this.encfacproService.getDatosProve(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO))
      const data = await this.encfacproService.getDatosProve(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO)
      const rs = await this.encfacproService.erBodCodigo1(this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO);
      console.log('***---*', data)
      if (data === null) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Proveedor no valido'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        return;
      }
      //GSRF 
      /*if (this.datosfe.COM_FEACTIVADA === '1') {
        this.strFacturacionElect = 'S';
      }*/
      if (rs === null) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Error, Código de la bodega no existe.'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        return;
      }
      this.encfacproService.calcularTotales();
      this.bolverificarIngresoTG = true;
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'La factura está anulada'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        return;
      } else {
        if (this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO === '') {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'warn',
            summary: 'Información',
            detail: 'Error, Por favor ingrese el código del proveedor.'
          });
          this.encfacproService.spin = false;//CDPJ
          this.botonGuardar = false;//CDPJ
        } else {
          if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE.length !== 6) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, El número de serie es incorrecto.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
          } else {
            if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA.length === 0) {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Información',
                detail: 'Error, El número de comprobante es incorrecto.'
              });
              this.encfacproService.spin = false;//CDPJ
              this.botonGuardar = false;//CDPJ
            } else {
              if (Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA) === 0) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'Error, El número de comprobante no puede ser cero.'
                });
                this.encfacproService.spin = false;//CDPJ
                this.botonGuardar = false;//CDPJ
              } else {
                let strSentencia = ' to_number(encfacpro_referencia) = to_number(\'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA + '\')' +
                  ' and pro_codigo = \'' + this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO + '\'' + ' and com_codigo = \''
                  + '01' + '\'' + ' and encfacpro_serie = \'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE + '\'';
                strSentencia = strSentencia + ' and encfacpro_numero<>nvl(\'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'' + ',\'Z\')';

                this.encfacproService.erEncfacproNumero1(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA,
                  this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE,
                  this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO,
                  this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM).subscribe(er => {
                    let strValor = '';

                    if (er !== null) {
                      if (er[0] !== undefined) {
                        strValor = er[0].ENCFACPRO_NUMERO;
                      }
                    }

                    if (strValor !== '') {
                      this.messageService.add({
                        key: 'encfacpro',
                        severity: 'warn',
                        summary: 'Información',
                        detail: 'Error,comprobante ya existe, por favor verifique.'
                      });
                      this.encfacproService.spin = false;//CDPJ
                      this.botonGuardar = false;//CDPJ
                    } else {
                      if (Number(this.encfacproService.lblTotalDscValorarray[this.indicador]) !== 0 && this.encfacproService.lblTotalDscValorarray[this.indicador] !== undefined) {
                        let strSentencia2 = 'SELECT B.CON_CODIGO FROM COM_MAEPROVEEDOR A, VEN_MAEGRUPO B, CON_MAECON C  WHERE A.GRU_CODIGO=B.GRU_CODIGO';
                        strSentencia2 = strSentencia2 + ' AND A.GRU_TIPO=B.GRU_TIPO ';
                        strSentencia2 = strSentencia2 + ' AND A.PRO_CODIGO=' + '\'' + this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO + '\'';
                        strSentencia2 = strSentencia2 + ' AND A.COM_CODIGO=' + '\'01\'';
                        strSentencia2 = strSentencia2 + ' AND B.CON_CODIGO=C.CON_CODIGO ';

                        this.encfacproService.getConCodigoMaeProv(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO).subscribe(data => {
                          let s = 0;
                          for (const rs of data) {
                            s++;
                          }
                          if (s === 0) {
                            this.messageService.add({
                              key: 'encfacpro',
                              severity: 'warn',
                              summary: 'Información',
                              detail: 'Error, La cuenta contable para el descuento de grupo  del proveedor no existe.'
                            });
                            this.encfacproService.spin = false;//CDPJ
                            this.botonGuardar = false;//CDPJ
                          } else {
                            this.masValidaciones();
                          }
                        });
                      } else {
                        this.masValidaciones();
                      }
                    }
                  });
              }
            }
          }
        }
      }
    }

    if (this.index === 1) {
      this.encfacproService.spin = true;//CDPJ
      this.botonGuardar = true;//CDPJ
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'La factura está anulada'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        return;
      }

      if (this.encfacproService.trnretencionarray[this.indicador].length === 0) {
        await this.GuardarRetencion();
        this.actualizarDatosTrans();
      }
      //CDPJ
      if (this.control === 0) {
        this.control = 1;
        this.verificarNumeroRetDif();
      }
      //CDPJ
      //this.verificarNumeroRetDif();

    }

    if (this.index === 2) {
      this.guardarCargos();
    }
  }

  // guardarCargos() {

  //   let intIndiceLista = 0;
  //   let item: ComTrncargos;
  //   for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trncargosarray[this.indicador].length; intIndiceLista++) {
  //     item = this.encfacproService.trncargosarray[this.indicador][intIndiceLista];
  //     if (item.CARGO_CODIGO === '') {
  //       this.encfacproService.trncargosarray[this.indicador].splice(intIndiceLista, 1);
  //     }
  //   }

  //   intIndiceLista = 0;

  //   // Elimina cargos

  //   let strSentencia = 'Delete from com_trncargos where encfacpro_numero=\'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';
  //   strSentencia = strSentencia + ' and com_codigo=\'01\'';

  //   this.encfacproService.eliminarTrnCargos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(() => {
  //     for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trncargosarray[this.indicador].length; intIndiceLista++) {
  //       item = this.encfacproService.trncargosarray[this.indicador][intIndiceLista];
  //       if (item.CARGO_CODIGO !== '') {
  //         this.encfacproService.insertarTRNCARGOS(item).subscribe(data2 => {
  //           for (const rs of data2) {
  //             item.TRNCARGOS_LINEA = rs[':B1'];
  //             this.auditoriagral.registrarAuditoria('COM_TRNCARGOS', item.CARGO_CODIGO + '/'
  //               + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, 'I',
  //               '', '01', '', '', '', '').subscribe();
  //           }
  //           this.distribuirGastosSobreCostos();
  //         });
  //       }
  //     }
  //   });
  // }
  //CDPJ
  async guardarCargos() {

    let intIndiceLista = 0;
    let item: ComTrncargos;
    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trncargosarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trncargosarray[this.indicador][intIndiceLista];
      if (item.CARGO_CODIGO === '') {
        this.encfacproService.trncargosarray[this.indicador].splice(intIndiceLista, 1);
      }
    }

    intIndiceLista = 0;

    // Elimina cargos

    let strSentencia = 'Delete from com_trncargos where encfacpro_numero=\'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';
    strSentencia = strSentencia + ' and com_codigo=\'01\'';

    //this.encfacproService.eliminarTrnCargos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(() => {
    await this.encfacproService.eliminarTrnCargos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).toPromise()
    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trncargosarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trncargosarray[this.indicador][intIndiceLista];
      if (item.CARGO_CODIGO !== '') {
        const data2 = await this.encfacproService.insertarTRNCARGOS(item).toPromise()//subscribe(data2 => {
        for (const rs of data2) {
          item.TRNCARGOS_LINEA = rs[':B1'];
          this.auditoriagral.registrarAuditoria('COM_TRNCARGOS', item.CARGO_CODIGO +
            +this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, 'I',
            '', '01', '', '', '', '').subscribe();
        }
        await this.distribuirGastosSobreCostos();
        //});
      }
    }
    // });
  }
  //CDPJ
  masValidaciones() {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION > this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAREC) {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Error, La fecha de emisión no puede ser mayor a la fecha de recepción.'
      });
      this.encfacproService.spin = false;//CDPJ
      this.botonGuardar = false;//CDPJ
    } else {
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION > this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHACADFAC) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Error, La fecha de emisión no puede ser mayor a la fecha de caducidad de la factura.'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
      } else {
        if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI !== '') {
          this.encfacproService.erCodSriDescripcion6(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI).subscribe(er => {
            if (er !== null) {
              if (er[0] !== undefined) {
                this.encfacproService.erEncfacproNumero2(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA,
                  this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO).subscribe(er2 => {
                    if (er2 !== null) {
                      this.messageService.add({
                        key: 'encfacpro',
                        severity: 'warn',
                        summary: 'Información',
                        detail: 'Factura ya existe con el mismo número de comprobante.'
                      });
                      this.encfacproService.spin = false;//CDPJ
                      this.botonGuardar = false;//CDPJ
                    } else {
                      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM !== '') {
                        this.encfacproService.erCodSriDescripcion7(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM).subscribe(er3 => {
                          if (er3 !== null) {
                            if (er3[0] !== undefined) {
                              const VALDATSRI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VALDATSRI').CFG_VALOR1;

                              if (VALDATSRI === 1) {
                                if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '') {
                                  this.messageService.add({
                                    key: 'encfacpro',
                                    severity: 'warn',
                                    summary: 'Información',
                                    detail: 'Error, Ingrese el Id del Crédito Tributario.'
                                  });
                                  this.encfacproService.spin = false;//CDPJ
                                  this.botonGuardar = false;//CDPJ
                                } else {
                                  if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM === '') {
                                    this.messageService.add({
                                      key: 'encfacpro',
                                      severity: 'warn',
                                      summary: 'Información',
                                      detail: 'Error, Ingrese el Tipo de Documento.'
                                    });
                                    this.encfacproService.spin = false;//CDPJ
                                    this.botonGuardar = false;//CDPJ
                                  } else {
                                    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION === '') {
                                      this.messageService.add({
                                        key: 'encfacpro',
                                        severity: 'warn',
                                        summary: 'Información',
                                        detail: 'Error, Ingrese el Número de Autorización de la factura.'
                                      });
                                      this.encfacproService.spin = false;//CDPJ
                                      this.botonGuardar = false;//CDPJ
                                    } else {
                                      this.autorizacionSri('guardar');
                                    }
                                  }
                                }
                              } else {
                                this.autorizacionSri('guardar');
                              }
                            }
                          } else {
                            this.messageService.add({
                              key: 'encfacpro',
                              severity: 'warn',
                              summary: 'Información',
                              detail: 'Error, Tipo de Comprobante incorrecto.'
                            });
                            this.encfacproService.spin = false;//CDPJ
                            this.botonGuardar = false;//CDPJ
                          }
                        });
                      } else {
                        this.messageService.add({
                          key: 'encfacpro',
                          severity: 'warn',
                          summary: 'Información',
                          detail: 'Error, Ingrese el Tipo de Comprobante.'
                        });
                        this.encfacproService.spin = false;//CDPJ
                        this.botonGuardar = false;//CDPJ
                      }
                    }
                  });
              }
            } else {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Información',
                detail: 'Error, Descripción del Crédito Tributario incorrecto.'
              });
              this.encfacproService.spin = false;//CDPJ
              this.botonGuardar = false;//CDPJ
            }
          });
        } else {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'warn',
            summary: 'Información',
            detail: 'Error, Ingrese el Crédito Tributario.'
          });
          this.encfacproService.spin = false;//CDPJ
          this.botonGuardar = false;//CDPJ
        }
      }
    }
  }

  autorizacionSri(accion) {
    if (this.strFacturacionElect === 'S') { // si esta activad la facturación electrónica entonces no valida el núnero de autorizacion
      console.log('elec', this.strFacturacionElect)
      this.habilitarbotonesfac = true;//CDPJ
      if (accion === 'guardar') {
        this.masValidacionesdos();
      }
      if (accion === 'tipdoc') {
        return;
      }
    } else {
      const intAUTSRI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'AUTSRI').CFG_VALOR1;
      if (intAUTSRI === 0) {  // Si necesita verifica el  numero de la factura de acuerdo a la serie, tipo de documento, y fecha de emision de la factura
        if (accion === 'guardar') {
          this.masValidacionesdos();
        }
        if (accion === 'tipdoc') {
          return;
        }
      } else {
        if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM === '99') { // si el codigo es 99
          if (accion === 'guardar') {
            this.masValidacionesdos();
          }
          if (accion === 'tipdoc') {
            return;
          }
        } else {
          let strCondicion = '';
          const strFechaEmision = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');

          strCondicion = 'PRO_CODIGO=\'' + this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO + '\'' + ' AND AUTSRI_TIPODOC=\'' +
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM + '\' AND ';
          strCondicion = strCondicion + ' AUTSRI_FECHAINI<=TO_DATE(\'' + strFechaEmision +
            '\',\'DD/MM/YYYY\') AND AUTSRI_FECHAFIN >= TO_DATE(\'' + strFechaEmision + '\',\'DD/MM/YYYY\') AND ';
          strCondicion = strCondicion + ' AUTSRI_SERIEESTAB||AUTSRI_SERIEPTOEMI = \'' +
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE + '\' AND';
          strCondicion = strCondicion + ' AUTSRI_SECUENCIALINI<=TO_NUMBER(\'' +
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA + '\')' + ' AND AUTSRI_SECUENCIALFIN >= TO_NUMBER(\'' +
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA + '\')';

          this.encfacproService.erAutSriAutorizaSri1(strFechaEmision, this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO,
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE,
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA).subscribe(data => {
              if (data !== null) {
                if (data[0] !== undefined) {
                  this.habilitarbotonesfac = true;//CDPJ
                  this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION = data[0].AUTSRI_AUTORIZASRI;
                  this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHACADFAC = data[0].AUTSRI_FECHAFIN;
                  if (accion === 'guardar') {
                    this.masValidacionesdos();
                  }
                  if (accion === 'tipdoc') {
                    return;
                  }
                }
              } else {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'No existe número de autorización del SRI para este documento, por favor verifique la información en el maestro del proveedor.'
                });
                this.encfacproService.spin = false;//CDPJ
                this.habilitarbotonesfac = false;//CDPJ
                this.botonGuardar = false;//CDPJ
              }
            });
        }
      }
    }
  }

  masValidacionesdos() {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION == null) {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION = '';
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION.length !== 49 &&
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION.length !== 37 &&
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION.length !== 10 &&
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION.length > 0 &&
      this.strFacturacionElect === 'S') {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Error, El número de autorización no tiene que ser diferente a 37 ó 49 dígitos.'
      });
      this.encfacproService.spin = false;//CDPJ
      this.botonGuardar = false;//CDPJ
    } else {
      if (this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO === null
        || this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO === undefined
        || this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO === '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Error, El campo Bodega no puede estar vacío.'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        return
      } else {
        this.validacionesDetalle();
      }

    }
  }

  async validacionesDetalle() {
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.encfacproService.detfacproarray[this.indicador].indexOf(this.detfacproSelected), 'BOD_CODIGO');

    let errores = 0;
    let rs;
    let rsajuste;//CDPJ
    let strDato;
    if (this.encfacproService.detfacproarray[this.indicador].length === 0) {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Error, No puede generar una factura de proveedores sin detalle.'
      });
      this.encfacproService.spin = false;//CDPJ
      this.botonGuardar = false;//CDPJ
    } else {
      for (const item of this.encfacproService.detfacproarray[this.indicador]) {
        let cont = 0;
        const intNOSALFSCC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOSALFSCC').CFG_VALOR1;
        const num = await this.encfacproService.validarcencodigo(item.CEN_CODIGO);
        if (num !== null && num !== undefined) {
          for (const rs of num) {
            if (rs.NUMERO !== null && rs.NUMERO !== undefined && rs.NUMERO !== '') {
              cont = rs.NUMERO
            }

          }
        }
        if (intNOSALFSCC === 1) {
          if (item.CEN_CODIGO === '' && item.DETFACPRO_TIPODET !== '*') {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Error',
              detail: 'Ingrese el centro de costos en los items de la factura.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }
        }

        //CDPJ
        if (item.CEN_CODIGO !== '' && item.DETFACPRO_TIPODET !== '*' && item.CEN_CODIGO !== null && item.CEN_CODIGO !== undefined) {
          if (cont === 0) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Error',
              detail: 'El centro de costos ' + item.CEN_CODIGO + ', no existe'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }

        }
        if (item.DETFACPRO_TIPODET !== '*') {
          if (item.DETFACPRO_TRIBICE === null || item.DETFACPRO_TRIBICE === undefined || item.DETFACPRO_TRIBICE === '') {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, Ingrese una N o una S en el campo del ICE'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }
        }
        //CDPJ
        if (item.DETFACPRO_TIPODET !== '*') {
          if (Number(item.DETFACPRO_CANTIDAD) === 0) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, La cantidad es incorrecta.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }
        }

        if (item.DETFACPRO_DESCRIPCION === undefined || item.DETFACPRO_DESCRIPCION === null || item.DETFACPRO_DESCRIPCION === '') {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'warn',
            summary: 'Información',
            detail: 'Error, Descripción del detalle de la factura no puede estar en blanco.'
          });
          this.encfacproService.spin = false;//CDPJ
          this.botonGuardar = false;//CDPJ
          errores++;
        }

        if (item.DETFACPRO_TIPODET === 'A') {
          rs = await this.encfacproService.erBodCodigo1(item.BOD_CODIGO);
          if (rs === null) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, Código de la bodega incorrecto.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }


          rs = await this.encfacproService.erArtCodigo1Prom(item.DETFACPRO_CODIGO);
          if (rs === null) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, Código del artículo es incorrecto.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }
          //CDPJ

          rsajuste = await this.encfacproService.erArtCodigo1Prom(item.DETFACPRO_AJUSTE);
          if (rsajuste === null && item.DETFACPRO_AJUSTE !== '' && item.DETFACPRO_AJUSTE !== null && item.DETFACPRO_AJUSTE !== undefined) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'IInformación',
              detail: 'No existe el código ' + item.DETFACPRO_AJUSTE + ', colocado en el campo artículo '
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }
          //CDPJ

          rs = await this.encfacproService.erUniCodigo1Prom(item.DETFACPRO_UNIDAD);
          if (rs === null) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, Código de la Unidad incorrecto.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }
          //CDPJ
          if (item.TARIIVA_CODIGO === null || item.TARIIVA_CODIGO === undefined || item.TARIIVA_CODIGO === '') {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Error en IVA',
              detail: 'Falta seleccionar el tipo de porcentaje del código ' + item.DETFACPRO_CODIGO
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }
          if (item.DETFACPRO_PORIVA === null || item.DETFACPRO_PORIVA === undefined || item.DETFACPRO_PORIVA === '') {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Error en IVA',
              detail: 'El campo IVA no puede guardese como vacío.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          } if (item.DETFACPRO_PORIVA !== null || item.DETFACPRO_PORIVA !== undefined || item.DETFACPRO_PORIVA !== '') {
            let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(item.DETFACPRO_PORIVA))
            if (data === undefined || data === null) {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Error en IVA',
                detail: 'El porcentaje ' + item.DETFACPRO_PORIVA + ' no forma parte de los porcentajes permitidos'
              });
              this.encfacproService.spin = false;//CDPJ
              this.botonGuardar = false;//CDPJ
              errores++;
            }
            if (Number(item.DETFACPRO_PORIVA) > 0 && item.TARIIVA_CODIGO !== null && item.TARIIVA_CODIGO !== undefined && item.TARIIVA_CODIGO !== '') {
              let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0 && Number(item.DETFACPRO_PORIVA) !== 0 && Number(element.TARIIVA_PORCENTAJE) === Number(item.DETFACPRO_PORIVA))
              if (data === undefined || data === null) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'El %IVA con el porcentaje ' + item.DETFACPRO_PORIVA + ' no es parte de los porcentajes que puede seleccionar cuando se tributa IVA.'
                });
                this.encfacproService.spin = false;//CDPJ
                this.botonGuardar = false;//CDPJ
                errores++;
              }
            }
            if (Number(item.DETFACPRO_PORIVA) === 0
              && item.TARIIVA_CODIGO !== null && item.TARIIVA_CODIGO !== undefined && item.TARIIVA_CODIGO !== '') {
              let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0 && Number(item.DETFACPRO_PORIVA) === 0 && Number(element.TARIIVA_PORCENTAJE) === Number(item.DETFACPRO_PORIVA))
              console.log(data)
              console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO))
              if (data === undefined || data === null) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'El %IVA con el porcentaje ' + item.DETFACPRO_PORIVA + ' no es parte de los porcentajes que puede seleccionar cuando no se tributan IVA.'
                });
                this.encfacproService.spin = false;//CDPJ
                this.botonGuardar = false;//CDPJ
                errores++;
              }
            }
          }
          //CDPJ
        } else if (item.DETFACPRO_TIPODET === 'C') {
          rs = await this.encfacproService.erConCodigo2Prom(item.DETFACPRO_CODIGO);
          if (rs === null) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, Código de la cuenta contable es incorrecta.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }


          strDato = await this.encfacproService.erConTipGas1Prom(item.DETFACPRO_CODIGO);
          if (strDato !== null) {
            if (strDato[0] !== undefined) {
              if (item.TIPGAS_CODIGO === ''
                && strDato[0].CON_TIPGAS === 'Si'
                && item.DETFACPRO_TIPODET !== '*') {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'warn',
                  summary: 'Error',
                  detail: 'Ingrese el tipo de gasto en los items de la factura'
                });
                this.encfacproService.spin = false;//CDPJ
                this.botonGuardar = false;//CDPJ
                errores++;
              }
            }
          }
          //CDPJ
          if (item.TARIIVA_CODIGO === null || item.TARIIVA_CODIGO === undefined || item.TARIIVA_CODIGO === '') {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Error en IVA',
              detail: 'Falta seleccionar el tipo de porcentaje del código ' + item.DETFACPRO_CODIGO
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }
          if (item.DETFACPRO_PORIVA === null || item.DETFACPRO_PORIVA === undefined || item.DETFACPRO_PORIVA === '') {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Error en IVA',
              detail: 'El campo IVA no puede guardese como vacío.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          } if (item.DETFACPRO_PORIVA !== null || item.DETFACPRO_PORIVA !== undefined || item.DETFACPRO_PORIVA !== '') {
            let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(item.DETFACPRO_PORIVA))
            if (data === undefined || data === null) {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Error en IVA',
                detail: 'El porcentaje ' + item.DETFACPRO_PORIVA + ' no forma parte de los porcentajes permitidos'
              });
              this.encfacproService.spin = false;//CDPJ
              this.botonGuardar = false;//CDPJ
              errores++;
            }
            if (Number(item.DETFACPRO_PORIVA) > 0 && item.TARIIVA_CODIGO !== null && item.TARIIVA_CODIGO !== undefined && item.TARIIVA_CODIGO !== '') {
              let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0 && Number(item.DETFACPRO_PORIVA) !== 0 && Number(element.TARIIVA_PORCENTAJE) === Number(item.DETFACPRO_PORIVA))
              if (data === undefined || data === null) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'El %IVA con el porcentaje ' + item.DETFACPRO_PORIVA + ' no es parte de los porcentajes que puede seleccionar cuando se tributa IVA.'
                });
                this.encfacproService.spin = false;//CDPJ
                this.botonGuardar = false;//CDPJ
                errores++;
              }
            }
            if (Number(item.DETFACPRO_PORIVA) === 0
              && item.TARIIVA_CODIGO !== null && item.TARIIVA_CODIGO !== undefined && item.TARIIVA_CODIGO !== '') {
              let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0 && Number(item.DETFACPRO_PORIVA) === 0 && Number(element.TARIIVA_PORCENTAJE) === Number(item.DETFACPRO_PORIVA))
              console.log(data)
              console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO))
              if (data === undefined || data === null) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'El %IVA con el porcentaje ' + item.DETFACPRO_PORIVA + ' no es parte de los porcentajes que puede seleccionar cuando no se tributan IVA.'
                });
                this.encfacproService.spin = false;//CDPJ
                this.botonGuardar = false;//CDPJ
                errores++;
              }
            }
          }
          //CDPJ

        } else if (item.DETFACPRO_TIPODET === 'S') {
          rs = await this.encfacproService.erSerComCodigo1Prom(item.DETFACPRO_CODIGO);
          if (rs === null) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, Código del servicio incorrecto.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }


          strDato = await this.encfacproService.erConTipGas2Prom(item.DETFACPRO_CODIGO);
          if (strDato !== null) {
            if (strDato[0] !== undefined) {
              if (item.TIPGAS_CODIGO === ''
                && strDato[0].CON_TIPGAS === 'Si'
                && item.DETFACPRO_TIPODET !== '*') {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'warn',
                  summary: 'Error',
                  detail: 'Ingrese el tipo de gasto en los items de la factura'
                });
                this.encfacproService.spin = false;//CDPJ
                this.botonGuardar = false;//CDPJ
                errores++;
              }
            }
          }
          //CDPJ
          if (item.TARIIVA_CODIGO === null || item.TARIIVA_CODIGO === undefined || item.TARIIVA_CODIGO === '') {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Error en IVA',
              detail: 'Falta seleccionar el tipo de porcentaje del código ' + item.DETFACPRO_CODIGO
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          }
          if (item.DETFACPRO_PORIVA === null || item.DETFACPRO_PORIVA === undefined || item.DETFACPRO_PORIVA === '') {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Error en IVA',
              detail: 'El campo IVA no puede guardese como vacío.'
            });
            this.encfacproService.spin = false;//CDPJ
            this.botonGuardar = false;//CDPJ
            errores++;
          } if (item.DETFACPRO_PORIVA !== null || item.DETFACPRO_PORIVA !== undefined || item.DETFACPRO_PORIVA !== '') {
            let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(item.DETFACPRO_PORIVA))
            if (data === undefined || data === null) {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Error en IVA',
                detail: 'El porcentaje ' + item.DETFACPRO_PORIVA + ' no forma parte de los porcentajes permitidos'
              });
              this.encfacproService.spin = false;//CDPJ
              this.botonGuardar = false;//CDPJ
              errores++;
            }
            if (Number(item.DETFACPRO_PORIVA) > 0 && item.TARIIVA_CODIGO !== null && item.TARIIVA_CODIGO !== undefined && item.TARIIVA_CODIGO !== '') {
              let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0 && Number(item.DETFACPRO_PORIVA) !== 0 && Number(element.TARIIVA_PORCENTAJE) === Number(item.DETFACPRO_PORIVA))
              if (data === undefined || data === null) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'El %IVA con el porcentaje ' + item.DETFACPRO_PORIVA + ' no es parte de los porcentajes que puede seleccionar cuando se tributa IVA.'
                });
                this.encfacproService.spin = false;//CDPJ
                this.botonGuardar = false;//CDPJ
                errores++;
              }
            }
            if (Number(item.DETFACPRO_PORIVA) === 0
              && item.TARIIVA_CODIGO !== null && item.TARIIVA_CODIGO !== undefined && item.TARIIVA_CODIGO !== '') {
              let data = this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0 && Number(item.DETFACPRO_PORIVA) === 0 && Number(element.TARIIVA_PORCENTAJE) === Number(item.DETFACPRO_PORIVA))
              console.log(data)
              console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO))
              if (data === undefined || data === null) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'El %IVA con el porcentaje ' + item.DETFACPRO_PORIVA + ' no es parte de los porcentajes que puede seleccionar cuando no se tributan IVA.'
                });
                this.encfacproService.spin = false;//CDPJ
                this.botonGuardar = false;//CDPJ
                errores++;
              }
            }
          }
          //CDPJ
        }
      }

      if (errores === 0) {
        this.guardarFacturaPromise();
      } else {
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
      }
    }
  }

  distribuirCargos() {

  }

  async actualizarEstadoOrdenCompra() {
    for (const item of this.encfacproService.detfacproarray[this.indicador]) {
      if (item.ENCORDCOM_NUMERO !== null) {
        if (item.ENCORDCOM_NUMERO.length !== 0) {
          this.encfacproService.actualizarOC(item.ENCORDCOM_NUMERO).subscribe();
        }
      }
    }

  }

  async guardarCuentaXPagar(accion) {
    const item: CxpTrncobro = {};

    item.TRNCOBRO_NRODOC = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
    item.COM_CODIGO = '01';
    item.TRNCOBRO_FECHATRN = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION;
    item.TRNCOBRO_FECHAVENCE = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAVENCIMIENTO;
    item.TRNCOBRO_FECHARECEPCION = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAREC;
    item.TRNCOBRO_IMPORTE = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL;
    if (accion === 'cargos') {
      item.TRNCOBRO_FECHATRN = this.datePipe.transform(item.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
      item.TRNCOBRO_FECHAVENCE = this.datePipe.transform(item.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
      item.TRNCOBRO_FECHARECEPCION = this.datePipe.transform(item.TRNCOBRO_FECHARECEPCION, 'dd/MM/yyyy');
      item.TRNCOBRO_IMPORTE = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALBD).toFixed(2)
    }
    item.TRNCOBRO_TIPODOC = 'FC';
    item.TRNCOBRO_NROCOMPROBANTE = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA;
    item.TRNCOBRO_CONCEPTO = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_COMENTARIO;

    item.PRO_CODIGO = this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO;
    item.APLORG_CODIGO = 'COM';

    item.TRNCOBRO_REFERENCIAEXTERNA = '';
    item.CON_CODIGO = this.strCodCuentaProv;
    //this.encfacproService.insertarCxpTrncobro(item).subscribe();
    await this.encfacproService.insertarCxpTrncobroProm(item).catch(e => {
      this.spin = false;//CDPJ
    });
  }

  actualizaPreciosABC() {

  }

  async guardarFacturaPromise() {
    let item: ComEncfacpro = {};
    item = this.encfacproService.encfacproarray[this.indicador];
    item.COM_CODIGO = '01';
    console.log(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION);
    item.ENCFACPRO_FECHAEMISION = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
    item.ENCFACPRO_FECHAVENCIMIENTO = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAVENCIMIENTO, 'dd/MM/yyyy');
    item.ENCFACPRO_FECHAREC = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAREC, 'dd/MM/yyyy');
    item.ENCFACPRO_FECHACADFAC = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHACADFAC, 'dd/MM/yyyy');
    item.ENCFACPRO_FECHAAUTRET_FE = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAAUTRET_FE, 'dd/MM/yyyy');
    item.ENCFACPRO_FECHAAUT_FELIQ = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAAUT_FELIQ, 'dd/MM/yyyy');

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PENDIENTE') {
      item.ENCFACPRO_ESTADO = 'P';
    } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA') {
      item.ENCFACPRO_ESTADO = 'G';
    } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      item.ENCFACPRO_ESTADO = 'A';
    }

    item.ENCFACPRO_PORCEIVA = Number(this.encfacproService.porceiva);//CDPJ
    item.ENCFACPRO_OTROS = Number(this.encfacproService.encfacproarray[this.indicador].TOTALCARGOS);
    item.ENCFACPRO_DISTCARGOS = 'N';
    if (item.ENCFACPRO_NUMDIASPLAZO === '') {
      item.ENCFACPRO_NUMDIASPLAZO = 0;
    }
    item.ENCFACPRO_GASTO = 'N';
    item.ENCFACPRO_NOOBJETOIVA = 'N';

    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALBD = this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALBD);
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO = this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO);
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA = this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA);
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEICE = this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEICE);
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO = this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO);
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA = this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA);
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORICE = this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORICE);
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASENOOBJIVA = this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASENOOBJIVA);
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES = this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES);
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES = this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES);

    item.ENCFACPRO_TOTAL = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALBD).toFixed(this.confIniciales.getNumDecSist());
    item.ENCFACPRO_TOTAL = Number(item.ENCFACPRO_TOTAL);
    item.ENCFACPRO_TOTALNETO = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALNETO).toFixed(this.confIniciales.getNumDecSist());
    item.ENCFACPRO_TOTALNETO = Number(item.ENCFACPRO_TOTALNETO);
    item.ENCFACPRO_BASEIVA = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
    item.ENCFACPRO_BASEIVA = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA);
    item.ENCFACPRO_BASEICE = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEICE);
    item.ENCFACPRO_BASECERO = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO);
    item.ENCFACPRO_VALORIVA = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
    item.ENCFACPRO_VALORIVA = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA);
    item.ENCFACPRO_VALORICE = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORICE);
    item.ENCFACPRO_BASENOOBJIVA = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASENOOBJIVA);
    item.ENCFACPRO_VALORDES = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES);
    item.ENCFACPRO_PORCEDES = Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES);
    item.ENCFACPRO_FLAG = item.ENCFACPRO_FLAG === null || item.ENCFACPRO_FLAG === undefined ? '' : item.ENCFACPRO_FLAG;//CDPJ
    item.ENCFACPRO_IVA = this.selecttipoiva.codigo;
    const encs = await this.encfacproService.insertarEncabezadoFactPromise(item)
    for (const enc of encs) {
      if (enc.V_ENCFACPRO_NUMERO !== null) {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO = enc.V_ENCFACPRO_NUMERO;
      }
      this.disabledEstadisticas = false;
      for (const det of this.encfacproService.detfacproarray[this.indicador]) {
        const itemdet: ComDetfacpro = det;
        //GSRF
        if (itemdet.DETFACPRO_CODIGO_IMP !== ''
          && itemdet.DETFACPRO_CODIGO_IMP !== null
          && itemdet.DETFACPRO_CODIGO_IMP !== undefined) {
          console.log('ingresa actualiza')
          await this.encfacproService.updatearthomo(itemdet, this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO);
        }
        //GSRF
        itemdet.ENCFACPRO_NUMERO = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
        itemdet.DETFACPRO_CANTIDAD = Number(det.DETFACPRO_CANTIDAD);
        itemdet.DETFACPRO_COSTO = Number(det.DETFACPRO_COSTO);
        itemdet.DETFACPRO_TOTAL = Number(det.DETFACPRO_TOTAL);
        itemdet.DETFACPRO_TOTALINCP = Number(det.DETFACPRO_TOTAL);
        itemdet.DETFACPRO_PORDES = Number(det.DETFACPRO_PORDES);
        itemdet.DETFACPRO_VALDES = Number(det.DETFACPRO_VALDES);
        itemdet.DETFACPRO_PORIVA = Number(det.DETFACPRO_PORIVA);
        itemdet.DETFACPRO_PORDES2 = Number(det.DETFACPRO_PORDES2);
        itemdet.DETFACPRO_PORDES3 = Number(det.DETFACPRO_PORDES3);
        itemdet.DETFACPRO_AJUSTE = det.DETFACPRO_AJUSTE === null || det.DETFACPRO_AJUSTE === undefined ? '' : det.DETFACPRO_AJUSTE;//GSRF
        //CDPJ
        if (itemdet.DETFACPRO_TIPODET === '*') {
          itemdet.DETFACPRO_PORIVA = 0;
          itemdet.TARIIVA_CODIGO = '-1';
        }
        //CDPJ
        await this.encfacproService.insertarDetalleFacturaPromise(itemdet).catch(e => {
          this.encfacproService.spin = false;//CDPJ
          this.botonGuardar = false;//CDPJ
        });
        //GSRF
        if (itemdet.DETFACPRO_LINEA === 0) {
          //CDPJ
          if (itemdet.ENCNOTREC_NUMERO !== null && itemdet.ENCNOTREC_NUMERO !== undefined && itemdet.ENCNOTREC_NUMERO !== '') {
            this.auditoriagral.registrarAuditoria('COM_DETFACPRO',
              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' + itemdet.ENCNOTREC_NUMERO + '/' +
              itemdet.DETFACPRO_CODIGO + '/' + itemdet.DETFACPRO_TIPODET + '/' + itemdet.DETFACPRO_CANTIDAD + '/' + itemdet.DETFACPRO_COSTO + '/' + itemdet.DETFACPRO_TOTAL, 'I',
              '', '01', '', '', '', '').subscribe();
          } else if (itemdet.ENCORDCOM_NUMERO !== null && itemdet.ENCORDCOM_NUMERO !== undefined && itemdet.ENCORDCOM_NUMERO !== '') {
            this.auditoriagral.registrarAuditoria('COM_DETFACPRO',
              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' + itemdet.ENCORDCOM_NUMERO + '/' +
              itemdet.DETFACPRO_CODIGO + '/' + itemdet.DETFACPRO_TIPODET + '/' + itemdet.DETFACPRO_CANTIDAD + '/' + itemdet.DETFACPRO_COSTO + '/' + itemdet.DETFACPRO_TOTAL, 'I',
              '', '01', '', '', '', '').subscribe();
          } else {//CDPJ
            this.auditoriagral.registrarAuditoria('COM_DETFACPRO',
              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' +
              itemdet.DETFACPRO_CODIGO + '/' + itemdet.DETFACPRO_TIPODET + '/' + itemdet.DETFACPRO_CANTIDAD + '/' + itemdet.DETFACPRO_COSTO + '/' + itemdet.DETFACPRO_TOTAL, 'I',
              '', '01', '', '', '', '').subscribe();
          }
          this.auditoriagral.registrarAuditoria('COM_DETFACPRO', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' + itemdet.DETFACPRO_CODIGO + '/'
            + 'PorIva:' + itemdet.DETFACPRO_PORIVA + '/' + 'CodTarIva:' + itemdet.TARIIVA_CODIGO, 'I',
            '', '01', '', '', '', '').subscribe(() => {
            });
        } else {
          //CDPJ
          if (itemdet.ENCNOTREC_NUMERO !== null && itemdet.ENCNOTREC_NUMERO !== undefined && itemdet.ENCNOTREC_NUMERO !== '') {
            this.auditoriagral.registrarAuditoria('COM_DETFACPRO',
              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' + itemdet.ENCNOTREC_NUMERO + '/' +
              itemdet.DETFACPRO_CODIGO + '/' + itemdet.DETFACPRO_TIPODET + '/' + itemdet.DETFACPRO_CANTIDAD + '/' + itemdet.DETFACPRO_COSTO + '/' + itemdet.DETFACPRO_TOTAL, 'A',
              '', '01', '', '', '', '').subscribe();
          } else if (itemdet.ENCORDCOM_NUMERO !== null && itemdet.ENCORDCOM_NUMERO !== undefined && itemdet.ENCORDCOM_NUMERO !== '') {
            this.auditoriagral.registrarAuditoria('COM_DETFACPRO',
              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' + itemdet.ENCORDCOM_NUMERO + '/' +
              itemdet.DETFACPRO_CODIGO + '/' + itemdet.DETFACPRO_TIPODET + '/' + itemdet.DETFACPRO_CANTIDAD + '/' + itemdet.DETFACPRO_COSTO + '/' + itemdet.DETFACPRO_TOTAL, 'A',
              '', '01', '', '', '', '').subscribe();
          } else {//CDPJ
            this.auditoriagral.registrarAuditoria('COM_DETFACPRO',
              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' +
              itemdet.DETFACPRO_CODIGO + '/' + itemdet.DETFACPRO_TIPODET + '/' + itemdet.DETFACPRO_CANTIDAD + '/' + itemdet.DETFACPRO_COSTO + '/' + itemdet.DETFACPRO_TOTAL, 'A',
              '', '01', '', '', '', '').subscribe();
          }
          this.auditoriagral.registrarAuditoria('COM_DETFACPRO', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' + itemdet.DETFACPRO_CODIGO + '/'
            + 'PorIva:' + itemdet.DETFACPRO_PORIVA + '/' + 'CodTarIva:' + itemdet.TARIIVA_CODIGO, 'A',
            '', '01', '', '', '', '').subscribe(() => {
            });
        }
        //GSRF
      }
      //GSRF
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO_IMP !== ''
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO_IMP !== null
        && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO_IMP !== undefined) {
        console.log('ingresa elimina')
        await this.encfacproService.deleteencimp(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO_IMP);
        await this.encfacproService.deletedetimp(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO_IMP);
      }
      //GSRF
      if (this.bolSerie === false) {
        this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, 'I',
          '', '01', '', '', '', '').subscribe();
      } else {
        this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, 'A',
          '', '01', '', '', '', '').subscribe();
      }
      this.distribuirCargos();
      console.log('ActualizaDatos');
      await this.encfacproService.ActualizaDatosProm(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
      await this.actualizarEstadoOrdenCompra();
      await this.guardarCuentaXPagar('guardarFac');
      this.actualizaPreciosABC();
      let strPagoLocalExt = '';

      const data = await this.encfacproService.erEncfacproPagolocalext1Prom(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).catch(e => {
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
      });
      if (data !== null) {
        if (data[0] !== undefined) {
          strPagoLocalExt = data[0].ENCFACPRO_PAGOLOCALEXT;
          if (strPagoLocalExt === '' || strPagoLocalExt === null) {
            let strSentencia = 'UPDATE COM_ENCFACPRO SET ENCFACPRO_PAGOLOCALEXT=\'01\' WHERE ENCFACPRO_NUMERO=\'' +
              data[0].ENCFACPRO_NUMERO + '\'';
            strSentencia = strSentencia + ' AND COM_CODIGO=' + '\'01\'';
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO = data[0].ENCFACPRO_NUMERO;
            await this.encfacproService.updatePagoLocalExtProm(data[0].ENCFACPRO_NUMERO);
          }
          this.datosanexos.cargarDatos();
          this.displayDialogDatosAnexos = true;
        }
      }

      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === 'Crédito' &&
        this.encfacproService.encfacproarray[this.indicador].ASI_NRO !== '') {
        this.generarAsiContable();
      }

      const intPANACTEMA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PANACTEMA').CFG_VALOR1;
      if (intPANACTEMA === 1) {
        if (this.datosfe.COM_FEACTIVADA === '1') { // Si esta en ambiente producción
          this.leerDatos('P', this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO);
          this.displayDialogActualizacionDE = true;
        }
      }
      this.encfacproService.spin = false;//CDPJ
      this.botonGuardar = true;//CDPJ
      this.cancelar();

    }
  }

  async cancelardetalle() {
    if (this.index === 0) {
      if (this.detfacproSelected.DETFACPRO_CODIGO === '' && this.detfacproSelected.DETFACPRO_TIPODET !== '*') {
        this.encfacproService.detfacproarray[this.indicador].splice(this.encfacproService.detfacproarray[this.indicador].findIndex(
          detfacpro => detfacpro.CLAVE === this.detfacproSelected.CLAVE),
          1);
        if (this.encfacproService.detfacproarray[this.indicador].length > 0) {
          this.detfacproSelected = this.encfacproService.detfacproarray[this.indicador][this.encfacproService.detfacproarray[this.indicador].length - 1];
        }
        this.encfacproService.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);
      }
    }

    if (this.index === 1) {
      this.listarDatosRetencion();
    }

    if (this.index === 2) {
      const data = await this.encfacproService.consultarCargos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
      this.encfacproService.trncargosarray[this.indicador] = data;
      this.aggrid.refreshaggrid(this.encfacproService.trncargosarray[this.indicador], this.defaultColDefCargos.objeto);
    }
  }

  eliminarLineaDetalle() {
    if (this.index === 0) {
      if (this.detfacproSelected !== undefined && this.encfacproService.detfacproarray[this.indicador].length > 0) {
        this.confirmationService.confirm({
          message: 'Está seguro de eliminar el registro?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarencfacpro',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            if (this.detfacproSelected.DETFACPRO_LINEA !== 0) {
              this.encfacproService.eliminarDetalleFact(this.detfacproSelected).subscribe(() => {
                this.encfacproService.detfacproarray[this.indicador].splice(this.encfacproService.detfacproarray[this.indicador].findIndex(
                  detfac => detfac.DETFACPRO_LINEA === this.detfacproSelected.DETFACPRO_LINEA),
                  1);
                //GSRF 
                if (this.detfacproSelected.ENCNOTREC_NUMERO !== null && this.detfacproSelected.ENCNOTREC_NUMERO !== undefined && this.detfacproSelected.ENCNOTREC_NUMERO !== '') {
                  this.auditoriagral.registrarAuditoria('COM_DETFACPRO',
                    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' +
                    this.detfacproSelected.DETFACPRO_CODIGO + '/' + this.detfacproSelected.DETFACPRO_TIPODET + '/' + this.detfacproSelected.ENCNOTREC_NUMERO,
                    'E', '', '01', '', '', '', '').subscribe();
                } else if (this.detfacproSelected.ENCORDCOM_NUMERO !== null && this.detfacproSelected.ENCORDCOM_NUMERO !== undefined && this.detfacproSelected.ENCORDCOM_NUMERO !== '') {
                  this.auditoriagral.registrarAuditoria('COM_DETFACPRO',
                    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' +
                    this.detfacproSelected.DETFACPRO_CODIGO + '/' + this.detfacproSelected.DETFACPRO_TIPODET + '/' + this.detfacproSelected.ENCORDCOM_NUMERO,
                    'E', '', '01', '', '', '', '').subscribe();
                } else {//CDPJ
                  this.auditoriagral.registrarAuditoria('COM_DETFACPRO',
                    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' +
                    this.detfacproSelected.DETFACPRO_CODIGO + '/' + this.detfacproSelected.DETFACPRO_TIPODET,
                    'E', '', '01', '', '', '', '').subscribe();
                }
                //GSRF
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'El detalle se elimino correctamente'
                });
                if (this.encfacproService.detfacproarray[this.indicador].length > 0) {
                  this.detfacproSelected = this.encfacproService.detfacproarray[this.indicador][this.encfacproService.detfacproarray[this.indicador].length - 1];
                }
                this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);
                this.encfacproService.calcularTotales();

                if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
                  this.guardar();
                }
              });
            } else {
              //GSRF
              // this.encfacproService.detfacproarray[this.indicador].splice(this.encfacproService.detfacproarray[this.indicador].findIndex(
              //   detfac => detfac.CLAVE === this.detfacproSelected.CLAVE),
              //   1);
              this.encfacproService.detfacproarray[this.indicador].splice(this.encfacproService.numcellfacpro,
                1);
              //GSRF
              this.messageService.add({
                key: 'encfacpro',
                severity: 'success',
                summary: 'Información',
                detail: 'El detalle se elimino correctamente'
              });
              if (this.encfacproService.detfacproarray[this.indicador].length > 0) {
                this.detfacproSelected = this.encfacproService.detfacproarray[this.indicador][this.encfacproService.detfacproarray[this.indicador].length - 1];
              }
              this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);
              this.encfacproService.calcularTotales();
            }
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      }
    }

    if (this.index === 1) {

      if (this.trnretencionSelected !== undefined && this.encfacproService.trnretencionarray[this.indicador].length > 0) {
        this.confirmationService.confirm({
          message: 'Está seguro de eliminar el registro?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarencfacpro',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            this.encfacproService.trnretencionarray[this.indicador].splice(this.encfacproService.trnretencionarray[this.indicador].findIndex(
              trnret => trnret.CLAVE === this.trnretencionSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encfacpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se elimino correctamente'
            });
            if (this.encfacproService.trnretencionarray[this.indicador].length > 0) {
              this.bolcmbSerie = true;
              this.trnretencionSelected = this.encfacproService.trnretencionarray[this.indicador][this.encfacproService.trnretencionarray[this.indicador].length - 1];
            } else {
              this.bolcmbSerie = false;
            }
            this.aggrid.refreshaggrid(this.encfacproService.trnretencionarray[this.indicador], this.defaultColDefRet.objeto);
            this.encfacproService.calcularTotalesRetencion();
            this.botonesmodificar();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      }
    }

    if (this.index === 2) {
      if (this.cargoSelected !== undefined && this.encfacproService.trncargosarray[this.indicador].length > 0) {
        this.confirmationService.confirm({
          message: 'Está seguro de eliminar el registro?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarencfacpro',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            this.encfacproService.trncargosarray[this.indicador].splice(this.encfacproService.trncargosarray[this.indicador].findIndex(
              carg => carg.CLAVE === this.cargoSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encfac',
              severity: 'success',
              summary: 'Información',
              detail: 'El cargo se elimino correctamente'
            });
            if (this.encfacproService.trncargosarray[this.indicador].length > 0) {
              this.cargoSelected = this.encfacproService.trncargosarray[this.indicador][this.encfacproService.trncargosarray[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encfacproService.trncargosarray[this.indicador], this.defaultColDefCargos.objeto);
            this.encfacproService.sumarTotalCargos();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      }
    }
  }

  multiunidad() {
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].getFocusedCell() === null) {
      return;
    }
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].getFocusedCell().column === null) {
      return;
    }
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      this.defaultColDef.objeto].getFocusedCell().column.colId === 'DETFACPRO_UNIDAD') {
      console.log(this.detfacproSelected.ART_MULTIUNIDAD, this.detfacproSelected.UNIDAD);
      if (this.detfacproSelected.ART_MULTIUNIDAD === 'S') {
        const opciones = [];
        opciones.push(this.detfacproSelected.UNIDAD);
        this.utilitariosService.getUnidadesEqui(this.detfacproSelected.UNIDAD).subscribe((res) => {
          if (res !== null) {
            res.map((unidades) => {
              opciones.push(unidades.UNI_CODIGODESTINO);
            });
          }
          this.columnDefsDetfac[5].cellEditor = 'agSelectCellEditor';
          this.columnDefsDetfac[5].editable = (params) => {
            const boolEdit = this.isEditFPUND(params.data);
            return boolEdit;
          };
          this.columnDefsDetfac[5].cellEditorParams = {
            values: opciones
          };
          this.aggrid.refreshColumnDefs();
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.encfacproService.detfacproarray[this.indicador].indexOf(this.detfacproSelected), 'DETFACPRO_UNIDAD');
        });
      }
      // else {
      //   this.columnDefsDetfac[5].cellEditor = 'cellPrueba';
      //   this.columnDefsDetfac[5].editable = false;
      //   this.aggrid.refreshColumnDefs();
      //   this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.encfacproService.detfacproarray[this.indicador].indexOf(this.detfacproSelected), 'DETFACPRO_UNIDAD');
      // }
    }
  }

  datosAnexos() {
    this.datosanexos.cargarDatos();
    this.strtotalfac = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL))
    this.displayDialogDatosAnexos = true;

    this.closeSidebar();
  }

  async enviarSenal(accion) {
    if (accion === 'Firmar') {
      if (this.datosfe.COM_FEACTIVADA === '0') {
        return;
      }

      this.insertarEleTrnDocumento(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
    }

    if (accion === 'EnviarCorreo') {
      if (this.CENREPDOCE === 1) {
        if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== 'AT') {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'info',
            summary: 'Información',
            detail: 'La retención debe estar en estado AT'
          });
          return
        }
        await this.despliegaopcionride();
        return
      }
      this.actualizarEstadoParaReenviarCorreo(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
    }
  }
  //CDPJ
  async despliegaopcionride() {
    this.strNombreForma = this.init.tabs[this.init.tabindex].component;
    this.auxreporte++;
    this.displayDialogfrmcorreos = true
  }
  cerrarfrmcorreos() {
    this.displayDialogfrmcorreos = false;
    this.cancelar();
  }
  //CDPJ

  verificarNumeroRetDif() {

    let intIndiceListaAux = 0;
    let intCount = 0;
    let intCountAut = 0;

    let item;
    let itemAux;
    let strAutorizacion = '';
    let strNumero = '';

    for (let intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];
      strNumero = item.TRNRETENCION_NRO;
      strAutorizacion = item.TRNRETENCION_AUTORIZACION;

      for (intIndiceListaAux = 0; intIndiceListaAux < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceListaAux++) {
        itemAux = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];

        if (strNumero !== itemAux.TRNRETENCION_NRO) {
          intCount = intCount + 1;
        }

        if (strAutorizacion !== itemAux.TRNRETENCION_AUTORIZACION) {
          intCountAut = intCountAut + 1;
        }
      }
    }

    if (intCount >= 1 || intCountAut >= 1) {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Error',
        detail: 'Número o autorización de la retención está incorrecto, por favor verifique.'
      });
      this.encfacproService.spin = false;//CDPJ
      this.botonGuardar = false;//CDPJ
      this.control = 0;//CDPJ
    } else {
      this.verificarFechaDif();
    }

  }


  verificarFechaDif() {

    let intIndiceListaAux = 0;
    let intCount = 0;

    let item;
    let itemAux;
    let strFecha = '';

    for (let intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];
      strFecha = item.TRNRETENCION_FECHAEMISION;

      for (intIndiceListaAux = 0; intIndiceListaAux < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceListaAux++) {
        itemAux = this.encfacproService.trnretencionarray[this.indicador][intIndiceListaAux];

        if (strFecha !== itemAux.TRNRETENCION_FECHAEMISION) {
          intCount = intCount + 1;
        }
      }
    }

    if (intCount >= 1) {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'error',
        summary: 'Error',
        detail: 'La fecha de la retencion debe ser la misma en las diferentes retenciones, por favor verifique.'
      });
      this.encfacproService.spin = false;//CDPJ
      this.botonGuardar = false;//CDPJ
      this.control = 0;//CDPJ
    } else {
      this.verificarLongNumeroRet();
    }

  }

  verificarLongNumeroRet() {
    let intIndiceLista = 0;

    let item;

    let strNumRet = '';


    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];

      strNumRet = item.TRNRETENCION_NRO;

      if (strNumRet.length !== 9) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'error',
          summary: 'Error',
          detail: 'Longitud del número de la retención está incorrecto, por favor verifique.'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        this.control = 0;//CDPJ
        return;
      }
    }


    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];

      if (item.RETENCION_CODIGO === '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'error',
          summary: 'Error',
          detail: 'Código de Retención incorrecto.'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        this.control = 0;//CDPJ
        return;
      }
      if (item.TRNRETENCION_NRO === '') {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'error',
          summary: 'Error',
          detail: 'Número de retención incorrecto.'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        this.control = 0;//CDPJ
        return;
      }

      let sw = 0;

      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IDCRETRI === '10' && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM === '19') {
        sw = 0;
      } else if (Number(item.TRNRETENCION_BASE) === 0) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'error',
          summary: 'Error',
          detail: 'Ingrese la base de la retención.'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        this.control = 0;//CDPJ
        return;
      }


      if (item.RETENCION_CODIGO !== this.strCODRET1
        && item.RETENCION_CODIGO !== this.strCODRET2) {
        if (item.TRNRETENCION_AUTORIZACION.length < 1) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'error',
            summary: 'Error',
            detail: 'El número de autorización es incorrecto.'
          });
          this.encfacproService.spin = false;//CDPJ
          this.botonGuardar = false;//CDPJ
          this.control = 0;//CDPJ
          return;
        }

        if (item.TRNRETENCION_SERIE.length !== 6) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'error',
            summary: 'Error',
            detail: 'La serie debe tener 6 dígitos.'
          });
          this.encfacproService.spin = false;//CDPJ
          this.botonGuardar = false;//CDPJ
          this.control = 0;//CDPJ
          return;
        }
      }
    }

    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      if (this.encfacproService.trnretencionarray[this.indicador].length > intIndiceLista + 1) {
        if (this.encfacproService.trnretencionarray[this.indicador][intIndiceLista].TRNRETENCION_NRO !==
          this.encfacproService.trnretencionarray[this.indicador][intIndiceLista + 1].TRNRETENCION_NRO) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'error',
            summary: 'Error',
            detail: 'El número de retención es distinto.'
          });
          this.encfacproService.spin = false;//CDPJ
          this.botonGuardar = false;//CDPJ
          this.control = 0;//CDPJ
          return;
        }

        if (this.encfacproService.trnretencionarray[this.indicador][intIndiceLista].TRNRETENCION_AUTORIZACION !==
          this.encfacproService.trnretencionarray[this.indicador][intIndiceLista + 1].TRNRETENCION_AUTORIZACION) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'error',
            summary: 'Error',
            detail: 'El número de autorización es distinto.'
          });
          this.encfacproService.spin = false;//CDPJ
          this.botonGuardar = false;//CDPJ
          this.control = 0;//CDPJ
          return;
        }
      }
    }

    const intRETSINVAL = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RETSINVAL').CFG_VALOR1;
    if (intRETSINVAL === 0) {
      this.verificarBasesRetencion(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA),
        this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO),
        this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA),
        this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASENOOBJIVA));//CDPJ SE AUMENTO this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASENOOBJIVA)
    } else {
      this.GuardarRetencion();
      this.actualizarDatosTrans();

      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === 'Crédito' && this.encfacproService.encfacproarray[this.indicador].ASI_NRO !== '') {
        this.generarAsiContable();
      }

    }
  }

  verificarBasesRetencion(dblBaseFactura, dblBaseCero, dblValorIva, dblValorNoObjIva) {//CDPJ SE AUMENTO dblValorNoObjIva

    let retFUENTE = '';
    let intIndiceLista = 0;
    let item;
    let dblTotalRetencionFuente = 0;
    let dblTotalRetencionIva = 0;
    let dblTotalBaseRetencionFuente = 0;
    let dblTotalValorNoObjIva = 0;//CDPJ
    let intNumReg = 0;
    let sw = 0;


    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];
      if (item.RETENCION_CODIGO === '332' || item.RETENCION_CODIGO === '322') {
        sw = sw + 1;
      }
      intNumReg = intNumReg + 1;
    }

    if (sw === intNumReg) { // si tiene insertado la ret 332 Y 322 y SON las únicas
      this.GuardarRetencion();
      this.actualizarDatosTrans();
      return;
    }

    // suma retención fuente
    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];
      if (item.RETENCION_TIPRET === 'RF') {
        dblTotalRetencionFuente = dblTotalRetencionFuente + Number(item.TRNRETENCION_BASE);
      }
    }

    retFUENTE = dblTotalRetencionFuente.toFixed(this.confIniciales.getNumDecSist());
    dblTotalRetencionFuente = Number(retFUENTE);

    // sum retencion iva
    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];
      if (item.RETENCION_TIPRET === '' || item.RETENCION_TIPRET === null) {
        dblTotalRetencionIva = dblTotalRetencionIva + Number(item.TRNRETENCION_BASE);
      }
    }

    dblTotalRetencionIva = Number(dblTotalRetencionIva.toFixed(this.encfacproService.decimalesTOTALDetFacPro));

    dblTotalBaseRetencionFuente = Number(dblBaseFactura) + Number(dblBaseCero) + Number(dblValorNoObjIva);//CDPJ SE AGREGÓ dblValorNoObjIva
    dblTotalBaseRetencionFuente = Number(dblTotalBaseRetencionFuente.toFixed(this.encfacproService.decimalesTOTALDetFacPro));
    //CDPJ
    //SUMA B. No Imp. Iva.
    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];
      if (item.RETENCION_TIPRET === 'RF') {
        dblTotalValorNoObjIva = dblTotalValorNoObjIva + Number(item.TRNRETENCION_BASENG);
      }
    }
    dblTotalValorNoObjIva = Number(Number(dblTotalValorNoObjIva).toFixed(this.confIniciales.getNumDecSist()))
    //CDPJ
    if (((dblTotalBaseRetencionFuente !== dblTotalRetencionFuente) && dblTotalRetencionFuente !== 0) ||
      ((Number(dblValorIva) !== dblTotalRetencionIva) && dblTotalRetencionIva !== 0)
      || ((Number(dblValorNoObjIva) !== dblTotalValorNoObjIva) && dblTotalValorNoObjIva !== 0)) {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Verifique las bases de Retención en la fuente, Retención IVA y B. No Imp. Iva.'
      });
      this.encfacproService.spin = false;//CDPJ
      this.botonGuardar = false;//CDPJ
      this.control = 0;//CDPJ
      return;
    }

    this.GuardarRetencion();
    this.actualizarDatosTrans();
  }

  async GuardarRetencion() {
    this.encfacproService.spin = true;
    // calculaTotalRetenido();
    // calcularBaseRetencion();
    //// totalRetencion();

    let intIndiceLista = 0;
    let item;
    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];
      if (item.RETENCION_CODIGO === '') {
        this.encfacproService.trnretencionarray[this.indicador].splice(intIndiceLista, 1);
      }
    }

    intIndiceLista = 0;

    // Elimina retenciones de saci_trnretencion

    await this.encfacproService.eliminarSaciTrnRetencionProm(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO).catch(e => {

      this.messageService.add({
        key: 'encfacpro',
        severity: 'error',
        summary: 'Error',
        detail: 'Error de  inserción en retenciones, vuelva a guardar por favor.'
      });
      this.encfacproService.spin = false;//CDPJ
      this.botonGuardar = false;//CDPJ
      this.control = 0;//CDPJ
      return;
    });

    // Elimina retenciones de cxp_trnresumen

    await this.encfacproService.eliminarRetencioncxpTrnResum(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO).catch(e => {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al insertar la retención, vuelva a guardar por favor.'
      });
      this.encfacproService.spin = false;//CDPJ
      this.botonGuardar = false;//CDPJ
      this.control = 0;//CDPJ
      return;
    });

    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];

      if (await this.verificarExistenciaRetencion(item.TRNRETENCION_NRO, item.TRNRETENCION_SERIE,
        item.RETENCION_CODIGO) === true) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'error',
          summary: 'Error',
          detail: 'El número de la retención ya existe, por favor verifique'
        });
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        this.control = 0;//CDPJ
        return;
      }

      await this.encfacproService.guardarRetencion(item, this.encfacproService.encretarray[this.indicador]).catch(e => {
        this.encfacproService.spin = false;//CDPJ
        this.botonGuardar = false;//CDPJ
        this.control = 0;//CDPJ
        this.messageService.add({
          key: 'encfacpro',
          severity: 'error',
          summary: 'Error',
          detail: 'No se pudo insertar la retención, por favor vuelva a guardar la factura primero.'
        });
        return;
      });
      if (this.nuevoret === true) {
        this.encfacproService.actualizarNumGAct(this.encfacproService.encretarray[this.indicador].DOCREF,
          this.encfacproService.encretarray[this.indicador].TRNRETENCION_NRO).subscribe();
      }

      this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION', 'RT' + '/' + item.RETENCION_CODIGO
        + '/' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' + item.TRNRETENCION_NRO, 'I',
        '', '01', '', '', '', '').subscribe();//CDPJ SE AUMENTO item.TRNRETENCION_NRO

      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === 'Crédito'
        && this.encfacproService.encfacproarray[this.indicador].ASI_NRO !== '') {
        this.generarAsiContable();
      }

      if (intIndiceLista + 1 === this.encfacproService.trnretencionarray[this.indicador].length) {
        this.actualizaNumeroRetFactura();
        this.botonGuardar = true;//CDPJ
        this.cancelar();
        this.botonGuardar = true;//CDPJ
      }

    }
    this.messageService.add({
      key: 'encfacpro',
      severity: 'success',
      summary: 'Retención Guardada',
      detail: 'La retención ha sido guardada correctamente.'
    });
    if (this.encfacproService.trnretencionarray[this.indicador].length === 0) {
      this.encfacproService.spin = false;//CDPJ
      this.botonGuardar = false;//CDPJ
      return;
    }
    this.encfacproService.spin = false;
    this.botonGuardar = true;//CDPJ
  }

  actualizarDatosTrans() {
    let strSentencia = '';

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_RUCTRANSPORTISTA === null) {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_RUCTRANSPORTISTA = '';
    }
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_RAZONTRANSPORTISTA === null) {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_RAZONTRANSPORTISTA = '';
    }
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REGIMENTRANSPOR === null) {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REGIMENTRANSPOR = '';
    }

    strSentencia = 'Update com_encfacpro set  encfacpro_ructransportista=' + '\''
      + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_RUCTRANSPORTISTA
      + '\' , encfacpro_razontransportista=' + '\'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_RAZONTRANSPORTISTA
      + '\' , encfacpro_regimentranspor=' + '\'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REGIMENTRANSPOR + '\'';
    strSentencia = strSentencia + ' where encfacpro_numero=' + '\'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

    this.encfacproService.actualizarDatosTransp(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_RUCTRANSPORTISTA, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_RAZONTRANSPORTISTA,
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REGIMENTRANSPOR, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe();
  }

  actualizaNumeroRetFactura() {
    let strSentencia = '';
    const intNUMRETSEC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMRETSEC').CFG_VALOR1;

    if (intNUMRETSEC === 1) {
      strSentencia = 'update com_encfacpro set encfacpro_numretencion=';
      strSentencia = strSentencia + ' (select distinct trnretencion_serie || trnretencion_nro from saci_trnretencion';
      strSentencia = strSentencia + ' where trncobro_nrocomprobante=' + '\'' +
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'' + 'and trnretencion_origen=\'COM\'';
      strSentencia = strSentencia + ' and trnretencion_estado<>\'1\') ';
      strSentencia = strSentencia + ' where encfacpro_numero=' + '\'' +
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';
      strSentencia = strSentencia + '  and com_codigo=' + '\'01\'';

    } else {
      strSentencia = 'update com_encfacpro set encfacpro_numretencion=';
      strSentencia = strSentencia + ' (select distinct trnretencion_serie || trim(trnretencion_nro) from saci_trnretencion';
      strSentencia = strSentencia + ' where trncobro_nrocomprobante=' + '\'' +
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'' + 'and trnretencion_origen=\'COM\'';
      strSentencia = strSentencia + ' and trnretencion_estado<>\'1\') ';
      strSentencia = strSentencia + ' where encfacpro_numero=' + '\'' +
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';
      strSentencia = strSentencia + '  and com_codigo=' + '\'01\'';
    }

    this.encfacproService.actualizarNumRetFact(intNUMRETSEC, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe();
  }

  Credito() {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'La factura está anulada'
      });
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === '') {
      this.VerificarIngresoRetenciones('Credito', '');
    }
  }

  Credito2() {
    this.confirmationService.confirm({
      message: 'Está seguro de realizar el pago a Crédito?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.generarAsiContable();
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO = 'Crédito';
        this.actualizarTipoPagoFacProv(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, 'C');
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  VerificarIngresoRetenciones(accion, strTipo) {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM === '41') { // si es reembolso
      this.verificarIngresoReembolsos(accion, strTipo);
      return;
    }
    if (this.encfacproService.encfacproarray[this.indicador].USU_IDENTIFICACION !== this.encfacproService.usuario.identificacion) {
      this.verificarIngresoReembolsos(accion, strTipo);
      return;
    }
    const intINGRETOBL = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRETOBL').CFG_VALOR1;
    if (intINGRETOBL === 0) {
      this.verificarIngresoReembolsos(accion, strTipo);
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '') {
      this.verificarIngresoReembolsos(accion, strTipo);
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA' ||
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA') {
      this.verificarIngresoReembolsos(accion, strTipo);
      return;
    }

    let strSentencia = 'select count(*) as COUNT from saci_trnretencion where trncobro_nrocomprobante=' + '\''
      + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';
    strSentencia = strSentencia + ' and pro_codigo=' + '\'' + this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO + '\'';

    this.encfacproService.countSacitrnretencion(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO,
      this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO).subscribe(ec => {
        if (ec !== null) {
          if (ec[0] !== undefined) {
            if (ec[0].COUNT === 0) {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Información',
                detail: 'No existen retenciones.'
              });
              return false;
            } else {
              this.verificarIngresoReembolsos(accion, strTipo);
              return;
            }
          }
        }
      });
  }

  verificarIngresoReembolsos(accion, strTipo) {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM !== '41') { // si es reembolso
      this.verificarFormaPago(accion, strTipo);
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].USU_IDENTIFICACION !== this.encfacproService.usuario.identificacion) {
      this.verificarFormaPago(accion, strTipo);
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '') {
      this.verificarFormaPago(accion, strTipo);
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA'
      || this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA') {
      this.verificarFormaPago(accion, strTipo);
      return;
    }

    const strSentencia = 'select count(*) as COUN  from COM_TRNREEMBOLSOS where ENCFACPRO_NUMERO=\'' +
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';

    this.encfacproService.countComtrnReembolsos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(data => {
      for (const rs of data) {
        if (rs.COUN === 0) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'info',
            summary: 'Información',
            detail: 'No existen reembolsos.'
          });
        } else {
          this.verificarFormaPago(accion, strTipo);
          return;
        }
      }
    });
  }

  verificarFormaPago(accion, strTipo) {
    if (accion === 'Credito') {
      this.Credito2();
    } else if (accion === 'PagoDocumento') {
      this.PagoDocumento2();
    } else {
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === '' &&
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '' &&
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO !== 'ANULADA' &&
        (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMLIQUIDACION === '' ||
          this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMLIQUIDACION === null)) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Forma de pago no existe en la factura de proveedores'
        });
        return;
      }
    }

    if (accion === 'Nuevo') {
      this.ValidarSerialesNuevaFac()
      
    }
    if (accion === 'buscarRegistroSinRet') {
      this.buscarRegistroSinRet2();
    }
    if (accion === 'seleccionarRegistro') {
      this.seleccionarRegistro2(strTipo);
    }
    if (accion === 'Buscar') {
      this.ValidarSerialesBusqueda()
      //this.busquedafacturapro();
    }
    if (accion === 'Salir') {
      this.ValidarSerialesSalida()
      
    }
  }
  //CDPJ
  async ValidarSerialesBusqueda() {
    let errores = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let notarecepcion = '';
    let intLinea = 0;
    let strSentencia = '';

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.busquedafacturapro();
    } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '') {
      this.busquedafacturapro();
    } else {
      for (let i = 1; i <= this.encfacproService.detfacproarray[this.indicador].length; i++) {
        artserialflag = this.encfacproService.detfacproarray[this.indicador][i - 1].ART_SERIALFLAG;
        articulo = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_CODIGO;
        cantidad = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_CANTIDAD;
        intLinea = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_LINEA;

        console.log(articulo, '||', artserialflag);
        notarecepcion = this.encfacproService.detfacproarray[this.indicador][i - 1].ENCNOTREC_NUMERO;

        if (notarecepcion === null) {
          notarecepcion = '';
        }

        if (artserialflag === 'S' && notarecepcion === '') {

          console.log(strSentencia);
          const data3 = await this.encfacproService.countTrnkardexotrProm(articulo, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, intLinea);
          for (const rs of data3) {
            if (rs.COUN !== Number(cantidad)) {
              errores++;
            }
          }
        }
      }

      if (errores > 0) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese los seriales.'
        });
      } else {
        this.busquedafacturapro();
      }
    }
  }
  async ValidarSerialesSalida() {
    let errores = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let notarecepcion = '';
    let intLinea = 0;
    let strSentencia = '';

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.init.tabs.splice(this.init.tabindex, 1);
    } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '') {
      this.init.tabs.splice(this.init.tabindex, 1);
    } else {
      for (let i = 1; i <= this.encfacproService.detfacproarray[this.indicador].length; i++) {
        artserialflag = this.encfacproService.detfacproarray[this.indicador][i - 1].ART_SERIALFLAG;
        articulo = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_CODIGO;
        cantidad = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_CANTIDAD;
        intLinea = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_LINEA;

        console.log(articulo, '||', artserialflag);
        notarecepcion = this.encfacproService.detfacproarray[this.indicador][i - 1].ENCNOTREC_NUMERO;

        if (notarecepcion === null) {
          notarecepcion = '';
        }

        if (artserialflag === 'S' && notarecepcion === '') {

          console.log(strSentencia);
          const data3 = await this.encfacproService.countTrnkardexotrProm(articulo, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, intLinea);
          for (const rs of data3) {
            if (rs.COUN !== Number(cantidad)) {
              errores++;
            }
          }
        }
      }

      if (errores > 0) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese los seriales.'
        });
      } else {
        this.init.tabs.splice(this.init.tabindex, 1);
      }
    }
  }
  async ValidarSerialesNuevaFac() {
    let errores = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let notarecepcion = '';
    let intLinea = 0;
    let strSentencia = '';

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.nuevo();
    } else if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '') {
      this.nuevo();
    } else {
      for (let i = 1; i <= this.encfacproService.detfacproarray[this.indicador].length; i++) {
        artserialflag = this.encfacproService.detfacproarray[this.indicador][i - 1].ART_SERIALFLAG;
        articulo = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_CODIGO;
        cantidad = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_CANTIDAD;
        intLinea = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_LINEA;

        console.log(articulo, '||', artserialflag);
        notarecepcion = this.encfacproService.detfacproarray[this.indicador][i - 1].ENCNOTREC_NUMERO;

        if (notarecepcion === null) {
          notarecepcion = '';
        }

        if (artserialflag === 'S' && notarecepcion === '') {

          console.log(strSentencia);
          const data3 = await this.encfacproService.countTrnkardexotrProm(articulo, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, intLinea);
          for (const rs of data3) {
            if (rs.COUN !== Number(cantidad)) {
              errores++;
            }
          }
        }
      }

      if (errores > 0) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese los seriales.'
        });
      } else {
        this.nuevo();
      }
    }
  }
  //CDPJ

  async generarAsiContable() {
    await this.encfacproService.GenerarAsientoContable(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
    this.messageService.add({
      key: 'encfacpro',
      severity: 'success',
      summary: 'Información',
      detail: 'El asiento se generó correctamente'
    });
    this.encfacproService.spin = false;//CDPJ
    this.botonGuardar = true;//CDPJ
    const er = await this.encfacproService.erAsiNro1Prom(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
    if (er !== null) {
      if (er[0] !== undefined) {
        this.encfacproService.encfacproarray[this.indicador].ASI_NRO = er[0].ASI_NRO;
      }
    }
  }

  activarNumero() {
    if (this.encfacproService.trnretencionarray[this.indicador].length > 0) {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Para modificar el número de secuencia tiene que eliminar las retenciones.'
      });
    } else {
      this.autorizacionCodigo = 'ACTNUMRE';
      this.claveAuth = '';
      this.displayDialogAutorizacion = true;
      setTimeout(() => {
        document.getElementById('clave').focus();
      }, 500);
    }
  }

  veerificarAnularRetenciones() {

    if (this.datosfe.COM_FEACTIVADA === '1' && this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PENDIENTE') {
      this.autorizacionCodigo = 'ACTRETFI';
      this.auxiliarAutorizacion = '1';
      this.claveAuth = '';
      this.displayDialogAutorizacion = true;
      setTimeout(() => {
        document.getElementById('clave').focus();
      }, 500);
    } else {
      this.autorizacionCodigo = 'AUTANRET';
      this.claveAuth = '';
      this.displayDialogAutorizacion = true;
      setTimeout(() => {
        document.getElementById('clave').focus();
      }, 500);
    }

  }

  anularRetenciones() {
    this.confirmationService.confirm({
      message: 'Está seguro de anular la retención?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.encfacproService.AnularRetencion(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(() => {
          this.utilitariosService.reversarProcesoFE(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, '07');
          this.utilitariosService.reversarProcesoFEClave(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_CLAVEACCESO_FE);
          this.encontrarFactura(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
        });
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  comprobarClave() {
    this.confIniciales.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  validarClave() {
    if (this.usuarioSup.length > 0) {
      this.verificarUsuarioSupervisor();  // verifica si el usuario es supervisor
    } else {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el usuario.'
      });
    }

  }

  verificarUsuarioSupervisor() {
    let strSql = 'SELECT COUNT(*) AS COUN FROM SACI_MAEGUIOBJPER S ';
    strSql = strSql + ' WHERE S.USU_IDENTIFICACIONSUP = \'' + this.usuarioSup + '\' AND ';
    strSql = strSql + ' S.COM_CODIGO = \'01\'';

    this.encfacproService.countSacimaeObjper(this.usuarioSup).subscribe(eC => {
      for (const rs of eC) {
        if (rs.COUN > 0) {
          if (this.claveSup.length > 0) {
            this.verificarClaveSupervisor();
          } else {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'error',
              summary: 'Información',
              detail: 'Ingrese la clave.'
            });
          }
        } else {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario ' + this.usuarioSup + ' no es Supervisor o No existe'
          });
        }
      }
    });
  }

  verificarClaveSupervisor() {
    let strSql = 'SELECT NVL(S.USU_CLAVESUP,\'\') AS USUCLAVESUP FROM  SACI_MAEGUIOBJ O, SACI_MAEGUIOBJPER S WHERE ';
    strSql = strSql + ' O.GUIOBJ_CODIGO = S.GUIOBJ_CODIGO AND ';
    strSql = strSql + ' O.GUIOBJ_FORMA = \'' + this.strDialogo + '\' AND ';
    strSql = strSql + ' O.GUIOBJ_NOMBOBJ = \'' + this.strObjeto + '\' AND ';
    strSql = strSql + ' S.COM_CODIGO = \'01\' AND ';
    strSql = strSql + ' S.USU_IDENTIFICACION = \'' + this.encfacproService.usuario.identificacion + '\' AND ';
    strSql = strSql + ' S.USU_IDENTIFICACIONSUP = \'' + this.usuarioSup + '\'';
    if (this.intPestaniaCol > 0) {
      strSql = strSql + ' AND O.GUIOBJ_TABCOL = ' + this.intPestaniaCol;
    }

    this.encfacproService.verificarPermisosSuperv(this.strDialogo, this.strObjeto, this.encfacproService.usuario.identificacion, this.usuarioSup,
      this.intPestaniaCol).subscribe(data => {
        if (data.length === 0) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'error',
            summary: 'Información',
            detail: 'Asigne la clave en los permisos ' +
              'especiales del usuario.'
          });
        } else {
          for (const rs of data) {
            if (rs.USUCLAVESUP !== null) {
              if (rs.USUCLAVESUP.length > 0) {
                if (rs.USUCLAVESUP === this.claveSup) {
                  this.accionesSupervisor();
                } else {
                  this.messageService.add({
                    key: 'encfacpro',
                    severity: 'error',
                    summary: 'Información',
                    detail: 'La clave está incorrecta..'
                  });
                }
              } else {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'Asigne la clave en los permisos ' +
                    'especiales del usuario.'
                });
              }
            } else {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'error',
                summary: 'Información',
                detail: 'Asigne la clave en los permisos ' +
                  'especiales del usuario.'
              });
            }
          }
        }
      });
  }

  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'ACTRETFI') {
      this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION',
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/COM/ACT-BOTON-ANULAR-RET', 'A',
        '', '01', '', '', '', '').subscribe();
      if (this.auxiliarAutorizacion === '1') {
        this.anularRetenciones();
      }
      if (this.auxiliarAutorizacion === '2') {
        this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION',
          this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/COM/ACT-BOTON-ANULAR-RET', 'A',
          '', '01', '', '', '', '').subscribe();

        this.bolAnularRetencion = false;
      }
      this.displayDialogAutorizacion = false;
    }

    if (this.autorizacionCodigo === 'AUTANRET') {
      this.anularRetenciones();
      this.displayDialogAutorizacion = false;
    }

    if (this.autorizacionCodigo === 'ACTNUMRE') {
      this.bolNumRet = false;
      this.displayDialogAutorizacion = false;
      //CDPJ
      this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION',
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/COM/ACT-BOTON-ACTIVAR-NUMRET', 'A',
        '', '01', '', '', '', '').subscribe();
      //CDPJ
    }

    if (this.autorizacionCodigo === 'ANULAFC') {
      this.anularFactura();
      this.displayDialogAutorizacion = false;
    }

    if (this.autorizacionCodigo === 'AUTUTIOC') {
      this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', 'AUTUTIOC' + '/' + this.strEncOrdCom, 'I',
        '', '01', '', '', '', '').subscribe();
      this.LlenarcamposOrdenCompra(this.strEncOrdCom);
      this.displayDialogAutorizacion = false;
    }

    if (this.autorizacionCodigo === 'ACTSSFC') {
      this.bolSerie = false;
      this.bolComprobante = false;
      this.displayDialogAutorizacion = false;
    }


    if (this.autorizacionCodigo === 'ACTDOC') {
      this.encfacproService.actualizarEstadoDocumento(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(() => {
        this.displayDialogAutorizacion = false;
        this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' + 'ACTIVA', 'A',
          '', '01', '', '', '', '').subscribe();
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Tiene que volver a grabar la factura para que se registre en cartera pasiva, ' +
            'realice un cambio en las observaciones.'
        });

        this.encontrarFactura(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);

        if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO !== '') {
          this.botonAnular = false;
        }
      });
    }
  }

  accionesSupervisor() {
    if (this.strObjeto === 'CmdAnular') {
      this.anularFactura();
      this.displayDialogSupervisor = false;
    }
  }

  async actualizarTipoPagoFacProv(strCodFacpro, strTipoPago) {
    let strSentencia = 'Update com_encfacpro set encfacpro_formapago=' + '\'' + strTipoPago + '\'';
    strSentencia = strSentencia + ' where encfacpro_numero=' + '\'' + strCodFacpro + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

    await this.encfacproService.actualizarFormapagofact(strTipoPago, strCodFacpro).toPromise()//.subscribe();
    //CDPJ
    this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', strCodFacpro + '/FP/' + strTipoPago, 'I',
      '', '01', '', '', '', '').subscribe();
    //CDPJ
  }


  verAsiento() {
    this.asiento = this.encfacproService.encfacproarray[this.indicador].ASI_NRO;
    if (this.asiento === null) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      this.displayDialogAsiento = true;
    }
  }

  EliminaTrnKardexOtr(strEncFacPro, strOrigen, lngLinea, strTipo, strCodArticulo) {
    let strSentencia = '';

    if (strTipo === 'A') { // Borrado por artículo
      strSentencia = 'delete from inv_trnkardexotr';
      strSentencia = strSentencia + ' where trnkardexotr_numero=' + '\'' + strEncFacPro + '\'';
      strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
      strSentencia = strSentencia + ' and trnkardexotr_origen=' + '\'' + strOrigen + '\'';
      strSentencia = strSentencia + ' and art_codigo=' + '\'' + strCodArticulo + '\'';
    } else {
      if (strTipo === 'L') { // Solo de la linea de datalle
        strSentencia = 'delete from inv_trnkardexotr';
        strSentencia = strSentencia + ' where trnkardexotr_numero=' + '\'' + strEncFacPro + '\'';
        strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
        strSentencia = strSentencia + ' and trnkardexotr_origen=' + '\'' + strOrigen + '\'';
        strSentencia = strSentencia + ' and trnkardexotr_linea=' + lngLinea;
      } else {
        strSentencia = 'delete from inv_trnkardexotr';
        strSentencia = strSentencia + ' where trnkardexotr_numero=' + '\'' + strEncFacPro + '\'';
        strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
        strSentencia = strSentencia + ' and trnkardexotr_origen=' + '\'' + strOrigen + '\'';
      }
    }

    this.encfacproService.eliminarTrnkardexotr(strEncFacPro, strOrigen, lngLinea, strTipo, strCodArticulo).subscribe();

  }

  comprobarTipoGasto(detfacpro) {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA' ||
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = true;
      return false;
    }
    return detfacpro.BOLTIPOGASTO === true;
  }

  comprobarTipoGastocell(detfacpro) {
    if (detfacpro.BOLTIPOGASTO === true) {
      return {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          opcionbuscar: true
        }
      };
    } else {
      return {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          opcionbuscar: false
        }
      };
    }
  }

  agregarSeriales() {
    this.strNumFact = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
    this.strFechaSer = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
    this.displayDialogSeriales = true;

    this.closeSidebar();
  }

  agregarReembolsos() {
    this.auxiliarrem++;
    this.strNumFact = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
    this.strFechaSer = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
    this.displayDialogReembolsos = true;

    this.closeSidebar();
  }
  copiarFactura() {
    this.closeSidebar();
    this.index = 0;
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.bolcmbSerie = false;
    this.bolNumRet = true;
    this.bolProveedor = false;
    this.bolSerie = false;
    this.bolComprobante = false;
    this.bolAnularRetencion = false;
    this.nuevoret = true;
    this.activarbotonesgrid();//CDPJ
    this.tipodetalle = this.TIPDET === null || this.TIPDET === undefined ? 'A' : this.TIPDET;
    if (this.encfacproService.encfacproarray[this.indicador] !== undefined) {
      this.ultimabodegacod = this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO;
      this.ultimabodeganame = this.encfacproService.encfacproarray[this.indicador].BOD_NOMBRE;
    }
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_SERIE = '';
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_REFERENCIA = '';
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION = '';
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO = '';
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO = '';
    this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO = 'PENDIENTE';
    this.encfacproService.encfacproarray[this.indicador].ASI_NRO = '';
    this.encfacproService.trnretencionarray[this.indicador] = [];
    this.encfacproService.encretarray[this.indicador] = {
      TRNRETENCION_SERIE: '',
      TRNRETENCION_NRO: '',
      TRNRETENCION_AUTORIZACION: '',
      DOCREF: ''
    };
    for (const det of this.encfacproService.detfacproarray[this.indicador]) {
      const itemdet: ComDetfacpro = det;
      itemdet.DETFACPRO_LINEA = 0
    }
    document.getElementById('contorno').style.pointerEvents = 'all';
    document.getElementById('contorno2').style.pointerEvents = 'all';
    document.getElementById('contorno3').style.pointerEvents = 'all';
    this.aggrid.habilitarGrid();
    document.getElementById('PRO_CODIGO').focus();
  }
  async ValidarSeriales(strNumDoc, strTipo) {
    let errores = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let notarecepcion = '';
    let intLinea = 0;
    let strSentencia = '';

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.irARegistro(strTipo);
    } else if (strNumDoc === '') {
      this.irARegistro(strTipo);
    } else {
      for (let i = 1; i <= this.encfacproService.detfacproarray[this.indicador].length; i++) {
        artserialflag = this.encfacproService.detfacproarray[this.indicador][i - 1].ART_SERIALFLAG;
        articulo = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_CODIGO;
        cantidad = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_CANTIDAD;
        intLinea = this.encfacproService.detfacproarray[this.indicador][i - 1].DETFACPRO_LINEA;

        console.log(articulo, '||', artserialflag);
        notarecepcion = this.encfacproService.detfacproarray[this.indicador][i - 1].ENCNOTREC_NUMERO;

        if (notarecepcion === null) {
          notarecepcion = '';
        }

        if (artserialflag === 'S' && notarecepcion === '') {
          strSentencia = 'select count(*) AS COUN from INV_TRNKARDEXOTR ';
          strSentencia = strSentencia + ' where art_codigo = \''
            + articulo + '\' and TRNKARDEXOTR_NUMERO = \''
            + strNumDoc + '\'';
          strSentencia = strSentencia + ' and trnkardexotr_linea = ' + intLinea;
          console.log(strSentencia);
          const data3 = await this.encfacproService.countTrnkardexotrProm(articulo, strNumDoc, intLinea);
          for (const rs of data3) {
            if (rs.COUN !== Number(cantidad)) {
              errores++;
            }
          }
        }
      }

      if (errores > 0) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese los seriales.'
        });
      } else {
        this.irARegistro(strTipo);
      }
    }
  }

  validarSerialesEli() {
    let retornar = true;
    let seriales = 0;
    let intExistencia = 0;
    let intSalidas = 0;
    let intEntradas = 0;


    this.encfacproService.erCounttrnkardexotr1(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(data => {
      if (data !== null) {
        if (data[0] !== undefined) {
          seriales = data[0].COUN;
        }
      }

      if (seriales > 0) {

        let strSentencia = 'select TRNKARDEXOTR_NUMSERIE from inv_trnkardexotr ';
        strSentencia = strSentencia + ' where TRNKARDEXOTR_NUMERO = \'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';
        strSentencia = strSentencia + ' and com_codigo = \'01\'';

        this.encfacproService.getTrnkardexotrnumserie(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(data2 => {
          for (const rs of data2) {
            this.encfacproService.erCounttrnkardexotr2(rs.TRNKARDEXOTR_NUMSERIE).subscribe(er => {
              if (er !== null) {
                if (er[0] !== undefined) {
                  intSalidas = er[0].COUN;
                }
              }

              this.encfacproService.erCounttrnkardexotr3(rs.TRNKARDEXOTR_NUMSERIE).subscribe(er2 => {
                if (er2 !== null) {
                  if (er2[0] !== undefined) {
                    intEntradas = er2[0].COUN;
                  }
                }
                intExistencia = Number(intEntradas) - Number(intSalidas);

                if (intExistencia === 0) {
                  retornar = false;
                }
              })
                ;
            });
          }

          setTimeout(() => {
            if (retornar === true) {
              this.validacionesEliminar();
            } else {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Información',
                detail: 'Existen seriales que tienen salidas. Elimine las salidas de los seriales para poder eliminar esta factura.'
              });
            }
          }, 500);
        });
      } else {
        this.validacionesEliminar();
      }
    });
  }

  leerDatos(strTipoCorreo, strCodigo) {
    if (strTipoCorreo === 'C') { // clientes
      this.encfacproService.erClicorreo1(strCodigo).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            this.txtCorreo1 = er[0].CLI_CORREO;
            this.txtCorreo2 = er[0].CLI_CORREO2;
          }
        }
      });
    }

    if (strTipoCorreo === 'P') {
      this.encfacproService.erProcorreo1(strCodigo).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            this.txtCorreo1 = er[0].PRO_CORREO;
            this.txtCorreo2 = er[0].PRO_CORREO2;
          }
        }
      });
    }
  }

  actualizarDatos(strTipoCorreo, strCodigo) {
    if (this.txtCorreo1 === null) {
      this.txtCorreo1 = '';
    }

    if (this.txtCorreo2 === null) {
      this.txtCorreo2 = '';
    }

    if (this.txtCorreo1.length === 0) {

      this.encfacproService.erComCorreoCopFac1().subscribe(er => {
        let correocopia = '';
        if (er !== null) {
          if (er[0] !== undefined) {
            correocopia = er[0].COM_CORREOCOPIAFAC;
          }
        }

        this.txtCorreo1 = correocopia;
      });


    }

    let strSentencia = '';


    if (strTipoCorreo === 'C') { // clientes
      strSentencia = 'update ven_maecliente set cli_correo=' + '\'' + this.txtCorreo1 + '\',';
      strSentencia = strSentencia + ' cli_correo2=' + '\'' + this.txtCorreo2 + '\'';
      strSentencia = strSentencia + ' where cli_codigo=' + '\'' + strCodigo + '\'';

      this.encfacproService.actualizarCorreosClientes(this.txtCorreo1, this.txtCorreo2, strCodigo).subscribe();
    }


    if (strTipoCorreo === 'P') {// proveedores
      strSentencia = 'update com_maeproveedor set pro_correo=' + '\'' + this.txtCorreo1 + '\',';
      strSentencia = strSentencia + ' pro_correo2=' + '\'' + this.txtCorreo2 + '\'';
      strSentencia = strSentencia + ' where pro_codigo=' + '\'' + strCodigo + '\'';

      this.encfacproService.actualizarCorreosProvee(this.txtCorreo1, this.txtCorreo2, strCodigo).subscribe();

    }

    this.displayDialogActualizacionDE = false;

  }

  anularRegistro() {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '' || this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === null ||
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === undefined) {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'error',
        summary: 'Error',
        detail: 'Seleccione el registro a Anular'
      });
      return;
    }


    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA') {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'La factura está pagada'
      });
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== '') {
      return;
    }

    this.validaranularFactura();
  }

  validaranularFactura() {
    let respuesta = false;
    let strVal = 0;


    strVal = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENCLAAUT').CFG_VALOR1;

    if (strVal === 0) {//CDPJ ANTES ERA 1 AHORA 0


      this.strDialogo = 'frmCOM_ENCFACPRO';
      this.strObjeto = 'CmdAnular';
      this.intPestaniaCol = 0;
      this.usuarioSup = '';
      this.claveSup = '';

      let strSql = 'SELECT COUNT(*) AS COUN FROM  SACI_MAEGUIOBJ O, SACI_MAEGUIOBJPER S WHERE ';
      strSql = strSql + ' O.GUIOBJ_CODIGO = S.GUIOBJ_CODIGO AND ';
      strSql = strSql + ' O.GUIOBJ_FORMA = \'' + this.strDialogo + '\' AND ';
      strSql = strSql + ' O.GUIOBJ_NOMBOBJ = \'' + this.strObjeto + '\' AND ';
      strSql = strSql + ' S.COM_CODIGO = \'01\' AND ';
      strSql = strSql + ' S.USU_IDENTIFICACION = \''
        + this.encfacproService.usuario.identificacion + '\' AND ';
      strSql = strSql + ' S.GUIOBJPERSUP_ESTADO = 1';

      if (this.intPestaniaCol > 0) {
        strSql = strSql + ' AND O.GUIOBJ_TABCOL = ' + this.intPestaniaCol;
      }

      this.encfacproService.countSacimaeobj(this.strDialogo, this.strObjeto, this.encfacproService.usuario.identificacion, this.intPestaniaCol).subscribe(eC => {
        for (const rs of eC) {
          if (rs.COUN > 0) {
            this.displayDialogSupervisor = true;
          } else {
            this.anularFactura();
          }
        }
      });


    } else {

      this.autorizacionCodigo = 'ANULAFC';
      this.claveAuth = '';
      this.displayDialogAutorizacion = true;
      setTimeout(() => {
        document.getElementById('clave').focus();
      }, 500);

    }
  }

  anularFactura() {
    this.confirmationService.confirm({
      message: 'Está seguro de anular la factura?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.encfacproService.CambiarEstadoENCFACPRO(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO,
          this.encfacproService.encfacproarray[this.indicador].ASI_NRO).subscribe(data => {
            for (const rs of data) {
              if (rs[':B1'] === 1) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'La Factura no puede ser anulada ya que tiene asociada pagos.'
                });
                return;
              }

            }

            this.messageService.add({
              key: 'encfacpro',
              severity: 'success',
              summary: 'Información',
              detail: 'Factura anulada correctamente.'
            });

            this.EliminaTrnKardexOtr(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, 'COM',
              0, '', '');
            this.encfacproService.extraerCodigo(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(ec => {
              const LstCodigosOrdComNotRec: ComDetfacpro[] = [];

              for (const rs of ec) {
                const item: ComDetfacpro = {};
                // item.DETFACPRO_ESQUEMADOC = rs.DETFACPRO_ESQUEMADOC;
                // item.ENCNOTREC_NUMERO = rs.ENCNOTREC_NUMERO;
                // item.ENCORDCOM_NUMERO = rs.ENCORDCOM_NUMERO;
                // LstCodigosOrdComNotRec.push(item);
                //CDPJ
                item.DETFACPRO_ESQUEMADOC = rs.DETFACPRO_ESQUEMADOC === null || rs.DETFACPRO_ESQUEMADOC === undefined ? '' : rs.DETFACPRO_ESQUEMADOC;
                item.ENCNOTREC_NUMERO = rs.ENCNOTREC_NUMERO === null || rs.ENCNOTREC_NUMERO === undefined ? '' : rs.ENCNOTREC_NUMERO;
                item.ENCORDCOM_NUMERO = rs.ENCORDCOM_NUMERO === null || rs.ENCORDCOM_NUMERO === undefined ? '' : rs.ENCORDCOM_NUMERO;
                item.ENCFACPRO_NUMERO = rs.ENCFACPRO_NUMERO === null || rs.ENCFACPRO_NUMERO === undefined ? '' : rs.ENCFACPRO_NUMERO;//CDPJ
                LstCodigosOrdComNotRec.push(item);
                //CDPJ
              }

              for (const item2 of LstCodigosOrdComNotRec) {
                item2.ENCFACPRO_NUMERO = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
                this.encfacproService.ActEstTransKardex(item2).subscribe();
              }

              this.actualizarEstadoOrdenCompra();

              this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, 'AN',
                '', '01', '', '', '', '').subscribe();

              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO = 'ANULADA';

              this.recalcularCostoPromedioArt();

              this.confirmationService.confirm({
                message: 'Está seguro de anular la retención?',
                header: 'Pregunta',
                icon: 'pi pi-exclamation-triangle',
                key: 'confirmarencfacpro',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                accept: () => {
                  this.confirmationService.close();
                  this.encfacproService.AnularRetencion(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(data => {
                    this.encontrarFactura(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
                  });
                },
                reject: () => {
                  this.confirmationService.close();
                }
              });
            });
          });
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  recalcularCostoPromedioArt() {
    for (const item of this.encfacproService.detfacproarray[this.indicador]) {
      if (item.DETFACPRO_TIPODET === 'A') { // si es artículo
        // Recalcula el costo promedio de este producto
        const strFechaEmision = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
        this.encfacproService.recalculaCostosUno(item.DETFACPRO_CODIGO, strFechaEmision).subscribe();
      }
    }
  }

  agregarOrdenCompra() {
    this.buscarOrdenCompra();
  }

  agregarNotaRecepcion() {
    this.buscarNotaRecepcion();
  }

  buscarOrdenCompra() {

    const strFechaEmision = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
    const strFiltro = 'to_date(to_char(encordcom_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')<=to_date(' + '\'' + strFechaEmision + '\'' + ' ,\'dd/mm/yyyy\')' +
      ' AND (ENCORDCOM_ESTADO=\'T\' OR ENCORDCOM_ESTADO=\'F\')' +
      ' AND COM_FNC_MONTO_PEND_FAC_OC(ENCORDCOM_NUMERO,COM_CODIGO)>0' +
      ' AND ENCORDCOM_NUMERO NOT IN (SELECT ENCORDCOM_NUMERO FROM COM_ENCFACPRO' +
      ' WHERE NOT ENCORDCOM_NUMERO IS NULL AND ENCFACPRO_ESTADO<>\'A\')';

    this.opcionbusqueda = 'agOC';
    this.types = [
      { label: 'NUMERO', value: 'ENCORDCOM_NUMERO' },
      { label: 'REFERENCIA', value: 'ENCORDCOM_REFERENCIA' },
      { label: 'FEC. EMISIÓN', value: 'ENCORDCOM_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCORDCOM_ESTADO' },
      { label: 'NOMBRE', value: 'PRO_NOMBRE' },
      { label: 'PROVEEDOR', value: 'PRO_CODIGO' },
      { label: 'CONTACTO', value: 'ENCORDCOM_CONTACTO' },
      { label: 'TOTAL', value: 'ENCORDCOM_TOTAL' },
      { label: 'NETO', value: 'ENCORDCOM_TOTALNETO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'COM_ENCORDCOM A';
    this.where = 'PRO_CODIGO=' + '\'' + this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO + '\'' + ' and ' + strFiltro;

    this.busqService.busquedaComEncOrdCom(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO, strFiltro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.ENCORDCOM_FECHAEMISION !== null) {
            registro.ENCORDCOM_FECHAEMISION = this.datePipe.transform(registro.ENCORDCOM_FECHAEMISION, 'dd/MM/yyyy');
          } else {
            registro.ENCORDCOM_FECHAEMISION = '';
          }
        });
      }
      this.displayDialogBusquedaFast = true;
    });

    this.closeSidebar();
  }

  buscarNotaRecepcion() {
    const strFechaEmision = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
    let strFiltro = 'to_date(to_char(encnotrec_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')<=to_date(' + '\'' + strFechaEmision + '\'' + ' ,\'dd/mm/yyyy\')';

    this.opcionbusqueda = 'agNR';
    this.types = [
      { label: 'NUMERO', value: 'ENCNOTREC_NUMERO' },
      { label: 'REFERENCIA', value: 'ENCNOTREC_REFERENCIA' },
      { label: 'FEC. EMISIÓN', value: 'ENCNOTREC_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCNOTREC_ESTADO' },
      { label: 'NOMBRE', value: 'PRO_NOMBRE' },
      { label: 'PROVEEDOR', value: 'PRO_CODIGO' },
      { label: 'CONTACTO', value: 'ENCNOTREC_CONTACTO' },
      { label: 'TOTAL', value: 'ENCNOTREC_TOTAL' },
      { label: 'NETO', value: 'ENCNOTREC_TOTALNETO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'COM_ENCNOTREC A';
    this.where = ' encnotrec_estado=\'P\' and  pro_codigo=' + '\'' + this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO +
      '\'' + ' and ' + strFiltro;

    this.busqService.busquedaComEncNotRec(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO, strFiltro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.ENCNOTREC_FECHAEMISION !== null) {
            registro.ENCNOTREC_FECHAEMISION = this.datePipe.transform(registro.ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
          } else {
            registro.ENCNOTREC_FECHAEMISION = '';
          }
        });
      }
      this.displayDialogBusquedaFast = true;
    });

    this.closeSidebar();
  }

  verificaSiTieneLiberacionReq(strOrdCompra) {
    let intNumReg = 0;

    let strSentencia = ' select count(*) as COUN from pre_detpresupuesto a, com_detordcom b';
    strSentencia = strSentencia + ' where not encreq_numero_lib is null ';
    strSentencia = strSentencia + ' and a.encreq_numero_lib=b.encreq_numero';
    strSentencia = strSentencia + ' and a.com_codigo=b.com_codigo and encordcom_numero=' + '\'' + strOrdCompra + '\'';

    this.encfacproService.coutDetpresupuesto(strOrdCompra).subscribe(eC => {
      for (const rs of eC) {
        intNumReg = rs.COUN;

      }

      if (intNumReg > 0) {
        this.TieneLiberacionReq(strOrdCompra);
      } else {
        // Si tiene liberación por las requisiciones

        strSentencia = ' select count(*) from com_encrequisicion a,com_detordcom b ';
        strSentencia = strSentencia + ' where  not a.encreq_numero_ref is null and encreq_estado<>\'A\' ';
        strSentencia = strSentencia + ' and a.encreq_numero_ref=b.encreq_numero';
        strSentencia = strSentencia + ' and a.com_codigo=b.com_codigo and b.encordcom_numero=' + '\'' + strOrdCompra + '\'';

        this.encfacproService.countEncrequisicion(strOrdCompra).subscribe(eC2 => {
          for (const rs of eC2) {
            intNumReg = rs.COUN;
          }
          if (intNumReg > 0) {
            this.TieneLiberacionReq(strOrdCompra);
          } else {
            this.LlenarcamposOrdenCompra(strOrdCompra);
          }
        });
      }
    });


  }

  TieneLiberacionReq(strEncOrdCom) {
    this.messageService.add({
      key: 'encfacpro',
      severity: 'warn',
      summary: 'Información',
      detail: 'La orden de compra esta enlazada con una requisición que tiene una liberación de presupuesto, por favor comuniquese con la area de contabilidad.'
    });
    this.autorizacionCodigo = 'AUTUTIOC';
    this.claveAuth = '';
    this.strEncOrdCom = strEncOrdCom;
    this.displayDialogAutorizacion = true;
    setTimeout(() => {
      document.getElementById('clave').focus();
    }, 500);
  }

  LlenarcamposOrdenCompra(strEncOrdComNumero) {
    let strSentencia = '';
    let strComprobante = '';
    let intFilaSel = this.encfacproService.detfacproarray[this.indicador].findIndex(
      dtf => dtf.CLAVE === this.detfacproSelected.CLAVE);
    let item: ComDetfacpro = null;
    let strCodArticulo = '';
    let strTipo = '';
    let strDescripcion = '';
    let dblPorcentajeDesc = 0;
    let dblValorDesc = 0;
    let intSW = 0;

    strSentencia = 'select * from com_encordcom where encordcom_numero='
      + '\'' + strEncOrdComNumero + '\'';
    strSentencia = strSentencia + ' and  com_codigo=' + '\'01\'';

    this.encfacproService.getEncordcompra(strEncOrdComNumero).subscribe(data => {
      for (const rs of data) {
        strComprobante = rs.ENCORDCOM_REFERENCIA;
        if (rs.ENCORDCOM_IVA === 0) {// Exento
          for (const k of this.cmbivaaux) {
            if (k.codigo === '0') {
              this.cmbiva = [{ codigo: k.codigo, name: k.name }];
              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA = k.codigo;
              this.encfacproService.strTipoIvaarray[this.indicador] = k.name;
              this.selecttipoiva = { codigo: k.codigo, name: k.name }//CDPJ
            }
          }
        }
        if (rs.ENCORDCOM_IVA === 1) {// Neto + Iva
          for (const k of this.cmbivaaux) {
            if (k.codigo === '1') {
              this.cmbiva = [{ codigo: k.codigo, name: k.name }];
              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA = k.codigo;
              this.encfacproService.strTipoIvaarray[this.indicador] = k.name;
              this.selecttipoiva = { codigo: k.codigo, name: k.name }//CDPJ
            }
          }
        }
        if (rs.ENCORDCOM_IVA === 2) { // Incluido iva
          for (const k of this.cmbivaaux) {
            if (k.codigo === '2') {
              this.cmbiva = [{ codigo: k.codigo, name: k.name }];
              this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA = k.codigo;
              this.encfacproService.strTipoIvaarray[this.indicador] = k.name;
              this.selecttipoiva = { codigo: k.codigo, name: k.name }//CDPJ
            }
          }
        }
        // Porcentaje de descuento
        dblPorcentajeDesc = rs.ENCORDCOM_PORCEDES;
        // Valor de descuento general
        dblValorDesc = rs.ENCORDCOM_VALORDES;

        this.valorDscValor = dblValorDesc; // Descuento por valor
        this.valorDscPorcentaje = dblPorcentajeDesc; // Descuento por porcentaje

        // Tipo de descuento
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES = (rs.ENCORDCOM_TIPODES === null ||
          rs.ENCORDCOM_TIPODES === undefined) ? '' : rs.ENCORDCOM_TIPODES;
        // this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES = this.valorDscValor;
        // this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES = this.valorDscPorcentaje;
      }

      // Despliega el detalle de la orden de compra

      strSentencia = 'SELECT A.*,B.UNI_SIMBOLO,C.ART_UNIDADCOSTEO,D.ENCORDCOM_PORCEIVA,C.ART_VALORICE, ';
      strSentencia = strSentencia
        + ' com_fnc_cantidad_factura_oc(a.encordcom_numero,a.com_codigo,a.detordcom_linea) as CANTIDADFACTURADAORDCOM, ';
      strSentencia = strSentencia
        + ' com_fnc_Cantutilizada_OC_item(a.encordcom_numero,a.com_codigo,a.detordcom_linea) as CANTIDADUTILIZADAORDCOMITEM ';
      strSentencia = strSentencia
        + ' from com_detordcom a, inv_maeunidad b, inv_maearticulo c,com_encordcom d';
      strSentencia = strSentencia
        + ' where a.encordcom_numero=d.encordcom_numero and a.com_codigo=d.com_codigo ';
      strSentencia = strSentencia
        + ' and b.uni_codigo(+)=a.detordcom_unidad and b.com_codigo(+)=a.com_codigo and ';
      strSentencia = strSentencia
        + ' c.art_codigo(+)=a.detordcom_codigo  and c.com_codigo(+)=a.com_codigo ';
      strSentencia = strSentencia + ' and a.encordcom_numero= ' + '\''
        + strEncOrdComNumero + '\'';
      strSentencia = strSentencia + ' and a.com_codigo=' + '\'01\'';

      let dblPorIva = 0;

      this.encfacproService.getDetordcompra(strEncOrdComNumero).subscribe(datadet => {
        const lista = this.encfacproService.detfacproarray[this.indicador];
        if (intFilaSel > 0) {
          item = this.encfacproService.detfacproarray[this.indicador][intFilaSel - 1];
          strCodArticulo = item.DETFACPRO_CODIGO;
          strTipo = item.DETFACPRO_TIPODET;
          strDescripcion = item.DETFACPRO_DESCRIPCION;
        }

        if (intFilaSel === -1) {
          intFilaSel = 0;
        }

        if (strCodArticulo !== ''
          || intFilaSel === 0
          || (strTipo === '*' && strDescripcion !== '')) {
          this.crearNuevoItem();
          item = this.nuevoItem;
          item.BOD_CODIGO = '';
          item.CEN_CODIGO = '';
          item.COM_CODIGO = '01';
          item.DETFACPRO_BASECERO = 0;
          item.DETFACPRO_BASEICE = 0;
          item.DETFACPRO_BASEIVA = 0;
          item.DETFACPRO_CANTIDAD = 0;
          item.DETFACPRO_CODIGO = '';
          item.DETFACPRO_COSTO = 0;
          item.DETFACPRO_DESCRIPCION = ' ** ORDEN DE COMPRA '
            + strComprobante + ' ** ';
          item.DETFACPRO_ENTREGADO = 0;
          item.DETFACPRO_ESQUEMADOC = '';
          item.DETFACPRO_FACTOR = 0;
          item.DETFACPRO_ICE = 0;
          item.DETFACPRO_IVA = 0;
          item.DETFACPRO_LINEA = 0;
          item.DETFACPRO_PORCEICE = 0;
          item.DETFACPRO_PORDES = 0;
          item.DETFACPRO_PORDES2 = 0;
          item.DETFACPRO_PORDES3 = 0;
          item.DETFACPRO_PORIVA = 0;
          item.DETFACPRO_PRECIOFOB = 0;
          item.DETFACPRO_PROMOCION = '';
          item.DETFACPRO_TIPODET = '*';
          item.DETFACPRO_TOTAL = 0;
          item.DETFACPRO_TOTALINCP = 0;
          item.DETFACPRO_TRIBICE = '';
          item.DETFACPRO_UNIDAD = '';
          item.DETFACPRO_VALDES = 0;
          item.DETNOTREC_LINEA = 0;
          item.DETORDCOM_LINEA = 0;
          item.ENCFACPRO_NUMERO = '';
          item.ENCNOTREC_NUMERO = '';
          item.ENCORDCOM_NUMERO = '';
          item.DETFACPRO_TIPOSRI = '';
          item.TARIIVA_CODIGO = '-1';

          lista.push(item);
        }

        let dblCantidad = 0;
        let dblDespacho = 0;
        let dblsaldo = 0;

        for (const rs of datadet) {
          dblPorIva = rs.ENCORDCOM_PORCEIVA;
          intFilaSel = intFilaSel + 1;
          this.crearNuevoItem();
          item = this.nuevoItem;

          dblsaldo = 0;
          dblCantidad = 0;
          dblDespacho = 0;

          const intSALCM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'SALCM').CFG_VALOR1;
          if (intSALCM === 1) {// si esta trabajando con
            // requisiciones (valida
            // cantidades en A y valida
            // total en S y C)

            if (rs.DETORDCOM_TIPODET === 'A') {
              dblCantidad = rs.DETORDCOM_CANTIDAD;
            } else {
              dblCantidad = rs.DETORDCOM_TOTAL;
            }

            dblDespacho = rs.CANTIDADFACTURADAORDCOM;
            dblsaldo = dblCantidad - dblDespacho;

            if (rs.DETORDCOM_TIPODET === 'A') {
              dblsaldo = Number(dblsaldo.toFixed(this.encfacproService.decimalesCANTIDADfac));
            } else {
              dblsaldo = Number(dblsaldo.toFixed(this.encfacproService.decimalesTOTALDetFacPro));
            }
          } else { // solo valida cantidades

            dblCantidad = rs.DETORDCOM_CANTIDAD;
            dblDespacho = rs.CANTIDADUTILIZADAORDCOMITEM;

            dblsaldo = dblCantidad - dblDespacho;

            dblsaldo = Number(dblsaldo.toFixed(this.encfacproService.decimalesCANTIDADfac));

          }


          if (intSW === 0) {
            this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO = rs.BOD_CODIGO == null ? '' : rs.BOD_CODIGO;
            intSW = 1;
          }

          if (dblsaldo >= 0) {//GSRF

            item.BOD_CODIGO = rs.BOD_CODIGO === null ? '' : rs.BOD_CODIGO;
            item.COM_CODIGO = '01';
            item.DETFACPRO_BASECERO = rs.DETORDCOM_BASECERO;
            item.DETFACPRO_BASEICE = rs.DETORDCOM_BASEICE;
            item.DETFACPRO_BASEIVA = rs.DETORDCOM_BASEIVA;
            item.DETFACPRO_CODIGO = rs.DETORDCOM_CODIGO;

            if (intSALCM === 1) {// si esta trabajando con
              if (rs.DETORDCOM_TIPODET === 'A') {
                item.DETFACPRO_CANTIDAD = dblsaldo;
              } else {
                item.DETFACPRO_CANTIDAD = 1;
              }

              if (rs.DETORDCOM_TIPODET === 'A') {
                item.DETFACPRO_COSTO = rs.DETORDCOM_COSTO;
              } else {
                item.DETFACPRO_COSTO = dblsaldo;
              }
            } else { // valida solo cantidades
              item.DETFACPRO_CANTIDAD = dblsaldo;
              item.DETFACPRO_COSTO = rs.DETORDCOM_COSTO;
            }

            item.DETFACPRO_DESCRIPCION = rs.DETORDCOM_DESCRIPCION;
            item.DETFACPRO_ICE = rs.DETORDCOM_ICE;
            item.DETFACPRO_IVA = rs.DETORDCOM_IVA;
            item.DETFACPRO_LINEA = 0;
            item.DETFACPRO_PORCEICE = rs.DETORDCOM_PORCEICE;
            item.DETFACPRO_PORDES = rs.DETORDCOM_DESCUENTO;

            if ((rs.DETORDCOM_TRIBIVA === null ? '' : rs.DETORDCOM_TRIBIVA) === 'S') {
              item.DETFACPRO_PORIVA = rs.DETORDCOM_PORIVA;
            } else {
              item.DETFACPRO_PORIVA = 0;
            }

            item.DETFACPRO_TIPODET = rs.DETORDCOM_TIPODET === null ? '' : rs.DETORDCOM_TIPODET;
            item.DETFACPRO_TOTAL = rs.DETORDCOM_TOTAL;
            item.DETFACPRO_TOTALINCP = rs.DETORDCOM_TOTAL;
            item.DETFACPRO_TRIBICE = rs.DETORDCOM_TRIBICE === null ? '' : rs.DETORDCOM_TRIBICE;
            item.DETFACPRO_UNIDAD = rs.DETORDCOM_UNIDAD === null ? '' : rs.DETORDCOM_UNIDAD;

            item.DETFACPRO_VALDES = 0;
            item.DETNOTREC_LINEA = 0;
            item.DETORDCOM_LINEA = rs.DETORDCOM_LINEA;
            // item.UNIDADCOSTEO = rs.ART_UNIDADCOSTEO === null ? '' : rs.ART_UNIDADCOSTEO;

            item.DETFACPRO_PORDES2 = rs.DETORDCOM_DESCUENTO2;
            item.DETFACPRO_PORDES3 = rs.DETORDCOM_DESCUENTO3;
            item.DETFACPRO_FACTOR = 0;
            item.DETFACPRO_PROMOCION = '';
            item.DETFACPRO_ESQUEMADOC = '';
            item.CEN_CODIGO = '';

            item.ENCFACPRO_NUMERO = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
            item.ENCNOTREC_NUMERO = '';
            item.ENCORDCOM_NUMERO = rs.ENCORDCOM_NUMERO === null ? '' : rs.ENCORDCOM_NUMERO;
            item.DETFACPRO_TIPOSRI = '';
            item.DETFACPRO_TRIBASENOOBJIVA = rs.TARIIVA_CODIGO === '6' ? 'S' : '';
            item.TARIIVA_CODIGO = rs.TARIIVA_CODIGO
            lista.push(item);

          }
        }

        this.encfacproService.detfacproarray[this.indicador] = lista;
        this.encfacproService.calcularTotales();
        if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES === 'V') {
          this.encfacproService.strTipoDescuentoarray[this.indicador] = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES;//CDPJ
          this.descuentoAplicado('valor');
        }
        if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES === 'P') {
          this.encfacproService.strTipoDescuentoarray[this.indicador] = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES;//CDPJ

          this.descuentoAplicado('porcentaje');
        }

        this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);
      });
    });

  }

  async LlenarcamposNotaRecepcion(strEncNotRecNumero) {
    let strSentencia = '';
    let strComprobante = '';
    let intFilaSel = this.encfacproService.detfacproarray[this.indicador].findIndex(
      dtf => dtf.CLAVE === this.detfacproSelected.CLAVE);
    let item: ComDetfacpro = null;
    let strCodArticulo = '';
    let strTipo = '';
    let strDescripcion = '';
    let intSW = 0;
    let dblCantidadArtDev = 0;
    let dblCantidadXFacturar = 0;
    let dblCantidadArtFac = 0;
    let dblPorcentajeDesc = 0;
    let dblValorDesc = 0;

    strSentencia = 'select * from com_encnotrec where encnotrec_numero='
      + '\'' + strEncNotRecNumero + '\'';
    strSentencia = strSentencia + ' and  com_codigo=' + '\'01\'';

    const data = await this.encfacproService.getEncnotrecProm(strEncNotRecNumero);
    for (const rs of data) {
      strComprobante = rs.ENCNOTREC_REFERENCIA;
      if (rs.ENCNOTREC_IVA === 0) {// Exento
        for (const k of this.cmbivaaux) {
          if (k.codigo === '0') {
            this.cmbiva = [{ codigo: k.codigo, name: k.name }];
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA = k.codigo;
            this.encfacproService.strTipoIvaarray[this.indicador] = k.name;
            this.selecttipoiva = { codigo: k.codigo, name: k.name }//CDPJ
          }
        }
      }
      if (rs.ENCNOTREC_IVA === 1) {// Neto + Iva
        for (const k of this.cmbivaaux) {
          if (k.codigo === '1') {
            this.cmbiva = [{ codigo: k.codigo, name: k.name }];
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA = k.codigo;
            this.encfacproService.strTipoIvaarray[this.indicador] = k.name;
            this.selecttipoiva = { codigo: k.codigo, name: k.name }//CDPJ
          }
        }
      }
      if (rs.ENCNOTREC_IVA === 2) { // Incluido iva
        for (const k of this.cmbivaaux) {
          if (k.codigo === '2') {
            this.cmbiva = [{ codigo: k.codigo, name: k.name }];
            this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_IVA = k.codigo;
            this.encfacproService.strTipoIvaarray[this.indicador] = k.name;
            this.selecttipoiva = { codigo: k.codigo, name: k.name }//CDPJ
          }
        }
      }
      // Porcentaje de descuento
      dblPorcentajeDesc = rs.ENCNOTREC_PORCEDES;
      // Valor de descuento general
      dblValorDesc = rs.ENCNOTREC_VALORDES;

      this.valorDscValor = Number(dblValorDesc); // Descuento por valor
      this.valorDscPorcentaje = Number(dblPorcentajeDesc); // Descuento por porcentaje

      // Tipo de descuento
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES = (rs.ENCNOTREC_TIPODES === null ||
        rs.ENCNOTREC_TIPODES === undefined) ? '' : rs.ENCNOTREC_TIPODES;

      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORDES = this.valorDscValor;
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PORCEDES = this.valorDscPorcentaje;
    }

    // Despliega el detalle de la orden de compra

    strSentencia = 'select a.*,b.uni_simbolo,c.art_unidadcosteo,d.encnotrec_porceiva,c.art_valorice,' +
      'c.art_tributaiva from com_detnotrec a ,inv_maeunidad b,inv_maearticulo c,com_encnotrec d';
    strSentencia = strSentencia
      + ' where a.encnotrec_numero=d.encnotrec_numero and a.com_codigo=d.com_codigo ';
    strSentencia = strSentencia
      + ' and b.uni_codigo(+)=a.detnotrec_unidad and b.com_codigo(+)=a.com_codigo and ';
    strSentencia = strSentencia
      + ' c.art_codigo(+)=a.detnotrec_codigo  and c.com_codigo(+)=a.com_codigo ';
    strSentencia = strSentencia + ' and a.encnotrec_numero= ' + '\''
      + strEncNotRecNumero + '\'';
    strSentencia = strSentencia + ' and a.com_codigo=' + '\'01\'';


    let dblPorIva = 0;

    const datadet = await this.encfacproService.getDetOrdenCompra2Prom(strEncNotRecNumero);
    const lista = this.encfacproService.detfacproarray[this.indicador];
    if (intFilaSel > 0) {
      item = this.encfacproService.detfacproarray[this.indicador][intFilaSel - 1];
      strCodArticulo = item.DETFACPRO_CODIGO;
      strTipo = item.DETFACPRO_TIPODET;
      strDescripcion = item.DETFACPRO_DESCRIPCION;
    }

    if (intFilaSel === -1) {
      intFilaSel = 0;
    }

    if (strCodArticulo !== ''
      || intFilaSel === 0
      || (strTipo === '*' && strDescripcion !== '')) {
      this.crearNuevoItem();
      item = this.nuevoItem;
      item.BOD_CODIGO = '';
      item.CEN_CODIGO = '';
      item.COM_CODIGO = '01';
      item.DETFACPRO_BASECERO = 0;
      item.DETFACPRO_BASEICE = 0;
      item.DETFACPRO_BASEIVA = 0;
      item.DETFACPRO_CANTIDAD = 0;
      item.DETFACPRO_CODIGO = '';
      item.DETFACPRO_COSTO = 0;
      item.DETFACPRO_DESCRIPCION = ' ** NOTA DE RECEPCION '
        + strComprobante + ' ** ';
      item.DETFACPRO_ENTREGADO = 0;
      item.DETFACPRO_ESQUEMADOC = '';
      item.DETFACPRO_FACTOR = 0;
      item.DETFACPRO_ICE = 0;
      item.DETFACPRO_IVA = 0;
      item.DETFACPRO_LINEA = 0;
      item.DETFACPRO_PORCEICE = 0;
      item.DETFACPRO_PORDES = 0;
      item.DETFACPRO_PORDES2 = 0;
      item.DETFACPRO_PORDES3 = 0;
      item.DETFACPRO_PORIVA = 0;
      item.DETFACPRO_PRECIOFOB = 0;
      item.DETFACPRO_PROMOCION = '';
      item.DETFACPRO_TIPODET = '*';
      item.DETFACPRO_TOTAL = 0;
      item.DETFACPRO_TOTALINCP = 0;
      item.DETFACPRO_TRIBICE = '';
      item.DETFACPRO_UNIDAD = '';
      item.DETFACPRO_VALDES = 0;
      item.DETNOTREC_LINEA = 0;
      item.DETORDCOM_LINEA = 0;
      item.ENCFACPRO_NUMERO = '';
      item.ENCNOTREC_NUMERO = '';
      item.ENCORDCOM_NUMERO = '';
      item.DETFACPRO_TIPOSRI = '';
      item.TARIIVA_CODIGO = '-1';

      lista.push(item);
    }

    for (const rs of datadet) {
      dblCantidadArtDev = 0;
      dblCantidadXFacturar = 0;
      dblCantidadArtFac = 0;
      dblPorIva = rs.ENCNOTREC_PORCEIVA;
      intFilaSel = intFilaSel + 1;
      if (intSW === 0) {
        this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO = rs.BOD_CODIGO == null ? '' : rs.BOD_CODIGO;
        intSW = 1;
      }

      strSentencia = 'select b.detdev_cantidad from com_encdev a,com_detdev b';
      strSentencia = strSentencia
        + ' where a.encdev_numero=b.encdev_numero and a.com_codigo=b.com_codigo';
      strSentencia = strSentencia + ' and b.detdev_numero=' + '\''
        + strEncNotRecNumero + '\'';
      strSentencia = strSentencia + ' and b.detdev_codigo=' + '\''
        + rs.DETNOTREC_CODIGO + '\'';
      strSentencia = strSentencia
        + ' and a.encdev_estado<>\'A\' and a.com_codigo=' + '\'01\'';

      const datdev = await this.encfacproService.getDetdevCantidadProm(strEncNotRecNumero, rs.DETNOTREC_CODIGO);
      this.crearNuevoItem();
      item = this.nuevoItem;

      for (const rs1 of datdev) {
        dblCantidadArtDev = rs1.DETDEV_CANTIDAD;
      }

      // Cantidad de productos facturados
      dblCantidadArtFac = await this.cantidadFacturadaProductos(strEncNotRecNumero, rs.DETNOTREC_CODIGO);

      // Cantidad por facturar
      dblCantidadXFacturar = (Number(rs.DETNOTREC_CANTIDAD) - dblCantidadArtDev)
        - dblCantidadArtFac;

      if (dblCantidadXFacturar !== 0
        || rs.DETNOTREC_TIPODET === '*') {

        item.BOD_CODIGO = rs.BOD_CODIGO === null ? '' : rs.BOD_CODIGO;
        item.COM_CODIGO = '01';
        item.DETFACPRO_BASECERO = rs.DETNOTREC_BASECERO;
        item.DETFACPRO_BASEICE = rs.DETNOTREC_BASEICE;
        item.DETFACPRO_BASEIVA = rs.DETNOTREC_BASEIVA;
        item.DETFACPRO_CODIGO = rs.DETNOTREC_CODIGO;
        item.DETFACPRO_CANTIDAD = dblCantidadXFacturar;
        item.DETFACPRO_COSTO = rs.DETNOTREC_COSTO;

        item.DETFACPRO_DESCRIPCION = rs.DETNOTREC_DESCRIPCION;
        item.DETFACPRO_ICE = rs.DETNOTREC_ICE;
        item.DETFACPRO_IVA = rs.DETNOTREC_IVA;
        item.DETFACPRO_LINEA = 0;
        item.DETFACPRO_PORCEICE = rs.DETNOTREC_PORCEICE;
        item.DETFACPRO_PORDES = rs.DETNOTREC_DESCUENTO;

        if ((rs.DETNOTREC_TRIBIVA === null ? '' : rs.DETNOTREC_TRIBIVA) === 'S') {
          item.DETFACPRO_PORIVA = rs.DETNOTREC_PORIVA;
        } else {
          item.DETFACPRO_PORIVA = 0;
        }

        item.DETFACPRO_TIPODET = rs.DETNOTREC_TIPODET === null ? '' : rs.DETNOTREC_TIPODET;
        item.DETFACPRO_TOTAL = rs.DETNOTREC_TOTAL;
        item.DETFACPRO_TOTALINCP = rs.DETNOTREC_TOTAL;
        item.DETFACPRO_TRIBICE = rs.DETNOTREC_TRIBICE === null ? '' : rs.DETNOTREC_TRIBICE;
        item.DETFACPRO_UNIDAD = rs.DETNOTREC_UNIDAD === null ? '' : rs.DETNOTREC_UNIDAD;

        item.DETFACPRO_VALDES = 0;
        item.DETORDCOM_LINEA = 0;
        item.DETNOTREC_LINEA = rs.DETNOTREC_LINEA;
        // item.UNIDADCOSTEO = rs.ART_UNIDADCOSTEO === null ? '' : rs.ART_UNIDADCOSTEO;

        item.DETFACPRO_PORDES2 = rs.DETNOTREC_DESCUENTO2;
        item.DETFACPRO_PORDES3 = rs.DETNOTREC_DESCUENTO3;
        item.DETFACPRO_FACTOR = 0;
        item.DETFACPRO_PROMOCION = '';
        item.DETFACPRO_ESQUEMADOC = '';
        item.CEN_CODIGO = rs.CEN_CODIGO === null || rs.CEN_CODIGO === undefined ? '' : rs.CEN_CODIGO;

        item.ENCFACPRO_NUMERO = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
        item.ENCNOTREC_NUMERO = rs.ENCNOTREC_NUMERO === null ? '' : rs.ENCNOTREC_NUMERO;
        item.ENCORDCOM_NUMERO = '';
        item.DETFACPRO_TIPOSRI = '';
        item.DETFACPRO_TRIBASENOOBJIVA = rs.TARIIVA_CODIGO === '6' ? 'S' : '';
        item.DETFACPRO_AJUSTE = rs.DETNOTREC_AJUSTE === null || rs.DETNOTREC_AJUSTE === undefined ? '' : rs.DETNOTREC_AJUSTE;//GSRF
        item.TARIIVA_CODIGO = rs.TARIIVA_CODIGO;
        item.CEN_CODIGO = rs.CEN_CODIGO === null || rs.CEN_CODIGO === undefined ? '' : rs.CEN_CODIGO
        lista.push(item);

      }
    }

    this.encfacproService.detfacproarray[this.indicador] = lista;
    this.encfacproService.calcularTotales();
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES === 'V') {
      this.encfacproService.strTipoDescuentoarray[this.indicador] = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES;//CDPJ
      this.descuentoAplicado('valor');
    }
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES === 'P') {
      this.encfacproService.strTipoDescuentoarray[this.indicador] = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPODES;//CDPJ

      this.descuentoAplicado('porcentaje');
    }

    this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);
  }


  async cantidadFacturadaProductos(strCodNotRec, strCodArticulo) {
    let dblCantidadArtFac = 0;

    let strSentencia = 'select sum(detfacpro_cantidad) as SUMA from com_encfacpro a,com_detfacpro b';
    strSentencia = strSentencia + ' where a.encfacpro_numero=b.encfacpro_numero ';
    strSentencia = strSentencia + ' and a.com_codigo=b.com_codigo ';
    strSentencia = strSentencia + ' and a.encfacpro_estado<>\'A\' and b.encnotrec_numero=' + '\'' + strCodNotRec + '\'';
    strSentencia = strSentencia + ' and a.com_codigo=\'01\'';
    strSentencia = strSentencia + ' and b.detfacpro_codigo=\'' + strCodArticulo + '\'';

    const data = await this.encfacproService.getSumaCantidadFactProdProm(strCodNotRec, strCodArticulo);
    for (const rs of data) {
      dblCantidadArtFac = rs.SUMA;
    }

    return dblCantidadArtFac;
  }

  distribuirCostos() {
    this.displayDialogDistribucion = true;
  }

  PagoDocumento() {
    this.auxiliarpag++;
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO === 'Crédito' &&
      this.encfacproService.encfacproarray[this.indicador].ASI_NRO !== '') {
      return;
    }

    this.VerificarIngresoRetenciones('PagoDocumento', '');

  }

  PagoDocumento2() {

    /*
    if(!ValidarSeriales(lblNumFac.getText())){
      return;
    }*/

    let dblSaldoFactura = 0;

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO === '') {

      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Primero guarde la factura.'
      });
      return;
    }
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {

      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'La factura esta anulada.'
      });
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO !== 'Contado') {
      /*if (UtilidadesCompras.existenciaPagosFac(lblNumFac.getText(), txtCodProveedor.getText())!=0)
      {
        MessageDialog.openInformation(shell, "Información","No puede realizar el pago directo, ya que existen pagos desde otro origen");
        return;
      }*/
    }

    // Desplegar pantalla si es al contado
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FORMAPAGO !== 'Contado') {
      if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA') {
        // Si esta en estado pagagos y tiene el tipo de pago esta en blanco
        return;                                   // entonces no esta muy bien la información
      }

      this.confirmationService.confirm({
        message: 'Pago al Contado?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencfacpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          this.auxiliar2++;
          this.generarAsiContable();
          this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO
            + '/' + 'ACCIÓN-BOTÓN-PAGO-CONTADO', 'I',
            '', '01', '', '', '', '').subscribe();

          this.displayDialogPagoDocumento = true;
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else {
      return;
    }
  }

  onHidePDEvent(event) {
    this.cerrarPago();
  }

  verificarImportes() {
    let strSentencia = '';
    let dblBaseCero = 0;
    let dblBaseIva = 0;
    let dblMontoIce = 0;
    let dblMontoIva = 0;

    strSentencia = 'select round(sum(trnree_basecero),2) as trnree_basecero,round(sum(trnree_baseiva),2) as trnree_baseiva,' +
      'round(sum(trnree_montoice),2) as trnree_montoice,round(sum(trnree_montoiva),2) as trnree_montoiva ' +
      'from COM_TRNREEMBOLSOS where encfacpro_numero='
      + '\'' + this.strNumFact + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

    this.encfacproService.getImportesReembolso(this.strNumFact).subscribe(data => {

      for (const rs of data) {
        if (rs.TRNREE_BASECERO === null) {
          return;
        }
      }
      //GSRF
      for (const rs of data) {
        dblBaseCero = rs.TRNREE_BASECERO.toFixed(this.confIniciales.getNumDecSist());
        dblBaseIva = rs.TRNREE_BASEIVA.toFixed(this.confIniciales.getNumDecSist());
        dblMontoIce = rs.TRNREE_MONTOICE.toFixed(this.confIniciales.getNumDecSist());
        dblMontoIva = rs.TRNREE_MONTOIVA.toFixed(this.confIniciales.getNumDecSist());
      }
      console.log('///******////', Number(dblBaseCero))
      console.log('///******////', Number(dblBaseIva))
      console.log('///******////', Number(dblMontoIce))
      console.log('///******////', Number(dblMontoIva))
      console.log('///******////', Number(Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO)).toFixed(this.confIniciales.getNumDecSist())))
      console.log('///******////', Number(Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA)).toFixed(this.confIniciales.getNumDecSist())))
      console.log('///******////', Number(Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORICE)).toFixed(this.confIniciales.getNumDecSist())))
      console.log('///******////', Number(Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA)).toFixed(this.confIniciales.getNumDecSist())))

      if (Number(Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA)).toFixed(this.confIniciales.getNumDecSist())) !== Number(dblBaseIva)) {
        this.displayDialogReembolsos = true;
        this.messageService.add({
          key: 'reemb',
          severity: 'warn',
          summary: 'Información',
          detail: 'La base iva de la factura del proveedor no es igual al total de la base iva de los reembolsos'
        });
      }

      if (Number(Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO)).toFixed(this.confIniciales.getNumDecSist())) !== Number(dblBaseCero)) {
        this.displayDialogReembolsos = true;
        this.messageService.add({
          key: 'reemb',
          severity: 'warn',
          summary: 'Información',
          detail: 'La base cero de la factura del proveedor no es igual al total de la base cero de los reembolsos'
        });
      }

      if (Number(Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORICE)).toFixed(this.confIniciales.getNumDecSist())) !== Number(dblMontoIce)) {
        this.displayDialogReembolsos = true;
        this.messageService.add({
          key: 'reemb',
          severity: 'warn',
          summary: 'Información',
          detail: 'El monto ice de la factura del proveedor no es igual al total del monto ice de los reembolsos'
        });
      }

      if (Number(Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA)).toFixed(this.confIniciales.getNumDecSist())) !== Number(dblMontoIva)) {
        this.displayDialogReembolsos = true;
        this.messageService.add({
          key: 'reemb',
          severity: 'warn',
          summary: 'Información',
          detail: 'El monto iva de la factura del proveedor no es igual al total del monto iva de los reembolsos'
        });
      }
      //GSRF
    });

  }

  cerrarPago() {
    this.saldoFacturaProv(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
    this.displayDialogPagoDocumento = false;
  }

  cerrarActPrecios() {
    this.displayDialogActualizacionPrecios = false;
  }

  cerrarReembolsos() {
    this.displayDialogReembolsos = false;
  }

  async saldoFacturaProv(strCodFacpro) {
    let dblSaldo = 0;


    let strSentencia = 'select nvl(sum(trncobro_importe),0) AS SALDO';
    strSentencia = strSentencia + ' from cxp_trnresumen ';
    strSentencia = strSentencia + ' where trncobro_referencia=' + '\'' + strCodFacpro + '\'';
    strSentencia = strSentencia + ' and nvl(trncobro_estado,0)=0 ';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

    const eC = await this.encfacproService.getsaldofactprov(strCodFacpro).toPromise()//.subscribe(eC => {
    for (const rs of eC) {
      dblSaldo = rs.SALDO;
    }

    if (dblSaldo <= 0) {
      await this.actualizarTipoPagoFacProv(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, 'T');
    } else {
      await this.actualizarTipoPagoFacProv(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, '');
    }

    await this.encontrarFactura(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
    //});
  }

  seleccionCargos(params) {
    if (params === 'nuevo') {
      this.nuevodetalle();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalle();
      return;
    }

    if (params.object !== undefined) {
      this.cargoSelected = params.object;
    }


  }

  actualizarPreciosFP() {
    this.auxiliarpre++;
    this.strNumFact = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
    this.displayDialogActualizacionPrecios = true;
  }


  desglosarElValorIva() {
    const intFilaSel = this.encfacproService.detfacproarray[this.indicador].findIndex(
      dtf => dtf.CLAVE === this.detfacproSelected.CLAVE);

    const item = this.encfacproService.detfacproarray[this.indicador][intFilaSel];

    item.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO) / 1.12;
    this.encfacproService.calcularTotales();
    this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);

    this.closeSidebar();
  }

  desplegarRetencionesSegunProv() {
    this.closeSidebar();

    const strProCodigo = this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO;
    // if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO !== '') {
    //   return;
    // }
    //CDPJ
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO !== 'PENDIENTE') {
      return;
    }
    //CDPJ
    if (this.index === 1) { // retenciones

      if (this.encfacproService.trnretencionarray[this.indicador].length <= 0) {
        //CDPJ
        if (this.encfacproService.trnretencionarray[this.indicador].length === 0) {
          if (this.cmbserie.length === 0) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'warn',
              summary: 'Confirmación',
              detail: 'Seleccione la serie por favor.'
            });
            return;
          }
        }
        //CDPJ
        let strSentencia = '';

        let strFechaEmision = '';
        let strRetencionTipo = '';
        let strCuenta = '';
        let intFila = 0;
        let dblNumReg = 0;
        const lstSaciTrnRetencion = [];

        this.encfacproService.erConCodigoRet332(strProCodigo).subscribe(er => {
          if (er !== null) {
            if (er[0] !== undefined) {
              strCuenta = er[0].CON_CODIGO_RET332;
            }
          }

          let intIndiceLista = 0;
          let item: ComDetfacpro = {};
          let dblValor = 0;
          for (intIndiceLista = 0; intIndiceLista < this.encfacproService.detfacproarray[this.indicador].length; intIndiceLista++) {
            item = this.encfacproService.detfacproarray[this.indicador][intIndiceLista];
            if (item.DETFACPRO_CODIGO === strCuenta && item.DETFACPRO_TIPODET === 'C') {
              dblValor = item.DETFACPRO_TOTAL;
            }
          }

          strSentencia = strSentencia + '  a.retencion_codigo=b.retencion_codigo and pro_codigo=' + '\'' + strProCodigo + '\'';
          strSentencia = strSentencia + ' and a.com_codigo=' + '\'01\'';
          strSentencia = strSentencia + ' and a.retencion_codigo in ' + '(\'' + '332' + '\',' + '\'' + '334' + '\'' + ')';
          strSentencia = strSentencia + ' order by a.retencion_codigo asc ';

          this.encfacproService.erCountMaeretprov1(strProCodigo).subscribe(er2 => {
            if (er2 !== null) {
              if (er2[0] !== undefined) {
                dblNumReg = er2[0].COUNT;
              }
            }

            if (dblNumReg === 0) {
              dblValor = 0;
            }

            strSentencia = 'select pro_codigo, b.* from COM_MAERETPROV a,saci_maeretencion b';
            strSentencia = strSentencia + ' where a.retencion_codigo=b.retencion_codigo and pro_codigo=' + '\'' + strProCodigo + '\'';
            strSentencia = strSentencia + ' and a.com_codigo=' + '\'01\'';
            strSentencia = strSentencia + ' order by a.retencion_codigo asc ';

            this.encfacproService.getprocodigoretpro(strProCodigo).subscribe(data => {
              for (const rs of data) {
                const itemret: SaciTrnretencion = {};

                itemret.CEN_CODIGO = '';
                itemret.CLI_CODIGO = '';
                itemret.CODIGO_AUXILIAR = '';
                itemret.COM_CODIGO = '01';
                itemret.CON_CODIGO = rs.CON_CODIGO === null ? '' : rs.CON_CODIGO;
                itemret.ENCFAC_NUMERO = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;//CDPJ
                itemret.PRO_CODIGO = rs.PRO_CODIGO === null ? '' : rs.PRO_CODIGO;
                itemret.RETENCION_CODIGO = rs.RETENCION_CODIGO === null ? '' : rs.RETENCION_CODIGO;
                itemret.RETENCION_TIPRET = rs.RETENCION_TIPRET === null ? '' : rs.RETENCION_TIPRET;
                itemret.TRNCOBRO_NRODOC = '';
                const intAUTRETBOD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'AUTRETBOD').CFG_VALOR1;
                if (intAUTRETBOD === 0) {
                  // item.TRNRETENCION_AUTORIZACION = (frmComEncFacPro.strNumAutorizaCompania);
                  //CDPJ
                  itemret.TRNRETENCION_SERIE = this.encfacproService.encretarray[this.indicador].TRNRETENCION_SERIE;
                  itemret.TRNRETENCION_NRO = this.encfacproService.encretarray[this.indicador].TRNRETENCION_NRO;
                  itemret.TRNRETENCION_AUTORIZACION = this.encfacproService.encretarray[this.indicador].TRNRETENCION_AUTORIZACION;
                  //CDPJ
                } else {
                  // item.TRNRETENCION_AUTORIZACION = (frmComEncFacPro.strNumeroAutorizacionBodega);
                }

                // item.TRNRETENCION_SERIE = (frmComEncFacPro.strNumSerieCompania);

                strRetencionTipo = rs.RETENCION_TIPRET == null ? '' : rs.RETENCION_TIPRET;
                if (strRetencionTipo === 'RF') { // RETENCION A LA FUENTE

                  if (itemret.RETENCION_CODIGO === this.strCODRET1
                    || itemret.RETENCION_CODIGO === this.strCODRET2
                    || itemret.RETENCION_CODIGO === this.strCODRET3
                    || itemret.RETENCION_CODIGO === this.strCODRET4
                    || itemret.RETENCION_CODIGO === this.strCODRET5
                    || itemret.RETENCION_CODIGO === this.strCODRET6) {
                    itemret.TRNRETENCION_BASE = dblValor;
                    itemret.TRNRETENCION_BASE0 = dblValor;
                    itemret.TRNRETENCION_BASEG = 0;
                    itemret.TRNRETENCION_BASENG = 0;
                  } else {
                    itemret.TRNRETENCION_BASE = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA)) +
                      Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO)) - dblValor;
                    itemret.TRNRETENCION_BASE0 = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASECERO)) - dblValor;
                    itemret.TRNRETENCION_BASEG = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_BASEIVA));
                    itemret.TRNRETENCION_BASENG = 0;
                  }
                }

                if (strRetencionTipo === '') { // RETENCION IVA
                  itemret.TRNRETENCION_BASE = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_VALORIVA));
                  itemret.TRNRETENCION_BASE0 = 0;
                  itemret.TRNRETENCION_BASEG = 0;
                  itemret.TRNRETENCION_BASENG = 0;
                }

                itemret.TRNRETENCION_DESCRIPCION = rs.RETENCION_NOMBRE == null ? '' : rs.RETENCION_NOMBRE;
                itemret.TRNRETENCION_FLAG = 0;
                //itemret.TRNRETENCION_NRO = '';//comenté CDPJ
                itemret.TRNRETENCION_ORIGEN = 'CXP';
                itemret.TRNRETENCION_PORCENTAJE = rs.RETENCION_PORCENTAJE;

                itemret.TRNRETENCION_TOTALRETENIDO = 0;

                const intRETFECEMI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RETFECEMI').CFG_VALOR1;

                if (intRETFECEMI === 0) {
                  strFechaEmision = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAREC;
                } else {
                  strFechaEmision = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION;
                }

                itemret.CON_CODIGO = rs.CON_CODIGO === null ? '' : rs.CON_CODIGO;
                itemret.TRNRETENCION_FECHAEMISION = this.datePipe.transform(strFechaEmision, 'dd/MM/yyyy');
                itemret.TRNRETENCION_FECHAPAGODIV = '';

                itemret.TRNRETENCION_IMPRENPAGADO = 0;

                itemret.TRNRETENCION_ANIOUTIL = '';


                lstSaciTrnRetencion.push(itemret);
                intFila = intFila + 1;
              }
              this.botonesmodificar();
              this.encfacproService.trnretencionarray[this.indicador] = lstSaciTrnRetencion;
              this.encfacproService.calcularTotalesRetencion();
              this.encfacproService.calcularTotalConRetenciones();//CDPJ
              this.ponerNumeroRetencion332();
            });
          });
        });
      }
    }
  }

  ponerNumeroRetencion332() {

    let strNumRet = '';

    // Selecciona el consecutivo
    let sentencia = 'select  nvl(trim(to_char(max(to_number(trnretencion_nro))+1,\'000000000\')),\'000000001\') ' +
      'from saci_trnretencion';
    sentencia = sentencia + ' where trnretencion_origen=\'COM\' and retencion_codigo in   ( ' + '\'' +
      this.strCODRET1 + '\'' + ', ' + '\'' + this.strCODRET2 + '\'' + ', ' + '\''
      + this.strCODRET3 + '\'' + ', ' + '\'' + this.strCODRET4 + '\'' + ', ' + '\''
      + this.strCODRET5 + '\'' + ', ' + '\'' + this.strCODRET6 + '\')';

    let item: SaciTrnretencion;
    for (let intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];
      if (item.RETENCION_CODIGO === '332') {

        this.encfacproService.getNumeroRetConsec(this.strCODRET1, this.strCODRET2, this.strCODRET3, this.strCODRET4, this.strCODRET5,
          this.strCODRET6).subscribe(data => {
            strNumRet = '000000001';
            for (const rs of data) {
              strNumRet = rs.getString(1);
            }
            item.TRNRETENCION_NRO = strNumRet;
          });
      }
    }
  }

  cambiarTipoIvaABaseNoObjetoDeIva() {
    this.closeSidebar();

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== '') {
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TIPCOM !== '41') {
      return;
    }

    this.confirmationService.confirm({
      message: 'Está seguro de cambiar todos los items a Base No Objeto de Iva?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        let intIndiceLista = 0;
        let item: ComDetfacpro;
        for (intIndiceLista = 0; intIndiceLista < this.encfacproService.detfacproarray[this.indicador].length; intIndiceLista++) {
          item = this.encfacproService.detfacproarray[this.indicador][intIndiceLista];

          if (item.DETFACPRO_TIPODET !== '*') {
            item.DETFACPRO_TRIBASENOOBJIVA = 'S';
            item.DETFACPRO_PORIVA = 0;
          }
        }

        this.encfacproService.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  buscarRegistroSinRet() {
    this.closeSidebar();

    this.VerificarIngresoRetenciones('buscarRegistroSinRet', '');

  }

  buscarRegistroSinRet2() {
    this.opcionbusqueda = 'factura';
    this.types = [
      { label: 'NUMERO', value: 'ENCFACPRO_NUMERO' },
      { label: 'REFERENCIA', value: 'ENCFACPRO_REFERENCIA' },
      { label: 'FEC.EMI', value: 'ENCFACPRO_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCFACPRO_ESTADO' },
      { label: 'NOMBRE_PROV', value: 'PRO_NOMBRE' },
      { label: 'NOMBRE_CORTO', value: 'PRO_NOMBREC' },
      { label: 'PROVEEDOR', value: 'PRO_CODIGO' },
      { label: 'CONTACTO', value: 'ENCFACPRO_CONTACTO' },
      { label: 'TOTAL', value: 'ENCFACPRO_TOTAL' },
      { label: 'TOTALNETO', value: 'ENCFACPRO_TOTALNETO' },
      { label: 'BASEIVA', value: 'ENCFACPRO_BASEIVA' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'COM_ENCFACPRO A';
    this.where = ' and encfacpro_flag=9 and encfacpro_numero not in (select trncobro_nrocomprobante ' +
      'from saci_trnretencion where trnretencion_origen= \'COM\')';
    this.busqService.busquedaComEncFacPro().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.ENCFACPRO_FECHAEMISION !== null) {
            registro.ENCFACPRO_FECHAEMISION = this.datePipe.transform(registro.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
          } else {
            registro.ENCFACPRO_FECHAEMISION = '';
          }
        });
      }
      this.displayDialogBusquedaFast = true;
    });
  }

  activarSerieSecuencial() {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PENDIENTE') {
      this.autorizacionCodigo = 'ACTSSFC';
      this.claveAuth = '';
      this.displayDialogAutorizacion = true;
      setTimeout(() => {
        document.getElementById('clave').focus();
      }, 500);
    }
    this.closeSidebar();
  }

  activarFacAnulada() {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.autorizacionCodigo = 'ACTDOC';
      this.claveAuth = '';
      this.displayDialogAutorizacion = true;
      setTimeout(() => {
        document.getElementById('clave').focus();
      }, 500);
    }
    this.closeSidebar();
  }

  activarBotonAnularRetFirElectro() {
    this.closeSidebar();
    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO !== 'PENDIENTE') {
      return;
    }

    this.autorizacionCodigo = 'ACTRETFI';
    this.auxiliarAutorizacion = '2';
    this.claveAuth = '';
    this.displayDialogAutorizacion = true;
    setTimeout(() => {
      document.getElementById('clave').focus();
    }, 500);
  }

  actualizarDatosElectronicos() {
    this.closeSidebar();

    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }

    let strSql = 'SELECT * FROM COM_ENCFACPRO';
    strSql = strSql + ' WHERE ENCFACPRO_NUMERO = \'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';
    strSql = strSql + ' AND COM_CODIGO = \'01\'';

    this.encfacproService.getDatosElectronicosFact(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(data => {
      for (const rs of data) {
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE = rs.ENCFACPRO_ESTADO_FE === null ? '' : rs.ENCFACPRO_ESTADO_FE;
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_AUTORIZACION_FE = rs.ENCFACPRO_ESTADO_FE === null ? '' : rs.ENCFACPRO_AUTORIZACION_FE;
        this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_CLAVEACCESO_FE = rs.ENCFACPRO_ESTADO_FE === null ? '' : rs.ENCFACPRO_CLAVEACCESO_FE;
      }
    });
  }

  reversarInicioProcesFactElectronica() {
    this.closeSidebar();

    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      return;
    }

    this.actualizarDatosElectronicos();

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== 'AT' &&
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== 'NT' &&
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== 'SN') {

      this.confirmationService.confirm({
        message: 'Está seguro de reversar el proceso?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencfacpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          this.utilitariosService.reversarProcesoFE(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, '07');
          this.utilitariosService.reversarProcesoFEClave(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_CLAVEACCESO_FE);
          this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' + '07' + 'COM_REVERSAR_RET', 'E',
            '', '01', '', '', '', '').subscribe();
          this.actualizarDatosElectronicos();
          // btnGenerarArcTextoRet.setEnabled(true);
          this.messageService.add({
            key: 'encfacpro',
            severity: 'warn',
            summary: 'Información',
            detail: 'Reversar proceso de inicio de firma electrónica terminó satisfactoriamente.'
          });

        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Solo puede reversar el proceso de facturación electrónica ' +
          'si la factura se encuentra en estado (VA)  '
      });
    }
  }

  visualizarLog() {
    this.closeSidebar();
    this.logResultado = '';
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO !== 'ANULADA') {
      this.encfacproService.obtenerLogFE(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO, '07').subscribe((res) => {
        if (res.length !== 0) {
          if (res[0] !== null) {
            this.logResultado = JSON.stringify(res[0].TRNDOC_RESULTADO);
          }
        }
        this.displayDialogLog = true;
      });
    } else {
      return;
    }
  }
  //CDPJ
  async visualizarEst() {
    this.closeSidebar();
    this.estfirma = '';
    const data = await this.encfacproService.obtenerEstFE().toPromise()
    this.displayDialogEst = true;
    if (data !== null && data !== undefined) {
      for (const est of data) {
        this.estfirma = this.estfirma + est.REF_CODIGO + ' ' + est.REF_NOMBRE + '\n'
      }
    }

  }
  //CDPJ
  actualizarCodigosAlternos() {
    this.closeSidebar();
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO !== '') {
      return;
    }

    this.confirmationService.confirm({
      message: 'Está seguro de actualizar los códigos alternos?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        let intIndiceLista = 0;
        let item: ComDetfacpro;
        let strSentencia = '';
        const strCodigoAlt: string[] = [];


        for (intIndiceLista = 0; intIndiceLista < this.encfacproService.detfacproarray[this.indicador].length; intIndiceLista++) {
          item = this.encfacproService.detfacproarray[this.indicador][intIndiceLista];

          if (item.DETFACPRO_TIPODET === 'A'
            && item.DETFACPRO_CODIGOALT !== '') {
            strSentencia = 'select art_codigoalt2, art_codigoalt3,art_codigoalt4 from  inv_maearticulo ';
            strSentencia = strSentencia + ' where art_codigo=' + '\''
              + item.DETFACPRO_CODIGO + '\'';
            strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

            this.encfacproService.getArtCodigosAlt(item.DETFACPRO_CODIGO).subscribe(data => {
              for (const rs of data) {
                strCodigoAlt[0] = rs.ART_CODIGOALT2 === null ? ''
                  : rs.ART_CODIGOALT2;
                strCodigoAlt[1] = rs.ART_CODIGOALT3 === null ? ''
                  : rs.ART_CODIGOALT3;
                strCodigoAlt[2] = rs.ART_CODIGOALT4 === null ? ''
                  : rs.ART_CODIGOALT4;
              }

              strSentencia = '';
              if (strCodigoAlt[0] === '') {
                strSentencia = 'Update inv_maearticulo set  art_codigoalt2='
                  + '\'' + item.DETFACPRO_CODIGOALT + '\'';
                strSentencia = strSentencia + ' where art_codigo=' + '\''
                  + item.DETFACPRO_CODIGO + '\'';
                strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
              } else {
                if (strCodigoAlt[1] === '') {
                  strSentencia = 'Update inv_maearticulo set  art_codigoalt3='
                    + '\'' + item.DETFACPRO_CODIGOALT + '\'';
                  strSentencia = strSentencia + ' where art_codigo='
                    + '\'' + item.DETFACPRO_CODIGO + '\'';
                  strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
                } else if (strCodigoAlt[2] === '') {
                  strSentencia = 'Update inv_maearticulo set  art_codigoalt4='
                    + '\'' + item.DETFACPRO_CODIGOALT + '\'';
                  strSentencia = strSentencia + ' where art_codigo='
                    + '\'' + item.DETFACPRO_CODIGO + '\'';
                  strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
                }
              }


              if (strSentencia !== '') {
                this.encfacproService.actualizarArtCodigosAlt(strCodigoAlt[0], strCodigoAlt[1], strCodigoAlt[2], item.DETFACPRO_CODIGOALT,
                  item.DETFACPRO_CODIGO).subscribe();
              }

              this.messageService.add({
                key: 'encfacpro',
                severity: 'warn',
                summary: 'Información',
                detail: 'Fin del Proceso'
              });
            });
          }
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  insertarEleTrnDocumento(strNumDoc) {

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === 'VA') {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Documento ya fue procesado'
      });
      return;
    }


    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'La factura está anulada'
      });
      return;
    }


    if (strNumDoc.length > 0 && this.botonGuardar === true) {
    } else {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Primero  guarde el documento'
      });
      return;
    }


    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === 'AT' ||
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === 'NT' ||
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Documento no puede se autorizado'
      });
      return;
    }

    const intCLAAUTFE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CLAAUTFE').CFG_VALOR1;
    if (intCLAAUTFE === 1) { // si esta activado la seguridad par firmar electrónicamente

      this.autorizacionCodigo = 'AUTFE';
      this.auxiliarAutorizacion = '2';
      this.claveAuth = '';
      this.displayDialogAutorizacion = true;
      setTimeout(() => {
        document.getElementById('clave').focus();
      }, 500);

    } else {
      this.firmarElectronicamente(strNumDoc);
    }

  }

  firmarElectronicamente(strNumDoc) {
    let strTipoDoc = '';
    let strFechaServidor = '';

    this.confirmationService.confirm({
      message: 'Está seguro de firmar el documento ' + strNumDoc + '?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        strTipoDoc = 'RET';
        const strFecha = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
        this.confirmationService.close();
        this.encfacproService.erFechaSystem().subscribe(er => {
          if (er !== null) {
            if (er[0] !== undefined) {
              strFechaServidor = er[0].FECHA;
            }
          }

          if (this.datosfe.COM_TIPOAMBFACTELEC === '1' ||
            this.datosfe.COM_TIPOAMBFACTELEC === '2') { // Si esta en ambiente producción
            // Si tiene fecha de caducidad de la firma electrónica ingresada
            if (this.datosfe.COM_FECHACADFIRMAELE !== '') {
              if (strFechaServidor > this.datosfe.COM_FECHACADFIRMAELE) {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'Error, No puede firmar ningún documento electrónico porque esta caducada la fecha de validez de la firma elctrónica.'
                });
                return;
              }

              const item: Eletrndocumento = {};
              item.TRNDOC_NUMDOC = strNumDoc;
              item.TRNDOC_TIPO = strTipoDoc;
              item.TRNDOC_ESTADO = 'VA';
              item.TRNDOC_RESULTADO = '';
              item.TRNDOC_UBICAARCH = '';
              item.TRNDOC_SECUENCIAL = '';
              item.COM_CODIGO = '01';
              item.TRNDOC_TIPOAMB_FE = this.datosfe.COM_TIPOAMBFACTELEC;

              this.encfacproService.insertarEleTrnDocumento(item).subscribe(async () => {
                this.messageService.add({
                  key: 'encfacpro',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'Proceso firma electrónica iniciado satisfactoriamente.'
                });
                this.encfacproService.spin = true;
                const data = await this.utilitariosService.procesoFirmaElectronica(strNumDoc, strFecha).catch(e => {
                  this.encfacproService.spin = false;
                });
                if (data !== 'NOAUTORIZADO') {
                  this.messageService.add({
                    key: 'encfacpro',
                    severity: 'warn',
                    summary: 'Información',
                    detail: data
                  });
                  this.encfacproService.spin = false;
                  this.encontrarFactura(strNumDoc);
                } else {
                  this.encfacproService.spin = true;
                  await setTimeout(async () => {

                    await this.actualizarDatosElectronicos();
                    this.encfacproService.spin = false;
                    this.messageService.add({
                      key: 'encfacpro',
                      severity: 'success',
                      summary: 'Información',
                      detail: 'Documento procesado'
                    });
                  }, 6000);

                }


                // this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE = 'VA';
                // this.bolAnular = true;
                // this.actualizarDatosElectronicos();
              });
            } else { // no tiene asignado la fecha de caducidad de la firma electrónica
              this.encfacproService.spin = false;
              return;

            }
          }
        });
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  actualizarEstadoParaReenviarCorreo(strNumDoc) {
    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'La factura está anulada'
      });
      return;
    }

    this.actualizarDatosElectronicos();

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === 'NT') {

      const strSql = 'UPDATE  ELE_TRNDOCUMENTO SET  TRNDOC_ESTADO=\'AT\', TRNDOC_FECHA=SYSDATE WHERE TRNDOC_NUMDOC = \'' + strNumDoc +
        '\' AND COM_CODIGO = \'01\'';

      this.encfacproService.actualizarDatosElect(strNumDoc).subscribe(async () => {
        const data = await this.utilitariosService.reenviarCorreo(strNumDoc);
        this.messageService.add({
          key: 'encfacpro',
          severity: 'success',
          summary: 'Información',
          detail: data
        });
        this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO', strNumDoc + 'RET_REEV_CORREO', 'A',
          '', '01', '', '', '', '').subscribe();
        this.messageService.add({
          key: 'encfacpro',
          severity: 'success',
          summary: 'Información',
          detail: 'Proceso actualizar estado para reenvió de correo  terminó satisfactoriamente.'
        });

      });
    } else {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Solo puede reenviar el correo si la Retención esta notificada.'
      });

    }
  }

  guardarDatosFacElec() {
  }


  guardarDatosRetElec() {
    this.closeSidebar();
    this.displayDialogCargarRetElec = true;
  }


  onBasicUpload(event) {
    const fileReader = new FileReader();
    for (const file of event.files) {
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_LOCALIZACIONXMLRET = file.name;
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_PATHXMLRET = file.name;
      // fileReader.readAsDataURL(file);
      fileReader.readAsText(file);
      fileReader.onload = () => {
        const strTexto = fileReader.result.toString();
        let ini = 0;
        let fin = 0;
        if (strTexto.indexOf('<numeroAutorizacion>') !== -1) {
          ini = strTexto.indexOf('<numeroAutorizacion>') + '<numeroAutorizacion>'.length;
          fin = strTexto.indexOf('</numeroAutorizacion>');
        } else {
          ini = strTexto.indexOf('&lt;numeroAutorizacion&gt;') + '&lt;numeroAutorizacion&gt;'.length;
          fin = strTexto.indexOf('&lt;/numeroAutorizacion&gt;');
        }

        this.numeroAutorizacion = strTexto.substr(ini, fin - ini);
        // localStorage.setItem('imgblob', fileReader.result + '');
      };
    }
  }

  cargarDatosRetencionElec() {
    this.desplegarAutorizacion(this.numeroAutorizacion);
  }

  desplegarAutorizacion(strAutorizacion) {
    let intIndiceLista = 0;
    let item: SaciTrnretencion;
    for (intIndiceLista = 0; intIndiceLista < this.encfacproService.trnretencionarray[this.indicador].length; intIndiceLista++) {
      item = this.encfacproService.trnretencionarray[this.indicador][intIndiceLista];

      if (item.RETENCION_CODIGO !== '') {
        item.TRNRETENCION_AUTORIZACION = strAutorizacion;
      }
    }
    this.aggrid.refreshaggrid(this.encfacproService.trnretencionarray[this.indicador], this.defaultColDefRet.objeto);
    this.displayDialogCargarRetElec = false;
  }


  subirFacturaXML() {

    this.closeSidebar();

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== '') {
      return;
    }

    this.bodcodigoxml = '';
    this.bodnombrexml = '';
    this.idcreditoxml = '';
    this.idcreditonombrexml = '';
    this.centrocostosxml = '';
    this.centrocostosnombrexml = '';
    this.localizacionxml2 = '';
    this.numerobloquearchivosxml = '';
    this.strnumerobloquearchivosxml = '';
    this.filesImportXML = [];
    this.porcentaje = 0;

    this.displayDialogSubirXmlFactura = true;
  }

  dlgIngresarFacturasDesdeXML(formato) {

    this.closeSidebar();

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE !== '') {
      return;
    }

    this.bodcodigoxml = '';
    this.bodnombrexml = '';
    this.idcreditoxml = '';
    this.idcreditonombrexml = '';
    this.centrocostosxml = '';
    this.centrocostosnombrexml = '';
    this.localizacionxml2 = '';
    this.numerobloquearchivosxml = '';
    this.strnumerobloquearchivosxml = '';
    this.subirxml = [];

    if (formato === '1') {
      this.displayDialogSubirXmlFacturaFormato1 = true;
    }

    if (formato === '2') {
      this.displayDialogSubirXmlFacturaFormato2 = true;
    }
  }


  procesar() {

    this.confirmationService.confirm({
      message: 'Está seguro de ejecutar el proceso?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        if (this.bodcodigoxml === '' || this.idcreditoxml === '') {
          this.messageService.add({
            key: 'encfacproimpxml',
            severity: 'warn',
            summary: 'Información',
            detail: 'Por favor ingrese la bodega el Id del crédito tributario, y el Centro de costos'
          });
          return;
        }

        // this.transformarArchivo();
        this.listarDatosXMLSubir();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });

  }

  procesarFormato1(formato) {

    this.confirmationService.confirm({
      message: 'Está seguro de ejecutar el proceso?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        if (this.bodcodigoxml === '' || this.idcreditoxml === '') {
          this.messageService.add({
            key: 'encfacproimpxml',
            severity: 'warn',
            summary: 'Información',
            detail: 'Por favor ingrese la bodega el Id del crédito tributario, y el Centro de costos'
          });
          return;
        }

        if (this.numerobloquearchivosxml !== '') {
          this.encfacproService.erCountEncfacpro1(this.numerobloquearchivosxml).subscribe(er => {
            if (er !== null) {
              if (er[0] !== undefined) {
                if (er[0].COUN === '0') {
                  if (formato === '1') {
                    this.subirLasFacturasDeProveedoresF1();
                  }

                  if (formato === '2') {
                    this.subirLasFacturasDeProveedoresF2();
                  }

                } else {
                  this.messageService.add({
                    key: 'encfacproimpxml',
                    severity: 'warn',
                    summary: 'Información',
                    detail: 'Ya existe por favor genere otro bloque'
                  });
                }
              }
            }
          });
        } else {
          this.messageService.add({
            key: 'encfacproimpxml',
            severity: 'warn',
            summary: 'Información',
            detail: 'Generar primero del número de bloque de archivos'
          });
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });

  }

  transformarArchivo(strTexto: string): string {
    let tmp = '';

    tmp = strTexto.replace('&lt;', '<');
    tmp = tmp.replace('&gt;', '>');

    return tmp;
  }

  listarDatosXMLSubir() {
    let lstarchivosXMLSubir: any = [];
    if (this.localizacionxml2 !== '') {
      let strDatos: string[] = [];
      let item = {}; // new archivosXMLSubir();

      this.transformaAXML(this.localizacionxml2);
      setTimeout(() => {
        this.subirLasFacturasDeProveedores();
      }, 500);

    }
  }

  actualizarCodigosAlternosXml() {
    let strSentencia = '';

    strSentencia = ' delete from inv_trncodaltart';

    this.encfacproService.eliminarTrnCodAltArt().subscribe(data => {

      strSentencia = 'insert into  inv_trncodaltart(art_codigo,art_codigoalterno) ' +
        'select  art_codigo,art_codigoalt1 from inv_maearticulo where not art_codigoalt1 is null';
      this.encfacproService.insertarTrnCodAlt1().subscribe(data2 => {

        strSentencia = 'insert into  inv_trncodaltart(art_codigo,art_codigoalterno) ' +
          'select  art_codigo,art_codigoalt2 from inv_maearticulo where not art_codigoalt2 is null';
        this.encfacproService.insertarTrnCodAlt2().subscribe(data3 => {

          strSentencia = 'insert into  inv_trncodaltart(art_codigo,art_codigoalterno) ' +
            'select  art_codigo,art_codigoalt3 from inv_maearticulo where not art_codigoalt3 is null';
          this.encfacproService.insertarTrnCodAlt3().subscribe(data4 => {


            this.messageService.add({
              key: 'encfacproimpxml',
              severity: 'success',
              summary: 'Información',
              detail: 'Fin del Proceso'
            });
          });
        });
      });
    });
  }

  SeleccionarDirectorio(event) {
    this.fileReader = new FileReader();
    for (const file of event.files) {
      this.localizacionxml2 = file.name;
      // fileReader.readAsDataURL(file);

      this.fileXml = file;

    }
  }

  SeleccionarDirectorioMultiple(event) {

    this.filesarray = [];
    this.localizacionxml2 = '';

    for (const file of event.files) {
      this.localizacionxml2 = this.localizacionxml2 + ' ' + file.name + '; ';
      this.fileReader = new FileReader();
      this.fileReader.readAsText(file);
      this.fileReader.onload = e => {
        this.filesarray.push({ nombre: file.name, archivo: e.target['result'] });
      };

    }
  }

  convertir() {
  }

  eliminarBloqueArchivos() {
    if (this.strnumerobloquearchivosxml === '') {
      this.messageService.add({
        key: 'encfacproimpxml',
        severity: 'warn',
        summary: 'Información',
        detail: 'Por favor ingrese el número de bloque de archivos'
      });
      return;
    }

    this.confirmationService.confirm({
      message: 'Está seguro de eliminar los registros ?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.encfacproService.eliminarFacturasSubidas(this.strnumerobloquearchivosxml).subscribe();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });

  }

  listarArchivos() {

    let strTexto = '';
    let intIni = 0;
    let intFin = 0;


    const subirXmlTmp: SubirXml[] = [];

    for (let i = 0; i < this.filesarray.length; i++) {
      const item: SubirXml = {};
      strTexto = this.filesarray[i].archivo.toString();

      // numeroAutorizacion
      if (strTexto.indexOf('<numeroAutorizacion>') !== -1) {
        intIni = strTexto.indexOf('<numeroAutorizacion>') + '<numeroAutorizacion>'.length;
        intFin = strTexto.indexOf('</numeroAutorizacion>');
      } else {
        intIni = strTexto.indexOf('&lt;numeroAutorizacion&gt;') + '&lt;numeroAutorizacion&gt;'.length;
        intFin = strTexto.indexOf('&lt;/numeroAutorizacion&gt;');
      }

      const numeroAutorizacion = strTexto.substring(intIni, intFin);
      item.AUTORIZACION = numeroAutorizacion;

      // Fecha de emision

      if (strTexto.indexOf('<fechaEmision>') !== -1) {
        intIni = strTexto.indexOf('<fechaEmision>') + '<fechaEmision>'.length;
        intFin = strTexto.indexOf('</fechaEmision>');
      } else {
        intIni = strTexto.indexOf('&lt;fechaEmision&gt;') + '&lt;fechaEmision&gt;'.length;
        intFin = strTexto.indexOf('&lt;/fechaEmision&gt;');
      }
      const fechaEmision = strTexto.substring(intIni, intFin);

      item.FECHA = fechaEmision;
      item.ARCHIVO = this.filesarray[i].nombre;
      item.FACTURA = '';
      item.TEXTO = strTexto;
      subirXmlTmp.push(item);
    }

    this.subirxml = subirXmlTmp;
  }

  transformaAXML(strNombre) {

    let path = strNombre;
    let strTexto = '';
    let strCadena = '';
    let intIni = 0;
    let intFin = 0;

    const strDatos: string[] = [];
    this.fileReader.readAsText(this.fileXml);
    this.fileReader.onload = () => {
      strCadena = this.fileReader.result.toString();
      strTexto = this.fileReader.result.toString();

      intIni = strCadena.indexOf('<comprobante>');
      intFin = strCadena.indexOf('<factura>');


      // numeroAutorizacion
      if (strTexto.indexOf('<numeroAutorizacion>') !== -1) {
        intIni = strTexto.indexOf('<numeroAutorizacion>') + '<numeroAutorizacion>'.length;
        intFin = strTexto.indexOf('</numeroAutorizacion>');
      } else {
        intIni = strTexto.indexOf('&lt;numeroAutorizacion&gt;') + '&lt;numeroAutorizacion&gt;'.length;
        intFin = strTexto.indexOf('&lt;/numeroAutorizacion&gt;');
      }

      const numeroAutorizacion = strTexto.substring(intIni, intFin);

      strDatos[0] = numeroAutorizacion;


      // Fecha de emision

      if (strTexto.indexOf('<fechaEmision>') !== -1) {
        intIni = strTexto.indexOf('<fechaEmision>') + '<fechaEmision>'.length;
        intFin = strTexto.indexOf('</fechaEmision>');
      } else {
        intIni = strTexto.indexOf('&lt;fechaEmision&gt;') + '&lt;fechaEmision&gt;'.length;
        intFin = strTexto.indexOf('&lt;/fechaEmision&gt;');
      }
      const fechaEmision = strTexto.substring(intIni, intFin);
      strDatos[1] = fechaEmision;


      this.strDatosXml = strDatos;

    };

  }

  subirLasFacturasDeProveedores() {
    this.leerDatosXmlRetComprasAut(this.localizacionxml2, this.strDatosXml[0], this.strDatosXml[1]);
  }

  subirLasFacturasDeProveedoresF1() {
    let intIndiceLista = 0;
    let item;

    if (this.subirxml.length === 0) {
      this.listarArchivos();
    }

    for (intIndiceLista = 0; intIndiceLista < this.subirxml.length; intIndiceLista++) {
      item = this.subirxml[intIndiceLista];
      item.TEXTO = this.transformarArchivo(item.TEXTO);

      this.leerDatosXmlRetComprasAutF1(item);
    }

  }


  subirLasFacturasDeProveedoresF2() {
    let intIndiceLista = 0;
    let item;

    if (this.subirxml.length === 0) {
      this.listarArchivos();
    }

    for (intIndiceLista = 0; intIndiceLista < this.subirxml.length; intIndiceLista++) {
      item = this.subirxml[intIndiceLista];
      item.TEXTO = this.transformarArchivo(item.TEXTO);

      this.leerDatosXmlRetComprasAutF2(item);
    }

  }


  async leerDatosXmlRetComprasAut(archivo, strNumeroAutorizacion, strFecha) {

    let cadena = '';
    let strNumFactura = '';
    let strConCodigo = '';

    const reader = new FileReader();
    reader.readAsText(this.fileXml);
    const result = await new Promise((resolve, reject) => {
      reader.onload = function (event) {
        resolve(reader.result.toString());
      };
    });

    cadena = result.toString().trim();

    cadena = cadena.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(
      /&amp;/g, '&').replace(/&apos;/g, '\'').replace(/&quot;/g, '"');

    cadena = cadena.replace('<?xml version="1.0" encoding="UTF-8"?>', '').replace('<![CDATA[', '').replace(']]>', '').
      replace('<?xml version="1.0" encoding="UTF-8" standalone="yes"?>', '')
    cadena = cadena.replace(/<\?xml\s+version=["']1\.0["']\s+encoding=["']utf-8["'](?:\s+standalone=["'](?:yes|no)["'])?\s*\?>/gi, '')

    // replace('<?xml version="1.0" encoding="utf-8"?>', '').replace('<?xml version="1.0" encoding="UTF-8" standalone="no"?>', '').
    // replace('<?xml version="1.0" encoding="utf-8" standalone="no"?>', '').replace('<![CDATA[', '').
    // replace(']]>', '').replace('<?xml version = \'1.0\' encoding = \'UTF-8\'?>','');//CDPJ SE AUMENTO replace('<?xml version="1.0" encoding="utf-8"?>', '')
    // cadena = cadena.replace('<?xml version="1.0" encoding="UTF-8" standalone="yes"?>', '')

    const parser = new DOMParser();
    console.log('cadena', cadena)
    const myXML = parser.parseFromString(cadena, 'application/xml');
    console.log('myXML', myXML)
    const Comprobante = myXML.getElementsByTagName('comprobante')[0];

    const factura = Comprobante.getElementsByTagName('factura')[0];

    const InfoTributaria = factura.getElementsByTagName('infoTributaria')[0];

    const strRazonSocial = InfoTributaria.getElementsByTagName('razonSocial')[0].childNodes[0].nodeValue;

    const strRuc = InfoTributaria.getElementsByTagName('ruc')[0].childNodes[0].nodeValue;


    const strEstable = InfoTributaria.getElementsByTagName('estab')[0].childNodes[0].nodeValue;
    const strPuntoEmision = InfoTributaria.getElementsByTagName('ptoEmi')[0].childNodes[0].nodeValue;
    const strSecuencia = InfoTributaria.getElementsByTagName('secuencial')[0].childNodes[0].nodeValue;


    const strDireccionMatriz = InfoTributaria.getElementsByTagName('dirMatriz')[0].childNodes[0].nodeValue;
    const strCodDoc = InfoTributaria.getElementsByTagName('codDoc')[0].childNodes[0].nodeValue;

    const strSerie = strEstable + strPuntoEmision;

    const InfoFactura = factura.getElementsByTagName('infoFactura')[0];

    const strFechaEmision = InfoFactura.getElementsByTagName('fechaEmision')[0].childNodes[0].nodeValue;
    const strTipoIdentiComprador = InfoFactura.getElementsByTagName('tipoIdentificacionComprador')[0].childNodes[0].nodeValue;
    const strRazonSocialComprador = InfoFactura.getElementsByTagName('razonSocialComprador')[0].childNodes[0].nodeValue;
    const strIdentificacionComprador = InfoFactura.getElementsByTagName('identificacionComprador')[0].childNodes[0].nodeValue;
    const strTotalSinImpuestos = InfoFactura.getElementsByTagName('totalSinImpuestos')[0].childNodes[0].nodeValue;
    const strTotalDescuento = InfoFactura.getElementsByTagName('totalDescuento')[0].childNodes[0].nodeValue;

    const totalConImpuestos = InfoFactura.getElementsByTagName('totalConImpuestos')[0];
    const totalImpuesto = totalConImpuestos.getElementsByTagName('totalImpuesto')[0];
    const strCodigo = totalImpuesto.getElementsByTagName('codigo')[0].childNodes[0].nodeValue;

    let strCodigoPorcentaje = '';
    if (totalImpuesto.getElementsByTagName('codigoPorcentaje')[0] !== undefined) {
      strCodigoPorcentaje = totalImpuesto.getElementsByTagName('codigoPorcentaje')[0].childNodes[0].nodeValue;
    } else {
      strCodigoPorcentaje = '0';
    }

    let strDescuentoAdicional = '0';
    if (totalImpuesto.getElementsByTagName('descuentoAdicional')[0] !== undefined) {
      strDescuentoAdicional = totalImpuesto.getElementsByTagName('descuentoAdicional')[0].childNodes[0].nodeValue;
    } else {
      strDescuentoAdicional = '0';
    }

    const strBaseImponible = totalImpuesto.getElementsByTagName('baseImponible')[0].childNodes[0].nodeValue;

    let strTarifa = '12';
    if (totalImpuesto.getElementsByTagName('tarifa')[0] !== undefined) {
      strTarifa = totalImpuesto.getElementsByTagName('tarifa')[0].childNodes[0].nodeValue;
    }

    const strValor = totalImpuesto.getElementsByTagName('valor')[0].childNodes[0].nodeValue;

    const strImporteTotal = InfoFactura.getElementsByTagName('importeTotal')[0];


    const dblDescuento = Number(strDescuentoAdicional);
    const dblNeto = Number(strBaseImponible);
    const dblIva = Number(strValor);
    const dblTotal = dblNeto + dblIva;
    const dblTarifa = Number(strTarifa);
    let dblPorcentaje = 0;
    let intFracciones = 0;
    const strCodigoTmpPrincipal = '';

    // calculo del porcetaje de descuento
    if (dblDescuento !== 0) {
      dblPorcentaje = 0;
    }

    // Guardar la cabecera de la factura

    const item: ComEncfacpro = {};

    item.ENCFACPRO_NUMERO = '';
    item.COM_CODIGO = '01';
    item.ENCFACPRO_FECHAEMISION = strFechaEmision;
    item.ENCFACPRO_FECHAVENCIMIENTO = strFechaEmision;
    item.ENCFACPRO_FECHAREC = strFechaEmision;
    item.ENCFACPRO_IVA = this.selecttipoiva.codigo//CDPJ;
    item.ENCFACPRO_ESTADO = 'P';
    item.PRO_CODIGO = strRuc;
    const proveedor = await this.encfacproService.getProcodigo(strRuc);
    if (proveedor !== null) {
      for (const rs of proveedor) {
        item.PRO_CODIGO = rs.PRO_CODIGO;
      }
    }
    item.ENCFACPRO_CONTACTO = strRazonSocial;
    item.ENCFACPRO_REFERENCIA = strSecuencia;
    item.ENCFACPRO_TOTAL = Number(dblTotal);
    item.ENCFACPRO_TOTALNETO = Number(strBaseImponible);
    item.ENCFACPRO_VALORDES = Number(dblDescuento);
    item.ENCFACPRO_PORCEDES = Number(dblPorcentaje);
    item.ENCFACPRO_VALORIVA = Number(dblIva);
    item.ENCFACPRO_PORCEIVA = Number(dblTarifa);
    item.ENCFACPRO_VALORICE = 0;
    item.ENCFACPRO_BASEIVA = Number(strBaseImponible);
    item.ENCFACPRO_BASEICE = 0;
    item.ENCFACPRO_BASECERO = 0;
    item.ENCFACPRO_COMENTARIO = '';
    item.ENCFACPRO_OTROS = 0;
    item.ENCFACPRO_DISTCARGOS = 'N';
    item.ENCFACPRO_NUMDIASPLAZO = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMDIASPLAZO;
    item.ENCFACPRO_IDCRETRI = this.idcreditoxml;
    item.ENCFACPRO_SERIE = strSerie;
    item.ENCFACPRO_AUTORIZACION = strNumeroAutorizacion;
    item.ENCFACPRO_TIPCOM = '01';
    item.MAEMOTIVO_CODIGO = '';
    item.ENCFACPRO_FECHACADFAC = strFechaEmision;
    item.ENCFACPRO_NUMINGRESO = '';
    item.ENCFACPRO_NUMLIQUIDACION = '';
    item.ENCFACPRO_TIPODES = 'V';

    item.ENCFACPRO_GASTO = '';
    item.ENCORDCOM_NUMERO = '';
    item.ENCFACPRO_NOOBJETOIVA = '';
    item.ENCFACPRO_BASENOOBJIVA = 0;
    item.TRNFORMAPAGO_CODIGO = '';
    item.ENCFACPRO_FLAG = '10';
    item.ENCFACPRO_ORIGEN = ''
    try {
      //* VALIDA PRIMERO QUE EL PORCENTAJE DE IVA EXISTA DENTRO DE LA TABLA DE IVAS CDPJ
      const sesion = factura.getElementsByTagName('detalles')[0];
      const listaAplicaciones = sesion.getElementsByTagName('detalle');

      let strDescripcion = '';

      for (let i = 0; i < listaAplicaciones.length; i++) {
        const nodo = listaAplicaciones[i];
        const impuestos = nodo.getElementsByTagName('impuestos')[0];
        const impuesto = impuestos.getElementsByTagName('impuesto')[0];
        const strTarifaImp = impuesto.getElementsByTagName('tarifa')[0].childNodes[0].nodeValue;
        const dblTarifaDet = Number(strTarifaImp);
        const strCodigoPocentjeImp = impuesto.getElementsByTagName('codigoPorcentaje')[0].childNodes[0].nodeValue;//TARIIVA_CODIGO
        let dato = this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(dblTarifaDet))
        if (dato === undefined || dato === null) {
          this.messageService.add({
            key: 'encfacproimpxml',
            severity: 'warn',
            summary: 'Error en IVA',
            detail: 'El porcentaje ' + dblTarifaDet + ' no forma parte de los porcentajes permitidos'
          });
          return;
        }
        let dato2 = this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === strCodigoPocentjeImp.toString())
        if (dato2 === undefined || dato2 === null) {
          this.messageService.add({
            key: 'encfacproimpxml',
            severity: 'warn',
            summary: 'Error en IVA',
            detail: 'El código ' + strCodigoPocentjeImp + ' no forma parte de los códigos permitidos'
          });
          return;
        }
      }
      //CDPJ
      const encs = await this.encfacproService.insertarEncabezadoFactPromise(item);
      for (const enc of encs) {
        if (enc.V_ENCFACPRO_NUMERO === '1') {
          this.messageService.add({
            key: 'encfacproimpxml',
            severity: 'warn',
            summary: 'Información',
            detail: 'Númeración general no existe para las facturas de proveedores'
          });
          return;
        }
        strNumFactura = enc.V_ENCFACPRO_NUMERO;

        this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', strNumFactura, 'I',
          '', '01', '', '', '', '').subscribe();

        const er = await this.encfacproService.erConCodigo1Proveedor1Prom(strRuc);

        if (er !== null) {
          if (er[0] !== undefined) {
            strConCodigo = er[0].CON_CODIGO1;
          }
        }
        // INSERTA EL DETALLE DE LA FACTURA

        const sesion = factura.getElementsByTagName('detalles')[0];

        const listaAplicaciones = sesion.getElementsByTagName('detalle');

        let strDescripcion = '';
        for (let i = 0; i < listaAplicaciones.length; i++) {
          const nodo = listaAplicaciones[i];
          console.log(nodo);

          this.porcentaje = this.porcentaje + Number(100 / listaAplicaciones.length);

          // let strCodigoPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
          // const strPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
          //CDPJ
          let strCodigoPrincipal = '';
          let strPrincipal = ''
          console.log('nodo', nodo.getElementsByTagName('codigoPrincipal')[0])
          if (nodo.getElementsByTagName('codigoPrincipal')[0] !== undefined) {
            strCodigoPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
            strPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
          }
          //CDPJ
          let strCodigoPrincipal2 = '';

          let strCodigoAuxiliar = '';
          try {
            if (nodo.getElementsByTagName('codigoAuxiliar')[0] !== undefined) {
              strCodigoAuxiliar = nodo.getElementsByTagName('codigoAuxiliar')[0].childNodes[0].nodeValue;
            }
          } catch (err) {

          }

          const strCodigoTmpPrincipal = strCodigoPrincipal;
          let strSentencia = '';

          strSentencia = 'select art_codigo, ser_codigo from INV_TRNDATOSALT where TRNDATOS_CODIGOALT=\'' + strCodigoPrincipal + '\'';

          const data = await this.encfacproService.getInvtrndatosalt(strCodigoPrincipal);
          console.log(data);
          if (data.length === 0) {
            strCodigoPrincipal = '';
          } else {
            if (data[0].ART_CODIGO === null) {
              strCodigoPrincipal = data[0].SER_CODIGO;
            } else {
              strCodigoPrincipal = data[0].ART_CODIGO;
            }
          }
          // for (const rs of data) {
          //   strCodigoPrincipal = rs.ART_CODIGO === null ? '' : rs.ART_CODIGO;
          // }
          strSentencia = 'select art_codigo, ser_codigo from INV_TRNDATOSALT where TRNDATOS_CODIGOALT=\'' + strCodigoAuxiliar + '\'';

          const data2 = await this.encfacproService.getInvtrndatosalt(strCodigoAuxiliar);
          console.log(data2);
          if (data2.length === 0) {
            strCodigoPrincipal2 = ' ';
          } else {
            if (data2[0].ART_CODIGO === null) {
              strCodigoPrincipal2 = data2[0].SER_CODIGO;
            } else {
              strCodigoPrincipal2 = data2[0].ART_CODIGO;
            }
          }

          // for (const rs2 of data2) {
          //   strCodigoPrincipal2 = rs2.ART_CODIGO === null ? '' : rs2.ART_CODIGO;
          // }

          if (strCodigoPrincipal === ' ') {
            if (strCodigoAuxiliar !== '') {
              strCodigoPrincipal = strCodigoPrincipal2;
            }
          }

          strDescripcion = nodo.getElementsByTagName('descripcion')[0].childNodes[0].nodeValue;
          const strCantidad = nodo.getElementsByTagName('cantidad')[0].childNodes[0].nodeValue;
          const strPrecioUnitario = nodo.getElementsByTagName('precioUnitario')[0].childNodes[0].nodeValue;
          const strDescuento = nodo.getElementsByTagName('descuento')[0].childNodes[0].nodeValue;
          const strPrecioTotalSinImpuesto = nodo.getElementsByTagName('precioTotalSinImpuesto')[0].childNodes[0].nodeValue;

          const impuestos = nodo.getElementsByTagName('impuestos')[0];
          const impuesto = impuestos.getElementsByTagName('impuesto')[0];
          const strCodigoImp = impuesto.getElementsByTagName('codigo')[0].childNodes[0].nodeValue;
          const strCodigoPocentjeImp = impuesto.getElementsByTagName('codigoPorcentaje')[0].childNodes[0].nodeValue;//TARIIVA_CODIGO
          const strTarifaImp = impuesto.getElementsByTagName('tarifa')[0].childNodes[0].nodeValue;
          const strBaseImponibleImp = impuesto.getElementsByTagName('baseImponible')[0].childNodes[0].nodeValue;
          const strValorImp = impuesto.getElementsByTagName('valor')[0].childNodes[0].nodeValue;



          let dblCantidad = Number(strCantidad);
          let dblPrecioUnitario = Number(strPrecioUnitario);
          const dblDescuentoDet = Number(strDescuento);
          const dblPrecioTotalSinImpuesto = Number(strPrecioTotalSinImpuesto);
          const dblValorImp = Number(strValorImp);
          const dblBaseImponibleImp = Number(strBaseImponibleImp);

          const dblTarifaDet = Number(strTarifaImp);

          const intINVCAJFRA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INVCAJFRA').CFG_VALOR1;
          const intINVFRACAJ = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INVFRACAJ').CFG_VALOR1;
          const intFUNLAB = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FUNLAB').CFG_VALOR1;
          const intFUNFAR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FUNFAR').CFG_VALOR1;

          strSentencia = 'select maefar_fraccion from inv_maefar where art_codigo=\'' + strCodigoPrincipal + '\'';

          const data3 = await this.encfacproService.getMaefarFaccionProm(strCodigoPrincipal);

          if ((intINVCAJFRA === 1 || intINVFRACAJ === 1) && intFUNLAB === 0) {
            // NO POR LOTE
            if (intFUNFAR === 1) {
              for (const rs3 of data3) {
                intFracciones = rs3.MAEFAR_FRACCION;
              }

              if (intFracciones === 0) {
                intFracciones = 1;
              }

              dblCantidad = dblCantidad * intFracciones;
              dblPrecioUnitario = dblPrecioUnitario / intFracciones;

            }
          }

          const itemdet: ComDetfacpro = {};
          itemdet.ENCFACPRO_NUMERO = strNumFactura;
          itemdet.COM_CODIGO = '01';
          itemdet.DETFACPRO_LINEA = 0;
          itemdet.DETFACPRO_TIPODET = strCodigoPrincipal !== '' ? (data[0].SER_CODIGO === null ? 'A' : 'S') : 'A';
          itemdet.BOD_CODIGO = this.bodcodigoxml;
          itemdet.DETFACPRO_CODIGO = strCodigoPrincipal;
          itemdet.DETFACPRO_DESCRIPCION = strDescripcion;
          itemdet.DETFACPRO_UNIDAD = 'XXX';  // unidad (se envía este valor para que lea la unidad de costeo)
          itemdet.DETFACPRO_CANTIDAD = Number(dblCantidad);
          itemdet.DETFACPRO_ENTREGADO = 0; // Entregado
          itemdet.DETFACPRO_COSTO = Number(dblPrecioUnitario);
          itemdet.DETFACPRO_VALDES = Number(dblDescuentoDet);
          itemdet.DETFACPRO_PORDES = 0;
          itemdet.DETFACPRO_TOTAL = Number(dblPrecioTotalSinImpuesto);
          itemdet.DETFACPRO_PORIVA = Number(dblTarifaDet);
          itemdet.DETFACPRO_IVA = Number(dblValorImp);
          itemdet.DETFACPRO_TRIBICE = 'N';
          itemdet.DETFACPRO_ICE = 0;
          itemdet.DETFACPRO_PORCEICE = 0;
          itemdet.DETFACPRO_BASEIVA = Number(dblBaseImponibleImp);
          itemdet.DETFACPRO_BASEICE = 0;
          itemdet.DETFACPRO_BASECERO = 0;
          itemdet.ENCORDCOM_NUMERO = '';
          itemdet.DETORDCOM_LINEA = 0;
          itemdet.ENCNOTREC_NUMERO = '';
          itemdet.DETNOTREC_LINEA = 0;
          // Control de la promoción
          itemdet.DETFACPRO_PROMOCION = '';
          itemdet.DETFACPRO_TOTALINCP = Number(dblPrecioTotalSinImpuesto);
          itemdet.DETFACPRO_PORDES2 = 0;
          itemdet.DETFACPRO_PORDES3 = 0;
          itemdet.CEN_CODIGO = this.centrocostosxml;
          itemdet.DETFACPRO_FACTOR = 0;
          itemdet.DETFACPRO_PRECIOFOB = 0;
          itemdet.DETFACPRO_ESQUEMADOC = '';
          itemdet.DETFACPRO_TIPOSRI = '';
          itemdet.DETFACPRO_PRECIOA = 0;
          itemdet.DETFACPRO_PRECIOB = 0;
          itemdet.DETFACPRO_PRECIOC = 0;
          itemdet.TRNSOLFAC_CODIGO = '';
          itemdet.TRNSOLFAC_LINEA = 0;
          itemdet.ENCREQ_NUMERO = '';
          itemdet.DETREQ_LINEA = 0;
          itemdet.ENCGRE_CODIGO = '';
          itemdet.DETGRE_LINEA = 0;
          itemdet.DETFACPRO_BASENOOBJIVA = 0;
          itemdet.DETFACPRO_TRIBASENOOBJIVA = '';
          itemdet.DETFACPRO_CODIGOALT = strPrincipal; // codigo alterno
          itemdet.DETFACPRO_FRACCIONES = Number(intFracciones);
          itemdet.TARIIVA_CODIGO = strCodigoPocentjeImp.toString();//cdpj

          await this.encfacproService.insertarDetalleFactura_IProm(itemdet);
          this.auditoriagral.registrarAuditoria('COM_DETFACPRO', strNumFactura + '/' + strCodigoPrincipal, 'I',
            '', '01', '', '', '', '').subscribe();

        }
        this.messageService.add({
          key: 'encfacpro',
          severity: 'success',
          summary: 'Información',
          detail: 'Proceso terminó satisfactoriamente '
        });

        await this.ActualizarDatosaAdi('FACT ' + strSecuencia + ' - ' + strRazonSocial, strNumFactura);
        await this.encfacproService.ActualizaDatosProm(strNumFactura);
        this.displayDialogSubirXmlFactura = false;


        this.encontrarFactura(strNumFactura);
        this.encfacproService.calcularTotales();

        this.guardarCuentaXPagarXml(strNumFactura, strSerie, strSecuencia, item.PRO_CODIGO,
          strConCodigo, dblTotal, strFechaEmision, 'Factura electrónica' + archivo);

      }
    } catch (err) {
      const error = this.errorService.generarMensajeError(err.error.substr(0, 9));
      this.messageService.add({
        key: 'encfacproimpxml',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    }

    // this.fileReader.readAsText(this.fileXml);
    // this.fileReader.onload = () => {
    //   cadena = this.fileReader.result.toString();
    //
    //   cadena = cadena.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(
    //     /&amp;/g, '&').replace(/&apos;/g, '\'').replace(/&quot;/g, '"');
    //
    //   cadena = cadena.replace('<?xml version="1.0" encoding="UTF-8"?>', '');
    //
    //   const parser = new DOMParser();
    //   const myXML = parser.parseFromString(cadena, 'text/xml');
    //
    //   const Comprobante = myXML.getElementsByTagName('comprobante')[0];
    //
    //   const factura = Comprobante.getElementsByTagName('factura')[0];
    //
    //   const InfoTributaria = factura.getElementsByTagName('infoTributaria')[0];
    //
    //   const strRazonSocial = InfoTributaria.getElementsByTagName('razonSocial')[0].childNodes[0].nodeValue;
    //
    //   const strRuc = InfoTributaria.getElementsByTagName('ruc')[0].childNodes[0].nodeValue;
    //   const strEstable = InfoTributaria.getElementsByTagName('estab')[0].childNodes[0].nodeValue;
    //   const strPuntoEmision = InfoTributaria.getElementsByTagName('ptoEmi')[0].childNodes[0].nodeValue;
    //   const strSecuencia = InfoTributaria.getElementsByTagName('secuencial')[0].childNodes[0].nodeValue;
    //
    //
    //   const strDireccionMatriz = InfoTributaria.getElementsByTagName('dirMatriz')[0].childNodes[0].nodeValue;
    //   const strCodDoc = InfoTributaria.getElementsByTagName('codDoc')[0].childNodes[0].nodeValue;
    //
    //   const strSerie = strEstable + strPuntoEmision;
    //
    //   const InfoFactura = factura.getElementsByTagName('infoFactura')[0];
    //
    //   const strFechaEmision = InfoFactura.getElementsByTagName('fechaEmision')[0].childNodes[0].nodeValue;
    //   const strTipoIdentiComprador = InfoFactura.getElementsByTagName('tipoIdentificacionComprador')[0].childNodes[0].nodeValue;
    //   const strRazonSocialComprador = InfoFactura.getElementsByTagName('razonSocialComprador')[0].childNodes[0].nodeValue;
    //   const strIdentificacionComprador = InfoFactura.getElementsByTagName('identificacionComprador')[0].childNodes[0].nodeValue;
    //   const strTotalSinImpuestos = InfoFactura.getElementsByTagName('totalSinImpuestos')[0].childNodes[0].nodeValue;
    //   const strTotalDescuento = InfoFactura.getElementsByTagName('totalDescuento')[0].childNodes[0].nodeValue;
    //
    //   const totalConImpuestos = InfoFactura.getElementsByTagName('totalConImpuestos')[0];
    //   const totalImpuesto = totalConImpuestos.getElementsByTagName('totalImpuesto')[0];
    //   const strCodigo = totalImpuesto.getElementsByTagName('codigo')[0].childNodes[0].nodeValue;
    //
    //   let strCodigoPorcentaje = '';
    //   if (totalImpuesto.getElementsByTagName('codigoPorcentaje')[0] !== undefined) {
    //     strCodigoPorcentaje = totalImpuesto.getElementsByTagName('codigoPorcentaje')[0].childNodes[0].nodeValue;
    //   } else {
    //     strCodigoPorcentaje = '0';
    //   }
    //
    //   let strDescuentoAdicional = '0';
    //   if (totalImpuesto.getElementsByTagName('descuentoAdicional')[0] !== undefined) {
    //     strDescuentoAdicional = totalImpuesto.getElementsByTagName('descuentoAdicional')[0].childNodes[0].nodeValue;
    //   } else {
    //     strDescuentoAdicional = '0';
    //   }
    //
    //   const strBaseImponible = totalImpuesto.getElementsByTagName('baseImponible')[0].childNodes[0].nodeValue;
    //
    //   let strTarifa = '12';
    //   if (totalImpuesto.getElementsByTagName('tarifa')[0] !== undefined) {
    //     strTarifa = totalImpuesto.getElementsByTagName('tarifa')[0].childNodes[0].nodeValue;
    //   }
    //
    //   const strValor = totalImpuesto.getElementsByTagName('valor')[0].childNodes[0].nodeValue;
    //
    //   const strImporteTotal = InfoFactura.getElementsByTagName('importeTotal')[0];
    //
    //
    //   const dblDescuento = Number(strDescuentoAdicional);
    //   const dblNeto = Number(strBaseImponible);
    //   const dblIva = Number(strValor);
    //   const dblTotal = dblNeto + dblIva;
    //   const dblTarifa = Number(strTarifa);
    //   let dblPorcentaje = 0;
    //   let intFracciones = 0;
    //   const strCodigoTmpPrincipal = '';
    //
    //   // calculo del porcetaje de descuento
    //   if (dblDescuento !== 0) {
    //     dblPorcentaje = 0;
    //   }
    //
    //   // Guardar la cabecera de la factura
    //
    //   const item: ComEncfacpro = {};
    //
    //   item.ENCFACPRO_NUMERO = '';
    //   item.COM_CODIGO = '01';
    //   item.ENCFACPRO_FECHAEMISION = strFechaEmision;
    //   item.ENCFACPRO_FECHAVENCIMIENTO = strFechaEmision;
    //   item.ENCFACPRO_FECHAREC = strFechaEmision;
    //   item.ENCFACPRO_IVA = '2';
    //   item.ENCFACPRO_ESTADO = 'P';
    //   item.PRO_CODIGO = strRuc;
    //   item.ENCFACPRO_CONTACTO = strRazonSocial;
    //   item.ENCFACPRO_REFERENCIA = strSecuencia;
    //   item.ENCFACPRO_TOTAL = Number(dblTotal);
    //   item.ENCFACPRO_TOTALNETO = Number(strBaseImponible);
    //   item.ENCFACPRO_VALORDES = Number(dblDescuento);
    //   item.ENCFACPRO_PORCEDES = Number(dblPorcentaje);
    //   item.ENCFACPRO_VALORIVA = Number(dblIva);
    //   item.ENCFACPRO_PORCEIVA = Number(dblTarifa);
    //   item.ENCFACPRO_VALORICE = 0;
    //   item.ENCFACPRO_BASEIVA = Number(strBaseImponible);
    //   item.ENCFACPRO_BASEICE = 0;
    //   item.ENCFACPRO_BASECERO = 0;
    //   item.ENCFACPRO_COMENTARIO = '';
    //   item.ENCFACPRO_OTROS = 0;
    //   item.ENCFACPRO_DISTCARGOS = 'N';
    //   item.ENCFACPRO_NUMDIASPLAZO = 0;
    //   item.ENCFACPRO_IDCRETRI = this.idcreditoxml;
    //   item.ENCFACPRO_SERIE = strSerie;
    //   item.ENCFACPRO_AUTORIZACION = strNumeroAutorizacion;
    //   item.ENCFACPRO_TIPCOM = '01';
    //   item.MAEMOTIVO_CODIGO = '';
    //   item.ENCFACPRO_FECHACADFAC = strFechaEmision;
    //   item.ENCFACPRO_NUMINGRESO = '';
    //   item.ENCFACPRO_NUMLIQUIDACION = '';
    //   item.ENCFACPRO_TIPODES = 'V';
    //
    //   item.ENCFACPRO_GASTO = '';
    //   item.ENCORDCOM_NUMERO = '';
    //   item.ENCFACPRO_NOOBJETOIVA = '';
    //   item.ENCFACPRO_BASENOOBJIVA = 0;
    //   item.TRNFORMAPAGO_CODIGO = '';
    //
    //   this.encfacproService.insertarEncabezadoFact(item).subscribe(encs => {
    //     for (const enc of encs) {
    //       if (enc.V_ENCFACPRO_NUMERO === '1') {
    //         this.messageService.add({
    //           key: 'encfacproimpxml',
    //           severity: 'warn',
    //           summary: 'Información',
    //           detail: 'Númeración general no existe para las facturas de proveedores'
    //         });
    //         return;
    //       }
    //       strNumFactura = enc.V_ENCFACPRO_NUMERO;
    //
    //       this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', strNumFactura, 'I',
    //         '', '01', '', '', '', '').subscribe();
    //       this.encfacproService.encontrarRegistro('con_codigo1', 'com_maeproveedor',
    //         'pro_codigo=' + '\'' + strRuc + '\'').subscribe(er => {
    //         if (er !== null) {
    //           if (er[0] !== undefined) {
    //             strConCodigo = er[0].CON_CODIGO1;
    //           }
    //         }
    //
    //         // INSERTA EL DETALLE DE LA FACTURA
    //
    //
    //         const sesion = factura.getElementsByTagName('detalles')[0];
    //
    //         const listaAplicaciones = sesion.getElementsByTagName('detalle');
    //
    //         let strDescripcion = '';
    //         for (let i = 0; i < listaAplicaciones.length; i++) {
    //           const nodo = listaAplicaciones[i];
    //
    //           let strCodigoPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
    //           const strPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
    //           let strCodigoPrincipal2 = '';
    //
    //           let strCodigoAuxiliar = '';
    //           if (nodo.getElementsByTagName('codigoAuxiliar') !== undefined) {
    //             strCodigoAuxiliar = nodo.getElementsByTagName('codigoAuxiliar')[0].childNodes[0].nodeValue;
    //           }
    //
    //           const strCodigoTmpPrincipal = strCodigoPrincipal;
    //           let strSentencia = '';
    //
    //           strSentencia = 'select art_codigo from inv_trncodaltart where art_codigoalterno=\'' + strCodigoPrincipal + '\'';
    //
    //           this.encfacproService.ejecutarConsulta(strSentencia).subscribe(data => {
    //             for (const rs of data) {
    //               strCodigoPrincipal = rs.ART_CODIGO === null ? '' : rs.ART_CODIGO;
    //             }
    //             strSentencia = 'select art_codigo from inv_trncodaltart where art_codigoalterno=\'' + strCodigoAuxiliar + '\'';
    //
    //             this.encfacproService.ejecutarConsulta(strSentencia).subscribe(data2 => {
    //               for (const rs2 of data2) {
    //                 strCodigoPrincipal2 = rs2.ART_CODIGO === null ? '' : rs2.ART_CODIGO;
    //               }
    //
    //
    //               if (strCodigoPrincipal === '') {
    //                 if (strCodigoAuxiliar !== '') {
    //                   strCodigoPrincipal = strCodigoPrincipal2;
    //                 }
    //               }
    //
    //               strDescripcion = nodo.getElementsByTagName('descripcion')[0].childNodes[0].nodeValue;
    //               const strCantidad = nodo.getElementsByTagName('cantidad')[0].childNodes[0].nodeValue;
    //               const strPrecioUnitario = nodo.getElementsByTagName('precioUnitario')[0].childNodes[0].nodeValue;
    //               const strDescuento = nodo.getElementsByTagName('descuento')[0].childNodes[0].nodeValue;
    //               const strPrecioTotalSinImpuesto = nodo.getElementsByTagName('precioTotalSinImpuesto')[0].childNodes[0].nodeValue;
    //
    //               const impuestos = nodo.getElementsByTagName('impuestos')[0];
    //               const impuesto = impuestos.getElementsByTagName('impuesto')[0];
    //               const strCodigoImp = impuesto.getElementsByTagName('codigo')[0].childNodes[0].nodeValue;
    //               const strCodigoPocentjeImp = impuesto.getElementsByTagName('codigoPorcentaje')[0].childNodes[0].nodeValue;
    //               const strTarifaImp = impuesto.getElementsByTagName('tarifa')[0].childNodes[0].nodeValue;
    //               const strBaseImponibleImp = impuesto.getElementsByTagName('baseImponible')[0].childNodes[0].nodeValue;
    //               const strValorImp = impuesto.getElementsByTagName('valor')[0].childNodes[0].nodeValue;
    //
    //
    //               let dblCantidad = Number(strCantidad);
    //               let dblPrecioUnitario = Number(strPrecioUnitario);
    //               const dblDescuentoDet = Number(strDescuento);
    //               const dblPrecioTotalSinImpuesto = Number(strPrecioTotalSinImpuesto);
    //               const dblValorImp = Number(strValorImp);
    //               const dblBaseImponibleImp = Number(strBaseImponibleImp);
    //
    //               const dblTarifaDet = Number(strTarifaImp);
    //
    //               const intINVCAJFRA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INVCAJFRA').CFG_VALOR1;
    //               const intINVFRACAJ = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INVFRACAJ').CFG_VALOR1;
    //               const intFUNLAB = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FUNLAB').CFG_VALOR1;
    //               const intFUNFAR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FUNFAR').CFG_VALOR1;
    //
    //               strSentencia = 'select maefar_fraccion from inv_maefar where art_codigo=\'' + strCodigoPrincipal + '\'';
    //
    //               this.encfacproService.ejecutarConsulta(strSentencia).subscribe(data3 => {
    //                 if ((intINVCAJFRA === 1 || intINVFRACAJ === 1) && intFUNLAB === 0) {
    //                   // NO POR LOTE
    //                   if (intFUNFAR === 1) {
    //                     for (const rs3 of data3) {
    //                       intFracciones = rs3.MAEFAR_FRACCION;
    //                     }
    //
    //                     if (intFracciones === 0) {
    //                       intFracciones = 1;
    //                     }
    //
    //                     dblCantidad = dblCantidad * intFracciones;
    //                     dblPrecioUnitario = dblPrecioUnitario / intFracciones;
    //
    //                   }
    //                 }
    //
    //                 const itemdet: ComDetfacpro = {};
    //                 itemdet.ENCFACPRO_NUMERO = strNumFactura;
    //                 itemdet.COM_CODIGO = '01';
    //                 itemdet.DETFACPRO_LINEA = 0;
    //                 itemdet.DETFACPRO_TIPODET = 'A';
    //                 itemdet.BOD_CODIGO = this.bodcodigoxml;
    //                 itemdet.DETFACPRO_CODIGO = strCodigoPrincipal;
    //                 itemdet.DETFACPRO_DESCRIPCION = strDescripcion;
    //                 itemdet.DETFACPRO_UNIDAD = 'XXX';  // unidad (se envía este valor para que lea la unidad de costeo)
    //                 itemdet.DETFACPRO_CANTIDAD = Number(dblCantidad);
    //                 itemdet.DETFACPRO_ENTREGADO = 0; // Entregado
    //                 itemdet.DETFACPRO_COSTO = Number(dblPrecioUnitario);
    //                 itemdet.DETFACPRO_VALDES = Number(dblDescuentoDet);
    //                 itemdet.DETFACPRO_PORDES = 0;
    //                 itemdet.DETFACPRO_TOTAL = Number(dblPrecioTotalSinImpuesto);
    //                 itemdet.DETFACPRO_PORIVA = Number(dblTarifaDet);
    //                 itemdet.DETFACPRO_IVA = Number(dblValorImp);
    //                 itemdet.DETFACPRO_TRIBICE = 'N';
    //                 itemdet.DETFACPRO_ICE = 0;
    //                 itemdet.DETFACPRO_PORCEICE = 0;
    //                 itemdet.DETFACPRO_BASEIVA = Number(dblBaseImponibleImp);
    //                 itemdet.DETFACPRO_BASEICE = 0;
    //                 itemdet.DETFACPRO_BASECERO = 0;
    //                 itemdet.ENCORDCOM_NUMERO = '';
    //                 itemdet.DETORDCOM_LINEA = 0;
    //                 itemdet.ENCNOTREC_NUMERO = '';
    //                 itemdet.DETNOTREC_LINEA = 0;
    //                 // Control de la promoción
    //                 itemdet.DETFACPRO_PROMOCION = '';
    //                 itemdet.DETFACPRO_TOTALINCP = Number(dblPrecioTotalSinImpuesto);
    //                 itemdet.DETFACPRO_PORDES2 = 0;
    //                 itemdet.DETFACPRO_PORDES3 = 0;
    //                 itemdet.CEN_CODIGO = this.centrocostosxml;
    //                 itemdet.DETFACPRO_FACTOR = 0;
    //                 itemdet.DETFACPRO_PRECIOFOB = 0;
    //                 itemdet.DETFACPRO_ESQUEMADOC = '';
    //                 itemdet.DETFACPRO_TIPOSRI = '';
    //                 itemdet.DETFACPRO_PRECIOA = 0;
    //                 itemdet.DETFACPRO_PRECIOB = 0;
    //                 itemdet.DETFACPRO_PRECIOC = 0;
    //                 itemdet.TRNSOLFAC_CODIGO = '';
    //                 itemdet.TRNSOLFAC_LINEA = 0;
    //                 itemdet.ENCREQ_NUMERO = '';
    //                 itemdet.DETREQ_LINEA = 0;
    //                 itemdet.ENCGRE_CODIGO = '';
    //                 itemdet.DETGRE_LINEA = 0;
    //                 itemdet.DETFACPRO_BASENOOBJIVA = 0;
    //                 itemdet.DETFACPRO_TRIBASENOOBJIVA = '';
    //                 itemdet.DETFACPRO_CODIGOALT = strPrincipal; // codigo alterno
    //                 itemdet.DETFACPRO_FRACCIONES = Number(intFracciones);
    //
    //                 this.encfacproService.insertarDetalleFactura_I(itemdet).subscribe(() => {
    //                   this.auditoriagral.registrarAuditoria('COM_DETFACPRO', strNumFactura + '/' + strCodigoPrincipal, 'I',
    //                     '', '01', '', '', '', '').subscribe();
    //                 });
    //               });
    //             });
    //           });
    //         }
    //
    //         this.messageService.add({
    //           key: 'encfacpro',
    //           severity: 'success',
    //           summary: 'Información',
    //           detail: 'Proceso terminó satisfactoriamente '
    //         });
    //         this.ActualizarDatosaAdi('FACT ' + strSecuencia + ' - ' + strRazonSocial, strNumFactura);
    //         this.encfacproService.ActualizaDatosProm(strNumFactura);
    //         this.displayDialogSubirXmlFactura = false;
    //
    //         setTimeout(() => {
    //           this.encontrarFactura(strNumFactura);
    //           this.encfacproService.calcularTotales();
    //         }, 500);
    //
    //       });
    //
    //
    //       this.guardarCuentaXPagarXml(strNumFactura, strSerie, strSecuencia, strRuc,
    //         strConCodigo, dblTotal, strFechaEmision, 'Factura electrónica' + archivo);
    //
    //     }
    //   }, error1 => {
    //     const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
    //     this.messageService.add({
    //       key: 'encfacpro',
    //       severity: 'error',
    //       summary: 'Error',
    //       detail: error
    //     });
    //   });
    // };
  }

  async leerDatosXmlRetComprasAutF1(xml) {
    let cadena = '';
    let strNumFactura = '';
    let strConCodigo = '';

    cadena = xml.TEXTO;

    const parser = new DOMParser();
    const myXML = parser.parseFromString(cadena, 'text/xml');

    const InfoTributaria = myXML.getElementsByTagName('infoTributaria')[0];

    const strRazonSocial = InfoTributaria.getElementsByTagName('razonSocial')[0].childNodes[0].nodeValue;
    const strRuc = InfoTributaria.getElementsByTagName('ruc')[0].childNodes[0].nodeValue;
    const strEstable = InfoTributaria.getElementsByTagName('estab')[0].childNodes[0].nodeValue;
    const strPuntoEmision = InfoTributaria.getElementsByTagName('ptoEmi')[0].childNodes[0].nodeValue;
    const strSecuencia = InfoTributaria.getElementsByTagName('secuencial')[0].childNodes[0].nodeValue;


    const strDireccionMatriz = InfoTributaria.getElementsByTagName('dirMatriz')[0].childNodes[0].nodeValue;
    const strCodDoc = InfoTributaria.getElementsByTagName('codDoc')[0].childNodes[0].nodeValue;

    const strSerie = strEstable + strPuntoEmision;

    const InfoFactura = myXML.getElementsByTagName('infoFactura')[0];

    const strFechaEmision = InfoFactura.getElementsByTagName('fechaEmision')[0].childNodes[0].nodeValue;
    const strTipoIdentiComprador = InfoFactura.getElementsByTagName('tipoIdentificacionComprador')[0].childNodes[0].nodeValue;
    const strRazonSocialComprador = InfoFactura.getElementsByTagName('razonSocialComprador')[0].childNodes[0].nodeValue;
    const strIdentificacionComprador = InfoFactura.getElementsByTagName('identificacionComprador')[0].childNodes[0].nodeValue;
    const strTotalSinImpuestos = InfoFactura.getElementsByTagName('totalSinImpuestos')[0].childNodes[0].nodeValue;
    const strTotalDescuento = InfoFactura.getElementsByTagName('totalDescuento')[0].childNodes[0].nodeValue;

    const totalConImpuestos = InfoFactura.getElementsByTagName('totalConImpuestos')[0];
    const totalImpuesto = totalConImpuestos.getElementsByTagName('totalImpuesto')[0];
    const strCodigo = totalImpuesto.getElementsByTagName('codigo')[0].childNodes[0].nodeValue;

    let strCodigoPorcentaje = '';
    if (totalImpuesto.getElementsByTagName('codigoPorcentaje')[0] !== undefined) {
      strCodigoPorcentaje = totalImpuesto.getElementsByTagName('codigoPorcentaje')[0].childNodes[0].nodeValue;
    } else {
      strCodigoPorcentaje = '0';
    }

    let strDescuentoAdicional = '0';
    if (totalImpuesto.getElementsByTagName('descuentoAdicional')[0] !== undefined) {
      strDescuentoAdicional = totalImpuesto.getElementsByTagName('descuentoAdicional')[0].childNodes[0].nodeValue;
    } else {
      strDescuentoAdicional = '0';
    }

    const strBaseImponible = totalImpuesto.getElementsByTagName('baseImponible')[0].childNodes[0].nodeValue;

    let strTarifa = '12';
    if (totalImpuesto.getElementsByTagName('tarifa')[0] !== undefined) {
      strTarifa = totalImpuesto.getElementsByTagName('tarifa')[0].childNodes[0].nodeValue;
    }

    const strValor = totalImpuesto.getElementsByTagName('valor')[0].childNodes[0].nodeValue;

    const strImporteTotal = InfoFactura.getElementsByTagName('importeTotal')[0];


    const dblDescuento = Number(strDescuentoAdicional);
    const dblNeto = Number(strBaseImponible);
    const dblIva = Number(strValor);
    const dblTotal = dblNeto + dblIva;
    const dblTarifa = Number(strTarifa);
    let dblPorcentaje = 0;
    let intFracciones = 0;
    const strCodigoTmpPrincipal = '';

    // calculo del porcetaje de descuento
    if (dblDescuento !== 0) {
      dblPorcentaje = 0;
    }

    // Guardar la cabecera de la factura

    const item: ComEncfacpro = {};

    item.ENCFACPRO_NUMERO = '';
    item.COM_CODIGO = '01';
    item.ENCFACPRO_FECHAEMISION = strFechaEmision;
    item.ENCFACPRO_FECHAVENCIMIENTO = strFechaEmision;
    item.ENCFACPRO_FECHAREC = strFechaEmision;
    item.ENCFACPRO_IVA = this.selecttipoiva.codigo//CDPJ;
    item.ENCFACPRO_ESTADO = 'P';

    const proveedor = await this.encfacproService.getProcodigo(strRuc);
    if (proveedor !== null) {
      for (const rs of proveedor) {
        item.PRO_CODIGO = rs.PRO_CODIGO;
      }
    }

    /**/
    item.ENCFACPRO_CONTACTO = strRazonSocial;
    item.ENCFACPRO_REFERENCIA = strSecuencia;
    item.ENCFACPRO_TOTAL = Number(dblTotal);
    item.ENCFACPRO_TOTALNETO = Number(strBaseImponible);
    item.ENCFACPRO_VALORDES = Number(dblDescuento);
    item.ENCFACPRO_PORCEDES = Number(dblPorcentaje);
    item.ENCFACPRO_VALORIVA = Number(dblIva);
    item.ENCFACPRO_PORCEIVA = Number(dblTarifa);
    item.ENCFACPRO_VALORICE = 0;
    item.ENCFACPRO_BASEIVA = Number(strBaseImponible);
    item.ENCFACPRO_BASEICE = 0;
    item.ENCFACPRO_BASECERO = 0;
    item.ENCFACPRO_COMENTARIO = '';
    item.ENCFACPRO_OTROS = 0;
    item.ENCFACPRO_DISTCARGOS = 'N';
    item.ENCFACPRO_NUMDIASPLAZO = 0;
    item.ENCFACPRO_IDCRETRI = this.idcreditoxml;
    item.ENCFACPRO_SERIE = strSerie;
    item.ENCFACPRO_AUTORIZACION = xml.AUTORIZACION;
    item.ENCFACPRO_TIPCOM = '01';
    item.MAEMOTIVO_CODIGO = '';
    item.ENCFACPRO_FECHACADFAC = strFechaEmision;
    item.ENCFACPRO_NUMINGRESO = '';
    item.ENCFACPRO_NUMLIQUIDACION = '';
    item.ENCFACPRO_TIPODES = 'V';

    item.ENCFACPRO_GASTO = '';
    item.ENCORDCOM_NUMERO = '';
    item.ENCFACPRO_NOOBJETOIVA = '';
    item.ENCFACPRO_BASENOOBJIVA = 0;

    this.encfacproService.importarEncfacpro(item).subscribe(encs => {
      for (const enc of encs) {
        if (enc.V_ENCFACPRO_NUMERO === '1') {
          this.messageService.add({
            key: 'encfacproimpxml',
            severity: 'warn',
            summary: 'Información',
            detail: 'Númeración general no existe para las facturas de proveedores'
          });
          return;
        }
        strNumFactura = enc.V_ENCFACPRO_NUMERO;

        this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', strNumFactura, 'I',
          '', '01', '', '', '', '').subscribe();
        this.encfacproService.erConCodigo1Proveedor1(strRuc).subscribe(er => {
          if (er !== null) {
            if (er[0] !== undefined) {
              strConCodigo = er[0].CON_CODIGO1;
            }
          }

          // INSERTA EL DETALLE DE LA FACTURA


          const sesion = myXML.getElementsByTagName('detalles')[0];

          const listaAplicaciones = sesion.getElementsByTagName('detalle');

          let strDescripcion = '';
          for (let i = 0; i < listaAplicaciones.length; i++) {
            const nodo = listaAplicaciones[i];

            // let strCodigoPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
            // const strPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
            //CDPJ
            let strCodigoPrincipal = ''
            if (nodo.getElementsByTagName('codigoPrincipal')[0] !== undefined) {
              strCodigoPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
              const strPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
            }
            //CDPJ
            let strCodigoPrincipal2 = '';

            let strCodigoAuxiliar = '';
            if (nodo.getElementsByTagName('codigoAuxiliar')[0] !== undefined) {
              strCodigoAuxiliar = nodo.getElementsByTagName('codigoAuxiliar')[0].childNodes[0].nodeValue;
            }

            const strCodigoTmpPrincipal = strCodigoPrincipal;
            let strSentencia = '';

            this.encfacproService.getArtcodigoTrnCodigoalt(strCodigoPrincipal).subscribe(data => {
              for (const rs of data) {
                strCodigoPrincipal = rs.ART_CODIGO === null ? '' : rs.ART_CODIGO;
              }

              this.encfacproService.getArtcodigoTrnCodigoalt(strCodigoAuxiliar).subscribe(data2 => {
                for (const rs2 of data2) {
                  strCodigoPrincipal2 = rs2.ART_CODIGO === null ? '' : rs2.ART_CODIGO;
                }


                if (strCodigoPrincipal === '') {
                  if (strCodigoAuxiliar !== '') {
                    strCodigoPrincipal = strCodigoPrincipal2;
                  }
                }

                strDescripcion = nodo.getElementsByTagName('descripcion')[0].childNodes[0].nodeValue;
                const strCantidad = nodo.getElementsByTagName('cantidad')[0].childNodes[0].nodeValue;
                const strPrecioUnitario = nodo.getElementsByTagName('precioUnitario')[0].childNodes[0].nodeValue;
                const strDescuento = nodo.getElementsByTagName('descuento')[0].childNodes[0].nodeValue;
                const strPrecioTotalSinImpuesto = nodo.getElementsByTagName('precioTotalSinImpuesto')[0].childNodes[0].nodeValue;

                const impuestos = nodo.getElementsByTagName('impuestos')[0];
                const impuesto = impuestos.getElementsByTagName('impuesto')[0];
                const strCodigoImp = impuesto.getElementsByTagName('codigo')[0].childNodes[0].nodeValue;
                const strCodigoPocentjeImp = impuesto.getElementsByTagName('codigoPorcentaje')[0].childNodes[0].nodeValue;
                const strTarifaImp = impuesto.getElementsByTagName('tarifa')[0].childNodes[0].nodeValue;
                const strBaseImponibleImp = impuesto.getElementsByTagName('baseImponible')[0].childNodes[0].nodeValue;
                const strValorImp = impuesto.getElementsByTagName('valor')[0].childNodes[0].nodeValue;


                let dblCantidad = Number(strCantidad);
                let dblPrecioUnitario = Number(strPrecioUnitario);
                const dblDescuentoDet = Number(strDescuento);
                const dblPrecioTotalSinImpuesto = Number(strPrecioTotalSinImpuesto);
                const dblValorImp = Number(strValorImp);
                const dblBaseImponibleImp = Number(strBaseImponibleImp);

                const dblTarifaDet = Number(strTarifaImp);

                const intINVCAJFRA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INVCAJFRA').CFG_VALOR1;
                const intINVFRACAJ = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INVFRACAJ').CFG_VALOR1;
                const intFUNLAB = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FUNLAB').CFG_VALOR1;
                const intFUNFAR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FUNFAR').CFG_VALOR1;

                strSentencia = 'select maefar_fraccion from inv_maefar where art_codigo=\'' + strCodigoPrincipal + '\'';

                this.encfacproService.getMaefarFaccion(strCodigoPrincipal).subscribe(data3 => {
                  if ((intINVCAJFRA === 1 || intINVFRACAJ === 1) && intFUNLAB === 0) {
                    // NO POR LOTE
                    if (intFUNFAR === 1) {
                      for (const rs3 of data3) {
                        intFracciones = rs3.MAEFAR_FRACCION;
                      }

                      if (intFracciones === 0) {
                        intFracciones = 1;
                      }

                      dblCantidad = dblCantidad * intFracciones;
                      dblPrecioUnitario = dblPrecioUnitario / intFracciones;

                    }
                  }

                  const itemdet: ComDetfacpro = {};
                  itemdet.ENCFACPRO_NUMERO = strNumFactura;
                  itemdet.COM_CODIGO = '01';
                  itemdet.DETFACPRO_LINEA = 0;
                  itemdet.DETFACPRO_TIPODET = 'A';
                  itemdet.BOD_CODIGO = this.bodcodigoxml;
                  itemdet.DETFACPRO_CODIGO = strCodigoPrincipal;
                  itemdet.DETFACPRO_DESCRIPCION = strDescripcion;
                  itemdet.DETFACPRO_UNIDAD = 'XXX';  // unidad (se envía este valor para que lea la unidad de costeo)
                  itemdet.DETFACPRO_CANTIDAD = Number(dblCantidad);
                  itemdet.DETFACPRO_ENTREGADO = 0; // Entregado
                  itemdet.DETFACPRO_COSTO = Number(dblPrecioUnitario);
                  itemdet.DETFACPRO_VALDES = Number(dblDescuentoDet);
                  itemdet.DETFACPRO_PORDES = 0;
                  itemdet.DETFACPRO_TOTAL = Number(dblPrecioTotalSinImpuesto);
                  itemdet.DETFACPRO_PORIVA = Number(dblTarifaDet);
                  itemdet.DETFACPRO_IVA = Number(dblValorImp);
                  itemdet.DETFACPRO_TRIBICE = 'N';
                  itemdet.DETFACPRO_ICE = 0;
                  itemdet.DETFACPRO_PORCEICE = 0;
                  itemdet.DETFACPRO_BASEIVA = Number(dblBaseImponibleImp);
                  itemdet.DETFACPRO_BASEICE = 0;
                  itemdet.DETFACPRO_BASECERO = 0;
                  itemdet.ENCORDCOM_NUMERO = '';
                  itemdet.DETORDCOM_LINEA = 0;
                  itemdet.ENCNOTREC_NUMERO = '';
                  itemdet.DETNOTREC_LINEA = 0;
                  // Control de la promoción
                  itemdet.DETFACPRO_PROMOCION = '';
                  itemdet.DETFACPRO_TOTALINCP = Number(dblPrecioTotalSinImpuesto);
                  itemdet.DETFACPRO_PORDES2 = 0;
                  itemdet.DETFACPRO_PORDES3 = 0;
                  itemdet.CEN_CODIGO = this.centrocostosxml;
                  itemdet.DETFACPRO_FACTOR = 0;
                  itemdet.DETFACPRO_PRECIOFOB = 0;
                  itemdet.DETFACPRO_ESQUEMADOC = '';
                  itemdet.DETFACPRO_TIPOSRI = '';
                  itemdet.DETFACPRO_PRECIOA = 0;
                  itemdet.DETFACPRO_PRECIOB = 0;
                  itemdet.DETFACPRO_PRECIOC = 0;
                  itemdet.TRNSOLFAC_CODIGO = '';
                  itemdet.TRNSOLFAC_LINEA = 0;
                  itemdet.ENCREQ_NUMERO = '';
                  itemdet.DETREQ_LINEA = 0;
                  itemdet.ENCGRE_CODIGO = '';
                  itemdet.DETGRE_LINEA = 0;
                  itemdet.DETFACPRO_BASENOOBJIVA = 0;
                  itemdet.DETFACPRO_TRIBASENOOBJIVA = '';

                  this.encfacproService.importarDetfacpro(itemdet).subscribe(dets => {
                    this.auditoriagral.registrarAuditoria('COM_DETFACPRO', strNumFactura + '/' + strCodigoPrincipal, 'I',
                      '', '01', '', '', '', '').subscribe();
                  });
                });
              });
            });
          }

          this.messageService.add({
            key: 'encfacproimpxml',
            severity: 'success',
            summary: 'Información',
            detail: 'Proceso terminó satisfactoriamente Fac.' + strNumFactura
          });
          this.ActualizarDatosaAdi('FACT ' + strSecuencia + ' - ' + strRazonSocial, strNumFactura);
          this.encfacproService.ActualizaDatosProm(strNumFactura);
          this.guardarDatosUbicacionDocElec('', strNumFactura);

          xml.FACTURA = strNumFactura;
        });

        this.guardarCuentaXPagarXml(strNumFactura, strSerie, strSecuencia, strRuc,
          strConCodigo, dblTotal, strFechaEmision, 'Factura electrónica' + xml.ARCHIVO);

      }
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'encfacproimpxml',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }

  leerDatosXmlRetComprasAutF2(xml) {
    let cadena = '';
    let strNumFactura = '';
    let strConCodigo = '';

    cadena = xml.TEXTO;

    const parser = new DOMParser();
    const myXML = parser.parseFromString(cadena, 'text/xml');

    const InfoTributaria = myXML.getElementsByTagName('infoTributaria')[0];

    const strRazonSocial = InfoTributaria.getElementsByTagName('razonSocial')[0].childNodes[0].nodeValue;
    const strRuc = InfoTributaria.getElementsByTagName('ruc')[0].childNodes[0].nodeValue;
    const strEstable = InfoTributaria.getElementsByTagName('estab')[0].childNodes[0].nodeValue;
    const strPuntoEmision = InfoTributaria.getElementsByTagName('ptoEmi')[0].childNodes[0].nodeValue;
    const strSecuencia = InfoTributaria.getElementsByTagName('secuencial')[0].childNodes[0].nodeValue;


    const strDireccionMatriz = InfoTributaria.getElementsByTagName('dirMatriz')[0].childNodes[0].nodeValue;
    const strCodDoc = InfoTributaria.getElementsByTagName('codDoc')[0].childNodes[0].nodeValue;

    const strSerie = strEstable + strPuntoEmision;

    const InfoFactura = myXML.getElementsByTagName('infoFactura')[0];

    const strFechaEmision = InfoFactura.getElementsByTagName('fechaEmision')[0].childNodes[0].nodeValue;
    const strTipoIdentiComprador = InfoFactura.getElementsByTagName('tipoIdentificacionComprador')[0].childNodes[0].nodeValue;
    const strRazonSocialComprador = InfoFactura.getElementsByTagName('razonSocialComprador')[0].childNodes[0].nodeValue;
    const strIdentificacionComprador = InfoFactura.getElementsByTagName('identificacionComprador')[0].childNodes[0].nodeValue;
    const strTotalSinImpuestos = InfoFactura.getElementsByTagName('totalSinImpuestos')[0].childNodes[0].nodeValue;
    const strTotalDescuento = InfoFactura.getElementsByTagName('totalDescuento')[0].childNodes[0].nodeValue;

    const totalConImpuestos = InfoFactura.getElementsByTagName('totalConImpuestos')[0];
    const totalImpuesto = totalConImpuestos.getElementsByTagName('totalImpuesto')[0];
    const strCodigo = totalImpuesto.getElementsByTagName('codigo')[0].childNodes[0].nodeValue;

    let strCodigoPorcentaje = '';
    if (totalImpuesto.getElementsByTagName('codigoPorcentaje')[0] !== undefined) {
      strCodigoPorcentaje = totalImpuesto.getElementsByTagName('codigoPorcentaje')[0].childNodes[0].nodeValue;
    } else {
      strCodigoPorcentaje = '0';
    }

    let strDescuentoAdicional = '0';
    if (totalImpuesto.getElementsByTagName('descuentoAdicional')[0] !== undefined) {
      strDescuentoAdicional = totalImpuesto.getElementsByTagName('descuentoAdicional')[0].childNodes[0].nodeValue;
    } else {
      strDescuentoAdicional = '0';
    }

    const strBaseImponible = totalImpuesto.getElementsByTagName('baseImponible')[0].childNodes[0].nodeValue;

    let strTarifa = '12';
    if (totalImpuesto.getElementsByTagName('tarifa')[0] !== undefined) {
      strTarifa = totalImpuesto.getElementsByTagName('tarifa')[0].childNodes[0].nodeValue;
    }

    const strValor = totalImpuesto.getElementsByTagName('valor')[0].childNodes[0].nodeValue;

    const strImporteTotal = InfoFactura.getElementsByTagName('importeTotal')[0];


    const dblDescuento = Number(strDescuentoAdicional);
    const dblNeto = Number(strBaseImponible);
    const dblIva = Number(strValor);
    const dblTotal = dblNeto + dblIva;
    const dblTarifa = Number(strTarifa);
    let dblPorcentaje = 0;
    let intFracciones = 0;
    const strCodigoTmpPrincipal = '';

    // calculo del porcetaje de descuento
    if (dblDescuento !== 0) {
      dblPorcentaje = 0;
    }

    // Guardar la cabecera de la factura

    const item: ComEncfacpro = {};

    item.ENCFACPRO_NUMERO = '';
    item.COM_CODIGO = '01';
    item.ENCFACPRO_FECHAEMISION = strFechaEmision;
    item.ENCFACPRO_FECHAVENCIMIENTO = strFechaEmision;
    item.ENCFACPRO_FECHAREC = strFechaEmision;
    item.ENCFACPRO_IVA = this.selecttipoiva.codigo//CDPJ;
    item.ENCFACPRO_ESTADO = 'P';
    item.PRO_CODIGO = strRuc;
    item.ENCFACPRO_CONTACTO = strRazonSocial;
    item.ENCFACPRO_REFERENCIA = strSecuencia;
    item.ENCFACPRO_TOTAL = Number(dblTotal);
    item.ENCFACPRO_TOTALNETO = Number(strBaseImponible);
    item.ENCFACPRO_VALORDES = Number(dblDescuento);
    item.ENCFACPRO_PORCEDES = Number(dblPorcentaje);
    item.ENCFACPRO_VALORIVA = Number(dblIva);
    item.ENCFACPRO_PORCEIVA = Number(dblTarifa);
    item.ENCFACPRO_VALORICE = 0;
    item.ENCFACPRO_BASEIVA = Number(strBaseImponible);
    item.ENCFACPRO_BASEICE = 0;
    item.ENCFACPRO_BASECERO = 0;
    item.ENCFACPRO_COMENTARIO = '';
    item.ENCFACPRO_OTROS = 0;
    item.ENCFACPRO_DISTCARGOS = 'N';
    item.ENCFACPRO_NUMDIASPLAZO = 0;
    item.ENCFACPRO_IDCRETRI = this.idcreditoxml;
    item.ENCFACPRO_SERIE = strSerie;
    item.ENCFACPRO_AUTORIZACION = xml.AUTORIZACION;
    item.ENCFACPRO_TIPCOM = '01';
    item.MAEMOTIVO_CODIGO = '';
    item.ENCFACPRO_FECHACADFAC = strFechaEmision;
    item.ENCFACPRO_NUMINGRESO = '';
    item.ENCFACPRO_NUMLIQUIDACION = '';
    item.ENCFACPRO_TIPODES = 'V';

    item.ENCFACPRO_GASTO = '';
    item.ENCORDCOM_NUMERO = '';
    item.ENCFACPRO_NOOBJETOIVA = '';
    item.ENCFACPRO_BASENOOBJIVA = 0;
    item.TRNFORMAPAGO_CODIGO = '';
    //* VALIDA PRIMERO QUE EL PORCENTAJE DE IVA EXISTA DENTRO DE LA TABLA DE IVAS CDPJ
    const sesion = myXML.getElementsByTagName('detalles')[0];
    const listaAplicaciones = sesion.getElementsByTagName('detalle');

    let strDescripcion = '';

    for (let i = 0; i < listaAplicaciones.length; i++) {
      const nodo = listaAplicaciones[i];
      const impuestos = nodo.getElementsByTagName('impuestos')[0];
      const impuesto = impuestos.getElementsByTagName('impuesto')[0];
      const strTarifaImp = impuesto.getElementsByTagName('tarifa')[0].childNodes[0].nodeValue;
      const dblTarifaDet = Number(strTarifaImp);
      const strCodigoPocentjeImp = impuesto.getElementsByTagName('codigoPorcentaje')[0].childNodes[0].nodeValue;//TARIIVA_CODIGO
      let dato = this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(dblTarifaDet))
      if (dato === undefined || dato === null) {
        this.messageService.add({
          key: 'encfacproimpxml',
          severity: 'warn',
          summary: 'Error en IVA',
          detail: 'El porcentaje ' + dblTarifaDet + ' no forma parte de los porcentajes permitidos'
        });
        return;
      }
      let dato2 = this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === strCodigoPocentjeImp.toString())
      if (dato2 === undefined || dato2 === null) {
        this.messageService.add({
          key: 'encfacproimpxml',
          severity: 'warn',
          summary: 'Error en IVA',
          detail: 'El código ' + strCodigoPocentjeImp + ' no forma parte de los códigos permitidos'
        });
        return;
      }
    }
    //CDPJ
    this.encfacproService.insertarEncabezadoFact(item).subscribe(encs => {
      for (const enc of encs) {
        if (enc.V_ENCFACPRO_NUMERO === '1') {
          this.messageService.add({
            key: 'encfacproimpxml',
            severity: 'warn',
            summary: 'Información',
            detail: 'Númeración general no existe para las facturas de proveedores'
          });
          return;
        }
        strNumFactura = enc.V_ENCFACPRO_NUMERO;

        this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', strNumFactura, 'I',
          '', '01', '', '', '', '').subscribe();
        this.encfacproService.erConCodigo1Proveedor1(strRuc).subscribe(er => {
          if (er !== null) {
            if (er[0] !== undefined) {
              strConCodigo = er[0].CON_CODIGO1;
            }
          }

          // INSERTA EL DETALLE DE LA FACTURA


          const sesion = myXML.getElementsByTagName('detalles')[0];

          const listaAplicaciones = sesion.getElementsByTagName('detalle');

          let strDescripcion = '';
          for (let i = 0; i < listaAplicaciones.length; i++) {
            const nodo = listaAplicaciones[i];

            // let strCodigoPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
            // const strPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
            //CDPJ
            let strCodigoPrincipal = ''
            if (nodo.getElementsByTagName('codigoPrincipal')[0] !== undefined) {
              strCodigoPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
              const strPrincipal = nodo.getElementsByTagName('codigoPrincipal')[0].childNodes[0].nodeValue;
            }
            //CDPJ
            let strCodigoPrincipal2 = '';

            let strCodigoAuxiliar = '';
            if (nodo.getElementsByTagName('codigoAuxiliar')[0] !== undefined) {
              strCodigoAuxiliar = nodo.getElementsByTagName('codigoAuxiliar')[0].childNodes[0].nodeValue;
            }

            const strCodigoTmpPrincipal = strCodigoPrincipal;
            let strSentencia = '';

            strSentencia = 'select art_codigo from inv_trncodaltart where art_codigoalterno=\'' + strCodigoPrincipal + '\'';

            this.encfacproService.getArtcodigoTrnCodigoalt(strCodigoPrincipal).subscribe(data => {
              for (const rs of data) {
                strCodigoPrincipal = rs.ART_CODIGO === null ? '' : rs.ART_CODIGO;
              }
              strSentencia = 'select art_codigo from inv_trncodaltart where art_codigoalterno=\'' + strCodigoAuxiliar + '\'';

              this.encfacproService.getArtcodigoTrnCodigoalt(strCodigoAuxiliar).subscribe(data2 => {
                for (const rs2 of data2) {
                  strCodigoPrincipal2 = rs2.ART_CODIGO === null ? '' : rs2.ART_CODIGO;
                }


                if (strCodigoPrincipal === '') {
                  if (strCodigoAuxiliar !== '') {
                    strCodigoPrincipal = strCodigoPrincipal2;
                  }
                }

                strDescripcion = nodo.getElementsByTagName('descripcion')[0].childNodes[0].nodeValue;
                const strCantidad = nodo.getElementsByTagName('cantidad')[0].childNodes[0].nodeValue;
                const strPrecioUnitario = nodo.getElementsByTagName('precioUnitario')[0].childNodes[0].nodeValue;
                const strDescuento = nodo.getElementsByTagName('descuento')[0].childNodes[0].nodeValue;
                const strPrecioTotalSinImpuesto = nodo.getElementsByTagName('precioTotalSinImpuesto')[0].childNodes[0].nodeValue;

                const impuestos = nodo.getElementsByTagName('impuestos')[0];
                const impuesto = impuestos.getElementsByTagName('impuesto')[0];
                const strCodigoImp = impuesto.getElementsByTagName('codigo')[0].childNodes[0].nodeValue;
                const strCodigoPocentjeImp = impuesto.getElementsByTagName('codigoPorcentaje')[0].childNodes[0].nodeValue;
                const strTarifaImp = impuesto.getElementsByTagName('tarifa')[0].childNodes[0].nodeValue;
                const strBaseImponibleImp = impuesto.getElementsByTagName('baseImponible')[0].childNodes[0].nodeValue;
                const strValorImp = impuesto.getElementsByTagName('valor')[0].childNodes[0].nodeValue;


                let dblCantidad = Number(strCantidad);
                let dblPrecioUnitario = Number(strPrecioUnitario);
                const dblDescuentoDet = Number(strDescuento);
                const dblPrecioTotalSinImpuesto = Number(strPrecioTotalSinImpuesto);
                const dblValorImp = Number(strValorImp);
                const dblBaseImponibleImp = Number(strBaseImponibleImp);

                const dblTarifaDet = Number(strTarifaImp);

                const intINVCAJFRA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INVCAJFRA').CFG_VALOR1;
                const intINVFRACAJ = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INVFRACAJ').CFG_VALOR1;
                const intFUNLAB = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FUNLAB').CFG_VALOR1;
                const intFUNFAR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FUNFAR').CFG_VALOR1;

                strSentencia = 'select maefar_fraccion from inv_maefar where art_codigo=\'' + strCodigoPrincipal + '\'';

                this.encfacproService.getMaefarFaccion(strCodigoPrincipal).subscribe(data3 => {
                  if ((intINVCAJFRA === 1 || intINVFRACAJ === 1) && intFUNLAB === 0) {
                    // NO POR LOTE
                    if (intFUNFAR === 1) {
                      for (const rs3 of data3) {
                        intFracciones = rs3.MAEFAR_FRACCION;
                      }

                      if (intFracciones === 0) {
                        intFracciones = 1;
                      }

                      dblCantidad = dblCantidad * intFracciones;
                      dblPrecioUnitario = dblPrecioUnitario / intFracciones;

                    }
                  }

                  const itemdet: ComDetfacpro = {};
                  itemdet.ENCFACPRO_NUMERO = strNumFactura;
                  itemdet.COM_CODIGO = '01';
                  itemdet.DETFACPRO_LINEA = 0;
                  itemdet.DETFACPRO_TIPODET = 'A';
                  itemdet.BOD_CODIGO = this.bodcodigoxml;
                  itemdet.DETFACPRO_CODIGO = strCodigoPrincipal;
                  itemdet.DETFACPRO_DESCRIPCION = strDescripcion;
                  itemdet.DETFACPRO_UNIDAD = 'XXX';  // unidad (se envía este valor para que lea la unidad de costeo)
                  itemdet.DETFACPRO_CANTIDAD = Number(dblCantidad);
                  itemdet.DETFACPRO_ENTREGADO = 0; // Entregado
                  itemdet.DETFACPRO_COSTO = Number(dblPrecioUnitario);
                  itemdet.DETFACPRO_VALDES = Number(dblDescuentoDet);
                  itemdet.DETFACPRO_PORDES = 0;
                  itemdet.DETFACPRO_TOTAL = Number(dblPrecioTotalSinImpuesto);
                  itemdet.DETFACPRO_PORIVA = Number(dblTarifaDet);
                  itemdet.DETFACPRO_IVA = Number(dblValorImp);
                  itemdet.DETFACPRO_TRIBICE = 'N';
                  itemdet.DETFACPRO_ICE = 0;
                  itemdet.DETFACPRO_PORCEICE = 0;
                  itemdet.DETFACPRO_BASEIVA = Number(dblBaseImponibleImp);
                  itemdet.DETFACPRO_BASEICE = 0;
                  itemdet.DETFACPRO_BASECERO = 0;
                  itemdet.ENCORDCOM_NUMERO = '';
                  itemdet.DETORDCOM_LINEA = 0;
                  itemdet.ENCNOTREC_NUMERO = '';
                  itemdet.DETNOTREC_LINEA = 0;
                  // Control de la promoción
                  itemdet.DETFACPRO_PROMOCION = '';
                  itemdet.DETFACPRO_TOTALINCP = Number(dblPrecioTotalSinImpuesto);
                  itemdet.DETFACPRO_PORDES2 = 0;
                  itemdet.DETFACPRO_PORDES3 = 0;
                  itemdet.CEN_CODIGO = this.centrocostosxml;
                  itemdet.DETFACPRO_FACTOR = 0;
                  itemdet.DETFACPRO_PRECIOFOB = 0;
                  itemdet.DETFACPRO_ESQUEMADOC = '';
                  itemdet.DETFACPRO_TIPOSRI = '';
                  itemdet.DETFACPRO_PRECIOA = 0;
                  itemdet.DETFACPRO_PRECIOB = 0;
                  itemdet.DETFACPRO_PRECIOC = 0;
                  itemdet.TRNSOLFAC_CODIGO = '';
                  itemdet.TRNSOLFAC_LINEA = 0;
                  itemdet.ENCREQ_NUMERO = '';
                  itemdet.DETREQ_LINEA = 0;
                  itemdet.ENCGRE_CODIGO = '';
                  itemdet.DETGRE_LINEA = 0;
                  itemdet.DETFACPRO_BASENOOBJIVA = 0;
                  itemdet.DETFACPRO_TRIBASENOOBJIVA = '';
                  itemdet.DETFACPRO_CANTIDADUND = '';
                  itemdet.TIPGAS_CODIGO = '';
                  itemdet.TARIIVA_CODIGO = strCodigoPocentjeImp.toString();//CDPJ

                  this.encfacproService.insertarDetalleFactura(itemdet).subscribe(() => {
                    this.auditoriagral.registrarAuditoria('COM_DETFACPRO', strNumFactura + '/' + strCodigoPrincipal, 'I',
                      '', '01', '', '', '', '').subscribe();
                  });
                });
              });
            });
          }

          this.messageService.add({
            key: 'encfacproimpxml',
            severity: 'success',
            summary: 'Información',
            detail: 'Proceso terminó satisfactoriamente Fac.' + strNumFactura
          });
          this.ActualizarDatosaAdi('FACT ' + strSecuencia + ' - ' + strRazonSocial, strNumFactura);
          this.encfacproService.ActualizaDatosProm(strNumFactura);
          this.guardarDatosUbicacionDocElec('', strNumFactura);

          xml.FACTURA = strNumFactura;
        });

        this.guardarCuentaXPagarXml(strNumFactura, strSerie, strSecuencia, strRuc,
          strConCodigo, dblTotal, strFechaEmision, 'Factura electrónica' + xml.ARCHIVO);

      }
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'encfacproimpxml',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }

  ActualizarDatosaAdi(strObservaciones, strEncFacPro) {
    let strSentencia = 'Update com_encfacpro set  encfacpro_comentario=' + '\'' + strObservaciones + '\'';
    strSentencia = strSentencia + ' ,encfacpro_flag=' + '\'' + '10' + '\'';
    strSentencia = strSentencia + ' where encfacpro_numero=' + '\'' + strEncFacPro + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

    this.encfacproService.actualizarDatosAdi(strObservaciones, strEncFacPro).subscribe();


  }

  guardarCuentaXPagarXml(strNumeroFactura, strSerie, strNumeroCompr, strCodProveedor,
    strCntaContPro, dblTotal, strFechaEmision, strComentario) {

    let strTRNCOBRONroDoc = '';

    const item: CxpTrncobro = {};

    item.TRNCOBRO_NRODOC = strNumeroFactura;
    item.COM_CODIGO = '01';
    item.TRNCOBRO_FECHATRN = strFechaEmision;
    item.TRNCOBRO_TIPODOC = 'FC';
    item.TRNCOBRO_NROCOMPROBANTE = strSerie + strNumeroCompr;
    item.TRNCOBRO_CONCEPTO = strComentario;
    item.TRNCOBRO_IMPORTE = Number(dblTotal);
    item.TRNCOBRO_FECHAVENCE = strFechaEmision;
    item.PRO_CODIGO = strCodProveedor;
    item.APLORG_CODIGO = 'COM';
    item.TRNCOBRO_FECHARECEPCION = strFechaEmision;
    item.TRNCOBRO_REFERENCIAEXTERNA = '';
    item.CON_CODIGO = strCntaContPro;

    this.encfacproService.insertarCxpTrncobro(item).subscribe(data => {
      for (const rs of data) {
        strTRNCOBRONroDoc = rs[':B1'] === null ? '' : rs[':B1'];
        this.auditoriagral.registrarAuditoria('CXP_TRNCOBRO', strTRNCOBRONroDoc, 'I',
          '', '01', '', '', '', '').subscribe();
      }
    });
  } // guardarCuentaXPagar

  numeroBloqueArchivos() {
    this.encfacproService.erDatoEncfacpro1().subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.numerobloquearchivosxml = er[0].DATO;
        }
      }
    });
  }


  guardarDatosUbicacionDocElec(strPathXml, strEncFacPro) {
    let strSentencia = 'Update com_encfacpro set  encfacpro_localizacionxml=' + '\'' + strPathXml + '\' , encfacpro_localizacionpdf=' + '\'' + '' + '\'';
    strSentencia = strSentencia + ', ENCFACPRO_FLAG=2 ,ENCFACPRO_FORMAPAGO=\'C\',ENCFACPRO_BLOQUEFACXML=\'' + this.numerobloquearchivosxml + '\'';
    strSentencia = strSentencia + ', encfacpro_pagolocalext=\'01\'';
    strSentencia = strSentencia + ' where encfacpro_numero=' + '\'' + strEncFacPro + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

    this.encfacproService.actualizarDatosUbidocelec(strPathXml, strEncFacPro, this.numerobloquearchivosxml).subscribe();
  }

  async verificarDatosAnexos() {

    for (const dt of this.datosanexos.datosanexService.formapago) {
      if (dt.TRNFORMAPAGO_CODIGO === '') {
        this.datosanexos.datosanexService.formapago.splice(this.datosanexos.datosanexService.formapago.findIndex(
          fp => fp.CLAVE === dt.CLAVE),
          1);
      }
    }

    if (this.datosanexos.datosFP.ENCFACPRO_PAGOLOCALEXT === '') {
      this.strtotalfac = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL))
      this.displayDialogDatosAnexos = true;
      this.messageService.add({
        key: 'datane',
        severity: 'warn',
        summary: 'Error',
        detail: 'Ingrese código del pago Local o al Exterior'
      });
      // } else if (Number(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALBD) > 1000
      //   && this.datosanexos.datosanexService.formapago.length === 0) {
      //   this.displayDialogDatosAnexos = true;
      //   this.messageService.add({
      //     key: 'datane',
      //     severity: 'warn',
      //     summary: 'Error',
      //     detail: 'Ingrese la forma de pago. La factura es mayor a 1000.'
      //   });
      // } 
    } else if (Number(await this.consultarFormapago()) !== 0) {//-------
      this.strtotalfac = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL))
      this.displayDialogDatosAnexos = true;
      // this.messageService.add({
      //       key: 'datane',
      //       severity: 'warn',
      //       summary: 'Error',
      //       detail: 'Ingrese la forma de pago'
      //     });
    } else {
      if (this.datosanexos.botonGuardar === false) {
        this.confirmationService.confirm({
          message: 'Existen cambios, desea guardarlos?',
          header: 'Salir',
          icon: 'pi pi-exclamation-triangle',
          key: 'dataneconf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            //this.confirmationService.close();
            this.datosanexos.guardarRegistro();
            this.strtotalfac = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL))
            this.displayDialogDatosAnexos = true;//CDPJ

            this.messageService.add({
              key: 'encfacpro',
              severity: 'success',
              summary: 'Información',
              detail: 'Datos guardados correctamente.'
            });
          }
        });
      }else{
        this.displayDialogDatosAnexos = false;
      }
    }
  }
  async consultarFormapago() {
    let error = 0;
    try {
      const fp = await this.datosanexos.datosanexService.ejecutarConsultaPromise(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
      console.log('fp', fp)
      if (fp !== null && fp !== undefined && fp.length !== 0) {
        this.datosanexos.datosanexService.formapago = fp;
        //this.formapagoSelected = {};
        for (const clave in this.datosanexos.datosanexService.formapago) {
          console.log('clave', this.datosanexos.datosanexService.formapago[clave].CLAVE)
          this.datosanexos.datosanexService.formapago[clave].CLAVE = clave;
          if (this.datosanexos.datosanexService.formapago[clave].CLAVE === null ||
            this.datosanexos.datosanexService.formapago[clave].CLAVE === undefined ||
            this.datosanexos.datosanexService.formapago[clave].CLAVE === '') {
            error = error + 1;
          }
        }
        console.log('error', error)
        if (error !== 0) {
          this.strtotalfac = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL))
          this.displayDialogDatosAnexos = true;
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'La factura no tiene forma de pago.'
          });
        } else {

          this.displayDialogDatosAnexos = false;
          //this.salir.emit(true);
        }
      } else {
        //if (Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL)) >= 500) {
          this.strtotalfac = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL))
          this.displayDialogDatosAnexos = true;
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'La factura no tiene forma de pago.'
          });
        //}else{
          //this.displayDialogDatosAnexos = false;
        //}


      }
    } catch (error) {
      this.strtotalfac = Number(this.init.quitarComas(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTAL))
      this.displayDialogDatosAnexos = true;
      this.messageService.add({
        key: 'datane',
        severity: 'warn',
        summary: 'Información',
        detail: 'La faCtura no tiene forma de pago'
      });

    }
    //this.botonGuardar = true;
    return error;
  }

  salirDialog() {
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
    this.displayDialogDescuento = false;
    this.displayDialogDatosAnexos = false;
    this.displayDialogAutorizacion = false;
    this.displayDialogAsiento = false;
    this.displayDialogSeriales = false;
    this.displayDialogReembolsos = false;
    this.displayDialogSupervisor = false;
    this.displayDialogDistribucion = false;
    this.displayDialogPagoDocumento = false;
    this.displayDialogActualizacionPrecios = false;
    this.displayDialogLog = false;
    this.displayDialogCargarRetElec = false;
    this.displayDialogSubirXmlFactura = false;
    this.displayDialogSubirXmlFacturaFormato1 = false;
    this.displayDialogSubirXmlFacturaFormato2 = false;
    this.displayDialogReportes = false;
    this.displayDialogLotes = false;
  }

  verAsientoContable() {
    if (this.encfacproService.encfacproarray[this.indicador].ASI_NRO !== '' && this.encfacproService.encfacproarray[this.indicador].ASI_NRO !== null) {
      this.asiento = this.encfacproService.encfacproarray[this.indicador].ASI_NRO;
      this.displayAsiContable = true;
    } else {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'info',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });

    }

  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  // distribuirGastosSobreCostos() {
  //   this.encfacproService.calcularTotales();
  //   let strDistribCargos = '';

  //   if (this.chkDisCargosSobreCostos === true) {
  //     strDistribCargos = 'S';
  //   } else {
  //     strDistribCargos = 'N';
  //   }

  //   let strSentencia = 'Update com_encfacpro set  encfacpro_total=' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALBD + ' , ' +
  //     'encfacpro_otros=' + this.encfacproService.encfacproarray[this.indicador].TOTALCARGOS;
  //   strSentencia = strSentencia + ',Encfacpro_distcargos=' + '\'' + strDistribCargos + '\'';
  //   strSentencia = strSentencia + ' where encfacpro_numero=' + '\'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';
  //   strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

  //   this.encfacproService.actualizarDistribGastosCostos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALBD, this.encfacproService.encfacproarray[this.indicador].TOTALCARGOS,
  //     strDistribCargos, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(() => {
  //       this.encfacproService.distribuirCargosCom(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe();
  //       this.guardarCuentaXPagar('cargos');

  //       this.encfacproService.sumarTotalCargos();
  //       this.cancelar();
  //     });

  // }
  //CDPJ
  async distribuirGastosSobreCostos() {
    this.encfacproService.calcularTotales();
    let strDistribCargos = '';

    if (this.chkDisCargosSobreCostos === true) {
      strDistribCargos = 'S';
    } else {
      strDistribCargos = 'N';
    }

    let strSentencia = 'Update com_encfacpro set  encfacpro_total=' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALBD + ' , ' +
      'encfacpro_otros=' + this.encfacproService.encfacproarray[this.indicador].TOTALCARGOS;
    strSentencia = strSentencia + ',Encfacpro_distcargos=' + '\'' + strDistribCargos + '\'';
    strSentencia = strSentencia + ' where encfacpro_numero=' + '\'' + this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

    // this.encfacproService.actualizarDistribGastosCostos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALBD, this.encfacproService.encfacproarray[this.indicador].TOTALCARGOS,
    //   strDistribCargos, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).subscribe(() => {
    await this.encfacproService.actualizarDistribGastosCostos(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_TOTALBD, this.encfacproService.encfacproarray[this.indicador].TOTALCARGOS,
      strDistribCargos, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).toPromise()
    await this.encfacproService.distribuirCargosCom(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO).toPromise()//.subscribe();
    await this.guardarCuentaXPagar('cargos');
    await this.encfacproService.sumarTotalCargos();
    await this.cancelar();
    this.encfacproService.spin = false;//CDPJ
    // });


  }
  //CDPJ
  eliminarUltimaLineaDetalle() {
    if (this.encfacproService.detfacproarray[this.indicador].length > 0) {
      if ((this.encfacproService.detfacproarray[this.indicador][this.encfacproService.detfacproarray[this.indicador].length - 1].DETFACPRO_CODIGO === ''
        || this.encfacproService.detfacproarray[this.indicador][this.encfacproService.detfacproarray[this.indicador].length - 1].DETFACPRO_CODIGO === null
        || this.encfacproService.detfacproarray[this.indicador][this.encfacproService.detfacproarray[this.indicador].length - 1].DETFACPRO_CODIGO === undefined)
        && this.encfacproService.detfacproarray[this.indicador][this.encfacproService.detfacproarray[this.indicador].length - 1].DETFACPRO_TIPODET !== '*') {
        this.encfacproService.detfacproarray[this.indicador].splice(this.encfacproService.detfacproarray[this.indicador].length - 1, 1);
        this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);
      }
    }
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  async verificarExistenciaRetencion(strNumRetencion, strNumeroSerie, codigoRetencion) {

    let strSentencia = '';
    let bolResultado = false;

    try {
      const data = await this.encfacproService.verifExistenciaRetencion(strNumRetencion, strNumeroSerie, this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
      if (data.length > 0) {
        bolResultado = true;
      } else {
        bolResultado = false;
      }
    } catch (error) {
    }

    return bolResultado;

  }

  mostrarEstadisticas() {
    this.comprasRealizadas();
    this.topProductos();
  }

  async comprasRealizadas() {
    const fechaInicial = new Date();
    fechaInicial.setMonth(fechaInicial.getMonth() - 12);
    fechaInicial.setDate(1);


    const fechaAct = new Date();
    const rangoFechas: any[] = [];

    for (let i = 1; fechaInicial <= fechaAct; i++) {
      rangoFechas.push(this.datePipe.transform(fechaInicial, 'dd/MM/yyyy'));
      fechaInicial.setMonth(fechaInicial.getMonth() + 1);
    }


    try {
      const rs = await this.venEstadisticasService.comprasAcumuladas(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO);

      if (rs !== null) {

        const index1 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[0]);
        const valor1 = index1 >= 0 ? rs[index1].VENTA_TOTAL : 0;
        const index2 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[1]);
        const valor2 = index2 >= 0 ? rs[index2].VENTA_TOTAL : 0;
        const index3 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[2]);
        const valor3 = index3 >= 0 ? rs[index3].VENTA_TOTAL : 0;
        const index4 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[3]);
        const valor4 = index4 >= 0 ? rs[index4].VENTA_TOTAL : 0;
        const index5 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[4]);
        const valor5 = index5 >= 0 ? rs[index5].VENTA_TOTAL : 0;
        const index6 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[5]);
        const valor6 = index6 >= 0 ? rs[index6].VENTA_TOTAL : 0;
        const index7 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[6]);
        const valor7 = index7 >= 0 ? rs[index7].VENTA_TOTAL : 0;
        const index8 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[7]);
        const valor8 = index8 >= 0 ? rs[index8].VENTA_TOTAL : 0;
        const index9 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[8]);
        const valor9 = index9 >= 0 ? rs[index9].VENTA_TOTAL : 0;
        const index10 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[9]);
        const valor10 = index10 >= 0 ? rs[index10].VENTA_TOTAL : 0;
        const index11 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[10]);
        const valor11 = index11 >= 0 ? rs[index11].VENTA_TOTAL : 0;
        const index12 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[11]);
        const valor12 = index12 >= 0 ? rs[index12].VENTA_TOTAL : 0;
        const index13 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[12]);
        const valor13 = index13 >= 0 ? rs[index13].VENTA_TOTAL : 0;

        this.dataComprasProveedor = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [valor1, valor2, valor3, valor4, valor5, valor6, valor7,
              valor8, valor9, valor10, valor11, valor12, valor13]
          }
          ]
        };

      } else {
        this.dataComprasProveedor = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          ]
        };
      }
    } catch (err) {

    }
  }

  async topProductos() {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    const detalle: ComTopProductos[] = [];

    try {
      const rs = await this.venEstadisticasService.topProductos(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO, 'cantidad');
      if (rs !== null) {
        for (const item of rs) {
          const det: ComTopProductos = {};
          det.DETFACPRO_CODIGO = item.DETFACPRO_CODIGO;
          det.DETFACPRO_DESCRIPCION = item.DETFACPRO_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFACPRO_COSTO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: ComTopProductos = {};
        totales.DETFACPRO_CODIGO = '';
        totales.DETFACPRO_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFACPRO_COSTO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  async changeTabs() {
    if (this.index === 3) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.proveedorServicio.llenarEstadoCuenta(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO);
      }
    }

    if (this.index === 4) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.proveedorServicio.llenarTablaSaldos(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO);
      }
    }

    if (this.index === 5) {
      this.dataComprasProveedor = {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ]
      };

      this.dataVentasVsCompras = {
        labels: ['', ''],
        datasets: [
          {
            label: 'Ventas',
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0]
          },
          {
            label: 'Compras',
            backgroundColor: '#9CCC65',
            borderColor: '#7CB342',
            data: [0]
          }
        ]
      };

      this.detTopProductos = [];
      this.detalleExistencia = [];
      this.detMovimientosProv = [];
      this.detExistenciaBodegas = [];
      this.txtArtCodigo = '';
      this.txtArtDescripcion = '';
      this.txtExistenciaMax = '';
      this.txtExistenciaMin = '';
      this.txtArtUnidad = '';
      this.txtArtRotacion = '';
      this.txtInventarioIni = '';
      this.txtComprasPeriodo = '';
      this.txtTotalDisponible = '';
      this.txtVenUltimoPer = '';
      this.txtVenPromPerAnt = '';
      this.txtIndiceProvUltPer = '';
      this.txtIndiceProvPeriodos = '';
      this.txtComprasPlanificadas = '';
      this.txtTotalDisponibleCom = '';
      this.txtIndiceProvUltPer2 = '';
      this.txtIndiceProvPeriodos2 = '';

      const existencias: ComExistencias = {};
      existencias.COMPRAR = '';
      existencias.EXIST_MIN = '';
      existencias.EXISTENCIA = '';
      existencias.ORDENES_COMPRA = '';
      existencias.PEDIDOS_CLIENTES = '';
      existencias.SALDO = '';
      existencias.TOTAL_COMPRA = '';
      existencias.ULTCOSTO_COM = '';
      this.detalleExistencia.push(existencias);

    }
  }

  async orderByTopProd(tipo) {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    const detalle: ComTopProductos[] = [];
    let rs;

    try {
      if (tipo === 'total') {
        rs = await this.venEstadisticasService.topProductos(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO, 'total');
      }

      if (tipo === 'cantidad') {
        rs = await this.venEstadisticasService.topProductos(this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO, 'cantidad');
      }
      if (rs !== null) {
        for (const item of rs) {
          const det: ComTopProductos = {};
          det.DETFACPRO_CODIGO = item.DETFACPRO_CODIGO;
          det.DETFACPRO_DESCRIPCION = item.DETFACPRO_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFACPRO_COSTO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: ComTopProductos = {};
        totales.DETFACPRO_CODIGO = '';
        totales.DETFACPRO_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFACPRO_COSTO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  buscarArticulo() {
    this.detalleExistencia = [];
    this.detMovimientosProv = [];
    this.detExistenciaBodegas = [];

    this.txtArtCodigo = '';
    this.txtArtDescripcion = '';
    this.txtExistenciaMax = '';
    this.txtExistenciaMin = '';
    this.txtArtUnidad = '';
    this.txtArtRotacion = '';
    this.txtInventarioIni = '';
    this.txtComprasPeriodo = '';
    this.txtTotalDisponible = '';
    this.txtVenUltimoPer = '';
    this.txtVenPromPerAnt = '';
    this.txtIndiceProvUltPer = '';
    this.txtIndiceProvPeriodos = '';
    this.txtComprasPlanificadas = '';
    this.txtTotalDisponibleCom = '';
    this.txtIndiceProvUltPer2 = '';
    this.txtIndiceProvPeriodos2 = '';

    const existencias: ComExistencias = {};
    existencias.COMPRAR = '';
    existencias.EXIST_MIN = '';
    existencias.EXISTENCIA = '';
    existencias.ORDENES_COMPRA = '';
    existencias.PEDIDOS_CLIENTES = '';
    existencias.SALDO = '';
    existencias.TOTAL_COMPRA = '';
    existencias.ULTCOSTO_COM = '';
    this.detalleExistencia.push(existencias);

    const grafico = {
      labels: ['', ''],
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0]
        },
        {
          label: 'Compras',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [0]
        }
      ]
    };

    this.dataVentasVsCompras = grafico;


    this.opcionbusqueda = 'articulo';
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'NombreCorto', value: 'ART_NOMBREC' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Ubicación', value: 'ART_UBICACION' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Alterno3', value: 'ART_CODIGOALT3' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ART_ACTIVO=\'S\'';

    this.busqService.busquedaInvMaeArticulo1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async llenarMovByProvArt(tipo) {
    this.detMovimientosProv = [];
    const detalle: ComMovimientoProv[] = [];
    let rs;

    try {
      if (tipo === 'fecha') {
        rs = await this.venEstadisticasService.movimientoArtProv(this.txtArtCodigo, 'fecha');
      }

      if (tipo === 'costo') {
        rs = await this.venEstadisticasService.movimientoArtProv(this.txtArtCodigo, 'costo');
      }
      if (rs !== null) {
        for (const item of rs) {
          const det: ComMovimientoProv = {};
          det.PRO_CODIGO = item.PRO_CODIGO;
          det.PRO_NOMBRE = item.PRO_NOMBRE;
          det.ENCFACPRO_FECHAEMISION = this.datePipe.transform(item.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);


          detalle.push(det);
        }
        this.detMovimientosProv = detalle;
      }

    } catch (err) {

    }
  }

  async llenarTablaExistencia() {

    let rs1;
    let rs2;
    let rs3;
    let rs4;

    try {
      rs1 = this.venEstadisticasService.ultimoCostoCompra(this.txtArtCodigo);
      rs2 = this.venEstadisticasService.existenciaArticulo(this.txtArtCodigo);
      rs3 = this.venEstadisticasService.cantidadOrdCompra(this.txtArtCodigo);
      rs4 = this.venEstadisticasService.cantidadPedidos(this.txtArtCodigo);
    } catch (err) {

    }

    rs1 = await rs1;
    rs2 = await rs2;
    rs3 = await rs3;
    rs4 = await rs4;

    this.detalleExistencia = [];
    const existencias: ComExistencias = {};
    existencias.ULTCOSTO_COM = Number(rs1[0].ULTIMO_COSTO).toFixed(2);
    existencias.EXISTENCIA = Number(rs2[0].EXISTENCIA);
    existencias.ORDENES_COMPRA = Number(rs3[0].TOTAL_ORDCOM);
    existencias.EXIST_MIN = this.txtExistenciaMin;
    existencias.PEDIDOS_CLIENTES = Number(rs4[0].TOTAL_PEDIDOS);
    existencias.SALDO = Number(rs2[0].EXISTENCIA) + Number(rs3[0].TOTAL_ORDCOM) - Number(rs4[0].TOTAL_PEDIDOS);
    existencias.COMPRAR = existencias.SALDO < Number(this.txtExistenciaMin) ? Math.abs(existencias.SALDO) + Number(this.txtExistenciaMin) : 0;
    existencias.TOTAL_COMPRA = this.init.moneyValidation(Number(existencias.COMPRAR * Number(existencias.ULTCOSTO_COM)).toFixed(2));


    this.detalleExistencia.push(existencias);
  }

  async llenarExistenciaBodegas() {
    this.detExistenciaBodegas = [];
    const detalle: ComExistenciaBodegas[] = [];

    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;


    try {
      const rs = await this.venEstadisticasService.llenarExistenciaBod(this.txtArtCodigo);
      if (rs !== null) {
        for (const item of rs) {
          const det: ComExistenciaBodegas = {};
          det.BOD_CODIGO = item.BOD_CODIGO;
          det.BOD_NOMBRE = item.BOD_NOMBRE;
          det.EXISTENCIA = Number(item.EXISTENCIA).toFixed(2);
          det.PEDIDO = Number(item.PEDIDOS).toFixed(2);
          det.SALDO = Number(item.SALDO).toFixed(2);


          detalle.push(det);
        }
        this.detExistenciaBodegas = detalle;
      }

    } catch (err) {

    }
  }

  async ventasVsCompras() {
    let rs1;
    let rs2;

    try {

      rs1 = this.venEstadisticasService.ventasVsCompras(this.txtArtCodigo, 'V');
      rs2 = this.venEstadisticasService.ventasVsCompras(this.txtArtCodigo, 'C');

    } catch (err) {

    }
    rs1 = await rs1;
    rs2 = await rs2;

    const grafico = {
      labels: ['', ''],
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [rs1[0].TOTALVENTAS]
        },
        {
          label: 'Compras',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [rs2[0].TOTALCOMPRAS]
        }
      ]
    };

    this.dataVentasVsCompras = grafico;

  }

  async llenarIndiceProvisiones() {

    let rs1;
    let rs2;
    let rs3;
    let rs4;

    try {
      rs1 = this.venEstadisticasService.obtenerExistenciaArtIndProv(this.txtArtCodigo);
      rs2 = this.venEstadisticasService.comprasPeriodo(this.txtArtCodigo);
      rs3 = this.venEstadisticasService.comprasUltPeriodo(this.txtArtCodigo);
      rs4 = this.venEstadisticasService.ventasPeriodo(this.txtArtCodigo);
    } catch (err) {

    }

    rs1 = await rs1;
    rs2 = await rs2;
    rs3 = await rs3;
    rs4 = await rs4;

    this.txtInventarioIni = Number(rs1[0].EXISTENCIA_ACTUAL).toFixed(2);
    this.txtComprasPeriodo = Number(rs2[0].COMPRAS_PERIODO).toFixed(2);
    this.txtTotalDisponible = Number(rs1[0].EXISTENCIA_ACTUAL + rs2[0].COMPRAS_PERIODO).toFixed(2);

    this.txtVenUltimoPer = Number(rs3[0].VENTAS_ULTIMOPER).toFixed(2);
    this.txtVenPromPerAnt = Number(rs4[0].VENTAS_PERIODOS / 10).toFixed(2);


    this.txtIndiceProvUltPer = Number(this.txtVenUltimoPer) !== 0 ? Number(this.txtTotalDisponible) / Number(this.txtVenUltimoPer) :
      0;
    this.txtIndiceProvUltPer = Number(this.txtIndiceProvUltPer).toFixed(2);
    this.txtIndiceProvPeriodos = Number(this.txtVenPromPerAnt) !== 0 ? Number(this.txtTotalDisponible) / Number(this.txtVenPromPerAnt) :
      0;
    this.txtIndiceProvPeriodos = Number(this.txtIndiceProvPeriodos).toFixed(2);

  }

  calcularIndice() {
    this.txtTotalDisponibleCom = Number(this.txtTotalDisponible) + Number(this.txtComprasPlanificadas);
    this.txtTotalDisponibleCom = Number(this.txtTotalDisponibleCom).toFixed(2);

    this.txtIndiceProvUltPer2 = Number(this.txtVenUltimoPer) !== 0 ? Number(this.txtTotalDisponibleCom) / Number(this.txtVenUltimoPer) : 0;
    this.txtIndiceProvUltPer2 = Number(this.txtIndiceProvUltPer2).toFixed(2);
    this.txtIndiceProvPeriodos2 = Number(this.txtVenPromPerAnt) !== 0 ? Number(this.txtTotalDisponibleCom) / Number(this.txtVenPromPerAnt) :
      0;
    this.txtIndiceProvPeriodos2 = Number(this.txtIndiceProvPeriodos2).toFixed(2);
  }

  abrirLotes(params) {
    this.detfacproSelected = params;
    this.auxiliarlot++;
    this.displayDialogLotes = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO.length > 0 && this.detfacproSelected.ART_CADUCA === 'S';
  }

  cerrarLotes() {
    this.displayDialogLotes = false;
  }

  cerrarSeriales() {
    this.displayDialogSeriales = false;
  }

  async agregarCodigoAlternoXml() {
    let strSql = '';
    let rsServicio;
    let rsArticulo;
    let rs;
    for (const item of this.encfacproService.detfacproarray[this.indicador]) {

      try {
        rsServicio = this.encfacproService.erSerComCodigo1Prom(item.DETFACPRO_CODIGO);

        rsArticulo = this.encfacproService.erArtCodigo1Prom(item.DETFACPRO_CODIGO);

        rs = this.encfacproService.ertrndatosCodalt(item.DETFACPRO_CODIGOALT);

        rsServicio = await rsServicio;
        rsArticulo = await rsArticulo;
        rs = await rs;


        if (rs === null) {
          if (rsArticulo !== null) {
            strSql = 'Insert into INV_TRNDATOSALT (TRNDATOS_CODIGOALT, COM_CODIGO, TRNDATOS_TIPO, ART_CODIGO, SER_CODIGO)' +
              ' values(\'' + item.DETFACPRO_CODIGOALT + '\', \'01\' ,\'A\',\'' + item.DETFACPRO_CODIGO + '\',\'\')';
            await this.encfacproService.insertarArtTrnDatosAlt(item.DETFACPRO_CODIGOALT, item.DETFACPRO_CODIGO);
          }

          if (rsServicio !== null) {
            strSql = 'Insert into INV_TRNDATOSALT (TRNDATOS_CODIGOALT, COM_CODIGO, TRNDATOS_TIPO, ART_CODIGO, SER_CODIGO)' +
              ' values(\'' + item.DETFACPRO_CODIGOALT + '\', \'01\' ,\'S\',\'\',\'' + item.DETFACPRO_CODIGO + '\')';
            await this.encfacproService.insertarSerTrnDatosAlt(item.DETFACPRO_CODIGOALT, item.DETFACPRO_CODIGO);
          }


        }
      } catch (err) {
        console.log(err.error);
      }

    }

  }


  salirImportarXml() {
    this.displayDialogSubirXmlFactura = false;
  }

  cambioTrnretencionFE(param) {
    /* console.log(param);
    let index= this.encfacproService.trnretencionarray[this.indicador].map(x=>{return x.CLAVE}).indexOf(this.trnretencionSelected.CLAVE);
    console.log(index);
    this.encfacproService.trnretencionarray[this.indicador][index].TRNRETENCION_FECHAEMISION = this.datePipe.transform(param.newValue, 'dd/MM/yyyy');   
    this.aggrid.refreshaggridindex(this.encfacproService.trnretencionarray[this.indicador], this.defaultColDefRet.objeto, index);
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefRet.objeto].setFocusedCell(index, 'TRNRETENCION_FECHAEMISION'); */
  }

  async continuarFE() {
    this.closeSidebar();
    const msgclave = 'encfacpro';
    const strNumDoc = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;

    if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'No ha seleccionado un documento'
      });
      return;
    }

    if (this.datosfe.COM_FEACTIVADA === '0') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Facturación electrónica no está activada.'
      });
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === ''
      || this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === undefined
      || this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === null) {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento no ha iniciado el proceso de firma electrónica.'
      });
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === 'VA') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra en su estado inicial, reverse el proceso de firma electrónica.'
      });
      return;
    }

    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === 'NT') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra notificado.'
      });
      return;
    }


    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO_FE === 'NA') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Visualize el log. Corrija el documento y reverse el proceso de firma electrónica.'
      });
      return;
    }

    this.encfacproService.spin = true;
    const data = await this.utilitariosService.continuarFirmaElectronica(strNumDoc).catch(e => {
      this.encfacproService.spin = false;//CDPJ
    });
    this.encfacproService.spin = false;
    if (data !== 'NOAUTORIZADO') {
      this.messageService.add({
        key: msgclave,
        severity: 'success',
        summary: 'Información',
        detail: data
      });
    }
  }

  dlgAlmacenarDocumentos() {

    this.closeSidebar();
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO !== '') {
      this.displayDialogDocumentos = true;
    }

  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }

  recalcularkardex() {
    if (this.mensajekardex === null) {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'info',
        summary: 'Información',
        detail: 'No necesita recalculo de kardex'
      });
      return;
    }
    this.encfacproService.ActualizaDatosProm(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
  }

  buscarGuiaRemision() {
    if (this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO !== undefined) {
      if (this.encfacproService.encfacproarray[this.indicador].PRO_CODIGO.length === 0) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese el proveedor.'
        });
        return;
      }
    } else {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese el proveedor.'
      });
      return;
    }

    const strFecha = this.datePipe.transform(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');

    let strCondicion = " COM_CODIGO = '01'";
    strCondicion = strCondicion + " AND NVL(ENCGRE_ESTADO,'S') <> 'A' ";
    strCondicion = strCondicion
      + " AND TO_DATE(ENCGRE_FECHAEMISION,'DD/MM/YYYY') <= TO_DATE('"
      + strFecha + "','DD/MM/YYYY')";
    // strCondicion = strCondicion
    //   + " and not exists(select * from com_encfacpro r,com_detfacpro s where";
    // strCondicion = strCondicion
    //   + " r.encfacpro_numero=s.encfacpro_numero and r.com_codigo=s.com_codigo";
    // strCondicion = strCondicion
    //   + " and r.encfacpro_estado<>'A' and s.encgre_codigo=A.encgre_codigo)";//cdpj EL ORIGINAL NO TENIA A.encgre_codigo SOLO ERA encgre_codigo


    this.opcionbusqueda = 'agGui';
    this.types = [
      { label: 'CÓDIGO', value: 'ENCGRE_CODIGO' },
      { label: 'NUMERO', value: 'ENCGRE_NUMERO' },
      { label: 'FEC. EMISIÓN', value: 'ENCGRE_FECHAEMISION' },
      { label: 'TRANSPORTISTA', value: 'TRANSPORT_NOMBRE' },
      { label: 'CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE', value: 'CLI_NOMBRE' },
      { label: 'TOTAL', value: 'ENCGRE_TOTAL' },
      { label: 'FACTURA', value: 'ENCFAC_NUMERO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = 'ENCGRE_CODIGO, ENCGRE_NUMERO, ENCGRE_FECHAEMISION, CLI_CODIGO, ' +
      'ENCGRE_TOTAL,ENCFAC_NUMERO, TRANSPORT_CODIGO, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE';
    this.tabla = 'VEN_ENCGRE';
    this.where = strCondicion;

    this.encfacproService.spin = true;
    this.busqService.busquedaVenEncgre1d(strCondicion).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.ENCGRE_FECHAEMISION !== null) {
            registro.ENCGRE_FECHAEMISION = this.datePipe.transform(registro.ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
          } else {
            registro.ENCGRE_FECHAEMISION = '';
          }
          this.busqService.obtenerNombreTransport(registro.TRANSPORT_CODIGO).subscribe((res2) => {
            registro.TRANSPORT_NOMBRE = res2[0].TRANSPORT_NOMBRE;
          });
        });
      }
      this.encfacproService.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  async LlenarcamposGuiaRemision(strCodigoGuiaRemision) {
    let strComprobante = "";
    let intFilaSel = -1;
    if (this.detfacproSelected !== undefined) {
      intFilaSel = this.encfacproService.detfacproarray[this.indicador].findIndex(
        dtf => dtf.CLAVE === this.detfacproSelected.CLAVE);
    }
    let item: any;
    let strCodArticulo = "";
    let strTipo = "", strDescripcion = "";

    const data = await this.encfacproService.getGuiaRemision(strCodigoGuiaRemision);

    for (const rs of data) {
      strComprobante = rs.ENCGRE_NUMERO == null ? "" : rs.ENCGRE_NUMERO;
    }

    const dd = await this.encfacproService.getDetalleGuiaRemision(strCodigoGuiaRemision);

    if (intFilaSel >= 0) {
      item = this.detfacproSelected;
      strCodArticulo = item.DETFACPRO_CODIGO;
      strTipo = item.DETFACPRO_TIPODET;
      strDescripcion = item.DETFACPRO_DESCRIPCION;
    }

    if (strCodArticulo !== ""
      || intFilaSel == -1
      || (strTipo === "*" && strDescripcion !== "")) {

      this.crearNuevoItem();
      const item: ComDetfacpro = this.nuevoItem;

      item.BOD_CODIGO = "";
      item.CEN_CODIGO = "";
      item.COM_CODIGO = '01';
      item.DETFACPRO_BASECERO = 0;
      item.DETFACPRO_BASEICE = 0;
      item.DETFACPRO_BASEIVA = 0;
      item.DETFACPRO_CANTIDAD = 0;
      item.DETFACPRO_CODIGO = '';
      item.DETFACPRO_COSTO = 0;
      item.DETFACPRO_DESCRIPCION = " ** GUIA DE REMISION " + strComprobante + " ** ";
      item.DETFACPRO_ENTREGADO = 0;
      item.DETFACPRO_ESQUEMADOC = '';
      item.DETFACPRO_FACTOR = 0;
      item.DETFACPRO_ICE = 0;
      item.DETFACPRO_IVA = 0;
      item.DETFACPRO_LINEA = 0;
      item.DETFACPRO_PORCEICE = 0;
      item.DETFACPRO_PORDES = 0;
      item.DETFACPRO_PORDES2 = 0;
      item.DETFACPRO_PORDES3 = 0;
      item.DETFACPRO_PORIVA = 0;
      item.DETFACPRO_PRECIOFOB = 0;
      item.DETFACPRO_PROMOCION = '';
      item.DETFACPRO_TIPODET = "*";
      item.DETFACPRO_TOTAL = 0;
      item.DETFACPRO_TOTALINCP = 0;
      item.DETFACPRO_TRIBICE = '';
      item.DETFACPRO_UNIDAD = '';
      item.DETFACPRO_VALDES = 0;
      item.DETNOTREC_LINEA = 0;
      item.DETORDCOM_LINEA = 0;
      item.ENCFACPRO_NUMERO = '';
      item.ENCNOTREC_NUMERO = '';
      item.ENCORDCOM_NUMERO = '';
      item.DETFACPRO_TIPOSRI = "";

      this.encfacproService.detfacproarray[this.indicador].push(item);
    }

    for (const rs of dd) {
      intFilaSel = intFilaSel + 1;
      this.crearNuevoItem();
      const item: ComDetfacpro = this.nuevoItem;

      if (rs.DETGRA_TIPODET !== "*") {
        item.BOD_CODIGO = rs.BOD_CODIGO == null ? "" : rs.BOD_CODIGO;
        item.COM_CODIGO = '01';
        item.DETFACPRO_BASECERO = rs.DETGRE_BASECERO;
        item.DETFACPRO_BASEICE = rs.DETGRE_BASEICE;
        item.DETFACPRO_BASEIVA = rs.DETGRE_BASEIVA;
        item.DETFACPRO_CANTIDAD = rs.DETGRE_CANTIDAD;
        item.DETFACPRO_CODIGO = rs.DETGRE_CODIGO == null ? "" : rs.DETGRE_CODIGO;
        item.DETFACPRO_COSTO = rs.DETGRE_PRECIO;
        item.DETFACPRO_DESCRIPCION = rs.DETGRE_DESCRIPCION == null ? "" : rs.DETGRE_DESCRIPCION;
        item.DETFACPRO_ICE = rs.DETGRE_ICE;
        item.DETFACPRO_IVA = rs.DETGRE_IVA;
        item.DETFACPRO_LINEA = 0;
        item.DETFACPRO_PORCEICE = rs.DETGRE_PORCEICE;
        item.DETFACPRO_PORDES = rs.DETGRE_DESCUENTO;
        if (rs.DETGRE_TRIBIVA == null ? "" : rs.DETGRE_TRIBIVA === "S")
          item.DETFACPRO_PORIVA = Number('12');
        else
          item.DETFACPRO_PORIVA = 0;

        item.DETFACPRO_TIPODET = rs.DETGRE_TIPODET == null ? "" : rs.DETGRE_TIPODET;
        item.DETFACPRO_TOTAL = rs.DETGRE_TOTAL;
        item.DETFACPRO_TOTALINCP = rs.DETGRE_TOTAL;
        item.DETFACPRO_TRIBICE = rs.DETGRE_TRIBICE == null ? "" : rs.DETGRE_TRIBICE;
        item.DETFACPRO_UNIDAD = rs.DETGRE_UNIDAD;
        item.DETFACPRO_VALDES = 0;
        item.DETNOTREC_LINEA = rs.DETGRE_LINEA;
        item.DETORDCOM_LINEA = 0;
        item.UNIDAD = rs.ART_UNIDADCOSTEO == null ? "" : rs.ART_UNIDADCOSTEO;

        item.DETFACPRO_PORDES2 = 0;
        item.DETFACPRO_PORDES3 = 0;

        item.DETFACPRO_FACTOR = 0;
        item.DETFACPRO_PROMOCION = "";
        item.DETFACPRO_ESQUEMADOC = "";
        item.CEN_CODIGO = "";

        item.ENCFACPRO_NUMERO = this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO;
        item.ENCNOTREC_NUMERO = "";
        item.ENCORDCOM_NUMERO = "";
        item.DETFACPRO_TIPOSRI = "";

        item.ENCGRE_CODIGO = rs.ENCGRE_CODIGO;
        item.DETGRE_LINEA = rs.DETGRE_LINEA;

        this.encfacproService.detfacproarray[this.indicador].push(item);

      }
    }

    this.encfacproService.calcularTotales();
    this.botonesmodificar();
    this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.indicador], this.defaultColDef.objeto);
  }
  isEditFP(params) {
    console.log(params.ENCNOTREC_NUMERO)
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA' ||
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = true;
      this.botonRegresar = true;
      return false;
    } else if (params.ENCNOTREC_NUMERO === '') {
      return true;
    } else {
      return false;
    }
  }
  //CDPJ
  isEditFPUND(params): boolean {
    console.log(params.DETFACPRO_TIPODET);
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA' ||
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = true;
      this.botonRegresar = true;
      return false;
    } else if (params.DETFACPRO_TIPODET === 'S' || params.DETFACPRO_TIPODET === 'C' || params.ART_MULTIUNIDAD === 'N') {
      return false;
    } else {
      return true;
    }
  }
  //CDPJ
  isEditAll(): boolean {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA' ||
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = true;
      this.botonRegresar = true;
      return false;
    } else {
      return true;
    }
  }
  isEditAllnotrec(params): boolean {
    if (this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'PAGADA' ||
      this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_ESTADO === 'ANULADA') {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = true;
      this.botonRegresar = true;
      return false;
    } else if (params.ENCNOTREC_NUMERO !== null && params.ENCNOTREC_NUMERO !== undefined && params.ENCNOTREC_NUMERO !== '') {
      return false;
    }
    else {
      return true;
    }
  }
  notrecnot(params) {
    console.log(params.ENCNOTREC_NUMERO)
    if (params.ENCNOTREC_NUMERO !== '') {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese el ajuste desde notas de recepción'
      });
      return
    }
  }
  //CDPJ
  activarbotonesgrid() {
    this.botonNuevoGrid = false;
    this.botonBorrarGrid = false;
    this.botonRegresarGrid = false;
    this.botonOrdenCompraGrid = false;
  }
  bloquearbotonesgrid() {
    this.botonNuevoGrid = true;
    this.botonBorrarGrid = true;
    this.botonRegresarGrid = true;
    this.botonOrdenCompraGrid = true;
  }
  //CDPJ
  async verbasesiva() {
    this.closeSidebar()
    this.basesiva = []
    const data = await this.encfacproService.obtenerbasesiva(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
    if (data !== null && data !== undefined) {
      this.basesiva = data
      for (const data2 of this.basesiva) {
        data2.TARIIVA_PORCENTAJE = isNaN(Number(data2.TARIIVA_PORCENTAJE)) ? Number(0).toFixed(2) : Number(data2.TARIIVA_PORCENTAJE).toFixed(2)
        data2.IVA_BASE = isNaN(Number(data2.IVA_BASE)) ? Number(0).toFixed(2) : Number(data2.IVA_BASE).toFixed(2)
        data2.IVA_VALOR = isNaN(Number(data2.IVA_VALOR)) ? Number(0).toFixed(2) : Number(data2.IVA_VALOR).toFixed(2)
      }
    }
    this.displaybasesiva = true;

  }
  async actbasesiva() {
    this.encfacproService.spin = true;//CDPJ
    this.closeSidebar();
    try {
      await this.encfacproService.actualizabasesiva(this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO);
      this.messageService.add({
        key: 'encfacpro',
        severity: 'success',
        summary: 'Información',
        detail: 'Bases IVA actualizadas correctamente'
      });
      this.auditoriagral.registrarAuditoria('COM_ENCFACPRO', this.encfacproService.encfacproarray[this.indicador].ENCFACPRO_NUMERO + '/' + 'ACCIONES_ACT_VALORES_BASES_IVA', 'A',
        '', '01', '', '', '', '').subscribe(() => {
        });
      this.encfacproService.spin = false;
    } catch (error) {
      this.messageService.add({
        key: 'encfacpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Error al actualiza las bases IVA' + error
      });
      this.encfacproService.spin = false;
    }

  }
  //CDPJ
  //CDPJ
  //GSRF  
  colorearCantidad(existencia) {
    console.log('tipo--- ', existencia.DETFACPRO_TIPODET);
    if (existencia.DETFACPRO_TIPODET === '') {
      return 'rgb(248, 66, 66)';
    } else {
      return 'transparent';
    }
    //GSRF
  }
}
