import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SacifrmenviocorreosService} from '../segservicios/sacifrmenviocorreos.service';
import {ConfirmationService, MessageService} from 'primeng';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import {Sacifrmenviocorreos} from '../seginterfaces/sacifrmenviocorreos';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';

@Component({
  selector: 'app-sacifrmenviocorreos',
  templateUrl: './sacifrmenviocorreos.component.html',
  styleUrls: ['./sacifrmenviocorreos.component.css']
})
export class SacifrmenviocorreosComponent implements OnInit,OnChanges {
  @Input() auxiliar: any;
  @Input() strNombreForma: any;
  @Input() strNumDoc: any;
  @Input() strNotaVentaPV: any;
  @Input() strCampo: any;
  @Input() strCampo2: any;
  @Input() strNumDoc2: any;
  @Input() strform:any;//CDPJ
  @Input() strliquicom:any;//CDPJ
  @Input() strCliProvCod:string
  @Input() CENREPDOCE:number
@Output() cerrar = new EventEmitter<boolean>();
  intNumfilasVP: number;
  intNumFilasVR: number;
  
//agggrid
defaultColFrmCorreos;
public frameworkComponents;
frmcorreos: Sacifrmenviocorreos[] = [];
rowStyle;
largo = '258';
rowSelection = 'multiple';
//REPORTE
ValoresReporte: string[][] = [];
ValoresParamentros: string[][] = [];
spin:boolean=false;
reporte='';
columnDefFrmCorreos = [
  {
    headerName: 'S', field: 'EMVIAR_CORREO',  cellRendererFramework: AggridBanComponent,  
    editable: (params) => {
      return false;
    }, cellEditor: 'cellPrueba', width: 50, onCellClicked: (params) => {
      //this.seleccionItem(params.data);  
    }
  },
  {
    headerName: 'Nombre', field: 'ENCENVIOCOR_NOMBRE', editable: (params) => {
      return false;
    }, cellEditor: 'cellPrueba', width: 180, 
    
  },
 
];
  constructor(private sacifrmenviocorreosService:SacifrmenviocorreosService,
    private init: NuevoComponentService,private confirmationService: ConfirmationService,
    private messageService: MessageService,) { }

  ngOnInit(): void {
  }
  manejarSenales(valor){
    if (valor === 'Correo'){
     if( this.validarCheksCorreos()===true){
      this.enviarCorreo();
      }else{
        this.messageService.add({
          key: 'frmCorreos',
          severity: 'error',
          summary: 'Información',
          detail: 'Seleccione uno de los archivos para enviar al correo.'
        });
        return;
      }
    }
    if (valor === 'Salir') {
      this.cerrar.emit(true);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      //console.log(e)
      if (e === 'auxiliar' && this.strNombreForma !== undefined) {
        this.listarformscorreos();
      }
    }
  }
  async listarformscorreos(){
    this.intNumfilasVP = 0;
    this.intNumFilasVR = 0;
    let strDatos = '';
    if (this.strNotaVentaPV === 'PV') {
      strDatos = 'PV';
    } else {
      strDatos = 'F';
    }
    console.log(this.strCampo, this.strNumDoc);
    if (this.strNumDoc === undefined) {
      this.strNumDoc = '';
    }

    if (this.strNombreForma === 'frmBAN_consilbancaria' || this.strNombreForma === 'frmCXC_TRNCOBRO') {
      this.FunCnfgMtrzRptConcil(this.strCampo, this.strNumDoc, this.strCampo2, this.strNumDoc2);
    } else {
      this.FunCnfgMtrzRpt(this.strCampo, this.strNumDoc);
    }
        
    // this.sacifrmenviocorreosService.CargarfrmsCorreos(this.strOrigen).subscribe(data => {
    //   this.frmcorreos = data;
    //   this.frmcorreos.map(correos=>{
    //     correos.EMVIAR_CORREO=false;
    //   })
    // });
    await this.sacifrmenviocorreosService.CargarfrmsCorreos(this.strNombreForma,this.strliquicom).toPromise().then(data=>{
      this.frmcorreos = data;
      for (const correos of this.frmcorreos){
        correos.EMVIAR_CORREO=false;
      }
    }).catch(e=>{
      this.messageService.add({
        key: 'frmCorreos',
        severity: 'error',
        summary: 'Información',
        detail: 'No existe ningún dato. Consulte con el Administrador'
      });
      return;
    })
  }
  FunCnfgMtrzRpt(strCampo, strNumDoc) {
    const intNumeroDatos = 2;
    this.ValoresReporte[0] = [];
    this.ValoresReporte[1] = [];
    this.ValoresReporte[0][0] = strCampo;
    //this.ValoresReporte[0][1] = strNumDoc.trim();
    this.ValoresReporte[0][1] = strNumDoc.toString().trim();//CDPJ
    this.ValoresReporte[1][0] = 'COM_CODIGO';
    this.ValoresReporte[1][1] = '01';
    this.intNumFilasVR = intNumeroDatos;
  }

  FunCnfgMtrzRptConcil(strCampo, strNumDoc, strCampo2, strNumDoc2) {
    const intNumeroDatos = 3;
    this.ValoresReporte[0] = [];
    this.ValoresReporte[1] = [];
    this.ValoresReporte[2] = [];
    this.ValoresReporte[0][0] = strCampo;
    this.ValoresReporte[0][1] = strNumDoc.trim();
    this.ValoresReporte[1][0] = strCampo2;
    this.ValoresReporte[1][1] = strNumDoc2.trim();
    this.ValoresReporte[2][0] = 'COM_CODIGO';
    this.ValoresReporte[2][1] = '01';
    this.intNumFilasVR = intNumeroDatos;
  }
  validarCheksCorreos(){
    let bool=false;
    for (const correos of this.frmcorreos){
      if(correos.EMVIAR_CORREO === true){
        return bool=true;
      }
    }
    return bool;
  }
  enviarCorreo(){
    if (this.frmcorreos.length >= 0) {
      this.recorrerArrayCorreos();
    } else {
      this.messageService.add({
        key: 'frmCorreos',
        severity: 'error',
        summary: 'Información',
        detail: 'No existe ningún dato. Consulte con el Administrador'
      });
      return;
    }
  }
  async recorrerArrayCorreos(){
    this.spin=true;
    let num=0;
    if(this.strNombreForma === 'frmCXC_TRNCOBRANZAS'){
      const numero=await this.sacifrmenviocorreosService.consultacorreocomp();
      if(numero !== null && numero !== undefined){

        num=numero[0].NUMERO;
      }
      if(Number(num) === 0){
        this.messageService.add({
          key: 'frmCorreos',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe registrar un correo, para el envio de correos, comuniquese con el administrador.'
        });
        this.spin=false;
        return
        
      }else{
        await this.recorrerArrayfinal();
      }
    }else{
      await this.recorrerArrayfinal();
    }
    
    this.spin=false;
  }
  async recorrerArrayfinal(){
    for (const correos of this.frmcorreos){
      if(correos.EMVIAR_CORREO === true){
        let cuerpo01='';
        let cuerpo02='';
        let piecorreo='';
        let i = 0;
        const data = await this.sacifrmenviocorreosService.consultaProcesoRep(correos.ENCENVIOCOR_REPCODIGO, '01').toPromise();
        const data2 = await this.sacifrmenviocorreosService.consultreporte(correos.ENCENVIOCOR_REPCODIGO);
        if(data2[0] !== null && data2[0] !== undefined){
          this.reporte=data2[0].NOM_ARCHIVO
        }else{
          this.reporte='';
        }
        //verificar si el reporte se ejecuta por proceso o consulta
        if(data[0].RESULTADO === 'SI'){ 
          console.log('si')
          await this.sacifrmenviocorreosService.generarproceso(correos.ENCENVIOCOR_REPCODIGO,this.ValoresReporte,this.intNumFilasVR,this.ValoresParamentros,this.strform).catch(error=>{
            this.messageService.add({
              key: 'frmCorreos',
              severity: 'error',
              summary: 'Información',
              detail: 'Hubo un error al Ejecutar el proceso'
            });
            this.spin=false;
            return;
          });
          const data=await this.sacifrmenviocorreosService.generarcorreo(correos,this.strNombreForma,this.strNumDoc).catch(error=>{
            this.messageService.add({
              key: 'frmCorreos',
              severity: 'error',
              summary: 'Información',
              detail: 'Hubo un error al cargar los datos del correo'
            });
            this.spin=false;
            return;
          }); 
          data[0].CUERPO01=data[0].CUERPO01 === null || data[0].CUERPO01 === undefined?'':data[0].CUERPO01;
          data[0].CUERPO02=data[0].CUERPO02 === null || data[0].CUERPO02 === undefined?'':data[0].CUERPO02;
          data[0].PIECORREO = data[0].PIECORREO === null || data[0].PIECORREO === undefined?'':data[0].PIECORREO;
          cuerpo01 = data[0].CUERPO01.replaceAll('\n','<br>');
          cuerpo02 = data[0].CUERPO02.replaceAll('\n','<br>');
          piecorreo = data[0].PIECORREO.replaceAll('\n','<br>');
          
          const resp=await this.sacifrmenviocorreosService.enviarCorreo(data[0].ASUNTO,data[0].REMITENTE,cuerpo01,cuerpo02,piecorreo,this.strCliProvCod,data[0].NOMARCHIVO,this.reporte,correos.ENCENVIOCOR_LOGO,
            correos.ENCENVIOCOR_REPCODIGO,this.ValoresReporte,this.intNumFilasVR,this.ValoresParamentros,this.strform,this.strNombreForma).catch(error=>{
            //this.mensajeAlerta('error', 'Error', 'Hubo un error al enviar el correo a '+rol.EMP_NOMBRE);
            this.messageService.add({
              key: 'frmCorreos',
              severity: 'error',
              summary: 'Información',
              detail: 'Hubo un error al enviar el correo: '+correos.ENCENVIOCOR_NOMBRE
            });
            this.spin=false;        
          });
          if(resp === true){
           // this.mensajeAlerta('success', 'Información', 'Éxito al enviar el correo a '+rol.EMP_NOMBRE);
            this.messageService.add({
              key: 'frmCorreos',
              severity: 'success',
              summary: 'Información',
              detail: 'Éxito al enviar el correo: '+correos.ENCENVIOCOR_NOMBRE
            });
            
            if(this.CENREPDOCE === 1){
              try {
                await this.sacifrmenviocorreosService.actualizarestadodocelec(this.strNumDoc,this.strliquicom)
                await this.sacifrmenviocorreosService.actualizarestadodocelecsacianex(this.strNumDoc,this.strliquicom)
                this.messageService.add({
                  key: 'frmCorreos',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'La actualización del estado del documento se realizó con éxito.'
                });
              } catch (error) {
                this.messageService.add({
                  key: 'frmCorreos',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'La actualización del estado del documento no se realizó con éxito.'
                });
              }              
            }
          }else{
            //this.mensajeAlerta('error', 'Error', 'Hubo un error al enviar el correo a '+rol.EMP_NOMBRE);
            this.messageService.add({
              key: 'frmCorreos',
              severity: 'error',
              summary: 'Información',
              detail: 'Hubo un error al enviar el correo: '+correos.ENCENVIOCOR_NOMBRE
            });
            this.spin=false;
            return;
          }
          this.spin=false;
        }else{
          const data=await this.sacifrmenviocorreosService.generarcorreo(correos,this.strNombreForma,this.strNumDoc).catch(error=>{
            this.messageService.add({
              key: 'frmCorreos',
              severity: 'error',
              summary: 'Información',
              detail: 'Hubo un error al cargar los datos del correo'
            });
            this.spin=false;
            return;
          }); 
          data[0].CUERPO01=data[0].CUERPO01 === null || data[0].CUERPO01 === undefined?'':data[0].CUERPO01;
          data[0].CUERPO02=data[0].CUERPO02 === null || data[0].CUERPO02 === undefined?'':data[0].CUERPO02;
          data[0].PIECORREO = data[0].PIECORREO === null || data[0].PIECORREO === undefined?'':data[0].PIECORREO;
          cuerpo01 = data[0].CUERPO01.replaceAll('\n','<br>');
          cuerpo02 = data[0].CUERPO02.replaceAll('\n','<br>');
          piecorreo = data[0].PIECORREO.replaceAll('\n','<br>');
          const resp=await this.sacifrmenviocorreosService.enviarCorreo(data[0].ASUNTO,data[0].REMITENTE,cuerpo01,cuerpo02,piecorreo,this.strCliProvCod,data[0].NOMARCHIVO,this.reporte,correos.ENCENVIOCOR_LOGO,
            correos.ENCENVIOCOR_REPCODIGO,this.ValoresReporte,this.intNumFilasVR,this.ValoresParamentros,this.strform,this.strNombreForma).catch(error=>{
            //this.mensajeAlerta('error', 'Error', 'Hubo un error al enviar el correo a '+rol.EMP_NOMBRE);
            this.messageService.add({
              key: 'frmCorreos',
              severity: 'error',
              summary: 'Información',
              detail: 'Hubo un error al enviar el correo: '+correos.ENCENVIOCOR_NOMBRE
            });
            this.spin=false;        
          });
          if(resp === true){
           // this.mensajeAlerta('success', 'Información', 'Éxito al enviar el correo a '+rol.EMP_NOMBRE);
            this.messageService.add({
              key: 'frmCorreos',
              severity: 'success',
              summary: 'Información',
              detail: 'Éxito al enviar el correo: '+correos.ENCENVIOCOR_NOMBRE
            });
            if(this.CENREPDOCE === 1){
              try {
                await this.sacifrmenviocorreosService.actualizarestadodocelec(this.strNumDoc,this.strliquicom)
                await this.sacifrmenviocorreosService.actualizarestadodocelecsacianex(this.strNumDoc,this.strliquicom)
                this.messageService.add({
                  key: 'frmCorreos',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'La actualización del estado del documento se realizó con éxito.'
                });
              } catch (error) {
                this.messageService.add({
                  key: 'frmCorreos',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'La actualización del estado del documento no se realizó con éxito.'
                });
              }              
            }
          }else{
            //this.mensajeAlerta('error', 'Error', 'Hubo un error al enviar el correo a '+rol.EMP_NOMBRE);
            this.messageService.add({
              key: 'frmCorreos',
              severity: 'error',
              summary: 'Información',
              detail: 'Hubo un error al enviar el correo: '+correos.ENCENVIOCOR_NOMBRE
            });
            this.spin=false;
            return;
          }

        }
        
        // for (const rs of data) {
        //   this.ValoresParamentros[i] = [];
        //   this.ValoresParamentros[i][0] = rs.PRMTCNFGIMPR_TIPO;
        //   this.ValoresParamentros[i][1] = rs.PRMTCNFGIMPR_VALOR;
        //   this.ValoresParamentros[i][2] = rs.PRMTCNFGIMPR_TIPODATO;
        //   i = i + 1;
        // }
        // this.intNumfilasVP = i;
      }
    }
  }
}
