<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="trnbanEdit"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="trnbanEditConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar" [barraBotonesDesplaz]="true" [barraBotones3]="true"
						 [barraBotonesAsiCon]="false" [botonverAsiento]="false"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-8"></div>
			<div class="p-col-2" style="text-align: right">
				<span>Nro. de Asiento:</span>
			</div>
			<div class="p-col-2" style="text-align: left">
				<input type="text" pInputText
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled [(ngModel)]="bantrnbanEditService.banTrnbanSelect.ASI_NRO">
			</div>
		</div>
	</div>
</div>
<p-tabView (onChange)="onTab1Change($event)" [activeIndex]="selectTab">
	<p-tabPanel header="Movimiento de bancos" [disabled]="boolIndex1">
		<div class="divgrups" id="divPrincipal3">
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Banco:</span>
					</div>
					<div class="p-col-2">
						<input type="text" id="ban_codigo" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.BAN_CODIGO" [disabled]="editCodBan">
					</div>
					<div class="p-col-3">
						<input type="text" pInputText style="background-color: white"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.BAN_NOMBRE" [disabled]="true">
					</div>
					<div class="p-col-1">
						<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
								class="littlebuttons" (click)="buscarBancos()" [hidden]="btnBancos"></button>
					</div>
					<div class="p-col-4"></div>

				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Tipo:</span>
					</div>
					<div class="p-col-2">
						<p-dropdown [style]="{'width':'100%'}" [(ngModel)]="strTipo"
									optionLabel="name" [options]="cmbTipo" [panelStyle]="{'width':'110%'}"
									[disabled]="editCmbTipo" (onChange)="cambioCmb($event)">
						</p-dropdown>
					</div>
					<div class="p-col-8"></div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Número:</span>
					</div>
					<div class="p-col-2">
						<input type="text" id="ban_numero" (keydown.enter)="cambiarFoco($event)"
							   onkeypress='return event.charCode>=48 && event.charCode <=57' pInputText
							   style="background-color: white"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.TRNBAN_NUMERO" [disabled]="editNum">
					</div>
					<div class="p-col-8"></div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Importe:</span>
					</div>
					<div class="p-col-2">
						<input type="text" id="ban_importe" (input)="cambio($event)" (blur)="pierdeFocoImporte()"
							   (keydown.enter)="cambiarFoco($event)" pInputText
							   style="background-color: white; text-align: right"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.IMPORTE" [disabled]="boolImporte">
					</div>
					<div class="p-col-8"></div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Fecha Contabilización :</span>
					</div>
					<div class="p-col-2" style="text-align: left">
						<p-calendar (ngModelChange)="cambio($event)"
									[(ngModel)]="bantrnbanEditService.banTrnbanSelect.FECHA_EMISION"
									dateFormat="dd/mm/yy" [inputStyle]="{'width': '80px'}"></p-calendar>
					</div>
					<div class="p-col-2" style="text-align: left">
						<span [hidden]="boolFechaVence">Fecha Vencimiento:</span>
					</div>
					<div class="p-col-2" style="text-align: left">
						<p-calendar [hidden]="boolFechaVence" (ngModelChange)="cambio($event)" dateFormat="dd/mm/yy"
									[inputStyle]="{'width': '80px'}"
									[(ngModel)]="bantrnbanEditService.banTrnbanSelect.FECHA_VENCIMIENTO"></p-calendar>
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-2" style="text-align: left">
						<p-checkbox label="Pago electrónico" binary="true" [(ngModel)]="boolPagoElect"></p-checkbox>
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Nombre:</span>
					</div>
					<div class="p-col-2" style="text-align: left">
						<input type="text" id="ban_beneficiario" (input)="cambio($event)"
							   (keydown.enter)="cambiarFoco($event)" pInputText style="background-color: white"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.BENEFICIARIO">
					</div>
					<div class="p-col-8"></div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Núm. de referencia:</span>
					</div>
					<div class="p-col-2">
						<input type="text" id="ban_referencia" (input)="cambio($event)"
							   (keydown.enter)="cambiarFoco($event)" pInputText style="background-color: white"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.REFERENCIA">
					</div>
					<div class="p-col-8"></div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Concepto:</span>
					</div>
					<div class="p-col-5">
						<input type="text" (input)="cambio($event)" id="ban_concepto"
							   (keydown.enter)="cambiarFoco($event)" pInputText style="background-color: white"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.CONCEPTO">
					</div>
					<div class="p-col-5"></div>
				</div>
			</div>
			<br>
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: center">
						<p-checkbox label="Anulado" binary="true" [(ngModel)]="boolAnulado"
									(onChange)="cambioAnulado($event)"></p-checkbox>
					</div>
					<div class="p-col-2" style="text-align: left">
						<p-checkbox label="Conciliado" binary="true" [(ngModel)]="boolConcilia"
									[disabled]="true"></p-checkbox>
					</div>
					<div class="p-col-1" style="text-align: left">
						<input type="text" pInputText style="background-color: white" [disabled]="true"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.NUMERO_CONCILIACION">
					</div>
					<div class="p-col-2" style="text-align: center">
						<p-checkbox label="Saldo inicial" binary="true" [(ngModel)]="boolSaldoInicial"></p-checkbox>
					</div>
					<div class="p-col-5"></div>
				</div>
			</div>
			<br>
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<div class="p-grid" style="text-align: left">
							<div class="p-col-4" style="text-align: left">
								<span>Origen:</span>
							</div>
							<div class="p-col-7" style="text-align: left">
								<input type="text" pInputText style="background-color: white" [disabled]="true"
									   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.ORIGEN">
							</div>
							<div class="p-col-1"></div>
						</div>
					</div>
					<div class="p-col-1" style="text-align: left">
						<span>Doc. Origen:</span>
					</div>
					<div class="p-col-2" style="text-align: left">
						<input type="text" pInputText style="background-color: white" [disabled]="true"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.NRO_DOCUMENTO">
					</div>
					<div class="p-col-1">
						<span>Proveedor:</span>
					</div>
					<div class="p-col-2" style="text-align: left">
						<input type="text" id="pro_codigo" pInputText style="background-color: white"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.PRO_CODIGO">
					</div>
					<div class="p-col-1" style="text-align: left">
						<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
								class="littlebuttons" (click)="buscarProveedor()"></button>
					</div>
					<div class="p-col-3" style="text-align: left">
						<input type="text" pInputText style="background-color: white" [disabled]="true"
							   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.PRO_NOMBRE">
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Caja" [disabled]="boolIndex2" id="tabCaja">
		<div class="divgrups" id="divPrincipal4">
			<div class="p-grid">
				<div class="p-col-6">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
								<div class="p-col-2" style="text-align: left">
									<span>Desde:</span>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-calendar [(ngModel)]="desde" dateFormat="dd/mm/yy"
												[inputStyle]="{'width': '80px'}"></p-calendar>
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-2" style="text-align: left">
									<span>Hasta:</span>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-calendar [(ngModel)]="hasta" dateFormat="dd/mm/yy"
												[inputStyle]="{'width': '80px'}"></p-calendar>
								</div>
								<div class="p-col-3"></div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
								<div class="p-col-2" style="text-align: left">
									<span>Tipo:</span>
								</div>
								<div class="p-col-3">
									<p-dropdown [(ngModel)]="strTipoCaj"
												optionLabel="name" [options]="cmbTipoCaj">
									</p-dropdown>
								</div>
								<div class="p-col-7"></div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
								<div class="p-col-2" style="text-align: left">
									<span>Caja:</span>
								</div>
								<div class="p-col-3">
									<p-dropdown [(ngModel)]="strCaja"
												optionLabel="name" [options]="cmbCaja">
									</p-dropdown>
								</div>
								<div class="p-col-7"></div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
								<div class="p-col-2" style="text-align: left">
									<span>Bodega:</span>
								</div>
								<div class="p-col-3">
									<p-dropdown [(ngModel)]="strBodega"
												optionLabel="name" [options]="cmbBodega">
									</p-dropdown>
								</div>
								<div class="p-col-7"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-6">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
								<div class="p-col-7" style="text-align: left">
									<p-checkbox label="Incluir movimientos de contabilidad" binary="true"
												[(ngModel)]="boolMovConta"></p-checkbox>
								</div>
								<div class="p-col-5" style="text-align: left">
									<p-checkbox label="Incluir movimientos de  Cons." binary="true"
												[(ngModel)]="boolMovCons"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
								<div class="p-col-7" style="text-align: left">
									<p-checkbox label="Incluir movimientos de cartera activa" binary="true"
												[(ngModel)]="boolMovCartAct"></p-checkbox>
								</div>
								<div class="p-col-5">
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
								<div class="p-col-7" style="text-align: left">
									<p-checkbox label="Incluir movimientos de ventas (PTOVTA-FAC)" binary="true"
												[(ngModel)]="boolMovVentas"></p-checkbox>
								</div>
								<div class="p-col-5" style="text-align: left">
									<p-checkbox label="SELECCIONAR TODOS" binary="true" [(ngModel)]="boolSelectTodos"
												(onChange)="selectTodos($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
								<div class="p-col-7" style="text-align: left">
									<p-checkbox label="Incluir movimientos de caja" binary="true"
												[(ngModel)]="boolMovCaja"></p-checkbox>
									&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
								</div>
								<div class="p-col-5" style="text-align: center">
									<!--									<input type="button" value="Aceptar" (click)="consultaFiltros()"-->
									<!--										   style="width: 70px; height: 30px">-->
									<!--									<button (click)="consultaFiltros()" pButton class="aceptar"-->
									<!--											style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">-->
									<!--										Aceptar-->
									<!--									</button>-->
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
								<div class="p-col-7" style="text-align: left">
									<p-checkbox label="Incluir movimientos de cartera pasiva" binary="true"
												[(ngModel)]="boolMovCartPas"></p-checkbox>
								</div>
								<div class="p-col-5">
									<button (click)="consultaFiltros()" pButton class="aceptar"
											style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
										Aceptar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br>
				<br>
				<br>
				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
						<div class="p-col-1">
						</div>
						<div class="p-col-1">
							<p-checkbox label="Abono" binary="true" [(ngModel)]="boolAbono"
										(onChange)="cambioAbono($event)"></p-checkbox>
						</div>
						<div class="p-col-2">
							&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
							<p-checkbox label="Cancelación" binary="true" [(ngModel)]="boolCancelacion"
										(onChange)="cambioCancelacion($event)"></p-checkbox>
						</div>
						<div class="p-col-8">
						</div>
					</div>
				</div>
				<br>
				<br>
				<br>
				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
						<div class="p-col-12">
							<p-tabView>
								<p-tabPanel header="Transacciones">
									<div class="divgrups">
										<div class="p-col-12">
											<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
												<div class="p-col-10">
												</div>
												<div class="p-col-2">
													<p-checkbox label="Seleccionar todos" binary="true"
																(onChange)="selectTodosDet($event)"></p-checkbox>
												</div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
												<div class="p-col-12" *ngIf="selectTab === 1">
													<app-aggridsaci [width]=""
																	[height]="largo"
																	[enableSorting]="true"
																	[enableFilter]="true"
																	[rowData]="bantrnbanEditService.banTrnBanCaja"
																	[frameworkComponents]="frameworkComponents"
																	[animateRows]="true"
																	[rowSelection]="rowSelection"
																	[columnDefs]="columnDefsBanTrnBanCaj"
																	[defaultColDef]="defaultBanTrnBanCaj"
																	[rowStyle]="rowStyle"
																	[singleClickEdit]="false"
																	[botones]="false"
																	[mostrarGuardar]="false"
																	(selected)="seleccionDetCaja($event)"
																	(cambios)="cambio($event)">
													</app-aggridsaci>
												</div>

											</div>
										</div>
									</div>
								</p-tabPanel>
							</p-tabView>
						</div>

					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
						<div class="p-col-2">
							<span>Importe efectivo en caja:</span>
						</div>
						<div class="p-col-2">
							<input type="text" pInputText style="background-color: white; text-align: right"
								   [disabled]="true" [(ngModel)]="bantrnbanEditService.banTrnbanSelect.EFECTIVO">
						</div>
						<div class="p-col-8"></div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
						<div class="p-col-2">
							<span>Importe cheque en caja:</span>
						</div>
						<div class="p-col-2">
							<input type="text" pInputText style="background-color: white; text-align: right"
								   [disabled]="true" [(ngModel)]="bantrnbanEditService.banTrnbanSelect.CHEQUE">
						</div>
						<div class="p-col-2">
							<span>Importe: </span>
						</div>
						<div class="p-col-2">
							<input type="text" pInputText style="background-color: white; text-align: right"
								   [disabled]="true" [(ngModel)]="bantrnbanEditService.banTrnbanSelect.IMPORTE_RES">
						</div>
						<div class="p-col-4"></div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Varios" [disabled]="boolIndex3">
		<app-aggridsaci *ngIf="selectTab === 2"
						[width]=""
						[height]="largo"
						[enableSorting]="false"
						[enableFilter]="true"
						[rowData]="bantrnbanEditService.banTrnBanVarios"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsBanTrnBanVar"
						[defaultColDef]="defaultBanTrnBanVar"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="true"
						[mostrarGuardar]="false"
						(selected)="seleccionDetVarios($event)"
						(cambios)="cambio($event)"
						[botonNuevo]="btnNuevoGrid"
						[botonBorrar]="btnBorrarGrid"
						[botonRegresar]="btnCancelarGrid">
		</app-aggridsaci>
	</p-tabPanel>
</p-tabView>
<div class="divgrupstabla">

	<div class="p-grid">
		<div class="p-col-4"></div>
		<div class="p-col-2">
			<span class="obligatorio">Importe Total: </span>
		</div>
		<div class="p-col-2">
			<input class="totales" type="text" pInputText [disabled]="true"
				   [(ngModel)]="bantrnbanEditService.banTrnbanSelect.IMPORTE_TOTAL">
		</div>
		<div class="p-col-4"></div>
	</div>
</div>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones($event)">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
				   [dismissible]="false" [showCloseIcon]="false">
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span class="archivoOpc"
																		 (click)="confirmarEliminar('eliminarOtroOrg')">Eliminar movimiento de otro origen</span>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBus"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [closable]="false"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="NumAsiRegCont" [abiertoDesde]="'banTrnBanEdit'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Clave Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '60vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
						   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>