<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="datane"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="dataneconf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonBorrar]="botonBorrar"
	[botonRegresar]="botonRegresar" [botonVisto]="strvisto" (signal)="manejarSenales($event)"
	[barraBotonesDesplaz]="barraBotones2" [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="barraBotones2"
	[barraBotones5]="true" [noNuevo]="true" [barraBotones3Alt]="false" [noEliminar]="true"></app-invmaebarra>
<div class="p-grid" style="margin-top: -5px; margin-bottom: -10px; width: 99%;">
	<!--LINEA 1-->
	<div class="p-col-2">
		<label for="ENCFACPRO_NUMERO"></label>
		<input class="frm" id="ENCFACPRO_NUMERO" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="strnumfac" autocomplete="off">
	</div>
</div>
<p-tabView [(activeIndex)]="index" (onChange)="gridsnull()" [style]="{'width':'98%'}">
	<p-tabPanel header="Datos Anexos">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
						<div class="p-col-1"></div>
						<div class="p-col-5" style="text-align: left">
							<span>Pago local o al exterior:</span>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<input type="text" id="pagolocal" (input)="cambio()" style="width: 100%" [maxLength]="10"
								(keydown.enter)="setFocus($event)" (keydown)="cambio()"
								[(ngModel)]="datosFP.ENCFACPRO_PAGOLOCALEXT">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								class="littlebuttons" (click)="abrirPagoLocal(datosFP.ENCFACPRO_PAGOLOCALEXT)"></button>
						</div>
						<div class="p-col-3">
							<span>{{datosFP.ENCFACPRO_PAGOLOCALEXTDET}}</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-5" style="text-align: left">
							<span>Tipo de régimen fiscal del exterior:</span>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-4">
							<!-- <p-dropdown [options]="cmbtiporegimen" [style]="{'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}"
										(onClick)="llenarcmbtiporegimen()"
										(onChange)="busqtiporegimen($event)"></p-dropdown> -->
							<!-- CDPJ -->
							<p-dropdown [options]="cmbtiporegimenaux" [style]="{'width':'100%'}" optionLabel="name"
								[panelStyle]="{'width':'110%'}" (onChange)="busqtiporegimen($event)"
								[(ngModel)]="selecttiporegimen" [disabled]="boolcmbtiporegimen"></p-dropdown>
							<!-- CDPJ -->

						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
						<div class="p-col-1"></div>
						<div class="p-col-5" style="text-align: left">
							<span>País al que se realiza el pago en régimen general:</span>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<input type="text" id="paisesreg" (input)="cambio()" style="width: 100%" [maxLength]="10"
								(keydown.enter)="setFocus($event)" (keydown)="cambio()"
								[(ngModel)]="datosFP.ENCFACPRO_CODPAISREGGEN" [disabled]="booleanRegimen">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								class="littlebuttons" (click)="abrirPaisesRegimen(datosFP.ENCFACPRO_CODPAISREGGEN)"
								[disabled]="booleanRegimen"></button>
						</div>
						<div class="p-col-3">
							<span>{{datosFP.ENCFACPRO_CODPAISREGGENDET}}</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
						<div class="p-col-1"></div>
						<div class="p-col-5" style="text-align: left">
							<span>País al que se realiza el pago paraíso fiscal:</span>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<input type="text" style="width: 100%" id="paisParaiso" (input)="cambio()" [maxLength]="10"
								(keydown.enter)="setFocus($event)" (keydown)="cambio()"
								[(ngModel)]="datosFP.ENCFACPRO_CODPAISPARFIS" [disabled]="booleanParaiso">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								class="littlebuttons" (click)="abrirPaisesParaiso(datosFP.ENCFACPRO_CODPAISPARFIS)"
								[disabled]="booleanParaiso"></button>
						</div>
						<div class="p-col-3">
							<span>{{datosFP.ENCFACPRO_CODPAISPARFISDET}}</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
						<div class="p-col-1"></div>
						<div class="p-col-5" style="text-align: left">
							<span>Denominación del régimen fiscal preferente o jurisdicción de menor imposición:</span>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-4"><input type="text" id="denoExp" style="width: 100%" (input)="cambio()"
								(keydown.enter)="setFocus($event)" [(ngModel)]="datosFP.ENCFACPRO_DENOMINACION"
								[maxLength]="100" [disabled]="booleandenomi"></div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
						<div class="p-col-1"></div>
						<div class="p-col-5" style="text-align: left">
							<span>País al que se efectúa el pago:</span>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<input type="text" style="width: 100%" id="paisResid" (keydown.enter)="setFocus($event)"
								(keydown)="cambio()" [(ngModel)]="datosFP.ENCFACPRO_PAISPAGO"
								[disabled]="booleanRegimenP" [maxLength]="10">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								class="littlebuttons" (click)="abrirPaisesExportacion(datosFP.ENCFACPRO_PAISPAGO)"
								[disabled]="booleanRegimenP"></button>
						</div>
						<div class="p-col-3">
							<span>{{datosFP.ENCFACPRO_PAISPAGODET}}</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-5" style="text-align: left">
							<span>Aplica Convenio de doble tributación en el pago:</span>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<!--<p-dropdown [options]="cmbconvdobletrib" [style]="{'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}"
										(onClick)="llenarcmbconvdobletrib()"
										(onChange)="busqconvdobletrib($event)"
							></p-dropdown>-->
							<p-dropdown [options]="cmbconvdobletribaux" [style]="{'width':'100%'}" optionLabel="name"
								[panelStyle]="{'width':'110%'}" [(ngModel)]="selectconvdobletrib"
								[disabled]="boolcmbtiporegimen"></p-dropdown>
						</div>
						<div class="p-col-5"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -20px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-5" style="text-align: left">
							<span>Pago al exterior sujeto a retención en aplicación a la norma legal:</span>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<!--<p-dropdown [options]="cmbpagoexterior" [style]="{'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}"
										(onClick)="llenarcmbpagoexterior()"
										(onChange)="busqpagoexterior($event)"></p-dropdown>-->
							<p-dropdown [options]="cmbpagoexterioraux" [style]="{'width':'100%'}" optionLabel="name"
								[panelStyle]="{'width':'110%'}" [(ngModel)]="selectpagoexterior"
								[disabled]="boolcmbtiporegimen"></p-dropdown>
						</div>
						<div class="p-col-5"></div>
					</div>
				</div>
				<div class="p-col-12" *ngIf="bolPagoRegFiscal" style="margin-top: -20px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-5" style="text-align: left">
							<span>El Pago es a un régimen fiscal preferente o de menor imposición:</span>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<!--<p-dropdown [options]="cmbpagoregfiscal" [style]="{'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}"
										(onClick)="llenarcmbpagoregfiscal()"
										(onChange)="busqpagoregfiscal($event)"></p-dropdown>-->
							<p-dropdown [options]="cmbpagoregfiscalaux" [style]="{'width':'100%'}" optionLabel="name"
								[panelStyle]="{'width':'110%'}" [(ngModel)]="selectpagoregfiscal"></p-dropdown>
						</div>
						<div class="p-col-5"></div>
					</div>
				</div>

				<div class="p-col-9" *ngIf="groupCompRet" style="margin-top: -20px">
					<span>Comprobante de Retención</span>
					<div class="divgrups" aria-valuetext="Comprobante">
						<div class="p-grid">
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left">
										<span>Establecimiento:</span>
									</div>
									<div class="p-col-2">
										<input type="text" id="establecimiento" (input)="cambio()" style="width: 100%"
											[maxLength]="10" (keydown.enter)="setFocus($event)" (keydown)="cambio()"
											[(ngModel)]="datosFP.ENCFACPRO_RETSERIEEST" [disabled]="booleanRegimen">
									</div>
									<div class="p-col-3" style="text-align: left">
										<span>Punto de Emisión:</span>
									</div>
									<div class="p-col-2">
										<input type="text" id="puntoemision" (input)="cambio()" style="width: 100%"
											[maxLength]="10" (keydown.enter)="setFocus($event)" (keydown)="cambio()"
											[(ngModel)]="datosFP.ENCFACPRO_RETSERIEPTOEMI" [disabled]="booleanRegimen">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -7px">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left">
										<span>No. Secuencial:</span>
									</div>
									<div class="p-col-2">
										<input type="text" id="secuencial" (input)="cambio()" style="width: 100%"
											[maxLength]="10" (keydown.enter)="setFocus($event)" (keydown)="cambio()"
											[(ngModel)]="datosFP.ENCFACPRO_RETSECUENCIAL" [disabled]="booleanRegimen">
									</div>
									<div class="p-col-3" style="text-align: left">
										<span>No. Autorización comprobante:</span>
									</div>
									<div class="p-col-4">
										<input type="text" id="autorizacion" (input)="cambio()" style="width: 100%"
											[maxLength]="10" (keydown.enter)="setFocus($event)" (keydown)="cambio()"
											[(ngModel)]="datosFP.ENCFACPRO_RETAUTORIZACION" [disabled]="booleanRegimen">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -10px">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left">
										<span>Fecha Emisión:</span>
									</div>
									<div class="p-col-2">
										<p-calendar id="fechaemision" [inputStyle]="{width: '100%', height: '20px'}"
											[(ngModel)]="datosFP.ENCFACPRO_RETFECHA" [readonlyInput]="true"
											dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
											(keydown)="cambio()">
										</p-calendar>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Forma Pago">
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
			[rowData]="datosanexService.formapago" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (selected)="seleccion($event)"
			[mostrarGuardar]="false" (cambios)="cambio()"></app-aggridsaci>
		<div class="p-col-12" style="margin-bottom: -20px">
			<div class="p-grid">
				<div class="p-col-11">
					<span style="text-align: left; color: darkred; font-weight: bold">SRI Bancarización para la
						deducibilidad de costos y gastos</span>
				</div>
				<div class="p-col-11">
				</div>
				<div class="p-col-11" style="text-align: left; margin-top: -10px; margin-bottom: 10px">
					<p style="text-align: justify; font-family: Arial, serif;font-size: 9pt;">
						"Para que el costo o gasto por cada caso entendido superior a los quinientos dólares
						de los Estados Unidos de América (USD 500,00) sea deducible para el cálculo del
						Impuesto a la Renta y el crédito tributario para el Impuesto al Valor Agregado sea
						aplicable, se establece la obligatoriedad de utilizar a cualquier institución del sistema
						financiero para realizar el pago, a través de giros, transferencias de fondos, tarjetas
						de crédito y débito, cheques o cualquier otro medio de pago electrónico."</p>
				</div>

			</div>
		</div>



	</p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>