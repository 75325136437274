<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="seriales"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="serialesconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonBorrar]="botonBorrar"
				 [botonRegresar]="botonRegresar"
				 (signal)="manejarSenales($event)" [barraBotonesDesplaz]="barraBotones2" [barraBotones3]="barraBotones2"
				 [barraBotonesAsiCon]="barraBotones2" [barraBotones5]="true" [imprimir]="true"
				 [noNuevo]="true"></app-invmaebarra>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-1">
			<span>Número:</span>
		</div>
		<div class="p-col-4">
			<label for="NUMFACT"></label>
			<input class="frm" id="NUMFACT" type="text" pInputText disabled
				   [(ngModel)]="strNumFact" autocomplete="off">
		</div>
		<div class="p-col-2">
			<span>Fecha Ingreso:</span>
		</div>
		<div class="p-col-2">
			<label for="FECHA"></label>
			<input class="frm" id="FECHA" type="text" pInputText disabled
				   [(ngModel)]="strFechaSer" autocomplete="off">
		</div>
		<div class="p-col-1">
		</div>
		<div class="p-col-1">
			<span>dd/mm/yyyy</span>
		</div>
		<div class="p-col-12">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="tabladetalle"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsDetalle"
							[defaultColDef]="defaultColDefDetalle"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selecciondetalle($event)"
							[notlistenkey]="true"
							(click)="selecciondetalle($event)">
			</app-aggridsaci>
		</div>
		<div class="p-col-2">
			<span>Artículo</span>
		</div>
		<div class="p-col-2">
			<span>{{detalleseleccionado.ARTCODIGO}}</span>
		</div>
		<div class="p-col-4">
		</div>
		<div class="p-col-2">
			<p-checkbox *ngIf="devBoolean === true" label="Todos" binary="true" [(ngModel)]="selectAll" (ngModelChange)="seleccionarSeriales()"></p-checkbox>
		</div>
		<div class="p-col-2">
			<button type="button"
					style="background-image: url(../../../assets/images/iconos/077-xls.png);"
					(click)="subirArchivo()"></button>
		</div>

		<div class="p-col-12">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="tablaseriales"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsSeriales"
							[defaultColDef]="defaultColDefSeriales"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="seleccionseriales($event)"
							(cambios)="cambio($event)"
							(click)="seleccionseriales($event)">
			</app-aggridsaci>
		</div>
	</div>
</div>
<!-- <input
#inputFile
type="=file"
(change)="onFileSelected($event)"
accept=".txt"
style="display: none"
> -->

<input  #inputFile type="file" style="display: none; opacity: 0.0; position: absolute; 
			top: 0; left: 0; bottom: 0; right: 0; width: 25px; height: 25px; cursor: pointer;"
			accept=".txt"
                (change)="onFileSelected($event)" />
<!--
<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clave" [(ngModel)]="claveAuth" (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>
-->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>