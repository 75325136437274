//
// SACI WEB
// Rutina: Ingreso de Toma Física
// Propietario: Ivonne Vega
// Modificado por: IV
// Fecha de creación: 2021
// Fecha de Modificación: 01/02/2021
//
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Invdettomafisicavar } from '../invinterfaces/invdettomafisicavar';
import { InvtomafisicaService } from '../invservicios/invtomafisica.service';
import { Invtrntomafisica } from '../invinterfaces/invtrntomafisica';
import { InvbusquedaService } from '../invservicios/invbusqueda.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { Usuario } from '../../usuario';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-invtomafisica',
  templateUrl: './invtomafisica.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvtomafisicaComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  choosedColor: string;

  activeIndex = 0;
  // botonNuevo: boolean;
  // botonGuardar: boolean;
  // botonRegresar: boolean;
  // botonBorrar: boolean;
  // botonPrimero: boolean;
  // botonAnterior: boolean;
  // botonSiguiente: boolean;
  // botonUltimo: boolean;
  claveAuth: string;
  arregloBusq: any[];

  displayDialogAsiento: boolean;
  displayAsiContable: boolean;

  largo: string;

  asiento: any;
  claveDialog: boolean;
  public frameworkComponents;
  rowStyle;
  rowStyleVar;
  defaultColDefTomF;
  defaultColDefTomFVar;
  rowSelection = 'multiple';

  // botones
  // btnVisto: boolean;
  // btnReversar: boolean;

  // NEW

  indicador: any;
  permisos: SegMaePermiso;

  // Busqueda
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  opcion: string;
  spin=false;//CDPJ
  boolAjuste:boolean//CDPJ

  strEstadoTomaF = '';
  // btnBuscar: boolean;
  // btnGenAsiento: boolean;
  // btnVerAsiento: boolean;
  strFiltroNav = ' COM_CODIGO = \'01\'';
  listaFilaAjuste: any[] = [];
  disabledDateTime = false;
  disabledTime = false;


  detalleTomFisSel: Invtrntomafisica = {};
  detalleVariosSel: Invdettomafisicavar = {};
  intRecalculaCostoPromedio = 0;
  nuevoitem: Invtrntomafisica;//CDPJ

  disabledTab0 = false;
  disabledTab1 = false;

  displayDialogReportes: boolean;
  auxreporte: number = 0;
  strNombreForma: string;

  displayDialogDocumentos = false;

  columnDefsTomaF = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      // editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          // obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Artículo', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 120,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Nombre', field: 'ART_NOMBRE', cellEditor: 'cellPrueba', width: 225,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 3,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 80
        }
      }
    },
    { // 3
      headerName: 'Unidad', field: 'ART_UNIDADCOSTEO', cellEditor: 'cellPrueba', editable: false, width: 90,
      // , editable: true,
    },
    { // 4
      headerName: 'Cant. Kardex', field: 'TRNTOMAFISICA_CANTIDADK', cellEditor: 'cellPrueba', editable: false, width: 110,
      cellStyle: { textAlign: 'right' },
      // , editable: true,
    },
    { // 5
      headerName: 'Toma Física', field: 'TRNTOMAFISICA_CANTIDAD', cellEditor: 'cellPrueba', width: 110, cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          int: true,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 6
      headerName: 'Ajust', cellRendererFramework: AggridBanComponent, field: 'TRNTOMAFISICA_AJUSTE', editable: false, width: 80,
    },
    { // 7
      headerName: 'Valor Ajuste', field: 'TRNVALOR_AJUSTE', editable: false, cellStyle: { textAlign: 'right' }, width: 110,
    },
    { // 8
      headerName: 'Costo Promedio', field: 'TRNTOMAFISICA_COSTOPROM', cellEditor: 'cellPrueba', editable: false, width: 110, cellStyle: { textAlign: 'right' },
      // , editable: true,
    },
    { // 9
      headerName: 'Costo Total', field: 'TRNTOMAFISICA_TOTAL', cellEditor: 'cellPrueba', width: 110, editable: false, cellStyle: { textAlign: 'right' },
      // , editable: true,      
    }];

  columnDefsTomaFVar = [
    {
      headerName: 'Ref. Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba',
      // editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 35
        }
      }
    },
    {
      headerName: 'Descripción', field: 'CON_NOMBRE', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 50
        }
      }
    },
    {
      headerName: 'Debe', field: 'DEBE', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          int: true,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Haber', field: 'HABER', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          int: true,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Cnt. Costo', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      editable: (params) => {
        const boolEdit = this.isEditCen(params.data);
        return boolEdit;
      },

      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    }];

  constructor(public tomFisService: InvtomafisicaService, private busquedaService: InvbusquedaService,
    private messageService: MessageService, private confirm: ConfirmationService, private busquedaSer: InvbusquedaService,
    private confIniciales: ConfInicialesService, private datePipe: DatePipe, private init: NuevoComponentService,
    private auditoriaGralService: AuditoriagralService, public usuario: Usuario,
    private permisosService: PermisosService, private errorService: ErroresBaseDatosService,
    private utilitariosService: UtilitariosService) {
    this.agTable();
    this.tomFisService.encTomaFisica[this.indicador] = {};
    this.tomFisService.detTomaFisicaArray[this.indicador] = [];
    this.tomFisService.detTomFisVarArray[this.indicador] = [];
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.rowStyleVar = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.rowStyle = (params) => {
      if (params.data.KARDEX === 'SI') {
        return { background: '#FFFAAD' };
      }
    };
    this.defaultColDefTomF = {
      editable: true,
      width: 130,
      objeto: 'maetomafisica'
    };
    this.defaultColDefTomFVar = {
      editable: true,
      width: 130,
      objeto: 'maetomafisicavar'
    };
  }

  async ngOnInit() {
    this.getPermisos();
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;

    this.tomFisService.encTomaFisica[this.indicador] = {};
    this.tomFisService.detTomaFisicaArray[this.indicador] = [];
    this.tomFisService.detTomFisVarArray[this.indicador] = [];
    this.largo = '-5800';

    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_COMENTARIO = '';
    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA = new Date();
    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_HORA = new Date();
    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO = '';
    this.tomFisService.encTomaFisica[this.indicador].ASI_NRO = '';
    this.tomFisService.encTomaFisica[this.indicador].VALOR_TOTAL = '';
    this.tomFisService.encTomaFisica[this.indicador].VALOR_TOTALAJU = '';

    this.habilitarBotones(false, true, true, true, false, false, false, false,
      true, true, true, true, false, true);
    this.habilitarBotonesDet(false, false, true, false, false);
    this.habilitarBotonesDetVar(false, false, true);
    const rs = await this.tomFisService.getDecimales('frmINV_TRNTOMAFISICA');
    this.tomFisService.configDecimales = rs;

    this.auditoriaGralService.obtenerConfiguracion1().subscribe((res) => {
      this.tomFisService.hmpConfiguracion = res;
    });
console.log(this.tomFisService.obtenerConfig('CANTIDAD', 'trntomaf'));
console.log(this.tomFisService.configDecimales)
    this.columnDefsTomaF[5].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.tomFisService.obtenerConfig('CANTIDAD', 'trntomaf'),
        obligatorio: false,
        fecha: false,
        int: true,
        tamanocelda: 32,
        tienecalculos: true
      }
    }

    setTimeout(() => {
      this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultColDefTomF.objeto, this.columnDefsTomaF);
    }, 2000);
  }

  async manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }
    if (valor === 'Guardar') {
      if (this.activeIndex === 0) {
        this.guardarRegistro();
      }
      if (this.activeIndex === 1) {
        this.guardarRegistroVarios();
      }

    }

    if (valor === 'Asiento') {
      this.generarAsientoContable();
    }

    if (valor === 'AsientoVer') {
      if (this.tomFisService.encTomaFisica[this.indicador].ASI_NRO !== '') {
        this.asiento = this.tomFisService.encTomaFisica[this.indicador].ASI_NRO;
        this.displayAsiContable = true;
      } else {
        this.mensajeAlerta('info', 'Información', 'No ha generado el Asiento Contable');
      }
    }

    if (valor === 'Cancelar') {
      this.cancelarRegistro();
    }

    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }

    if (valor === 'Primero') {
      this.navegarRegistro('P', this.strFiltroNav, '');
    }

    if (valor === 'Anterior') {
      this.registroAnterior();
    }

    if (valor === 'Siguiente') {
      this.registroSiguiente();

    }
    if (valor === 'Ultimo') {
      this.navegarRegistro('U', this.strFiltroNav, '');
    }

    if (valor === 'Visto') {
      this.ajustarTomaFKardex();
    }

    if (valor === 'Reversar') {
      this.eliminarTomaFKardexRev();
    }

    if (valor === 'Buscar') {
      this.buscarRegistro();
    }

    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }

    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }

    if (valor === 'Almacenardoc') {
      if (this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'tom',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }
        
      }
    }

  }

  async guardarRegistroVarios() {
    this.aggrid.gridApi.stopEditing();
    if (await this.insertarVarios(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO)) {
      this.disabledTab0 = false;
      this.habilitarBotones(false, true, false, true, false, false, false, false, false, false,
        false, false, false, false);
      this.habilitarBotonesDetVar(false, false, true);
    }
    this.cancelarRegistro();//CDPJ
  }

  async insertarVarios(strNumDoc) {
    this.eliminarUltimaLineaVarios();
    let bolInsertar = true;
    const dblTotalDoc = Number(this.tomFisService.encTomaFisica[this.indicador].VALOR_TOTALAJU);
    const dblTotalVarios = this.obtenerTotalVarios();
    let dblDiferencia = dblTotalDoc + dblTotalVarios;
    let strDato6 = 0;

    dblDiferencia = Number(Number(dblDiferencia).toFixed(2));

    if (this.tomFisService.detTomFisVarArray[this.indicador].length > 0 &&
      String(this.tomFisService.detTomFisVarArray[this.indicador][0].CON_CODIGO).length > 0) {
      if (dblDiferencia === 0) {
        console.log(this.tomFisService.detTomFisVarArray[this.indicador].length);
        for (let fila = 0; fila < this.tomFisService.detTomFisVarArray[this.indicador].length; fila++) {
          let item: Invdettomafisicavar = {};
          item = this.tomFisService.detTomFisVarArray[this.indicador][fila];
          console.log(item);
          if (Number(item.DEBE) > 0) {
            strDato6 = Number(item.DEBE);
          } else {
            strDato6 = Number(item.HABER) * (-1);
          }

          try {
            await this.tomFisService.insertarVarios(strNumDoc, item.CON_CODIGO, item.CON_NOMBRE, item.CEN_CODIGO, Number(fila + 1),
              strDato6);
            bolInsertar = true;
            if (fila === 0) {
              this.auditoriaGralService.registrarAuditoria('INV_DETTOMAFVAR', strNumDoc + '/INV', 'I',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => { });
            }
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'La información no se grabó correctamente. ' + mensaje);
            bolInsertar = false;
          }
        }
      } else {
        this.aggrid.gridApi.stopEditing();
        this.mensajeAlerta('info', 'Información', 'Los valores ingresados descuadran con el total de ajustes de la Toma ' +
          'Física.');
        bolInsertar = false;
      }
    } else if (this.tomFisService.detTomFisVarArray[this.indicador].length === 0) {
      //const strSentencia = ' DELETE FROM INV_DETTOMAFVAR WHERE TRNTOMAFISICA_NRO = \'' + strNumDoc + '\' AND COM_CODIGO = \'01\'';
      try {
        await this.tomFisService.eStrntomafisica(strNumDoc);
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'La información no se grabó correctamente. ' + mensaje);
        bolInsertar = false;
      }
    }
    return bolInsertar;
  }



  eliminarUltimaLineaVarios() {
    let intIndiceLista = 0;
    let item: Invdettomafisicavar = {};
    for (intIndiceLista = 0; intIndiceLista < this.tomFisService.detTomFisVarArray[this.indicador].length; intIndiceLista++) {
      item = this.tomFisService.detTomFisVarArray[this.indicador][intIndiceLista];
      console.log(item);
      if (item.CON_CODIGO === '') {
        this.tomFisService.detTomFisVarArray[this.indicador].splice(this.tomFisService.detTomFisVarArray[this.indicador].findIndex(
          tomFis => tomFis.LINEA === item.LINEA),
          1);
      }

    }
    this.aggrid.refreshaggrid(this.tomFisService.detTomFisVarArray[this.indicador], this.defaultColDefTomFVar.objeto);
  }

  async eliminarTomaFKardexRev() {
    if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
      if (await this.verificarTomaFKardex(String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO))) {
        this.claveAuth = '';
        this.claveDialog = true;
      } else {
        this.mensajeAlerta('info', 'Información', 'Ningún artículo de la toma física está ajustado en el Kardex.');
      }
    } else {
      this.mensajeAlerta('info', 'Información', 'Seleccione la Toma Física que desea Eliminar del Kardex');
    }
  }

  cancelarRegistro() {

    this.aggrid.gridApi.stopEditing();

    if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
      const strCondicion = ' com_codigo = \'01\' and trntomafisica_nro = \'' + this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO
        + '\'';
      this.navegarRegistro('R', strCondicion, '');
      this.disabledTab0 = false;
      this.disabledTab1 = false;
    } else {
      this.disabledTab0 = false;
      this.disabledTab1 = false;
      this.limpiarDatos();
      this.habilitarBotones(false, true, true, true, false, false, false, false,
        true, true,
        true, true, false, true);
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      document.getElementById('divPrincipal2').style.pointerEvents = 'none';
    }

    this.tomFisService.btnNuevoDet[this.indicador] = false;
    this.tomFisService.btnEliminarDet[this.indicador] = false;
    this.tomFisService.btnCancelarDet[this.indicador] = true;

    this.tomFisService.btnNuevoDetVar[this.indicador] = true;
    this.tomFisService.btnEliminarDetVar[this.indicador] = true;
    this.tomFisService.btnCancelarDetVar[this.indicador] = false;

  }

  async eliminarRegistro() {
    const strNumDoc = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO;

    if (this.permisos !== null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERELIMACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');

          return;
        }
      }
    }
    if (! await this.verificarTomaFKardex(strNumDoc)) {
      if (String(strNumDoc).length > 0) {

        this.confirm.confirm({
          message: 'Está seguro de eliminar la Toma Física ' + strNumDoc + ' ?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'tomConf',
          accept: () => {
            this.confimElimTomFis(strNumDoc);
          },
          reject: () => {
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'No hay un documento para la eliminación.');
      }
    } else {
      this.mensajeAlerta('info', 'Información', 'Existen artículos ajustados en el Kardex, primero debe eliminar la ' +
        'toma física del Kardex.');
    }
  }

  async confimElimTomFis(strNumDoc) {
    if (await this.eliminarTomaFisica(strNumDoc, -1, false)) {
      this.mensajeAlerta('success', 'Información', 'Registro elimnado exitosamente...!!! ');
      this.disabledTab0 = false;
      this.disabledTab1 = false;
      this.habilitarBotones(false, true, true, true, false, false, false, false,
        false, true, true, false, false, true);
    }
  }

  async guardarRegistro() {
    this.aggrid.gridApi.stopEditing();
    this.eliminarUltimaLineaDetalle();

    // this.guardarRegistroTomFis();
    // this.disabledTab1 = false;
    // this.tomFisService.btnNuevoDet[this.indicador] = false;
    // this.tomFisService.btnEliminarDet[this.indicador] = false;
    // this.tomFisService.btnCancelarDet[this.indicador] = true;

    // this.habilitarBotones(false, true, false, true, false, false, false, false,
    //   false, true, false, false, false, false);
    //CDPJ
    if(await this.guardarRegistroTomFis() === true){
      this.disabledTab1 = false;
      this.tomFisService.btnNuevoDet[this.indicador] = false;
      this.tomFisService.btnEliminarDet[this.indicador] = false;
      this.tomFisService.btnCancelarDet[this.indicador] = true;
  
      this.habilitarBotones(false, true, false, true, false, false, false, false,
        false, true, false, false, false, false);
        this.cancelarRegistro();
    }
    //CDPJ

  }

  async guardarRegistroTomFis() {
    let bolGuardar: any = false;
    this.cargarTotalTomaFisica();
    if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length === 0) {
      if (this.permisos !== null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERINSERCION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
            return false;
          }
        }
      }

    } else {
      if (this.permisos !== null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERACTUALIZACION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
            return false;
          }
        }
      }
    }
    for (let fila = 0; fila < this.tomFisService.detTomaFisicaArray[this.indicador].length; fila++) {
      let item: Invtrntomafisica = {};
      item = this.tomFisService.detTomaFisicaArray[this.indicador][fila];
      //strNumTFAnt = item.TRNTOMAFISICA_NRO;
      const rsdata: any = await this.tomFisService.encontrarArtCntaySer(item.ART_CODIGO, 'A');
      let strCodigo = 0;
        if (rsdata !== null) {
          for (const rs of rsdata) {
            strCodigo = rs.NUMERO;
          }
        }
        if ( strCodigo === 0) {
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Ingreso Notas de Recepción',
            detail: 'El artículo ' + item.ART_CODIGO + ' no existe'
          });
          this.mensajeAlerta('error', 'Error', 'El artículo ' + item.ART_CODIGO + ' no existe');
          return false;
        }
    }
    bolGuardar = await this.insertarTomaFisica();

    return bolGuardar;
  }

  async insertarTomaFisica() {

    let bolInsertar = false;

    let strNumTF = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO;
    let strNumTFAnt = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO; // para saber si es actualización no debe estar vacío
    console.log(strNumTFAnt);
    console.log(String(strNumTFAnt).length);
    let strFecha1 = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA;
    if (strFecha1.toString().includes('-')) {
      strFecha1 = this.datePipe.transform(strFecha1, 'dd/MM/yyyy');
    }

    for (let fila = 0; fila < this.tomFisService.detTomaFisicaArray[this.indicador].length; fila++) {
      let item: Invtrntomafisica = {};
      item = this.tomFisService.detTomaFisicaArray[this.indicador][fila];
      strNumTFAnt = item.TRNTOMAFISICA_NRO;


      const time = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_HORA;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      const strHoraTF = hora + ':' + minutos;


      try {
        const rs = await this.tomFisService.insertarTomaFisica(
          String(item.ART_CODIGO), String(item.ART_NOMBRE),
          Number(item.TRNTOMAFISICA_CANTIDADP), Number(item.TRNTOMAFISICA_CANTIDADK),
          Number(item.TRNTOMAFISICA_CANTIDADF), Number(item.TRNTOMAFISICA_CANTIDAD),
          String(item.BOD_CODIGO), strFecha1, '01', strNumTF,
          Number(item.TRNTOMAFISICA_LINEA), item.TRNTOMAFISICA_AJUSTE === true ? 'S' : 'N',
          strHoraTF, Number(item.TRNTOMAFISICA_COSTOPROM), String(item.ART_UNIDADCOSTEO),
          this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_COMENTARIO, 0);

        console.log(rs);
        if (rs !== null) {
          strNumTF = rs[0][':B2'];
          this.tomFisService.detTomaFisicaArray[this.indicador][fila].TRNTOMAFISICA_LINEA = rs[0][':B1'];
          bolInsertar = true;
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'La información no se grabó correctamente. ' + mensaje);
        bolInsertar = false;
      }
    }

    if (bolInsertar) {
      console.log(String(strNumTFAnt).length);
      if (String(strNumTFAnt).length === 0) {
        this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente...!!! ');
        this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO = strNumTF;
        this.auditoriaGralService.registrarAuditoria('INV_TRNTOMAFISICA', strNumTF + '/INV', 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => { });
      } else {
        this.mensajeAlerta('success', 'Información', 'Registro actualizado exitosamente...!!! ');
        this.auditoriaGralService.registrarAuditoria('INV_TRNTOMAFISICA', strNumTF + '/INV', 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => { });
      }
    } else {
      await this.eliminarTomaFisica(strNumTF, -1, true);
    }

    return bolInsertar;
  }

  eliminarUltimaLineaDetalle() {
    let intIndiceLista = 0;
    let item: Invtrntomafisica = {};
    for (intIndiceLista = 0; intIndiceLista < this.tomFisService.detTomaFisicaArray[this.indicador].length; intIndiceLista++) {
      item = this.tomFisService.detTomaFisicaArray[this.indicador][intIndiceLista];
      if (item.ART_CODIGO === '') {
        this.tomFisService.detTomaFisicaArray[this.indicador].splice(this.tomFisService.detTomaFisicaArray[this.indicador].findIndex(
          tomFis => tomFis.LINEA === item.LINEA),
          1);
      }

    }
    this.aggrid.refreshaggrid(this.tomFisService.detTomaFisicaArray[this.indicador], this.defaultColDefTomF.objeto);

  }

  nuevoRegistro() {
    if (this.permisos !== null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERINSERCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');

          return;
        }
      }
    }

    this.encNuevoRegistro();
  }

  encNuevoRegistro() {
    console.log(this.tomFisService.encTomaFisica[this.indicador]);
    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    document.getElementById('divPrincipal2').style.pointerEvents = 'all';
    document.getElementById('divPrincipal3').style.pointerEvents = 'all';
    this.disabledTime = false;
    this.disabledDateTime = false;
    this.disabledTab1 = true;
    document.getElementById('comentario').focus();
    // cmpVariosTF.groupVariosTF.setEnabled(true);
    this.habilitarBotones(true, false, true, false, true, true, true, true,
      true, true, true, true, true, true);

    if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
      this.limpiarDatos();
    }
  }

  limpiarDatos() {
    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO = '';
    this.tomFisService.encTomaFisica[this.indicador].ANULADO = '';
    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA = new Date();
    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_HORA = new Date();
    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_COMENTARIO = '';
    this.tomFisService.encTomaFisica[this.indicador].ASI_NRO = '';
    this.tomFisService.encTomaFisica[this.indicador].VALOR_TOTAL = '';
    this.tomFisService.encTomaFisica[this.indicador].VALOR_TOTALAJU = '';

    this.tomFisService.detTomaFisicaArray[this.indicador] = [];

    if (this.tomFisService.detTomFisVarArray[this.indicador].length > 0) {
      this.tomFisService.detTomFisVarArray[this.indicador] = [];
    }
  }

  registroSiguiente() {
    const strNumeroTomaF = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO;
    if (String(strNumeroTomaF).length > 0) {
      const strCondicion = ' TRNTOMAFISICA_NRO > \'' + strNumeroTomaF + '\'';
      this.navegarRegistro('S', this.strFiltroNav, strCondicion);
    } else {
      this.navegarRegistro('U', this.strFiltroNav, '');
    }

  }

  registroAnterior() {
    const strNumeroTomaF = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO;
    if (String(strNumeroTomaF).length > 0) {
      const strCondicion = ' TRNTOMAFISICA_NRO < \'' + strNumeroTomaF + '\'';

      this.navegarRegistro('A', this.strFiltroNav, strCondicion);
    } else {
      this.navegarRegistro('P', this.strFiltroNav, '');
    }

  }

  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('error', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }

  buscarRegistro() {
    if (this.permisos !== null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de consulta. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.opcion = 'buscarTomFis';
    this.types = [
      { label: 'NÚMERO', value: 'TRNTOMAFISICA_NRO' },
      { label: 'FECHA', value: 'TRNTOMAFISICA_FECHA' },
      { label: 'HORA', value: 'TRNTOMAFISICA_HORA' },
      { label: 'COMENTARIO', value: 'TRNTOMAFISICA_COMENTARIO' },
      { label: 'ASIENTO', value: 'ASI_NRO' },
    ];
    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = ' Distinct(TRNTOMAFISICA_NRO), TRNTOMAFISICA_FECHA, TRNTOMAFISICA_HORA, ASI_NRO, TRNTOMAFISICA_COMENTARIO';
    this.tabla = 'INV_TRNTOMAFISICA';
    this.where = '';

    this.busquedaSer.busquedaInvTrnTomaFisica1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((dato) => {
        if (dato.TRNTOMAFISICA_FECHA !== null) {
          dato.TRNTOMAFISICA_FECHA = this.datePipe.transform(dato.TRNTOMAFISICA_FECHA, 'dd/MM/yyyy');
        } else {
          dato.TRNTOMAFISICA_FECHA = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }


  manejarSeleccion(opcion) {
    if (this.opcion === 'buscarTomFis') {
      this.navegarRegistro('R', 'COM_CODIGO = \'01\' and TRNTOMAFISICA_nro = \'' + opcion.TRNTOMAFISICA_NRO + '\'', '');
    }

    this.displayDialogBusquedaFast = false;
  }

  async navegarRegistro(strOpcion, strFiltro, strCondicion) {
    if (this.permisos !== null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de consulta. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    let strNumTF: any = '';
    strNumTF = await this.seleccionarRegistro(strOpcion);
    if (String(strNumTF).length > 0) {
      strFiltro = strFiltro + ' and trntomafisica_nro = \'' + strNumTF + '\'';
    }

    await this.consultarRegistro(strOpcion, strFiltro, strCondicion);

  }

  async consultarRegistro(strOpcion, strFiltro, strCondicion) {
    this.spin=true;//CDPJ
    this.tomFisService.encTomaFisica[this.indicador].ANULADO = '';
    if (await this.consultarTomaFisica(strOpcion, strFiltro, strCondicion)) {
      this.cargarTotalTomaFisica();

      // verificar Periodo Contable

      const dia = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA.getDate();
      const mes = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA.getMonth();
      const anio = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA.getFullYear();

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio)) {
        if (String(this.tomFisService.encTomaFisica[this.indicador].ASI_NRO).length > 0) {
          this.habilitarBotones(false, true, false, true, false, false, false, false,
            true, false, false, false, false, false);
        } else {
          this.habilitarBotones(false, true, false, true, false, false, false, false,
            false, true, false, false, false, false);
        }

        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        document.getElementById('divPrincipal2').style.pointerEvents = 'all';
        document.getElementById('divPrincipal3').style.pointerEvents = 'all';//CDPJ
        // toolBarDetalle.setEnabled(true);
        // groupDetalle.setEnabled(true);
      } else {
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        document.getElementById('divPrincipal2').style.pointerEvents = 'none';
        document.getElementById('divPrincipal3').style.pointerEvents = 'none';//CDPJ
        // toolBarDetalle.setEnabled(false);
        this.habilitarBotones(false, true, true, true, false, false, false, false, true,
          true, true, false, false, false);
          this.boolAjuste=false;
      }
      this.spin=false;//CDPJ
    }

    if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
      if (this.tomFisService.detTomFisVarArray[this.indicador].length > 0) {
        this.tomFisService.detTomFisVarArray[this.indicador] = [];
      }
      await this.consultarVarios(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);
      // verificar Periodo Contable
      // if ( Utilidades.verificarPeriodoContable( dateTimeFecha.getDay(),
      //   dateTimeFecha.getMonth(), dateTimeFecha.getYear()) )
      // {
      //   for (Control ctrl : cmpVariosTF.getChildren())
      //   ctrl.setEnabled(true);
      // }
      // else{
      //   for (Control ctrl : cmpVariosTF.getChildren())
      //   ctrl.setEnabled(false);
      // }
    }
//CDPJ
if (this.boolAjuste === true) {
  //this.tomFisService.encTomaFisica[this.indicador].ANULADO = 'ANULADO';
  document.getElementById('divPrincipal').style.pointerEvents = 'none';
  document.getElementById('divPrincipal2').style.pointerEvents = 'none';
  // document.getElementById('divPrincipal3').style.pointerEvents = 'none';
  this.habilitarBotones(false, true, true, true, false, false, false, false,
    true, false, false, false, false, false);
}
//CDPJ
    if (this.strEstadoTomaF !== null) {
      if (this.strEstadoTomaF === 'A') {
        this.tomFisService.encTomaFisica[this.indicador].ANULADO = 'ANULADO';
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        document.getElementById('divPrincipal2').style.pointerEvents = 'none';
        document.getElementById('divPrincipal3').style.pointerEvents = 'none';
        this.habilitarBotones(false, true, true, true, false, false, false, false,
          true, true, true, true, false, true);
      }
    }
  }

  async consultarVarios(strNumTF) {
    this.habilitarBotonesDetVar(false, false, true);
    const bolConsultar = false;

    this.tomFisService.detTomFisVarArray[this.indicador] = [];
    const listaVariosTF: Invdettomafisicavar[] = [];

    try {
      const rs = await this.tomFisService.eSdettomafvartomafisica(strNumTF);
      let num = 0;
      for (const varios of rs) {
        const item: Invdettomafisicavar = {};
        num = num + 1;
        item.TRNTOMAFISICA_NRO = varios.TRNTOMAFISICA_NRO;
        item.CON_CODIGO = varios.CON_CODIGO;
        item.CON_NOMBRE = varios.CON_NOMBRE;
        item.CEN_CODIGO = varios.CEN_CODIGO === null ? '' : varios.CEN_CODIGO;
        item.COM_CODIGO = varios.COM_CODIGO;
        item.DETTOMAFVAR_LINEA = varios.DETTOMAFVAR_LINEA;
        item.LINEA = num;
        if (Number(varios.DETTOMAFVAR_IMPORTE) >= 0) {
          item.DEBE = Number(varios.DETTOMAFVAR_IMPORTE).toFixed(2);
          item.HABER = Number(0).toFixed(2);
        } else {
          item.HABER = Number(Number(varios.DETTOMAFVAR_IMPORTE) * (-1)).toFixed(2);
          item.DEBE = Number(0).toFixed(2);
        }
        listaVariosTF.push(item);
      }
      this.tomFisService.detTomFisVarArray[this.indicador] = listaVariosTF;

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede recuperar la información de la pestaña Varios. ' + mensaje);
    }

    return bolConsultar;
  }

  // habilitarBotones(nuevo, guardar, eliminar, cancelar, inicio, anterior, siguiente, ultimo, ajustar, elimTFKard, genAsiento, verAsiento,
  //   busqueda, imprimir) {
  //   this.botonNuevo = nuevo;
  //   this.botonGuardar = guardar;
  //   this.botonBorrar = eliminar;
  //   this.botonPrimero = inicio;
  //   this.botonAnterior = anterior;
  //   this.botonSiguiente = siguiente;
  //   this.botonUltimo = ultimo;
  //   this.btnVisto = ajustar;
  //   this.btnReversar = elimTFKard;
  //   this.btnBuscar = busqueda;
  //   this.btnGenAsiento = genAsiento;
  //   this.btnVerAsiento = verAsiento;
  // }
  habilitarBotones(nuevo, guardar, eliminar, cancelar, inicio, anterior, siguiente, ultimo, ajustar, elimTFKard, genAsiento, verAsiento,
    busqueda, imprimir) {
    this.tomFisService.botonNuevo = nuevo;
    this.tomFisService.botonGuardar = guardar;
    this.tomFisService.botonBorrar = eliminar;
    this.tomFisService.botonPrimero = inicio;
    this.tomFisService.botonAnterior = anterior;
    this.tomFisService.botonSiguiente = siguiente;
    this.tomFisService.botonUltimo = ultimo;
    this.tomFisService.btnVisto = ajustar;
    this.tomFisService.btnReversar = elimTFKard;
    this.tomFisService.btnBuscar = busqueda;
    this.tomFisService.btnGenAsiento = genAsiento;
    this.tomFisService.btnVerAsiento = verAsiento;
  }

  cargarTotalTomaFisica() {
    let dblTotalTomaF = 0;
    let value = null;
    let dblTotalAjuste = 0;

    for (let index = 0; index < this.tomFisService.detTomaFisicaArray[this.indicador].length; index++) {
      let item: Invtrntomafisica = {};
      item = this.tomFisService.detTomaFisicaArray[this.indicador][index];
      dblTotalTomaF = dblTotalTomaF + Number(item.TRNTOMAFISICA_TOTAL);
    }

    value = dblTotalTomaF;
    value = Number(value).toFixed(this.tomFisService.obtenerConfig('COSTO', 'trntomaf'));

    this.tomFisService.encTomaFisica[this.indicador].VALOR_TOTAL = value;

    dblTotalAjuste = this.obtenerTotalAjuste();
    value = dblTotalAjuste;
    value = Number(value).toFixed(this.tomFisService.obtenerConfig('COSTO', 'trntomaf'));

    this.tomFisService.encTomaFisica[this.indicador].VALOR_TOTALAJU = value;
  }



  async consultarTomaFisica(strOpcion, strFiltro, strCondicion) {
    let bolConsultar = false;
    let dblCostoProm = 0;
    let dblValorAjuste = 0;
    this.listaFilaAjuste = [];
    let listaTomaF: Invtrntomafisica[] = [];

    try {
      const rs = await this.tomFisService.consultarTomaFisica(strOpcion, strFiltro, strCondicion);
      let bolPrimerReg = true;
      let num = 0;
      for (const tomFis of rs) {
        if (bolPrimerReg) {
          listaTomaF = [];
        }
        const item: Invtrntomafisica = {};
        num = num + 1;
        item.ART_CODIGO = tomFis.ART_CODIGO;
        item.ART_NOMBRE = tomFis.ART_NOMBRE;
        item.TRNTOMAFISICA_CANTIDADP = tomFis.TRNTOMAFISICA_CANTIDADP;
        item.TRNTOMAFISICA_CANTIDADK = Number(tomFis.TRNTOMAFISICA_CANTIDADK).toFixed(this.tomFisService.obtenerConfig('CANTIDAD', 'trntomaf'));
        item.TRNTOMAFISICA_CANTIDADF = tomFis.TRNTOMAFISICA_CANTIDADF;
        item.TRNTOMAFISICA_CANTIDAD = Number(tomFis.TRNTOMAFISICA_CANTIDAD).toFixed(this.tomFisService.obtenerConfig('CANTIDAD', 'trntomaf'));
        item.BOD_CODIGO = tomFis.BOD_CODIGO;
        item.TRNTOMAFISICA_FECHA = tomFis.TRNTOMAFISICA_FECHA;
        item.COM_CODIGO = tomFis.COM_CODIGO;
        item.TRNTOMAFISICA_NRO = tomFis.TRNTOMAFISICA_NRO;
        item.TRNTOMAFISICA_LINEA = tomFis.TRNTOMAFISICA_LINEA;
        item.TRNTOMAFISICA_AJUSTE = tomFis.TRNTOMAFISICA_AJUSTE === 'S' ? true : false;
        item.ASI_NRO = tomFis.ASI_NRO;
        item.TRNTOMAFISICA_COSTOPROM = Number(tomFis.TRNTOMAFISICA_COSTOPROM).toFixed(this.tomFisService.obtenerConfig('COSTO', 'trntomaf'));
        item.TRNTOMAFISICA_HORA = tomFis.TRNTOMAFISICA_HORA;
        item.TRNTOMAFISICA_COMENTARIO = tomFis.TRNTOMAFISICA_COMENTARIO;
        item.ART_UNIDADCOSTEO = tomFis.ART_UNIDADCOSTEO;
        item.TRNTOMAFISICA_ESTADO = tomFis.TRNTOMAFISICA_ESTADO;
        item.TRNTOMAFISICA_CANTIDADUND = tomFis.TRNTOMAFISICA_CANTIDADUND;
        item.STRINGRESO_CANTUND = '';
        item.KARDEX = 'NO';
        item.LINEA = num;

        dblValorAjuste = Number(tomFis.TRNTOMAFISICA_CANTIDAD) - Number(tomFis.TRNTOMAFISICA_CANTIDADK);
        item.TRNVALOR_AJUSTE = Number(dblValorAjuste).toFixed(this.tomFisService.obtenerConfig('CANTIDAD', 'trntomaf'));

        dblCostoProm = Number(tomFis.TRNTOMAFISICA_COSTOPROM);
        item.TRNTOMAFISICA_TOTAL = Number(dblCostoProm * dblValorAjuste).toFixed(this.tomFisService.obtenerConfig('COSTO', 'trntomaf'));
        console.log(item);
        listaTomaF.push(item);
        bolPrimerReg = false;
      }

      this.tomFisService.detTomaFisicaArray[this.indicador] = listaTomaF;
      // Mostrar encabezado de la toma física
      if (listaTomaF.length > 0) {
        if (this.cargarDatosEncabezado()) {
          bolConsultar = true;
          this.strEstadoTomaF = listaTomaF[0].TRNTOMAFISICA_ESTADO;
        }
        // removeRowColors();
        // cambiar el color de los artículos ajustados en el kardex
        this.cambiarColorAjuste();
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede leer la información de la base de datos. ' + mensaje);
      this.spin=false;//CDPJ
    }

    await this.ubicarCantidadPorUnidades();
    return bolConsultar;
  }

  async cambiarColorAjuste() {
    let strSentencia = '';
    let intNumReg = 0;
    let intTotalNumRegAj = 0;
    this.listaFilaAjuste = [];
    this.boolAjuste=false;//CDPJ
    //console.log(this.tomFisService.detTomaFisicaArray[this.indicador].length)
    for (let index = 0; index < this.tomFisService.detTomaFisicaArray[this.indicador].length; index++) {
      //console.log(index)
      const item = this.tomFisService.detTomaFisicaArray[this.indicador][index];
      intNumReg = 0;
      strSentencia = 'SELECT COUNT(*) as COUNT FROM INV_TRNKARDEX WHERE ART_CODIGO = \'' + item.ART_CODIGO + '\'';
      strSentencia = strSentencia + ' AND TRNART_REFERENCIA = \'' + this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO + '\'';

      try {
        const rs = await this.tomFisService.eSCodigoKardex(item.ART_CODIGO, this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);
        //console.log(rs);
        if (rs !== null) {
          intNumReg = rs[0].COUNT;
        }

      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'No se puede consultar en la base de datos. ' + mensaje);
      }

      if (intNumReg === 1) {
        this.tomFisService.detTomaFisicaArray[this.indicador][index].KARDEX = 'SI';
        this.listaFilaAjuste.push(index + 1);
        intTotalNumRegAj = intTotalNumRegAj + 1;
        this.boolAjuste=true;//CDPJ
      }
      //console.log(this.tomFisService.detTomaFisicaArray[this.indicador]);

      if (intTotalNumRegAj > 0) {
        this.disabledDateTime = true;
        this.disabledTime = true;

      } else {
        this.disabledDateTime = false;
        this.disabledTime = false;

      }
    }
    this.aggrid.refreshaggrid(this.tomFisService.detTomaFisicaArray[this.indicador], this.defaultColDefTomF.objeto);
  }

  async ubicarCantidadPorUnidades() {
    this.listaFilaAjuste = [];
    let index = 0;
    for (index = 0; index < this.tomFisService.detTomaFisicaArray[this.indicador].length; index++) {
      try {
        const rs = await this.tomFisService.frubicarCantidadPorUnidades(this.tomFisService.detTomaFisicaArray[this.indicador][index].ART_CODIGO);

        this.tomFisService.detTomaFisicaArray[this.indicador][index].STRINGRESO_CANTUND = rs === null ? '' : rs[0].ART_CANTIDAD_UND;
      } catch (err) {
        
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
        this.spin=false;//CDPJ
      }
    }
  }

  cargarDatosEncabezado() {
    const bolCargar = true;
    let item: Invtrntomafisica = {};
    console.log(this.tomFisService.detTomaFisicaArray[this.indicador]);
    item = this.tomFisService.detTomaFisicaArray[this.indicador][0];

    if (item.TRNTOMAFISICA_NRO === null) {
      item.TRNTOMAFISICA_NRO = '';
    }

    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO = item.TRNTOMAFISICA_NRO;
    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA = new Date(item.TRNTOMAFISICA_FECHA);

    let index = 0;
    for (let letra = 0; letra < String(item.TRNTOMAFISICA_HORA).length; letra++) {
      if (String(item.TRNTOMAFISICA_HORA).charAt(letra) === ':') {
        index = letra;
      }
    }

    const hora = String(item.TRNTOMAFISICA_HORA).substring(0, index);
    const minutos = String(item.TRNTOMAFISICA_HORA).substring(index + 1, String(item.TRNTOMAFISICA_HORA).length);
    const horaAct = new Date();
    horaAct.setHours(Number(hora), Number(minutos));
    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_HORA = horaAct;

    if (item.ASI_NRO === null) {
      item.ASI_NRO = '';
    }
    this.tomFisService.encTomaFisica[this.indicador].ASI_NRO = item.ASI_NRO;

    if (item.TRNTOMAFISICA_COMENTARIO === null) {
      item.TRNTOMAFISICA_COMENTARIO = '';
    }
    this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_COMENTARIO = item.TRNTOMAFISICA_COMENTARIO;
    return bolCargar;
  }

  async seleccionarRegistro(strOpcion) {
    let strNumTF = '';
    let strSentencia = '';

    if (strOpcion === 'P') {
      strSentencia = 'Select';
    } else if (strOpcion === 'U') {
      strSentencia = 'Select';
    } else if (strOpcion === 'S') {
      if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
        strSentencia = 'select ';
      }
    } else if (strOpcion === 'A') {
      if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
        strSentencia = 'select'
      }
    }

    /*if (strOpcion === 'P') {
     strSentencia = 'Select TRNTOMAFISICA_NRO From inv_trntomafisica where com_codigo = \'01\' order by TRNTOMAFISICA_NRO asc';
   } else if (strOpcion === 'U') {
     strSentencia = 'Select TRNTOMAFISICA_NRO From inv_trntomafisica where com_codigo = \'01\' order by TRNTOMAFISICA_NRO desc';
   } else if (strOpcion === 'S') {
     if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0 ) {
       strSentencia = 'select nvl(min(TRNTOMAFISICA_NRO),\'' + this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO
         + '\') as TRNTOMAFISICA_NRO ';
       strSentencia = strSentencia + 'from INV_TRNTOMAFISICA where TRNTOMAFISICA_NRO> \'' +
         this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO + '\'';
       strSentencia = strSentencia + ' and com_codigo = \'01\' order by art_codigo asc';
     }
   } else if (strOpcion === 'A') {
     if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0 ) {
       strSentencia = 'select nvl(max(TRNTOMAFISICA_NRO),\'' + this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO
         + '\') as TRNTOMAFISICA_NRO ';
       strSentencia = strSentencia + 'from INV_TRNTOMAFISICA where TRNTOMAFISICA_NRO < \'' +
         this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO + '\'';
       strSentencia = strSentencia + ' and com_codigo = \'01\' order by art_codigo asc ';
     }
   }*/

    if (String(strSentencia).length > 0) {

      try {
        //const rs = await this.tomFisService.ejecutarSentencia(strSentencia);
        const rs = await this.tomFisService.eStomafisicaselecregistro(strOpcion, this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);
        if (rs !== null) {
          strNumTF = rs[0].TRNTOMAFISICA_NRO;
        }

      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'No se puede consultar la Toma Física. ' + mensaje);
      }

    }

    return strNumTF;
  }

  seleccionToma(valor) {
    if (valor === 'nuevo') {
      this.nuevaLinea();
    }
    if (valor === 'eliminar') {
      this.eliminarLineaDetalle();
    }
    if (valor === 'cancelar') {
      this.cancelarLineaDetalle();
    }
    if (valor === 'recalcular') {
      this.recalcularCostos();
    }
    if (valor === 'copiarCostos') {
      this.actualizaCostosTFAKardex(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);
    }
     //CDPJ
     if (valor === 'agregaritems') {
      this.agregaritemsfaltantes(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);
    }
     if(valor === 'actcantkardex'){
      this.actualizarCantidadK(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);
    }
    //CDPJ
    if (valor.object !== undefined) {
      this.detalleTomFisSel = valor.object;
    }
  }
//CDPJ
async actualizarCantidadK(TRN_NRO){
  if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
    if (this.permisos !== null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERACTUALIZACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
  }
  try {
    let numero=0;
    const data= await this.tomFisService.consultaitemskardex(TRN_NRO)
    if(data!== null && data !== undefined){
      if(data.length >0){
        for(const data1 of data){
          numero=data1.NUMERO
        }
      }
    }
    if(numero > 0){
      this.mensajeAlerta('info', 'Información', 'No puede realizar el procedimiento porque los artículos estan registrados en el kardex, por favor primero reverse el ingreso al kardex');
    return;
    }
    this.confirm.confirm({
      message: 'Desea actualizar la cantidad del kardex generada?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'tomConf',
      accept: async () => {

        this.confirm.close();
        await this.actcanKardex(TRN_NRO);
        const strCondicion = ' com_codigo = \'01\' and trntomafisica_nro = \'' + this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO
        + '\'';
      await this.navegarRegistro('R', strCondicion, '');
      this.disabledTab0 = false;
      this.disabledTab1 = false;
       await this.habilitarBotones(true, false, true, false, true, true, true,
          true, true, true, true, true, true, true);
      },
      reject: () => {
        
      }
    });


  } catch (error) {
    console.log(error)
  }

}
async actcanKardex(TRN_NRO){
  try {
    await this.tomFisService.actcantitemskardex(TRN_NRO) 
    this.auditoriaGralService.registrarAuditoria('INV_TRNTOMAFISICA', TRN_NRO+'/Act.cantidad Kar.', 'E',this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
    });
    
    this.mensajeAlerta('success', 'Información', 'Proceso terminó correctamente, por favor  guarde ');

      
  } catch (error) {
    
  }
}
agregaritemsfaltantes(TRN_NRO) {
  //const bodcodigo=this.tomFisService.detTomaFisicaArray[this.indicador][0].BOD_CODIGO
  if (TRN_NRO === '' || TRN_NRO === null || TRN_NRO === undefined ||
    this.tomFisService.detTomaFisicaArray[this.indicador].length === 0) {
    this.mensajeAlerta('error', 'Error', 'Primero guarde el documento por favor');
    return;
  } else {
    
    this.confirm.confirm({
      message: 'Está seguro de agregar los items que faltan?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'tomConf',
      accept: async () => {

      await this.consulitemstock(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);
       
      },
      reject: () => {
      }
    });
  }
}
async consulitemstock(TRN_NRO) {
  this.confirm.close();
  this.confirm.confirm({
    message: 'Desea ajustar automáticamente a existencia cero los nuevos producctos a insertar?',
    header: 'Pregunta',
    icon: 'pi pi-exclamation-triangle',
    key: 'tomConf2',
    accept: () => {
      this.confirm.close();
      this.consulitemstockaj();
      this.auditoriaGralService.registrarAuditoria('INV_TRNTOMAFISICA', TRN_NRO+'/INV'+'ART.AJUSTE', 'I',this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    },
    reject: () => {
       this.consulitemstocksnaj();
      this.auditoriaGralService.registrarAuditoria('INV_TRNTOMAFISICA', TRN_NRO+'/INV'+'ART.SNAJUSTE', 'I',this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    }
  });

}
async consulitemstockaj(){
    let strFecha = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA;
  if (strFecha.toString().includes('-')) {
    strFecha = this.datePipe.transform(strFecha, 'dd/MM/yyyy');
  }
  await this.tomFisService.consultaitemstock(strFecha, this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO,'S')
  await this.cancelarRegistro()

}
async consulitemstocksnaj(){
  let strFecha = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA;
if (strFecha.toString().includes('-')) {
  strFecha = this.datePipe.transform(strFecha, 'dd/MM/yyyy');
}
await this.tomFisService.consultaitemstock(strFecha, this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO,'N')
await this.cancelarRegistro()

}
//CDPJ
  actualizaCostosTFAKardex(strNumeroTomaF) {
    this.confirm.confirm({
      message: 'Está seguro de recalcular los costos?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'tomConf',
      accept: () => {
        this.confirmCopiarCostos(strNumeroTomaF);

      },
      reject: () => {
      }
    });
  }

  async confirmCopiarCostos(strNumeroTomaF) {
    try {
      await this.tomFisService.copiarCostos(strNumeroTomaF);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede actualizar los costos de la toma física al kardex ' + mensaje);
    }
  }

  recalcularCostos() {

    this.confirm.confirm({
      message: 'Está seguro de recalcular los costos?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'tomConf',
      accept: () => {
        this.confirmRecalcCosto();

      },
      reject: () => {
      }
    });
  }

  async confirmRecalcCosto() {
    let bolRecalcular = false;
    for (let fila = 0; fila < this.tomFisService.detTomaFisicaArray[this.indicador].length; fila++) {
      const item = this.tomFisService.detTomaFisicaArray[this.indicador][fila];

      const strArtCodigo = item.ART_CODIGO;
      const dblValorAjuste = Number(item.TRNVALOR_AJUSTE);

      let strFecha = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA;

      if (strFecha.toString().includes('-')) {
        strFecha = this.datePipe.transform(strFecha, 'dd/MM/yyyy');
      }

      const time = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_HORA;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      const strHora = hora + ':' + minutos;

      const dblCostoProm = await this.obtenerCostoPromedio(strArtCodigo, strFecha, strHora);
      const dblCostoTotal = Number(dblCostoProm) * dblValorAjuste;

      this.tomFisService.detTomaFisicaArray[this.indicador][fila].TRNTOMAFISICA_COSTOPROM = Number(dblCostoProm).toFixed(
        this.tomFisService.obtenerConfig('COSTO', 'trntomaf'));
      this.tomFisService.detTomaFisicaArray[this.indicador][fila].TRNTOMAFISICA_TOTAL = Number(dblCostoTotal).toFixed(
        this.tomFisService.obtenerConfig('COSTO', 'trntomaf'));
      this.aggrid.refreshaggrid(this.tomFisService.detTomaFisicaArray[this.indicador], this.defaultColDefTomF.objeto);
      bolRecalcular = true;
    }

    if (bolRecalcular) {
      // Elimina del kardex

      /* let strSentencia = 'DELETE FROM INV_TRNKARDEX WHERE  TRNART_ORIGEN=\'TF_INV\' AND TRNART_REFERENCIA = \'' +
        this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO + '\'';
      strSentencia = strSentencia + ' AND COM_CODIGO = \'01\''; */

      try {
        await this.tomFisService.eSeliminarkardex(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'No se puede eliminar la toma física ' + mensaje);
      }
      await this.generarKardex();
      this.cargarTotalTomaFisica();

      this.habilitarBotones(true, false, true, false, true, true, true, true,
        true, true, true, true, true, true);
    }
  }

  async obtenerCostoPromedio(strArtCodigo, strFecha, strHora) {
    let dblCostoProm = 0;

    /* let strSentencia = 'select INV_FNC_OBTENERCOSTOPROM(\'' + strArtCodigo + '\',';
    strSentencia = strSentencia + 'TO_DATE(\'' + strFecha + '\',\'DD/MM/YYYY HH24:MI:SS\'),\'';
    strSentencia = strSentencia + strHora +  '\',\'01\') as COSTOPROM FROM DUAL '; */

    try {
      const rs = await this.tomFisService.eSobtenercostopromedio(strArtCodigo, strFecha, strHora);

      if (rs !== null) {
        dblCostoProm = rs[0].COSTOPROM;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede obtener el costo promedio del artículo ' + mensaje);
    }
    return dblCostoProm;
  }

  cancelarLineaDetalle() {
    this.cancelarAcciones();
    this.tomFisService.btnNuevoDet[this.indicador] = false;
    this.tomFisService.btnEliminarDet[this.indicador] = false;
    this.tomFisService.btnCancelarDet[this.indicador] = true;
    this.cargarTotalTomaFisica();
  }

  cancelarAcciones() {
    let intIndiceLista = 0;
    let item: Invtrntomafisica = {};
    for (intIndiceLista = 0; intIndiceLista < this.tomFisService.detTomaFisicaArray[this.indicador].length; intIndiceLista++) {
      item = this.tomFisService.detTomaFisicaArray[this.indicador][intIndiceLista];
      if (item.ART_CODIGO === '') {
        this.tomFisService.detTomaFisicaArray[this.indicador].splice(this.tomFisService.detTomaFisicaArray[this.indicador].findIndex(
          tomFis => tomFis.LINEA === item.LINEA),
          1);
      }

    }
    this.aggrid.refreshaggrid(this.tomFisService.detTomaFisicaArray[this.indicador], this.defaultColDefTomF.objeto);
  }

  eliminarLineaDetalle() {
    const bolRespuesta = false;
    let intIndex = -1;

    if (this.permisos !== null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERACTUALIZACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    if (Object.keys(this.detalleTomFisSel).length > 0 && this.tomFisService.detTomaFisicaArray[this.indicador].length > 0) {
      intIndex = this.tomFisService.detTomaFisicaArray[this.indicador].indexOf(this.detalleTomFisSel);

      if (this.buscarFilaAjuste(intIndex) === -1) {

        this.confirm.confirm({
          message: 'Está seguro de eliminar el registro?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'tomConf',
          accept: () => {
            this.confimElimLinDet(intIndex);

          },
          reject: () => {
          }
        });

        this.cargarTotalTomaFisica();

      } else {
        this.mensajeAlerta('info', 'Información', 'No se puede Eliminar un Artículo ajustado en el Kardex.');
      }
    } else {
      this.mensajeAlerta('info', 'Información', 'Seleccione la fila que desea eliminar.');
    }

  }

  async confimElimLinDet(intIndex) {
    let bolEliminar: any = false;
    let item: Invtrntomafisica = {};
    item = this.tomFisService.detTomaFisicaArray[this.indicador][intIndex];

    if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
      if (await this.controlKardex(item.ART_CODIGO, String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).trim(),
        item.TRNVALOR_AJUSTE, item.BOD_CODIGO)) {
        bolEliminar = await this.eliminarTomaFisica('', intIndex, false);
      }

    } else {
      bolEliminar = true;
      this.tomFisService.detTomaFisicaArray[this.indicador].splice(this.tomFisService.detTomaFisicaArray[this.indicador].findIndex(
        tomFis => tomFis.LINEA === this.detalleTomFisSel.LINEA),
        1);
      this.aggrid.refreshaggrid(this.tomFisService.detTomaFisicaArray[this.indicador], this.defaultColDefTomF.objeto);
    }


    // modeloTomaF.getListaTomaF().remove(item);
    // tablaTomaF.redraw();

    if (bolEliminar) {
      this.cargarTotalTomaFisica();
      this.habilitarBotonesDet(false, false, true, false, false);
    }
  }

  async eliminarTomaFisica(strNumTF, intFilaEliminar, rollback) {
    let bolEliminar = false;
    let item: Invtrntomafisica = {};
    let strFechaEmision = '';
    let strDatos0 = '';
    let strDatos1 = '';
    let strDatos2: any = '';

    if (intFilaEliminar > -1) {
      item = this.tomFisService.detTomaFisicaArray[this.indicador][intFilaEliminar];
    }

    let intDelete = 0;

    if (intFilaEliminar > -1) {
      strDatos0 = item.TRNTOMAFISICA_NRO;
      strNumTF = item.TRNTOMAFISICA_NRO;
    } else {
      strDatos0 = strNumTF;
    }

    if (intFilaEliminar > -1) {
      strDatos1 = item.ART_CODIGO;
    } else {
      strDatos1 = '';
    }



    if (intFilaEliminar > -1) {
      strDatos2 = item.TRNTOMAFISICA_LINEA;
    } else {
      strDatos2 = 0;
    }

    try {
      const rs = await this.tomFisService.eliminarTomaFisica(strDatos0, strDatos1, strDatos2);

      console.log(rs);
      if (rs !== null) {
        intDelete = rs[0]['1'];
        if (intDelete === 1) {
          if (intFilaEliminar > -1) {
            console.log(this.detalleTomFisSel.LINEA);
            console.log(this.tomFisService.detTomaFisicaArray[this.indicador].findIndex(
              tomFis => tomFis.LINEA === this.detalleTomFisSel.LINEA));
            this.tomFisService.detTomaFisicaArray[this.indicador].splice(this.tomFisService.detTomaFisicaArray[this.indicador].findIndex(
              tomFis => tomFis.LINEA === this.detalleTomFisSel.LINEA),
              1);
            this.aggrid.refreshaggrid(this.tomFisService.detTomaFisicaArray[this.indicador], this.defaultColDefTomF.objeto);

          } else {
            if (String(this.tomFisService.encTomaFisica[this.indicador].ASI_NRO).length > 0) {
              this.anularAsientoContable(String(this.tomFisService.encTomaFisica[this.indicador].ASI_NRO).trim());
            }

            if (rollback === false) {
              this.limpiarDatos();
            }

          }
          if (rollback === false) {
            if (intFilaEliminar > -1) {
              this.auditoriaGralService.registrarAuditoria('INV_TRNTOMAFISICA', item.TRNTOMAFISICA_NRO + '/' + item.ART_CODIGO
                + '/INV', 'E',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                });

              if (this.intRecalculaCostoPromedio === 1) {// Recalcula el costo promedio de los articulos insertados
                strFechaEmision = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA;

                if (strFechaEmision.toString().includes('-')) {
                  strFechaEmision = this.datePipe.transform(strFechaEmision, 'dd/MM/yyyy');
                }
                this.recalcularCostoPromedio(item.ART_CODIGO, strFechaEmision);
              }
            } else {
              this.auditoriaGralService.registrarAuditoria('INV_TRNTOMAFISICA', strNumTF + '/INV', 'E',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                });

              if (this.intRecalculaCostoPromedio === 1) { // Recalcula el costo promedio de los articulos insertados
                strFechaEmision = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA;

                if (strFechaEmision.toString().includes('-')) {
                  strFechaEmision = this.datePipe.transform(strFechaEmision, 'dd/MM/yyyy');
                }
                this.recalcularCostosArtEliminados(strNumTF, strFechaEmision);
              }
            }
          }
          this.aggrid.refreshaggrid(this.tomFisService.detTomaFisicaArray[this.indicador], this.defaultColDefTomF.objeto);
        }
      }
      bolEliminar = true;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se ha eliminado el registro. ' + mensaje);
    }

    return bolEliminar;
  }

  async recalcularCostosArtEliminados(strDocumento, strFecha) {
    try {
      await this.tomFisService.recalcularCostosArtEliminados(strDocumento, strFecha);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '¡ Recalcular el costo promedio de productos eliminados, no terminó satisfactoriamente ! ' + mensaje);
    }
  }

  async recalcularCostoPromedio(strCodArticulo, strFecha) {
    try {
      await this.tomFisService.recalcularCostoPromedio(strCodArticulo, strFecha);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '¡ Recalcular el costo promedio, no terminó satisfactoriamente ! ' + mensaje);
    }
  }

  async anularAsientoContable(strAsiNro) {
    let bolAnular = false;

    try {
      await this.tomFisService.anularAsientoContable(strAsiNro);
      bolAnular = true;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede anular el asiento. ' + mensaje);
    }

    return bolAnular;
  }

  async controlKardex(strArtCodigo, strNumTF, dblValorAj, strBodCodigo) {
    let bolControl = true;
    let strSentencia = '';

    let strFecha = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA;

    if (strFecha.toString().includes('-')) {
      strFecha = this.datePipe.transform(strFecha, 'dd/MM/yyyy');
    }


    const time = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_HORA;
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;



    let intNumReg = 0;

    try {
      const rs = await this.tomFisService.eScontrolkardex(strArtCodigo, strNumTF);
      intNumReg = rs[0].COUNT;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede obtener la información de la base de datos. ' + mensaje);
    } finally {
      strSentencia = '';
    }
    // Verificar si trabaja con inventario negativo para poder eliminar o  si no se ha generado un ajuste

    if (Number(this.tomFisService.getConfiguraciones('TRAINVNEG')) === 1 || intNumReg === 0) {
      return bolControl;
    } else {
      bolControl = false;

      // si generó un ajuste de salida si se puede borrar
      if (dblValorAj < 0) {
        bolControl = true;
      } else {
        // obtener existencia por bodega del artículo a eliminar
        const dblExistencia = await this.obtenerExistenciaBodega('FAC', strArtCodigo,
          strBodCodigo, strFecha, strHora);

        // obtener la cantidad en el kardex del artículo a eliminar

        let dblCantidad = 0;

        try {
          const rs = await this.tomFisService.eScantidadkardex(strArtCodigo, strNumTF);
          if (rs !== null) {
            dblCantidad = rs[0].TRNART_CANTIDAD;
          }
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', 'No se puede obtener la información de la base de datos. ' + mensaje);
        } finally {
          strSentencia = '';
        }

        if ((dblExistencia - dblCantidad) < 0) {
          this.mensajeAlerta('info', 'Información', 'No se Puede Eliminar éste Ajuste ya que Genera Inventario Negativo');
          bolControl = false;
        } else {
          bolControl = true;
        }
      }
    }

    return bolControl;
  }

  async obtenerExistenciaBodega(strTipo, strArtCodigo, strBodCodigo, strFecha, strHora) {
    let dblExistencia = 0;
    let strSentencia = 'select INV_FNC_OBTENEREXISTARTCL_H(\'' + strTipo + '\',\'' + strArtCodigo + '\',\'';
    strSentencia = strSentencia + strBodCodigo + '\',\'01\',\'' + strFecha + '\',\'';
    strSentencia = strSentencia + strHora + '\') as EXISTENCIA from dual ';

    try {
      const rs = await this.tomFisService.eSobtenerexistencia(strTipo, strArtCodigo, strBodCodigo, strFecha, strHora);
      if (rs !== null) {
        dblExistencia = rs[0].EXISTENCIA;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede obtener la existencia del artículo ' + mensaje);
    }
    return dblExistencia;
  }

  buscarFilaAjuste(intFila): number {
    let intMitad = 0;
    let intInferior = 0;
    let intSuperior = this.listaFilaAjuste.length - 1;

    if (this.listaFilaAjuste.length === 0) {
      return -1;
    }
    do {
      intMitad = (intInferior + intSuperior) / 2;
      if (intFila > this.listaFilaAjuste[intMitad]) {
        intInferior = intMitad + 1;
      } else {
        intSuperior = intMitad - 1;
      }
    } while (this.listaFilaAjuste[intMitad] !== intFila && intInferior <= intSuperior);
    if (this.listaFilaAjuste[intMitad] === intFila) {
      return intMitad;
    } else {
      return -1;
    }
  }

  seleccionTomaVar(valor) {
    if (valor === 'nuevo') {
      this.nuevaLineaVar();
    }
    if (valor === 'eliminar') {
      this.eliminarLineaVarios();
    }
    if (valor === 'cancelar') {
      this.cancelarLineaVarios();
    }
    if (valor.object !== undefined) {
      this.detalleVariosSel = valor.object;
    }
  }

  cancelarLineaVarios() {
    this.aggrid.gridApi.stopEditing();
    this.cancelarAccionesVar();
    this.habilitarBotonesDetVar(false, false, true);
  }

  cancelarAccionesVar() {
    let intIndiceLista = 0;
    let item: Invdettomafisicavar = {};
    for (intIndiceLista = 0; intIndiceLista < this.tomFisService.detTomFisVarArray[this.indicador].length; intIndiceLista++) {
      item = this.tomFisService.detTomFisVarArray[this.indicador][intIndiceLista];
      if (item.CON_CODIGO === '') {
        this.tomFisService.detTomFisVarArray[this.indicador].splice(this.tomFisService.detTomFisVarArray[this.indicador].findIndex(
          tomFis => tomFis.LINEA === item.LINEA),
          1);
      }

    }
    this.aggrid.refreshaggrid(this.tomFisService.detTomFisVarArray[this.indicador], this.defaultColDefTomFVar.objeto);
  }

  eliminarLineaVarios() {
    const intIndex = -1;

    if (this.permisos !== null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERACTUALIZACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    if (Object.keys(this.detalleVariosSel).length > 0) {

      this.confirm.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'tomConf',
        accept: () => {
          this.confirmEliminarVar();
        },
        reject: () => {
        }
      });
    } else {
      this.mensajeAlerta('info', 'Información', 'Seleccione la fila que desea eliminar.');
    }
  }

  confirmEliminarVar() {
    const intIndex = this.tomFisService.detTomFisVarArray[this.indicador].indexOf(this.detalleVariosSel);
    const item = this.tomFisService.detTomFisVarArray[this.indicador][intIndex];

    this.tomFisService.detTomFisVarArray[this.indicador].splice(this.tomFisService.detTomFisVarArray[this.indicador].findIndex(
      tomFis => tomFis.LINEA === item.LINEA),
      1);

    this.aggrid.refreshaggrid(this.tomFisService.detTomFisVarArray[this.indicador], this.defaultColDefTomFVar.objeto);

    this.habilitarBotonesDetVar(false, false, false);

    this.habilitarBotones(true, false, true, false, true, true, true,
      true, true, true, true, true, true, true);

  }

  nuevaLineaVar() {
    if (this.permisos !== null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERACTUALIZACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    if (this.tomFisService.encTomaFisica[this.indicador].VALOR_TOTAL === '' ||
      this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO === '') {
      this.mensajeAlerta('info', 'Información', 'Por favor, Ingrese primero la Toma Física.');
    } else {
      this.nuevaLineaVarios();
      this.tomFisService.btnEliminarDetVar[this.indicador] = false;
      this.tomFisService.btnCancelarDetVar[this.indicador] = false;

      this.habilitarBotones(true, false, true, false, true, true, true, true, true,
        true, true, true, true, true);
    }
  }

  nuevaLineaVarios() {
    const item: Invdettomafisicavar = {};

    item.CON_CODIGO = '';
    item.CON_NOMBRE = '';
    item.DETTOMAFVAR_IMPORTE = Number(0).toFixed(2);
    item.DEBE = Number(0).toFixed(2);
    item.HABER = Number(0).toFixed(2);
    item.CEN_CODIGO = '';
    item.COM_CODIGO = '01';
    item.CON_CENTRO = '';
    item.LINEA = this.tomFisService.detTomFisVarArray[this.indicador].length + 1;
    item.DETTOMAFVAR_LINEA = this.tomFisService.detTomFisVarArray[this.indicador].length + 1;

    const indi = this.tomFisService.detTomFisVarArray[this.indicador].indexOf(this.detalleVariosSel);
    if (indi === -1) {
      this.tomFisService.detTomFisVarArray[this.indicador].push(item);
      this.detalleVariosSel = this.tomFisService.detTomFisVarArray[this.indicador]
      [this.tomFisService.detTomFisVarArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.tomFisService.detTomFisVarArray[this.indicador], this.defaultColDefTomFVar.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefTomFVar.objeto].setFocusedCell(
        this.tomFisService.detTomFisVarArray[this.indicador].length - 1, 'CON_CODIGO');
    } else {
      this.tomFisService.detTomFisVarArray[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.tomFisService.detTomFisVarArray[this.indicador], this.defaultColDefTomFVar.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefTomFVar.objeto].setFocusedCell(
        indi + 1, 'CON_CODIGO');
    }

    this.tomFisService.btnNuevoDetVar[this.indicador] = true;
    this.disabledTab0 = true;
    this.disabledTab1 = false;

  }

  cambioTab() {
    if (this.activeIndex === 0) {
    }
  }

  async comprobarClave() {
    try {
      const rs = await this.tomFisService.obtenerClaveProm();
      let claveReal;
      claveReal = rs[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.claveDialog = false;
        this.confirm.confirm({
          message: 'Desea Eliminar la Toma Física del Kardex?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'tomConf',
          accept: () => {
            this.confirmReversar();
          },
          reject: () => {
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'Debe ingresar la clave de autorización para eliminar la ' +
          'toma física del Kardex.');
      }

    } catch (err) {

    }
  }

  async confirmReversar() {
    await this.eliminarTomaFKardex();
    this.cargarTotalTomaFisica();
    this.tomFisService.encTomaFisica[this.indicador].ASI_NRO = '';

    if (String(this.tomFisService.encTomaFisica[this.indicador].ASI_NRO).length > 0) {
      this.habilitarBotones(false, true, false, true, false, false, false, false,
        true, false, false, false, false, false);
    } else {
      this.habilitarBotones(false, true, false, true, false, false, false, false,
        false, true, false, false, false, false);
    }

    if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
      const strCondicion = ' com_codigo = \'01\' and trntomafisica_nro = \'' + this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO
        + '\'';
      this.navegarRegistro('R', strCondicion, '');
    }
  }

  cambio() {
    if (this.activeIndex === 0) {
      this.disabledTab1 = true;
      this.disabledTab0 = false;
    } else {
      this.disabledTab1 = false;
      this.disabledTab0 = true;
    }
    this.habilitarBotones(true, false, true, false, true, true, true,
      true, true, true, true, true, true, true);
  }


  nuevaToma() {

    if (this.tomFisService.btnNuevoDet[this.indicador] === false) {
      this.nuevaLinea();
    }

    this.habilitarBotones(true, false, true, false, true, true, true, true,
      true, true, true, true, true, true);

  }

  nuevaLinea() {
    if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
      if (this.permisos !== null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERACTUALIZACION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }

    this.nuevaLineaDetalle();
    this.habilitarBotonesDet(true, false, false, false, false);
    this.habilitarBotones(true, false, true, false, true, true, true, true,
      true, true, true, true, true, true);
  }

  nuevaLineaDetalle() {
    const item: Invtrntomafisica = {};

    item.BOD_CODIGO = '';
    item.ART_CODIGO = '';
    item.ART_NOMBRE = '';
    item.TRNTOMAFISICA_CANTIDADP = Number(0).toFixed(2);
    item.TRNTOMAFISICA_CANTIDADK = Number(0).toFixed(this.tomFisService.obtenerConfig('CANTIDAD', 'trntomaf'));
    item.TRNTOMAFISICA_CANTIDADF = Number(0).toFixed(2);
    item.TRNTOMAFISICA_CANTIDAD = Number(0).toFixed(this.tomFisService.obtenerConfig('CANTIDAD', 'trntomaf'));
    item.TRNTOMAFISICA_AJUSTE = false;
    item.TRNVALOR_AJUSTE = Number(0).toFixed(this.tomFisService.obtenerConfig('CANTIDAD', 'trntomaf'));
    item.TRNTOMAFISICA_COSTOPROM = Number(0).toFixed(this.tomFisService.obtenerConfig('COSTO', 'trntomaf'));
    item.TRNTOMAFISICA_TOTAL = Number(0).toFixed(this.tomFisService.obtenerConfig('COSTO', 'trntomaf'));
    item.COM_CODIGO = '01';
    item.TRNTOMAFISICA_FECHA = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA;
    item.TRNTOMAFISICA_HORA = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_HORA;
    item.ART_UNIDADCOSTEO = '';
    item.TRNTOMAFISICA_LINEA = 0;
    item.ASI_NRO = '';
    item.LINEA = this.tomFisService.detTomaFisicaArray[this.indicador].length + 1;
    item.KARDEX = 'NO';
    item.TRNTOMAFISICA_NRO = '';

    // Coloca automáticamente la bodega de la última fila
    if (this.tomFisService.detTomaFisicaArray[this.indicador].length > 0) {
      const intUltFila = this.tomFisService.detTomaFisicaArray[this.indicador].length;
      item.BOD_CODIGO = this.tomFisService.detTomaFisicaArray[this.indicador][intUltFila - 1].BOD_CODIGO;
    } else if (this.tomFisService.detTomaFisicaArray[this.indicador].length === 0) {
      this.listaFilaAjuste = [];
    }

    const indi = this.tomFisService.detTomaFisicaArray[this.indicador].indexOf(this.detalleTomFisSel);
    if (indi === -1) {
      this.tomFisService.detTomaFisicaArray[this.indicador].push(item);
      this.detalleTomFisSel = this.tomFisService.detTomaFisicaArray[this.indicador]
      [this.tomFisService.detTomaFisicaArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.tomFisService.detTomaFisicaArray[this.indicador], this.defaultColDefTomF.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefTomF.objeto].setFocusedCell(
        this.tomFisService.detTomaFisicaArray[this.indicador].length - 1, 'BOD_CODIGO');
    } else {
      this.tomFisService.detTomaFisicaArray[this.indicador].splice(indi + 1, 0, item);
      this.detalleTomFisSel=item//CDPJ
      this.aggrid.refreshaggridindex(this.tomFisService.detTomaFisicaArray[this.indicador], this.defaultColDefTomF.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefTomF.objeto].setFocusedCell(
        indi + 1, 'BOD_CODIGO');
    }


    this.tomFisService.btnNuevoDet[this.indicador] = true;
    // cambiar el color de los artículos ajustados en el kardex
    this.cambiarColorAjuste();
    this.nuevoitem = item;//CDPJ
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  isEdit(data): boolean {
    if (data.ART_CODIGO === '') {
      return true;
    }
    return false;
  }

  isEditCen(data): boolean {
    if (data.CON_CENTRO === 'Si') {
      return true;
    }
    return false;
  }

  ajustarTomaFKardex() {
    if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length === 0 || this.tomFisService.botonGuardar === false) {
      this.mensajeAlerta('info', 'Información', 'Grabe la Toma Física para Generar el Ajuste en el Kardex...');
    } else {
      this.confirmarTomFisKardex();
    }
    if (String(this.tomFisService.encTomaFisica[this.indicador].ASI_NRO).length > 0) {
      this.habilitarBotones(false, true, false, true, false, false, false, false,
        true, false, false, false, false, false);
    } else {
      this.habilitarBotones(false, true, false, true, false, false, false, false,
        false, true, false, false, false, false);
    }
  }

  confirmarTomFisKardex() {
    this.confirm.confirm({
      message: 'Esta seguro de generar el ajuste en el kardex ?',
      header: 'Generar Kardex',
      icon: 'pi pi-exclamation-triangle',
      key: 'tomConf',
      accept: () => {
        this.generarTomFisKardex();
      },
      reject: () => {
      }
    });
  }

  async generarTomFisKardex() {
    const dblTotalDoc = this.obtenerTotalAjuste();
    const dblTotalVarios = this.obtenerTotalVarios();
    const dblDiferencia = Math.abs(dblTotalDoc) - Math.abs(dblTotalVarios);
    if (dblDiferencia === 0) {
      this.spin=true//CDPJ
      await this.generarKardex();
      this.cargarTotalTomaFisica();
      if (! await this.generarAsientoContable()) {
        this.eliminarTomaFKardex();
        this.cargarTotalTomaFisica();
        this.mensajeAlerta('error', 'Error', 'No se puede generar el ajuste en el kardex porque el asiento contable está descuadrado. ');
        this.spin=false//CDPJ
      } else {
        if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
          const strCondicion = ' com_codigo = \'01\' and trntomafisica_nro = \'' + this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO
            + '\'';
          this.navegarRegistro('R', strCondicion, '');
        }
        this.tomFisService.btnReversar = false;
        this.tomFisService.btnVisto = true;
        this.spin=false//CDPJ
      }
    } else {
      this.mensajeAlerta('error', 'Error', 'No se puede generar el ajuste en el kardex porque el valor de varios no es ' +
        'igual al valor del ajuste.');
        this.spin=false//CDPJ
    }
  }

  async eliminarTomaFKardex() {
    console.log(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);
    let bolEliminar = false;
    try {
      await this.tomFisService.eliminarTomaFKardex(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);

      this.auditoriaGralService.registrarAuditoria('INV_TRNKARDEX', this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO +
        '/TF/' + '/INV', 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });

      this.listaFilaAjuste = [];
      this.disabledTime = false;
      this.disabledDateTime = false;
      bolEliminar = true;
      //let mensaje: string;
      this.mensajeAlerta('success', 'Información', 'Toma Física eliminada exitosamente...!!! ');

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede eliminar la Toma Física ' + this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO
        + ' del Kardex. ' + mensaje);
    }
    return bolEliminar;
  }

  async generarKardex() {
    const strNumTF = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO;
    let intNumAjustes = 0; // indica el número de filas ajustadas
    let strFechaEmision = '';

    for (let fila = 0; fila < this.tomFisService.detTomaFisicaArray[this.indicador].length; fila++) {
      const item = this.tomFisService.detTomaFisicaArray[this.indicador][fila];
      const strCodBodega = item.BOD_CODIGO;
      const strCodArticulo = item.ART_CODIGO;
      const strAjuste = item.TRNTOMAFISICA_AJUSTE === true ? 'S' : 'N';
      const dblValorAjuste = Number(item.TRNVALOR_AJUSTE);
      let intNumReg = 0;
      let strTipo = '';

      let strFecha = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_FECHA;
      if (strFecha.toString().includes('-')) {
        strFecha = this.datePipe.transform(strFecha, 'dd/MM/yyyy');
      }

      const time = this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_HORA;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      const strHora = hora + ':' + minutos;


      const dblCostoProm = Number(item.TRNTOMAFISICA_COSTOPROM);
      const dblCostoTotal = dblCostoProm * Math.abs(dblValorAjuste);
      let intRegGenerado = 0;
      let strNumGenerado = '';

      // verificar si hay la toma física del artículo en el kardex


      try {
        const rs = await this.tomFisService.eStomafisicakardex(strCodArticulo, strNumTF);
        intNumReg = rs[0].CONT;
      } catch (err) {
        this.mensajeAlerta('error', 'Error', 'No se puede consultar en la base de datos.');
        this.spin=false//CDPJ
      }
      if (strAjuste === 'S' && intNumReg === 0) {
        if (dblValorAjuste > 0) {
          strTipo = 'EN';
        } else {
          strTipo = 'SA';
        }

        try {
          const rs = await this.tomFisService.generarKardexProm(strCodBodega, strCodArticulo, strTipo, strFecha, strNumTF,
            Number(Math.abs(dblValorAjuste)),
            Number(Number(dblCostoTotal).toFixed(2)), strHora, 'TF_INV', Number(dblCostoProm));

          if (rs !== null) {
            intRegGenerado = rs[0]['1'];
            strNumGenerado = rs[0][':B1'];
          }

          if (intRegGenerado === 1) {
            this.auditoriaGralService.registrarAuditoria('INV_TRNKARDEX', strNumGenerado + '/TF/' + strCodArticulo + '/INV', 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });

            this.listaFilaAjuste.push(fila);
            // modeloTomaF.cambiarColorFila(fila, colorAjuste);
            // tablaTomaF.redraw();
            this.disabledDateTime = true;
            this.disabledTime = true;
            intNumAjustes = intNumAjustes + 1;
          }
        } catch (err) {
          this.mensajeAlerta('error', 'Error', 'No se puede generar el Ajuste en el Kardex.');
          this.spin=false//CDPJ
        }
      } else { // intNumReg == 0
        if (intNumReg !== 0) { // ya esta insertado
          this.mensajeAlerta('info', 'Información', 'El artículo ' + strCodArticulo + ' esta ingresado mas de una vez, por favor verifique');
          this.spin=false//CDPJ
        }
      }
    }
    if (intNumAjustes === 0) {
      this.mensajeAlerta('info', 'Información', 'No se generó ningún Ajuste. Revise que esté seleccionado algún artículo.');
      this.spin=false//CDPJ
    } // Recalcular el costo promedio
  }

  obtenerTotalAjuste() {
    let dblTotalAjuste = 0;
    for (const item of this.tomFisService.detTomaFisicaArray[this.indicador]) {
      if (item.TRNTOMAFISICA_AJUSTE === true || item.TRNTOMAFISICA_AJUSTE === 'S') {
        dblTotalAjuste = dblTotalAjuste + Number(item.TRNTOMAFISICA_TOTAL);
      }
    }
    dblTotalAjuste = Number(Number(dblTotalAjuste).toFixed(2));

    return dblTotalAjuste;
  }

  obtenerTotalVarios() {
    let dblTotalVarios = 0;

    for (const item of this.tomFisService.detTomFisVarArray[this.indicador]) {

      dblTotalVarios = dblTotalVarios + Number(item.DEBE) - Number(item.HABER);
    }

    dblTotalVarios = Number(Number(dblTotalVarios).toFixed(this.tomFisService.obtenerConfig('', 'dettomafisica')));

    return dblTotalVarios;

  }

  async generarAsientoContable() {
    let bolGenerar = false;
    const dblTotalVarios = this.obtenerTotalVarios();
    const dblTotalAjuste = this.obtenerTotalAjuste();
    // verificar Periodo Contable

    if (String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).length > 0) {
      if (await this.verificarTomaFKardex(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO)) {
        if (Math.abs(dblTotalAjuste) === Math.abs(dblTotalVarios)) {
          try {
            await this.tomFisService.generarAsientoProm(String(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO).trim());

            // Recuperar el número de asiento

            const rs = await this.tomFisService.eSreperarnumasiento(this.tomFisService.encTomaFisica[this.indicador].TRNTOMAFISICA_NRO);

            if (rs !== null) {
              this.tomFisService.encTomaFisica[this.indicador].ASI_NRO = rs[0].ASI_NRO;
            } else {
              this.tomFisService.encTomaFisica[this.indicador].ASI_NRO = '';
            }

            if (String(this.tomFisService.encTomaFisica[this.indicador].ASI_NRO).length > 0) {
              this.auditoriaGralService.registrarAuditoria('CON_ENCASI_L',
                this.tomFisService.encTomaFisica[this.indicador].ASI_NRO + '/INV/', 'I',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                });
              bolGenerar = true;
            }
          } catch (err) {
            this.mensajeAlerta('error', 'Error', 'No se generó el Asiento Contable');
            this.spin=false//CDPJ

          }
        } else {
          this.mensajeAlerta('info', 'Información', 'El total de Varios es diferente al total de ajustes generados ' +
            'en la Toma Física, No se puede generar el Asiento Contable');
            this.spin=false//CDPJ
        }
      } else {
        this.mensajeAlerta('info', 'Información', 'No se puede generar el Asiento Contable. Ninguno de los artículos de ' +
          'la Toma Física afectó al Kardex ');
          this.spin=false//CDPJ
      }
    } else {
      this.mensajeAlerta('info', 'Información', 'Escoja un documento, para generar el Asiento Contable');
      this.spin=false//CDPJ
    }


    return bolGenerar;
  }

  async verificarTomaFKardex(strNumTF) {
    let bolExiste = false;

    try {
      const rs = await this.tomFisService.esverificarTomaFKardex(strNumTF);
      if (rs[0].CONT > 0) {
        bolExiste = true;
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', 'No se puede consultar en la base de datos.');
      this.spin=false//CDPJ
    }
    return bolExiste;
  }

  habilitarBotonesDet(nuevo, eliminar, cancelar, recalcular, copiar) {
    this.tomFisService.btnNuevoDet[this.indicador] = nuevo;
    this.tomFisService.btnEliminarDet[this.indicador] = eliminar;
    this.tomFisService.btnCancelarDet[this.indicador] = cancelar;
    this.tomFisService.btnRecalcular[this.indicador] = recalcular;
    this.tomFisService.btnCopiar[this.indicador] = copiar;
  }
  habilitarBotonesDetVar(nuevo, eliminar, cancelar) {
    this.tomFisService.btnNuevoDetVar[this.indicador] = nuevo;
    this.tomFisService.btnEliminarDetVar[this.indicador] = eliminar;
    this.tomFisService.btnCancelarDetVar[this.indicador] = cancelar;
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.messageService.add({
      key: 'tom',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }

}
