<script src="inventrada.component.ts"></script>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="ent"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarEnt" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonRegresar]="botonRegresar"
			[botonBorrar]="botonBorrar" (signal)="manejarSenales($event)" [barraBotonesDesplaz]="barraBotones2"
			[barraBotones3]="barraBotones2" [barraBotonesAsiCon]="barraBotones2" [botonAnterior]="botonAnterior"
			[botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo" [botonSiguiente]="botonSiguiente"
			[botonBuscar]="false" [botonAsiento]="false" [botonverAsiento]="false" [botonAlmacenardoc] = "true"></app-invmaebarra>
	</div>
</div>
<div *ngIf="modulo !== 'PROD'"  class="p-grid" style="margin-top: -10px; margin-bottom: -5px; width: 99%;">
	<div class="p-col-8"></div>
	<div class="p-col-2">
		<label for="ASI_NRO"></label>
		<input class="frm" id="ASI_NRO" type="text" pInputText style="background-color: #EFFFF7" [disabled]="true"
			[(ngModel)]="encentradaSeleccionada.ASI_NRO" autocomplete="off">
	</div>
</div>
<p-tabView [(activeIndex)]="activeIndex" [style]="{'width':'98%'}">
	<p-tabPanel header="Entradas">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 400px)'}">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Nro. Doc</span>
							</div>
							<div class="p-col-2">
								<input pInputText type="text" size="14" [disabled]="true"
									[(ngModel)]="encentradaSeleccionada.KAR_NRO" autocomplete="off">
							</div>
							<div class="p-col-2">
								<p-checkbox *ngIf="modulo !== 'PROD'" label="Saldo Inicial" [(ngModel)]="checkSaldoI"
									binary="true" [disabled]="encentradaSeleccionada.KAR_NRO !== ''"></p-checkbox>
							</div>
							<div  class="p-col-5">
								<div class="p-grid">
									<div *ngIf="modulo === 'PROD'" class="p-col-4"><label for="produccion">Orden de Producción:</label></div>
									<div *ngIf="modulo === 'PROD'" class="p-col-6"><input type="text" pInputText id="produccion"
										[(ngModel)]="encentradaSeleccionada.ENCORDPRO_NUMERO" [disabled]="true"
											autocomplete="off"></div>
									<div class="p-col-2">
										<button *ngIf="modulo === 'PROD'" class="littlebuttons"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedaorden()"
											></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="origen">Origen:</label>
								<input type="text" pInputText id="origen" style="width: 80px"
									[(ngModel)]="encentradaSeleccionada.KAR_ORIGEN" [disabled]="true"
									autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Nro. Compr</span>
							</div>
							<div class="p-col-2">
								<input pInputText type="text" id="nroCompra" (keydown.enter)="cambiarFoco($event)"
									size="14" [(ngModel)]="encentradaSeleccionada.KAR_NROCOMPROBANTE"
									(input)="cambio($event)" [disabled]="inputsBoolean" autocomplete="off">
							</div>
							<div class="p-col-9">
								<div class="p-grid">
									<div class="p-col-6">
										<div class="p-grid">
											<div class="p-col-2"></div>
											<div class="p-col-1">
												<span for="fecha">Fecha:</span>
											</div>
											<div class="p-col-6">
												<p-calendar id="fecha" [inputStyle]="{width: '100%', height: '20px'}"
													[(ngModel)]="date1" (input)="cambio($event)" dateFormat="dd/mm/yy"
													[disabled]="inputsBoolean"></p-calendar>
											</div>
											<div class="p-col-3">
												<span>dd/MM/YYYY</span>
											</div>
										</div>
									</div>
									<div class="p-col-3">
										<label for="hora">Hora:</label>
										<p-calendar id="hora" [timeOnly]="true"
											[inputStyle]="{width: '120px', height: '20px'}" [(ngModel)]="hora"
											(input)="cambio($event)" [disabled]="inputsBoolean"></p-calendar>
									</div>
									<div class="p-col-3">
										<p-checkbox *ngIf="modulo !== 'PROD'" label="No afectar al kardex"></p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px; margin-bottom: -10px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Comentario:</span>
							</div>
							<div class="p-col-11">
								<input pInputText type="text" style="width: 100%" id="comentario"
									(keydown.enter)="cambiarFoco($event)"
									[(ngModel)]="encentradaSeleccionada.KAR_COMENTARIO" (input)="cambio($event)"
									[disabled]="inputsBoolean" autocomplete="off">
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--<div class="divgrups">-->
			<app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
				[rowData]="entradaService.detkardexarray[indicador]" [frameworkComponents]="frameworkComponents"
				[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsEntrada"
				[defaultColDef]="defaultColDefEntrada" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
				[mostrarGuardar]="false" (cambios)="cambio($event)" [botonNuevo]="btnNuevoGrid"
				[botonGuardar]="btnGuardarGrid" [botonBorrar]="btnBorrarGrid" [botonRegresar]="btnCancelarGrid"
				(selected)="seleccionEntrada($event)"></app-aggridsaci>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel *ngIf="modulo !== 'PROD'" header="Varios" [disabled]="checkSaldoI">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 380px)'}">
			<app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
				[rowData]="entradaService.detkardexvararray[indicador]" [frameworkComponents]="frameworkComponents"
				[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsEntradaVar"
				[defaultColDef]="defaultColDefEntradaVar" [rowStyle]="rowStyle" [singleClickEdit]="false"
				[botones]="true" [mostrarGuardar]="false" (cambios)="cambio($event)" [botonNuevo]="btnNuevoGrid"
				[botonGuardar]="btnGuardarGrid" [botonBorrar]="btnBorrarGrid" [botonRegresar]="btnCancelarGrid"
				(selected)="seleccionEntradaVar($event)"></app-aggridsaci>
		</p-scrollPanel>
	</p-tabPanel>
</p-tabView>
<div class="p-grid" style="width: 99%">
	<div class="p-col-8"></div>
	<div class="p-col-4">
		<div class="divgrupstabla">
			<div class="p-grid">
				<div class="p-col-8">
					<span for="total" class="obligatorio">Costo Total:</span>
				</div>
				<div class="p-col-4">
					<input type="text" id="total" class="totales" [disabled]="true"
						[(ngModel)]="entradaService.totalEntradaarray[indicador]" autocomplete="off">
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog header="Seleccione la opción" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>

<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'inventrada'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>


<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones($event)">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="false" [showCloseIcon]="false">
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="abrirSeriales()"
								class="archivoOpc">Ingresar Seriales</span>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<!-- <p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr [strNumFact]="encentradaSeleccionada.KAR_NRO"
		[strFechaSer]="encentradaSeleccionada.KAR_FECHA" [strTipoDoc]="'INVENT'" [strTipoTrn]="'EN'"
		(cerrar)="cerrarSeriales()"></app-invtrnkardexotr>
</p-dialog> -->

<p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr *ngIf="displayDialogSeriales" [strNumFact]="strNumFact" [strFechaSer]="strFechaSer" [strTipoDoc]="'INVENT'"
		[strTipoTrn]="'EN'" (cerrar)="cerrarSeriales()"></app-invtrnkardexotr>
</p-dialog>


<p-dialog header="Lotes" [(visible)]="displayDialogLotes" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-invtrnlote [auxiliar]="auxiliarlot" [detalle]="entradaSeleccionada" [encabezado]="encentradaSeleccionada"
		[cadena]="'EN'" (salir)="cerrarLotes()"></app-invtrnlote>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="encentradaSeleccionada.KAR_NRO" [strCampo]="'KAR_NRO'" (cerrar)="cerrarReportes()">
	</app-sacimaecfngimpr>
</p-dialog>
<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'INVENTRADA'" [txtNumComprobante] = "encentradaSeleccionada.KAR_NRO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
<!-- CDPJ -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<!-- CDPJ -->